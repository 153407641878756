import _ from "lodash";
import moment from "moment";
import * as TS from "../../../../types";
import * as DA from "../../../../types/DataAccess";
import Icons from "../../../controls/enumerations/Icons.Enumeration";


// TODO typing
type getIsReadyForOutlineResult = {
  ready:boolean,
  issues:Array<string>
}
function getIsReadyForOutline(adventureInfo:TS.AdventureOutlineAdventureInfo, activities:any): getIsReadyForOutlineResult {
  const issues = []
  // the adventure's startTime needs to be in the future
  let now = moment().add(10, 'm'); //.toDate() // we add 1 minute to have some wiggle room
  // TODO FUD check if this still works after removing .toDate()
  let adventureInfoReady = moment(adventureInfo.startTime) > now; 
  if(!adventureInfoReady) {
    issues.push("Das Startdatum der Überraschung muss mindesten 10 Minuten in der Zukunft liegen.")
  }

  // we need activities
  if((activities.length || []).length === 0) {
    issues.push("Keine Aktivitäten gefunden")
  }
  else {
    // each activity needs to have a duration
    if(!activities.every((a:any) => a.duration)) {
      issues.push("Allen Aktivitäten muss eine Dauer zugewiesen sein")
    }
    // each activity needs to have a startLocation
    if(!activities.every((a:any) => a.startLocation)) {
      issues.push("Allen Aktivitäten muss ein Anbieter zugewiesen sein")
    }
    // each activity needs to have a travelMode
    if(!activities.every((a:any) => a.travelMode)) {
      issues.push("Allen Aktivitäten muss ein Anreisemodus zugewiesen sein")
    }
  }

  // return
  return {
    issues, ready:issues.length === 0
  }
}

// TODO typing
function moveActivity(activities: Array<any>, order:number, direction:"up"|"down") {
  const modifier = direction === "up" ? -1 : 1
  const a1 = activities.find(a => a.order === order)
  const a2 = activities.find(a => a.order === order + modifier)
  a1.order += modifier
  a2.order -= modifier
  const sortedActivities = activities.sort((a,b) => a.order > b.order ? 1: -1).map(a => a)
  return sortedActivities
}

type loadResult = {
  activities: Array<TS.AssistantActivity>,
  adventureInfo: TS.AdventureOutlineAdventureInfo,
  outline?: TS.AdventureOutline,
}
async function load(adventureId:string): Promise<loadResult> {
  const adventure = await TS.AdventureOld.load(adventureId);

  // create the default adventure info (if we find an outline, we will change some of its properties)
  // prepare the start location (use the one in from the adventure, if that is not present, we use a fallback)
  const userStartLocation = adventure.UserStartLocation || {Lat:46.947415, Lng:7.440780}; // Bern, Bahnhof
  userStartLocation.text = adventure.UserStartLocationText || "Bern, Bahnhof [default fallback]";
  const adventureInfo: TS.AdventureOutlineAdventureInfo = {
    id: adventure._id,
    id4: TS.Adventure.getShortId(adventure._id),
    who: adventure.RecieverRealFirstName || adventure.RecieverName || "unbekannt",
    startTime: adventure.StartTime || adventure.UserStartTime || new Date(),
    startOffset: 10, // TODO ... get value
    endTime: new Date(),
    startLocation: {
      lat: userStartLocation.Lat, 
      lng: userStartLocation.Lng, 
      text: userStartLocation.text
    },
    startLocationUser: {
      lat: userStartLocation.Lat, 
      lng: userStartLocation.Lng, 
      text: userStartLocation.text
    },
    userStartTime: adventure.UserStartTime || new Date(),
    hasOutline:false
  }

  // get activities and saved outlines of adventure
  //let resultActivities = await getAllActivitiesForAdventure(adventureInfo.id);
  //let outlines = await TS.AdventureOutlineHelper.search({adventureId:adventureInfo.id}, {});
  
  // get activities of the adventure
  const activities = await getAllActivitiesForAdventure(adventureInfo.id);
  const defaultTravelMode = (!(adventure.TransportOptions || []).find((x:any) => { return x.indexOf("Auto") >= 0 }) ) ? "transit" : "driving";
  activities.forEach((a, index) => {
    a.order = index;
    a.travelMode = defaultTravelMode;
  });

  // get the outline - if we find one, we update properties on the adventureInfo and on the activities
  const outline = await TS.AdventureOutlineHelper.loadOutline(String(adventure._id));
  if(outline) {
    adventureInfo.startLocation = _.merge(adventureInfo.startLocation, outline.startLocation); // merge so we keep the startLocation.text
    adventureInfo.startTime = new Date(outline.startTime);
    adventureInfo.endTime = new Date(outline.endTime);
    adventureInfo.startOffset = outline.startOffset || 10;
    adventureInfo.hasOutline = true;

    // update our activity represenations with the data from the outline
    let index = 0;
    for(let oa of outline.activities) {
      // the provider location coordinates might have changed, we reload them here
      const providerLocation = await DA.ProviderLocationRepository.findById(oa.providerLocationId!);
      // TODO what if the provider location is not found?
      // get the provider
      let provider = null;
      if(oa.providerId) {
        provider = await DA.ProviderRepository.findById(oa.providerId);
      }
      
      // make the activity
      const activity = activities.find(a => a.activityId === oa.activityId);
      if(activity) {
        activity.order = index;
        activity.duration = oa.duration;
        activity.providerName = provider ? provider.name : "???";
        activity.providerId = oa.providerId;
        activity.providerLocationId = oa.providerLocationId;
        if(providerLocation!.start) {
          activity.startLocation = {lat: providerLocation!.start.coordinates.lat, lng: providerLocation!.start.coordinates.lng, text: providerLocation!.start.title || ""};
        }
        else {
          // TODO FUD ... is this right?
          activity.startLocation = {lat:oa.startLocation.lat, lng:oa.startLocation.lng, text:oa.startLocation.text} 
        }
        activity.endLocation = oa.endLocation ? {lat:oa.endLocation.lat, lng: oa.endLocation.lng, text:oa.endLocation.text} : undefined;
        activity.travelMode = oa.travelMode
        activity.time = oa.startTime;
        activity.routes = oa.routes || [];
      }
      
      index += 1;
    }
  }
  
  // done
  return {
    adventureInfo, activities, outline, //trips, tripsInvalid: false
  }
  
}

function getBookingStateIcon(state:string):any {
  switch (state) {
    case "waiting":
      return Icons.Hourglass;
    case "accepted":
      return Icons.ThumbsUp;
    case "declined":
      return Icons.ThumbsDown;
    default:
      return Icons.Cancel;
  }
}

function getBookingKindIcon(kind:string): any {
  switch(kind) {
    case "web":
      return Icons.Globe;
    case "email":
      return Icons.Envelope;
    case "phone":
      return Icons.Phone;
    case "none":
    default:
      return Icons.Cancel;
  }
}

function getBookingKindTitle(kind:string):string {
  switch(kind) {
    case "web":
      return "Online";
    case "email":
      return "E-Mail";
    case "phone":
      return "Telefonisch";
    default:
      return "unbekannt";
  }
}


/**
 * Returns all activities (or subactivities in case there are composite activities) with information needed
 */
async function getAllActivitiesForAdventure(adventureId:any): Promise<Array<TS.AssistantActivity>> {
  try {
    const activities: Array<TS.AssistantActivity> = [];
    // load the adventure
    const adventure = await TS.AdventureOld.findOne({_id:adventureId}, {Activities:1}); // await mongoose.models.Adventure.findOne({ "_id": adventureId })
    // iterate through all activities
    for (let a of adventure.Activities) {
      if (!a.RegionVariantId) {
        throw new Error("Ungültiges Format der zugewiesenen Aktivitäten")
      }
  
      let participantCount = _.get(a, "Configuration.ParticipantCount", 1)
      let regionCode = _.get(a, "RegionCode", "CH-BE")
      
      // if an activity has subactivities, we use those - if not, we use the activity
      if((a.Subactivities || []).length > 0) {
        // collect subactivities
        for(let sa of a.Subactivities) {
          const subActivity = await DA.ActivityRepository.findOneByFilter({"_id": sa.ActivityId});
          let subRegionVariant = subActivity!.regionVariants.find((rv:any) => rv.regionCode === regionCode);
          if(!subActivity) {
            throw new Error("Fehlende Subaktivität");
          }
          if (!subRegionVariant) {
            throw new Error("Fehlende Regionsvariante in der Subaktivität");
          }
          activities.push({
            activityId: subActivity._id!,
            parentActivityId: a.ActivityId,
            regionVariantId: subRegionVariant!._id!,
            title: subActivity.title.de,
            duration: subRegionVariant.duration.minutes * 60,
            participantCount,
            regionCode,
            order:0,
            travelMode:"",
            routes:[]
          });
        }
      }
      else {
        // no subactivities, we use the activity itself
        const activity = await DA.ActivityRepository.findOneByFilter({"_id": a.ActivityId});
        if(!activity) {
          throw new Error("Fehlende Aktivität");
        }
        let regionVariant = activity.regionVariants.find((rv:any) => rv._id.toString() === a.RegionVariantId.toString())
        if(!regionVariant) {
          throw new Error("Fehlende Regionsvariante");
        }
        activities.push({
          activityId: a.ActivityId,
          parentActivityId: null,
          regionVariantId: a.RegionVariantId,
          title: activity.title.de,
          duration: regionVariant.duration.minutes * 60,
          participantCount,
          regionCode,
          order:0,
          travelMode:"",
          routes:[]
        });
      }
    }
  
    // done
    return activities;
  } 
  catch (error) {
    console.error(error);
    return [];
  }
  
}

// export
export default {
  load,

  moveActivity,

  getIsReadyForOutline, 

  getBookingStateIcon,
  getBookingKindIcon,
  getBookingKindTitle
}