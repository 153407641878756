import { WeatherCondition } from "./WeatherCondition";
import { Mapper } from "../_Common/Mapper";
import { TranslatableMapper } from "../_Common/TranslatableMapper";


export class WeatherConditionMapper extends Mapper<WeatherCondition> {
  public apiEndpoint = "weatherConditions";

  public toDb(weatherCondition: WeatherCondition): any {
    return {
      _id: weatherCondition._id,
      title: TranslatableMapper.toDb(weatherCondition.title),
    }
  }

  public fromDb(obj:any): WeatherCondition {
    return {
      _id: obj._id || undefined,
      title: TranslatableMapper.fromDb(obj.title),
    }
  }

}