import React, {useState} from "react"
import _ from "lodash"
import Api from "../../../util/api2"

import {Redirect} from 'react-router-dom'
import {Card, Breadcrumb, Form, Heading, Checkbox, Loader, TextInput, TextArea, Validators} from "../../controls"

function Create() {
  // state
  const [data] = useState({title:"",shortDescription:"", longDescription:"", isSubactivity:false})
  const [busy, setBusy] = useState(false)
  const [activityId, setActivityId] = useState(null) // once this is filled, we redirect to the activity
  const [isSubactivity, setIsSubactivity] = useState(data.isSubactivity) // we only use this to show or hide the long description field
  const [cancel, setCancel] = useState(false)

  // save
  const onSave = async(formResult) => {
    setBusy(true)
    // gather data
    let updated_data = {}
    _.merge(updated_data, data)
    _.merge(updated_data, formResult.changeset)
    const payload = updated_data
    // save
    let apiResult = await Api.post("activities", "create", payload)
    if(apiResult.success) {
      setActivityId(apiResult.data.activity._id)
    }
    else {
      // TODO display error
    }
  }

  // render
  if(activityId) {
    // got an activityId - i.e. the activity was created ... redirect
    return <Redirect to={`/activities/${activityId}`} />
  }
  if(cancel) {
    return <Redirect to={`/activities`} />
  }

  let content = null
  if(busy) {
    content = <Loader />
  }
  else {
    const textareaLongDescription = isSubactivity ? null : <TextArea path="longDescription" label="Lange Beschreibung"  />
    content = (
      <Form entity={data} onSave={onSave} onCancel={() => setCancel(true)} labelSave="erstellen" labelCancel="abbrechen">
        <Checkbox path="isSubactivity" label="Unteraktivität" secondaryLabel="ist eine Unteraktivität (wird im Shop nicht angezeigt)" onChange={setIsSubactivity} />
        <TextInput path="title" label="Titel"  validate={Validators.isRequired("Bitte einen Titel eingeben")} placeholder="Titel"  />
        <TextArea path="shortDescription" label="Kurze Beschreibung" validate={Validators.isRequired("Bitte eine kurze Beschreibung angeben")} />
        {textareaLongDescription}
      </Form>
    )
  }
  return (
    <div id="v-activity-create">
      <Breadcrumb links={[ {title:'Home', to:'/'}, {title:"Aktivitäten", to:"/activities"}]} location="Aktivität erstellen" />
      <Heading>Aktivität erstellen</Heading>
      <Card>
        {content}
      </Card>

    </div>
  )
}

export default Create