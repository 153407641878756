import React, {useContext, useState, useEffect} from "react"
import _ from "lodash"

import Util from "./_util";
import { Validation, ValidationError } from ".";
import {FormContext} from "./FormContext";
import { Label, MapLink } from ".";

import CSS from "./Coordinates.module.scss";


type CoordinatesProps = {
  path: string,
  label?: string,
  explanation?: string,
  pathLat?: string,
  pathLng?: string, 
  required?: boolean,
  locationValidator?: (v:any) => Validation
}
export function Coordinates({path, label, explanation, pathLat, pathLng, required, locationValidator} : CoordinatesProps) {
  // state
  const [id] = useState(Util.getId(label)) 
  const [value, setValue] = useState("") 
  const [validation, setValidation] = useState<Validation>(new Validation(true));

  // context
  const form = useContext(FormContext)

  // mount
  useEffect(() => {
    if(form.entity && path) {
      let _valueCoords = _.get(form.entity, path) // TODO what if no value is found?
      let _valueString = _latLngToString(_valueCoords, pathLat, pathLng)
      let _validation = _validateLatLng(_valueCoords, pathLat, pathLng, required || false, locationValidator)

      setValue(_valueString)
      setValidation(_validation)

      form.onFieldChange(path, _valueCoords, _validation)
    }
  }, [form.entity, setValidation, setValue, path, required])

  // form.resetIndex changed -> reset the item
  useEffect(() => {
    if(form.resetIndex !== 0) {
      // TODO not sure if this is correct, should it not set the stringified version?
      setValue(form.getOldValue(path))
    }
  }, [form.resetIndex, path, form])

  // to make sure the displayed value also changes when changed externally (i.e. another TextInput serving the same path or when manually trigger an onChangeField event on FormContext)
  useEffect(() => {
    const newValue = form.getNewValue(path);
    if(newValue) {
      const newValueString = _latLngToString(newValue, pathLat, pathLng);
      const curValueString = _latLngToString(_stringToLatLng(value, pathLat, pathLng), pathLat, pathLng);
      if(newValueString !== curValueString) {
        onChange(_latLngToString(newValue, pathLat, pathLng));
      } 
    }
  }, [form.version]);


  // handler
  const onChange = (v:string) => {
    let _valueString = v;
    let _valueCoords = _stringToLatLng(_valueString, pathLat, pathLng)
    let _validation = _validateLatLng(_valueCoords, pathLat, pathLng, required || false, locationValidator)

    form.onFieldChange(path, _valueCoords, _validation)
    setValue(_valueString)
    setValidation(_validation)
  }

  // render 
  let map_link = null;
  if(validation.valid && value) {
    let coords = _stringToLatLng(value, pathLat, pathLng);
    if(coords) {
      map_link = <MapLink lat={coords[pathLat!]} lng={coords[pathLng!]} />
    }
  }
  return (
    <div className={CSS.container}>
      <Label label={label} explanation={explanation}>
        <input id={id} type="text" value={value} onChange={e => onChange(e.target.value)} placeholder="lat, lng" />
      </Label>
      {map_link}
      <ValidationError validation={validation} />
    </div>
  )
}

function _latLngToString(latLng:any, pathLat:any, pathLng:any) : string {
  if(_.isNil(latLng)) {
    return ''
  }
  else {
    return `${latLng[pathLat]}, ${latLng[pathLng]}`
  }
}

function _stringToLatLng(s:string, pathLat:any, pathLng:any) : any{
  let latlng:any = {}
  if((s || "").trim().length === 0) {
    latlng[pathLat] = null
    latlng[pathLng] = null
    latlng = null
  }
  else {
    const tokens:any[] = s.split(',')
    const lat = _.isNil(tokens[0]) || tokens[0].trim().length === 0 || isNaN(tokens[0]) ? null : Number(tokens[0])
    const lng = _.isNil(tokens[1]) || tokens[1].trim().length === 0 || isNaN(tokens[1]) ? null : Number(tokens[1])
    latlng[pathLat] = lat
    latlng[pathLng] = lng
  }

  return latlng
  
}

function _validateLatLng(latlng:any, pathLat:any, pathLng:any, required:boolean, locationValidator:any) : any {
  let validation:any = {valid:true, message:null}
  
  // internal validation
  if(!_.isNil(latlng)) {
    if(_.isNil(latlng[pathLat]) || _.isNil(latlng[pathLng])) {
      validation.valid = false
      validation.message = "ungültige Koordinaten"
    }
    else if(locationValidator) {
      validation = locationValidator(latlng);
    }
  } 
  else {
    if(required) {
      validation.valid = false
      validation.message = "bitte Koordinaten angeben"
    }
  }

  return validation
}


/*

Notes:
- unlike other form elements coordinates do no support custom validation
- unlike other form elements coordinates provider a `required` property
- we follow google's way by taking in a string with two number separated by a comma, e.g. 46.960289, 7.338221
- the first number is lat, the second number is lng
- lat ranges from -90 to 90
- lng ranges from -180 to 180
- wrapping is currently not supported (i.e. -190 is not automatitically converted to 170)
- more info: https://developers.google.com/maps/documentation/javascript/reference/coordinates#LatLng
*/