import React from "react";
import DashboardPanel from "./DashboardPanel";
import { Button, Rating, Loader, Text, Table, Tr, Td } from "../../controls";
import iconStar from './../../../assets/images/icon_star.svg';

type RatingsProps = {
  data: any, dataStatus:any, requestData:any
}
export default function Ratings({data, dataStatus, requestData} : RatingsProps) {
  const loadData = async() => {
    await requestData();
  }

  // prepare content to render
  let content = null;
  if(dataStatus === "notloaded") {
    content = (
      <>
        <Text>Daten wurden noch nicht geladen.</Text> <Button size="small" onClick={loadData}>Daten laden</Button>
      </>
    );
  }
  else if(dataStatus === "loading") {
    content = <Loader />;
  }
  else {
    content = (
      <Table>
        <Tr>
          <Td label>Ablauf</Td>
          <Td align="right">{data.organization.average.toFixed(1)}</Td>
        </Tr>
        <Tr>
          <Td label>Anbieter</Td>
          <Td align="right">{data.providers.average.toFixed(1)}</Td>
        </Tr>
        <Tr>
          <Td label>bewertete Überraschungen</Td>
          <Td align="right">{data.overall.count}</Td>
        </Tr>
        <Tr>
          <Td></Td>
          <Td><Rating value={data.overall.average} max={5} displayValue={true} /></Td>
        </Tr>
      </Table>
    )
  }

  // render
  return (
    <DashboardPanel title="Bewertungen" icon={iconStar} iconRounded={false} smallOnDesktop={true} smallOnMobile={true}>
      {content}
    </DashboardPanel>
  )
}