import React, { useState } from "react";
import { Icon, Icons } from ".";

// styling
import CSS from "./Modal.module.scss";
type ModalProps = {
  isOpen: boolean,
  title: string,
  wide?: boolean,
  onClose: () => void,
  children: React.ReactNode|React.ReactNode[]
}
export function Modal({isOpen, title, wide=false, onClose, children} : ModalProps) {
  // state
  const [isWide, setIsWide] = useState<boolean>(wide);

  // render nothing if not open
  if(!isOpen) {
    return null;
  }

  // render
  return (
    <div className={CSS.modal}>
      <div className={`${CSS.inner} ${isWide ? CSS.wide : ""}`}>
        <div className={CSS.header}>
          <div className={CSS.title}>{title}</div>
          <div className={CSS.buttons_right}>
            <button className={CSS.button} onClick={() => setIsWide(!isWide)}>{isWide ? <Icon icon={Icons.CompressAlt} /> : <Icon icon={Icons.ArrowsAltH} />}</button>
            <button className={CSS.button} onClick={onClose}><Icon icon={Icons.Cancel} /></button>
          </div>
        </div>
        <div className={CSS.content}>
          {children}
        </div>
      </div>
    </div>
  )
}


export function useModal<T>() {
  const [action, setAction] = useState<T|undefined>();
  const [title, setTitle] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [params, setParams] = useState<any>({ding:"blingo"});

  const modal = {
    action,
    isOpen, 
    title, 
    params,
    onClose: () => {
      setAction(undefined);
      setIsOpen(false);
    }
  }

  const setModal = (action:T, title: string, params?: any) => {
    setAction(action);
    setTitle(title);
    setIsOpen(true);
    setParams(params);
  }

  return [modal, setModal ] as const
}