import * as DA from "../DataAccess";

export class ActivityInstruction {
  static getLines(activityInstruction: DA.ActivityInstruction, numberOfParticipants:number, language:"de"|"en" = "de"):string[] {
    const lines = activityInstruction.text[language].split("\n")
      .filter(s => s.trim().length > 0)
      .map((line:any) => {
        const parts = line.split('|');
        if(parts.length > 1 && numberOfParticipants > 1) {
          return parts[1];
        }
        else {
          return parts[0];
        }
      }
    );
    return lines;
  }
}