import React, { useEffect, useState, useContext } from "react";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";

import { Dropdown, DropdownOption, Validators, Actions, Alert, DropdownOptions } from "../../controls";

type ProviderPriceListsCreateProps = {
  providerId:string,
  activities: TS.Activity[],
  regions: DA.Region[],
  priceLists: DA.PriceList[],
  onCreated: (priceList:DA.PriceList) => void, 
  closeDialog: () => void,
}
export default function ProviderPriceListsCreate({providerId, activities, regions, priceLists, closeDialog, onCreated}:ProviderPriceListsCreateProps) {
  // state
  const [busy, setBusy] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const [selected, setSelected] = useState<{provider_id:string, activity_id:string, currencyCode:"CHF"|"EUR", regionCode:string}>({provider_id:"", activity_id:"", currencyCode:"CHF", regionCode:""});
  const [optionsActivities, setOptionsActivities] = useState<DropdownOption[]>([]);
  const [optionsRegions, setOptionsRegions] = useState<DropdownOption[]>([]);

  // mount
  useEffect(() => {
    // activities
    const optionsActivities: DropdownOption[] = activities.sort((a,b) => a.title.de.localeCompare(b.title.de)).map(a => {
      return {label: a.title.de, value:a._id};
    });
    optionsActivities.unshift({label:"---", value:""});
    // regions
    const optionsRegions: DropdownOption[] = regions.map(r => {
      return {label: r.title.de, value:r.code};
    });
    optionsRegions.unshift({label:"---", value:""});
    // update state
    setOptionsActivities(optionsActivities);
    setOptionsRegions(optionsRegions);
  }, []);

  // selected value changes
  useEffect(() => {
    // validate - need both activity and provider
    let isValid = true;
    if(selected.activity_id === "") {
      isValid = false;
    }
    // validate - cannot create duplicates
    let isDuplicate = priceLists.some(pl => pl.activity_id === selected.activity_id && (pl.regionCode || "") === selected.regionCode);
    // update state
    setIsValid(isValid);
    setIsDuplicate(isDuplicate);
  }, [selected]);

  // user cancels
  const onCancel = () => { 
    closeDialog();
  };

  // user wants to save
  const onSave = async() => {
    const priceList = DA.PriceListRepository.make(providerId, selected.activity_id, selected.currencyCode, selected.regionCode === "" ? null : selected.regionCode);
    priceList.regionCode = selected.regionCode != "" ? selected.regionCode : null;
    const createResult = await DA.PriceListRepository.create(priceList);
    // onCreated will also close the dialog and reload the list
    onCreated(createResult.data as DA.PriceList);
  }

  // pre-render duplicate alert
  let duplicateAlert = null;
  if(isDuplicate) {
    duplicateAlert = <Alert size="small" intent="error">Es besteht bereits eine Preisliste für diese Kombination von Aktivät, Anbieter, Region.</Alert>
  }
  // render
  // TODO disable dropdown when form busy
  return (
    <>
      <Dropdown 
        label="Aktivität" 
        value={selected.activity_id}
        onChange={v => setSelected({...selected, activity_id:v})}
        options={optionsActivities} 
        validate={Validators.isRequired("bitte Aktivität wählen")} 
      />
      <Dropdown 
        label="Region" 
        value={selected.regionCode}
        onChange={v => setSelected({...selected, regionCode:v})}
        explanation="wird keine Region angegeben, wird dieser Preise für alle Regionen verwendet, für welche kein Preis definiert wurde" path="regionCode" 
        options={optionsRegions} 
      />
      <Dropdown 
        label="Währung"
        value={selected.currencyCode}
        onChange={v => setSelected({...selected, currencyCode:v as "CHF"|"EUR"})}
        options={DropdownOptions.Currencies}
      />

      {duplicateAlert}

      <Actions onCancel={onCancel} onSave={onSave} disabled={!isValid || isDuplicate} />
    </>
  )
}
