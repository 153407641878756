import _ from "lodash";
import React, { useEffect, useState } from "react";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";

type Data = {
  providers: DA.Provider[], // all providers that support collective invoices
  collectiveInvoices: TS.CollectiveInvoice[], 
  bookingRequests: DA.BookingRequest[], // potential booking requests
}

// context
type CollectiveInvoicesContextType = {
  isLoaded: boolean,
  isValid: boolean,

  data: Data,
  period: TS.CollectiveInvoicePeriod,

  reload: () => void,
  createInvoice: (provider:DA.Provider, bookingRequests: DA.BookingRequest[]) => void,
  deleteInvoice: (invoice:TS.CollectiveInvoice) => void,
  changePeriod: (period:TS.CollectiveInvoicePeriod) => void,
}
const CollectiveInvoicesContext = React.createContext<CollectiveInvoicesContextType>({} as CollectiveInvoicesContextType);

// provider
type CollectiveInvoicesProviderProps = {
  children: React.ReactNode|Array<React.ReactNode>
}
function CollectiveInvoicesProvider({children}: CollectiveInvoicesProviderProps) {
  // state
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [data, setData] = useState<Data>({providers:[], bookingRequests:[], collectiveInvoices:[]});
  const [period, setPeriod] = useState<TS.CollectiveInvoicePeriod>(TS.CollectiveInvoice.getPreviousPeriod());
  
  // mount
  useEffect(() => {
    const load = async() => {
      setData(await loadData(data, period));
      setIsLoaded(true);
    }
    load();
  }, []);

  // provider value
  const providerValue = {
    // values
    data,
    isLoaded,
    isValid,
    period,
    
    // functions
    reload: async() => {
      setIsLoaded(false);
      setData(await loadData(data, period, {keepProviders:true}));
      setIsLoaded(true);
    },
    changePeriod: async(period:TS.CollectiveInvoicePeriod) => {
      setPeriod(period);
      setIsLoaded(false);
      setData(await loadData(data, period, {keepInvoices:true, keepProviders:true}));
      setIsLoaded(true);
    },
    createInvoice: async(provider:DA.Provider, bookingRequests: DA.BookingRequest[]) => {
      setIsLoaded(false);
      TS.CollectiveInvoice.create(provider, period, bookingRequests);
      setData(await loadData(data, period, {keepProviders:true}))
      setIsLoaded(true);
    },
    deleteInvoice: async(invoice:TS.CollectiveInvoice) => {
      // remove
      await TS.CollectiveInvoice.remove(invoice);
      // reload data
      setIsLoaded(false);
      setData(await loadData(data, period, {keepProviders:true}));
      setIsLoaded(true);
    }
  }

  // render
  return (
    <CollectiveInvoicesContext.Provider value={providerValue}>
      {children}
    </CollectiveInvoicesContext.Provider>
  )
}

type LoadDataOptions = {
  keepProviders?:boolean,
  keepBookingRequests?:boolean,
  keepInvoices?:boolean
}
async function loadData(data:Data, period:TS.CollectiveInvoicePeriod, options?:LoadDataOptions): Promise<Data> {
  options = options || {};
  const providers = options.keepProviders ? data.providers : await TS.CollectiveInvoice.getProviders();
  const bookingRequests = options.keepBookingRequests ? data.bookingRequests : await TS.CollectiveInvoice.getOpenBookingRequests(period);
  const collectiveInvoices = options.keepInvoices ? data.collectiveInvoices : await TS.CollectiveInvoice.all();
  return {
    providers,
    bookingRequests,
    collectiveInvoices,
  }
}


// export
export {
  CollectiveInvoicesContext, 
  CollectiveInvoicesProvider,
}