import React, {useState, useEffect, useContext} from "react";

import {SurpriseContext} from "../../Surprise.Context";
import {Loader} from "../../../../controls";
import Selfie from "./Selfie";
import * as TS from "../../../../../types";
import * as DA from "../../../../../types/DataAccess";

export default function Selfies() {
  // context 
  const SC = useContext(SurpriseContext);
  // state
  const [selfies, setSelfies] = useState<DA.AdventureImage[]|null>(null);

  // mount
  useEffect(() => {
    const load = async() => {
      setSelfies(await DA.AdventureImageRepository.findForAdventure(SC.surprise._id));
    }
    load();
  }, []);

  // render while loading
  if(!selfies) {
    return <Loader />
  }

  // render nothing if there are no selfies
  if(selfies.length === 0) {
    return null
  }

  // render
  let items = selfies.map((selfie, index) => {
    return <Selfie key={index} adventureImage={selfie}/>
  })
  return (
    <div id="v-surprise-contextual-selfies">
      {items}
    </div>
  )
}
