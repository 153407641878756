import React, {useState, useEffect } from "react";
import { Redirect } from "react-router";
import * as DA from "../../../types/DataAccess";
// components & subviews
import { Breadcrumb, Card, Heading, Modal, List, ListColumn, Loader, Validators, Form, TextInput } from "../../controls";

// list columns
const columns: ListColumn[] = [
  {
    header: "Name",
    path: "Name"
  }
];

// the component
export default function AdventureTemplateList(props:any) {
  // state
  const [templates, setTemplates] = useState<DA.AdventureTemplate[]|null>(null);
  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<string|null>(null);

  // {Util.getRouteId(props)}
  // mount
  useEffect(() => {
    load();
  }, [])

  // loads data
  const load = async() => {
    setTemplates(null);
    const templates = await DA.AdventureTemplateRepository.findAll();
    setTemplates(templates);
  }

  // // user wants to create or update
  // const onClickAddUpdate = (template?: TS.AdventureTemplate) => {
    
  //   const title = template ? "Überraschungs-Vorlage bearbeiten" : "Neue Überraschungs-Vorlage erfassen";
  //   setModalTitle(title);
  //   setModalParam(template || new TS.AdventureTemplate());
  //   setModalOpen(true);
  //   //MC.push(title, <Edit template={template || new TS.AdventureTemplate()} onUpdate={onUpdate} />);
  // }

  // user updated something (edit or create)
  const onUpdate = async() => {
    await load();
  }

  // user wants to delete
  const onDelete = async(template: DA.AdventureStepTemplate) => {
    await DA.AdventureStepTemplateRepository.remove(template._id!);
    load();
  }

  const closeDialog = () => {
    setCreateOpen(false);
  }

  const onCreated = (id:string) => {
    setRedirect(id);
  }

  // redirect if requests
  if(redirect !== null) {
    return <Redirect to={`/adventuretemplates/${redirect}`} />
  }

  // pre-render
  let content = null;
  if(templates === null) {
    content = <Loader />;
  }

  // render
  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}]} location="Überraschungs-Vorlagen" />
    <Heading>Überraschungs-Vorlagen</Heading>
    <Card>
      <List 
        columns={columns} 
        items={templates} 
        onClickCreate={() => setCreateOpen(true)} 
        rowTo={v => `/adventuretemplates/${v._id}`}
        getKey={item => item._id} 
        onClickDelete={onDelete} 
      />
    </Card>
    <Modal isOpen={createOpen} title="Überraschungs-Vorlage erzeugen" onClose={closeDialog}>
      <Create onCreated={onCreated} onClose={closeDialog} />
    </Modal>
    
  </>
}

type CreateProps = {
  onCreated: (id: string) => void,
  onClose: () => void,
}
function Create({onClose, onCreated} : CreateProps) {
  const [entity] = useState<DA.AdventureTemplate>(DA.AdventureTemplateRepository.make());

  const onSave = async(formResult:any) => {
    const merged = formResult.merge(entity);
    const result = await DA.AdventureTemplateRepository.create(merged);
    if(result.success && result.data) {
      onCreated(result.data._id!);
    }
    else {
      // TODO handle error properly
      alert("error");
    }
  }

  return (
    <Form entity={entity} onSave={onSave} onCancel={onClose}>
      <TextInput path="Name" label="Name der Vorlage" validate={Validators.isRequired("Bitte Namen der Vorlage eingeben")} />
    </Form>
  )
}