export class AdventureRating {
  Rating: number = 0;
  Rating2: number = 0;
  Rating3: number = 0;
  Comment: string = "";
  Feedback: string = "";
  ProcessedOn: Date|null = null; // an operator finalized their processing of the rating in the admin tool. This is used to filter adventures that still need their rating processed

  public static fromDb(obj:any): AdventureRating|null {
    if(obj) {
      const r = new AdventureRating();
      r.Comment = obj.Comment || "";
      r.Feedback = obj.Feedback || "";
      r.Rating = obj.Rating || 0; // overall
      r.Rating2 = obj.Rating2 || 0; // instructions, organization ("ablauf")
      r.Rating3 = obj.Rating3 || 0; // providers
      r.ProcessedOn = obj.ProcessedOn ? new Date(obj.ProcessedOn) : null;
      return r;
    }
    else {
      return null;
    }
  }
}