import React, { useState } from "react";
import _ from "lodash";
import * as TS from "../../../../types";
import * as DA from "../../../../types/DataAccess";
import AssistantLogic from "./Assistant.Logic";

type AssistantContextData = {
  activities: Array<any>,
  activitiesSaved: Array<any>, // TODO we use this in Booking.js ... to make sure booking requestss can be made ... quite a bit of silly fuzzy logic, fix that
  bookingRequests: Array<DA.BookingRequest>,
  outline?: TS.AdventureOutline
}
type AssistantContextType = {
  // values
  data: AssistantContextData,
  adventureInfo: TS.AdventureOutlineAdventureInfo,
  readyForOutline: { ready: boolean, issues: Array<string> },
  isLoaded: boolean,
  tripsInvalid: boolean, // TODO rename to routesInvalid? travelInfoInvalid?
  
  // functions
  load:(adventureId:string) => Promise<void>,
  reloadBookingRequests:() => void,
  createOutline:() => void,
  recalculateOutline:(fromRoute: TS.AdventureOutlineRoute) => void,
  setUserStartTime:(date:Date) => void,
  setStartTime:(date:Date) => void,
  setStartOffset: (minutes: number) => void,
  setStartLocation: (location:TS.AdventureOutlineLocation) =>  void,
  moveActivity: (order:any, direction:any) => void,
  updateActivity: (activityId:any, update:any) => void
}
// the context and its type
const AssistantContext = React.createContext<AssistantContextType|null>(null);

// the provider and its props
type AssistantProviderProps = {
  children: React.ReactNode|Array<React.ReactNode>
}
function AssistantProvider({children}:AssistantProviderProps) {
  // state
  const [adventureInfo, setAdventureInfo] = useState<TS.AdventureOutlineAdventureInfo>({id:"", id4:"", who:"", startTime:new Date(), endTime: new Date(), hasOutline: false, startOffset:0, startLocation:{lat:0, lng:0, text:""}, startLocationUser:{lat:0, lng:0, text:""}, userStartTime:new Date()});
  const [readyForOutline, setReadyForOutline] = useState({ready:false, issues:["noch nicht geladen"]});
  const [tripsInvalid, setTripsInvalid] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState<AssistantContextData>({
    activities: [],
    activitiesSaved: [],
    bookingRequests: [],
  });

  // the provided value
  const providerValue = {
    // values
    adventureInfo,
    readyForOutline,
    isLoaded,
    tripsInvalid,
    data,

    // functions
    load: async(adventureId:string) => {
      setIsLoaded(false);
      const loadedData = await AssistantLogic.load(adventureId);
      const bookingRequests = await DA.BookingRequestRepository.findByAdventure(adventureId);
      setData ({
        ...data,
        activities: loadedData.activities,
        activitiesSaved: _.cloneDeep(loadedData.activities), // only saved changes
        bookingRequests,
        outline: loadedData.outline
      })
      setAdventureInfo(loadedData.adventureInfo);
      setIsLoaded(true);
      setTripsInvalid(false);
      setReadyForOutline(AssistantLogic.getIsReadyForOutline(loadedData.adventureInfo, loadedData.activities));
    },

    createOutline: async() => {
      // prepare
      const sortedActivities = data.activities.sort((a:any, b:any) => a.order > b.order ? 1 : -1); // TODO should sort inside createOutline
    
      // create 
      //const result = await AdventureOutline.createOutline(adventureId, startLocation, startTime, startOffset, sortedActivities);
      const result = await TS.AdventureOutlineHelper.createOutline(adventureInfo, sortedActivities);

      // handle result
      if(result.success) {
        // fill data which was returned (or errors)
        for(let i = 0; i < data.activities.length; i+=1) {
          // TODO is it save to rely on indices?
          let time = new Date();
          if(result.data && result.data.activities && result.data.activities[i]) {
            time = new Date(result.data.activities[i]);
          }
          data.activities[i].time = time; //new Date(result.data.activities[i])
        }
    
        // update adventure info
        const updatedAdventureInfo = {...adventureInfo};
        updatedAdventureInfo.startTime = result.data ? result.data.outline.startTime : new Date();
        updatedAdventureInfo.endTime = result.data ? result.data.outline.endTime : new Date();
        updatedAdventureInfo.hasOutline = true;
        setAdventureInfo(updatedAdventureInfo);
    
        // update state
        setTripsInvalid(false);
        setData({
          ...data,
          activities: [...data.activities],
          activitiesSaved: _.cloneDeep([...data.activities])
        });
        
      }
      else {
        let lines = [];
        lines.push("Der Planungsvorschlag konnte nicht erstellt werden. Das kann daran liegen, dass das Wunschdatum zu weit in der Zukunft liegt um ÖV-Verbindungen anzufragen.");
        lines.push("");
        (result.missingData || []).forEach(md => lines.push(md));
        alert(lines.join("\n"));
      }
    },

    recalculateOutline: async(fromRoute: TS.AdventureOutlineRoute) => {
      if(data.outline) {
        await TS.AdventureOutlineHelper.recalculate(data.outline, fromRoute, adventureInfo); 
      }
    },

    setUserStartTime: (date: Date) => {
      const startTime = data.outline ? adventureInfo.startTime : date; // only use this date if we have no outline yet
      const updatedAdventureInfo = {...adventureInfo, userStartTime: date, startTime};
      setAdventureInfo(updatedAdventureInfo);
      setReadyForOutline(AssistantLogic.getIsReadyForOutline(updatedAdventureInfo, data.activities));
    },

    setStartTime: (date: Date) => {
      const updatedAdventureInfo = {...adventureInfo, startTime:new Date(date)};
      setAdventureInfo(updatedAdventureInfo);
      setReadyForOutline(AssistantLogic.getIsReadyForOutline(updatedAdventureInfo, data.activities));
    },

    setStartOffset: (minutes: number) => {
      const updatedAdventureInfo = {...adventureInfo, startOffset:minutes};
      setReadyForOutline(AssistantLogic.getIsReadyForOutline(updatedAdventureInfo, data.activities));
      setAdventureInfo(updatedAdventureInfo);
    },

    setStartLocation: (location:TS.AdventureOutlineLocation) => {
      const updatedAdventureInfo = {...adventureInfo, startLocation:location};
      setReadyForOutline(AssistantLogic.getIsReadyForOutline(updatedAdventureInfo, data.activities));
      setAdventureInfo(updatedAdventureInfo);
    },
      
    moveActivity: (order:any, direction:any) => {
      const updatedActivities = AssistantLogic.moveActivity(data.activities, order, direction);
      setTripsInvalid(true);
      setData({...data, activities: updatedActivities});
    },

    reloadBookingRequests: async() => {
      const bookingRequests = await DA.BookingRequestRepository.findByAdventure(adventureInfo.id);
      setData({
        ...data,
        bookingRequests
      });
    },
          
    updateActivity: (activityId:any, update:any) => {
      const a = data.activities.find((a:any) => String(a.activityId) === String(activityId));
      _.merge(a, update);
      setReadyForOutline(AssistantLogic.getIsReadyForOutline(adventureInfo, data.activities));
      setTripsInvalid(true);
    }
  }

	// render
	return (
		<AssistantContext.Provider value={providerValue}>
      {children}
		</AssistantContext.Provider>
	)
}

// and export
export {
  AssistantProvider, 
  AssistantContext,
}