import React, {useContext, useEffect, useState} from "react";
import { PageContext } from "./Page.Context";
import Config from "../../../config";
import { Link, Loader } from "../../controls";

import CSS from "./Page.Preview.module.scss";

type PagePreviewProps = {
  country: "CH"|"DE"|"ch"|"de"
}
export default function PagePreview({country} : PagePreviewProps) {
  // context
  const PC = useContext(PageContext);
  // state
  const [url, setUrl] = useState<string|null>(null);

  // side effect: page has changed
  useEffect(() => {
    const url:string = `${Config.web.url[country]}${PC.page!.path}?preview&country=${country}`;
    setUrl(url);
  }, [PC.page, country])

  // pre-render
  let content = null;
  if(!url) {
    content = <Loader text="lade ..." />;
  }
  else {
    content = <>
      <div className={CSS.link}>
        <Link to={url} target="_blank">{url}</Link>
      </div>
      <div className={CSS.browser}>
        <iframe src={url} title="page preview" />
      </div>)
    </>
  }
  // render
  return <div className={CSS.container}>
    {content}
  </div>
}