import React from "react";
import { Icon, Icons } from ".";
import CSS from "./Stars.module.scss";


type StarsProps = {
  max: number,
  value: number
}
export function Stars({max, value}: StarsProps) {
  if(value > max) max = value;
  const stars = value;
  const fullStars = Math.floor(stars);
  const halfStars = (stars - fullStars) >= 0.5 ? 1:0;
  const emptyStars = max - fullStars - halfStars;
  const starSpans:any[] = [];

  const items:any = [];
  
  // full stars
  for(let i=0; i < fullStars; i++) {
    items.push(<Icon key={`full_${i}`} icon={Icons.StarFull} />)
    //starSpans.push(<span key={'full_' + i} className={`${CSS.star} ${CSS.on}`}></span>)

  }
  // half star
  for(let i=0; i < halfStars; i++) {
    items.push(<Icon key={`half_${i}`}  icon={Icons.StarHalfAlt} />)
    //starSpans.push(<span key={'half_' + i} className={`${CSS.star} ${CSS.half}`}></span>)
  }
  // empty stars
  for(let i=0; i < emptyStars; i++) {
    items.push(<Icon key={`empty_${i}`}  icon={Icons.StarEmpty} />);
    //starSpans.push(<span key={'empty_' + i} className={CSS.star}></span>)
  }

  return <div className={CSS.container}>{items}</div>
  
}