import React, { useContext } from "react";
import { Checkbox } from "../../../../controls";
import { SearchContext } from "../Search.Context";
import FilterGroup from "../Search.FilterGroup";

export default function BulkOrder() {
  // context
  const Search = useContext(SearchContext);

  // onChange
  const onChange = (property:"onlyPrepaid"|"includePrepaidInWarehouse", checked:boolean) => {
    const updatedFilter = Search.filter.clone();
    updatedFilter[property] = checked;
    Search.reset(updatedFilter);
  }

  // render
  return (
    <FilterGroup title="Sammelbestellungen">
      <Checkbox secondaryLabel="nur Überraschungen aus Sammelbestellungen" value={Search.filter.onlyPrepaid} onChange={checked => onChange("onlyPrepaid", checked)} />
      <Checkbox secondaryLabel="auch nicht verkaufte aus Sammelbestellungen anzeigen" value={Search.filter.includePrepaidInWarehouse} onChange={checked => onChange("includePrepaidInWarehouse", checked)} />
    </FilterGroup>
  )
}