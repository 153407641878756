import { Module, ModuleInfo, Theme } from ".";

export class VideoModule implements Module {
  readonly code: string;

  _id?: string;
  countries: string[];
  position: number;
  theme: Theme;
  anchor?: string;

  elements: {
    title?: string;
    subtitle?: string;
    text?: string;
    youtubeId?: string;
    vimeoId?: string;
    layout: "stacked"|"beside";
    buttonText?: string;
    buttonHref?: string;  
  }

  constructor() {
    this.code = VideoModule.info.code;
    this.countries = ["CH", "DE"];
    this.position = 0;
    this.theme = "primary";
    this.elements = {
      title: "",
      subtitle: "",
      text: "",
      youtubeId: "",
      vimeoId: "",
      layout: "stacked",
      buttonText: "",
      buttonHref: ""
    }
  }

  static get info(): ModuleInfo {
    return {
      code: "video",
      title: "Video-Modul",
      description: "Video-Modul"
    }
  }
}