import _ from "lodash";
import React from "react";
import { Icon, Icons } from ".";
// style
import CSS from "./Value.module.scss";

type ValueProps = {
  children?: React.ReactNode|Array<React.ReactNode>,
  onClick?: () => void,
  label?: string,
  explanation?: string,
  placeholder?: string,
  disabled?: boolean,
  className?: string,
  size?: "small"|"medium"
}
/**
 * Displays a value and an edit button if a onClick callback is provided
 * @param param0 
 * @returns 
 */
export function Value({children, onClick, label, explanation, placeholder, disabled, className, size} : ValueProps) {
  // defaults
  size = size || "medium";

  // handle click event
  const _onClick = () => {
    if(!disabled && onClick) {
      onClick();
    }
  }

  // pre-render
  let content = children;
  if(!_.isNil(placeholder)) {
    if(_.isNil(children) || String(children).trim().length === 0) {
      content = <div className={CSS.placeholder}>{placeholder}</div>
    }
  }
  const c_label = !_.isNil(label) ? <div className={CSS.label}>{label}</div> : null;
  const c_explanation = !_.isNil(explanation) ? <div className={CSS.explanation}>{explanation}</div> : null;
  const c_icon = onClick ? <div className={CSS.icon} onClick={_onClick}><Icon icon={Icons.Edit} /></div> : null;
  // render
  return (
    <div className={`${CSS.container} ${disabled && CSS.disabled} ${CSS[size]}`}>
      <div className={`${CSS.inner} ${className || ""}`}>
        {c_label}
        <div className={CSS.content}>
          <div className={CSS.value} onClick={_onClick}>
            {content}
          </div>
          {c_icon}
        </div>
        {c_explanation}
      </div>
    </div> 
  )
}




