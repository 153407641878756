import React, {useState} from "react";
import Code from "../Code";
import {Form, TextInput, TextArea, Validators, Card, Heading} from "../../../controls";

export default function FormDemo() {
  const [entity] = useState({firstName:"Peter", middleName:"", lastName:"", notes:""})
  const [formResult, setFormResult] = useState({})
  const [newEntity, setNewEntity] = useState({})
  const save = async(formResult) => {
    setFormResult(formResult);
    let newEntity = formResult.merge(entity);
    setNewEntity(newEntity);

    /* Note: merge() does:

      let newEntity = {}
      _.merge(newEntity, entity)
      _.merge(newEntity, formData.changeset)
    */
  }
  return <>
    <Heading>Form</Heading>
    <Card>
      <Form entity={entity} onSave={save}>
        <TextInput label="Vorname" path="firstName" validate={Validators.isRequired("Vorname eingeben")} />
        <TextInput label="Mittelname" path="middleName" />
        <TextInput label="Familiename" path="lastName" validate={Validators.isRequired("Familienname eingeben")}  />
        <TextArea label="Notizen" path="notes" showCharacterCount={true} explanation="this is an explanation" />
      </Form>
      <div>formResult:</div>
      <Code>{JSON.stringify(formResult)}</Code>
      <div>newEntity</div>
      <Code>{JSON.stringify(newEntity)}</Code>
    </Card>
  </>
}