// IMPORTANT: THIS IS A WORK IN PROGRESS ... NOT ALL PROPERTIES ARE IMPLEMENTED
import _ from "lodash";
import Api from "../../util/api2";
import { ActivityRegionVariant } from "./Activity.RegionVariant";
import { Translatable } from "../Translatable";


export class ActivitySubactivity {
  activity_id: string = "";

  public toDb() : any {
    return {
      activity_id: this.activity_id
    }
  }

  public static fromDb(obj:any) {
    const sa = new ActivitySubactivity();
    sa.activity_id = obj.activity_id || "";
    return sa;
  }
}

export class ActivityImage {
  _id?: string;
  url: string = "";
  blurhash?: string = undefined;
  default: boolean = false;
  copyright: string = "";
  source: string = "";
  seo: Translatable = new Translatable();

  public toDb():any {
    return {
      _id: this._id,
      url: this.url,
      blurhash: this.blurhash || null,
      default: this.default,
      copyright: this.copyright,
      source: this.source,
      seo: this.seo.toDb()
    }
  }

  public static fromDb(obj:any) {
    const image = new ActivityImage();
    image._id = obj._id;
    image.url = obj.url;
    image.blurhash = obj.blurhash || undefined;
    image.default = obj.default ? true : false;
    image.copyright = obj.copyright || "";
    image.source = obj.source || "";
    image.seo = Translatable.fromDb(obj.seo);
    return image;
  }
}

export class ActivityVideo { 
  _id?: string;
  url: string = "";
  
  public toDb() : any {
    return {
      _id: this._id,
      url: this.url
    }
  }

  public static fromDb(obj:any) {
    const video = new ActivityVideo();
    video._id = obj._id;
    video.url = obj.url || "";
    return video;
  }
}

export class ActivityStory {
  _id?: string;
  imageUrl: string = "";
  position: number = 0;
  summary: string = "";
  title: string = "";
  url: string = "";
  youtubeVideoId: string = "";
  vimeoVideoId: string = "";
  countries: string[] = [];
  

  public static fromDb(obj:any) {
    const story = new ActivityStory();
    story._id = obj._id;
    story.imageUrl = obj.imageUrl ||"";
    story.position = obj.position || 0;
    story.summary = obj.summary || "";
    story.title = obj.title ||"";
    story.url = obj.url ||"";
    story.vimeoVideoId = obj.vimeoVideoId || "";
    story.youtubeVideoId = obj.youtubeVideoId || "";
    story.countries = (obj.countries || []).map((country:any)=> country);
    
    return story;
  }

  toDb() : any {
    //return JSON.parse(JSON.stringify(this));
    const emptyToNull = (v:string) => v.trim().length === 0 ? null : v.trim();
    return {
      _id: this._id,
      imageUrl: emptyToNull(this.imageUrl),
      position: this.position,
      summary: emptyToNull(this.summary),
      title: emptyToNull(this.title),
      url: emptyToNull(this.url),
      vimeoVideoId: emptyToNull(this.vimeoVideoId),
      youtubeVideoId: emptyToNull(this.youtubeVideoId),
      countries: this.countries
    }
    
  }
}

export class ActivityReferencePriority {
  man: number = 0;
  woman: number = 0;
  couple: number = 0;

  public toDb() : any {
    return {
      man: this.man,
      woman: this.woman,
      couple: this.couple
    }
  }
  
  public static fromDb(obj:any) {
    const arp = new ActivityReferencePriority();
    // TODO might want to check if values are numbers
    arp.man = obj.man || 0;
    arp.woman = obj.woman || 0;
    arp.couple = obj.couple || 0;
    return arp;
  }
}


/**
 * INCOMPLETE IMPLEMENTATION
 * - missing toDb()
 * - missing create() -> see User.ts
 */
export class Activity {
  _id: string = "";
  // texts
  title: Translatable = new Translatable();
  slug: Translatable = new Translatable();
  shortDescription: Translatable = new Translatable();
  longDescription: Translatable = new Translatable();
  availableTimes: string = ""; // text containing information concering possible weekdays / time of day
  comments: string = ""; // for internal use only
  // flags
  isFeatured: boolean = false;
  isFresh: boolean = false;
  isBestseller: boolean = false;
  isHomeDelivery: boolean = false;  // home delivery activities are things like food that are deliverd to the receiver
  isOnlineEvent: boolean = false; // online events happen virtually, mostly via video calls ... e.g. online concerts
  isSubactivity: boolean = false; // subactivities are only part of other activities, are not displayed in shop (exception -> isOptionalSubactivity)
  isOptionalSubactivity: boolean = false;  // buyer can chose if this subactivity is included (i.e. is displayed as selectable option in shop)
  requiresMeteocheck: boolean = false;
  upsellAsFood: boolean = false;
  // execution
  availableHours: number[] = [];
  availableWeekdays: number[] = [];
  // categorization / ranking / etc
  tags: string[] = [];
  referencePriority: ActivityReferencePriority = new ActivityReferencePriority();
  salesRank: number = 999999;
  salesRankUpdatedOn: Date|null = null;
  rating: number|null = null; // TODO don't think this is used anywhere
  ratingUpdatedOn: Date|null = null; // TODO don't think this is used anywhere
  ratingAverage: number|null = null; // this is updated within the api by daily pseudo-cron job (or started manually from admin? cannot remember)
  ratingCount: number|null = null; // this is updated within the api by daily pseudo-cron job (or started manually from admin? cannot remember)
  // subdocuments and collections
  images: ActivityImage[] = [];
  videos: ActivityVideo[] = [];
  stories: ActivityStory[] = [];
  // some activities are a composite of other activities / parts, we use this information in automation to get provider information
  subactivities: ActivitySubactivity[] = [];
  regionVariants: ActivityRegionVariant[] = [];

  /** 
   * computed properties ***********************************************
   */

  /**
   * Returns true if any region variant is active
   */
  public get isActive(): boolean {
    return this.regionVariants.some(rv => rv.isActive);
  }

  /**
   * Creates pojo from activity
   */
  public toDb() : any {
    return {
      _id: this._id,
      // texts
      title: this.title.toDb(),
      slug: this.slug.toDb(),
      shortDescription: this.shortDescription.toDb(),
      longDescription: this.longDescription.toDb(),
      availableTimes: this.availableTimes, 
      comments: this.comments,
      // flags
      isFeatured: this.isFeatured,
      isFresh: this.isFresh,
      isBestseller: this.isBestseller,
      isHomeDelivery: this.isHomeDelivery,
      isOnlineEvent: this.isOnlineEvent,
      isSubactivity: this.isSubactivity,
      isOptionalSubactivity: this.isOptionalSubactivity,
      requiresMeteocheck: this.requiresMeteocheck,
      upsellAsFood: this.upsellAsFood,
      // execution
      availableHours: this.availableHours,
      availableWeekdays: this.availableWeekdays,
      // categorization / ranking / etc
      tags: this.tags,
      referencePriority: this.referencePriority.toDb(),
      salesRank: this.salesRank,
      salesRankUpdatedOn: this.salesRankUpdatedOn,
      rating: this.rating,
      ratingUpdatedOn: this.ratingUpdatedOn,
      ratingAverage: this.ratingAverage,
      ratingCount: this.ratingCount,
      // subdocuments and collections
      images: this.images.map(a => a.toDb()),
      videos: this.videos.map(v => v.toDb()),
      stories: this.stories.map(s => s.toDb()),
      // composites of other activities / parts
      subactivities: this.subactivities.map(sa => sa.toDb()),
      regionVariants: this.regionVariants.map(rv => rv.toDb())
    }
  }

  public getCategoryTags():string[] {
    const allCategoryTags = ["Action", "Fun", "Kulinarisches", "Wellness"]; // TODO should have these somewhere in config
    const categoryTags = _.intersection(allCategoryTags, this.tags);
    return categoryTags;
  }

  /**
   * Returns the default image (or the first image) of an activity or null if no images are available
   * @param activity 
   * @returns 
   */
  public getDefaultImage(): ActivityImage|null {
    const defaultImage = this.images.find(image => image.default) || this.images[0];
    return defaultImage || null;
  }

  /**
   * 
   * @param obj a pojo delivered from db (or api for that matter)
   * @returns 
   */
  public static fromDb(obj:any) : Activity {
    const activity = new Activity();

    activity._id = obj._id;
    activity.availableTimes = obj.availableTimes || ""; // relic
    activity.availableHours = obj.availableHours || [];
    activity.availableWeekdays = obj.availableWeekdays || [];
    activity.comments = obj.comments || "";
    activity.images = (obj.images || []).map((imageObj:any) => ActivityImage.fromDb(imageObj));
    activity.isBestseller = obj.isBestseller ? true : false;
    activity.isFeatured = obj.isFeatured ? true : false;
    activity.isFresh = obj.isFresh ? true : false;
    activity.isHomeDelivery = obj.isHomeDelivery ? true : false;
    activity.isOnlineEvent = obj.isOnlineEvent ? true : false;
    activity.isOptionalSubactivity = obj.isOptionalSubactivity  ? true : false;
    activity.isSubactivity = obj.isSubactivity  ? true : false;
    activity.longDescription = Translatable.fromDb(obj.longDescription);
    activity.rating = obj.rating || null;
    activity.ratingAverage = obj.ratingAverage || null;
    activity.ratingCount = obj.ratingCount || null;
    activity.ratingUpdatedOn = obj.ratingUpdatedOn ? new Date(obj.ratingUpdatedOn) : null;
    activity.referencePriority = ActivityReferencePriority.fromDb(obj.referencePriority);
    activity.regionVariants = (obj.regionVariants || []).map((rv:any) => ActivityRegionVariant.fromDb(rv));
    activity.requiresMeteocheck = obj.requiresMeteocheck || false;
    activity.salesRank = obj.salesRank || 0;
    activity.salesRankUpdatedOn = new Date(obj.salesRankUpdatedOn || 0);
    activity.shortDescription = Translatable.fromDb(obj.shortDescription);
    activity.slug = Translatable.fromDb(obj.slug);
    activity.stories = (obj.stories || []).map((storyObj:any) => ActivityStory.fromDb(storyObj));
    activity.subactivities = (obj.subactivities || []).map((sa:any) => ActivitySubactivity.fromDb(sa));
    activity.tags = (obj.tags || []).map((tag:any) => String(tag));
    activity.title = Translatable.fromDb(obj.title);
    activity.upsellAsFood = obj.upsellAsFood  ? true : false;
    activity.videos = (obj.videos || []).map((videoObj:any) => ActivityVideo.fromDb(videoObj));
    
    return activity;
  }

  /**
   * Searches activities
   * @param filter mongo filter object
   * @returns 
   */
  static async search(filter:any): Promise<Activity[]> {
    const projection = {};
    let result = await Api.post("activities", "search", {filter, projection});
    if(result.success) {
      const activities: Activity[] = result.data.items.map((obj:any) => {
        return Activity.fromDb(obj);
      })
      return activities.sort((a,b) => a.title.de.localeCompare(b.title.de));
    }
    else {
      console.error(result.error);
      return [];
    }
  }

  /**
   * Returns an activity by id - returns null if not found
   * @param id 
   * @returns 
   */
  static async findOneById(id:string) : Promise<Activity|null> {
    const searchResult = await Activity.search({_id:id});
    return searchResult[0] || null;
  }

  /**
   * Returns all activities in the database
   */
  static async all(): Promise<Activity[]> {
    return await Activity.search({})
  }

  /**
   * Returns all active main activities (i.e. no subactivities)
   */
  static async allActiveMain(): Promise<Activity[]> {
    const activitiesAllMain = await Activity.search({isSubactivity:false});
    const activities = activitiesAllMain.filter(a => a.isActive);
    return activities;
  }

  /**
   * Updates an activity with a changeset
   * @param id 
   * @param changeset 
   * @returns 
   */
  static async update(id:string, changeset:any) : Promise<Activity> {
    const result = await Api.post("activities", "update", {id:id, set:changeset});
    return Activity.fromDb(result.data.item);
  }

  static async upsertStory(activityId: string, story: ActivityStory) : Promise<Activity> {
    const payload = {
      id: activityId,
      arrayName: "stories",
      item: story.toDb()
    };
    const apiResult = await Api.post("activities", "upsertInArray", payload);
    // return activity
    return Activity.fromDb(apiResult.data.item);
  }

  static async deleteStory(activityId: string, story: ActivityStory): Promise<Activity> {
    const payload = {
      id:activityId, arrayName:"stories", itemId: story._id
    }
    const apiResult = await Api.post("activities", "deleteInArray", payload);
    // return activity
    return Activity.fromDb(apiResult.data.item);
  }

  static async upsertVideo(activityId:string, video:ActivityVideo): Promise<Activity> {
    const payload = {activityId, video};
    const apiResult = await Api.post("activities", "upsertVideo", payload);
    // return activity
    return Activity.fromDb(apiResult.data.item);
  }
  
  static async deleteVideo(activityId:string, video:ActivityVideo): Promise<Activity> {
    const payload = {activityId, videoId:video._id};
    const apiResult = await Api.post("activities", "deleteVideo", payload);
    // return activity
    return Activity.fromDb(apiResult.data.item);
  }

  /*
  static async getReservedSlugs(activityId: string) {
    // get all slugs
    const filter = {}
    const projection = {"slug":1}
    const apiResult = await Api.post("activities", "search", {filter, projection});
    const allSlugs = apiResult.data.items.map((a:any) => {return {activity_id:a._id, de:a.slug.de, en:a.slug.en}})
    // get reserved slugs
    const reservedSlugValues = allSlugs
      .filter((s:any) => {return s.activity_id !== activityId && s.de !== undefined && s.de !== ''})
      .map((s:any) => {return s.de.toLowerCase()})
    // done
    return reservedSlugValues;
  }
  */


  static async addSubactivity(activityId: string, subactivityId: string): Promise<void> {
    // TODO would be nice if this returned the updated activity
    await Api.post("activities", "addSubactivity", {activityId, subactivityId});
  }

  static async removeSubactivity(activityId: string, subactivityId: string): Promise<void> {
    // TODO would be nice if this returned the updated activity
    await Api.post("activities", "removeSubactivity", {activityId, subactivityId});
  }

  static async getSubactivityIssues(activityId: string) {
    const issuesResult = await Api.post("activities", "getSubactivityIssues", {activityId});
    if(issuesResult.success) {
      return issuesResult.data.issues;
    }
    else {
      console.error(issuesResult.error.message); // TODO should do something about this
      return [];
    }
  }

  static async addImage(activityId: string, imageUrl: string): Promise<Activity> {
    const image = {
      url: imageUrl,
      default: false,
      copyright: "",
      source: "",
      seo: {
        de:"", en:""
      }
    }
    const payload = {activityId, image};
    const apiResult = await Api.post("activities", "upsertImage", payload);
    return Activity.fromDb(apiResult.data.activity);
  }
  
  static async updateImage(activityId:string, image: ActivityImage): Promise<Activity> {
    const payload = {activityId, image: image.toDb()};
    const apiResult = await Api.post("activities", "upsertImage", payload);
    return Activity.fromDb(apiResult.data.activity);
  }

  static async deleteImage(activityId:string, image: ActivityImage): Promise<Activity> {
    const payload:any = {activityId, imageId:image._id};
    const apiResult = await Api.post("activities", "deleteImage", payload);
    return Activity.fromDb(apiResult.data.activity);
  }

  static async addRegionVariant(activityId:string, regionCode:string): Promise<Activity> {
    // TODO does api check if that already exists?
    const payload = {activityId, regionCode};
    const apiResult = await Api.post("activities", "addRegionVariant", payload);
    return Activity.fromDb(apiResult.data.activity);
  }
  
}
