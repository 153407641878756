import React, {useState, useEffect, useContext} from "react";
import {ProviderContext} from "./Provider.Context";

import "./Rating.scss";

function Rating() {
  // context
  const PC = useContext(ProviderContext);
  // state
  const [busy, setBusy] = useState(true);
  
  // mount with provider
  useEffect(() => {
    if(PC.provider) {
      setBusy(false);
    }
  }, [PC.provider]);

  // rating changed
  const onChange = async(color) => {
    setBusy(true);
    const changeset = {colorRating:color};
    await PC.update(changeset);
    setBusy(false);
  }

  // render
  return (
    <div className="c-provider-rating">
      <div className="c-provider-rating-label">Rating</div>
      <div className="c-provider-rating-items">
        <RatingItem color="green" onChange={onChange} busy={busy} />
        <RatingItem color="orange" onChange={onChange} busy={busy} />
        <RatingItem color="red" onChange={onChange} busy={busy} />
      </div>
    </div>
  )
}

function RatingItem({color, busy, onChange}) {
  // provider context
  const PC = useContext(ProviderContext);

  // handles click events
  const onClick = () => {
    if(!busy) {
      if(PC.provider.colorRating !== color) {
        onChange(color);
      }
    }
  }
  
  // render
  let mode = ""
  if(busy) {
    mode = "busy"
  }
  else {
    mode = PC.provider.colorRating === color ? "active" : "inactive";
  }
  return <div className={`c-provider-rating-item ${color} ${mode}`} onClick={onClick}></div>
}

export default Rating;