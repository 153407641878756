import React, {useEffect, useState} from "react"
import _ from "lodash"
import Api from "../../../util/api2"

import {Form, SearchableDropdown, TextArea, Value, Validators} from "../../controls"


/**
 * Allows the user to assign a provider to an activity variant or vice versa
 * Scenarios
 * a. only the providerId is provided
 *   - create
 *   - the user needs to select an activity region variant
 * b. activityId and regionVariant are provided
 *   - create
 *   - the user needs to select a provider
 */
function ProviderToActivity({activityId, regionVariantId, providerId, onCancel, onSave}) {

  const [activityVariant, setActivityVariant] = useState(null)
  const [provider, setProvider] = useState(null)

  const [providers, setProviders] = useState([])
  const [activityVariants, setActivityVariants] = useState([])
  
  const [entity, setEntity] = useState(null)
  
  useEffect(() => {
    load()
  }, [])

  const load = async() => {
    // get provider
    let provider = null
    let providers = []
    if(providerId) {
      let result = await Api.post("providers", "search", {filter:{_id:providerId}, projection:{_id:1, name:1, colorRating:1}})
      provider = result.data.items[0]
    }
    else {
      let result = await Api.post("providers", "search", {filter:{}, projection:{_id:1, name:1, colorRating:1}})
      providers = result.data.items.map(p => {
        return {
          value:p._id,
          label:p.name,
          colorRating:p.colorRating
        }
      })
    }

    // get activity
    let activityVariant = null
    let activityVariants = []
    if(activityId && regionVariantId) {
      let result = await Api.post("activities", "search", {filter:{_id:activityId}, projection:{_id:1, title:1, "regionVariants._id":1, "regionVariants.regionCode":1}})
      let a = result.data.items[0]
      let rv = _.get(a, "regionVariants", []).find(rv => String(rv._id) === String(regionVariantId))
      activityVariant = {
        title:a.title.de,
        regionCode:rv.regionCode,
        activityId, regionVariantId
      }
    }
    else {
      let result = await Api.post("activities", "search", {filter:{}, projection:{_id:1, title:1, "regionVariants._id":1, "regionVariants.regionCode":1, "regionVariants.isActive":1}})
      let items = []
      result.data.items.forEach(a => {
        _.get(a, "regionVariants", []).forEach(rv => {
          if(rv.isActive) {
            items.push({
              label:`${_.get(a, "title.de", "Unbekannte Aktivität")} (${_.get(rv, "regionCode", "??-''")})`,
              value:`${a._id}_${rv._id}`
            })
          }
        })
      })
      activityVariants = items.sort((a, b) => a.label.localeCompare(b.label))
      
    }

    // define the entity
    let entity
    if(provider && activityVariant) {
      // get the assignment
      let resultAssignments = await Api.post("activities", "getProviders", {activityId, regionVariantId})
      let assignment = resultAssignments.data.items.find(a => a.provider._id === providerId).assignment

      entity = {
        providerId, activityId, regionVariantId,
        notes:assignment.notes
      }
    }
    else {
      entity = {
        providerId, activityId, regionVariantId,
        notes:""
      }
    }

    // update the state
    setProvider(provider)
    setProviders(providers)
    setActivityVariant(activityVariant)
    setActivityVariants(activityVariants)
    setEntity(entity)
  }
  
  
  
  /**
   * Form.onSave handler
   * @param {} formData 
   */
  const save = async(formData) => {
    /* API expects the following payload
    * payload: {
      *  activityId:<ObjectId>,
      *  regionVariantId:<ObjectId>,
      *  providerId:<ObjectId>,
      *  notes:<string>, // optional
      * }
    */

    let newAssignment = {}
    _.merge(newAssignment, entity)
    _.merge(newAssignment, formData.changeset)

    // the activity dropdown delivers activityId_regionVariantId, dissect this
    if(newAssignment.activityId_regionVariantId) {
      const tokens = newAssignment.activityId_regionVariantId.split('_')
      newAssignment.activityId = tokens[0]
      newAssignment.regionVariantId = tokens[1]
    }

    // we only save non-empty locations
    // TODO shouldn't this all happen in the API?
    /*
    const clearLocation = (l) => {
      if(!_.isNil(l)) {
        l.title = l.title || ""
        l.instructions = l.instructions || ""
        if(!_.isNil(l.coordinates) || _.get(l, "title").trim().length > 0 || _.get(l, "instructions").trim().length > 0) {
          let title = _.get(l, "title", "").trim()
          l.title = title.length > 0 ? title : null
          let instructions = _.get(l, "instructions", "").trim()
          l.instructions = instructions.length > 0 ? instructions : null
          return l
        }
        else {
          return null
        }
      }
    }
    */
    const payload = {
      activityId: activityId || newAssignment.activityId, 
      regionVariantId: regionVariantId || newAssignment.regionVariantId, 
      providerId: providerId || newAssignment.providerId,
      notes: newAssignment.notes
    }

    await Api.post('activities', 'assignProvider', payload)

    if(onSave) {
      onSave()
    }
    
  }
  
  // rendering
  if(_.isNil(entity)) {
    return null
  }
  
  let providerSelect = null
  if(provider) {
    providerSelect = <Value label="Anbieter">{_.get(provider, "name", "Unbekannter Anbieter")}</Value>;
  }
  else {
    providerSelect = <SearchableDropdown label="Anbieter" path="providerId" options={providers} validate={Validators.isRequired("Bitte einen Anbieter wählen")} transform={transformProvider} focusOnMount={true} />
  }

  let activitySelect = null
  if(activityVariant) {
    activitySelect = <Value label="Aktivität">
      {`${_.get(activityVariant, "title", "Unbekannte Aktivität")} (${_.get(activityVariant, "regionCode", "??-''")})`}
    </Value>
  }
  else {
    activitySelect = <SearchableDropdown label="Aktivität" path="activityId_regionVariantId" options={activityVariants} validate={Validators.isRequired("Bitte eine Aktivität wählen")} />
  }

  return (
    <div id="v-provider-assignment">
      <Form entity={entity} onSave={save} onCancel={onCancel} labelSave="speichern">
        {providerSelect}
        {activitySelect}
        <TextArea path="notes" label="Notizen" />
      </Form>
    </div>
  )
  
}

function transformProvider(p) {
  let icon = " "
  switch((p.colorRating || '').toLowerCase()) {
    case 'green':
      icon = "🟢"
      break
    case 'orange':
      icon = "🟠"
      break
    case 'red':
      icon = "🔴"
      break
    default:
      icon = "⚪️"
      break
  }
  return `${icon} ${p.label}`
}


export default ProviderToActivity