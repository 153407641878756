import React from "react";
import CSS from './Loader.module.scss';

/**
 * @typedef {Object} LoaderProps
 * @property {string} kind 
 * @property {string} text
 */

/**
 * Animated Loading Indicator
 * @param {LoaderProps} props 
 */

type LoaderProps = {
  kind?: "line"|"dots"|"circle",
  size?: "regular"|"small",
  text?: string
}
export function Loader({kind, text, size} : LoaderProps) {
  // defaults
  kind = kind || "line";
  size = size || "regular";

  // pre-render
  let content = null;
  if(kind.toLowerCase() === "dots") {
    content = <div className={CSS.dots}>
      <span className={CSS.dot}>&bull;</span>
      <span className={CSS.dot}>&bull;</span>
      <span className={CSS.dot}>&bull;</span>
    </div>
  }
  else {
    content = <div className={`${CSS[kind]} ${CSS[size]}`} />
  }

  // pre-render text
  let contentText = null;
  if(text && text.trim().length > 0) {
    contentText = <div className={CSS.text}>{text}</div>;
  }

  // render
  return (
    <div className={CSS.container}>
      {content}
      {contentText}
    </div>
  )

}
