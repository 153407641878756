import { number } from 'prop-types';
import React, {useState, useEffect, useRef} from 'react'
import { Blurhash } from "react-blurhash";
import CSS from "./AsyncImage.module.scss";

type AsyncImageProps = {
  src?: string,
  alt?: string,
  className?: string,
  style?: any,
  blurhash?: string,
  showInfo?: boolean,
}
export function AsyncImage({src="", alt, className, style, blurhash, showInfo=false} : AsyncImageProps) {
  // state
  const [loaded, setLoaded] = useState<boolean>(false);
  const [dimensions, setDimensions] = useState<{width:number, height:number}|null>(null);
  // ref
  const refImg = useRef<HTMLImageElement>(null);

  // mount
  useEffect(() => {
    const imageElement = new Image();
    imageElement.onload = onLoad;
    imageElement.onerror = onError;
    imageElement.src = src;
  }, []);

  // ref or loaded state changes
  useEffect(() => {
    if(refImg.current) {
      setDimensions({width:refImg.current.naturalWidth, height:refImg.current?.naturalHeight});
    }
  }, [refImg.current, loaded]);

  const onLoad = () => {
    setLoaded(true);
  }
  const onError = () => {
    // TODO
  }

  // render
  let img = null;
  if(loaded) {
    img = <img src={src} alt={alt} width="100%" height="100%" ref={refImg} className={className || ""} />
  }
  else {
    img = <Blurhash hash={blurhash || "UDL{#Gj1PAF|030M{yZ~01$xPAt79YxT9]$g"} width="100%" height="100%" />;
  }
  let info = null;
  if(dimensions && showInfo) {
    info = <div className={CSS.info}>{dimensions.width}x{dimensions.height}</div>
  }
  return (
    <div className={`${CSS.container} ${!loaded ? CSS.loading : CSS.loaded}`} style={style}>
      {img}
      {info}
    </div>
  )
}
