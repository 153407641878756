import _ from "lodash";
import moment from "moment";
import Util from "../../util/util";
import Timetable from "../../util/searchTimetable";
import * as TS from "..";
import * as DA from "../DataAccess";
import * as BL from "../BusinessLogic";
import Texts from "./Automatization.Texts";
import Placeholders, { PlaceholderSourceData } from "./Automatization.Placeholders";

type Result = {
  steps: Array<TS.AdventureStep>,
  errors:any[],
  warnings:string[]
}
async function getStepsFromOutline(adventure:any) : Promise<Result> {
  const result:Result = { steps:[], errors: [], warnings: []};
  const adventureId = String(adventure._id);

  try {
    // get outline
    const outline = await TS.AdventureOutlineHelper.loadOutline(adventureId);
    // no outline found? abort
    if(!outline) {
      result.warnings.push("Keinen Planungsvorschlag gefunden");
      return result;
    }

    // get activities from catalogue (we need this for additional information)
    const activities = await getActivities(outline);

    // get provider locations
    const providerLocationIds = outline.activities.map(a => a.providerLocationId);
    const providerLocations = await DA.ProviderLocationRepository.search({_id: {$in:providerLocationIds}});
    
    // get booking requests
    const bookingRequests = await DA.BookingRequestRepository.search({adventure_id:adventureId});
    
    // missing (accepted) booking requests?
    if(bookingRequests.filter(br => _.get(br, "response.accepted") === true).length !== activities.filter(a => a.isParentActivity === false).length) {
      result.warnings.push("Es scheinen Buchungsanfragen zu fehlen oder nicht alle Buchungsanfragen wurden bestätigt oder mehrere Buchungsanfragen der gleichen Aktivitäten wurden akzeptiert. Deshalb konnten nicht alle Aktivitätsschritte vollständig oder richtig erzeugt werden.")
    }

    // iterate through activities to create an array of raw steps
    const rawSteps: Array<RawStep> = [];
    const addedParentActivities = []; // used to keep track of parent activities we already added
    
    let currentIndex = 0; 
    for(let i=0; i < outline.activities.length; i += 1) {
      // get the activity and the selected route
      const currentActivity = outline.activities[i];
    
      // iterate through all steps of the selected route and create a raw step for each route step
      const route = currentActivity.routes.find(r => r.selected);
      if(route) {
        let ticketInformationAdded = false; // flag ... is changed to true once we reached the first transit step
        for(let j=0; j < route.steps.length; j += 1) {
          const rawStep = new RawStep("trip", currentIndex);
          rawStep.outlineStep = route.steps[j];
          // transit? 
          if(route.travelMode === "TRANSIT") {
            // add ticket buying information to the first transit step in the route
            if(!ticketInformationAdded) {
              if(rawStep.outlineStep.transit) {
                rawStep.ticketInformation = getTicketBuyingInformation(route);
                ticketInformationAdded = true;
              }
            }
          }
          // walking? figure out if this is just transfer
          let ignore = false; 
          if(rawStep.outlineStep.travelMode === "WALKING") {
            // we ignore walking steps from transit to transit
            const prevTravelMode:string = j > 0 ? route.steps[j - 1].travelMode : "";
            const nextTravelMode:string = j < (route.steps.length - 1) ?  route.steps[j + 1].travelMode : "";
            if(prevTravelMode === "TRANSIT" && nextTravelMode === "TRANSIT") {
              ignore = true; 
            }
          }
          // add to collection
          if(!ignore) {
            rawSteps.push(rawStep);
            currentIndex += 1;
          }
        }
      } 

      // add the activity
      // does it have a parent activity?
      if(currentActivity.parentActivityId) {
        // not yet added? then do so now
        if(addedParentActivities.includes(currentActivity.parentActivityId) === false) {
          const parentActivity = activities.find(a => a._id === currentActivity.parentActivityId);
          addedParentActivities.push(currentActivity.parentActivityId);
          const rawStepParent = new RawStep("activity", currentIndex);
          rawStepParent.catalogueActivity = parentActivity;
          
          rawSteps.push(rawStepParent);
          currentIndex += 1;
        }
        
      }
      // get the catalogue activity
      const catalogueActivity = activities.find(a => a._id === currentActivity.activityId);
      
      // create the raw step
      const rawStep = new RawStep("activity", currentIndex)
      rawStep.outlineActivity = currentActivity;
      rawStep.catalogueActivity = catalogueActivity;
      rawStep.isLastActivity = i === (outline.activities.length - 1);
      rawSteps.push(rawStep);
      currentIndex += 1;


      // increase current index
      currentIndex++;
    }

    // add destination activity to those travel steps that lead to an activity
    for(let i=0; i < rawSteps.length; i += 1) {
      let rawStep = rawSteps[i];
      if(rawStep.kind === "activity") {
        if(i > 0) {
          let previousStep = rawSteps[i - 1];
          if(previousStep.kind === "trip") {
            previousStep.destinationCatalogueActivity = rawStep.catalogueActivity;
            previousStep.destinationOutlineActivity = rawStep.outlineActivity;
          }
        }
      }
    }

    // get timetable information from search.ch so we ca add platform information
    await addPlatformInformation(rawSteps);

    // create steps
    const steps:Array<TS.AdventureStep> = [];
    for(let raw of rawSteps) {
      let stepsToAdd:Array<TS.AdventureStep> = [];
      if(raw.kind === "activity") {
        const providerLocation = providerLocations.find(pl => String(pl._id) === String(_.get(raw, "outlineActivity.providerLocationId")));
        const providerInfo = {
          location: providerLocation,
          name: _.get(raw, "outlineActivity.providerName")
        }
        stepsToAdd = activityStepsFromRaw(raw, adventure, bookingRequests, providerInfo);
      }
      if(raw.kind === "trip") {
        let providerInfo: any;
        if(raw.destinationOutlineActivity) {
          const providerLocation = providerLocations.find(pl => String(pl._id) === String(_.get(raw, "destinationOutlineActivity.providerLocationId")));
          providerInfo = {
            location: providerLocation,
            name: _.get(raw, "destinationOutlineActivity.providerName")
          }
        }
        stepsToAdd = await travelStepsFromRaw(raw, adventure, providerInfo);
      }
      // add steps to steps collection
      stepsToAdd.forEach(step => steps.push(step));
    }

    

    // done
    result.steps = steps;
    return result;


    
  }
  catch(err) {
    console.error(err);
    result.errors.push(_.get(err, "message"));
    return result;
  }
}


export class RawStep {
  index: number = 0;
  kind: "trip"|"activity" = "trip";
  destinationCatalogueActivity?: ActivityInfo; // the catalogue activity this step leads to
  destinationOutlineActivity?: TS.AdventureOutlineActivity; // the outline activity this step leads to
  catalogueActivity?: ActivityInfo;
  outlineActivity?: TS.AdventureOutlineActivity;
  isLastActivity: boolean = false;
  outlineStep?: TS.AdventureOutlineRouteStep;
  ticketInformation?: any;
  platform?: string;

  constructor(kind:"trip"|"activity", index:number) {
    this.kind = kind;
    this.index = index;
  }
}


type ActivityInfo = {
  _id: string,
  title: {de:string, en:string},
  images: Array<any>,
  isParentActivity: boolean,
  instructions: DA.ActivityInstruction[]
}
async function getActivities(outline: TS.AdventureOutline) : Promise<ActivityInfo[]> {
  // get all activities from db
  const activityIds:Array<string> = [];
  const parentActivityIds:Array<string> = [];
  outline.activities.forEach(a => {
    activityIds.push(a.activityId);
    if(a.parentActivityId) {
      activityIds.push(a.parentActivityId);
      parentActivityIds.push(a.parentActivityId);
    }
  });

  // get activities
  const activities = await DA.ActivityRepository.search({_id: {$in:activityIds}});
  // mark parent activities (we need this information to check for missing bookingRequests) add instructions
  const activityInstructions = await DA.ActivityInstructionRepository.search({activityId:{$in:activityIds}});
  // generate info objects
  const infos: ActivityInfo[] = activities.map(activity => {
    return {
      _id: activity._id!,
      title: activity.title,
      images: activity.images,
      isParentActivity: parentActivityIds.includes(activity._id!),
      instructions: activityInstructions.filter(ai => ai.activityId === activity._id)
    }
  });
  // done
  return infos;
}

function activityStepsFromRaw(rawStep:RawStep, adventure:any, bookingRequests:any[], providerInfo:any) {
  // steps we are going to return
  const steps: TS.AdventureStep[] = [];

  // activity info
  const activityInfo = rawStep.catalogueActivity;
  
  // the image url
  let imageUrl:string|undefined = undefined;
  if(activityInfo && activityInfo.images && activityInfo.images.length > 0) {
    const defaultImage = activityInfo.images.find((image:any) => image.default) || activityInfo.images[0];
    imageUrl = defaultImage.url;
  }

  // booking request
  let bookingRequest = bookingRequests.find(br => br.activity.id === _.get(activityInfo, "_id") && _.get(br, "response.accepted") === true);
  if(!bookingRequest) {
    // no accepted booking request, get the newest without response instead
    const openRequests = bookingRequests
      .filter(br => br.activity.id === _.get(activityInfo, "_id") && !_.get(br, "response"))
      .sort((a,b) => {
        const date_a = new Date(a.request.createdOn);
        const date_b = new Date(b.request.createdOn);
        return date_a > date_b ? -1 : 1;
      }); 
    bookingRequest = openRequests[0];
  }

  // instructions
  let instructions: DA.ActivityInstruction[] = [];
  // can I assume we have activityInfo?
  if(activityInfo!.instructions.length  === 0) {
    // no activity instruction defined, use the generic default instruction
    const defaultInstructions = Texts.getDefaultActivityInstructions(adventure, bookingRequest, providerInfo, rawStep);
    const parsedInstructions = parseInstructions(defaultInstructions, adventure, bookingRequest, providerInfo, rawStep);

    // create the step
    const step: TS.AdventureStep = {
      Title: _.get(rawStep, "catalogueActivity.title.de") || "???",
      Instructions: parsedInstructions, // parseInstructions(instructions, adventure, bookingRequest, providerInfo),
      ActivityId: _.get(activityInfo, "_id"),
      ImageUrl: imageUrl,
      AlertDate: rawStep.outlineActivity ? moment(rawStep.outlineActivity.startTime).add(10, "m").toDate() : undefined, 
      HasSelfieButton: false,
      Attachments: [],
      Links:[],
      SentAlert: false
    }
    steps.push(step);
  }
  else {
    // one or more activity instructions, create a step for each
    activityInfo!.instructions
      .sort((a, b) => a.position - b.position)
      .forEach((ai, index) => {
        // parse instructions
        const parsedInstructions = parseInstructions(BL.ActivityInstruction.getLines(ai, adventure.Persons), adventure, bookingRequest, providerInfo, rawStep);
        // alert date only for first step of this activity
        let alertDate = undefined;
        if(index === 0) {
          alertDate = rawStep.outlineActivity ? moment(rawStep.outlineActivity.startTime).add(10, "m").toDate() : undefined; 
        }
        // add the step
        const step: TS.AdventureStep = {
          Title: ai.title.de || _.get(rawStep, "catalogueActivity.title.de") || "???",
          Instructions: parsedInstructions, 
          ActivityId: _.get(activityInfo, "_id"),
          ImageUrl: imageUrl,
          AlertDate: alertDate,
          HasSelfieButton: false,
          Attachments: [],
          Links:[],
          SentAlert: false
        };
        if(ai.travel) {
          step.StartCoordinates = {Lat: ai.travel.start.lat, Lng: ai.travel.start.lng};
          step.EndCoordinates = {Lat: ai.travel.destination.lat, Lng: ai.travel.destination.lng};
          step.TravelMode = ai.travel.mode;
        }
        steps.push(step);
      })
  }

  // add enjoy step (only if not parent activity)
  if(rawStep.catalogueActivity && rawStep.catalogueActivity.isParentActivity === false) {
    const instructions = Texts.getDefaultEnjoyInstructions(adventure);
    const parsedInstructions = parseInstructions(instructions, adventure, bookingRequest, providerInfo, rawStep);
    const stepEnjoy: TS.AdventureStep = {
      Title: "Viel Vergnügen!",
      Instructions: parsedInstructions, // Texts.getDefaultEnjoyInstructions(adventure),
      Attachments: [],
      Links:[],
      HasSelfieButton: false,
      SentAlert: false
    }
    // if this is the final activity, we add "good bye and travel back home on your own", if not we instruct the user to click continue when done with this
    if(rawStep.isLastActivity) {
      const goodbyeInstructions = Texts.getUltimateInstructions(activityInfo, adventure);
      goodbyeInstructions.forEach(instruction => stepEnjoy.Instructions.push(instruction));
    }
    else {
      const continueWhenDone = Texts.get("continue_when_done", adventure.Persons);
      stepEnjoy.Instructions.push(continueWhenDone); 
    }
    steps.push(stepEnjoy);
  }
  
  // done, return steps
  return steps;
}

async function travelStepsFromRaw(rawStep:RawStep, adventure:any, providerInfo:any): Promise<TS.AdventureStep[]> {
  // array of steps we will return
  const steps: TS.AdventureStep[] = [];
  // templates
  const template_base: TS.AdventureStep = {
    Title: "???",
    Attachments: [],
    Links: [],
    Instructions: [],
    TravelMode: _.get(rawStep, "outlineStep.travelMode"), 
    HasSelfieButton: false,
    SentAlert: false,
  }
  const template_coordinates: TS.AdventureStep = {
    ...template_base,
    StartCoordinates: {Lat:_.get(rawStep, "outlineStep.startLocation.lat"), Lng:_.get(rawStep, "outlineStep.startLocation.lng")},
    EndCoordinates: {Lat:_.get(rawStep, "outlineStep.endLocation.lat"), Lng:_.get(rawStep, "outlineStep.endLocation.lng")}
  }
  // data
  const duration = Math.floor((_.get(rawStep, "outlineStep.duration") || 0) / 60);
  const departureDate = new Date(_.get(rawStep, "outlineStep.startTime"));
  const arrivalDate = moment(departureDate).add(duration, "minutes").toDate();
  const arrivalStop = _.get(rawStep, "outlineStep.transit.arrivalStop");
  const vehicle = getVehicleInfo(_.get(rawStep, "outlineStep.transit.vehicle") || ""); 
  const terminus = _.get(rawStep, "outlineStep.transit.headsign");
  
  // create step(s) depending on travel mode of the trip
  switch(_.get(rawStep, "outlineStep.travelMode")) {
    case "DRIVING":
      const stepDriving: TS.AdventureStep = {...template_coordinates};
      const drivingTexts = await Texts.getDrivingTexts(adventure.Persons, duration, providerInfo, stepDriving.EndCoordinates || null);
      stepDriving.TickerTravel = "car";
      stepDriving.Title = rawStep.index === 0 ? drivingTexts.titleFirst : drivingTexts.title;
      stepDriving.Instructions = drivingTexts.instructions;
      steps.push(stepDriving);
      break;
    case "WALKING":
      const stepWalking: TS.AdventureStep = {...template_coordinates};
      const walkingTexts = await Texts.getWalkingTexts(adventure.Persons, duration, providerInfo, stepWalking.EndCoordinates || null);
      stepWalking.TickerTravel = "walk";
      stepWalking.Title = rawStep.index === 0 ? walkingTexts.titleFirst : walkingTexts.title;
      stepWalking.Instructions = walkingTexts.instructions;
      steps.push(stepWalking);
      break;
    case "TRANSIT":  
      // TODO all the text handling in here should be done in Automatization.Texts (just as we did for DRIVING and WALKING)
      // ticker travel modes: train, tram, bus, bike
      // get on the public transport
      const singular = (adventure.Persons ? adventure.Persons : 1) === 1;
      const stepGetOn: TS.AdventureStep = {...template_base};
      const platform = rawStep.platform ? `, Gleis ${rawStep.platform}` : "";
      stepGetOn.Title = `${Util.printTime(departureDate)}, ${vehicle.label} in Richtung '${terminus}'${platform}`; 
      stepGetOn.Instructions = [
        `${singular ? "Nimm" : "Nehmt"} um ${Util.printTime(departureDate)} ${vehicle.accusativeLabel} ${_.get(rawStep, "outlineStep.transit.line")} in Richtung '${terminus}'${platform}`,
        singular ? `Wähle "weiter" wenn du eingestiegen bist`:`Wählt "weiter" wenn ihr eingestiegen seid`
      ];
      if(rawStep.ticketInformation) {
        stepGetOn.Instructions.unshift(`${singular ? "Löse" : "Löst"} (falls nötig) ein Ticket nach '${rawStep.ticketInformation}'`)
      }
      steps.push(stepGetOn);
      // get off the public transport
      const stepGetOff: TS.AdventureStep = {...template_base};
      stepGetOff.TickerTravel = vehicle.tickerTravel;
      stepGetOff.Title = `Aussteigen: ${arrivalStop}`;
      stepGetOff.Instructions = [
        `Um ${Util.printTime(arrivalDate)} ${singular ? "triffst du" : "trefft ihr"} in '${arrivalStop}' ein`,
        `${singular ? "Steige" : "Steigt"} dort aus`,
        // TODO if another leg in front: `Achtung, du musst dort einen Anschluss erwischen - nicht trödeln :)`,
        `${singular ? "Wähle" : "Wählt"} "weiter" sobald ${singular ? "du ausgestiegen bist" : "ihr ausgestiegen seid"}`
      ];
      // if the "get off" step is not reached within 5 minutes of the departure, we trigger a slack alert
      stepGetOff.AlertDate = moment(departureDate).add(5, "m").toDate();
      stepGetOff.ImageUrl = "https://storage.googleapis.com/appentura/adventures/template_5b69630fdbf7af0014e4df83_1557489598884_Achtung_umsteigen.jpg";
      steps.push(stepGetOff);
      break;
    default: 
      const stepUnknown: TS.AdventureStep = {...template_base};
      stepUnknown.Title = "?????"
      steps.push(stepUnknown);
  }
  return steps;
}

async function addPlatformInformation(rawSteps: Array<RawStep>) {
  for(let rawStep of rawSteps) {
    if(rawStep.kind === "trip") {
      if(rawStep.outlineStep) {
        if(rawStep.outlineStep.transit) {
          // get connections
          let fromStation = rawStep.outlineStep.transit.departureStop; 
          let toStation = rawStep.outlineStep.transit.arrivalStop; 
          let when = new Date(rawStep.outlineStep.transit.departureTime); 
          let connections = await Timetable.getRoutes(fromStation, toStation, when);
          
          // get the one matching the time
          let matchingConnection = connections.find((connection:any) => {
            // we only have to look at the first leg
            let leg = connection.legs[0];
            return Util.printTime(leg.departure) === Util.printTime(when);
          })
          if(matchingConnection) {
            const platform = matchingConnection.legs[0].track; 
            rawStep.platform = platform; 
          }
          else {
            // TODO should we let the user know?
            console.error(":(")
          }
        }
        
      }
    }
  }
}

function getTicketBuyingInformation(route:TS.AdventureOutlineRoute) {
  // gets the destination of the last transit stop
  let ticketDestination = "???";
  for(let i=0; i < route.steps.length; i+= 1) {
    const step = route.steps[i];
    if(step.transit) {
      ticketDestination = step.transit.arrivalStop;
    }
  }
  return ticketDestination;
}

function parseInstructions(instructions:any[], adventure:any, bookingRequest:any[], providerInfo:any, rawStep:RawStep) {
  const data: PlaceholderSourceData = {adventure, bookingRequest, providerInfo, rawStep}
  const lines = instructions
    .map(line => {
      const found = line.startsWith("Die Aktivität ");
      Placeholders.forEach(ph => {
        if(line.includes(ph.placeholder)) {
          const value = ph.retrieve(data);
          if(value) {
            line = line.replace(ph.placeholder, value);
          }
          else {
            line = "";
          }
        }
      });
      return line;
    })
    .filter(line => line.trim().length > 0);
  return lines;
}


interface VehicleInfo {
  label: string;
  tickerTravel?: string;
  accusativeLabel: string;
}
function getVehicleInfo(key:String) : VehicleInfo {
  // https://developers.google.com/maps/documentation/javascript/reference/directions#VehicleType
  switch(key.toUpperCase()) {
    case "RAIL": 
      return {label: "Zug", tickerTravel: "train", accusativeLabel:"den Zug" };
    case "HEAVY_RAIL": 
      return {label: "Zug", tickerTravel: "train", accusativeLabel:"den Zug" };
    case "COMMUTER_TRAIN": 
      return {label: "Zug", tickerTravel: "train", accusativeLabel:"den Zug"};
    case "HIGH_SPEED_TRAIN": 
      return {label: "Zug", tickerTravel: "train", accusativeLabel:"den Zug"};
    case "LONG_DISTANCE_TRAIN": 
      return {label: "Zug", tickerTravel: "train", accusativeLabel:"den Zug"};
    case "BUS": 
      return {label: "Bus", tickerTravel: "bus", accusativeLabel:"den Bus"};
    case "TRAM": 
      return {label: "Tram", tickerTravel: "tram", accusativeLabel:"das Tram"};
    default:
      return {label: "ÖV", tickerTravel: undefined, accusativeLabel: "den ÖV"}
  }
 
}

export default {
  getStepsFromOutline,
  //placeholders:Placeholders
}