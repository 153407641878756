import React from "react";
import CSS from "./Image.module.scss";

type ImageProps = {
  src?: string
}
export function Image({src} : ImageProps) {
  if(src) {
    return (
      <div className={CSS.container}>
        <img src={src} alt="..." />
      </div>
    )
  }
  return null;
}