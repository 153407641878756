import { library, IconProp } from "@fortawesome/fontawesome-svg-core";
// import all regular icons
import { far } from "@fortawesome/free-regular-svg-icons";
// import all solid icons
import { fas } from "@fortawesome/free-solid-svg-icons";
// import brand icons
import { fab } from "@fortawesome/free-brands-svg-icons";

library.add(far, fas, fab);

// free icons: https://fontawesome.com/search?o=r&m=free

export default {
  Award: "fa-solid fa-award" as IconProp,
  ArrowDown: "fa-solid fa-arrow-down" as IconProp,
  ArrowUp: "fa-solid fa-arrow-up" as IconProp,
  ArrowLeft: "fa-solid fa-arrow-left" as IconProp,
  ArrowRight: "fa-solid fa-arrow-right" as IconProp,
  ArrowsAltH: "fa-solid fa-arrows-alt-h" as IconProp,
  ArrowsAltV: "fa-solid fa-arrows-alt-v" as IconProp,
  Baby: "fa-solid fa-baby" as IconProp,
  Ban: "fa-solid fa-ban" as IconProp,
  Bicycle: "fa-solid fa-bicycle" as IconProp,
  Bomb: "fa-solid fa-bomb" as IconProp,
  Bookmark: "fa-solid fa-bookmark" as IconProp,
  BookmarkEmpty: "fa-regular fa-bookmark" as IconProp,
  Bolt: "fa-solid fa-bolt" as IconProp,
  Box: "fa-solid fa-box" as IconProp,
  BoxOpen: "fa-solid fa-box-open" as IconProp,
  Building: "fa-regular fa-building" as IconProp,
  Bug: "fa-solid fa-bug" as IconProp,
  Bus: "fa-solid fa-bus" as IconProp,
  Calendar: "fa-regular fa-calendar" as IconProp,
  Cancel: "fa-solid fa-times" as IconProp,
  Car: "fa-solid fa-car" as IconProp,
  CaretDown: "fa-solid fa-caret-down" as IconProp,
  CaretUp: "fa-solid fa-caret-up" as IconProp,
  Check: "fa-solid fa-check" as IconProp,
  CheckCircle: "fa-regular fa-check-circle" as IconProp,
  CheckCircleSolid: "fa-solid fa-check-circle" as IconProp,
  ChecklistIncomplete: "fa-solid fa-list-check" as IconProp,
  ChevronUp: "fa-solid fa-chevron-up" as IconProp,
  ChevronDown: "fa-solid fa-chevron-down" as IconProp,
  ClipboardCheck: "fa-solid fa-clipboard-check" as IconProp,
  Circle: "fa-regular fa-circle" as IconProp,
  CircleSolid: "fa-solid fa-circle" as IconProp,
  CircleNotch: "fa-solid fa-circle-notch" as IconProp,
  Clock: "fa-regular fa-clock" as IconProp,
  Clone: "fa-regular fa-clone" as IconProp,
  Coffee: "fa-solid fa-coffee" as IconProp,
  Cog: "fa-solid fa-cog" as IconProp,
  Cogs: "fa-solid fa-cogs" as IconProp,
  Coins: "fa-solid fa-coins" as IconProp,
  CommentAlt: "fa-regular fa-comment-alt" as IconProp,
  CommentSms: "fa-solid fa-comment-sms" as IconProp,
  CompressAlt: "fa-solid fa-compress-alt" as IconProp,
  Confirm: "fa-solid fa-check" as IconProp,
  Crown: "fa-solid fa-crown" as IconProp,
  Download: "fa-solid fa-download" as IconProp,
  Edit: "fa-regular fa-edit" as IconProp,
  EllipsisH: "fa-solid fa-ellipsis-h" as IconProp,
  Envelope: "fa-solid fa-envelope" as IconProp,
  EnvelopeOpenText: "fa-solid fa-envelope-open-text" as IconProp,
  EyeSlash: "fa-solid fa-eye-slash" as IconProp,
  Eye: "fa-solid fa-eye" as IconProp,
  Exclamation: "fa-solid fa-exclamation" as IconProp,
  ExclamationCircle: "fa-solid fa-exclamation-circle" as IconProp,
  ExclamationTriangle : "fa-solid fa-exclamation-triangle" as IconProp,
  ExternalLink: "fa-solid fa-external-link-alt" as IconProp,
  FileAlt: "fa-regular fa-file-alt" as IconProp,
  FileDownload: "fa-solid fa-file-download" as IconProp,
  Flag: "fa-regular fa-flag" as IconProp,
  Gift: "fa-solid fa-gift" as IconProp,
  Globe: "fa-solid fa-globe" as IconProp,
  GoogleDrive: "fa-brands fa-google-drive" as IconProp,
  Handshake: "fa-regular fa-handshake" as IconProp,
  Hashtag: "fa-solid fa-hashtag" as IconProp,
  Hiking: "fa-solid fa-hiking" as IconProp,
  Hourglass: "fa-regular fa-hourglass" as IconProp,
  Info: "fa-solid fa-info" as IconProp,
  InfoCircle: "fa-solid fa-info-circle" as IconProp,
  Invoice: "fa-solid fa-file-invoice" as IconProp,
  Key: "fa-solid fa-key" as IconProp,
  Laptop: "fa-solid fa-laptop" as IconProp,
  Layers: "fa-solid fa-layer-group" as IconProp,
  LevelDown: "fa-solid fa-level-down-alt" as IconProp,
  LevelUp: "fa-solid fa-level-up-alt" as IconProp,
  Lightbulb: "fa-regular fa-lightbulb" as IconProp,
  Link: "fa-solid fa-link" as IconProp,
  List: "fa-solid fa-th-list" as IconProp,
  Lock: "fa-solid fa-lock" as IconProp,
  Magic: "fa-solid fa-magic" as IconProp,
  MagicSparkles: "fa-solid fa-wand-magic-sparkles" as IconProp,
  Map: "fa-regular fa-map" as IconProp,
  MapMarked: "fa-solid fa-map-marked-alt" as IconProp,
  MapMarker: "fa-solid fa-map-marker-alt" as IconProp,
  Microscope: "fa-solid fa-microscope" as IconProp,
  Minus: "fa-solid fa-minus" as IconProp,
  MinusSquare: "fa-regular fa-minus-square" as IconProp,
  MoneyBill: "fa-solid fa-money-bill" as IconProp,
  Palette: "fa-solid fa-palette" as IconProp,
  Pdf: "fa-solid fa-file-pdf" as IconProp,
  PersonWalking: "fa-solid fa-person-walking" as IconProp,
  Phone: "fa-solid fa-phone" as IconProp,
  Plus: "fa-solid fa-plus" as IconProp,
  PlusSquare: "fa-regular fa-plus-square" as IconProp,
  Qr: "fa-solid fa-qrcode" as IconProp,
  Robot: "fa-solid fa-robot" as IconProp,
  Rocket: "fa-solid fa-rocket" as IconProp,
  Search: "fa-solid fa-search" as IconProp,
  SearchMinus: "fa-solid fa-search-minus" as IconProp,
  SearchPlus: "fa-solid fa-search-plus" as IconProp,
  ShareAlt: "fa-solid fa-share-alt" as IconProp,
  Slack: "fa-brands fa-slack" as IconProp,
  Sort: "fa-solid fa-sort" as IconProp,
  Spider: "fa-solid fa-spider" as IconProp,
  Star: "fa-regular fa-star" as IconProp,
  StarFull: "fa-solid fa-star" as IconProp,
  StarHalf: "fa-solid fa-star-half" as IconProp,
  StarHalfAlt: "fa-solid fa-star-half-alt" as IconProp,
  StarEmpty: "fa-regular fa-star" as IconProp, 
  Steps: "fa-solid fa-shoe-prints" as IconProp,
  Subway: "fa-solid fa-subway" as IconProp,
  Sync: "fa-solid fa-sync" as IconProp,
  Table: "fa-solid fa-border-all" as IconProp,
  ThermometerHalf: "fa-solid fa-thermometer-half" as IconProp,
  ThumbsDown: "fa-regular fa-thumbs-down" as IconProp,
  ThumbsUp: "fa-regular fa-thumbs-up" as IconProp,
  Times: "fa-solid fa-times" as IconProp,
  TimesCircle: "fa-regular fa-times-circle" as IconProp,
  TowerBroadcast: "fa-solid fa-tower-broadcast" as IconProp,
  Train: "fa-solid fa-train" as IconProp,
  Trash: "fa-regular fa-trash-alt" as IconProp,
  University: "fa-solid fa-university" as IconProp,
  Unlock: "fa-solid fa-unlock" as IconProp,
  User: "fa-solid fa-user" as IconProp,
  UserFriends: "fa-solid fa-user-friends" as IconProp,
  Utensils: "fa-solid fa-utensils" as IconProp, 
  Vial: "fa-solid fa-vial" as IconProp,
  Walking: "fa-solid fa-walking" as IconProp,
  Weather: "fa-solid fa-cloud-sun-rain" as IconProp,
}