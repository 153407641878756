import React, { useState } from "react";
import Code from "../Code";
import { Card, Heading, Form, Dropdown, DropdownOption } from "../../../controls";

export default function DropdownDemo() {
  // TODO replace with DropdownOption
  const [options] = useState<{label:any, value:any}[]>([
    {label:"red", value:"red"},
    {label:"blue", value:"blue"},
    {label:"green", value:"green"},
    {label:"yellow", value:"yellow"},
  ])
  const [mixedOptions] = useState<DropdownOption[]>([
    {label: "a string", value: "this is a string"},
    {label: "a number", value: 23},
    {label: "an object", value: {name: "Anne", age: 32}}
  ]);

  const [selectedColor, setSelectedColor] = useState<string>("red");
  const [formData, setFormData] = useState<string>("");
  const [selectedMixedValue, setSelectedMixedValue] = useState<any>(mixedOptions[0].value);


  // form save
  const onSave = (formData:any) => {
    setFormData(JSON.stringify(formData))
  }


  // render

  return <>
    <Heading>Dropdown</Heading>
    
    <Card title="In Form">
      <Form busy={false} entity={{color: "blue"}} onSave={onSave}>
        <Dropdown path="color" label="Farbe" options={options} />
        <Dropdown path="color" label="Farbe copy" options={options} size="small" />
      </Form>
      <div>
        <div>formData:</div>
        <Code>
          {formData}
        </Code>
      </div>
    </Card>

    <Card title="Without Form">
      <Dropdown label="Geburtstag" options={options} value={selectedColor} onChange={(v:any) => setSelectedColor(v)} />
      <div>selected color: {selectedColor}</div>
    </Card>

    <Card title="Type aware - returns any type of value (i.e. not only strings)">
      <Dropdown label="Mixed options" options={mixedOptions} value={selectedMixedValue} onChange={(v:any) => setSelectedMixedValue(v) } />
      <div>selected value typeof: {typeof selectedMixedValue}</div>
    </Card>

    <Card title="Incomplete Implementation">
      <Dropdown label="Favourite color" options={options} />
    </Card>

  </>
}
