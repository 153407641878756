import React from "react";
import { Link } from "../../../controls";
import CSS from "./Video.module.scss";

type VideoProps = {
  youtubeId?: string,
  vimeoId?: string,
  orientation?: "horizontal"|"vertical"

}
export function Video({youtubeId, vimeoId, orientation} : VideoProps) {
  orientation = orientation || "horizontal";
  const width = orientation === "horizontal" ? 160 : 120;
  const height = orientation === "horizontal" ? 120: 160;

  if(youtubeId && youtubeId.trim().length > 0) {
    return (
      <div className={CSS.container}>
        <iframe width={`${width}`} height={`${height}`} src={`https://www.youtube.com/embed/${youtubeId}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>;
        <Link to={`https://www.youtube.com/watch?v=${youtubeId}`}>YouTube</Link>
      </div>
    )
  }
  if(vimeoId && vimeoId.trim().length > 0) {
    return (
      <div className={CSS.container}>
        <iframe src={`https://player.vimeo.com/video/${vimeoId}`} width={`${width}`} height={`${height}`} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen={true}></iframe>
        <Link to={`https://vimeo.com/${vimeoId}`}>Vimeo</Link>
      </div>
    )
  }
  return null;
}