import React, {useEffect, useState, useContext} from "react";
import * as BL from "../../../../types/BusinessLogic";
import * as DA from "../../../../types/DataAccess";
import { Alert } from "../../../controls";
import { ActivityContext } from "../ActivityContext";

export default function SanityCheck() {
  // context
  const AC = useContext(ActivityContext)!;

  // state
  const [errors, setErrors] = useState<any>([]);

  // mount / region variant changed
  useEffect(() => {
    if(AC.regionVariant) {
      setErrors(BL.Activity.sanityCheckVariant(AC.regionVariant as DA.ActivityRegionVariant)); // TODO remove typescast
    }
  }, [AC.regionVariant]);


  // no errors? no render
  if(errors.length === 0) {
    return null;
  }
  // pre-render
  const items = errors.map((err:any, index:number) => <li key={index}>{err}</li>)
  // render
  return (
    <Alert title="Unvollständige Konfiguration" intent="error" size="medium">
      <div>Diese Regionsvariante ist nicht vollständig konfiguriert. Folgende Fehler korrigieren:</div>
      <ul>{items}</ul>
    </Alert>
  )
}