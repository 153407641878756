import _ from "lodash";
import { RawStep } from "./Steps";
import moment from "moment";

/**
 * the retrive method expects an object structured like so: 
 * {
 *    adventure,
 *    bookingRequest,
 *    providerInfo {
 *      name,
 *      location {  
 *        start.title,
 *        start.description
 *      }
 *    }
 * }
 */

export type PlaceholderSourceData = {
  adventure:any,
  bookingRequest:any,
  rawStep: RawStep,
  providerInfo: {
    name:string,
    location: {
      start: {
        title: string,
        description: string;
      }
    }
  }
}

type Placeholder = {
  placeholder: string,
  explanation: string,
  retrieve: (data:PlaceholderSourceData) => any;
}

const placeholders: Placeholder[] = [
  {
    placeholder:"@@beschenkter@@",
    explanation:"Name des Beschenkten (Quelle: Überraschung)",
    retrieve: (data:PlaceholderSourceData) => {
      return data.adventure.RecieverRealFirstName || data.adventure.RecieverName || null;
    }
  },
  {
    placeholder:"@@anbieter@@",
    //explanation:"Der Name des Anbieters (Quelle: Buchungsanfrage, 'Anbieter')",
    explanation:"Name des Anbieters (und das Titel-Feld des Treffpunkts des Anbieters als Zusatzinformation). Falls nicht vorhanden, wird der Name des Anbieters aus der Buchungsanfrage verwendet.",
    retrieve: (data:PlaceholderSourceData) => {
      const locationTitle = _.get(data, "providerInfo.location.start.title");
      const providerName = _.get(data, "providerInfo.name");
      const providerNameFromBookingRequest = _.get(data, "bookingRequest.provider.name");
      if(providerName && locationTitle) {
        return `${providerName} (${locationTitle})`;
      }
      else if(providerName) {
        return providerName;
      }
      else if(providerNameFromBookingRequest) {
        return providerNameFromBookingRequest;
      }
      else {
        return null;
      }
    }
  },
  {
    placeholder:"@@anbieter_anweisungen@@",
    explanation: "Anweisungen aus der Treffpunkt-Information des Anbieters.",
    retrieve: (data:PlaceholderSourceData) => {
      return _.get(data, "providerInfo.location.start.instructions") || null;
    }
  },
  {
    placeholder:"@@venue@@",
    explanation: "Austragungsort des Anbieters.",
    retrieve: (data:PlaceholderSourceData) => {
      return _.get(data, "providerInfo.location.venue") || null;
    }
  },
  {
    placeholder:"@@zeit@@",
    explanation:"Reservationszeit (Quelle: Buchungsanfrage, Angegefragte Leistung 'Zeit')",
    retrieve: (data:PlaceholderSourceData) => {
      let service = _.get(data, "bookingRequest.services", []).find((s:any) => s.key.startsWith("Zeit"));
      return service ? service.value : null; 
    }
  },
  {
    placeholder:"@@zeit_ende@@",
    explanation:"Ende der Aktivität (Reservationszeit + geplante Dauer)",
    retrieve: (data:PlaceholderSourceData) => {
      if(data.rawStep.outlineActivity) {
        
        const duration = data.rawStep.outlineActivity.duration;
        const startTime = data.rawStep.outlineActivity.startTime;
        const endTime = moment(startTime).add(duration, "seconds");
        return moment(endTime).format("HH:mm");
      }
      else {
        return null;
      }
    }
  },
  {
    placeholder:"@@personen@@",
    explanation:"Anzahl Personen (Quelle: Buchungsanfrage, Angegefragte Leistung 'Anzahl Personen')",
    retrieve: (data:PlaceholderSourceData) => {
      let service = _.get(data, "bookingRequest.services", []).find((s:any) => s.key.startsWith("Anzahl Personen"));
      return service ? service.value : null; 
    }
  },
  {
    placeholder:"@@konsumationsguthaben@@", 
    explanation:"Das gebuchte Konsumationsguthaben (Quelle: Buchungsanfrage, Angegefragte Leistung 'Konsumationsguthaben')",
    retrieve: (data:PlaceholderSourceData) => {
      let service = _.get(data, "bookingRequest.services", []).find((s:any) => s.key.startsWith("Konsumationsguthaben"));
      return service ? service.value : null;
    }
  },
  {
    placeholder:"@@leistungen@@",
    explanation:"Angefragte Leistungen (Quelle: Buchungsanfrage, Angegefragte Leistung 'Leistung')",
    retrieve: (data:PlaceholderSourceData) => {
      let service = _.get(data, "bookingRequest.services", []).find((s:any) => s.key.startsWith("Leistung"));
      return service ? service.value : null;
    }
  }
];

export default placeholders;