import { BookingRequestMapper } from "./BookingRequest.Mapper";
import { BookingRequest, BookingRequestActivity, BookingRequestProvider, BookingRequestRequest, BookingRequestService } from "./BookingRequest";
import { RepositoryFactory } from "../_Common/RepositoryFactory";


export class BookingRequestRepository {
  private static mapper = new BookingRequestMapper();
  
  // Note: we use a bespoke make method instead of the one provided by the factory static make = RepositoryFactory.make<BookingRequest, BookingRequestMapper>(this.mapper);
  static search = RepositoryFactory.search<BookingRequest, BookingRequestMapper>(this.mapper);
  static create = RepositoryFactory.create<BookingRequest, BookingRequestMapper>(this.mapper);
  static update = RepositoryFactory.update<BookingRequest, BookingRequestMapper>(this.mapper);
  static remove = RepositoryFactory.remove<BookingRequest, BookingRequestMapper>(this.mapper);
  static findById = RepositoryFactory.findById<BookingRequest, BookingRequestMapper>(this.mapper);

  /**
   * Creates a new booking request object without saving it to the database
   */
  static make(adventureId: string, adventureDate: Date, activity:BookingRequestActivity, provider: BookingRequestProvider, request: BookingRequestRequest, services: BookingRequestService[]) {
    const br: BookingRequest = {
      _id: undefined,
      public_id: "",
      adventure_id: adventureId,
      adventure_date: adventureDate,
      activity: activity,
      provider: provider,
      request: request,
      services: services,
      response: null,
      reminders: [],
      collectiveInvoice: null
    }
    return br;
  }

  /**
   * Finds all booking requests for a given adventure
   * @param adventureId 
   * @returns 
   */
  static async findByAdventure(adventureId:string) : Promise<BookingRequest[]> {
    const searchResult = await this.search({adventure_id: adventureId});
    return searchResult;
  }
}

