import React, { useState } from "react";
import { Redirect } from 'react-router-dom';
import Code from "../Code";
import Notes from "../Notes";
import { Card, Icon, Icons, List, Heading } from "../../../controls";

export default function ListDemo() {
  const [columns] = useState([
    {header: "Name", path:"name", key: "key1", print:(v:any) => v.name, sort: (a:any,b:any) => a.name.localeCompare(b.name)},
    {header: "Aktiv", path:"isActive", key: "key2", print: (v:any) => v.isActive ? <Icon icon={Icons.Check} /> :"", sort: (a:any,b:any) => (a.isActive ? 1 : 0) > (b.isActive ? 1 : 0) ? 1 : -1},
    {header: "E-Mail", key: "key3", path:"email", print: (v:any) => <a href={`mailto:${v.email}`}>{v.email}</a>, sort: (a:any,b:any) => a.email.localeCompare(b.email)}
  ])
  const [items] = useState([
    {id:"pmc", name: "McCartney, Paul", isActive: true, email:"paul@abbeyroad.co.uk"},
    {id:"gh", name: "Harrison, George", isActive: false, email:"george@abbeyroad.co.uk"},
    {id:"jl", name: "Lennon, John", isActive: false, email:"john@abbeyroad.co.uk"},
    {id:"rs", name: "Starr, Ringo", isActive: true, email:"ringo@abbeyroad.co.uk"}
  ])
  const [redirect, setRedirect] = useState(null);

  // row is clicked
  const onRowClick = (item:any) => {
    setRedirect(item.id);
  }

  // TODO document this better
  const listFilter = (item:any) => {
    return true;
  }

  // redirect
  if(redirect) {
    return <Redirect to={`/some/path/${redirect}`} />
  }
  
  // render
  return <Card>
    <Heading>List</Heading>
    <List columns={columns} items={items} getKey={v => v.email} onRowClick={onRowClick} sortColumnKey="key1" filter={listFilter}/>
  </Card>
}