import React, { useState } from "react";
import Code from "../Code";
import { Card, Heading, Form, DateInput, Validation} from "../../../controls";

export default function TextInputDemo() {
  const [date1, setDate1] = useState<Date|null>(new Date(1984, 5, 10));
  const [date2, setDate2] = useState<Date|null>(null);
  const [formData, setFormData] = useState<string>("");


  // form save
  const onSave = (formData:any) => {
    setFormData(JSON.stringify(formData))
  }

  // validate date
  const validateDate = (v:Date|null): Validation => {
    if(!v) {
      return new Validation(false, "Date is required");
    }

    if(v.getMonth() !== 4) {
      return new Validation(false, "Date needs to be in May");
    }
    else {
      return new Validation(true);
    }
  }

  // render
  return <>
    <Heading>DateInput</Heading>
    
    <Card title="In Form">
      <Form busy={false} entity={{date1: new Date(), date2: new Date(1974, 11, 12), date3: null}} onSave={onSave}>
        <DateInput path="date1" label="Date and Time" kind="datetime" />
        <DateInput path="date2" label="another date" explanation="just another date" />
        <DateInput path="date3" label="a nullable date" nullable={true} />
      </Form>
      <div>
        <div>formData:</div>
        <Code>
          {formData}
        </Code>
      </div>
    </Card>

    <Card title="Without Form - date only">
      <DateInput label="Date" value={date1} onChange={(d) => setDate1(d)} />
      <div>selected date: {String(date1)}</div>
    </Card>

    <Card title="Without Form - date and time">
      <DateInput label="Date" value={date1} kind="datetime" onChange={(d) => setDate1(d)} explanation="please enter a date and time" />
      <div>selected date: {String(date1)}</div>
    </Card>

    <Card title="Validation">
    <DateInput label="Date in May" value={date1} kind="date" onChange={(d) => setDate1(d)} explanation="please enter a date in May" validate={validateDate} />
      <div>selected date: {String(date1)}</div>
    </Card>

    <Card title="Nullable">
      <DateInput label="Nullable Date" nullable={true} value={date2} kind="date" onChange={(d) => setDate2(d)} />
      <div>selected date: {String(date2)}</div>
    </Card>

    <Card title="Incomplete Implementation">
      <DateInput label="Something Missing" />
    </Card>

  </>
}
