import React, { useState, useEffect } from "react";
import qs from "qs";

import _ from "lodash"

import { Card, Heading, Link } from "../../controls";

import ActionsDemo from "./Demos/ActionsDemo";
import AddRemoveDemo from "./Demos/AddRemoveDemo";
import AlertDemo from "./Demos/AlertDemo";
import AllFormControlsDemo from "./Demos/AllFormControlsDemo";
import ButtonDemo from "./Demos/ButtonDemo";
import CardDemo from "./Demos/CardDemo";
import CheckboxDemo from "./Demos/CheckboxDemo";
import CircleDemo from "./Demos/CircleDemo";
import ClipboardButtonDemo from "./Demos/ClipboardButtonDemo";
import ConfirmButtonDemo from "./Demos/ConfirmButtonDemo";
import DateInputDemo from "./Demos/DateInputDemo";
import DropdownDemo from "./Demos/DropdownDemo";
import DriveLinkDemo from "./Demos/DriveLinkDemo";
import FileSelectorDemo from "./Demos/FileSelectorDemo";
import FormDemo from "./Demos/FormDemo";
import IconDemo from "./Demos/IconDemo";
import CloudStorageUploaderDemo from "./Demos/CloudStorageUploaderDemo";
import LinkButtonDemo from "./Demos/LinkButtonDemo";
import LinkDemo from "./Demos/LinkDemo";
import ListDemo from "./Demos/ListDemo";
import LoaderDemo from "./Demos/LoaderDemo";
import MapLinkDemo from "./Demos/MapLinkDemo";
import ModalDemo from "./Demos/ModalDemo";
import NumberInputDemo from "./Demos/NumberInputDemo";
import ParagraphsDemo from "./Demos/ParagraphsDemo";
import SearchableDropdownDemo from "./Demos/SearchableDropdownDemo";
import StarsDemo from "./Demos/StarsDemo";
import SwitchboardDemo from "./Demos/SwitchboardDemo";
import SwitchDemo from "./Demos/SwitchDemo";
import TabsDemo from "./Demos/TabsDemo";
import TextAreaDemo from "./Demos/TextAreaDemo";
import TextInputDemo from "./Demos/TextInputDemo";
import ValueDemo from "./Demos/ValueDemo";

// css
import CSS from "./Kitchensink.module.scss";

// the component
export default function Kitchensink(props:any) {  
  // mount - check url search params
  useEffect(() => {
    const query = qs.parse(props.location.search, {ignoreQueryPrefix:true});
    if(query.group && query.item) {
      setSelected([query.group as string, query.item as string])
    }
  }, [props])

  // the catalogue 
  const groups: any = {
    Input: {
      Form: <FormDemo />,
      TextInput: <TextInputDemo />,
      TextArea: <TextAreaDemo />,
      NumberInput: <NumberInputDemo />,
      DateInput: <DateInputDemo />,
      Dropdown :<DropdownDemo />,
      Checkbox: <CheckboxDemo />,
      CloudStorageUploader: <CloudStorageUploaderDemo />,
      SearchableDropdown: <SearchableDropdownDemo />,
      Button: <ButtonDemo />,
      ConfirmButton: <ConfirmButtonDemo />,
      Actions: <ActionsDemo />,
      AllFormControls: <AllFormControlsDemo />
    }, 
    Output: {
      List: <ListDemo />,
      Value: <ValueDemo />,
      Paragraphs: <ParagraphsDemo />,
    },
    Layout: {
      Card: <CardDemo />,
      Circle: <CircleDemo />,
      Tabs: <TabsDemo />,
      Switchboard: <SwitchboardDemo />,
      Modal: <ModalDemo />,
    },
    Navigation: {
      LinkButton: <LinkButtonDemo />,
      Link: <LinkDemo />,
      MapLink: <MapLinkDemo />,
      DriveLink: <DriveLinkDemo />
    },
    Info: {
      Alert: <AlertDemo />,
      Icon: <IconDemo />,
      Loader: <LoaderDemo />,
    },
    Other: {
      AddRemove: <AddRemoveDemo />,
      ClipboardButton: <ClipboardButtonDemo />,
      Stars: <StarsDemo />,
      Switch: <SwitchDemo />,
      FileSelectorDemo: <FileSelectorDemo />
    }
  }

  // state
  const [selected, setSelected] = useState<[string, string]|null>(null);

  // pre-render
  const catalogue:any[] = [];
  Object.entries(groups).forEach(([groupKey, group]) => {
    catalogue.push(<div className={CSS.groupName} key={groupKey}>{groupKey.toUpperCase()}</div>);
    Object.entries(group as any).forEach(([itemKey, item]) => {
      catalogue.push(
        <Link key={itemKey} to={`/kitchensink/?group=${groupKey}&item=${itemKey}`}>
          <div className={CSS.itemName} key={itemKey}>{itemKey}</div>
        </Link>
      );
    })
  });

  //  onClick={() => setSelected([groupKey, itemKey])}

  // pre-render
  const component = selected ? groups[selected[0]][selected[1]] || null : null;

  // render
  return <div className={CSS.container}>
    <div className={CSS.inner}>
      <div className={CSS.catalogue}>
        {catalogue}
      </div>
      <div className={CSS.component}>
        {component}
      </div>
    </div>
  </div>
}
