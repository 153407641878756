import * as DA from "../../types/DataAccess";
import { Link, Icon, Icons } from "../controls";

type ProviderLinkProps = {
  provider: DA.Provider,
  target?: "_blank"|"_self"|"_parent"|"_top"|undefined,
}
export function ProviderLink({provider,target="_blank"} : ProviderLinkProps) {
  return (
    <Link to={`/providers/${provider._id!}`} target={target}>
      {provider.name}
    </Link>
  )
}