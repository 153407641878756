import _ from "lodash";
import { Filter, ResultItem } from ".";
import * as Api from "../../util/ApiTS";
import { Adventure } from "../Adventure/Adventure";

export class Searcher {
  public static readonly PAGE_SIZE = 100;

  private static readonly PROJECTION = {
    _id: 1,
    "Activities.Title": 1,
    "Activities.IsHomeDelivery":1,
    "Activities.IsOnlineEvent":1,
    "CreateDate": 1,
    "HasPriority": 1,
    "IsMe": 1,
    "IsTest": 1,
    "LiveId": 1,
    "Order.Comments": 1,
    "Order.Packaging.Code": 1,
    "Order.Prepaid":1,
    "Operator": 1,
    "PaymentReminderLevel": 1,
    "Rating": 1,
    "RegionCode": 1,
    "RecieverEmail": 1,
    "RecieverName": 1,
    "ReserverEmail": 1,
    "RecieverRealFirstName": 1,
    "ReserverName": 1,
    "ScheduleDate": 1,
    "SoldOfflineInfo":1, 
    "ShippingDate": 1,
    "StartTime": 1,
    "Status": 1,
    "TransportOptions": 1,
    "UserStartLocationText": 1,
    "UserStartTime": 1,
  }

  public static async count(filter:Filter) : Promise<number> {
    const payload = {
      filter: filter.toMongoFilter()
    }
    // query api
    const result = await Api.post("adventures", "count", payload);
    // return result
    if(result.success) {
      return result.data.count;
    }
    else {
      // TODO deal with error
      return 0;
    }
  }

  public static async search(filter:Filter, pages:number[], sortField:string, sortDirection:"desc"|"asc") : Promise<ResultItem[]> {
    // this will contain all items (i.e. all items of all pages)
    const allItems: ResultItem[] = [];
    // search for each page
    for(let page of pages) {
      // build payload
      const sort:any = {};
      sort[sortField] = sortDirection;
      const limit = Searcher.PAGE_SIZE;
      const payload = {
        filter: filter.toMongoFilter(), 
        projection: Searcher.PROJECTION, 
        sort,
        limit,
        skip:page * limit,
      }

      // query api
      const result = await Api.post("adventures", "search", payload);

      // convert received data to ResultItem and add to allItems collection
      if(result.success) {
        const items: ResultItem[] = result.data.items.map((adv:any) => {
          // activity titles
          let activityTitles: string[] = [];
          if(adv.Activities && _.isArray(adv.Activities)) {
            activityTitles = adv.Activities.map((a:any) => {
              return _.get(a, "Title", "unbekannt");
            })
          }
          else if(adv.SoldOfflineInfo && _.isArray(adv.SoldOfflineInfo.Activities)) {
            activityTitles = adv.SoldOfflineInfo.Activities.map((a:any) => {
              return _.get(a, "title.de", "unbekannt");
            })
          }
          
          // create item
          return {
            _id: adv._id,
            shortId: Adventure.getShortId(adv._id),
            activityTitles: activityTitles.join(", "),
            dateCreate: adv.CreateDate,
            status: adv.Status,
            hasPriority: adv.HasPriority || false,
            isTest: adv.IsTest || false,
            isMe: adv.IsMe || false,
            isOnlineEvent: (adv.Activities || []).some((a:any) => a.IsOnlineEvent),
            isHomeDelivery: (adv.Activities || []).some((a:any) => a.IsHomeDelivery),
            isPrepaidInWarehouse: _.get(adv, "Order.Prepaid.InWarehouse", false) ? true : false,
            liveId: adv.LiveId || false,
            dateStartUser: adv.UserStartTime || null,
            dateStart: adv.StartTime || null,
            dateScheduled: adv.ScheduleDate || null,
            orderComments: adv.Order && adv.Order.Comments ? adv.Order.Comments : "",
            operator: adv.Operator || null,
            nameReceiver: adv.RecieverName || null,
            nameReserver: adv.ReserverName || null,
            emailReceiver: adv.RecieverEmail || null,
            emailReserver: adv.ReserverEmail || null,
            paymentReminderLevel: _.isNil(adv.PaymentReminderLevel) ? null : adv.PaymentReminderLevel,
            packagingCode: _.get(adv, "Order.Packaging.Code", null) ? adv.Order.Packaging.Code : null,
            regionCode: adv.RegionCode || null,
            startLocation: adv.UserStartLocationText || null,
            ratingOverall: _.get(adv, "Rating.Rating", null),
            ratingOrganization: _.get(adv, "Rating.Rating2", null),
            ratingProvider: _.get(adv, "Rating.Rating3", null),
            transportOptions: _.isArray(adv.TransportOptions) ? adv.TransportOptions.join(", ") : null,
          } as ResultItem; 
        });

        // collect
        allItems.push(...items);
      }
      else {
        // TODO handle error
      }
    }

    // done
    return allItems;
  }
}
