// TODO we probably have that in other places as well ... find them, unite them, reduce them to one, mash them, fry them, boil them
export class Translatable {
  de: string;
  en: string;

  constructor(de:string = "", en?:string) {
    this.de = de;
    this.en = en || ""
  }

  public toDb(): any {
    
    return {
      de: this.de,
      en: this.en.trim() === "" ? `!_${this.de}` : this.en
    }
  }

  public static fromDb(obj:any) : Translatable {
    return new Translatable(obj.de || "", obj.en);
  }
}
