import _ from "lodash";
import React, {useEffect, useState} from "react";
import moment from "moment";
import Util from "../../../../util/util";
import {GMAP_TRAVEL_MODES_OBJ, GMAP_VEHICLES} from "../../../../config/constants"
import {Link} from "../../../controls";
import OutlineTimelineItem from "./OutlineTimelineItem";
import * as TS from "../../../../types";

import "./OutlineRouteStep.scss";

// Maps Url: https://developers.google.com/maps/documentation/urls/guide

type OutlineRouteStepProps = {
  step: TS.AdventureOutlineRouteStep,
  invalid:boolean
}
export default function OutlineRouteStep({step, invalid}:OutlineRouteStepProps) {
  const [ready, setReady] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<Date>(new Date());
  const [endTime, setEndTime] = useState<Date>(new Date());
  const [duration, setDuration] = useState<number>(0);
  const [travelMode, setTravelMode] = useState<any>(GMAP_TRAVEL_MODES_OBJ.WALKING);
  const [mapsUrl, setMapsUrl] = useState<string>("");
  const [warning, setWarning] = useState<string|null>(null);
  const [transitInfo, setTransitInfo] = useState<string|null>(null);

  //const [endLocation, setEndLocation] = useState<string>("");
  useEffect(() => {
    if(step) {
      const travelMode = getTravelMode(step.travelMode.toLocaleUpperCase()); 
      const startLocation = `${step.startLocation.lat},${step.startLocation.lng}`;
      const endLocation = `${step.endLocation.lat},${step.endLocation.lng}`;
      const mapsUrl = `https://www.google.com/maps/dir/${startLocation}/${endLocation}${travelMode.urlExtension}`;
      const warning = step.travelMode.toUpperCase() === "TRANSIT" ? "Achtung: die Karte zeigt die Route für HEUTE an" : null;
      let transitInfo = null;
      if(step.transit) {
        transitInfo = `${getVehicleLabel(step.transit.vehicle)} von ${step.transit.departureStop} nach ${step.transit.arrivalStop} (${step.transit.line}, in Richtung ${step.transit.headsign})`
      }
      
      setStartTime(step.startTime);
      setEndTime(moment(step.startTime).add(step.duration, "seconds").toDate());
      setDuration(Math.floor((step.duration / 60) + 1));
      setTravelMode(travelMode);
      setMapsUrl(mapsUrl);
      setWarning(warning);
      setReady(true);
      setTransitInfo(transitInfo);
    }
    

  }, [step]);

  // render not readdy / no data
  if(!step || !ready) {
    return null;
  }
  
  // render
  return (
    <div className={`c-outline-trip ${invalid?"invalid":""}`}>
      <OutlineTimelineItem start={startTime} end={endTime} icon={travelMode.icon2} />
      <div className="trip-content">
        {transitInfo ? <div>{transitInfo}</div> : null}
        <div>
          Start: {Util.printDateAndTimeWithoutYear(startTime)}
        </div>
        <div>
          Ende: {Util.printDateAndTimeWithoutYear(endTime)}
        </div>
        <div>
          Dauer: {duration} Minuten
        </div>
        <div>
          <Link to={mapsUrl} target="_blank">
            Route {travelMode.label}
          </Link>
          {warning ? <span className="route-warning">{warning}</span> : null}
        </div>
      </div>
      <div className="trip-invalid">
        <div className="trip-invalid-inner">
          Nicht mehr gültig - Planungsvorschlag erstellen oder wieder herstellen
        </div>
      </div>
    </div>
  )
}


function getVehicleLabel(code:string) {
  switch(code) {
    case "RAIL":
    case "HEAVY_RAIL":
    case "COMMUTER_TRAIN":
    case "HIGH_SPEED_TRAIN":
    case "LONG_DISTANCE_TRAIN":
      return "Zug";
    case "BUS":
      return "Bus";
    case "TRAM":
      return "Tram";
    default:
      return "ÖV";
  }
}

function getTravelMode(code:string) {
  switch(code) {
    case "DRIVING":
      return GMAP_TRAVEL_MODES_OBJ.DRIVING;
    case "TRANSIT":
      return GMAP_TRAVEL_MODES_OBJ.TRANSIT;
    case "BICYCLING":
      return GMAP_TRAVEL_MODES_OBJ.BICYCLING;
    default:
      return GMAP_TRAVEL_MODES_OBJ.WALKING;
  }
}