import React, {useState} from "react";
import * as DA from "../../../types/DataAccess";

// context
type ProviderContextType = {
  // values
  provider: DA.Provider,
  // functions
  load: (id:string) => Promise<void>,
  reload: () => Promise<void>,
  update: (changeset:any) => Promise<void>,
  upsertLink: (link:DA.ProviderLink) => Promise<void>,
  deleteLink: (link:DA.ProviderLink) => Promise<void>,
  upsertContact: (contact:DA.ProviderContact) => Promise<void>,
  deleteContact: (contact:DA.ProviderContact) => Promise<void>,
  upsertAddress: (address:DA.ProviderAddress) => Promise<void>,
  deleteAddress: (address:DA.ProviderAddress) => Promise<void>,
  upsertBankAccount: (bankAccount:DA.ProviderBankAccount) => Promise<void>,
  deleteBankAccount: (bankAccount:DA.ProviderBankAccount) => Promise<void>,
}
const ProviderContext = React.createContext<ProviderContextType>({} as ProviderContextType);

// provider
type ProviderProviderProps = {
  children: React.ReactNode|Array<React.ReactNode>
}
function ProviderProvider({children} : ProviderProviderProps) {
  // state
  const [provider, setProvider] = useState<DA.Provider>({} as DA.Provider);

  // provider value
  const value = {
    // props
    provider,

    // functions
    load: async(id:string) => {
      const p = await DA.ProviderRepository.findById(id);
      if(p) {
        setProvider(p);
      }
      else {
        // TODO maybe should let user know ...
        console.error("no provider with id '", id, "' found");
      }
    },
    reload: async() => {
      if(provider) {
        const p = await DA.ProviderRepository.findById(provider._id!);
        if(p) {
          setProvider(p);
        }
      }
    },
    update: async(changeset:any) => {
      await DA.ProviderRepository.update(provider._id!, changeset);
      const p = await DA.ProviderRepository.findById(provider._id!);
      if(p) {
        setProvider(p);
      }
    },
    upsertLink: async(link:DA.ProviderLink) => {
      await DA.ProviderRepository.upsertLink(provider, link);
    },
    deleteLink: async(link:DA.ProviderLink) => {
      await DA.ProviderRepository.deleteLink(provider, link);
    },
    upsertContact: async(contact:DA.ProviderContact) => {
      await DA.ProviderRepository.upsertContact(provider, contact);
    },
    deleteContact: async(contact:DA.ProviderContact) => {
      await DA.ProviderRepository.deleteContact(provider, contact);
    },
    upsertAddress: async(address:DA.ProviderAddress) => {
      await DA.ProviderRepository.upsertAddress(provider, address);
    },
    deleteAddress: async(address:DA.ProviderAddress) => {
      await DA.ProviderRepository.deleteAddress(provider, address);
    },  
    upsertBankAccount: async(bankAccount:DA.ProviderBankAccount) => {
      await DA.ProviderRepository.upsertBankAccount(provider, bankAccount);
    },
    deleteBankAccount: async(bankAccount:DA.ProviderBankAccount) => {
      await DA.ProviderRepository.deleteBankAccount(provider, bankAccount);
    }
  }
  return (
    <ProviderContext.Provider value={value}>
      {children}
    </ProviderContext.Provider>
  )
}

export {ProviderContext, ProviderProvider}
