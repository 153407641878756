import React, {useState, useEffect, useContext} from "react";
import {ProviderContext} from "./Provider.Context";
// components
import {Card, Form, Value, Modal, TextArea} from "../../controls";

// the main component
function Notes() {
  const [mode, setMode] = useState<"edit"|"view">("view");
  const PC = useContext(ProviderContext);

  const startEdit = () => setMode("edit");
  const stopEdit = () => setMode("view");

  return <>
    <Card title="Notizen" expanded={false} collapsible={true}>
      <Value onClick={startEdit} label="Notizen">
        {(PC.provider.notes || "").split('\n').map((n,i) => <div key={i}>{n}</div>)}
      </Value>
    </Card>
    <Modal isOpen={mode==="edit"} title="Notizen bearbeiten" onClose={stopEdit}>
      <NotesEdit stopEdit={stopEdit} />
    </Modal>
  </>
}

// the edit dialog
type NotesEditProps = {
  stopEdit: () => void,
}
function NotesEdit({stopEdit} : NotesEditProps) {
  const PC = useContext(ProviderContext);

  const [entity, setEntity] = useState({notes:""});
  const [busy, setBusy] = useState(true);
  
  useEffect(() => {
    if(PC.provider.notes) {
      setEntity({notes:PC.provider.notes});
    }
    else {
      setEntity({notes:""});
    }
    setBusy(false);
  }, [PC.provider.notes]);


  const onSave = async(formResult:any) => {
    setBusy(true);
    await PC.update(formResult.changeset);
    stopEdit();
  }

  const onCancel = () => {
    stopEdit();
  }

  // render
  return <>
    <Form busy={busy} entity={entity} onSave={onSave} labelSave="speichern" onCancel={onCancel} labelCancel="abbrechen">
      <TextArea disabled={busy} path="notes" label="Notizen" placeholder="Hier Notizen eingeben"  />
    </Form>
  </>
}

export default Notes;