import React, {useState, useEffect, useContext} from "react";
import { SurpriseContext } from "../Surprise.Context";
import { Form, Dropdown, Value, Alert, Modal } from "../../../controls";
import { Adventure } from "../../../../types";

export default function Transport() {
  // state
  const [mode, setMode] = useState<"edit"|"view">("view");
  // context
  const SC = useContext(SurpriseContext);

  // state
  const [state, setState] = useState<any>({containsHomedelivery:false, containsOnlineEvent:false, meansOfTransport:"", tickets:null, preferredModeOfTransport:null});

  // opens edit dialog
  const startEdit = () => setMode("edit");
  const stopEdit = () => setMode("view");

  // mount
  useEffect(() => {
    const adventure = Adventure.fromDb(SC.surprise);
    const containsHomedelivery = (SC.surprise.Activities || []).some((a:any) => a.IsHomeDelivery);
    const containsOnlineEvent =  (SC.surprise.Activities || []).some((a:any) => a.IsOnlineEvent);
    const meansOfTransport = getMeansOfTransport(SC.surprise.TransportOptions);
    let tickets = null;
    if(meansOfTransport === "oeffentlicher Verkehr") {
      tickets = `GA: ${SC.surprise.TransportOptions_GACount || 0}, Halbtax: ${SC.surprise.TransportOptions_HTCount || 0}`;
    }
    setState({containsHomedelivery, containsOnlineEvent, meansOfTransport, tickets, preferredModeOfTransport: adventure.PreferredModeOfTransport});
  }, [SC.surprise.Activities, SC.surprise.TransportOptions])

  // render
  if(state.containsHomedelivery || state.containsOnlineEvent) {
    return <div>"kein Transportmittel benötigt (Online-Event oder Heimlieferung)"</div>;
  }
  else {
    let preferredModeAlert = null;
    if(state.preferredModeOfTransport === "none") {
      preferredModeAlert = (
        <Alert size="small" intent="info">
          Für den Benutzer kommen sowohl Auto wie auch öffentlicher Verkehr in Frage
        </Alert>
      )
    }
    return <>
      <div>
        <Value onClick={startEdit} size="small">
          {state.meansOfTransport}{state.tickets ? <span>&nbsp;{state.tickets}</span> : null}
        </Value>
        {preferredModeAlert}
      </div>
      <Modal isOpen={mode==="edit"} title="Transportmittel bearbeiten" onClose={stopEdit}>
        <TransportEdit stopEdit={stopEdit} />
      </Modal>
    </>
  }
}


type TransportEditProps = {
  stopEdit: () => void,
}
function TransportEdit({stopEdit} : TransportEditProps) {
  // context
  const SC = useContext(SurpriseContext);
  // state
  const [entity, setEntity] = useState<any>(null);
  const [options] = useState([
    {value:"", label:"-"},
    {value:"oeffentlicher Verkehr", label:"öffentlicher Verkehr"},
    {value:"eigenes Auto", label:"Auto"},
  ])
  // mount 
  useEffect(() => {
    const meansOfTransport = (SC.surprise.TransportOptions || [])[0] || "";
    setEntity({meansOfTransport})
  }, [SC.surprise]);

  // form event handlers
  const save = async({changeset}:any) => {
    await SC.updateAtPath("TransportOptions", [changeset.meansOfTransport], false);
    stopEdit();
  }
  const cancel = () => stopEdit();

  // render
  return (
    <div>
      <Form 
        entity={entity} 
        busy={false}
        onSave={save} 
        onCancel={cancel}
      >
        <Dropdown options={options} label="Transportmittel" path="meansOfTransport" />
      </Form>
    </div>
  )
}


function getMeansOfTransport(options:any) {
  let opts = options || [];
  let value;
  switch(opts.length) {
    case 0:
      // default
      value = "oeffentlicher Verkehr";
      break;
    case 1:
      // newer ones have one of the following two values: "oeffentlicher Verkehr", "eigenes Auto" (set in aspx solution Schedule.cshtml)
      value = opts[0];
      break;
    default:
      // older adventures have may have more than one item in TransportOptions ... newer and current only one
      value = "";
      break;
  }
  return value;
}
