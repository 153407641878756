import React, { useContext } from "react";
import * as TS from "../../../../../types";
import { Checkbox  } from "../../../../controls";
import { SearchContext } from "../Search.Context";
import FilterGroup from "../Search.FilterGroup";

export default function Status() {
  // context
  const Search = useContext(SearchContext);

  // onChange
  const onChange = (status:TS.AdventureStatus, checked:boolean) => {
    toggleStatus([status], checked);
  }

  // onChange all
  const onChangeAll = (checked: boolean) => {
    toggleStatus(TS.AdventureStatiOrdered, checked);
  }

  // toggles status
  const toggleStatus = (stati: TS.AdventureStatus[], checked: boolean) => {
    const updatedFilter = Search.filter.clone();
    updatedFilter.stati = Search.filter.stati.filter(s => stati.includes(s) === false);
    if(checked) {
      stati.forEach(s => updatedFilter.stati.push(s));
    }
    Search.reset(updatedFilter);
  }

  // pre-render
  const items = TS.AdventureStatiOrdered.map((s) => {
    const checked = Search.filter.stati.includes(s);
    return <Checkbox key={s} value={checked} secondaryLabel={TS.Adventure.getStatusName(s)} onChange={v => onChange(s, v)} />
    
  })
  
  // render
  return (
    <FilterGroup title="Status">
      <Checkbox value={false} secondaryLabel="alle" onChange={c => onChangeAll(c)} />
      {items}
    </FilterGroup>
  )
}