import React, {useContext, useState} from "react";
import { SurpriseContext } from "../Surprise.Context";
import { CardSection, Value, Modal } from "../../../controls";
import GenericEdit from "../Generic.Edit";

type NotesAndCommentsProps = {
  expanded:boolean
}
export default function NotesAndComments({expanded}: NotesAndCommentsProps) {
  // state
  const [mode, setMode] = useState<"view"|"editNotes"|"editComments">("view");
  // context 
  const SC = useContext(SurpriseContext);

  // edit button event handler
  const startEditNotes = () => setMode("editNotes");
  const startEditComments = () => setMode("editComments");
  const stopEdit = () => setMode("view");

  // pre-render modal content
  let modalContent = null;
  let modalTitle = "";
  switch(mode) {
    case "editComments":
      modalTitle = "Kommentare bearbeiten";
      modalContent = <GenericEdit path="Comments" label="Kommentare" kind="textarea" stopEdit={stopEdit} />;
      break;
    case "editNotes":
      modalTitle = "Notizen bearbeiten";
      modalContent = <GenericEdit path="Notes" label="Notizen" kind="textarea" stopEdit={stopEdit} />;
      break;
  }
  // render
  return <>
    <CardSection title="Notizen">
      <Value label="Notizen" onClick={startEditNotes}>
        {(SC.surprise.Notes || "").split("\n").map((line:string, index:number) => <p key={index}>{line}</p>)}
      </Value>
      <Value label="Kommentare" onClick={startEditComments}>
        {(SC.surprise.Comments || "").split("\n").map((line:string, index:number) => <p key={index}>{line}</p>)}
      </Value>
    </CardSection>
    <Modal isOpen={mode !== "view"} title={modalTitle} onClose={stopEdit}>
      {modalContent}
    </Modal>
  </>
}