import React, { useEffect, useState } from "react";
import * as DA from "../../../types/DataAccess";

import { Actions, Alert, Button, CardSection, Dropdown, DropdownOptions, Form, Icon, Icons, Loader, Table, Td, TextArea, TextInput, Tr, Value } from "../../controls";

type ProviderPriceListsEditProps = {
  priceList: DA.PriceList,
  loadList: Function,
  closeDialog: () => void,
}
export default function ProviderPriceListsEdit({priceList, loadList, closeDialog}: ProviderPriceListsEditProps) {
  // state
  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<string|null>(null);
  const [entity, setEntity] = useState<DA.PriceList>(priceList);
  
  // loads data
  const reload = async() => {
    const updatedEntity = await DA.PriceListRepository.findById(entity._id!);
    loadList()
    setEntity(updatedEntity!);
  };

  // user wants to edit the currency
  const editCurrency = () => {
    //MC.push("Währung bearbeiten", <EditCurrency priceList={entity} onChange={() => reload()}/>);
  };

  // pre-render
  let content = null;
  // render busy
  if(busy) {
    content = <Loader />;
  }
  // render error
  else if(error) {
    content = <Alert intent="error" size="medium" title="Fehler beim Laden der Preisliste">{error}</Alert>;
  }
  else {
    content = <>
      <CardSection title="Währung">
        <Value>{entity.currencyCode}</Value>
      </CardSection>
      <CardSection title="Preise">
        <Prices priceList={entity} onChange={() => reload()} />
      </CardSection>
      <Button onClick={closeDialog} size="small"><Icon icon={Icons.Cancel} /> Dialog schliessen</Button>
    </>;
  }

  // render
  return content;
}

type PricesProps = {
  priceList:DA.PriceList,
  onChange:() => void,
}
function Prices({priceList, onChange}: PricesProps) { 
  // state
  const [mode, setMode] = useState<"view"|"edit">("view");
  const [pricesText, setPricesText] = useState<string>("");
  const [editErrors, setEditErrors] = useState<string[]>([]);
  const [editWarnings, setEditWarnings] = useState<string[]>([]);


  // mount (with priceList change)
  useEffect(() => {
    setPricesText(getTextFromPrices(priceList.prices));
    setMode("view");
    setEditErrors([]);
    setEditWarnings(getWarnings(priceList.prices));
  } , [priceList]);

  // user wants to save
  const onSave = async() => {
    const result = getPricesFromText(pricesText);
    if(result.errors.length === 0) {
      await DA.PriceListRepository.update(priceList._id!, { prices: result.prices });
      onChange();
    }
    else {
      setEditErrors(result.errors);
    }
  };

  // pre-render
  let content = null;
  let warningsAlert = null;
  if(editWarnings.length > 0) {
    warningsAlert = <Alert intent="warning" size="medium" title="Warnung">{editWarnings.map((warning, idx) => <div key={idx}>{warning}</div>)}</Alert>;
  }
  if(mode === "view") {
    // view mode
    let trEmpty = null;
    if(priceList.prices.length === 0) {
      trEmpty = <Tr><Td colSpan={2}>noch keine Preise vorhanden</Td></Tr>;
    }
    const rows = priceList.prices.map(price => {
      return <Tr key={price._id}>
        <Td>{price.participants}</Td>
        <Td>{price.price.toFixed(2)}</Td>
      </Tr>
    });
    content = <>
      <Value onClick={() => setMode("edit")}>
        <Table>
          <Tr>
            <Td label>Teilnehmer</Td>
            <Td label>Preis</Td>
          </Tr>
          {trEmpty}
          {rows}
        </Table>
      </Value>
      {warningsAlert}
      
    </>
  }
  else {
    // edit mode
    let errorsAlert = null;
    if(editErrors.length > 0) {
      errorsAlert = <Alert intent="error" size="medium" title="Fehler">{editErrors.map((error, idx) => <div key={idx}>{error}</div>)}</Alert>;
    }
    content = <>
      <TextArea 
        rows={10} 
        value={pricesText} 
        explanation="Pro Zeile einen Preis eingeben (Teilenehmer und Preis getrennt durch Doppelpunkt). Z.B.: 1:50"
        onChange={v => setPricesText(v)} 
      />
      {errorsAlert}
      {warningsAlert}
      <Actions onCancel={() => setMode("view")} onSave={onSave} />
    </>
  }
  // render
  return content;
}

function getPricesFromText(text:string): {errors:string[], prices:DA.PriceListPrice[]} {
  const errors: string[] = [];
  const prices: DA.PriceListPrice[] = [];

  const lines = text.trim().split("\n");

  // gather prices and errors
  if(text.trim().length > 0) {
    lines.forEach((line,index) => {
      if(line.trim().length > 0) {
        const tokens = line.split(":");
        if(tokens.length !== 2) {
          errors.push(`Zeile ${index+1}: zwei Werte müssen getrennt durch einen Doppelpunkt stehen.`);
        }
        else if(isNaN(Number(tokens[0])) || isNaN(Number(tokens[1]))) { 
          errors.push(`Zeile ${index+1}: bitte nur Zahlwerte eingeben.`);
        }
        else if(Number(tokens[0]) <= 0 || Number(tokens[1]) <= 0) {
          errors.push(`Zeile ${index+1}: bitte nur positive Zahlen eingeben.`);
        }
        else {
          prices.push({
            _id: undefined,
            participants: Number(tokens[0]),
            price: Number(tokens[1]),
          })
        }
      }
    })
  }

  // sort prices
  const sortedPrices = prices.sort((a,b) => a.participants - b.participants);

  // done 
  return {errors, prices:sortedPrices};
}

function getWarnings(prices:DA.PriceListPrice[]):string[] {
  const warnings:string[] = [];

  // sort prices
  const sortedPrices = prices.sort((a,b) => a.participants - b.participants);

  // gather warnings
  for(let i=0; i<sortedPrices.length; i++) {
    // prices ascending?
    let lastPrice = i === 0 ? 0 : sortedPrices[i-1].price;
    if(lastPrice >= sortedPrices[i].price) {
      warnings.push(`Zeile ${i+1}: der Preis ist kleiner als der vorherige Preis.`);
    }
    // missed a participant?
    if(i > 0) {
      const lastParticipants = i === 0 ? 0 : sortedPrices[i-1].participants;
      if(sortedPrices[i].participants > (lastParticipants + 1)) {
        warnings.push(`Zeile ${i+1}: eine Teilnehmerzahl wurde übersprungen.`);
      }
    }
  }
  
  // done
  return warnings;
}

function getTextFromPrices(prices:DA.PriceListPrice[]):string {
  return prices.map(p => `${p.participants}:${p.price}`).join("\n");
}