import React, { useEffect, useState } from "react";
import { Form, DateInput, TextInput, Alert, Loader, Validators, TextArea, Dropdown, NumberInput, Checkbox } from "../../controls";
import * as DA from "../../../types/DataAccess";

type VouchersEditProps = {
  id?: string,
  onUpdate: Function,
  onCancel: () => void,
}
export default function VouchersEdit({id, onUpdate, onCancel} : VouchersEditProps) {
  // state
  const [voucher, setVoucher] = useState<DA.Voucher|null>(null);
  const [busy] = useState(false);
  const [error, setError] = useState<any|null>(null);
  const [salesDateVisible, setSalesDateVisible] = useState(false);
  const [freeOfCharge, setFreeOfCharge] = useState<{kind:string, value:number}>({kind:"percentage", value:100});
  
  // mount
  useEffect(() => {
    const load = async() => {
      if(id) {
        const voucher = await DA.VoucherRepository.findById(id);
        //const entity = voucher!.toDb();
        setVoucher(voucher);
        //setEntity(entity);
      }
      else {
        const voucher = DA.VoucherRepository.make();
        setVoucher(voucher);
        //setEntity(voucher.toDb());
      }
    };
    load();
  }, [id])

  // entity is set
  useEffect(() => {
    if(voucher) {
      setSalesDateVisible(voucher.relevantToSales);
      setFreeOfCharge({kind:voucher.kind, value: voucher.value});
    }
  }, [voucher])

  // user wants to save
  const onSave = async(formResult:any) => {
    // get updated entity
    const updatedVoucher = formResult.merge(voucher);
    updatedVoucher.code = updatedVoucher.code.toUpperCase();
    updatedVoucher.notes = updatedVoucher.notes.trim().length > 0 ? updatedVoucher.notes : "-"; // the mongoose model requires a string with content
    // update / create
    let result: DA.Result<DA.Voucher>;
    if(voucher!._id) {
      result = await DA.VoucherRepository.update(voucher!._id, updatedVoucher);
    }
    else {
      result = await DA.VoucherRepository.create(updatedVoucher);
    }
    if(result.success) {
      await onUpdate();
    }
    else {
      setError(result.error);
    }
  }

  // use changes relvant to sales checkbox
  const onChangeRelevantToSales = (e:any) => {
    setSalesDateVisible(e.checked);
  }
  // user changes kind or value
  const onChangeKindOrValue = (what:"kind"|"value", e:any) => {
    if(what === "kind") {
      setFreeOfCharge({...freeOfCharge, kind:e});
    }
    else {
      setFreeOfCharge({...freeOfCharge, value:e});
    }
  }

  // render
  if(!voucher)  {
    return <Loader />
  }
  else {
    let errorAlert = null;
    if(error) {
      errorAlert = <Alert intent="error" size="medium" title="Fehler beim Speichern">{error.message}</Alert>
    }
    let relevantToSalesDate = null;
    if(salesDateVisible) {
      relevantToSalesDate = <div>
        <DateInput path="relevantToSalesDate" label="Verkaufsdatum" />  
      </div>
    }
    let freeOfChargeWarning = null;
    if(freeOfCharge.kind === "percentage" && freeOfCharge.value === 100) {
      freeOfChargeWarning = (
        <Alert title="100% Gutscheine" intent="info">
          Bei 100% Gutscheinen 
          <ul>
            <li>sollte <strong>einmalige Anwendung</strong> aktiviert werden</li>
            <li>ist für den Käufer auch die Verpackung gratis</li>
          </ul> 
        </Alert>
      );
    }
    return <>
      <Form entity={voucher} onSave={onSave} onCancel={onCancel} busy={busy}>
        <TextInput label="Code" path="code" disabled={busy} validate={Validators.isRequired("Code eingeben")} explanation="Buchstaben werden beim Speichern in Grossbuchstaben umgewandelt." />
        <TextArea label="Notizen" path="notes" disabled={busy} />
        <div style={{display:"flex", flexDirection:"row"}}>
          <NumberInput path="value" label="Wert" onChange={e => onChangeKindOrValue("value", e)} />
          &nbsp;
          <Dropdown
            path="kind" 
            label="Art" 
            options={[
              {label: "Betrag", value: "amount"},
              {label: "% Prozent", value: "percentage"}
            ]} 
            onChange={e => onChangeKindOrValue("kind", e)}
          />
        </div>
        {freeOfChargeWarning}
        <div style={{display:"flex", flexDirection:"row"}}>
          <DateInput path="validFrom" label="gültig von" />  
          &nbsp;
          <DateInput path="validTo" label="gültig bis" />
        </div>
        <NumberInput path="minOrderValue" label="Mindestbestellwert" />
        <Checkbox path="singleUse" label="einmalige Anwendung" />
        <Checkbox path="countsTowardsMinValue" label="zählt zum Mindestbestellwert" explanation="Normalerweise zählt der Wert des Promocodes nicht zum Mindestbestellwert im Shop. Ist diese Option aktiviert, jedoch schon." />
        <Checkbox path="relevantToSales" label="ist Umsatzrelevant" explanation="Der Wert des Gutscheins zählt zum Umsatz (wird bei % Gutscheinen ignoriert)" onChange={onChangeRelevantToSales} />
        {relevantToSalesDate}
        {errorAlert}
      </Form>
      
    </>
  }
}