import React, { useState, useEffect, useContext } from "react";
import { ProviderContext } from "./Provider.Context";
// components
import { Form, Value, TextInput, Validators, Modal } from "../../controls";

// the main component
export default function ProviderName() {
  // state
  const [mode, setMode] = useState<"edit"|"view">("view");
  // context
  const PC = useContext(ProviderContext);

  // starts editing
  const startEdit = () => setMode("edit");

  // closes edit dialog
  const closeDialog = () => setMode("view");

  return <>
    <Value onClick={startEdit} label="Name">{PC.provider.name}</Value>
    <Modal isOpen={mode==="edit"} onClose={closeDialog} title="Anbietername bearbeiten">
      <NameEdit closeDialog={closeDialog} />
    </Modal>
  </>
  
}

// the edit dialog
type NameEditProps = {
  closeDialog: () => void,
}
function NameEdit({closeDialog}: NameEditProps) {
  const PC = useContext(ProviderContext);

  const [entity, setEntity] = useState({name:""});
  const [busy, setBusy] = useState(true);
  
  useEffect(() => {
    if(PC.provider.name) {
      setEntity({name:PC.provider.name});
      setBusy(false);
    }
  }, [PC.provider.name]);


  const onSave = async(formResult:any) => {
    setBusy(true);
    await PC.update(formResult.changeset);
    closeDialog();
  }

  const onCancel = () => {
    closeDialog();
  }

  // render
  return <>
    <Form busy={busy} entity={entity} onSave={onSave} labelSave="speichern" onCancel={onCancel} labelCancel="abbrechen">
      <TextInput disabled={busy} path="name" label="Name" validate={Validators.isRequired("Bitte einen Namen eingeben")} placeholder="Name des Anbieters"  />
    </Form>
  </>
}

