import environment  from './environment'

const config = {
  development: {
    url:{
      CH: 'http://localhost:3020',
      DE: 'http://localhost:3020'
    } 
  },
  staging: {
    url:{
      CH: 'https://ch.appentura.dev',
      DE: 'https://de.appentura.dev'
    }
  },
  production: {
    url:{
      CH: 'https://appentura.ch',
      DE: 'https://appentura.de'
    }
  }
}

// NOTE: the APPENTURA_ENV variable is delivered by Webpack via a plugin (see plugins section in webpack.config.js'. There are three environments: development, staging, production
export default config[environment]