import React, { useEffect, useState } from "react";
import _ from "lodash"
import Api from "../../../util/api2";

import {Coordinates, Form, TextArea, TextInput, Validation, Value} from "../../controls"

import "./ProviderLocation.scss"

type GroupProps = {
  children:any,
  label:string
}
function Group({children, label}: GroupProps) {
  return (
    <div className="c-provider-location-group">
      <div className="title">{label}</div>
      <div className="content">{children}</div>
    </div>
  )
}

type ProviderLocationProps = {
  location: any,
  existingVenues: any,
  onSave: Function,
  closeDialog: VoidFunction
}
function ProviderLocation({location, existingVenues, onSave, closeDialog}:ProviderLocationProps) {
  // state
  const [activityVariant, setActivityVariant] = useState<any>(null);
  const [provider, setProvider] = useState<any>(null);
  const [entity, setEntity] = useState<any>(null);
  
  // mount
  useEffect(() => {
    load();
  }, [])

  // loads data
  const load = async() => {
    // get provider
    let provider = null
    let resultProvider = await Api.post("providers", "search", {filter:{_id:location.providerId}, projection:{_id:1, name:1, colorRating:1}})
    provider = resultProvider.data.items[0]

    // get activity
    let activityVariant = null
    let resultActivities = await Api.post("activities", "search", {filter:{_id:location.activityId}, projection:{_id:1, title:1, "regionVariants._id":1, "regionVariants.regionCode":1}})
    let a = resultActivities.data.items[0]
    let rv = _.get(a, "regionVariants", []).find((rv:any) => String(rv._id) === String(location.regionVariantId))
    activityVariant = {
      title:a.title.de,
      regionCode:rv.regionCode,
      activityId:location.activityId, 
      regionVariantId:location.regionVariantId
    }

    // define the entity
    let entity = {
      providerId:location.providerId, 
      activityId:location.activityId, 
      regionVariantId:location.regionVariantId,
      notes:location.notes || "",
      venue:location.venue || "",
      start: location.start || null, 
      end: location.end || null
    }

    // update the state
    setProvider(provider);
    setActivityVariant(activityVariant);
    setEntity(entity);
  }
  
  /**
   * Form.onSave handler
   * @param {} formData 
   */
  const save = async(formData:any) => {
    /* API expects the following payload
    * payload: {
      *  activityId:<ObjectId>,
      *  regionVariantId:<ObjectId>,
      *  providerId:<ObjectId>,
      *  venue:<string>, // optional
      *  notes:<string>, // optional
      *  start: {   // optional
      *    title:<string>, // optional
      *    instructions:<string>, // optional
      *    coordinates: {
      *      lat:<number>,
      *      lng:<number>
      *    }
      *  },
      *  end: { // optional
      *    title:<string>, // optional
      *    instructions:<string>, // optional
      *    coordinates: {
      *      lat:<number>,
      *      lng:<number>
      *    }
      *  }
      * }
    */

    let newLocation = {};
    _.merge(newLocation, entity)
    _.merge(newLocation, formData.changeset);

    await Api.post('providers', 'addUpdateLocation', newLocation);

    if(onSave) {
      onSave();
    }

  }
  
  // render null if no entity
  if(_.isNil(entity)) {
    return null
  }

  // pre-render: venue is displayed as text only if it is an update
  let venue = null;
  if(location._id) {
    venue = <Value label="Austragungsort">{location.venue || "-"}</Value>
  }
  else {
    venue = <TextInput label="Austragungsort" path="venue" placeholder="z.B. Igludorf Ost, Igludorf West" explanation="manche Anbieter haben mehrere Austragungsorte für eine Aktivität" validate={venueValidator(existingVenues)} />
  }
  
  // render
  return (
    <div id="v-provider-location">
      <Form entity={entity} onSave={save} onCancel={closeDialog} labelSave="speichern">
        <Value label="Anbieter">
          {_.get(provider, "name", "Unbekannter Anbieter")}
        </Value>
        <Value label="Aktivität">
          {`${_.get(activityVariant, "title", "Unbekannte Aktivität")} (${_.get(activityVariant, "regionCode", "??-''")})`}
        </Value>
        {venue}
        <Group label="Treffpunkt (hier führen wir die beschenkte Person hin)">
          <Coordinates label="Koordinaten" path="start.coordinates" pathLat="lat" pathLng="lng" required={true} />
          <TextInput label="Adresse / Bezeichnung" path="start.title" placeholder="z.B. Haupteingang Bernapark" />
          <TextArea label="Anweisungen" path="start.instructions" placeholder="z.B. nimm den Lift in den 3. Stock und klopfe dort an die rote Tür" />
        </Group>
        <Group label="Endpunkt (falls abweichend vom Treffpunkt, z.B. Canyoning)">
          <Coordinates label="Koordinaten" path="end.coordinates" pathLat="lat" pathLng="lng" />
          <TextInput label="Adresse / Bezeichnung" path="end.title" placeholder="z.B. Haupteingang Bernapark" />
          <TextArea label="Anweisungen" path="end.instructions" placeholder="z.B. nimm den Lift in den 3. Stock und klopfe dort an die rote Tür" />
        </Group>
        <TextArea path="notes" label="Notizen" />
      </Form>
    </div>
  )
}

function venueValidator(existingVenues:any[]) {
  let existing = existingVenues.map(v => (v || "").trim().toLowerCase());
  
  return (v:any) => {
    let i = existing.findIndex(e => v.trim().toLowerCase() === e)
    if(i === -1) {
      return new Validation(true);
    }
    else {
      return new Validation(false, "Dieser Austragungsort wurde bereits erfasst");
    }
    
  }
}
export default ProviderLocation