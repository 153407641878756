import React, { useEffect, useState } from "react";
import * as TS from "../../../../types";
import * as DA from "../../../../types/DataAccess";

type InfoProps = {
  provider: DA.Provider,
  activityId: string,
  regionVariantId: string,
}
export default function Info({provider, activityId, regionVariantId}: InfoProps) {
  // state
  const [lines, setLines] = useState<string[]>([]);
  // mount
  useEffect(() => {
    const load = async() => {
      const assignmentNotes = await DA.ProviderRepository.fetchAssignmentNotes(provider, activityId, regionVariantId);
      const bookingInfo = provider.booking[0];
      const lines: string[] = [];
      (bookingInfo.notes || "").split("\n").forEach(s => lines.push(s));
      (provider.notes).split("\n").forEach(s => lines.push(s));
      (assignmentNotes || "").split("\n").forEach(s => lines.push(s));
      const linesCleaned = lines.filter(s => s.length > 0);
      setLines(linesCleaned);
    }
    load();
  }, [provider]);

  // render
  if(lines.length > 0) {
    return (
      <div className="booking-info" dangerouslySetInnerHTML={{__html:lines.join('<br/>')}} />
    )
  }
  else {
    return null
  }
}