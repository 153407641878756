import React, {useState} from "react"
import moment from "moment"
import {Api} from "./../../../util";
import {Alert, Button, ConfirmButton } from "../../controls"

function PublishCatalogue({requestConfirmation}) {
  const [busy, setBusy] = useState(false)
  const [message, setMessage] = useState("")

  const publish = async(v) => {
    setBusy(true)
    // empty the cache
    let result = await Api.delete(`shop3/cache`)
    if(result.success) {
      // force building of cache by loading the catalogue
      await Api.get(`shop3/data`)
      setMessage(`Änderungen wurden publiziert(${moment().format("h:mm:ss")})`)
    }
    else {
      setMessage(`Ein Fehler ist aufgetreten: ${result.error}`)
    }
    setBusy(false)
  }

  // render
  let button = null
  if(requestConfirmation) {
    button = (
      <ConfirmButton busy={busy} onConfirm={publish}>Shop Zwischenspeicher leeren</ConfirmButton>
    )
  }
  else {
    button = (
      <Button busy={busy} onClick={() => {publish()}}>Shop Zwischenspeicher leeren</Button>
    )
  }

  return <>
    <Alert intent="info" size="medium" title="Shop Zwischenspeicher leeren">
      <div>
        <div>Um Sicherzustellen, dass Änderungen im Shop sichtbar sind, muss der Zwischenspeicher des Shop geleert werden.</div>
        <br />
        <div>Achtung: den Zwischenspeicher nicht zu leeren, ist <b>KEINE</b> Garantie dafür, dass Änderungen nicht publiziert werden (ein anderer Benutzer oder das System kann jederzeit den Zwischenspeicher leeren).</div>
      </div>
      <br />
      <div>
        {button}
        <span>&nbsp;{message}</span>
      </div>
    </Alert>
  </>
}

export default PublishCatalogue