// IMPORTANT: this is a WIP ... there are elements missing in the Adventure type
import { Result } from "../Result";
import Util from "../../util/util";
import Config from "../../config";
import * as Api from "../../util/ApiTS";
import * as BL from "../BusinessLogic";
import * as DA from "../DataAccess";
import { AdventureOrder, AdventureRating, AdventureStatus, AdventureStep, AdventureLog, AdventureCancelReason, AdventureCancelReasons } from ".";
import { AdventureActivity, Mail, MailSendResult } from "../.";
import _ from "lodash";

export class AdventureUserStartLocation {
  Lat: number = 0;
  Lng: number = 0;
  City: string|null = null;
  Country: string|null = null;

  public static fromDb(obj:any) : AdventureUserStartLocation|null {
    if(!obj) {
      return null;
    }
    const l = new AdventureUserStartLocation();
    l.Lat = Number(obj.Lat);
    l.Lng = Number(obj.Lng);
    l.City = obj.City || null;
    l.Country = obj.Country || null;
    return l;
  }

  public toString() {
    return `${this.Lat}, ${this.Lng}`;
  }
}

export class Adventure {
  public static ApiEndpoint = "adventures";

  // fields
  _id: string|undefined;
  Activities: AdventureActivity[] = [];
  Budget: number = 0;
  Operator: string|null = null;
  Price: number = 0;
  PreferredModeOfTransport: "public"|"private"|"none"|null = null; // null: data unavaible, "none": user does not care
  Rating: AdventureRating|null = null;
  Status: AdventureStatus = AdventureStatus.Archived;
  StartTime: Date|null = null; // actual start date and time
  ShippingDate: Date|null = null; // the date the adventure was shipped
  UserStartTime: Date|null = null; // start date and timeselected by the user
  ScheduleDate: Date|null = null; // the date the adventure was scheduled by the customer
  RecieverName: string|null = null;
  RecieverEmail: string|null = null;
  ReserverName: string|null = null;
  RecieverRealFirstName: string|null = null; // the real first name of the reciever (given by themselves when booking)
  ReserverEmail: string|null = null;
  TakeIntoAccount: {
    Anxieties: string[],
    Allergies: string[]
  };
  Steps: AdventureStep[] = [];

  UserStartLocationText: string|null = null;
  UserStartLocation: AdventureUserStartLocation|null = null;

  Order: AdventureOrder = new AdventureOrder();


  constructor() {
    this.TakeIntoAccount = {
      Allergies:[], Anxieties: []
    }
  }

  public static fromDb(obj:any) : Adventure {
    // TODO this is incomplete
    console.warn("INCOMPLETE IMPLEMENTATION");

    const a = new Adventure();
    
    a._id = obj._id;
    a.Budget = Number(obj.Budget);
    a.Operator = obj.Operator || null;
    a.Price = Number(obj.Price);
    a.Rating = AdventureRating.fromDb(obj.Rating);
    a.Status = obj.Status;
    a.Activities = (obj.Activities || []).map((aa:any) => AdventureActivity.fromDb(aa));
    a.ScheduleDate = obj.ScheduleDate ? new Date(obj.ScheduleDate) : null;
    a.StartTime = obj.StartTime ? new Date(obj.StartTime) : null;
    a.UserStartTime = obj.UserStartTime ? new Date(obj.UserStartTime) : null;
    a.UserStartLocationText = obj.UserStartLocationText || null;
    a.UserStartLocation = AdventureUserStartLocation.fromDb(obj.UserStartLocation);
    a.RecieverName = obj.RecieverName;
    a.RecieverEmail = obj.RecieverEmail;
    a.ReserverName = obj.ReserverName;
    a.ReserverEmail = obj.ReserverEmail;
    a.Order = AdventureOrder.fromDb(obj.Order);
    a.ShippingDate = obj.ShippingDate ? new Date(obj.ShippingDate) : null;
    a.Steps = (obj.Steps || []).map((s:any) => s as AdventureStep); // TODO is this sufficient?
    if(obj.TakeIntoAccount) {
      a.TakeIntoAccount.Allergies = (obj.TakeIntoAccount.Allergies || []).map((s:any) => String(s));
      a.TakeIntoAccount.Anxieties = (obj.TakeIntoAccount.Anxieties || []).map((s:any) => String(s));
    }
    if(obj.PreferredModeOfTransport && ["private","public","none"].includes(obj.PreferredModeOfTransport)) {
      a.PreferredModeOfTransport = obj.PreferredModeOfTransport;
    }
    return a;
  }

  /*
  public toDb(): any {
    // TODO this is incomplete
    console.warn("INCOMPLETE IMPLEMENTATION");
    
    return {

    }
  }
  */

  static async search(filter:any): Promise<Adventure[]> {
    const projection = {};
    const result = await Api.post("adventures", "search", {filter, projection});
    if(result.success) {
      const adventures: Adventure[] = result.data.items.map((obj:any) => {
        return Adventure.fromDb(obj);
      })
      return adventures;
    }
    else {
      console.error(result.error);
      return [];
    }
  }

  static async load(id:string): Promise<Adventure|null> {
    const adventures = await Adventure.search({_id: id});
    return adventures[0] || null;
  }

  public static getAdventureLink(adventureId: string, mode?:"admin"|"test"): string {
    const baseUrl = Config.surprise.baseUrlSurprise;
    return `${baseUrl}/${adventureId}${mode ? `?mode=${mode}` : ""}`;
  }

  public static getStatusName(status: AdventureStatus) {
    switch(status) {
      case AdventureStatus.Archived:
        return "Archiviert";
      case AdventureStatus.BuddySelected:
        return "Buddy gewählt";
      case AdventureStatus.ConditionsAccepted:
        return "Bedingungen akzeptiert";
      case AdventureStatus.ConditionsSent:
        return "Bedingungen verschickt";
      case AdventureStatus.DateSelected:
        return "Termin gewählt";
      case AdventureStatus.Finished:
        return "Durchgeführt";
      case AdventureStatus.Locked:
        return "Gesperrt";
      case AdventureStatus.OrderCancelled:
        return "Bestellung abgebrochen";
      case AdventureStatus.Ordered:
        return "Bestellt";
      case AdventureStatus.Prepaid:
        return "Wertgutschein";
      case AdventureStatus.Ready:
        return "Bereit";
      case AdventureStatus.Reviewed:
        return "Durchgeführt und bewertet"
      default:
        return "Unbekannter Status"
    }
  }

  public get shortId():string {
    if(this._id) {
      return this._id.substring(this._id.length - 4);
    }
    else {
      return "????";
    }
  }
  public static getShortId(id: String, withBrackets?:boolean) {
    const shortId = id.length > 4 ? id.substr(-4) : "????";
    return withBrackets ? `[${shortId}]` : shortId;
  }

  // TODO move this to TS.Adventure
  // TODO once we only use this class (and not logic/surprise), transform this into a non-static function
  public static getTitle(surprise:any) {
    if(surprise.IsMe) {
      return `Appentura.me für ${surprise.RecieverName}`;
    }
    else {
      return `Überraschung von ${surprise.ReserverName} für ${surprise.RecieverName}`;
    }
  } 

  // updates an adventure
  static async update(id:string, changeset:any) : Promise<Adventure> {
    const apiResult = await Api.post(this.ApiEndpoint, "update", {id:id, set:changeset});
    return Adventure.fromDb(apiResult.data.item);
  }

  // adds a log entry
  static async addLogEntry(id:string, entry:AdventureLog) : Promise<Adventure> {
    const payload = {adventureId:id, entry: entry.toDb()};
    await Api.post("adventures", "upsertLog", payload);
    // TODO is it really necessary to reload and return the adventure?
    const updatedAdventure = await Adventure.load(id);
    return updatedAdventure!;
  }

  /**
   * Cancels an adventure and informs customer and provider
   * -> https://app.clickup.com/4757339/v/dc/4h5uv-5541/4h5uv-2467
   * @param adventureId 
   */
  public static async cancelAventure(adventureId: string, reason: AdventureCancelReason, note: string, deleteSteps: boolean): Promise<{success:boolean, error?:string, createdTasks:DA.AdventureTask[]}> {
    // result
    const result: {success:boolean, error?:string, createdTasks:DA.AdventureTask[]} = {success: true, createdTasks: [] as DA.AdventureTask[]};

    // load adventure
    const adventure = await Adventure.load(adventureId);
    if(adventure === null) {
      result.success = false;
      result.error = "Adventure not found";
      return result;
    }

    // get operator(s)
    const currentOperator = await BL.User.getCurrent();
    let adventureOperator: DA.User|null = null;
    if(adventure.Operator) {
      adventureOperator = await DA.UserRepository.findByEmail(adventure.Operator)
    }
    if(!adventureOperator) {
      adventureOperator = currentOperator;
    }
    
    // changes to adventure
    const update:any = {
      Status: AdventureStatus.Ordered,
      OldStatus: adventure.Status,
      CurrentStep: 0,
      MaxStep: 0,
      ScheduleDate: null,
      UserStartTime: null,
      StartTime: null,
      EndTime: null,
      SentStartMail: false,
      SentStartAlert: false,
      SentStartFirebase: false,
      SentAcceptReminderMailOn: null,
      Meteochecks: [] 
    }
    // delete steps?
    if(deleteSteps) {
      update.Steps = [];
    }
    else {
      // if we do not delete steps (and if there are steps), we add a tasks asking to double check existing steps
      if(adventure.Steps.length > 0) {
        const checkStepsTasks = DA.AdventureTaskRepository.make();
        checkStepsTasks.adventureId = adventureId;
        checkStepsTasks.code = "cancel-booking";
        checkStepsTasks.assignedTo = currentOperator.email;
        checkStepsTasks.title = "Schritte nach Absage überprüfen";
        checkStepsTasks.description = "Die Überraschung wurde abgesagt, die Schritte aber beibehalten. Sicherstellen, dass sie noch Sinn ergeben.";
        checkStepsTasks.createdBy = currentOperator.email;
        checkStepsTasks.assignedTo = adventureOperator.email;
        checkStepsTasks.dateDue = new Date();
        await DA.AdventureTaskRepository.create(checkStepsTasks);
        result.createdTasks.push(checkStepsTasks);
      }
    }
    // update adventure
    await Adventure.update(adventureId, update);


    // TODO was geschah bisher mit Meteochecks: 
    // - gelöscht? im admin steht "zurückgesetzt"
    // - in logic/surprise resetMeteochecks() werden sie zurückgesetzt (angekickt im SurpriseContext) ... ist das notwendig? wo und wann werden sie generiert?
    // 

    // add entry in adventure log
    const reasonObject = AdventureCancelReasons.find(r => r.reason === reason)!;
    const reasonNote = note.trim() !== "" ? ` (${note})` : ""
    const reasonText = reasonObject.label + reasonNote;
    const logEntry = new AdventureLog();
    logEntry.CreateDate = new Date();
    logEntry.Operator = currentOperator.email;
    logEntry.ShowWarning = false;
    logEntry.Text = `Verschoben. Grund: ${reasonText}. Vorheriges Buchungsdatum: ${Util.printDateAndTime(adventure.ScheduleDate)}. Vorherige gewählte Startzeit: ${Util.printDateAndTime(adventure.UserStartTime)}. Vorherige Startzeit: ${Util.printDateAndTime(adventure.StartTime)}. Vorheriger Startort: ${adventure.UserStartLocationText}`;
    logEntry.Type = "cancelled";
    await Adventure.addLogEntry(adventureId, logEntry);

    // inform customer (send mail and create task)
    let cancelMailSent = false;
    let cancelMailSendResult: MailSendResult = {success:false};
    const cancelMail = await Mail.cancellationToCustomer(adventure, reason, note);
    if(cancelMail) {
      cancelMailSendResult = await cancelMail.send();
      cancelMailSent = true;
    }
    // create corresponding task
    const informCustomerTask = DA.AdventureTaskRepository.make();
    informCustomerTask.adventureId = adventureId;
    informCustomerTask.code = "cancel-booking";
    informCustomerTask.assignedTo = currentOperator.email;
    informCustomerTask.title = "Verschiebungs E-Mail an Kunden";
    informCustomerTask.description = `Kunde über Absage informieren. Grund der Absage: ${reasonText}`;
    informCustomerTask.createdBy = currentOperator.email;
    informCustomerTask.assignedTo = adventureOperator.email;
    informCustomerTask.dateDue = new Date();
    if(cancelMailSent) {
      informCustomerTask.dateCompleted = new Date();
      if(cancelMailSendResult.success && cancelMailSendResult.data && cancelMailSendResult.data.mail_id) {
        informCustomerTask.relatedMailId = cancelMailSendResult.data.mail_id;
      }
    }
    
    // TODO add property relatedMailId to AdventureTask
    await DA.AdventureTaskRepository.create(informCustomerTask);
    result.createdTasks.push(informCustomerTask);

    // collect all relevant booking requests (those that were accepted)
    const allBookingRequest = await DA.BookingRequestRepository.findByAdventure(adventureId);
    const bookingRequests: DA.BookingRequest[] = [];
    for(const bookingRequest of allBookingRequest) {
      if(bookingRequest.response && bookingRequest.response.accepted) {
        bookingRequests.push(bookingRequest);
      }
    }

    // cancel booking requests, send mails and create tasks
    for(const bookingRequest of bookingRequests) {
      // cancel booking request
      const bookingRequestResponse: DA.BookingRequestResponse = {
        accepted: false,
        comment: `automatisch abgelehnt wegen Absage. Grund: ${reasonText}`,
        receivedOn: new Date()
      }
      await DA.BookingRequestRepository.update(bookingRequest._id!, {response: bookingRequestResponse});

      // send mail if possible
      let mailSent = false;
      let mailSendResult: MailSendResult = {success: false}
      const provider = await DA.ProviderRepository.findById(bookingRequest.provider.id);
      if(provider && BL.Provider.getBookingKind(provider) === "email") {
        mailSent = true;
        const mail = await Mail.cancellationToProvider(adventure, bookingRequest, reason, note);
        if(mail) {
          mailSendResult = await mail.send();
        }
        else {
          mailSent = false;
        }
      }

      // create task
      const task = DA.AdventureTaskRepository.make();
      task.adventureId = adventureId;
      task.code = "cancel-booking";
      task.assignedTo = currentOperator.email;
      task.title = `Anbieter (${provider ? provider.name : "???"}) über Absage informieren`;
      task.description = `Anbieter (${provider ? provider.name : "???"}) über Absage informieren. Grund der Absage: ${reason}`;
      task.createdBy = currentOperator.email;
      task.assignedTo = adventureOperator.email;
      task.relatedEntityId = bookingRequest._id!;
      task.relatedEntityType = "BookingRequest";
      task.dateDue = new Date();
      if(mailSent) {
        task.dateCompleted = new Date();
        if(mailSendResult.success && mailSendResult.data && mailSendResult.data.mail_id) {
          task.relatedMailId = mailSendResult.data.mail_id;
        }
      }
      await DA.AdventureTaskRepository.create(task);
      result.createdTasks.push(task);
    }

    // TODO send message in slack
    // - informing operators that adventure was cancelled

    // done
    return result
  }
}
