import React, { useEffect, useState } from "react";
import Util from "./../../../util/util";
import * as TS from "../../../types";
import * as BL from "../../../types/BusinessLogic";
// components
import {Icon, Icons, Link, Loader, Table, Td, Tr} from "../../controls";
import DashboardPanel from "./DashboardPanel"
// styling & images
import iconWarning from './../../../assets/images/icon_warning.svg'

export default function Warnings() {
  // state
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [warnings, setWarnings] = useState<BL.DashboardWarning[]>([]);

  // mount
  useEffect(() => {
    load();
  }, []);
  
  // loads data
  const load = async() => {
    const warnings = await BL.Dashboard.getWarnings();
    setWarnings(warnings);
    setIsLoading(false);
  }

  // pre-render
  let content = null;
  if(isLoading) {
    content = <Loader text="lade Warnungen ..." />
  }
  else { 
    let rows = warnings.sort((a, b) => { return (a.startTime || new Date()) > (b.startTime || new Date()) ? 1 : -1}).map((warning, index) => {
      let issues = warning.issues.map((i:any, index:number) => {
        return <p key={index}>{i}</p>
      })
      return (
        <Tr key={index}>
          <Td>{warning.hasPriority ? <Icon icon={Icons.Crown} /> : null}</Td>
          <Td style={{whiteSpace:"nowrap"}}>{Util.printDateAndTimeWithoutYear(warning.startTime)}</Td>
          <Td>{warning.operator ? warning.operator.firstName : "nicht zugewiesen"}</Td>
          <Td>{TS.Adventure.getShortId(warning.surprise_id || "", true)}</Td>
          <Td><Link to={`surprise/${warning.surprise_id}`}>{`${warning.receiverName} von ${warning.reserverName}`}</Link></Td>
          <Td>{issues}</Td>
        </Tr>
      )
    })
    content = <Table>{rows}</Table>
  }

  // render
  return (
    <DashboardPanel title="Warnungen" icon={iconWarning} iconRounded={false} smallOnDesktop={false} smallOnMobile={false}>
      {content}
    </DashboardPanel>
  )
   
}