import { PriceListMapper } from "./PriceList.Mapper";
import { PriceList, PriceListCurrency } from "./PriceList";
import { RepositoryFactory } from "../_Common/RepositoryFactory";


export class PriceListRepository {
  private static mapper = new PriceListMapper();
  
  // from factory
  static search = RepositoryFactory.search<PriceList, PriceListMapper>(this.mapper);
  static create = RepositoryFactory.create<PriceList, PriceListMapper>(this.mapper);
  static update = RepositoryFactory.update<PriceList, PriceListMapper>(this.mapper);
  static remove = RepositoryFactory.remove<PriceList, PriceListMapper>(this.mapper);
  static findById = RepositoryFactory.findById<PriceList, PriceListMapper>(this.mapper);

  /**
   * Creates a new PriceList object
   * @param provider_id 
   * @param activity_id 
   * @param currencyCode 
   * @param regionCode 
   * @returns 
   */
  static make(provider_id:string, activity_id:string, currencyCode: PriceListCurrency, regionCode: string|null): PriceList {
    return {
      _id: undefined,
      provider_id,
      activity_id,
      currencyCode,
      regionCode,
      prices: []
    }
  }

  /**
   * Returns all price lists
   * @returns {Promise<PriceList[]>}
   */
  static async findAll(): Promise<PriceList[]> {
    const priceLists = await PriceListRepository.search({});
    return priceLists; //.sort((a,b) => a.provider_id.localeCompare(b.provider_id));
  }

}

