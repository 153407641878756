import { UserMapper } from "./User.Mapper";
import { User } from "./User";
import { RepositoryFactory } from "../_Common/RepositoryFactory";


export class UserRepository {
  // mapper
  private static mapper = new UserMapper();
  
  // factory methods
  static make = RepositoryFactory.make<User, UserMapper>(this.mapper);
  static search = RepositoryFactory.search<User, UserMapper>(this.mapper);
  static create = RepositoryFactory.create<User, UserMapper>(this.mapper);
  static update = RepositoryFactory.update<User, UserMapper>(this.mapper);
  static findById = RepositoryFactory.findById<User, UserMapper>(this.mapper);

  // additional
  static async findAll(): Promise<User[]> {
    return UserRepository.search({});
  }
  static async findByEmail(email:string): Promise<User|null> {
    const searchResult = await UserRepository.search({email:email.toLowerCase()});
    return searchResult[0] || null; 
  }
}

