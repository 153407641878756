
import _ from "lodash";
import moment from "moment";
import Api from "../../util/api";
import { AdventureStatus } from "..";

type Cache = {
  date:Date|null,
  data:any|null
}
const cache: Cache = {
  date:null,
  data:null
}

export type StatisticsRange = {
  from: Date,
  to: Date,
  caption: string
}

export type StatisticsData = {
  meta: any,
  surprises: any,
  activities: any,
  timestamp: Date,
  yearMin: number,
  yearMax: number
}

export class Statistics {
  /**
   * Updates data on the server. 
   * @param forceRefresh If forceRefresh is true, ALL data will be purged and rebuilt. Use with caution. This is a work-heavy operation
   */
  static async update(forceRefresh:boolean):Promise<{}> {
    await Api.get(`statistics/update${forceRefresh ? '?purge':''}`)
    return {}
  }

  /**
   * Loads statistical data from server
   */
  static async load(forceRefresh:boolean): Promise<StatisticsData> {
    const data: StatisticsData = {} as StatisticsData;

    // load from cache
    if(!forceRefresh && cache.data && cache.date && moment().isSame(cache.date, 'day')) {
      return cache.data
    }

    // get data from api
    data.meta = await Api.get('meta');
    data.surprises = await Api.get('statistics/surprises2');
    data.activities = await Api.get('statistics/activities');

    // set timestamp
    data.timestamp = data.meta.statisticsUpdatedOn || new Date();

    // get min and max year and prepare data
    let yearMin = 9999
    let yearMax = 0
    if(data.surprises) {
      data.surprises.forEach((s:any) => {
        if(s.dateSold) {
          let year = new Date(s.dateSold).getFullYear();
          yearMin = year < yearMin ? year : yearMin;
          yearMax = year > yearMax ? year : yearMax;
        }
      })
    }
    data.yearMin = yearMin
    data.yearMax = yearMax

    // cache
    cache.date = new Date()
    cache.data = data

    return data
  }

  static calculateSurprises(data:any, dateFrom:Date, dateTo:Date): any {
    // TODO optimization ... we iterate through all data several times (data.forEach)
    // filter by date range
    const surprises = data.surprises || [];
  
    // surprises sold within the time range
    let data_sold = surprises.filter((s:any) => {
      return (new Date(s.dateSold) >= new Date(dateFrom)) && (new Date(s.dateSold) < new Date(dateTo));
    })
  
    // surprises executed within the time range
    let data_executed = surprises
      .filter((s:any) => {
        return s.dateExecution
      })
      .filter((s:any) => {
        return (new Date(s.dateExecution) >= new Date(dateFrom)) && (new Date(s.dateExecution) < new Date(dateTo)) && s.status >= AdventureStatus.Finished && s.status <= AdventureStatus.Reviewed;
      })
    let itemsExecuted = data_executed.length
  
    // number of people that participated
    let participants = data_executed.reduce((accumulator:number, currentValue:any) => {
      if(currentValue.dateExecution) {
        return accumulator + (currentValue.persons || 1)
      }
      else {
        return accumulator
      }
    }, 0)
  
    // sales value
    let totalValue = data_sold.reduce((accumulator:number, currentValue:any) => {
      return accumulator + currentValue.money_in
    }, 0)
  
    // number of sold items
    let itemsSold = data_sold.length
  
    // number of items sold AND executed
    let itemsSoldAndExecuted = data_sold.reduce((accumulator:number, currentValue:any) => {
      if(currentValue.dateExecution) {
        return accumulator + 1
      }
      else {
        return accumulator
      }
    }, 0)
  
    // Ratings
    const getRating = (field:any) => {
      let rating = data_executed
        .filter((item:any) => item[field] !== null)
        .reduce((accumulator:any, current:any) => {
          accumulator.total += current[field];
          accumulator.count += 1;
          return accumulator;
        }, {total:0, count:0})
      
      let ratingAverage = rating.count > 0 ? rating.total / rating.count : 0;
      let ratingCount = rating.count > 0 ? rating.count : 0;
  
      return {
        average:ratingAverage,
        count:ratingCount
      }
    }
    const ratings:any = {};
    // overall rating
    const ratingOverall = getRating('rating_overall');
    const ratingAverage = ratingOverall.average;
    const ratingCount = ratingOverall.count;
    ratings.overall = ratingOverall;
    // organization & providers rating
    ratings.organization = getRating('rating_organization');
    ratings.providers = getRating('rating_providers');
  
  
    // items sold per time of day
    const time_of_day = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    data_sold
      .filter((item:any) => !item.soldOffline)
      .filter((item:any) => (item.money_in || 0) > 0) // some items have no value (e.g. Shoppy Promo 2018-11-24)
      .forEach((item:any) => {
        time_of_day[item.dateSold_hour] += 1
      })
    let sales_per_time_of_day = time_of_day.map((item, index) => {
      let absolute = item
      let percent = data_sold.length > 0 ? item / (data_sold.length / 100) : 0
      return { hour: index, label:`${index}:00`, absolute, percent }
    })
  
    // items sold per weekday and day of month (we exclude items that were sold offline)
    let week_day = new Array(7).fill(0); // index 0 = Sunday
    let month_day = new Array(31).fill(0); // index 0 = 1st of month
    let day_names = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];
    data_sold
      .filter((item:any) => !item.soldOffline)
      .filter((item:any) => (item.money_in || 0) > 0) // some items have no value (e.g. Shoppy Promo 2018-11-24)
      .forEach((item:any) => {
        // weekday (note:we get the Sunday as 0, but we want the week to start on Monday)
        let weekday = item.dateSold_weekday === 0 ? 6 : item.dateSold_weekday - 1
        week_day[weekday] += 1
  
        // day of the month
        let day_index = new Date(item.dateSold).getDate() - 1
        month_day[day_index] += 1 // on the server we do not pre-calculate this value
      })
    let sales_per_weekday = week_day.map((item, index) => {
      let absolute = item
      let percent = data_sold.length > 0 ? item / (data_sold.length / 100) : 0
      return { weekday: index, label:day_names[index], absolute, percent}
    })
    let sales_per_monthday = month_day.map((item, index) => {
      let absolute = item
      let percent = data_sold.length > 0 ? item / (data_sold.length / 100) : 0
      return { monthday: index, label:index + 1, absolute, percent}
    })
  
    // receivers turned into buyers ... Anzahl Kunden, die beschenkt wurden und (danach) selber schenkten
    // TODO the aspect "danach" is currently ignored
    const set_receivers = new Set();
    const set_buyers = new Set();
    data_sold.forEach((item:any) => {
      if(item.email_buyer) {
        set_buyers.add(item.email_buyer.toLowerCase().trim());
      }
      if(item.email_receiver) {
        set_receivers.add(item.email_receiver.toLowerCase().trim());
      }
    })
    const buyer_receiver_intersection = _.intersection(Array.from(set_receivers), Array.from(set_buyers))
    const receivers_turned_into_buyers = buyer_receiver_intersection.length
  
    // repeat buyers
    const buyers:any = {}
    data_sold.forEach((item:any) => {
      if(item.email_buyer) {
        const count = buyers[item.email_buyer.toLowerCase().trim()] || 0;
        buyers[item.email_buyer.toLowerCase().trim()] = count + 1;
      }
    })
    let repeat_buyers = 0;
    for(let key in buyers) {
      if(buyers.hasOwnProperty(key)) {
        const count = buyers[key];
        if(count > 1) {
          repeat_buyers += 1
        }
      }
    }
  
    // regions
    const regionCounts:any = {};
    let countRegion = (region:any) => {
      if(region === null) {
        region = "Unbekannt";
      }
      region = region.replace(/Region/g, '');
      if(regionCounts[region]) {
        regionCounts[region] += 1;
      }
      else {
        regionCounts[region] = 1;
      }
    }
  
    data_sold.forEach((item:any) => {
      countRegion(item.region);
    })
  
    const regions:any[] = [];
    const regions_other:any = {name:"andere", color:Statistics._stringToColour("andere"), absolute:0, percent:0};
    for(let key in regionCounts) {
      if(regionCounts.hasOwnProperty(key)) {
        let absolute = regionCounts[key];
        let percent = data_sold.length > 0 ? absolute / (data_sold.length / 100) : 0;
        if(percent > 2) {
          regions.push({
            name:key,
            color:Statistics._stringToColour(key),
            absolute,
            percent:Math.floor(percent * 10) / 10 // for some reason toFixed() breaks things here
          })
        }
        else {
          regions_other.absolute += absolute
          regions_other.percent += percent
        }
      }
    }
    if(regions_other.absolute > 0) {
      regions_other.percent = regions_other.percent.toFixed(1)
      regions.push(regions_other)
    }
  
    // item between sale and execution
    let time_between_total = 0
    data_executed.forEach((s:any) => {
      let exec = moment(s.dateExecution)
      let sold = moment(s.dateSold)
      let duration = moment.duration(exec.diff(sold))
      time_between_total += duration.asDays()
    })
    let daysToExecution = data_executed.length > 0 ? time_between_total / data_executed.length : 0
  
    // done
    return {
      totalValue,
      itemsSold,
      itemsSoldAndExecuted,
      itemsExecuted,
      participants,
      ratings,
      ratingAverage,  // TODO use ratings.overall.average instead
      ratingCount,  // TODO use ratings.overall.count instead
      daysToExecution,
      sales : {
        per : {
          time: sales_per_time_of_day,
          weekday: sales_per_weekday,
          monthday: sales_per_monthday
        }
      },
      regions,
      //persons,
      receivers_turned_into_buyers,
      repeat_buyers
    }
  }
  
  static calculateActivities(allData:any, dateFrom: Date, dateTo: Date): any {
    // filter by date range
    let activities = allData.activities || []
  
    let data = activities.filter((a:any) => {
      return (new Date(a.date_sold) >= new Date(dateFrom)) && (new Date(a.date_sold) < new Date(dateTo));
    })
  
    // ranking
    const ranking_data:any[] = [];
    data.forEach((a:any) => {
      let activity = ranking_data.find(item => item.id.toString() === a.activity_id.toString());
      if(!activity) {
        activity = {
          id:a.activity_id,
          title:a.title,
          times_sold:1
        }
        ranking_data.push(activity);
      }
      else {
        activity.title = a.title;
        activity.times_sold += 1;
      }
    })
    const ranking = ranking_data
      .sort((a, b) => a.times_sold > b.times_sold ? -1 : 1)
      .map((a, index) => {
        return { ranking:index, caption: a.title, value:a.times_sold}
      })
  
    return {
      ranking
    }
  }
  

  private static _stringToColour(str:string): string {
    str = (str || '').toLowerCase().trim();
    const region_colors:any = {
      "bern":"#ca1126",
      "zürich":"#152d59",
      "basel":"#BD830E",
      "luzern":"#00A8DF",
      "st. gallen":"#00A858"
    }
    if(region_colors[str]) {
      return region_colors[str];
    }
    else {
      let hash = 0
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
      }
      let colour = '#'
      for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
      }
      return colour;
    }
  }

  private static _getDate(year:number, month:number, day:number): Date {
    let s = `${year}-${_.padStart(month.toString(), 2, '0')}-${_.padStart(day.toString(), 2, '0')}T00:00:00.000Z`;
    let m = moment(s);
    return m.toDate();
  }

  static getTotalRange(): StatisticsRange {
    return {
      from: Statistics._getDate(1000, 1, 1),
      to: Statistics._getDate(3000, 1, 1),
      caption: "Total"
    }
  }

  static getYearRange(year:number): StatisticsRange {
    return {
      from: Statistics._getDate(year, 1, 1),
      to: Statistics._getDate(year + 1, 1, 1),
      caption:`${year}`
    }
  }

  static getMonthRange(year:number, month:number): StatisticsRange {
    const months = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
  
    let month_end = month + 1
    let year_end = year
    if(month_end > 12) {
      month_end = 1;
      year_end = year + 1;
    }
  
    return {
      from: Statistics._getDate(year, month, 1),
      to: Statistics._getDate(year_end, month_end, 1),
      caption:`${months[month-1]} ${year}`
    }
  }
  
  static getWeekRange(year:number, week:number): StatisticsRange {
    // in switzerland the week containing Jan 4th is the first calendar week of the year
    const jan4th = moment(Statistics._getDate(year, 1, 4));
    const jan4th_weekday = jan4th.day() === 0 ? 7 : jan4th.day();
    const kw1_start = jan4th.subtract(jan4th_weekday - 1, 'days');
  
    const week_start = kw1_start.add(week - 1, 'weeks');
    const week_end = moment(week_start).add(7, 'days');
  
    return {
      from: Statistics._getDate(week_start.year(), week_start.month() + 1, week_start.date()),
      to: Statistics._getDate(week_end.year(), week_end.month() + 1, week_end.date()),
      caption:`KW ${week}, ${year}`
    }
  }

}




















