import React, {useState} from "react"
import {Breadcrumb} from "../../controls"
import Resources from "./Resources"

function MailTemplates() {
  const [resources] = useState([
    {
      title:"Anbieteranfrage",
      description:"Reservationsanfragen an Gastrobetriebe und Anbietern von anderen Aktivitäten.",
      url:'https://docs.google.com/document/d/1hwKC6tt4s4wWQSr57L6SFrM5QgInIwhGmvn_ehNuifo'
    },
    {
      title:"Gastroanfrage bei Betrieben die uns kennen",
      description:"Mail-Vorlage zur Reservation bei Gastro-Betrieben, die bereits mehrmals mit uns zu tun hatten.",
      url:"https://docs.google.com/document/d/1dzr_T5R_EqGku2r1n-CrsIMk5KmiZ8LAI2MyabLuvrE/edit"
    },
    {
      title:"Zahlungsaufforderung Schenker",
      description:"Aufforderung an Schenker eine unbezahlte Überraschung zu bezahlen, für welche der Beschenkte bereits ein Termin gewählt hat.",
      url:"https://docs.google.com/document/d/1cdl5pxAGsn_uKe6rQnD3FnpjSaxReRjJaorjKGPNJ18"
    },
    {
      title:"Terminverschiebung",
      description:"Mail an Erlebnisteilnehmer mit der Mitteilung, dass die Überraschung verschoben werden muss und wie sie weiter vorgehen soll.",
      url:"https://docs.google.com/document/d/1_5RfsvVkfN5gpM42Y1dFEQq0CdmKwnjRBZJ5V92QZGY"
    },
    {
      title:"Startort unklar",
      description:"Mail an Beschenkten, falls ihr Startort ungenau angegeben wurde.",
      url:"https://docs.google.com/document/d/1cD8k-oSzKiXqeAfUragOoUgK-SUVFIU2iabfEcw0x7s"
    },
    {
      title:"Begleitperson mit Aufpreis",
      description:"Mail an Schenker mit Informationen zu den enstehenden Zusatzkosten und mit Zahlungsinformationen.",
      url:"https://docs.google.com/document/d/1n3Tczt-44VwThfRP9zTdTbrI0IPpJK4EXCSWYQmPM1A"
    },
    {
      title:"Begleitperson ohne Aufpreis",
      description:"Informationen falls keine Mehrkosten durch eine (zusätzliche) Begleitperson entstehen.",
      url:"https://docs.google.com/document/d/1pQarm7xhHEL68z4-CBh2-hh2jVoEcvb1zlvRBp-dgtI/edit"
    },
    {
      title:"Wetter unsicher",
      description:"Mail an Beschenkten, welches ihn mitteilt, dass das Wetter an seinem gewünschten Datum eventuell nicht ganz ideal ist - mit Anweisungen wie sie vorgehen soll, falls sie den Termin verschieben möchte.",
      url:"https://docs.google.com/document/d/1kgPqscqcOdmfqAEdzFIZ8zgqjqgZBofSBQEaKnw15gk"
    },
    {
      title:"Abklärungen laufen",
      description:"Wenn der Status seit längerem auf \"Termin gewählt\" ist und wir noch auf ein Anbieterfeedback warten",
      url:"https://docs.google.com/document/d/1xOtF1gtR-P0XNuKuhIWTaCIdFD5CGjeKzQvvdT9SCus/edit#"
    }
  ])

  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}]} location='Mailvorlagen' />
    <Resources title="Mail-Vorlagen" resources={resources}/>
  </>
  
}

export default MailTemplates

