
export type AdventureCancelReason = "other"|"weather"|"customer-illness"|"provider-full"|"provider-postponed"|"customer-postponed"|"customer-conditions-not-accepted"|"customer-not-paid"|"date-not-possible";
export const AdventureCancelReasons: {reason:AdventureCancelReason, needsNote:boolean, label:string}[] = [
  {reason:"other", label:"anderes", needsNote:true},
  {reason:"weather", label:"Wetter unsicher", needsNote:false},
  {reason:"customer-illness", label:"Krankheit Kunde", needsNote:false},
  {reason:"provider-full", label:"Anbieter ausgebucht", needsNote:false},
  {reason:"provider-postponed", label:"Verschiebung durch Anbieter", needsNote:false},
  {reason:"customer-postponed", label:"Verschiebung durch Kunden", needsNote:false},
  {reason:"customer-conditions-not-accepted", label:"Bedingungen nicht erfüllt", needsNote:false},
  {reason:"customer-not-paid", label:"Rechnung noch nicht bezahlt", needsNote:false},
  {reason:"date-not-possible", label:"Wochentag / Datum nicht möglich", needsNote:false}
];

