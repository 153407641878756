import React, { useContext, useEffect, useState } from "react";
import * as TS from "../../../../types";
import Util from "../../../../util/util";
import { Button, Row } from "../../../controls";
import { BookingRequestContext } from "./BookingRequest.Context";
import ServiceItem from "./BookingRequest.ServiceItem";

export default function ServiceItems() {
  // context
  const BRC = useContext(BookingRequestContext);

  // render
  const list = BRC.services.map(s => <ServiceItem service={s} key={Util.getKey()} />)
  return (
    <div className="services">
      <div className="title">Anfrage-Elemente / Dienstleistungen</div>
      <table>
        <tbody>
          {list}
        </tbody>
      </table>
      <Row align="center">
        <Button size="small" onClick={() => BRC.addServiceItem()}>Element hinzufügen</Button>
      </Row>
    </div>
  )
}