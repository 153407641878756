import _ from "lodash";
import React, {useContext, useState, useEffect} from "react";
import moment from "moment";
import * as TS from "../../../../types";
import * as BL from "../../../../types/BusinessLogic";
import Util from "../../../../util/util";
import Config from "../../../../config";
import { SurpriseContext } from "../Surprise.Context";
import { ConfirmButton, Card, Modal, Alert, Value, Icons, Link } from "../../../controls";
import GenericEdit from "../Generic.Edit";

import CSS from "./Dunning.View.module.scss";

type DunningProps = {
  expanded:boolean
}
export default function Dunning({expanded}: DunningProps) {
  // context 
  const SC = useContext(SurpriseContext);

  // state
  const [paymentInfo, setPaymentInfo] = useState<any>(null);
  const [mode, setMode] = useState<"editPaymentDate"|"view">("view");

  // mount
  useEffect(() => {
    setPaymentInfo(getPaymentInfo(SC.surprise));
  }, [SC.surprise])

  useEffect(() => {
    setPaymentInfo(getPaymentInfo(SC.surprise));
  }, [SC.surprise.PaymentDate])

  // user wants to edit payment date
  const startEditPaymentDate = () => setMode("editPaymentDate");
  // stops edit
  const stopEdit = () => setMode("view");

  // toggle dunning lock
  const toggleDunningBlock = async() => {
    const dunningBlock = SC.surprise.DunningBlock ? false : true;
    await SC.updateAtPath("DunningBlock", dunningBlock, true);
  }

  // pre-render
  if(_.isNil(paymentInfo)) {
    return null;
  };
  let summaryContent = [
    `Zahlungsart: ${paymentInfo.paymentTypeTitle}`,
    `Zahlungstatus: ${paymentInfo.paymentStateSummary}`,
    `Mahnstufe: ${paymentInfo.paymentReminderLevel}`,
    `Mahnsperre: ${SC.surprise.DunningBlock ? "ja":"nein"}`
  ];
  const dunningBlockButton = <ConfirmButton onConfirm={toggleDunningBlock}>{SC.surprise.DunningBlock ? "Mahnsperre aufheben":"Mahnsperre setzen"}</ConfirmButton>;
  let dunningBlockContent;
  if(SC.surprise.DunningBlock) {
    dunningBlockContent = <Alert intent="warning" title="Mahnsperre aktiv">
      Der Käufer dieser Überraschung soll nicht gemahnt werden.<br/>
      {dunningBlockButton}
    </Alert>
  }
  else {
    dunningBlockContent = <>
      <div>keine Mahnsperre</div>
      {dunningBlockButton}
    </>
  }
  // render
  return <>
    <Card title="Mahnwesen" icon={Icons.Invoice} collapsible={true} expanded={expanded} summaryContent={summaryContent}>
      <Value label="Zahlungsart">{paymentInfo.paymentTypeTitle}</Value>
      <Value label="Referenznummer">{paymentInfo.paymentReferenceNumber || "-"}</Value>
      <Value onClick={startEditPaymentDate} label="Zahlungsdatum">
        {_.isNil(SC.surprise.PaymentDate) ? "noch kein Zahlungsdatum" : Util.printDate(SC.surprise.PaymentDate)}
      </Value>
      <Value label="Zahlungsstatus">{paymentInfo.paymentStateSummary}</Value>
      <Value label="Mahnstufe(n)">{paymentInfo.paymentReminderSummary}</Value>
      <Value label="Mahnsperre">{dunningBlockContent}</Value>
      <ReminderAction />
      <LockingAction />
    </Card>
    <Modal isOpen={mode==="editPaymentDate"} title="Zahlungsdatum bearbeiten" onClose={stopEdit}>
      <GenericEdit path="PaymentDate" label="Zahlungsdatum" kind="dateinput" stopEdit={stopEdit} />
    </Modal>
  </>;
}

function ReminderAction() {
  // context 
  const SC = useContext(SurpriseContext);

  // state
  const [isSending, setIsSending] = useState<boolean>(false);
  const [info, setInfo] = useState<any>(null)

  useEffect(() => {
    setInfo(getPaymentInfo(SC.surprise));
  }, [SC.surprise]);

  // sends reminder
  const sendReminder = async() => {
    setIsSending(true);
    await TS.AdventureOld.updatePaymentReminderLevel(SC.surprise._id, info.paymentReminderLevel + 1);
    SC.load(SC.surprise._id); // TODO ... reloading the whole thing is suboptimal ... this all needs some refactoring ... all payment/dunning related things should be a subdocument or even a dedicated collection
    setIsSending(false);
  }

  // render
  if(_.isNil(info)) {
    return null;
  }
  let canSend = true;
  let textButton, textInfo;
  if(info.isOverdue) {
    switch (info.paymentReminderLevel) {
      case 0:
        canSend = true;
        textButton = '1. Mahnung per E-Mail verschicken';
        textInfo = `Ein E-Mail wird an die Adresse ${info.reserverEmail} verschickt und die Mahnstufe auf 1 gesetzt.`;
        break
      case 1:
        canSend = true;
        textButton = '2. Mahnung per E-Mail verschicken';
        textInfo = `Ein E-Mail wird an die Adresse ${info.reserverEmail} verschickt und die Mahnstufe auf 2 gesetzt.`;
        break
      case 2:
        canSend = true;
        textButton = '3. Mahnung per Brief verschicken';
        textInfo = `Ein Brief adressiert an '${info.reserverAddress}' wird erzeugt und die Mahnstufe auf 3 gesetzt.`;
        break
      case 3:
        canSend = false;
        textButton = '4. Mahnung per Brief verschicken';
        textInfo = `Ein Brief adressiert an '${info.reserverAddress}' wird erzeugt und die Mahnstufe auf 4 gesetzt.`;
        break
      default:
        canSend = false;
    }
  }

  if(info.isOverdue && canSend) {
    return (
      <Value label="Mahnung verschicken">
        <div>
          {textInfo}
        </div>
        <ConfirmButton onConfirm={sendReminder} busy={isSending}>{textButton}</ConfirmButton>
      </Value>
    )
  }
  else {
    return null
  }
}

function LockingAction() {
  // context
  const SC = useContext(SurpriseContext);

  // state
  const [lockingReason, setLockingReason] = useState("");
  
  // locks the surprise
  const lockSurprise = async() => {
    await execute(TS.AdventureStatus.Locked, "gesperrt");
  }

  // unlocks the surprise
  const unlockSurprise = async() => {
    await execute(SC.surprise.OldStatus, "entsperrt");
  }

  // executes locking / unlocking
  const execute = async(nextStatus:any, logType:any) => {
    const currentUser = await BL.User.getCurrent();
    const logEntry = { Type:logType, Text:lockingReason, Operator:currentUser.email};
    await SC.updateStatus(nextStatus, true, logEntry);
    setLockingReason("");
  }

  // prepare rendering
  const disabled = lockingReason.trim().length === 0 || SC.busy;
  let heading, reasonPlaceholder, button, infos; 
  if(SC.surprise.Status === TS.AdventureStatus.Locked) {
    heading = "Überraschung entsperren";
    reasonPlaceholder = "Grund der Entsperrung";
    const lockingLogs = (SC.log || [])
      .filter((log:any) => log.Type === 'gesperrt')
      .sort((a:any,b:any) => {
        return new Date(a.CreateDate) > new Date(b.CreateDate) ? -1 : 1
      })
    const lockingReason = (lockingLogs.length) > 0 ? (lockingLogs[0].Text || 'unbekannt') : 'unbekannt';
    button = <ConfirmButton onConfirm={unlockSurprise} disabled={disabled}>entsperren</ConfirmButton>
    infos = [
      `Diese Überraschung ist gegenwärtig gesperrt`,
      `Grund der Sperrung: ${lockingReason}`,
      `Entsperren setzt ihren Status zurück auf '${TS.Adventure.getStatusName(SC.surprise.OldStatus) || 'unbekannt'}'.`
    ]; 
  }
  else {
    heading = "Überraschung sperren";
    reasonPlaceholder = "Grund der Sperrung";
    button = <ConfirmButton onConfirm={lockSurprise} disabled={disabled}>sperren</ConfirmButton>
    infos = [
      "Wird eine Überraschung gesperrt, kann sie nicht mehr durchgeführt werden.",
      "Anders als archivierte Überraschungen zählen gesperrte Überraschungen zum Umsatz."
    ];
  }
  // render
  return <>
    <Value label={heading} className={CSS.locking}>
      <div className={CSS.info}>
        {infos.map((info, index) => { return <div key={index}>{info}</div>})}
      </div>
      <div className={CSS.action_1}>
        <textarea rows={3} value={lockingReason} onChange={e => setLockingReason(e.target.value)} placeholder={reasonPlaceholder} />
      </div>
      <div className={CSS.action_2}>
        {button}
      </div>
    </Value>
  </>
}

function getPaymentInfo(surprise:any) {
  // TODO move number of days to config
  let daysUntilOverdue = Config.misc.NumberOfDaysUntilOverdue;
  let dateDue = moment(surprise.CreateDate).add(daysUntilOverdue, 'days').toDate()
  //let createDate = surprise.CreateDate
  let paymentDate = surprise.PaymentDate
  let paymentType = surprise.PaymentType || 'stripe'
  let paymentTypeTitle = paymentType === 'transfer' ? 'Rechnung' : 'Kreditkarte'
  let paymentReferenceNumber = surprise.PaymentReferenceNumber || null;
  let paymentReminderLevel = surprise.PaymentReminderLevel || 0;
  let isOverdue = !paymentDate && dateDue < new Date() && paymentType === 'transfer';
  let paymentReminderDates = [];
  paymentReminderDates[1] = surprise.PaymentReminderSentDate;
  paymentReminderDates[2] = surprise.PaymentReminder2SentDate;
  paymentReminderDates[3] = surprise.PaymentReminder3SentDate;
  paymentReminderDates[4] = surprise.PaymentReminder4SentDate;

  // payment state summary
  let paymentStateSummary = ''
  if(paymentDate || paymentType === 'stripe') {
    if(paymentDate) {
      paymentStateSummary = `bezahlt am ${Util.printDate(paymentDate)}`
    }
    else {
      paymentStateSummary = `kein Zahlungsdatum. Zahlung via Kreditkarte wahrscheinlich abgebrochen`
    }
  }
  else {
    if(isOverdue) {
      paymentStateSummary = `überfällig seit ${Util.printDate(dateDue)}`
    }
    else {
      paymentStateSummary = `fällig am ${Util.printDate(dateDue)}`
    }
  }

  // payment reminder summary
  let paymentReminderSummary = []
  if(paymentReminderLevel === 0) {
    paymentReminderSummary.push(<div key="reminderLevel">Mahnstufe 0. Noch keine Mahnung verschickt.</div>)
  }
  else {
    paymentReminderSummary.push(<div key="reminderLevel">Mahnstufe {paymentReminderLevel}</div>)
    for(let i=1; i<=paymentReminderLevel; i+=1) {
      let sentOn = Util.printDate(paymentReminderDates[i])
      let media
      if(i < 3) {
        media = 'E-Mail'
      }
      else {
        media = (
          <Link to={`${Config.api.ApiRoot}/v1/dunningletter/${surprise._id}?level=${i}`} target="_blank">Brief</Link>
        )
      }
      paymentReminderSummary.push(
        <div key={`reminderSent_${i}`}>
          {i}. Mahnung per {media} verschickt am {sentOn}
        </div>
      )
    }
  }

  // reserver email, name, address, etc
  let reserverEmail = surprise.ReserverEmail
  if(Config.environment !== "production" || surprise.IsTest) {
    // TODO not sure, but I think this is handled server-side when sending e-mail
    reserverEmail = 'teamappentura@gmail.com';
  }
  let reserverAddress = `${_.get(surprise, "Order.InvoiceAddress.FirstName") || ""} ${_.get(surprise, "Order.InvoiceAddress.LastName") || ""}, ${_.get(surprise, "Order.InvoiceAddress.Address1") || ""}, ${_.get(surprise, "Order.InvoiceAddress.Address2") || ""}, ${_.get(surprise, "Order.InvoiceAddress.Zip") || "????"} ${_.get(surprise, "Order.InvoiceAddress.City") || "??????"}`

  const info = {
    dateDue, isOverdue, paymentType, paymentTypeTitle, paymentDate, paymentReminderLevel, paymentStateSummary, reserverEmail, reserverAddress, paymentReminderSummary, paymentReferenceNumber
  };
  return info;
}
