import React, {useContext, useState, useEffect} from "react"
import * as TS from "../../../../types";

import {Value, Button, Icon, Icons, Loader, CardSection, Modal} from "../../../controls";
import {ActivityContext} from "../ActivityContext";

import CSS from "./Activity.RegionVariant.AvailableMonths.module.scss";

// type AvailabilityProps = {
//   regionVariant: TS.ActivityRegionVariant
// }
// export default function Availability({regionVariant}: AvailabilityProps) {
export default function Availability() {
  // state 
  const [mode, setMode] = useState<"edit"|"view">("view");
  const [months] = useState(["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"]);

  // context
  const AC = useContext(ActivityContext)!;

  // user wants to edit
  const onClickEdit = () => {
    setMode("edit");
  }

  // stops edit mode
  const stopEdit = () => setMode("view");

  // saves changes
  const saveEdit = async(availableMonths:any) => {
    const changeset = {availableMonths};
    await AC.updateRegionVariant(AC.regionVariant!._id!, changeset);
    stopEdit();
  }

  // not loaded?
  if(AC.activity === null) {
    return <Loader text="lade Aktivität ..." />
  }

  // subactivity? do not display available months
  if(AC.activity.isSubactivity) {
    return null;
  }
  
  // pre-reender
  const trMonths: any[] = [];
  const trIcons: any[] = [];
  months.forEach((month, index) => {
    const active = AC.regionVariant!.availableMonths.map(v => Number(v)).includes(index + 1);
    const icon = active ? <Icon icon={Icons.Check} /> : null;
    trMonths.push(<td key={index} className={active ? CSS.active : ""}>{month}</td>)
    trIcons.push(<td key={index} className={active ? CSS.active : ""}>{icon}</td>)
  })
  // render
  return <>
    <CardSection title="Verfügbare Monate">
      <div className={CSS.availability}>
        <Value onClick={onClickEdit}>
          <table onClick={onClickEdit}><tbody>
            <tr className={CSS.labels}>{trMonths}</tr>
            <tr className={CSS.icons}>{trIcons}</tr>
          </tbody></table>
        </Value>
      </div>
    </CardSection>
    <Modal
      isOpen={mode==="edit"}
      title="Regionsvariante - Verfügbare Monate"
      onClose={stopEdit}
    >
      <Edit regionVariant={AC.regionVariant!} saveEdit={saveEdit} months={months} onCancel={stopEdit} />
    </Modal>
  </>
}


type EditProps = {
  months: any,
  regionVariant: TS.ActivityRegionVariant,
  saveEdit: (months:number[]) => void,
  onCancel: () => void,
}
function Edit({months, regionVariant, saveEdit, onCancel} : EditProps) {
  // state
  const [availableMonths, setAvailableMonths] = useState<number[]>([]);
  const [busy, setBusy] = useState(false);

  // mount
  useEffect(() => {
    setAvailableMonths(regionVariant.availableMonths.map(m => m));
  }, []);

  const toggleMonth = (index:number) => {
    const monthToToggle = index + 1;
    let updatedMonths;
    if(availableMonths.includes(monthToToggle)) {
      updatedMonths = availableMonths.filter(m => m !== monthToToggle);
    } 
    else {
      availableMonths.push(monthToToggle);
      updatedMonths = availableMonths.map(m => m).sort((a,b) => a - b);
    }
    setAvailableMonths(updatedMonths);
  }

  // save the update
  const onSave = async(formResult: any) => {
    setBusy(true);
    await saveEdit(availableMonths); 
    setBusy(false);
  }

  // pre-reender
  const trMonths: any[] = [];
  const trIcons: any[] = [];
  months.forEach((month:any, index:number) => {
    const active = (availableMonths || []).includes(index + 1);
    const icon = active ? <Icon icon={Icons.Check} /> : "";
    trMonths.push(<td key={index} className={active ? CSS.active : ""} onClick={() => toggleMonth(index)}>{month}</td>)
    trIcons.push(<td key={index} className={active ? CSS.active : ""} onClick={() => toggleMonth(index)}>{icon}</td>)
  })

  // render
  return (
    <div className={CSS.edit}>
      <div className={CSS.availability}>
        <table><tbody>
          <tr className={CSS.labels}>{trMonths}</tr>
          <tr className={CSS.icons}>{trIcons}</tr>
        </tbody></table>
        <br />
      </div>
      <div className={CSS.actions}>
        <Button onClick={onCancel} intent="cancel" busy={busy}>abbrechen</Button>
        <Button onClick={onSave} intent="confirm" busy={busy}>speichern</Button>
      </div>
    </div>
  ); 
}