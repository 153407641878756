import React, {useState, useEffect, useContext} from "react";
import _ from "lodash";
import Api from "../../../util/api2";

import { Modal, Button, ConfirmButton, Icon, Icons, Link, Loader, CardSection, Row} from "../../controls";
import ProviderToActivity from "../Common/ProviderToActivity"
import ProviderLocations from "./ProviderLocations"

type AssignedActivitiesProps = {
  providerId: string,
}
function AssignedActivities({providerId}: AssignedActivitiesProps) {
  
  // state
  const [mode, setMode] = useState<"view"|"edit">("view");
  const [assignments, setAssignments] = useState<null|any[]>(null);
  const [assignmentToEdit, setAssignmentToEdit] = useState<any|undefined>();

  useEffect(() => {
    load()
  }, [providerId])

  // loads existing assignments
  const load = async() => {
    let result = await Api.post("providers", "getActivityAssignments", {providerId})
    setAssignments(_.get(result, "data.items", []));
  }

  // removes an assignment
  const removeAssignment = async (activityId: string, regionVariantId: string) => {
    await Api.post('activities', 'removeProvider', {activityId, regionVariantId, providerId});
    load();
  }

  // user wants to edit
  const onClickAddUpdate = (providerId: string, assignment?: any) => {
    setMode("edit");
    setAssignmentToEdit(assignment);
    //MC.push("Anbieter zu Aktivität zuweisen", <Edit assignmentToEdit={assignment || {}} providerId={providerId} reload={load} />);
  }
  const stopEdit = () => setMode("view");

  // pre-render
  let content = null
  if(_.isNil(assignments)) {
    content = <Loader />
  }
  else {
    let rows = []
    assignments
      .sort((a:any, b:any) => a.activity.title.de.localeCompare(b.activity.title.de))
      .forEach(assignment => {
        let notes = assignment.notes ? <div className="notes">{assignment.notes}</div>: null
        let row_assignment = (
          <tr key={`assignment_${assignment.activity.regionVariantId}`}>
            <td>
              <Link to={`/activities/${assignment.activity.activityId}`}>
                {assignment.activity.title.de}
              </Link>
              {notes}
            </td>
            <td>{assignment.activity.regionCode}</td>
            <td>
              <Row>
                <ConfirmButton onConfirm={() => removeAssignment(assignment.activity.activityId, assignment.activity.regionVariantId)}>
                  <Icon icon={Icons.Trash} />
                </ConfirmButton>
                <Button size="small" onClick={() => onClickAddUpdate(providerId, assignment)}>
                  <Icon icon={Icons.Edit} />
                </Button>
              </Row>
            </td>
          </tr>
        )
        let row_locations = (
          <tr key={`locations_${assignment.activity.regionVariantId}`}><td colSpan={3}>
            <ProviderLocations providerId={providerId} activityId={assignment.activity.activityId} regionVariantId={assignment.activity.regionVariantId} />
          </td></tr>
        )

        rows.push(row_assignment);
        rows.push(row_locations);
    })
    // add create row
    rows.push(
      <tr key="create">
        <td colSpan={4} className="create">
          <Button size="small" onClick={() => onClickAddUpdate(providerId)}><Icon icon={Icons.Plus} /> Aktivität zuweisen</Button>
        </td>
      </tr>
    )
    content = (
      <table><tbody>{rows}</tbody></table>
    )
  }

  // render
  return <>
    <CardSection title="Zugewiesene Aktivitäten" key="assignedactivities">
      <div id="v-provider-assignedactivities">
        {content}
      </div>
    </CardSection>
    <Modal isOpen={mode==="edit"} title="Anbieter zu Aktivität zuweisen" onClose={stopEdit}>
      <Edit assignmentToEdit={assignmentToEdit || undefined} providerId={providerId} reload={load} stopEdit={stopEdit} />
    </Modal>
  </>
}

type EditProps = {
  assignmentToEdit: any,
  providerId: string,
  reload: Function,
  stopEdit: () => void,
}
function Edit({assignmentToEdit, providerId, reload, stopEdit}: EditProps) {
  // render
  return (
    <ProviderToActivity 
      activityId={assignmentToEdit ? assignmentToEdit.activity.activityId : undefined} 
      regionVariantId={assignmentToEdit ? assignmentToEdit.activity.regionVariantId : undefined} 
      providerId={providerId} 
      onCancel={() => stopEdit()} 
      onSave={() => {
        reload();
        stopEdit();
      }}
    />
  )
}



export default AssignedActivities