import React, {useState, useEffect} from "react"
import Api from "../../../../util/api2"

import { Loader, MapLink } from "../../../controls"
import { ProviderLink } from "../../../common"

type ProviderInfoProps = {
  providerId:any,
  providerLocationId:any
}
function ProviderInfo({providerId, providerLocationId}:ProviderInfoProps) {
  // state
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<any>(null)

  // mount
  useEffect(() => {
    const load = async() => {
      setIsLoading(true)
      const providerResult = await Api.post("providers", "search", {filter:{_id:providerId}, projection:{name:1}})
      const provider = providerResult.data.items[0];
      
      const locationResult = await Api.post("providerLocations", "search", {filter:{_id:providerLocationId}})
      const location = locationResult.data.items[0];

      setData({provider, location})
      setIsLoading(false)
    }
    if(providerId && providerLocationId) {
      load()
    }
  }, [providerId, providerLocationId])

  // render
  if(isLoading) {
    return <Loader text="lade ..." />
  }
  else if(!data) {
    return <div>kein Anbieter gewählt</div>
  }
  else {
    let providerInfo = <div>keine Angaben zum Anbieter verfügbar</div>
    let locationInfo = <div>keine Angaben zum Startort verfügbar</div>
    if(data.provider) {
      providerInfo = (
        <div>
          <ProviderLink provider={data.provider} />
        </div>
      );
    }
    if(data.location && data.location.start) {
      locationInfo = <div><MapLink lat={data.location.start.coordinates.lat} lng={data.location.start.coordinates.lng} zoom={undefined} /></div>
    }
    return <div>{providerInfo}{locationInfo}</div>
  }
}

export default ProviderInfo