import _ from "lodash";
import React, {useContext, useState} from "react";
import {SurpriseContext} from "../Surprise.Context";
import { Card, Icons, Link, Modal, Value } from "../../../controls";
import AddressEdit from "./Address.Edit";
import GenericEdit from "../Generic.Edit";
import * as BL from "../../../../types/BusinessLogic";
import Util from "../../../../util/util";
import {ShippingStatus, CoverletterLinks} from "../Common";
// styling
import "./Shipping.View.scss";

// the component
type ShippingProps = {
  expanded:boolean,
  headless:boolean
}
export default function Shipping({expanded, headless}: ShippingProps) {
  // state
  const [mode, setMode] = useState<"view"|"editAddress"|"editMessage"|"editDoneeName">("view");
  const [modalParam, setModalParam] = useState<any>("");
  const [modalTitle, setModalTitle] = useState<string>("");
  // context
  const SC = useContext(SurpriseContext);

  // show edit
  const showAddressEdit = (which:any) => {
    const title = which === "ShippingAddress" ? "Versandadresse" : "Rechnungsadresse";
    setModalTitle(title);
    setModalParam(which);
    setMode("editAddress")
  }
  const showMessageEdit = () => {
    setModalTitle("Mitteilung auf Gutschein (Schenker an Beschenkten)");
    setMode("editMessage")
  }
  const showDoneeNameEdit = () => {
    setModalTitle("Name des Beschenkten");
    setMode("editDoneeName");
  }
  const stopEdit = () => setMode("view");

  // pre-render modal content
  let modalContent = null;
  switch(mode) {
    case "editAddress":
      modalContent = <AddressEdit which={modalParam} stopEdit={stopEdit} />
      break;
    case "editDoneeName":
      modalContent = <GenericEdit path="RecieverName" label="Name des Beschenkten" kind="textinput" stopEdit={stopEdit} />
      break;
    case "editMessage":
      modalContent = <GenericEdit path="PersonalComment" label="Mitteilung auf Gutschein" kind="textarea" stopEdit={stopEdit}  />
      break;
  }
  // render
  const surprise = SC.surprise;
  // TODO shippingInfo already contains an .addresses object 
  const addressLines = {
    shipping: BL.Shipping.getAddressLines(SC.surprise.Order.ShippingAddress),
    invoice: BL.Shipping.getAddressLines(SC.surprise.Order.InvoiceAddress)
  }
  const isDirectShipping = _.isEqual(addressLines.shipping, addressLines.invoice) === false;
  const summaryContent = [
    BL.Shipping.getShippingStatus(SC.surprise).text,
    `Nachricht an Beschenkten: ${surprise.PersonalComment || ''}`
  ]
  return <>
    <Card title="Versand" icon={Icons.Box}  expanded={expanded} collapsible={true} summaryContent={summaryContent}>
      <div id="v-surprise-shipping">
        <div>
          <Row label="Bestelldatum">{Util.printDateAndTime(surprise.CreateDate)}</Row>
          <Row label="Versandart">{SC.shippingInfo.packaging.name || "unbekannte Versandart"}</Row>
          <Row label="Name Beschenkter auf Gutschein" onEdit={() => showDoneeNameEdit()}>{surprise.RecieverName}</Row>
          <Row label="Kommentar auf Gutschein" onEdit={() => showMessageEdit()}>{SC.surprise.PersonalComment || "-"}</Row>
          <Row label="Gutschein PDF"><TicketLinks ticketGroup={SC.shippingInfo.tickets.pdf} /></Row>
          <Row label="Gutschein HTML"><TicketLinks ticketGroup={SC.shippingInfo.tickets.html} /></Row>
          <Row label="Begleitbrief"><CoverletterLinks coverletters={SC.shippingInfo.coverletters} isDirectShipping={isDirectShipping} /></Row>
          <Row label="Versandadresse" onEdit={() => showAddressEdit("ShippingAddress")}><Address lines={addressLines.shipping} /></Row>
          <Row label="Rechnungsadresse" onEdit={() => showAddressEdit("InvoiceAddress")}><Address lines={addressLines.invoice} /></Row>
          <Row label="Versandstatus"><ShippingStatus /></Row>
        </div>
        <div id="v-surprise-shipping-image">
          <img src={SC.shippingInfo.packaging.imageUrl} alt={SC.shippingInfo.packaging.name}/>
        </div>
      </div>
    </Card>
    <Modal isOpen={mode!=="view"} title={modalTitle} onClose={stopEdit}>
      {modalContent}
    </Modal>
  </>;
}

type RowProps = {
  label: string,
  children: React.ReactNode|React.ReactNode[],
  onEdit?: () => void,
}
function Row({label, children, onEdit}: RowProps) {
  return <Value label={label} onClick={onEdit}>{children}</Value>
}

type AddressProps = {
  lines:string[]
}
function Address({lines}: AddressProps) {
  return <address>
  {lines.map((line, index) => <p key={index}>{line}</p>)}
  </address>
}

type TicketLinksProps = {
  ticketGroup:any
}
function TicketLinks({ticketGroup}:TicketLinksProps) {
  return <><Link to={ticketGroup.primary.url} target="_blank">{ticketGroup.primary.label}</Link> (oder <Link to={ticketGroup.secondary.url} target="_blank">{ticketGroup.secondary.label}</Link>)</>
}
