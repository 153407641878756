import React from "react";
import Code from "../Code";
import { Alert, Card, Heading, Stars } from "../../../controls";

export default function AlertDemo() {
  // render
  return <>
    <Heading>Alert</Heading>
    <Card>
      <Stars max={5} value={5} />
      <Stars max={5} value={4.5} />
      <Stars max={5} value={4} />
      <Stars max={5} value={3.5} />
      <Stars max={5} value={3} />
      <Stars max={5} value={0} />
    </Card>
    
  </>
}
/*
    <Card>
      <Alert intent="warning" title="Small Warning" size="small">
        Hello there, this is a small warning
      </Alert>
      <Code>{
  `<Alert intent="warning" title="Small Warning" size="small">
    Hello there, this is a small warning
  </Alert>`
      }</Code>

      <Alert intent="hint" title="Small Hint" size="small">
        Hello there, this is a small hint
      </Alert>
      <Code>{
  `<Alert intent="hint" title="Small Hint" size="small">
    Hello there, this is a small hint
  </Alert>`
      }</Code>

      <Alert intent="info" title="Medium Info" size="medium">
        Hello there, this is a medium warning
      </Alert>
      <Code>{
  `<Alert intent="info" title="Medium Info" size="medium">
    Hello there, this is a medium warning
  </Alert>`
      }</Code>

      <Alert intent="error" title="Large Error" size="large">
        Hello there, this is a large warning
      </Alert>
      <Code>{
  `<Alert intent="error" title="Large Error" size="large">
    Hello there, this is a large warning
  </Alert>`
      }</Code>

    </Card>
    */