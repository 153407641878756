// @ts-ignore
import esr from "esr-ref";
import { Td, TextInput, Tr, Validators } from "../../controls";
import { FormData } from "./VendorInvoice";
import CSS from  './VendorInvoice.module.scss';

type PurposeProps = {
  formData: FormData,
  disabled?: boolean,
  onChange:(referenceNumber:string|null, paymentPurpose:string|null) => void
}
export default function Purpose({formData, disabled=false, onChange} : PurposeProps) {
  // render read only
  if(formData.isOutgoing || formData.isPaid) {
    let label = "";
    let value = ""
    if(formData.referenceNumber !== null && formData.referenceNumber.trim().length > 0) {
      label = "Referenznummer";
      value = formData.referenceNumber;
    }
    else if (formData.paymentPurpose !== null && formData.paymentPurpose.trim().length > 0){
      label = "Zahlungszweck"
      value = formData.paymentPurpose;
    }
    if(label !== "" && value != "") {
      return <Tr><Td label>{label}</Td><Td>{value}</Td></Tr>
    }
    else {
      return null;
    }
  }

  // editable only possible 
  // - condition A: we are creating the invoice and the selected payment kind is "bank" 
  // or 
  // - condition B: we are updating and the invoice is not paid and not outgoing (which implictly makes it a "bank" payment kind invoice, since the "creditcard" and "employee" can not be "not paid" and update at the same time)
  const conditionA = formData.isUpdate === false && formData.paymentTypeKind === "bank";
  const conditionB = formData.isUpdate && formData.isPaid === false && formData.isOutgoing === false;
  if(conditionA || conditionB) {
    let _disabled: "referenceNumber"|"paymentPurpose"|"" = "";
    if(formData.referenceNumber !== null && formData.referenceNumber.trim().length > 0) {
      _disabled = "paymentPurpose";
    }
    else if (formData.paymentPurpose !== null && formData.paymentPurpose.trim().length > 0){
      _disabled = "referenceNumber";
    }
    let prettyEsr = null;
    if(formData.referenceNumber && formData.referenceNumber.trim().length > 0) {
      prettyEsr = (
        <div className={CSS.secondary}>
          {esr.prettify(formData.referenceNumber)}
        </div>
      )
    }
    return <>
      <Tr>
        <Td label>Referenznummer</Td>
        <Td>
          <TextInput
            value={formData.referenceNumber || ""}
            onChange={v => onChange(v, null)}
            //validate={disabled !== "referenceNumber" ? Validators.isEsrReferenceNumber("ungültige Referenznummer") : undefined}
            validate={Validators.isPainReferenceNumber("ungültige Referenznummer")}
            disabled={_disabled === "referenceNumber" || disabled}
          />
          {prettyEsr}
        </Td>
      </Tr>
      <Tr>
        <Td label> </Td>
        <Td className={CSS.secondary}>oder</Td>
      </Tr>
      <Tr>
        <Td label>Zahlungszweck</Td>
        <Td>
          <TextInput
            value={formData.paymentPurpose || ""}
            onChange={v => onChange(null, v)}
            //validate={disabled !== "paymentPurpose" ? Validators.isSepaCompliant() : undefined}
            validate={Validators.isSepaCompliant()}
            disabled={_disabled === "paymentPurpose" || disabled}
          />
        </Td>
      </Tr>
    </>
  }

  // nothing to render
  return null;
}