import React, { useState } from "react";
import * as DA from "../../../types/DataAccess";
import {Alert, Form, TextInput, Dropdown, Validators, Validation} from "../../controls";

type PaymentTypeEditProps = {
  paymentType: DA.PaymentType,
  fields:any,
  onUpdate: any,
  closeDialog: VoidFunction,
}
export default function PaymentTypeEdit({paymentType, fields, onUpdate, closeDialog} : PaymentTypeEditProps) {
// state
  const [error, setError] = useState<any>(null);
  const [busy, setBusy] = useState<boolean>(false);
  const [entity] = useState<DA.PaymentType>(paymentType);
  const [currencyOptions] = useState([
    {label:"CHF", value:"CHF"},
    {label:"EUR", value:"EUR"}
  ]);
  
  // user cancels
  const onCancel = () => { 
    closeDialog();
  };

  // user wants to save
  const onSave = async(formResult:any) => {
    setBusy(true);
    // save
    let result;
    if(entity._id) {
      // update
      result = await DA.PaymentTypeRepository.update(entity._id, formResult.merge(entity));
    }
    else {
      // create
      const pt_obj = formResult.merge(entity);
      result = await DA.PaymentTypeRepository.create(pt_obj);
    }
  
    // pop dialog on success, display error if not
    if(result.success) {
      onUpdate();
      closeDialog();
    }
    else {
      setBusy(false);
      setError(result.error);
    }
  }

  // pre-render
  const items:any[] = fields.map((field:any) => {
    if(field.isCurrency) {
      return <Dropdown key={field.key} label={field.header} path={field.key} onChange={() => {}} options={currencyOptions} validate={() => {return new Validation(true)}} />
    }
    else {
      return <TextInput key={field.key} path={field.key} label={field.header} validate={Validators.isRequired(`Bitte ${field.header} eingeben`)} />
    }
  })
  let errorAlert = null;
  if(error) {
    errorAlert = <Alert title="Zahlungsart konnte nicht gespeichert werden" intent="error" size="medium">{error.message}</Alert>
  }
  // render
  return (
    <Form entity={entity} onSave={onSave} onCancel={onCancel} labelSave="speichern" labelCancel="abbrechen" busy={busy}>
      {items}
      {errorAlert}
    </Form>
  )
}