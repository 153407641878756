import React, {useEffect, useState, useContext} from "react"
import _ from "lodash"
import { CardSection, Icon, Icons } from "../../controls";
import { ActivityPackageContext } from "./ActivityPackage.Context";
import * as TS from "../../../types";
//import { Activity, ActivityPriceConfiguration } from "../../../logic/_.deprecated.20230525.Models/_.deprecated.20230525.Activity";

export default function ActivityPrices() {
  // context
  const AC = useContext(ActivityPackageContext);

  // state
  const [participantCount, setParticipantCount] = useState<number>(-1);
  const [participantCounts, setParticipantCounts] = useState<number[]>([]);
  const [activities, setActivities] = useState<TS.Activity[]>([]);
  const [regionCodes, setRegionCodes] = useState<string[]>([]);
  const [totals, setTotals] = useState<any>({});

  // no context? no render
  if(!AC || !AC.activityPackage) {
    return null;
  }

  // mount
  useEffect(() => {
    if(AC.activityPackage) {
      // get activities
      const activities: Array<TS.Activity> = [];
      AC.activityPackage.activities.map((a:any) => {
        // find activity in catalogue
        const activity = AC.activityCatalogue.find(ca => ca._id == a.activityId);
        if(activity) {
          activities.push(activity);
        } 
      });
      // get region codes
      const regionCodes = AC.regionCatalogue.map(r => r.code);
      // valid participant counts
      const participantCounts = getParticpantCounts(activities, regionCodes);
      
      // update state
      setActivities(activities);
      setRegionCodes(regionCodes);
      setParticipantCounts(participantCounts);
      setParticipantCount(participantCount === -1 ? participantCounts[0] : participantCount);
      setTotals(getTotals(participantCount, activities, regionCodes));
    }
    
  }, [AC.activityPackage])

  // selected participant count changed
  useEffect(() => {
    setTotals(getTotals(participantCount, activities, regionCodes));
  }, [participantCount]);
  

  // pre-render header cells
  const headerCells:any[] = [];
  headerCells.push(<th key=""></th>);
  regionCodes.forEach(rc => headerCells.push(<th key={rc}>{rc}</th>));

  // pre-render activity rows
  const activityRows:any[] = activities.map(a => {
    const totals:any = {};
    const prices = regionCodes.map(regionCode => {
      totals[regionCode] = 0;
      const regionVariant = a.regionVariants.find(rv => rv.regionCode === regionCode);
      let price: number|"-" = "-";
      if(regionVariant && regionVariant.isActive) {
        const priceConfiguration = regionVariant.priceConfigurations.find(pc => pc.participantCount === participantCount);
        if(priceConfiguration) {
          price = priceConfiguration.price;
        }
      }
      return <td key={regionCode} className="price">{price}</td>
    })
    return <tr key={a._id}>
      <td key="activity">{a.title.de}</td>
      {prices}
    </tr>
  })

  // pre-render totals
  const totalCells:any[] = regionCodes.map(regionCode => {
    const total = totals[regionCode] || <Icon icon={Icons.ExclamationTriangle} />
    return <td key={regionCode} className="price">{total}</td>
  })
  
  // render
  return (
    <CardSection title="Preise der Aktivitäten">
      <div id="v-activitypackage-activityprices">
        <table>
          <thead><tr>{headerCells}</tr></thead>
          <tbody>
            {activityRows}
            <tr key="totals" className="totals">
              <td></td>
              {totalCells}
            </tr>
          </tbody>
        </table>
        <div className="participantCounts">
          <div className="title">Teilnehmer:</div>
          {
            participantCounts.map(i => <div className={`item ${i === participantCount ? "active" : ""}`} key={i} onClick={() => setParticipantCount(i)}>{i}</div>)
          }
        </div>
        </div>
    </CardSection>
  )
}

function getParticpantCounts(activities: Array<TS.Activity>, regionCodes: Array<string>): Array<number> {
  const allCounts: Array<number> = [];
  regionCodes.forEach(regionCode => {
    activities.forEach(a => {
      const rv = a.regionVariants.find(rv => rv.regionCode === regionCode);
      if(rv) {
        const counts:number[] = rv.priceConfigurations.map(pc => pc.participantCount);
        allCounts.push(...counts);
      }
    })
  });

  const participantCounts:number[] = _.uniq(allCounts).sort((a,b) => a-b);
  return participantCounts;
}

/**
 * Returns totals for all region variants ... if a total cannot be calculated the value is undefined
 * @param participantCount 
 * @param activities 
 * @param regionCodes 
 */
function getTotals(participantCount: number, activities: Array<TS.Activity>, regionCodes: Array<string>): any {
  const totals:any = {};
  regionCodes.forEach(regionCode => {
    totals[regionCode] = 0;
    activities.forEach(activity => {
      // if the total for this region was already set to undefined we no longer bother to add to the toal
      if(!isNaN(totals[regionCode])) {
        let pc:TS.ActivityPackagePriceConfiguration|undefined;
        const rv = activity.regionVariants.find(rv => rv.regionCode === regionCode);
        if(rv) {
          pc = rv.priceConfigurations.find(pc => pc.participantCount === participantCount);
        }
        // no configuration found? set the total to undefined
        if(pc) {
          totals[regionCode] += pc.price;
        }
        else {
          totals[regionCode] = undefined;
        }
      }
    })
  });
  return totals;
}
