import React, { useContext } from "react";
import { FilterDunningProp } from "../../../../../types/AdventureSearch";
import { Checkbox } from "../../../../controls";
import { SearchContext } from "../Search.Context";
import FilterGroup from "../Search.FilterGroup";

export default function Dunning() {
  // context
  const Search = useContext(SearchContext);

  // onChange
  const onChange = (dunningProp:FilterDunningProp, checked: boolean) => {
    const updatedFilter:any = Search.filter.clone();
    if(dunningProp !== "unpaid" && checked) {
      // if any option other than unpaid is selected we need to select unpaid. if we fail to do so, we get more items than are actually affected.
      updatedFilter.dunning.unpaid = true;  
    }
    updatedFilter.dunning[dunningProp] = checked;
    Search.reset(updatedFilter);
  }

  // render
  return (
    <FilterGroup title="Mahnwesen">
      <Checkbox secondaryLabel="unbezahlt" value={Search.filter.dunning.unpaid || false} onChange={checked => onChange("unpaid", checked)} />
      <Checkbox secondaryLabel="überfällig" value={Search.filter.dunning.overdue || false} onChange={checked => onChange("overdue", checked)} />
      <Checkbox secondaryLabel="Mahnstufe 0" value={Search.filter.dunning.level0 || false} onChange={checked => onChange("level0", checked)} />
      <Checkbox secondaryLabel="Mahnstufe 1" value={Search.filter.dunning.level1 || false} onChange={checked => onChange("level1", checked)} />
      <Checkbox secondaryLabel="Mahnstufe 2" value={Search.filter.dunning.level2 || false} onChange={checked => onChange("level2", checked)} />
      <Checkbox secondaryLabel="Mahnstufe 3" value={Search.filter.dunning.level3 || false} onChange={checked => onChange("level3", checked)} />
    </FilterGroup>
  )
}