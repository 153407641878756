import React, {useContext, useEffect, useState} from "react";
import {  Form, Loader, TextInput, Validators } from "../../controls";
import * as TS from "../../../types";
import * as DataAccess from "../../../types/DataAccess";

type WeatherConditionsItemProps = {
  id?: string,
  onUpdate: () => void,
  onCancel: () => void,
}
function WeatherConditionsItem({id, onUpdate, onCancel} : WeatherConditionsItemProps) {  
  // state
  const [entity, setEntity] = useState<DataAccess.WeatherCondition|null>(null);
  const [busy, setBusy] = useState(false);
  
  // mount
  useEffect(() => {
    const load = async(id:string) => {
      const wc = await DataAccess.WeatherConditionRepository.findById(id) || DataAccess.WeatherConditionRepository.make();
      setEntity(wc);
    }
    if(id) {
      load(id);
    }
    else {
      setEntity(DataAccess.WeatherConditionRepository.make());
    }
  }, [id]);

  // save the weather condition
  const onSave = async(formResult:any) => {
    setBusy(true);
    if(entity!._id) {
      await DataAccess.WeatherConditionRepository.update(entity!._id, formResult.changeset);
    }
    else {
      await DataAccess.WeatherConditionRepository.create(formResult.merge(entity));
    }
    onUpdate();
  }

  // render
  if(entity === null) {
    return <Loader />
  }
  return (
    <Form entity={entity} onSave={onSave} onCancel={onCancel} busy={busy}>
      <TextInput label="" path="title.de" disabled={busy} validate={Validators.isRequired("Wetterbedingung angeben")} />
    </Form>
  )
}

export default WeatherConditionsItem