import moment from "moment";
import Api from "../../util/api2"; // TODO there should be no api calls in here, use DataAccess instead
import { Adventure, AdventureStatus } from "../.";
import * as DA from "../DataAccess";

export interface DashboardWarning {
  surprise_id: string;
  startTime: Date|null;
  operator: DA.User|null;
  receiverName: string|null;
  reserverName: string|null;
  hasPriority: boolean;
  issues: string[];
}

export class Dashboard {
  public static async getOpenBookingRequests() {
    // open requests
    const filterBookingrequests = {"$or":[
      {response:null},
      {"response.receivedOn":null}
    ]};
    
    // get open requests
    const openRequests = await DA.BookingRequestRepository.search(filterBookingrequests);
    
    // get adventures they belong to
    const adventureIds = openRequests.map(br => br.adventure_id);
    const filterAdventures = {_id:{$in:adventureIds}}
    const resultAdventures = await Api.post("adventures", "search", {filter:filterAdventures, projection:{}});
    const adventures = resultAdventures.data.items;
  
    // create array containing both booking request and the adventure it belongs to
    const items = openRequests.map(bookingRequest => {
      const adventure = adventures.find((a:any) => String(a._id) === String(bookingRequest.adventure_id))
      return {
        bookingRequest,
        adventure
      }
    });
    return items;
  }

  public static async getWarnings(): Promise<DashboardWarning[]> {
    // get all users
    const users = await DA.UserRepository.findAll();

    // get all surprises that will start within the next 56 hours and are not test, finished or archived
    const stati_to_check = [
      AdventureStatus.ConditionsAccepted,
      AdventureStatus.ConditionsSent,
      AdventureStatus.DateSelected,
      AdventureStatus.Ready
    ];
    let d1 = new Date();
    let d2 = moment().add(56, 'hours').toDate();
    const surprises = await Adventure.search({
      $and:[
        {"StartTime":{$gt:d1}},
        {"StartTime":{$lt:d2}},
        {"Status":{$in:stati_to_check}},
        {"IsTest":false}
      ]
    });

    // check all surprises and collect warnings
    let warnings:DashboardWarning[] = [];
    surprises.forEach((surprise:any) => {
      let issues = []
      // no steps defined?
      if((surprise.Steps || []).length === 0) {
        issues.push("Noch keine Schritte definiert.")
      }
      // not in 'ready' state?
      if(surprise.Status !== AdventureStatus.Ready) {
        issues.push("Ist noch nicht im Status 'Bereit'.")
      }

      // user no longer active?
      const user:DA.User|null = users.find(u => u.email === surprise.Operator) || null;
      if(user && !user.isActive) {
        issues.push("Der Operator ist nicht mehr aktiv.");
      }

      // any issues? add to collection
      if(issues.length > 0) {
        //const warning:DashboardWarning = new DashboardWarning(surprise, issues, user);
        const warning:DashboardWarning = {
          surprise_id: surprise._id,
          startTime: surprise.StartTime,
          receiverName: surprise.RecieverName,
          reserverName: surprise.ReserverName,
          hasPriority: surprise.HasPriority,
          issues: issues,
          operator: user
        }
        warnings.push(warning);
      }
    })

    // done
    return warnings;
  }
}