import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
// types
import * as Cms from "../../../../types/Cms";
// contexts and components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageContext } from "../Page.Context";
import { Card, Actions, Alert, Paragraphs, TextInput, TextArea, Button, Form, Label, Validators, SortableList, SortableListItem, CloudStorageUploader } from "../../../controls";
import * as Selectors from "../Selectors";
import * as Preview from "../Preview";

// styling and images
import CSS from "./IconTiles.module.scss";

type IconTilesProps = {
  instance?: Cms.IconTilesModule,
  closeDialog: () => void,
}
export function IconTiles({ instance, closeDialog }: IconTilesProps) {
  // context
  const PC = useContext(PageContext);

  // state
  const [entity, setEntity] = useState<Cms.IconTilesModule | null>(null);
  const [busy, setBusy] = useState<boolean>(false);
  const [sortingDisabled, setSortingDisabled] = useState<boolean>(false);
  const [mode, setMode] = useState<"properties" | "item">("properties");
  const [itemToEdit, setItemToEdit] = useState<Cms.IconTilesModuleItem | null>(null);

  // mount
  useEffect(() => {
    let entity: Cms.IconTilesModule;
    if (instance) {
      entity = instance;
    }
    else {
      entity = new Cms.IconTilesModule();
    }
    setEntity(entity)
  }, [])

  // user wants to cancel
  const onCancel = () => {
    closeDialog();
  }

  // user wants to save
  const onSave = async (formResult: any) => {
    setBusy(true);
    const updatedEntity = formResult.merge(entity);

    if (formResult.changeset.countries) {
      // TODO we need to do this because formResult.merge does not seem to handle arrays correctly
      updatedEntity.countries = formResult.changeset.countries;
    }

    await PC.upsertModule(updatedEntity);

    // close dialog
    closeDialog();
  }

  // user wants to save an item
  const onSaveItem = async (itemToSave: Cms.IconTilesModuleItem) => {
    if (entity) {
      // update or add the item
      const items = entity.elements.items.filter(item => String(item.id) !== String(itemToSave.id));
      items.push(itemToSave);

      // get updated version of the module
      const updatedModule = _.cloneDeep(entity);
      updatedModule!.elements.items = items;

      // save the module
      await PC.upsertModule(updatedModule);

      // get the updated module and set it as the entity
      setEntity(updatedModule);
    }
  }

  // user wants to delete item
  const onClickDelete = async (itemToDelete: Cms.IconTilesModuleItem) => {
    if (entity) {
      // update or add the item
      const items = entity.elements.items.filter(item => String(item.id) !== String(itemToDelete.id));

      // get updated version of the module
      const updatedModule = _.cloneDeep(entity);
      updatedModule!.elements.items = items;

      // save the module
      await PC.upsertModule(updatedModule);

      // get the updated module and set it as the entity
      setEntity(updatedModule);
    }
  }


  // user wants to add/update item
  const onClickEditItem = (item: Cms.IconTilesModuleItem) => {
    setItemToEdit(item);
    setMode("item");
  }

  const onClickAddItem = () => {
    setItemToEdit(new Cms.IconTilesModuleItem());
    setMode("item");
  }

  const onDrop = async (id: string, targetIndex: number) => {
    setSortingDisabled(true);
    // get a copy of the items to work with
    const updatedItems = entity!.elements.items.map(item => item).sort((a, b) => a.position - b.position);

    // get source and target indices
    const indexFrom = updatedItems.findIndex(item => item.id === id); // result.source.index;

    // remove element and move to new position
    const element = updatedItems.splice(indexFrom, 1)[0]; // splice returns the removed elements as an array, since we only remove one item, we can get it from index 0
    updatedItems.splice(targetIndex, 0, element);

    // update position property on items
    updatedItems.forEach((item, index) => item.position = index);

    // TODO NOT DRY this is to a great extent the same code as above in onSaveItem
    // get updated version of the module
    const updatedModule = _.cloneDeep(entity!);
    updatedModule!.elements.items = updatedItems;

    // save the module
    await PC.upsertModule(updatedModule);
    setSortingDisabled(false);

    // get the updated module and set it as the entity
    setEntity(updatedModule);

  }

  // entity not set yet? render nothing
  if (!entity) {
    return null;
  }

  if (mode === "properties") {
    return (
      <Properties
        entity={entity}
        onClickEditItem={onClickEditItem}
        onClickDelete={onClickDelete}
        onClickAddItem={onClickAddItem}
        onCancel={onCancel}
        onSave={onSave}
        onDrop={onDrop}
        sortingDisabled={sortingDisabled}
      />
    )
  }
  else {
    return (
      <Item
        item={itemToEdit!}
        onSaveItem={onSaveItem}
        closeDialog={() => setMode("properties")}
      />
    )
  }


}

type PropertiesProps = {
  entity: Cms.IconTilesModule,
  onClickEditItem: (item: Cms.IconTilesModuleItem) => void,
  onClickDelete: (item: Cms.IconTilesModuleItem) => void,
  onClickAddItem: () => void,
  onCancel: () => void,
  onSave: (formResult: any) => void,
  onDrop: (id: string, targetIndex: number) => void,
  sortingDisabled: boolean,
}
function Properties({ entity, onClickEditItem, onClickDelete, onClickAddItem, onCancel, onSave, onDrop, sortingDisabled }: PropertiesProps) {
  // pre-render items
  const items = entity.elements.items
    .sort((a, b) => a.position - b.position)
    .map((item: Cms.IconTilesModuleItem, index: number) => {
      return (
        <SortableListItem key={item.id} id={item.id}>
          <Card title={item.title} size="small">
            <Paragraphs text={item.text} />
            <Actions size="small" onEdit={() => onClickEditItem(item)} onDelete={() => onClickDelete(item)} />
          </Card>
        </SortableListItem>
      )
    })

  // render
  let listAction = null;
  let list = null;
  if (entity._id) {
    listAction = <div><Button size="small" onClick={onClickAddItem}>Kachel hinzufügen</Button></div>;
    list = (
      <SortableList onDrop={onDrop} disabled={sortingDisabled} noItemsText="noch keine Einträge">
        {items}
      </SortableList>
    );
  }
  else {
    listAction = <Alert size="small" intent="info" title="Speichern notwendig">Bevor Kachlen hinzugefügt werden können, muss dieses Modul erst gespeichert werden</Alert>
  }
  // TODO <Alert /> needs content
  // TODO busy needs value
  const busy = false;
  return (
    <Form entity={entity} onCancel={onCancel} onSave={onSave} busy={busy}>
      <Selectors.BaseProperties busy={false} />

      <TextInput label="Titel" path="elements.title" placeholder="Titel eingeben" disabled={busy} />
      <TextInput label="Untertitel" path="elements.subtitle" placeholder="Untertitel eingeben" disabled={busy} />
      <TextInput label="Button Text" path="elements.buttonText" placeholder="Text auf Button" disabled={busy} />
      <TextInput label="Button Url" path="elements.buttonHref" placeholder="Href auf Button" disabled={busy} />

      <Label label="Kacheln" />
      {list}
      {listAction}
    </Form>
  )
  return (
    <Form entity={entity} onCancel={onCancel} onSave={onSave} busy={busy}>
      <Selectors.BaseProperties busy={false} />

      <TextInput label="Titel" path="elements.title" placeholder="Titel eingeben" disabled={busy} />
      <TextInput label="Untertitel" path="elements.subtitle" placeholder="Untertitel eingeben" disabled={busy} />
      <TextInput label="Button Text" path="elements.buttonText" placeholder="Text auf Button" disabled={busy} />
      <TextInput label="Button Url" path="elements.buttonHref" placeholder="Href auf Button" disabled={busy} />

      <Label label="Kacheln" />

      <SortableList onDrop={onDrop} disabled={sortingDisabled}>
        {items}
      </SortableList>

      {listAction}

    </Form>
  )
}

type ItemProps = {
  item?: Cms.IconTilesModuleItem,
  onSaveItem: (itemToSave: Cms.IconTilesModuleItem) => void,
  closeDialog: () => void,
}
function Item({ item, onSaveItem, closeDialog }: ItemProps) {
  // state
  const [entity, setEntity] = useState<Cms.IconTilesModuleItem>(new Cms.IconTilesModuleItem());
  const [busy, setBusy] = useState<boolean>(false);

  // mount
  useEffect(() => {
    if (item) {
      setEntity(item);
    }
  }, []);

  // user wants to cancel
  const onCancel = () => {
    closeDialog();
  }

  // user wants to save
  const onSave = async (formResult: any) => {
    setBusy(true);
    const updatedEntity = formResult.merge(entity);
    await onSaveItem(updatedEntity);
    closeDialog();
  }

  // render
  return (
    <Form entity={entity} onCancel={onCancel} onSave={onSave} busy={false}>
      <TextInput label="Titel" path="title" placeholder="Titel eingeben" disabled={busy} validate={Validators.isRequired("Titel eingeben")} />
      <TextArea label="Text" path="text" placeholder="Untertitel eingeben" disabled={busy} validate={Validators.isRequired("Text eingeben")} />
      <CloudStorageUploader kind="image" label="Bild" folder="cms" path="imageUrl" pathBlurhash="imageBlurhash" disableUrlEditing={true} prefix="cms" />
      <Selectors.IconSelector busy={busy} />
    </Form>
  )
}

type IconTilesPreviewType = {
  instance: Cms.IconTilesModule,
  expanded?: boolean,
  onClickAction: (module: Cms.Module, action: "edit" | "delete" | "duplicate") => void
}
export function IconTilesPreview({ instance, expanded, onClickAction }: IconTilesPreviewType) {
  const items = instance.elements.items.sort((a, b) => a.position - b.position).map((item: Cms.IconTilesModuleItem) => {
    return (
      <div className={CSS.tile} key={item.id}>
        <div className={CSS.image}>
          <Preview.Image src={item.imageUrl} />
        </div>
        <div className={CSS.icon}>
          <FontAwesomeIcon icon={[item.iconGroup, item.iconName as any]} />
        </div>
        <div className={CSS.title}>{item.title}</div>
        <div className={CSS.text}>
          <Paragraphs text={item.text} />
        </div>
      </div>
    )
  })
  return (
    <Preview.Container expanded={expanded} module={instance} title={Cms.IconTilesModule.info.title} onClickAction={onClickAction}>
      <div className={CSS.preview}>
        <Preview.Table>
          <Preview.TableRow label="Titel">{instance.elements.title || "-"}</Preview.TableRow>
          <Preview.TableRow label="Untertitel">{instance.elements.subtitle || "-"}</Preview.TableRow>
          <Preview.TableRow label="Button">
            <Preview.Button text={instance.elements.buttonText} href={instance.elements.buttonHref} />
          </Preview.TableRow>
          <Preview.TableRow label="Kacheln">
            <div className={CSS.tiles}>{items}</div>
          </Preview.TableRow>
        </Preview.Table>
      </div>
    </Preview.Container>
  )
}