import React from "react";
import { Icon, Icons, Link } from ".";
import CSS from "./DriveLink.module.scss";

type DriveLinkProps = {
  children: React.ReactNode|React.ReactNode[],
  driveId: string
}
export function DriveLink({driveId, children} : DriveLinkProps) {
  return (
    <Link className={CSS.driveLink} target="_blank" to={`https://drive.google.com/file/d/${driveId}/view`}>
      {children}&nbsp;<Icon icon={Icons.GoogleDrive} />
    </Link>
  )
}