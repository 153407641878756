import React, {useContext, useState, useEffect} from "react"
import _ from "lodash"

import { FormContext } from "./FormContext";
import { Alert, Label, ValidationError } from ".";
import { Validation } from "./Validation";

import CSS from "./Dropdown.module.scss";

export type DropdownOption = {
  label: string,
  value: any
}

type DropdownProps = {
  path?: string,
  value?:any
  options: DropdownOption[],
  label?: string,
  explanation?: string,
  validate?: any,
  size?: "small"|"regular",
  disabled?: boolean,
  onChange?: (value:string, validation:Validation) => void,
}
export function Dropdown({path, value, label, explanation, options, validate, onChange, size, disabled=false} : DropdownProps) {
  // get context
  const form = useContext(FormContext);
  size = size || "regular";

  // if we have a form context, we render with form if not without
  if(form && path) {
    // warn about value not being used if provided
    if(value) {
      console.warn("since Dropdown is inside a FormContext and a 'path' was provided, the provided 'value' will be ignored");
    }
    // render the form control
    return FormControl({path, options, label, explanation, validate, size, disabled, onChange});
  }
  else if(value !== undefined) {
    // a value was provided
    return Widget({value, options, label, explanation, validate, size, disabled, onChange});
  }
  // something is missing
  return (
    <Alert intent="error" size="small" title="Implementation Error">
      Expected the path property (if control is inside a Form) or the value property.
    </Alert>
  )
}


type FormControlProps = {
  path: string,
  options: DropdownOption[],
  label?: string,
  explanation?: string,
  validate?: any,
  size:"small"|"regular",
  disabled?: boolean,
  onChange?: (value:any, validation:Validation) => void,
}
function FormControl({path, options, label, explanation, validate, size, disabled=false, onChange}:FormControlProps) {
  // state
  const [initialValue, setInitialValue] = useState<string>("");

  // context
  const form = useContext(FormContext);

  // mount
  useEffect(() => {
    if(form.entity && path) {
      // setting initial value
      const value = _.get(form.entity, path); // TODO what if no value is found?
      setInitialValue(value);
    }
  }, [form.entity, validate, path])

  // form.resetIndex changed -> reset the item
  useEffect(() => {
    if(form.resetIndex !== 0) {
      setInitialValue(form.getOldValue(path))
    }
  }, [form.resetIndex, path, form])

  // to make sure the displayed value also changes when changed externally (i.e. another TextInput serving the same path or when manually trigger an onChangeField event on FormContext)
  useEffect(() => {
    const newValue = form.getNewValue(path);
    if(newValue) {
      if(form.getNewValue(path) !== initialValue) {
        setInitialValue(newValue);
      }
    }
  }, [form.version]);

  // handler
  const onWidgetChange = (value:string, validation: Validation) => {
    form.onFieldChange(path, value, validation)
    if(onChange) {
      onChange(value, validation);
    }
  }

  // render
  return <Widget value={initialValue} options={options} label={label} size={size} explanation={explanation} validate={validate} onChange={onWidgetChange} />
}

type WidgetProps = {
  value: string
  options: DropdownOption[],
  label?: string,
  explanation?: string,
  size:"small"|"regular",
  disabled?: boolean,
  validate?: (v:string) => Validation,
  onChange?: (value:any, validation: Validation) => void,
}
function Widget({value, options, label, explanation, size, disabled=false, validate, onChange}:WidgetProps) {
  // state
  const [selectedValue, setSelectedValue] = useState<any>(value);
  const [selectedValueString, setSelectedValueString] = useState<string>(JSON.stringify(value));
  const [validation, setValidation] = useState<Validation>(new Validation(true));

  // value changes
  useEffect(() => {
    const validation = validate ? validate(value) : new Validation(true);
    setValidation(validation);
    setSelectedValue(value);
    setSelectedValueString(JSON.stringify(value));
  }, [value]);

  // pre-render 
  let opts = options.map(o => {
    const v = JSON.stringify(o.value);
    return <option value={v} key={v}>{o.label}</option>
  })

  // handler
  const onSelectChange = (e:React.ChangeEvent<HTMLSelectElement>) => {
    // get updated value and validation
    const selectedValueString = e.target.value;
    const selectedValue = JSON.parse(selectedValueString);
    const validation = validate ? validate(selectedValue) : new Validation(true);
    
    // update state
    setSelectedValue(selectedValue);
    setSelectedValueString(selectedValueString);
    setValidation(validation);
    // inform onChange listeners
    if(onChange) {
      onChange(selectedValue, validation);
    }
  }

  // render
  return (
    <div className={`${CSS.container} ${CSS[size]}`}>
      <Label label={label} explanation={explanation}>
        <select value={selectedValueString} onChange={onSelectChange} disabled={disabled}>
          {opts}
        </select>
        <ValidationError validation={validation} />
      </Label>
    </div>
  )
}