import _ from "lodash";
import Api from "../../../util/api2"; 
import { ActivityMapper } from "./Activity.Mapper";
import { Activity, ActivityRegionVariant } from "./Activity";
import { RepositoryFactory } from "../_Common/RepositoryFactory";


export class ActivityRepository {
  private static mapper = new ActivityMapper();
  
  // factory methods
  static make = RepositoryFactory.make<Activity, ActivityMapper>(this.mapper);
  static search = RepositoryFactory.search<Activity, ActivityMapper>(this.mapper);
  static create = RepositoryFactory.create<Activity, ActivityMapper>(this.mapper);
  static update = RepositoryFactory.update<Activity, ActivityMapper>(this.mapper);
  static remove = RepositoryFactory.remove<Activity, ActivityMapper>(this.mapper);
  static findById = RepositoryFactory.findById<Activity, ActivityMapper>(this.mapper);

  /**
   * Returns one activity that matches the filter
   * @param filter 
   * @returns 
   */
  static async findOneByFilter(filter: any): Promise<Activity | null> {
    const allActivities = await ActivityRepository.search(filter);
    if(allActivities.length === 0) {
      return null;
    } 
    else {
      return allActivities[0];
    }
  }

  /**
   * Returns all slugs that are already used by other activities
   * @param activityId 
   * @returns 
   */
  static async getReservedSlugs(activityId: string) {
    // get all slugs
    const filter = {}
    const allActivities = await ActivityRepository.search(filter);
    const allSlugs = allActivities.map(a => {return {activity_id:a._id, de:a.slug.de, en:a.slug.en}});
    // get reserved slugs
    const reservedSlugValues = allSlugs
      .filter((s:any) => {return s.activity_id !== activityId && s.de !== undefined && s.de !== ''})
      .map((s:any) => {return s.de.toLowerCase()})
    // done
    return reservedSlugValues;
  }

  /**
   * Retrievs all tags that are used in activities
   * @returns {string[]} tags
   */
  static async getUsedTags(): Promise<string[]> {
    const apiResult = await Api.post("activities", "getUsedTags", {});
    return apiResult.data.tags;
  }

  /**
   * Returns activities are subactivity is part of
   * @param {string} subactivityId 
   */
  static async getParentActivities(subactivityId:string): Promise<Activity[]> {
    const filter = {"subactivities.activity_id":subactivityId};
    const activitiesUnfiltered = await ActivityRepository.search(filter);
    const activities = activitiesUnfiltered.filter(a => {
      return a.regionVariants.some((rv:any) => rv.isActive);
    });
    return activities;
  }

  /**
   * Returns all active main activities (i.e. .isHidden and .isSubactivity items are ignored)
   */
  static async getAllActiveMain(): Promise<Activity[]> {
    const filter = {$and:[
      {isHidden: {$ne:true}},
      {isSubactivity: {$ne:true}},
      {"regionVariants.isActive": true}
    ]}
    const activities = await ActivityRepository.search(filter);
    return activities;
  }

  /**
   * Returns all activities
   */
  static async findAll(): Promise<Activity[]> {
    const activities = await ActivityRepository.search({});
    return activities;
  }

  /**
   * Updates the region variant with the given id
   * @param activityId 
   * @param regionVariantId 
   * @param changeset 
   * @returns 
   */
  static async updateRegionVariant(activityId:string, regionVariantId:string, changeset:Partial<ActivityRegionVariant>): Promise<Activity> {
    // if the changeset contains the price configuration we also update the (obsolete?) price property
    if(!_.isNil(changeset.priceConfigurations) && _.isNil(changeset.price)) {
      if(changeset.priceConfigurations.length > 0) {
        const minConfiguration = changeset.priceConfigurations.reduce((prev:any, curr:any) => {
          return (curr.participantCount < prev.participantCount) ? curr : prev;
        }, changeset.priceConfigurations[0]);
        changeset.price = minConfiguration.price / minConfiguration.participantCount;
      }
      else {
        changeset.price = 0;
      }
    }
    // update and return updated activity
    const payload = {activityId, regionVariantId, set:changeset};
    const apiResult = await Api.post("activities", "updateRegionVariant", payload);
    return this.mapper.fromDb(apiResult.data.activity);
  }

  /**
   * Copies a region variant
   * @param activityId 
   * @param sourceRegionCode 
   * @param targetRegionCode 
   * @returns 
   */
  static async copyRegionVariant(activityId:string, sourceRegionCode:string, targetRegionCode:String): Promise<Activity> {
    // get activity
    const activity = await this.findById(activityId);
    if(!activity) {
      throw new Error(`Activity with id ${activityId} not found`);
    }
    // get the source variant
    const source = activity.regionVariants.find((rv:any) => rv.regionCode === sourceRegionCode);
    if(!source) {
      throw new Error(`Region variant with code ${sourceRegionCode} not found`);
    }
    // get the target variant
    let target = activity.regionVariants.find((rv:any) => rv.regionCode === targetRegionCode);
    if(!target) {
      throw new Error(`Region variant with code ${targetRegionCode} not found`);
    }
    // apply props (simply clone it, because we don't want to copy ObjectIds)
    const changeset:Partial<ActivityRegionVariant> = {}
    changeset.availableMonths = source.availableMonths.map((m:any) => m);
    changeset.availableWeekdays = source.availableWeekdays.map((wd:any) => wd); 
    changeset.benefits = source.benefits.map((b:any) => {return {de:b.de, en:b.de}});  
    changeset.comments = source.comments;  
    changeset.conditions = source.conditions.map((c:any) => {
      return { condition_id:c.condition_id, title:{de:c.title.de, en:c.title.en}}
    })
    changeset.duration = { minutes:source.duration.minutes}
    changeset.equipment = source.equipment.map((e:any) => {
      return { equipment_id:e.equipment_id, title:{de:e.title.de, en:e.title.de}}
    })
    changeset.isActive = source.isActive
    changeset.isSoldOut = false
    changeset.options = source.options.map((o:any) => {
      return {
        name:{de:o.name.de, en:o.name.en},
        description:{de:o.description.de, en:o.description.en},
        minUnits:o.minUnits,
        maxUnits:o.maxUnits,
        price:o.price
      }
    })
    changeset.participants = {min:source.participants.min, max:source.participants.max}  
    changeset.price = source.price || 0;
    changeset.price = 0;
    changeset.priceConfigurations = source.priceConfigurations.map((pc:any) => {
      return {
        participantCount: pc.participantCount,
        price: pc.price
      }
    });
    changeset.providers = [];
    changeset.weatherConditions = source.weatherConditions.map((wc:any) => {
      return { weatherCondition_id:wc.weatherCondition_id, title:{de:wc.title.de, en:wc.title.end}}
    });
  
    // update
    const updatedActivity = await this.updateRegionVariant(activityId, target._id!, changeset);
    return updatedActivity;
  }
}

