import React from "react";
//import Code from "../Code";
import {Modal, useModal, Heading, Button} from "../../../controls";

export default function TabsDemo() {
  const [modal, setModal] = useModal<"one"|"two">();

  // 
  const onClickOpenOne = () => {
    setModal("one", "Dialog One", {title:"Hello there"});
  }

  const onClickOpenTwo = () => {
    setModal("two", "Dialog Two");
  }

  // render
  return <>
    <Heading>Tabs</Heading>

    <Button onClick={onClickOpenOne}>open ONE</Button>
    <Button onClick={onClickOpenTwo}>open TWO</Button>
    
    <Modal {...modal}>
      {modal.action === "one" && <One closeModal={modal.onClose} {...modal.params} />}
      {modal.action === "two" && <Two closeModal={modal.onClose} />}
    </Modal>
  </>
}

type OneProps = {
  title: string,
  closeModal: () => void
}
function One({title, closeModal} : OneProps) {
  return (
    <div>
      <h1>{title}</h1>
      <Button onClick={closeModal}>close</Button>
    </div>
  )
}

type TwoProps = {
  closeModal: () => void
}
function Two({closeModal}: TwoProps) {
  return (
    <div>
      <h1>Two</h1>
      <Button onClick={closeModal}>close</Button>
    </div>
  )
}
