import { Incident } from "./Incident";
import { Mapper } from "../_Common/Mapper";

export class IncidentMapper extends Mapper<Incident> {
  public apiEndpoint = "incidents";

  public toDb(incident: Incident): any {
    return {
      _id: incident._id,
      surpriseId: incident.surpriseId,
      categoryId: incident.categoryId,
      subcategoryId: incident.subcategoryId,
      blamedProviderId: incident.blamedProviderId,
      blamedProviderName: incident.blamedProviderName,
      notes: incident.notes,
      operator: incident.operator
    }
  }

  public fromDb(obj:any): Incident {
    return {
      _id: obj._id,
      surpriseId: obj.surpriseId,
      categoryId: obj.categoryId,
      subcategoryId: obj.subcategoryId,
      operator: obj.operator,
      blamedProviderId: obj.blamedProviderId || null,
      blamedProviderName: obj.blamedProviderName || null,
      notes: obj.notes || ""
    }
  }

}