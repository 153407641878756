import _ from "lodash";
import React, { useEffect, useState } from "react";
import Util from "../../../util/util";
import * as TS from "../../../types";
import { Breadcrumb, Card, Heading, Loader, Table, Td, Tr } from "../../controls";

export default function SurpriseData(props:any) {
  // state
  const [adventure, setAdventure] = useState<TS.Adventure|null>(null);

  // id in url changed, load the surprise
  useEffect(() => {
    const load = async() => {
      const _id = _.get(props, "match.params.id");
      if(_id) {
        const adventures = await TS.Adventure.search({_id});
        setAdventure(adventures[0]);
      }
    }
    load();
  }, [props.match.params.id]);

  // render loading
  if(!adventure) {
    return <Loader />
  }

  // render content
  let content = <PurchaseMe adventure={adventure} />;

  // render
  const title = `Überraschung von ${adventure.ReserverName} für ${adventure.RecieverName}`;
  return (
    <div>
      <Breadcrumb links={[ {title:'Home', to:'/'}, {title:'Überraschungen', to:'/surprises/search'}, {title:title, to:`/surprise/${adventure._id}`}]} location="Data" />
      <Heading>{title}</Heading>
      {content}
    </div>
  )
}

type Field = {
  name: string,
  label: string,
  value: string
}

/***
 * Displays data set when customer buys a 'me' adventure
 */
function PurchaseMe({adventure} : {adventure:TS.Adventure}) {
  // fields
  const fields: Field[] = [
    {name: "Status", label: "Status", value: TS.Adventure.getStatusName(adventure.Status)},
    {name: "RecieverName", label:"Name Empfänger", value: adventure.RecieverName || ""},
    {name: "RecieverEmail", label:"E-Mail Empfänger", value: adventure.RecieverEmail || ""},
    {name: "ReserverName", label:"Name Käufer", value: adventure.ReserverName || ""},
    {name: "RecieverEmail", label:"E-Mail Käufer", value: adventure.ReserverEmail || ""},
    {name: "Order.InvoiceAddress.FirstName", label: "Vorname Käufer", value: adventure.Order.InvoiceAddress.FirstName || ""},
    {name: "Order.InvoiceAddress.LastName", label: "Familienname Käufer", value: adventure.Order.InvoiceAddress.LastName || ""},
    {name: "UserStartTime", label: "gewähltes Startdatum", value: adventure.UserStartTime ? Util.printDateAndTime(adventure.UserStartTime.toISOString()) : "kein Datum gewählt"},
    {name: "UserStartLocationText", label: "gewählter Startort (Text)", value: adventure.UserStartLocationText || ""},
    {name: "UserStartLocation", label: "gewählter Startort (Koordinaten)", value: adventure.UserStartLocation ? adventure.UserStartLocation.toString() : ""},
    {name: "PreferredModeOfTransport", label: "bevorzugtest Transportmittel", value: adventure.PreferredModeOfTransport || "-"},
    {name: "Order.Me.ActivityTypes", label: "gewählte Interessen", value: adventure.Order.Me ? adventure.Order.Me.ActivityTypes.join(',') : ""},
    {name: "Order.Me.DurationInDays", label: "Dauer in Tagen", value: adventure.Order.Me ? String(adventure.Order.Me.DurationInDays) : ""},
    {name: "Order.Me.Accommodation", label: "Art der Unterbringung", value: adventure.Order.Me ? adventure.Order.Me.Accommodation || "-" : "-"},
    {name: "Order.Me.AccommodationPrice", label: "Preis pro Nacht pro Person", value: adventure.Order.Me ? adventure.Order.Me.AccommodationPrice.toFixed() || "-" : "-"},
    {name: "TakeIntoAccount.Allergies", label:"Allergien", value: adventure.TakeIntoAccount.Allergies.join(", ")},
    {name: "TakeIntoAccount.Anxieties", label:"Ängste", value: adventure.TakeIntoAccount.Anxieties.join(", ")},
    {name: "Order.Me.ParticipantCount", label: "Anzahl Teilnehmer", value: adventure.Order.Me ? String(adventure.Order.Me.ParticipantCount) : ""},
    {name: "Order.Me.ParticipantBudget", label: "Budget pro Teilnehmer", value: adventure.Order.Me ? String(adventure.Order.Me.ParticipantBudget) : ""},
    {name: "Budget", label: "Budget", value: adventure.Budget.toFixed(2)},
    {name: "Order.Voucher", label: "Promocode", value: adventure.Order.Voucher ? adventure.Order.Voucher.toString() : "-"},
    {name: "Price", label: "Preis", value: adventure.Price.toFixed(2)},
  ];

  // render rows
  const rows = fields.map((field, index) => {
    return (
      <Tr key={index}>
        <Td label>{field.label}</Td>
        <Td>{field.value}</Td>
      </Tr>
    )
  });
  // render
  return (
    <Card title="Me Einkauf">
      <Table>
        {rows}
      </Table>
    </Card>
  )
}