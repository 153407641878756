import React, {useEffect, useState} from "react";
import { Decimal } from "decimal.js"
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";


// types
export class VendorInvoiceExtended extends TS.VendorInvoice {
  __selected:boolean = false;
  __allocations: any;
  __sepa: any;
}

export type DatasetName = "unpaid"|"unpaidByDate"|"paid"|"outgoing";

export type Data = {
  datasetName: DatasetName, 
  datasetDate:Date, 
  datasetYear: number|null,
  invoices:VendorInvoiceExtended[],
  sepaDisabled: boolean,
  selectedSum: number,
  selectedCount: number
};

export type Status = "loading"|"ready";

export type VendorInvoicesPostfilter = {
  providerId: string|null
  amount: number,
  amountCompare: "gt"|"lt"|"eq"|"",
  dateType: "invoice"|"due"|"payment"|"",
  dateCompare: "before"|"after"|"on",
  dateDate: Date,
}

// context
type VendorInvoicesContextType = {
  // values
  isMounted: boolean,
  status: Status,
  paymentTypes: DA.PaymentType[],
  data: Data,
  postfilter: VendorInvoicesPostfilter,
  // functions
  selectDataset: (l:DatasetName, d?:Date) => Promise<void>,
  selectDatasetYear: (year:number|null) => Promise<void>,
  reloadDataset: () => Promise<void>,
  toggleInvoices: (invoiceIds:string[], selected:boolean) => void,
  getSelectedInvoices: () => VendorInvoiceExtended[],
  setPostfilter: (postfilter:VendorInvoicesPostfilter) => void,
  resetPostfilter: () => void
}
const VendorInvoicesContext = React.createContext<VendorInvoicesContextType>({} as VendorInvoicesContextType);

// provider
type VendorInvoicesProviderProps = {
  children: React.ReactNode|Array<React.ReactNode>
}
function VendorInvoicesProvider({children} : VendorInvoicesProviderProps) {
  // state
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [status, setStatus] = useState<Status>("loading");
  const [paymentTypes, setPaymentTypes] = useState<DA.PaymentType[]>([]);
  const [data, setData] = useState<Data>({
    datasetName: "unpaid",
    datasetDate: new Date(), 
    datasetYear: new Date().getFullYear(),
    invoices: [],
    sepaDisabled: true,
    selectedCount: 0,
    selectedSum: 0
  });
  const [postfilter, setPostfilter] = useState<VendorInvoicesPostfilter>({
    providerId:null,
    amount: 100,
    amountCompare: "",
    dateType: "",
    dateCompare: "after",
    dateDate: new Date(),
  });

  // mount
  useEffect(() => {
    const load = async() => {
      // load available payment types
      const paymentTypes = await DA.PaymentTypeRepository.findAll();
      setPaymentTypes(paymentTypes);
      setIsMounted(true);
      // load data
      const data = await loadData("unpaid", new Date().getFullYear());
      setData(data);
      setStatus("ready");
    }
    load();
  }, []);

  // provider value
  const value = {
    // props
    isMounted,
    status,
    paymentTypes,
    data,
    postfilter,

    // functions
    selectDataset: async(n:DatasetName, d?: Date) => {
      setStatus("loading");
      setData(await loadData(n, data.datasetYear, d));
      setPostfilter(getEmptyPostfilter());
      setStatus("ready");
    },
    selectDatasetYear: async(year:number|null) => {
      setStatus("loading");
      setData(await loadData(data.datasetName, year));
      setStatus("ready");
    },
    reloadDataset: async() => {
      setStatus("loading");
      setData(await loadData(data.datasetName, data.datasetYear, data.datasetDate));
      setStatus("ready");
    },
    toggleInvoices: (invoiceIds:string[], selected:boolean) => {
      invoiceIds.forEach(invoiceId => {
        const invoice = data.invoices.find(inv => inv._id === invoiceId);
        invoice!.__selected = selected;
      })
      const selectedInfo = getSelectedInfo(data.invoices);
      const sepaDisabled = data.invoices.some(invoice => invoice.__selected && invoice.__sepa.ok === false);
      setData({...data, selectedCount:selectedInfo.count, selectedSum: selectedInfo.sum, sepaDisabled});
    },
    getSelectedInvoices: () => {
      return data.invoices.filter(invoice => invoice.__selected);
    },
    setPostfilter(postfilter:VendorInvoicesPostfilter) {
      setPostfilter(postfilter);
    },
    resetPostfilter() {
      setPostfilter(getEmptyPostfilter());
    }
  }
  return (
    <VendorInvoicesContext.Provider value={value}>
      {children}
    </VendorInvoicesContext.Provider>
  )
}


async function loadData(datasetName: DatasetName, datasetYear:number|null, datasetDate?:Date): Promise<Data> {
  let invoices: TS.VendorInvoice[];
  switch(datasetName) {
    case "unpaid":
      invoices = await TS.VendorInvoice.findUnpaid();
      break;
    case "unpaidByDate":
      invoices = await TS.VendorInvoice.findUnpaidByDate(datasetDate || new Date());
      break;
    case "outgoing":
      invoices = await TS.VendorInvoice.findOutgoing();
      break;
    case "paid":
      invoices = await TS.VendorInvoice.findPaid();
      if(datasetYear) {
        invoices = invoices.filter(invoice => {
          if(invoice.invoiceDate === null) {
            console.error("corrupt vendor invoice", invoice)
            // TODO this can only happen if an invoice is corrupted
            return false;
          }
          return invoice.invoiceDate!.getFullYear() === datasetYear
        })
      }
      break;
    default:
      invoices = [];
      break;
  }

  // enrich the invoices
  const extendedInvoices: VendorInvoiceExtended[] = invoices.map(invoice => {
    const extendedInvoice = invoice as VendorInvoiceExtended;
    // selected?
    extendedInvoice.__selected = false

    // check allocation
    extendedInvoice.__allocations = {ok:true, message: "Gesamter Rechnungsbetrag wurde Überraschungen zugewiesen"}
    let actualAmount = invoice.allocatedAmounts.reduce((accumulator:any, current:any) => { return accumulator.plus(new Decimal(current.amount))}, new Decimal(0))
    if(actualAmount.toNumber() !== invoice.amount) {
      extendedInvoice.__allocations = {ok:false, message:"zugewiesene Beträge stimmen nicht mit Rechnungsbetrag überein"}
    }

    // check if sepa (automatic) payment possible
    extendedInvoice.__sepa = {ok:true, message: "Diese Rechnung kann automatisch überwiesen werden"}
    if(invoice.provider.bankAccount === null || invoice.provider.billingAddress === null) {
      extendedInvoice.__sepa = {ok:false, message:"für automatische Zahlungen werden Bankverbindung und Zahlungsadresse des Anbieters benötigt"}
    }

    return extendedInvoice;
  })
  

  // done 
  return {
    datasetDate: datasetDate || new Date(),
    datasetName: datasetName,
    datasetYear: datasetYear,
    invoices: extendedInvoices,
    selectedCount: 0,
    selectedSum: 0,
    sepaDisabled: true
  };
}

function getSelectedInfo(invoices: VendorInvoiceExtended[]): {count:number, sum:number}  {
  let count = 0;
  const sum = invoices
    .filter(invoice => invoice.__selected)
    .reduce((accumulator, current) => {
      count++;
      return accumulator + current.amount
    }, 0);
  return {count, sum};
}

function getEmptyPostfilter(): VendorInvoicesPostfilter {
  return {providerId:null, amount: 100, amountCompare: "", dateType: "", dateCompare: "before", dateDate: new Date()};
}

export { VendorInvoicesContext, VendorInvoicesProvider }
