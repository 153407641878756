import _ from "lodash";
import Api from "../../../util/api2";
import { BulkOrderMapper } from "./BulkOrder.Mapper";
import { BulkOrder, BulkOrderCreatePayload } from "./BulkOrder";
import { RepositoryFactory } from "../_Common/RepositoryFactory";
import { ActivityRepository } from "../Activity/Activity.Repository";
import { RegionRepository } from "../Region/Region.Repository";
import { AdventureStatus } from "../../Adventure";


export class BulkOrderRepository {
  private static mapper = new BulkOrderMapper();
  
  // from factory
  static make = RepositoryFactory.make<BulkOrder, BulkOrderMapper>(this.mapper);
  static search = RepositoryFactory.search<BulkOrder, BulkOrderMapper>(this.mapper);
  static update = RepositoryFactory.update<BulkOrder, BulkOrderMapper>(this.mapper);
  static remove = RepositoryFactory.remove<BulkOrder, BulkOrderMapper>(this.mapper);
  static findById = RepositoryFactory.findById<BulkOrder, BulkOrderMapper>(this.mapper);
  // Note: We cannot use the RepositoryFactory since we need to create adventures. We have a bespoke create method instead (see below)

  static makeCreatePayload(): BulkOrderCreatePayload {
    return {
      count: 1, // number of prepaids to create
      itemValue: 100, // value in CHF per prepaid
      fees: {
        processing: 0,
        shipping: 0,
        packaging: 0
      },
      customer: {
        phone: "",
        firstName: "",
        lastName: "",
        company: "",
        street1: "",
        street2: "",
        zip: "",
        city: "",
        country: ""
      },
      packaging: {
        code: "METALLBOX",
        from: "",
        message: ""
      },
      notes: ""
    }
  }
  /**
   * Creates a bulk order
   * Note: this does not use the RepositoryFactory.create method because it needs to do some additional stuff which is done server-side
   */
  static async create(payload: BulkOrderCreatePayload): Promise<{success:boolean}> {
    const result = await Api.post("bulkorders", "create", payload);
    return result;
  }

  /**
   * Returns all bulk orders
   * @returns all bulk orders
   */
  static async findAll(): Promise<BulkOrder[]> {
    const bulkorders_unsorted = await BulkOrderRepository.search({});
    const bulkorders = bulkorders_unsorted.sort((a,b) => a.customer.company.localeCompare(b.customer.company)); 
    return bulkorders;
  }

  /**
   * 
   * @param {*} bulkOrderId 
   * @param {*} activityId 
   * @param {*} activityVariantId 
   * @param {*} participantCount
   * @param {*} receiverNames 
   * 
   * Note: does not currently support activities options and optional subactivities
   * Note: as just about every other fucking thing this had to be implemented in bloody hurry ... so don't expect any due dilligence in here
   */
  static async simulateBuddyProcess(bulkOrderId:string, activityId:string, activityVariantId:string, participantCount:string, receiverNames:string[]): Promise<any> {
    // get the bulk order
    const bulkOrder = await this.findById(bulkOrderId);
    if(!bulkOrder) {
      return { success: false, message: `Sammelbestellung mit id '${bulkOrderId}' nicht gefunden.`}
    }

    // get the activity and add elements so we can create the Surprise.Activities array
    // const activityResult = await Api.post("activities", "search", {filter: {_id:activityId}, projection: {}});
    // const activity = activityResult.data.items[0];
    const activity = await ActivityRepository.findById(activityId);
    if(!activity) {
      return { success:false, message: `Aktivität mit id '${activityId}' nicht gefunden` };
    }

    const regionVariant = activity.regionVariants.find((rv:any) => rv._id === activityVariantId);
    if(!regionVariant) {
      return { success: false, message: `Regionsvariante mit id '${activityVariantId}' nicht gefunden.` };
    }

    //regionVariant.participantCount = participantCount;
    const priceConfiguration = regionVariant.priceConfigurations.find((pc:any) => pc.participantCount === participantCount);
    
    // get the region
    // const regionResult = await Api.post("regions", "search", {filter: {code:regionVariant.regionCode}, projection:{}});
    // const region = regionResult.data.items[0];
    const region = await RegionRepository.findByCode(regionVariant.regionCode);
    if(!region) {
      return { success: false, message: `Region mit Code '${regionVariant.regionCode}' nicht gefunden.` };
    }

    // before we continue: make sure we have a all needed pieces
    if(!priceConfiguration) {
      return {success: false, message: "Für diese Aktivität ist keine Preis für diese Anzahl Teilnehmer hinterlegt"};
    }

    // create the activities array for the surprise
    let duration = 0;
    let total = 0;
    let remainder = 0;
    const activities = [activity].map(activity => {
      // calculate price & add to total
      let activityPrice = priceConfiguration.price;
      total += activityPrice;

      // duration
      let minutes = _.get(regionVariant, "duration.minutes");
      duration += isNaN(minutes) ? 0 : Number(minutes);

      // return mapped item
      return {
        // IDs
        ActivityId:activity._id,
        RegionVariantId:regionVariant._id,
        RegionCode:regionVariant.regionCode,

        // Texts
        Title: activity.title.de,
        ShortDescription: activity.shortDescription.de,
        LongDescription: activity.longDescription.de,
        AvailableTimes: "", // TODO
        AvailableAreas: "", // TODO

        // Flags
        IsHomeDelivery: activity.isHomeDelivery || false,
        IsOnlineEvent: activity.isOnlineEvent || false,

        // Properties
        Benefits: (regionVariant.benefits || []).map((v:any) => v.de),
        Equipment: (regionVariant.equipment || []).map((v:any) => v.title.de),
        Conditions: (regionVariant.conditions || []).map((v:any) => v.title.de),
        WeatherConditions: (regionVariant.weatherConditions || []).map((v:any) => v.title.de),

        // Configuration
        Configuration: {
          ParticipantCount: priceConfiguration.participantCount, 
          Price: priceConfiguration.price
        },

        // Bought Options
        Options: [],

        // Bought Subactivities
        Subactivities: [],

        // the calculated price (i.e. configuration price + options)
        Price: activityPrice
      }
    })

    // surprise changeset
    const update: any = {
      "Status": AdventureStatus.Ordered,
      "OldStatus": AdventureStatus.BuddySelected,
      "Region": region.title.de,
      "RegionCode": region.code,
      "Persons": participantCount,
      "Activities": activities,
      "Order.Prepaid.BuddyName": "Appentura Robot",
      "Order.Prepaid.BuddyEmail": "hello@appentura.ch",
      "Order.Prepaid.BuddySelectionComment": "",
      "Order.Prepaid.ValueUsed": total,
      "Order.Prepaid.ValueCharity": remainder,
      "Order.Prepaid.CharityId": null,
      "Order.Prepaid.CharityName": null,
      "EstimatedDuration": duration,
    }

    // apply to all adventures in bulk order
    let index = 0;
    for(let adventureId of bulkOrder.adventures) {
      update.RecieverName = receiverNames[index] || receiverNames[0];
      const payload = {id:adventureId, set:update};
      // TODO use DataAccess/AdventureRepository instead
      await Api.post("adventures", "update", payload);
      index++;
    }

    // seems to have worked
    return {success: true};
  }

}

