import React from "react";
import { Alert, Dropdown, TextInput, Validators, Link } from "../../../controls";

import CSS from "./IconSelector.module.scss";

const iconGroupOptions:any = [
  {value:"far", label:"Regular"},
  {value:"fas", label:"Solid"},
  {value:"fab", label:"Brands"}
];

type IconSelectorProps = {
  busy?: boolean
}
export function IconSelector({busy} : IconSelectorProps) {
  return (
    <div className={CSS.container}>
      <Dropdown label="Icon-Gruppe" path="iconGroup" options={iconGroupOptions} />
      <TextInput label="Icon-Name" path="iconName" placeholder="Name eingeben" disabled={busy} />
      <Alert size="small" intent="info" title="Icons wählen">
        Bei <Link to="https://fontawesome.com/v5.15/icons?d=gallery&p=2&m=free">Fontawesome</Link> eines der 'Free' Icons suchen, darauf klicken um die Gruppe zu eruieren. Name des Icons kopieren und hier Eintragen.
      </Alert>
    </div>
  );
  
}