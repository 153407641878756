import React, { useState } from "react";
import { Form, TextInput, Validators, Alert, Dropdown } from "../../controls";
import { Redirect} from 'react-router-dom';
import * as Cms from "../../../types/Cms";

// Note: first is used as default
// TODO not dry ... also used in Page.Properties.tsx
const DIRECTORY_OPTIONS = [
  {value:"/lp", label:"Landing Page (/lp)"},
  //{value:"/p/magazin", label:"Magazin (/magazin)"},
  //{value:"/p/firmenkunden", label:"Firmenkunden"}
]

type PageCreateProps = {
  closeDialog: () => void,
}
export default function PageCreate({closeDialog} : PageCreateProps) {
  // state
  const [entity, setEntity] = useState({title:"", directory:DIRECTORY_OPTIONS[0].value, slug:""});
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState<string|null>(null);
  const [redirectTo, setRedirectTo] = useState<string|null>(null);
  const [directoryOptions] = useState(DIRECTORY_OPTIONS);

  // user wants to abort
  const onCancel = () => {
    closeDialog();
  }


  // user wants to save
  const onSave = async(formResult:any) => {
    // update entity
    setBusy(true);
    const mergedEntity = formResult.merge(entity);
    setEntity(mergedEntity);

    // make path
    let path = mergedEntity.directory;
    if(mergedEntity.slug.trim() !== "") {
      path += `/${mergedEntity.slug.trim()}`
    }

    // create the page
    const result = await Cms.Page.create(mergedEntity.title, path);

    // evaluate result
    if(result.success) {
      // redirect
      setRedirectTo(`/pages/${result.page._id}`);
    }
    else {
      // we got an error
      setBusy(false)
      setError(result.errorMessage);
    }
  }

  // redirect?
  if(redirectTo) {
    return <Redirect to={redirectTo} />
  }

  // pre-render
  let errorAlert = null;
  if(error) {
    errorAlert = <Alert intent="error" title="Seite konnte nicht erstellt werden" size="small">{error}</Alert>
  }

  // render
  return <>
    <Form entity={entity} 
      onSave={onSave} onCancel={onCancel}
      busy={busy}
    >
      <div>
        <TextInput label="Title" path="title" placeholder="Titel eingeben" disabled={busy} validate={Validators.isRequired("Überschrift eingeben")} explanation="Überschrift der Seite. Kann später geändert werden." />
        <Dropdown label="Pfad (Basis-URL)" path="directory" options={directoryOptions} />
        <TextInput label="Url Erweiterung" path="slug" disabled={busy} validate={Validators.isSlug({allowEmpty:false})} placeholder="Url-Erweiterung eingeben" explanation="URL-Erweiterung, z.B. neue-angebote. Darf nur Buchstaben, Zahlen und Bindestrich enthalten" />
        
        {errorAlert}
      </div>
    </Form>
  </>
}
