import React from "react";
import {Card} from '../../controls'

import Meteocheck from "./Activity.Execution.Meteocheck";
import Providers from "./Activity.Execution.Providers";
import Instructions from "./Activity.Execution.Instructions";
import Subactivities from "./Activity.Execution.Subactivities";
import AvailableHours from "./Activity.Execution.AvailableHours";
import AvailableWeekdays from "./Activity.Execution.AvailableWeekdays";

export default function Execution() {
  // render
  return <Card title="Durchführung" expanded={false} collapsible={true}>
    <Meteocheck />
    <Subactivities />
    <Providers />
    <Instructions /> 
    <AvailableWeekdays />
    <AvailableHours />
    {/*
    
    */}
  </Card>
}