import React, { useContext, useState } from "react";
import JSZip from "jszip";
import { saveAs } from 'file-saver';
// components and context
import { Breadcrumb, Heading, Card, Loader, Button, Icons, Icon, Checkbox } from "../../controls";
import { SelfieProvider, SelfieContext, Selfie } from "./Selfie.Context";
import Filter from "./Selfie.Filter";
import Item from "./Selfie.Item";
// styling
import CSS from "./Selfie.module.scss";
import { Util } from "../../../util";

export default function Selfies() {
  // render
  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}]} location="Selfies" />
    <Heading>Selfies</Heading>
    <SelfieProvider>
      <Content />
    </SelfieProvider>
  </>
}

function Content() {
  // context
  const SC = useContext(SelfieContext);
  // state
  const [hideInfo, setHideInfo] = useState<boolean>(false);

  // pre-render
  let content = null;
  if(SC.loading) {
    content = <Loader />
  }
  else {
    const items = SC.selfies.map(selfie => <Item adventureImage={selfie.adventureImage} adventure={selfie.adventure} key={selfie.adventureImage._id} hideInfo={hideInfo} />);
    
    content = (
      <Card>
        <div className={CSS.options}><Checkbox value={hideInfo} onChange={v => setHideInfo(v)} secondaryLabel="Infos ausblenden" /></div>
        <div className={CSS.list}>{items}</div>
      </Card>
    )
  }
  // render
  return <>
    <Filter />
    <Selected />
    {content}
  </>
}

function Selected() {
  // state
  const [busy, setBusy] = useState<boolean>(false);
  // context
  const SC = useContext(SelfieContext);

  // user wants to download
  const onClickDownload = async() => {
    setBusy(true);

    // collect files
    const files: File[] = [];
    for(const selfie of SC.selected) {
      const imageUrl = selfie.adventureImage.ImageUrl; 
      // Fetch the image and parse the response stream as a blob
      const response = await fetch(imageUrl); // .then(response => response.blob());
      const imageBlob = await response.blob();
      // create a new file from the blob object
      const fileName = selfie.adventureImage.ImageUrl.split('/').pop();
      const imageFile = new File([imageBlob], fileName!);
      // add to list
      files.push(imageFile);
    }

    // Create a new instance of JSZip
    const zip = new JSZip();
    // create a folder and add items
    const folderName = "selfies"; // `selfies_${Util.printDateAndTime(new Date())}`;
    const folder = zip.folder(folderName);
    files.forEach(f => folder?.file(f.name, f));
    // Generate a zip file asynchronously and trigger the download */
    folder!.generateAsync({ type: "blob" }).then(content => saveAs(content, folderName));

    // done
    setBusy(false);
  }

  // user wants to deselect one
  const onClickDeselect = (selfie:Selfie) => {
    SC.toggleSelect(selfie);
  }

  // user wants to deselect all
  const onClickClear = () => {
    SC.clearSelected()
  }

  // pre-render items
  let images:any = SC.selected.map((s, index) => {
    return (
      <div className={CSS.image} key={index}>
        <img src={s.adventureImage.ImageUrlThumbnail} />
        <div className={CSS.delete} onClick={() => onClickDeselect(s)}>
          <Icon icon={Icons.Trash} />
        </div>
      </div>
    )
  });

  // render
  return (
    <div className={CSS.selectedDashboard}>
      <div className={CSS.images}>
        {images}
      </div>
      <div className={CSS.actions}>
        <Button busy={busy} size="small" onClick={onClickDownload}>download</Button>
        <Button busy={busy} size="small" onClick={onClickClear}>clear</Button>
      </div>

      <div className={CSS.summary}>
      {SC.selected.length} selfies selected.
      </div>
    </div>
  )
}