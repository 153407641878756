import React, {useState, useEffect} from "react"
import * as TS from "../../../../types";

import { Redirect } from "react-router-dom"
import { Alert, Card, ConfirmButton, Breadcrumb, Loader, Heading, Row } from "../../../controls";

import "./ActivitiesFix.scss";

type ActivitiesFixProps = {
  adventure: any
}
function ActivitiesFix({adventure}:ActivitiesFixProps) {
  const [busy, setBusy] = useState(true);
  const [data, setData] = useState<any>(null);
  const [excluded, setExcluded] = useState<any[]>([]);
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const load = async(adventure:any) => {
      let fixingInfo = await TS.AdventureOld.getActivityFixingInfo(adventure);
      setData({adventure:fixingInfo.adventure, activities:fixingInfo.activitiesThatNeedFixing});
      const exclude:any[] = [];
      fixingInfo.activitiesThatNeedFixing.forEach((ma:any) => {
        (ma.__subactivities || []).forEach((sa:any) => {
          if(sa.isOptionalSubactivity) {
            exclude.push(`${ma._id}_${sa._id}`);
          }
        })
      })
      setExcluded(exclude);
      setBusy(false);
    };
    load(adventure);
  }, [])

  // toggle inclusion of subactivity
  const toggleInclusion = (ma_id:any, sa_id:any, include:any) => {
    const id = `${ma_id}_${sa_id}`;
    const updated = excluded.filter(e => e !== id);
    if(!include) {
      updated.push(id);
    }
    setExcluded(updated);
  }

  const isIncluded = (ma_id:any, sa_id:any) => {
    const id = `${ma_id}_${sa_id}`;
    return excluded.includes(id) === false;
  }

  const fix = async() => {
    setBusy(true);
    const itemsToExclude = excluded.map(item => {
      const id_parts = item.split('_');
      return {activityId:id_parts[0], subactivityId:id_parts[1]}
    })
    await TS.AdventureOld.fixActivities(data.adventure, itemsToExclude);
    setIsFixed(true);
  }

  // render loading
  if(!data) {
    return <Loader />
  }

  // render redirect
  if(isFixed) {
    return <Redirect to={`/surprise/${data.adventure._id}`} />
  }
  
  // render
  let title = `Überraschung von ${data.adventure.ReserverName} für ${data.adventure.RecieverName}`
  const activities = data.activities.map((ma:any) => {
    const advActivity = data.adventure.Activities.find((a:any) => a.ActivityId === ma._id);
    
    // bought options
    let options = null
    const optionItems = (advActivity.Options || []).map((option:any, index:number) => {
      return <li key={index} className="option">{option.Title}, {option.Description}, {option.Count} &times; {option.UnitPrice.toFixed(2)} = {(option.Count * option.UnitPrice).toFixed(1)} </li>
    })
    if(optionItems.length === 0) {
      options = <div>keine Optionen vorhanden</div>
    }
    else {
    options = <ul>{optionItems}</ul>
    }

    // missing subactivities
    const subactivities = (ma.__subactivities || []).map((sa:any) => {
      return (
        <div key={sa._id}>
          <label>
            <input type="checkbox" checked={isIncluded(ma._id, sa._id)} onChange={e => toggleInclusion(ma._id, sa._id, e.target.checked)} disabled={sa.isOptionalSubactivity === false} />
            <span>{sa.title.de}</span>
          </label>
        </div>
      )
    })

    return (
      <div key={ma._id}>
        <Heading>{advActivity.Title}</Heading>
        <div className="activityfix-options">
          <div className="activityfix-subtitle">Folgende Optionen hat der Kunde gewählt:</div>
          <div className="activityfix-items">
            {options}
          </div>
        </div>
        <div className="activityfix-subactivities">
          <div className="activityfix-subtitle">Wähle die zugehörigen Unteraktivitäten:</div>
          <div className="activityfix-explanation">(nicht allen Optionen muss zwangsläufig eine Unteraktivität zuweisbar sein)</div>
          <div className="activityfix-items">
            {subactivities}
          </div>
        </div>
      </div>
    )
  })

  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}, {title:'Überraschungen', to:'/surprises/search'}]} location={title} />
    <Heading>{title}</Heading>
    <Card id="v-surprise-activitiesfix">
      <Alert title="Überraschung muss repariert werden" intent="info" size="large">
        Diese Überraschung enhält Aktivitäten, welche Unteraktivitäten enthalten.<br/>
        Diese Unteraktivitäten sind in der Überraschung jedoch nicht festgehalten. Um die Überraschung planen zu können, muss dieser Umstand behoben werden.
      </Alert>
      <div>
        <div>
          {activities}
        </div>
        <Row align="center">
          <ConfirmButton onConfirm={fix} busy={busy}>Problem beheben</ConfirmButton>
        </Row>
      </div>
    </Card>
  </>
}

export default ActivitiesFix