import { AdventureImage, AdventureImagePost } from "./AdventureImage";
import { Mapper } from "../_Common/Mapper";

export class AdventureImageMapper extends Mapper<AdventureImage> {
  public apiEndpoint = "adventureImages";

  public toDb(adventureImage: AdventureImage): any {
    return {
      _id: adventureImage._id,
      Bookmarked: adventureImage.Bookmarked,
      CreateDate: adventureImage.CreateDate,
      ChangeDate: adventureImage.ChangeDate,
      Step: adventureImage.Step,
      AdventureId: adventureImage.AdventureId,
      ImageUrl: adventureImage.ImageUrl,
      ImageUrlThumbnail: adventureImage.ImageUrlThumbnail,
      MarketingAllowed: adventureImage.MarketingAllowed,
      DeletedByUser: adventureImage.DeletedByUser,
      Tags: adventureImage.Tags,
      Posts: adventureImage.Posts.map(p => this.toDb_post(p))
    }
  }

  public toDb_post(post: AdventureImagePost): any {
    return {
      _id: post._id, 
      CreateDate: post.CreateDate,
      Channel: post.Channel,
      Url: post.Url,
      Notes: post.Notes
    }
  }

  public fromDb(obj:any): AdventureImage {
    return {
      _id: obj._id,
      Bookmarked: obj.Bookmarked ? true : false,
      CreateDate: obj.CreateDate ? new Date(obj.CreateDate) : new Date(),
      ChangeDate: obj.ChangeDate ? new Date(obj.ChangeDate) : new Date(),
      Step: obj.Step || 0,
      AdventureId: obj.AdventureId || "",
      ImageUrl: obj.ImageUrl || "",
      ImageUrlThumbnail: obj.ImageUrlThumbnail || "",
      MarketingAllowed: obj.MarketingAllowed ? true : false,
      DeletedByUser: obj.DeletedByUser ? true : false,
      Tags: obj.Tags || [],
      Posts: (obj.Posts || []).map((p:any) => this.fromDb_post(p))
    }
  }

  public fromDb_post(obj:any) : AdventureImagePost {
    return {
      _id: obj._id,
      CreateDate: obj.CreateDate ? new Date(obj.CreateDate) : new Date(),
      Channel: obj.Channel || "",
      Url: obj.Url || "",
      Notes: obj.Notes || ""
    }
  }

}