import React, {Component} from "react"

import Api from './../../../util/api'
import Util from './../../../util/util'

import {Button, Breadcrumb, Link, Loader, Card} from "../../controls";

import './Esr.scss'

class Esr extends Component {

  constructor(props) {
    super(props)

    this.state = {
      state:'selecting_file', // one of 'selecting_file', 'reading_file', 'posting_to_check', 'check_received', 'posting_to_process', 'process_received', 'error'
      check_result:undefined,
      process_result:undefined,
      error:undefined,
      data:undefined
    }

    this.onFileChange = this.onFileChange.bind(this)
    this.onReaderLoad = this.onReaderLoad.bind(this)
    this.onButtonProcessClick = this.onButtonProcessClick.bind(this)
    this.onButtonResetClick = this.onButtonResetClick.bind(this)

    this.reader = new FileReader()
    this.reader.addEventListener("load", this.onReaderLoad)

  }

  render() {
    let content = null
    switch(this.state.state) {
      case 'selecting_file':
        content = this.render_selecting_file()
        break
      case 'reading_file':
        content = this.render_reading_file()
        break
      case 'posting_to_check':
        content = this.render_posting_to_check()
        break
      case 'posting_to_process':
        content = this.render_posting_to_process()
        break
      case 'check_received':
        content = this.render_result_check()
        break
      case 'process_received':
        content = this.render_result_process()
        break
      case 'error':
        content = this.render_error()
        break
      default:
        break
    }
    return (
      <>
        <Breadcrumb links={[ {title:'Home', to:'/'}]}
                    location="ESR" />
        <Card title="ESR">
          {content}
        </Card>
      </>
    )
  }

  render_error() {
    return (
      <div>
        Ein Fehler is aufgetreten: {(this.state.error || {}).message || 'unbekannter Fehler'}
      </div>
    )
  }

  render_selecting_file() {
    return (
      <div>
        <input type="file" onChange={this.onFileChange} name="file-selector" />
        <label htmlFor="file-selector">File hochladen</label>
      </div>
    )
  }

  render_reading_file() {
    return (
      <div>
        lade File ...
        <Loader />
      </div>
    )
  }

  render_posting_to_check() {
    return this.render_posting("lade hoch zur Überprüfung ...")
  }

  render_posting_to_process() {
    return this.render_posting("lade hoch zur Verarbeitung ...")
  }

  render_posting(heading) {
    return (
      <div>
        <div>{heading}</div>
        <Loader />
      </div>
    )
  }

  render_result_check() {
    let action = (
      <div className="action">
        <Button onClick={this.onButtonProcessClick}>verarbeiten</Button>
      </div>
    )
    return this.render_result('Resultat der Überprüfung', this.state.check_result, action)
  }

  render_result_process() {
    let action = (
      <div className="action">
        <Button onClick={this.onButtonResetClick}>reset</Button>
      </div>
    )
    return this.render_result('Resultat der Verarbeitung', this.state.process_result, action)
  }

  render_result(heading, result, action) {
    let rows = result.map((item, index) => {
      let isOk = item.status.toLowerCase().startsWith('error') === false
      let link = null
      if(item.id) {
        link = <Link to={'/surprise/' + item.id} target="_blank">{item.id}</Link>
      }
      else {
        link = "unbekannt"
      }

      return (
        <tr key={index} className={isOk ? 'ok':'error'}>
          <td>{link}</td>
          <td>{item.line.reference}</td>
          <td>{Util.printDate(new Date(item.line.date))}</td>
          <td>{item.line.amount}</td>
          <td>{item.status}</td>
        </tr>
      )
    })

    return (
      <div>
        <h3>{heading}</h3>
        <table className="result">
          <thead><tr>
            <td>Überraschung</td>
            <td>Referenz-Nr.</td>
            <td>Datum</td>
            <td>Betrag</td>
            <td>Status</td>
          </tr></thead>
          <tbody>
            {rows}
          </tbody>
        </table>
        {action}
      </div>
    )
  }

  onFileChange(e) {
    this.setState({...this.state, state:'reading_file'})
    let file = e.target.files[0]
    if(file) {
      this.setState({...this.state,
        file:{
          prefix:this.props.prefix,
          name:file.name,
          size:file.size,
          type:file.type,
          //timestampName:true
        }
      })
      this.reader.readAsDataURL(file)
    }
  }

  onReaderLoad() {
    let data = this.reader.result
    this.setState({...this.state, data:data, state:'posting_to_check'})
    Api.post('/adventures/esr/check', data)
      .then(result => {
        this.setState({...this.state, check_result:result, state:'check_received'})
      })
      .catch(err => {
        this.setState({...this.state, error:err, state:"error"})
        console.error("error:", err.message, err)
      })
  }

  onButtonProcessClick(e) {
    let data = this.state.data
    this.setState({...this.state, state:'posting_to_process'})
    Api.post('/adventures/esr/process', data)
      .then(result => {
        this.setState({...this.state, process_result:result, state:'process_received'})
      })
      .catch(err => {
        this.setState({...this.state, error:err, state:"error"})
        console.error("error:", err.message, err)
      })
  }

  onButtonResetClick(e) {
    this.setState({...this.state, state:'selecting_file', data:undefined, check_result:undefined, process_result:undefined})
  }

}

export default Esr


/*

Check result arrives as an array of objects structured like so:

{
  "line":{
    "reference":"000000000000000000001234567",
    "amount":123.55,
    "date":"2017-11-14T00:00:00.000Z"
   },
   "status":"Error - Reference number unknown"
}
 */