import React from "react";
import CSS from "./Heading.module.scss";

type HeadingProps = {
  children: React.ReactNode|React.ReactNode[]
}
export function Heading({children}:HeadingProps) {
  return (
    <div className={CSS.container}>
      {children}
    </div>
  )
  
}