import React from "react";

import CSS from "./Button.module.scss";

type ButtonProps = {
  text?: string,
  href?: string
}
export function Button({text, href} : ButtonProps) {
  if(text && href) {
    return (
      <div className={CSS.container} onClick={() => alert(`href: ${href}`)}>
        <div className={CSS.inner}>
          {text}
        </div>
      </div>
    )
  }
  return null
}