import { useContext, useEffect, useState } from "react";
import * as TS from "../../../../types";
// import * as BL from "../../../../types/BusinessLogic";
import * as DA from "../../../../types/DataAccess";
import { Card, Icon, Icons, Link, Loader, Switchboard } from "../../../controls";
import { SurpriseContext } from "../Surprise.Context";
import CSS from "./Audit.module.scss";

export default function Audit() {
  // context
  const SC = useContext(SurpriseContext);
  // state
  const [audit, setAudit] = useState<TS.AdventureAudit | null>(null);
  const [show, setShow] = useState<Array<"all"|"failed"|"passed">>(["passed"]);

  // mount & status changed
  useEffect(() => {
    runAudit(SC.activityCatalogue);
  }, [SC.surprise.Status, SC.activityCatalogue, SC.steps]);

  // runs the audit
  const runAudit = async (activityCatalogue: DA.Activity[]) => {
    const adventure = TS.Adventure.fromDb(SC.surprise);
    const audit = new TS.AdventureAudit(adventure!, activityCatalogue);
    await audit.run(); // note: the run method makes sure the audit is only run if the status is relevant
    setAudit(audit);
  };

  // render nothing if surprise is not yet loaded or not in status ConditionsSent, ConditionsAccepted or Ready  
  if( SC.surprise === null || TS.AdventureAudit.isRelevantStatus(SC.surprise.Status) === false) {
    return null;
  }

  // pre-render
  let content = null
  let summaryContent = "";
  const hasIssues = audit && audit.failedItems.length > 0;
  if(!audit) {
    content = <Loader />;
  }
  else {
    summaryContent = hasIssues ? `${audit.failedItems.length} mögliche${audit.failedItems.length > 1 ? "" : "s"} Problem${audit.failedItems.length > 1 ? "e" : ""}` : "";
    content = audit.items
    .sort((a, b) => a.passed === b.passed ? 0 : a.passed ? 1 : -1)
    //.filter(item => show[0] === "all" || (show[0] === "failed" && item.passed === false) || (show[0] === "passed" && item.passed === true))
    .map((issue, index) => <Item item={issue} key={index} />);
  }
  
  // render
  return <>
    <Card title="Audit" summaryContent={summaryContent} icon={Icons.Microscope} collapsible={true} expanded={false} intent={hasIssues ? "warning" : "primary"}>
      <div className={CSS.container}>
        {content}
      </div>
    </Card>
  </>;
}

function Item({ item: item }: { item: TS.AdventureAuditItem }) {
  let hint = null;
  if(item.passed === false) {
    hint = <div className={CSS.hint}>{item.hint}</div>
  }
  let link = null;
  if(item.relatedLink && item.passed === false) {
    link = (
      <div className={CSS.link}>
        <Link to={item.relatedLink.url} target="_blank">
          <Icon icon={Icons.ArrowRight} /> {item.relatedLink.title}
        </Link>
      </div>
    );    
  }
  
  return (
    <div className={CSS.item}>
      <div className={`${CSS.icon} ${item.passed ? CSS.pass : CSS.failed}`}>
        <Icon icon={ item.passed ? Icons.CheckCircle : Icons.ExclamationCircle} />
      </div>
      {/* 
      <div className={`${CSS.icon}`} title={item.severityString}>
        <Icon icon={getSeverityIcon(item.severity)} tooltip={item.severityString} />
      </div>
      */}
      <div className={CSS.text}>
        <div className={CSS.title}>{item.title}</div>
        <div className={CSS.description}>{item.description}</div>
        {hint}
        {link}
      </div>
    </div>
  );
}

function getSeverityIcon(severity: TS.AdventureAuditItemSeverity) {
  switch (severity) {
    case 1:
      return Icons.ExclamationCircle;
    case 2:
      return Icons.ExclamationTriangle;
    case 3:
      return Icons.Bomb;
  }
}