import React, {useState, useContext, useEffect} from "react";
import * as TS from "../../../../types";
import * as DA from "../../../../types/DataAccess";
import { Card, Icons, Modal, Value } from "../../../controls";
import {SurpriseContext} from "../Surprise.Context";
import OperatorEdit from "./Operator.Edit";

export default function Operator() {
  // context
  const SC = useContext(SurpriseContext);
  // state
  const [mode, setMode] = useState<"edit"|"view">("view");
  const [operator, setOperator] = useState<any>(null);

  // mount
  useEffect(() => {
    const load = async() => {
      const user = await DA.UserRepository.findByEmail(SC.surprise.Operator || "");
      setOperator(user);
    }
    load();
  }, [SC.surprise.Operator])

  // user wants to start/stop editing
  const startEdit = () => setMode("edit");
  const stopEdit = () => setMode("view");

  // render
  return <>
    <Card title="Operator" icon={Icons.User} collapsible={false}>
      <Value onClick={startEdit}>
        {operator ? operator.firstName : SC.surprise.Operator || "noch kein Operator zugewiesen"}
      </Value>
    </Card>
    <Modal isOpen={mode==="edit"} title="Operator zuweisen" onClose={stopEdit}>
      <OperatorEdit stopEdit={stopEdit} />
    </Modal>
  </>
}