import _ from "lodash";
import React, { useState, useMemo, useEffect } from "react";
// helper & types
import Util from "../../../util/util";
import * as DA from "../../../types/DataAccess";
import * as BL from "../../../types/BusinessLogic";

// components
import {Redirect} from 'react-router-dom';
import {Breadcrumb, Modal, Card, Heading, Form, List, TextInput, Validators, Loader, ListColumn, ListFilter} from "../../controls";
// icons
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faCircle, faCheck} from "@fortawesome/free-solid-svg-icons";
// styling
import "./Providers.List.scss";

type ListItem = {
  _id: string,
  name: string,
  ratingColor: string, 
  ratingColorSort: number,
  hasDefaultLocation: boolean, 
  hasDefaultLocationSort: number,
  isActive: boolean, 
  isActiveSort: number,
  bookingKind: string
}

export default function Providers(props:any) {
  // state
  const [mode, setMode] = useState<"create"|"view">("view");

  // memo
  const columns: ListColumn[] = useMemo(() => {
    return [
      {header:"Rating", path:"rating", print: (v:ListItem) => <Icon icon={faCircle} style={{color:v.ratingColor}} />, sort: (a:ListItem, b:ListItem) => a.ratingColorSort > b.ratingColorSort ? 1 : -1},
      {header:"Name", path:"name", key:"name"},
      {header:"Treffpunkt definiert", path:"hasDefaultLocation", print: (v:ListItem) => v.hasDefaultLocation ? <Icon icon={faCheck} />:null, sort: (a:ListItem, b:ListItem) => a.hasDefaultLocationSort > b.hasDefaultLocationSort ? 1 : -1},
      {header:"Aktiv", path:"isActive", print: v => v.isActive ? <Icon icon={faCheck} />:null, sort: (a, b) => a.isActiveSort > b.isActiveSort ? 1 : -1},
      {header:"Buchung via", path:"bookingKind"}
    ]
  }, []);

  // get route id and save to sate
  const [routeId] = useState(Util.getRouteId(props));
  // state
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [items, setItems] = useState<ListItem[]>([]);
  const [redirect, setRedirect] = useState<string|null>(null);
  const [filters] = useState<ListFilter[]>([
    {
      label:"inaktive anzeigen", 
      kind:"checkbox", 
      initialValue: false,
      onChange: (checked:any) => { 
        load(checked);
      }
    }
  ]);

  // mount
  useEffect(() => {
    load(false);
  }, [])

  // mount with route id
  useEffect(() => {
    if(routeId) {
      setRedirect(routeId);
    }
  }, [routeId]);

  // loads all items
  const load = async(showInactive:boolean) => {
    setIsLoaded(false);
    // load providers
    const providers = await DA.ProviderRepository.search({});
    // convert to list items
    const items:ListItem[] = providers.map((p:DA.Provider) => {
      const ratingColor = (p.colorRating || "silver").toLowerCase();
      const ratingColorSort = ["green", "orange", "red"].findIndex(v => v === ratingColor) || 99;
      const hasDefaultLocation = BL.Provider.getDefaultLocation(p) !== null;
      const hasDefaultLocationSort = hasDefaultLocation ? 1 : 2;
      const isActive = p.isActive;
      const isActiveSort = p.isActive ? 1 : 2;
      const bookingKind = p.booking[0] ? p.booking[0].kind : "-";
      return {
        _id: p._id!,
        name: p.name,
        ratingColor, ratingColorSort,
        hasDefaultLocation, hasDefaultLocationSort,
        isActive, isActiveSort,
        bookingKind,
      }
    }).filter(item => {
      if(item.isActive === false) {
        return showInactive
      }
      else {
        return true;
      }
    });
    // update state
    setItems(items);
    setIsLoaded(true);
  }

  // start / stop creating a new entry
  const startCreate = () => setMode("create");
  const stopCreate = () => setMode("view");

  // item created
  const onCreated = async(providerId:string) => {
    setRedirect(providerId);
  }

  // redirect?
  if(redirect) {
    return <Redirect to={`/providers/${redirect}`} />
  }

  // render content
  let content = <Loader />;
  if(isLoaded) {
    content = <>
      <List 
        columns={columns} 
        items={items} 
        getKey={v => v._id} 
        onRowClick={item => 
        setRedirect(item._id)} 
        sortColumnKey="name" 
        //filter={listFilter} 
        filters={filters} 
        onClickCreate={startCreate}
      />

      <Modal isOpen={mode==="create"} onClose={stopCreate} title="Anbieter erfassen">
        <Create onCreated={onCreated} stopCreate={stopCreate} />
      </Modal>
    </>;
  }

  // render
  return <>
    <Breadcrumb 
      links={[ {title:'Home', to:'/'}]}
      location="Anbieter" 
    />
    <Heading>Anbieter</Heading>
    <Card id="v-providers-list">
      {content}
    </Card>
  </>
}

type CreateProps = {
  onCreated: (provider_id:string) => void,
  stopCreate: () => void,
}
function Create({onCreated, stopCreate}:CreateProps) {
  // state
  const [entity, setEntity] = useState<{name:string}|null>(null);
  const [busy, setBusy] = useState(true);

  // mount
  useEffect(() => {
    setEntity({name:""})
    setBusy(false);
  }, []);

  // save
  const onSave = async(formResult:any) => {
    const d = formResult.merge(entity);
    const provider = DA.ProviderRepository.make();
    provider.name = d.name;
    provider.isActive = true;
    const result = await DA.ProviderRepository.create(provider);
    const id = result.data!._id!;
    onCreated(id);
    stopCreate();
  }

  // render
  return <Form
    entity={entity}
    onSave={onSave} labelSave="speichern"
    onCancel={stopCreate} labelCancel="abbrechen"
  >
    <TextInput 
      disabled={busy} 
      label="Name" 
      path="name" 
      placeholder="Name, Ort" 
      validate={Validators.isRequired("Name des Anbieters angeben (Name, Ort)")}  
    />
  </Form>
}