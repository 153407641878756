import React, {useContext, useState, useEffect} from "react";
import * as TS from "../../../../types";
import * as DataAccess from "../../../../types/DataAccess";
import { Actions, Button, Icon, Icons, List, Loader, AddRemove, AddRemoveItem, Modal, CardSection} from "../../../controls";
import { ActivityContext } from "../ActivityContext";

export default function Conditions() {
  const [mode, setMode] = useState<"edit"|"view">("view");

  // context
  const AC = useContext(ActivityContext)!;

  // stops edit
  const stopEdit = () => setMode("view");

  // users wants to edit
  const startEdit = () => setMode("edit");

  // saves changes
  const saveEdit = async(updatedConditions: TS.ActivityRegionVariantCondition[]) => {
    const changeset = {conditions: updatedConditions.map(c => c.toDb())};
    await AC.updateRegionVariant(AC.regionVariant!._id!, changeset);
    stopEdit();
  }

  // render
  return <>
    <CardSection title="Bedingungen">
      <List 
        columns={[{header:"Bedingung", path:"title.de"}]} 
        items={AC.regionVariant!.conditions} 
        getKey={v => v._id}
        hideHeader={true} hideFooter={true} hideSearch={true} 
      />
      <Button onClick={startEdit} size="small"><Icon icon={Icons.Edit} /> bearbeiten</Button>
    </CardSection>
    <Modal isOpen={mode === "edit"} title="Regionsvariante - Bedingungen" onClose={stopEdit}>
      <Edit conditions={AC.regionVariant!.conditions} saveEdit={saveEdit} onCancel={stopEdit} />
    </Modal>
  </>
}

type EditProps = {
  conditions: TS.ActivityRegionVariantCondition[],
  saveEdit: (updatedConditions: TS.ActivityRegionVariantCondition[]) => void,
  onCancel: () => void,
}
function Edit({conditions, saveEdit, onCancel} : EditProps) {
  // state
  const [items, setItems] = useState<AddRemoveItem[]>([]);
  const [busy, setBusy] = useState(false);
  const [catalogue, setCatalogue] = useState<DataAccess.Condition[]|null>(null);

  // mount
  useEffect(() => {
    const load = async() => {
      const catalogue: DataAccess.Condition[] = await DataAccess.ConditionRepository.searchAll(); 
      const items: AddRemoveItem[] = catalogue.map(catalogueItem => {
        const selected = conditions.some(c => c.condition_id === catalogueItem._id);
        return {
          selected,
          label: catalogueItem.title.de,
          id: catalogueItem._id!
        }
      })
      setItems(items);
      setCatalogue(catalogue);
    };
    load();
  }, []);

  // save the update
  const onSave = async() => {
    setBusy(true);
    const updatedConditions : TS.ActivityRegionVariantCondition[] = items
      .filter(item => item.selected)
      .map(item => {
        // find item in catalogue
        const catalogueItem = catalogue!.find((ci:any) => ci._id === item.id);
        // create instance of region variant condition
        const condition = new TS.ActivityRegionVariantCondition();
        condition.title = TS.Translatable.fromDb(catalogueItem!.title);
        condition.condition_id = catalogueItem!._id;
        // add to collection
        return condition;
      })
    // save  
    await saveEdit(updatedConditions); 
    // done
    setBusy(false);
  }

  // still loading data?
  if(catalogue === null) {
    return <Loader text="lade Katalog der Bedingungen" />
  }

  // render
  return (
    <div>
      <AddRemove items={items} maxHeight={300} />
      <Actions align="center" onSave={onSave} onCancel={onCancel} busy={busy} />
    </div>
  )
}
