import { Module, ModuleInfo, Theme } from ".";
export class TrustBannerModule implements Module {
  readonly code: string;

  _id?: string;
  countries: string[];
  position: number;
  theme: Theme;
  anchor?: string;

  elements: {
  }

  constructor() {
    this.code = TrustBannerModule.info.code;
    this.countries = ["CH", "DE"];
    this.position = 0;
    this.theme = "primary";
    this.elements = {

    }
  }

  static get info(): ModuleInfo {
    return {
      code: "trustbanner",
      title: "TrustBanner-Modul",
      description: "TrustBanner (Bewertungen, Bekannt aus: TV, Zeitung)"
    }
  }
}