import { MarketingChannel } from "./MarketingChannel";
import { Mapper } from "../_Common/Mapper";

export class MarketingChannelMapper extends Mapper<MarketingChannel> {
  public apiEndpoint = "marketingChannels";

  public toDb(marketingChannel: MarketingChannel): any {
    return {
      _id: marketingChannel._id,
      name: marketingChannel.name,
    }
  }

  public fromDb(obj:any): MarketingChannel {
    return {
      _id: obj._id || undefined,
      name: obj.name || "",
    }
  }

}