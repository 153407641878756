import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
// types
import * as Cms from "../../../../types/Cms";
// contexts and components
import { PageContext } from "../Page.Context";
import { Actions, Card, Alert, TextInput, TextArea, Button, Form, Label, Paragraphs, Validators, SortableList, SortableListItem } from "../../../controls";
import * as Selectors from "../Selectors";
import * as Preview from "../Preview";

// styling and images
import CSS from "./Accordion.module.scss";

type AccordionProps = {
  instance?: Cms.AccordionModule,
  closeDialog: () => void,
}
export function Accordion({instance, closeDialog}:AccordionProps) {
  // context
  const PC = useContext(PageContext);
  
  // state
  const [mode, setMode] = useState<"properties"|"entries">("properties");
  const [itemToEdit, setItemToEdit] = useState<Cms.AccordionModuleItem|null>(null);
  const [entity, setEntity] = useState<Cms.AccordionModule|null>(null);
  const [busy, setBusy] = useState<boolean>(false);

  // mount
  useEffect(() => {
    let entity: Cms.AccordionModule;
    if(instance) {
      entity = instance;
    }
    else {
      entity = new Cms.AccordionModule();
    }
    setEntity(entity)
  }, [])

  // user wants to cancel
  const onCancel = () => {
    closeDialog();
  }

  // user wants to save
  const onSave = async(formResult:any) => {
    setBusy(true);
    const updatedEntity = formResult.merge(entity);
    
    if(formResult.changeset.countries) {
      // TODO we need to do this because formResult.merge does not seem to handle arrays correctly
      updatedEntity.countries = formResult.changeset.countries;
    }

    await PC.upsertModule(updatedEntity);

    // close dialog
    closeDialog();
  }

  // user wants to save an item
  const onSaveItem = async(itemToSave:Cms.AccordionModuleItem) => {
    if(entity) {
      // update or add the item
      const items = entity.elements.items.filter((item:Cms.AccordionModuleItem) => String(item.id) !== String(itemToSave.id));
      items.push(itemToSave);
  
      // get updated version of the module
      const updatedModule = _.cloneDeep(entity);
      updatedModule!.elements.items = items;
      
      // save the module
      await PC.upsertModule(updatedModule);

      // get the updated module and set it as the entity
      setEntity(updatedModule);
    }
  }

  // user wants to delete item
  const onClickDelete = async(itemToDelete:Cms.AccordionModuleItem) => {
    if(entity) {
      // update or add the item
      const items = entity.elements.items.filter((item:Cms.AccordionModuleItem) => String(item.id) !== String(itemToDelete.id));

      // get updated version of the module
      const updatedModule = _.cloneDeep(entity);
      updatedModule!.elements.items = items;
      
      // save the module
      await PC.upsertModule(updatedModule);

      // get the updated module and set it as the entity
      setEntity(updatedModule);
    }
  }

  // user wants to add/update item
  const onClickEditItem = (item:Cms.AccordionModuleItem) => {
    setItemToEdit(item);
    setMode("entries");
  }

  // user wants to add an item
  const onClickAddItem = () => {
    const newItem = new Cms.AccordionModuleItem();
    newItem.position = entity!.elements.items.length;

    setItemToEdit(newItem);
    setMode("entries");
  }

  const onDrop = async(id:string, targetIndex:number) => {
    // get a copy of the items to work with
    const updatedItems = entity!.elements.items.map(item => item).sort((a,b) => a.position - b.position);

    // get source and target indices
    const indexFrom = updatedItems.findIndex(item => item.id === id); // result.source.index;

    // remove element and move to new position
    const element = updatedItems.splice(indexFrom, 1)[0]; // splice returns the removed elements as an array, since we only remove one item, we can get it from index 0
    updatedItems.splice(targetIndex, 0, element);

    // update position property on items
    updatedItems.forEach((item, index) => item.position=index);

    // TODO NOT DRY this is to a great extent the same code as above in onSaveItem
    // get updated version of the module
    const updatedModule = _.cloneDeep(entity!);
    updatedModule!.elements.items = updatedItems;
    
    // save the module
    await PC.upsertModule(updatedModule);

    // get the updated module and set it as the entity
    setEntity(updatedModule);
  }

  // entity not set yet? render nothing
  if(!entity) {
    return null;
  }


  if(mode === "properties") {
    return (
      <Properties 
        entity={entity} 
        onClickAddItem={onClickAddItem} 
        onCancel={closeDialog}
        onClickEditItem={onClickEditItem}
        onSave={onSave}
        onDrop={onDrop}
        onClickDelete={onClickDelete}
      />
    )
  }
  else {
    return (
      <EditItem item={itemToEdit!} onSaveItem={onSaveItem} closeDialog={() => setMode("properties")} />
    )
  }
}

type PropertiesProps = {
  entity: Cms.AccordionModule,
  onClickAddItem: () => void,
  onClickEditItem: (item:any) => void,
  onCancel: () => void,
  onSave: (item:any) => void,
  onDrop: (id:string, targetIndex:number) => void,
  onClickDelete: (itemToDelete:Cms.AccordionModuleItem) => void,
}
function Properties({entity, onClickAddItem, onClickEditItem, onSave, onCancel, onDrop, onClickDelete} : PropertiesProps) {
  // pre-render
  let listAction = null;
  let list = null;
  if(entity._id) {
    listAction = (
      <div>
        <Button size="small" onClick={onClickAddItem}>Eintrag hinzufügen</Button>
      </div>
    );
    const items = entity.elements.items
      .sort((a,b) => a.position - b.position)
      .map((item:Cms.AccordionModuleItem, index:number) => {
        return (
          <SortableListItem id={item.id} key={item.id}>
            <Card title={item.title} size="small">
              <Paragraphs text={item.text} />
              <Actions size="small" onEdit={() => onClickEditItem(item)} onDelete={() => onClickDelete(item)} />
            </Card>
          </SortableListItem>
        )
      });
    list = (
      <SortableList onDrop={onDrop} noItemsText="noch keine Einträge">
        {items}
      </SortableList>
    )
  }
  else {
    listAction = <Alert size="small" intent="info" title="Speichern notwendig">Bevor Einträge hinzugefügt werden können, muss dieses Modul erst gespeichert werden</Alert>
  }

  // render
  return (
    <Form entity={entity} onCancel={onCancel} onSave={onSave}>
      <Selectors.BaseProperties busy={false}/>

      <TextInput label="Titel" path="elements.title" placeholder="Titel eingeben" />
      <TextInput label="Untertitel" path="elements.subtitle" placeholder="Untertitel eingeben" />
      
      <Label label="Einträge" />

      {list}
      {listAction}
    </Form>
  )
}

type EditItemProps = {
  item: Cms.AccordionModuleItem,
  onSaveItem:(itemToSave:Cms.AccordionModuleItem) => void,
  closeDialog: () => void,
}
function EditItem({item, onSaveItem, closeDialog} : EditItemProps) {
  // state
  const [entity, setEntity] = useState<Cms.AccordionModuleItem>(item);
  const [busy, setBusy] = useState<boolean>(false);

  // user wants to cancel
  const onCancel = () => {
    closeDialog();
  }

  // user wants to save
  const onSave = async(formResult:any) => {
    setBusy(true);
    const updatedEntity = formResult.merge(entity);
    await onSaveItem(updatedEntity);
    closeDialog();
  }
  
  // render
  return (
    <Form entity={entity} onCancel={onCancel} onSave={onSave} busy={false}>
      <TextInput label="Titel" path="title" placeholder="Titel eingeben" disabled={busy} validate={Validators.isRequired("Titel eingeben")} />
      <TextArea label="Text" path="text" placeholder="Untertitel eingeben" disabled={busy} validate={Validators.isRequired("Text eingeben")} />
    </Form>
  )
}

type AccordionPreviewType = {
  instance: Cms.AccordionModule,
  expanded?: boolean,
  onClickAction: (module:Cms.Module, action:"edit"|"delete"|"duplicate") => void
}
export function AccordionPreview({instance, expanded, onClickAction}:AccordionPreviewType) {
  const items = instance.elements.items.sort((a, b) => a.position - b.position).map((item:Cms.AccordionModuleItem) => {
    return (
      <li key={item.id}>
        <div>{item.title}</div>
        <div>
          <Paragraphs text={item.text} />
        </div>
      </li>
    )
  })
  return (
    <Preview.Container expanded={expanded} module={instance} title={Cms.AccordionModule.info.title} onClickAction={onClickAction}>
      <Preview.Table>
        <Preview.TableRow label="Titel">{instance.elements.title || "-"}</Preview.TableRow>
        <Preview.TableRow label="Untertitel">{instance.elements.subtitle || "-"}</Preview.TableRow>
        <Preview.TableRow label="Einträge">
          <ul>{items}</ul>
        </Preview.TableRow>
      </Preview.Table>
      
    </Preview.Container>
  )
}
