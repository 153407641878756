import _ from "lodash";
import React, { useState, useEffect } from "react";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";
// components
import { Card, List, ListColumn, Loader, Modal } from "../../controls";
import Create from "./Provider.PriceLists.Create";
import Edit from "./Provider.PriceLists.Edit";

type EnrichedPriceList = {
  priceList: DA.PriceList,
  activity: TS.Activity,
  region?: DA.Region,
}

type ProviderPriceListsProps = {
  providerId: string
}
export default function ProviderPriceLists({providerId}: ProviderPriceListsProps) {
  // modal state
  const [modalAction, setModalAction] = useState<"none"|"create"|"edit">("none");
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modalParams, setModalParams] = useState<any>();
  // state
  const [columns] = useState<ListColumn[]>([
    {header:"Aktivität", path:"activity.title.de"},
    {header:"Region", path:"region.regionCode", print:(v:EnrichedPriceList) => v.region ? v.region.title.de : "---"},
    {header:"Währung", path:"priceList.currencyCode"},
    {header:"Preise", path:"priceList.prices", print:(v:EnrichedPriceList) => v.priceList.prices.map(p => `${p.participants}: ${p.price}`).join(", ")},
  ]);
  const [loading, setLoading] = useState<boolean>(true);
  const [activities, setActivities] = useState<TS.Activity[]>([]);
  const [regions, setRegions] = useState<DA.Region[]>([]);
  const [priceLists, setPriceLists] = useState<DA.PriceList[]>([]);
  const [enrichedPriceLists, setEnrichedPriceLists] = useState<EnrichedPriceList[]>([]);

  // loads data
  useEffect(() => {
    if(providerId) {
      load(true);
    }
  }, [providerId]);

  // loads data
  const load = async(loadAll:boolean) => {
    // get activities and regions (if necessary)
    let _activities: TS.Activity[] = activities;
    let _regions: DA.Region[] = regions;
    if(loadAll) {
      _activities = await TS.Activity.allActiveMain();
      _regions = await DA.RegionRepository.findAll();
    }
    // get price lists of provider and enrich with data
    const _priceLists = await DA.PriceListRepository.search({provider_id:providerId});
    const _enrichedPriceLists: EnrichedPriceList[] = [];
    _priceLists.forEach(pl => {
      const enriched = getEnriched(pl, _activities, _regions);
      if(enriched) {
        _enrichedPriceLists.push(enriched);
      }
    });

    setActivities(_activities);
    setRegions(_regions);
    setPriceLists(_priceLists);
    setEnrichedPriceLists(_enrichedPriceLists);
    setLoading(false);
  }

  // user wants to create a new one
  const onClickCreate = () => {
    setModalAction("create");
    setModalTitle("Preisliste erstellen");
  }

  const onCreated = async(priceList:DA.PriceList) => {
    await load(false);
    const enriched = getEnriched(priceList, activities, regions);
    onClickEdit(enriched!);
  }

  // user wants to edit an item
  const onClickEdit = (item:EnrichedPriceList) => {
    let title = `Preisliste ${item.activity.title.de} - ${item.region?.code}`;
    setModalAction("edit");
    setModalTitle(title);
    setModalParams(item.priceList);
  }

  // user wants to delete an item
  const onClickDelete = async (item:EnrichedPriceList) => {
    await DA.PriceListRepository.remove(item.priceList._id!);
    load(false);
  }

  // closes modal dialog
  const closeDialog = () => {
    setModalAction("none");
  }


  // pre-render
  let content = <Loader />;
  if(!loading) {
    let modalContent = null;
    switch(modalAction) {
      case "create":
        modalContent = <Create 
          providerId={providerId} 
          activities={activities} 
          regions={regions} 
          priceLists={priceLists} 
          onCreated={onCreated}
          closeDialog={closeDialog}
        />
        break;
      case "edit":
        modalContent = (
          <Edit 
            priceList={modalParams} 
            loadList={() => load(false)} 
            closeDialog={closeDialog}
          />
        )
        break;
    }
    content = <>
      <List 
        columns={columns} 
        items={enrichedPriceLists} 
        onClickCreate={onClickCreate}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
        getKey={(item:EnrichedPriceList) => item.priceList._id!} 
      />
      <Modal isOpen={modalAction !== "none"} title={modalTitle} onClose={closeDialog}>
        {modalContent}
      </Modal>
    </>
  }
  // render
  return <Card title="Preise" expanded={false} collapsible={true}>
    {content}
  </Card>
}

function getEnriched(pl: DA.PriceList, _activities:TS.Activity[], _regions:DA.Region[]) : EnrichedPriceList|undefined {
  const activity = _activities.find(a => a._id === pl.activity_id);
  const region = pl.regionCode ? _regions.find(r => r.code === pl.regionCode) : undefined;
  if(activity) {
    return {
      priceList: pl,
      activity: activity,
      region: region,
    };
  }
  else {
    return undefined;
  }
}