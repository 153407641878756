import React from "react";
import { Button, ConfirmButton, Icon, Icons } from ".";
import CSS from "./Actions.module.scss";

type ActionsProps = {
  size?:"regular"|"small", 
  align?:"left"|"center"|"right",
  disabled?: boolean,
  busy?: boolean,
  data?: any,
  onEdit?: (data:any) => void,
  onDelete?: (data:any) => void,
  onDuplicate?: (data:any) => void,
  onSave?: (data:any) => void,
  onCancel?: (data:any) => void
}
export function Actions({size, align, disabled, busy, data, onDelete, onEdit, onDuplicate, onSave, onCancel} : ActionsProps) {
  // defaults
  size = size || "regular";
  align = align || "left";
  disabled = disabled || false;
  busy = busy || false;
  const buttonSize = size === "regular" ? "medium" : "small";

  // events
  const _onEdit = () => {
    if(onEdit && !busy && !disabled) {
      onEdit(data);
    }
  }
  const _onDelete = () => {
    if(onDelete && !busy && !disabled) {
      onDelete(data);
    }
  }
  const _onDuplicate = () => {
    if(onDuplicate && !busy && !disabled) {
      onDuplicate(data);
    }
  }
  const _onSave = () => {
    if(onSave && !busy && !disabled) {
      onSave(data);
    }
  }
  const _onCancel = () => {
    if(onCancel && !busy) {
      onCancel(data);
    }
  }

  // pre-render
  const buttonDuplicate = onDuplicate ? <ConfirmButton disabled={disabled} busy={busy} size={buttonSize} onConfirm={_onDuplicate}><Icon icon={["far", "clone"]} /></ConfirmButton> : null;
  const buttonEdit = onEdit ? <Button disabled={disabled} busy={busy} size={buttonSize} onClick={_onEdit}><Icon icon={Icons.Edit } /></Button> : null;
  const buttonDelete = onDelete ? <ConfirmButton disabled={disabled} busy={busy} intent="delete" size={buttonSize} onConfirm={_onDelete}><Icon icon={Icons.Trash } /></ConfirmButton> : null;
  const buttonSave = onSave ? <Button disabled={disabled} busy={busy} size={buttonSize} onClick={_onSave}><Icon icon={Icons.Check} /></Button> : null;
  const buttonCancel = onCancel ? <Button busy={busy} size={buttonSize} onClick={_onCancel} intent="cancel"><Icon icon={Icons.Cancel} /></Button> : null;
  // render
  return (
    <div className={`${CSS.container} ${CSS[size]} ${CSS[align]}`}>
      <div className={CSS.inner}>
        {buttonDelete}
        {buttonCancel}
        {buttonDuplicate}
        {buttonEdit}
        {buttonSave}
      </div>
    </div>
  )
}