import React, {useState, useRef} from "react";
import Config from "../../../config";
import Bitly from "../../../util/bitly";
import Util from "../../../util/util";
import { Loader, Button, CsvDownload } from "../../controls";

type BulkOrderExportProps = {
  bulkOrder: any
}
function BulkOrderExport({bulkOrder}: BulkOrderExportProps) {
  const [mode, setMode] = useState("ready"); // ready, busy, done
  const [csv, setCsv] = useState<any>();
  const refProgress = useRef<any>();
  
  const createCsv = async() => {
    setMode("busy");
    const links = [];
    let counter = 0;
    for(const adventureId of bulkOrder.adventures) {
      counter++;
      if(refProgress.current) {
        refProgress.current.innerText = `${counter} von ${bulkOrder.adventures.length}`;
      }
      await sleep(1000); // wait so the Bit.ly API does not get angry with us
      //const longUrl = `${Config.api.ApiRoot}/v1/certificate/${adventureId}?kind=flyer_prepaid&o=pdf`.replace("localhost", "127.0.0.1");
      const ticketLink_prepaid_a4_html = `${Config.api.ApiRoot}/v1/certificate/${adventureId}?kind=standard_prepaid&o=html`.replace("localhost", "127.0.0.1");

      const shortenResult = await Bitly.createShortUrl(ticketLink_prepaid_a4_html);
      links.push(shortenResult.link);
    }
    links.unshift("Link");
    const csv = links.join('\n');
    setCsv(csv);
    setMode("done");
  }
  
  // render
  switch(mode) {
    case "busy":
      return <div>
        <div ref={refProgress}>1 von {bulkOrder.adventures.length}</div>
        <Loader />
      </div>
    case "done":
      return <CsvDownload size="small" csv={csv} filename={`sammelbestellung_ticketlinks_${Util.printDate(new Date())}.csv`}>CSV</CsvDownload>
    case "ready":
    default:
      return <Button size="small" onClick={createCsv}>CSV mit Links Prepaid-Tickets erzeugen</Button>
  }
}

async function sleep(ms:number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default BulkOrderExport