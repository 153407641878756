import { AdventureTask } from "./AdventureTask";
import { Mapper } from "../_Common/Mapper";


export class AdventureTaskMapper extends Mapper<AdventureTask> {
  public apiEndpoint = "adventureTasks";

  public toDb(task: AdventureTask):any {
    return {
      _id: task._id,
      code: task.code,
      adventureId: task.adventureId,
      title: task.title,
      description: task.description,
      relatedEntityType: task.relatedEntityType,
      relatedEntityId: task.relatedEntityId,
      relatedMailId: task.relatedMailId,
      assignedTo: task.assignedTo,
      createdBy: task.createdBy,
      completedBy: task.completedBy,
      dateDue: task.dateDue,
      dateCompleted: task.dateCompleted,
      dataCreated: task.dateCreated,
      dateUpdated: task.dateUpdated,
      // note: dateCreated and dateUpdated are writte by mongoose timestamps 
    }
  }

  public fromDb(obj:any): AdventureTask {
    return {
      _id: obj._id || undefined,
      adventureId: obj.adventureId || null,
      code: obj.code,
      assignedTo: obj.assignedTo || "martin@appentura.ch",
      title: obj.title || "",
      description: obj.description || "",
      relatedEntityType: obj.relatedEntityType || null,
      relatedEntityId: obj.relatedEntityId || null,
      relatedMailId: obj.relatedMailId || null,
      createdBy: obj.createdBy || null,
      completedBy: obj.completedBy || null,
      dateDue: obj.dateDue || null,
      dateCompleted: obj.dateCompleted || null,
      dateCreated: obj.dateCreated || null,
      dateUpdated: obj.dateUpdated || null,
    }
  }

}