import Api from "../../../util/api2"; 
import { ProviderMapper } from "./Provider.Mapper";
import { Provider, ProviderAddress, ProviderBankAccount, ProviderContact, ProviderLink } from "./Provider";
import { RepositoryFactory } from "../_Common/RepositoryFactory";
import { isProviderBookingInfoKind } from "../../_deprecated.20230710.Provider";

export class ProviderRepository {
  private static mapper = new ProviderMapper();
  
  // from factory
  static make = RepositoryFactory.make<Provider, ProviderMapper>(this.mapper);
  static search = RepositoryFactory.search<Provider, ProviderMapper>(this.mapper);
  static create = RepositoryFactory.create<Provider, ProviderMapper>(this.mapper);
  static update = RepositoryFactory.update<Provider, ProviderMapper>(this.mapper);
  static remove = RepositoryFactory.remove<Provider, ProviderMapper>(this.mapper);
  static findById = RepositoryFactory.findById<Provider, ProviderMapper>(this.mapper);

  static makeAddress(): ProviderAddress {
    return this.mapper.fromDb_address({});
  }

  static makeLink(): ProviderLink {
    return this.mapper.fromDb_providerLink({});
  }

  static makeBankAccount(): ProviderBankAccount {
    return this.mapper.fromDb_bankAccount({});
  }

  static makeContact(): ProviderContact {
    return this.mapper.fromDb_contact({});
  }


  static async upsertLink(provider: Provider, link: ProviderLink): Promise<void> {
    await this._upsertInArray(provider._id!, "links", link);
  }

  static async deleteLink(provider: Provider, link: ProviderLink): Promise<void> {
    this._deleteInArray(provider._id!, "links", link._id);
  }


  static async deleteContact(provider: Provider, contact: ProviderContact): Promise<void> {
    await this._deleteInArray(provider._id!, "contacts", contact._id);
  }
  static async upsertContact(provider: Provider, contact: ProviderContact): Promise<void> {
    const updatedArray = await this._upsertInArray(provider._id!, "contacts", contact);
  }

  static async deleteAddress(provider: Provider, address:ProviderAddress) {
    await this._deleteInArray(provider._id!, "addresses", address._id);
  }
  static async upsertAddress (provider: Provider, address:ProviderAddress) {
    const updatedArray = await this._upsertInArray(provider._id!, "addresses", address);
  }

  // TODO // Note: bank accounts should not be deleted
  static async deleteBankAccount(provider: Provider, bankAccount:ProviderBankAccount) {
    await this._deleteInArray(provider._id!, "bankAccounts", bankAccount._id);
  }
  static async upsertBankAccount(provider: Provider, bankAccount:ProviderBankAccount) {
    const updatedArray = await this._upsertInArray(provider._id!, "bankAccounts", bankAccount);
  }

  /**
   * Fetches Partner-Portal Login Link. This is only available for providers that have a booking email address.
   * @param provider 
   * @returns returns the login link for the partner portal. If the provider does not have a booking email address, and empty string is returned.
   */
  static async fetchLoginLink(provider:Provider) : Promise<string> {
    const bookingInfo = provider.booking[0];
    if(!bookingInfo) return "";
    if(bookingInfo.kind !== "email") return "";
    const partnerLoginLinkResult = await Api.post("providers", "getPartnerPortalLoginLink", {email:bookingInfo.value});
    return partnerLoginLinkResult.data.loginLink;
  }

  /**
   * Fetches the assignment notes for a provider and a regionVariant of an activity
   * @param provider 
   * @param activityId 
   * @param regionVariantId 
   * @returns 
   */
  static async fetchAssignmentNotes(provider: Provider, activityId: string, regionVariantId: string): Promise<string|null> {
    let assignmentNotes:string|null = null;
    const activityResult = await Api.post("activities", "search", {filter:{_id:activityId}, projection:{"regionVariants.providers":1, "regionVariants._id":1}})
    const activity = activityResult.data.items[0]
    if(activity) {
      const regionVariant = activity.regionVariants.find((rv:any) => String(rv._id) === String(regionVariantId))
      if(regionVariant) {
        const providerAssignment = regionVariant.providers.find((p:any) => String(p.id) === String(provider._id!))
        if(providerAssignment) {
          if(providerAssignment.notes) {
            assignmentNotes = providerAssignment.notes.trim().length > 0 ? providerAssignment.notes : null
          }
        }
      }
    }
    return assignmentNotes;
  }


  private static _deleteInArray = async(providerId:string, arrayName:string, itemId:any) => {
    const payload = {
      id: providerId,
      arrayName: arrayName,
      itemId: itemId
    }
    await Api.post("providers", "deleteInArray", payload);
  }

  private static _upsertInArray = async (providerId: string, arrayName: string, item: any): Promise<any[]> => {
    const payload = {
      id: providerId,
      arrayName: arrayName,
      item: item
    }
    await Api.post("providers", "upsertInArray", payload);
    // reload the array
    const projection:any = {}; projection[arrayName] = 1;
    const result:any = await Api.post("providers", "search", {filter:{_id:providerId}, projection})
    const array = result.data.items[0][arrayName];
    return array;
  }
}

