import _ from "lodash";
import React, { useState, useEffect } from "react";
import * as DA from "../../../types/DataAccess";
import { Form, TextInput, Dropdown, Alert, TextArea, Validators, Loader, CloudStorageUploader, DropdownOption } from "../../controls";

type TestimonialEditProps = {
  testimonial: DA.Testimonial,
  onUpdate: () => void,
  onCancel: () => void,
}
export default function TestimonialEdit({testimonial, onUpdate, onCancel} : TestimonialEditProps) {
  // state
  const [busy, setBusy] = useState<boolean>(false);
  const [entity] = useState<DA.Testimonial>(testimonial);
  const [regions, setRegions] = useState<DropdownOption[]|null>(null);

  // mount
  useEffect(()=> {
    load();
  }, []);

  // loads data
  const load = async() => {
    const regions = await DA.RegionRepository.findAll();
    const regionOptions = regions.map(region => {
      return {label: region.title.de, value:region.code}
    });
    regionOptions.unshift({label: "--Region wählen--", value:""});
    setRegions(regionOptions);
  }

  // user wants to save
  const onSave = async(formResult:any) => {
    setBusy(true);
    // save
    if(entity._id) {
      // update
      await DA.TestimonialRepository.update(entity._id, formResult.changeset);
    }
    else {
      // create
      const toCreate = formResult.merge(entity);
      await DA.TestimonialRepository.create(toCreate);
    }
    // pop dialog on success, display error if not
    onUpdate();
  }


  // render
  if(!regions) {
    return <Loader />
  }
  return (
    <div id="v-testimonial-edit">
      <Form entity={entity} onSave={onSave} onCancel={onCancel} labelSave="speichern" labelCancel="abbrechen" busy={busy}>
        <TextInput path="name" label="Name" disabled={busy} validate={Validators.isRequired("Bitte Namen eingeben")} placeholder="Name des Kunden" explanation="" />
        <TextArea path="text" label="Text" disabled={busy} validate={Validators.isRequired("Bitte einen Text eingeben")} placeholder="Text des Testimonials" rows={3} /> 
        <Dropdown path="regionCode" label="Region" onChange={() => {}} options={regions} validate={Validators.isRequired("Bitte Region wählen")} />
        <CloudStorageUploader kind="image" label="Bild" path="imageUrl" folder="testimonials" disableUrlEditing={true} />
      </Form>
    </div>
  )
}
