import axios from 'axios'
import ApiConfig from './../config/api'

// TODO the put method should not fallback to post 
// TODO there should only be one put method

function get(apiEndpoint, id, version) {
  // TODO the version is an ugly fix because our /auth route is not part of the /v1 endpoint and we only added this later
  if(version == null) {
    version = 'v1'
  }
  if(version !== '') {
    version += '/'
  }
  let url = `${ApiConfig.ApiRoot}/${version}${apiEndpoint}`
  if(id) {
    url += '/' + id
  }
  return request({url:url, method:'GET'})
}

function post(apiEndpoint, entity) {
  let url = `${apiRoot()}/v1/${apiEndpoint}`
  return request({url:url, data:entity, method:'POST'})
}

function del(apiEndpoint, id) {
  let url = `${apiRoot()}/v1/${apiEndpoint}`
  if(id) {
    url += '/' + id
  }
  return request({url:url, method:'DELETE'})
}

function deleteWithPayload(apiEndpoint, payload) {
  let url = `${apiRoot()}/v1/${apiEndpoint}`
  return request({url, method:'DELETE', data:payload})
}

function put(apiEndpoint, entity, id_property) {
  id_property = id_property || '_id'
  let url = `${apiRoot()}/v1/${apiEndpoint}`
  let method
  if(entity && entity[id_property]) {
    url += '/' + entity[id_property]
    method = 'PUT'
  }
  else {
    // if we have no ._id we post instead to create a new entity
    method = 'POST'
  }
  return request({url:url, data:entity, method:method})
}

function putSimple(apiEndpoint, data) {
  let url = `${apiRoot()}/v1/${apiEndpoint}`
  return request({url:url, data:data || {}, method:'PUT'})
}

function patch(apiEndpoint, changeset, id) {
  let url = `${apiRoot()}/v1/${apiEndpoint}/${id}`
  return request({url:url, data:changeset, method:'PATCH'})
}

function patchMany(apiEndpoint, changeset, filter) {
  let url = `${apiRoot()}/v1/${apiEndpoint}`
  return request({url, data:{changeset, filter}, method:'PATCH'})
}

function patchWithPayload(apiEndpoint, payload) {
  let url = `${apiRoot()}/v1/${apiEndpoint}`
  return request({url, data:payload, method:'PATCH'})
}

// use this if the route does not expect any data (in body or url)
function patchSimple(apiEndpoint) {
  let url = `${apiRoot()}/v1/${apiEndpoint}`
  return request({url:url, data:{}, method:'PATCH'})
}

function request(options) {
  return new Promise((resolve, reject) => {
    let validatedStatus
    let token = window.localStorage.getItem('appentura_token')
    let request = axios({
      url: options.url,
      method: options.method,
      data: options.data,
      headers: {'x-access-token': token},
      validateStatus: function(status) {
        validatedStatus = status
        return ((status >= 200 && status < 300) || status === 404) // || (status >= 400 && status < 500) //)
      }
    })
    request
      .then(({data}) => {
        switch(validatedStatus) {
          case 404:
            reject(data)
            break;
          default:
            resolve(data)
            break
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

function apiRoot() {
  return ApiConfig.ApiRoot
}


export default {
  get, 
  del, delete:del, deleteWithPayload,
  post, 
  put, putSimple, 
  patch, patchMany, patchWithPayload,
  patchSimple, 
  apiRoot
}