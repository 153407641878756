import { Region } from "./Region";
import { Mapper } from "../_Common/Mapper";
import { TranslatableMapper } from "../_Common/TranslatableMapper";


export class RegionMapper extends Mapper<Region> {
  public apiEndpoint = "regions";

  public toDb(region: Region): any {
    return {
      _id: region._id,
      code: region.code.toUpperCase(),
      title: TranslatableMapper.toDb(region.title),
      position: region.position,
      currencyCode: region.currencyCode
    }
  }

  public fromDb(obj: any): Region {
    return {
      _id: obj._id,
      code: (obj.code || "").toUpperCase(),
      position: obj.position || 0,
      currencyCode: obj.currencyCode || "CHF",
      title: TranslatableMapper.fromDb(obj.title),
    }
  }

}