import _ from "lodash";
import React, { useContext, useState, useEffect } from "react";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";

import { Alert, Card, Value, Form, Icon, Icons, TextInput, Checkbox, Validators, Dropdown, DropdownOptions, Table, Tr, Td, Modal } from "../../controls";
import { ProviderContext } from "./Provider.Context";

import CollectiveInovicesLists from "../CollectiveInvoices/CollectiveInvoices.List";

export default function ProviderMonthlyStatements() {
  // context
  const PC = useContext(ProviderContext);
  // state
  const [collectiveInvoices, setCollectiveInvoices] = useState<TS.CollectiveInvoice[]>([]);
  const [mode, setMode] = useState<"edit"|"view">("view");

  // mount
  useEffect(() => {
    if(PC.provider && PC.provider._id) {
      loadInvoices();
    }
  }, [PC.provider]);

  // loads invoices
  const loadInvoices = async() => {
    setCollectiveInvoices(await TS.CollectiveInvoice.search({provider_id:PC.provider._id}));
  }

  // user wants to edit
  const onClickEdit = () => setMode("edit");

  // closes dialog
  const closeDialog = () => setMode("view");

  // pre-render
  const ci_isActive = _.get(PC.provider, "collectiveInvoice.isActive") ? true : false;
  const ci_email = _.get(PC.provider, "collectiveInvoice.email") || "-";
  const ci_currency = _.get(PC.provider, "collectiveInvoice.currency") || "CHF";

  // render
  return <>
    <Card title="Monatsabrechnung" collapsible={true} id="v-provider-monthlystatements">
      <Value onClick={onClickEdit}>
        <Table>
          <Tr>
            <Td label align="right">Monatliche Abrechnung</Td>
            <Td><Icon icon={ci_isActive ? Icons.Check : Icons.Cancel} />&nbsp;{ci_isActive ? "ja":"nein"}</Td>
          </Tr>
          <Tr>
            <Td label align="right">E-Mail</Td>
            <Td>{ci_email}</Td>
          </Tr>
          <Tr>
            <Td label align="right">Währung</Td>
            <Td>{ci_currency}</Td>
          </Tr>
        </Table>
      </Value>

      <Alert intent="hint">
        Für alle Anbieter, die diese Option aktiviert haben, wird monatlich eine Abrechnung der durchgeführten Buchungen (Buchung bestätigt und Überraschung durchgeführt) erstellt und per E-Mail verschickt
      </Alert>

      <CollectiveInovicesLists collectiveInvoices={collectiveInvoices} onChanged={loadInvoices} hideProvider={true} /> 
    </Card>

    <Modal isOpen={mode === "edit"} title="Monatsabrechnung-Einstellungen bearbeiten" onClose={closeDialog}>
      <Edit closeDialog={closeDialog} />
    </Modal>
  </>
}

type EditProps = {
  closeDialog: () => void,
}
function Edit({closeDialog} : EditProps) {
  // context
  const PC = useContext(ProviderContext);

  // state
  const [entity, setEntity] = useState<{isActive:boolean, email:string, currency:"CHF"|"EUR"}>({isActive:false, email:"", currency:"CHF"});
  const [busy, setBusy] = useState<boolean>(true);

  // mount
  useEffect(() => {
    if(PC.provider) {
      const ci = PC.provider.collectiveInvoice;
      if(ci) {
        setEntity({
          isActive: ci.isActive ? true : false,
          email: ci.email || "",
          currency: ci.currency || "CHF"
        });
      }
      setBusy(false);
    }
  }, [PC.provider]);

  // user wants to save
  const onSave = async(formResult:any) => {
    setBusy(true);
    const collectiveInvoice = formResult.merge(entity);
    const changeset = {collectiveInvoice};
    await PC.update(changeset);
    closeDialog();
  }

  // user wants to cancel
  const onCancel = () => {
    closeDialog();
  }

  // render
  return <>
    <Form busy={busy} entity={entity} onSave={onSave} labelSave="speichern" onCancel={onCancel} labelCancel="abbrechen">
      <Checkbox path="isActive" label="Monatliche Abrechnung" />
      <TextInput path="email" label="E-Mail an welche Abrechnung geschickt wird" validate={Validators.isEmail("bitte gültige E-Mail Adresse eingeben")} />
      <Dropdown path="currency" label="Währung" options={DropdownOptions.Currencies} />
    </Form>
  </>
}
