import CSS from "./Search.module.scss";

type FilterGroupProps = {
  title:string,
  children: React.ReactNode|React.ReactNode[]
}
export default function FilterGroup({title, children} : FilterGroupProps) {
  return (
    <div className={CSS.filtergroup}>
      <div className={CSS.title}>{title}</div>
      <div className={CSS.content}>{children}</div>
    </div>
  )
}
