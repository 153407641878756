import React, {useContext, useState, useEffect} from "react"
import _ from "lodash"

import { FormContext } from "./FormContext";
import { Alert, Label, ValidationError } from ".";
import { Validation } from "./Validation";
import CSS from "./NumberInput.module.scss";


type NumberInputProps = {
  path?: string,
  value?: number,
  label?: string,
  explanation?: string,
  placeholder?: string,
  disabled?: boolean,
  validate?: any,
  onChange?: (value:number, validation:Validation) => void
}
export function NumberInput({path, value, label, explanation, placeholder, disabled, validate, onChange} : NumberInputProps) {
  // get context
  const form = useContext(FormContext);

  // if we have a form context, we render with form if not without
  if(form && path) {
    // warn about value not being used if provided
    if(value) {
      console.warn("since TextInput is inside a FormContext and a 'path' was provided, the provided 'value' will be ignored");
    }
    // render the form control
    return FormControl({path, label, explanation, disabled, validate, onChange});
  }
  else if(value !== undefined) {
    // a value was provided
    return Widget({value, label, explanation, disabled, validate, onChange});
  }
  // something is missing
  return (
    <Alert intent="error" size="small" title="Implementation Error">
      Expected the path property (if control is inside a Form) or the value property.
    </Alert>
  )
}


type FormControlProps = {
  path: string,
  label?: string,
  explanation?: string,
  placeholder?: string,
  disabled?: boolean,
  validate?: any,
  onChange?: (value:number, validation:Validation) => void,
}
function FormControl({path, label, explanation, placeholder, disabled, validate, onChange}:FormControlProps) {
  // state
  const [initialValue, setInitialValue] = useState<number>(0);

  // context
  const form = useContext(FormContext);

  // mount
  useEffect(() => {
    // TODO we always get a form and and entity (ensured in <TextInput />) ... no need to check this
    if(form.entity && path) {
      // setting initial value
      const value = toNumberOr0(_.get(form.entity, path)); // TODO what if no value is found?
      setInitialValue(value);
    }
  }, [form.entity, validate, path])

  // form.resetIndex changed -> reset the item
  useEffect(() => {
    if(form.resetIndex !== 0) {
      setInitialValue(form.getOldValue(path))
    }
  }, [form.resetIndex, path, form])

  // handler
  const onWidgetChange = (value:number, validation: Validation) => {
    form.onFieldChange(path, value, validation)
    if(onChange) {
      onChange(value, validation);
    }
  }

  // render
  return <Widget value={initialValue} label={label} explanation={explanation} disabled={disabled} validate={validate} onChange={onWidgetChange} />
}

type WidgetProps = {
  value: number
  label?: string,
  explanation?: string,
  placeholder?: string,
  disabled?: boolean,
  validate?: (v:number) => Validation,
  onChange?: (value:number, validation: Validation) => void,
}
function Widget({value, label, explanation, placeholder, disabled, validate, onChange}:WidgetProps) {
  // state
  const [displayValue, setDisplayValue] = useState<string>("0");
  const [validation, setValidation] = useState<Validation>(new Validation(true));

  // value changes
  useEffect(() => {
    const validation = validate ? validate(value) : new Validation(true);
    setValidation(validation);
    setDisplayValue(String(value));
  }, [value]);

  // handler
  const onInputChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    // get updated value and validation
    const eventValue = e.target.value;
    const numberValue: number = toNumberOr0(eventValue);
    const validation = validate ? validate(numberValue) : new Validation(true);
    
    // update state
    setDisplayValue(eventValue);
    setValidation(validation);
    // inform onChange listeners
    if(onChange) {
      onChange(numberValue, validation);
    }
  }

  
  // render
  return (
    <div className={CSS.container}>
      <Label label={label} explanation={explanation}>
        <input type="number" value={displayValue} disabled={disabled} onChange={onInputChange} placeholder={placeholder} />
        <ValidationError validation={validation} />
      </Label>
    </div>
  )
}


// return number or 0
const toNumberOr0 = (v:any):number => {
  return isNaN(Number(v)) ? 0 : Number(v);
}