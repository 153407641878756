import _ from "lodash";
import moment from "moment";
import Placeholders from "./Automatization.Placeholders";
import { PlaceholderSourceData } from "./Automatization.Placeholders";
import { AdventureStepCoordinates } from "../Adventure";
import StepText from "./StepText";
import StepTextGroup from "./StepTextGroup";
import {GoogleMaps as GoogleMapsUtil} from "../../util";
import { RawStep } from "./Steps";

// TODO type as Record
const texts:any = {
  reservation: new StepTextGroup([
    new StepText(
      "Auf den Namen '@@beschenkter@@' (Stichwort 'Appentura') ist für @@personen@@ Person für dich reserviert", 
      "Auf den Namen '@@beschenkter@@' (Stichwort 'Appentura') ist für @@personen@@ Personen für euch reserviert"
    )
  ]),
  reservation_with_time: new StepTextGroup([
    new StepText(
      "Auf den Namen '@@beschenkter@@' (Stichwort 'Appentura') ist um @@zeit@@ Uhr für @@personen@@ Person reserviert", 
      "Auf den Namen '@@beschenkter@@' (Stichwort 'Appentura') ist um @@zeit@@ Uhr für @@personen@@ Personen reserviert"
    ),
    new StepText(
      "Um @@zeit@@ Uhr ist auf den Namen '@@beschenkter@@' (Stichwort 'Appentura') für dich reserviert", 
      "Um @@zeit@@ Uhr ist auf den Namen '@@beschenkter@@' (Stichwort 'Appentura') für @@personen@@ Personen reserviert"
    )
  ]),
  provider: new StepTextGroup([
    new StepText("Du stehst jetzt vor '@@anbieter@@'", "Ihr steht jetzt vor '@@anbieter@@'"),
    new StepText("Du bist jetzt bei '@@anbieter@@'" , "Ihr seid jetzt bei '@@anbieter@@'"),
    new StepText("Willkommen bei '@@anbieter@@'")
  ]),
  provider_instructions: new StepTextGroup([
    new StepText("@@anbieter_anweisungen@@")
  ]),
  services: new StepTextGroup([
    new StepText("Auf dich wartet hier: @@leistungen@@", "Auf euch wartet hier: @@leistungen@@")
  ]),
  enter: new StepTextGroup([
    new StepText("Tritt ein, du wirst schon erwartet", "Tretet ein, ihr werdet schon erwartet")
  ]),
  continue: new StepTextGroup([
    new StepText(`Wähle "weiter"`, `Wählt "weiter"`)
  ]),
  continue_when_done: new StepTextGroup([
    new StepText(`Wähle "weiter" wenn du fertig bist`, `Wählt "weiter" wenn ihr fertig seid`)
  ]),
  credit_1: new StepTextGroup([
    new StepText("Du hast ein Konsumationgsguthaben von insgesamt CHF @@konsumationsguthaben@@", "Ihr habt ein Konsumationgsguthaben von insgesamt CHF @@konsumationsguthaben@@")
  ]),
  credit_2: new StepTextGroup([
    new StepText("Solltest du mehr konsumieren kannst du die Differenz einfach direkt vor Ort bezahlen", "Solltet ihr mehr konsumieren könnt ihr die Differenz einfach direkt vor Ort bezahlen")
  ]),
  enjoy: new StepTextGroup([
    new StepText("Geniess es und hab viel Spass!", "Geniesst es und habt viel Spass!")
  ]),
  enjoy2: new StepTextGroup([
    new StepText("Die Aktivität dauert bis @@zeit_ende@@")
  ])
}


/**
 * Returns a text by key and number of people. Uses index to round-robin texts (if more than one variation is available)
 */
function get(key:string, numberOfPeople:number): string {
  let group:StepTextGroup = texts[key];
  return group.getText(numberOfPeople);
}

/**
 * Returns default instructions for a given activity. Uses adventure and booking request to retrieve necessary information 
 * @param {*} adventure 
 * @param {*} bookingRequest 
 * @param {*} providerInfo
 */
function getDefaultActivityInstructions(adventure:any, bookingRequest:any, providerInfo:any, rawStep: RawStep) {
  const instructions = [];
  const data: PlaceholderSourceData = {adventure, bookingRequest, providerInfo, rawStep};
  // gets value for placeholder
  const getPV = (placeholder:any) => {
    const ph = Placeholders.find(p => p.placeholder === placeholder);
    return !_.isNil(ph) ? ph.retrieve(data) : null;
  }
  // checks if we have a value for a placeholder
  const hasPV = (placeholder:any) => {
    return !_.isNil(getPV(placeholder));
  }
  
  // get the number of people
  // TODO we might as well use the adventure's Person property
  let numberOfPeople = 1;
  if(hasPV("@@personen@@")) {
    let personen:any = getPV("@@personen@@");
    if(personen !== null && !isNaN(personen)) {
      numberOfPeople = Number(personen);
    }
    else {
      numberOfPeople = adventure.Persons;
    }
  }

  // Anbieter
  if(hasPV("@@anbieter@@")) {
    instructions.push(get("provider", numberOfPeople));
    if(hasPV("@@anbieter_anweisungen@@")) {
      instructions.push(get("provider_instructions", numberOfPeople));
    }
    else {
      instructions.push(get("enter", numberOfPeople));
    }
  }
  // Leistungen
  if(hasPV("@@leistungen@@")) {
    instructions.push(get("services", numberOfPeople));
  }
  // Reservation
  if(hasPV("@@beschenkter@@") && hasPV("@@personen@@")) {
    if(hasPV("@@zeit@@")) {
      instructions.push(get("reservation_with_time", numberOfPeople));
    }
    else {
      instructions.push(get("reservation", numberOfPeople));
    }
  }
  // Konsumationgsguthaben
  if(hasPV("@@konsumationsguthaben@@")) {
    instructions.push(get("credit_1", numberOfPeople));
    instructions.push(get("credit_2", numberOfPeople));
  }

  // Standard
  instructions.push(get("continue", numberOfPeople));

  // done
  return instructions;
}

function getDefaultEnjoyInstructions(adventure:any) {
  
  const instructions = [];
  instructions.push(get("enjoy", adventure.Persons));
  instructions.push(get("enjoy2", adventure.Persons));
  return instructions;
}

/**
 * Returns titleFirst, title, instructions
 * @param {*} numberOfPeople 
 * @param {*} durationInMinutes 
 */
async function getDrivingTexts(numberOfPeople:number, durationInMinutes:number, providerInfo:any, startLocation:AdventureStepCoordinates|null): Promise<any> {
  // title for first
  const titleFirst = new StepText("Folge der Karte", "Folgt der Karte");
  // title for others
  const title = new StepText("Weiter gehts!")
  // instructions
  let address = "";
  if(providerInfo && _.get(providerInfo, "location.start.title") && _.get(providerInfo, "location.start.title").trim().length > 0) {
    address = ` (${ _.get(providerInfo, "location.start.title")})`;
  }
  else {
    const addressForStartLocation = startLocation ? await GoogleMapsUtil.getAddressFromCoordinates(startLocation.Lat, startLocation.Lng) : null;
    address = addressForStartLocation ? ` (${addressForStartLocation})` : "";
  }
  const stepTexts = [];
  stepTexts.push(new StepText(`Du siehst das Ziel auf der Karte${address}. Fahr dort hin`, `Ihr seht das Ziel auf der Karte${address}. Fahrt dort hin`));
  if(durationInMinutes && durationInMinutes > 0) {
    stepTexts.push(new StepText(
      `Die Fahrt dauert ca. ${durationInMinutes} Minuten, die Route kannst du frei wählen`,
      `Die Fahrt dauert ca. ${durationInMinutes} Minuten, die Route könnt ihr frei wählen`
    ))
  }
  else {
    stepTexts.push(new StepText("Du kannst die Route frei wählen", "Ihr könnt die Route frei wählen"))
  }
  // TODO `Solltest due eine Parkuhr füttern müssen, rechnet mit einem Aufenthalt von ca.`
  stepTexts.push(new StepText(`Wähle "weiter" wenn du dort parkiert hast`, `Wählt "weiter" wenn ihr dort parkiert habt`));
  stepTexts.push(new StepText("Gute Fahrt!"));
  
  // return texts depending on number of people
  return {
    title: title.getText(numberOfPeople),
    titleFirst: titleFirst.getText(numberOfPeople),
    instructions: stepTexts.map(st => st.getText(numberOfPeople))
  }
}

async function getWalkingTexts(numberOfPeople:number, durationInMinutes:number, providerInfo: any, startLocation:AdventureStepCoordinates|null): Promise<any> {
  // title of first
  const titleFirst = new StepText("Folge der Karte", "Folgt der Karte");
  // title of others
  const titles = [new StepText("Weiter gehts!"), new StepText("Auf zum nächsten Ziel!")];
  const title:any = _.sample(titles);
  // instructions
  let address = "";
  if(providerInfo && _.get(providerInfo, "location.start.title") && _.get(providerInfo, "location.start.title").trim().length > 0) {
    console.log("from providerInfo", providerInfo);
    address = ` (${ _.get(providerInfo, "location.start.title")})`;
  }
  else {
    const addressForStartLocation = startLocation ? await GoogleMapsUtil.getAddressFromCoordinates(startLocation.Lat, startLocation.Lng) : null;
    address = addressForStartLocation ? ` (${addressForStartLocation})` : "";
  }
  const stepTexts = [];
  stepTexts.push(new StepText(`Du siehst das Ziel auf der Karte${address}, gehe zu Fuss dort hin`, `Ihr seht das Ziel auf der Karte${address}, geht zu Fuss dort hin`));
  if(durationInMinutes && durationInMinutes > 0) {
    stepTexts.push(new StepText(`Der Spaziergang dauert ca. ${durationInMinutes} Minuten`));
  }
  else {
    stepTexts.push(new StepText("Geniess den kurzen Spaziergang", "Geniesst den kurzen Spaziergang"));
  }
  stepTexts.push(new StepText(`Wähle "weiter" wenn du dort angekommen bist`, `Wählt "weiter" wenn ihr dort angekommen seid`));
  // return texts depending on number of people
  return {
    title: title.getText(numberOfPeople),
    titleFirst: titleFirst.getText(numberOfPeople),
    instructions: stepTexts.map(st => st.getText(numberOfPeople))
  }
}

/**
 * Returns instructions for the very last step
 * @param {*} activity 
 * @param {*} adventure 
 */
function getUltimateInstructions(activity:any, adventure:any) {
  const pl = (adventure.Persons || 1) > 1; 
  const m = moment(activity.endTime);
  let remainderText = ""
  switch(m.isoWeekday()) {
    case 6:
    case 7:
      remainderText = `${pl ? "Geniesst" : "Geniess"} den Rest des Wochenendes 🙂`;
      break;
    default:
      if(m.hour() > 1 && m.hour() < 17) {
        remainderText = `${pl ? "Geniesst" : "Geniess"} den Rest des Tages 🙂`;
      }
      else {
        remainderText = `${pl ? "Geniesst" : "Geniess"} den Rest des Abends 🙂`;
      }
  }
  return [
    `${pl ? "Euer" : "Dein"} Erlebnis ist nachher zu Ende und ${pl ? "ihr könnt" : "du kannst"} individuell nach Hause zurückkehren`,
    `Wir hoffen die Überraschung hat ${pl ? "euch" : "dir"} gefallen`,
    remainderText
  ];
}


export default {
  get,
  getDefaultActivityInstructions,
  getUltimateInstructions,
  getDefaultEnjoyInstructions,
  getDrivingTexts,
  getWalkingTexts
}



