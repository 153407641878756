import CSS from "./Row.module.scss";

type RowProps = {
  align?:"left"|"center"|"right",
  xalign?:"start"|"center"|"end",
  className?: string,
  children: React.ReactNode|React.ReactNode[],
}
export function Row({align="left", xalign="start", children, className}: RowProps) {

  return <div className={`${CSS.container} ${CSS[align]} ${CSS["x"+xalign]} ${className || ""}`}>{children}</div>
}