import _ from "lodash";
import Api from "../../util/api2";
import * as TS from "../../types";

// reflects the ActivityPackage mongoose model in the API
interface DbActivityPackage {
  _id: string|null,
  // active? (i.e. displayed in shop)
  isActive:boolean,
  // slug used in shop, e.g. "secret-agent" ->  /shop/packages/secret-agent
  slug: {
    de: string, en: string
  },
  // texts displayed in shop
  title: {
    de: string, en: string
  },
  shortDescription: {
    de: string, en: string
  },
  longDescription: {
    de: string, en: string
  },
  // image
  image: { 
    url?: string,
    blurhash?: string
  },
  // activities included
  activities: Array<{
    activityId: string,
    sortIndex: number,
    title: {
      de: string, en: string
    },
    description: {
      de: string, en: string
    },
    image: {
      url?: string,
      blurhash?: string
    }
  }>,
  // prices per country
  regionVariants: Array<{
    regionCode: string;
    priceConfigurations: Array<{
      participantCount: number,
      price: number
    }>
  }>;
}

export interface ActivityPackageRegionVariant {
  regionCode: string;
  priceConfigurations: ActivityPackagePriceConfiguration[];
}

export interface ActivityPackagePriceConfiguration {
  participantCount: number, 
  price: number
}
export interface ActivityPackageActivity {
  activityId: string, 
  sortIndex: number,
  title: string,
  description: string,
  image: ActivityPackageImage
}

export interface ActivityPackageImage {
  url?: string,
  blurhash?: string
}

export class ActivityPackage {
  //#region fields
  _id: string|null = null;
  isActive: boolean;
  slug: string;
  title: string;
  shortDescription: string;
  longDescription: string;
  image: ActivityPackageImage;
  activities: Array<ActivityPackageActivity>;
  regionVariants: Array<ActivityPackageRegionVariant>;
  //#endregion

  //#region constructor
  constructor(_id:string|null, slug:string, title: string) {
    this._id = _id;
    this.isActive = false;
    this.slug = slug.toLowerCase().trim();
    this.title = title.trim();
    this.shortDescription = "-";
    this.longDescription = "";
    this.image = {};
    this.activities = [];
    this.regionVariants = [];
  }
  //#endregion

  //#region methods
  async save() : Promise<TS.ResultOld> {
    // clean up data
    this.slug = this.slug.toLowerCase().trim();
    this.title = this.title.trim();
    this.shortDescription = this.shortDescription.trim();
    this.longDescription = this.longDescription.trim();
    // get rid of unnecessary 0 price configurations
    this.regionVariants.forEach(rv => {
      
      const hasPrices = rv.priceConfigurations.some(pc => pc.price !== 0);
      // we only get rid of trailing 0 prices, lest the user cannot add 0 price at slots 0,1,2,... etc
      if(hasPrices) {
        const cleanedPcs: ActivityPackagePriceConfiguration[] = [];
        for(let i = rv.priceConfigurations.length - 1; i >= 0; i-=1) {
          const pc = rv.priceConfigurations[i];
          if(pc.price !== 0 || cleanedPcs.length > 0) {
            cleanedPcs.unshift(pc);
          }
        }
        rv.priceConfigurations = cleanedPcs;
      }
    })

    // make sure the slug does not already exist
    const checkSlugResult = await Api.post("activityPackages", "search", {filter:{"slug.de":this.slug}});
    const itemsWithSlug = _.get(checkSlugResult, "data.items") || [];
    if(itemsWithSlug.length > 0) {
      if(!(String(itemsWithSlug[0]._id) === this._id)) {
        const error = {
          name: "slug missing",
          message: "Diese Url-Erweiterung wird bereits verwendet"
        };
        return new TS.ResultOld(false, error, null);
      }
    }

    // create db object
    const dbObj = ActivityPackage.toDb(this);

    // create / update
    if(!dbObj._id) {
      // create
      const apiResult = await Api.post("activityPackages", "create", dbObj);
      if(apiResult.success) {
        this._id = String(apiResult.data.item._id);
        return new TS.ResultOld(true, null, _.cloneDeep(this));
      }
      else {
        return new TS.ResultOld(false, {message:apiResult.error.message, name:"save error"}, null);
      }
    }
    else {
      // update
      const apiResult = await Api.post("activityPackages", "update", {id:dbObj._id, set:dbObj});
      // TODO handle apiResult.success === false
      return new TS.ResultOld(true, null, _.cloneDeep(this)); 
    }
  }

  getNextPriceConfiguration(regionCode:string): ActivityPackagePriceConfiguration {
    const newPriceConfiguration: ActivityPackagePriceConfiguration = {participantCount:1, price:0};
    const regionVariant = this.getRegionVariant(regionCode);
    if(regionVariant) {
      if(regionVariant.priceConfigurations.length > 0) {
        const last = regionVariant.priceConfigurations.sort((a,b) => a.participantCount - b.participantCount)[regionVariant.priceConfigurations.length - 1];
        newPriceConfiguration.participantCount = last.participantCount + 1;
        newPriceConfiguration.price = newPriceConfiguration.participantCount * Math.round(last.price / last.participantCount);
      }
    }
    return newPriceConfiguration;
  }

  getIncludedActivities(activityCatalogue:Array<TS.Activity>): Array<TS.Activity> {
    const activities: Array<TS.Activity> = [];
    this.activities.forEach(a => {
      const catalogueActivity = activityCatalogue.find(ca => ca._id === a.activityId);
      if(catalogueActivity) {
        activities.push(catalogueActivity);
      }
    });
    return activities;
  }

  validatePriceConfigurations(regionCode: string, activityCatalogue:Array<TS.Activity>) : string[] {
    const issues:string[] = [];
    const regionVariant = this.getRegionVariant(regionCode);
    const catalogueActivities = this.getIncludedActivities(activityCatalogue);
    const catalogueRegionVariants: Array<TS.ActivityRegionVariant> = []; 
    catalogueActivities.forEach(ca => {
      const crv = ca.regionVariants.find(rv => rv.regionCode === regionCode);
      if(crv) {
        catalogueRegionVariants.push(crv);
      }
    });
  
    let hasGaps = false;
    let hasNegativePriceIncrease = false;  

    if(regionVariant && regionVariant.priceConfigurations.length > 0) {
      // check if participant count and prices make sense
      let lastParticipantCount = regionVariant.priceConfigurations[0].participantCount - 1;
      let lastPrice = -1000;
      regionVariant.priceConfigurations
        .sort((a,b) => a.participantCount - b.participantCount)
        .forEach(pc => {
          // particpant count need to be a continuous set of values
          if(lastParticipantCount + 1 !== pc.participantCount) {
            hasGaps = true;
          }
          lastParticipantCount = pc.participantCount;

          // prices should be ascending
          if(pc.price !== 0 && lastPrice >= pc.price) {
            hasNegativePriceIncrease = true;
          }
          lastPrice = pc.price;

          // make sure the activities actually support the price configuration
          catalogueActivities.forEach(catalogueActivity => {
            const crv = catalogueActivity.regionVariants.find(item => item.regionCode === regionCode);
            if(!crv || crv.isActive === false) {
              issues.push(`Die Aktivität '${catalogueActivity.title.de}' ist nicht verfügbar`);
            }
            else {
              const cpc = crv.priceConfigurations.find(item => item.participantCount === pc.participantCount);
              if(!cpc && pc.price !== 0) {
                issues.push(`Die Aktivität '${catalogueActivity.title.de}' ist für ${pc.participantCount} Teilnehmer nicht verfügbar`)
              }  
            }
          });
        });
    }

    if(hasGaps) {
      issues.push("Die Liste der Teilnehmerzahlen hat Lücken.");
    }
    if(hasNegativePriceIncrease) {
      issues.push("Die Preise sind nicht ansteigend.")
    }
    return _.uniq(issues);
  }

  getParticipantCounts() : number[] {
    const allCounts:number[] = [];
    this.regionVariants.forEach(rv => {
      rv.priceConfigurations.forEach(pc => {
        allCounts.push(pc.participantCount);
      })
    });
    return _.uniq(allCounts).sort((a,b) => a-b);
  }

  getRegionVariant(regionCode:string) : ActivityPackageRegionVariant|null {
    return this.regionVariants.find(rv => rv.regionCode === regionCode) || null;
  }
  //#endregion

  

  //#region static methods
  static fromDb(obj:DbActivityPackage, catalogueActivities:any[]) : ActivityPackage {
    const ap = new ActivityPackage(obj._id, obj.slug.de, obj.title.de);
    ap.isActive = obj.isActive ? true : false;
    ap.shortDescription = obj.shortDescription.de;
    ap.longDescription = obj.longDescription.de;
    ap.image = {
      url: _.get(obj, "image.url"), blurhash: _.get(obj, "image.blurhash")
    }
    ap.activities = obj.activities.map(a => {
      return {
        activityId: String(a.activityId), 
        sortIndex: _.get(a, "sortIndex") || 0,
        title: _.get(a, "title.de") || "???",
        description: _.get(a, "description.de") || "???",
        image: {
          url: _.get(a, "image.url"), 
          blurhash: _.get(a, "image.blurhash")
        }
      };
    });
    ap.regionVariants = obj.regionVariants.map(rv => {
      const priceConfigurations:ActivityPackagePriceConfiguration[] = rv.priceConfigurations.map(pc => {
        return {
          participantCount: pc.participantCount,
          price: pc.price
        }
      })
      return {regionCode: rv.regionCode, priceConfigurations};
    })
    return ap;
  }

  static toDb(ap:ActivityPackage) : DbActivityPackage {
    const obj:DbActivityPackage = {
      _id: ap._id, 
      isActive: ap.isActive,
      slug: { de: ap.slug, en: `!_${ap.slug}` },
      title: { de: ap.title, en: `!_${ap.title}` },
      shortDescription: { de: ap.shortDescription, en: `!_${ap.shortDescription}` },
      longDescription: { de: ap.longDescription, en: `!_${ap.longDescription}` },
      image: {
        url: ap.image.url,
        blurhash: ap.image.blurhash
      },
      activities: ap.activities.map(a => {
        return {
          activityId: a.activityId,
          title: { de: a.title, en: `!_${a.title}` },
          description: { de: a.description, en: `!_${a.description}` },
          sortIndex: a.sortIndex,
          image: {
            url: a.image.url,
            blurhash: a.image.blurhash
          },
        };
      }),
      regionVariants: ap.regionVariants.map(rv => {
        const priceConfigurations = rv.priceConfigurations.map(pc => {
          return {
            participantCount: pc.participantCount,
            price: pc.price
          }
        })
        return {regionCode: rv.regionCode, priceConfigurations};
      })
    }
    return obj;
  }

  static async loadAll():Promise<ActivityPackage[]> {
    // get activity packages
    const resultActivityPackages = await Api.post("activityPackages", "search", {filter:{}, projection:{}});
    const dbActivityPackages = resultActivityPackages.data.items;
    
    // get related activities
    const activityIds:Array<string> = [];
    dbActivityPackages.forEach((dbap:DbActivityPackage) => {
      dbap.activities.forEach(a => activityIds.push(a.activityId));
    })
    const resultActivities = await Api.post("activities", "search", {filter:{_id:{$in:activityIds}}, projection:{"title.de":1}});
    const activities = resultActivities.data.items;

    // create ActivityPackage Objects
    const activityPackages = dbActivityPackages.map((item:DbActivityPackage) => {
      return ActivityPackage.fromDb(item, activities);
    });


    return activityPackages;
  }

  static async loadById(id:string):Promise<ActivityPackage|undefined> {
    // get dbActivityPackage
    const resultActivityPackage = await Api.post("activityPackages", "search", {filter:{_id:id}, projection:{}});
    const dbActivityPackage: DbActivityPackage = resultActivityPackage.data.items[0];
    
    // found, convert to ActivityPackage
    if(dbActivityPackage) {
      // get related activities
      const activityIds:Array<string> = dbActivityPackage.activities.map(a => a.activityId);
      const resultActivities = await Api.post("activities", "search", {filter:{_id:{$in:activityIds}}, projection:{"title.de":1}});
      // create ActivityPackage object
      return ActivityPackage.fromDb(dbActivityPackage, resultActivities.data.items);
    }
    else {
      return undefined;
    }
  }

  static async deleteById(id:string) {
    await Api.post("activityPackages", "delete", {id:id})
  }
  //#endregion
}

