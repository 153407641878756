import { PaymentType, PaymentTypeBank, PaymentTypeCreditcard, PaymentTypeEmployee } from "./PaymentType";
import { Mapper } from "../_Common/Mapper";

export class PaymentTypeMapper extends Mapper<PaymentType> {
  public apiEndpoint = "paymentTypes";

  public toDb(paymentType: PaymentType): any {
    return {
      _id: paymentType._id,
      name: paymentType.name,
      kind: paymentType.kind,
      accountingId: paymentType.accountingId,
      isActive: paymentType.isActive,
      bank: this.toDb_bank(paymentType.bank),
      employee: this.toDb_employee(paymentType.employee),
      creditcard: this.toDb_creditcard(paymentType.creditcard)
    }
  }

  private toDb_bank(bank: PaymentTypeBank|null): any {
    if(!bank) return null;
    return {
      name: bank.name,
      iban: bank.iban,
      bic: bank.bic,
      currency: bank.currency
    }
  }

  private toDb_creditcard(creditcard: PaymentTypeCreditcard|null): any {
    if(!creditcard) return null;
    return {
      cardNumber: creditcard.cardNumber
    }
  }

  private toDb_employee(employee: PaymentTypeEmployee|null): any {
    if(!employee) return null;
    return {
      fullName: employee.fullName
    }
  }


  public fromDb(obj: any): PaymentType {
    return {
      _id: obj._id,
      name: obj.name || "",
      kind: obj.kind,
      accountingId: obj.accountingId,
      isActive: obj.isActive ? true : false,
      bank: this.fromDb_bank(obj.bank),
      employee: this.fromDb_employee(obj.employee),
      creditcard: this.fromDb_creditcard(obj.creditcard)
    }
  }

  private fromDb_bank(obj: any): PaymentTypeBank|null {
    if(!obj) return null;
    return {
      name: obj.name || "",
      iban: obj.iban || "",
      bic: obj.bic || "",
      currency: obj.currency || ""
    }
  }

  private fromDb_creditcard(obj: any): PaymentTypeCreditcard|null {
    if(!obj) return null;
    return {
      cardNumber: obj.cardNumber || ""
    }
  }

  private fromDb_employee(obj: any): PaymentTypeEmployee|null{
    if(!obj) return null;
    return {
      fullName: obj.fullName || ""
    }
  }
}