import React, { useEffect, useContext } from "react";
import _ from "lodash";
import Util from "../../../util/util";

import { ActivityPackageContext, ActivityPackageProvider } from "./ActivityPackage.Context";
import {Alert, Breadcrumb, Loader, Heading, Card} from "../../controls";
import Status from "./ActivityPackage.Status";
import Texts from "./ActivityPackage.Texts";
import Activities from "./ActivityPackage.Activities";
import ActivityPrices from "./ActivityPackage.ActivityPrices";
import Prices from "./ActivityPackage.Prices";
import Image from "./ActivityPackage.Image";

import "./ActivityPackage.scss";

export default function ActivityPackage(props:any) {
  return (
    <ActivityPackageProvider>
      <Content id={Util.getRouteId(props)} />
    </ActivityPackageProvider>
  )
}

type ContentProps = {
  id:string
}
function Content({id}:ContentProps) {
  // context
  const AC = useContext(ActivityPackageContext);

  // no AC? no render
  if(!AC) {
    return null;
  }

  // mount
  useEffect(() => {
    AC.load(id);
    
  }, []);

  

  // still loading? render loader
  if(!AC.loaded) {
    return <Loader />
  }

  // no package found
  if(!AC.activityPackage) {
    return <Alert title="Aktivitätspaket nicht gefunden" intent="error" size="large">
      Konnte kein Aktivitätspaket mit dieser Id finden.
    </Alert>
  }
  

  // render
  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}, {title:"Aktivitäts-Pakete", to:"/activitypackages"}]} location={AC.activityPackage.title} />
    <Heading>Aktivitäts-Paket "{AC.activityPackage.title}"</Heading>
    <div id="v-activitypackage-edit">
      <Card title="Eigenschaften" expanded={true} collapsible={true}>
        <Status />
        <Texts />
        <Image />
      </Card>
      <Card title="Aktivitäten" expanded={true} collapsible={false}>
        <Activities />
        <ActivityPrices />
      </Card>
      
      <Prices />
    </div>
  </>
}