import { AdventureDocumentMapper } from "./AdventureDocument.Mapper";
import { AdventureDocument } from "./AdventureDocument";
import { RepositoryFactory } from "../_Common/RepositoryFactory";

export class AdventureDocumentRepository {
  private static mapper = new AdventureDocumentMapper();
  
  // from factory
  static make = RepositoryFactory.make<AdventureDocument, AdventureDocumentMapper>(this.mapper);
  static search = RepositoryFactory.search<AdventureDocument, AdventureDocumentMapper>(this.mapper);
  static create = RepositoryFactory.create<AdventureDocument, AdventureDocumentMapper>(this.mapper);
  static update = RepositoryFactory.update<AdventureDocument, AdventureDocumentMapper>(this.mapper);
  static remove = RepositoryFactory.remove<AdventureDocument, AdventureDocumentMapper>(this.mapper);
  static findById = RepositoryFactory.findById<AdventureDocument, AdventureDocumentMapper>(this.mapper);

  /**
   * returns all locations for a given provider
   * @param providerId 
   * @returns {Promise<AdventureDocument[]>}
   */
  static findByAdventureId = async(adventureId:string) : Promise<AdventureDocument[]> => {
    const documents = await this.search({surprise_id:adventureId});
    return documents;
  } 

  /**
   * Returns all exsiting adventure documents
   * @returns {Promise<AdventureDocument[]>} 
   */
  static findAll = async() : Promise<AdventureDocument[]> => {
    const documents = await this.search({});
    return documents;
  }
}

