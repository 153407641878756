import React, {useMemo, useEffect, useState} from "react";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";
import * as BL from "../../../types/BusinessLogic";
import Util from "../../../util/util";
import {Card, Link, List, ListColumn} from "../../controls";
// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglassHalf, faThumbsUp, faThumbsDown, IconDefinition} from "@fortawesome/free-solid-svg-icons";

// local type to map to list
type Item = {
  id: string,
  activityName: string,
  regionCode: string,
  date: string,
  dateSort: string,
  date2: string,
  dateSort2: string,
  advLink: string,
  advId4: string,
  status: string,
  statusColor: string,
  statusIcon: IconDefinition,
  operator: string,
  value: number,  
}

// columns for list
const columns: ListColumn[] = [
  {header:"Status", key:"s", path:"statusIcon", print:(v:Item) => <span style={{color:v.statusColor}}><FontAwesomeIcon icon={v.statusIcon} /></span>, sort: (a:Item,b:Item) => a.status.localeCompare(a.status)},
  {header:"Preis", key:"value", path:"value", print:(v:Item) => v.value.toFixed(2), sort: (a:Item,b:Item) => a.value - b.value, align:"right" },
  {header:"Überraschungs-Datum", path:"dateSort", key:"üd", print:(v:Item) => v.date, sort: (a:Item,b:Item) => a.dateSort.localeCompare(b.dateSort), align:"right"},
  {header:"Überraschung", path:"advLink", key:"ü", print: (v:Item) => <Link to={v.advLink}>{v.advId4}</Link>, sort: (a:Item, b:Item) => a.advId4.localeCompare(b.advId4)},
  {header:"Aktivität", path:"activityName", key:"an"},
  {header:"Region", path:"regionCode", key:"r" },
  {header:"Operator", path:"operator", key:"o" },
  {header:"Anfrage-Datum", path:"date2", key:"ad", print:(v:Item) => v.date2, sort: (a:Item, b:Item) => a.dateSort2.localeCompare(b.dateSort2) * -1, align:"right"}
];

// the component
type BookingRequestsProps = {
  providerId: string
}
export default function BookingRequests({providerId} : BookingRequestsProps) {
  // state
  const [items, setItems] = useState<Item[]|null>(null);

  // mount
  useEffect(() => {
    const load = async() => {
      // get from database
      const users = await DA.UserRepository.findAll();
      const bookingRequestsWithValue = await BL.BookingRequest.getForProviderWithValues(providerId);
      //const bookingRequests = await DA.BookingRequestRepository.search({"provider.id":providerId, "response.accepted":{$exists:true}});
      
      // map to items
      const items:Item[] = bookingRequestsWithValue.map((brv, index) => {
        const br = brv.bookingRequest;
        const user = users.find(u => u.email === br.request.createdBy);
        const item = {
          id: br._id || String(index),
          activityName: br.activity.name,
          regionCode: br.activity.regionCode || "unbekannt",
          date: br.adventure_date ? Util.printDate(br.adventure_date) : 'unbekannt',
          dateSort: Util.printSortableDate(br.adventure_date),
          date2: Util.printDate(br.request.createdOn),
          dateSort2: Util.printSortableDate(br.request.createdOn),
          advLink: `/surprise/${br.adventure_id}`,
          advId4: TS.Adventure.getShortId(br.adventure_id),
          operator: user ? user.firstName : br.request.createdBy || "nicht zugewiesen",
          status: 'waiting',
          statusIcon: faHourglassHalf,
          statusColor: "black",
          value: brv.value,
        }
        
        if(br.response) {
          if(br.response.accepted) {
            item.status = "accepted";
            item.statusIcon = faThumbsUp;
            item.statusColor = "green";
          }
          else {
            item.status = "declined";
            item.statusIcon = faThumbsDown;
            item.statusColor = "red";
          }
        }

        return item;
      });

      // update state
      setItems(items);
    }
    if(providerId) {
      load();
    }
  }, [providerId])

  // render
  return <Card title="Bisherige Buchungsanfragen" expanded={false} collapsible={true}>
    <List columns={columns} items={items} getKey={v => v.id} onRowClick={() => {}} hideFooter={true} hideSearch={true} sortColumnKey="ad" />
  </Card>
}
