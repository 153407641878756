import React, { useState, useEffect } from "react";
import _ from "lodash";
import Api from "../../../util/api2";
import * as DA from "../../../types/DataAccess";
import * as BL from "../../../types/BusinessLogic";

import { Coordinates, Form, Loader, TextArea, TextInput } from "../../controls"

// TODO should use the ProviderContext here

type DefaultLocationFormProps = {
  provider: DA.Provider,
  onClose: (reload:boolean) => void,
}
function DefaultLocationForm({provider, onClose} : DefaultLocationFormProps) {
  const [entity, setEntity] = useState<any>(null);
  
  useEffect(() => {
    const load = async() => {
      const location = await BL.Provider.getDefaultLocation(provider);
      const start = location ? location.start || null : null
      setEntity(start || {title:"", instructions:"", coordinates:null})
    }
    load();
  }, [provider])

  const save = async(formData:any) => {
    let newLocation = {}
    _.merge(newLocation, entity)
    _.merge(newLocation, formData.changeset)
    let payload = {
      providerId:provider._id,
      start:newLocation,
      endLocation:null
    }
    await Api.post("providers", "addUpdateLocation", payload) // TODO move this to Provider.ts
    setEntity(newLocation);
    //await provider.reloadLocations();
    onClose(true);
  }

  const cancel = () => {
    onClose(false);
  }

  // create content to render
  let content
  if(_.isNil(entity)) {
    content = <Loader />
  }
  else {
    content = (
      <Form entity={entity} onSave={save} onCancel={cancel} labelCancel="abbrechen" labelSave="speichern">
        <TextInput label="Adresse / Bezeichnung" path="title" placeholder="z.B. Haupteingang Bernapark" />
        <TextArea label="Anweisungen" path="instructions" placeholder="z.B. nimm den Lift in den 3. Stock und klopfe dort an die rote Tür" />
        <Coordinates label="Koordinaten" path="coordinates" pathLat="lat" pathLng="lng" />
      </Form>
    )
  }
  
  // render
  return content
}

export default DefaultLocationForm