import React, {useContext} from "react";
import Util from "../../../../../util/util";
import {SurpriseContext} from "../../Surprise.Context";
import { Doner, Row } from "../Items";
import { Table } from "../../../../controls";

export default function OrderCanceled() {
  // context 
  const SC = useContext(SurpriseContext);
  
  // render
  return (
    <Table fontSize="small">
      <Row label="Bestelldatum">{Util.printDateAndTime(SC.surprise.CreateDate)}</Row>
      
      <Doner />
    </Table>
  )
}