import React, { useEffect, useState } from "react";
import * as TS from "../../../types";

import { Redirect } from 'react-router-dom'
import { Form, TextInput, Alert, Loader, Validators } from "../../controls";


type ActivityPackageCreateProps = {
  closeDialog: VoidFunction
}
export default function ActivityPackageCreate({closeDialog} : ActivityPackageCreateProps) {
  // state
  const [entity, setEntity] = useState<any>(null);
  const [busy] = useState(false);
  const [error, setError] = useState<string|undefined>();
  const [redirectTo, setRedirectTo] = useState<string|null>(null);
  
  // mount
  useEffect(() => {
    setEntity({slug:"", title:""});
  }, []);

  // user wants to save
  const onSave = async(formResult:any) => {
    const updatedEntity = formResult.merge(entity);
    const activityPackage = new TS.ActivityPackage(null, updatedEntity.slug, updatedEntity.title);
    
    const result:TS.ResultOld = await activityPackage.save();
    if(result.success) {
      setEntity(updatedEntity);
      setRedirectTo(activityPackage._id);
    }
    else {
      setEntity(updatedEntity);
      setError(result.error ? result.error.message : "unbekannter Fehler");
    }
  }

  // user wants to abort
  const onCancel = () => {
    closeDialog();
  }

  // redirect?
  if(redirectTo) {
    return <Redirect to={`/activitypackages/${redirectTo}`} />
  }

  // render
  if(!entity)  {
    return <Loader />
  }
  else {
    let errorAlert = null;
    if(error) {
      errorAlert = <Alert intent="error" title="Fehler beim Speichern" size="medium">{error}</Alert>
    }
    return <>
      <Form 
        entity={entity} 
        onSave={onSave} 
        onCancel={onCancel}
        busy={busy} 
      >
        <TextInput 
          label="Titel" 
          path="title" 
          disabled={busy} 
          validate={Validators.isRequired("Name eingeben")} 
          explanation="wird auf der Webseite als Titel des Pakets verwendet" 
        />
        <TextInput 
          label="Url-Erweiterung" 
          path="slug" 
          disabled={busy} 
          validate={Validators.isSlug()} 
          explanation="z.B. 'geheim-agent'"
        />
        
        {errorAlert}
      </Form>
      
    </>
  }
}