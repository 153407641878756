import React, { useState } from "react";
import Code from "../Code";
import { Card, Heading, Form, TextArea, Validators } from "../../../controls";

export default function TextAreaDemo() {
  const [text, setText] = useState<string>("");
  const [formData, setFormData] = useState<string>("");

  const [entity] = useState({text1: "Hello", text2: ""})


  // form save
  const onSave = (formData:any) => {
    setFormData(JSON.stringify(formData))
  }

  // render

  return <>
    <Heading>TextArea</Heading>
    
    <Card title="In Form">
      <Form busy={false} entity={entity} onSave={onSave}>
        <TextArea rows={3} path="text1" label="Text1" />
        <TextArea rows={3} path="text2" label="Text2" validate={Validators.isRequired()} />
      </Form>
      <div>
        <div>formData:</div>
        <Code>
          {formData}
        </Code>
      </div>
    </Card>

    <Card title="Without Form">
      <TextArea rows={3} label="Text3" value={text} onChange={(v:string) => setText(v)} />
      <div>entered text1: {text}</div>
    </Card>

    <Card title="Explanation, Placeholder, CharacterCount, Validation">
      <TextArea 
        label="Text1" 
        explanation="please enter a text" 
        placeholder="enter some text"
        rows={3}  
        value={text} 
        onChange={(v:string) => setText(v)} 
        validate={Validators.isRequired()}
        showCharacterCount={true}
        />
    </Card>


    <Card title="Incomplete Implementation">
      <TextArea rows={3} label="Something Missing" />
    </Card>

  </>
}
