import _ from "lodash";
import React, {useContext, useState} from "react";
import {SurpriseContext} from "../Surprise.Context";
import {CardSection, Modal, Validators, Value } from "../../../controls";

import GenericEdit from "../Generic.Edit";

export default function Donee() {
  // render
  return (
    <CardSection title="Beschenkter">
      <Row label="Name (vom Schenker vergeben)" path="RecieverName" kind="textinput" />
      <Row label="Echter Name (beim Einlösen angegeben)" path="RecieverRealFirstName" kind="textinput" />
      <Row label="E-Mail" path="RecieverEmail" kind="textinput" validate={Validators.isEmail("bitte gültige E-Mail Adresse eingeben")} />
      <Row label="Telefon" path="RecieverPhone" kind="textinput" />
      <Row label="Anzahl Personen (kann in Aktivitäten abweichen)" path="Persons" kind="numberinput" />
    </CardSection>
  );
}


type RowProps = {
  label:string, 
  path: string,
  kind: string,
  validate?: any,
}
function Row({label, path, kind, validate} : RowProps) {
  // state
  const [mode, setMode] = useState<"edit"|"view">("view");
  // context
  const SC = useContext(SurpriseContext);

  // edit button even handler
  const startEdit = () => setMode("edit");
  // stops edit mode
  const stopEdit = () => setMode("view");

  // render
  return <>
    <Value label={label} onClick={startEdit}>{_.get(SC.surprise, path) || ""}</Value>
    <Modal isOpen={mode==="edit"} title={`${label} bearbeiten`} onClose={stopEdit}>
      <GenericEdit path={path} label={label} kind={kind} validate={validate} stopEdit={stopEdit} />
    </Modal>
  </>
}