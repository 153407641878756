import React, {useContext, useState} from "react"
import * as TS from "../../../types";

import { Checkbox, Value, Form, Icon, Icons, Loader, CardSection, Modal } from "../../controls";
import { ActivityContext } from "./ActivityContext";


export default function SubactivityAsOption() {
  // state
  const [modalMode, setModalMode] = useState<"none"|"edit">("none");
  // context
  const AC = useContext(ActivityContext)!;

  // user wants to edit
  const onClickEdit = () => {
    setModalMode("edit");
  }

  // closes modal dialog
  const closeModal = () => setModalMode("none");

  // saves changes
  const saveEdit = async(changeset:any) => {
    await AC.update(changeset);
  }

  // render not loaded
  if(AC.activity === null) {
    return <Loader text="lade Aktivität ..." />
  }

  // not a subactivity? no render
  if(AC.activity.isSubactivity) {
    return null;
  }
  
  // render
  const active = AC.activity.isOptionalSubactivity;
  return <>
    <CardSection title="Wählbare Unteraktivität">
      <Value label="kann als Option gewählt werden"  onClick={onClickEdit} >
        <Icon icon={active ? Icons.Check : Icons.Cancel} />&nbsp;die Unteraktivität kann {active ? "": "nicht "}wie einen Option gewählt werden
      </Value>
    </CardSection>
    <Modal isOpen={modalMode === "edit"} title="Aktivität - Shop Eigenschaften" onClose={closeModal}>
      <Edit activity={AC.activity!} saveEdit={saveEdit} closeModal={closeModal} />);
    </Modal>
  </>
}


type EditProps = {
  activity: TS.Activity, 
  saveEdit: Function,
  closeModal: () => void,
}
function Edit({activity, saveEdit, closeModal} : EditProps) {
  // state
  const [entity] = useState({isOptionalSubactivity:activity.isOptionalSubactivity});
  const [busy, setBusy] = useState(false);

  // save the update
  const onSave = async(formResult:any) => {
    setBusy(true);
    await saveEdit(formResult.changesetFlat); 
    setBusy(false);
    closeModal();
  }

  // cancel
  const onCancel = () => {
    closeModal();
  }

  // render
  return (
    <Form onCancel={onCancel} onSave={onSave} entity={entity} busy={busy}>
      <Checkbox path="isOptionalSubactivity" label="kann als Option gewählt werden" secondaryLabel="die Unteraktivität als Option darstellen" />
    </Form>
  ); 
}