
const config = {
  name:"Appentura AG",
  address: {
    postal : [
      "Appentura AG", 
      "Bernapark 28",
      "3066 Stettlen"
    ],
    location:  [
      "Appentura AG", 
      "Bernapark 28",
      "3066 Stettlen (Bern)"
    ]
  }
}

export default config