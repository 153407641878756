import React, { useContext, useState, useEffect } from "react";
import * as TS from "../../../../../types";
import { Loader, Dropdown, DropdownOption } from "../../../../controls";
import { SearchContext } from "../Search.Context";
import FilterGroup from "../Search.FilterGroup";

export default function TransportOptions() {
  // context
  const Search = useContext(SearchContext);

  // state
  const [options] = useState<DropdownOption[]>([
    {label:"-", value: ""},
    {label:"eigenes Auto", value:"eigenes Auto"},
    {label:"oeffentlicher Verkehr", value:"oeffentlicher Verkehr"}
  ]);

  // onChange
  const onDropdownChange = (v:string) => {
    const updatedFilter = Search.filter.clone();
    updatedFilter.transportOption = v as ""|"eigenes Auto"|"oeffentlicher Verkehr";
    Search.reset(updatedFilter);
  }

  // render
  return (
    <FilterGroup title="Transportmittel">
      <Dropdown options={options} onChange={onDropdownChange} value={Search.filter.transportOption} />
    </FilterGroup>
  )
}