import React, {useContext, useState, useEffect} from "react"
import * as DA from "../../../types/DataAccess";

import {Value, Button, Icon, Icons, Loader, CardSection, Modal} from "../../controls";
import {ActivityContext} from "./ActivityContext";

import CSS from "./Activity.Execution.Availability.module.scss";

type Weekday = {
  value: number, 
  label: string
}
const weekdays:Weekday[] = [
  {value: 1, label: "Mo"},
  {value: 2, label: "Di"},
  {value: 3, label: "Mi"},
  {value: 4, label: "Do"},
  {value: 5, label: "Fr"},
  {value: 6, label: "Sa"},
  {value: 0, label: "So"},
]; // 0: Sunday ... 6: Saturday


export default function AvailableWeekdays() {
  // state 
  const [mode, setMode] = useState<"edit"|"view">("view");

  // context
  const AC = useContext(ActivityContext)!;

  // user wants to edit
  const onClickEdit = () => {
    setMode("edit");
  }

  // stops edit mode
  const stopEdit = () => setMode("view");

  // saves changes
  const saveEdit = async(availableWeekdays:number[]) => {
    const changeset = {availableWeekdays};
    await AC.update(changeset);
    stopEdit();
  }

  // not loaded?
  if(AC.activity === null) {
    return <Loader text="lade Aktivität ..." />
  }

  
  // pre-reender
  const divDays: any[] = [];
  weekdays.forEach(weekday => {
    const active = AC.activity!.availableWeekdays.includes(weekday.value);
    const icon = active ? <Icon icon={Icons.Check} /> : null;
    divDays.push(
      <div key={weekday.value} className={CSS.matrix_item}>
        <div key={"lbl_" + weekday.value} className={CSS.label}>{weekday.label}</div>
        <div key={"val_" + weekday.value} className={`${CSS.status} ${active ? CSS.active : ""}`}>{icon}</div>
      </div>
    );
  })
  // render
  return <>
    <CardSection title="Verfügbare Wochentage">
      <div className={CSS.availability}>
        <Value onClick={onClickEdit}>
          <div className={CSS.matrix} onClick={onClickEdit}>
            {divDays}
          </div>
        </Value>
      </div>
    </CardSection>
    <Modal
      isOpen={mode==="edit"}
      title="Verfügbare Wochentage"
      onClose={stopEdit}
      wide={true}
    >
      <Edit 
        activity={AC.activity2!}
        onSave={saveEdit}  
        onCancel={stopEdit} 
      />
    </Modal>
  </>
}


type EditProps = {
  activity: DA.Activity,
  onSave: (weekdays:number[]) => void,
  onCancel: () => void,
}
function Edit({activity, onSave, onCancel} : EditProps) {
  // state
  const [availableWeekdays, setAvailableWeekdays] = useState<number[]>([]);
  const [busy, setBusy] = useState(false);

  // mount
  useEffect(() => {
    setAvailableWeekdays(activity.availableWeekdays.map(h => h));
  }, []);

  const toggleWeekday = (weekdayToToggle:number) => {
    let updatedWeekdays;
    if(availableWeekdays.includes(weekdayToToggle)) {
      updatedWeekdays = availableWeekdays.filter(m => m !== weekdayToToggle);
    } 
    else {
      availableWeekdays.push(weekdayToToggle);
      updatedWeekdays = availableWeekdays.map(m => m).sort((a,b) => a - b);
    }
    setAvailableWeekdays(updatedWeekdays);
  }

  // save the update
  const _onSave = async() => {
    setBusy(true);
    await onSave(availableWeekdays); 
    setBusy(false);
  }

  // pre-reender
  const divDays: any[] = [];
  weekdays.forEach(weekday => {
    const active = availableWeekdays.includes(weekday.value);
    const icon = active ? <Icon icon={Icons.Check} /> : "";
    divDays.push(
      <div key={weekday.value} className={CSS.matrix_item}>
        <div key={"lbl_" + weekday.value} className={CSS.label}>{weekday.label}</div>
        <div key={"val_" + weekday.value} className={`${CSS.status} ${active ? CSS.active : ""}`} onClick={() => toggleWeekday(weekday.value)}>{icon}</div>
      </div>
    );
  })
  const trWeekdays: any[] = [];
  const trIcons: any[] = [];
  weekdays.forEach(weekday => {
    const active = availableWeekdays.includes(weekday.value);
    const icon = active ? <Icon icon={Icons.Check} /> : "";
    trWeekdays.push(<td key={weekday.value} className={active ? CSS.active : ""} onClick={() => toggleWeekday(weekday.value)}>{weekday.label}</td>)
    trIcons.push(<td key={weekday.value} className={active ? CSS.active : ""} onClick={() => toggleWeekday(weekday.value)}>{icon}</td>)
  })

  // render
  return (
    <div className={CSS.edit}>
      <div className={CSS.availability}>
        <div className={CSS.matrix}>
          {divDays}
        </div>
      </div>
      <div className={CSS.actions}>
        <Button onClick={onCancel} intent="cancel" busy={busy}>abbrechen</Button>
        <Button onClick={_onSave} intent="confirm" busy={busy}>speichern</Button>
      </div>
    </div>
  ); 
}