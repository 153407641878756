import _ from "lodash";
import { ProviderDocument } from "./ProviderDocument";
import { Mapper } from "../_Common/Mapper";

export class ProviderDocumentMapper extends Mapper<ProviderDocument> {
  public apiEndpoint = "providerDocuments";

  public toDb(providerDocument: ProviderDocument): any {
    return {
      _id: providerDocument._id,
      provider_id: providerDocument.provider_id,
      driveId: providerDocument.driveId,
      driveTitle: providerDocument.driveTitle,
      documentDate: providerDocument.documentDate,
      notes: providerDocument.notes
    }
  }

  public fromDb(obj: any): ProviderDocument {
    return {
      _id: obj._id || undefined,
      provider_id: obj.provider_id || null,
      driveId: obj.driveId ||null,
      driveTitle: obj.driveTitle || "",
      documentDate: obj.documentDate ? new Date(obj.documentDate) : new Date(),
      notes: obj.notes || ""
    }
  }
}