import React from 'react';
import { AdventureWarningKind } from '../../../../types/BusinessLogic';
import { Icon, Icons } from "../../../controls";
import CSS from "./Warning.Control.module.scss";

const WarningIcons:[AdventureWarningKind, any][] = [
  ["warning", Icons.ExclamationCircle],
  ["info", Icons.InfoCircle],
  ["priority", Icons.Crown],
  ["anxiety", Icons.Spider],
  ["allergy", Icons.Utensils],
  ["critical", Icons.Bomb]
];

type WarningProps = {
  kind: AdventureWarningKind,
  children: React.ReactNode|React.ReactNode[]
}
export default function Warning({kind, children}: WarningProps) {
  return (
    <div className={`${CSS.container} ${CSS[kind]}`}>
      <div className={CSS.icon}>
        <Icon icon={getIcon(kind)} />
      </div>
      <div>
        {children}
      </div>
    </div>
  )
}

function getIcon(kind:AdventureWarningKind): any {
  const found = WarningIcons.find(icon => icon[0] === kind);
  if(found) {
    return found[1];
  }
  else {
    Icons.InfoCircle;
  } 
}
