import React from "react";
import Code from "../Code";
// import Notes from "../Notes";
import { Card, Button, Heading } from "../../../controls";

export default function ButtonDemo() {
  return <>
    <Heading>Button</Heading>
    <Card>
      <Button onClick={(data) => alert(data)} data="hello" size="small">Hello</Button>
      <Code>{`<Button onClick={(data) => alert(data)} data="hello">Hello</Button>`}</Code>

      <Button disabled={true} size="small" onClick={() => {}}>Hello</Button>
      <Code>{`<Button disabled={true} size="small">Hello</Button>`}</Code>

      <Button busy={true} size="small" onClick={() => {}}>Hello</Button>
      <Code>{`<Button busy={true}>Hello</Button>`}</Code>

      <Button size="medium" onClick={() => {}}>Hello</Button>
      <Code>{`<Button size="medium">Hello</Button>`}</Code>
    </Card>

    <Card title="intents">
      <Button intent="ok" onClick={() => {}}>ok / confirm</Button>
      <Button intent="delete" onClick={() => {}}>cancel / delete</Button>
    </Card>

    <Card title="inverted">
      <Button intent="ok" inverted onClick={() => {}}>ok / confirm</Button>
      <Button intent="delete" inverted onClick={() => {}}>cancel / delete</Button>
    </Card>
  </>
}
