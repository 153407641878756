import _ from "lodash";
import {GMAP_TRAVEL_MODES} from "../config/constants";

function getMapUrl(startCoordinates, endCoordinates, travelMode) {
  const coordsToString = (c) => `${_.get(c, "lat") || _.get(c, "Lat") || ""},${_.get(c, "lng") || _.get(c, "Lng") || ""}`;
  const extension = travelMode ? GMAP_TRAVEL_MODES.find(tm => {return tm.value === travelMode}).urlExtension : "";
  return `https://www.google.com/maps/dir/${coordsToString(startCoordinates)}/${coordsToString(endCoordinates)}${extension}`;
}

function getDirections({origin, destination, startTime, travelMode}) {
  // documentation of directions api: https://developers.google.com/maps/documentation/javascript/directions
  // documentation of directions service: https://developers.google.com/maps/documentation/javascript/reference/directions?hl=de
  // documentation of result: https://developers.google.com/maps/documentation/javascript/directions#DirectionsResults
  // get directions service
  //const google = window.google;
  //const directionsService = new google.maps.DirectionsService();

  return new Promise((resolve, reject) => {
    // get directions service
    const google = window.google;
    const directionsService = new google.maps.DirectionsService();
    // build request (-> https://developers.google.com/maps/documentation/javascript/directions#DirectionsRequests)
    let request = {
      origin: new google.maps.LatLng({lat:origin.lat, lng:origin.lng}), // new google.maps.LatLng({lat:46.931342, lng:7.422693}),
      destination: new google.maps.LatLng({lat:destination.lat, lng:destination.lng}),
      travelMode: travelMode.toUpperCase(),
      unitSystem: google.maps.UnitSystem.METRIC,
      provideRouteAlternatives: true,
      transitOptions: {
        departureTime: startTime
      },
      drivingOptions: {
        departureTime: startTime
      }
    }
    // fetch directions
    directionsService.route(request, (directionsResult, directionsStatus) => {
      resolve({result: directionsResult, status: directionsStatus});
    });
  })
}

/**
 * Returns the group a transit vehicle belongs to. Either "intercity" or "innercity"
 * @param {*} vehicle 
 */
function getTransitVehicleGroup(vehicle) {
  // https://developers.google.com/maps/documentation/directions/overview#VehicleType
  const innercity = ["RAIL", "HEAVY_RAIL", "COMMUTER_TRAIN", "HIGH_SPEED_TRAIN", "LONG_DISTANCE_TRAIN"];
  return innercity.includes(vehicle.toUpperCase()) ? "intercity" : "innercity";
}

function getAddressFromCoordinates(lat, lng) {
  
  // get geocoder service
  const google = window.google;
  const geocoder = new google.maps.Geocoder();

  // get google maps latlng object
  const latlng = new google.maps.LatLng({lat, lng});

  return new Promise((resolve, reject) => {
    geocoder.geocode({
      'latLng': latlng
    }, (results, status) => {
      try {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            if(results[0].formatted_address) {
              const address = results[0].formatted_address.replace(", Switzerland", "").replace(", Germany", "");
              resolve(address);
            }
            resolve(null);
          } else {
            resolve(null);
          }
        } else {
          console.error('Geocoder failed due to: ' + status);
          resolve(null);
        }
      }
      catch(e) {
        resolve(null);
      }
    });
  });
  
}

function getAddressFromCoordinates2(lat, lng) {
  
  // get geocoder service
  const google = window.google;
  const geocoder = new google.maps.Geocoder();

  // get google maps latlng object
  const latlng = new google.maps.LatLng({lat, lng});

  return new Promise((resolve, reject) => {
    geocoder.geocode({
      'latLng': latlng
    }, function (results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          if(results[0].formatted_address) {
            const address = results[0].formatted_address.replace(", Switzerland", "").replace(", Germany", "");
            resolve(address);
          }
          resolve(null);
        } else {
          resolve(null);
        }
      } else {
        console.error('Geocoder failed due to: ' + status);
      }
    });
  });
  
}

export default {
  getMapUrl,
  getDirections,
  getTransitVehicleGroup,
  getAddressFromCoordinates
}