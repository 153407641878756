import React from "react";
import { Icon, Icons } from ".";
import { Base } from "./internal";
import CSS from "./Button.module.scss";

type ButtonProps = {
  children: React.ReactNode|React.ReactNode[],
  onClick: (data?:any) => void,
  data?: any,
  size?: "small"|"medium",
  narrow?: boolean,
  intent?: "ok"|"confirm"|"cancel"|"delete",
  inverted?: boolean,
  disabled?: boolean,
  busy?: boolean
}
/**
 * A button that make the user confirm the action
 * @param {ButtonProps} props
 */
export function Button({children, onClick, data, size="medium", disabled, busy, intent="ok", inverted, narrow=false} : ButtonProps) {
  // user clicks
  const click = () => {
    if(!disabled && !busy && onClick) {
      onClick(data);
    }
  }

  // render
  let cssIntent = CSS[intent] || "";
  if(inverted) {
    cssIntent = CSS[intent + "_inverted"];
  }
  return (
    <Base disabled={disabled} busy={busy}>
      <div className={`${CSS.inner} ${disabled ? CSS.disabled : ""} ${busy ? CSS.busy : ""} ${narrow ? CSS.narrow : ""} ${size ? CSS[size] : ""} ${cssIntent}`}>
        <div className={CSS.main} onClick={click}>{children}</div>
        <div className={CSS.cog}>
          <div className={CSS.cog_inner}>
            <Icon icon={Icons.CircleNotch} />
          </div>
        </div>
      </div>
    </Base>
  )
  /*
  return <div className={`${CSS.container} ${disabled ? CSS.disabled : ""} ${busy ? CSS.busy : ""} ${narrow ? CSS.narrow : ""} ${size ? CSS[size] : ""} ${cssIntent}`}>
    <div className={CSS.inner}>
      <div className={CSS.main} onClick={click}>{children}</div>
      <div className={CSS.cog}><Icon icon={Icons.CircleNotch} /></div>
    </div>
  </div>
  */
}
