import React, { useState, useEffect } from "react";
import * as TS from "../../../types";
// components & subviews
import { Breadcrumb, Card, Heading, Icon, Icons, Switch, List, ListColumn, Loader, LinkButton } from "../../controls";
import PublishCatalogue from "../Shop/PublishCatalogue";

// throw away type only used in list
type ListedActivity = {
  _id: string,
  title: string,
  isActive: boolean,
  isSubactivity: boolean,
  isOnlineEvent: boolean,
  isFeatured: boolean,
  requiresMeteocheck: boolean,
  availableInCH: boolean,
  availableInDE: boolean,
  tags: string,
  // parent?: any 
}

// the component
export default function ActivityList() {
  // state
  const [activities, setActivities] = useState<ListedActivity[]|null>(null);
  const [showInactive, setShowInactive] = useState(false);
  const [showSubactivities, setShowSubactivities] = useState(false);
  const [columns] = useState<ListColumn[]>([
    {
      header: "Name", 
      path:"title", 
      print: (v:ListedActivity) => { 
        return v.isSubactivity ? <span><Icon icon={Icons.Layers} />{v.title}</span> : v.title;
      },
      sort: (a:ListedActivity, b:ListedActivity) => a.title.localeCompare(b.title)
    },
    {
      header: "CH", 
      path: "availableInCH", 
      print: (v:ListedActivity) => v.availableInCH ? <Icon icon={Icons.Check} /> : "",
      sort:(a:ListedActivity, b:ListedActivity) => {
        const v_a = a.availableInCH ? 0 : 1;
        const v_b = b.availableInCH ? 0 : 1;
        return v_a > v_b ? 1 : -1;
      }
    },
    {
      header: "DE", 
      path: "availableInDE", 
      print: (v:ListedActivity) => v.availableInDE ? <Icon icon={Icons.Check} /> : "",
      sort:(a:ListedActivity, b:ListedActivity) => {
        const v_a = a.availableInDE ? 0 : 1;
        const v_b = b.availableInDE ? 0 : 1;
        return v_a > v_b ? 1 : -1;
      }
    },
    {
      header: "Online", // header:<span>Online<Icon icon={Icons.Laptop} /></span>,
      path: "isOnlineEvent",
      print: (v:ListedActivity) => v.isOnlineEvent ? <Icon icon={Icons.Laptop} /> : "",
      sort:(a:ListedActivity, b:ListedActivity) => {
        const v_a = a.isOnlineEvent ? 0 : 1;
        const v_b = b.isOnlineEvent ? 0 : 1;
        return v_a > v_b ? 1 : -1;
      }
    },
    {
      header: "Featured", //<span>Featured<Icon icon={Icons.Award} /></span>,
      path: "isFeatured",
      print: (v:ListedActivity) => v.isFeatured ? <Icon icon={Icons.Award} /> : "",
      sort:(a:ListedActivity, b:ListedActivity) => {
        const v_a = a.isFeatured ? 0 : 1;
        const v_b = b.isFeatured ? 0 : 1;
        return v_a > v_b ? 1 : -1;
      }
    },
    {
      header: "Unteraktvität", // <span>Unteraktivität<Icon icon={Icons.Layers} /></span>,
      path: "isSubactivity",
      print: (v:ListedActivity) => v.isSubactivity ? <Icon icon={Icons.Layers} /> : "",
      sort:(a:ListedActivity, b: ListedActivity) => {
        const v_a = a.isSubactivity ? 0 : 1;
        const v_b = b.isSubactivity ? 0 : 1;
        return v_a > v_b ? 1 : -1;
      }
    },
    {
      header: "Meteocheck", //<span>Meteocheck<Icon icon={Icons.Weather} /></span>,
      path: "requiresMeteocheck",
      print: (v:ListedActivity) => v.requiresMeteocheck ? <Icon icon={Icons.Weather} /> : "",
      sort:(a:ListedActivity, b:ListedActivity) => {
        const v_a = a.requiresMeteocheck ? 0 : 1;
        const v_b = b.requiresMeteocheck ? 0 : 1;
        return v_a > v_b ? 1 : -1;
      }
    },
    {
      header: "Aktiv", 
      path: "isActive", 
      print: (v:ListedActivity) => {
        return v.isActive ? <Icon icon={Icons.Check} /> : "";
      }, 
      sort:(a:ListedActivity, b:ListedActivity) => {
        const v_a = a.isActive ? 0 : 1;
        const v_b = b.isActive ? 0 : 1;
        return v_a > v_b ? 1 : -1;
      }
    },
    {
      header: "Tags",
      path: "tags",
      print: (v:ListedActivity) => v.tags,
      sort: () => 0
    }
  ])

  // mount
  useEffect(() => {
    load()
  }, [])

  // loads data
  const load = async() => {
    const data = await TS.Activity.search({});
    const activities:ListedActivity[] = data.map(a => {
      const listedActivity:ListedActivity = {
        _id: a._id,
        // title
        title: a.title.de,
        // flags
        isActive: a.isActive, // (a.regionVariants || []).some((a:any) => a.isActive),
        isSubactivity: a.isSubactivity,
        isFeatured: a.isFeatured,
        isOnlineEvent: a.isOnlineEvent,
        requiresMeteocheck: a.requiresMeteocheck,
        // countries
        availableInDE: a.regionVariants.some((rv:any) => rv.regionCode.includes("DE-") && rv.isActive),
        availableInCH: a.regionVariants.some((rv:any) => rv.regionCode.includes("CH-") && rv.isActive),
        // tags
        tags: (a.tags || []).sort((a:any, b:any) => a.localeCompare(b)).join(", "),
        
      }
      /*
      // what is the parent activity (if it is a subactivity)
      if(a.isSubactivity) {
        // find main activity
        const ma = data.find(item => {
          const sas = item.subactivities;
          return sas.some(sa => String(sa.activity_id) === (String(a._id)))
        })
        if(ma) {
          listedActivity.parent = ma
        }
      }
      */
      // done
      return listedActivity;
    })
    setActivities(activities);
  }

  // render
  let content = null;
  if(activities === null) {
    content = <Loader text="lade Aktivitäten ..." />
  }
  else {
    const filter = (v:ListedActivity) => {
      if(!showInactive && !v.isActive) {
        return false;
      }
      else if(!showSubactivities && v.isSubactivity) {
        return false;
      }
      else {
        return true;
      }
    }
    // TODO do not use inline style
    content = <>
      <div>
        <PublishCatalogue requestConfirmation={true} />
      </div>
      <div style={{display:"flex",flexDirection:"row"}}>
        <LinkButton to="/activities/create" size="small"><Icon icon={Icons.Plus} />&nbsp;neue Aktivität</LinkButton>
      </div>
      <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
        <Switch value={showInactive} onChange={(v:any) => setShowInactive(!showInactive)} size="small" />&nbsp;inaktive anzeigen
      </div>
      <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
        <Switch value={showSubactivities} onChange={(v:any) => setShowSubactivities(!showSubactivities)} size="small" />&nbsp;Unteraktivitäten anzeigen
      </div>
      <List columns={columns} items={activities} rowTo={item => `/activities/${item._id}`} getKey={item => item._id} filter={filter} />
      
    </>
  }
  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}]} location="Aktivitäten" />
    <Heading>Aktivitäten</Heading>
    <Card id="v-packagingtype-list">
      {content}
    </Card>
  </>
}


