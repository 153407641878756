import Util from '../../../util/util'
import * as TS from "../../../types";
import { Table, Td, Tr } from "../../controls";
import CSS from  './VendorInvoice.module.scss';


type PaymentsProps = {
  invoice?: TS.VendorInvoice|null
}
export default function Payments({invoice} : PaymentsProps) {
  let content = null;
  if(invoice && invoice.payments.length > 0) {
    const paymentItems = invoice.payments.map(p => {
      let paymentDate = p.paymentDate ? Util.printDate(p.paymentDate) : "Zahlungsausgang"
      let paymentType = p.paymentType ? `${p.paymentType.accountingId} ${p.paymentType.name}` : ''
      return (
        <Tr key={p._id}>
          <Td>{paymentDate}</Td>
          <Td>{paymentType}</Td>
          <Td align="right">{p.amount.toFixed(2)}</Td>
        </Tr>
      )
    })
    content = <Table className={CSS.secondary}>{paymentItems}</Table>;
  }
  else {
    content = <div className={CSS.secondary}>noch keine Zahlungen vorhanden</div>;
  }

  return (
    <Tr>
      <Td label>Zahlungen</Td>
      <Td>{content}</Td>
    </Tr>
  )
}