import _ from "lodash";
import React, { useState, useMemo, useEffect } from "react";
import { Breadcrumb, Card, Heading, List, Icon, Icons, Alert } from "../../../controls";
import Api from '../../../../util/api';

export default function OfflineSoldAdventureList(props) {
  // memoize columns
  const columns = useMemo(() => {
    return [
      //{header:"Bedingung", key:"value", print: v => v.title.de, sort: (a, b) => a.title.de.localeCompare(b.title.de)},
      {header:"Name", print: v => _.get(v, "SoldOfflineInfo.Name") || ""},
      {header:"Laden", print: v => _.get(v, "SoldOfflineInfo.Store") || ""},
      {header:"Region", print: v => _.get(v, "Region") || ""},
      {header:"Id", print: v => _.get(v, "_id") || ""},
      {header:"Test", print: v => v.IsTest ? <Icon icon={Icons.Check} /> : null, sort:(a,b) => (a.IsTest ? 1:-1) > (b.IsTest ? 1:-1) ? 1:-1}
    ]
  }, []);

  // state
  const [items, setItems] = useState();

  // mount
  useEffect(() => {
    const load = async() => {
      const items = await Api.get('adventures?soldoffline');
      setItems(items);
    }
    load();
  }, [])

  // render
  return <>
    <Breadcrumb 
      links={[ {title:'Home', to:'/'}]}
      location="Vorgefertigte Überraschungen" 
    />
    <Heading>Vorgefertigte Überraschungen</Heading>
    <Card>
      <Alert intent="info" size="small">
        es können keine neuen vorgefertigten Überraschungen mehr erstellt werden
      </Alert>
      <List columns={columns} items={items} getKey={v => v._id} rowTo={item => `/surprise/${item._id}`} />
    </Card>
  </>
}

