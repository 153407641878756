import React from "react";
import {Card} from "../../controls";

import Images from "./Activity.Images";
import Videos from "./Activity.Videos";
import Stories from "./Activity.Stories";

export default function ImagesAndVideos() {
  // render
  return <Card title="Bilder, Videos & Erfahrungsberichte" expanded={false} collapsible={true}>
    <Images />
    <br />
    <Videos />
    <br />
    <Stories />
  </Card>
}