import _ from "lodash";
import React, {useContext, useRef, useState, useEffect} from 'react';
import {TICKER_TRAVEL_MODES, GMAP_TRAVEL_MODES} from '../../../../config/constants';
import {GoogleMaps as GoogleMapsUtil} from "../../../../util";
import Util from "../../../../util/util";

// context
import {StepsContext} from "./Steps.Context";
import {SurpriseContext} from "../Surprise.Context";

// controls
import { Button, ConfirmButton, Link, Icon, Icons, PdfLink, Row, LinkButton} from '../../../controls'
import Section from "./Step.Section";

// styling
import "./Step.Read.scss";
//import Step from "./Step.Control";

// the component
export default function Read({step}) {
  // context
  const SC = useContext(SurpriseContext);
  const STC = useContext(StepsContext);

  // starts editing mode
  const startEdit = () => {
    STC.setCurrentStep(step);
  }
  // removes step
  const remove = () => {
    const steps = SC.steps.filter(s => s !== step);
    SC.updateAtPath("Steps", steps, true);
  }

  // render
  return (
    <div className="c-step-read">
      {_.isNil(step.AlertDate) ? null : <div className="alert"><Icon icon={Icons.Slack} />&nbsp;Slack Alert wenn nicht erreicht bis {Util.printTime(step.AlertDate)}</div>}
      <div className="title">{step.Title}</div>
      <div className="instructions">
        <ul>
          {(step.Instructions || []).map((line, i) => <li key={i}>{line}</li>)}
        </ul>
      </div>
      <Image step={step} />  
      <Map step={step} surprise={SC.surprise} />
      <Attachments step={step} />
      <Links step={step} />
      <Activity step={step} />
      <Ticker step={step} />
      <Row align="center">
        <ConfirmButton disabled={STC.currentStep !== null} intent="delete" onConfirm={remove}>
          <Icon icon={Icons.Trash} />
        </ConfirmButton>
        <Button disabled={STC.currentStep !== null} onClick={startEdit} size="small">bearbeiten</Button>
      </Row>
    </div>
  )
}


function Image({step}) {
  const [large, setLarge] = useState(false);
  
  // no image to render? abort
  if((step.ImageUrl || "").trim() === "") {
    return null
  }

  // render
  return ( <>
    <Section title="Bild" className={`image${large ? " large" : ""}`}>
      <div className="image-inner" onClick={() => setLarge(!large)} >
        <img src={step.ImageUrl} alt="step illustration" />
        <span className="toggle">
          {large ? <Icon icon={Icons.SearchMinus} /> : <Icon icon={Icons.SearchPlus} />}
        </span>
      </div>
    </Section>
    {step.ImageText ?  <Section title="Text auf dem Bild">
      <div>{step.ImageText}</div>
    </Section>  : null }
    </>
  )
}

function Activity({step}) {
  // context
  const SC = useContext(SurpriseContext);

  // render
  if(!step.ActivityId) {
    return null;
  }
  let activity = SC.activityCatalogue.find(a => a._id === step.ActivityId);
  if(!activity) {
    return null;
  }
  return (
    <Section title="Verbunden Aktivität">
      {_.get(activity, "title.de") || "unbekannte Aktivität"}
    </Section>
  )
}

function Map({step, surprise}) {
  const divMap = useRef();
  const [hasRoute, setHasRoute] = useState(step.StartCoordinates && step.EndCoordinates);
  const [showMap, setShowMap] = useState(false);
 
  useEffect(() => {
    if(hasRoute && showMap) {
      if (window.google && window.google.maps) {
        initMap()
      } else {
        window.addEventListener("googleMapsLoaded", initMap);
      }
    }
  }, [hasRoute, showMap])

  useEffect(() => {
    if(step) {
      const hasRoute = step.StartCoordinates && step.EndCoordinates;
      setHasRoute(hasRoute);
    }
    else {
      setHasRoute(false)
    }
  }, [step]);


  const initMap = () => {
    const google = window.google

    // get gmap coordinate objects
    const latlngStart = new google.maps.LatLng(step.StartCoordinates.Lat, step.StartCoordinates.Lng);
    const latlngEnd = new google.maps.LatLng(step.EndCoordinates.Lat, step.EndCoordinates.Lng);

    // calculate map bounds
    const bounds = new google.maps.LatLngBounds();
    bounds.extend(latlngStart);
    bounds.extend(latlngEnd);

    // set up directions service and options
    const directionsService = new google.maps.DirectionsService();
    const directionsDisplay = new google.maps.DirectionsRenderer();
    const myOptions = {
      zoom: 14,
      center: latlngStart,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false
    };

    // get map object and apply bounds and directions
    const map = new google.maps.Map(divMap.current, myOptions);
    map.fitBounds(bounds);
    directionsDisplay.setMap(map);
    directionsDisplay.setPanel(document.getElementById("directionsPanel"));

    // if there is a transport option Auto - use car travel mode
    const isCar = surprise.TransportOptions && surprise.TransportOptions.find(x => { return x.indexOf("Auto") >= 0 })
    
    // create the request object
    const request = {
      origin: latlngStart,
      destination: latlngEnd,
      travelMode: step.TravelMode ? google.maps.TravelMode[step.TravelMode] : (isCar ? google.maps.TravelMode.DRIVING : google.maps.DirectionsTravelMode.TRANSIT)
    };

    // submit request
    directionsService.route(request, (response, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        directionsDisplay.setDirections(response);
      } else {
        // TODO should we really deal with this in here? move to logic 
        if (status === 'ZERO_RESULTS') {
          alert('No route could be found between the origin and destination.');
        } else if (status === 'UNKNOWN_ERROR') {
          alert('A directions request could not be processed due to a server error. The request may succeed if you try again.');
        } else if (status === 'REQUEST_DENIED') {
          alert('This webpage is not allowed to use the directions service.');
        } else if (status === 'OVER_QUERY_LIMIT') {
          alert('The webpage has gone over the requests limit in too short a period of time.');
        } else if (status === 'NOT_FOUND') {
          alert('At least one of the origin, destination, or waypoints could not be geocoded.');
        } else if (status === 'INVALID_REQUEST') {
          alert('The DirectionsRequest provided was invalid.');
        } else {
          alert("There was an unknown error in your request. Requeststatus: \n\n" + status);
        }
      }
    });
  
  }

  // render
  if(hasRoute) {
    let map = showMap ? <div ref={divMap} className="googleMap"></div> : null
    let mapToggle = showMap ? <div className="map-toggle" onClick={() => setShowMap(false)}>Karte ausblenden</div> : <div className="map-toggle" onClick={() => setShowMap(true)}>Karte anzeigen</div>
    if(_.isNil(step.TravelMode)) {
      // dirty fix: older adventures have no travel mode ... set it to null so we can find the 'undefined' mode in GMAP_TRAVEL_MODES
      step.TravelMode = null;
    }
    const mode = GMAP_TRAVEL_MODES.find(mode => mode.value === step.TravelMode);
    const mapUrl = GoogleMapsUtil.getMapUrl(step.StartCoordinates, step.EndCoordinates, step.TravelMode);
    return (
      <Section title="Anreise">
        <div className="map">
          {map}
          <div className="toggle-and-link">
            <div className="map-travelmode">
              Reise mit <Icon icon={mode.icon2} tooltip={mode.label} />
            </div>
            {mapToggle}
            <LinkButton to={mapUrl} target="_blank">
              Google Maps öfnnen
            </LinkButton>
          </div>
        </div>
      </Section>
    )
  }
  else {
    return null
  }
}

function Attachments({step}) {
  if((step.Attachments || []).length === 0) {
    return null
  }
  const lis = (step.Attachments || []).map(a => <li key={a._id || a._tempId}><PdfLink to={a.Url} target="_blank">{a.Title}</PdfLink></li>);
  return (
    <Section title="Anhänge">
      <div className="attachments"><ul>{lis}</ul></div>
    </Section>
  )
}

function Links({step}) {
  if((step.Links || []).length === 0) {
    return null
  }
  const lis = (step.Links || []).map(a => <li key={a._id || a._tempId}><Link to={a.Url} target="_blank">{a.Title}</Link></li>);
  return (
    <Section title="Links">
      <div className="attachments"><ul>{lis}</ul></div>
    </Section>
  )
}

function Ticker({step}) {
  let message = null;
  if((step.TickerMessage || "").trim().length !== 0) {
    message = <div><label>angezeigter Text: </label>{step.TickerMessage}</div>
  } 
  let travel = null;
  if((step.TickerTravel || "").trim().length !== 0) {
    const mode = TICKER_TRAVEL_MODES.find(m => m.value === step.TickerTravel);
    if(mode) {
      travel = <div><label>unterwegs mit: </label><Icon tooltip={mode.label} icon={mode.icon2} /></div>
    }
    
  }
  if((step.TickerMessage || "").trim().length === 0 && (step.TickerTravel || "").trim().length === 0) {
    return null;
  }

  return (
    <Section title="Ticker">
      {message}
      {travel}
    </Section>
  )
}