import React, {useEffect, useState, useContext} from "react"
import _ from "lodash"
//import Logic from "../../../../logic";
import * as TS from "../../../../types";
import * as DA from "../../../../types/DataAccess";
import * as BL from "../../../../types/BusinessLogic";
import { Button, ConfirmButton, Form, Icon, Icons, Loader, Modal, Value, TextArea, SearchableDropdown, Validators, CardSection, Table, Tr, Td } from "../../../controls";
import { ProviderLink } from "../../../common/";
import { ActivityContext } from "../ActivityContext";

import CSS from "./Activity.RegionVariant.Providers.module.scss";


export default function Providers() {
  // context
  const AC = useContext(ActivityContext)!;

  // state
  const [providers, setProviders] = useState<any>(null);
  const [mode, setMode] = useState<"view"|"edit"|"create">("view");
  const [modalParam, setModalParam] = useState<any|null>(null);

  // load providers when region variant has changed
  useEffect(() => {
    // load
    const load = async() => {
      const ids = (AC.regionVariant!.providers || []).map(p => p.id);
      const providers = await DA.ProviderRepository.search({_id:{$in:ids}});
      const providersSorted = providers.sort((a, b) => {
        let sort = BL.Provider.colorRatingToValue(a.colorRating) - BL.Provider.colorRatingToValue(b.colorRating)
        if(sort === 0) {
          sort = a.name.localeCompare(b.name);
        }
        return sort;
      });
      providersSorted.forEach((p:any) => {
        const assignment = AC.regionVariant!.providers.find(a => a.id === p._id);
        p.notes = assignment ? assignment.notes : "";
      });
      setProviders(providersSorted);
    }
    load();
  }, [AC.regionVariant!, AC.regionVariant!.providers])

  // saves changes
  const saveEdit = async(providerId:string, notes:string) => {
    const updatedProviders = AC.regionVariant!.providers.map(p => p.toDb());
    updatedProviders.find(p => p.id === providerId)!.notes = notes;
    const changeset = {providers: updatedProviders};
    await AC.updateRegionVariant(AC.regionVariant!._id!, changeset);
  }

  // adds a provider
  const saveAdd = async(providerId: string, notes: string) => {
    const newProvider = new TS.ActivityRegionVariantProvider(providerId, notes);
    AC.regionVariant!.providers.push(newProvider);
    const updatedProviders = AC.regionVariant!.providers.map(p => p.toDb());
    const changeset = {providers: updatedProviders};
    await AC.updateRegionVariant(AC.regionVariant!._id!, changeset);
  }

  // closes dialog
  const stopEdit = () => setMode("view");

  // user wants to add a provider
  const onClickAdd = () => {
    setMode("create");
  }

  // user wants to edit an entry (i.e. edit assigment notes)
  const onClickEdit = ({providerId, providerName, notes}: {providerId:string, providerName: string, notes: string}) => {
    setModalParam({providerId, providerName, notes});
    setMode("edit");
  }

  // user wants to delete an entry
  const onClickDelete = async(providerId: string) => {
    const updatedProviders = AC.regionVariant!.providers.filter(p => p._id !== providerId).map(p => p.toDb());
    const changeset = {providers: updatedProviders};
    await AC.updateRegionVariant(AC.regionVariant!._id!, changeset);
  }

  // pre-render
  let content = null;
  if(_.isNil(providers)) {
    content = <Loader text="lade Anbieter" />;
  }
  else {
    const items = providers.map((p:any) => {
      let notes = null;
      if(p.notes && p.notes.trim().length > 0) {
        const lines = p.notes.trim().split('\n').map((line:string, index:number) => <div key={index}>{line}</div>);
        notes = <div className={CSS.notes}>{lines}</div>;
      }
      // render
      return <Tr key={p._id}>
        <Td>{colorRatingToIcon(p.colorRating)}</Td>
        <Td>
          <ProviderLink provider={p as DA.Provider} />
          {notes}
        </Td>
        <Td><ConfirmButton onConfirm={() => onClickDelete(p._id)}><Icon icon={Icons.Trash} /></ConfirmButton></Td>
        <Td><Button size="small" onClick={() => onClickEdit({providerId:p._id, providerName:p.name, notes:p.notes})}><Icon icon={Icons.Edit} /></Button></Td>
      </Tr>
    })
    content = <>
      <Table>{items}</Table>
      <Button size="small" onClick={onClickAdd}><Icon icon={Icons.Plus} /> hinzufügen</Button>
    </>
  }

  // render
  let modalContent = null;
  let modalTitle = "";
  switch(mode) {
    case "create":
      modalTitle = "Anbieter hinzufügen";
      modalContent = <Add saveAdd={saveAdd} stopEdit={stopEdit} />
      break;
    case "edit":
      modalTitle = "Anbieterzuweisung bearbeiten";
      modalContent = <Edit providerName={modalParam.providerName} providerId={modalParam.providerId} notes={modalParam.notes} saveEdit={saveEdit} stopEdit={stopEdit} />
      break;
  }
  return <>
    <CardSection title="Anbieter">
      <div className={CSS.container}>
        {content}
      </div>
    </CardSection>
    <Modal isOpen={mode !== "view"} title={modalTitle} onClose={stopEdit}>
      {modalContent}
    </Modal>
  </>;
}


type EditProps = {
  providerName: string,
  providerId: string,
  notes: string,
  saveEdit: Function,
  stopEdit: () => void,
}
function Edit({providerName, providerId, notes, saveEdit, stopEdit} : EditProps) {
  

  // state
  const [entity] = useState({notes:notes});
  const [busy, setBusy] = useState(false);

  // save the update
  const onSave = async(formResult:any) => {
    setBusy(true);
    await saveEdit(providerId, formResult.changeset.notes); 
    setBusy(false);
    stopEdit();
  }

  // cancel
  const onCancel = () => {
    stopEdit();
  }

  // render
  return (
    <Form onCancel={onCancel} onSave={onSave} entity={entity} busy={busy}>
      <Value label="Anbieter">{providerName}</Value>
      <TextArea path="notes" label="Notizen" />
    </Form>
  ); 
}

type AddProps = {
  saveAdd: Function,
  stopEdit: () => void,
}
function Add({saveAdd, stopEdit} : AddProps) {
  // state
  const [busy, setBusy] = useState(false);
  const [entity] = useState({notes:"", providerId:null})
  const [providers, setProviders] = useState<any>(null);

  // mount (load all available providers)
  useEffect(() => {
    const load = async() => {
      const items = await DA.ProviderRepository.search({});
      
      const providers = items.map(p => {
        return {
          value:p._id,
          label:p.name,
          colorRating:p.colorRating
        }
      });
      setProviders(providers);
    };
    load();
  }, []);

  // saves the entry
  const onSave = async(formResult: any) => {
    setBusy(true);
    const providerId = _.get(formResult, "changesetFlat.providerId");
    const notes = _.get(formResult, "changesetFlat.notes");
    await saveAdd(providerId, notes);
    setBusy(false);
    stopEdit();
  }

  // cancel
  const onCancel = () => {
    stopEdit();
  }

  // still loading?
  if(_.isNil(providers)) {
    return <Loader text="lade Anbieter ..." />
  }
  // render
  return (
    <Form onCancel={onCancel} onSave={onSave} busy={busy} entity={entity}>
      <SearchableDropdown 
        label="Anbieter" 
        path="providerId" 
        options={providers} 
        validate={Validators.isRequired("Bitte einen Anbieter wählen")} 
        transform={transformProvider} 
        focusOnMount={true} 
        onChange={undefined}
      />
      <TextArea path="notes" label="Notizen" />
    </Form>
  )
}


function colorRatingToIcon(colorRating: string) {
  const icons:any = {green:Icons.ThumbsUp, orange:Icons.ExclamationTriangle, red:Icons.ThumbsDown};
  const icon = icons[colorRating];
  if(icon) {
    return <span style={{color:colorRating}}><Icon icon={icons[colorRating]} /></span>
  }
  else {
    return null;
  }
}

function transformProvider(p:any) {
  let icon = " "
  switch((p.colorRating || '').toLowerCase()) {
    case 'green':
      icon = "🟢"
      break
    case 'orange':
      icon = "🟠"
      break
    case 'red':
      icon = "🔴"
      break
    default:
      icon = "⚪️"
      break
  }
  return `${icon} ${p.label}`
}

