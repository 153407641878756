import _ from "lodash";
import React, {useContext, useState, useEffect} from "react"
import * as TS from "../../../../types";
import { CardSection, Checkbox, Form, Icon, Icons, Modal } from "../../../controls";
import { ActivityContext } from "../ActivityContext";

import CSS from "./Activity.RegionVariant.Status.module.scss";

// throw away type
type Field = {
  path: string, label: string, getValue: (v:TS.ActivityRegionVariant) => boolean
}

// the component
export default function Status() {
  // state 
  const [mode, setMode] = useState<"edit"|"view">("view");
  const [fields, setFields] = useState<Field[]>([]);

  // context
  const AC = useContext(ActivityContext)!;

  // mount 
  useEffect(() => {
    setFields([
      {path:"isActive", label:"ist aktiv", getValue: v => v.isActive},
      {path:"isSoldOut", label:"ist ausverkauft", getValue: v => v.isSoldOut}
    ])
  }, [AC.regionVariant]);

  // user wants to edit
  const startEdit = () => setMode("edit");
  // stops edit mode
  const stopEdit = () => setMode("view");


  // saves changes
  const saveEdit = async(changeset:any) => {
    await AC.updateRegionVariant(AC.regionVariant!._id!, changeset);
    stopEdit();
  }
  
  // pre-reender
  const items = fields.map(field => {
    const active = _.get(AC.regionVariant, field.path) || false;
    return <tr key={field.path} className={active ? CSS.active : CSS.inactive}>
      <td><Icon icon={active ? Icons.Check : Icons.Cancel} /></td>
      <td>{field.label}</td>
      <td><Icon icon={Icons.Edit} /></td>
    </tr>
  })
  // render
  return <>
    <CardSection title="Status">
      <div className={CSS.container}>
        <table onClick={startEdit}><tbody>
          {items}
        </tbody></table>
      </div>
    </CardSection>
    <Modal isOpen={mode==="edit"} title="Regionsvariante - Status" onClose={stopEdit}>
      <Edit regionVariant={AC.regionVariant!} saveEdit={saveEdit} onCancel={stopEdit} fields={fields} />
    </Modal>
  </>
}


type EditProps = {
  fields: Field[],
  regionVariant: TS.ActivityRegionVariant,
  saveEdit: Function,
  onCancel: () => void,
}
function Edit({fields, regionVariant, saveEdit, onCancel} : EditProps) {
  // state
  const [entity, setEntity] = useState(null);
  const [busy, setBusy] = useState(false);

  // mount
  useEffect(() => {
    const entity:any = {};
    fields.map(field => entity[field.path] = field.getValue(regionVariant));
    setEntity(entity);
  }, []);

  // save the update
  const onSave = async(formResult:any) => {
    setBusy(true);
    await saveEdit(formResult.changesetFlat); 
    setBusy(false);
  }

  // render
  const checkboxes = fields.map(field => <Checkbox key={field.path} path={field.path} label={field.label} />);
  return (
    <Form onCancel={onCancel} onSave={onSave} entity={entity} busy={busy}>
      {checkboxes}
    </Form>
  ); 
}