import React from 'react'

// subviews
import RedeemTimeframe from "./RedeemTimeframe"
import Message from "./Message"

// controls
import {Breadcrumb, Heading} from "../../../controls"


function SurpriseProperties() {
  return (
    <div id="v-surpriseproperties">
      <Breadcrumb
        links={[{title:'Home', to:'/'}]}
        location='Texte für surprise.appentura.ch'
      />
      <RedeemTimeframe />
      <Heading>Meldung auf Einlöseseite</Heading>
      <Message propertyKey="REDEEM_MESSAGE" title="Standard-Überraschungen" />
      <Message propertyKey="REDEEM_MESSAGE_HOMEDELIVERY" title="Heimlieferung" />
      <Message propertyKey="REDEEM_MESSAGE_ONLINEEVENT" title="Online-Event" />
    

      <Heading>Meldung für die Stati 'Termin gewählt', 'Bedingungen verschickt', 'Bedingungen akzeptiert'"</Heading>
      <Message propertyKey="PREPARING_MESSAGE" title="Standard-Überraschungen" />
      <Message propertyKey="PREPARING_MESSAGE_HOMEDELIVERY" title="Heimlieferung" />
      <Message propertyKey="PREPARING_MESSAGE_ONLINEEVENT" title="Online-Event" />
    
    </div>
  )
}

export default SurpriseProperties