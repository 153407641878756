import { useEffect } from "react";
import { Util } from "../../../util";
import AdventureTemplateList from "./AdventureTemplate.List";
import AdventureTemplateEdit from "./AdventureTemplate.Edit";

export default function AdventureTemplate(props:any) {
  useEffect(() => {
    
  }, [props]);
  const routeId = Util.getRouteId(props);
  if(!routeId) {
    return <AdventureTemplateList />
  }
  else {
    return <AdventureTemplateEdit templateId={routeId} />
  }
}