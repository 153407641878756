import { RegionMapper } from "./Region.Mapper";
import { Region } from "./Region";
import { RepositoryFactory } from "../_Common/RepositoryFactory";


export class RegionRepository {
  private static mapper = new RegionMapper();
  
  // from factory
  static make = RepositoryFactory.make<Region, RegionMapper>(this.mapper);
  static search = RepositoryFactory.search<Region, RegionMapper>(this.mapper);
  static create = RepositoryFactory.create<Region, RegionMapper>(this.mapper);
  static update = RepositoryFactory.update<Region, RegionMapper>(this.mapper);
  static remove = RepositoryFactory.remove<Region, RegionMapper>(this.mapper);

  // additional
  static async findAll(): Promise<Region[]> {
    const regions = await RegionRepository.search({});
    return regions.sort((a,b) => a.code.localeCompare(b.code));
  }

  static async findByCode(code:string): Promise<Region|null> {
    const regions = await RegionRepository.search({filter: {code:code}});
    if(regions.length === 0) {
      return null;
    } else {
      return regions[0];
    }
  }
}

