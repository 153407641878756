import React, {useState, useEffect} from "react";
import Api from "../../../../util/api";
import { Breadcrumb, Card, Heading, Icon, Icons, List, Loader } from "../../../controls";
import * as TS from "../../../../types";

// the component
export default function Promotions() {
  // state
  const [adventures, setAdventures] = useState(null);

  const [columns] = useState([
    {
      header: "Promo", 
      path:"PromoName", 
      print:(name:string) => { 
        return name ? name : '---';
      }
    },
    {
      header: "Receiver", 
      path: "RecieverName",
      print: (v:any) => v || "---"
    },
    {
      header: "Id", 
      path: "_id",
      print: (v:any) => v
    },
    {
      header: "Test", 
      path: "IsTest", 
      print:(v:any) => { 
        return v ? <Icon icon={Icons.Check} /> : null;
      }
    }
  ])

  // mount
  useEffect(() => {
    load()
  }, [])

  // loads data
  const load = async() => {
    const projection = {
      PromoName:1,
      IsTest:1,
      RecieverName:1
    };
    const filter = {
      "$and" : [
        {"PromoName":{"$exists":true}},
        {"PromoName":{"$ne":null}},
        {"Status":{"$ne":TS.AdventureStatus.Archived}}
      ]
    };

    // build the request body
    let requestBody = {
      projection:projection,
      filter:filter
    };

    // execute the search
    const result = await Api.post('adventures/search', requestBody);
    // set state
    setAdventures(result.data);
    
  }

  // render
  let content = null;
  if(adventures === null) {
    content = <Loader text="lade Daten ..." />
  }
  else {
    // TODO do not use inline style
    content = (
      <List 
        columns={columns} 
        items={adventures} 
        rowTo={item => `/surprises/${item._id}`} 
        getKey={item => item._id}  
      />
    );
  }
  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}]} location="Überraschungen aus Promotionen" />
    <Heading>Überraschungen aus Promotionen</Heading>
    <Card>
      {content}
    </Card>
  </>
}
