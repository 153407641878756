import React from "react";
//import Code from "../Code";
import {Tabs, TabsItem, Heading} from "../../../controls";

export default function TabsDemo() {
  // onChange event
  const onChange = (tabItem: TabsItem) => {
    
  }

  // pre-render
  const items: TabsItem[] = [
    {label:"left", value:"r"},
    {label:"right", value:"r"}
  ]
  // render
  return <>
    <Heading>Tabs</Heading>
    <Tabs items={items} onChange={onChange} />
  </>
}
