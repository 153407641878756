import React, {useState} from "react";
// import Code from "../Code";
// import Notes from "../Notes";
import { Card, Heading, Switch } from "../../../controls";

export default function IconDemo() {
  const [isOn1, setIsOn1] = useState(false);
  const [isOn2, setIsOn2] = useState(false);
  // render
  return <>
    <Heading>Switch</Heading>
    <Card>
      <Switch label="Foobar" value={isOn1} onChange={() => setIsOn1(!isOn1)} />
      <br />
      <Switch label="Barfoo" size="medium" value={isOn2} onChange={() => setIsOn2(!isOn2)} />
    </Card>
  </>
}
