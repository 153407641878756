import { useContext, useEffect, useState } from "react";
import * as TS from "../../../../types";
import * as BL from "../../../../types/BusinessLogic";
import * as DA from "../../../../types/DataAccess";
import { Card, Icon, Icons, Loader, Modal, useModal, Table, Td, Tr, Row, ConfirmButton, Button, Title, Text, Link, Switch, Checkbox, Dropdown, DropdownOption, Alert } from "../../../controls";
import { SurpriseContext } from "../Surprise.Context";
import EditTask from "./Tasks.Edit";

export default function TasksList() {
  // context
  const SC = useContext(SurpriseContext);  
  // state
  const [tasks, setTasks] = useState<DA.AdventureTask[]|null>(null);
  const [modal, setModal] = useModal<"edit"|"viewMail">();

  // mount to load tasks
  useEffect(() => {
    if(SC.loadTimestamp) {
      load();
    }
  }, [SC.loadTimestamp]);

  // loads tasks
  const load = async() => {
    const tasks = await DA.AdventureTaskRepository.findByAdventure(SC.surprise._id);
    setTasks(tasks);
  }

  // user wants to edit a task
  const onEdit = (task: DA.AdventureTask) => {
    setModal("edit", "Aufgabe bearbeiten", {task});
  }

  // user wants to create a new task
  const onCreate = async() => {
    const currentUser = await BL.User.getCurrent();
    const newTask = DA.AdventureTaskRepository.make()
    newTask.adventureId = SC.surprise._id!;
    newTask.code = "user-created";
    newTask.assignedTo = currentUser.email;
    newTask.title = "Neue Aufgabe";
    newTask.description = "Beschreibung";
    onEdit(newTask);
  }

  // task was updated or deleted
  const onUpdate = () => {
    load();
  }

  // user wants to see mail
  const onShowMail = (mailId:string) => {
    setModal("viewMail", "Mail anzeigen", {mailId});
  }

  // render loading
  if(tasks === null) {
    return <Loader text="lade Aufgaben ..." />
  }

  // pre-render elements
  const openTasks = tasks.filter(t => t.dateCompleted === null);
  const completedTasks = tasks.filter(t => t.dateCompleted !== null);
  const rows: any[] = [];
  if(openTasks.length > 0) {
    rows.push(<Tr key="openTasks"><Td label colSpan={3}>Offene Aufgaben</Td></Tr>);
    openTasks.map(t => rows.push(<Task onEdit={onEdit} onShowMail={onShowMail} key={t._id} task={t} />));
  }
  if(completedTasks.length > 0) {
    rows.push(<Tr key="completedTasks"><Td label colSpan={3}>Erledigte Aufgaben</Td></Tr>);
    completedTasks.map(t => rows.push(<Task onEdit={onEdit} onShowMail={onShowMail} key={t._id} task={t} />));
  }
  let summaryContent:string|undefined = undefined;
  let collapsible = true;
  let expanded = false;
  if(openTasks.length > 0) {
    summaryContent = `${openTasks.length} Aufgabe${openTasks.length > 1 ? "n" : ""} zu erledigen`;
    expanded = true;
  }
  else if(completedTasks.length > 0) {
    collapsible = true;
    expanded = false;
  }
  if(rows.length === 0) {
    rows.push(<Tr key="noTasks"><Td colSpan={3}>Keine Aufgaben vorhanden</Td></Tr>)
  }

  // render
  return <>
    <Card title="Aufgaben" summaryContent={summaryContent} icon={openTasks.length > 0 ? Icons.Circle : Icons.CheckCircleSolid} collapsible={collapsible} expanded={expanded}>
      <Table>
        {rows}
      </Table>
      <Row align="left">
        <Button size="small" onClick={onCreate}>
          <Icon icon={Icons.Plus} /> Aufgabe hinzufügen
        </Button>
      </Row>
    </Card>
    <Modal {...modal}>
      {modal.action === "edit" ? <EditTask task={modal.params.task} onUpdate={onUpdate} closeModal={modal.onClose} /> : null}
      {modal.action === "viewMail" ? <Mail mailId={modal.params.mailId} /> : null }
    </Modal>
  </>;
  
}

type TaskProps = {
  task: DA.AdventureTask,
  onEdit: (task: DA.AdventureTask) => void,
  onShowMail: (mailId:string) => void,
}
function Task({task, onEdit, onShowMail}: TaskProps) {
  const onClickShowMail = (e:React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onShowMail(task.relatedMailId!);
  }

  let divMail = null;
  if(task.relatedMailId) {
    divMail = <div onClick={onClickShowMail} style={{fontSize:"0.9em", color:"#666", cursor:"pointer"}}><Icon icon={Icons.Envelope} /> versendetes E-Mail anzeigen</div>
  }
  const icon = task.dateCompleted === null ? <Icon icon={Icons.Circle} intent="warn" /> : <Icon icon={Icons.CheckCircleSolid} intent="confirm" />;
  return (
    <Tr onClick={() => onEdit(task)} >
      <Td>{icon}</Td>
      <Td>
        <div>{task.title}</div>
        <div style={{fontSize:"0.9em", color:"#666"}}>{task.description}</div>
        {divMail}
        <div style={{fontSize:"0.9em", color:"#666"}}>
          <Icon icon={Icons.User} />&nbsp;{BL.User.firstNameFromEmail(task.assignedTo)}
        </div>
      </Td>
    </Tr>
  )
}


type MailProps = {
  mailId: string
}
function Mail({mailId}: MailProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [mail, setMail] = useState<TS.MailMail|null>(null);

  useEffect(() => {
    const loadMail = async() => {
      const mail = await TS.Mail.findOneById(mailId)
      if(mail) {
        setMail(mail.mail);
      }
      setLoading(false);
    }
    loadMail()
  }, [])

  // still loading
  if(loading) { 
    return <Loader text="lade E-Mail..." />
  }

  // mail could not be found
  if(mail === null) {
    <Alert intent="error" title="E-Mail nicht gefunden">Konnte dieses E-Mail nicht finden.</Alert>
  }

  // render mail
  return (
    <div>
      <div style={{fontSize:"0.9em", color:"#666"}}>
        <Table>
          <Tr>
            <Td label>To:</Td>
            <Td>{mail!.to}</Td>
          </Tr>
          <Tr>
            <Td label>From:</Td>
            <Td>{mail!.from}</Td>
          </Tr>
          <Tr>
            <Td label>Subject:</Td>
            <Td>{mail!.subject}</Td>
          </Tr>
          <Tr>
            <Td colSpan={2}><hr /></Td>
          </Tr>
          <Tr>
            <Td colSpan={2}>
              <div dangerouslySetInnerHTML={{ __html: mail!.html }}></div>
            </Td>
          </Tr>
        </Table>
      </div>
    </div>
  )
}

