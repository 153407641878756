import React, { useState, useEffect } from "react";
import Util from "../../../util/util";
import * as DataAccess from "../../../types/DataAccess";

import { List, ListColumn, Modal, useModal, Breadcrumb, Card, Heading } from "../../controls";
import ConditionsItem from "./Conditions.Item";

const columns: ListColumn[] = [
  {header:"Bedingung", path:"title.de", key:"value", print: (v:DataAccess.Condition) => v.title.de, sort: (a:any, b:any) => a.title.de.localeCompare(b.title.de)}
];


export default function ConditionsList(props: React.FC<any>) {
  // state
  const [items, setItems] = useState<null|DataAccess.Condition[]>(null);
  const [routeId] = useState(Util.getRouteId(props));
  const [modal, setModal] = useModal<"edit">();

  // mount
  useEffect(() => {
    load();
  }, [])

  // mount with route id
  useEffect(() => {
    if(routeId) {
      startEdit(routeId);
    }
  }, [routeId]);

  // loads all items
  const load = async() => {
    const items = await DataAccess.ConditionRepository.searchAll();
    setItems(items);
  }

  // onRowClick
  const startEdit = (id?:string) => {
    setModal("edit", id ? "Bedingung bearbeiten" : "Bedingung erstellen", {id});
  }

  // items updated
  const onUpdate = async() => {
    setItems(null);
    load();
  }

  // render
  return <>
    <Breadcrumb 
      links={[ {title:'Home', to:'/'}]}
      location="Bedingungen" 
    />
    <Heading>Bedingungen</Heading>
    <Card>
      <List 
        columns={columns} 
        items={items} 
        getKey={v => v._id} 
        onClickCreate={() => startEdit()}
        onRowClick={item => startEdit(item._id)} 
      />
    </Card>
    <Modal {...modal}>
      <ConditionsItem id={modal.params.id} onUpdate={onUpdate} closeModal={modal.onClose} />
    </Modal>
  </>
}