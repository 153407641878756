import React, { useEffect, useState } from "react";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";
import * as BL from "../../../types/BusinessLogic";
import { FormData } from "./VendorInvoice"
import { DropdownOption, Dropdown, SearchableDropdown, Validators, LinkButton, Button, Loader, Validation, ValidationError } from "../../controls";

import CSS from "./VendorInvoice.Provider.module.scss";

export type SelectedProviderAndAccount = {
  provider: DA.Provider|null,
  account: DA.ProviderBankAccount|null,
  billingAddress: DA.ProviderAddress|null
}
type ProviderAndBankaccountProps = {
  formData: FormData,
  // provider: TS.Provider|null,
  // bankAccount: TS.ProviderBankAccount|null,
  // billingAddress: TS.ProviderAddress|null,
  isBankingInfoRequired: boolean, // TODO why not just pass paymentType?
  onChange: (provider: DA.Provider|null, account: DA.ProviderBankAccount|null, billingAddress: DA.ProviderAddress|null) => void,
  validate: (formData: FormData) => Validation,
  disabled?: boolean
}
export default function ProviderAndBankaccount({formData, isBankingInfoRequired=true, onChange, validate, disabled} : ProviderAndBankaccountProps) {
  // state
  const [isLoading, setIsLoading] = useState<boolean>(false);
  /*
  const [selected, setSelected] = useState<SelectedProviderAndAccount>({
    provider:null,
    billingAddress:null,
    //account_id:'',
    account:null
  });
  */
  const [providers, setProviders] = useState<DA.Provider[]>([]);
  const [validation, setValidation] = useState<Validation>({valid:false, message:"Bitte Anbieter wählen"})
  

  // mount to load data
  useEffect(() => {
    const loadProviders = async() => {
      setIsLoading(true);
      const providers = await DA.ProviderRepository.search({isActive:{$ne:false}});
      setProviders(providers);
      setIsLoading(false);
    }
    loadProviders();
  }, []);

  // mount to validate / validate changes
  useEffect(() => {
    setValidation(validate(formData));
  }, [formData, validate]);

  // user clicks on reload
  const onClickReload = async() => {
    if(formData.provider) {
      onProviderSelected(formData.provider._id!);
    }
  }

  const onProviderSelected = async(id:string) => {
    setIsLoading(true);
    const provider = await DA.ProviderRepository.findById(id);
    if(provider) {
      // get the billing address
      const billingAddress = (provider.addresses || []).find((a:any) => a.kind === "billing") || null;
      // get the default account
      let account: DA.ProviderBankAccount|null = null;
      if(provider.bankAccounts) {
        account = provider.bankAccounts.find((b:any) => b.isDefault && b.isActive) || provider.bankAccounts.find((b:any) => b.isActive) || null;
      }
      update(provider, account, billingAddress);
    }
    setIsLoading(false);
  }

  // user selects an account
  const onAccountSelected = (account:any) => {
    update(formData.provider, account, formData.providerBillingAddress);
  }

  // updates 
  const update = (provider:DA.Provider|null, providerBankAccount:DA.ProviderBankAccount|null, providerBillingAddress:DA.ProviderAddress|null) => {
    setValidation(validate({...formData, provider, providerBankAccount, providerBillingAddress}));
    onChange(provider, providerBankAccount, providerBillingAddress);
  }

  // render loading
  if(isLoading) {
    return <Loader size="small" />;
  }
  // render
  return (
    <div className={CSS.container}>
      <SearchableDropdown
        value={formData.provider ? formData.provider._id : ""}
        options={providers.map(p => { return {value:p._id, label:p.name}})}
        validate={Validators.isRequired("Anbieter wählen")}
        onChange={onProviderSelected}
        disabled={disabled}
      />
      <Actions 
        isBankingInfoRequired={isBankingInfoRequired}
        onClickReload={onClickReload}
        formData={formData}
      />
      <BillingAddress 
        isBankingInfoRequired={isBankingInfoRequired}
        formData={formData}
      />
      <Accounts 
        isBankingInfoRequired={isBankingInfoRequired} 
        formData={formData}
        onAccountSelected={onAccountSelected}
      />
      <ValidationError validation={validation} />
    </div>
  )
}


type ActionsProps = {
  isBankingInfoRequired:boolean,
  formData: FormData,
  //provider: TS.Provider|null,
  onClickReload: () => void
}
function Actions({isBankingInfoRequired, formData, onClickReload} : ActionsProps) {
  if(!isBankingInfoRequired) {
    return null;
  }
  if(formData.provider) {
    return (
      <div style={{display:"flex", flexDirection:"row"}}>
        <LinkButton to={`/providers/${formData.provider._id}`} target="_blank" size="small">Anbieter bearbeiten</LinkButton>
        <Button size="small" onClick={onClickReload}>Anbieter neu laden</Button>
      </div>
    )
  }
  else {
    return null;
  }
}

type BillingAddressProps = {
  isBankingInfoRequired:boolean,
  formData: FormData,
}
function BillingAddress({isBankingInfoRequired, formData} : BillingAddressProps) {
  if(!isBankingInfoRequired) {
    return null;
  }

  if(formData.provider) {
    if(formData.providerBillingAddress) {
      return (
        <p className={CSS.billingaddress}>{BL.Provider.printAddress(formData.providerBillingAddress, '\n')}</p>
      )
    }
    else {
      return <div style={{color:"red"}}>keine Rechnungsadresse hinterlegt (um die Rechnung speichern zu können, bitte eine Rechnungadresse erfassen und wählen)</div>
    }
  }
  else {
    return null
  }
}


type AccountsProps = {
  isBankingInfoRequired: boolean,
  formData: FormData,
  onAccountSelected: (account:any) => void,
}
function Accounts({isBankingInfoRequired, formData, onAccountSelected} : AccountsProps) {
  // state
  const [accounts, setAccounts] = useState<any[]>([]);
  const [options, setOptions] = useState<DropdownOption[]>([]);

  // selected changed
  useEffect(() => {
    if(formData.provider) {
      const accounts = formData.provider.bankAccounts || [];
      const options = accounts
        .filter((b:any) => b.isActive)
        .map((ac:any) => {
          return {
            value: ac._id,
            label: `${ac.bankName} ${ac.accountNumber || "kein Kontonummer"}`
          }
        });
      setAccounts(accounts);
      setOptions(options);
    }
  }, [formData.provider]);

  if(!isBankingInfoRequired) {
    return null;
  }

  // no provider? no accounts ...
  if(!formData.provider) {
    return null;
  }

  // user selects
  const onChange = (id:string) => {
    const account = accounts.find(ac => ac._id === id);
    onAccountSelected(account);
  }

  // no (active) accounts for this provider?
  if(accounts.length === 0) {
    return <div style={{color:"orange"}}>keine aktiven Bankverbindungen hinterlegt</div>
  }

  // dropdown with accounts
  return <>
    <Dropdown 
      options={options}
      value={formData.providerBankAccount?._id}
      onChange={onChange}
    />
  </>
}