import { Module, ModuleInfo, Theme } from ".";

export class CustomFooterModule implements Module {
  readonly code: string;

  _id?: string;
  countries: string[];
  position: number;
  theme: Theme;
  anchor?: string;

  elements: {
    showSocial: boolean;
    showContact: boolean;
    showPrimaryLinks: boolean;
    showSecondaryLinks: boolean;
    showPaymentOptions: boolean;
    showNewsletterSubscription: boolean;
    showLogo: boolean;
  }

  constructor() {
    this.code = CustomFooterModule.info.code;
    this.countries = ["CH", "DE"];
    this.position = 0;
    this.theme = "primary";
    this.elements = {
      showSocial: false,
      showContact: false,
      showPrimaryLinks: false,
      showSecondaryLinks: false,
      showPaymentOptions: false,
      showNewsletterSubscription: false,
      showLogo: true
    }
  }

  static get info(): ModuleInfo {
    return {
      code: "customfooter",
      title: "Konfigurierbarer Footer",
      description: "Footer mit der Möglichkeit Informationsblöcke ein- oder auszublenden"
    }
  }
}