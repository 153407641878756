import _ from "lodash";
import React, {useContext, useState} from "react";
import Util from "../../../../../util/util";
import {ConfirmButton} from "../../../../controls";
import {SurpriseContext} from "../../Surprise.Context";
import * as TS from "../../../../../types";

export default function SendAcceptReminderMail() {
  // context
  const SC = useContext(SurpriseContext);

  // state
  const [info, setInfo] = useState(null);
  const [busy, setBusy] = useState(false);

  // event handlers
  const sendMail = async() => {
    setBusy(true);
    const result = await SC.sendAcceptMail();
    setBusy(false);
    if(result.success) {
      setInfo("Das Mail wurde erfolgreich verschickt.");
    }
    else {
      setInfo("Das Mail konnte nicht verschickt werden. Fehler: " + JSON.stringify(result.error));
    }
  }

  // do not render if status does not fit
  if(![TS.AdventureStatus.ConditionsSent, TS.AdventureStatus.DateSelected].includes(SC.surprise.Status) ) {
    return null;
  }
  // render
  const infoStyle = {fontSize:"10px", padding:"0 4px 0 4px"};
  let alreadySent = !_.isNil(SC.surprise.SentAcceptReminderMailOn);
  
  let message = alreadySent ? <div style={infoStyle}>{` Ein Bestätigungs-Reminder-Mail wurde am ${Util.printDateAndTime(SC.surprise.SentAcceptReminderMailOn)} verschickt`}</div> : null
  let sentAgainMessage = <div style={infoStyle}>{info}</div>
  return (
    <div>
      <ConfirmButton onConfirm={sendMail} disabled={alreadySent} busy={busy}>Bestätigungs-Reminder Mail verschicken</ConfirmButton>
        {message}
        {sentAgainMessage}
    </div>
  );
}
