import React, { useContext, useState, useEffect } from "react";
import { Heading, Loader, Breadcrumb, Tabs, TabsItem } from "../../controls";
import { PageContext, PageProvider } from "./Page.Context";
import Util from "../../../util/util";
import Properties from "./Page.Properties";
import Modules from "./Page.Modules";
import Preview from "./Page.Preview";

type PageEditProps = any; // TODO

function PageEdit(props: PageEditProps) {
  // context
  const PC = useContext(PageContext);

  // state
  const [tabItems] = useState<TabsItem[]>([{label:"Bearbeiten", value:"edit"}, {label:"Preview .ch", value:"preview-CH"}, {label:"Preview .de", value:"preview-DE"}]);
  const [mode, setMode] = useState("edit");

  // mount
  useEffect(() => {
    const id = Util.getRouteId(props);
    PC.load(id);
  }, []);

  // tab clicked
  const onChangeTab = (tabItem:any) => {
    setMode(tabItem.value);
  }

  // pre-render
  let content = <Loader text="lade ..." />
  if(PC.page) {
    let content2 = null;
    if(mode === "edit") {
      content2 = <>
        <Properties />
        <Modules />
      </>
    }
    else if (mode === "preview-CH") {
      content2 = <Preview country="CH" />
    }
    else if(mode === "preview-DE") {
      content2 = <Preview country="DE" />
    }
    else {
      content2 = "unknown mode";
    }

    content = <>
      <Heading>{PC.page.title}</Heading>
      <Tabs items={tabItems} onChange={onChangeTab} />
      {content2}
    </>
  }
  // render
  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}, {title:"Seiten", to:"/pages"}]} location={PC.page ? PC.page.title : "..."} />
    {content}
  </>
}

export default (props:any) => <PageProvider><PageEdit {...props} /></PageProvider>