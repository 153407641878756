import _ from "lodash";
import React, {useContext} from "react";
import { Card, Icons } from '../../../controls';
import {SurpriseContext} from "../Surprise.Context";
import Buddy from "./Buddy.View";
import Prepaid from "./Prepaid.View";

export default function PrepaidAndBuddy({expanded}) {
  // context
  const SC = useContext(SurpriseContext);

  // render
  let content = null;
  if(_.isNil(_.get(SC.surprise, "Order.Prepaid.Value"))) {
    // No prepaid value? well then it is no prepaid surprise
    content = <div>diese Überraschung wurde nicht als Wertgutschein gekauft</div>
  }
  else {
    content = <>
      <Prepaid /> 
      <Buddy />
    </>
  }
  return (
    <Card title="Wertgutschein & Buddy" icon={Icons.Handshake} collapsible={true} expanded={expanded}>
      {content}
    </Card>
  );
}
