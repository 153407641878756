import { ClipboardButton, Card, Heading } from "../../../controls";
import Code from "../Code";
import Notes from "../Notes";

export default function ClipboardButtonDemo() {
  return <Card>
    <Heading>ClipboardButton</Heading>
    <ClipboardButton size="small" text="this will end up the clipboard" />
    <Code>{`<ClipboardButton size="small" text="this will end up the clipboard" />`}</Code>
    <Notes>
      It is also possible to pass an HTML element instead. The content of that element will then be copied to the clipboard.
    </Notes>
    <Code>{`<ClipboardButton size="small" element={someHtmlElement} />`}</Code>
  </Card>
}