import { VoucherKind } from "../DataAccess";

export class Voucher {
  public static printKind(kind:VoucherKind) : string {
    if(kind === "percentage") {
      return "%";
    }
    else {
      return "CHF";
    }
  }
}