import React, {useEffect, useContext} from "react";
// components
import { ShippingProvider, ShippingContext } from "./Shipping.Context";
import { Breadcrumb, Heading, Loader } from "../../controls";
import Labels from "./Shipping.Labels";
import Group from "./Shipping.Group";

// styling
import "./Shipping.View.scss";

// the componet
function Shipping() {
  // context
  const SC = useContext(ShippingContext);
  
  // mount
  useEffect(() => {
    SC.load();
  }, []);

  useEffect(() => {
    if(SC.loaded) {
      window.document.title = "Versand";
    }
  }, [SC.loaded])

  // render
  let content = null;
  if(!SC.loaded) {
    content = <Loader text="lade Daten ..." />
  }
  else {
    const relevantShippingTypes = SC.shippingTypes
    .filter(st => ["letter", "parcel"].includes(st.deliveryMethod))
    .sort((a,b) => {
      let byMethod = a.deliveryMethod.localeCompare(b.deliveryMethod);
      if(byMethod === 0) {
        return a.title.de.localeCompare(b.title.de);
      }
      else {
        return byMethod
      }
    })

    content = relevantShippingTypes.map(st => <Group key={st.code} shippingCode={st.code} />);
  }

  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}]} location="Versand" />
    <Heading>Versand</Heading>
    <div id="v-shipping">
      {content}
      <Labels />
    </div>
  </>
}

export default () => <ShippingProvider><Shipping /></ShippingProvider>