import _ from "lodash";
import React, {useState, useEffect} from "react";
import { Alert, Breadcrumb, Button, Card, Heading, Loader, Table, Tr, Td } from "../../controls";
import * as BL from "../../../types/BusinessLogic";
import Util from "../../../util/util";

export default function DataWarehouse() {
  // state
  const [info, setInfo] = useState<any>(null);

  // mount
  useEffect(() => {
    load();
  }, []);

  // loads data
  const load = async() => {
    const info = await BL.DataWarehouse.getInfo();
    setInfo(info);
  }

  // 
  const onClickUpdateAdventures = async() => {
    setInfo(null);
    await BL.DataWarehouse.updateAdventures();
    load();
  }

  //
  const onClickUpdateVouchersRelevantToSales = async() => {
    setInfo(null);
    await BL.DataWarehouse.updateVouchersRelevantToSales();
    load();
  }

  // pre-render
  let content;
  if(_.isNil(info)) {
    content = <Loader />
  }
  else {
    const adventuresLastUpdatedOn = _.isNil(info.adventuresUpdatedOn) ? "noch nie" : Util.printDateAndTime(info.adventuresUpdatedOn);
    const vouchersRelevantToSalesLastUpdatedOn = _.isNil(info.vouchersRelevantToSalesUpdatedOn) ? "noch nie" : Util.printDateAndTime(info.vouchersRelevantToSalesUpdatedOn);
    content = <>
      <Table>
        <Tr>
          <Td label>letztes Update Tabelle 'adventures'</Td>
          <Td>{adventuresLastUpdatedOn}</Td>
        </Tr>
        <Tr>
          <Td label>Update der Tabelle 'adventures' durchführen</Td>
          <Td><Button size="small" onClick={onClickUpdateAdventures}>update</Button></Td>
        </Tr>
        <Tr>
          <Td label>letztes Update Tabelle 'vouchersRelevantToSales'</Td>
          <Td>{vouchersRelevantToSalesLastUpdatedOn}</Td>
        </Tr>
        <Tr>
          <Td label>Update der Tabelle 'vouchersRelevantToSales' durchführen</Td>
          <Td><Button size="small" onClick={onClickUpdateVouchersRelevantToSales}>update</Button></Td>
        </Tr>
      </Table>
      <Alert intent="info" size="small" title="Daten zu DataStudio hinzufügen">
        <ul>
          <li>in DataStudio "Daten hinzufügen" klicken</li>
          <li>den "BigQuery" Connector wählen</li>
          <li>das Projekt "Appentura" wählen und daraus den Datensatz "datastudio" (Anmerkung: "datastudio_test" sind Daten aus dem Staging)</li>
          <li>die Tabelle "adventures" oder "vouchersRelevantToSales" wählen</li>
          <li>"hinzufügen" klicken</li>
        </ul>
        
      </Alert>
    </>
  }
  

  // render
  return <>
    <Breadcrumb links={[{title:'Home', to:'/'}]} location='Data-Warehouse Update'/>
    <Heading>Data-Warehouse Update</Heading>
    <Card>
      {content}
    </Card>
  </>
}