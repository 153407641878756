import React from "react";
import { Icon, Icons } from '../../controls';
import CSS from "./DashboardPanel.module.scss";

type DashboardPanelProps = {
  id?: string,
  title?: string,
  smallOnDesktop?: boolean,
  smallOnMobile?: boolean,
  icon?: string,
  iconRounded?: boolean,
  children: React.ReactNode|React.ReactNode[]
}
export default function DashboardPanel({children, title, smallOnDesktop, smallOnMobile, icon, iconRounded, id}: DashboardPanelProps) {
  // defaults
  smallOnDesktop = smallOnDesktop || false;
  smallOnMobile = smallOnMobile || false;
  iconRounded = iconRounded === false ?  false : true;

  // pre-render title
  let titleElement = null;
  let iconElement = null;
  if(icon) {
    iconElement = <div className={`${CSS.title_icon} ${iconRounded ? CSS.rounded : ''}`}><img src={icon} alt="" /></div>;
    //iconElement = <Icon icon={Icons.StarFull} />;
  }
  if(title) {
    titleElement = (
      <div className={CSS.title}>
        {iconElement}
        <div className={CSS.title_text}>{title}</div>
      </div>
    )
  }

  // render
  return (
    <div className={`${CSS.container} ${smallOnDesktop ? CSS.desktop_small : ''} ${smallOnMobile ? CSS.mobile_small : ''}`} id={id || ""}>
      <div className={CSS.inner}>
        {titleElement}
        <div className={CSS.content}>
          {children}
        </div>
      </div>
    </div>
  )
}
