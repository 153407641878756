import React, { useState } from "react";
import _ from "lodash";
// import Code from "../Code";
// import Notes from "../Notes";
import { Card, Form, Validators, SearchableDropdown, Heading, DropdownOption } from "../../../controls";

export default function SearchableDropdownDemo() {
  const countries: DropdownOption[] = [
    {value:"ch", label:"Schweiz"},
    {value:"de", label:"Deutschland"},
    {value:"at", label:"Österreich"},
    {value:"fr", label:"Frankreich"},
    {value:"it", label:"Italien"}
  ];
  const [entity, setEntity] = useState({country1:"ch", country2:"fr"});
  const [value, setValue] = useState<string>("ch");

  const save = async(formData: any) => {
    let newEntity = {}
    _.merge(newEntity, entity)
    const updated = _.merge(newEntity, formData.changeset);
    setEntity(updated);
  }

  return <Card>
    <Heading>SearchableDropdown</Heading>
    <Card title="In Form">
      <Form entity={entity} onSave={save} labelSave="speichern">
        <SearchableDropdown label="Land 1" path="country1" options={countries} validate={Validators.isRequired("Bitte ein Land wählen")} transform={(item:any) => `👍🏻 ${item.label}`} focusOnMount={false} />
        <SearchableDropdown label="Land 2" path="country2" options={countries} validate={Validators.isRequired("Bitte ein Land wählen")} transform={undefined} focusOnMount={false} />
      </Form>
    </Card>
    <Card title="Without Form">
      <SearchableDropdown label="Land" value={value} options={countries} onChange={(v) => setValue(v)} />
      <div>value: {value}</div>
    </Card>
  </Card>
  
}
