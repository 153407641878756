import React, { useContext, useState, useEffect } from "react";
import * as TS from "../../../../../types";
import * as DA from "../../../../../types/DataAccess";
import { Loader, Dropdown, DropdownOption } from "../../../../controls";
import { SearchContext } from "../Search.Context";
import FilterGroup from "../Search.FilterGroup";

export default function Operator() {
  // context
  const Search = useContext(SearchContext);

  // state
  const [users, setUsers] = useState<DropdownOption[]|null>(null);

  // mount
  useEffect(() => {
    const load = async () => {
      const data = await DA.UserRepository.findAll(); 
      const users:DropdownOption[] = data.map(u => {
        return { label: u.firstName, value:u.email};
      });
      users.unshift({label:"-", value: ""});
      setUsers(users);
    }
    load();
  }, []);

  // onChange
  const onDropdownChange = (v:string) => {
    const updatedFilter = Search.filter.clone();
    updatedFilter.operator = v;
    Search.reset(updatedFilter);
  }

  // render
  let content = null;
  if(users === null) {
    content =  <Loader text={"lade Operators ..."} />
  }
  else {
    content = <Dropdown options={users} onChange={onDropdownChange} value={Search.filter.operator} />
  }
  return (
    <FilterGroup title="Operator">
      {content}
    </FilterGroup>
  )
}