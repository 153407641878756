import React, { useEffect, useState } from "react";
// components
import { Button, ValidationError } from ".";
import { Validation } from "./Validation";
// styling
import CSS from "./FileSelector.module.scss";

export type FileSelectedInfo = {
  source:"local"|"drive",
  localFile?: File,
  driveFile?: {
    id: string,
    name: string,
    url: string,
    description: string
  }
}

type FileSelectorProps = {
  clientId: string,

  disableDriveFiles?: boolean,
  disableLocalFiles?: boolean,
  disabled?: boolean,

  onFileSelected: (info:FileSelectedInfo) => void,

  validate?: (v:FileSelectedInfo|null) => Validation,
  children?: React.ReactNode|React.ReactNode[],

}
export function FileSelector({ clientId, disabled, disableDriveFiles, disableLocalFiles, onFileSelected, validate, children } : FileSelectorProps) {
  // state
  const [signedIn, setSignedIn] = useState<boolean>(false);
  const [selectedInfo, setSelectedInfo] = useState<FileSelectedInfo|null>(null);
  const [access_token, setAcccess_token] = useState<string>("");
  const [validation, setValidation] = useState<Validation>({valid:true, message:""});

  // mount
  useEffect(() => {
    _validate(selectedInfo);
  }, []);

  // mount to load scripts
  useEffect(() => {
    // not necessary if drive files not enabled
    if(disableDriveFiles !== true) {
      loadScript();
    }
  }, [disableDriveFiles]);

  // watch selected info to validate
  useEffect(() => {
    _validate(selectedInfo);
  }, [selectedInfo]);
  
  // sets validation (if validate method is available)
  const _validate = (selectedInfo: FileSelectedInfo|null) => {
    if(validate) {
      setValidation(validate(selectedInfo));
    }
    else {
      setValidation({valid:true, message:""});
    }
  }
  
  // loads gapi client
  const loadScript = () => {
    // note: we have the script included in index.html
    (window as any).gapi.load('client:auth2', initScript);
    // load the picker
    (window as any).gapi.load('picker', () => {});
  }

  // inits gapi
  const initScript = () => {
    // Google Drive API Stuff
    let DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v2/rest"]; // Array of API discovery doc URLs for APIs used by the quickstart
    let SCOPES = "https://www.googleapis.com/auth/drive"; // Authorization scopes required by the API; multiple scopes can be included, separated by spaces. We give full access

    // callback function used by the api to inform about signin status
    let updateSigninStatus = (signedIn:any) => { 
      let googleAuth = (window as any).gapi.auth2.getAuthInstance();
      let currentUser = googleAuth.currentUser.get();
      let authResponse = currentUser.getAuthResponse();
      setSignedIn(signedIn);
      setAcccess_token(authResponse.access_token);
      //this.setState({...this.state, signedIn, access_token:authResponse.access_token})
      
    }

    // init the gapi client
    (window as any).gapi.client.init({
      discoveryDocs: DISCOVERY_DOCS,
      clientId: clientId,
      scope: SCOPES
    }).then(function () {
      // Listen for sign-in state changes.
      (window as any).gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
      // Handle the initial sign-in state.
      updateSigninStatus((window as any).gapi.auth2.getAuthInstance().isSignedIn.get())
    });
  }

  // authorize with google dirve
  const onAuthorize = () => {
    (window as any).gapi.auth2.getAuthInstance().signIn()
  }

  // signout from google drive
  const onSignOut = () => {
    (window as any).gapi.auth2.getAuthInstance().signOut();
  }

  // local file selected
  const onLocalFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(e.target && e.target.files) {
      const selectedFile = e.target.files[0];
      const info: FileSelectedInfo = {
        source: "local",
        localFile: selectedFile
      }
      setSelectedInfo(info);
      onFileSelected(info);
    }
  }

  // drive file selected
  const onDriveFileSelected = (data:any) => {
    if (data[(window as any).google.picker.Response.ACTION] === (window as any).google.picker.Action.PICKED) {
      const selectedFile = data[(window as any).google.picker.Response.DOCUMENTS][0];
      const info: FileSelectedInfo = {
        source: "drive",
        driveFile: selectedFile
      }
      setSelectedInfo(info);
      onFileSelected(info);
    }
  }

  // 
  const onShowPicker = (e:any) => {
    let picker = new (window as any).google.picker.PickerBuilder()
      .addView((window as any).google.picker.ViewId.DOCS) // PDFS if only PDFs
      .setOAuthToken(access_token)
      //.setDeveloperKey(developerKey)
      .setCallback(onDriveFileSelected)
      .build()
    picker.setVisible(true);
  }
  
  let buttonSignIn = <Button onClick={onAuthorize} size="small">autorisieren</Button>
  let buttonSelectId = `filepicker_${Date.now()}`;
  let buttonSelectLocalFile = null
  if(!disableLocalFiles) {
    buttonSelectLocalFile = (
      <div className={`${CSS.select} ${disabled ? CSS.disabled : ""}`}>
        <input disabled={disabled} type="file" onChange={onLocalFileSelected} id={buttonSelectId} />
        <label htmlFor={buttonSelectId}>Lokales File wählen</label>
      </div>
    )
  }
  let buttonSelectDriveFile = null
  if(!disableDriveFiles) {
    buttonSelectDriveFile = (
      <div className={`${CSS.select} ${disabled ? CSS.disabled : ""}`} onClick={disabled ? undefined : onShowPicker}>
        <label>File aus Drive wählen</label>
      </div>
    )
  }

  // render
  let content = null;
  if(!signedIn && !disableDriveFiles) {
    content = buttonSignIn;
  }
  else {
    content = (
      <div className={CSS.selectButtons}>
        {buttonSelectLocalFile}
        {buttonSelectDriveFile}
      </div>
    )
  }
  return (
    <div className={CSS.container}>
      {content}
      <ValidationError validation={validation} />
      {children}
    </div>
  )
}


export class FileSelectorHelper {

  static getFileInfo = (selectedInfo: FileSelectedInfo): {filename:string, url:string} => {
    let filename = "";
    let url = "";
    if(selectedInfo.source === "local") {
      filename = `${selectedInfo.localFile ? selectedInfo.localFile.name : "???"} (lokal)`;
      url = "";
    }
    else {
      filename = `${selectedInfo.driveFile ? selectedInfo.driveFile.name : "???"} (Google Drive)`;
      url = selectedInfo.driveFile ? selectedInfo.driveFile.url : "???";
    }
    return {
      filename, url
    }
  }
}
