import _ from "lodash";
import React, {useContext} from "react";
import { CardSection } from '../../../controls';
import {SurpriseContext} from "../Surprise.Context";
import BuddyUpsells from "../GeneralInfo/BuddyUpsells.View";

export default function Prepaid() {
  // context
  const SC = useContext(SurpriseContext);
  
  // render
  return (
    <CardSection title="Gutschein">
      <table>
        <tbody>
          <tr>
            <td className="label">Wert</td>
            <td>CHF {_.get(SC.surprise, "Order.Prepaid.Value")}</td>
          </tr>
          <tr>
            <td className="label">verwendeter Betrag</td>
            <td>CHF {_.get(SC.surprise, "Order.Prepaid.ValueUsed") || 0}</td>
          </tr>
          <tr>
            <td className="label">Betrag an wohltätige Organisation</td>
            <td>CHF {_.get(SC.surprise, "Order.Prepaid.ValueCharity") || 0}</td>
          </tr>
          <tr>
            <td className="label">Name der wohltätigen Organisation</td>
            <td>{_.get(SC.surprise, "Order.Prepaid.Charity") || "-"}</td>
          </tr>
          <tr>
            <td className="label">Buddy Upsells</td>
            <td><BuddyUpsells adventure={SC.surprise} /></td>
          </tr>
          <tr>
            <td className="label">Verkaufsstatus</td>
            <td>{_.get(SC.surprise, "Order.Prepaid.InWarehouse") === true ? "noch nicht verkauft (im Lager beim Verkäufer)" : "verkauft"}</td>
          </tr>
        </tbody>
      </table>
    </CardSection>
  );
}
