import React from "react"

import {Card, Heading, Link, Table, Td, Tr} from "../../controls"

import "./Resources.scss"

type ResourcesProps = {
  title: any,
  resources: any,
}
function Resources({resources, title}: ResourcesProps) {
  const rows = resources.map((resource:any, index:number) => {
    return (
      <Tr key={index}>
        <Td label><Link to={resource.url} target="_blank">{resource.title}</Link></Td>
        <Td>{resource.description}</Td>
      </Tr>
    )
  })

  return <>
    <Heading>{title}</Heading>
    <Card className="view-resources">
      <Table>
        {rows}
      </Table>
    </Card>
  </>
}
export default Resources