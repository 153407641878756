import React, { useState } from "react";
import Util from "../../../util/util";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess"; 
import { Actions, Tr, Td, Table, Alert, Button, Link, Icons, Icon } from "../../controls";
import CSS from "./CollectiveInvoices.Details.module.scss";
import { ProviderLink } from "../../common";

type CollectiveInvoiceDetailsProps = {
  onChanged: () => void,
  collectiveInvoice:TS.CollectiveInvoice,
  closeDialog: () => void,
}
export default function CollectiveInvoiceDetails({collectiveInvoice, onChanged, closeDialog} : CollectiveInvoiceDetailsProps) {
  // state
  const [mode, setMode] = useState<"view"|"delete"|"pay">("view");

  // user deleted item
  const onActionCompleted = () => {
    onChanged();
    closeDialog();
  }

  // user wants to delete it
  const onClickDelete = () => setMode("delete");

  // user wants to mark it as paid
  const onClickPay= () => setMode("pay");

  // user cancels deleting or paying
  const onCancel = () => setMode("view");

  if(mode === "view") {
    // pre-render items
    const rows = collectiveInvoice.items.sort((a,b) => a.activity_date > b.activity_date ? 1 : -1).map((item:TS.CollectiveInvoiceItem) => {
      return (
        <Tr key={item._id}>
          <Td>{Util.printDate(item.activity_date)}</Td>
          <Td><Link target="_blank" to={`/surprise/${item.adventure_id}`}>{TS.Adventure.getShortId(item.adventure_id)} <Icon icon={Icons.ExternalLink} /></Link></Td>
          <Td>{item.text}</Td>
          <Td align="right">{item.amount}</Td>
        </Tr>
      )
    })
    // render
    return (
      <div>
        <div className={CSS.heading}>

          <Table>
            <Tr>
              <Td label>Anbieter</Td>
              <Td>
                {collectiveInvoice.provider_name} <ProviderLink provider={{_id:collectiveInvoice.provider_id, name:collectiveInvoice.provider_name} as DA.Provider} />
              </Td>
            </Tr>
            <Tr>
              <Td label>Periode</Td>
              <Td>{TS.CollectiveInvoice.printPeriod(collectiveInvoice.period)}</Td>
            </Tr>
            <Tr>
              <Td label>Datum Versand</Td>
              <Td>{collectiveInvoice.dateSent ? Util.printDate(collectiveInvoice.dateSent) : "-"}</Td>
            </Tr>
            <Tr>
              <Td label>Datum Bezahlung</Td>
              <Td>{collectiveInvoice.datePaid ? Util.printDate(collectiveInvoice.datePaid) : "-"}</Td>
            </Tr>
            <Tr>
              <Td label>Status</Td>
              <Td>{TS.CollectiveInvoice.printStatus(collectiveInvoice)}</Td>
            </Tr>
          </Table>

          <div className={CSS.actions}>
            <Button size="small" onClick={onClickDelete} intent="delete" disabled={collectiveInvoice.datePaid !== null}><Icon icon={Icons.Trash} />&nbsp;löschen</Button>
            <Button size="small" onClick={onClickPay} disabled={collectiveInvoice.datePaid !== null}><Icon icon={Icons.Coins} />&nbsp;bezahlen</Button>
          </div>
        </div>
        
        <Table>
          <Tr key="header">
            <Td label>Datum</Td>
            <Td label>Überraschung</Td>
            <Td label>Text</Td>
            <Td label align="right">Betrag</Td>
          </Tr>
          {rows}
        </Table>
      </div>
    )
  }
  else if(mode==="pay") {
    return <Pay collectiveInvoice={collectiveInvoice} onPaid={onActionCompleted} onCancel={onCancel} />
  }
  else {
    return <Delete collectiveInvoice={collectiveInvoice} onDeleted={onActionCompleted} onCancel={onCancel} />
  }
}

type DeleteProps = {
  collectiveInvoice: TS.CollectiveInvoice,
  onDeleted: () => void,
  onCancel: () => void
}
function Delete({collectiveInvoice, onDeleted, onCancel}: DeleteProps) {
  // user wants to delete it
  const onConfirm = async() => {
    await TS.CollectiveInvoice.remove(collectiveInvoice);
    onDeleted();
  }

  // render
  return (
    <div>
      <Alert title="Löschen bestätigen" size="large" intent="warning">
        Monatsabrechnung wirklich löschen?
        <ul>
          <li>die Rechnung verschwindet aus dem Portal des Anbieters</li>
          <li>der Anbieter wird NICHT über das löschen informiert</li>
          <li>die zugewiesenen Buchungsafragen werden wieder freigestellt und können einer neuen Monatsabrechnung zugewiesen werden</li>
        </ul>
      </Alert>
      <Actions
        onSave={onConfirm}
        onCancel={onCancel}
      />
    </div>
  )
}

type PayProps = {
  collectiveInvoice: TS.CollectiveInvoice,
  onPaid: () => void,
  onCancel: () => void,
}
function Pay({collectiveInvoice, onPaid, onCancel}: PayProps) {
  // user wants to delete it
  const onConfirm = async() => {
    await TS.CollectiveInvoice.update(collectiveInvoice._id!, {datePaid: new Date()});
    onPaid();
  }

  // render
  return (
    <div>
      <Alert title="Bezahlung bestätigen" size="large" intent="warning">
        Monatsabrechnung wirklich als bezahlt markieren?
      </Alert>
      <Actions
        onSave={onConfirm}
        onCancel={onCancel}
      />
    </div>
  )
}