import _ from "lodash";
import React, {useEffect, useContext} from "react";
import Util from "../../../util/util";
import Config from "../../../config";
import { ActivityProvider, ActivityContext } from "./ActivityContext";
import { Alert, Heading, Link, Loader, Breadcrumb } from "../../controls";

import SubactivityInfo from "./Activity.SubactivityInfo";
import Execution from "./Activity.Execution";
import Shop from "./Activity.Shop";
import Texts from "./Activity.Texts";
import RegionVariants from "./Activity.RegionVariants";
import ImagesAndVideos from "./Activity.ImagesAndVideos";


function Activity(props:any) {
  return (
    <ActivityProvider>
      <Content id={Util.getRouteId(props)} />
    </ActivityProvider>
  )
}

type ContentProps = {
  id: string
}
function Content({id} : ContentProps) {
  // context
  const AC = useContext(ActivityContext)!;

  // mount
  useEffect(() => {
    AC.load(id);
  }, [id]);


  // pre-render
  let content, heading, title, subactivityInfo, shopUrl_ch = "", shopUrl_de = "";
  if(_.isNil(AC.activity)) {
    content = <Loader text="lade Aktivität ..." />
    heading = null;
    subactivityInfo = null;
    title = "Aktivität wird geladen";
  }
  else {
    title = _.get(AC.activity, "title.de") || "kein Titel";
    heading = <Heading>{title}</Heading>;
    subactivityInfo = <SubactivityInfo />;
    content = <>
      <Texts />
      <Shop />
      <ImagesAndVideos />
      <Execution />
      <RegionVariants />
    </>;
    if(!AC.activity.isSubactivity) {
      const url_tail = `/activity/${AC.activity.slug ? AC.activity.slug.de:''}`;
      shopUrl_ch = Config.shop.ch.url + url_tail;
      shopUrl_de = Config.shop.de.url + url_tail;
    }
  }
  
  // render
  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}, {title:"Aktivitäten", to:"/activities"}]} location={title} />
    {heading}
    <Alert intent="info" title="Shop Links" size="small">
      <ShopLink url={shopUrl_ch} country="CH" />
      <ShopLink url={shopUrl_de} country="DE" />
    </Alert>
    {subactivityInfo}
    {content}
  </>
}

type ShopLinkProps = {
  url: string,
  country: string
}
function ShopLink({url, country} : ShopLinkProps) {
  if(url) {
    return <div>{country}: <Link to={url} target="_blank">{url}</Link></div> 
  }
  return null;
}

export default Activity;