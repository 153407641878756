import { Module, ModuleInfo, Theme } from ".";
export class TestimonialModule implements Module {
  readonly code: string;

  _id?: string;
  countries: string[];
  position: number;
  theme: Theme;
  anchor?: string;

  elements: {
  }

  constructor() {
    this.code = TestimonialModule.info.code;
    this.countries = ["CH", "DE"];
    this.position = 0;
    this.theme = "primary";
    this.elements = {

    }
  }

  static get info(): ModuleInfo {
    return {
      code: "testimonial",
      title: "Testimonial",
      description: "Testimonial (Bewertungen aus der Datenbank)"
    }
  }
}