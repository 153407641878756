import _ from "lodash";
import React, { useContext, useEffect, useState } from "react"
import Util from '../../../util/util';
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";
// components
import { SelfieContext, Selfie } from "./Selfie.Context";
import { Button, Form, ConfirmButton, DateInput, Dropdown, DropdownOption, Icon, Icons, Link, Modal, Stars, TextInput, TextArea } from "../../controls";
// styling
import CSS from "./Selfie.module.scss";

type ItemProps = {
  adventureImage: DA.AdventureImage,
  adventure: TS.Adventure,
  hideInfo?: boolean
}
export default function Item({adventureImage, adventure, hideInfo=false}: ItemProps) {
  // context
  const SC = useContext(SelfieContext);

  // state
  const [selfie, setSelfie] = useState<Selfie>(new Selfie(adventure, adventureImage));

  // user clicks bookmark
  const onClickBookmark = async() => {
    const changeset = {Bookmarked: !selfie.adventureImage.Bookmarked};
    const result = await DA.AdventureImageRepository.update(adventureImage._id!, changeset);
    setSelfie(new Selfie(adventure, result.data!));
  }

  // user adds to selection
  const onClickSelect = () => {
    SC.toggleSelect(new Selfie(adventure, adventureImage));
  }

  // pre-render info
  let info = null;
  if(!hideInfo) {
    info = (
      <div className={CSS.info}>
          <table><tbody>
              <tr>
                <td><Icon icon={selfie.adventureImage.MarketingAllowed ? Icons.CheckCircle : Icons.Ban} intent={selfie.adventureImage.MarketingAllowed ? "confirm" : "delete"} /></td>
                <td>{selfie.adventureImage.MarketingAllowed ? "Marketing Ok" : "kein Marketing"}</td>
              </tr>
              <tr>
                <td><Icon icon={Icons.Calendar} /></td>
                <td>{Util.printDate(selfie.adventureImage.CreateDate)}</td>
              </tr>
              <tr>
                <td><Icon icon={Icons.Star} /></td>
                <td>
                  {adventure.Rating ? <Stars max={5} value={adventure.Rating.Rating} /> : "-"}
                </td>
              </tr>
              <tr>
                <td><Icon icon={Icons.FileAlt} tooltip="Kommentar" /></td>
                <td><RatingText rating={adventure.Rating} element="Comment" /></td>
              </tr>
              <tr>
                <td><Icon icon={Icons.CommentAlt} tooltip="Feedback" /></td>
                <td><RatingText rating={adventure.Rating} element="Feedback" /></td>
              </tr>
              <tr>
                <td><Icon icon={Icons.Gift} /> </td>
                <td>
                  <Link 
                    to={`/surprise/${adventure._id}`} 
                    target="_blank"
                  >
                    {adventure.shortId}
                  </Link>
                </td>
              </tr>
              <tr>
                <td><Icon icon={Icons.Magic} /></td>
                <td>
                  {SC.getActivityNames(adventure)}
                </td>
              </tr>
              <tr>
                <td><Icon icon={Icons.ShareAlt} /></td>
                <td>Social Media Posts</td>
              </tr>
              <tr>
                <td></td>
                <td><Posts adventureImage={selfie.adventureImage} /></td>
              </tr>
          </tbody></table>
        </div>
    )
  }
  // render

  return (
    <div className={CSS.selfie}>
      <div className={CSS.selfie_inner}>
        <div className={CSS.image}>
          <Link to={selfie.adventureImage.ImageUrl} target="_blank">
            <img src={selfie.adventureImage.ImageUrlThumbnail} alt={selfie.adventureImage._id} />
          </Link>
          <div className={`${CSS.bookmark} ${selfie.adventureImage.Bookmarked ? CSS.active:""}`} onClick={onClickBookmark} >
            <Icon icon={selfie.adventureImage.Bookmarked ? Icons.Bookmark : Icons.BookmarkEmpty} />
          </div>
          <div className={`${CSS.selected} ${SC.isSelected(selfie) ? CSS.active:""}`} onClick={onClickSelect} >
            <Icon icon={SC.isSelected(selfie) ? Icons.CheckCircleSolid : Icons.Circle} />
          </div>
        </div>
        
        {info}
      </div>
    </div>
  )
}

type RatingTextProps = {
  rating: TS.AdventureRating|null,
  element: "Comment"|"Feedback"
}
function RatingText({rating, element} : RatingTextProps) {
  if(rating === null) {
    return <div>-</div>
  }
  const text = element === "Comment" ? rating.Comment : rating.Feedback;
  const lines = text.trim().split('\n').filter(line => line.trim().length > 0);
  
  return <div className={CSS.testimonials}>
    {lines.length > 0 ? lines : "-"}
  </div>
}


type PostsProps = {
  adventureImage: DA.AdventureImage
}
function Posts({adventureImage}:PostsProps) {
  // state
  const [posts, setPosts] = useState<DA.AdventureImagePost[]>(adventureImage.Posts);
  const [postToEdit, setPostToEdit] = useState<DA.AdventureImagePost|null>(null);

  // add
  const onClickAdd = () => {
    setPostToEdit(DA.AdventureImageRepository.makePost());
  }
  // edit
  const onClickEdit = (post:DA.AdventureImagePost) => {
    setPostToEdit(post);
  }
  // delete
  const onClickDelete = async(post:DA.AdventureImagePost) => {
    await DA.AdventureImageRepository.deletePost(adventureImage, post);
    setPosts(adventureImage.Posts);
  }
  // close edit dialog
  const stopEdit = () => {
    setPostToEdit(null);
    setPosts(adventureImage.Posts);
  }


  // pre-render
  let content:any = <div>wurde bisher noch nicht veröffentlicht</div>
  if(posts.length > 0) {
    content = adventureImage.Posts.sort((a, b) => a.CreateDate > b.CreateDate ? 1 : -1).map((post, index) => {
      let channel:any = post.Channel;
      if(post.Url && post.Url.length > 0) {
        channel = <Link to={post.Url} target="_blank">{post.Channel}</Link>
      }
      let notes:any = null;
      if(post.Notes.trim().length > 0) {
        notes = <div>{post.Notes.split('\n').map((s,i) => <p key={i}>{s}</p>)}</div>
      }
      return (
        <div key={index} className={CSS.post}>
          <div>{Util.printDate(post.CreateDate)}</div>
          {channel}
          {notes}
          <div>
            <ConfirmButton onConfirm={() => onClickDelete(post)}><Icon icon={Icons.Trash} /></ConfirmButton>
            <Button size="small" onClick={() => onClickEdit(post)}><Icon icon={Icons.Edit} /></Button>
          </div>
        </div>
      )
    });
  }

  // render
  return <>
    <div>
      {content}
      <Button size="small" onClick={onClickAdd} inverted><Icon icon={Icons.Plus} /></Button>
    </div>
    <Modal isOpen={postToEdit !== null} onClose={stopEdit} title="Social Media Post">
      <EditPost adventureImage={adventureImage} post={postToEdit!} stopEdit={stopEdit} />
    </Modal>
  </>;
}

type EditPostProps = {
  adventureImage: DA.AdventureImage,
  post: DA.AdventureImagePost,
  stopEdit: () => void,
}
function EditPost({adventureImage, post, stopEdit}:EditPostProps) {
  // context
  const SC = useContext(SelfieContext);
  // state
  const [entity] = useState<DA.AdventureImagePost>(post || DA.AdventureImageRepository.makePost());

  // mount
  useEffect(() => {
    
  }, []);

  // save
  const onSave = async(result:any) => {
    let updatedEntity = {};
    _.merge(updatedEntity, entity);
    _.merge(updatedEntity, result.changeset);
    await DA.AdventureImageRepository.upsertPost(adventureImage, updatedEntity as DA.AdventureImagePost);

    stopEdit();
  }

  // cancel
  const onCancel = () => {
    stopEdit();
  }

  // render
  const copts: DropdownOption[] = SC.marketingChannels.map(c => {return {value:c.name, label:c.name}});
  return (
    <Form entity={post} onSave={onSave} onCancel={onCancel} labelSave="speichern" labelCancel="abbrechen">
      <DateInput path="CreateDate" label="Datum der Veröffentlichung" />
      <Dropdown path="Channel" label="Kanal" options={copts} />
      <TextInput path="Url" label="Link" />
      <TextArea path="Notes" label="Notizen" />
    </Form>
  )
}