
import * as DA from "../DataAccess";
import Api from "../../util/api";

export class VendorInvoicePayment {
  _id: string|undefined;
  paymentDate: Date|null = null; // date the payment was made - NOTE: if null, the payment is an outgoing payment readied for automatic payment
  // TODO while VendorInvoice's payment has the same structure as PaymentType, it is NOT the same ... 
  paymentType: DA.PaymentType = DA.PaymentTypeRepository.make("bank"); // payment information (see model PaymentType)
  amount: number = 0; // amount assigned to this payment 

  public static fromDb(obj:any) : VendorInvoicePayment {
    const vip = new VendorInvoicePayment();
    vip._id = obj._id;
    vip.paymentDate = obj.paymentDate ? new Date(obj.paymentDate) : null;
    if(!obj.paymentType) {
      vip.paymentType = DA.PaymentTypeRepository.make("bank");
    }
    else {
      vip.paymentType = DA.PaymentTypeRepository.makeFromDb(obj.paymentType);
    }
    vip.amount = obj.amount;
    
    return vip;
  }

  public toDb() : any {
    return {
      _id: this._id,
      paymentDate: this.paymentDate,
      paymentType: this.paymentType,
      amount: this.amount,
    }
  }


  /**
    * Make payments
    * @param {*} invoices 
    * @param {*} paymentType 
    * @param {*} paymentDate 
    */
  static async makePayments(invoices:any, paymentType:any, paymentDate:any): Promise<any> {
    // create payment objects
    let payments = invoices
      .map((invoice:any) => {
        let amountPaid = (invoice.payments || []).reduce((prev:any, curr:any) => { return prev += curr.amount}, 0)
        let amountOustanding = invoice.amount - amountPaid
        // api expects objects form like the one below
        return {
          vendorInvoice_id:invoice._id,
          amount:amountOustanding,
          paymentDate,
          paymentType:paymentType
        }
      })
      .filter((payment:any) => payment.amount > 0) // TODO this should really never happen, if so we might want to inform user if this happends

    // api call  
    let result = await Api.post('vendorinvoices/payments', payments);
    if(result.success) {
      return result.data
    }
    else {
      // TODO properly handle result errors
      console.error(result.error)
      return undefined
    }
  }

  /**
   * Prepare automatic payments
   * @param {*} invoices 
   * @param {*} paymentType 
   */
  static async prepareAutomaticPayments(invoices:any, paymentType:any): Promise<any> {
    // we prepare automatic payments by simply making payments WITHOUT a payment date
    let result = await this.makePayments(invoices, paymentType, null)
    return result
  }

  static async abortAutomaticPayments(invoices:any): Promise<any> {
    let paymentsToDelete = this.getOustandingPayments(invoices)
    
    let result = await Api.deleteWithPayload('vendorinvoices/payments', paymentsToDelete)
    if(result.success) {
      return result.data
    }
    else {
      // TODO properly handle result errors
      console.error(result.error)
      return undefined
    }
  }
  
  static async completeAutomaticPayments(invoices:any, paymentDate:any): Promise<any> {
    let paymentsToComplete = this.getOustandingPayments(invoices)
    paymentsToComplete.forEach(p => p.changeset = {paymentDate})
  
    let result = await Api.patchWithPayload('vendorinvoices/payments', paymentsToComplete)
    if(result.success) {
      return result.data
    }
    else {
      // TODO properly handle result errors
      console.error(result.error)
      return undefined
    }
  }

  private static getOustandingPayments(invoices:any): any[] {
    let payments:any[] = []
    invoices
      .filter((invoice:any) => invoice.payments && invoice.payments.length > 0)
      .forEach((invoice:any) => {
        invoice.payments
          .filter((p:any) => p.paymentDate === null)
          .forEach((p:any) => {
            payments.push({
                vendorInvoice_id:invoice._id,
                payment_id:p._id
            })
          })
        })
  
    return payments
  }
}
