import React from "react";
import {Card, Icons} from '../../../controls';
// subviews
import Doner from "./Doner.View"
import Donee from "./Donee.View"
import Comments from "./Comments.View"
import PurchaseInfo from "./PurchaseInfo.View"
import Season from "./Season.View"

type GeneralInfoProps = {
  expanded: boolean
}
export default function GeneralInfo({expanded}:GeneralInfoProps) {

  // render
  return (
    <Card title="Allgemeine Infos" icon={Icons.ExclamationCircle} collapsible={true} expanded={expanded}>
      <PurchaseInfo />
      <Doner />
      <Donee />
      <Comments />
      <Season />
    </Card>
  );
}
