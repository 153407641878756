import React, { useContext, useEffect, useState } from "react";
// types
import * as Cms from "../../../../types/Cms";
// contexts and components
import { PageContext } from "../Page.Context";
import { Alert, Dropdown, TextInput, CloudStorageUploader, Form, Checkbox, Icon, Icons } from "../../../controls";
import * as Selectors from "../Selectors";
import * as Preview from "../Preview";
import CSS from "./Hero.module.scss";

const layoutOptions: any = [
  { value: "text-image", label: "Text-Bild" },
  { value: "image-text", label: "Bild-Text" }
];

type HeroProps = {
  instance?: Cms.HeroModule,
  closeDialog: () => void
}
export function Hero({ instance, closeDialog }: HeroProps) {
  // context
  const PC = useContext(PageContext);

  // state
  const [entity, setEntity] = useState<Cms.HeroModule | null>(null);
  const [busy, setBusy] = useState<boolean>(false);

  // mount
  useEffect(() => {
    let entity: Cms.HeroModule;
    if (instance) {
      entity = instance;
    }
    else {
      entity = new Cms.HeroModule();
    }
    setEntity(entity)
  }, [])

  // user wants to cancel
  const onCancel = () => {
    closeDialog();
  }

  // user wants to save
  const onSave = async (formResult: any) => {
    setBusy(true);
    const updatedEntity = formResult.merge(entity);

    if (formResult.changeset.countries) {
      // TODO we need to do this because formResult.merge does not seem to handle arrays correctly
      updatedEntity.countries = formResult.changeset.countries;
    }

    //return;
    await PC.upsertModule(updatedEntity);
    // close dialog
    closeDialog();
  }


  // TODO <Alert /> needs content
  return (
    <Form entity={entity} onCancel={onCancel} onSave={onSave} busy={false}>
      <Selectors.BaseProperties busy={busy} />

      <TextInput label="Titel" path="elements.title" placeholder="Titel eingeben" disabled={busy} />
      <TextInput label="Untertitel" path="elements.subtitle" placeholder="Untertitel eingeben" disabled={busy} />
      <Alert intent="info" title="Text in Titel und Untertitel mit Farbe hinterlegen" size="small">
        Um Text mit Farbe zu hinterlegen <pre>{`<mark1>Text mit primärer Farbe</mark1>`}</pre> und <pre>{`<mark2>Text mit sekundärer Farbe</mark2>`}</pre> verwenden
      </Alert>
      <TextInput label="Button Text" path="elements.buttonText" placeholder="Text auf Button" disabled={busy} />
      <TextInput label="Button Url" path="elements.buttonHref" placeholder="Href auf Button" disabled={busy} />

      <TextInput label="Button 2 Text" path="elements.secondaryButtonText" placeholder="Text auf Button" disabled={busy} />
      <TextInput label="Button 2 Url" path="elements.secondaryButtonHref" placeholder="Href auf Button" disabled={busy} />

      <CloudStorageUploader kind="image" label="Bild (Desktop)" folder="cms" path="elements.imageUrl" pathBlurhash="elements.imageBlurhash" disableUrlEditing={true} prefix="cms" />
      <CloudStorageUploader kind="image" label="Bild (Mobile)" folder="cms" path="elements.imageMobileUrl" pathBlurhash="elements.imageMobileBlurhash" disableUrlEditing={true} prefix="cms" />
      <TextInput label="Alternativtext Bild" path="elements.imageAlt" placeholder="Alternativtext eingeben" disabled={busy} />
      <Alert intent="info" size="small">
        Das horizontale Bild sollte (idealerweise) ein Seitenverhältnis von 1.6 : 1 haben (z.B. 1600 x 1000, oder 800 x 500)<br />
        Das vertikale 1 : 1.6
      </Alert>

      <TextInput label="Vimeo ID" path="elements.vimeoId" placeholder="Vimeo ID eingeben" disabled={busy} />
      <Checkbox label="Autoplay aktivieren" path="elements.autoPLayVimeoVideo" disabled={busy} />
      
      <Dropdown label="Layout" path="elements.mobileLayout" options={layoutOptions} />
    </Form>
  )
}


type HeroPreviewType = {
  instance: Cms.HeroModule,
  expanded?: boolean,
  onClickAction: (module: Cms.Module, action: "edit" | "delete" | "duplicate") => void
}
export function HeroPreview({ instance, expanded, onClickAction }: HeroPreviewType) {
  return (
    <Preview.Container expanded={expanded} module={instance} title={Cms.HeroModule.info.title} onClickAction={onClickAction}>
      <Preview.Table>
        <Preview.TableRow label="Bild">
          <Preview.Image src={instance.elements.imageUrl} />
        </Preview.TableRow>
        <Preview.TableRow label="Bild Mobile">
          <Preview.Image src={instance.elements.imageMobileUrl} />
        </Preview.TableRow>
        <Preview.TableRow label="Alternativtext">
          <MarkedText text={instance.elements.imageAlt} />
        </Preview.TableRow>
        <Preview.TableRow label="Vimeo ID">
          <Preview.Video vimeoId={instance.elements.vimeoId} />
        </Preview.TableRow>
        <Preview.TableRow label={'Autoplay Video'}>
          <Icon icon={instance.elements.autoPLayVimeoVideo ? Icons.Check : Icons.Minus} />
        </Preview.TableRow>
        <Preview.TableRow label="Titel">
          <MarkedText text={instance.elements.title} />
        </Preview.TableRow>
        <Preview.TableRow label="Untertitel">
          <MarkedText text={instance.elements.subtitle} />
        </Preview.TableRow>
        <Preview.TableRow label="Button">
          <Preview.Button text={instance.elements.buttonText} href={instance.elements.buttonHref} />
        </Preview.TableRow>
        <Preview.TableRow label="Button 2">
          <Preview.Button text={instance.elements.secondaryButtonText} href={instance.elements.secondaryButtonHref} />
        </Preview.TableRow>
        <Preview.TableRow label="Layout">
          {instance.elements.mobileLayout}
        </Preview.TableRow>
      </Preview.Table>
    </Preview.Container>

  )
}

type MarkedTextProps = {
  text?: string
}
function MarkedText({ text }: MarkedTextProps) {
  if (!text) {
    return null;
  }
  const innerHtml = text
    .replaceAll("<mark1>", `<mark class=${CSS.mark1}>`).replaceAll("</mark1>", "</mark>")
    .replaceAll("<mark2>", `<mark class=${CSS.mark2}>`).replaceAll("</mark2>", "</mark>")

  return <div dangerouslySetInnerHTML={{ __html: innerHtml }} />
}