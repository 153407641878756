import { Module, ModuleInfo, Theme } from ".";
import uniqid from 'uniqid';

export class TestimonialCarouselModuleItem {
  id:  string;
  who: string;
  what: string;
  text: string;
  imageUrl: string;
  rating: number;
  position: number;

  constructor() {
    this.id = uniqid(); // NOTE: we really only need this id to manage adding and removing items ... (ObjectId is not an option inside browser)
    this.who = "";
    this.what = "";
    this.text = "";
    this.imageUrl = "";
    this.rating = 5;
    this.position = 0;
  }
}

export class TestimonialCarouselModule implements Module {
  readonly code: string;

  _id?: string;
  countries: string[];
  position: number;
  theme: Theme;
  anchor?: string;

  elements: {
    title?:string,
    text?:string,
    items: TestimonialCarouselModuleItem[]
  };

  constructor() {
    this.code = TestimonialCarouselModule.info.code;
    this.theme = "primary";
    this.countries = ["CH", "DE"];
    this.position = 0;
    this.elements = {
      title: "",
      text: "",
      items:[]
    }
  }

  static get info(): ModuleInfo {
    return {
      code: "testimonialcarousel",
      title: "Testimonial-Karussell",
      description: "Karussel mit optionalem Titel und 1..n Testimonials"
    }
  }
}