import React, {useContext} from "react"

// provider
import {AssistantProvider} from "./Assistant.Context"

// context
import {SurpriseContext} from "../Surprise.Context";

// controls
import {Card, Icons} from "../../../controls";
import Outline from "./Outline"
import Booking from "./Booking"
import Activities from "./Activities"
import AppenturaMe from "./AppenturaMe";
import Override from "./Override"

// styling
import "./Assistant.scss"

// the component
// TODO replace on change calls
// TODO subviews should use the surprise object from context, so we don't have to pass it here ... 
type AssistantProps = {
  expanded?:boolean
}
function Assistant({expanded}:AssistantProps) {
  // context
  const SC = useContext(SurpriseContext);

  return (
    <AssistantProvider>
      <Card title="Planungsassistent" icon={Icons.MagicSparkles} id="v-assistant" expanded={expanded} collapsible={true}>
        <AppenturaMe />
        <Activities surprise={SC.surprise} regions={SC.regions} />
        <Outline surprise={SC.surprise} />
        <Override />
        <Booking />
      </Card>
    </AssistantProvider>
  )
}

export default Assistant

