import React, {useState, useEffect} from "react"
import _ from "lodash"
import Api from "../../../../util/api"

import {Form, Loader, NumberInput, Card, Heading} from "../../../controls"

function RedeemTimeframe() {
  // state
  const [data, setData] = useState(null)
  const [busy, setBusy] = useState(true)

  // mount
  useEffect(() => {
    load()
  }, [])

  const load = async() => {
    // get data from API
    const allprops = await Api.get('surpriseproperties')

    // prepare state
    let data = {key:"REDEEM_TIMEFRAME"}
    let data_existing = allprops.find(p => p.key === "REDEEM_TIMEFRAME")
    if(data_existing && data_existing.value) {
      data.value= {
        standard: data_existing.value.standard || 5,
        homedelivery: data_existing.value.homedelivery || 2,
        onlineevent: data_existing.value.onlineevent || 5,
      }
    }
    else {
      data.value = {
        standard:5, homedelivery:2, onlineevent:5
      }
    }

    // update state
    setData(data)
    setBusy(false)

  }

  // save
  const onSave = async(result) => {
    setBusy(true)

    // gather data to save
    let updated_data = {}
    _.merge(updated_data, data)
    _.merge(updated_data, result.changeset)

    // save 
    await Api.put('surpriseproperties', updated_data, "key")

    // done
    setData(updated_data)
    setBusy(false)
  }

  // render
  let content = null
  if(busy || data === null) {
    content = <Loader />
  }
  else {
    content = (
      <Form entity={data} onSave={onSave} onCancel={() => {}} labelSave="speichern" labelCancel="zurücksetzen">
        <NumberInput path="value.standard" label="Standard (in Tagen)" />
        <NumberInput path="value.homedelivery" label="Heimlieferung (in Tagen)" />
        <NumberInput path="value.onlineevent" label="Online-Event (in Tagen)" />
      </Form>
    )
  }
  return <>
    <Heading>Einlösefristen</Heading>
    <Card>
      {content}
    </Card>
  </>
}

export default RedeemTimeframe

