import _ from "lodash";
import React, {useEffect, useState} from "react";
import * as TS from "../../../types";
import {Card, Form, Heading, Checkbox, Loader, TextInput, TextArea, Validators} from "../../controls";

/*
type InfoHeaderType = {
  country:string // "ch" or "de"
}
*/
type InfoHeaderProps = {
  country: "de"|"ch"
} 
function InfoHeader({country}:InfoHeaderProps) {
  // state
  const [busy, setBusy] = useState<boolean>(false);
  const [infoHeader, setInfoHeader] = useState<string|null>(null);
  const [title] = useState<string>(`Info Header ${country === "de" ? "Deutschland" : "Schweiz"}`);

  // mount
  useEffect(() => {
    load();
  }, [])

  // loads data
  const load = async() => {
    const ih = await TS.ShopProperty.getInfoHeader(country);
    setInfoHeader(ih);
  }
  
  // saves data
  const save = async(formResult:any) => {
    setBusy(true);
    const value = _.merge(infoHeader, formResult.changeset);
    TS.ShopProperty.updateInfoHeader(country, value);
    setBusy(false);
  }
  

  // render
  let content = null;
  if(infoHeader === null) {
    content = <Loader />
  }
  else {
    content = (
      <Form entity={infoHeader} onSave={save} labelSave="speichern" busy={busy} onCancel={null}>
        <Checkbox path="show" label="Infoheader anzeigen" disabled={busy} />
        <TextInput disabled={busy} path="text" label="Haupttext" validate={Validators.isRequired("Bitte einen Hauptext eingeben")} placeholder="Hauptext" />
        <TextArea path="description" disabled={busy} label="Zusatzinformationen" rows={3} placeholder="Zusatzinformationen" />
      </Form>
    )
  }
  return <>
    <Heading>{title}</Heading>
    <Card>
      {content}
    </Card>
  </>
}

export default InfoHeader;
