
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import CSS from "./Icon.module.scss";

type IconProps = {
  className?: string,
  icon: IconProp,
  tooltip?: string,
  intent?: "delete"|"cancel"|"error"|"confirm"|"info"|"hint"|"warn"
  onClick?: Function
}
/**
 * Display a fontawesome icon
 * @param {IconProps} props
 */
export function Icon({icon, className, tooltip, onClick, intent} : IconProps) {
  // TODO if onClick it should 
  // - be the color that indicates action (green)
  // - have user-select:none
  const click =() => {
    if(onClick) {
      onClick();
    }
  }
  return <div className={`${CSS.container} ${className || ""} ${intent ? CSS["intent_" + intent] : ""} ${onClick ? CSS.clickable : ""}`} onClick={click} title={tooltip || ''}>
    <FontAwesomeIcon icon={icon} />
  </div>
}