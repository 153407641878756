import React, { useEffect, useState } from "react";
import { Decimal } from "decimal.js";
import * as TS from "../../../types";

import { Validators, Button, CardSection, ConfirmButton, Icon, Icons, Link, Loader, TextInput, Table, Tr, Td } from "../../controls";

type Allocation = {
  surprise_id: {
    value: string,
    valid: boolean
  },
  bookingText: {
    value: string,
    valid: boolean
  },
  amount: {
    value: string,
    valid: boolean
  }
}

type AllocatedAmountsProps = {
  vendorInvoiceId:string,
  surprise:any,
  closeDialogDisabled:boolean,
  closeDialog: () => void,
}
export default function AllocatedAmounts({vendorInvoiceId, surprise, closeDialogDisabled=true, closeDialog} : AllocatedAmountsProps) {
  // state
  const [allocatedAmounts, setAllocatedAmounts] = useState<any[]|null>(null);
  const [amountTarget, setAmountTarget] = useState<number>(0);
  const [amountActual, setAmountActual] = useState<number>(0);
  const [amountCurrent, setAmountCurrent] = useState<number>(0);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [allocation, setAllocation] = useState<Allocation>({
    surprise_id: {
      value: surprise ? surprise._id : '',
      valid: Validators.isObjectId("")(surprise ? surprise._id : '').valid,
    },
    bookingText: {
      value: "",
      valid: false
    },
    amount: {
      value: "0",
      valid: false
    }
  });

  // mount
  useEffect(() => {
    load();
  }, []);

  // user wants to remove item  
  const onClickRemove = async(info:any) => {
    setIsSaving(true);
    await TS.VendorInvoice.deallocateAmount(vendorInvoiceId, info.allocation_id);
    await load();
  }

  // user wants to allocatate an amount
  const onClickAllocate = async() => {
    setIsSaving(true);
    await TS.VendorInvoice.allocateAmount(vendorInvoiceId, allocation.surprise_id.value, allocation.bookingText.value, Number(allocation.amount.value));
    await load();
  }

  // loads data
  const load = async() => {
    const invoice = await TS.VendorInvoice.findOneById(vendorInvoiceId);
    if(invoice) {
      const amountTarget = new Decimal(invoice.amount);
      const allocatedAmounts = (invoice && invoice.allocatedAmounts) ? invoice.allocatedAmounts : [];
      const amountActual: Decimal = allocatedAmounts.reduce((accumulator:any, current:any) => { return accumulator.plus(new Decimal(current.amount))}, new Decimal(0));
      const remainingAmount = amountTarget.minus(amountActual);
  
      setAllocatedAmounts(allocatedAmounts);
      setAmountTarget(amountTarget.toNumber());
      setAmountActual(amountActual.toNumber());
      setIsSaving(false);
      setAllocation({
        surprise_id:{value:surprise ? surprise._id : "", valid:surprise ? true : false},
        bookingText:{value:"", valid:false},
        amount:{value: Number(remainingAmount) === 0 ? "" : remainingAmount.toFixed(2), valid:remainingAmount.toNumber() > 0},
      });
    }
    else {
      // TODO do proper error handling
      alert("invoice not found");
    }
  }

  /* RENDER ****************************************/

  // render loading
  if(!allocatedAmounts) {
    return <Loader />
  }

  // the list of allocations
  let rows = allocatedAmounts.map((a:any) => {
    return (
      <Tr key={a._id}>
        <Td><Link to={`/surprise/${a.surprise_id}`} target="_blank">{a.surprise_id}</Link></Td>
        <Td>{a.bookingText}</Td>
        <Td align="right">{a.amount.toFixed(2)}</Td>
        <Td>
          <ConfirmButton onConfirm={onClickRemove} data={{allocation_id:a._id}}><Icon icon={Icons.Trash} /></ConfirmButton>
        </Td>
      </Tr>
    )
  })
  let table = <div>noch keine Überraschungen zugewiesen</div>
  if(rows.length > 0) {
    let calculatedAmount = amountActual + amountCurrent;
    let remainingAmount = amountTarget - calculatedAmount;

    rows.push(
      <Tr key="total">
        <Td>{`${calculatedAmount.toFixed(2)} von ${amountTarget.toFixed(2)}`}</Td>
        <Td>Restbetrag</Td>
        <Td align="right" style={{color:remainingAmount === 0 ? "green" : "red"}}>
          {remainingAmount.toFixed(2)}
        </Td>
        <Td></Td>
      </Tr>
    )
    table = (
      <Table>
        {rows}  
      </Table>
    )
  }

  // the form to allocate values
  let form = <Loader />

  // only validate if at least one field has a value
  let doValidate = false;
  if(allocation.amount.value.trim() !== "" || allocation.bookingText.value.trim() !== "" || allocation.surprise_id.value.trim().length > 0) {
    doValidate = true;
  }
  // if a surprise is supplied we do not let the user edit that
  let textInputSurprise = null
  if(surprise) {
    doValidate = true;
    textInputSurprise = surprise._id
  }
  else {
    textInputSurprise = (
      <TextInput
        validate={doValidate ? Validators.isObjectId("gültige ID eingeben") : undefined}
        value={allocation.surprise_id.value}
        onChange={(value, validation) => setAllocation({...allocation, surprise_id: {value, valid:validation.valid}})}
      />
    )
  }
  if(!isSaving) {
    form = ( 
      <Table>
        <Tr>
          <Td>Überraschungs-Id</Td>
          <Td>Buchungstext</Td>
          <Td>Betrag</Td>
          <Td></Td>
        </Tr>
        <Tr>
          <Td>
            {textInputSurprise}
          </Td>
          <Td>
            <TextInput
              validate={doValidate ? Validators.isRequired("Buchungstext eingeben") : undefined}
              value={allocation.bookingText.value}
              onChange={(value, validation) => setAllocation({...allocation, bookingText: {value, valid:validation.valid}})}
            />
          </Td>
          <Td>
            <TextInput
              validate={doValidate ? Validators.isGreaterThan0("Wert grösser als 0 eingeben") : undefined}
              value={allocation.amount.value}
              onChange={(value, validation) => setAllocation({...allocation, amount: {value, valid:validation.valid}})}
            />
          </Td>
          <Td>
            <Button 
              size="medium"
              onClick={onClickAllocate} 
              disabled={(allocation.amount.valid && allocation.bookingText.valid && allocation.surprise_id) === false}
            ><Icon icon={Icons.Check} /></Button>
          </Td>
        </Tr>
      </Table>
    )
  }
  
  return (
    <CardSection title="Zugewiesene Überraschungen">
      <div id="aat-v-vendorinvoice-surprises">
        {form}
        {table}
        <Button onClick={() => closeDialog()} size="medium" disabled={closeDialogDisabled}>
          Dialog schliessen
        </Button>
      </div>
    </CardSection>
  )

}
