import React from "react";
import CSS from './Rating.module.scss';

type RatingProps = {
  value: number,
  max: number,
  displayValue: boolean
}

export function Rating({value, max, displayValue}: RatingProps) {
  // pre-render value
  let valueSpan = null;
  if(displayValue) {
    valueSpan = <span className={CSS.value}>{value.toFixed(1)}</span>
  }

  // pre-render stars
  const stars = value;
  const fullStars = Math.floor(stars);
  const halfStars = (stars - fullStars) >= 0.5 ? 1:0;
  const emptyStars = max - fullStars - halfStars;
  const starSpans:any[] = [];
  // full starts
  for(let i=0; i < fullStars; i++) {
    starSpans.push(<span key={'full_' + i} className={`${CSS.star} ${CSS.on}`}></span>)
  }
  // half star
  for(let i=0; i < halfStars; i++) {
    starSpans.push(<span key={'half_' + i} className={`${CSS.star} ${CSS.half}`}></span>)
  }
  // empty stars
  for(let i=0; i < emptyStars; i++) {
    starSpans.push(<span key={'empty_' + i} className={CSS.star}></span>)
  }

  // render
  return (
    <div className={CSS.container}>
      {starSpans}
      {valueSpan}
    </div>
  )
}
