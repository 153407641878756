import { PackagingType, PackagingTypeDeliveryInfo, PackagingTypeDeliveryMethod, PackagingTypeImage } from "./PackagingType";
import { Mapper } from "../_Common/Mapper";
import { TranslatableMapper } from "../_Common/TranslatableMapper";

export class PackagingTypeMapper extends Mapper<PackagingType> {
  public apiEndpoint = "packagingTypes";

  public toDb(packagingType: PackagingType): any {
    return {
      _id: packagingType._id,
      code: packagingType.code,
      image: this.toDb_image(packagingType.image),
      title: TranslatableMapper.toDb(packagingType.title),
      description: TranslatableMapper.toDb(packagingType.description),
      price: packagingType.price,
      deliveryInfo: packagingType.deliveryInfo.map(di => this.toDb_deliveryInfo(di)),
      position: packagingType.position,
      isActive: packagingType.isActive,
      country: packagingType.country,
      deliveryMethod: packagingType.deliveryMethod
    }
  }

  private toDb_deliveryInfo(deliveryInfo: PackagingTypeDeliveryInfo): any {
    return {
      deliveryDayA: deliveryInfo.deliveryDayA,
      deliveryDayB: deliveryInfo.deliveryDayB,
      orderDay: deliveryInfo.orderDay,
      orderTime: deliveryInfo.orderTime,
    }
  }

  private toDb_image(image: PackagingTypeImage): any {
    return {
      url: image.url
    }
  }

  public fromDb(obj:any): PackagingType {
    return {
      _id: obj._id,
      code: obj.code,
      image: this.fromDb_image(obj.image),
      title: TranslatableMapper.fromDb(obj.title),
      description: TranslatableMapper.fromDb(obj.description),
      price: obj.price || 0,
      deliveryInfo: (obj.deliveryInfo || []).map((di:any) => this.fromDb_deliveryInfo(di)),
      position: obj.position || 0,
      isActive: obj.isActive ? true : false,
      country: ["CH","DE"].includes(String(obj.country).toUpperCase()) ? obj.country.toUpperCase() : "CH",
      deliveryMethod: ["letter","parcel","digital","virtual"].includes(String(obj.deliveryMethod).toLowerCase()) ? obj.deliveryMethod : "letter"
    }
  }

  private fromDb_deliveryInfo(obj:any): PackagingTypeDeliveryInfo {
    return {
      deliveryDayA: obj.deliveryDayA || 0,
      deliveryDayB: obj.deliveryDayB || 0,
      orderDay: obj.orderDay || 0,
      orderTime: obj.orderTime || 0
    }
  }

  private fromDb_image(obj:any): PackagingTypeImage {
    return {
      url: obj.url || ""
    }
  }

  

}