import React from "react";

import { Breadcrumb, Heading, Card } from "../../controls";

export default function CreateMe() {

  // render
  return <>
    <Breadcrumb links={[ {title:'Me Generieren', to:'/'}]} location="Appentura.Me Generieren" />
    <Heading>Appentura.ME generieren</Heading>
  </>
}