import { Module, ModuleInfo, Theme } from ".";
export class BasicModule implements Module {
  readonly code: string;

  _id?: string;
  countries: string[];
  position: number;
  theme: Theme;
  anchor?: string;

  elements: {
    title?: string;
    subtitle?: string;
    text?: string;
    imageUrl?: string;
    imageBlurhash?: string;
    imageDescription?: string;
    buttonText?: string;
    buttonHref?: string;
    layout?: "stacked"|"beside";
  }

  constructor() {
    this.code = BasicModule.info.code;
    this.countries = ["CH", "DE"];
    this.position = 0;
    this.theme = "primary";
    this.elements = {
      title: "",
      subtitle: "",
      text: "",
      imageUrl: "",
      imageDescription: "",
      buttonText: "",
      buttonHref: "",
      layout: "stacked"
    }
  }

  static get info(): ModuleInfo {
    return {
      code: "basic",
      title: "Basis-Modul",
      description: "Basis-Module bestehend aus optionalen Feldern: Titel, Untertitel, Text, Bild, Bildbeschriftung, Button"
    }
  }
}