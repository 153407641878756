import React from "react";
import { Dropdown } from "../../../controls";

const themeOptions:any = [
  {value:"primary", label:"Primär"},
  {value:"primary-new", label:"Primär Neu"},
  {value:"secondary", label:"Sekundär"},
  {value:"secondary-new", label:"Sekundär Neu"},
  {value:"tertiary", label:"Tertiär"},
  {value:"green", label:"Grün"}
];

export function ThemeSelector() {
  return <Dropdown label="Theme" path="theme" options={themeOptions}/>
}