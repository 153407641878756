import React, {useContext, useState} from "react"
import { Form, Value, TextArea, Modal, CardSection } from "../../../controls";
import { ActivityContext } from "../ActivityContext";


export default function Comments() {
  // state
  const [mode, setMode] = useState<"edit"|"view">("view");

  // context
  const AC = useContext(ActivityContext)!;

  // stops edit mode
  const stopEdit = () => setMode("view");

  // users wants to edit
  const onClickEdit = () => setMode("edit");

  // saves changes
  const saveEdit = async(updatedComments:string) => {
    const changeset = {comments: updatedComments};
    await AC.updateRegionVariant(AC.regionVariant!._id!, changeset);
    stopEdit();
  }

  // pre-render
  const lines = AC.regionVariant!.comments.split('\n').filter(l => l.trim().length > 0);
  const comments = lines.length > 0 ? lines.map((l,i) => <p key={i}>{l}</p>) : null;
  // render
  return <>
    <CardSection title="Kommentare">
      <Value onClick={onClickEdit} placeholder="-">{comments}</Value>
    </CardSection>
    <Modal isOpen={mode==="edit"} title="Regionsvariante - Kommentare" onClose={stopEdit}>
      <Edit comments={AC.regionVariant!.comments} saveEdit={saveEdit} onCancel={stopEdit} />
    </Modal>
  </>
}


type EditProps = {
  comments: string, 
  saveEdit: (updatedComment:string) => void,
  onCancel: () => void,
}
function Edit({comments, saveEdit, onCancel} : EditProps) {
  // state
  const [entity] = useState({comments:comments || ""});
  const [busy, setBusy] = useState(false);

  // save the update
  const onSave = async(formResult:any) => {
    setBusy(true);
    await saveEdit(formResult.changesetFlat.comments); 
    setBusy(false);
  }

  // render
  return (
    <Form onCancel={onCancel} onSave={onSave} entity={entity} busy={busy}>
      <TextArea path="comments" label="Kommentare" />
    </Form>
  ); 
}
