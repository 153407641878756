import Api from "../../../util/api2";
import { Mapper } from "./Mapper";
import { Result } from "./Result";


/**
 * 
 */
export class RepositoryFactory {
  /**
   * Higher order function. Returns a function that executes a search operation using the provided mapper.
   * @param mapper The mapper object that defines the API endpoint and conversion functions.
   * @returns An asynchronous function that accepts a filter object and returns a Promise resolving to an array of items.
   */
  static search<T, M extends Mapper<T>>(mapper:M) {
    return async(filter:any): Promise<T[]> => {
      const projection = {};
      const result = await Api.post(mapper.apiEndpoint, "search", {filter, projection});
      if(result.success) {
        return result.data.items.map((obj:any) => {
          return mapper.fromDb(obj);
        })
      }
      else {
        console.error(result.error);
        return [];
      }
    }
  }

  /**
   * Higher order function. Returns a function that searches a model by its _id
   * @param mapper The mapper object that defines the API endpoint and conversion functions.
   * @returns An asynchronous function that accepts an id and returns the corresponding object or null
   */
  static findById<T, M extends Mapper<T>>(mapper:M) {
    return async(_id:string): Promise<T|null> => {
      const filter = {"_id":_id};
      const projection = {};
      const result = await Api.post(mapper.apiEndpoint, "search", {filter, projection});
      if(result.success) {
        if(result.data.items[0]) {
          return mapper.fromDb(result.data.items[0]);
        }
        else {
          return null;
        }
      } else {
        console.error(result.error);
        return null;
      }
    }
  }

  static make<T, M extends Mapper<T>>(mapper:M) {
    return (): T => {
      const obj = mapper.fromDb({});
      return obj;
    }
  }

  static create<T, M extends Mapper<T>>(mapper:M) {
    return async(obj: T): Promise<Result<T>> => {
      const result = await Api.post(mapper.apiEndpoint, "create", mapper.toDb(obj));
      if(result.success) {
        const data = mapper.fromDb(result.data.item);
        return new Result<T>(true, data, null);
      }
      else {
        console.error(result.error);
        return new Result<T>(false, null, new Error(result.error.message));
      }
    }
  }

  static update<T, M extends Mapper<T>>(mapper:M) {
    return async(id:string, changeset: Partial<T>): Promise<Result<T>> => {
      const result = await Api.post(mapper.apiEndpoint, "update", {id:id, set:changeset});
      if(result.success) {
        const data = mapper.fromDb(result.data.item);
        return new Result<T>(true, data, null);
      }
      else {
        return new Result<T>(false, null, new Error(result.error.message));
      }
    }
  }

  static remove<T, M extends Mapper<T>>(mapper:M, actionName:string = "delete") {
    return async(id:string): Promise<void> => {
      const result = await Api.post(mapper.apiEndpoint, actionName, {id});
      if(!result.success) {
        console.error(result.error);
      }
    }
  }

}