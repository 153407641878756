import React, { useContext } from "react";
import { Checkbox, TextInput  } from "../../../../controls";
import { SearchContext } from "../Search.Context";
import FilterGroup from "../Search.FilterGroup";

export default function Shipping() {
  // context
  const Search = useContext(SearchContext);

  // onChange
  const onChange = (b:boolean) => {
    const updatedFilter = Search.filter.clone();
    updatedFilter.isNotShipped = b;
    Search.reset(updatedFilter);
  }

  // render
  return (
    <FilterGroup title="Versand">
      <Checkbox secondaryLabel="noch nicht versendet" onChange={onChange} value={Search.filter.isNotShipped} />
    </FilterGroup>
  )
}