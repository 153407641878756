import Api from "../../util/api";
import Api2 from "../../util/api2";
import { XmlNode, XmlAttribute, XmlDocument } from "../../util/Xml";
import moment from "moment";
import _ from "lodash";
import config from "../../config";

export class VendorInvoiceSepa {
  static async createPainFile(invoices:any, account:any, executeOn:any): Promise<string> {
    // Info
    // - Testplattformen: https://www.moneytoday.ch/iso20022/movers-shakers/unternehmen-institutionen/testplattformen-iso20022/
    // - Testplattform Postfinance: https://isotest.postfinance.ch/corporates/
    // - Testplattform UBS: https://ubs-paymentstandards.ch/ (kann jeder einen Account erzeugen)
    // - IBAN Rechner für PostFinance Kontonummer
    // - Spezifikation: https://www.six-group.com/interbank-clearing/dam/downloads/de/standardization/iso/swiss-recommendations/implementation-guidelines-ct.pdf
    // - Spezifikation: https://www.abnamroclearing.com/en/images/010_About_Us/070_news_and_views/Regulations_and_Projects/Documents/2014-03-SEPA-Payment-Initiation-Format.pdf
    // - Spezifikation: https://www.zkb.ch/media/dok/zahlen/handbuch-iso-20022.pdf
  
    let createdOn = new Date()
    let sumAmounts = invoices.reduce((prev:any, curr:any) => {
      return prev + curr.amount
    }, 0)
  
    // the first node below document
    const CstmrCdtTrfInitn = new XmlNode("CstmrCdtTrfInitn");
  
    // the group header
    const GrpHdr = CstmrCdtTrfInitn.addNode(new XmlNode("GrpHdr"));
    GrpHdr.addNode(new XmlNode("MsgId", `appentura-${createdOn.getTime()}`)); // muss eindeutig sein - da wir diese per Knopfdruck erzeugen, sollte der Timestamp ausreichend sein}
    GrpHdr.addNode(new XmlNode("CreDtTm", moment(createdOn).format("YYYY-MM-DDTHH:mm:ss"))); // Create Date Time - soll dem effektiven Erstellungsdatum entsprechen
    GrpHdr.addNode(new XmlNode("NbOfTxs", invoices.length)); // number of transactions
    GrpHdr.addNode(new XmlNode("CtrlSum", sumAmounts)); // sum of all amounts
    GrpHdr.addNode(new XmlNode("InitgPty"))
      .addNode(new XmlNode("Nm", config.appentura.name));
    
    // the payment information (contains the transactions)
    const PmtInf = CstmrCdtTrfInitn.addNode(new XmlNode("PmtInf"));
    PmtInf.addNode(new XmlNode("PmtInfId", "appentura-payment")); // TODO was können wir hier sinnvolles reintun (und nein, auch wenn gewisse Spezifikationen sagen, dass das nicht mehr verwendet wird, es wird verlangt)
    PmtInf.addNode(new XmlNode("PmtMtd", "TRF")); // payment method (darf nur TRF oder TRA enthalten, TRA wird in der Schweiz analog TRF verarbeitet, hat keine spezielle Funktion)
    PmtInf.addNode(new XmlNode("BtchBookg", true)); // Es erfolgt, soweit möglich, eine Sammelbuchung pro "PaymentInformation"
    PmtInf.addNode(new XmlNode("ReqdExctnDt"))
      .addNode(new XmlNode("Dt", moment(executeOn).format("YYYY-MM-DD"))); // das gewünschte Ausführungsdatum - allfällig wird automatisch auf nächtsmögliches Bankwertag/Postwerktag Datum angepasst
    PmtInf.addNode(new XmlNode("Dbtr"))
      .addNode(new XmlNode("Nm", config.appentura.name));
    PmtInf.addNode(new XmlNode("DbtrAcct"))
      .addNode(new XmlNode("Id"))
        .addNode(new XmlNode("IBAN", account.bank.iban.replace(/\s/g, '')));
    PmtInf.addNode(new XmlNode("DbtrAgt"))
      .addNode(new XmlNode("FinInstnId"))
        .addNode(new XmlNode("BICFI", account.bank.bic.replace(/\s/g, '')))
  
    // add the transactions to the payment information
    invoices.forEach((invoice:any) => {
      // make sure we have a 27 digit esr
      let referenceNumber = (invoice.referenceNumber || '').replace(/\s/g, '');
      
      // payment purpose
      let paymentPurpose = (invoice.paymentPurpose || '').substring(0, 140).trim() // TODO currently we only check on client side of SEPA character set is used
      if(paymentPurpose.length === 0) {
        paymentPurpose = "Appentura Dienstleistung"
      }
      paymentPurpose = this.__toSepaString(paymentPurpose)
  
      // IBAN or ESR participant?
      let isEsrParticipant = invoice.provider.bankAccount.accountNumber.match(/[^\0]*-[^\0]*-[^\0]*/) !== null
      
      // Create the creditor transaction info
      const CdtTrfTxInf = new XmlNode("CdtTrfTxInf");
      const PmtId = CdtTrfTxInf.addNode(new XmlNode("PmtId"));
      PmtId.addNode(new XmlNode("InstrId", invoice._id)); // 'optional element which is used between debtor and debtor bank'
      PmtId.addNode(new XmlNode("EndToEndId", invoice._id)); // "wird im Kontoauszug des Empfängers ausgegeben" http://www.ex-sepa.de/?SEPA_mit_Excel%2C_Calc_oder_Access:SEPA_Lastschrift:EndToEndID
      if(isEsrParticipant) {
        // payment type information only works with ESR
        CdtTrfTxInf.addNode(new XmlNode("PmtTpInf"))
          .addNode(new XmlNode("LclInstrm"))
            .addNode(new XmlNode("Prtry", "CH01"))
      }
      CdtTrfTxInf.addNode(new XmlNode("Amt"))
        .addNode(new XmlNode("InstdAmt", invoice.amount)).addAttribute(new XmlAttribute("Ccy", "CHF")); // instructed amount
      
      // creditor
      const Cdtr = CdtTrfTxInf.addNode(new XmlNode("Cdtr"));
      Cdtr.addNode(new XmlNode("Nm", this.__toSepaString(invoice.provider.billingAddress.companyName)));
      const PstlAdr = Cdtr.addNode(new XmlNode("PstlAdr"));
      if(String(invoice.provider.billingAddress.street).trim().length > 0) {
        PstlAdr.addNode(new XmlNode("StrtNm", this.__toSepaString(invoice.provider.billingAddress.street)));
      }
      if(String(invoice.provider.billingAddress.houseNumber).trim().length > 0) {
        PstlAdr.addNode(new XmlNode("BldgNb", this.__toSepaString(invoice.provider.billingAddress.houseNumber)));
      }
      PstlAdr.addNode(new XmlNode("PstCd", this.__toSepaString(invoice.provider.billingAddress.zip)));
      PstlAdr.addNode(new XmlNode("TwnNm", this.__toSepaString(invoice.provider.billingAddress.city)));
      const ctry = this.__toSepaString(invoice.provider.billingAddress.countryCode);
      if(ctry.trim().length > 0) {
        PstlAdr.addNode(new XmlNode("Ctry", ctry));
      }
      
      // ESR Info
      if(isEsrParticipant) {
        if(referenceNumber.length < 27) {
          referenceNumber = referenceNumber.padStart(27, '0')
        }
        // ESR participant number 
        CdtTrfTxInf.addNode(new XmlNode("CdtrAcct"))
          .addNode(new XmlNode("Id"))
            .addNode(new XmlNode("Othr"))
              .addNode(new XmlNode("Id", invoice.provider.bankAccount.accountNumber.replace(/\s/g,'')));
  
        // ESR reference number
        let hasReferenceNumber = true /// TODO what if there is none ... using the <Strd> is not allowed with this type of payment ...
        if(hasReferenceNumber) {
          CdtTrfTxInf.addNode(new XmlNode("RmtInf"))
            .addNode(new XmlNode("Strd"))
              .addNode(new XmlNode("CdtrRefInf"))
                .addNode(new XmlNode("Ref", referenceNumber))
        }
      } 
      else {
        CdtTrfTxInf.addNode(new XmlNode("CdtrAcct"))
          .addNode(new XmlNode("Id"))
            .addNode(new XmlNode("IBAN", invoice.provider.bankAccount.accountNumber.replace(/\s/g,'')));
        // ever since QR invoices are a thing, IBAN invoices can also have a reference number
        if(referenceNumber.trim().length === 0) {
          CdtTrfTxInf.addNode(new XmlNode("RmtInf"))
            .addNode(new XmlNode("Ustrd", paymentPurpose));
        }
        else {
          const RmtInf = CdtTrfTxInf.addNode(new XmlNode("RmtInf"));
          //RmtInf.addNode(new XmlNode("Ustrd", paymentPurpose));
          const CdtrRefInf = RmtInf.addNode(new XmlNode("Strd")).addNode(new XmlNode("CdtrRefInf"));
          if(referenceNumber.startsWith("RF")) {
            CdtrRefInf.addNode(new XmlNode("Tp"))
              .addNode(new XmlNode("CdOrPrtry"))
                .addNode(new XmlNode("Cd", "SCOR"));
          }
          else {
            CdtrRefInf.addNode(new XmlNode("Tp"))
              .addNode(new XmlNode("CdOrPrtry"))
                .addNode(new XmlNode("Prtry", "QRR"));
          }
          
          CdtrRefInf.addNode(new XmlNode("Ref", referenceNumber));
        }
      }
  
      // add the Creditor-Transfer-Transcation-Info to the Payment-Information
      PmtInf.addNode(CdtTrfTxInf);
    })
  
    // build the Document
    const document = new XmlDocument();
    document.addAttribute(new XmlAttribute("xmlns", "urn:iso:std:iso:20022:tech:xsd:pain.001.001.09"));
    document.addAttribute(new XmlAttribute("xmlns:xsi", "http://www.w3.org/2001/XMLSchema-instance"));
    document.addAttribute(new XmlAttribute("xsi:schemaLocation", "http://www.six-interbank-clearing.com/de/pain.001.001.03.ch.02.xsd  pain.001.001.03.ch.02.xsd"));
    document.addNode(CstmrCdtTrfInitn);
    
    // create the xml string and return it
    const xmlString = document.toString();
    return xmlString
  }


  /**
   * Makes sure only SEPA accepted characters are inside a string
   * @param {*} s 
   */
  static __toSepaString(s:string): string {
    // Unicode characters take up two character slots ... e.g. 'u' followed by ' ̈' makes an 'ü' 
    // Kombinierende diakritische Zeichen (https://unicode-table.com/de/#0308):
    //  ̈ : 776
    //  ̀ : 768
    //  ́ : 769
    //  ̂ : 770
    let diacritics:any = {
      776: {
        "A":"Ae", "O":"Oe", "U":"Ue",
        "a":"ae", "o":"oe", "u":"ue"
      }
    }
    
    let umlauts:any = {
      "Ä":"Ae", "ä":"ae", 
      "Ö":"Oe", "ö": "oe",
      "Ü":"Ue", "ü":"ue",
    }
    
    // characters allowed
    const sepachars:any = [
      "a","b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z",
      "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
      "0", "1", "2", "3", "4", "5", "6", "7", "8", "9",
      "/", "-", "?", ":", "(", ")", ".", ",", "'", "+", 
      " "
    ]

    // split the string into an array of characters
    let arr = (s || '').split('')
    
    // the result we are going to return as a joined string
    let result = []
    
    // go through all the characters
    for(let i=0; i<arr.length; i+=1) {
      // check if sepa character, if so, add it ... if not try to replace it
      if(sepachars.includes(arr[i])) {
        result.push(arr[i])
      }
      else {
        // the character is not part the sepa character table, attempt to replace it
        // is it an umlaut we handle?
        let umlaut:any = umlauts[arr[i]]
        if(umlaut) {
          result.push(umlaut)
        }
        // is it a diacritic we handle?
        let code = arr[i].charCodeAt(0)
        let diacritic:any = diacritics[code]
        if(diacritic) {
          // diacritic found
          if(i > 0) {
            // replace the character before the diacritic
            let replacement:any = diacritic[result[i - 1]]
            if(replacement) {
              result[i-1] = replacement
            }
          }
        }
      }
    }
    
    return result.join('');
  }
}