import React, { useState } from "react";
import Code from "../Code";
import { Card, Heading, Form, NumberInput } from "../../../controls";

export default function TextInputDemo() {
  const [age, setAge] = useState<number>(20);
  const [minAge, setMinAge] = useState<number>(16);
  const [formData, setFormData] = useState<string>("");


  // form save
  const onSave = (formData:any) => {
    setFormData(JSON.stringify(formData))
  }

  // validates minimal age
  const validateMinAge = (v:number) => {
    if(v >= 18) {
      return {valid: true};
    }
    else {
      return {valid: false, message: "you need to be at least 18 years old"}
    }
  }

  // render

  return <>
    <Heading>NumberInput</Heading>
    
    <Card title="In Form">
      <Form busy={false} entity={{age: 12, ageValidate:16, ageNaN:"won't work"}} onSave={onSave}>
        <NumberInput path="age" label="Age" />
        <NumberInput path="ageValidate" label="Age with validation" validate={validateMinAge} />
        <NumberInput path="ageNaN" label="Age NaN" />
      </Form>
      <div>
        <div>formData:</div>
        <Code>
          {formData}
        </Code>
      </div>
    </Card>

    <Card title="Without Form">
      <NumberInput label="Age" value={age} onChange={(v:number) => setAge(v)} />
      <div>entered age: {age}</div>
    </Card>

    <Card title="Validation">
      <NumberInput label="Your Age" explanation="welcome to the club, what is your age?" value={minAge} onChange={(v:number) => setMinAge(v)} validate={validateMinAge} />
    </Card>

    <Card title="Incomplete Implementation (needs value or path if in form context)">
      <NumberInput label="Something Missing" />
    </Card>

  </>
}
