import React, { useEffect, useState } from "react";
// components
import { Button, ConfirmButton } from ".";


type DriveDeleteProps = {
  labelDelete: string,
  clientId: string,  // the project/product client id
  fileId: string,
  onDeleteCompleted: (info:any) => void,
  onDeleteFailed: (info:any) => void,
  onCancel: () => void,
  data?: any,
  children: React.ReactNode|React.ReactNode[]
}
export function DriveDelete({labelDelete, clientId, fileId, onDeleteCompleted, onDeleteFailed, children, data, onCancel} : DriveDeleteProps) {
  // state
  const [signedIn, setSignedIn] = useState<boolean>(false);
  const [deleteEnabled, setDeleteEnabled] = useState<boolean>(true);

  // mount
  useEffect(() => {
    loadScript();
  }, []);

  // loads script
  const loadScript = () => {
    // note: we have the script included in index.html
    (window as any).gapi.load('client:auth2', initScript);
  }
  // inits script
  const initScript = () => {
    // Google Drive API Stuff
    let DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v2/rest"]; // Array of API discovery doc URLs for APIs used by the quickstart
    let SCOPES = "https://www.googleapis.com/auth/drive"; // Authorization scopes required by the API; multiple scopes can be included, separated by spaces. We give full access

    // callback function used by the api to inform about signin status
    let updateSigninStatus = (signedIn:any) => { 
      setSignedIn(signedIn); 
    }

    // init the gapi client
    (window as any).gapi.client.init({
      discoveryDocs: DISCOVERY_DOCS,
      clientId: clientId,
      scope: SCOPES
    }).then(function () {
      // Listen for sign-in state changes.
      (window as any).gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
      // Handle the initial sign-in state.
      updateSigninStatus((window as any).gapi.auth2.getAuthInstance().isSignedIn.get())
    });
  }

  // user wants to sign in
  const signIn = () => {
    (window as any).gapi.auth2.getAuthInstance().signIn();
  }
  // user wants to sign out
  const signOut = () => {
    (window as any).gapi.auth2.getAuthInstance().signOut();
  }
  const deleteFile = () => {
    // request object
    const request = (window as any).gapi.client.drive.files.trash({
      'fileId':fileId
    })

    request.execute((response:any) => {
      // when successful, we get a response as described here: https://developers.google.com/drive/v2/reference/files#resource
      // when not successful, we get an object with 'code', 'message' and other properties
      let success = response.id !== undefined;
      if(success) {
        const successInfo = {
          fileId:response.id,
          message:'File moved to trash',
          data:data
        }
        setDeleteEnabled(false);
        onDeleteCompleted(successInfo)
      }
      else {
        const errorInfo = {
          code:response.code,
          message:response.message,
          data:data
        }
        setDeleteEnabled(false);
        onDeleteFailed(errorInfo);
      }
    })
  }

  // user wants to cancel
  const cancel = () => {
    if(onCancel) {
      onCancel()
    }
  }

  // pre-render
  const buttonSignIn = <Button size="small" onClick={signIn}>authorisieren</Button>;
  
  // render
  return <div>
    {!signedIn ? buttonSignIn :null}
    {children}
    <ConfirmButton onConfirm={deleteFile} onCancel={cancel} disabled={!signedIn || !deleteEnabled}>{labelDelete}</ConfirmButton>
  </div>
}