import _ from "lodash";
import React, { useEffect, useContext, useState } from "react";
import Placeholders from "../../../types/Automatization/Automatization.Placeholders";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";

// context
import { ActivityContext } from "./ActivityContext";
import { Coordinates, Dropdown, DropdownOption, Group, List, ListColumn, NumberInput, TextInput, Validators, Table, Tr, Td, Modal } from "../../controls";

// controls
import { Alert, Button, Loader, CardSection, Form, TextArea } from "../../controls";
import { GMAP_TRAVEL_MODES } from "../../../config/constants";

const columns: ListColumn[] = [
  {
    header: "Position",
    path: "position",
    print: (v) => v.position,
    sort: (a,b) => a.position - b.position
  },
  {
    header: "Titel",
    path: "title.de"
  }
];

export default function Instructions() {
  // context
  const AC = useContext(ActivityContext)!;

  // state
  const [instructionToEdit, setInstructionToEdit] = useState<any>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [instructions, setInstructions] = useState<DA.ActivityInstruction[]>([]);

  // mount
  useEffect(() => {
    if(AC.activity) {
      load(AC.activity);
    }
  }, [AC.activity])

  // loads data
  const load = async(activity:TS.Activity|null) => {
    if(activity) {
      const instructions = await DA.ActivityInstructionRepository.search({activityId:activity._id});
      const instructionsSorted = instructions.sort((a,b) => a.position - b.position);
      setInstructions(instructionsSorted);
      setLoaded(true);
    }
    setInstructionToEdit(null);
  };

  // saves
  const save = async(instruction:DA.ActivityInstruction) : Promise<void> => {
    if(instruction._id) {
      await DA.ActivityInstructionRepository.update(instruction._id!, instruction);
    }
    else {
      await DA.ActivityInstructionRepository.create(instruction);
    }
    await load(AC.activity);
  }

  // start edit
  const startEdit = (instruction:DA.ActivityInstruction|null) => {
    instruction = instruction || DA.ActivityInstructionRepository.make(AC.activity!._id);
    setInstructionToEdit(instruction);
  }

  const stopEdit = () => {
    setInstructionToEdit(null);
  }

  // user wants to delete item
  const onClickDelete = async(instruction:DA.ActivityInstruction) => {
    DA.ActivityInstructionRepository.remove(instruction._id!);
    await load(AC.activity);
  }

  // render
  let content = null;
  if(!loaded) {
    content = <Loader text="lade Anweisungen ..." />
  }
  else {
    if (instructions.length === 0) {
      content = (
        <div style={{ textAlign: "center" }}>
          <Alert intent="info" title="Keine Anweisungen vorhanden" size="medium">
            <div>Es sind noch keine Anweisungen vorhanden.</div>
            <Button size="small" onClick={startEdit}>erfassen</Button>
          </Alert>
        </div>
      );
    }
    else {
      content = (
        <List 
          columns={columns}
          items={instructions}
          getKey={item => item._id}
          hideSearch
          hideFooter
          onClickEdit={(item) => startEdit(item)}
          onClickCreate={() => startEdit(null)}
          onClickDelete={(item) => onClickDelete(item)}
        />
      );
    }
  }
  return (
    <CardSection title="Schritt-Anweisungen">
      {content}
      <Modal isOpen={instructionToEdit !== null} title="Anweisungen bearbeiten" onClose={stopEdit}>
        <Edit instruction={instructionToEdit} save={save} cancel={stopEdit} />
      </Modal>
    </CardSection>
  )
}

type EditProps = {
  instruction: DA.ActivityInstruction,
  cancel: () => void,
  save: (instruction: DA.ActivityInstruction) => Promise<void>
}
function Edit({instruction, save, cancel} : EditProps) {
  // state
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [entity] = useState<any>({
    title: instruction.title.de, 
    text:instruction.text.de, 
    position: instruction.position,
    travel: instruction.travel
  });
  const [travelmodeOptions] = useState<DropdownOption[]>(GMAP_TRAVEL_MODES.map(m => {return {value:m.value || "", label:m.label}}).sort((a, b) => a.label.localeCompare(b.label)));
  
  // form save
  const onSave = async(formResult:any) => {
    const updatedEntity = formResult.merge(entity);
    const updatedInstruction: DA.ActivityInstruction = {
      _id: instruction._id,
      activityId: instruction.activityId,
      position: updatedEntity.position,
      providerId: instruction.providerId,
      title: {
        de: updatedEntity.title,
        en: instruction.title.en,
      },
      text: {
        de: updatedEntity.text,
        en: instruction.text.en,
      },
      travel: updatedEntity.travel
    }

    setIsBusy(true);
    await save(updatedInstruction);
  }

  // render
  if(isBusy) {
    return <Loader />
  }
  return (
    <div>
      <Form 
        entity={entity} 
        onSave={onSave} onCancel={cancel}
        busy={false}
      >
        <TextInput 
          label="Titel" 
          path="title" 
          validate={Validators.isRequired("Bitte Titel für Schritt eingeben")}
        />
        <TextArea 
          label="Anweisungen" 
          path="text" 
          rows={8} 
          validate={Validators.isRequired("Bitte Schritt-Anweisungen eingeben")}
        />
        <PlaceholdersDisplay />
        <NumberInput
          label="Position"
          path="position"
          validate={Validators.isGreaterThan(0, "Bitte einen Wert grösser oder gleich 1 eingeben")}
        />
        <Group title="Anreise">
          <div className="coordinates">
            <Coordinates label="Start" path="travel.start" pathLat="lat" pathLng="lng" />
            <Coordinates label="Ende" path="travel.destination" pathLat="lat" pathLng="lng" />
          </div>
          <Dropdown label="Transportmittel" path="travel.mode" options={travelmodeOptions} />
          <Alert size="medium" title="Angaben zur Anreise nur setzen, wenn du weisst was du tust" intent="warning">
            ACHTUNG: der hier gesetzt Wert überschreibt andere verfügbare Koordinaten. Nur setzen wenn: 
            <ul>
              <li>du genau weisst, was du tust</li>
              <li>dies nicht die erste Schritt-Anweisung für diese Aktivität ist</li>
              <li>diese Aktivität nur einen Anbieter hat</li>
            </ul>
          </Alert>
        </Group>
      </Form>
    </div>
  )
}

function PlaceholdersDisplay() {
  const rows = (Placeholders || []).map(ph => {
    return <Tr key={ph.placeholder}>
      <Td>{ph.placeholder}</Td>
      <Td>{ph.explanation}</Td>
    </Tr>
  })
  rows.unshift(<Tr key="plural"><Td>| (Pipe Symbol)</Td><Td>Um zwei Varianten einer Zeile (die erste für Plural, die zweite für Singular) zu erfassen. z.B: "Dein Abenteuer|Euer Abenteuer"</Td></Tr>)
  return <Table fontSize="small">{rows}</Table>
}
