import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Config from "./config";
// components
import { Table, Tr, Td, Icon, Icons } from "./components/controls";

// styling
import CSS from "./UpdateHint.module.scss";

type MetaNote = {
  kind: "bugfix"|"feature"|"internal"|"other",
  text: string
}

function UpdateHint() {
  const [needsUpdate, setNeedsUpdate] = useState(false);
  const [notes, setNotes] = useState<MetaNote[]>([]);
  const [release, setRelease] = useState(0);

  // page changed?
  let location = useLocation();
  useEffect(() => {
    // get latest version info
    const load = async() => {
      try {
        const data = await fetch(`${Config.site.url}/meta.json?${Date.now()}`);
        const meta = await data.json();
        if(meta.release !== Config.meta.release) {
          setNeedsUpdate(true);
          setNotes(meta.notes);
          setRelease(meta.release);
        }
      }
      catch(error) {
        console.error(error);
      }
    };
    load();
  }, [location]);

  // rednder
  if(!needsUpdate) {
    return null
  }
  else {
    const notesRows = notes.map((note, index) => {
      let icon: IconProp = {
        "bugfix": Icons.Bug,
        "feature": Icons.BoxOpen,
        "internal": Icons.Cogs,
        "other": Icons.Info,
      }[note.kind] || Icons.InfoCircle;
      return <Tr key={index}><Td><Icon icon={icon} /></Td><Td>{note.text}</Td></Tr>
    });
    return <div onClick={() => window.location.reload()} className={CSS.container}>
      <div className={CSS.heading}>
        <div>Es ist eine neue Version verfügbar. Klicke auf diesen Balken, um die Seite neu zu laden.</div>
        <div><Icon icon={Icons.Sync} /></div>
      </div>
      <div className={CSS.subheading}>Anmerkungen zum Release {release}: </div>
      <Table fontSize="large">
        {notesRows}
      </Table>
    </div>
  }
}


export default UpdateHint