import React, { useState, useEffect } from "react";
import { Link } from "./controls";
import config from "./../config";
// other components
import Status from "./Sidebar-Status";
// images and styling
import images from "./../images";
import "./Sidebar.scss";

export default function Sidebar(props) {
  // state
  const [state, setState] = useState({
    menuHidden: true,
    location: '',
    visibility: {
      shop: true,
      surprise: true,
      tools: true,
      user: true,
      templates:true,
      office:true,
      finances: true
    }
  })

  // mount
  useEffect(() => {
    // restore visibility states
    let drawerVisibility = window.localStorage.getItem('drawer-visibility')
    if(drawerVisibility) {
      let visbilitySource = JSON.parse(drawerVisibility)
      let visibility = state.visibility
      for(let target in visbilitySource) {
        if(visbilitySource.hasOwnProperty(target)) {
          visibility[target] = visbilitySource[target]
        }
      }
      setState({...state, visibility})
    }
  }, [])

  // event handler: header is clicked
  const onHeaderClick = (e) => {
    let target = e.target.getAttribute('data-target');
    if(!target) {
      target = e.target.parentNode.getAttribute('data-target');
    }

    let visibility = state.visibility;
    visibility[target] = !visibility[target];
    setState({...state, visibility})

    window.localStorage.setItem('drawer-visibility', JSON.stringify(visibility))
  }

  const onToggleClick = () => {
    setState({...state, menuHidden:!state.menuHidden})
  }

  // render
  let hamburgerClassName = state.menuHidden ? 'hamburger' : 'hamburger open'
  return (
    <div id="aat-v-sidebar" className={`${config.environment === "production" ? "" : "staging"}`}>
      <div className="aat-v-sidebar-header">
        <div className="aat-v-sidebar-header-toggle">
          <div className={hamburgerClassName} onClick={onToggleClick}>
            <span></span><span></span><span></span>
          </div>
        </div>
        <div className="aat-v-sidebar-header-logo">
          <Link to="/">
            <img src={config.environment === "production" ? images.logo.white : images.logo.white_staging} alt="appentura logo" />
          </Link>
        </div>
      </div>
      <div className={`menu ${state.menuHidden ? 'hidden':''}`}>
        <Section title="Shop" target="shop" onClick={onHeaderClick} expanded={state.visibility.shop}>
          <MenuItem to="/shop">Shop</MenuItem>
          <MenuItem to="/regions">Regionen</MenuItem>
          <MenuItem to="/conditions">Bedingungen</MenuItem>
          <MenuItem to="/weatherconditions">Wetterbedingungen</MenuItem>
          <MenuItem to="/equipment">Ausrüstung</MenuItem>
          <MenuItem to="/vouchers">Promocodes</MenuItem>
          <MenuItem to="/packaging">Verpackungen</MenuItem>
          <MenuItem to="/providers">Anbieter</MenuItem>
          <MenuItem to="/activities">Aktivitäten</MenuItem>
          <MenuItem to="/activitypackages">Aktivitäts-Pakete</MenuItem>
          <MenuItem to="/charities">Hilfsorganisationen</MenuItem>
          <MenuItem to="/testimonials">Testimonials</MenuItem>
          <MenuItem to="/pages">CMS</MenuItem>
        </Section>

        <Section title="Überraschungen" target="surprise" onClick={onHeaderClick} expanded={state.visibility.surprise}>
          <MenuItem to="/surprises/search">Suche</MenuItem>
          <MenuItem to="/surprises/search/notfinished" subitem>Bestellung abgebrochen</MenuItem>
          <MenuItem to="/surprises/search/ordered" subitem>Bestellt</MenuItem>
          <MenuItem to="/surprises/search/scheduled" subitem>Termin gewählt</MenuItem>
          <MenuItem to="/surprises/search/organized" subitem>Bedingungen verschickt</MenuItem>
          <MenuItem to="/surprises/search/accepted" subitem>Bedingungen akzeptiert</MenuItem>
          <MenuItem to="/surprises/search/ready" subitem>Bereit</MenuItem>
          <MenuItem to="/surprises/search/finished,reviewed" subitem>Durchgeführt</MenuItem>
          <MenuItem to="/surprises/search/shipping" subitem>Versand</MenuItem>
          <MenuItem to="/surprises/search/all" subitem>alle</MenuItem>
          <MenuItem to="/surpriseproperties">Einstellungen</MenuItem>
          <MenuItem to="/bulkorders">Sammelbestellungen</MenuItem>
          <MenuItem to="/selfies">Selfies</MenuItem>
          <MenuItem to="/meteocheck">Meteo-Check</MenuItem>
          <MenuItem to="/live">Live</MenuItem>
          <Additionals>
            <MenuItem to="/surprises/search">Alte Suche</MenuItem>
            <MenuItem to="/offlinesoldadventures">Vorgefertigt</MenuItem>
            <MenuItem to="/promotionsurprises">aus Promotionen</MenuItem>
            <MenuItem to="/surprises/search/archived">Archiviert</MenuItem>
          </Additionals>
        </Section>

        <Section title="Hilfsmittel" target="templates" onClick={onHeaderClick} expanded={state.visibility.templates}>
          <MenuItem to="/resources/mailtemplates">Mailvorlagen</MenuItem>
          <MenuItem to="/resources/manuals">Anleitungen & Checklisten</MenuItem>
          <MenuItem to="/adventuresteptemplates">Schritt-Vorlagen</MenuItem>
          <MenuItem to="/adventuretemplates">Überraschungs-Vorlagen</MenuItem>
          <MenuItem to="https://groups.google.com/a/appentura.ch/g/hello" target="_blank">hello@appentura.ch</MenuItem>
        </Section>

        <Section title="Tools" target="tools" onClick={onHeaderClick} expanded={state.visibility.tools}>
          <MenuItem to="/statistics/datawarehouse">Data Warehouse</MenuItem>
          <MenuItem to="/sanitycheck">Datenzustand</MenuItem>
          <MenuItem to="/mailfail">Mailfail</MenuItem>
          <MenuItem to="/marketingchannels">Marketingkanäle</MenuItem>
          <MenuItem to="/massmailer">Massenmails</MenuItem>
          <MenuItem to="/statistics">Statistiken</MenuItem>
          <MenuItem to="/shipping">Versand</MenuItem>
          <MenuItem to="/incidents/process">Zwischenfälle</MenuItem>
          <MenuItem to="/incidents/categories">Zwischenfall-Kategorien</MenuItem>
        </Section>

        <Section title="Buchhaltung" target="finances" onClick={onHeaderClick} expanded={state.visibility.finances}>
          <MenuItem to="/esr">ESR</MenuItem>
          <MenuItem to="/vendorinvoices">Kreditoren-Rechnungen</MenuItem>
          <MenuItem to="/dunning">Mahnwesen</MenuItem>
          <MenuItem to="/invoices">Rechnungen</MenuItem>
          <MenuItem to="/collectiveInvoices">Monatsabrechnungen</MenuItem>
          <MenuItem to="/paymenttypes">Zahlungsarten</MenuItem>
        </Section>

        <Section title="Office" target="office" onClick={onHeaderClick} expanded={state.visibility.office}>
          <MenuItem to="https://zid.yarooms.com/schedule/daily?location=39181" target="_blank">Raumreservation</MenuItem>
          <MenuItem to="https://www.cognitoforms.com/ZIDBernapark/Ziegelh%C3%BCsiLunchService" target="_blank">Lunch-Service Ziegelhüsi</MenuItem>
        </Section>

        <Section title="User" target="user" onClick={onHeaderClick} expanded={state.visibility.user}>
          <MenuItem to="/logout">ausloggen</MenuItem>
        </Section>

        <Status />
      </div>
    </div>
  )
}

function MenuItem({to, children, target, subitem}) {
  return <div className={`aat-sidebar-section-${subitem ? "sub" : ""}item`}>
    <Link to={to} target={target || "_self"}>{children}</Link>
  </div>
}

function Section({children, expanded, target, onClick, title}) {
  let hint = expanded ? '- ' : '+ '
  return (
    <div className="aat-sidebar-section">
      <div className="aat-sidebar-section-header" data-target={target} onClick={onClick}>
        <div className="aat-sidebar-section-header-text">{title}</div>
        <div className="aat-sidebar-section-header-toggle">{hint}</div>
      </div>
      <div style={{display: expanded ? 'block':'none'}}>
        {children}
      </div>
    </div>
  )
}

function Additionals({children}) {
  const [expanded, setExpanded] = useState(false);
  if(expanded) {
    return <>
      {children}
      <div className="aat-sidebar-additionals" onClick={() => setExpanded(false)}>weniger ...</div>
    </>
  }
  else {
    return <div  className="aat-sidebar-additionals" onClick={() => setExpanded(true)}>mehr ...</div>
  }
}


// export default Sidebar
