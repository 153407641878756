import React, { useContext, useState } from "react"
import _ from "lodash";
// types
import * as DA from "../../../../types/DataAccess";

// contexts
import {AssistantContext} from "./Assistant.Context"

// components
import { Button, Link, CardSection, Modal } from "../../../controls";
import BookingRequest from "./BookingRequest"
import BookingRequests from "./BookingRequests"
import BookingResponse from "./BookingResponse"
import BookingReminder from "./BookingReminder"

// styling
import CSS from "./Booking.module.scss"
import { ProviderLink } from "../../../common";

// the component
function Booking() {
  // context
  const AC = useContext(AssistantContext);

  // modal state
  const [modalMode, setModalMode] = useState<"none"|"response"|"reminder"|"create">("none");
  const [modalParams, setModalParams] = useState<any>();
  const [modalTitle, setModalTitle] = useState<string>("");

  // reloads all booking requests
  const reloadBookingRequests = () => {
    AC!.reloadBookingRequests();
  }

  // user wants to create a booking request
  const onClickCreate = (selectedActivity: any) => {
    const outlineActivity = AC!.data.activities.find(a => a.activityId === selectedActivity.activityId);
    setModalMode("create");
    setModalTitle("Buchungsanfrage erstellen");
    setModalParams({
      activityInfo: selectedActivity, 
      providerId: outlineActivity.providerId,
      regionVariantId: selectedActivity.regionVariantId,
    });
  }

  // user wants to set booking request's response
  const onClickEdit = (requestId:string) => {
    setModalMode("response");
    setModalParams(requestId);
    setModalTitle("Buchungsanfrage bearbeiten");
  }

  // user wants to send a reminder
  const onClickReminder = (requestId:string) => {
    setModalMode("reminder");
    setModalParams(requestId);
    setModalTitle("Erinnerungsmail verschicken");
  }

  // closes modal
  const closeDialog = () => {
    setModalMode("none");
  }

  // no assistant context available
  if(!AC) {
    return null;
  }

  // pre-render items
  let activityList = null
  if(AC.data.activities) {
    activityList = AC.data.activities
    .sort((a:any, b:any) => {
      const da = a.time ? new Date(a.time) : new Date(0);
      const db = b.time ? new Date(b.time) : new Date(0);
      return db > da ? -1 : 1;
    })
    .map(a => {  
      const savedActivity = AC.data.activitiesSaved.find(sa => sa.activityId === a.activityId);
      const hasProvider = !_.isNil(a.providerId)
      const linkToActivity = <Link to={`/activities/${a.activityId}`} target="_blank" className="uppercase">{a.title}</Link>
      let linkCompound
      if(hasProvider) {
        linkCompound = <>
          {linkToActivity} bei <ProviderLink provider={{_id:a.providerId, name:a.providerName} as DA.Provider} />
        </>
      }
      else {
        linkCompound = <>
          {linkToActivity}, Anbieter wählen
        </>
      }
      // creating a booking request is only possible if a provider was selected and the outline has been saved. We check the latter by comparing the saved value against the value currently in memory
      let createActionDisabled = !hasProvider;
      let createActionWarning = null;
      if(!_.isNil(a.providerId) && !_.isNil(savedActivity.providerId) && a.providerId !== savedActivity.providerId) {
        //createAction = <Alert intent="warning">der Anbieter wurde geändert - bitte Planungsvorschlag neu erstellen</Alert>;
        createActionDisabled = true;
        createActionWarning = <tr><td colSpan={5} className={CSS.warning}>der Anbieter wurde geändert - bitte Planungsvorschlag neu erstellen</td></tr>;
      }
      // creating is also disabled if there already is an accepted booking request
      let createActionInfo = null;
      const hasAcceptedBookingRequest = AC.data.bookingRequests.some(br => {
        return br.activity.id === a.activityId && br.response && br.response.accepted
      })
      if(hasAcceptedBookingRequest) {
        createActionDisabled = true;
        createActionInfo = <div>da schon eine Buchungsanfrage akzeptiert wurde, kann keine neue erzeugt werden</div>
      }


      // render
      return (
        <div className={CSS.activity} key={a.activityId}>
          <table>
            <tbody>
              <tr>
                <td colSpan={4}>{linkCompound}</td>
                <td>
                  <Button size="small" onClick={() => onClickCreate(a)} disabled={hasAcceptedBookingRequest}>Anfrage erstellen</Button>
                  {createActionInfo}
                </td>
              </tr>
              {createActionWarning}
              <BookingRequests 
                element={a} 
                editRequest={(data:any) => {
                  onClickEdit(data.requestId);
                }} 
                createReminder={(data:any) => {
                  onClickReminder(data.requestId);
                }}
              />
            </tbody>
          </table>
          
        </div>
      )
    })
  }

  // pre-render content for modal
  let modalContent = null;
  switch(modalMode) {
    case "create":
      modalContent = (
        <BookingRequest 
          adventureInfo={AC!.adventureInfo}
          onDone={reloadBookingRequests}
          activityInfo={modalParams.activityInfo}
          providerId={modalParams.providerId}
          regionVariantId={modalParams.regionVariantId}
          closeDialog={closeDialog}
        />
      )
      break;
    case "response":
      modalContent = (
        <BookingResponse 
          requestId={modalParams}
          onDone={reloadBookingRequests}
          closeDialog={closeDialog}
        />
      )
      break;
    case "reminder":
      modalContent = (
        <BookingReminder 
          requestId={modalParams}
          onDone={reloadBookingRequests}
          closeDialog={closeDialog}
        />
      )
      break;
  }

  // render
  return <>
    <CardSection title="Buchungsanfragen" className={CSS.container}>
      {activityList}
    </CardSection>
    <Modal isOpen={modalMode!=="none"} title={modalTitle} onClose={closeDialog}>
      {modalContent}
    </Modal>
  </>
}

export default Booking



