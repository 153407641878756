import _ from "lodash";
import React, { useContext, useState } from "react";
import {SurpriseContext} from "../Surprise.Context";
import {CardSection, Modal, Validators, Value} from "../../../controls";
import GenericEdit from "../Generic.Edit";

export default function Season() {  
  // state
  const [mode, setMode] = useState<"edit"|"view">("view");
  // context
  const SC = useContext(SurpriseContext);

  const startEdit = () => setMode("edit");
  const stopEdit = () => setMode("view");

  // render
  return <>
    <CardSection title="Saison">
      <Value onClick={startEdit} label="Mögliche Saison (zwischen ...)">
        {_.get(SC.surprise, "Season") || ""}
      </Value>
    </CardSection>
    <Modal isOpen={mode==="edit"} title="Mögliche Saison bearbeiten" onClose={stopEdit}>
      <GenericEdit path="Season" label="Mögliche Saison" kind="textinput" validate={Validators.isRequired("Bitte eine Saison eingeben")} stopEdit={stopEdit} />
    </Modal>
  </>;
}
