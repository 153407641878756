import React, { useState, useEffect } from "react" 
// types
import * as TS from "../../../../types";
// components and controls
import VendorInvoice from "../../VendorInvoices/VendorInvoice";
import { Button, CardSection, DriveLink, Loader, Alert, Modal, useModal } from "../../../controls";
// styling
import "./Invoices.scss";

type InvoicesProps = {
  surprise:any
}
export default function Invoices({surprise}:InvoicesProps) {
  // state
  const [invoices, setInvoices] = useState<any|null>(null);
  const [modal, setModal] = useModal<"add">();

  // mount
  useEffect(() => {
    load();
  }, []);

  // loads data
  const load = async() => {
    // load the data
    setInvoices(null);
    const invoices = await TS.VendorInvoice.getAllocatedAmountsBySurprise(surprise._id)

    // enrich with some properties
    invoices.forEach((invoice:any) => {
      invoice.__allocations = (invoice.allocatedAmounts || []).filter((a:any) => String(a.surprise_id) === String(surprise._id));
    })

    // update state
    setInvoices(invoices);
  }

  // user wants to add
  const onClickAdd = () => {
    setModal("add", "Rechnung erfassen", {invoice:null});
  }

  // user wants to edit
  const onClickEdit = (invoiceToEdit:any) => {
    setModal("add", "Rechnung bearbeiten", {invoice:invoiceToEdit});
  }

  // render when loading data
  if(invoices === null) {
    return <Loader text="lade Rechnungen ..." />
  }

  // pre-render
  let content;
  // Button to add an invoice
  const buttonAdd = (
    <Button size="small" onClick={onClickAdd}>Rechnung hinzufügen</Button>
  )


  // no invoices assigned
  if(invoices.length === 0) {
    content = (
      <div id="aat-v-surprise-invoices">
        <Alert intent="info" title="Keine zugewiesenen Rechnungen" size="medium">
          Diese Überraschung ist noch keiner Rechnung zugewiesen
        </Alert>
        {buttonAdd}
      </div>
    )
  }
  else {
    // render assigned invoices
    let rows = [];
    let total = 0;

    invoices.forEach((invoice:any) => {  
      invoice.__allocations.forEach((a:any, index:number) => {
        total += a.amount
        let providerName, driveLink, button = null
        if(index === 0) {
          providerName = invoice.provider.name
          driveLink = <DriveLink driveId={invoice.driveId}>{invoice.driveTitle || "file"}</DriveLink>
          button = (
            <Button size="small" onClick={() => onClickEdit(invoice)}>edit</Button> 
          )
        }
        rows.push(
          <tr key={`${invoice._id}-${a._id}`}>
            <td>{providerName}</td>
            <td>{driveLink}</td>
            <td>{a.bookingText}</td>
            <td style={{textAlign:"right"}}>{a.amount.toFixed(2)}</td>
            <td>{button}</td>
          </tr>
        )
        
      })
    })

    if(rows.length > 0) {
      rows.push(
        <tr className="total" key="total">
          <td colSpan={3}>TOTAL</td>
          <td style={{textAlign:"right"}}>{total.toFixed(2)}</td>
          <td></td>
        </tr>
      )
    }

    content = (
      <>
        <div id="aat-v-surprise-invoices">
          <table><tbody>{rows}</tbody></table>
          {buttonAdd}
        </div>
        
      </>
    )
  }

  // render
  return <>
    <CardSection title="Rechnungen">
      {content}
    </CardSection>
    <Modal {...modal}>
      { modal.action==="add" &&  <Edit invoice={modal.params.invoice} surprise={surprise} load={load} stopEdit={modal.onClose} />}
    </Modal>
  </>
  
}


type EditProps = {
  invoice: TS.VendorInvoice|null, 
  surprise:any,
  load?:any,
  stopEdit: () => void
}
function Edit({invoice, surprise, load, stopEdit} : EditProps) {
  // state
  const [invoiceToEdit, setInvoiceToEdit] = useState<any|null>(invoice);

  // dialog should be closed
  const onCloseDialog = async() => {
    stopEdit();
    await load();
  }

  // a new invoice was created, do NOT close the dialog be update the invoice to edit
  const onInvoiceCreated = async(invoice:any) => {
    setInvoiceToEdit(invoice);
  }

  // render
  return (
    <VendorInvoice
      invoiceId={invoiceToEdit ? invoiceToEdit._id : null}
      surprise={surprise}
      closeDialog={onCloseDialog}
      onInvoiceCreated={onInvoiceCreated}
    />
  )
}
