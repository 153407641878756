import React from "react";
import CSS from "./Table.module.scss";

type TableProps = {
  children: React.ReactNode|React.ReactNode[], //typeof Tr|typeof Tr[],
  fontSize?: "small"|"regular"|"large",
  className?: string,
  onClick?: () => void
}
export function Table({children, className, fontSize, onClick}: TableProps) {
  // defaults
  fontSize = fontSize || "regular";

  // user clicks
  const _onClick = () => {
    if(onClick) {
      onClick();
    }
  }
  // render
  return (
    <div className={CSS.container}>
      <table className={`${className || ""} ${CSS['fontSize_' + fontSize]} ${onClick ? CSS.clickable : ""}`} onClick={_onClick}>
        <tbody>
          {children}
        </tbody>
      </table>
    </div>
  )
}

type TrProps = {
  children: React.ReactNode|React.ReactNode[], //typeof Td|typeof Td[],
  className?: string,
  onClick?: () => void
}
export function Tr({children, className, onClick}: TrProps) {
  const _onClick = () => {
    if(onClick) {
      onClick();
    }
  }
  return (
    <tr className={`${className || ""} ${onClick ? CSS.clickable : ""}`} onClick={_onClick}>
      {children}
    </tr>
  )
}

type TdProps = {
  children?: React.ReactNode|React.ReactNode[],
  colSpan?: number,
  label?: boolean,
  warning?: boolean,
  secondaryLabel?: boolean,
  className?: string,
  align?: "left"|"center"|"right",
  valign?: "top"|"middle"|"bottom",
  style?: React.CSSProperties,
  onClick?: () => void
}
export function Td({children, className, style, label, warning, secondaryLabel, colSpan, align="left", valign="top", onClick}: TdProps) {
  const _onClick = () => {
    if(onClick) {
      onClick();
    }
  }
  return (
    <td onClick={_onClick} className={`${onClick ? CSS.clickable : ""} ${className || ""} ${label ? CSS.label : ""} ${secondaryLabel ? CSS.secondaryLabel : ""} ${warning ? CSS.warning : ""} ${CSS[align]} ${CSS[valign]}`} colSpan={colSpan} style={style}>
      {children || ""}
    </td>
  )
}
