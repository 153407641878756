import React, { useContext, useState } from "react";
import { Breadcrumb, Card, Heading, Tabs, TabsItem } from "../../controls";
import { CollectiveInvoicesContext, CollectiveInvoicesProvider } from "./CollectiveInvoices.Context";
import List from "./CollectiveInvoices.List";
import Create from "./CollectiveInvoices.Create";


function CollectiveInvoices() {
  // context
  const CIC = useContext(CollectiveInvoicesContext);
  // state
  const [tabItems] = useState<TabsItem[]>([{label:"Erzeugen", value: "create"}, {label:"Rechnungen", value: "list"}, ]);
  const [mode, setMode] = useState<"list"|"create">(tabItems[0].value);

  // user changes tab
  const onChangeTab = (tabItem: TabsItem) => {
    setMode(tabItem.value);
  }

  // pre-render
  let content = null;
  switch(mode) {
    case "create":
      content = <Create />;
      break;
    case "list":
    default:
      content = <List collectiveInvoices={CIC.data.collectiveInvoices} onChanged={() => {CIC.reload()}} />;
      break;
  }
  // render
  return <>
    <Breadcrumb 
      links={[ {title:'Home', to:'/'}]}
      location="Monatsabrechnungen" 
    />
    <Heading>Monatsabrechnungen</Heading>
    <Tabs 
        onChange={onChangeTab}
        items={tabItems}
      />
    <Card>
      {content}
    </Card>
  </>
}

export default () => <CollectiveInvoicesProvider><CollectiveInvoices /></CollectiveInvoicesProvider>;