import React, { useContext, useEffect } from "react";
import * as DA from "../../../../types/DataAccess";
import { Button, Loader, NumberInput, Row, TextInput, Validators } from "../../../controls";
import { BookingRequestContext } from "./BookingRequest.Context";
import ServiceItems from "./BookingRequest.ServiceItems";

type FormPhoneProps = {
  adventureInfo:any, activityInfo:any, 
  provider: DA.Provider,
  reloadBookingRequests: Function,
  closeDialog: () => void,
}
export default function FormPhone({adventureInfo, activityInfo, provider, reloadBookingRequests, closeDialog}: FormPhoneProps) {
  // context
  const BRC = useContext(BookingRequestContext);
  
  // mount
  useEffect(() => {
    const load = async() => {
      await BRC.load(adventureInfo, activityInfo, provider); 
    }
    load();
  }, [provider]);

  // save
  const save = async() => {
    await BRC!.save("phone", null);
    reloadBookingRequests();
    closeDialog();
  }

  // render loading
  if(!BRC.isLoaded) {
    return <Loader />;
  }

  // pre-render
  let billingType = null;
  let invoiceValue = null;
  if(BRC.paymentType === "monthly") {
    // the provider has monthly billing, no need to select a billing type
    billingType = <div>Monatsabrechnung</div>;
    // we need a value for the monthly invoice
    invoiceValue = (
      <div>
        <div className="title">Angaben auf Monatsabrechnung</div>
        <NumberInput label={`Betrag in ${provider.collectiveInvoice.currency}`} value={BRC.collectiveInvoice!.amount} onChange={amount => BRC.changeCollectiveInvoice({...BRC.collectiveInvoice!, amount})} validate={Validators.isPrice("den zu verrechnenden Betrag eingeben")} />
        <TextInput label="Bezeichnung" value={BRC.collectiveInvoice!.text} onChange={text => BRC.changeCollectiveInvoice({...BRC.collectiveInvoice!, text})} validate={Validators.isRequired("Bezeichnung des Rechnungspostens eingeben")} />
      </div>
    )
  }
  // render
  return (
    <>
      <div className="booking-form">
        <ServiceItems />
        {invoiceValue}
      </div>
      <Row align="center">
        <Button onClick={closeDialog} intent="cancel">abbrechen</Button>
        <Button disabled={!BRC.isValid} onClick={save}>speichern</Button>
      </Row>
    </>
  )
}