import React, {useContext, useState} from "react";
import {SurpriseContext} from "../Surprise.Context";
import {ConfirmButton, Loader} from "../../../controls";
import * as BL from "../../../../types/BusinessLogic";

export default function ShippingStatus() {
  // context
  const SC = useContext(SurpriseContext);

  // state
  const [busy, setBusy] = useState<boolean>(false);

  // event handler
  const markAsShipped = async() => {
    setBusy(true);
    await SC.markAsShipped();
    setBusy(false);
  }

  // render
  const status = BL.Shipping.getShippingStatus(SC.surprise);
  if(status.shipped) {
    return <div>{status.text}</div>
  }
  else {
    return (
      <div>
        {status.text}<br />
        {busy ? <Loader kind="dots" /> : <ConfirmButton busy={busy} onConfirm={markAsShipped}>als verschickt markieren</ConfirmButton>}
      </div>
    ) 
  }
}