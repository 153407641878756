import React, {useState, useEffect, useContext} from "react";
import Util from "../../../util/util";
import * as DataAccess from "../../../types/DataAccess";
import * as TS from "../../../types";
import { Breadcrumb, Card, Heading, List, ListColumn, Validators, Form, TextInput, Modal } from "../../controls";


const columns: ListColumn[] = [
  {header:"Ausrüstung", path:"title.de",print: (v:any) => v.title.de, sort: (a:any, b:any) => a.title.de.localeCompare(b.title.de)}
]

export default function EquipmentList(props:any) {
  // state
  const [routeId] = useState(Util.getRouteId(props));
  const [items, setItems] = useState<null|DataAccess.Equipment[]>(null);
  const [itemToEdit, setItemToEdit] = useState<DataAccess.Equipment|null>(null);

  // mount
  useEffect(() => {
    load();
  }, [])

  // mount with route id
  useEffect(() => {
    if(routeId) {
      startEdit(routeId);
    }
  }, [routeId]);

  // loads all items
  const load = async() => {
    const items = await DataAccess.EquipmentRepository.search({});
    console.log(items);
    setItems(items);
  }

  // onRowClick
  const startEdit = (equipment?: DataAccess.Equipment) => {
    if(!equipment) {
      equipment = DataAccess.EquipmentRepository.make();
    }
    setItemToEdit(equipment);
  }

  // closes modal
  const closeDialog = () => setItemToEdit(null);

  // render
  return <>
    <Breadcrumb 
      links={[ {title:'Home', to:'/'}]}
      location="Ausrüstung" 
    />
    <Heading>Ausrüstung</Heading>
    <Card>
      <List columns={columns} items={items} getKey={v => v._id} onRowClick={item => startEdit(item)} onClickCreate={() => startEdit()} />
    </Card>
    <Modal isOpen={itemToEdit !== null} title="Ausrüstung" onClose={closeDialog}>
      <Edit equipment={itemToEdit!} onUpdate={load} closeDialog={closeDialog} />
    </Modal>
  </>
}

type EditProps = {
  equipment:DataAccess.Equipment,
  onUpdate: () => void,
  closeDialog: VoidFunction
}
function Edit({equipment, onUpdate, closeDialog} : EditProps) {
  // // state
  // const [entity] = useState({
  //   title: equipment.title
  // });

  // form save
  const onSave = async(formResult:any) => {
    const changeset = formResult.changeset;
    if(equipment._id) {
      await DataAccess.EquipmentRepository.update(equipment._id, changeset);
    }
    else {
      const merged = formResult.merge(equipment);
      await DataAccess.EquipmentRepository.create(merged);
    }

    onUpdate();
    closeDialog();
  }

  // render
  return (
    <div>
      <Form entity={equipment} onSave={onSave} onCancel={closeDialog}>
        <TextInput label="Aurüstung" path="title.de" validate={Validators.isRequired("Bitte Bezeichnung der Ausrüstung angeben")} />
      </Form>
    </div>
  )
}

