import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
// types
import * as Cms from "../../../../types/Cms";
// contexts and components
import { PageContext } from "../Page.Context";
import { Actions, Card, Alert, TextInput, TextArea, Button, Form, Label, Paragraphs, Validators, Dropdown, DropdownOption, CloudStorageUploader, Icon, SortableList, SortableListItem } from "../../../controls";
import * as Selectors from "../Selectors";
import * as Preview from "../Preview";

// styling and images
import CSS from "./TestimonialCarousel.module.scss";

// rating options
const ratingOptions:Array<DropdownOption> = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5].map(n => { 
  return {value:n, label:`${n}`}
});

type TestimonialCarouselProps = {
  instance?: Cms.TestimonialCarouselModule,
  closeDialog: () => void,
}
export function TestimonialCarousel({instance, closeDialog}:TestimonialCarouselProps) {
  // context
  const PC = useContext(PageContext);
  
  // state
  const [mode, setMode] = useState<"properties"|"item">("properties");
  const [entity, setEntity] = useState<Cms.TestimonialCarouselModule|null>(null);
  const [itemToEdit, setItemToEdit] = useState<Cms.TestimonialCarouselModuleItem>(new Cms.TestimonialCarouselModuleItem());
  const [busy, setBusy] = useState<boolean>(false);
  const [sortingDisabled, setSortingDisabled] = useState<boolean>(false);

  // mount
  useEffect(() => {
    let entity: Cms.TestimonialCarouselModule;
    if(instance) {
      entity = instance;
    }
    else {
      entity = new Cms.TestimonialCarouselModule();
    }
    setEntity(entity)
  }, [])

  // user wants to cancel
  const onCancel = () => {
    closeDialog();
  }

  // user wants to save
  const onSave = async(formResult:any) => {
    setBusy(true);
    const updatedEntity = formResult.merge(entity);
    
    if(formResult.changeset.countries) {
      // TODO we need to do this because formResult.merge does not seem to handle arrays correctly
      updatedEntity.countries = formResult.changeset.countries;
    }

    await PC.upsertModule(updatedEntity);

    // close dialog
    closeDialog();
  }

  // user wants to save an item
  const onSaveItem = async(itemToSave:Cms.TestimonialCarouselModuleItem) => {
    if(entity) {
      // update or add the item
      const items = entity.elements.items.filter((item:Cms.TestimonialCarouselModuleItem) => String(item.id) !== String(itemToSave.id));
      items.push(itemToSave);
  
      // get updated version of the module
      const updatedModule = _.cloneDeep(entity);
      updatedModule!.elements.items = items;
      
      // save the module
      await PC.upsertModule(updatedModule);

      // get the updated module and set it as the entity
      setEntity(updatedModule);
    }
  }

  // user wants to delete item
  const onClickDelete = async(itemToDelete:Cms.TestimonialCarouselModuleItem) => {
    if(entity) {
      // update or add the item
      const items = entity.elements.items.filter((item:Cms.TestimonialCarouselModuleItem) => String(item.id) !== String(itemToDelete.id));

      // get updated version of the module
      const updatedModule = _.cloneDeep(entity);
      updatedModule!.elements.items = items;
      
      // save the module
      await PC.upsertModule(updatedModule);

      // get the updated module and set it as the entity
      setEntity(updatedModule);
    }
  }

  // user wants to add/update item
  const onClickEditItem = (item:Cms.TestimonialCarouselModuleItem) => {
    setItemToEdit(item);
    setMode("item");
  }

  // user wants to add an item
  const onClickAddItem = () => {
    const newItem = new Cms.TestimonialCarouselModuleItem();
    newItem.position = entity!.elements.items.length;
    setItemToEdit(newItem);
    setMode("item");
  }

  // user wants to rearrange
  const onDrop = async(id:string, targetIndex:number) => {
    setSortingDisabled(true);
    // get a copy of the items to work with
    const updatedItems = entity!.elements.items.map(item => item).sort((a,b) => a.position - b.position);

    // get source and target indices
    const indexFrom = updatedItems.findIndex(item => item.id === id);

    // remove element and move to new position
    const element = updatedItems.splice(indexFrom, 1)[0]; // splice returns the removed elements as an array, since we only remove one item, we can get it from index 0
    updatedItems.splice(targetIndex, 0, element);

    // update position property on items
    updatedItems.forEach((item, index) => item.position=index);

    // TODO NOT DRY this is to a great extent the same code as above in onSaveItem
    // get updated version of the module
    const updatedModule = _.cloneDeep(entity!);
    updatedModule!.elements.items = updatedItems;
    
    // save the module
    await PC.upsertModule(updatedModule);
    setSortingDisabled(false);

    // get the updated module and set it as the entity
    setEntity(updatedModule);

  }

  // entity not set yet? render nothing
  if(!entity) {
    return null;
  }

  if(mode === "properties") {
    return (
      <Properties 
        entity={entity}
        onCancel={onCancel}
        onClickAddItem={onClickAddItem}
        onClickDelete={onClickDelete}
        onClickEditItem={onClickEditItem}
        onDrop={onDrop}
        onSave={onSave}
        busy={busy}
        sortingDisabled={sortingDisabled}
      />
    )
  }
  else {
    return (
      <EditItem 
        item={itemToEdit} 
        onSaveItem={onSaveItem} 
        closeDialog={() => setMode("properties")} 
      />
    )
  }
}


type PropertiesProps = {
  entity: Cms.TestimonialCarouselModule,
  onCancel: () => void,
  onSave: (formData:any) => void,
  onClickAddItem: () => void,
  onClickEditItem: (item:Cms.TestimonialCarouselModuleItem) => void,
  onClickDelete: (item:Cms.TestimonialCarouselModuleItem) => void,
  onDrop: (id:string, targetIndex:number) => void,
  busy: boolean,
  sortingDisabled: boolean,
}
function Properties({entity, busy, sortingDisabled, onCancel, onSave, onClickEditItem, onClickAddItem, onClickDelete, onDrop} : PropertiesProps) {
  // pre-render
  let listAction = null;
  let list = null;
  if(entity._id) {
    // action
    listAction = (
      <div>
        <Button size="small" onClick={onClickAddItem}>Eintrag hinzufügen</Button>
      </div>
    )
    // list
    const items = entity.elements.items
      .sort((a,b) => a.position - b.position)
      .map((item:Cms.TestimonialCarouselModuleItem, index:number) => {
        return (
          <SortableListItem id={item.id} key={item.id}>
            <Card title={item.who} size="small">
              <ItemPreview item={item} />
              <Actions size="small" onEdit={() => onClickEditItem(item)} onDelete={() => onClickDelete(item)} />
            </Card>
          </SortableListItem>
        )
      });
    list = (
      <SortableList onDrop={onDrop} disabled={sortingDisabled} noItemsText="noch keine Einträge">
        {items}
      </SortableList>
    )
  }
  else {
    listAction = <Alert size="small" intent="info" title="Speichern notwendig">Bevor Einträge hinzugefügt werden können, muss dieses Modul erst gespeichert werden</Alert>
  }

  // render
  return (
  <Form entity={entity} onCancel={onCancel} onSave={onSave} busy={false}>
    <Selectors.BaseProperties busy={busy} />

    <TextInput label="Titel" path="elements.title" placeholder="Titel eingeben" disabled={busy} />
    <TextInput label="Text" path="elements.text" placeholder="Text eingeben" disabled={busy} />
    
    <Label label="Einträge" />

    {list}
    {listAction}
  </Form>
  )
}

type EditItemProps = {
  item: Cms.TestimonialCarouselModuleItem,
  onSaveItem:(itemToSave:Cms.TestimonialCarouselModuleItem) => void,
  closeDialog: () => void,
}
function EditItem({item, onSaveItem, closeDialog} : EditItemProps) {
  // state
  const [entity, setEntity] = useState<Cms.TestimonialCarouselModuleItem>(item);
  const [busy, setBusy] = useState<boolean>(false);

  // user wants to cancel
  const onCancel = () => {
    closeDialog();
  }

  // user wants to save
  const onSave = async(formResult:any) => {
    setBusy(true);
    const updatedEntity = formResult.merge(entity);
    await onSaveItem(updatedEntity);
    closeDialog();
  }
  
  // render
  
  return (
    <Form entity={entity} onCancel={onCancel} onSave={onSave} busy={false}>
      <TextInput label="Wer" path="who" placeholder="Trude, Buxdehude" disabled={busy} validate={Validators.isRequired("Name und Ort eingeben")} />
      <TextInput label="Was" path="what" placeholder="Schiessbuden-Lotto" disabled={busy} validate={Validators.isRequired("Aktivität(en) eingeben")} />
      <Dropdown label="Rating" path="rating" options={ratingOptions} />
      <TextArea label="Text" path="text" placeholder="Mein Lieblingstier ist die Bratwurst, und die grünsten gibts bei Appentura!" disabled={busy} validate={Validators.isRequired("Bewertungstext eingeben")} />
      <CloudStorageUploader kind="image" label="Bild" folder="cms" path="imageUrl" disableUrlEditing={true} prefix="cms" />
    </Form>
  )
}

type TestimonialCarouselPreviewType = {
  instance: Cms.TestimonialCarouselModule,
  expanded?: boolean,
  onClickAction: (module:Cms.Module, action:"edit"|"delete"|"duplicate") => void
}
export function TestimonialCarouselPreview({instance, expanded, onClickAction}:TestimonialCarouselPreviewType) {
  const items = instance.elements.items.sort((a, b) => a.position - b.position).map((item:Cms.TestimonialCarouselModuleItem) => {
    return <ItemPreview key={item.id} item={item} />
  })

  return (
    <Preview.Container expanded={expanded} module={instance} title={Cms.TestimonialCarouselModule.info.title} onClickAction={onClickAction}>
      <Preview.Table>
        <Preview.TableRow label="Titel">{instance.elements.title || "-"}</Preview.TableRow>
        <Preview.TableRow label="Text">{instance.elements.text || "-"}</Preview.TableRow>
        <Preview.TableRow label="Einträge">
          {items}
        </Preview.TableRow>
      </Preview.Table>
    </Preview.Container>
  )
}

function ItemPreview({item} : {item: Cms.TestimonialCarouselModuleItem}) {
  const stars = [1,2,3,4,5].map(rating => {
    if(item.rating >= rating) {
      return <Icon key={rating} icon={["fas", "star"]} />
    }
  })
  return (
    <div className={CSS.preview_item}>
      <div className={CSS.left}>
        <div className={CSS.image}>
          <img src={item.imageUrl} />
        </div>
      </div>
        <div className={CSS.right}>
          <div className={CSS.who}>{item.who}</div>
          <div className={CSS.what}>{item.what}</div>
          <div className={CSS.rating}>{stars}</div>
          <Paragraphs text={item.text} className={CSS.text} />
      </div>
    </div>
  )
}