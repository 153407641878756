import React from "react";
import * as DA from "../../../../types/DataAccess";
import AssistantLogic from "./Assistant.Logic";

import { Icon, Link } from "../../../controls";

type HeaderProps = {
  provider: DA.Provider,
}
export default function Header({provider} : HeaderProps) {
  const fixUrl = (url:string) => {
    if(url.toLowerCase().startsWith("http"))
      return url
    else
      return `http://${url}`
  }

  let booking_href;
  let booking_kind;
  const bookingInfo = provider.booking[0];
  
  switch (bookingInfo.kind) {
    case "web":
      booking_href = fixUrl(bookingInfo.value);
      booking_kind = "Buchung via Buchungssystem";
      break
    case "phone":
      booking_href = `tel:${bookingInfo.value}`;
      booking_kind = "Telefonische Buchung";
      break
    case "email":
    default:
      booking_href = `mailto:${bookingInfo.value}`
      booking_kind = "Buchung via E-Mail";
      break
  }

  return (
    <div className="booking-kind">
      <Icon icon={AssistantLogic.getBookingKindIcon(bookingInfo.kind)} />
      <span>{booking_kind}:</span>
      <Link to={booking_href} target="_blank">
        {bookingInfo.value}
      </Link>
    </div>
  )
}