import React, { useState, useEffect } from "react";
import Util from "../../../util/util";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";
import { Breadcrumb, Card, Heading, List, ListColumn, Validators, Form, TextInput, Modal } from "../../controls";


const columns: ListColumn[] = [
  {header:"Name", path:"name"}
]

export default function MarketingChannelList(props:any) {
  // state
  const [items, setItems] = useState<null|DA.MarketingChannel[]>(null);
  const [itemToEdit, setItemToEdit] = useState<DA.MarketingChannel|null>(null);
  const [routeId] = useState(Util.getRouteId(props));

  // mount
  useEffect(() => {
    load();
  }, [])

  // mount with route id
  useEffect(() => {
    if(routeId) {
      startEdit(routeId);
    }
  }, [routeId]);

  // loads all items
  const load = async() => {
    const items = await DA.MarketingChannelRepository.findAll();
    setItems(items);
    setItemToEdit(null);
  }

  // onRowClick
  const startEdit = (marketingChannel?: DA.MarketingChannel) => {
    if(!marketingChannel) {
      marketingChannel = DA.MarketingChannelRepository.make();
    }
    setItemToEdit(marketingChannel);
  }

  // stops edit
  const stopEdit = () => setItemToEdit(null);


  // render
  return <>
    <Breadcrumb 
      links={[ {title:'Home', to:'/'}]}
      location="Marketingkanäle" 
    />
    <Heading>Marketingkanäle</Heading>
    <Card>
      <List columns={columns} items={items} getKey={v => v._id} onRowClick={item => startEdit(item)} onClickCreate={() => startEdit()} />
    </Card>
    <Modal
      isOpen={itemToEdit !== null}
      title="Marketingkanal"
      onClose={stopEdit}
    >
      <Edit marketingChannel={itemToEdit!} onUpdate={load} onCancel={stopEdit} />
    </Modal>
  </>
}

type EditProps = {
  marketingChannel:DA.MarketingChannel,
  onUpdate: () => void,
  onCancel: () => void,
}
function Edit({marketingChannel, onUpdate, onCancel} : EditProps) {
  // state
  const [entity] = useState({
    name: marketingChannel.name
  });

  // form save
  const onSave = async(formResult:any) => {
    const changeset = formResult.changeset;
    if(marketingChannel._id) {
      await DA.MarketingChannelRepository.update(marketingChannel._id, changeset);
    }
    else {
      const merged = formResult.merge(marketingChannel);
      await DA.MarketingChannelRepository.create(merged);
    }

    onUpdate();
  }

  // render
  return (
    <div>
      <Form entity={entity} onSave={onSave} onCancel={onCancel}>
        <TextInput label="Name" path="name" validate={Validators.isRequired("Bitte Name des Marketingkanals angeben")} />
      </Form>
    </div>
  )
}


