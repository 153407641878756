import React, { useState, useEffect } from "react";
import _ from "lodash";
import Api from "../../../util/api2";
import { Form, TextArea } from "../../controls"

function Check({onSave, data, closeDialog} : any) {
  // state
  const [entity, setEntity] = useState(null);

  useEffect(() => {
    setEntity(_.clone(data));
  }, [data]);

  const onFormCancel = () => {
    closeDialog();
  }

  const onFormSave = async(formData:any) => {
    let data:any = {};
    _.merge(data, entity);
    _.merge(data, formData.changeset);
    const text = `Meteocheck Bemerkung zu ${data.activityTitle}. Notiz: ${data.text}`;
    const payload = { adventureId: data.adventureId, text};
    await Api.post("meteocheck", "addLogEntry", payload); 
    onSave();
    closeDialog();
  }

  // render
  return (
    <Form entity={entity} onSave={onFormSave} onCancel={onFormCancel} labelSave="speichern" labelCancel="abbrechen">
      <TextArea path="text" label="Text" />
    </Form>
  )
}

export default Check;