import React, {useState} from "react";
import { Card, Heading, ClipboardButton, Link, TextInput } from "../../controls";

/**
 * Lets the user create links to the shop that pre-populate the search depending on tag
 */
export default function ShopLinkCreator() {
  // state
  const [tags, setTags] = useState<string>("");
  const [title, setTitle] = useState<string>("");

  /**
   * Tag changed
   * @param {string} v
   */
  const onTagsChanged = (v:string) => setTags(v);

  /**
   * Title changed
   * @param {string} v
   */
  const onTitleChanged = (v:string) => setTitle(v);

  // render
  const link = `https://appentura.ch/shop/?t=${tags}&tt=${title}`;
  return <>
    <Heading>Shop Link Creator</Heading>
    <Card id="v-shoplinkcreator">
      <TextInput label="Tag(s)" value={tags} onChange={onTagsChanged} placeholder="z.B. wellness oder wellness,männer" />
      <TextInput label="Titel" value={title} onChange={onTitleChanged} placeholder="Wellness Erlebnisse für Männer" />
      <Link to={link} target="_blank">{link}</Link>
      <ClipboardButton text={link} size="small">kopieren</ClipboardButton>
    </Card>
  </>
}