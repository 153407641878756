import React, { useContext, useState, useEffect } from "react";
import * as TS from "../../../../../types";
import { Loader, Dropdown, DropdownOption } from "../../../../controls";
import { SearchContext } from "../Search.Context";
import FilterGroup from "../Search.FilterGroup";

export default function SoldOffline() {
  // context
  const Search = useContext(SearchContext);

  // state
  const [options] = useState<DropdownOption[]>([
    {label:"-", value: ""},
    {label:"keine vorgefertigte", value:"excluded"},
    {label:"nur vorgefertigte", value:"only"},
    {label:"nur vorgefertigte nicht verkaufte", value:"onlyNotSold"}
  ]);

  // onChange
  const onDropdownChange = (v:string) => {
    const updatedFilter = Search.filter.clone();
    updatedFilter.soldOffline = v as ""|"excluded"|"only"|"onlyNotSold";
    Search.reset(updatedFilter);
  }

  // render
  return (
    <FilterGroup title="Vorgefertigte">
      <Dropdown options={options} onChange={onDropdownChange} value={Search.filter.soldOffline} />
    </FilterGroup>
  )
}