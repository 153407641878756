import { useContext } from "react";
import { Util } from "../../../../util";
import { Button, Card, ConfirmButton, Link, Row, Table, Td, Tr } from "../../../controls";
import { SearchContext } from "./Search.Context";
import CSS from "./Search.module.scss";

export default function SearchResult() {
  // context
  const Search = useContext(SearchContext);

  // pre-render header
  const headerColumns = Search.columns.map((rc, index) => {
    return <Td key={index} className={CSS.header}>{rc.header}</Td>
  })
  const header = <Tr key="header">{headerColumns}</Tr>

  // pre-render items
  const items: any[] = Search.loadedItems.map(item => {
    const tds = Search.columns.map((rc, index) => {
      const content = rc.print(item);
      return <LinkedTd id={item._id} key={rc.key}>{content}</LinkedTd>
    });
    return (
      <Tr key={item._id}>
        {tds}
      </Tr>
    )
  });
  if(items.length === 0) {
    items.push(<Tr key="no_result"><Td colSpan={Search.columns.length}>keine Daten gefunden oder keine Suche ausgeführt</Td></Tr>)
  }

  // render paging?
  let paging = null;
  if(Search.loadedItems.length > 0) {
    let buttonAll = null;
    let buttonMore = null;
    if(Search.loadedItems.length < Search.count) {
      buttonAll = <ConfirmButton onConfirm={() => Search.page("all")} size="medium" inverted busy={Search.isBusy}>alle anzeigen</ConfirmButton>
      buttonMore = <Button onClick={() => Search.page("next")} busy={Search.isBusy}>mehr</Button>;
    }
    if(buttonMore || buttonAll) {
      paging = (
        <Row align="center">
          {buttonAll}
          {buttonMore}
        </Row>
      )
    }
  }
  // render
  return (
    <Card className={CSS.searchResult}>
      <Table>
        {header}
        {items}
      </Table>
      <div className={CSS.searchResult_counts}>
        <Row align="center">{Search.loadedItems.length} von {Search.count}</Row>
      </div>
      {paging}
      <br />
    </Card>
  )
}

type LinkedTdProps = {
  id:string,
  children:React.ReactNode|React.ReactNode[]
}
function LinkedTd({id, children}: LinkedTdProps) {
  return <Td><Link to={`/surprise/${id}`} target="_blank">{children}</Link></Td>;
}