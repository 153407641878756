import React, {useContext} from "react";
import Util from "../../../../../util/util";
import { SurpriseContext } from "../../Surprise.Context";
import { Activities, ActivityPackages, Donee, Review, Row, StartLocation, Text, TransportRow } from "../Items";
import { Table } from "../../../../controls";

export default function Reviewed() {
  // context 
  const SC = useContext(SurpriseContext);
  
  // render
  const s = SC.surprise;
  return (
    <>
      <Table fontSize="small">
        <Donee />
        <Row label="Start">{Util.printDateAndTime(s.StartTime || s.UserStartTime)}</Row>
        <StartLocation />
        <TransportRow />
        <Activities />
        <ActivityPackages />
        <Text label="Notizen" path="surprise.Notes" placeholder="-" />
      </Table>
      <Review />
    </>
  );
}


