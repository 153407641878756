import { Module, ModuleInfo, Theme } from ".";
export class NewsletterModule implements Module {
  readonly code: string;

  _id?: string;
  countries: string[];
  position: number;
  theme: Theme;
  anchor?: string;

  elements: {
    tag?: string;
  }

  constructor() {
    this.code = NewsletterModule.info.code;
    this.countries = ["CH", "DE"];
    this.position = 0;
    this.theme = "primary";
    this.elements = {
      tag: "",
    }
  }

  static get info(): ModuleInfo {
    return {
      code: "newsletter",
      title: "Newsletter-Modul",
      description: "Newsletter-Module für signup bei Mailchimp"
    }
  }
}