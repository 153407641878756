import React from "react";
import Code from "../Code";
import Notes from "../Notes";
import {Card,  Heading, Paragraphs} from "../../../controls";

export default function ParagraphsDemo() {
  const myText = "first line\nsecond line";
  return (
    <Card>
      <Heading>Paragraphs</Heading>
      <Notes>
        Transforms a string with linebreaks ('\n') into an array of paragraphs
      </Notes>
      
      <Code>
        {
`const myText = "First line\\nsecond line";
...
<Paragraphs text={myText} />
`
        }
      </Code>
      <Paragraphs text={myText} />
      
    </Card>
  )
}
