import React, { useState, useEffect } from "react";
import _ from "lodash";
import Api from "../../../util/api2";
import { Form, TextArea } from "../../controls"


function Check({onSave, data, closeDialog} : any) {
  // state
  const [entity, setEntity] = useState(null);

  // mount (or data changed)
  useEffect(() => {
    setEntity(_.clone(data));
  }, [data]);

  // user aborts
  const onFormCancel = () => {
    closeDialog();
  }

  // user wants to save
  const onFormSave = async(formData:any) => {
    let payload:any = {};
    _.merge(payload, entity);
    _.merge(payload, formData.changeset);
    delete payload.providerName;
    await Api.post("meteocheck", "setMeteoCheck", payload); 
    onSave();
    closeDialog();
  }

  // render
  return (
    <Form entity={entity} onSave={onFormSave} onCancel={onFormCancel} labelSave="speichern" labelCancel="abbrechen">
      <TextArea path="notes" label="Bemerkungen" />
    </Form>
  )
}

export default Check;