// types
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";
import * as BL from "../../../types/BusinessLogic";
import { FormData } from "./VendorInvoice";
// controls
import { Td, Tr } from "../../controls";
// functions
import { validateProviderAndAccount } from "./VendorInvoice";
// subviews
import ProviderAndBankAccount from "./VendorInvoice.ProviderAndBankAccount";
import ProviderBankAccount from "./VendorInvoice.ProviderBankAccount";
// styling
import CSS from  './VendorInvoice.module.scss';
import { ProviderLink } from "../../common";


type ProviderProps = {
  // TODO we could do without invoice param ... all info needed is in formData
  invoice: TS.VendorInvoice|null,
  paymentKind?: DA.PaymentTypeKind,
  formData: FormData,
  disabled?: boolean,
  onChange:(provider:DA.Provider|null, bankAccount:DA.ProviderBankAccount|null, billingAddress:DA.ProviderAddress|null) => void,
}
export default function Provider({invoice, paymentKind, formData, disabled=false, onChange}:ProviderProps) {
  /**
   * Provider can only be selected 
   * - before an invoice is created.
   * The provider's selected bank account can be selected if
   * - payment via bank was selected (i.e. not if we paid via creditcard or employee advance payment)
   * - the vendor invoice is not yet in "Zahlungsausgang"
   * - the vendor invoice is not yet paid
   */
  const isBankingInfoRequired = paymentKind !== 'creditcard' && paymentKind !== 'employee';
  let content = null;
  
  if(formData.isUpdate === false) {
    // create a new invoice: let user select provider
    content = (
      <ProviderAndBankAccount 
        onChange={onChange} 
        formData={formData}
        isBankingInfoRequired={isBankingInfoRequired} 
        validate={validateProviderAndAccount}
        disabled={disabled}
      />
    ) 
  }
  else {
    let content_bankAccount = null;
    if(formData.isReadOnly) {
      const bankAccount = formData.providerBankAccount;
      const bankAccountDescription = bankAccount ? `Bankverbindung: ${bankAccount.bankName} ${bankAccount.accountNumber}` : "keine Bankverbindung";
      content_bankAccount = (
        <div className={CSS.secondary}>
          {bankAccountDescription}
        </div>
      );
    }
    else {
      content_bankAccount = (
        <ProviderBankAccount  
          provider={formData.provider!} 
          accountId={formData.providerBankAccount ? formData.providerBankAccount._id! : ""}
          onChange={(bankAccount:DA.ProviderBankAccount) => {
            onChange(formData.provider!, bankAccount, formData.providerBillingAddress)}
          }
          disabled={disabled}
        />
      )
    }
    // update an existing invoice: user can only change the bank account
    content = <>
      <div>
        <ProviderLink provider={{_id:invoice!.provider._id, name:invoice!.provider.name} as DA.Provider} />
      </div>
      <div className={CSS.secondary}>{invoice!.provider.billingAddress ? `Rechnungsadresse: ${BL.Provider.printAddress(invoice!.provider.billingAddress)}` : "keine Rechnungsadresse"}</div>
      {content_bankAccount}
    </>
  }

  
  return (
    <Tr>
      <Td label>Anbieter</Td>
      <Td>
        {content}
      </Td>
    </Tr>
  )
}