import Axios from "axios";

const config = {
  //generic_access_token: "5674deb1fe055ad0e2c3d2ec3f02f8f490de2b59" // teamappentura@gmail.com
  generic_access_token: "f8b2b476abc1def86b185489f23147b614efca10" // martin@appentura.ch
}

async function createShortUrl(url) {
  return new Promise((resolve, reject) => {
    // headers
    const headers = {
      'Authorization': `Bearer ${config.generic_access_token}`,
      'Content-Type': 'application/json'
    };
    // data we post
    const data = {
      long_url:url,
      // domain: "bit.ly", 
      // group_guid: "xxxxxxx"
    }

    // create request
    let validatedStatus
    let request = Axios({
      url: 'https://api-ssl.bitly.com/v4/shorten',
      method: "POST",
      data,
      headers: headers,
      validateStatus: function(status) {
        validatedStatus = status
        return ((status >= 200 && status < 300) || status === 404) // || (status >= 400 && status < 500) //)
      }
    })

    // execute request
    request
      .then((result) => {
        switch(validatedStatus) {
          case 404:
            reject(result)
            reject({
              success:false,
              error:{message:"unknown", data:result}
            })
            break;
          default:
            // note: data is what axios delivers ... it should be structured : {success:<bool>, data:<obj>}
            resolve(result.data)
            break
        }
      })
      .catch(err => {
        reject(err)
      })  
  })
    
}

export default {
  createShortUrl
}
