import React from 'react';

// subviews
import InfoHeader from "./InfoHeader";
import Cache from "./Cache";
import ShopLinkCreator from "./ShopLinkCreator";

// controls
import { Breadcrumb } from "../../controls";

// the exported component
export default function Shop() {
  return (
    <div id="aat-v-shop">
      <Breadcrumb
        links={[{title:'Home', to:'/'}]}
        location='Shop'
      />
      <Cache/>
      <ShopLinkCreator />
      <InfoHeader country="ch" />
      <InfoHeader country="de" />
    </div>
  )
}