import { BookingRequest, BookingRequestActivity, BookingRequestProvider, BookingRequestReminder, BookingRequestRequest, BookingRequestService } from "./BookingRequest";
import { Mapper } from "../_Common/Mapper";

export class BookingRequestMapper extends Mapper<BookingRequest> {
  public apiEndpoint = "bookingrequests";

  public toDb(bookingRequest: BookingRequest): any {
    // filter empty services
    const services = bookingRequest.services.filter(s => {
      return (s.key.trim().length > 0 && s.value.trim().length > 0);
    })
    return {
      _id: bookingRequest._id,
      public_id: bookingRequest.public_id,
      adventure_id: bookingRequest.adventure_id,
      adventure_date: bookingRequest.adventure_date,
      activity: bookingRequest.activity,
      provider: bookingRequest.provider,
      services: services,
      request: bookingRequest.request,
      response: bookingRequest.response,
      reminders: bookingRequest.reminders,
      collectiveInvoice: bookingRequest.collectiveInvoice,
    }
  }

  public fromDb(obj:any): BookingRequest {
    const adventure_id = obj.adventure_id;
    const adventure_date = new Date(obj.adventure_date);
    const activity: BookingRequestActivity = {
      id: obj.activity.id,
      subactivity_id: obj.activity.subactivity_id,
      subactivity_code: obj.activity.subactivity_code,
      name: obj.activity.name,
      regionCode: obj.activity.regionCode,
      date: obj.activity.date ? new Date(obj.activity.date) : undefined
    }
    const provider: BookingRequestProvider = {
      id: obj.provider.id,
      name: obj.provider.name
    }
    const request: BookingRequestRequest = {
      createdOn: new Date(obj.request.createdOn),
      createdBy: obj.request.createdBy,
      kind: obj.request.kind,
      comment: obj.request.comment || "",
      email: obj.request.email ? {
        to:obj.request.email.to, 
        from: obj.request.email.from, 
        subject:obj.request.email.subject,
        body: obj.request.email.body
      } : undefined
    }
    const services: BookingRequestService[] = obj.services.map((s:any) => {
      return { key: s.key, value: s.value }
    })
    const reminders: BookingRequestReminder[] = (obj.reminders || []).map((r:any) => {
      return {
        createdOn: new Date(r.createdOn),
        createdBy: r.createdBy,
        email: {
          to: r.email.to,
          from: r.email.from,
          subject: r.email.subject,
          body: r.email.body
        }
      }
    });

    const bookingRequest: BookingRequest = {
      _id: obj._id,
      public_id: obj.public_id,
      adventure_id: adventure_id,
      adventure_date: adventure_date,
      activity: activity,
      provider: provider,
      request: request,
      services: services,
      reminders: reminders,
      response: null,
      collectiveInvoice: null,
    }

    if(obj.response) {
      bookingRequest.response = {
        receivedOn: new Date(obj.response.receivedOn),
        operator: obj.response.operator,
        accepted: obj.response.accepted,
        contact: obj.response.contact || "",
        comment: obj.response.comment || ""
      }
    }
    
    if(obj.collectiveInvoice) {
      bookingRequest.collectiveInvoice = {
        amount: obj.collectiveInvoice.amount,
        text: obj.collectiveInvoice.text,
        collectiveInvoice_id: obj.collectiveInvoice.collectiveInvoice_id || null,
      }
    }

    return bookingRequest;
  }
}