import { Activity, ActivityRegionVariant, ActivityRegionVariantPriceConfiguration } from ".";

export class AdventureActivity {
  // IDs
  ActivityId: string;
  RegionVariantId: string;
  RegionCode: string;
  ActivityPackageId: string|null = null;

  // Texts
  Title: string;
  ShortDescription: string;
  LongDescription: string;
  AvailableTimes: string|null = null; // notes about availability in terms of weekday and time of the day (e.g. only available on Sundays), as presented when sold. May be ""
  AvailableAreas: string|null = null; // notes about availability in terms of area within the selected region (e.g. "nur im Kreis 5", "inneralb PLZ 3097, 3098"), as presented when sold. May be ""

  // Flags
  IsHomeDelivery: boolean = false; // default false
  IsOnlineEvent: boolean = false; // default false
        
  // Properties
  Benefits:string[] = [];
  Equipment:string[] = [];
  Conditions:string[] = [];
  WeatherConditions:string[] = [];

  // Configuration, i.e. number of participants, price for this configuration (see Activity Model PriceConfigurations)
  Configuration: {ParticipantCount:number, Price:number} = {
    ParticipantCount: 0,
    Price: 0
  };

  // Bought Options
  Options: Array<{
    Count: number;
    UnitPrice: number;
    Title: string;
    Description: string;
  }> = [];

  // Bought Subactivities
  Subactivities: Array<{
    ActivityId: string;
    Title: string;
    ParticipantCount: number;
    Price: number;
    IsOption: boolean; // default false - the subactivity was marked as isOptional and was added by the user, it contributes to the price if set to true
    IsOptionReplacement: boolean // default - the subactivity was used to replace an option in an adventure that was created before optional subactivities existed
  }> = [];
  
  // the calculated price (i.e. configuration price + options)
  Price: number = 0;

  constructor(activityId:string, regionCode:string, regionVariantId:string, title: string, shortDescription: string, longDescription: string) {
    this.ActivityId = activityId;
    this.RegionCode = regionCode;
    this.RegionVariantId = regionVariantId;
    this.Title = title;
    this.ShortDescription = shortDescription;
    this.LongDescription = longDescription;
  }
  
  public toDb() : any {
    // lazy implementation
    return {...this};
  }

  public static fromDb(obj:any) {
    return obj as AdventureActivity; // TODO lazy implementation assuming all values are given
  }

  public static async fromActivity(activity: Activity, regionCode:string, participantCount:number): Promise<AdventureActivity[]|never> { //{adventureActivity:AdventureActivity|null, error:string|null} {
    // assume the activity has no subactivity
    let activities: Activity[] = [activity];

    // if the activity has subactivies we get those
    if(activity.subactivities.length > 0) {
      const activityIds = activity.subactivities.map(item => item.activity_id);
      activities = await Activity.search({_id:{$in:activityIds}});
    }
    
    // iterate through all activities to create AdventureActivities
    const adventureActivities = activities.map(activity => {
      // get region variant
      const regionVariant:ActivityRegionVariant|undefined = activity.regionVariants.find(rv => rv.regionCode === regionCode);
      // no region variant for this regionCode? no soup for you!
      if(!regionVariant) {
        throw new Error(`There is no region variant available for region '${regionCode}'`);
      }

      // get price configuration
      const priceConfiguration:ActivityRegionVariantPriceConfiguration|undefined = regionVariant.priceConfigurations.find(pc => pc.participantCount === participantCount);
      // no pc for this participantCount? no goulash for you!
      if(!priceConfiguration) {
        throw new Error(`There is no price configuration available for '${participantCount}' participants`);
      }

      // get texts
      let title: string = activity.title.de;
      if(title.trim().length === 0) {
        title = "Aktivität ohne Namen";
      }
      let shortDescription: string = activity.shortDescription.de;
      if(shortDescription.trim().length === 0) {
        shortDescription = title;
      }
      let longDescription: string = activity.longDescription.de;
      if(longDescription.trim().length === 0) {
        longDescription = title;
      }

      const aa = new AdventureActivity(activity._id, regionCode, regionVariant._id!, title, shortDescription, longDescription);
      aa.ActivityPackageId = null;
      aa.AvailableAreas = regionVariant.availableAreas;
      aa.AvailableTimes = activity.availableTimes;
      aa.Benefits = regionVariant.benefits.map(b => b.de);
      aa.Conditions = regionVariant.conditions.map(c => c.title.de);
      aa.Configuration = {
        ParticipantCount: priceConfiguration.participantCount,
        Price: priceConfiguration.price
      }
      aa.Equipment = regionVariant.equipment.map(e => e.title.de);
      aa.IsHomeDelivery = activity.isHomeDelivery;
      aa.IsOnlineEvent = activity.isOnlineEvent;
      aa.Options = []; // TODO not yet supported 
      aa.Price = priceConfiguration.price;
      aa.Subactivities = []; // TODO not yet supported
      aa.WeatherConditions = regionVariant.weatherConditions.map(w => w.title.de);

      // done mapping
      return aa;
    });
    
    // done
    return adventureActivities;
  }

}