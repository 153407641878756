import React, { useState } from "react";
import Code from "../Code";
import { Card, Heading, Form, Checkbox } from "../../../controls";

export default function TextInputDemo() {
  const [isActive, setIsActive] = useState<boolean>(true);
  const [data, setData] = useState<any>();

  const [formData, setFormData] = useState<string>("");
  


  // form save
  const onSave = (formData:any) => {
    setFormData(JSON.stringify(formData))
  }

  // change handler
  const onChange = (checked:boolean, data:any) => {
    setIsActive(checked);
    setData(data);
  }

  // render
  return <>
    <Heading>Checkbox</Heading>
    
    <Card title="In Form">
      <Form busy={false} entity={{isActive:true}} onSave={onSave}>
        <Checkbox path="name" label="Name" />
      </Form>
      <div>
        <div>formData:</div>
        <Code>
          {formData}
        </Code>
      </div>
    </Card>

    <Card title="Without Form">
      <Checkbox label="Is Active" value={isActive} onChange={(v:boolean) => setIsActive(v)} />
      <div>is active: {isActive}</div>
      <div>data: {data}</div>
    </Card>

    <Card title="Data parameter">
    <Checkbox label="Is Active" value={isActive} onChange={onChange} data="has data" />
      <div>is active: {isActive}</div>
      <div>data: {data}</div>
    </Card>

    <Card title="Explanation">
      <Checkbox 
        label="this is the label" 
        explanation="This is the explanation" 
        value={isActive} onChange={(v:boolean) => setIsActive(v)} 
      />
    </Card>

    <Card title="Secondary Label">
      <Checkbox 
        secondaryLabel="this is the label" 
        value={isActive} onChange={(v:boolean) => setIsActive(v)} 
      />
    </Card>

    <Card title="Incomplete Implementation">
      <Checkbox label="Something Missing" />
    </Card>

  </>
}
