import { ProviderLocationMapper } from "./ProviderLocation.Mapper";
import { ProviderLocation } from "./ProviderLocation";
import { RepositoryFactory } from "../_Common/RepositoryFactory";

export class ProviderLocationRepository {
  private static mapper = new ProviderLocationMapper();
  
  // from factory
  static make = RepositoryFactory.make<ProviderLocation, ProviderLocationMapper>(this.mapper);
  static search = RepositoryFactory.search<ProviderLocation, ProviderLocationMapper>(this.mapper);
  static create = RepositoryFactory.create<ProviderLocation, ProviderLocationMapper>(this.mapper);
  static update = RepositoryFactory.update<ProviderLocation, ProviderLocationMapper>(this.mapper);
  static remove = RepositoryFactory.remove<ProviderLocation, ProviderLocationMapper>(this.mapper);
  static findById = RepositoryFactory.findById<ProviderLocation, ProviderLocationMapper>(this.mapper);

  /**
   * returns all locations for a given provider
   * @param providerId 
   * @returns {Promise<ProviderLocation[]>}
   */
  static findByProviderId = async(providerId:string) : Promise<ProviderLocation[]> => {
    const locations = await this.search({providerId:providerId});
    return locations;
  } 

  static findByProviderAndActivity = async(providerId:string, activityId:string, regionVariantId:string) : Promise<ProviderLocation[]> => {
    const filter:any = {
      providerId:providerId,
      activityId:activityId,
      regionVariantId:regionVariantId
    };
    const locations = await this.search(filter);
    return locations;
  }
}

