import _ from "lodash";
import { Adventure } from "../Adventure";
import Util from "../../util/util";
import { ResultItem } from "./ResultItem";

export type ResultColumnKey = "id"|"shortId"|"HasPriority"|"Status"|"IsTest"|"CreateDate"|"UserStartTime"|"StartTime"|"ScheduleDate"|"Order.Comments"|"Operator"|"ReserverName"|"RecieverName"|"ActivityTitles"|"PaymentReminderLevel"|"Order.Packaging.Code"|"LiveId"|"RegionCode"|"UserStartLocationText"|"TransportOptions"|"Rating.Rating"|"Rating.Rating2"|"Rating.Rating3"|"RecieverEmail"|"ReserverEmail"|"IsMe"|"Activities.IsOnlineEvent"|"Activities.IsHomeDelivery"|"Order.Prepaid.InWarehouse";
export type ResultColumn = {
  key: ResultColumnKey,
  header: string,
  sortField?: string,
  print: (v:any) => any, 
}

export class ResultColumnHelper {
  public static getColumnsByKeys = (keys:ResultColumnKey[]) : ResultColumn[] => {
    const columns: ResultColumn[] = [];
    keys.forEach(field => {
      const col = ResultColumns.find(rc => rc.key === field);
      if(col) {
        columns.push(col);
      }
    })
    return columns;
  }
}

export const ResultColumns:ResultColumn[] = [
  {
    key:'id', 
    header:'Id', 
    print:(item:ResultItem) => item._id
  },
  {
    key:'shortId', 
    header:'Kurz-Id', 
    print:(item:ResultItem) => item.shortId
  },
  {
    key:'HasPriority', 
    header:'Prio', 
    print:(item:ResultItem) => {return item.hasPriority ? "👑" :''}
  },
  {
    key:'Status', 
    header:'Status', 
    print:(item:ResultItem) => {return Adventure.getStatusName(item.status)}
  },
  {
    key:'IsTest', 
    header:'Test',
    print:(item:ResultItem) => {return item.isTest ? "🧪" :''}
  },
  {
    key:"CreateDate", 
    header:"Bestellt am", 
    print:(item:ResultItem) => Util.printDate(item.dateCreate)
  },
  {
    key:"UserStartTime",
    header:"Wunschdatum",
    print:(item:ResultItem) => item.dateStartUser ? Util.printDate(item.dateStartUser) : "-",
  },
  {
    key:"StartTime",
    header:"Erlebnisdatum",
    print:(item:ResultItem) => item.dateStart ? Util.printDate(item.dateStart) : "-",
  },
  {
    key:"ScheduleDate",
    header:"Einlösedatum",
    print:(item:ResultItem) => item.dateScheduled ? Util.printDate(item.dateScheduled) : "-",
  },
  {
    key:"Order.Comments",
    header:"Bestellkommentar",
    print:(item:ResultItem) => item.orderComments,
  },
  {
    key:"Operator",
    header:"Operator",
    print:(item:ResultItem) => item.operator ? _.capitalize((item.operator || '-').split('@')[0]): "-",
  },
  {
    key:"ReserverName", 
    header:"von", 
    print:(item:ResultItem) => item.nameReserver || "-"
  },
  {
    key:"RecieverName", 
    header:"für",
    print:(item:ResultItem) => item.nameReceiver || "-",
  },
  {
    key:'ActivityTitles', 
    header:'Aktivitäten', 
    print:(item:ResultItem) => item.activityTitles
  },
  {
    key:"PaymentReminderLevel", 
    header:"Mahnstufe", 
    print:(item:ResultItem) => _.isNil(item.paymentReminderLevel) ? "-" : item.paymentReminderLevel
  },
  {
    key:"Order.Packaging.Code",
    header:"Versandart",
    print:(item:ResultItem) => item.packagingCode || "-"
  },
  {
    key:"LiveId",
    header:"LiveId",
    print:(item:ResultItem) => item.liveId || "-"
  },
  {
    key: 'RegionCode',
    header: 'Region',
    print:(item:ResultItem) => item.regionCode || "-"
  },
  {
    key:"UserStartLocationText",
    header:"Startort", 
    print:(item:ResultItem) => item.startLocation || "-"
  },
  {
    key:"TransportOptions",
    header:"Transportmittel",
    print:(item:ResultItem) => item.transportOptions || "-"
  },
  {
    key:"Rating.Rating",
    header:"Bewertung",
    print:(item:ResultItem) => item.ratingOverall ? numberToStars(item.ratingOverall) : "-"
  },
  {
    key:"Rating.Rating2",
    header:"Bewertung Ablauf",
    print:(item:ResultItem) => item.ratingOrganization ? numberToStars(item.ratingOrganization) : "-"
  },
  {
    key:"Rating.Rating3",
    header:"Bewertung Anbieter",
    print:(item:ResultItem) => item.ratingProvider ? numberToStars(item.ratingProvider) : "-"
  },
  {
    key:"RecieverEmail",
    sortField:"RecieverEmail",
    header:"E-Mail Beschenkter",
    print:(item:ResultItem) => item.emailReceiver || "",
  },
  {
    key:"ReserverEmail",
    sortField:"ReserverEmail",
    header:"E-Mail Schenker",
    print:(item:ResultItem) => item.emailReserver || "-"
  },
  {
    key:"IsMe",
    header:"Appentura.me",
    print:(item:ResultItem) => item.isMe ? "ja" : "nein"
  },
  {
    key:"Activities.IsOnlineEvent",
    header:"Online-Event",
    print:(item:ResultItem) => item.isOnlineEvent ? "ja" : "nein"
  },
  {
    key:"Activities.IsHomeDelivery",
    header:"Heimlieferung",
    print:(item:ResultItem) => item.isHomeDelivery ? "ja" : "nein"
  },
  {
    key:"Order.Prepaid.InWarehouse",
    header:"Sammelbestellung verkauft",
    print:(item:ResultItem) => item.isPrepaidInWarehouse ? "nein" : "ja"
  },
]

function numberToStars(n:number) {
  try {
    let stars = "";
    for(let i = 0; i < n; i++) {
      stars += "⭐️";
    }
    return `${stars} (${n})`;
  }
  catch(err) {
    return n;
  }
}