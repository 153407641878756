import _ from "lodash";
import { ProviderLocation, ProviderLocationLocation } from "./ProviderLocation";
import { Mapper } from "../_Common/Mapper";

export class ProviderLocationMapper extends Mapper<ProviderLocation> {
  public apiEndpoint = "providerLocations";

  public toDb(region: ProviderLocation): any {
    return {
      _id: region._id,
      providerId: region.providerId,
      activityId: region.activityId,
      regionVariantId: region.regionVariantId,
      venue: region.venue,
      notes: region.notes,
      start: _.isNil(region.start) ? null : this.toDb_location(region.start),
      end: _.isNil(region.end) ? null : this.toDb_location(region.end),
    }
  }

  private toDb_location(location: ProviderLocationLocation): any {
    return {
      title: location.title,
      instructions: location.instructions,
      coordinates: {
        lat: location.coordinates.lat,
        lng: location.coordinates.lng
      }
    }
  }

  public fromDb(obj: any): ProviderLocation {
    return {
      _id: obj._id,
      providerId: obj.providerId,
      activityId: obj.activityId || null,
      regionVariantId: obj.regionVariantId || null,
      venue: obj.venue || null,
      notes: obj.notes || "",
      start: _.isNil(obj.start) ? null : this.fromDb_location(obj.start),
      end: _.isNil(obj.end) ? null : this.fromDb_location(obj.end),
    }
  }

  private fromDb_location(obj: any): ProviderLocationLocation {
    return {
      title: obj.title || "",
      instructions: obj.instructions || "",
      coordinates: { 
        lat: obj.coordinates.lat,
        lng: obj.coordinates.lng
      }
    }
  }

}