import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import * as TS from "../../../../types";
import * as DA from "../../../../types/DataAccess";
import * as BL from "../../../../types/BusinessLogic";
import { Alert, Card, CardSection, ConfirmButton, Dropdown, NumberInput, DropdownOption, Loader, Button, Checkbox, Value, Td, Tr, Table } from "../../../controls";
// context
import { SurpriseContext } from "../Surprise.Context";
// styling
import CSS from "./Activities.Edit.module.scss";

type ActivitiesEditProps = {
  stopEdit: () => void
}
export default function ActivitiesEdit({stopEdit} : ActivitiesEditProps) {
  // context
  const SC = useContext(SurpriseContext);

  // state
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activities, setActivities] = useState<TS.Activity[]>([]);
  const [regionOptions, setRegionOptions] = useState<DropdownOption[]>([]);
  const [activityOptions, setActivityOptions] = useState<DropdownOption[]>([]);
  const [selectedRegionCode, setSelectedRegionCode] = useState<string>("");
  const [selectedParticipantCount, setSelectedParticipantCount] = useState<number>(1);
  const [selectedCategories, setSelectedCategories] = useState<string[]|null>(null);
  const [selectedActivityId, setSelectedActivityId] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string|null>(null);

  // mount
  useEffect(() => {
    const load = async() => {
      // region options and pre-selected region
      const regions = await DA.RegionRepository.findAll();
      const regionOptions:DropdownOption[] = regions.map(r => {
        return { label: r.title.de, value: r.code};
      });

      // get possible participants, selected participant cound & selected region code
      const participantOptions: DropdownOption[] = [];
      let selectedRegionCode: string|null = null; 
      let selectedParticipantCount = _.get(SC.surprise, "Order.Me.ParticipantCount") || 1;
      (SC.surprise.Activities || []).forEach((a:any) => {
        // TODO we should use TS.AdventureActivity (once the SC.surprise is of type TS.Adventure)
        const participantCount = _.get(a, "Configuration.ParticipantCount");
        if(participantCount && !participantOptions.some(o => String(o.value) === String(participantCount))) {
          participantOptions.push({label:`${participantCount}`, value:participantCount});
          if(selectedParticipantCount < participantCount) {
            selectedParticipantCount = participantCount;
          }
        }
        // set selected region code (if available)
        const regionCode = _.get(a, "RegionCode");
        if(regionCode && regionOptions.some(ro => ro.value === regionCode)) {
          //... we simply set it to the last we receive
          selectedRegionCode = regionCode;
        }
      })
      if(participantOptions.length === 0) {
        // no participant count found ? add 1 as an option
        participantOptions.push({value:1, label:"1"});
      }

      // no region yet? get the closest
      if(!selectedRegionCode && SC.surprise.UserStartLocation) {
        const closestRegion = BL.Me.getClosestRegion(SC.surprise.UserStartLocation.Lat, SC.surprise.UserStartLocation.Lng);
        if(closestRegion) {
          selectedRegionCode = closestRegion.code;
        }
      }

      // still no region? select the first from all
      if(!selectedRegionCode) {
        selectedRegionCode = regionOptions[0].value; // as string;
      }
      
      // load activities (active main activities)
      const activities = await TS.Activity.allActiveMain();
      
      // get activity options
      const activityOptions = getActivityOptions(activities, SC.surprise.Activities, selectedRegionCode!, selectedParticipantCount, null);
    
      // update state
      setRegionOptions(regionOptions);
      setSelectedRegionCode(selectedRegionCode!);
      setSelectedParticipantCount(selectedParticipantCount);
      setActivityOptions(activityOptions);
      setSelectedActivityId("");
      setActivities(activities);
      setIsLoading(false);
    }
    load();
  }, []);

  /**
   * Returns selectable activities based on the selected participant count and region
   * @param activities 
   * @param regionCode 
   * @param participantCount 
   * @returns 
   */
  const getActivityOptions = (activities: TS.Activity[], existingAdventureActivities: any[], regionCode:string, participantCount:number, categories:string[]|null): DropdownOption[] => {
    const activityOptions: DropdownOption[] = activities
      .filter(activity => {
        // only in selected categories
        if(categories && categories.length > 0) {
          const intersection = _.intersection(categories, activity.tags);
          return intersection.length > 0;
        }
        return true;
      })
      .filter(activity => {
        // get region variant
        const rv = activity.regionVariants.find(rv => rv.regionCode === regionCode); // && rv.isActive);
        if(rv && rv.isActive) {
          if(rv.priceConfigurations.find(pc => pc.participantCount === participantCount)) {
            return true;
          }
        }
        return false;
      }).map(activity => {
        return {value:activity._id, label:`${activity.title.de} (${activity.getCategoryTags().join(", ")})` }
      })
    if(activityOptions.length > 0) {
      activityOptions.unshift({value:"", label:"Aktivität wählen ..."})
    }
    
    return activityOptions;
  } 


  // user changes selection
  const onSelectionChange = (regionCode:string, participantCount:number, categories:string[]|null) => {
    setIsLoading(true);
    setSelectedRegionCode(regionCode);
    setSelectedParticipantCount(participantCount);
    setSelectedCategories(categories || null);
    setActivityOptions(getActivityOptions(activities,  SC.surprise.Activities, regionCode, participantCount, categories));
    setErrorMessage(null);
    setIsLoading(false);
  }

  // user changes activity
  const onActivityChange = (activityId: string) => {
    setSelectedActivityId(activityId);
    setErrorMessage(null);
  }


  // user wants to add an activity
  const onClickAdd = async() => {
    const activity = activities.find(a => a._id === selectedActivityId)!;
    try {
      // create adventure activities
      const adventureActivities = await TS.AdventureActivity.fromActivity(activity, selectedRegionCode, selectedParticipantCount);

      // add adventure activities to adventure
      const updatedActivities = (SC.surprise.Activities || []).map((a:any) => a);
      adventureActivities.forEach(aa => {
        if(updatedActivities.some((a:any) => a.ActivityId === aa.ActivityId) === false) {
          updatedActivities.push(aa.toDb());
        }
      });
      SC.updateAtPath("Activities", updatedActivities, true);

      // write log
      const currentUser = await BL.User.getCurrent();
      const logText = adventureActivities.map(aa => `${aa.Title} (${aa.RegionCode}, ${aa.Configuration.ParticipantCount} Teilnehmer})`).join(", ");
      const logEntry = {
        CreateDate: new Date(),
        Text: `${currentUser.email} fügt ${logText} hinzu.`,
        Operator: "robot",
        Type: "adventure-activities",
        ShowWarning: false
      }
      await SC.addLog(logEntry);
    }
    catch(error) {
      setErrorMessage((error as Error).message);
    }
  }

  // user wants to remove an activity
  const onClickRemove = async(activityToRemove: any) => {
    // remove activitiy
    const updatedActivities = (SC.surprise.Activities || []).filter((a:any) => a.ActivityId !== activityToRemove.ActivityId);
    SC.updateAtPath("Activities", updatedActivities, true);
    // write log
    const currentUser = await BL.User.getCurrent();
    const logEntry = {
      CreateDate: new Date(),
      Text: `${currentUser.email} entfernt Aktivität '${activityToRemove.Title}' in Region ${activityToRemove.RegionCode} für ${activityToRemove.Configuration.ParticipantCount} Teilnehmer.`,
      Operator: "robot",
      Type: "adventure-activities",
      ShowWarning: false
    }
    await SC.addLog(logEntry);
  }

  // render loading
  if(isLoading) {
    return <Loader />
  }

  // pre-render
  const adventure = TS.Adventure.fromDb(SC.surprise);
  const meOrder = adventure.Order.Me!; // SC.surprise.Order.Me as TS.AdventureOrderMe;
  
  let selectActivity = null;
  if(activityOptions.length > 0) {
    selectActivity = <Dropdown label="Aktivität" options={activityOptions} value={selectedActivityId} onChange={onActivityChange} />;
  }
  else {
    selectActivity = <Alert title="Keine Aktivät verfügbar" intent="error" size="small">Für diese Kombination von Region und Anzahl Teilnehmer ist keine Aktivität verfügbar</Alert>
  }
  let errorAlert = null;
  if(errorMessage) {
    errorAlert = <Alert title="Error" intent="error" size="small">{errorMessage}</Alert>
  }
  const canRemoveActivity = (SC.surprise.Activities || []).length > 1;
  //const budget = meOrder.ParticipantCount * meOrder.ParticipantBudget;
  let total: number = 0;
  let rows = (SC.surprise.Activities || []).map((a:any) => {
    total += a.Price;
    return <tr key={a.ActivityId}>
      <Td>{`${a.Title} (Region: ${a.RegionCode}, Teilnehmer: ${a.Configuration.ParticipantCount})`}</Td>
      <Td align="right">{a.Price.toFixed(2)}</Td>
      <td><ConfirmButton disabled={!canRemoveActivity} onConfirm={() => onClickRemove(a)}>entfernen</ConfirmButton></td>
    </tr>
  })
  rows.push(
    <Tr key="total">
      <Td label>Total</Td>
      <Td align="right">{total.toFixed(2)}</Td>
      <Td></Td>
    </Tr>
  )
  if(meOrder) {
    rows.push(
      <Tr key="budget">
        <Td label>Budget</Td>
        <Td align="right">{(meOrder.ParticipantBudget * meOrder.ParticipantCount).toFixed(2)}</Td>
        <Td></Td>
      </Tr>
    )
  }
  let removeInfo = null;
  if(!canRemoveActivity) {
    removeInfo = <Alert intent="info" size="small" title="Aktivität kann nicht entfernt werden">
      Die Überraschung muss mindestens eine Aktivität enthalten. Zuerst eine Aktivität hinzufügen, um diese Aktivität löschen zu können.
    </Alert>
  }

  // pre-render appentura.me
  let appenturaMe = null;
  if(SC.surprise.IsMe && _.get(SC.surprise, "Order.Me.ActivityTypes")) {
    const meOrder = SC.surprise.Order.Me as TS.AdventureOrderMe;
    const categories = meOrder.ActivityTypes || [];
    
    if(categories.length > 0) {
      appenturaMe = ( 
        <>
          <Checkbox 
            label="Nur Aktivitäten aus gewünschten Kategorien (appentura.me Bestellung)"
            secondaryLabel={`nur Aktivitäten aus Kategorien: ${categories.join(', ')}`} 
            value={selectedCategories !== null} 
            onChange={checked => {
              if(checked) {
                onSelectionChange(selectedRegionCode, selectedParticipantCount, categories)
              }
              else {
                onSelectionChange(selectedRegionCode, selectedParticipantCount, null)
              }
            }}
          />
          <Value label="Me-Paket">
            {meOrder.PackageName || "-"}
          </Value>
        </>
      )
    }
  }
  // render
  let confirmDisabled = false;
  let confirmTitle = "Aktivität zur Überraschung hinzufügen";
  if(selectedActivityId === "") {
    confirmDisabled = true;
    confirmTitle = "Bitte eine Aktivität auswählen";
  }
  if((SC.surprise.Activities || []).some((a:any) => a.ActivityId === selectedActivityId)) {
    confirmDisabled = true;
    confirmTitle = "Aktivität ist bereits in der Überraschung enthalten";
  }
  return (
    <Card>
      <CardSection title="Aktivität hinzufügen">
        {appenturaMe}
        <Dropdown label="Region" options={regionOptions} value={selectedRegionCode} onChange={v => onSelectionChange(v, selectedParticipantCount, selectedCategories)} />
        <NumberInput label="Anzahl Teilnahmer" value={selectedParticipantCount}  onChange={v => onSelectionChange(selectedRegionCode, Number(v), selectedCategories)} />
        {selectActivity}
        <div>
          <ConfirmButton disabled={confirmDisabled} onConfirm={onClickAdd}>{confirmTitle}</ConfirmButton>
        </div>
        {errorAlert}
      </CardSection>
      <CardSection title="Hinzugefügte Aktivitäten">
        <Table>{rows}</Table>
        {removeInfo}
      </CardSection>
      <div className={CSS.actions}>
        <Button onClick={stopEdit}>Fertig</Button>
      </div>
    </Card>
  )
}