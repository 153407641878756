import * as DA from "../DataAccess";

export class PaymentType {
  public static print(paymentType: DA.PaymentType):string {
    return `${paymentType.accountingId}-${paymentType.name} [${this.printKind(paymentType.kind)}]`
  }
  
  public static printKind(kind: DA.PaymentTypeKind): string {
    switch(kind) {
      case "bank":
        return "Bank";
      case "creditcard":
        return "Kreditkarte";
      case "employee":
        return "Vorauszahlung Mitarbeiter";
      default:
        return kind;
    }
  }
}