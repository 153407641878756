import _ from "lodash";
import React, { useContext, useState, useEffect } from "react";
import * as DA from "../../../types/DataAccess";
import { ActivityContext } from "./ActivityContext";
import { Button, Value, Loader, Icons, Icon, TextInput, CardSection, Table, Tr, Td, Modal } from "../../controls";

import CSS from "./Activity.Shop.Tags.module.scss";

export default function ShopTags() {
  // context
  const AC = useContext(ActivityContext)!;

  // state
  const [busy, setBusy] = useState<boolean>(false);
  const [mode, setMode] = useState<"edit"|"view">("view");

  // user wants to edit
  const startEdit = () => {
    setMode("edit")
  }

  // exits edit
  const stopEdit = () => {
    setMode("view");
  }

  // user clicks a tag
  const onClickTag = async(tag:string) => {
    setBusy(true);
    const usedTags = AC.activity!.tags.map(t => t);
    let updatedTags:string[] = [];
    if(usedTags.includes(tag)) {
      // remove
      updatedTags = usedTags.filter(t => t !== tag);
    }
    else {
      // add 
      updatedTags = usedTags.map(t => t);
      updatedTags.push(tag);
    }
    await onSave(updatedTags);
    setBusy(false);
  }

  // user wants to save
  const onSave = async(tags:string[]) => {
    await AC.update({tags:tags});
    stopEdit();
  }

  // still loading?
  if(AC.activity === null) {
    return <Loader text="lade Aktivität ..." />
  }

  // subactivity? no render
  if(AC.activity.isSubactivity) {
    return null;
  }
  
  // pre-render
  const usedTags = AC.activity.tags.sort((a,b) => a.localeCompare(b)).map(tag => <Pill disabled={busy} key={tag} active={true} tag={tag} onClick={startEdit} />)
  // render
  return <>
    <CardSection title="Tags">
      <div className={CSS.tagsContainer}>
        <div className={CSS.used_tags}><Value onClick={startEdit}>{usedTags}</Value></div>
        <SpecialTags usedTags={AC.activity.tags} onClickTag={onClickTag} busy={busy} />
        <MeTags usedTags={AC.activity.tags} onClickTag={onClickTag} busy={busy} />
      </div>
    </CardSection>
    <Modal
      isOpen={mode === "edit"}
      title="Aktivität - Tags"
      onClose={stopEdit}
    >
      <Edit activity={AC.activity2!} onSave={onSave} onCancel={stopEdit} />
    </Modal>
  </>
}

type EditProps = {
  activity: DA.Activity,
  onSave: (tags:string[]) => void,
  onCancel: () => void
}
function Edit({activity, onSave, onCancel} : EditProps) {
  // state
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [tags, setTags] = useState<{all:string[],used:string[], available:string[]}>({all:[], used:[], available:[]});
  const [newTag, setNewTag] = useState("");

  // mount
  useEffect(() => {
    const load = async() => {
      const all:string[] = await DA.ActivityRepository.getUsedTags();
      const used:string[] = activity.tags.map(tag => tag);
      const available:string[] = all.filter(tag => used.includes(tag) === false);
      setTags({all, used, available});
      setIsLoaded(true);
    }
    load();
  }, []);


  // toggle a tag
  const onClickTag = (tagToToggle: string) => {
    let used: string[];
    if(tags.used.includes(tagToToggle)) {
      used = tags.used.filter(tag => tag !== tagToToggle);
    }
    else {
      used = tags.used.map(tag => tag);
      used.push(tagToToggle);
    }
    const available = tags.all.filter(tag => used.includes(tag) === false);
    setTags({all:tags.all, used, available});
  }

  // save
  const onClickSave = () => {
    onSave(tags.used);
  } 

  // add new tag
  const onClickAdd = () => {
    const tagToAdd = newTag.trim();
    if(tagToAdd.length === 0) {
      return;
    }
    const all = tags.all.map(tag => tag);
    if(!all.includes(tagToAdd)) {
      all.push(tagToAdd);
    }
    const used = tags.used.map(tag => tag)
    if(!used.includes(tagToAdd)) {
      used.push(tagToAdd);
    }
    const available = tags.all.filter(tag => used.includes(tag) === false);
    setTags({all, used, available});
    setNewTag("");
  }

  // render loading
  if(!isLoaded) {
    return <Loader text="lade ..." />
  }
  // pre-render
  const items = tags.all.sort((a, b) => a.localeCompare(b)).map(tag => {
    const active = tags.used.includes(tag);
    return <Pill disabled={false} key={tag} active={active} tag={tag} onClick={() => onClickTag(tag)} />
  })
  // render
  return <div className={CSS.editContainer}>
    <div className={CSS.tags}>{items}</div>
    <div className={CSS.newtag}>
      <TextInput label="Neues Tag" onChange={v => setNewTag(v)} value={newTag} /> 
      <Button onClick={onClickAdd} intent="confirm"><Icon icon={Icons.Plus} /></Button>
    </div>
    <div className={CSS.actions}>
      <Button onClick={onCancel} intent="cancel">abbrechen</Button>
      <Button onClick={onClickSave} intent="confirm">speichern</Button>
    </div>
  </div>
}


type SpecialTagsProps = {
  usedTags: string[],
  busy: boolean,
  onClickTag: (tag:string) => void
}
function SpecialTags({usedTags, busy, onClickTag} : SpecialTagsProps) {
  // tags used as filters in shop with explanation
  const [glossary] = useState([
    {tag:"Kulinarisches", explanation:"die Aktivität erscheint wenn im Shop nach 'Kulinarik' gefiltert wird"},
    {tag:"Fun", explanation:"die Aktivität erscheint, wenn im Shop nach 'Fun' gefiltert wird"},
    {tag:"Wellness", explanation:"die Aktivität erscheint, wenn im Shop nach 'Wellness' gefiltert wird"},
    {tag:"Stadt-Aktivitäten", explanation:"(wird im Warenkorb als Upsell angezeigt, falls im Warenkorb bereits Aktivitäten mit dem Tag 'Kulinarisches' angezeigt werden)"},
  ]);
  // pre-render
  const items = glossary.map((item, index) => {
    const active = usedTags.includes(item.tag);
    return (
      <Tr key={index}>
        <Td>
          <Pill disabled={busy} tag={item.tag} active={active} onClick={onClickTag} />
        </Td>
        <Td>
          {item.explanation}
        </Td>
      </Tr>
    );
  })
  // render
  return (
    <Table>
      <Tr>
        <Td colSpan={2} label>Spezielle Tags</Td>
      </Tr>
      {items}
    </Table>
  );
}

type MeTagsProps = {
  usedTags: string[],
  busy: boolean,
  onClickTag: (tag:string) => void
}
function MeTags({usedTags, busy, onClickTag}: MeTagsProps) {
  // me tags
  const [tags] = useState(["Action", "Fun", "Kulinarisches", "Wellness"]);

  // pre-render
  const items = tags.map((item, index) => {
    const active = usedTags.includes(item);
    return <Pill disabled={busy} key={index} tag={item} active={active} onClick={onClickTag} />
  })
  // render
  return <Table>
    <Tr>
      <Td colSpan={2} label>Appentura.me Tags</Td>
    </Tr>
    <Tr>
      <Td>
        Diese Tags werden in Appentura.me gesetzt. Um das suchen von passenden Aktivitäten für die Organisation zu erleichtern, sollte mindestens eines dieser Tags gesetzt sein.
      </Td>
    </Tr>
    <Tr>
      <Td>{items}</Td>
    </Tr>
  </Table>
}

type PillProps = {
  tag: string,
  active: boolean,
  disabled: boolean,
  onClick?: (tag:string) => void
}
function Pill({tag, active, disabled, onClick} : PillProps) {
  const _onClick = () => {
    if(onClick && !disabled) {
      onClick(tag);
    }
  }
  return (
    <div className={`${CSS.activity_tag_pill} ${onClick ? CSS.clickable : ""} ${active ? CSS.active : ""} ${disabled ? CSS.disabled : ""}`} onClick={_onClick} ><div className={CSS.inner}>{tag}</div></div>
  )
}