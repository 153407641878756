import _ from "lodash";
import ApiOld from "../../util/api"; // TODO we should not have api calls in the business logic, move to DataAccess
import * as DA from "../DataAccess";

export class Activity {
  /**
   * Checks if a region variant has all necessary data
   * @param {*} regionVariant 
   * @returns {*} array of strings containing error messages
   */
  static sanityCheckVariant(regionVariant: DA.ActivityRegionVariant): string[] {
    const errors:any [] = [];
    // needs to have price configurations
    if((regionVariant.priceConfigurations || []).length === 0) {
      errors.push("es muss mindestens eine Preiskonfiguration definiert sein")
    }
    // options max value needs to be bigger than min value
    (regionVariant.options || []).forEach(o => {
      if(o.minUnits >= o.maxUnits) {
        errors.push(`in der Option '${o.name.de}' muss die maximale Teilnehmer-Anzahl grösser sein als die minimale`);
      }
    })
  
    return errors;
  }

  /**
   * Returns the lowest price per person for an activity
   * @param activity 
   * @returns {number} lowest price per person
   */
  static getLowestPricePerPerson(activity: DA.Activity): number {
    // TODO: note this is just a quick and dirty implementation that ignores the country
    let lowestPrice = 9999999;
    activity.regionVariants.forEach(rv => {
      rv.priceConfigurations.forEach((pc:any) => {
        if(pc.price < lowestPrice && pc.price !== 0) {
          lowestPrice = pc.price;
        }
      })
    })
  
    return lowestPrice;
  }

  /**
   * Checks if an activity is active (it is active if at least one region variant is active)
   * @param activity 
   * @returns {boolean} true if at least one region variant is active
   */
  static isActive(activity: DA.Activity): boolean { 
    return activity.regionVariants.some(rv => rv.isActive);
  }


  /**
   * Updates the sales rankings of all activities
   * @returns {Date} the date of the last update
   */
  static async updateSalesRankings(): Promise<Date> {
    // TODO we should not have api calls in the business logic, move to DataAccess. To do so, we need to move the corresponding code in the api to activity actions
    const data = await ApiOld.patchSimple('/activities/salesrank');
    const activitySalesRankUpdatedOn = new Date(data.salesRankUpdatedOn);
    return activitySalesRankUpdatedOn;
  }
}