import React, { useState } from "react";
import Code from "../Code";
import { Card, Heading, Form, TextInput, Validators, Row, Dropdown, DropdownOption } from "../../../controls";

const dropdownOptions: DropdownOption[] = [
  {label: "George Harrison", value: "George"},
  {label: "Paul McCartney", value: "Paul"},
  {label: "Ringo Star", value: "Ringo"},
  {label: "John Lennon", value: "John"},
]
export default function AllFormControlsDemo() {
  const [selected, setSelected] = useState<string>(dropdownOptions[0].value);


  return <>
    <Heading>Form Controls Next to Each (to make sure sizes etc lign up)</Heading>

    <Card>
      <Row>
        <Dropdown value="George" label="Beatle" options={dropdownOptions} onChange={v => setSelected(v)} />
        <TextInput value="Note" label="Note" />
      </Row>
    </Card>
    

  </>
}
