import React, { useContext } from "react";
import { Filter } from "../../../../../types/AdventureSearch";
import { Checkbox, DateInput, Row } from "../../../../controls";
import { SearchContext } from "../Search.Context";
import FilterGroup from "../Search.FilterGroup";

type FilterProperties = "ordered"|"activated"|"startUser"|"start";
export default function Dates() {
  // context
  const Search = useContext(SearchContext);

  // onChange
  const onChangeActive = (property:FilterProperties, checked: boolean) => {
    const updatedFilter:Filter = Search.filter.clone();
    switch(property) {
      case "activated":
        updatedFilter.dateActivated.active = checked;
        break;
      case "ordered":
        updatedFilter.dateOrdered.active = checked;
        break;
      case "start":
        updatedFilter.dateStart.active = checked;
        break;
      case "startUser":
        updatedFilter.dateStartUser.active = checked;
        break;
    }
    Search.reset(updatedFilter);
  }
  const onChangeDate = (property:FilterProperties, which:"from"|"to", date:Date|null) => {
    date = date || new Date();
    const updatedFilter:Filter = Search.filter.clone();
    switch(property) {
      case "activated":
        updatedFilter.dateActivated[which] = date;
        break;
      case "ordered":
        updatedFilter.dateOrdered[which] = date;
        break;
      case "start":
        updatedFilter.dateStart[which] = date;
        break;
      case "startUser":
        updatedFilter.dateStartUser[which] = date;
        break;
    }
    Search.reset(updatedFilter);
  }

  // render
  const filter = Search.filter;
  return (
    <FilterGroup title="Datum">
      <Row xalign="start">
        <Checkbox secondaryLabel="Bestelldatum" value={filter.dateOrdered.active} onChange={checked => onChangeActive("ordered", checked)} />
        <DateRange property="ordered" show={filter.dateOrdered.active} valueFrom={filter.dateOrdered.from} valueTo={filter.dateOrdered.to} onChange={onChangeDate} />
      </Row>
      <Row xalign="start">
        <Checkbox secondaryLabel="Einlösedatum" value={filter.dateActivated.active} onChange={checked => onChangeActive("activated", checked)} />
        <DateRange property="activated" show={filter.dateActivated.active} valueFrom={filter.dateActivated.from} valueTo={filter.dateActivated.to} onChange={onChangeDate} />
      </Row>
      <Row xalign="start">
        <Checkbox secondaryLabel="Wunschdatum" value={filter.dateStartUser.active} onChange={checked => onChangeActive("startUser", checked)} />
        <DateRange property="startUser" show={filter.dateStartUser.active} valueFrom={filter.dateStartUser.from} valueTo={filter.dateStartUser.to} onChange={onChangeDate} />
      </Row>
      <Row xalign="start">
        <Checkbox secondaryLabel="Erlebnisdatum" value={filter.dateStart.active} onChange={checked => onChangeActive("start", checked)} />
        <DateRange property="start" show={filter.dateStart.active} valueFrom={filter.dateStart.from} valueTo={filter.dateStart.to} onChange={onChangeDate} />
      </Row>
    </FilterGroup>
  )
}

type DateRangeProps = {
  show:boolean, 
  property: FilterProperties,
  valueFrom:Date, 
  valueTo:Date, 
  onChange:(property:FilterProperties, which:"from"|"to", date:Date|null) => void
}
function DateRange(props:DateRangeProps) {
  // render
  if(props.show) {
    return <>
      <DateInput value={props.valueFrom} onChange={date => props.onChange(props.property, "from", date)} />
      <DateInput value={props.valueTo} onChange={date => props.onChange(props.property, "to", date)} />
    </>
  }
  return null;
}