import _ from "lodash";
import React, { useEffect, useState } from "react"
import { Form, Loader, Validators, Dropdown, DropdownOption, TextInput, CloudStorageUploader, Coordinates, TextArea } from "../../controls";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";
import { GMAP_TRAVEL_MODES, TICKER_TRAVEL_MODES } from "../../../config/constants";

// dropdown options
const GoogleMapTravelModes: DropdownOption[] = GMAP_TRAVEL_MODES.map((gtm:any) => { 
  return {value: gtm.value, label: gtm.label}
});
const TickerTravelModes: DropdownOption[] = TICKER_TRAVEL_MODES.map((ttm:any) => {
  return {value: ttm.value, label: ttm.label}
})

// internal types
type Entity = {
  template: DA.AdventureStepTemplate,
  instructions: string, // instructions[] as multiline string
}

type AdventureStepTemplateEditProps = {
  template: DA.AdventureStepTemplate
  onUpdate: Function,
  closeDialog: () => void,
}
export default function AdventureStepTemplateEdit({template, onUpdate, closeDialog} : AdventureStepTemplateEditProps) {
  // state
  const [entity, setEntity] = useState<Entity>({template:template, instructions:template.Instructions.join('\n')});
  const [activityOptions, setActivityOptions] = useState<DropdownOption[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);

  // mount
  useEffect(() => {
    const load = async() => {
      const activities = await TS.Activity.allActiveMain();
      const activityOptions: DropdownOption[] = activities.map(a => {
        return {value:a._id, label:a.title.de}
      })
      activityOptions.unshift({label:"-", value:null});
      setActivityOptions(activityOptions);
      setLoaded(true);
    };
    load();
  }, []);

  // user wants to save
  const onSave = async(formResult:any) => {
    // get updated entity
    const updatedEntity: Entity = formResult.merge(entity);
    updatedEntity.template.Instructions = updatedEntity.instructions.split('\n').filter((s:string) => s.trim().length > 0);
    // update / create
    let result: DA.Result<DA.AdventureStepTemplate>;
    if(entity.template._id) {
      result = await DA.AdventureStepTemplateRepository.update(entity.template._id, updatedEntity.template);
    }
    else {
      result = await DA.AdventureStepTemplateRepository.create(updatedEntity.template);
    }
    await onUpdate();
    closeDialog();
  }

  // user wants to abort
  const onCancel = () => {
    closeDialog();
  }

  // not yet loaded?
  if(!loaded) {
    return <Loader />
  }
  // render
  return (
    <Form entity={entity} onSave={onSave} onCancel={onCancel}>
      {template.AdventureTemplateId === null ? <TextInput path="Name" label="Name der Vorlage" validate={Validators.isRequired("Bitte Namen der Vorlage eingeben")} /> : null}
      <TextInput path="template.Title" label="Titel des Schrittes" validate={Validators.isRequired("Bitte Titel des Schrittes eingeben")} />
      <TextArea path="instructions" label="Schrittanweisungen" />
      <CloudStorageUploader path="template.ImageUrl" kind="image" label="Bild" folder="adventures" prefix="template_" disableUrlEditing={false} />
      <Coordinates path="template.StartCoordinates" pathLat="Lat" pathLng="Lng"  label="Start-Koordinaten"/>
      <Coordinates path="template.EndCoordinates" pathLat="Lat" pathLng="Lng"  label="End-Koordinaten" />
      <Dropdown path="template.TravelMode" label="Transportmittel" options={GoogleMapTravelModes} />
      <Dropdown path="template.ActivityId" label="Verbundene Aktivität" options={activityOptions} />
      <br />
      <Dropdown path="template.TickerTravel" label="Unterwegs mit ..." options={TickerTravelModes} />
      <TextInput path="template.TickerMessage" label="Ticker Meldung" explanation="im Ticker anzuzeigende Meldung" />
    </Form>
  )
} 