import Config from './../../../config';
// types
import * as TS from "../../../types";
import { FormData } from "./VendorInvoice";
// functions
import { getDriveTitle } from "./VendorInvoice";
// controls
import { DriveLink, FileSelectedInfo, FileSelector, FileSelectorHelper, Link, Table, Td, Tr } from "../../controls";
// styling
import CSS from  './VendorInvoice.module.scss';



type FileProps = {
  invoice: TS.VendorInvoice|null,
  formData: FormData,
  onChange: (info:FileSelectedInfo) => void,
  disabled: boolean
}
export default function File({invoice, formData, onChange, disabled} : FileProps) {
  // if we already have an invoice we display a link to the file that belongs to it
  if(invoice) {
    return (
      <Tr>
        <Td label>File</Td>
        <Td><DriveLink driveId={invoice.driveId}>{invoice.driveTitle || 'file'}</DriveLink></Td>
      </Tr>
    )
  }

  // no invoice? looks like we need to let the user select a file to upload
  let info = null;
  if(formData.fileSelectedInfo) {
    const targetFileName = getDriveTitle(formData);
    const sourceInfo = FileSelectorHelper.getFileInfo(formData.fileSelectedInfo);
    info = (
      <Table className={CSS.secondary}>
        <Tr>
          <Td>Quelle:</Td>
          <Td>
            <Link target="_blank" to={sourceInfo.url}>{sourceInfo.filename}</Link>
          </Td>
        </Tr>
        <Tr>
          <Td>Ziel:</Td>
          <Td>{targetFileName}</Td>
        </Tr>
      </Table>
    )
  }
  return (
    <Tr>
      <Td label>
        File
      </Td>
      <Td>
        <FileSelector 
          clientId={Config.drive.clientId}
          onFileSelected={onChange}
          validate={v => {
            return {
              valid: v !== null,
              message: "bitte File wählen"
            }
          }}
          disabled={disabled}
        >
          {info} 
        </FileSelector>
        
      </Td>
    </Tr>
  )
}
