import _ from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import * as DA from "../../../types/DataAccess";
import { Util } from "../../../util";
import { Button, Icon, Icons, Link, Loader, Row } from "../../controls";
import { VendorInvoicesContext, VendorInvoiceExtended, VendorInvoicesPostfilter } from "./VendorInvoices.Context";
import { ProviderLink } from "../../common";

type VendorInvoicesListProps = {
  onInvoiceSelectedForEdit: Function,
  onInvoiceSelectedForDelete: Function
}
export default function VendorInvoicesList({onInvoiceSelectedForEdit, onInvoiceSelectedForDelete} : VendorInvoicesListProps) {
  // context
  const VIC = useContext(VendorInvoicesContext);

  // state
  const [csv, setCsv] = useState<string|null>(null);
  const [displayedItems, setDisplayedItems] = useState<VendorInvoiceExtended[]>([]);

  // items changed
  useEffect(() => {
    setDisplayedItems(postfilterAndSort(VIC.data.invoices, VIC.postfilter));
  }, [
    VIC.data.invoices, VIC.postfilter
  ]);

  // (de)select all
  const onSelectAll = (selected:boolean) => {
    if(!selected) {
      // when deselecting, we deselect all invoices (even those not visible due to postfiltering)
      VIC.toggleInvoices(VIC.data.invoices.map(i => i._id!), selected);
    } 
    else {
      // when selecting, we only select those invoices that are visible due to postfiltering
      //const postfilteredInvoices = postfilterAndSort(VIC.data.invoices, VIC.postfilter);
      //VIC.toggleInvoices(postfilteredInvoices.map(i => i._id!), selected);
      VIC.toggleInvoices(displayedItems.map(i => i._id!), selected);
    }
  }

  const onClickInvoiceCheckbox = (e:any) => {
    let id = e.target.dataset.id 
    const selected = e.target.checked;
    VIC.toggleInvoices([id], selected);
  }

  const onClickExport = () => {
    //let lines = invoices!.map(i => {
    let lines = VIC.data.invoices.map(i => {
      let vals = []
      // Rechnungsdatum
      vals.push(i.invoiceDate) 
      // Anbieter
      vals.push(i.provider.name) 
      // Zahldatum
      if(i.payments && i.payments.length > 0) {
        vals.push(i.payments[0].paymentDate) 
      }
      else {
        vals.push("")
      }
      // Betrag
      vals.push(i.amount)
      // Zahlungsart & Bank name
      if(i.payments && i.payments.length > 0) {
        vals.push(_.get(i.payments[0], "paymentType.accountingId") || "");
        vals.push(_.get(i.payments[0], "paymentType.name") || "");
      }
      else {
        vals.push("")
        vals.push("")
      }
      
      // get rid of ;
      vals.forEach(val => (val || '').toString().replace(';',':'))

      // return as string
      return vals.join(';')
    })

    // add headers
    lines.unshift("Rechnungsdatum;Anbieter;Zahldatum;Betrag;FibuNr;Name Zahlungsart")

    // update state
    let csv = lines.join('\n');
    setCsv(csv);
  }

  // render loading
  if(VIC.status === "loading") {
    return <Loader />;
  }

  // pre-render items
  let trs = displayedItems.map(invoice => {
    let icon_allocations = invoice.__allocations.ok ? Icons.Check : Icons.ExclamationTriangle;
    let icon_sepa = invoice.__sepa.ok ? Icons.University : Icons.ExclamationTriangle;
    let paymentDates = (invoice.payments || [])
      .filter((payment:any) => !_.isNil(payment.paymentDate))
      .map((payment:any) => payment.paymentDate)
      .map((date:any) => Util.printDate(date))
      .join('<br />');
    return (
      <tr key={invoice._id}>
        <td style={{color:invoice.__allocations.ok ? "green" : "red"}}>
          <Icon icon={icon_allocations} tooltip={invoice.__allocations.message} />
        </td>
        <td style={{color:invoice.__sepa.ok ? "green" : "orange"}}>
          <Icon icon={icon_sepa} tooltip={invoice.__sepa.message} />
        </td>
        <td>
        <input 
          type="checkbox" 
          onChange={onClickInvoiceCheckbox} 
          data-id={invoice._id} 
          checked={invoice.__selected}
          />
        </td>
        <td>
          <ProviderLink provider={{_id:invoice.provider._id, name:invoice.provider.name} as DA.Provider} />
        </td>
        <td style={{textAlign:"right"}}>{Number(invoice.amount).toFixed(2)}</td>
        <td>{Util.printDate(invoice.invoiceDate)}</td>
        <td>{Util.printDate(invoice.dueDate)}</td>
        <td dangerouslySetInnerHTML={{ __html: paymentDates }} />
        <td>
          <Button onClick={() => onInvoiceSelectedForEdit(invoice)} size="small"><Icon icon={Icons.Edit} /></Button>
        </td>
        <td>
          <Button onClick={() => onInvoiceSelectedForDelete(invoice)} size="small" intent="cancel"><Icon icon={Icons.Trash} /></Button>
        </td>
      </tr>
    )
  })

  let list = null;
  if(trs) {
    let ths = ["", "", "", "Anbieter", "Betrag", "Belegdatum", "Fälligkeitsdatum", "Zahlungsdatum", ""].map((l, i) => {
      return <th key={i}>{l}</th>
    })
    list = (
      <table id="aat-v-vendorinvoices-list">
        <thead><tr>{ths}</tr></thead>
        <tbody>{trs}</tbody>
      </table>
    )
  }
  else {
    // TODO Text anpassen (je nach modus der liste)
    list = <div>nichts gefunden</div>
  }

  // selected
  let selected = null;
  if(VIC.data.selectedCount > 0) {
    selected = (
      <div>
        {`${VIC.data.selectedCount} Rechnungen im Wert von ${VIC.data.selectedSum.toFixed(2)} selektiert`}
      </div> 
    )
  }

  // csv export
  let csvExport
  if(csv) {
    csvExport = (
      <div>
        <Link to={"data:text/csv;charset=utf-8," + encodeURIComponent(csv)} download={`vendorinvoices_export_${VIC.data.datasetName}.csv`}>CSV herunterladen</Link>
      </div>
    )
  }
  else {
    csvExport = (
      <div>
        <Button size="small" onClick={onClickExport}>CSV erzeugen</Button>
      </div>
    )
  }

  let postfilterInfo = null;
  if(displayedItems.length !== VIC.data.invoices.length) {
    postfilterInfo = (
      <Row xalign="center" className="vendorinvoices-postfilter-info">
        Es werden {displayedItems.length} von {VIC.data.invoices.length} Rechnungen angezeigt. <Button size="small" onClick={VIC.resetPostfilter} >filter aufheben</Button>
      </Row>
    );
  }


  // done
  return (
    <>
      {postfilterInfo}
      <Row>
        <Button size="small" onClick={() => onSelectAll(true)}>alle {postfilterInfo && "sichtbaren "}wählen</Button>
        <Button size="small" onClick={() => onSelectAll(false)}>alle abwählen</Button>
      </Row>
      {list}
      {selected}
      {csvExport}
    </>
  )

}

function postfilterAndSort(invoices:VendorInvoiceExtended[], postfilter:VendorInvoicesPostfilter) {
  const postfilteredItems = invoices.filter(inv_ex => {
    // postfilter - provider
    let postfilter_provider = true;
    if(postfilter.providerId) {
      postfilter_provider = inv_ex.provider._id === postfilter.providerId;
    }

    // postfilter - amount
    let postfilter_amount = true;
    if(postfilter.amountCompare !== "") {
      if(postfilter.amountCompare === "gt") {
        postfilter_amount = inv_ex.amount > postfilter.amount;
      }
      if(postfilter.amountCompare === "lt") {
        postfilter_amount = inv_ex.amount < postfilter.amount;
      }
      if(postfilter.amountCompare === "eq") {
        postfilter_amount = inv_ex.amount === postfilter.amount;
      }
    }

    // post filter - date
    let postfilter_date = true;
    if(postfilter.dateType !== "") {
      let date2: Date|null = postfilter.dateDate;
      let date1: Date|null = null;
      switch(postfilter.dateType) {
        case "invoice":
          date1 = inv_ex.invoiceDate;
          break;
        case "due":
          date1 = inv_ex.dueDate;
          break;
        case "payment":
          if(inv_ex.payments && inv_ex.payments.length > 0) {
            date1 = inv_ex.payments[0].paymentDate;
          }
          break;
      }
      if(date1 && date2) {
        switch(postfilter.dateCompare) {
          case "before":
            postfilter_date = moment(date1).isBefore(postfilter.dateDate, "day");
            break;
          case "after":
            postfilter_date = moment(date1).isAfter(postfilter.dateDate, "day");
            break;
          case "on":
            postfilter_date = moment(date1).isSame(postfilter.dateDate, "day");
            break;
        }
      }
    }

    // done
    return postfilter_provider && postfilter_amount && postfilter_date;
  })

  // sort
  const sortedItems = postfilteredItems.sort((a, b) => {
    const date_a = a.dueDate ? a.dueDate.getTime() : 0;
    const date_b = b.dueDate ? b.dueDate.getTime() : 0;
    if(date_a === date_b) {
      return a.provider.name.localeCompare(b.provider.name);
    }
    else {
      return date_a < date_b ? -1 : 1;
    }
  })

  // done
  return sortedItems;
}