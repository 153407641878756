import React, {useState, useEffect, useContext} from "react";
import { SurpriseContext } from "../Surprise.Context";
import { Card, ClipboardButton, Icon, Icons, Link } from "../../../controls";
import * as TS from "../../../../types";
import Config from "../../../../config";
import "./Links.View.scss";

export default function Links({expanded}) {
  // context
  const SC = useContext(SurpriseContext);
  // state
  const [state, setState] = useState(null);
  
  // surprise or surprise.Status changed
  useEffect(() => {
    const urls = {
      surprise: TS.Adventure.getAdventureLink(SC.surprise._id), // Config.surprise.live_url(SC.surprise),
      surpriseTest: TS.Adventure.getAdventureLink(SC.surprise._id, "test"), // Config.surprise.live_url(SC.surprise, "test"),
      surpriseAdmin: TS.Adventure.getAdventureLink(SC.surprise._id, "admin"), //Config.surprise.live_url(SC.surprise, "admin"),
      ticker: Config.surprise.ticker_url(SC.surprise),
      static: Config.surprise.static_url(SC.surprise),
      buddy: Config.surprise.buddy_url(SC.surprise)
    };
    const displayLive = [2, 3, 4, 5, 6, 7, 8, 9, 10].includes(SC.surprise.Status);
    const displayTest = [3, 4, 5, 6, 7, 8, 9, 10].includes(SC.surprise.Status);
    const displayLumen5 = [7, 8].includes(SC.surprise.Status);
    setState({...state, urls, displayLive, displayTest, displayLumen5})
  }, [SC.surprise, SC.surprise.Status]);

  // render
  if(!state) {
    return null
  }
  return (
    <Card id="appentura-surprise-links" icon={Icons.Link} title="Links" collapsible={true} expanded={expanded}>
      <table>
        <tbody>
          <Row url={state.urls.surprise} clipboard={true}>Link zur Überraschung (für Kunden)</Row>  
          <Row url={state.urls.static} clipboard={true}>Link zur Überraschung (für Kunden mit inkompatiblem Browser)</Row>
          <Row url={state.urls.buddy} clipboard={true}>Buddy</Row>
          {state.displayLive ? <Row url={state.urls.surpriseAdmin}>Überraschung betrachten (gegenwärtiger Schritt / Status des Kunden)</Row>:null}
          {state.displayTest ? <Row url={state.urls.surpriseTest}>Überraschung ausprobieren</Row>:null}
          {SC.surprise.LiveId ? <Row url={state.urls.ticker}>Live-View</Row>: <Row>Live-View noch nicht registriert</Row>}
          {SC.surprise.LiveId && state.displayLumen5 ? <Row url={`http://api-staging.appentura.io/lumen5/${SC.surprise.LiveId}?${Date.now()}`} clipboard={true}>Experimental: Live-View für Lumen5</Row>: null}
        </tbody>
      </table>
    </Card>
  );
}


function Row({label, url, to, clipboard, children}) {
  let copyButton = clipboard ? <ClipboardButton text={url || to} size="small" /> : null;
  let a = url ? <Link to={url} target="_blank">{children}</Link> : null;
  let link = to ? <Link to={to}>{children}</Link> : null;
  let text = !url && !to ? children : null;
  return (
    <tr>
      <td><Icon icon={Icons.Link}/></td>
      <td>{a}{link}{text}</td>
      <td>{copyButton}</td>
    </tr>
  )
}

