import React, {useContext} from "react"

import {Button} from "./Button";
import {FormContext} from "./FormContext"
import { Row } from "./layout/Row";

type FormActionsProps = {
  onSave?: any,
  onCancel?: any,
  onOk?: any,
  labelSave?: string,
  labelCancel?: string,
  labelOk?: string,
  busy?: boolean
}
function FormActions({onSave, onCancel, onOk, labelSave, labelCancel, labelOk, busy} : FormActionsProps) {
  // defaults
  busy = busy ? true : false;

  // form context
  const form = useContext(FormContext)

  // user clicks save
  const onClickSave = () => {
    let result = form.bake()
    if(onSave) {
      onSave(result);
    }
  }

  // user clicks cancel
  const onClickCancel = () => {
    form.reset()
    if(onCancel) {
      onCancel({});
    }
  }

  // user clicks ok
  const onClickOk = () => {
    let result = form.bake();
    let updatedEntity = result.merge(form.entity);
    if(onOk) {
      onOk(updatedEntity);
    }
  }

  // pre-render
  const buttonCancel = onCancel ? <Button onClick={onClickCancel} intent="cancel" disabled={busy}>{labelCancel || "abbrechen"}</Button> : null;
  const buttonSave = onSave ? <Button onClick={onClickSave} intent="ok" disabled={!form.isValid || !form.isDirty || busy}>{labelSave || "speichern"}</Button> : null;
  const buttonOk = onOk ? <Button onClick={onClickOk} intent="ok" disabled={busy}>{labelOk || "ok"}</Button> : null;

  // render
  return (
    <Row align="center">
      {buttonCancel}{buttonSave}{buttonOk}
    </Row>
  )
}

export default FormActions