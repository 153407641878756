import { AdventureTemplate } from "./AdventureTemplate";
import { Mapper } from "../_Common/Mapper";

export class AdventureTemplateMapper extends Mapper<AdventureTemplate> {
  public apiEndpoint = "adventureTemplates";

  public toDb(adventureTemplate: AdventureTemplate): any {
    return {
      _id: adventureTemplate._id,
      Name: adventureTemplate.Name,
    }
  }

  public fromDb(obj:any): AdventureTemplate {
    return {
      _id: obj._id || undefined,
      Name: obj.Name || ""
    }
  }

}