import React, {useContext, useEffect, useState} from "react";
import * as DA from "../../../types/DataAccess";
import { ActivityContext } from "./ActivityContext";
import { Alert, Link, Loader } from "../../controls";

export default function SubactivityInfo() {
  // context
  const AC = useContext(ActivityContext)!;

  // state
  const [parentActivities, setParentActivities] = useState<DA.Activity[]|null>(null);

  // mount
  useEffect(() => {
    const load = async() => {
      const activities = await DA.ActivityRepository.getParentActivities(AC.activity!._id);
      setParentActivities(activities);
    }
    if(AC.activity2) {
      load();
    }
  }, [AC.activity2]);

  // no subactivity? no render
  if(!AC.activity!.isSubactivity) {
    return null;
  }

  // render
  let content;
  if(!parentActivities) {
    content = <Loader text="lade Unteraktivitäten ..." />
  }
  else {
    const activityLinks = parentActivities.map(a => <div key={a._id}><Link to={`/activities/${a._id}`}>{a.title.de}</Link></div>);
    if(activityLinks.length > 0) {
      content = <Alert intent="info" size="medium" title="Dies ist eine Unteraktivität von:">
        {activityLinks}
      </Alert>
    }
    else {
      content = <div>noch keiner Aktivität zugewiesen</div>
    }
  }
  return <>
    {content}
  </>
}