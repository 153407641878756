import { useContext } from "react";
import { useDrag } from "react-dnd"
import { SortableListContext } from "./SortableList.Context";
import CSS from "./SortableList.module.scss";

type SortableListItemProps = {
  id: string,
  children: React.ReactNode|React.ReactNode[]
}
export function SortableListItem({id, children} : SortableListItemProps) {
  // context
  const SLC = useContext(SortableListContext);

  // useDrag hook
  const [{ isDragging }, refDrag, refDragPreview] = useDrag(() => ({
		// "type" is required. It is used by the "accept" specification of drop targets.
    type: 'BOX',
    // the item's "specification" (consumed by useDrop's drop)
    item: () => {
      return { id }
    },
		// The collect function utilizes a "monitor" instance to pull important pieces of state from the DnD system.
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }));


  
  // render
  if(SLC.disabled) {
    return (
      <div className={`${CSS.item} ${CSS.disabled}`} key={id}>
        <div className={CSS.item_inner}>
          {children}
        </div>
      </div>
    )
  }
  else {

    return (
      <div ref={refDragPreview} className={`${CSS.item} ${isDragging ? CSS.dragging:""}`} key={id}>
        <div role="Handle" ref={refDrag}  className={CSS.item_inner}>
          {children}
        </div>
      </div>
    )
  }
}
