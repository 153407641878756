import React, {useContext} from "react";
import {ActivityContext} from "../ActivityContext";

import AvailableAreas from "./Activity.RegionVariant.AvailableAreas";
import AvailableMonths from "./Activity.RegionVariant.AvailableMonths";
import Benefits from "./Activity.RegionVariant.Benefits";
import Comments from "./Activity.RegionVariant.Comments";
import Conditions from "./Activity.RegionVariant.Conditions";
import CopyFrom from "./Activity.RegionVariant.CopyFrom";
import Duration from "./Activity.RegionVariant.Duration";
import Equipment from "./Activity.RegionVariant.Equipment";
import Options from "./Activity.RegionVariant.Options";
import PriceConfigurations from "./Activity.RegionVariant.PriceConfigurations";
import Providers from "./Activity.RegionVariant.Providers";
import SanityCheck from "./Activity.RegionVariant.SanityCheck";
import Status from "./Activity.RegionVariant.Status";
import WeatherConditions from "./Activity.RegionVariant.WeatherConditions";


export default function RegionVariant() {
  // context
  const AC = useContext(ActivityContext)!;

  // render
  let content = null;
  if(AC.regionVariant !== null) {
    content = <>
      <SanityCheck />
      <Status />
      <AvailableMonths />
      <AvailableAreas />
      <Duration />
      <Options />
      <PriceConfigurations />
      <Benefits />
      <Conditions />
      <WeatherConditions />
      <Equipment />
      <Providers />
      <Comments />
      <CopyFrom />
    </>
  }
  return (
    <div>
      {content}
    </div>
  )
}