import React, { useContext, useEffect, useState } from "react"

import Util from "../../../util/util";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";

import { DateInput, Dropdown, DropdownOption, Button, Card, Heading, Breadcrumb, CardSection, Loader, Modal} from "../../controls";

import List from "./VendorInvoices.List";
import Postfilter from "./VendorInvoices.Postfilter";
import VendorInvoice from "./VendorInvoice";
import DeleteDialog from "./VendorInvoices.Delete";
import Actions from "./VendorInvoices.Actions";
import Download from "./VendorInvoices.Download";

import "./VendorInvoices.scss"
import { VendorInvoicesContext, DatasetName, VendorInvoicesProvider, VendorInvoiceExtended } from "./VendorInvoices.Context";

// options for invoice status dropdown
const optionsInvoiceStatus: DropdownOption[] = [
  {value:"unpaid", label:"unbezahlte"},
  {value:"unpaidByDate", label:"unbezahlte nach Stichtag"},
  {value:"outgoing", label:"Zahlungsausgang"},
  {value:"paid", label:"bezahlte"}
];
// options for year selection
const optionsYear: DropdownOption[] = [];
optionsYear.push({label:"alle", value:null});
for(let year=2017; year <= new Date().getFullYear(); year+=1) {
  optionsYear.push({label:String(year), value:year});
}

function VendorInvoices(props:any) {
  // context
  const VIC = useContext(VendorInvoicesContext);
  // state
  const [listDate, setListDate] = useState<Date>(new Date());
  const [painFileContent, setPainFileContent] = useState<string|null>(null);
  const [selectedPaymentType, setSelectedPaymentType]= useState<DA.PaymentType|null>(null);
  const [paymentDate, setPaymentDate] = useState<Date>(Util.getNextBusinessDay());
  const [modalAction, setModalAction] = useState<"create"|"update"|"delete"|"none">("none");
  const [modalParams, setModalParams] = useState<any>();
  const [postfilter, setPostfilter] = useState<any>(null);
  
  // context is loaded
  useEffect(() => {
    if(VIC.isMounted ) {
      // if we get a query parameter ?invoice=xxxxxx we want the edit dialog to open. to do so, we populate the mode and invoiceToEdit states
      let invoiceToEditId = null;
      if(Util.getRouteQuery(props,"invoice")) {
        invoiceToEditId = Util.getRouteQuery(props, "invoice");
        setModalAction("update");
        setModalParams(invoiceToEditId);
      }

      // TODO we should manage that in context
      setSelectedPaymentType(VIC.paymentTypes[0]);
      
    }
  }, [VIC.isMounted])

  // user wants to create an invoice
  const onClickCreate = () => {
    setModalAction("create");
    setModalParams(undefined);
  }

  // user wants to edit an invoice
  const onClickEditInvoice = (invoiceToEdit:VendorInvoiceExtended) => {
    setModalAction("update");
    setModalParams(invoiceToEdit._id);
  }

  // user wants to delete an invoice
  const onClickDeleteInvoice = (invoiceToDelete:VendorInvoiceExtended) => {
    setModalAction("delete");
    setModalParams(invoiceToDelete);
  }

  
  // user wants to move invoice to outgoing state (Zahlungsausgang)
  const onClickAddToOutgoing = async() => {
    // create the pain file
    const invoices = VIC.getSelectedInvoices();
    const painFileContent = await TS.VendorInvoiceSepa.createPainFile(invoices, selectedPaymentType, paymentDate);
    setPainFileContent(painFileContent);

    // initate payments
    await TS.VendorInvoicePayment.prepareAutomaticPayments(invoices, selectedPaymentType);

    // update the list
    VIC.reloadDataset();
  }

  // user wants to move invoice back to not paid state
  const onClickRemoveFromOutgoing = async() => {
    // abort
    const invoices = VIC.getSelectedInvoices();
    await TS.VendorInvoicePayment.abortAutomaticPayments(invoices)
    
    // update the list
    VIC.reloadDataset();
  }

  const onClickPaid = async() => {
    // collect selected elements
    const invoices = VIC.getSelectedInvoices();
    // make payments
    if(VIC.data.datasetName === "outgoing") {
      await TS.VendorInvoicePayment.completeAutomaticPayments(invoices, paymentDate);
    } 
    else {
      await TS.VendorInvoicePayment.makePayments(invoices, selectedPaymentType, paymentDate);
    }
    
    // update the list
    VIC.reloadDataset();
  }

  const closeModal = () => setModalAction("none");

  // render loading animation if context is not yet mounted
  if(!VIC.isMounted) {
    return <Loader />;
  }

  // pre-render modal content
  let modalContent = null;
  let modalTitle = "";
  switch(modalAction) {
    case "create":
      modalTitle = "Rechnung erfassen";
      modalContent = <Crud closeDialog={closeModal} />
      break;
    case "update":
      modalTitle = "Rechnung bearbeiten";
      modalContent = <Crud invoiceToEditId={modalParams} closeDialog={closeModal} />;
      break;
    case "delete":
      modalTitle = "Rechnung löschen";
      modalContent = <DeleteDialog invoiceToDelete={modalParams} closeDialog={closeModal}  />
      break;
  }

  // render
  return (
    <>
      <Breadcrumb links={[ {title:'Home', to:'/'}]} location="Kreditoren-Rechnungen" />
      <Heading>Kreditoren-Rechnungen</Heading>
      <Card>
        <CardSection title="Rechnung erfassen" key="new">
          <Button size="small" onClick={onClickCreate}>Rechnung erfassen</Button>
        </CardSection>
        <CardSection title="Rechnungen" key="list">
          <div style={{display:"flex", flexDirection:"row", paddingBottom:"8px"}}>
            <Dropdown
              label="Rechnungsstatus"
              value={VIC.data.datasetName}
              options={optionsInvoiceStatus}
              onChange={(v) => VIC.selectDataset(v as DatasetName)}
            />
          </div>
  
          <div style={{display:VIC.data.datasetName === "unpaidByDate" ? "block":"none"}}>
            <DateInput label="unbezahlt oder Belegdatum vor Stichdatum, Zahlungsdatum nach dem Stichdatum" value={listDate} onChange={d => setListDate(d as Date)} />
          </div>

          <div style={{display:VIC.data.datasetName === "paid" ? "block":"none"}}>
            <Dropdown 
              label="Jahr des Rechnungsdatums" 
              value={VIC.data.datasetYear} 
              options={optionsYear}
              onChange={v => VIC.selectDatasetYear(v ? Number(v) : null)}
            />
          </div>

          <Postfilter />

          <List
            onInvoiceSelectedForEdit={onClickEditInvoice}
            onInvoiceSelectedForDelete={onClickDeleteInvoice}
          />

          <Actions
            paymentType={selectedPaymentType!}
            onChangePaymentOption={(v:any) => setSelectedPaymentType(v)}
            paymentDate={paymentDate}
            listMode={VIC.data.datasetName}
            onChangePaymentDate={(d:any) => setPaymentDate(d as Date)}
            onClickAddToOutgoing={onClickAddToOutgoing}
            onClickRemoveFromOutgoing={onClickRemoveFromOutgoing}
            onClickPaid={onClickPaid}
          />
          <Download 
            painFileContent={painFileContent}
          />
        </CardSection>
      </Card>

      <Modal isOpen={modalAction !== "none"} title={modalTitle} onClose={closeModal}>
        {modalContent}
      </Modal>
    </>
  )
}

type CrudProps = {
  invoiceToEditId?: string,
  closeDialog: () => void,
}
function Crud({invoiceToEditId, closeDialog}: CrudProps) {
  // context
  const VIC = useContext(VendorInvoicesContext);
  
  const onCloseDialog = () => {
    closeDialog();
    VIC.reloadDataset();
  }

  return <>
    <VendorInvoice
      closeDialog={onCloseDialog}
      invoiceId={invoiceToEditId}
      onInvoiceCreated={() => VIC.reloadDataset()}
    />
  </>
}


export default (props:any) => <VendorInvoicesProvider><VendorInvoices {...props} /></VendorInvoicesProvider>