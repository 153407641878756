import React, { useState } from "react";
import Config from "../../../../config"
import Code from "../Code";
import Notes from "../Notes";
import { Card, Heading, FileSelector, FileSelectedInfo, CardSection } from "../../../controls";

export default function FileSelectorDemo() {
  const [selectedInfo, setSelectedInfo] = useState<FileSelectedInfo|null>(null);

  let info = null;
  if(selectedInfo) {
    if(selectedInfo.source === "drive") {
      info = <div>drive - filename: {selectedInfo.driveFile!.name}, id: {selectedInfo.driveFile!.id}</div>
    } 
    else {
      info = <div>local - filename: {selectedInfo.localFile!.name}</div>
    }
  }

  return <Card>
    <Heading>FileSelector</Heading>
    <div>
      <FileSelector 
        clientId={Config.drive.clientId} 
        onFileSelected={info => setSelectedInfo(info)}
      />
    </div>
    <div>
      {info}
    </div>

    <CardSection title="Drive file selection disabled">
      <FileSelector 
        clientId={Config.drive.clientId} 
        disableDriveFiles
        onFileSelected={info => setSelectedInfo(info)}
      />
    </CardSection>

    <CardSection title="Local file selection disabled">
      <FileSelector 
        clientId={Config.drive.clientId} 
        disableLocalFiles
        onFileSelected={info => setSelectedInfo(info)}
      />
    </CardSection>
  </Card>
}

