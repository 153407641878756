import _ from "lodash";
import React, {useState, useEffect, useContext} from "react";
import * as DA from "../../../types/DataAccess";
// components & contexts
import { ProviderContext } from "./Provider.Context";
import { Button, Card, Form, Dropdown, List, TextInput, Icon, Icons, Checkbox, Modal } from "../../controls";

const bankAccountColumns = [
  { path:'bankName', header:"Name der Bank" },
  { path:'currency', header:"Währung" },
  { path:'accountNumber', header:"IBAN / ESR Teilnehmernummer" },
  { path:'isActive', header:"aktiv", print:(v: DA.ProviderBankAccount) => v.isActive ? 'ja':'nein' },
  { path:'isDefault', header:"default", print:(v: DA.ProviderBankAccount) => v.isDefault ? 'ja':'nein' }
];

const bankAccountCurrencies = [
  {value:'CHF', label:'CHF', default:true},
  {value:'EUR', label:'EUR'}
];


export default function ProviderBankAccounts() {
  // state
  const [accountToEdit, setAccountToEdit] = useState<DA.ProviderBankAccount|null>(null);
  // context
  const PC = useContext(ProviderContext);

  // user wants to create
  const onClickCreate = () => {
    // create an empty contact
    const bankAccount = DA.ProviderRepository.makeBankAccount();
    setAccountToEdit(bankAccount);
  }

  // user wants to edit
  const onClickEdit = (bankAccount: DA.ProviderBankAccount) => {
    setAccountToEdit(bankAccount);
  }

  // user wants to delete
  const onClickDelete = async(bankAccount: DA.ProviderBankAccount) => {
    await PC.deleteBankAccount(bankAccount);
    await PC.reload();
  }

  // closes dialog
  const closeDialog = () => setAccountToEdit(null);
 
  // render
  return <>
    <Card title="Bankverbindungen" expanded={false} collapsible={true}>
      <List 
        columns={bankAccountColumns} 
        items={PC.provider.bankAccounts}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
        hideFooter={true}
        hideSearch={true}
        getKey={item => item._id}
      />
      <Button onClick={onClickCreate} size="small"><Icon icon={Icons.Plus} /></Button>
    </Card>
    <Modal isOpen={accountToEdit !== null} title="Bankverbindung" onClose={closeDialog}>
      <Edit bankAccount={accountToEdit!} closeDialog={closeDialog} />
    </Modal>
  </>
}

type EditProps = {
  bankAccount: DA.ProviderBankAccount,
  closeDialog: () => void
}
function Edit({bankAccount, closeDialog}: EditProps) {
  // context
  const PC = useContext(ProviderContext);

  // state
  const [busy, setBusy] = useState(false);
  const [entity, setEntity] = useState(null);

  // mount
  useEffect(() => {
    const entity:any = {};
    _.forOwn(bankAccount, (value, key) => {
      entity[key] = value;
    });
    setEntity(entity);
  }, []);

  // user wants to save
  const onSave = async(formResult:any) => {
    setBusy(true);
    const bankAccountToUpsert = formResult.merge(entity);
    await PC.upsertBankAccount(bankAccountToUpsert);
    await PC.reload();
    closeDialog();
  }

  // render
  return <>
    <Form 
      entity={entity} 
      onSave={onSave} 
      onCancel={closeDialog}
      busy={busy}
    >
      <TextInput label="Name der Bank" path="bankName" />
      <TextInput label="IBAN / ESR" path="accountNumber" />
      <Checkbox label="aktiv" path="isActive" />
      <Checkbox label="default" path="isDefault" />
      <Dropdown label="Währung" path="currency" options={bankAccountCurrencies} />
    </Form>
  </>
}
