import React from "react";
import { Icon, Icons } from ".";
import { Validation } from "./Validation";

import CSS from "./ValidationError.module.scss";

type ValidationErrorProps = {
  validation: Validation
}
export function ValidationError({validation} : ValidationErrorProps) {
  if(validation.valid) {
    return null;
  }

  return (
    <div className={CSS.container}>
      <div className={CSS.icon}>
        <Icon icon={Icons.ExclamationCircle} />
      </div>
      <div className={CSS.message}>
        {validation.message || "invalid value"}
      </div>
    </div>
  )
}