import React, { useState, useEffect } from "react";
import moment from "moment";
import { Card, DateInput, Form, Icon, Icons, Link, List, ListColumn, Loader, Table, Tr, Td, Label, Row, LinkButton } from "../../controls";
import Util from "../../../util/util";
import * as TS from "../../../types";

const columns: ListColumn[] = [
  {header: "Rechnungdatum", path:"dateInvoice", print: (v:any) => Util.printDate(v.dateInvoice), sort:(a:any, b:any) => Util.sortDate(a.dateInvoice, b.dateInvoice) },
  {header: "Fälligkeitsdatum", path:"dateDue", print: (v:any) => Util.printDate(v.dateDue), sort:(a:any,b:any) => Util.sortDate(a.dateDue, b.dateDue) },
  {header: "Zahlungsdatum", path:"datePaid", print: (v:any) => v.datePaid ? Util.printDate(v.datePaid) : "-", sort:(a,b) => Util.sortDate(a.datePaid, b.datePaid) },
  {header: "Überraschung(en)", path:"allocatedAmounts", print: (v:any) => <AllocatedAmounts allocatedAmounts={v.allocatedAmounts} /> },
  {header: "Betrag", path:"amount", print: (v:any) => Number(v.amount).toFixed(2), align:"right" },
  {header: "", path:"_id", print: (v:any) => <LinkButton to={`/vendorinvoices?invoice=${v._id}`} target="_blank"><Icon icon={Icons.Eye} /></LinkButton> },
]


type ProviderInvoicesProps = {
  providerId: string
}
export default function ProviderInvoices({providerId} : ProviderInvoicesProps) {
  // state 
  const [items, setItems] = useState<any>(null);
  const [itemsFiltered, setItemsFiltered] = useState<any[]>([]);
  const [filter, setFilter] = useState<any>(null);

  // mount
  useEffect(() => {
    init();
  },[providerId]);

  // sets filter and loads data
  const init = async() => {
    // load invoices and get them into a shape that is easily digestable by the list
    if(providerId) {
      // default filter
      const currentYear = (new Date()).getFullYear();
      const filter = {
        dateFrom: new Date(currentYear, 0, 1), 
        dateTo: new Date(currentYear, 11, 31)
      };
      const invoices = await TS.VendorInvoice.findByProviderId(providerId);
      const items = invoices.map((i:any) => {
        let amountPaid = 0;
        let datePaid = null;
        (i.payments || []).forEach((p:any) => {
          amountPaid += p.amount
          datePaid = new Date(p.paymentDate);
        })
        return {
          _id: i._id,
          amount: i.amount,
          amountPaid,
          amountDue: i.amount - amountPaid,
          dateDue: i.dueDate,
          dateInvoice: i.invoiceDate,
          datePaid,
          allocatedAmounts: i.allocatedAmounts || []
        }
      })

      // update state
      setItems(items);
      setItemsFiltered(filterItems(items, filter));
      setFilter(filter);
    }
  }

  // filters items
  const filterItems = (items:any, filter:any) => {
    const dateFrom = moment(filter.dateFrom).hour(0).minute(0).second(0).millisecond(0);
    const dateTo = moment(filter.dateTo).hour(23).minute(59).second(59).millisecond(999);
    const itemsFiltered = items.filter((item:any) => {
      const dateInvoice = moment(item.dateInvoice);
      return dateInvoice >= dateFrom && dateInvoice <= dateTo;
    });
    return itemsFiltered;
  }

  // filter
  const onClickFilter = (filter:any) => {
    setItemsFiltered(filterItems(items, filter));
    //setFilter(filter);
  }

  // pre-render
  let content = <Loader />;
  if(items) {
    content = <>
      
      <Form entity={filter} onOk={onClickFilter} labelOk="filtern">
        <Label label="Rechnungsdatum" />
        <Row>
          <DateInput label="von" path="dateFrom" />
          <DateInput label="bis und mit" path="dateTo" />
        </Row>
      </Form>
    
      <List 
        columns={columns}
        items={itemsFiltered}
        hideFooter={true}
        hideSearch={true}
        getKey={item => item._id}
      />
      <Totals items={items} itemsFiltered={itemsFiltered} />
    </>
  }

  // render
  return <Card title="Rechnungen" collapsible={true} expanded={false}>
    {content}
  </Card>
}


function AllocatedAmounts({allocatedAmounts}:any) {
  return (allocatedAmounts || []).map((aa:any) => {
    return <div key={aa._id}>
      <Link to={`/surprise/${aa.surprise_id}`} target="_blank">{Util.shortObjectId(aa.surprise_id, 0, 4)} <Icon icon={Icons.ExternalLink} /></Link>
      , {aa.bookingText || "??"}, {Number(aa.amount).toFixed(2)}
    </div>
  })
}

function Totals({items, itemsFiltered} : any) {
  const total = items.reduce((prev:any, curr:any) => {
    return prev + curr.amount;
  }, 0);
  const totalFiltered = itemsFiltered.reduce((prev:any, curr:any) => {
    return prev + curr.amount;
  }, 0);
  return <div id="v-provider-invoices-totals">
    <Table>
      <Tr>
        <Td colSpan={2}>{`${itemsFiltered.length} von ${items.length} Rechnungen werden angezeigt`}</Td>
      </Tr>
      <Tr>
        <Td label>Total angezeigte:</Td>
        <Td align="right">{totalFiltered.toFixed(2)}</Td>
      </Tr>
      <Tr>
        <Td label>Total:</Td>
        <Td align="right">{total.toFixed(2)}</Td>
      </Tr>
    </Table>
  </div>
}