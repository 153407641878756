import React, {useState, useEffect} from "react"
import _ from "lodash"
import Api from "../../../../util/api2"
import Util from "../../../../util/util"
import {Icon, Icons, Link, Loader} from "../../../controls";
import * as TS from "../../../../types";

import "./ProviderOccupancy.scss"

function ProviderOccupancy({providerId, date}) {
  const [data, setData] = useState(null)

  useEffect(() => {
    const load = async() => {
      let dateFrom = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)
      let dateTo = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0, 0, 0)
      let payload = {
        providerId,
        dateFrom,
        dateTo
      }
      let result = await Api.post("providers", "getBookingRequests", payload)
      setData(result.data)
    }
    load()
  }, [])


  let content = null

  if(_.isNil(data)) {
    content = <Loader kind="dots" />
  }
  else {
    const detailsRows = data.bookingRequests.map(b => {
      let time = (_.get(b, "services") || []).find(s => s.key.toLowerCase() === "zeit")
      let timeString = time ? time.value : "keine Zeitangabe"
      let icon = Icons.Hourglass;
      let icon_color = "yellow"
      if(_.get(b, "response")) {
        if(_.get(b, "response.accepted")) {
          icon = Icons.ThumbsUp;
          icon_color = "green";
        }
        else {
          icon = Icons.ThumbsDown;
          icon_color = "red";
        }
      }
      return (
        <tr key={b._id}>
          <td className={icon_color}><Icon icon={icon} /></td>
          <td>{timeString}</td>
          <td>{_.get(b, "activity.name") || "unbekannte Aktivität"}</td>
          <td>
            <Link to={`/surprise/${b.adventure_id}`} target="_blank">
              {TS.Adventure.getShortId(b.adventure_id, true)}
            </Link>
          </td>
        </tr>
      )
    })
    
    if(detailsRows.length > 0) {
      content = (
        <div className="details">
          Buchungsanfragen am {Util.printDate(date)}:
          <table><tbody>
            {detailsRows}
          </tbody></table>
        </div>
      )
    }
    else {
      content = `noch keine Buchungen am ${Util.printDate(date)}`
    }
  }
  return (
    <div className="c-provider-occupancy">
      {content}
    </div>
  )
}

export default ProviderOccupancy
