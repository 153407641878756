import React, {useState, useEffect, useContext} from "react";
import Util from "../../../../util/util";

// types
import * as TS from "../../../../types";

// context
import { AssistantContext } from "./Assistant.Context";
import { SurpriseContext } from "../Surprise.Context";

// controls & subviews
import { Alert, Button, ConfirmButton, Icon, Icons, Loader, NumberInput, MapLink, Row, CardSection, Modal, Link } from "../../../controls";
import OutlineActivity from "./OutlineActivity";
import OutlineRoutes from "./OutlineRoutes";
import Transport from "./Transport";
import Location from "./Location";
import StartTime from "./StartTime";

// styling
import CSS from "./Outline.module.scss";

// component
type OutlineProps = {
  surprise: any
}
export default function Outline({ surprise } : OutlineProps) {
  // context
  const AC = useContext(AssistantContext);
  const SC = useContext(SurpriseContext);
  
  // state management
  const [isBusy, setIsBusy] = useState(false); // TODO maybe we should have the assistant context provide that value
  const [showTravelDetails, setTravelDetails] = useState(false); 
  const [mode, setMode] = useState<"view"|"editStartTime"|"editUserStartTime"|"editStartLocation">("view");
  
  // mount
  useEffect(() => {
    if(AC) {
      // loads all necessary data into context
      const load = async(surprise: any) => {
        setIsBusy(true)
        await AC.load(surprise._id);
        setIsBusy(false)
      }
      load(surprise);
    }
  }, [surprise])


  // creates an outline
  const create = async() => {
    if(AC) {
      setIsBusy(true);
      await AC.createOutline();
      await AC.load(surprise._id); // to make sure we have ids and stuff // TODO should not be necessary, should be done in context
      setIsBusy(false);
    }
  }

  //
  const recalculateFromRoute= async(route:TS.AdventureOutlineRoute) => {
    if(AC) {
      setIsBusy(true);
      await AC.recalculateOutline(route);
      await AC.load(surprise._id); // to make sure we have ids and stuff // TODO should not be necessary, should be done in context
      setIsBusy(false);
    }
  }

  //
  const reload = async() => {
    if(AC) {
      setIsBusy(true);
      await AC.load(surprise._id);
      setIsBusy(false);
    }
  }

  // stops / starts edit modes
  const stopEdit = () => setMode("view");
  const startEditStartTime = () => {
    if(AC) {
      setMode("editStartTime")
    }
  }
  const startEditUserStartTime = () => {
    if(AC && SC) {
      setMode("editUserStartTime")
    }
  }
  const startEditStartLocation = () => {
    if(AC) {
      setMode("editStartLocation");
    }
  }


  const updateStartTime = (date: Date) => {
    if(AC) {
      AC.setStartTime(date);
    }
  }

  const updateStartOffset = (minutes: number) => {
    if(AC) {
      AC.setStartOffset(minutes);
    }
  }

  const updateStartLocation = (location:TS.AdventureOutlineLocation) => {
    if(AC) {
      AC.setStartLocation(location);
    }
  }

  let content = null
  if(isBusy || !AC || !AC.isLoaded) {
    content = <Loader text="lade Planung ..." />
  }
  else if(!AC.data.activities) {
    content = <Loader text="lade Aktivitäten ..." />
  } 
  else {
    // collect items
    const items: Array<any> = [];
    AC.data.activities
      .sort((a, b) => a.order > b.order ? 1 : -1)
      .forEach(a => {
        // the trips to the activity
        if(showTravelDetails) {
          items.push(<OutlineRoutes key={`routes_${a.activityId}`} routes={a.routes || []} recalculateFromRoute={recalculateFromRoute} />)
        }
        // the activity 
        const isFirst = a.order === 0;
        const isLast = a.order === AC.data.activities.length - 1;
        items.push(<OutlineActivity activity={a} isFirst={isFirst} isLast={isLast} key={a.activityId} />)
      })
    // any issues?
    const readyIssues = AC.readyForOutline.ready ? null : AC.readyForOutline.issues.map((issue, index) => <div className={CSS.ready_issues} key={index}>{issue}</div> );
    
    // start location warning (if outline and adventure do not have the same start location - this happens if things were planned, cancelled, and user selected a new start location)
    let differingStartLocation = null;
    if(AC.adventureInfo.startLocation.lat !== AC.adventureInfo.startLocationUser.lat || AC.adventureInfo.startLocation.lng !== AC.adventureInfo.startLocationUser.lng) {
      differingStartLocation = <>
        <div style={{paddingTop:"4px"}}></div>
        <Alert intent="warning" size="small" title="Geänderter Startort">
          <div>
            Der Startort der Überraschung hat sich seit der Planung verändert.
          </div>
          <div>
            Neuer Startort: <MapLink lat={AC.adventureInfo.startLocationUser.lat} lng={AC.adventureInfo.startLocationUser.lng} zoom={8}>{AC.adventureInfo.startLocationUser.text}</MapLink>
          </div>
          <div>
            <ConfirmButton onConfirm={() => {
              updateStartLocation(AC.adventureInfo.startLocationUser);
            }}>
              neuen Startort übernehmen
            </ConfirmButton>
          </div>
          <div>
            <b>ACHTUNG: der Planungsvorschlag muss nacher manuell neu erstellt werden!</b>
          </div>
        </Alert>
      </>
    }

    // alerts
    let noStartLocationAlert = null;
    if(!SC.surprise.UserStartLocation) {
      noStartLocationAlert = (
        <Alert intent="warning" title="Noch kein Startort gewählt">
          Der Kunde hat noch keinen Startort und/oder Transpormittel gewählt. Dies kann der Fall sein, wenn es sich um eine Me-Überraschung handelt.<br />
          Bitte beim Kunden (<Link to='tel:{SC.surprise.RecieverPhone}'>{SC.surprise.RecieverPhone}</Link>, <Link to='mailto:{SC.surprise.RecieverEmail}'>{SC.surprise.RecieverEmail}</Link>) nachfragen:
          <ul>
            <li>wo er genau starten möchte</li>
            <li>welches Transportmittel er verwenden möchte</li>
          </ul> 
          WICHTIG: der Startort muss weiter unten, unter 'Werte übersteuern' eingetragen werden.
        </Alert>
      )
    }

    // modal
    let modalTitle = "";
    let modalContent = null;
    switch(mode) {
      case "editStartTime":
        modalTitle = "Geplante Startzeit bearbeiten";
        modalContent = <StartTime initialValue={AC.adventureInfo.startTime} onChange={updateStartTime} stopEdit={stopEdit} label="geplante Startzeit" />;
        break;
      case "editUserStartTime":
        modalTitle = "Gewünschte Startzeit bearbeiten";
        const update = (date:Date) => {
          SC.updateAtPath("UserStartTime", date, false);
          AC.setUserStartTime(date);
        }
        modalContent = <StartTime initialValue={AC.adventureInfo.userStartTime} onChange={update} stopEdit={stopEdit} label="gewünschte Startzeit" />;
        break;
      case "editStartLocation":
        modalTitle = "Startort bearbeiten";
        modalContent = <Location onChange={updateStartLocation} stopEdit={stopEdit} />
        break;
    }
    
    // assemble content
    content = (
      <>
        <table>
          <tbody>
            <tr>
              <td className={CSS.label}>Gewählter Startort</td>
              <td>
                <div> 
                  <MapLink lat={AC.adventureInfo.startLocation.lat} lng={AC.adventureInfo.startLocation.lng} zoom={8}>{AC.adventureInfo.startLocation.text}</MapLink>&nbsp;
                  <Icon icon={Icons.Edit} onClick={startEditStartLocation} intent="confirm" />
                </div>
                {differingStartLocation}
                {noStartLocationAlert}
              </td>
            </tr>
            <tr>
              <td className={CSS.label}>Gewünschter Start</td>
              <td>
                {Util.printDateAndTime(AC.adventureInfo.userStartTime)}&nbsp;
                <Icon icon={Icons.Edit} onClick={startEditUserStartTime} intent="confirm"  />
              </td>
            </tr>
            <tr>
              <td className={CSS.label}>Geplanter Start</td>
              <td>
                {Util.printDateAndTime(AC.adventureInfo.startTime)}&nbsp;
                <Icon icon={Icons.Edit} onClick={startEditStartTime} intent="confirm"  />
              </td>
            </tr>
            <tr>
              <td className={CSS.label}>Geplantes Ende</td>
              <td>{AC.adventureInfo.endTime ? Util.printDateAndTime(AC.adventureInfo.endTime) : "-"}</td>
            </tr>
            <tr>
              <td className={CSS.label}>Transportmittel</td>
              <td><Transport /></td>
            </tr>
            <tr>
              <td className={CSS.label}>Vorlaufzeit (in Minuten)</td>
              <td>
                <NumberInput value={AC.adventureInfo.startOffset} onChange={(v) => updateStartOffset(v)} placeholder="Minuten Vorlaufzeit" />
              </td>
            </tr>
            <tr>
              <td className={CSS.label}>Reiseetappen anzeigen</td>
              <td>
                <input type="checkbox" checked={showTravelDetails} onChange={e => setTravelDetails(e.target.checked)} />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                {items}
              </td>
            </tr>
          </tbody>
        </table>
        {readyIssues}
        <Row align="center">
          <Button onClick={reload} disabled={!AC.adventureInfo.hasOutline} size="small">Änderungen verwerfen</Button>
          <Button onClick={create} disabled={!AC.readyForOutline.ready} size="small">Planungsvorschlag erstellen</Button>
        </Row>

        <Modal isOpen={mode !== "view"} title={modalTitle} onClose={stopEdit}>
          {modalContent}
        </Modal>
      </>
    )
  }

  return (
    <CardSection className={CSS.container} title="Ablauf">
      {content}
    </CardSection>
  )

}

/*
function transformProvider(p) {
  let icon = {"green":"🟢", "orange":"🟠", "red":"🔴"}[(p.colorRating || "").toLowerCase()] || "⚪️"
  return `${icon} ${p.name}`
}
*/