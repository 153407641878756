import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Api from "../../../util/api2";
import Util from "../../../util/util";

// components
import DashboardPanel from "./DashboardPanel"
import { Loader, Link, Table, Tr, Td, LinkButton, Alert } from "../../controls";

// images and styling
import iconMeteocheck from './../../../assets/images/icon_meteocheck.svg'

type MetocheckItem = {
  adventureId:string,
  adventureStart:Date,
  receiverName:string,
  reserverName:string,
  activities:Array<{
    title:string,
    providerName:string
  }>
}

export default function MeteoCheck() {
  // state
  const [isLoading, setIsLoading] = useState(true);
  const [adventures, setAdventures] = useState<MetocheckItem[]>([]);

  // mount 
  useEffect(() => {
    const load = async() => {
      setIsLoading(true);
      const apiResult = await Api.post("meteocheck", "getList", {}); 
      const adventures: MetocheckItem[] = apiResult.data.adventures
        .map((d:any) => {
          return {
            adventureId: d._id,
            adventureStart: new Date(d.start),
            receiverName: d.receiverName,
            reserverName: d.reserverName,
            activities: (d.activities || [])
              .filter((a:any) => {
                const latestMeteocheck = (a.meteochecks || []).sort((a:any, b:any) => a.date > b.date ? -1 : 1)[0];
                const passedCheck = latestMeteocheck ? latestMeteocheck.passed : false;
                return passedCheck === false;
              })
              .map((a:any) => {
                return {
                  title: a.title,
                  providerName: _.get(a, "provider.name")
                }
              })
          }
        })
        .filter((item:MetocheckItem) => {
          return item.activities.length > 0;
        })
        .filter((item:MetocheckItem) => {
          const now = moment();
          const start = moment(item.adventureStart);
          const diffInHours = moment.duration(start.diff(now)).asHours();
          return diffInHours < 48 && diffInHours >= 0;
        })
        .sort((a:MetocheckItem, b:MetocheckItem) => {
          return a.adventureStart > b.adventureStart ? 1 : -1;
        })
      setAdventures(adventures);
      setIsLoading(false);
    }
    load();
  }, [])

  // pre-render
  let content = null;
  if(isLoading) {
    content = <Loader />
  }
  else {
    let alert = null;
    if(adventures.length === 0) {
      alert = <Alert intent="info">Keine fehlenden Meteochecks für die nächsten 48 Stunden.</Alert>
    }
    else {
      alert = <Alert intent="warning">{`${adventures.length} fehlend${adventures.length === 1 ? "er":"e"} Meteocheck${adventures.length === 1 ? "":"s"} für die nächsten 48 Stunden`}</Alert>
    }
    const rows = adventures.map((item, index) => {
      const activities = item.activities.map((a, index:number) => <div key={index}>{a.title}: {a.providerName}</div>)
      return <Tr key={index}>
        <Td>
          {Util.printDateAndTime(item.adventureStart)}
        </Td>
        <Td>
          <Link to={`surprise/${item.adventureId}`}>{`${item.reserverName} für ${item.receiverName}`}</Link>
        </Td>
        <Td>
          {activities}
        </Td>
      </Tr>
    });
    let table = null;
    if(rows.length > 0) {
      table = <Table>{rows}</Table>
    }
    content = <>
      {alert}
      {table}
    </>;
  }
  // render
  return (
    <DashboardPanel title="Meteocheck" icon={iconMeteocheck} iconRounded={false} smallOnDesktop={false} smallOnMobile={false}>
      {content}
      <LinkButton to="/meteocheck">zum Meteocheck</LinkButton>
      
    </DashboardPanel>
  )
}