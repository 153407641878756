import React, { useEffect, useState } from "react";
import moment from "moment";
import Config from '../../../config'
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";
// utility
import Util from '../../../util/util'
import { DriveUploadHelper } from "../../controls/DriveUpload.Helper";
// controls
import { Alert, ClipboardButton, Button, Loader, Validators, TextArea, Table, Tr, Td, FileSelectedInfo, CardSection, Validation } from "../../controls";
// subviews
import Provider from "./VendorInvoice.Provider";
import PaymentType from "./VendorInvoice.PaymentType";
import Purpose from "./VendorInvoice.Purpose";
import Dates from "./VendorInvoice.Dates";
import Payments from "./VendorInvoice.Payments";
import AllocatedAmounts from "./VendorInvoice.AllocatedAmounts";
import PaymentTypeKind from "./VendorInvoice.PaymentTypeKind";
import Amount from "./VendorInvoice.Amount";
import File from "./VendorInvoice.File";
// styling
import CSS from  './VendorInvoice.module.scss';

/*
Two modes
- create
  - user needs to select a file
  - then select the kind of payment (bank, creditcard, employee)
  - then enter the required information
  - required information depends on the kind of payment
    - "bank" additionally requires the user to 
      - select a bank account
      - enter a paypment purpose or reference number
- update
  - after creation invoices are either "not paid" (payment will happen via banking) or "paid" (creditcart, employee prepaid)
  - invoices that are "not paid" are always to be paid via banking and can be moved to be "outgoing" from the list of vendorinvoices
  - which elements are still editable dependd on those states
    - "not paid"
      - file and provider no longer editable (file was already uploaded and its name reflects the provider)
      - everything else is editable (including amount allocations)
    - "outgoing" and "paid": only the following can be edited
      - notes
      - amount allocations
*/

export type FormData = {
  isUpdate: boolean,
  isReadOnly: boolean,
  isOutgoing: boolean,
  isPaid: boolean,
  paymentTypeKind: DA.PaymentTypeKind|null,
  provider: DA.Provider|null,
  providerBankAccount: DA.ProviderBankAccount|null,
  providerBillingAddress: DA.ProviderAddress|null,
  paymentType: DA.PaymentType|null;
  amount: number,
  referenceNumber: string|null;
  paymentPurpose: string|null;
  notes: string,
  invoiceDate: Date,
  dueDate: Date,
  driveId: string,
  fileSelectedInfo: FileSelectedInfo|null
}

type VendorInvoiceProps = {
  invoiceId?: string,
  surprise?: any, // TODO typing
  closeDialog: () => void,
  onInvoiceCreated: (invoice:TS.VendorInvoice) => void,
}

export default function VendorInvoice({invoiceId, surprise, closeDialog, onInvoiceCreated} : VendorInvoiceProps) {
  const [error, setError] = useState<string|null>(null);
  // flags
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  // the invoice (if we are editing)
  const [invoice, setInvoice] = useState<TS.VendorInvoice|null>(null);
  // payment options (for creditcard and employee payments)
  const [paymentTypes, setPaymentTypes] = useState<DA.PaymentType[]>([]);
  // data used to create and update
  const [formData, setFormData] = useState<FormData>(getFormData(null, null));

  // mount
  useEffect(() => {
    

    // if we got an invoice, we load it ... if not we display an empty form
    load(invoiceId);
  }, []);

  // form changes -> validate
  useEffect(() => {
    setIsInvalid(validate(formData) === false);
  }, [formData]);

  // loads invoice and sets form data
  const load = async(invoiceId?: string) => {
    // set flag
    setIsLoading(true);

    // load the invoice
    if(invoiceId) {
      const invoice = await TS.VendorInvoice.findOneById(invoiceId);
      if(!invoice) {
        setError(`Die Kreditorenrechnung mit der Id '${invoiceId}' konnte nicht geladen werden.`);
      }
      else {
        const provider = await DA.ProviderRepository.findById(invoice.provider._id!);
        setInvoice(invoice);
        setFormData(getFormData(invoice, provider));
      }
    }
    else {
      setFormData(getFormData(null, null));
    }

    // load payment options (for creditcard and employee payments)
    let paymentTypes = await DA.PaymentTypeRepository.findAll();
    paymentTypes = paymentTypes.sort((a:any,b:any) => a.accountingId > b.accountingId ? 1: -1 );
    setPaymentTypes(paymentTypes);

    // unset flag
    setIsLoading(false);
  }

  // user wants to save changes
  const onClickSave = async() => {
    setIsSaving(true);
    const saveResult = await save(formData, invoice);
    if(saveResult.success) {
      // reload to update form data
      await load(saveResult.vendorInvoice?._id);
      // reload list
      onInvoiceCreated(saveResult.vendorInvoice!);
    }
    setIsSaving(false);
  }

  // render error
  if(error) {
    return <Alert title="Fehler" intent="error" size="medium">{error}</Alert>
  }

  // render loading
  if(isLoading) {
    return <Loader />
  }

  // render 
  let allocations = null;
  if(invoice) {
    allocations = <>
      <div className={CSS.divider}></div>
      <AllocatedAmounts 
        vendorInvoiceId={invoice._id!} 
        closeDialog={closeDialog}
        closeDialogDisabled={false} // TODO
        surprise={surprise}
      />
    </>
  }
  return <>
    <CardSection title="Rechnung">
      <Table className={CSS.table}>
        <CopyInvoiceLink invoice={invoice} />
        <File 
          invoice={invoice} 
          formData={formData} 
          onChange={fileSelectedInfo => setFormData({...formData, fileSelectedInfo})} 
          disabled={isSaving} 
        />
        <PaymentTypeKind 
          formData={formData}
          onChange={paymentTypeKind => {
            setFormData({...formData, paymentTypeKind});
          }} 
          disabled={isSaving}
        />
        <Provider 
          invoice={invoice} 
          paymentKind={formData.paymentTypeKind || undefined} // TODO provider should look at formData instead
          onChange={(provider, bankAccount, billingAddress) => {
            setFormData({
              ...formData,
              provider: provider,
              providerBankAccount: bankAccount,
              providerBillingAddress: billingAddress
            });
          }} 
          formData={formData}
          disabled={isSaving}
        />
        <PaymentType 
          formData={formData}
          paymentTypes={paymentTypes}
          onChange={paymentType => {
            setFormData({...formData, paymentType});
          }}
        />
        <Amount 
          formData={formData} 
          onChange={amount => setFormData({...formData, amount})}
          disabled={isSaving}
        />
        <Purpose 
          formData={formData}
          onChange={(referenceNumber, paymentPurpose) => setFormData({...formData, referenceNumber, paymentPurpose})}
          disabled={isSaving}
        />
        <Dates 
          formData={formData}
          onChangeInvoiceDate={(invoiceDate) => setFormData({...formData, invoiceDate})}
          onChangeDueDate={(dueDate) => setFormData({...formData, dueDate})}
          disabled={isSaving}
        />
        <Tr>
          <Td label>Notizen</Td>
          <Td>
            <TextArea 
              rows={5} 
              value={formData.notes} 
              onChange={notes => setFormData({...formData, notes})} 
              disabled={isSaving} 
            />
          </Td>
        </Tr>
        <Payments invoice={invoice} />
        <Tr>
          <Td label> </Td>
          <Td>
            <Button size="medium" onClick={onClickSave} disabled={isSaving || isInvalid} busy={isSaving}>
              {invoice ? "Änderungen speichern" : "Rechnung erzeugen"}
            </Button>
          </Td>
        </Tr>
      </Table>
    </CardSection>
    {allocations}
  </>
}

function CopyInvoiceLink({invoice}:{invoice:TS.VendorInvoice|null}) {
  if(invoice) {
    return (
      <Tr>
        <Td></Td>
        <Td>
          <ClipboardButton text={`${Config.site.url}/vendorinvoices?invoice=${invoice._id}`} size="small">Link zur Rechnung kopieren</ClipboardButton>
        </Td>
      </Tr>
    )
  }
  return null;
}

/**
 * returns the target folder for drive upload
 */
function getDriveFolder(form: FormData): {id:string, path:any, year:number} {
  // the target folder for drive upload
  //let d = this.entity.invoiceDate || new Date()
  // if(!(invoiceDate instanceof Date)) {
  //   invoiceDate = new Date(d)
  // }
  let folder = Config.drive.receiptFolders.find((f:any) => {
    return f.year.toString() === form.invoiceDate.getFullYear().toString()
  })
  if(!folder) {
    folder = Config.drive.receiptRootFolder
  }

  // done
  return folder;
}

export function getDriveTitle(formData:FormData): string {
  
  // the file extension
  let extension = '.???';
  if(formData.fileSelectedInfo) {
    const info = formData.fileSelectedInfo;
    const selectedFileName = info.source === "drive" ? info.driveFile!.name : info.localFile!.name;
    const filenameParts = selectedFileName.split('.');
    if(filenameParts.length > 1) {
      extension = '.' + filenameParts[filenameParts.length - 1];
    }
  }
  // TODO get provider name
  let providerName = "???";
  if(formData.provider) {
    providerName = formData.provider.name;
  }

  // concat
  return `${Util.printDatePrefix(formData.invoiceDate)}-${providerName}${extension}`;
}

function getFormData(invoice: TS.VendorInvoice|null, provider: DA.Provider|null): FormData {
  if(!invoice) {
    return {
      isUpdate: false,
      isReadOnly: false,
      isOutgoing: false,
      isPaid: false,
      paymentTypeKind: "bank",
      provider: null,
      providerBankAccount: null,
      providerBillingAddress: null,
      paymentType: null,
      amount:0, 
      referenceNumber: null,
      paymentPurpose: null,
      notes: "", 
      invoiceDate: new Date(), 
      dueDate: new Date(), 
      driveId:"",
      fileSelectedInfo: null,
    }
  }
  else {
    return {
      isUpdate: true,
      isReadOnly: invoice.isOutgoing || invoice.isPaid, 
      isOutgoing: invoice.isOutgoing,
      isPaid: invoice.isPaid,
      paymentTypeKind: null, // no longer relevant when updating
      provider: provider,
      providerBankAccount: invoice.provider.bankAccount,
      providerBillingAddress: invoice.provider.billingAddress,
      paymentType: null, // can no longer be changed (only relevant when creating an invoice to create the first and only creditcard or employee payment )
      amount:invoice.amount, 
      referenceNumber: invoice.referenceNumber, // TODO not sure, should that not be nullabel in TS.VendorInvoice?
      paymentPurpose: invoice.paymentPurpose, // TODO not sure, should that not be nullabel in TS.VendorInvoice?
      notes:invoice.notes, 
      invoiceDate:invoice.invoiceDate || new Date(), 
      dueDate: invoice.dueDate || new Date(), 
      driveId: "",
      fileSelectedInfo: null, // can no longer be changed (only relevant when creating an invoice)
    }
  }
}

function validate(formData: FormData): boolean {
  // validate reference number & payment purpose
  const refOrPurpose = validateReferenceNumberAndPaymentPurpose(formData);
  // mount
  const amount = formData.amount > 0;
  // dates
  const invoiceDate = moment(formData.invoiceDate).isValid();
  const dueDate = moment(formData.dueDate).isValid();
  // file selected? only relevant when creating
  const fileSelected = formData.isUpdate ? true : formData.fileSelectedInfo !== null;
  // provider
  const providerValidation = validateProviderAndAccount(formData);
  const provider = providerValidation.valid;

  // validate
  //console.log({amount, invoiceDate, dueDate, provider, fileSelected, refOrPurpose})
  const isValid = amount && invoiceDate && dueDate && provider && fileSelected && refOrPurpose;
  return isValid;
}

function validateReferenceNumberAndPaymentPurpose(formData: FormData): boolean {
  // check only when not read only and when dealing with a bank payment
  if(formData.isReadOnly === false && formData.paymentTypeKind === "bank") {
    if(formData.paymentPurpose && Validators.isSepaCompliant()(formData.paymentPurpose).valid) {
      return true;
    }
    else if(formData.referenceNumber && Validators.isPainReferenceNumber("")(formData.referenceNumber).valid) {
      return true;
    }
    return false;
  }
  else {
    // we can assume it is valid
    return true;
  }
}

export function validateProviderAndAccount(formData: FormData): Validation {
  let valid = true;
  let message = "";
  if(formData.provider === null) {
    valid = false;
    message = "Bitte Anbieter wählen"
  }
  else if(formData.isUpdate && formData.paymentTypeKind === "bank" && formData.providerBankAccount === null) {
    // invoices that are going to be paid via bank need bank account
    // note: once created we can no longer need to validate this because we longer have a concept kind of payment this invoice belongs to
    valid = false;
    message = "Bitte Konto wählen"
  }
  return { valid, message};
}

async function save(formData: FormData, invoice: TS.VendorInvoice|null): Promise<{success:boolean, vendorInvoice:TS.VendorInvoice|null}> {
  
  // TODO what's with the following 3 lines of comments?
  // create object to save ...
  // - driveId and driveTitle depending on create or update
  // - provider and bankAccount depending on create or update

  // update or create
  
  if(!invoice) {
    // Create
    // 1. upload file
    const uploadResult = await uploadFile(formData);
    // 2. create invoice if upload was success
    // TODO should inform user if upload did not work and tell them that invoice was not created
    if(uploadResult.success) {
      // create VendorInvoice instance
      const provider = TS.VendorInvoiceProvider.fromProvider(formData.provider!);
      const vendorInvoice = new TS.VendorInvoice(provider, formData.amount, uploadResult.fileId!, getDriveTitle(formData));
      // dates
      vendorInvoice.invoiceDate = formData.invoiceDate;
      vendorInvoice.dueDate = formData.dueDate;
      // notes
      vendorInvoice.notes = formData.notes;
      // some properties are only relevant for bank payments
      if(formData.paymentTypeKind === "bank") {
        vendorInvoice.provider.bankAccount = formData.providerBankAccount;
        vendorInvoice.provider.billingAddress = formData.providerBillingAddress;
        vendorInvoice.paymentPurpose = formData.paymentPurpose || "";
        vendorInvoice.referenceNumber = formData.referenceNumber || "";
      }
      // add one payment entry if we deal with cc or employee prepayment
      if(formData.paymentTypeKind === "creditcard" || formData.paymentTypeKind === "employee") {
        if(formData.paymentType) {
          const payment = new TS.VendorInvoicePayment();
          payment.paymentDate = new Date();
          payment.amount = formData.amount,
          payment.paymentType = formData.paymentType;
          vendorInvoice.payments = [payment];
        }
      }

      // create it
      const createResult = await TS.VendorInvoice.create(vendorInvoice);
      if(createResult.success) {
        return {success:true, vendorInvoice: createResult.data!}
      }
      else {
        console.error("Error attempting to create VendorInvoice:", createResult.error)
        alert("Rechnung konnte nicht erzeugt werden");
        return {success:false, vendorInvoice: null};
      }
    }
    else {
      return {success:false, vendorInvoice: null};
    }
  }
  else {
    // Update
    // we do NOT change driveId, driveTitle, or provider info when updating 
    const changeset:any = {
      amount: formData.amount,
      invoiceDate: formData.invoiceDate,
      dueDate: formData.dueDate,
      referenceNumber: formData.referenceNumber,
      paymentPurpose: formData.paymentPurpose,
      notes: formData.notes 
    }
    if(formData.providerBankAccount) {
      changeset.provider = invoice.provider;
      changeset.provider.bankAccount = formData.providerBankAccount;
    }

    // save changes
    const updateResult = await TS.VendorInvoice.update(invoice._id!, changeset);
    if(updateResult.success) {
      return {success:true, vendorInvoice: updateResult.data!};
    }
    else {
      console.error("Error attempting to create VendorInvoice:", updateResult.error)
      alert("Rechnung konnte nicht gespeichert werden");
      return {success:false, vendorInvoice: null};
    }
  }
}

async function uploadFile(formData:FormData): Promise<any> {
  let uploadResult: {success:boolean, fileId?:string};
  
  // abort if no file selected
  if(formData.fileSelectedInfo === null) {
    return {success:false};
  }

  // upload file
  if(formData.fileSelectedInfo.source === "local") {
    // local file
    const options = {
      selectedFile:formData.fileSelectedInfo.localFile!, 
      fileTitle:getDriveTitle(formData), 
      folderId:getDriveFolder(formData).id
    }
    uploadResult = await DriveUploadHelper.uploadLocalFile(options);
  }
  else {
    // move selected drive file
    const selectedFile = formData.fileSelectedInfo.driveFile!;
    const options = {
      fileId: selectedFile.id, 
      fileTitle:getDriveTitle(formData), 
      folderId:getDriveFolder(formData).id
    }
    uploadResult= await DriveUploadHelper.moveDriveFile(options);
  }

  // check if upload successful
  if(!uploadResult.success) {
    // TODO output some info
    alert("upload failed");
    console.error("upload failed");
  }

  return uploadResult;
}