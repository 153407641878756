import React, { useContext, useState, useEffect } from "react";
import { ActivityContext } from "./ActivityContext";
import { Alert, Button, Form, Dropdown, TextInput, TextArea, NumberInput, Loader, Modal, Icon, Icons, ConfirmButton, Link, DropdownOption, CardSection, CloudStorageUploader, Table, Tr, Td, Row } from "../../controls";
import * as TS from "../../../types";
import CSS from "./Activity.Stories.module.scss";

export default function ActivityStories() {
  // context
  const AC = useContext(ActivityContext)!;

  // state
  const [stories, setStories] = useState<TS.ActivityStory[]|null>(null);
  const [storyToEdit, setStoryToEdit] = useState<TS.ActivityStory|null>(null);

  // mount / activity changes in context
  useEffect(() => {
    if(AC.activity) {
      setStories(AC.activity.stories);
    }
  }, [AC.activity])

  // starts editing
  const startEdit = (story?: TS.ActivityStory) => {
    if(!story) {
      story = new TS.ActivityStory();
      story.countries = ["CH", "DE"]; 
    }
    setStoryToEdit(story);
  }

  // stops edit mode
  const stopEdit = () => setStoryToEdit(null);
  /*
  // user wants to add a story
  const onClickAdd = async() => {
    const newStory = new TS.ActivityStory();
    newStory.countries = ["CH", "DE"]; 
    setStoryToEdit(newStory);
  }

  const onClickEdit = async(story: TS.ActivityStory) => {
    setStoryToEdit(story);
  }
  */

  const onClickDelete = async(story: TS.ActivityStory) => {
    await AC.deleteStory(story);
  }

  // saves a story
  const onSave = async(story:TS.ActivityStory) => {
    await AC.upsertStory(story);
    setStoryToEdit(null);
  }

  // pre-render
  let content;
  if(stories === null) {
    content = <Loader text="lade Erfahrungsberichte" />
  }
  else {
    const items = stories.sort((a, b) => a.position - b.position).map((story: TS.ActivityStory) => {
      return <Item key={story._id} story={story} onClickEdit={startEdit} onClickDelete={onClickDelete} />
    })
    content = (
      <div>
        <div>{items}</div>
        <Button onClick={() => startEdit()}><Icon icon={Icons.Plus} />&nbsp;Erfahrungsbericht hinzufügen</Button>
      </div>
    )
  }

  // render
  return <>
    <CardSection title="Erfahrungsberichte" className={CSS.container}>
      {content}
    </CardSection>
    <Modal
      title="Erfahrungsbericht"
      isOpen={storyToEdit !== null}
      onClose={stopEdit}
    >
      <Edit story={storyToEdit!} onSave={onSave} onCancel={stopEdit} />
    </Modal>
  </>
}


type ItemProps = {
  story: TS.ActivityStory,
  onClickEdit: (story:TS.ActivityStory) => void,
  onClickDelete: (story:TS.ActivityStory) => void
}
function Item({story, onClickEdit, onClickDelete}:ItemProps) {
  // pre-render
  const videoIFrame = story.youtubeVideoId.length === 0 ? "-" : <iframe width="280" height="158" src={`https://www.youtube.com/embed/${story.youtubeVideoId}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title={story.youtubeVideoId}></iframe>;
  
  return (
    <div className={CSS.item}>
      <Table>
        <Tr>
          <Td label>Länder</Td>
          <Td>
            {story.countries.join(", ")}
          </Td>
        </Tr>
        <Tr>
          <Td label>Titel</Td>
          <Td>{story.title.length > 0 ? story.title : "-"}</Td>
        </Tr>
        <Tr>
          <Td label>Zusammenfassung</Td>
          <Td>{story.summary.length > 0 ? story.summary.split('\n').map((l, index) => <div key={index}>{l}</div>) : "-"}</Td>
        </Tr>
        <Tr>
          <Td label>Url</Td>
          <Td>{story.url.length > 0 ? <Link to={story.url}>{story.url}</Link> : "-"}</Td>
        </Tr>
        <Tr>
          <Td label>Bild</Td>
          <Td>{story.imageUrl.length > 0 ? <div className={CSS.image}><img src={story.imageUrl} /></div> : "-" }</Td>
        </Tr>
        <Tr>
          <Td label>Video</Td>
          <Td>{videoIFrame}</Td>
        </Tr>
        <Tr>
          <Td label></Td>
          <Td>
            <Row>
              <ConfirmButton onConfirm={() => onClickDelete(story)}>löschen</ConfirmButton>
              <Button size="small" onClick={() => onClickEdit(story)}>bearbeiten</Button>
            </Row>
          </Td>
        </Tr>
      </Table> 
    </div>
  )
}

type EditProps = {
  story: TS.ActivityStory,
  onSave: Function,
  onCancel: () => void,
}
function Edit({story, onSave, onCancel}:EditProps) {
  // state
  const [entity, setEntity] = useState<any|null>(null);
  const [dropdownOptions] = useState<DropdownOption[]>([
    {label: "-", value:""},
    {label: "Schweiz & Deutschland", value:"CH,DE"},
    {label: "Schweiz", value:"CH"},
    {label: "Deutschland", value:"DE"}
  ])

  // mount
  useEffect(() => {
    const entity = story.toDb();
    entity.__countriesJoined = story.countries.sort((a,b) => a.localeCompare(b)).join(",");
    setEntity(entity);
  }, [story]);

  // user wants to save
  const onFormSave = async(formResult:any) => {
    const updated = formResult.merge(entity);
    updated.countries = updated.__countriesJoined.split(",");
    const updatedStory = TS.ActivityStory.fromDb(updated);
    await onSave(updatedStory);
  }
  
  // render the form
  return (
    <Form entity={entity} onSave={onFormSave} onCancel={onCancel}>
      <Dropdown label="Länder" path="__countriesJoined" options={dropdownOptions} />
      <TextInput label="Titel" path="title" />
      <TextArea label="Zusammenfassung" path="summary" />
      <TextInput label="Url" path="url" />
      <CloudStorageUploader kind="image" label="Bild" path="imageUrl" folder="activities" prefix="" disableUrlEditing={false} />
      <TextInput label="YouTube Video Id" path="youtubeVideoId" explanation="bitte nur die Video Id eingeben, nicht die ganze Url" />
      <NumberInput label="Position" path="position" />
      <Alert intent="info" size="small" title="Keines der Felder ist ein Pflichtfeld - im Shop wird angezeigt, was vorhanden ist.">
        <ul>
          <li>wenn eine Url vorhanden ist, wird der Ehrfahrungsbericht verlinkt, sonst nicht</li>
          <li>sind sowohl Video und Bild angegeben, wird das Video angezeigt</li>
        </ul>
      </Alert>
    </Form>
  )
}