import Api from "../util/api2"; // TODO there should be not Api calls in here, use DataAccess instead
import Util from "../util/util";
import * as BL from "../types/BusinessLogic";

export class Mailfail {
  static async getUnresolved(): Promise<any> {
    // const filter = {status:{$in:["delivery-failed", "posting-failed"]}, isTest:false};
    const filter = {$or:[{status:"delivery-failed"}, {status:"posting-failed"}]};
    // TODO should use DataAccess
    const result = await Api.post("mail", "search", {filter});
    if(result.success) {
      return result.data.items.filter((item:any) => !item.isTest).sort((a:any,b:any) => Util.sortDate(a.createdAt, b.createdAt));
    }
    else {
      console.error(result.error.message);
      return [];
    }
  }

  static async resolve(id:any): Promise<any> {
    const operator = await BL.User.getCurrent();
    const result = await Api.post("mail", "resolve", {id, operator:operator.email});
    return result.success;
  }

  static getStatusName(statusKey:string) {
    switch(statusKey) {
      case "accepted": // received by our mail service (mailgun)
        return "akzeptiert"; 
      case "delivered":
        return "zugestellt";
      case "delivery-failed": // probably blocked by receiver's mail server
        return "Zustellung gescheitert";
      case "posting-failed": // mostly likely due to invalid e-mail address
        return "Verschicken gescheitert";
      case "resolved":
        return "Problem behoben";
      default:
        return "unbekannt";
    }
  }
}
