import React, {useContext, useState} from "react";
import {ConfirmButton} from "../../../../controls";
import {SurpriseContext} from "../../Surprise.Context";
import * as TS from "../../../../../types";

export default function SendPostponedMail() {
  // context
  const SC = useContext(SurpriseContext);

  // state
  const [info, setInfo] = useState(null);
  const [busy, setBusy] = useState(false);

  // event handlers
  const sendMail = async() => {
    setBusy(true);
    const result = await SC.sendPostponedMail();
    setBusy(false);
    if(result.success) {
      setInfo("Das Mail wurde erfolgreich verschickt.");
    }
    else {
      setInfo("Das Mail konnte nicht verschickt werden. Fehler: " + JSON.stringify(result.error));
    }
  }

  // do not render if status does not fit
  if(![TS.AdventureStatus.ConditionsSent, TS.AdventureStatus.DateSelected, TS.AdventureStatus.Ready].includes(SC.surprise.Status) ) {
    return null;
  }
  // render
  return (
    <div>
      <ConfirmButton onConfirm={sendMail} busy={busy}>Verschoben-wegen-Wetter Mail verschicken</ConfirmButton>
      {info ? <div style={{fontSize:"10px", padding:"0 4px 0 4px"}}>{info}</div> : null}
    </div>
  );
}

