import React, { useEffect, useState } from "react";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";
import { Alert, List, ListColumn, Modal } from "../../controls";
import { ProviderLink } from "../../common";
import Details from "./CollectiveInvoices.Details";

type ListItem = {
  id: string,
  refNr: string,
  providerName: string,
  providerId: string,
  period: string,
  periodDate: Date,
  amount: number,
  status: TS.CollectiveInvoiceStatus
}

type CollectiveInvoicesListProps = {
  collectiveInvoices: TS.CollectiveInvoice[],
  hideProvider?: boolean,
  onChanged: () => void
}
export default function CollectiveInvoicesList({collectiveInvoices, onChanged, hideProvider}: CollectiveInvoicesListProps) {
  // state
  const [items, setItems] = useState<ListItem[]>([]);
  const [itemToEdit, setItemToEdit] = useState<TS.CollectiveInvoice|null>(null);
  const [listColumns, setListColumns] = useState<ListColumn[]>([]);

  // mount with invoices
  useEffect(() => {
    const items = collectiveInvoices.map(ci => getListedCollectiveInvoice(ci));
    setItems(items);
  }, [collectiveInvoices]);
  // mount with changed options
  useEffect(() => {
    const listColumns: ListColumn[] = [
      {header:"Ref-Nr.", path:"refNr"},
      {header:"Periode", path:"period", sort:(a: ListItem, b:ListItem) => a.periodDate > b.periodDate ? 1 : -1},
      {header:"Betrag", align:"right", path:"amount", sort:(a:ListItem, b:ListItem) => a.amount > b.amount ? 1 : -1},
      {header:"Status", path:"status"}
    ];
    if(!hideProvider) {
      listColumns.unshift({
        header:"Anbieter", 
        path:"providerName", 
        sort:(a:ListItem,b:ListItem) => a.providerName.localeCompare(b.providerName),
        print:(item:ListItem) => <ProviderLink provider={{_id:item.providerId, name:item.providerName} as DA.Provider} />
      });
    }
    setListColumns(listColumns);
  }, [hideProvider])

  // user wants to delete an item
  const onClickEdit = (lci: ListItem) =>  {
    const invoice = collectiveInvoices.find(ci => ci._id === lci.id);
    if(invoice) {
      setItemToEdit(invoice);
      // const component = <Details collectiveInvoice={invoice} onChanged={onChanged} />
      // MC.push("Monatsabrechnung", component, undefined, true);
    }
  }

  // closes modal dialog
  const closeDialog = () => {
    setItemToEdit(null);
  }

  // pre-render
  let content
  if(items.length === 0) {
    content = <Alert size="medium" intent="info">Keine Monatsabrechnungen gefunden</Alert>;
  }
  else {
    content = <>
      <List 
        getKey={item => item.id}
        columns={listColumns}
        items={items}
        onClickEdit={onClickEdit}
      />
      <Modal isOpen={itemToEdit !== null} onClose={closeDialog} title="Monatsabrechnung">
        <Details collectiveInvoice={itemToEdit!} onChanged={onChanged} closeDialog={closeDialog} />
      </Modal>
    </>;
  }

  // render
  return <>
    <Alert intent="info">
      Hier kommt dann noch die Möglichkeit zu filtern nach Periode, Status, Anbieter, etc
    </Alert>
    {content}
  </>
}

function getListedCollectiveInvoice(ci:TS.CollectiveInvoice) : ListItem {
  const amount = ci.items.reduce((prev, curr) => {
    return prev + curr.amount;
  }, 0);
  const period = `${ci.period.month + 1}/${ci.period.year}`;
  const periodDate = new Date(ci.period.year, ci.period.month);
  const status = TS.CollectiveInvoice.printStatus(ci);
  
  return {
    id: ci._id!,
    refNr: ci.referenceNumber,
    providerName: ci.provider_name,
    providerId: ci.provider_id,
    period,
    periodDate,
    amount,
    status,
  }
}


