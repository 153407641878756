import React, { useState, useEffect, useContext, useMemo } from "react";
import { ProviderContext } from "./Provider.Context";
// components
import {Card, Form, Value, Dropdown, TextArea, TextInput, Validators, Modal } from "../../controls";
// styling
import "./Provider.BookingSettings.scss";

// the main component
export default function BookingSettings() {
  // context
  const PC = useContext(ProviderContext);

  // state
  const [booking, setBooking] = useState<any>({kind:"", value:"", notes: ""});
  const [mode, setMode] = useState<"edit"|"view">("view");

  // mount with provider
  useEffect(() => {
    const b = (PC.provider.booking || [])[0];
    if(b) {
      setBooking(b);
    }
  }, [PC.provider]);

  const startEdit = () => setMode("edit");
  const stopEdit = () => setMode("view");

  // render
  // TODO use business model class to get name of booking kind (see "Buchungsanfragen via" below)
  return <>
    <Card title="Buchung Einstellungen" expanded={false} collapsible={true}>
      <Value onClick={startEdit} label="Buchungsanfragen Einstellungen">
        <div id="v-provider-bookingsettings">
          <table><tbody>
            <tr>
              <th>Buchungsanfragen via</th>
              <td>{{"":"", "phone":"Telefon", "email":"E-Mail","web":"Web"}[booking.kind as string]}: {booking.value}</td>
            </tr>
            <tr>
              <th>Anmerkungen</th>
              <td>{booking.notes}</td>
            </tr>
          </tbody></table>
        </div>
      </Value>
    </Card>
    <Modal isOpen={mode === "edit"} title="Buchungsanfragen-Einstellungen bearbeiten" onClose={stopEdit}>
      <BookingSettingsEdit closeDialog={stopEdit} />
    </Modal>
  </>
}

// the edit dialog
type BookingSettingsEditProps = {
  closeDialog: () => void
}
function BookingSettingsEdit({closeDialog} : BookingSettingsEditProps) {
  // context
  const PC = useContext(ProviderContext);

  // state
  const [entity, setEntity] = useState<any>({kind:"", value: "", notes:"", order:1});
  const [busy, setBusy] = useState<boolean>(true);
  const [valueInfo, setValueInfo] = useState({label:"", placeholder: ""});

  // memo options
  const kindOptions = useMemo(() => {
    return [
      {label: "-", value: ""},
      {label: "E-mail", value: "email"},
      {label: "Telefon", value: "phone"},
      {label: "Web", value: "web"}
    ]
  }, []);
  
  // mount
  useEffect(() => {
    if(PC.provider) {
      const booking = (PC.provider || []).booking[0];
      if(booking) {
        setEntity({
          kind: booking.kind,
          value: booking.value,
          notes: booking.notes,
          order: booking.order || 0
        });
        onKindChange(booking.kind);
      }
      setBusy(false);
    }
  }, [PC.provider]);

  // kind changed
  const onKindChange = (kind:any) => {
    switch(kind) {
      case "phone":
        setValueInfo({label:"Telefonnummer", placeholder: "Telefonnummer eingeben"});
        break;
      case "web":
        setValueInfo({label:"Adresse der Webseite", placeholder: "Url eingeben"});
        break;
      case "email":
        setValueInfo({label:"E-Mail", placeholder: "E-Mail Adresse eingeben"});
        break;
      default:
        setValueInfo({label:"", placeholder: ""});
        break;
    }
  }

  const onSave = async(formResult:any) => {
    // Note: the provider.bookings property is an array, we only ever save a single value however, that is why we overwrite the whole array here
    setBusy(true);
    const bookingItem = formResult.merge(entity);
    const changeset = {booking:[bookingItem]};
    await PC.update(changeset);
    closeDialog();
  }

  // render
  return <>
    <Form 
      busy={busy} 
      entity={entity} 
      onSave={onSave} 
      labelSave="speichern" 
      onCancel={closeDialog} 
      labelCancel="abbrechen"
    >
      <Dropdown disabled={busy} label="Buchungsanfragen via" path="kind" options={kindOptions} onChange={onKindChange} />
      <TextInput disabled={busy} path="value" label={valueInfo.label} validate={Validators.isRequired("Bitte einen Namen eingeben")} placeholder={valueInfo.placeholder}  />
      <TextArea disabled={busy} path="notes" label="Notizen" placeholder={`z.B. Benutzername & Passwort für Buchungssystem oder "immer auf den Namen 'Stefan Flück' reservieren"`} rows={3} />
    </Form>
  </>
}
