import React, { useEffect, useState } from "react";
import * as DA from "../../../../types/DataAccess";
import Config from "../../../../config"
import { Button, CardSection, List, ListColumn, Loader, DriveLink, DriveUpload, DriveDelete, Form, TextInput, Validators, TextArea, Modal, Validation } from "../../../controls";


const columns: ListColumn[] = [
  {
    header: "Name",
    path: "driveTitle",
    sort: (a:DA.AdventureDocument, b:DA.AdventureDocument) => a.driveTitle.localeCompare(b.driveTitle),
    print: (v:DA.AdventureDocument) => <DriveLink driveId={v.driveId!}>{v.driveTitle}</DriveLink>
  },
  {
    header: "Notizen",
    path: "notes",
    sort: (a:DA.AdventureDocument, b:DA.AdventureDocument) => a.notes.localeCompare(b.notes),
    print: (v:DA.AdventureDocument) => v.notes.split('\n').map((s, i) => <div key={i}>{s}</div>)
  }
];

type DocumentProps = {
  adventureId: string
}
export default function Documents({adventureId} : DocumentProps) {
  const [mode, setMode] = useState<"view"|"edit"|"create"|"delete">("view");
  const [document, setDocument] = useState<DA.AdventureDocument|null>(null);
  // state
  const [items, setItems] = useState<DA.AdventureDocument[]>([]);

  // mount
  useEffect(() => {
    load();
  }, [adventureId]);

  // loads items
  const load = async() => {
    const items = await DA.AdventureDocumentRepository.findByAdventureId(adventureId);
    setItems(items);
  }

  // user wants to edit
  const onClickEdit = (doc:DA.AdventureDocument) => {
    setDocument(doc);
    setMode("edit");
  }

  // user wants to delete
  const onClickDelete = (doc:DA.AdventureDocument) => {
    setDocument(doc);
    setMode("delete");
  }

  // user wants to create
  const onClickCreate = () => {
    setMode("create");
    
  }
  const stopEdit = () => setMode("view");

  // pre-render modal stuff
  let modalTitle = "";
  let modalContent = null;
  switch(mode) {
    case "create":
      modalTitle = "Dokument erfassen";
      modalContent = <Create adventureId={adventureId} loadList={load} stopEdit={stopEdit} />
      break;
    case "edit":
      modalTitle = "Dokument bearbeiten";
      modalContent = <Edit document={document!} loadList={load} stopEdit={stopEdit} />;
      break;
    case "delete":
      modalTitle = "Dokument löschen";
      modalContent = <Delete document={document!} loadList={load} stopEdit={stopEdit} />;
      break;
  }
  // render
  return <>
    <CardSection title="Dokumente">
      <List 
        columns={columns} 
        items={items} 
        getKey={(item:DA.AdventureDocument) => item._id!} 
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
        onClickCreate={onClickCreate}
        hideSearch 
        hideFooter 
      />
    </CardSection>
    <Modal isOpen={mode!=="view"} title={modalTitle} onClose={stopEdit}>
      {modalContent}
    </Modal>
  </>
}


type CreateProps = {
  adventureId: string,
  loadList: () => void,
  stopEdit: () => void,
}
function Create({adventureId, loadList, stopEdit}:CreateProps) {
  // state 
  const [isBusy, setIsBusy] = useState(false);
  const [title, setTitle] = useState<string>("");
  const [titleValid, setTitleValid] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>("");
  const [extension, setExtenion] = useState<string>("");
  
  const onCancel = () => {
    stopEdit();
  }

  const onChangeTitle = (value:string, validation:Validation ) => {
    setTitle(value);
    setTitleValid(validation.valid);
  }

  const onFileSelected = (e:any) => {
    // a local file was selected
    if(e.source === "local") {
      const file = e.file;
      // get the extension (we make that the input's postfix)
      const filenameParts = file.name.split('.');
      let extension = "";
      if(filenameParts.length > 1) {
        extension = '.' + filenameParts[filenameParts.length - 1];
      }
      setExtenion(extension);
    }

    // a drive file was selected
    if(e.source === "drive") {
      // let file = e.file
      // We do not support this yet
    }
  }

  const onUploadStarted = () => {
    setIsBusy(true);
  }

  const onUploadFailed = () => {
    setIsBusy(false);
    alert("Upload fehlgeschlagen");
  }

  const onUploadComplete = async(file:any) => {
    // create the document entry
    const driveId = file.id;
    const document = DA.AdventureDocumentRepository.make();
    document.surprise_id = adventureId;
    document.driveId = driveId;
    document.driveTitle = title + extension;
    document.notes = notes;
    await DA.AdventureDocumentRepository.create(document);
    // reload list and pop dialog
    loadList();
    stopEdit();
  }


  // pre-render
  let content = null;
  if(isBusy) {
    content = <Loader />;
  }
  else {
    content = (
      <DriveUpload 
        clientId={Config.drive.clientId}
        folder={Config.drive.surpriseDocsRootFolder.id}
        subfolderName={adventureId} 
        labelUpload="Dokument speichern"
        rename={() => title + extension}
        describe={() => "Dokument zu Überraschung " + adventureId}

        onUploadStarted={onUploadStarted}
        onUploadComplete={onUploadComplete}
        onUploadFailed={onUploadFailed}
        onFileSelected={onFileSelected}
        
        disableUpload={!titleValid}
        disableDriveFiles={true}
      >
        <TextInput value={title} onChange={onChangeTitle} label="Titel" validate={Validators.isRequired("Bitte Name für das File eingeben")} />
        <TextArea value={notes} onChange={setNotes} label="Notizen" />
        <Button onClick={onCancel} size="small" intent="cancel">abbrechen</Button>

      </DriveUpload>
    );
  }
  // render
  return content;
}


type EditProps = {
  document: DA.AdventureDocument,
  loadList: () => void,
  stopEdit: () => void,
}
function Edit({document, loadList, stopEdit}: EditProps) {
  // state
  const [entity] = useState<any>(document);

  // saves item
  const onSave = async(formResult:any) => {
    if(formResult.changeset.notes) {
      await DA.AdventureDocumentRepository.update(document._id!, formResult.changeset);
      loadList();
    }
    stopEdit();
  }

  return (
    <Form entity={entity} onCancel={stopEdit} onSave={onSave}>
      <TextArea label="Notizen" path="notes" />
    </Form>
  ) 
}


type DeleteProps = {
  document: DA.AdventureDocument,
  loadList: () => void,
  stopEdit: () => void,
}
function Delete({document, loadList, stopEdit}: DeleteProps) {
  // state
  const [isBusy, setIsBusy] = useState(false);
  
  const onCancel = () => {
    stopEdit();
  }

  const onFileDeleted = async() => {
    setIsBusy(true);
    await DA.AdventureDocumentRepository.remove(document._id!);
    loadList();
    stopEdit();
  }

  const onFileDeleteFailed = () => {
    alert("Das Dokument konnte nicht gelöscht werden");
    stopEdit();
  }


  // pre-render
  let content = null;
  if(isBusy) {
    return <Loader />;
  }
  else {
    content = (
      <DriveDelete 
        labelDelete="löschen"
        clientId={Config.drive.clientId}
        fileId={document.driveId!}
        onDeleteCompleted={onFileDeleted}
        onDeleteFailed={onFileDeleteFailed}
        onCancel={onCancel}
      >
        <Button onClick={onCancel} size="small" intent="cancel">abbrechen</Button>&nbsp;
      </DriveDelete>
    );
  }
  // render
  return content;
}
