import React, { useContext } from "react";
import { TextInput  } from "../../../../controls";
import { SearchContext } from "../Search.Context";
import FilterGroup from "../Search.FilterGroup";

export default function NameOrEmail() {
  // context
  const Search = useContext(SearchContext);

  // onChange
  const onChange = (nameOrEmail:string) => {
    const filter = Search.filter.clone();
    filter.nameOrEmailContains = nameOrEmail;
    Search.reset(filter);
  }

  // render
  return (
    <FilterGroup title="Name / E-Mail Beschenkter / Schenker">
      <TextInput placeholder="Name / E-mail" onChange={onChange} value={Search.filter.nameOrEmailContains} />
    </FilterGroup>
  )
}