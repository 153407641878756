import _ from "lodash";
import React, {useState, useContext} from "react"

// components and contexts
import { Alert, Loader, CardSection, CloudStorageUploader } from "../../controls";
import { ActivityPackageContext } from "./ActivityPackage.Context";

export default function Texts() {
  // context
  const AC = useContext(ActivityPackageContext);

  // state
  const [busy, setBusy] = useState(false);

  // image uploaded
  const onUploadComplete = async(imageUrl:string) => {
    if(AC && AC.activityPackage) {
      AC.activityPackage.image.url = imageUrl;
      setBusy(true);
      await AC.save();
      setBusy(false);
    }
  }
  
  // no data? render loader
  if(!AC || !AC.activityPackage) {
    return <Loader />
  }

  // pre-render 
  let image = null;
  let noImage = null;
  if(AC.activityPackage.image.url) {
    image = <div className="image-container"><img src={AC.activityPackage.image.url} alt={AC.activityPackage.title} /></div>
  }
  else {
    noImage = <Alert title="Noch kein Bild" intent="warning" size="medium">
      Bitte ein Bild mit Seitenverhältnis 1:1 hochladen. Mindestes 512px x 512px und nicht grösser als 1024px x 1024px Pixel sein
    </Alert>
  }

  // render
  // TODO the button does nothing ...
  return (
    <CardSection title="Bild">
      <div id="v-activitypackage-edit-image">
        {noImage}
        <CloudStorageUploader kind="image" value={AC.activityPackage.image.url || ""} folder="activities" prefix="" onChange={onUploadComplete} disableUrlEditing={true} />
      </div>
    </CardSection>
  )
}
