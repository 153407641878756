import React from "react";
import { Icon, Icons } from ".";
import CSS from "./PdfLink.module.scss";

type PdfLinkProps = {
  children: React.ReactNode|React.ReactNode[],
  to: string
}
export function PdfLink({to, children} : PdfLinkProps) {
  return (
    <a className={CSS.pdfLink} target="_blank" rel="noopener noreferrer" href={to}>
      {children}&nbsp;<Icon icon={Icons.Pdf} />
    </a>
  )
}