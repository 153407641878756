import React, {useState} from "react"
import Config from "../config"
import { Link } from "./controls";

export default function SidebarStatus() {

  // render
  return (
    <div className="environmentinfo">
      <div>release: R{Config.meta.release}</div>
      <div>environment: {Config.environment}</div>
      <div>api root: {Config.api.ApiRoot}</div>
      <div>
        <Link to="https://app.clickup.com/4757339/v/dc/4h5uv-5541/4h5uv-2347" target="_blank">release notes</Link>
      </div>
      <div>
        <Link to="https://sentry.io/appentura/api/" target="_blank">exception log</Link>
      </div>
    </div>
  )
}
