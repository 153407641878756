import React, {useContext, useState} from "react";
import * as TS from "../../../../../types";
import * as BL from "../../../../../types/BusinessLogic";
import { CardSection, ConfirmButton, Alert } from "../../../../controls";
import { SurpriseContext } from "../../Surprise.Context";

export default function MoveToReady() {
  // context
  const SC = useContext(SurpriseContext);
  // state
  const [busy, setBusy] = useState(false);

  // event handler
  const updateStatus = async() => {
    setBusy(true);
    const currentUser = await BL.User.getCurrent();
    const logEntry = { Type:"status", Text:`in den Status 'Bereit' gesetzt durch ${currentUser.email}`, Operator:"robot"};
    const nextStatus = TS.AdventureStatus.Ready;
    await SC.updateStatus(nextStatus, true, logEntry);
    setBusy(false);
  }
  
  // not in the right status? render nothing.
  if(SC.surprise.Status !== TS.AdventureStatus.ConditionsAccepted) {
    return null
  }

  // any errors? 
  const errors = [];
  if((SC.surprise.Steps || []).length === 0) {
    errors.push("du hast noch keine Schritte definiert");
  }

  // create info to display
  let info = null;
  let heading = null;
  if(errors.length > 0) {
    const lis = errors.map((error, index) => <li key={index}>{error}</li>)
    info = (
      <Alert intent="error" title="Der Status kann nicht abgeschlossen werden" size="small">
        <ul>{lis}</ul>
      </Alert>
    );
  }
  else {
    info = (
      <Alert intent="info" size="small">
        <ul>
          <li>Der Status wird auf 'Bereit' gewechselt</li>
        </ul>
      </Alert>
    );
  }

  // render
  return ( 
    <CardSection title="Status abschliessen">
      <ConfirmButton onConfirm={updateStatus} busy={busy} disabled={errors.length > 0}>Status abschliessen</ConfirmButton>
      {info}
    </CardSection>
  )
}
