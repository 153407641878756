import _ from "lodash";
import React, {useState, useEffect, useContext} from "react";
import * as DA from "../../../../types/DataAccess";

// context
import { BookingRequestContext } from "./BookingRequest.Context";

// components
import { Button, Icon, Icons, TextInput } from "../../../controls";


type ServiceItemProps = {
  service: DA.BookingRequestService
}
export default function ServiceItem({service} : ServiceItemProps) {
  // context
  const BRC = useContext(BookingRequestContext)

  // state
  const [value, setValue] = useState(service.value);
  const [key, setKey] = useState(service.key);

  // event handlers
  const onChangeKey = (s:string) => {
    service.key = s;
    setKey(s);
  }
  const onChangeValue = (s:string) => {
    service.value = s;
    setValue(s);
  }

  // render
  return (
    <tr className={value.trim().length > 0 ? 'active':'inactive'}>
      <td>
        <TextInput value={key} onChange={onChangeKey} />
      </td>
      <td>
        <TextInput value={value} onChange={onChangeValue} />
      </td>
      <td>
        <Button size="small" intent="delete" onClick={() => BRC!.removeServiceItem(service)}><Icon icon={Icons.Trash} /></Button>
      </td>
    </tr>
  )
}