import _ from "lodash";
import React, {useState, useEffect, useContext} from "react";
import {SurpriseContext} from "./Surprise.Context";
import {Form, DateInput, NumberInput, TextArea, TextInput} from "../../controls";

type GenericEditProps = {
  path: string,
  label: string,
  kind: any,
  validate?: any,
  stopEdit: () => void
} 
export default function GenericEdit({path, label, kind, validate, stopEdit} : GenericEditProps) {
  // context
  const SC = useContext(SurpriseContext);
  
  // state
  const [entity, setEntity] = useState<any>(null);
  
  // mount
  useEffect(() => {
    setEntity({value:_.get(SC.surprise, path) || ""})
  }, []);

  // form save event handler
  const save = async({changeset}:any) => {
    await SC.updateAtPath(path, changeset.value, false);
    stopEdit();
  }

  // the component to use in the form
  let component = null;
  switch((kind || "textinput").toLowerCase()) {
    case "dateinput":
      component = <DateInput label={label} path="value" validate={validate} />
      break;
    case "datetimeinput":
      component = <DateInput label={label} path="value" validate={validate} kind="datetime" />
      break;
    case "textarea":
      component = <TextArea label={label} path="value" validate={validate} />;
      break;
    case "numberinput":
      component = <NumberInput label={label} path="value" validate={validate} />
      break;
    case "textinput":
    default:
      component = <TextInput label={label} path="value" validate={validate} />;
      break;
  }
  
  // render
  return (
    <div id="v-comments-edit">
      <Form entity={entity} onSave={save} onCancel={stopEdit} labelSave="speichern" labelCancel="abbrechen">
        {component}
      </Form>
    </div>
  );
}
