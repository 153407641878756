import { User } from "./User";
import { Mapper } from "../_Common/Mapper";


export class UserMapper extends Mapper<User> {
  public apiEndpoint = "users";

  public toDb(user: User): any {
    return {
      _id: user._id,
      email: user.email.toLowerCase(),
      firstName: user.firstName,
      lastName: user.lastName,
      slackId: user.slackId,
      imageUrl: user.imageUrl,
      isActive: user.isActive,
      isSuperuser: user.isSuperuser
    }
  }

  public fromDb(obj:any): User {
    return {
      _id: obj._id,
      email: (obj.email || "").toLowerCase(),
      firstName: obj.firstName || "",
      lastName: obj.lastName || "",
      slackId: obj.slackId || "",
      imageUrl: obj.imageUrl || "",
      isActive: obj.isActive ? true: false,
      isSuperuser: obj.isSuperuser ? true: false
    }
  }

}