import React, {useContext, useState} from "react";
import * as TS from "../../../../../types";
import { Alert, ConfirmButton, Icon, Icons, Table, Tr, Td, CardSection} from "../../../../controls";
import { SurpriseContext } from "../../Surprise.Context";

export default function ResetMeteochecks() {
  // context
  const SC = useContext(SurpriseContext);

  // state
  const [busy, setBusy] = useState(false);

  // event handlers
  const resetMeteochecks = async() => {
    setBusy(true);
    await SC.resetMeteochecks();
    setBusy(false);
  }

  // not in the right status? render nothing.
  if([TS.AdventureStatus.OrderCancelled, TS.AdventureStatus.Ordered, TS.AdventureStatus.Prepaid].includes(SC.surprise.Status)) {
    return null;
  }

  // render
  return (
    <CardSection title="Meteochecks manuell zurücksetzen">
      <Table>
        <Tr>
          <Td>
            <ConfirmButton onConfirm={resetMeteochecks} busy={busy}>Meteochecks zurücksetzen</ConfirmButton>
          </Td>
        </Tr>
        <Tr>
          <Td>
            <Alert intent="info" size="small">
              Alle Meteochecks werden zurückgesetzt
            </Alert>
          </Td>
        </Tr>
      </Table>
    </CardSection>
  );
}