import React from "react";
import Code from "../Code";
import {Card, CardSection, Heading} from "../../../controls";

export default function CardDemo() {
  // pre-render 
  const card1 = <Card>
    <p>this is a card without title</p>
    <Code>{`
      <Card><p>this is a card without title</p></Card>
    `}</Code>
  </Card>

  const card2 = <Card title="This is a title">
    <p>this is a card with title</p>
    <Code>{`
      <Card title="This is a title"><p>this is a card with title</p></Card>
    `}</Code>
  </Card>

  const card3 = <Card title="This is collapsible" collapsible={true}>
    <p>this is a collapsible card</p>
    <Code>{`
    <Card title="This is collapsible" collapsible={true}>
    <p>this is a collapsible card</p>
  </Card>
  `}</Code>
  </Card>

  const card4 = <Card title="This is collapsible and pre-expanded" collapsible={true} expanded={true}>
    <Code>{`
    <Card title="This is collapsible and pre-expanded" collapsible={true} expanded={true}>
    
    </Card>
    `}</Code>
  </Card>

  const card5 = (
    <Card title="Card with sections" collapsible={true} expanded={true}>
      <CardSection title="Section 1">
        <Code>{`
          <CardSection title="Section 1"></CardSection>
      `}</Code>
      </CardSection>
      <CardSection title="Section 2">
        Hello there
      </CardSection>
    </Card>
  )

  const card6 = (
    <Card title="Small Card" collapsible={true} expanded={true} size="small">
      <CardSection title="Section 1">
        <Code>{`
          <CardSection title="Section 1"></CardSection>
      `}</Code>
      </CardSection>
      <CardSection title="Section 2">
        Hello there
      </CardSection>
    </Card>
  )

  // render
  return <>
    <Heading>Card</Heading>
    {card1}
    {card2}
    {card3}
    {card4}
    {card5}
    {card6}
  </>
}
