import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import * as DA from "../../../../types/DataAccess";
import { Button, Icon, Icons, Loader, NumberInput, Row, Switchboard, TextArea, TextInput, Validators } from "../../../controls";
//import { AssistantContext } from "./Assistant.Context";
import { BookingRequestContext, EmailTexts } from "./BookingRequest.Context";
import ServiceItems from "./BookingRequest.ServiceItems";

import CSS from "./BookingRequest.Form.Email.module.scss";

const GREEN:string = "#54AC60";

type FormEmailProps = {
  adventureInfo:any, activityInfo:any, 
  currentUser: DA.User,
  provider: DA.Provider,
  reloadBookingRequests: Function,
  closeDialog: () => void,
}
export default function FormEmail({adventureInfo, activityInfo, currentUser, provider, reloadBookingRequests, closeDialog} : FormEmailProps) {
  // context
  const BRC = useContext(BookingRequestContext);
  
  // state
  const [mode, setMode] = useState("edit") // "edit" or "preview"

  // mount
  useEffect(() => {
    BRC.load(adventureInfo, activityInfo, provider);
  }, [provider]);

  // save
  const save = async(email:  DA.BookingRequestEmail) => {
    await BRC!.save("email", email);
    reloadBookingRequests();
    closeDialog();
  }

  // render loading
  if(!BRC.isLoaded) {
    return <Loader />;
  }

  // render
  if(mode === "edit") {
    let billingType = null;
    let invoiceValue = null;

    if(BRC.paymentType === "monthly") {
      // the provider has monthly billing, no need to select a billing type
      billingType = <div>Monatsabrechnung</div>;
      // we need a value for the monthly invoice
      invoiceValue = (
        <div>
          <div className="title">Angaben auf Monatsabrechnung</div>
          <NumberInput label={`Betrag in ${provider.collectiveInvoice.currency}`} value={BRC.collectiveInvoice!.amount} onChange={amount => BRC.changeCollectiveInvoice({...BRC.collectiveInvoice!, amount})} validate={Validators.isPrice("den zu verrechnenden Betrag eingeben")} />
          <TextInput label="Bezeichnung" value={BRC.collectiveInvoice!.text} onChange={text => BRC.changeCollectiveInvoice({...BRC.collectiveInvoice!, text})} validate={Validators.isRequired("Bezeichnung des Rechnungspostens eingeben")} />
        </div>
      )
    }
    else {
      // user needs to select a billing type
      billingType = (
        <Switchboard 
          selectedValues={[BRC.paymentType]}
          onChange={values => BRC.changePaymentType(values[0])} 
          minItems={1} maxItems={1}
          items={[
            {value:"invoice", label:"Rechnung"},
            {value:"cc", label:"Kreditkarte"},
          ]}
        />
      )
    }

    // render
    return (
      <>
        <div className="booking-form">
          <ServiceItems />
          <div className="paymentTypeText">
            <div className="title">Verrechnungsart</div>
            {billingType}
          </div>
          {invoiceValue}
          <TextInput label="Betreff" value={BRC.emailTexts.subject} onChange={v => BRC.changeSubject(v)} validate={Validators.isRequired("Betreff eingeben")} />
          <TextArea label="Zusatztext" rows={4} value={BRC.emailTexts.addendum} onChange={v => BRC.changeAddendum(v)} explanation="(Begleitpersonen, Allergien, etc)" />
        </div>
        <Row align="center">
          <Button onClick={closeDialog}>abbrechen</Button>
          <Button onClick={() => setMode('preview')} disabled={!BRC.isValid}>weiter <Icon icon={Icons.ArrowRight} /></Button>
        </Row>
      </>
    )
  }
  else {  // preview
    // create the mail body
    const mailBody = getMailBody(BRC!.services, adventureInfo, BRC.emailTexts, BRC.paymentType)
    const bookingInfo = provider.booking[0];
    // create the email object we pass to the save method
    const email: DA.BookingRequestEmail = {
      from: `${currentUser.firstName} von Appentura<${currentUser.email}>`,
      to: bookingInfo.value,
      subject: BRC.emailTexts.subject,
      body: mailBody
    }
    // render
    return <>
      <div className={CSS.preview}>
        <table><tbody>
        <tr className="email-to">
          <td>An:</td>
          <td>{`${provider.name} <${bookingInfo.value}>`}</td>
        </tr>
        <tr className="email-subject">
          <td>Betreff:</td>
          <td>{BRC.emailTexts.subject}</td>
        </tr>
        <tr className="email-body">
          <td colSpan={2} dangerouslySetInnerHTML={{__html:mailBody}}></td>
        </tr>
        </tbody></table>
      </div>
      <Row align="center">
        <Button onClick={() => setMode('edit')}><Icon icon={Icons.ArrowLeft}/> zurück</Button>
        <Button onClick={() => save(email)}>verschicken <Icon icon={Icons.Envelope} /></Button>
      </Row>
    </>
  }
  
}


function getMailBody(services: DA.BookingRequestService[], adventureInfo:any, emailTexts: EmailTexts, emailPaymentType:"invoice"|"cc"|"monthly") {
  // filter out empty service items and add the reference number as a service item
  const serviceItems = services.filter(s => s.value.trim().length > 0);
  if(emailPaymentType==="monthly") {
    serviceItems.push({key:"Referenz-Nummer", value:`${adventureInfo.id4}`})
  }
  else {
    serviceItems.push({key:"Referenz-Nummer", value:`${adventureInfo.id4} (bitte auf der Rechnung vermerken)`})
  }
  
  // create the html body
  let body = ""
  body += emailTexts.body.top.map((l:any) => `<p>${l}</p>`).join('')
  body += "<ul>" + serviceItems.map((s:any) => `<li>${s.key}: ${s.value}</li>`).join('') + "</ul>"
  let addendum_text = emailTexts.addendum.trim().split('\n').map((l:any) => `<p>${l}</p>`).join('')
  if(addendum_text.length > 0) {
    body += addendum_text
  }
  body += emailTexts.body.portal.map((l:any) => `<p>${l}</p>`).join('')
  body += "<p>" + emailTexts.body.payment[emailPaymentType] + "</p>"
  body += emailTexts.body.bottom.map((l:any) => `<p>${l}</p>`).join('')
  body += "<p>" + emailTexts.signature.map((l:any) => l).join('<br />') + "</p>"
  body += "<br />"
  body += `<div style='text-align: center;color:white;background-color: ${GREEN};padding:16px 0 16px 0'>`;
  body += emailTexts.footer.explanation.map((l:any) => `<div style='padding:8px 8px 8px 8px'>${l}</div>`).join('')
  body += "</div>"
  body += "<br />"
  body += "<div style='text-align: center'>"
  body += emailTexts.footer.signature.map((l:any) => l).join('<br />')
  body += "</div>"

  // done
  return body;
}