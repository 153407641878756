const config = {
  development: {
    baseUrlSurprise: 'http://localhost:3002/s',
    live_url:(surprise, mode) => `http://localhost:3002/s/${surprise._id}${mode ? `?mode=${mode}` : ''}`,
    ticker_url:(surprise) => `http://localhost:3002/v/${surprise.LiveId}`,
    static_url:(surprise) => `http://localhost:8090/s/${surprise._id}`,
    buddy_url:(surprise) => `http://localhost:3004/${surprise._id}`
  },
  staging: {
    baseUrlSurprise: 'https://surprise-staging.appentura.ch/s',
    live_url:(surprise, mode) => `https://surprise-staging.appentura.com/s/${surprise._id}${mode ? `?mode=${mode}` : ''}`,
    ticker_url:(surprise) => `https://live-staging.appentura.com/v/${surprise.LiveId}`,
    static_url:(surprise) => `https://appentura-api-staging.herokuapp.com/s/${surprise._id}`,
    buddy_url:(surprise) => `https://buddy-staging.appentura.ch/${surprise._id}`
  },
  production: {
    baseUrlSurprise: 'https://surprise.appentura.ch/s',
    live_url:(surprise, mode) => `https://surprise.appentura.ch/s/${surprise._id}${mode ? `?mode=${mode}` : ''}`,
    ticker_url:(surprise) => `https://live.appentura.ch/v/${surprise.LiveId}`,
    static_url:(surprise) => `https://appentura-api.herokuapp.com/s/${surprise._id}`,
    buddy_url:(surprise) => `https://buddy.appentura.ch/${surprise._id}`
  }
}

export default config