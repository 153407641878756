import React, { useContext, useState } from "react"
import Util from "../../../../util/util"
import * as TS from "../../../../types";
import * as DA from "../../../../types/DataAccess";
import AssistantLogic from "./Assistant.Logic"

// contexts
import { AssistantContext } from "./Assistant.Context";

// components
import { Alert, Icon, Icons, Button, ConfirmButton, Link, Modal } from "../../../controls";
import CollectiveInvoicesDetails from "../../CollectiveInvoices/CollectiveInvoices.Details";

// styling
import CSS from "./BookingRequests.module.scss"
import { ProviderLink } from "../../../common";

// the component
type BookingRequestsProps = {
  element:any,
  editRequest:any,
  createReminder:any
}
function BookingRequests({ element, editRequest, createReminder } : BookingRequestsProps) {
  // state
  const [collectiveInvoiceToView, setCollectiveInvoiceToView] = useState<TS.CollectiveInvoice|null>(null);

  // context
  const AC = useContext(AssistantContext);

  // deletes a booking request
  const deleteBookingRequest = async(requestId:any, adventureId:any) => {
    if(AC && requestId && adventureId) {
      await DA.BookingRequestRepository.remove(requestId);
      await AC.reloadBookingRequests();
    }
  }

  // user wants to view a collcetive invoice
  const onClickViewColletiveInvoice = async(id:string) => {
    // load invoice
    const collectiveInvoice = await TS.CollectiveInvoice.findOneById(id);
    // show
    if(collectiveInvoice) {
      setCollectiveInvoiceToView(collectiveInvoice);
    }
  }

  // closes the modal dialog
  const closeModal = () => setCollectiveInvoiceToView(null);

  // no assistant context found (i.e. component is not inside a AssistantProvider)
  if(!AC) {
    console.error("No AssistantContext found")
    return null;
  }

  // we count the accepted to output a warning if there is more than one
  let countAccepted = 0;

  // render
  let booking_element = element
  let rows = [];
  AC.data.bookingRequests
    .filter(r => {
      let include = false
      if(booking_element.activityId === r.activity.id) {
        if(booking_element.subactivityId) {
          include = booking_element.subactivityId === r.activity.subactivity_id
        }
        else if(booking_element.subactivityCode) {
          include = booking_element.subactivityCode === r.activity.subactivity_code
        }
        else {
          include = true
        }
      }
      return include
    })
    .forEach(r => {
      let state = 'waiting'
      let responseComment = null;
      let date = Util.printDateAndTime(r.request.createdOn)
      if(r.response) {
        date = Util.printDateAndTime(r.response.receivedOn)
        responseComment = r.response.comment;
        if(r.response.accepted) {
          state = 'accepted';
          countAccepted += 1;
        }
        else {
          state = 'declined'
        }
      }

      // if we deal with an e-mail request, we allow the user to send a reminder
      let button_reminder = null
      let reminder_list = null
      if(r.request.kind === 'email') {
        let reminder_items = ((r.reminders || [])
          .sort((a:any, b:any) => {return new Date(a.createdOn) > new Date(b.createdOn) ? -1: 1})
          .map((item:any, index:number) => {
            return (
              <div key={index}>
                {Util.printDateAndTime(item.createdOn)}
              </div>
            )})
        )
        if(reminder_items.length > 0) {
          reminder_list = (
            <React.Fragment>
              <div>Reminder verschickt am</div>
              {reminder_items}
            </React.Fragment>
          )
        }

        button_reminder = (
          <Button size="small" onClick={createReminder} data={{requestId:r._id}}>Reminder verschicken</Button>
        )
      }

      // collective invoice?
      let collectiveInvoiceInfo = null;
      let isPartOfCollectiveInvoice = r.collectiveInvoice !== null && r.collectiveInvoice.collectiveInvoice_id !== null;
      if(isPartOfCollectiveInvoice) {
        collectiveInvoiceInfo = <div>Ist Teil einer <span onClick={() => onClickViewColletiveInvoice(r.collectiveInvoice!.collectiveInvoice_id!)} className={CSS.textButton}>Monatsabrechnung <Icon icon={Icons.Eye} /></span> (Betrag: {r.collectiveInvoice!.amount.toFixed(2)}) und kann daher nicht mehr geändert werden.</div>
      }

      // collective invoice candidate?
      if(isPartOfCollectiveInvoice === false && r.collectiveInvoice !== null && r.response && r.response.accepted) {
        collectiveInvoiceInfo = <div>Betrag in Monatsabrechnung: {r.collectiveInvoice!.amount.toFixed(2)}</div>
      }


      // comment from provider?
      let comment = null;
      if(responseComment) {
        const lines = responseComment.split("\n")
        lines.unshift("Bestätigungskommentar:")
        const items = lines.map((line:String, index:number) => <div key={index}>{line}</div>);
        comment = <div className={CSS.responseComment}>{items}</div>
      }
      
      rows.push(
        <tr key={r._id} className={CSS.bookingRequest}>
          <td className={CSS[state]}><Icon icon={AssistantLogic.getBookingStateIcon(state)} /></td>
          <td><Icon icon={AssistantLogic.getBookingKindIcon(r.request.kind)} /></td>
          <td>{date}</td>
          <td>
            <ProviderLink provider={{_id:r.provider.id, name:r.provider.name} as DA.Provider} />
            {comment}
          </td>
          <td>
            <Button disabled={isPartOfCollectiveInvoice} size="small" onClick={editRequest} data={{requestId:r._id}}>bearbeiten</Button>
            <ConfirmButton disabled={isPartOfCollectiveInvoice} onConfirm={() => deleteBookingRequest(r._id, AC.adventureInfo.id)}>löschen</ConfirmButton>
            {button_reminder}
            {reminder_list}
            {collectiveInvoiceInfo}
          </td>
        </tr>
      )
      // if(providerComment) {
      //   rows.push(
      //     <tr key={r._id + "_providerComment"}>
      //       <td colSpan={2}></td>
      //       <td colSpan={2}>
      //         Kommentar des Anbieters:
      //         {providerComment.split("\n").map((line:string, index:number) => {
      //           return <div key={"comment" + index}>{line}</div>
      //         })}
      //       </td>
      //       <td></td>
      //     </tr>
      //   );
      // }
    })

  if(countAccepted > 1)  {
    rows.unshift(<tr key="warning">
      <td colSpan={5}>
        <Alert title="zu viele akzeptierte Buchungsanfragen" intent="warning" size="small">
          Mehr als eine Buchungsanfrage hat den Status "akzeptiert".<br/>
          Das kann dazu führen, dass der Planungsassistent den falschen Anbieter verwendet, um die Routen zu berechnen, und beim Erzeugen von Schritten fehlerhafte Schritte erzeugt werden.
        </Alert>
      </td>
    </tr>)
  }

  return <>
    {rows}
    <Modal isOpen={collectiveInvoiceToView !== null} title="Monatsabrechnung" onClose={closeModal}>
      <CollectiveInvoicesDetails collectiveInvoice={collectiveInvoiceToView!} onChanged={()=>{}} closeDialog={closeModal} />      
    </Modal>
  </>
}

// modalMode === "invoiceDetails"

export default BookingRequests



