import React, { useState, useEffect } from "react";
import moment from "moment";
import * as TS from "../../../types";
import * as BL from "../../../types/BusinessLogic";

// subviews
import Warnings from "./Warnings";
import Tasks from "./Tasks";
import Calendar from "./Calendar";
import Active from "./Active";
import Ratings from "./Ratings";
import Sales from "./Sales";
import CompletedAdventures from "./CompletedAdventures";
import BookingRequests from "./BookingRequests";
import Meteocheck from "./Meteocheck.Dashboard";
import LatestRating from "./LatestRating";
import Mailfails from "./Mailfails";

// css and images
import CSS from "./Dashboard.module.scss";

export default function Dashboard() {
  // state
  const [data, setData] = useState<any>({ratings:null, sales:null, executions:null});
  const [dataStatus, setDataStatus] = useState<"notloaded"|"loading"|"loaded">("notloaded"); 

  // loads the data and sets data status
  const initDataLoading = () => {
    setDataStatus("loading");
    load();
  }
  const load = async () => {
    const _data = await loadData();
    setData(_data);
    setDataStatus("loaded");
  }

  // mount
  useEffect(() => {
    window.document.title = "Admin - Appentura"
  }, []);

  // render
  return (
    <div className={CSS.container}>
      <LatestRating />
      <Ratings dataStatus={dataStatus} data={data.ratings} requestData={initDataLoading} />
      <CompletedAdventures dataStatus={dataStatus} data={data.executions} requestData={initDataLoading}  />
      <Sales dataStatus={dataStatus} data={data.sales}  requestData={initDataLoading} />
      <Active/>
      <Warnings/>
      <Meteocheck/>
      <Mailfails />
      <Tasks/>
      <BookingRequests />
      <Calendar/>
    </div>
  )
}

async function loadData() {
  // fetch the data
  let data = await BL.Statistics.load(false);
  // get date ranges
  let today = moment()
  let year_range = BL.Statistics.getYearRange(today.year());
  let month_range = BL.Statistics.getMonthRange(today.year(), today.month() + 1)
  let total_range = BL.Statistics.getTotalRange()
  // get data per range
  let data_month = BL.Statistics.calculateSurprises(data, month_range.from, month_range.to)
  let data_year = BL.Statistics.calculateSurprises(data, year_range.from, year_range.to)
  let data_allTime = BL.Statistics.calculateSurprises(data, total_range.from, total_range.to)

  // sales data
  let sales = {
    month: {
      count:data_month.itemsSold,
      sales:data_month.totalValue
    },
    year: {
      count:data_year.itemsSold,
      sales:data_year.totalValue
    },
    allTime: {
      count:data_allTime.itemsSold,
      sales:data_allTime.totalValue
    }
  }

  // executions data
  let executions = {
    month:{count:data_month.itemsExecuted},
    year:{count:data_year.itemsExecuted},
    allTime:{count:data_allTime.itemsExecuted}
  }

  // ratings data
  let ratings = {
    organization: data_allTime.ratings.organization,
    providers: data_allTime.ratings.providers,
    overall: data_allTime.ratings.overall
  }

  // update the state
  return {ratings, sales, executions}
}