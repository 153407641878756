import React, {useContext} from "react";
import Row from "./Row";
import {SurpriseContext} from "../../Surprise.Context";

export default function CurrentStep() {
  // context 
  const SC = useContext(SurpriseContext);
  
  // render
  const steps = SC.surprise.Steps || [];
  let current_step_index = 0;
  if(SC.surprise.CurrentStep) {
    if(SC.surprise.CurrentStep > 0) {
      current_step_index = SC.surprise.CurrentStep - 1 // steps are not strictly 0-index ... if CurrentStep is 0 in the database the adventure did not start ... if CurrentStep === 1 we are in step with index 0, etc
    }
  }
  if(current_step_index >= steps.length) {
    current_step_index = steps.length - 1
  }
  let content = null
  if(steps.length === 0) {
    content = "noch keine Schritte definiert"
  }
  else {
    let current_step = steps[current_step_index]
    let instructions = (current_step.Instructions || []).map((instruction, index) => {
      return <div key={index }>{instruction}</div>
    })
    content = (
      <div>
        <div>{current_step_index + 1} - {current_step.Title}</div>
        <div>{instructions}</div>
      </div>
    )
  }
  return (
    <Row label="Aktueller Schritt">{content}</Row>
  )
}
