import React from "react";
import { Card, Icons } from '../../../controls';
import Notes from "./Notes.View";
import Log from "./Log.View";

export default function NotesAndLog({expanded}) {
  
  // render
  return (
    <Card title="Notizen und Log" icon={Icons.List} collapsible={true} expanded={expanded}>
      <Notes />
      <Log />
    </Card>
  );
}
