import React from "react";

import CSS from "./CardSection.module.scss";

type CardSectionProps = {
  children: React.ReactNode|React.ReactNode[],
  title: string,
  className?: string
}

export function CardSection({title, children, className} : CardSectionProps) {
  return (
    <div className={CSS.container}>
      <div className={CSS.inner}>
        <div className={CSS.title}>
          {title}
        </div>
        <div className={`${CSS.content} ${className || ""}`}>
          {children}
        </div>
      </div>
    </div>
  )
}