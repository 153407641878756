import * as DA from "../DataAccess";

export type SanityCheckActivityResult = {
  tagIssues: {
    activity: DA.Activity,
    issue: string
  }[],
  lastSalesRankUpdate: Date
}

export class SanityCheck {
  static async getActivitySanity(): Promise<SanityCheckActivityResult> {
    // get all active main activities
    const filter = {isSubactivity:{$ne:true}, "regionVariants":{$elemMatch:{"isActive":true}}};
    //const projection = {title:1, shortDescription:1, slug:1, images:1, tags:1, isFeatured:1, isFresh:1, "regionVariants.price":1, "regionVariants.isActive":1, salesRankUpdatedOn:1};
    const activities = await DA.ActivityRepository.search(filter);
    // const result = await Api2.post("activities", "search", {filter, projection});
    // const activities = result.data.items;
  
    // collect tag issues
    let tagIssues:any[] = [];
    let tags = {
      gender:["frauen", "männer", "paare"],
      category:["wellness", "fun", "kulinarisches"]
    }
    activities.forEach(a => {
      // each needs to have a gender tag
      let activity = { id:a._id, title:a.title}
      if(!a.tags.some((t:any) => (tags.gender.includes(t.toLowerCase())))) {
        tagIssues.push({ activity, issue:'hat kein Geschlechts-Tag (frauen, männer oder paare)'})
      }
      // each activity needs to have a category tag
      if(!a.tags.some((t:any) => (tags.category.includes(t.toLowerCase())))) {
        tagIssues.push({ activity, issue:'hat kein Kategorie-Tag (wellness, fun oder kulinarisches)'})
      }
    })
  
    // get last sales rank update
    let lastSalesRankUpdate = null;
    let activityWithSalesRankDate = activities.find(a => {
      return a.salesRankUpdatedOn !== null;
    })
    if(activityWithSalesRankDate) {
      lastSalesRankUpdate = activityWithSalesRankDate.salesRankUpdatedOn!;
    }
    else {
      lastSalesRankUpdate = new Date(0);
    }
  
    // done
    return {
      tagIssues,
      lastSalesRankUpdate
    }
  }
}