import * as DA from "../DataAccess";
import { ProviderMapper } from "../DataAccess/Provider/Provider.Mapper";

export class VendorInvoiceAllocatedAmount {
  _id: string|undefined;
  surprise_id: string; // id of the adventure
  amount: number; // amount assigned to the adventure
  bookingText: string;

  constructor(surprise_id:string, amount:number, bookingText: string) {
    this.surprise_id = surprise_id;
    this.amount = amount;
    this.bookingText = bookingText;
  }

  public toDb() : any {
    return {
      _id: this._id,
      surprise_id: this.surprise_id,
      amount: this.amount,
      bookingText: this.bookingText
    }
  }

  public static fromDb(obj:any) : VendorInvoiceAllocatedAmount {
    const vip = new VendorInvoiceAllocatedAmount(obj.surprise_id, obj.amount, obj.bookingText);
    vip._id = obj._id;
    return vip;
  }
}

export class VendorInvoiceProvider {
  static providerMapper = new ProviderMapper();

  _id: string|undefined; // id of the provider
  name: string = ""; // simply to make reading data easier
  bankAccount: DA.ProviderBankAccount|null = null;
  billingAddress: DA.ProviderAddress|null = null;

  public toDb() : any {
    return {
      _id: this._id,
      name: this.name,
      bankAccount: this.bankAccount,
      billingAddress: this.billingAddress,
    }
  }

  public static fromDb(obj:any): VendorInvoiceProvider {
    const vip = new VendorInvoiceProvider();
    vip._id = obj._id;
    vip.name = obj.name || "";
    vip.bankAccount = obj.bankAccount ? this.providerMapper.fromDb_bankAccount(obj.bankAccount) : null;
    vip.billingAddress = obj.billingAddress ? this.providerMapper.fromDb_address(obj.billingAddress) : null;
    return vip;
  }

  public static fromProvider(provider: DA.Provider): VendorInvoiceProvider {
    const vip = new VendorInvoiceProvider();
    vip._id = provider._id;
    vip.name = provider.name;
    vip.bankAccount = null;
    vip.billingAddress = null;
    return vip;
  }

}