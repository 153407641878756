import { Testimonial, TestimonialActivity } from "./Testimonial";
import { Mapper } from "../_Common/Mapper";

export class TestimonialMapper extends Mapper<Testimonial> {
  public apiEndpoint = "testimonials";

  public toDb(testimonial: Testimonial): Object {
    return {
      _id: testimonial._id,
      name: testimonial.name,
      text: testimonial.text,
      regionCode: testimonial.regionCode,
      imageUrl: testimonial.imageUrl,
      activities: testimonial.activities.map(a => {
        return {
          activityId: a.activityId,
          title: a.title
        }
      })
    }
  }

  public fromDb(obj:any): Testimonial {
    const activities = (obj.activities || []).map((a:any) => {
      return {
        activityId: obj.activityId,
        title: obj.title
      }
    })
    return {
      _id: obj._id,
      name: obj.name,
      text: obj.text,
      regionCode: obj.regionCode,
      imageUrl: obj.imageUrl,
      activities: activities
    }
  }

}