import React from "react";
import Code from "../Code";
import {Circle, Card, Heading, Icon, Icons} from "../../../controls";

export default function CircleDemo() {
  // render
  return <>
    <Heading>Circle</Heading>
    <Card>
      <Circle size="small">
        <Icon icon={Icons.Plus} />
      </Circle>
      <Code>{
`<Circle size="small">
  <Icon icon={Icons.Plus} />
</Circle>`
      }</Code>

      <Circle size="medium">
        <Icon icon={Icons.Plus} />
      </Circle>
      <Code>{
`<Circle size="medium">
  <Icon icon={Icons.Plus} />
</Circle>`
      }</Code>

      <Circle size="large">
        <Icon icon={Icons.Plus} />
      </Circle>
      <Code>{
`<Circle size="large">
  <Icon icon={Icons.Plus} />
</Circle>`
      }</Code>


    </Card>

    <Card title="data and onClickEvent">
      <Circle size="small" data="hello" onClick={(data:any) => alert(data)}>
        <Icon icon={Icons.Plus} />
      </Circle>
      <Code>{
`<Circle size="small" data="hello" onClick={(data:any) => alert(data)}>
  <Icon icon={Icons.Plus} />
</Circle>`
      }</Code>
    </Card>
  </>
}
