// Note: this reflects what db.getCollection("Adventure").distinct("Log.Type") returned on feb 3| 2023 
// TODO: at some point we might want to clean this up
// IMPORTANT: when adding values| make sure to add them to the _isAdventureLogType() method below
export type AdventureLogType = 
  "adventure-activities"|
  "archived"|
  "bookingrequest"|
  "bookingrequest_created"|
  "bookingrequest_reminder"|
  "bookingrequest_removed"|
  "bookingrequest_response"|
  "bookingrequest_response_partner"|
  "buddy_finished"|
  "buddy_selected"|
  "buddy_upsell"|
  "cancelled"|
  "dearchived"|
  "dunning_lock"|
  "entsperrt"|
  "gesperrt"|
  "mahnsperre"|
  "mahnung"|
  "meteocheck"|
  "payrexx_cancelled"|
  "payrexx_confirmed"|
  "payrexx_declined"|
  "payrexx_error"|
  "payrexx_expired"|
  "payrexx_partially-refunded"|
  "payrexx_refunded"|
  "payrexx_reserved"|
  "payrexx_waiting"|
  "postponed"|
  "retourniert"|
  "status"|
  "verschoben"; 

function _isAdventureLogType(type: string): type is AdventureLogType {
  return [
    "adventure-activities",
    "archived",
    "bookingrequest",
    "bookingrequest_created",
    "bookingrequest_reminder",
    "bookingrequest_removed",
    "bookingrequest_response",
    "bookingrequest_response_partner",
    "buddy_finished",
    "buddy_selected",
    "buddy_upsell",
    "cancelled", 
    "dearchived",
    "dunning_lock",
    "entsperrt",
    "gesperrt",
    "mahnsperre",
    "mahnung",
    "meteocheck",
    "payrexx_cancelled",
    "payrexx_confirmed",
    "payrexx_declined",
    "payrexx_error",
    "payrexx_expired",
    "payrexx_partially-refunded",
    "payrexx_refunded",
    "payrexx_reserved",
    "payrexx_waiting",
    "postponed",
    "retourniert",
    "status",
    "verschoben"
  ].includes(type);
}
export class AdventureLog {
  CreateDate: Date = new Date();
  Text: string = "";
  Operator: string|null = null;
  Type: AdventureLogType|null = null;
  ShowWarning: boolean = false; // if set to true| the entry will be displayed as a warning in the admin tool

  public toDb(): any {
    return {
      CreateDate: this.CreateDate,
      Text: this.Text,
      Operator: this.Operator,
      Type: this.Type,
      ShowWarning: this.ShowWarning
    }
  }

  public static fromDb(obj:any): AdventureLog|null {
    if(obj) {
      const log = new AdventureLog();
      log.CreateDate = obj.CreateDate ? new Date(obj.CreateDate) : new Date();
      log.Text = obj.Text || "";
      log.Operator = obj.Operator || null;
      log.Type = _isAdventureLogType(obj.Type) ? obj.Type : null;
      log.ShowWarning = obj.ShowWarning || false;
      return log;
    }
    else {
      return null;
    }
  }
}

