import { IncidentMapper } from "./Incident.Mapper";
import { Incident } from "./Incident";
import { RepositoryFactory } from "../_Common/RepositoryFactory";


export class IncidentRepository {
  private static mapper = new IncidentMapper();
  
  // from factory
  static make = RepositoryFactory.make<Incident, IncidentMapper>(this.mapper);
  static search = RepositoryFactory.search<Incident, IncidentMapper>(this.mapper);
  static create = RepositoryFactory.create<Incident, IncidentMapper>(this.mapper);
  static update = RepositoryFactory.update<Incident, IncidentMapper>(this.mapper);
  static remove = RepositoryFactory.remove<Incident, IncidentMapper>(this.mapper, "remove"); // TODO in api this is exported as delete, should be remove like all the others

  // additional
  static async findAll(): Promise<Incident[]> {
    return IncidentRepository.search({});
  }
}

