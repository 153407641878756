import React, {useContext, useState, useEffect} from "react";
import _ from "lodash";

import { FormContext } from "./FormContext";
import { Alert, Label, ValidationError } from ".";
import { Validation } from "./Validation";

import CSS from "./TextArea.module.scss";


type TextAreaProps = {
  path?: string,
  value?: string,
  label?: string
  explanation?: string,
  placeholder?: string,
  disabled?: boolean,
  validate?: any,
  rows?: number,
  showCharacterCount?: boolean,
  onChange?: (value:string, validation:Validation) => void
}
export function TextArea({path, value, label, explanation, placeholder, disabled, validate, showCharacterCount, rows, onChange} : TextAreaProps) {
  // get context
  const form = useContext(FormContext);

  // if we have a form context, we render with form if not without
  if(form && path) {
    // warn about value not being used if provided
    if(value) {
      console.warn("since TextArea is inside a FormContext and a 'path' was provided, the provided 'value' will be ignored");
    }
    // render the form control
    return FormControl({path, label, explanation, placeholder, disabled, validate, onChange, showCharacterCount, rows});
  }
  else if(value !== undefined) {
    // a value was provided
    return Widget({value, label, explanation, placeholder, disabled, validate, onChange, showCharacterCount, rows});
  }
  // something is missing
  return (
    <Alert intent="error" size="small" title="Implementation Error">
      Expected the path property (if control is inside a Form) or the value property.
    </Alert>
  )
}


type FormControlProps = {
  path: string,
  label?: string,
  explanation?: string,
  placeholder?: string,
  disabled?: boolean,
  validate?: (v:any) => Validation,
  rows?: number,
  showCharacterCount?: boolean,
  onChange?: (value:any, validation:Validation) => void,
}
function FormControl({path, label, explanation, placeholder, disabled, validate, showCharacterCount, rows, onChange}:FormControlProps) {
  // state
  const [initialValue, setInitialValue] = useState<string>("");

  // context
  const form = useContext(FormContext);

  // mount
  useEffect(() => {
    // TODO we always get a form and and entity (ensured in <TextArea />) ... no need to check this
    if(form.entity && path) {
      // setting initial value
      const value = _.get(form.entity, path); // TODO what if no value is found?
      setInitialValue(value);
    }
  }, [form.entity, validate, path])

  // form.resetIndex changed -> reset the item
  useEffect(() => {
    if(form.resetIndex !== 0) {
      setInitialValue(form.getOldValue(path))
    }
  }, [form.resetIndex, path, form])

  // handler
  const onWidgetChange = (value:string, validation: Validation) => {
    form.onFieldChange(path, value, validation)
    if(onChange) {
      onChange(value, validation);
    }
  }

  // render
  return <Widget value={initialValue} label={label} explanation={explanation} placeholder={placeholder} disabled={disabled} validate={validate} onChange={onWidgetChange} showCharacterCount={showCharacterCount} rows={rows}/>
}

type WidgetProps = {
  value: string
  label?: string,
  explanation?: string,
  placeholder?: string,
  disabled?: boolean,
  rows?: number,
  showCharacterCount?: boolean,
  validate?: (v:string) => Validation,
  onChange?: (value:any, validation: Validation) => void,
}
function Widget({value, label, explanation, placeholder, disabled, showCharacterCount, rows, validate, onChange}:WidgetProps) {
  // state
  const [enteredValue, setEnteredValue] = useState<string>(value);
  const [validation, setValidation] = useState<Validation>(new Validation(true));

  // value changes
  useEffect(() => {
    const validation = validate ? validate(value) : new Validation(true);
    setValidation(validation);
    setEnteredValue(value);
  }, [value]);

  // handler
  const onInputChange = (e:React.ChangeEvent<HTMLTextAreaElement>) => {
    // get updated value and validation
    const enteredValue = e.target.value;
    const validation = validate ? validate(enteredValue) : new Validation(true);
    
    // update state
    setEnteredValue(enteredValue);
    setValidation(validation);
    // inform onChange listeners
    if(onChange) {
      onChange(enteredValue, validation);
    }
  }

  // pre-render character counter
  let characterCounter = null;
  if(showCharacterCount) {
    characterCounter = <div className={CSS.charactercount}>{enteredValue.length}</div>
  }
  // render
  return (
    <div className={CSS.container}>
      <Label label={label} explanation={explanation}>
        <div className={CSS.textareaContainer}>
          <textarea rows={rows || 5} value={enteredValue} disabled={disabled} onChange={onInputChange} placeholder={placeholder || ""} />
          {characterCounter}
        </div>
        
        
        <ValidationError validation={validation} />
      </Label>
    </div>
  )
}