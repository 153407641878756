import _ from "lodash";
import React, { useContext, useState, useEffect } from "react";
import * as TS from "../../../../types";
import { Button, ConfirmButton, Form, Icon, Icons, NumberInput, TextArea, TextInput, Validators, Modal, CardSection, Table, Tr, Td } from "../../../controls";
import { ActivityContext } from "../ActivityContext";

export default function Options() {
  // state
  const [itemToEdit, setItemToEdit] = useState<TS.ActivityRegionVariantOption|null>(null);
  // context
  const AC = useContext(ActivityContext)!;

  // users wants to edit or create (create if no optionToEdit is passed)
  const onClickEdit = (optionToEdit: TS.ActivityRegionVariantOption|null) => {
    if(optionToEdit === null) {
      optionToEdit = new TS.ActivityRegionVariantOption();
    }
    setItemToEdit(optionToEdit);
  }
  const stopEdit = () => setItemToEdit(null);

  // user wants to delete
  const onClickDelete = async(optionToDelete: TS.ActivityRegionVariantOption) => {
    const options = AC.regionVariant!.options.filter(b => b._id !== optionToDelete._id).map(o => o.toDb());
    const changeset = {options};
    await AC.updateRegionVariant(AC.regionVariant!._id!, changeset);
  }

  // saves changes
  const saveEdit = async(optionToSave: TS.ActivityRegionVariantOption) => {  
    const updatedOptions = AC.regionVariant!.options.map(b => b._id === optionToSave._id ? optionToSave : b).map(o => o.toDb());
    if(_.isNil(optionToSave._id)) {
      updatedOptions.push(optionToSave.toDb());
    }
    const changeset = {options:updatedOptions};
    await AC.updateRegionVariant(AC.regionVariant!._id!, changeset);
  }

  // subactivity? do not display options
  if(AC.activity!.isSubactivity) {
    return null;
  }
  
  // pre-render
  const items = AC.regionVariant!.options.map(o => {
    return (
      <Tr key={o._id}>
        <Td>{o.name.de}</Td>
        <Td>{o.description.de}</Td>
        <Td align="center">{o.minUnits}</Td>
        <Td align="center">{o.maxUnits}</Td>
        <Td align="center">{o.price}</Td>
        <Td>
          <ConfirmButton onConfirm={() => onClickDelete(o)}><Icon icon={Icons.Trash} /></ConfirmButton>
        </Td>
        <Td>
          <Button onClick={() => onClickEdit(o)} size="small"><Icon icon={Icons.Edit} /></Button>
        </Td>
      </Tr>
    )
  })
  let thead = null;
  if(items.length > 0) {
    thead = (
      <Tr>
        <Td label>Name</Td>
        <Td label>Beschreibung</Td>
        <Td label align="center">Teiln. mind</Td>
        <Td label align="center">Teiln. max.</Td>
        <Td label align="center">Preis pro Teiln.</Td>
      </Tr>
    )
  }
  // render
  return <>
    <CardSection title="Optionen">
      <Table>{thead}{items}</Table>
      <Button onClick={() => onClickEdit(null)} size="small"><Icon icon={Icons.Plus} /> neue Option</Button>
    </CardSection>
    <Modal isOpen={itemToEdit !== null} title="Regionsvariante-Option" onClose={stopEdit} >
      <Edit option={itemToEdit!} saveEdit={saveEdit} stopEdit={stopEdit} />
    </Modal>
  </>
}

type EditProps = {
  option: TS.ActivityRegionVariantOption,
  saveEdit: (option:TS.ActivityRegionVariantOption) => void,
  stopEdit: () => void,
}
function Edit({option, saveEdit, stopEdit} : EditProps) {
  // state
  const [entity, setEntity] = useState<any>(null);
  const [busy, setBusy] = useState(false);

  // mount
  useEffect(() => {
    if(option) {
      // update
      setEntity(option);
    }
    else {
      // create
      setEntity({
        name: {de:"", en:""},
        description: {de:"", en:""},
        minUnits: 0,
        maxUnits: 5,
        price: 10
      })
    }
  }, []);

  // save the update
  const onSave = async(formResult:any) => {
    setBusy(true);
    const updatedEntity = formResult.merge(entity);
    option.description.de = updatedEntity.description.de;
    option.name.de = updatedEntity.name.de;
    option.minUnits = updatedEntity.minUnits;
    option.maxUnits = updatedEntity.maxUnits;
    option.price = updatedEntity.price;
    await saveEdit(option); 
    setBusy(false);
    stopEdit();
  }

  // cancel
  const onCancel = () => {
    stopEdit();
  }

  // render
  return (
    <Form onCancel={onCancel} onSave={onSave} entity={entity} busy={busy}>
      <TextInput path="name.de" label="Name" validate={Validators.isRequired("bitte einen Namen für die Option eingeben")} />
      <TextArea path="description.de" label="Beschreibung" validate={Validators.isRequired("bitte eine Beschreibung der Option eingeben")} />
      <NumberInput path="minUnits" label="mind. Anzahl Teilnehmer" validate={Validators.isRequired("bitte Wert eingeben")} />
      <NumberInput path="maxUnits" label="max. Anzahl Teilnehmer" validate={Validators.isRequired("bitte Wert eingeben")} />
      <NumberInput path="price" label="Preis pro Teilnehmer" validate={Validators.isPrice("bitte einen Preis grösser als 0 eingeben")} />
    </Form>
  ); 
}
