import React, {useState} from "react";
import { Button, Icon, Icons } from ".";
import CSS from "./ClipboardButton.module.scss";

type ClipboardButtonProps = {
  children?: React.ReactNode|React.ReactNode[],
  element?: any,
  text?: string,
  size?: "small"|"medium"
}
/**
 * 
 * @param {ClipboardButtonProps} props
 */
export function ClipboardButton({element, text, size, children}: ClipboardButtonProps) {
  // defaults
  size = size || "small";
  
  // state
  const [copied, setCopied] = useState(false);

  // copies to clipboard
  const copyToClipboard = () => {
    if(element) {
      // an element was provided, we copy the content of that element
      let range = document.createRange();
      range.selectNode(element);
      window.getSelection()!.removeAllRanges(); // clear current selection
      window.getSelection()!.addRange(range); // to select text
      document.execCommand("copy");
      window.getSelection()!.removeAllRanges();// to deselect
    }
    else if (text) {
      // no element provided, we copy the provided text property instead
      const el = document.createElement('textarea');
      el.value = text || "";
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);    
    }  
    setCopied(true);  
    setTimeout(() => {setCopied(false)}, 2500);
  }

  // render
  return <Button size={size} onClick={copyToClipboard}>{children || "kopieren"}<span className={`${CSS.icon} ${copied ? CSS.copied : ""}`}>&nbsp;<Icon icon={Icons.ClipboardCheck} /></span></Button>
}
