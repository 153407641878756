import _ from "lodash";
import React, { useState, useEffect } from "react";
import * as DataAccess from "../../../types/DataAccess";

// components & subviews
import { Form, TextInput, TextArea, Checkbox, Breadcrumb, Card, Heading, Icon, Icons, Modal, List, ListColumn, Validators, CloudStorageUploader } from "../../controls";

// list columns
const columns: ListColumn[] = [
  {
    header:"Name",
    path:"name.de"
  },
  {
    header:"Aktiv",
    path:"isActive",
    print: (charity:DataAccess.Charity) => charity.isActive ? <Icon icon={Icons.Check} /> : null,
  }
]

// the component
export default function CharityList() {
  // state
  const [charities, setCharities] = useState<DataAccess.Charity[]|null>(null);
  const [itemToEdit, setItemToEdit] = useState<DataAccess.Charity|null>(null);
  
  // mount
  useEffect(() => {
    load();
  }, [])

  // loads data
  const load = async() => {
    const charities = await DataAccess.CharityRepository.search({});
    console.log(charities);
    setCharities(charities);
  }

  // user wants to create or update
  const startEdit = (charity?: DataAccess.Charity) => {
    charity = charity || DataAccess.CharityRepository.make();
    setItemToEdit(charity);
  }
  const stopEdit = () => setItemToEdit(null);

  // render
  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}]} location="Hilfsorganisationen" />
    <Heading>Hilfsorganisationen</Heading>
    <Card>
      <List columns={columns} items={charities} onClickCreate={() => startEdit()} onRowClick={item => startEdit(item)} getKey={item => item._id} />
    </Card>
    <Modal isOpen={itemToEdit !== null} title="Hilfsorganisation" onClose={stopEdit}>
      <Edit charity={itemToEdit!} onUpdate={load} stopEdit={stopEdit} />
    </Modal>
  </>
}

type EditProps = {
  charity: DataAccess.Charity,
  onUpdate: () => void,
  stopEdit: () => void,
}
function Edit({charity, onUpdate, stopEdit} : EditProps) {
  // state
  const [entity] = useState<DataAccess.Charity>(charity);
  const [busy, setBusy] = useState<boolean>(false);

  // save
  const onSave = async(formResult:any) => {
    setBusy(true);
    if(charity._id) {
      //await TS.Charity.update(entity!._id!, formResult.changeset);
      await DataAccess.CharityRepository.update(entity._id!, formResult.changeset); // TODO formResult should make sure we have a Partial<T>
    }
    else {
      //await TS.Charity.create(TS.Charity.fromDb(formResult.merge(entity)));
      await DataAccess.CharityRepository.create(formResult.merge(entity)); // TODO formResult should make sure we have a Partial<T>
    }
    onUpdate();
    stopEdit()
  }

  // render
  return (
    <Card>
      <Form
        entity={entity} 
        onSave={onSave} 
        onCancel={stopEdit}
        busy={busy}
      >
        <TextInput disabled={busy} path="name.de" label="Name" validate={Validators.isRequired("Bitte einen Namen eingeben")} placeholder="Name der Hilfsorganisation" />
        <TextArea path="description.de" label="Beschreibung" validate={Validators.isRequired("Bitte eine Beschreibung eingeben")} />
        <Checkbox path="isActive" label="aktiv" disabled={busy} />
        <CloudStorageUploader kind="image" path="imageUrl" label="Logo (320 x 200)" folder="charities" prefix="" disableUrlEditing={true} />
      </Form>
    </Card>
  )

}