import Util from "../../../../../util/util";
import React, {useContext} from "react";
import Row from "./Row";
import {SurpriseContext} from "../../Surprise.Context";

export default function NextAlert() {
  // context 
  const SC = useContext(SurpriseContext);
  
  // render
  let nextStepWithAlert_index = 0
  let nextStepWithAlert = (SC.surprise.Steps || []).find((step, index) => {
    if ((index >= SC.surprise.CurrentStep) && (step.AlertDate)) {
      nextStepWithAlert_index = index
      return true
    }
    else {
      return false
    }
  })
  let content = '-'
  if(nextStepWithAlert) {
    content =`${Util.printTime(nextStepWithAlert.AlertDate)}, Schritt ${nextStepWithAlert_index + 1}, "${nextStepWithAlert.Title}"`
  }

  return (
    <Row label="Nächster Alert">{content}</Row>
  )
}
