import React, { useContext, useEffect, useState } from "react";
// types
import * as Cms from "../../../../types/Cms";
// contexts and components
import { PageContext } from "../Page.Context";
import { TextInput, TextArea, Form, Paragraphs, CloudStorageUploader, Dropdown } from "../../../controls";
import * as Selectors from "../Selectors";
import * as Preview from "../Preview";

// styling and images
import CSS from "./Basic.module.scss";
// import exampleImage from "./media/example_1_256x256.jpg"

const layoutOptions:any = [
  {value:"stacked", label:"Gestapelt"},
  {value:"beside", label:"Nebeneinander"}
];

type BasicProps = {
  instance?:Cms.BasicModule,
  closeDialog: () => void,
}

export function Basic({instance, closeDialog}:BasicProps) {
  // context
  const PC = useContext(PageContext);
  
  // state
  const [entity, setEntity] = useState<Cms.BasicModule|null>(null);
  const [busy, setBusy] = useState<boolean>(false);

  // mount
  useEffect(() => {
    let entity: Cms.BasicModule;
    if(instance) {
      entity = instance;
    }
    else {
      entity = new Cms.BasicModule();
    }
    setEntity(entity)
  }, [])

  // user wants to cancel
  const onCancel = () => {
    closeDialog();
  }

  // user wants to save
  const onSave = async(formResult:any) => {
    setBusy(true);
    const updatedEntity = formResult.merge(entity);
    
    if(formResult.changeset.countries) {
      // TODO we need to do this because formResult.merge does not seem to handle arrays correctly
      updatedEntity.countries = formResult.changeset.countries;
    }

    await PC.upsertModule(updatedEntity);
    // close dialog
    closeDialog();
  }

  
  // render
  return (
    <Form entity={entity} onCancel={onCancel} onSave={onSave} busy={false}>
      <Selectors.BaseProperties busy={busy} />

      <Dropdown label="Layout" path="elements.layout" options={layoutOptions} />
      <TextInput label="Titel" path="elements.title" placeholder="Titel eingeben" disabled={busy} />
      <TextInput label="Untertitel" path="elements.subtitle" placeholder="Untertitel eingeben" disabled={busy} />
      <TextArea label="Text" path="elements.text" placeholder="Text eingeben" disabled={busy} />
      <TextInput label="Button Text" path="elements.buttonText" placeholder="Text auf Button" disabled={busy} />
      <TextInput label="Button Url" path="elements.buttonHref" placeholder="Href auf Button" disabled={busy} />
      <CloudStorageUploader kind="image" label="Bild" folder="cms" path="elements.imageUrl" pathBlurhash="elements.imageBlurhash" disableUrlEditing={true} prefix="cms" />
      <TextInput label="Bildbeschreibung Url" path="elements.imageDescription" placeholder="Beschreibung des Bildes" disabled={busy} />
    </Form>
  )
}


type BasicPreviewProps = {
  instance: Cms.BasicModule,
  expanded?: boolean,
  onClickAction: (module:Cms.Module, action:"edit"|"delete"|"duplicate") => void
}
export function BasicPreview({instance, expanded, onClickAction} : BasicPreviewProps) {
  return (
    <Preview.Container expanded={expanded} module={instance} title={Cms.BasicModule.info.title} onClickAction={onClickAction}>
      <Preview.Table>
        <Preview.TableRow label="Titel">{instance.elements.title || "-"}</Preview.TableRow>
        <Preview.TableRow label="Untertitel">{instance.elements.subtitle || "-"}</Preview.TableRow>
        <Preview.TableRow label="Text">
          <Paragraphs text={instance.elements.text || "-"} />
        </Preview.TableRow>
        <Preview.TableRow label="Bild">
          <Preview.Image src={instance.elements.imageUrl} />
        </Preview.TableRow>
        <Preview.TableRow label="Bildbeschriftung">{instance.elements.imageDescription || "-"}</Preview.TableRow>
        <Preview.TableRow label="Button">
          <Preview.Button text={instance.elements.buttonText} href={instance.elements.buttonHref} />
        </Preview.TableRow>
        <Preview.TableRow label="Layout">
          {instance.elements.layout}
        </Preview.TableRow>
      </Preview.Table>
    </Preview.Container>
  )
}
