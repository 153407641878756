import _ from "lodash";
import React, {useState, useContext} from "react";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";
// subviews & components
import { Dropdown, Form, Checkbox, TextInput, TextArea, NumberInput, Validators, Loader, Alert, CloudStorageUploader, DropdownOptions, DropdownOption, Proxy, Validation } from "../../controls";
import DeliveryInfo from "./PackagingType.DeliveryInfo";

// dropdown options
const deliveryMethodOptions: DropdownOption[] = DA.PackagingTypeRepository.deliveryMethods.map(opt => opt as DropdownOption);

type EditProps = {
  packagingType: DA.PackagingType,
  onUpdate: () => void,
  closeDialog: VoidFunction
}
export default function Edit({packagingType, onUpdate, closeDialog}: EditProps) {
  // state
  const [entity] = useState<DA.PackagingType>(packagingType);
  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<string|null>(null);
  
  const [deliveryInfo, setDeliveryInfo] = useState(null);
  const [deliveryInfoDefaults] = useState({
    letter: {
      orderTimes: [11, 11, 11, 11, 11, 11, 11],
      deliveryDays: {
        A:[2, 2, 3, 4, 5, 6, 2], // before order time
        B:[2, 3, 4, 5, 6, 2, 2], // after order time
      }
    },
    parcel: {
      orderTimes: [11, 11, 11, 11, 11, 11, 11],
      deliveryDays: {
        A:[2, 2, 3, 4, 5, 1, 2], // before order time
        B:[2, 3, 4, 5, 1, 2, 2], // after order time
      }
    }
  })

  // save
  const onSave = async(formResult:any) => {
    setBusy(true);
    // save
    let result: DA.Result<DA.PackagingType>;
    if(!packagingType._id) {
      // create
      const mergedEntity = formResult.merge(entity);
      result = await DA.PackagingTypeRepository.create(mergedEntity);
    }
    else {
      // update
      result = await DA.PackagingTypeRepository.update(packagingType._id, formResult.changeset);
    }
    // pop dialog on success, display error if not
    if(result.success) {
      onUpdate();
      closeDialog();
    }
    else {
      setBusy(false);
      setError(result.error!.message);
    }
    
  }

  // user wants to cancel
  const onCancel = () => {closeDialog() };

  // render
  let content = null
  if(_.isNil(packagingType)) {
    content = <Loader />
  }
  else {
    content = (
      <div id="v-packagingtype-edit">
      <Form entity={entity} onSave={onSave} onCancel={onCancel} labelSave="speichern" labelCancel="abbrechen" busy={busy}>
        <Checkbox label="Status" path="isActive" secondaryLabel="ist aktiv" />
        <TextInput disabled={packagingType._id ? true : false} path="code" label="Code" validate={codeValidator} placeholder="Eindeutiger Code" />
        <TextInput path="title.de" label="Name" validate={Validators.isRequired("Bitte Namen eingeben")} placeholder="Name" />
        <TextArea path="description.de" label="Beschreibung" validate={Validators.isRequired("Bitte Beschreibung eingeben")} placeholder="Beschreibung" />
        <NumberInput path="price" label="Preis" />
        <Dropdown path="country" label="Land" options={DropdownOptions.Countries} />
        <Dropdown path="deliveryMethod" label="Versandart" options={deliveryMethodOptions} />
        <NumberInput path="position" label="Position" />
        <CloudStorageUploader kind="image" path="image.url" label="Bild der Verpackung" prefix="" folder="shippingtypes" disableUrlEditing={false} />
        <Proxy proxiedValue={deliveryInfo} path="deliveryInfo" label="Versandinformationen">
          <DeliveryInfo 
            shippingType={packagingType} 
            defaults={deliveryInfoDefaults} 
            onChange={(info:any) => {
              //console.log("delivery info changed", info)
              setDeliveryInfo(info);
            }} 
          />
        </Proxy>
        { error ? <Alert intent="error">{error}</Alert> : null }
      </Form>
      </div>
    )
  }
  return content;
}

function codeValidator(v:string): Validation {
  // eslint-disable-next-line
  const re_code = /^[A-Z_]+$/;
  const valid = re_code.test(String(v));
  return new Validation(valid, "Code darf nur Grossbuchstaben und _ enthalten");
}