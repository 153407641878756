import React, {useState, useEffect, useContext} from "react"
import * as DA from "../../../../types/DataAccess";

import { AssistantContext } from "./Assistant.Context";
import { Button, Link, Loader, MapLink, Dropdown, DropdownOption, Label, Alert } from "../../../controls";

import DefaultLocationForm from "../../Provider/DefaultLocationForm";
import ProviderOccupancy from "./ProviderOccupancy";

import "./ProviderSelector.scss"
import { ProviderLink } from "../../../common";


type ProviderEx = {
  provider: DA.Provider,
  assignmentNotes: string|null,
  relevantLocations: DA.ProviderLocation[]
}

type ProviderSelectorProps = {
  activityInfo:any,
  selectProviderAndLocation:any,
  closeDialog: () => void,
}
export default function ProviderSelector({activityInfo, selectProviderAndLocation, closeDialog} : ProviderSelectorProps) {
  // context
  const AC = useContext(AssistantContext);

  // state
  const [busy, setBusy] = useState(true)
  const [selectedRegionCode, setSelectedRegionCode] = useState<string|null>(null) // useState(activityInfo.regionCode)
  const [regionOptions, setRegionOptions] = useState<DropdownOption[]>([]);
  const [items, setItems] = useState<ProviderEx[]|null>(null);
  const [providerNeedingStartLoction, setProviderNeedingStartLocation] = useState<DA.Provider|null>(null); // if this is set, we display DefaultLocationForm instead

  // mount
  useEffect(() => {
    const load = async() => {
      const regions = (await DA.RegionRepository.findAll()).sort((a,b) => a.code.localeCompare(b.code));
      const regionOptions: DropdownOption[] = regions.map(r => {
        return { label: r.title.de.replace("Region", "").trim(), value: r.code }
      });
      setRegionOptions(regionOptions);
      selectRegion(activityInfo.regionCode)
      setBusy(false)
    }
    load()
  }, [])

  const loadProviders = async(activityId:string, regionCode:any) => {
    const activity = await DA.ActivityRepository.findById(activityId);
    const regionVariant = activity?.regionVariants.find(rv => rv.regionCode === regionCode);
    
    if(!regionVariant) {
      setItems([])
    }
    else {
      // get providers
      const providerIds = (regionVariant.providers || []).map((p:any) => p.id)
      const providers = await DA.ProviderRepository.search({_id:{"$in":providerIds}});
      // get locations of all of these providers
      const locations = await DA.ProviderLocationRepository.search({providerId:{"$in":providerIds}});
      
      const items:ProviderEx[] = providers.map(provider => {
        // get assignment notes
        let assignmentNotes = null;
        let assignment = regionVariant.providers.find((a:any) => a.id === provider._id);
        if(assignment && assignment.notes) {
          assignmentNotes = assignment.notes.trim().length > 0 ? assignment.notes : null
        }
        // get relevant locations (do we have activity specific locations?)
        const providerLocations = locations.filter(location => location.providerId === provider._id);
        let relevantLocations = providerLocations;
        const activityLocations = providerLocations.filter(location => String(location.activityId) === String(activityId) && String(location.regionVariantId) === String(regionVariant._id))
        if(activityLocations.length > 0) {
          relevantLocations = activityLocations;
        }
        // done
        return {
          provider,
          assignmentNotes,
          relevantLocations
        }
      })

      setItems(items)
    }
  }

  // user wants to set start location of a provider that has none
  const showDefaultLocationForm = (provider:DA.Provider) => {
    setProviderNeedingStartLocation(provider);
  }

  // selects a region
  const selectRegion = (regionCode:string) => {
    setSelectedRegionCode(regionCode)
    loadProviders(activityInfo.activityId, regionCode)
  }

  // No AC? no render
  if(!AC) {
    console.error("No AssistantContext");
    return null;
  }

  // does user need to define start location? render the corresponding dialog
  if(providerNeedingStartLoction !== null) {
    return (
      <DefaultLocationForm 
        provider={providerNeedingStartLoction} 
        onClose={() => {
          loadProviders(activityInfo.activityId, selectedRegionCode);
          setProviderNeedingStartLocation(null);
        }}
      />
    )
  }

  // render
  let content
  if(busy || !items) {
    content = <Loader />
  }
  else {
    let providerRows 
    if(!items || items.length === 0) {
      providerRows = [<tr key="noprovider1"><td><Alert intent="warning">Für diese Aktivität ist in dieser Region kein Anbieter hinterlegt, oder die Aktivität steht in dieser Region nicht zur Verfügung.</Alert></td></tr>]
    }
    else {
      providerRows = items.map((p:ProviderEx) => {
        let notes_text = [p.provider.notes, p.assignmentNotes].filter(t => (t || '').length > 0).map(t => (t || "").trim()).join('\n')
        let locations
        if(p.relevantLocations.length > 0) {
          const rows = p.relevantLocations.map((l:any) => {
            return (
              <tr key={l._id}>
                <td><Location location={l} /></td>
                <td>
                  <Button 
                    onClick={() => {
                      closeDialog();
                      selectProviderAndLocation({providerId:p.provider._id, providerLocation:l, providerName:p.provider.name})
                    }} 
                    size="small">wählen</Button>
                </td>
              </tr>
            )
          })
          locations = <table><tbody>{rows}</tbody></table>
        }
        else {
          locations = (
            <div>
              für diesen Anbieter ist noch kein Startort hinterlegt. <Button size="small" onClick={() => showDefaultLocationForm(p.provider)}>erfassen</Button>
            </div>
          )
        }
        return (
          <tr key={p.provider._id}>
            <td><div className={`color-rating ${p.provider.colorRating || ''}`} /></td>
            <td>
              <ProviderLink provider={p.provider} />
              <ProviderOccupancy providerId={p.provider._id} date={new Date(AC.adventureInfo.startTime)} />
              <div className="provider-notes" dangerouslySetInnerHTML={{__html:notes_text.split('\n').join('<br/>')}} />
              {locations}
            </td>
          </tr>
        )
      })
    }
    
    content = (
      <div id="assisant-outline-providerselector">
        <div>
          <Dropdown label="Region" options={regionOptions} onChange={rc => selectRegion(rc)} value={selectedRegionCode} />
        </div>
        <div>
          <Label label="Anbieter" />
          <table><tbody>{providerRows}</tbody></table>
        </div>
      </div>
    )
    
  }
  return content
}


type LocationProps = {
  location:any
}
function Location({location}:LocationProps) {
  let infos = []
  if(location.venue) {
    infos.push(<span key="venue">{`Austragungsort: ${location.venue}, `}</span>)
  }
  if(location.start && location.start.coordinates) {
    if(location.start.title.trim().length > 0) {
      infos.push(<span key="start_title">{location.start.title}: </span>)
    }
    infos.push(<span key="maplink"><MapLink lat={location.start.coordinates.lat} lng={location.start.coordinates.lng} /></span>)
  }
  
  return <div>{infos.map(item => item)}</div>
}



