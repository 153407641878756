import React from "react";
import Util from "../../../../util/util"
import {Icon} from "../../../controls";
import "./OutlineTimelineItem.scss";


type OutlineTimelineItemProps = {
  start?: Date,
  end?: Date,
  icon?: any
}

export default function OutlineTimelineItem({start, end, icon}:OutlineTimelineItemProps) {
  const sStart = start ? Util.printDateAndTimeWithoutYear(start) : "-";
  const sEnd = end ? Util.printDateAndTimeWithoutYear(end) : "";
  const divIcon = icon ? <div className="icon"><span><Icon icon={icon} /></span></div> : null;

  return (
    <div className="c-outline-timelineitem">
      <div className={`line ${end ? "" : "trailing"}`}>
        <div className="line-inner"></div>
      </div>
      {divIcon}
      <div className="start">{sStart}</div>
      <div className="end">{sEnd}</div>
    </div>
  )
}