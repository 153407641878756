import { EquipmentMapper } from "./Equipment.Mapper";
import { Equipment } from "./Equipment";
import { RepositoryFactory } from "../_Common/RepositoryFactory";


export class EquipmentRepository {
  // mapper
  private static mapper = new EquipmentMapper();
  
  // factory methods
  static make = RepositoryFactory.make<Equipment, EquipmentMapper>(this.mapper);
  static search = RepositoryFactory.search<Equipment, EquipmentMapper>(this.mapper);
  static create = RepositoryFactory.create<Equipment, EquipmentMapper>(this.mapper);
  static update = RepositoryFactory.update<Equipment, EquipmentMapper>(this.mapper);

  // additional
  static async searchAll(): Promise<Equipment[]> {
    return EquipmentRepository.search({});
  }
}

