import _ from "lodash";
import React, { useState } from "react";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";
import * as BL from "../../../types/BusinessLogic";

// context
type ShippingContextType = {
  // values
  loaded:boolean,
  adventures: TS.Adventure[],
  shippingTypes: DA.PackagingType[], 
  labels: any[],
  labelsCsv: string,
  // functions
  load: () => void,
  markAsShipped: (adventureId:string) => void
}
const ShippingContext = React.createContext<ShippingContextType>({} as ShippingContextType);

// provider
type ShippingProviderProps = {
  children: React.ReactNode|Array<React.ReactNode>
}
function ShippingProvider({children}:ShippingProviderProps) {
  // state
  const [loaded, setLoaded] = useState<boolean>(false);
  const [adventures, setAdventures] = useState<TS.Adventure[]>([]);
  const [shippingTypes, setShippingTypes] = useState<any[]>([]);
  const [labels, setLabels] = useState<any[]>([]);
  const [labelsCsv, setLabelsCsv] = useState<string>("");
  
  // provider value
  const value = {
    // props
    loaded,
    adventures,
    shippingTypes, 
    labels,
    labelsCsv,

    // loads data
    load: async() => {
      setLoaded(false);
      // load shipping types
      const shippingTypes = await DA.PackagingTypeRepository.findAll();
      setShippingTypes(shippingTypes);
      // find all adventures that need shipping and create labels collection
      const adventures = await BL.Shipping.getAdventuresToShip(true);
      setAdventures(adventures);
      // labels
      const [labels, labelsCsv] = getLabelsAndCsv(adventures, shippingTypes);
      
      setLabels(labels);
      setLabelsCsv(labelsCsv)
      // done loading
      setLoaded(true);
    },
    // marks an adventure as shipped
    markAsShipped: async(adventureId:string) => {
      await BL.Shipping.markAsShipped(adventureId);
      setAdventures(await BL.Shipping.getAdventuresToShip(true));
    },
  }

  return (
    <ShippingContext.Provider value={value}>
      {children}
    </ShippingContext.Provider>
  )
}

/**
 * Returns address lines and a csv string
 * @param {*} adventures 
 * Notes:
 * - csv structure: Firmenname;Vorname;Nachname;Strasse/Hausnummer;PLZ;Ort
 */
function getLabelsAndCsv(adventures:any[], shippingTypes:any[]) : [any[], string] {
  const csvLines = ["Firmenname;Vorname;Nachname;Strasse/Hausnummer;PLZ;Ort"];
  const labels:any[] = [];
  adventures.forEach(adv => {
    const a = adv.Order.ShippingAddress;
    labels.push(BL.Shipping.getAddressLines(a));
    if(isShippedInParcel(adv, shippingTypes)) {
      csvLines.push(`${a.Company || ""};${a.FirstName || ""};${a.LastName || ""};${a.Address1 || ""};${a.Zip || ""};${a.City || ""}`);
    }
  });
  return [labels, csvLines.join("\n")];
}

function isShippedInParcel(adventure:any, shippingTypes:any[]) {
  const shippingType = shippingTypes.find(st => st.code === adventure.Order.Packaging.Code);
  if(shippingType) {
    return _.get(shippingType, "deliveryMethod") === "parcel";
  }
  return false;
}

export {ShippingContext, ShippingProvider}


