import React from "react"

import DashboardPanel from "./DashboardPanel"
import iconCalendar from './../../../assets/images/icon_calendar.svg'

class Calendar extends React.Component {
  render() {
    return (
      <DashboardPanel title="Kalender" smallOnDesktop={false} smallOnMobile={false} icon={iconCalendar} iconRounded={false} id="Dashboard-Calendar">
        <iframe src="https://calendar.google.com/calendar/embed?showTitle=0&amp;showPrint=0&amp;showTz=0&amp;mode=WEEK&amp;height=600&amp;wkst=2&amp;bgcolor=%23ffffff&amp;src=teamappentura%40gmail.com&amp;color=%230D7813&amp;src=de.ch%23holiday%40group.v.calendar.google.com&amp;color=%23182C57&amp;src=tdtnl5hvs4briojtfpap25bg4o%40group.calendar.google.com&amp;color=%232952A3&amp;ctz=Europe%2FZurich" style={{border: 0}} width="100%" height="600" frameBorder="0" scrolling="no" title="calendar iframe"></iframe>
      </DashboardPanel>
    )
  }
}

export default Calendar