import React from "react";
import Util from './../../../util/util';
import * as TS from "../../../types";
import * as BL from "../../../types/BusinessLogic";

import { BarChart, Bar, XAxis, YAxis, PieChart, Pie, LabelList, Cell, Legend, ResponsiveContainer } from "recharts";
import { Label, ConfirmButton, Breadcrumb, Card, Heading, Loader, CsvDownload } from "../../controls";

import "./Statistics.scss"

class Statistics extends React.Component {
  constructor(props) {
    super(props)

    let year_current = BL.Statistics.getYearRange((new Date()).getFullYear())
    let year_previous = BL.Statistics.getYearRange((new Date()).getFullYear() - 1)

    this.state = {
      mode: "loading", // one of loading, calculating, ready
      mode_message: null,

      data:{
        surprises:null,
        activities:null,
        timestamp:null
      },
      from_a:year_current.from,
      to_a:year_current.to,
      from_b:year_previous.from,
      to_b:year_previous.to,

      groups: {
        a: {
          caption:'A',
          color:"#134f5c",
          color_disabled:"#5e5e5e",
          year:year_current.from.getFullYear(),
          month:1,
          week:1,
          primary:true,
          active:true
        },
        b: {
          caption:'B',
          color:"#a2c4c9",
          color_disabled:"#5e5e5e",
          year:year_previous.from.getFullYear(),
          month:1,
          week:1,
          primary:false,
          active:true
        },
        total: {
          caption: 'Total',
          color:"#d9d9d9",
          color_disabled:"#5e5e5e",
          primary:false,
          active:true
        }
      },

      dataKeys: {
        sales: {
          per: {
            monthday:'absolute', // 'absolute' or 'percent'
            weekday:'percent', // 'absolute' or 'percent'
            time:'percent' // 'absolute' or 'percent'
          }
        }
      },

      compareGroups: true,
      timeframe:'year'
    }

  }

  componentDidMount() {
    this.loadData(false)
  }

  render() {
    let content
    switch(this.state.mode) {
      case "loading":
      case "calculating":
        content = this.render_busy()
        break
      case "ready":
        content = this.render_stats()
        break
      default:
        content = <div>etwas ist schief gelaufen ...</div>
        break
    }

    return <>
      <Breadcrumb links={[ {title:'Home', to:'/'}]}
                    location="Statistiken" />
      <Heading>Statistiken</Heading>
      <Card id="v-statistics">
        {content}
      </Card>
    </>
  }

  render_busy() {
    return (
      <div>
        <Loader />
        <div style={{textAlign:"center"}}>{this.state.mode_message}</div>
      </div>
    )
  }

  render_stats() {
    // get the data
    let dateRanges = this.getDateRanges()

    let stats = {
      surprises : {
        a:BL.Statistics.calculateSurprises(this.state.data, dateRanges.a.from, dateRanges.a.to),
        b:BL.Statistics.calculateSurprises(this.state.data, dateRanges.b.from, dateRanges.b.to),
        total:BL.Statistics.calculateSurprises(this.state.data, dateRanges.total.from, dateRanges.total.to)
      },
      activities : {
        a:BL.Statistics.calculateActivities(this.state.data, dateRanges.a.from, dateRanges.a.to),
        b:BL.Statistics.calculateActivities(this.state.data,dateRanges.b.from, dateRanges.b.to),
        total:BL.Statistics.calculateActivities(this.state.data, dateRanges.total.from, dateRanges.total.to),
      }
    }

    // render
    return (
      <div>
        <div className="timestamp">
          <div className="info">Daten vom {Util.printDateAndTime(this.state.data.timestamp)}</div>
          <div className="button">
            <ConfirmButton label="Daten neu erzeugen" onConfirm={() => this.loadData(true)}>Daten neu erzeugen</ConfirmButton>
          </div>

        </div>
        {this.render_timeframe()}
        {this.render_sales_numbers(stats, dateRanges)}
        {this.render_regions(stats.surprises.a, stats.surprises.b, stats.surprises.total, dateRanges)}
        {/* this.render_persons(stats.surprises.a, stats.surprises.b, stats.surprises.total, dateRanges) */}
        {this.render_sales_per_monthday(stats.surprises.a, stats.surprises.b, stats.surprises.total, dateRanges)}
        {this.render_sales_per_weekday(stats.surprises.a, stats.surprises.b, stats.surprises.total, dateRanges)}
        {this.render_sales_per_time(stats.surprises.a, stats.surprises.b, stats.surprises.total, dateRanges)}
        {this.render_activity_ranking(stats.activities.a, stats.activities.b, stats.activities.total, dateRanges)}
      </div>
    )
  }

  render_timeframe() {
    let makearray = (start, end) => {
      let a = []
      for(let i = start; i <= end; i+=1) {
        a.push(i)
      }
      return a
    }

    let onDropdownChange = (e) => {
      let groups = this.state.groups
      let groupName = e.currentTarget.dataset.group
      let property = e.currentTarget.dataset.property
      let value = Number(e.currentTarget.value)
      groups[groupName][property] = value

      this.setState({...this.state, groups})
    }

    let onTimeframeChange = (e) => {
      this.setState({...this.state, timeframe:e.currentTarget.value})
    }

    /*
    let onVsClick = (e) => {
      let value = e.currentTarget.checked
      this.setState({...this.state, compareGroups:value})
    }
    */

    let group_header = (group_name) => {
      let group = this.state.groups[group_name]
      let color = group.active ? group.color : "#5e5e5e"

      let onChange = (e) => {

        let groups = this.state.groups
        groups[group_name].active = e.target.checked
        this.setState({groups})
      }
      return (
        <div className="name" style={{backgroundColor:color}}>

          <input type="checkbox"
                 checked={this.state.groups[group_name].active}
                 onChange={onChange}
          />
          <span>&nbsp;{group.caption.toUpperCase()}</span>
        </div>
      )
    }

    let rendergroup = (groupName) => {
      let group = this.state.groups[groupName]
      let year_min = this.state.data.yearMin // 2015 // TODO hardcoded
      let year_max = this.state.data.yearMax // 2018 // TODO hardcoded
      let year_options = makearray(year_min, year_max).map((year) => {
        return <option key={year} value={year}>{year}</option>
      })
      let month_options = makearray(1, 12).map((month) => {
        return <option key={month} value={month}>{month}</option>
      })
      let week_options = makearray(1, 53).map((week) => {
        return <option key={week} value={week}>{week}</option>
      })

      return(
        <div className="group">
          {group_header(groupName)}
          <div className="range">
            <div className="range-item">
              <label>Jahr</label>
              <select value={group.year} onChange={onDropdownChange} data-group={groupName} data-property={'year'} disabled={this.state.compareGroups === false && !group.primary}>
                {year_options}
              </select>
            </div>
            <div className="range-item">
              <label>Monat</label>
              <select value={group.month} onChange={onDropdownChange} data-group={groupName} data-property={'month'} disabled={this.state.timeframe === 'year' || this.state.timeframe === 'week' || (this.state.compareGroups === false && !group.primary) }>
                {month_options}
              </select>
            </div>
            <div className="range-item">
              <label>Woche</label>
              <select value={group.week} onChange={onDropdownChange} data-group={groupName} data-property={'week'} disabled={this.state.timeframe === 'year' || this.state.timeframe === 'month' || (this.state.compareGroups === false && ! group.primary) }>
                {week_options}
              </select>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="timeframe-and-groups">
        <div className="timeframe">
          <label>Vergleichsbasis</label>
          <select value={this.state.timeframe} onChange={onTimeframeChange}>
            <option key='year' value='year'>Jahr</option>
            <option key='month' value='month'>Monat</option>
            <option key='week' value='week'>Kalenderwoche</option>
          </select>
        </div>
        <div className="groups">
          {rendergroup('a')}
          {/*
          <div className="vs">
            <label htmlFor="checkbox_vs">vergleichen</label>
            <input id="checkbox_vs" type="checkbox" checked={this.state.compareGroups} onChange={onVsClick} />
          </div>
          */}
          {rendergroup('b')}

          <div className="group">
            {group_header('total')}
            <div className="range">
              <div className="range-item">
                alle Daten
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render_sales_numbers(stats, dateRanges) {

    let headerRow = (caption) => {
      return (
        <tr>
          <td className="section-header">
            {caption}
          </td>
          {this.state.groups.a.active ? <td className="subheader" style={{color:this.state.groups.a.color}}>{dateRanges.a.caption}</td> : null}
          {this.state.groups.b.active ? <td className="subheader" style={{color:this.state.groups.b.color}}>{dateRanges.b.caption}</td> : null}
          {this.state.groups.total.active ? <td className="subheader" style={{color:this.state.groups.total.color}}>{dateRanges.total.caption}</td> : null}
        </tr>
      )
    }

    let row = (caption, value1, value2, value3, suffix) => {
      return (
        <tr>
          <td>{caption}</td>
          {this.state.groups.a.active? <td className="value">{value1}{suffix || ''}</td> : null}
          {this.state.groups.b.active ? <td className="value">{value2}{suffix || ''}</td> : null}
          {this.state.groups.total.active ? <td className="value">{value3}{suffix || ''}</td> : null}
        </tr>
      )
    }

    return (
      <table>
        <tbody>
        {headerRow('Verkauf')}
        {row('Verkäufe',
          stats.surprises.a.itemsSold,
          stats.surprises.b.itemsSold,
          stats.surprises.total.itemsSold
        )}
        {row('Umsatz (CHF)',
          Math.floor(stats.surprises.a.totalValue),
          Math.floor(stats.surprises.b.totalValue),
          Math.floor(stats.surprises.total.totalValue)
        )}
        {row('Durchschnittswert (CHF)',
          Math.floor(Number(stats.surprises.a.totalValue / stats.surprises.a.itemsSold)),
          Math.floor(Number(stats.surprises.b.totalValue / stats.surprises.b.itemsSold)),
          Math.floor(Number(stats.surprises.total.totalValue / stats.surprises.total.itemsSold))
        )}

        {headerRow('Durchführung')}
        {row('Durchführungen',
          stats.surprises.a.itemsExecuted,
          stats.surprises.b.itemsExecuted,
          stats.surprises.total.itemsExecuted
        )}
        {row('Überraschte Personen',
          stats.surprises.a.participants,
          stats.surprises.b.participants,
          stats.surprises.total.participants
        )}
        {row('Durchschnittliche Bewertung',
          stats.surprises.a.ratingAverage.toFixed(1),
          stats.surprises.b.ratingAverage.toFixed(1),
          (stats.surprises.total.ratingAverage || 0).toFixed(1)
        )}
        {row('Durchschnittliche Zeit zwischen Kauf und Durchführung (Tage)',
          Math.floor(stats.surprises.a.daysToExecution),
          Math.floor(stats.surprises.b.daysToExecution),
          Math.floor(stats.surprises.total.daysToExecution)
        )}
        {row('Verhältnis Verkäufe/Durchführungen',
          Number(stats.surprises.a.itemsExecuted / (stats.surprises.a.itemsSold / 100)).toFixed(1),
          Number(stats.surprises.b.itemsExecuted / (stats.surprises.b.itemsSold / 100)).toFixed(1),
          Number(stats.surprises.total.itemsExecuted / (stats.surprises.total.itemsSold / 100)).toFixed(1),
          '%'
        )}
        {row('% durchgeführte',
          Number(stats.surprises.a.itemsSoldAndExecuted / (stats.surprises.a.itemsSold / 100)).toFixed(1),
          Number(stats.surprises.b.itemsSoldAndExecuted / (stats.surprises.b.itemsSold / 100)).toFixed(1),
          Number(stats.surprises.total.itemsSoldAndExecuted / (stats.surprises.total.itemsSold / 100)).toFixed(1),
          '%'
        )}



        {headerRow('Verkaufsverhalten')}
        {row('Anzahl Kunden, die beschenkt wurden und (danach) selber schenkten',
          stats.surprises.a.receivers_turned_into_buyers,
          stats.surprises.b.receivers_turned_into_buyers,
          stats.surprises.total.receivers_turned_into_buyers
        )}
        {row('Anzahl Kunden, die mehrmals gekauft haben',
          stats.surprises.a.repeat_buyers,
          stats.surprises.b.repeat_buyers,
          stats.surprises.total.repeat_buyers
        )}
        </tbody>
      </table>
    )
  }

  /**
   * Renders the sales per day of the week
   * @param stats_a The data for date range A
   * @param stats_b The data for date range B
   * @param stats_total The data for all time
   * @param dateRanges Meta data about the date ranges used for labels in the graph
   * @returns {*}
   */
  render_sales_per_weekday(stats_a, stats_b, stats_total, dateRanges) {
    let onDropdownChange = (e) => {
      let dataKeys = this.state.dataKeys
      dataKeys.sales.per.weekday = e.currentTarget.value
      this.setState({...this.state, dataKeys})
    }

    // combine the data so we can send it to the graph component
    let data = this.combineData(stats_a.sales.per.weekday, stats_b.sales.per.weekday, stats_total.sales.per.weekday, this.state.dataKeys.sales.per.weekday)

    return (
      <div className="chart-container">
        <div className="section-header">Verkauf pro Wochentag</div>
        <div className="section-notes">Offline verkaufte werden hier nicht berücksichtigt</div>
        <select value={this.state.dataKeys.sales.per.weekday} onChange={onDropdownChange}>
          <option value={'absolute'}>absolut</option>
          <option value={'percent'}>prozentual</option>
        </select>
        <ResponsiveContainer width="100%" height={200}>
          <BarChart data={data} width={800} height={200}>
            <XAxis dataKey="label">
              <Label value="Wochentag" position="insideBottom" offset={0} />
            </XAxis>
            <YAxis/>
            <Legend verticalAlign="top" height={36} />
            {this.state.groups.a.active ? <Bar dataKey={`${this.state.dataKeys.sales.per.weekday}_a`} fill={this.state.groups.a.color} name={dateRanges.a.caption} /> : null}
            {this.state.groups.b.active ? <Bar dataKey={`${this.state.dataKeys.sales.per.weekday}_b`} fill={this.state.groups.b.color} name={dateRanges.b.caption} /> : null}
            {this.state.groups.total.active ? <Bar dataKey={`${this.state.dataKeys.sales.per.weekday}_total`} fill={this.state.groups.total.color} name={dateRanges.total.caption} /> : null}
          </BarChart>
        </ResponsiveContainer>
      </div>
    )
  }

  render_sales_per_monthday(stats_a, stats_b, stats_total, dateRanges) {
    let onDropdownChange = (e) => {
      let dataKeys = this.state.dataKeys
      dataKeys.sales.per.monthday = e.currentTarget.value
      this.setState({...this.state, dataKeys})
    }

    // combine the data so we can send it to the graph component
    let data = this.combineData(stats_a.sales.per.monthday, stats_b.sales.per.monthday, stats_total.sales.per.monthday, this.state.dataKeys.sales.per.monthday)

    return (
      <div className="chart-container">
        <div className="section-header">Verkauf pro Tag im Monat</div>
        <div className="section-notes">Offline verkaufte werden hier nicht berücksichtigt</div>
        <select value={this.state.dataKeys.sales.per.monthday} onChange={onDropdownChange}>
          <option value={'absolute'}>absolut</option>
          <option value={'percent'}>prozentual</option>
        </select>
        <ResponsiveContainer width="100%" height={200}>
          <BarChart data={data} width={800} height={200}>
            <XAxis dataKey="label">
              <Label value="Monatstag" position="insideBottom" offset={0} />
            </XAxis>
            <YAxis/>
            <Legend verticalAlign="top" height={36} />
            {this.state.groups.a.active ? <Bar dataKey={`${this.state.dataKeys.sales.per.monthday}_a`} fill={this.state.groups.a.color} name={dateRanges.a.caption} /> : null}
            {this.state.groups.b.active ? <Bar dataKey={`${this.state.dataKeys.sales.per.monthday}_b`} fill={this.state.groups.b.color} name={dateRanges.b.caption} /> : null}
            {this.state.groups.total.active ? <Bar dataKey={`${this.state.dataKeys.sales.per.monthday}_total`} fill={this.state.groups.total.color} name={dateRanges.total.caption} /> : null}
          </BarChart>
        </ResponsiveContainer>
      </div>
    )
  }

  render_sales_per_time(stats_a, stats_b, stats_total, dateRanges) {
    let onDropdownChange = (e) => {
      let dataKeys = this.state.dataKeys
      dataKeys.sales.per.time = e.currentTarget.value
      this.setState({...this.state, dataKeys})
    }

    let data = this.combineData(stats_a.sales.per.time, stats_b.sales.per.time, stats_total.sales.per.time, this.state.dataKeys.sales.per.time)

    return (
      <div className="chart-container">
        <div className="section-header">Verkauf pro Tageszeit</div>
        <div className="section-notes">Offline verkaufte werden hier nicht berücksichtigt</div>
        <select value={this.state.dataKeys.sales.per.time} onChange={onDropdownChange}>
          <option value={'absolute'}>absolut</option>
          <option value={'percent'}>prozentual</option>
        </select>
        <ResponsiveContainer width="100%" height={200}>
          <BarChart data={data} width={800} height={200}>
            <XAxis dataKey="label">
              <Label value="Tageszeit" position="insideBottom" offset={0} />
            </XAxis>
            <YAxis/>
            <Legend verticalAlign="top" height={36} />
            {this.state.groups.a.active ?  <Bar dataKey={`${this.state.dataKeys.sales.per.time}_a`} fill={this.state.groups.a.color} name={dateRanges.a.caption}  /> : null}
            {this.state.groups.b.active ? <Bar dataKey={`${this.state.dataKeys.sales.per.time}_b`} fill={this.state.groups.b.color} name={dateRanges.b.caption}  /> : null}
            {this.state.groups.total.active ? <Bar dataKey={`${this.state.dataKeys.sales.per.time}_total`} fill={this.state.groups.total.color} name={dateRanges.total.caption}  /> : null}
          </BarChart>
        </ResponsiveContainer>
      </div>
    )
  }

  render_activity_ranking(stats_a, stats_b, stats_total, dateRanges) {
    let items_a = stats_a.ranking.map(item => item).sort((a,b) => a.value > b.value ? -1 : 1)
    let items_b = stats_b.ranking.map(item => item).sort((a,b) => a.value > b.value ? -1 : 1)
    let items_total = stats_total.ranking.map(item => item).sort((a,b) => a.value > b.value ? -1 : 1)

    let items = []
    let csvLines = [`${dateRanges['a'].caption};${dateRanges['b'].caption};${dateRanges['total'].caption}`];

    for(let i = 0; i < (items_a.length > items_b.length ? items_a.length : items_b.length); i+=1) {
      let a = items_a[i] || {caption:'', value:null}
      let b = items_b[i] || {caption:'', value:null}
      let total = items_total[i] || {caption:'', value:null}
      items.push(
        <tr key={i}>
          <td>{i + 1}.</td>
          {this.state.groups.a.active ? <td>{a.caption} {a.value ? `(${a.value})` : ''}</td> : null}
          {this.state.groups.b.active ? <td>{b.caption} {b.value ? `(${b.value})` : ''}</td> : null}
          {this.state.groups.total.active ? <td>{total.caption} {total.value ? `(${total.value})` : ''}</td>:null}
        </tr>
      )
      csvLines.push(`${a.caption} (${a.value ? a.value : ''});${b.caption} (${b.value ? b.value : ''});${total.caption} (${total.value ? total.value : ''})`)
    }

    let th = (group_name) => {
      let group = this.state.groups[group_name]
      return group.active ? <th style={{color:group.color}}>{dateRanges[group_name].caption}</th> : null
    }

    let csv = csvLines.join('\n');

    return (
      <div>
        <div className="section-header">Aktivitäten</div>
        <table>
          <thead>
            <tr>
              <th></th>
              {th('a')}
              {th('b')}
              {th('total')}
            </tr>
          </thead>
          <tbody>
            {items}
          </tbody>
        </table>
        <br />
        <CsvDownload csv={csv} filename={`ranking_activities_${Util.printDate(new Date())}.csv`} size="small" />
      </div>

    )

  }

  render_regions(stats_a, stats_b, stats_total, dateRanges) {
    let label = (v) => {
      return v.name
    }

    // bakes a pie chart
    let bake = (data, group, caption, width) => {
      if(!group.active) {
        return null
      }
      else {
        return (
          <div style={{width:width}}>
            <ResponsiveContainer width="100%" height={260}>
              <PieChart width={400} height={260}>
                <Pie data={data} dataKey="percent" nameKey="name" cx="50%" cy="50%" outerRadius="80%" fill="#8884d8" label={label} legendType={"circle"} >
                  <LabelList />
                  {
                    data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color}/>
                    ))
                  }
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            <div style={{color:group.color, textAlign:"right"}}>
              {
                data
                  .sort((a, b) => a.percent > b.percent ? -1 : 1)
                  .map((entry, index) => {
                    return <div key={index}>{entry.name}: {entry.percent}%</div>
                  })
              }
            </div>
            <div style={{color:group.color, textAlign:"center"}}>{caption}</div>
          </div>
        )
      }

    }

    let pie_width = `${Math.floor(100 / this.countActiveGroups())}%`

    // TODO actual data for totals ... stats_total.region
    return (
      <div className="chart-container">
        <div className="section-header">Regionen</div>
        <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
          {bake(stats_a.regions, this.state.groups.a, dateRanges.a.caption, pie_width)}
          {bake(stats_b.regions, this.state.groups.b, dateRanges.b.caption, pie_width)}
          {bake(stats_total.regions, this.state.groups.total, dateRanges.total.caption, pie_width)}
        </div>
      </div>
    )
  }

  /* Calculation and Helper Methods ***********************************************************/

  async loadData(forceRefresh) {
    // update statistical data (whether update is necessary or not is decided serverside) ... the flag 'purge' lets us enforce complete rebuilding of data
    this.setState({...this.state, data:{}, mode:"loading", mode_message:"bereite Daten auf dem Server vor ..."})
    await BL.Statistics.update(forceRefresh)

    // get statistical data
    this.setState({...this.state, data:{}, mode:"loading", mode_message:"lade statistische Daten ..."})
    let data = await BL.Statistics.load(forceRefresh)

    // done
    this.setState({...this.state, data, mode:'ready'})
  }


  getDateRanges() {
    let getRange = (group) => {
      switch(this.state.timeframe) {
        case 'week':
          return BL.Statistics.getWeekRange(group.year, group.week)
        case 'month':
          return BL.Statistics.getMonthRange(group.year, group.month)
        case 'year':
        default:
          return BL.Statistics.getYearRange(group.year)
      }
    }
    return {
      a: getRange(this.state.groups.a),
      b: getRange(this.state.groups.b),
      total: {
        from:new Date(1900, 1, 1),  // TODO actual date
        to:new Date(2100, 1, 1),    // TODO actual date
        caption:'Total'
      }
    }
  }


  countActiveGroups() {
    let count = 0
    count += this.state.groups.a.active ? 1 : 0
    count += this.state.groups.b.active ? 1 : 0
    count += this.state.groups.total.active ? 1 : 0
    return count
  }


  /**
   * Combines sets of data so they can be used by the Graph component
   * @param a
   * @param b
   * @param total
   * @param property
   * @returns {Array}
   */
  combineData(a, b, total, property) {
    // TODO we are not dealing with cases where the two sets do not have the same number of items
    let combined = []
    for( let i=0; i < a.length; i++) {
      let item = Object.assign({}, a[i])
      delete item[property]
      item[`${property}_a`] = a[i][property]
      item[`${property}_b`] = b[i][property]
      item[`${property}_total`] = total[i][property]
      combined.push(item)
    }
    return combined
  }


}

export default Statistics
