import React, { ReactElement, useContext, useState } from "react";
import { PageContext } from "./Page.Context";

// types
import * as Cms from "../../../types/Cms";

// components
import { Button, Icon, Icons, Modal, Row, SortableList, SortableListItem } from "../../controls";
import AddModule from "./Page.AddModule";
import * as Modules from "./Modules";

// styling
import CSS from "./Page.Modules.module.scss";
import _ from "lodash";

export default function ModuleList() {
  
  // context
  const PC = useContext(PageContext);

  // state
  const [moduleToEdit, setModuleToEdit] = useState<Cms.Module | null>(null);
  const [expandAll, setExpandAll] = useState<boolean>(false);
  const [sortingDisabled, setSortingDisabled] = useState<boolean>(false);

  // user drops item
  const onDrop = async(id: string, targetIndex: number) => {
    setSortingDisabled(true);
    await PC.moveModule(id, targetIndex);
    setSortingDisabled(false);
    
  }

  // closes edit dialog
  const closeDialog = () => {
    setModuleToEdit(null);
  }

  // user wants to edit, delete or duplicate
  const onClickAction = async (module: Cms.Module, action: "edit" | "delete" | "duplicate") => {
    switch (action) {
      case "edit":
        await onClickEdit(module);
        break;
      case "delete":
        await onClickDelete(module);
        break;
      case "duplicate":
        await onClickDuplicate(module);
        break;
    }
  }

  // user wants to add a module
  const onClickEdit = async (module: Cms.Module) => {
    setModuleToEdit(module);
  }

  // user wants to delete a module
  const onClickDelete = async (module: Cms.Module) => {
    await PC.removeModule(module);
  }

  // user wants to duplicate a module
  const onClickDuplicate = async (module: Cms.Module) => {
    await PC.duplicateModule(module);
  }

  // pre-render
  //const items: ReactElement[] = modules.map((module: Cms.Module, index: number) => {
  const items: ReactElement[] = PC.page!.modules.sort((a: Cms.Module, b: Cms.Module) => a.position > b.position ? 1 : -1).map((module: Cms.Module, index: number) => {
    const previewProps = { instance: module, expanded: expandAll, onClickAction: onClickAction };
    let preview: ReactElement;
    let info: Cms.ModuleInfo | null = null;
    switch (module.code) {
      case Cms.HeroModule.info.code:
        preview = <Modules.HeroPreview {...previewProps} />
        info = Cms.HeroModule.info;
        break;
      case Cms.BasicModule.info.code:
        preview = <Modules.BasicPreview {...previewProps} />
        info = Cms.BasicModule.info;
        break;
      case Cms.AccordionModule.info.code:
        preview = <Modules.AccordionPreview {...previewProps} />
        info = Cms.AccordionModule.info;
        break;
      case Cms.CardSliderModule.info.code:
        preview = <Modules.CardSliderPreview {...previewProps} />
        info = Cms.CardSliderModule.info;
        break;
      case Cms.IconTilesModule.info.code:
        preview = <Modules.IconTilesPreview {...previewProps} />
        info = Cms.IconTilesModule.info;
        break;
      case Cms.LogosModule.info.code:
        preview = <Modules.LogosPreview {...previewProps} />
        info = Cms.LogosModule.info;
        break;
      case Cms.NumberedTextListModule.info.code:
        preview = <Modules.NumberedTextListPreview {...previewProps} />
        info = Cms.NumberedTextListModule.info;
        break;
      case Cms.SimpleHeaderModule.info.code:
        preview = <Modules.SimpleHeaderPreview {...previewProps} />
        info = Cms.SimpleHeaderModule.info;
        break;
      case Cms.CustomFooterModule.info.code:
        preview = <Modules.CustomFooterPreview {...previewProps} />
        info = Cms.CustomFooterModule.info;
        break;
      case Cms.TestimonialModule.info.code:
        preview = <Modules.TestimonialPreview {...previewProps} />
        info = Cms.TestimonialModule.info;
        break;
      case Cms.TestimonialCarouselModule.info.code:
        preview = <Modules.TestimonialCarouselPreview {...previewProps} />
        info = Cms.TestimonialCarouselModule.info;
        break;
      case Cms.TrustBannerModule.info.code:
        preview = <Modules.TrustBannerPreview {...previewProps} />
        info = Cms.TrustBannerModule.info;
        break;
      case Cms.ActivitiesStaticModule.info.code:
        preview = <Modules.ActivitiesStaticPreview {...previewProps} />
        info = Cms.ActivitiesStaticModule.info;
        break;
      case Cms.VideoModule.info.code:
        preview = <Modules.VideoPreview {...previewProps} />
        info = Cms.VideoModule.info;
        break;
      case Cms.NewsletterModule.info.code:
        preview = <Modules.NewsletterPreview {...previewProps} />
        info = Cms.NewsletterModule.info;
        break;
      default:
        preview = <div>unbekannter Modultyp mit code '${module.code}'</div>;
        break;
    }

    return (
      <SortableListItem key={module._id} id={module._id || index.toString()}>
        {preview}
      </SortableListItem>
    )
  });

  // pre-render modal content
  let modalContent = null;

  const props: any = { closeDialog, instance: moduleToEdit }

  if (moduleToEdit !== null) {
    switch (moduleToEdit.code) {
      case Cms.HeroModule.info.code:
        modalContent = <Modules.Hero {...props} />
        break;
      case Cms.BasicModule.info.code:
        modalContent = <Modules.Basic {...props} />
        break;
      case Cms.AccordionModule.info.code:
        modalContent = <Modules.Accordion {...props} />
        break;
      case Cms.CardSliderModule.info.code:
        modalContent = <Modules.CardSlider {...props} />
        break;
      case Cms.IconTilesModule.info.code:
        modalContent = <Modules.IconTiles {...props} />
        break;
      case Cms.LogosModule.info.code:
        modalContent = <Modules.Logos {...props} />
        break;
      case Cms.NumberedTextListModule.info.code:
        modalContent = <Modules.NumberedTextList {...props} />
        break;
      case Cms.SimpleHeaderModule.info.code:
        modalContent = <Modules.SimpleHeader {...props} />
        break;
      case Cms.CustomFooterModule.info.code:
        modalContent = <Modules.CustomFooter {...props} />
        break;
      case Cms.TestimonialModule.info.code:
        modalContent = <Modules.Testimonial {...props} />
        break;
      case Cms.TestimonialCarouselModule.info.code:
        modalContent = <Modules.TestimonialCarousel {...props} />
        break;
      case Cms.TrustBannerModule.info.code:
        modalContent = <Modules.TrustBanner {...props} />
        break;
      case Cms.ActivitiesStaticModule.info.code:
        modalContent = <Modules.ActivitiesStatic {...props} />
        break;
      case Cms.VideoModule.info.code:
        modalContent = <Modules.Video {...props} />
        break;
      case Cms.NewsletterModule.info.code:
        modalContent = <Modules.Newsletter {...props} />
        break;
      default:
        modalContent = <div>unbekannter Modultyp</div>;
        break;
    }
  }


  // render
  return <>
    <div id="page" className={CSS.container}>
      <Row>
        <AddModule />
        <Button size="small" onClick={() => setExpandAll(true)}><Icon icon={Icons.PlusSquare} /></Button>
        <Button size="small" onClick={() => setExpandAll(false)}><Icon icon={Icons.MinusSquare} /></Button>
      </Row>
      <SortableList onDrop={onDrop} disabled={sortingDisabled}>
        {items}
      </SortableList>
    </div>
    <Modal isOpen={moduleToEdit !== null} title="Module bearbeiten" onClose={closeDialog}>
      {modalContent}
    </Modal>
  </>
}
