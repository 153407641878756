import React, {useEffect, useState, useContext} from "react";
import Util from '../../../../util/util'
import Api from "../../../../util/api";
// components
import { Alert, CardSection, DriveLink, Loader } from "../../../controls";

// styling
import CSS from './Receipts.module.scss'

type ReceiptsProps = {
  surprise: any,
}
export default function Receipts({surprise} : ReceiptsProps) {
  // state
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [receipts, setReceipts] = useState<any[]>([]);

  // mount
  useEffect(() => {
    load();
  }, []);

  // receipts changed
  useEffect(() => {
    
  }, [receipts]);

  // loads data
  const load = async() => {
    
    setIsLoading(true);
    
    let requestBody = {
      filter:{"surprise_id":surprise._id},
      projection:{}
    }
    const apiResult = await Api.post("receipts/search", requestBody);

    setReceipts(apiResult.data);
    setIsLoading(false);
  }

  // still loading
  if(isLoading) {
    return <Loader text="lade Dokumente ..." />
  }

  // render
  return (
    <CardSection title="Belege">
      <div className={CSS.container}>
        <List receipts={receipts} />
        <div>
          <Alert intent="info" title="Belege werden nicht mehr unterstützt" size="medium">
            Neue Belege können nicht mehr hinzugefügt werde. Erfasse stattdessen eine Rechnung.
          </Alert>
        </div>
      </div>
    </CardSection>
  )
}

type ListProps = {
  receipts: any[]
}
function List({receipts}: ListProps) {
  const formatNumber = (n:any) => {
    if(isNaN(n)) {
      n = 0
    }
    return parseFloat(String(Math.round(Number(n) * 100) / 100)).toFixed(2);
  } // TODO move this to some utility collection

  let total = 0;

  // pre-render items
  if(receipts.length > 0) {
    const rows = receipts.map((receipt, index) => {
      total += Number(receipt.amount || 0)
      return (
        <tr key={index}>
          <td>{receipt.bookingText}</td>
          <td>{receipt.providerName}</td>
          <td>
            <DriveLink driveId={receipt.driveId}>{receipt.driveTitle || 'file'}</DriveLink>
          </td>
          <td>
            {Util.printDate(receipt.receiptDate)}
          </td>
          <td style={{textAlign:'right'}}>{formatNumber(receipt.amount)}</td>
        </tr>
      )
    })

    // add total
    rows.push(
      <tr key="total" className="total">
        <td>TOTAL</td>
        <td colSpan={3}></td>
        <td className="sum">{formatNumber(total)}</td>
      </tr>
    )

    // return the table
    return (
      <table>
        <thead>
        <tr>
          <th>Buchungstext</th>
          <th>Anbieter</th>
          <th>File</th>
          <th>Datum</th>
          <th>Betrag</th>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </table>
    )
  }
  else {
    return null;
  }
}





/* example of an upload response :

 {
  "kind":"drive#file",
  "id":"0Bw79iBczf9jdT24zVkRrNUNhcDQ",
  "etag":"\"rpiaj_6IM3Sq7Rqb_-Ag3GlnZZU/MTQ4ODk3MzU5NTk4MQ\"",
  "selfLink":"https://www.googleapis.com/drive/v2/files/0Bw79iBczf9jdT24zVkRrNUNhcDQ",
  "webContentLink":"https://drive.google.com/a/appentura.ch/uc?id=0Bw79iBczf9jdT24zVkRrNUNhcDQ&export=download",
  "alternateLink":"https://drive.google.com/a/appentura.ch/file/d/0Bw79iBczf9jdT24zVkRrNUNhcDQ/view?usp=drivesdk",
  "embedLink":"https://drive.google.com/a/appentura.ch/file/d/0Bw79iBczf9jdT24zVkRrNUNhcDQ/preview?usp=drivesdk",
  "iconLink":"https://ssl.gstatic.com/docs/doclist/images/icon_10_generic_list.png",
  "title":"ApiTest.rtf",
  "mimeType":"text/rtf",
  "labels":{
  "starred":false,
  "hidden":false,
  "trashed":false,
  "restricted":false,
  "viewed":true},
  "createdDate":"2017-03-08T11:46:35.981Z",
  "modifiedDate":"2017-03-08T11:46:35.981Z",
  "modifiedByMeDate":"2017-03-08T11:46:35.981Z",
  "lastViewedByMeDate":"2017-03-08T11:46:35.981Z",
  "markedViewedByMeDate":"1970-01-01T00:00:00.000Z",
  "version":"27186",
  "parents":[
  {
  "kind":"drive#parentReference",
  "id":"0AA79iBczf9jdUk9PVA",
  "selfLink":"https://www.googleapis.com/drive/v2/files/0Bw79iBczf9jdT24zVkRrNUNhcDQ/parents/0AA79iBczf9jdUk9PVA",
  "parentLink":"https://www.googleapis.com/drive/v2/files/0AA79iBczf9jdUk9PVA",
  "isRoot":true
  }
  ],
  "downloadUrl":"https://doc-00-ac-docs.googleusercontent.com/docs/securesc/t2sua69qhmq5to6g…488/0Bw79iBczf9jdT24zVkRrNUNhcDQ?h=17793498138710249245&e=download&gd=true",
  "userPermission":{
  "kind":"drive#permission",
  "etag":"\"rpiaj_6IM3Sq7Rqb_-Ag3GlnZZU/Lx4qaVWLOk3amGoJCqaPXuKw79s\"",
  "id":"me",
  "selfLink":"https://www.googleapis.com/drive/v2/files/0Bw79iBczf9jdT24zVkRrNUNhcDQ/permissions/me",
  "role":"owner",
  "type":"user"
  },
  "originalFilename":"ApiTest.rtf",
  "fileExtension":"rtf",
  "md5Checksum":"8aeeb9dddf3a5ede90ffc90d66af5303",
  "fileSize":"390",
  "quotaBytesUsed":"390",
  "ownerNames":["Martin Imfeld"],
  "owners":[
  {
  "kind":"drive#user",
  "displayName":"Martin Imfeld",
  "picture":{"url":"https://lh5.googleusercontent.com/-b-vKGNbHtM0/AAAAAAAAAAI/AAAAAAAAAI0/GK7wRitoOzo/s64/photo.jpg"},
  "isAuthenticatedUser":true,
  "permissionId":"16406473814327499488",
  "emailAddress":"martin@appentura.ch"
  }
  ],
  "lastModifyingUserName":"Martin Imfeld",
  "lastModifyingUser":{
  "kind":"drive#user",
  "displayName":"Martin Imfeld",
  "picture":{
  "url":"https://lh5.googleusercontent.com/-b-vKGNbHtM0/AAAAAAAAAAI/AAAAAAAAAI0/GK7wRitoOzo/s64/photo.jpg"
  },
  "isAuthenticatedUser":true,
  "permissionId":"16406473814327499488",
  "emailAddress":"martin@appentura.ch"
  },
  "capabilities":{
  "canCopy":true,
  "canEdit":true
  },
  "editable":true,
  "copyable":true,
  "writersCanShare":true,
  "shared":false,
  "explicitlyTrashed":false,
  "appDataContents":false,
  "headRevisionId":"0Bw79iBczf9jddWlHdmRXcGJmMUxDNGRLZGxXUThJd0N6cWE4PQ",
  "spaces":["drive"]
  }




 */