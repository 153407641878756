import * as DA from "../../types/DataAccess";

type UserCache = {
  current: DA.User|null
}
const cache: UserCache = {
  current: null
}

export class User {
  static async getCurrent() : Promise<DA.User> {
    if(cache.current) {
      return cache.current;
    }
    else {
      // get google login data from localStorage
      // Note: this relies on the user never getting here if he is not logged in
      const storage_string = window.localStorage.getItem('appentura_user');
      const storage_object:any = JSON.parse(storage_string || "");
      const user = await DA.UserRepository.findByEmail(storage_object.email!)
      cache.current = user;
      return cache.current!;
    }
  }

  static firstNameFromEmail(email:string): string {
    const fn = email.split("@")[0];
    return fn.charAt(0).toUpperCase() + fn.slice(1);
  }
}