import _ from "lodash";
import React, {useContext} from "react";
import { Link } from "../../../../controls";
import {SurpriseContext} from "../../Surprise.Context";
import Row from "./Row";

export default function Doner() {
  // context 
  const SC = useContext(SurpriseContext);
  // render
  const s = SC.surprise;
  const mailLink = <Link to={`mailto:${_.get(s, "ReserverEmail")}`}>{_.get(s, "ReserverEmail")}</Link>;
  return <Row label="Schenker">{_.get(s, "ReserverName") || "Unbekannt"} ({mailLink})</Row>
}
