import React, {useState, useEffect} from "react"
import Util from "../../../../util/util"
import * as TS from "../../../../types";
// components
import ActivitiesFix from "./ActivitiesFix";
import SoldOfflineFix from "./SoldOfflineFix";
import {Loader} from "../../../controls";


function SurpriseFix(props:any) {
  const [adventure, setAdventure] = useState(null);
  const [neededFix, setNeededFix] = useState(""); // "activitiesFix", "soldOfflineFix"
  
  useEffect(() => {
    const load = async() => {
      // get id from router and load surprise
      const id = Util.getRouteId(props);
      const adv = await TS.AdventureOld.load(id);
      // which fix?
      const needsSoldOfflineFix = await TS.AdventureOld.needsSoldOfflineFix(adv);
      const needsActivitiesFix = await TS.AdventureOld.needsActivitiesFix(adv);
      if(needsSoldOfflineFix) {
        setNeededFix("soldOfflineFix");
      }
      else if(needsActivitiesFix) {
        setNeededFix("activitiesFix")
      }
      setAdventure(adv);
    };
    load();
  }, [props])

  // render loading
  if(!adventure) {
    return <Loader />
  }

  // render
  let fix = null;
  switch(neededFix) {
    case "soldOfflineFix":
      fix = <SoldOfflineFix adventure={adventure} />
      break;
    case "activitiesFix":
      fix = <ActivitiesFix adventure={adventure} />
      break;
    default:
      fix = <div>nichts zu fixen ...</div>
      break;
  }
  return fix;
}

export default SurpriseFix
