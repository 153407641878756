import React, {useContext} from "react";
import { Icon, Icons, LinkButton } from "../../../../controls";
import { SurpriseContext } from "../../Surprise.Context";
import Row from "./Row";

export default function SMS() {
  // context 
  const SC = useContext(SurpriseContext);

  // no render if the adventurer has started
  if(SC.surprise.CurrentStep > 0 || SC.surprise.StartTime > Date.now() ) {
    return null;
  }

  // render 
  const text = `Hallo ${SC.surprise.RecieverRealFirstName || SC.surprise.RecieverName}. Dein Appentura-Erlebnis erwartet dich! Rufe deine Überraschungsseite auf, um nichts zu verpassen: ${SC.surprise.ShortUrl} ... Viel Spass! Dein Team Appentura :)`;
  return (
    <Row label="SMS Aufforderung zum Start">
      <div>
        {text}
      </div>
      <div className="c-sms-buttons">
        <LinkButton to={`sms:${SC.surprise.RecieverPhone}?body=${text}`}><Icon icon={Icons.CommentSms} />&nbsp;Android</LinkButton>
        <LinkButton to={`sms:${SC.surprise.RecieverPhone}&body=${text}`}><Icon icon={Icons.CommentSms} />&nbsp;iPhone</LinkButton>
      </div>
    </Row>
  )
}
