import React from "react";
import Code from "../Code";
import Notes from "../Notes";
import {Actions, Card,  Heading, } from "../../../controls";

export default function ActionsDemo() {
  const onEdit = (data:any) => alert(`edit ${data}`);
  const onDelete = (data:any) => alert(`delete ${data}`);
  const onDuplicate = (data:any) => alert(`duplicate ${data}`);
  const onSave = (data:any) => alert(`save ${data}`);
  const onCancel = (data:any) => alert(`save ${data}`);

  return (
    <Card>
      <Heading>Actions</Heading>
      <Notes>
        Displays edit and delete action buttons
      </Notes>
      
      <Code>
        {
`
`
        }
      </Code>
      <h2>size (regular, small)</h2>
      <Actions onDelete={onDelete} onEdit={onEdit} onDuplicate={onDuplicate} onCancel={onCancel} onSave={onSave} data={"1"} />
      <br />
      <Actions size="small" onDelete={onDelete} onEdit={onEdit} />
      <h2>hide buttons by not supplying event handler</h2>
      <Actions size="small" onEdit={onEdit} />
      <h2>disabled, busy</h2>
      <Actions size="small" disabled={true} onDelete={onDelete} onEdit={onEdit} /> 
      <Actions size="small" busy={true} onDelete={onDelete} onEdit={onEdit} /> 
      <h2>align (left, center, right)</h2>
      <Actions size="small" align="left" disabled={true} onDelete={onDelete} onEdit={onEdit} /> 
      <Actions size="small" align="right" disabled={true} onDelete={onDelete} onEdit={onEdit} /> 
      <Actions size="small" align="center" disabled={true} onDelete={onDelete} onEdit={onEdit} /> 
    </Card>
  )
}
