import React from "react";
import { LinkButton, Icon, Icons } from "../../controls";

type DownloadProps = {
  painFileContent?: any
}
export default function Download({painFileContent}: DownloadProps) {
  if(painFileContent) {
    return (
      <LinkButton to={"data:text/xml;charset=utf-8," + encodeURIComponent(painFileContent)} download="zahlungen.pain.xml">
        <Icon icon={Icons.Download} />&nbsp;Zahlungsfile herunterladen
      </LinkButton>    
    )
  }
  else {
    return null;
  }
}