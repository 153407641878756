
export class AdventureOrderInvoiceAddress {
  FirstName: string|null = null;
  LastName: string|null = null;
  Company: string|null = null;
  Address1: string|null = null;
  Address2: string|null = null;
  Zip: string|null = null;
  City: string|null = null;
  Country: string|null = null;

  public static fromDb(obj: any) : AdventureOrderInvoiceAddress {
    const a = new AdventureOrderInvoiceAddress();
    a.FirstName = obj.FirstName || null;
    a.LastName = obj.LastName || null;
    a.Company = obj.Company || null;
    a.Address1 = obj.Address1 || null;
    a.Address2 = obj.Address2 || null;
    a.Zip = obj.Zip || null;
    a.City = obj.City || null;
    return a;    
  }
}

export class AdventureOrderShippingAddress {
  FirstName: string|null = null;
  LastName: string|null = null;
  Company: string|null = null;
  Address1: string|null = null;
  Address2: string|null = null;
  Zip: string|null = null;
  City: string|null = null;
  Country: string|null = null;

  public static fromDb(obj: any) : AdventureOrderShippingAddress {
    const a = new AdventureOrderShippingAddress();
    a.FirstName = obj.FirstName || null;
    a.LastName = obj.LastName || null;
    a.Company = obj.Company || null;
    a.Address1 = obj.Address1 || null;
    a.Address2 = obj.Address2 || null;
    a.Zip = obj.Zip || null;
    a.City = obj.City || null;
    return a;    
  }
}

export class AdventureOrderPackaging {
  Code: string|null = null;
  Price: number|null = null;

  public static fromDb(obj: any) : AdventureOrderPackaging {
    const p = new AdventureOrderPackaging();
    p.Code = obj.Code || null;
    p.Price = obj.Price ? Number(obj.Price) : null;
    return p;
  }
}

export class AdventureOrderVoucher {
  Code: string|null = null;
  Kind: "percentage"|"amount"|null = null;
  Value: number|null = null; // note: this is the value in its kind, eg. 10% or 10 CHF
  
  public static fromDb(obj:any) : AdventureOrderVoucher|null {
    if(!obj) {
      return null;
    }
    const v = new AdventureOrderVoucher();
    v.Code = obj.Code || null;
    v.Kind = obj.Kind || null;
    v.Value = obj.Value ? Number(obj.Value) : null;
    return v;
  }

  public toString(): string {
    return `${this.Code || "---"}, ${this.Value || 0}${this.Kind === "percentage" ? "%" : "CHF/EUR"}`
  }
}

export class AdventureOrderMe {
  // fields
  ActivityTypes: string[] = []; // should match activity tags
  DurationInDays: number = 0;
  ParticipantBudget: number = 0;
  ParticipantCount: number = 0;
  PackageName: string|null = null;
  Accommodation: string|null = null;
  AccommodationPrice: number = 0; // price per night per person

  public static fromDb(obj:any) : AdventureOrderMe|null {
    if(!obj) {
      return null;
    }
    const me = new AdventureOrderMe();
    me.ActivityTypes = (obj.ActivityTypes || []).map((s:any) => String(s));
    me.DurationInDays = obj.DurationInDays;
    me.ParticipantBudget = obj.ParticipantBudget;
    me.ParticipantCount = obj.ParticipantCount;
    me.PackageName = obj.PackageName;
    me.Accommodation = obj.Accommodation || null;
    me.AccommodationPrice = obj.AccommodationPrice || 0;
    return me;
  }
}

export class AdventureOrder {
  IsDirectShipping: boolean = false;
  InvoiceAddress: AdventureOrderInvoiceAddress = new AdventureOrderInvoiceAddress();
  ShippingAddress: AdventureOrderShippingAddress = new AdventureOrderShippingAddress();
  Packaging: AdventureOrderPackaging = new AdventureOrderPackaging();
  Me: AdventureOrderMe|null = null;
  Voucher: AdventureOrderVoucher|null = null;

  public static fromDb(obj:any) : AdventureOrder {
    const order = new AdventureOrder();
    order.IsDirectShipping = obj.IsDirectShipping || false;
    order.InvoiceAddress = AdventureOrderInvoiceAddress.fromDb(obj.InvoiceAddress);
    order.ShippingAddress = AdventureOrderShippingAddress.fromDb(obj.ShippingAddress);
    order.Packaging = AdventureOrderPackaging.fromDb(obj.Packaging);
    order.Me = AdventureOrderMe.fromDb(obj.Me);
    order.Voucher = AdventureOrderVoucher.fromDb(obj.Voucher);
    return order;
  }
}