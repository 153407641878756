import { Equipment } from "./Equipment";
import { Mapper } from "../_Common/Mapper";
import { TranslatableMapper } from "../_Common/TranslatableMapper";


export class EquipmentMapper extends Mapper<Equipment> {
  public apiEndpoint = "equipment";

  public toDb(equipment: Equipment): any {
    return {
      _id: equipment._id,
      title: TranslatableMapper.toDb(equipment.title),
    }
  }

  public fromDb(obj:any): Equipment {
    return {
      _id: obj._id || undefined,
      title: TranslatableMapper.fromDb(obj.title),
    }
  }

}