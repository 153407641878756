import * as DA from '../DataAccess';

export class Provider {
  /**
   * Returns the default location for a provider. Default location is the first location that has no activity, regionVariant or venue
   * @param provider 
   * @returns 
   */
  public static async getDefaultLocation(provider: DA.Provider): Promise<DA.ProviderLocation|null> {
    const locations = await DA.ProviderLocationRepository.findByProviderId(provider._id!);
    const items = locations.filter(location => location.activityId === null && location.regionVariantId === null && location.venue === null);
    return items[0] || null;
  }
  
  /**
   * While the schema supports multiple (i.e. an array of) 'booking' items the front-end currently only offers one ... hence the booking will always overwrite the booking[0] item
   * @param provider 
   * @returns 
   */
  public static getBookingKind(provider: DA.Provider): DA.ProviderBookingInfoKind|null {
    if(provider.booking.length === 0) return null;
    return provider.booking[0].kind;
  }

  public static getBookingEmail(provider: DA.Provider): string|null {
    return Provider.getBookingKind(provider) === "email" ? provider.booking[0].value : null;
  }

  public static colorRatingToValue(color:any): number {
    switch((color || '').toLowerCase()) {
      case "green":
        return 1;
      case "orange":
        return 2;
      case "red":
        return 3;
      default:
        return 4
    }
  }


  public static printAddress(address: DA.ProviderAddress, divider:string = ", "): string {
    let lines = [
      address.companyName,
      `${address.firstName} ${address.lastName}`,
      `${address.street} ${address.houseNumber}`,
      `${address.zip} ${address.city}`,
      address.countryCode
    ].filter(line => line.trim().length > 0)
  
    return lines.join(divider);
  }

  public static getContactKindLabel(kind: DA.ProviderContactKind): string {
    switch(kind) {
      case "allgemein":
        return "Allgemein";
      case "meteo":
        return "Meteocheck";
      default:
        return "Unbekannt";
      }
  }
}