import React, {useState, useEffect, useContext} from "react"
import _ from "lodash"

import AssistantLogic from "./Assistant.Logic";
import * as TS from "../../../../types";
import * as DA from "../../../../types/DataAccess";
import * as BL from "../../../../types/BusinessLogic";

// contexts
import { AssistantContext } from "./Assistant.Context";
import { BookingRequestProvider } from "./BookingRequest.Context";

// components
import { Loader } from "../../../controls";
import FormEmail from "./BookingRequest.Form.Email";
import FormPhone from "./BookingRequest.Form.Phone";
import FormWeb from "./BookingRequest.Form.Web";
import Header from "./BookingRequest.Header";
import Info from "./BookingRequest.Info";

// styling
import "./BookingRequest.scss";


type BookingRequestProps = {
  adventureInfo: TS.AdventureOutlineAdventureInfo,
  activityInfo: TS.AdventureOutlineActivity,
  providerId: string,
  regionVariantId: string,
  onDone: Function,
  closeDialog: () => void
}
function BookingRequest({adventureInfo, activityInfo, providerId, regionVariantId, onDone, closeDialog} : BookingRequestProps) {
  // context
  const AC = useContext(AssistantContext);
    
  // state
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [provider, setProvider] = useState<DA.Provider|null>(null);
  const [currentUser, setCurrentUser] = useState<DA.User|null>(null);
  
  // mount
  useEffect(() => {
    const load = async() => {
      setIsLoading(true)
      // load data
      const currentUser = await BL.User.getCurrent();
      const provider = await DA.ProviderRepository.findById(providerId);
      // update state
      setCurrentUser(currentUser);
      setProvider(provider);
      setIsLoading(false);
    }
    load()
  }, [providerId])

  
  // render
  let content = null
  let modalTitle = null
  if(isLoading) {
    modalTitle = "Buchungsanfrage"
    content = <Loader text="lade Daten ..." />
  }
  else {
    modalTitle = `${activityInfo.title} bei '${provider!.name}' buchen`
    let form;
    const bookingInfo = provider!.booking[0];
    switch(bookingInfo.kind) {
      case "web":
        form = <FormWeb adventureInfo={adventureInfo} activityInfo={activityInfo} provider={provider!} currentUser={currentUser!} reloadBookingRequests={onDone} closeDialog={closeDialog} />
        break
      case "email":
        form = <FormEmail adventureInfo={adventureInfo} activityInfo={activityInfo} provider={provider!} currentUser={currentUser!} reloadBookingRequests={onDone} closeDialog={closeDialog} />
        break
      case "phone":
        form = <FormPhone adventureInfo={adventureInfo} activityInfo={activityInfo} provider={provider!} reloadBookingRequests={onDone} closeDialog={closeDialog} />
        break
      default:
        form = <div>unknown booking kind: {bookingInfo.kind}</div>
        break
    }
    
    content = (
      <div className="appentura-surprise-bookingrequest">
        <div className="editor">
          <Header provider={provider!} />
          <Info provider={provider!} activityId={activityInfo.activityId} regionVariantId={regionVariantId} />
          {form}
        </div>
      </div>
    )
  }

  return (
    <BookingRequestProvider>{content}</BookingRequestProvider>
  )
}

export default BookingRequest