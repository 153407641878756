import { Voucher } from "./Voucher";
import { Mapper } from "../_Common/Mapper";


export class VoucherMapper extends Mapper<Voucher> {
  public apiEndpoint = "vouchers";

  public toDb(voucher: Voucher): any {
    return {
      _id: voucher._id,
      code: voucher.code,
      countsTowardsMinValue: voucher.countsTowardsMinValue,
      kind: voucher.kind,
      minOrderValue: voucher.minOrderValue,
      notes: voucher.notes,
      numberOfUses: voucher.numberOfUses,
      relevantToSales: voucher.relevantToSales,
      relevantToSalesDate: voucher.relevantToSalesDate,
      singleUse: voucher.singleUse,
      validFrom: voucher.validFrom,
      validTo: voucher.validTo,
      value: voucher.value
    }
  }

  public fromDb(obj: any): Voucher {
    const voucher: Voucher = {
      _id: String(obj._id),
      code: obj.code,
      countsTowardsMinValue: obj.countsTowardsMinValue || false,
      kind: obj.kind,
      minOrderValue: obj.minOrderValue,
      notes: obj.notes,
      numberOfUses: obj.numberOfUses,
      relevantToSales: obj.relevantToSales,
      relevantToSalesDate: new Date(obj.relevantToSalesDate),
      singleUse: obj.singleUse || false,
      validFrom: new Date(obj.validFrom),
      validTo: new Date(obj.validTo),
      value: obj.value,
    }
    
    return voucher
  }

}