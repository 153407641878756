import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Util } from "../../../util";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";
import * as BL from "../../../types/BusinessLogic";

// components
import {Icon, Icons, Link, Loader, Tr, Td, Table, Title, Text} from "../../controls";
import DashboardPanel from "./DashboardPanel";

// images
import iconUnknownUser from './../../../assets/images/icon_unknown_user.svg'
// styling
import CSS from "./Tasks.module.scss";


type DataType = {
  users: DA.User[],
  adventureTasks: DA.AdventureTask[],
  surprises: any[],
}
export default function Tasks() {
  // state
  const [data, setData] = useState<DataType|null>(null);
  const [user, setUser] = useState<DA.User|null>(null);

  // mount
  useEffect(() => {
    load();
  }, []);

  // loads data
  const load = async() => {
    try {
      // get all users
      const users = await DA.UserRepository.findAll();
    
      // get adventure tasks that are not yet completed
      const adventureTasks = await DA.AdventureTaskRepository.findOpen();

      // get all relevant surprises
      const surprises = await TS.AdventureOld.getLiving({Status:1, Activities:1, Operator:1, UserStartTime:1, StartTime:1, HasPriority:1, RecieverName:1, IsMe:1, Order:1});
      // get current user
      const user = await BL.User.getCurrent();
      // update state
      setUser(user);
      setData({surprises, adventureTasks, users});
    }
    catch(error) {
      console.error("ERROR:", error);
      alert(error);
    } 
  }

  // pre-render
  let contentAnon = null;
  let contentUser = null;
  
  if(!data) {
    contentAnon = <Loader text="lade unzugewiesene Aufgaben ..." />
    contentUser = <Loader text="lade zugewiesene Aufgaben ..." />
  }
  else {
    contentAnon = <>
      <AdventureTasks currentUser={user!} data={data} mode="unassigned" />
      <Surprises user={null} data={data} country="DE" />
      <Surprises user={null} data={data} country="CH" />
    </>
    contentUser = <>
      <AdventureTasks currentUser={user!} data={data} mode="currentUser" />
      <Surprises user={user} data={data} country="DE" />
      <Surprises user={user} data={data} country="CH" />
    </>
  }

  // render
  return <>
    <DashboardPanel title="Meine Aufgaben" smallOnDesktop={false} smallOnMobile={false} icon={iconUnknownUser}>
      {contentUser}
    </DashboardPanel>
    <DashboardPanel title="Nicht zugewiesene Aufgaben" smallOnDesktop={false} smallOnMobile={false} icon={iconUnknownUser}>
      {contentAnon}
    </DashboardPanel>
  </>
}

type AdventureTasksProps = {
  data:DataType, 
  currentUser: DA.User, 
  mode:"currentUser"|"unassigned"
}
function AdventureTasks({data, currentUser, mode} : AdventureTasksProps) {
  // collect items
  const rows = (data.adventureTasks as DA.AdventureTask[])
    .filter(task => {
      if(mode === "currentUser" && task.assignedTo) {
        return task.assignedTo!.trim().toLowerCase() === currentUser.email.trim().toLowerCase();
      }
      else {
        // task not assigned to any user -> show
        if(task.assignedTo === null) {
          return true;
        }
        // task assigned to another user -> filter out
        if(task.assignedTo!.trim().toLowerCase() === currentUser.email.trim().toLowerCase()) {
          return false;
        }
        // task assigned to an inactive or inexistant user -> show
        const assignedUser = data.users.find(u => u.email.trim().toLowerCase() === task.assignedTo!.trim().toLowerCase());
        if(!assignedUser) { 
          return true;
        }
        if(assignedUser.isActive === false) {
          return true;
        }
      }
    })
    .sort((a, b) => {
      if(a.dateDue && b.dateDue) {
        return new Date(a.dateDue) > new Date(b.dateDue) ? 1 : -1
      }
      else {
        return a.dateDue ? -1 : 1
      }
    })
    .map(task => {
      const dateDue = task.dateDue ? Util.printDate(task.dateDue) : "-";
      const isOverdue = task.dateDue !== null && (new Date(task.dateDue) < (new Date()));
      const text = `${task.title}`;
      return (
        <Tr key={`adventureTask_${task._id}`}>
          <Td warning={isOverdue}>{dateDue}</Td>
          <Td>{TS.Adventure.getShortId(task.adventureId, true)}</Td>
          <Td>
            <Link to={`surprise/${task.adventureId}`}>
              {text}
            </Link>
          </Td>
        </Tr>
      )
    })

  // pre-render
  let content;
  if(rows.length === 0) {
    content = <Text>keine Aufgaben</Text>
  }
  else {
    content = <Table>{rows}</Table>
  }
  return (
    <div className={CSS.list}>
      <Title>Aufgaben</Title>
      {content}
    </div>
  );
}

type SurprisesProps = {
  data: any,
  user: DA.User|null,
  country:"CH"|"DE"
}
function Surprises({data, user, country} : SurprisesProps) {
  // collect items
  let lastStatus = -1
  const rows:any[] = [];
  data.surprises
    .filter((s:any) => {
      const operator = (s.Operator || "").trim().toLowerCase();
      return _.isNil(user) ? operator === "" : user.email.trim().toLowerCase() === operator;
    })
    .filter((s:any) => {
      const regionCodes = (s.Activities || []).map((a:any) => a.RegionCode);
      const countryCodes = _.uniq(regionCodes.map((regionCode:string) => regionCode.split("-")[0] || "").filter((countryCode:string) => countryCode.trim().length > 0).map((countryCode:string) => countryCode.toUpperCase()));
      // appentura.me do not have a country code yet, we pull it from the Order document TODO could we no always add that?
      if(s.IsMe && s.Order) {
        countryCodes.push(s.Order.ShopCountry)
      }
      return countryCodes.includes(country);
    })
    .map((s:any) => {
      // surprises with state 3 do not have yet a StartTime use UserStartTime instead ... enrich with __date property to make the following code easier to read
      s.__date = s.Status === TS.AdventureStatus.DateSelected ? new Date(s.UserStartTime) : new Date(s.StartTime);
      return s;
    })
    .sort((a:any, b:any) => a.Status > b.Status ? 1 : -1)
    .sort((a:any, b:any) => {
      if(a.Status === b.Status) {
        return a.__date > b.__date ? 1 : -1;
      }
      else {
        return 0;
      }
    })
    .forEach((s:any) => {
      // add header row
      if(lastStatus !== s.Status) {
        rows.push(
          <Tr key={`header_${s.Status}`}>
            <Td colSpan={5}>
              <Title level={2}>{TS.Adventure.getStatusName(s.Status)}</Title>
            </Td>
          </Tr>
        )
        lastStatus = s.Status
      }

      // add surprise row
      let activities: string;
      if(s.IsMe) {
        activities = "noch keine Aktivitäten (Appentura.me)";
      }
      else {
        activities = (s.Activities || []).map((a:any) => `${a.Title} (${a.RegionCode})`).join(', ');
      }
      
      rows.push (
        <Tr key={`surprise_${s._id}`}>
          <Td>{s.HasPriority ? <Icon icon={Icons.Crown} /> : null}</Td>
          <Td>
            {Util.printDate(s.__date)}
          </Td>
          <Td>
            {TS.Adventure.getShortId(s._id, true)}
          </Td>
          <Td>
            <Link to={`surprise/${s._id}`}>
              {s.RecieverName || 'Unbekannt'}
            </Link>
          </Td>
          <Td>{activities}</Td>
        </Tr>
      )
    })

  // pre-render
  let content;
  if(rows.length === 0) {
    content = <Text>keine Überraschungen</Text>
  }
  else {
    content = <Table>{rows}</Table>
  }

  // and return result
  return (
    <div className={CSS.list}>
      <Title>Überraschungen {country}</Title>
      {content}
    </div>
  )
}
