import _ from "lodash";
import { Filter } from ".";
import * as Api from "../../util/ApiTS";
import Util from "../../util/util";

export class Exporter {
  public static readonly PAGE_SIZE = 100;

  private static readonly ADVENTURE_PROJECTION = {
    _id: 1,
    "CreateDate":1, 
    "ReserverName":1, 
    "ReserverEmail":1, 
    "RecieverName":1, 
    "RecieverEmail":1, 
    "RecieverRealFirstName":1, 
    "StartTime":1, 
    "UserStartTime":1, 
    "Budget":1, 
    "Price":1, 
    "Status":1, 
    "Rating":1, 
    "PaymentDate":1, 
    "PaymentType":1, 
    "PaymentReminderLevel":1, 
    "Persons":1, 
    "ImportJson":1, 
    "Comments":1, 
    "LiveId":1,
    "ScheduleDate":1,
    "Order.Occasion":1
  }

  private static readonly VENDORINVOICE_PROJECTION = {
    "allocatedAmounts":1
  }

  private static readonly RECEIPT_PROJECTION = {
    "amount":1, 
    "surprise_id":1
  }

  private static async count(collection:"adventures"|"receipts"|"vendorinvoices", filter:any, projection:any, ) {
    // query api
    const result = await Api.post(collection, "count", {filter:filter});
    // return result
    if(result.success) {
      return result.data.count;
    }
    else {
      // TODO deal with error
      return 0;
    }
  }

  private static async loadData(collection:"adventures"|"receipts"|"vendorinvoices", filter:any, projection:any) {
    // data we collect
    const data: any[] = [];

    // get count
    const count = await this.count(collection, filter, projection);

    // calculated number of pages
    const pageSize = 500;
    const pages:number[] = [];
    for(let page=0; page < Math.ceil(count / pageSize); page++) {
      pages.push(page)
    };

    // gather data for each page
    for(let page of pages) {
      const payload = {
        filter: filter,
        projection: projection,
        limit: pageSize,
        skip: page * pageSize,
      }

      // query api
      const result = await Api.post(collection, "search", payload);

      // collect data
      if(result.success) {
        const items: any[] = result.data.items;
        data.push(...items);
      }
      else {
        // TODO handle error
      }
    }

    // done
    return data;
  }

  public static async createCsv(filter:Filter): Promise<string> {
    // get adventures
    const adventures = await this.loadData("adventures", filter.toMongoFilter(), this.ADVENTURE_PROJECTION);
    const adventureIds = adventures.map(a => a._id);
    // get vendor invoices
    const invoices_filter = {"allocatedAmounts.surprise_id":{$in:adventureIds}};
    const vendorInvoices = await this.loadData("vendorinvoices", invoices_filter, this.VENDORINVOICE_PROJECTION);
    // get receipts
    const receipts_filter = {"surprise_id":{$in:adventureIds}};
    const receipts = await this.loadData("receipts", receipts_filter, this.RECEIPT_PROJECTION);

    // helper method
    const getValueFromComments = (comments:any, title:any) => {
      let value = ''
      let lines = comments.split('\n')
      let lineWithTitle = lines.find((line:any) => {
        return line.trim().toLowerCase().startsWith(title.trim().toLowerCase())
      })
      if(lineWithTitle) {
        let parts = lineWithTitle.split(':')
        if(parts.length > 0) {
          value = parts[1].trim()
        }
      }
      return value
    }
    // helper method
    const getCosts_vendorInvoices = (surprise_id:any) => {
      const relatedVendorInvoices = vendorInvoices.filter(vi => vi.allocatedAmounts.some((aa:any) => aa.surprise_id === surprise_id));
      const relatedAllocatedAccounts:any[] = []
      relatedVendorInvoices.forEach(vi => {
        vi.allocatedAmounts.filter((aa:any) => aa.surprise_id === surprise_id).forEach((aa:any) => relatedAllocatedAccounts.push(aa))
      })
      let costs = relatedAllocatedAccounts.reduce((prev, curr) => {
        return prev + curr.amount;
      }, 0);
    
      return costs;
    }
    // helper method
    const getCosts_receipts = (surprise_id:any) => {
      const relatedReceipts = receipts.filter(r => r.surprise_id === surprise_id)
      let costs = relatedReceipts.reduce((prev, curr) => {
        return prev + curr.amount;
      }, 0);
      return costs;
    }
  
    // create csv lines
    let lines = adventures.map(a => {
      let rating = a.Rating || {}
      let vals = []
      vals.push(a._id)
      vals.push(a.CreateDate ? Util.printDate(a.CreateDate) : '')
      vals.push(a.ReserverName)
      vals.push(a.ReserverEmail)
      vals.push(a.RecieverName)
      vals.push(a.RecieverEmail)
      vals.push(a.RecieverRealFirstName || '')
      vals.push(a.UserStartTime ? Util.printDate(a.UserStartTime) : '')
      vals.push(a.StartTime ? Util.printDate(a.StartTime) : '')
      vals.push(a.ScheduleDate ? Util.printDate(a.ScheduleDate) : '')
      // budget and price
      vals.push((a.Budget || 0).toFixed(2))
      vals.push((a.Price || 0).toFixed(2))
      // costs
      const costs_vendorInvoices = getCosts_vendorInvoices(a._id)
      const costs_receipts = getCosts_receipts(a._id)
      const costs_total = costs_vendorInvoices + costs_receipts
      vals.push(costs_vendorInvoices.toFixed(2))
      vals.push(costs_receipts.toFixed(2))
      vals.push(costs_total.toFixed(2))
      // earnings
      const earnings:any = Number((a.Price || 0) - costs_total).toFixed(2)
      vals.push(earnings)
      const earningsPercentage = (a.Price || 0) > 0 ? Number(earnings / a.Price * 100).toFixed(2) : 0
      vals.push(earningsPercentage)
      // credit card payments (may) have no payment date, we use the CreateDate if we have no payment date
      let paymentDate = a.PaymentDate
      if(a.PaymentType === "stripe" && a.PaymentDate === null) {
        paymentDate = a.CreateDate
      }
      // payment date
      vals.push(paymentDate ? Util.printDate(paymentDate) : '')
      // ratings
      vals.push(rating.Rating)
      vals.push(rating.Rating2)
      vals.push(rating.Rating3)
      vals.push(a.PaymentReminderLevel || '')
      vals.push(a.Persons || 1)
      // items from ImportJson
      let importJson = JSON.parse(a.ImportJson || "{}")
      vals.push(importJson.ClientFirstName || '')
      vals.push(importJson.ClientName || '')
      vals.push(getValueFromComments(a.Comments || '', 'Source')) // referralSource
      vals.push(getValueFromComments(a.Comments || '', 'Medium')) // referralMedium
      vals.push(a.LiveId)
      vals.push(_.get(a, "Order.Occasion") || "")
    
      // get rid of ;
      vals.forEach(val => (val || '').toString().replace(';',':'))

      return vals.join(';')
    })
    // add headers
    lines.unshift("id;CreateDate;ReserverName;ReserverEmail;ReceiverName;ReceiverEmail;ReceiverRealFirstName;UserStartTime;StartTime;ScheduleDate;Budget;Price;Total Rechnungen;Total Belege;Total Kosten;Ertrag;Ertrag %;Zahlungsdatum;Rating Allgemein; Rating Ablauf;Rating Anbieter;Mahnstufe;Personen;ClientFirstName;ClientLastName;ReferralSource;ReferralMedium;LiveId;Anlass")

    // create and return csv data
    const csv = lines.join('\n');
    //const length = adventures.length;
    return csv;
  }
}
