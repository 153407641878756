import { Module, ModuleInfo, Theme } from ".";
import uniqid from 'uniqid';

export class IconTilesModuleItem {
  id: string;
  title: string;
  text: string;
  iconGroup: "fas"|"far"|"fab";
  iconName: string;
  imageUrl?: string;
  imageBlurhash?: string;
  imageAlt?: string;
  position: number;

  constructor() {
    this.id = uniqid(); // NOTE: we really only need this id to manage adding and removing items ... could not find a working ObjectId library for React ...
    this.title = "";
    this.text = "";
    this.iconGroup = "far";
    this.iconName = "";
    this.position = 0;
  }
}

export class IconTilesModule implements Module {
  readonly code: string;

  _id?: string;
  countries: string[];
  position: number;
  theme: Theme;
  anchor?: string;

  elements: {
    title?: string,
    subtitle?: string,
    buttonText?: string,
    buttonHref?: string,
    items: IconTilesModuleItem[],
  };

  constructor() {
    this.code = IconTilesModule.info.code;
    this.theme = "primary";
    this.countries = ["CH", "DE"];
    this.position = 0;
    this.elements = {
      title: "",
      subtitle: "",
      items:[]
    }
  }

  static get info(): ModuleInfo {
    return {
      code: "icontiles",
      title: "Icon-Kacheln",
      description: "Beliebige Anzahl von Icon-Kacheln"
    }
  }
}