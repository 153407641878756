import Archived from "./Archived.Status";
import BuddySelected from "./BuddySelected.Status";
import ConditionsAccepted from "./ConditionsAccepted.Status";
import ConditionsSent from "./ConditionsSent.Status";
import DateSelected from "./DateSelected.Status";
import Finished from "./Finished.Status";
import Locked from "./Locked.Status";
import OrderCanceled from "./OrderCanceled.Status";
import Ordered from "./Ordered.Status";
import Prepaid from "./Prepaid.Status";
import Ready from "./Ready.Status";
import Reviewed from "./Reviewed.Status";
import Unknown from "./Unknown.Status";

export default {
  Archived,
  BuddySelected,
  ConditionsAccepted, 
  ConditionsSent, 
  DateSelected, 
  Finished, 
  Locked,
  OrderCanceled, 
  Ordered, 
  Prepaid,
  Ready, 
  Reviewed,
  Unknown
}