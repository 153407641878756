import React, { useState, useEffect } from "react";
import * as DA from "../../../types/DataAccess";
// components & subviews
import { Breadcrumb, Card, Heading, List, ListColumn, Modal } from "../../controls";

import Edit from "./AdventureStepTemplate.Edit";

// list columns
const columns: ListColumn[] = [
  {
    header: "Name",
    path: "Name"
  }
];

// the component
export default function AdventureStepTemplateList() {
  // state
  const [templates, setTemplates] = useState<DA.AdventureStepTemplate[]|null>(null);
  const [templateToEdit, setTemplateToEdit] = useState<DA.AdventureStepTemplate|null>(null);

  // mount
  useEffect(() => {
    load();
  }, [])

  // loads data
  const load = async() => {
    setTemplates(null);
    const templates = await DA.AdventureStepTemplateRepository.findStandalone();
    setTemplates(templates);
  }

  const closeDialog = () => {
    setTemplateToEdit(null);
  }

  // user wants to create or update
  const onClickAddUpdate = (template?: DA.AdventureStepTemplate) => {
    if(!template) {
      template = DA.AdventureStepTemplateRepository.make();
    }
    setTemplateToEdit(template);
  }

  // user updated something (edit or create)
  const onUpdate = async() => {
    await load();
  }

  // user wants to delete
  const onDelete = async(template: DA.AdventureStepTemplate) => {
    await DA.AdventureStepTemplateRepository.remove(template._id!);
    load();
  }


  // render
  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}]} location="Schrittvorlagen" />
    <Heading>Schrittvorlagen</Heading>
    <Card>
      <List 
        columns={columns} 
        items={templates} 
        onClickCreate={() => onClickAddUpdate()} 
        onRowClick={item => onClickAddUpdate(item)} 
        getKey={item => item._id} 
        onClickDelete={onDelete} 
        onClickEdit={item => onClickAddUpdate(item)}
      />
    </Card>
    <Modal isOpen={templateToEdit !== null} title="Schrittvorlage bearbeiten" onClose={closeDialog}>
      <Edit template={templateToEdit!} onUpdate={onUpdate} closeDialog={closeDialog} />
    </Modal>
  </>
}