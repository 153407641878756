import React from "react";
import Log from "../NotesAndLog/Log.View";
import "./Log.View.scss";

export default () => {
  // render
  return (
    <div id="v-surprise-contextual-log">
      <div className="subheading">Log</div>
      <Log headless={true} expandable={true} />
    </div>
  )
}