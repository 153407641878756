import _ from "lodash";
import React, {useContext, useState, useEffect, useRef} from "react"

import { Value, Form, NumberInput, Validators, CardSection, Modal } from "../../../controls";
import { ActivityContext } from "../ActivityContext";


export default function Duration() {
  const [mode, setMode] = useState<"edit"|"view">("view");
  // context
  const AC = useContext(ActivityContext)!;

  // user wants to edit
  const startEdit = () => setMode("edit");
  // stops edit mode
  const stopEdit = () => setMode("view");

  // saves changes
  const saveEdit = async(changeset:any) => {
    await AC.updateRegionVariant(AC.regionVariant!._id!, changeset);
    stopEdit();
  }
  
  // render
  const duration = _.get(AC.regionVariant, "duration.minutes");
  const printedDuration = _.isNil(duration) ? "keine Angabe" : printDuration(duration);
  
  return <>
    <CardSection title="Dauer">
      <Value label="Dauer in Minuten" explanation="die geschätzte Dauer" onClick={startEdit}>{printedDuration}</Value>
    </CardSection>
    <Modal isOpen={mode==="edit"} title="Regionsvariante - Dauer in Minuten" onClose={stopEdit}>
      <Edit regionVariant={AC.regionVariant} saveEdit={saveEdit} onCancel={stopEdit} />
    </Modal>
  </>
}


type EditProps = {
  regionVariant: any,
  saveEdit: Function,
  onCancel: () => void,
}
function Edit({regionVariant, saveEdit, onCancel} : EditProps) {
  // state
  const [entity, setEntity] = useState<any>(null);
  const [busy, setBusy] = useState(false);

  // ref
  const refPrintedDuration = useRef<any>();

  // mount
  useEffect(() => {
    const entity = {
      duration: {
        minutes: _.get(regionVariant, "duration.minutes") || 0
      }
    }
    setEntity(entity);
  }, []);

  // save the update
  const onSave = async(formResult:any) => {
    setBusy(true);
    await saveEdit(formResult.changesetFlat); 
    setBusy(false);
  }

  // number input value changed
  const onChange = (e:any) => {
    if(refPrintedDuration.current) {
      refPrintedDuration.current.innerText = printDuration(e.value);
    }
  }

  // render
  return (
    <Form onCancel={onCancel} onSave={onSave} entity={entity} busy={busy}>
      <NumberInput path="duration.minutes" label="Dauer in Minuten" validate={Validators.isGreaterThan(0, "bitte Dauer in Minuten angeben")} onChange={onChange} />
      <div ref={refPrintedDuration}>{printDuration(_.get(entity, "duration.minutes") || 0)}</div>
    </Form>
  ); 
}


function printDuration(minutes:any) {
  const m = isNaN(minutes) ? 0 : Number(minutes);
  const h = Math.floor(m / 60);
  if(h > 0) {
    return `${h} Stunden, ${m - h * 60} Minuten`;
  }
  else {
    return `${m} Minuten`;
  }
}