import { Condition } from "./Condition";
import { Mapper } from "../_Common/Mapper";
import { TranslatableMapper } from "../_Common/TranslatableMapper";


export class ConditionMapper extends Mapper<Condition> {
  public apiEndpoint = "conditions";

  public toDb(equipment: Condition): any {
    return {
      _id: equipment._id,
      title: TranslatableMapper.toDb(equipment.title),
    }
  }

  public fromDb(obj:any): Condition {
    return {
      _id: obj._id || undefined,
      title: TranslatableMapper.fromDb(obj.title),
    }
  }

}