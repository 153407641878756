import React, {useContext, useState} from "react";
import * as TS from "../../../../../types";
import * as BL from "../../../../../types/BusinessLogic";
import { Alert, CardSection, ConfirmButton } from "../../../../controls";
import { SurpriseContext } from "../../Surprise.Context";

export default function MoveToConditionsSent() {
  // context
  const SC = useContext(SurpriseContext);
  // state
  const [busy, setBusy] = useState(false);

  // event handler
  const updateStatus = async() => {
    setBusy(true);
    const currentUser = await BL.User.getCurrent();
    const logEntry = { Type:"status", Text:`in den Status 'Termin gewählt' gesetzt durch ${currentUser.email}`, Operator:"robot"};
    const nextStatus = TS.AdventureStatus.ConditionsSent;
    await SC.updateStatus(nextStatus, true, logEntry);
    setBusy(false);
  }
  
  // not in the right status? render nothing.
  if(SC.surprise.Status !== TS.AdventureStatus.DateSelected) {
    return null
  }

  // any errors? 
  const errors = [];
  const start = new Date(SC.surprise.StartTime);
  const end = new Date(SC.surprise.EndTime);
  if(!((start !== undefined) && (end !== undefined) && (start < end))) {
    errors.push('Bitte Start- und Enddatum korrigieren (Enddatum darf nicht vor dem Startdatum liegen).')
  }

  // create info to display
  let info = null;
  if(errors.length > 0) {
    const lis = errors.map((error, index) => <li key={index}>{error}</li>)
    //heading = "Der Status kann nicht abgeschlossen werden";
    info = (
      <Alert intent="error" title="Der Status kann nicht abgeschlossen werden" size="small">
        <ul>{lis}</ul>
      </Alert>
    );
  }
  else {
    info = (
      <Alert intent="info" size="small">
        <ul>
          <li>Der Status wird auf 'Organisiert' gewechselt</li>
          <li>{`Der Beschenkte (${SC.surprise.RecieverName}) wird über Zeit und Datum der Überraschung per E-Mail (an ${SC.surprise.RecieverEmail}) informiert`}</li>
        </ul>
      </Alert>
    );
  }

  // render
  return (
    <CardSection title="Status abschliessen">
      <ConfirmButton onConfirm={updateStatus} busy={busy} disabled={errors.length > 0}>Status abschliessen</ConfirmButton>
      {info}
    </CardSection>
  )
}
