import React from "react";
import CSS from "./Circle.module.scss";

type CircleProps = {
  children: React.ReactNode|React.ReactNode[],
  size?: "small"|"medium"|"large",
  onClick?: (data?:any) => void,
  data?: any,
  intent?: "confirm"|"delete"
}
export function Circle({size, intent, children, onClick, data} : CircleProps) {
  // defaults
  size = size || "medium";
  intent = intent || "confirm";

  // user clicks
  const _onClick = () => {
    if(onClick) {
      onClick(data);
    }
  }
  // render
  return <div className={`${CSS.container} ${CSS[size]} ${CSS[intent]}`} onClick={_onClick}>
    <div className={CSS.inner}>
      {children}
    </div>
  </div>
}