import React from "react";
import { Card, CardSection, Icons } from '../../../controls';
// subviews
import Archive from "./Actions/Archive.View";
import MoveToConditionsSent from "./Actions/MoveToConditionsSent.View";
import MoveToReady from "./Actions/MoveToReady.View";
import ChangeStatus from "./Actions/ChangeStatus.View";
import Cancel from "./Actions/Cancel.View";
import SendSaleMail from "./Actions/SendSaleMail.View";
import SendAcceptReminderMail from "./Actions/SendAcceptReminderMail.View";
import SendScheduledWithoutPayment from "./Actions/SendScheduledWithoutPayment.View";
import SendPostponedMail from "./Actions/SendPostponedMail.View";
import SendReviewTest from "./Actions/SendReviewTest.View";
import PriorityFlag from "./Actions/PriorityFlag.View";
import LiveId from "./Actions/LiveId.View";
import ResetMeteochecks from "./Actions/ResetMeteochecks";
import ResetSteps from "./Actions/ResetSteps.View";
// styling
import "./Actions.View.scss";


export default function Actions({expanded}) {
  return (
    <Card 
      id="v-surprise-actions" 
      collapsible={false} 
      expanded={expanded} 
      title="Aktionen"
      icon={Icons.Bolt} 
    >
      <MoveToConditionsSent />
      <MoveToReady />
      <Cancel />
      <ResetMeteochecks />
      <Archive /> 
      <ChangeStatus />    
      <CardSection title="E-Mails">
        <SendSaleMail />
        <SendAcceptReminderMail />
        <SendScheduledWithoutPayment />
        <SendPostponedMail />
        <SendReviewTest />
      </CardSection>
      <CardSection title="Anderes">
        <PriorityFlag />
        <LiveId />
        <ResetSteps />
      </CardSection>
    </Card>
  )
}

