import { Activity, ActivityRegionVariant, ActivityRegionVariantCondition, ActivityRegionVariantEquipment, ActivityRegionVariantOption, ActivityRegionVariantPriceConfiguration, ActivityRegionVariantProvider, ActivityRegionVariantWeatherCondition, ActivityStory } from "./Activity";
import { Mapper } from "../_Common/Mapper";
import { TranslatableMapper } from "../_Common/TranslatableMapper";


export class ActivityMapper extends Mapper<Activity> {
  public apiEndpoint = "activities";

  public toDb(activity: Activity):any {
    return { 
      _id: activity._id,
      _sql_id: activity._sql_id,

      // flags
      isFeatured: activity.isFeatured,
      isFresh: activity.isFresh,
      isBestseller: activity.isBestseller,
      isHomeDelivery: activity.isHomeDelivery,
      isOnlineEvent: activity.isOnlineEvent,
      isSubactivity: activity.isSubactivity,
      isOptionalSubactivity: activity.isOptionalSubactivity,
      upsellAsFood: activity.upsellAsFood,
      requiresMeteocheck: activity.requiresMeteocheck,

      // texts, tags, and categories
      title: TranslatableMapper.toDb(activity.title),
      slug: TranslatableMapper.toDb(activity.slug),
      shortDescription: TranslatableMapper.toDb(activity.shortDescription),
      longDescription: TranslatableMapper.toDb(activity.longDescription),
      tags: activity.tags.map(t => t),
      referencePriority: {
        man: activity.referencePriority.man,
        woman: activity.referencePriority.woman,
        couple: activity.referencePriority.couple
      },

      // availability
      availableTimes: activity.availableTimes,
      availableHours: activity.availableHours,
      availableWeekdays: activity.availableWeekdays,
      
      // images and videos
      images: activity.images.map(img => {
        return {
          _id: img._id,
          url: img.url,
          blurhash: img.blurhash,
          default: img.default,
          copyright: img.copyright,
          source: img.source,
          seo: TranslatableMapper.toDb(img.seo)
        }
      }), 
      videos: activity.videos.map(v => {
        return {
          _id: v._id,
          url: v.url
        }
      }),

      // internal comments
      comments: activity.comments,
      
      // rank and rating
      salesRank: activity.salesRank,
      salesRankUpdatedOn: activity.salesRankUpdatedOn,
      rating: activity.rating,
      ratingUpdatedOn: activity.ratingUpdatedOn,
      ratingAverage: activity.ratingAverage,
      ratingCount: activity.ratingCount,
      
      // collections
      stories: activity.stories.map(story => {
        return {
          _id: story._id,
          url: story.url,
          title: story.title,
          summary: story.summary,
          youtubeVideoId: story.youtubeVideoId,
          vimeoVideoId: story.vimeoVideoId,
          imageUrl: story.imageUrl,
          position: story.position,
          countries: story.countries
        }
      }),
      subactivities: activity.subactivities.map(sa => sa),
      regionVariants: activity.regionVariants.map(regionVariant => {
        return {
          _id: regionVariant._id,
          regionCode: regionVariant.regionCode,
          isActive: regionVariant.isActive || false,
          isSoldOut: regionVariant.isSoldOut || false,
          price: regionVariant.price || 0,
          benefits: regionVariant.benefits.map(b => TranslatableMapper.toDb(b)),
          participants: {
            min: regionVariant.participants.min,
            max: regionVariant.participants.max
          },
          equipment: regionVariant.equipment.map(e => {
            return {
              equipment_id: e.equipment_id,
              title: TranslatableMapper.toDb(e.title)
            }
          }),
          conditions: regionVariant.conditions.map(c => {
            return {
              condition_id: c.condition_id,
              title: TranslatableMapper.toDb(c.title)
            }
          }),
          weatherConditions: regionVariant.weatherConditions.map(w => {
            return {
              weatherCondition_id: w.weatherCondition_id,
              title: TranslatableMapper.toDb(w.title)
            }
          }),  
          
          options: regionVariant.options.map(o => {
            return {
              name: TranslatableMapper.toDb(o.name),
              description: TranslatableMapper.toDb(o.description),
              minUnits: o.minUnits,
              maxUnits: o.maxUnits,
              price: o.price
            }
          }),
          priceConfigurations: regionVariant.priceConfigurations.map(p => {
            return {
              participantCount: p.participantCount,
              price: p.price
            }
          }),
          duration: {
            minutes: regionVariant.duration
          },
          comments: regionVariant.comments,
          availableMonths: regionVariant.availableMonths,
          availableWeekdays: regionVariant.availableWeekdays,
          availableAreas: regionVariant.availableAreas,
          providers: regionVariant.providers.map(p => {
            return {
              id: p.id,
              isActive: p.isActive,
              notes: p.notes
            }
          })
        }
      })
    }
  }


  public fromDb(obj:any): Activity {
    const referencePriority = obj.referencePriority || {};

    return {
      _id: obj._id,
      _sql_id: obj._sql_id,

      // flags
      isFeatured: obj.isFeature || false,
      isFresh: obj.isFresh || false,
      isBestseller: obj.isBestseller || false,
      isHomeDelivery: obj.isHomeDelivery || false,
      isOnlineEvent: obj.isOnlineEvent || false,
      isSubactivity: obj.isSubactivity || false,
      isOptionalSubactivity: obj.isOptionalSubactivity || false,
      upsellAsFood: obj.upsellAsFood || false,
      requiresMeteocheck: obj.requiresMeteocheck || false,

      // texts, tags, and categories
      title: TranslatableMapper.fromDb(obj.title),
      slug: TranslatableMapper.fromDb(obj.slug),
      shortDescription: TranslatableMapper.fromDb(obj.shortDescription),
      longDescription: TranslatableMapper.fromDb(obj.longDescription),
      tags: (obj.tags || []).map((t:any) => t).filter((t:any) => t && t.trim().length > 0),
      referencePriority: {
        man: referencePriority.man || 0,
        woman: referencePriority.woman || 0,
        couple: referencePriority.coupl || 0
      },

      // availability
      availableTimes: obj.availableTimes || "",
      availableHours: (obj.availableHours || []).map((n:any) => n),
      availableWeekdays: (obj.availableWeekdays || []).map((n:any) => n),
      
      // images and videos
      images: (obj.images || []).map((image:any) => {
        return {
          _id: image._id,
          url: image.url || "",
          blurhash: image.blurhash || null,
          default: image.default || false,
          copyright: image.copyright || "",
          source: image.source || "",
          seo: TranslatableMapper.fromDb(image.seo)
        }
      }),
      videos: (obj.videos || []).map((v:any) => {
        return {
          _id: obj._id,
          url: obj.url || ""
        }
      }),

      // internal comments
      comments: obj.comments || "",
      
      // rank and rating
      salesRank: obj.salesRank || 999999,
      salesRankUpdatedOn: new Date(obj.salesRankUpdatedOn) || null,
      rating: obj.rating || null,
      ratingUpdatedOn: new Date(obj.ratingUpdatedOn) || null,
      ratingAverage: obj.ratingAverage || null,
      ratingCount: obj.ratingCount || null,
      
      // collections
      stories: (obj.stories || []).map((s:any) => {
        const activityStory: ActivityStory = {
          _id: s._id,
          url: s.url || null,
          title: s.title || null,
          summary: s.summary || null,
          youtubeVideoId: s.youtubeVideoId || null,
          vimeoVideoId: s.vimeoVideoId || null,
          imageUrl: s.imageUrl || null,
          position: s.position || 999,
          countries: (s.countries || []).map((c:any) => String(c).toUpperCase()),
        };
        return activityStory;
      }),
      subactivities: (obj.subactivities || [])
        .filter((sa:any) => {
          return sa.activity_id !== null && sa.activity_id !== undefined
        })
        .map((sa:any) => { 
          return {activity_id:sa.activity_id}
        }),
        
      regionVariants: obj.regionVariants.map((rv:any) => {
        const regionVariant: ActivityRegionVariant = {
          _id: rv._id,
          regionCode: rv.regionCode,
          isActive: rv.isActive || true,
          isSoldOut: rv.isSoldOut || false,
          price: rv.price || 0,
          benefits: (rv.benefits || []).map((b:any) => TranslatableMapper.fromDb(b)),
          participants: {
            min: rv.participant ? rv.participant.min || 1 : 1,
            max: rv.participant ? rv.participant.max || 1 : 1,
          },
          equipment: (rv.equipment || []).map((e:any) => this.fromDb_equipment(e)),
          conditions: (rv.conditions || []).map((c:any) => this.fromDb_condition(c)),
          weatherConditions: (rv.weatherConditions || []).map((w:any) => this.fromDb_weatherCondition(w)),
          options: (rv.options || []).map((o:any) => this.fromDb_option(0)),
          priceConfigurations: (rv.priceConfigurations || []).map((pc:any) => this.fromDb_priceConfiguration(pc)),  
          duration: {
            minutes: rv.duration ? rv.duration.minutes || 0 : 0,
          },
          comments: rv.comments || "",
          availableMonths: (rv.availableMonths || []).map((av:any) => av),
          availableWeekdays: (rv.availableWeekdays || []).map((av:any) => av),
          availableAreas: rv.availableAreas || "",
          providers: (rv.providers || []).map((p:any) => this.fromDb_provider(p))
        }
        return regionVariant;
      })
    }
  }

  private fromDb_equipment(obj:any): ActivityRegionVariantEquipment {
    return {
      _id: obj._id,
      equipment_id: obj.equipment_id || "",
      title: TranslatableMapper.fromDb(obj.title)
    }
  }

  private fromDb_condition(obj:any): ActivityRegionVariantCondition {
    return {
      _id: obj._id,
      condition_id: obj.condition_id || "",
      title: TranslatableMapper.fromDb(obj.title)
    }
  }

  private fromDb_weatherCondition(obj:any): ActivityRegionVariantWeatherCondition {
    return {
      _id: obj._id,
      weatherCondition_id: obj.weatherCondition_id || "",
      title: TranslatableMapper.fromDb(obj.title)
    }
  }

  private fromDb_option(obj:any): ActivityRegionVariantOption {
    return {
      _id: obj._id,
      name: TranslatableMapper.fromDb(obj.name),
      description: TranslatableMapper.fromDb(obj.description),
      minUnits: obj.minUnits || 1,
      maxUnits: obj.maxUnits || 1,
      price: obj.price || 1
    }
  }

  private fromDb_priceConfiguration(obj:any): ActivityRegionVariantPriceConfiguration {
    return {
      _id: obj._id,
      participantCount: obj.participantCount || 1,
      price: obj.price || 1
    }
  }

  private fromDb_provider(obj:any): ActivityRegionVariantProvider {
    return {
      _id: obj._id,
      id: obj.id || "",
      isActive: obj.isActive || false,
      notes: obj.notes || "",
    }
  }
}