import { useEffect, useState } from "react";
import { SearchProvider } from "./Search.Context";
import Filter from "./Search.Filter";
import Actions from "./Search.Actions";
import Result from "./Search.Result";
import { Util } from "../../../../util";
import { Breadcrumb } from "../../../controls";

export default function Search(props:any) {
  // get prefab from route
  const [prefab, setPrefab] = useState<string>(Util.getRouteParam(props, "prefab"));
  
  // §
  useEffect(() => {
    const prefab = Util.getRouteParam(props, "prefab");
    setPrefab(prefab);
  }, [props]);

  // render
  return (
    <SearchProvider prefabName={prefab}>
      <Breadcrumb links={[ {title:'Home', to:'/'}]} location="Überraschungen suchen" browserTitle="Überraschungen suchen" />
      <Filter />
      <Actions />
      <Result />
    </SearchProvider>
  )
}