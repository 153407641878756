import uniqid from "uniqid";
import { Module, ModuleInfo, Theme } from ".";

export class NumberedTextListModuleItem {
  id: string;
  text: string;
  position: number;

  constructor() {
    this.id = uniqid(); // NOTE: we really only need this id to manage adding and removing items ... could not find a working ObjectId library for React ...
    this.text = "";
    this.position = 0;
  }
}

export class NumberedTextListModule implements Module {
  readonly code: string;

  _id?: string;
  countries: string[];
  position: number;
  theme: Theme;
  anchor?: string;

  elements: {
    title?: string,
    text?: string,
    imageUrl?: string,
    imageBlurhash?: string,
    imageAlt?: string,
    items: NumberedTextListModuleItem[],
  }

  constructor() {
    this.code = NumberedTextListModule.info.code;
    this.countries = ["CH", "DE"];
    this.position = 0;
    this.theme = "primary";
    this.elements = {
      imageUrl: "",
      imageBlurhash: undefined,
      imageAlt: "",
      title: "",
      items:[]
    }
  }

  static get info(): ModuleInfo {
    return {
      code: "numbered_textlist",
      title: "Nummerierte Liste",
      description: "Nummerierte Liste mit Bild"
    }
  }
}