import React, {useContext} from "react";
import Util from "../../../../../util/util";
import {SurpriseContext} from "../../Surprise.Context";
import {Activities, ActivityPackages, Donee, Row, StartLocation, CurrentStep, NextAlert, SMS, Text, TransportRow} from "../Items";
import { Table } from "../../../../controls";

export default function Ready() {
  // context 
  const SC = useContext(SurpriseContext);
  
  // render
  const s = SC.surprise;
  return (
    <Table fontSize="small">
      <Donee />
      <Row label="Start">{Util.printDateAndTime(s.StartTime || s.UserStartTime)}</Row>
      <StartLocation />
      <TransportRow />
      <Activities />
      <ActivityPackages />
      <SMS />
      <CurrentStep />
      <NextAlert />
      <Text label="Notizen" path="surprise.Notes" placeholder="-" />
    </Table>
  );
}


