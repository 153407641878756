import _ from "lodash";
import React, { useEffect, useState } from "react";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";
import { Button, Dropdown, DropdownOptions, Row, Table, Tr, Td } from '../../controls'

/*
Notes
------------------------------------------------------------------------------------------
The 'deliveryInfo' property of a shipping type is an array of objects formed like so:
{
  orderDay:<n0...6>,  // weekday order occurs
  orderTime:<n0...23>,   // the time of the day where delivery day changes for orders occur
  deliveryDayA:<n0...6>,  // the day delivery occurs when ordered before the given time
  deliveryDayB:<n0...6>   // the day delivery occurs when ordered after the given time
}
This array has seven entries, one for each weekday (thanks Cpt. Obvious!).
Sunday has the index 0, Monday has index 1, etc.
 */


type SelectedDays = { A: number[], B: number[] };

type DeliveryInfoProps = {
  shippingType: DA.PackagingType, 
  defaults: any,
  onChange: (info:any) => void 
}
export default function DeliveryInfo({shippingType, defaults, onChange} : DeliveryInfoProps) {
  // state
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [data, setData] = useState<{selectedTimes:number[], selectedDays:SelectedDays}>({selectedTimes:[], selectedDays:{A:[], B:[]}});
  
  // mount
  useEffect(() => {
    const di = shippingType.deliveryInfo;
    const selectedDays: SelectedDays = {A:Array(7).fill(1), B:Array(7).fill(1)};
    let selectedTimes = Array(7).fill(10);

    if(di && di.length === 7) {
      // order the deliveryInfo by orderDay, so we can map more easily
      di.sort((a, b) => {
        if(a.orderDay < b.orderDay)
          return -1
        if(a.orderDay > b.orderDay)
          return 1
        return 0
      })
      // map delivery info so we can more easily use it
      selectedTimes = di.map((info) => { return info.orderTime})
      selectedDays['A'] = di.map((info) => { return info.deliveryDayA})
      selectedDays['B'] = di.map((info) => { return info.deliveryDayB})
    }
    else {
      // create default values if there is no delivery info available
      let defaults = getDefaults("letter");
      for(let i=0; i < selectedTimes.length; i+=1) {
        selectedTimes[i] = defaults.selectedTimes[i]
        selectedDays['A'][i] = defaults.selectedDays['A'][i]
        selectedDays['B'][i] = defaults.selectedDays['B'][i]
      }
    }

    // set state
    setData({selectedTimes, selectedDays});
    setIsMounted(true);
  }, []);

  // propagate changes
  useEffect(() => {
    const updatedDeliveryInfo = data.selectedTimes.map((time:any, index:number) => {
      return {
        orderDay: index,
        orderTime: time,
        deliveryDayA: data.selectedDays['A'][index],
        deliveryDayB: data.selectedDays['B'][index]
      }
    })
    onChange(updatedDeliveryInfo);
  }, [isMounted, data])

  const applyTime = (weekday:number, selectedTime:number) => {
    const updatedSelectedTimes = data.selectedTimes.map(v => v);
    updatedSelectedTimes[weekday] = selectedTime;
    setData({...data, selectedTimes:updatedSelectedTimes});
  }

  const applyDay = (orderDay:number, deliveryDay:number, partOfDay:"A"|"B") => {
    const updatedSelectedDays = {
      A: data.selectedDays.A.map(v => v),
      B: data.selectedDays.B.map(v => v)
    };
    updatedSelectedDays[partOfDay][orderDay] = deliveryDay;
    setData({...data, selectedDays:updatedSelectedDays});
  }

  const getDefaults = (kind: "letter"|"parcel") => {
    const d = defaults[kind];
    return {
      selectedTimes: d.orderTimes,
      selectedDays: d.deliveryDays
    }
  }

  const onApplyDefaults = (kind: "letter"|"parcel") => {
    const selectedTimes: number[] = [1,2,3,4,5,6,7];
    const selectedDays: SelectedDays = {A:[], B:[]};
    const defaults = getDefaults(kind);
    for(let i=0; i < selectedTimes.length; i+=1) {
      selectedTimes[i] = defaults.selectedTimes[i]
      selectedDays['A'][i] = defaults.selectedDays['A'][i]
      selectedDays['B'][i] = defaults.selectedDays['B'][i]
    }
    setData({selectedTimes, selectedDays});
  }

  const onTimeChange = (selectedTime: number, weekday: number) => {
    applyTime(weekday, selectedTime)
  }

  const onDayChange = (orderDay: number, deliveryDay: number, partOfDay:"A"|"B") => {
    applyDay(orderDay, deliveryDay, partOfDay)
  }

  // render when not yet mounted
  if(!isMounted) {
    return null;
  }

  // render
  return <>
    <Table>
      <Tr>
        <Td label colSpan={2}>Bestelleingang</Td>
        <Td label colSpan={2}>Zustellung wenn</Td>
      </Tr>
      <Tr>
        <Td label>Tag</Td>
        <Td label>bis</Td>
        <Td label>vorher bestellt</Td>
        <Td label>nachher bestellt</Td>
      </Tr>
        {
          _.range(7).map(weekday => {
            const time = data.selectedTimes[weekday];
            const dayA = data.selectedDays.A[weekday];
            const dayB = data.selectedDays.B[weekday];
            return <Day key={weekday} weekday={weekday} time={time} dayA={dayA} dayB={dayB} onTimeChange={onTimeChange} onDayChange={onDayChange} />
          })
        }
    </Table>
    <Row>
      <Button size="small" onClick={() => onApplyDefaults("letter")}>Standardwerte setzen (Brief)</Button>
      <Button size="small" onClick={() => onApplyDefaults("parcel")}>Standardwerte setzen (Paket)</Button>
    </Row>
  </>
}

type DayProps = {
  weekday: number,
  time: number,
  dayA: number, 
  dayB: number,
  onTimeChange: (selectedTime: number, weekday: number) => void,
  onDayChange: (orderDay: number, deliveryDay: number, partOfDay:"A"|"B") => void
}
function Day({weekday, time, dayA, dayB, onDayChange, onTimeChange}: DayProps) {
  // TODO use a library (moment with localisation for instance) instead
  const weekdays = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
  //const [time2, setTime] = useState<number>(time);
  return (
    <Tr>
      <Td>{weekdays[weekday]}</Td>
      
      <Td>
        <Dropdown options={DropdownOptions.HoursOfTheDay} onChange={v => onTimeChange(Number(v), weekday)} value={time} />
      </Td>
      <Td>
        <Dropdown options={DropdownOptions.Weekdays} onChange={v => onDayChange(weekday, Number(v), "A")} value={dayA} />
      </Td>
      <Td>
        <Dropdown options={DropdownOptions.Weekdays} onChange={v => onDayChange(weekday, Number(v), "B")} value={dayB} />
      </Td>
    </Tr>
  )
}
