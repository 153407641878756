import React, {useState, useEffect, useContext} from "react"
import _ from "lodash"
import * as DA from "../../../types/DataAccess";
import * as BL from "../../../types/BusinessLogic";

import {Button, Loader, CardSection, Modal, Table, Tr, Td, Link} from "../../controls";
import DefaultLocationForm from "./DefaultLocationForm";

type DefaultLocationProps = {
  provider: DA.Provider
}
export default function DefaultLocation({provider} : DefaultLocationProps) {
  // state
  const [entity, setEntity] = useState<any>(null);
  const [mode, setMode] = useState<"edit"|"view">("view");
  
  useEffect(() => {
    load();
  }, [provider])

  const load = async() => {
    const location = await BL.Provider.getDefaultLocation(provider);
    const start = location ? location.start || null : null;
    setEntity(start || {title:"", instructions:"", coordinates:null});
  } 

  const startEdit = () => setMode("edit");
  const stopEdit = () => setMode("view");

  const closeForm = (reload:boolean) => {
    stopEdit();
    if(reload) {
      load();
    }
  }

  // create content to render
  let content
  if(_.isNil(entity)) {
    content = <Loader />
  }
  else {
    const printText = (s:string) => s.trim().length === 0 ? '—' : s.trim()
    const printCoordinates = (c:any) => {
      if(c){
        const coordinatesString = `${_.get(c, "lat", 0)}, ${_.get(c, "lng", 0)}`
        return <Link to={`https://maps.google.com/?q=${coordinatesString}&z=8`} target="_blank">{coordinatesString}</Link>
      } 
      else {
        return "—"
      }
    }
    content = (
      <Table>
        <Tr>
          <Td label>Adresse / Bezeichnung</Td>
          <Td>{printText(_.get(entity, "title", "").trim())}</Td>
        </Tr>
        <Tr>
          <Td label>Anweisungen</Td>
          <Td>{printText(_.get(entity, "instructions", "").trim())}</Td>
        </Tr>
        <Tr>
          <Td label>Koordinaten</Td>
          <Td>{printCoordinates(_.get(entity, "coordinates"))}</Td>
        </Tr>
        <Tr>
          <Td></Td>
          <Td>
            <Button onClick={startEdit} size="small">bearbeiten</Button>
          </Td>
        </Tr>
      </Table>
      
    )
  }

  // render
  return <>
    <CardSection title="Treffpunkt (hier führen wir die beschenkte Person hin)" key="location">
      <div id="v-provider-location">
        {content}
      </div>
    </CardSection>
    <Modal isOpen={mode==="edit"} title="Treffpunkt erfassen" onClose={stopEdit}>
      <DefaultLocationForm provider={provider} onClose={closeForm} />
    </Modal>
  </>
}