import { PriceList, PriceListPrice } from "./PriceList";
import { Mapper } from "../_Common/Mapper";
import { TranslatableMapper } from "../_Common/TranslatableMapper";



export class PriceListMapper extends Mapper<PriceList> {
  public apiEndpoint = "priceLists";

  public toDb(priceList: PriceList): any {
    return {
      _id: priceList._id,
      provider_id: priceList.provider_id,
      activity_id: priceList.activity_id,
      regionCode: priceList.regionCode,
      currencyCode: priceList.currencyCode,
      prices: priceList.prices.map(price => this.toDb_price(price))
    }
  }

  private toDb_price(price: PriceListPrice): any {
    return {
      _id: price._id,
      price: price.price,
      participants: price.participants
    }
  }


  public fromDb(obj: any): PriceList {
    return {
      _id: obj._id,
      provider_id: obj.provider_id,
      activity_id: obj.activity_id,
      regionCode: obj.regionCode || null,
      currencyCode: obj.currencyCode || "CHF",
      prices: (obj.prices || []).map((price:any) => this.fromDb_price(price))
    }
  }

  private fromDb_price(obj: any): PriceListPrice {
    return {
      _id: obj._id,
      price: obj.price,
      participants: obj.participants
    }
  }

}