import { MarketingChannelMapper } from "./MarketingChannel.Mapper";
import { MarketingChannel } from "./MarketingChannel";
import { RepositoryFactory } from "../_Common/RepositoryFactory";


export class MarketingChannelRepository {
  // mapper
  private static mapper = new MarketingChannelMapper();
  
  // factory methods
  static make = RepositoryFactory.make<MarketingChannel, MarketingChannelMapper>(this.mapper);
  static search = RepositoryFactory.search<MarketingChannel, MarketingChannelMapper>(this.mapper);
  static create = RepositoryFactory.create<MarketingChannel, MarketingChannelMapper>(this.mapper);
  static update = RepositoryFactory.update<MarketingChannel, MarketingChannelMapper>(this.mapper);
  static findById = RepositoryFactory.findById<MarketingChannel, MarketingChannelMapper>(this.mapper);
  
  /**
   * Returns all marketing channels
   * @returns 
   */
  static async findAll(): Promise<MarketingChannel[]> {
    const all = await MarketingChannelRepository.search({});
    return all.sort((a,b) => a.name.localeCompare(b.name));
  }
}

