import { TestimonialMapper } from "./Testimonial.Mapper";
import { Testimonial } from "./Testimonial";
import { RepositoryFactory } from "../_Common/RepositoryFactory";


export class TestimonialRepository {
  // mapper
  private static mapper = new TestimonialMapper();
  
  // factory methods
  static make = RepositoryFactory.make<Testimonial, TestimonialMapper>(this.mapper);
  static search = RepositoryFactory.search<Testimonial, TestimonialMapper>(this.mapper);
  static create = RepositoryFactory.create<Testimonial, TestimonialMapper>(this.mapper);
  static update = RepositoryFactory.update<Testimonial, TestimonialMapper>(this.mapper);
  static findById = RepositoryFactory.findById<Testimonial, TestimonialMapper>(this.mapper);
  
  // additional
  static async findAll(): Promise<Testimonial[]> {
    return TestimonialRepository.search({});
  }
}

