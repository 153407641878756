import React, { useContext } from "react";
import { Filter } from "../../../../../types/AdventureSearch";
import { Checkbox, DateInput, Row } from "../../../../controls";
import { SearchContext } from "../Search.Context";
import FilterGroup from "../Search.FilterGroup";

type FilterProperties = "unpaid"|"prepaid";

export default function PaymentStatus() {
  // context
  const Search = useContext(SearchContext);

  // onChange
  const onChangeActive = (property:FilterProperties, checked: boolean) => {
    const updatedFilter:Filter = Search.filter.clone();
    switch(property) {
      case "unpaid":
        updatedFilter.paymentStatusUnpaid.active = checked;
        break;
      case "prepaid":
        updatedFilter.paymentStatusPrepaid.active = checked;
        break;
    }
    Search.reset(updatedFilter);
  }
  const onChangeDate = (property:FilterProperties, date:Date|null) => {
    date = date || new Date();
    const updatedFilter:Filter = Search.filter.clone();
    switch(property) {
      case "unpaid":
        updatedFilter.paymentStatusUnpaid.date = date;
        break;
      case "prepaid":
        updatedFilter.paymentStatusPrepaid.date = date;
        break;
    }
    Search.reset(updatedFilter);
  }

  // render
  const filter = Search.filter;
  return (
    <FilterGroup title="Zahlungsstatus">
      <Checkbox 
        secondaryLabel="Unbezahlte" 
        explanation="unbezahlt oder Zahlungsdatum nach dem Stichdatum, Bestelldatum vor Stichdatum, keine vorgefertigten" 
        value={filter.paymentStatusUnpaid.active} 
        onChange={checked => onChangeActive("unpaid", checked)} 
      />
      <StatusDate 
        property="unpaid" 
        show={filter.paymentStatusUnpaid.active} 
        value={filter.paymentStatusUnpaid.date} 
        onChange={onChangeDate} 
      />
      <Checkbox 
        secondaryLabel="Vorausbezahlt" 
        explanation="nicht durchgeführt vor dem Stichdatum, bezahlt vor Stichdatum, keine vorgefertigten" 
        value={filter.paymentStatusPrepaid.active} 
        onChange={checked => onChangeActive("prepaid", checked)} 
      />
      <StatusDate 
        property="prepaid" 
        show={filter.paymentStatusPrepaid.active} 
        value={filter.paymentStatusPrepaid.date} 
        onChange={onChangeDate} 
      />
    </FilterGroup>
  )
}

type StatusDateProps = {
  show:boolean, 
  property: FilterProperties,
  value: Date, 
  onChange:(property:FilterProperties, date:Date|null) => void
}
function StatusDate(props:StatusDateProps) {
  // render
  if(props.show) {
    return <DateInput value={props.value} onChange={date => props.onChange(props.property, date)} />;
  }
  return null;
}