import React, { useState, useEffect } from "react";
import Api from "../../../util/api";
import Util from "./../../../util/util";

import { Breadcrumb, Button, CsvDownload, Card, Heading, List, ListFilter, ListColumn } from "../../controls";

// the component
export default function Promotions() {
  // memoized items
  const [columns] = useState<ListColumn[]>([
    {
      header:'Belegdatum', 
      path:'receiptDate', 
      print: (v:any) => datePrint(v.receiptDate), 
      printCsv: (v:any) => datePrint(v.receiptDate), 
      sort:(a:any, b:any) => dateSort(a.receiptDate, b.receiptDate)
    },
    {header:'Anbieter', path:'providerName'},
    {header:'Buchungstext', path:'bookingText'},
    {header:'Überraschungs-Id', path:'surprise_id', hidden:true},
    {header:'Betrag', path:'amount', print:(v:any) => Number(v.amount).toFixed(2)},
    {
      header:'Fälligkeitsdatum', 
      path:'dueDate', 
      print: (v:any) => datePrint(v.dueDate), 
      printCsv: (v:any) => datePrint(v.dueDate), 
      sort:(a:any, b:any) => dateSort(a.dueDate, b.dueDate)
    },
    {
      header:'Zahldatum', 
      path:'paymentDate', 
      print: (v:any) => datePrint(v.paymentDate), 
      printCsv: (v:any) => datePrint(v.paymentDate), 
      sort:(a:any, b:any) => dateSort(a.paymentDate, b.paymentDate)
    },
  ]);
  const [filters] = useState<ListFilter[]>([
    {
      label:"bezahlte anzeigen", 
      kind:"checkbox", 
      initialValue: false,
      onChange: (checked:any) => { load(checked as boolean)}
    }
  ]);

  // state
  const [receipts, setReceipts] = useState(null);

  // mount 
  useEffect(() => {
    load(false)
  }, [])

  // loads data
  const load = async(includePayedItems:boolean) => {
    setReceipts(null);

    let projection = {}
    let filter = includePayedItems ? {} : {"paymentDate":{"$eq":null}}

    try {
      let result = await Api.post('receipts/search', {projection, filter})
      let receipts = result.data.map((item:any) => {
        item.dueDate = item.dueDate ? new Date(item.dueDate) : undefined,
        item.paymentDate = item.paymentDate ? new Date(item.paymentDate) : undefined,
        item.receiptDate = item.receiptDate ? new Date(item.receiptDate) : undefined
        item._linkParams = `?action=open_receipt&receipt_id=${item._id}`
        return item
      })

      setReceipts(receipts);
    }
    catch(err) {
      // TODO display error to user
      console.error('error:', err)
    }
    
  }

  // render
  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}]} location="Kreditoren-Rechnungen" />
    <Heading>Kreditoren-Rechnungen</Heading>
    <Card>
      <List 
        columns={columns} 
        filters={filters}
        items={receipts} 
        rowTo={item => `/surprises/${item.surprise_id}`} 
        showCsvExport={true}
        csvExportFilename={`kreditoren_rechnungen_export_${Util.printSortableDate(new Date())}.csv`}
        getKey={item => item._id}  
      />
    </Card>
  </>
}


function datePrint(v?:Date) {
  if (v) {
    return Util.printDate(v);
  }
  else {
    return '-';
  }
}

function dateSort(d1?:Date, d2?:Date): number {
  const v1 = d1 ? d1.getTime() : 0;
  const v2 = d2 ? d2.getTime() : 0;
  return v2 > v1 ? 1 : -1;
}
