import { Module, ModuleInfo, Theme } from ".";

export class HeroModule implements Module {
  readonly code: string;

  _id?: string;
  countries: string[];
  position: number;
  theme: Theme;
  anchor?: string;

  elements: {
    imageUrl?: string;
    imageBlurhash?: string;
    imageMobileUrl?: string;
    imageMobileBlurhash?: string;
    imageAlt?: string;
    vimeoId?: string;
    autoPLayVimeoVideo?: boolean;
    title?: string;
    subtitle?: string;
    buttonText?: string;
    buttonHref?: string;
    secondaryButtonText?: string;
    secondaryButtonHref?: string;
    mobileLayout?: "text-image" | "image-text";
  };

  constructor() {
    this.code = HeroModule.info.code;
    this.countries = ["CH", "DE"];
    this.position = 0;
    this.theme = "primary";
    this.elements = {
      imageUrl: "",
      imageBlurhash: undefined,
      imageMobileUrl: "",
      imageMobileBlurhash: undefined,
      imageAlt: "",
      vimeoId: "",
      autoPLayVimeoVideo: false,
      title: "",
      subtitle: "",
      buttonText: "",
      buttonHref: "",
      secondaryButtonText: "",
      secondaryButtonHref: "",
      mobileLayout: "image-text",
    };
  }

  static get info(): ModuleInfo {
    return {
      code: "hero",
      title: "Hero",
      description:
        "Hero mit Bild oder Video und optionalem Titel, Untertitel und Button",
    };
  }
}