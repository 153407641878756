import _ from "lodash";
import React, { useEffect, useState } from "react";
import Config from "../../../../config";
import Util from "../../../../util/util";
import * as BL from "../../../../types/BusinessLogic";
import * as DA from "../../../../types/DataAccess";

// appentura color
// TODO move this to config
const GREEN:string = "#54AC60";

// local types
type PaymentType = "invoice"|"cc"|"monthly";

// context
type BookingRequestContextType = {
  isLoaded: boolean,
  isValid: boolean,
  services: DA.BookingRequestService[],
  collectiveInvoice: DA.BookingRequestCollectiveInvoice|null,
  emailTexts: EmailTexts,
  paymentType: PaymentType,
  
  load: (adventureInfo:any, activityInfo:any, provider:DA.Provider) => void,
  addServiceItem: () => void,
  removeServiceItem: (service: DA.BookingRequestService) => void,
  save: (kind: DA.BookingRequestRequestKind, email:any|null) => void,
  changeCollectiveInvoice: (ci:DA.BookingRequestCollectiveInvoice) => void,
  changeSubject: (subject:string) => void,
  changeAddendum: (addendum:string) => void,
  changePaymentType: (paymentType:PaymentType) => void,
}
const BookingRequestContext = React.createContext<BookingRequestContextType>({} as BookingRequestContextType);

// provider
type BookingRequestProviderProps = {
  children: React.ReactNode|Array<React.ReactNode>,
}
function BookingRequestProvider({children}: BookingRequestProviderProps) {
  // state
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);

  const [paymentType, setPaymentType] = useState<PaymentType>("invoice");
  const [services, setServices] = useState<DA.BookingRequestService[]>([]);
  const [collectiveInvoice, setCollectiveInvoice] = useState<DA.BookingRequestCollectiveInvoice|null>(null);
  const [emailTexts, setEmailTexts] = useState<EmailTexts>(new EmailTexts());

  const [adventureInfo, setAdventureInfo] = useState<any|null>(null);
  const [activityInfo, setActivityInfo] = useState<any|null>(null);
  
  // mount
  useEffect(() => {
  }, []);

  // provider value
  const providerValue = {
    // values
    isLoaded,
    isValid,
    paymentType,
    services,
    emailTexts,
    collectiveInvoice,
    
    // functions
    load: async(adventureInfo:any, activityInfo:any, provider:DA.Provider) => {
      // get login link for provider and the current user
      const providerLoginLink = await DA.ProviderRepository.fetchLoginLink(provider);
      const currentUser = await BL.User.getCurrent();
      // set services and email texts
      setServices(getDefaultServices(adventureInfo, activityInfo));
      const emailTexts = getEmailTexts(adventureInfo, activityInfo, currentUser, providerLoginLink);
      setEmailTexts(emailTexts);
      // set type of payment
      if(provider.collectiveInvoice.isActive) {
        setPaymentType("monthly");
        const collectiveInvoice = {amount:0, text:`${activityInfo.title}, ${activityInfo.participantCount} Person${activityInfo.participantCount > 1 ? "en" : ""}`, collectiveInvoice_id:null};
        setIsValid(validate(emailTexts, collectiveInvoice));
        setCollectiveInvoice(collectiveInvoice);
      }
      // set infos
      setActivityInfo(activityInfo);
      setAdventureInfo(adventureInfo);
      // set loaded flag
      setIsLoaded(true);
    },
    addServiceItem: () => {
      const item: DA.BookingRequestService = { key:"", value:""};
      const updatedServices: DA.BookingRequestService[] = [...services, item];
      setServices(updatedServices);
    },
    removeServiceItem: (serviceToRemove:DA.BookingRequestService) => {
      const servicesUpdated = services.filter(s => {
        return s.key.trim() !== serviceToRemove.key.trim() && s.value.trim() !== serviceToRemove.value.trim();
      });
      setServices(servicesUpdated);
    },
    changePaymentType: (paymentType: PaymentType) => {
      setPaymentType(paymentType);
    },
    changeSubject:(subject:string) => {
      const copy = _.cloneDeep(emailTexts);
      copy.subject = subject;
      setIsValid(validate(emailTexts, collectiveInvoice));
      setEmailTexts(copy);
    },
    changeAddendum:(addendum:string) => {
      const copy = _.cloneDeep(emailTexts);
      copy.addendum = addendum;
      setEmailTexts(copy);
    },
    changeCollectiveInvoice: (ci:DA.BookingRequestCollectiveInvoice) => {
      setCollectiveInvoice(ci);
      setIsValid(validate(emailTexts, ci));
    },
    save: async(kind: DA.BookingRequestRequestKind, email:DA.BookingRequestEmail|null) => {
      const user = await BL.User.getCurrent();
      // create the booking request object
      const activity:DA.BookingRequestActivity = {
        date: activityInfo!.time,
        id: activityInfo!.activityId,
        name: activityInfo!.title,
        regionCode: activityInfo!.regionCode
      };
      const provider:DA.BookingRequestProvider = {
        id: activityInfo!.providerId!,
        name: activityInfo!.providerName
      };
      const request:DA.BookingRequestRequest = {
        createdOn: new Date(),
        createdBy: user.email,
        kind: kind,
        email: email || undefined,
        comment: emailTexts.addendum
      }
      const br = DA.BookingRequestRepository.make(adventureInfo!.id, adventureInfo!.startTime, activity, provider, request, services);
      br.collectiveInvoice = collectiveInvoice;
      br.services = services;
      // save the booking request
      await DA.BookingRequestRepository.create(br);
    }
  }

  // render
  return (
    <BookingRequestContext.Provider value={providerValue}>
      {children}
    </BookingRequestContext.Provider>
  )
}

// locally used types
class EmailTextsBodyPayment {
  invoice: string = "";
  cc: string = "Die oben genannten Leistungen können sie unserer Kreditkarte fernbelasten, zusätzliche Leistungen / Konsumationen kassieren Sie bitte direkt vor Ort ein.";
  monthly: string = "";
}
class EmailTextsBody  {
  top: string[] = [];
  body: string[] = [];
  portal: string[] = [];
  payment: EmailTextsBodyPayment = new EmailTextsBodyPayment();
  bottom: string[] = [];
}
class EmailTextsFooter {
  explanation: string[] = [];
  signature: string[] = [];
}
class EmailTexts {
  subject: string = ""; 
  body: EmailTextsBody = new EmailTextsBody();
  addendum: string = "";
  signature: string[] = [];
  footer: EmailTextsFooter = new EmailTextsFooter();
}

/**
 * Assembles texts for the e-mail
 * @param adventureInfo 
 * @param activityInfo 
 * @param currentUser 
 * @param providerLoginLink 
 * @returns 
 */
function getEmailTexts(adventureInfo:any, activityInfo:any, currentUser:DA.User, providerLoginLink:string): EmailTexts {
  const email:EmailTexts = new EmailTexts();
  
  // set up default service items
  const when = new Date(activityInfo.time)
  const participants = activityInfo.participantCount
  const adventureId4 = adventureInfo.id4

  // default subject
  email.subject = `Buchungsanfrage: ${participants} Personen am ${Util.printDate(when, {dayName:true})} [${adventureId4}]`

  // default bodyTop / bodyBottom
  email.body.top = [
    "Guten Tag",
    "Gerne möchten wir folgende Leistungen bei Ihnen buchen:"
  ];
  email.body.portal = [
    "",
    "Klicken sie einfach auf den Button um die Buchungsanfrage zu bearbeiten:",
    "",
    `<a style="background-color: ${GREEN};white-space: nowrap; padding: 4px 16px 4px 16px; color:white; border-radius:4px;text-decoration:none" href='${providerLoginLink}'>Buchungsanfrage ansehen</a>`,
    "",
    ""
  ];
  email.body.bottom = [
    "Bei Fragen antworten Sie einfach auf dieses E-Mail oder rufen Sie uns an unter <a href='tel:+41315112132'>+41 31 511 21 32</a>.",
    //"Vielen Dank für eine kurze Bestätigung."
  ];
  email.body.payment.invoice = `Die Rechnung für die oben genannten Leistungen schicken Sie bitte an uns, zusätzliche Leistungen / Konsumationen kassieren Sie bitte direkt vor Ort ein.<br />Rechnungsadresse: ${Config.appentura.address.postal.join(', ')}`;
  email.body.payment.cc = "Die oben genannten Leistungen können sie unserer Kreditkarte fernbelasten, zusätzliche Leistungen / Konsumationen kassieren Sie bitte direkt vor Ort ein.";
  email.body.payment.monthly = "Die oben genannte Leistung wird ihnen mit der nächsten Monatsabrechnung erstattet.";
  
  // addendum & signature
  email.addendum = ""
  email.signature = [
    "Beste Grüsse",
    `${currentUser.firstName} ${currentUser.lastName}`,
    `<a href='mailto:${currentUser.email}'>${currentUser.email}</a>`
  ]

  // footer
  email.footer.explanation = [
    "Wir sind ein Startup aus Bern und bieten Überraschungs-Erlebnisse. Der Kunde wurde beschenkt und wird per Smartphone durch seine Überraschung geführt.",
    // "Weitere für Sie relevante Informationen finden Sie auf <a href='https://www.appentura.ch/erlebnispartner' style='color:white;text-decoration: underline'>appentura.ch/anbieter</a>.",
  ];
  email.footer.signature = [
    Config.appentura.address.postal.join(' - '),
    "<a href='https://www.appentura.ch'>www.appentura.ch</a>",
    "<a href='mailto:hello@appentura.ch'>hello@appentura.ch</a>",
    "<a href='tel:+41315112132'>+41 31 511 21 32</a>"
  ];

  // done
  return email
}

/**
 * Returns default services based on the adventure and the activity
 * @param adventureInfo 
 * @param activityInfo 
 * @returns 
 */
function getDefaultServices(adventureInfo: any, activityInfo: any): DA.BookingRequestService[] {
  const when = new Date(activityInfo.time)

  return [
    {key:"Datum", value:Util.printDate(when, {dayName:true})},
    {key:"Zeit", value:Util.printTime(when)},
    {key:"Leistung", value:activityInfo.title},
    {key:"Anzahl Personen", value:String(activityInfo.participantCount)},
    {key:"Konsumationsguthaben (CHF)", value:""},
    {key:"Name(n) der Kunden", value:adventureInfo.who},
  ]
}

/**
 * Validates the form
 * @param ci 
 * @returns 
 */
function validate(emailTexts:EmailTexts, ci:DA.BookingRequestCollectiveInvoice|null) : boolean {
  if(ci) {
    return (ci.amount > 0) && (ci.text.trim().length > 0) && (emailTexts.subject.trim().length > 0);
  }
  else {
    return (emailTexts.subject.trim().length > 0);
  }
}


// export
export {
  BookingRequestContext, 
  BookingRequestProvider,
  EmailTexts
}