import React from "react";
import CSS from "./Switchboard.module.scss";

export type SwitchboardItem = {
  label: string, 
  value: any
}
type SwitchboardProps = {
  onChange: (values:any[]) => void,
  items: SwitchboardItem[],
  selectedValues: any[],
  minItems?: number,
  maxItems?: number,
  disabled?: boolean
}
export function Switchboard({items, onChange, selectedValues, minItems, maxItems, disabled} : SwitchboardProps) {

  const _onChange = (v:any) => {
    if(onChange && disabled !== true) {
      if(selectedValues.includes(v)) {
        // remove item
        const updated = selectedValues.filter(item => item !== v);
        const remove = minItems ? updated.length >= minItems : true;
        if(remove) {
          onChange(updated)
        }
      }
      else {
        // add item
        let updated = selectedValues.map(item => item);
        const itemToAdd = items.find(item => item.value === v);
        if(maxItems) {
          if(maxItems === 1) {
            updated = [itemToAdd!.value];
          }
          else if(selectedValues.length < maxItems) {
            updated.push(itemToAdd!.value);
          }
        }
        else {
          updated.push(itemToAdd!.value);
        }
        onChange(updated);
      }
    }
  }
  // pre-render items
  const elements = items.map((item, index) => {
    const active = selectedValues.includes(item.value);
    return <div key={index} className={`${CSS.item} ${active && CSS.active}`} onClick={()=>_onChange(item.value)}>{item.label}</div>
  });
  // render
  return <div className={`${CSS.container} ${disabled ? CSS.disabled : undefined }`}>
    <div className={CSS.inner}>
      {elements}
    </div>
  </div>
}