import React, {useEffect, useState} from "react";
import * as TS from "../../../../types";
import { Alert, Button, Card, Loader, MapLink, Heading, Table, Tr, Td, Row } from "../../../controls";
import { Redirect } from "react-router-dom"
import "./SoldOfflineFix.scss";

type SoldOfflineFixProps = {
  adventure: any
}
export default function SoldOfflineFix({adventure}: SoldOfflineFixProps) {
  // state
  const [busy, setBusy] = useState<boolean>(true);
  const [data, setData] = useState<any>(null);
  const [selectedRegion, setSelectedRegion] = useState<string>("");
  const [isFixed, setIsFixed] = useState<boolean>(false);

  // mount
  useEffect(() => {
    const load = async() => {
      const activities = await TS.AdventureOld.getActivities(adventure);
      const setRegions = new Set();
      activities.forEach((a:any) => {
        a.regionVariants.forEach((rv:any) => {
          if(rv.isActive) {
            setRegions.add(rv.regionCode);
          }
        })
      })
      setData({availableRegions:Array.from(setRegions), activities});
      setBusy(false);
    };
    load();
    
  }, [adventure]);

  // applies the fix
  const fix = async() => {
    setBusy(true);
    await TS.AdventureOld.fixSoldOffline(adventure, selectedRegion);
    setIsFixed(true);
  }

  // render redirect
  if(isFixed) {
    return <Redirect to={`/surprise/${adventure._id}`} />
  }
  // render loading
  if(!data) {
    return <Loader />
  }

  // render
  const title = `Überraschung von ${adventure.ReserverName} für ${adventure.RecieverName}`;
  const selectedLocation = (adventure.UserStartLocationText && adventure.UserStartLocation) ? <div>Der Beschenkte hat folgenden Startort gewählt: <MapLink lat={adventure.UserStartLocation.Lat} lng={adventure.UserStartLocation.Lng}>{adventure.UserStartLocationText}</MapLink></div> : <div style={{color:"orange"}}>ACHTUNG: Der Beschenkte hat noch keinen Startort gewählt. Eine Region für die Aktivitäten zu wählen macht wahrscheinlich wenig Sinn.</div>
  const headerCells = data.availableRegions.map((r:any) => <Td key={r}><span>{r}</span></Td>)
  const rows = data.activities.map((a:any) => {
    const regionCells = data.availableRegions.map((regionCode:any) => {
      const rv = a.regionVariants.find((r:any) => r.regionCode === regionCode);
      return rv && rv.isActive ? <Td key={regionCode}>&#10004;</Td> : <Td key={regionCode}>&times;</Td>
    })
    return <Tr key={a._id}><Td>{a.title.de}</Td>{regionCells}</Tr>
  })
  const regionOptions = data.availableRegions.map((regionCode:any) => <option key={regionCode} value={regionCode}>{regionCode}</option>)
  regionOptions.unshift(<option key="nil" value="">Region wählen</option>)

  return <>
    <Heading>{title}</Heading>
    <Card id="v-soldofflinefix">
      <Alert title="Offline verkaufte Überraschung" intent="info">
        Diese Überraschung wurde Offline verkauft und den enthaltenen Aktivitäten ist deshalb keine Region zugewiesen.
        <br />
        Um die Überraschung planen zu können, muss eine Region gewählt werden.
      </Alert>

      <div className="location">
        {selectedLocation}
      </div>

      <div className="activities">
        <div>Enthaltene Aktivitäten und ihrer Verfügbarkeit in den Regionen</div>
        <Table>
          <Tr><Td></Td>{headerCells}</Tr>
          {rows}
        </Table>
      </div>

      <Row align="center">
        <div>
          <select value={selectedRegion} onChange={e => setSelectedRegion(e.target.value)}>
            {regionOptions}
          </select>
        </div>
        <Button disabled={selectedRegion === "" || busy} onClick={fix} size="small">gewählte Region zuweisen</Button>
      </Row>
    </Card>
  </>
}