import _ from "lodash";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React, { useEffect, useState } from "react";
import { Icon, Icons } from ".";

import CSS from "./Card.module.scss";

type CardProps = {
  title?: string,
  children?: React.ReactNode|React.ReactNode[],
  className?: string,
  id?: string,
  collapsible?: boolean,
  expanded?: boolean,
  size?: "regular"|"small",
  intent?: "primary"|"error"|"warning",
  summaryContent?: string|string[],
  icon?: IconProp,
}
export function Card({title, children, className, id, collapsible, expanded, size, summaryContent, icon, intent="primary"} : CardProps) {
  // defaults
  collapsible = collapsible || false;
  expanded = expanded || false;
  size = size || "regular";

  // state
  const [isExpanded, setIsExpanded] = useState<boolean>(expanded);

  // expanded changed
  useEffect(() => {
    if(expanded !== isExpanded) {
      setIsExpanded(expanded || false);
    }
  }, [expanded]);

  // pre-render
  let content, header, divider;
  
  if(collapsible && !isExpanded) {
    content = null;
  }
  else {
    content = <div className={CSS.content}>{children}</div>;
  }

  let summary:any = null;
  if(summaryContent && !isExpanded) {
    let summaryInner;
    if(_.isArray(summaryContent)) {
      summaryInner = summaryContent
        .filter(c => !_.isNil(c))
        .filter(c => String(c).trim().length > 0)
        .map((c, index) => <div key={index}>{c}</div>)
    }
    else {
      let c = String(summaryContent).trim();
      if(c.length > 0) {
        summaryInner = <div>{c}</div>
      }
    }
    summary = <div className={CSS.summary}>{summaryInner}</div>
  }

  const headerIcon = icon ? <><Icon icon={icon} />&nbsp;</> : null;

  if(!collapsible) {
    header = title ? <div className={CSS.heading}><div className={CSS.title}>{headerIcon}{title}</div></div> : null;
  }
  else {
    header = <div className={`${CSS.heading_collapsible} ${isExpanded ? CSS.expanded:""}`} onClick={() => setIsExpanded(!isExpanded)}>
      <div className={CSS.title_and_toggle}>
        <div className={CSS.title}>{headerIcon}{title || ""}</div>
        <div className={CSS.toggle}><Icon icon={isExpanded ? Icons.MinusSquare : Icons.PlusSquare} /></div>
      </div>
      {summary}
    </div>
  }

  // render
  return (
    <div id={id || undefined} className={`${CSS.container} ${CSS["size_" + size]} ${className || ""} ${CSS['intent_'+intent]}`}>
      {header}
      {content}
    </div>
  )
  
}
