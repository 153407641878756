import { Translatable } from "../Translatable";

export class ActivityRegionVariantBenefit {
  _id?: string;
  de: string = "";
  en: string = "";

  public toDb() : any {
    return {
      _id: this._id,
      de: this.de,
      en: this.en
    }
  }

  public static fromDb(obj:any) : ActivityRegionVariantBenefit {
    const benefit = new ActivityRegionVariantBenefit();
    benefit._id = String(obj._id);
    benefit.de = obj.de || "";
    benefit.en = obj.en || "";
    return benefit;
  }

}

export class ActivityRegionVariantCondition {
  _id?: string;
  condition_id?: string;
  title: Translatable;

  constructor() {
    this.title = new Translatable();
  }

  public toDb() : any {
    return {
      _id: this._id,
      condition_id: this.condition_id,
      title: this.title.toDb()
    }
  }

  public static fromDb(obj:any): ActivityRegionVariantCondition {
    const condition = new ActivityRegionVariantCondition();
    condition._id = String(obj._id);
    condition.condition_id = String(obj.condition_id);
    condition.title = Translatable.fromDb(obj.title);
    return condition;
  }
}

export class ActivityRegionVariantWeatherCondition {
  _id?: string;
  weatherCondition_id?: string;
  title: Translatable;

  constructor() {
    this.title = new Translatable();
  }

  public toDb() : any {
    return {
      _id: this._id,
      weatherCondition_id: this.weatherCondition_id,
      title: this.title.toDb()
    }
  }

  public static fromDb(obj:any): ActivityRegionVariantWeatherCondition {
    const weatherCondition = new ActivityRegionVariantWeatherCondition();
    weatherCondition._id = String(obj._id);
    weatherCondition.weatherCondition_id = String(obj.weatherCondition_id);
    weatherCondition.title = Translatable.fromDb(obj.title);
    return weatherCondition;
  }
}

export class ActivityRegionVariantEquipment {
  _id?: string;
  equipment_id?: string;
  title: Translatable;

  constructor() {
    this.title = new Translatable();
  }

  public toDb() : any {
    return {
      _id: this._id,
      equipment_id: this.equipment_id,
      title: this.title.toDb()
    }
  }

  public static fromDb(obj:any): ActivityRegionVariantEquipment {
    const equipment = new ActivityRegionVariantEquipment();
    equipment._id = String(obj._id);
    equipment.equipment_id = String(obj.equipment_id);
    equipment.title = Translatable.fromDb(obj.title);
    return equipment;
  }
}

export class ActivityRegionVariantParticipants {
  min: number = 1;
  max: number = 1;

  public toDb() : any {
    return {
      max: this.max,
      min: this.min
    }
  }

  public static fromDb(obj:any): ActivityRegionVariantParticipants {
    const participants = new ActivityRegionVariantParticipants();
    participants.min = obj.min || 0;
    participants.max = obj.max || 0;
    return participants;
  }
}

export class ActivityRegionVariantPriceConfiguration {
  _id?: string;
  participantCount: number = 0;
  price: number = 0;

  public toDb() : any {
    return {
      _id: this._id,
      participantCount: this.participantCount,
      price: this.price
    }
  }

  public static fromDb(obj:any): ActivityRegionVariantPriceConfiguration {
    const pc = new ActivityRegionVariantPriceConfiguration();
    pc._id = String(obj._id);
    pc.participantCount = obj.participantCount;
    pc.price = obj.price;
    return pc;
  }
}

export class ActivityRegionVariantOption {
  _id?:  string;
  name: Translatable = new Translatable();
  description: Translatable = new Translatable();
  minUnits: number = 1;
  maxUnits: number = 1;
  price: number = 1;

  public toDb() : any {
    return {
      _id: this._id,
      name: this.name.toDb(),
      description: this.description.toDb(),
      maxUnits: this.maxUnits,
      minUnits: this.minUnits,
      price: this.price
    }
  }

  public static fromDb(obj:any): ActivityRegionVariantOption {
    const option = new ActivityRegionVariantOption();
    option._id = String(obj._id);
    option.name = Translatable.fromDb(obj.name);
    option.description = Translatable.fromDb(obj.description);
    option.minUnits = obj.minUnits;
    option.maxUnits = obj.maxUnits;
    option.price = obj.price;
    return option;
  }
}

export class ActivityRegionVariantDuration {
  minutes: number = 0;

  public toDb(): any {
    return {
      minutes: this.minutes
    }
  }

  public static fromDb(obj:any) : ActivityRegionVariantDuration {
    const duration = new ActivityRegionVariantDuration();
    duration.minutes = obj.minutes || 0;
    return duration;
  }
}

export class ActivityRegionVariantProvider {
  _id?: string;
  id: string = "";
  isActive: boolean = true;
  notes: string = "";

  constructor(providerId: string, notes:string) {
    this.id = providerId;
    this.notes = notes
  }

  public toDb() : any {
    return {
      _id: this._id,
      id: this.id,
      isActive: this.isActive,
      notes: this.notes
    }
  }

  public static fromDb(obj:any):ActivityRegionVariantProvider {
    const provider = new ActivityRegionVariantProvider(obj.id, obj.notes || "");
    provider._id = obj._id;
    provider.isActive = obj.isActive ? true :  false;
    return provider;
  }
}

/**
 * 
 */
export class ActivityRegionVariant {
  _id: string|undefined;
  regionCode: string;
  // flags
  isActive: boolean = false;
  isSoldOut: boolean = false;
  // 
  price: number = 0; // TODO to be honest, not sure if (and if so why) we use this
  availableAreas: string = ""; // e.g. PLZ 3097, 3098 / Kreis 5 / etc
  benefits: ActivityRegionVariantBenefit[] = [];
  conditions: ActivityRegionVariantCondition[] = [];
  weatherConditions: ActivityRegionVariantWeatherCondition[] = [];
  equipment: ActivityRegionVariantEquipment[] = [];
  priceConfigurations: ActivityRegionVariantPriceConfiguration[] = [];
  participants: ActivityRegionVariantParticipants = new ActivityRegionVariantParticipants();
  options: ActivityRegionVariantOption[] = [];
  comments: string = "";
  duration: ActivityRegionVariantDuration = new ActivityRegionVariantDuration();
  availableMonths: Array<1|2|3|4|5|6|7|8|9|10|11|12> = [];
  availableWeekdays: Array<1|2|3|4|5|6|7> = [];
  providers: ActivityRegionVariantProvider[] = [];

  constructor() {
    this.regionCode = "CH-BE"; // TODO not sure how wise it is to use this as the default
  }

  public toDb() : any {
    return {
      _id: this._id,
      regionCode: this.regionCode,
      // flags
      isActive: this.isActive,
      isSoldOut: this.isSoldOut,
      // 
      price: this.price,
      availableAreas: this.availableAreas,
      benefits: this.benefits.map(b => b.toDb()),
      conditions: this.conditions.map(c => c.toDb()),
      weatherConditions: this.weatherConditions.map(wc => wc.toDb()),
      equipment: this.equipment.map(e => e.toDb()),
      priceConfigurations: this.priceConfigurations.map(pc => pc.toDb()),
      participants: this.participants.toDb(),
      options: this.options.map(o => o.toDb()),
      comments: this.comments,
      duration: this.duration.toDb(),
      availableMonths: this.availableMonths.map(m => m),
      availableWeekdays: this.availableWeekdays.map(d => d),
      providers: this.providers.map(p => p.toDb())
    }
  }


  /**
   * Validates the available price configurations. They should have participant counts without gaps. The prices should increase according to the increase of participants.
   * @returns 
   */
  public validatePriceConfigurations() : {valid:boolean, issues:string[]} {
    const issues: string[] = [];
    const sortedPcs = this.priceConfigurations.sort((a, b) => a.participantCount - b.participantCount);
    for(let i=0; i < sortedPcs.length; i++) {
      if(i > 0) {
        const previous = sortedPcs[i-1];
        const current = sortedPcs[i];
        if(previous.participantCount === current.participantCount) {
          issues.push(`Es sind mehrere Preiskonfigurationen für ${current.participantCount} Teilnehmer definiert.`)
        }
        if((current.participantCount - previous.participantCount) > 1) {
          issues.push(`Zwischen ${previous.participantCount} und ${current.participantCount} Teilnehmer besteht eine Lücke.`)
        }
        if(previous.price >= current.price) {
          issues.push(`Der Preis für ${sortedPcs[i-1].participantCount} Teilnehmer darf nicht grösser oder gleich sein wie der Preis für ${sortedPcs[i].participantCount} Teilnehmer.`)
        }
      }
    }

    return {
      valid: issues.length === 0, issues
    }
  }


  public static fromDb(obj:any) : ActivityRegionVariant {
    const rv = new ActivityRegionVariant();
    rv._id = String(obj._id);
    rv.regionCode = obj.regionCode || "CH-BE";
    rv.isActive = obj.isActive ? true : false;
    rv.isSoldOut = obj.isSoldOut ? true : false;
    rv.price = obj.price || 0;
    rv.availableAreas = obj.availableAreas || "";
    rv.benefits = (obj.benefits || []).map((benefit:any) => ActivityRegionVariantBenefit.fromDb(benefit));
    rv.conditions = (obj.conditions || []).map((condition:any) => ActivityRegionVariantCondition.fromDb(condition)); 
    rv.weatherConditions = (obj.weatherConditions || []).map((weatherCondition:any) => ActivityRegionVariantWeatherCondition.fromDb(weatherCondition)); 
    rv.equipment = (obj.equipment || []).map((equipment:any) => ActivityRegionVariantEquipment.fromDb(equipment));
    rv.participants = ActivityRegionVariantParticipants.fromDb(obj.participants);
    rv.priceConfigurations = (obj.priceConfigurations || []).map((pc:any) => ActivityRegionVariantPriceConfiguration.fromDb(pc));
    rv.options = (obj.options || []).map((option:any) => ActivityRegionVariantOption.fromDb(option));
    rv.comments = obj.comments || "";
    rv.duration = ActivityRegionVariantDuration.fromDb(obj.duration);
    rv.availableMonths = (obj.availableMonths || []).filter((m:any) => m >= 1 && m <= 12);
    rv.availableWeekdays = (obj.availableWeekdays || []).filter((m:any) => m >= 1 && m <= 7);
    rv.providers = (obj.providers || []).map((provider:any) => ActivityRegionVariantProvider.fromDb(provider));
    //
    return rv;
  }
}