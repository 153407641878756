import React from "react";
import Code from "../Code";
import Notes from "../Notes";
import { Card, Loader, Heading } from "../../../controls";

export default function LoaderDemo() {
  return <Card>
    <Heading>Loader</Heading>
    <Loader text="loading ..." />
    <Code>{`<Loader text="loading ..." />`}</Code>
    <Loader kind="circle" />
    <Code>{`<Loader kind="circle" />`}</Code>
    <Loader kind="circle" size="small" />
    <Code>{`<Loader kind="circle-small" />`}</Code>
    <Loader kind="dots"  />
    <Code>{`<Loader kind="dots" />`}</Code>
  </Card>
}
