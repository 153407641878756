import _ from "lodash";
import React, {useContext} from "react";
import {SurpriseContext} from "../../Surprise.Context";
import {Link} from "../../../../controls";
import Row from "./Row";

export default function ActivitiesPackages() {
  // context 
  const SC = useContext(SurpriseContext);

  
  // pre-render
  const items = (_.get(SC.surprise, "Order.ActivityPackages") || []).map(ap => {
    return(
      <div key={ap.ActivityPackageId}>
        <Link to={`/activitypackages/${ap.ActivitiesPackageId}}`} target="_blank">
          {ap.Title}
        </Link>
        , {_.get(ap, "ParticipantCount") || "?"} Personen in {_.get(ap, "RegionCode") || "?"}, Preis: {_.get(ap, "Price")}
      </div>
    )
  })

  // render
  return <Row label="Aktivitätspakete">{items.length > 0 ? items : "-"}</Row>

  
}
