import React from "react";

type ParagraphsProps = {
  text:string,
  className?:string
}
export function Paragraphs({text, className}:ParagraphsProps) {
  const lines = (text || "").trim().split("\n");
  const paragraphs = lines.map((line:string, index:number) => <p key={index}>{line}</p>);
  return <div className={className}>{paragraphs}</div>
}
