import _ from "lodash";
import React, {useContext, useEffect, useState} from "react";
import * as TS from "../../../../types";
import * as BL from "../../../../types/BusinessLogic";
import * as DA from "../../../../types/DataAccess";
import Util from "../../../../util/util";
// components and contexts
import { SurpriseContext } from "../Surprise.Context";
import { Button, CardSection, Icon, Icons, Loader, Switchboard, SwitchboardItem, Table, Tr, Td, Row, Modal } from "../../../controls";
import LogEdit from "./Log.Edit";
// styling
import CSS from "./Log.View.module.scss";

type LogViewProps = {
  headless: boolean, 
  expandable: boolean
}
export default function Log({headless, expandable} : LogViewProps) {
  // context 
  const SC = useContext(SurpriseContext);

  // state
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalParam, setModalParam] = useState<any>();
  const [modalTitle, setModalTitle] = useState<string>("");

  const [filterItems] = useState<SwitchboardItem[]>([
    {label: "Robot", value:"robot"},
    {label: "Operator", value:"operator"},
  ])
  const [filterValues, setFilterValues] = useState<any[]>(["robot", "operator"]);
  const [expanded, setExpanded] = useState(expandable ? false : true);
  const [currentUser, setCurrentUser] = useState<DA.User|null>(null);

  // mount
  useEffect(() => {
    const load = async() => {
      const currentUser = await BL.User.getCurrent();
      setCurrentUser(currentUser);
    }
    load();
  }, []);

  // opens edit modal
  const edit = (entry: any) => {
    setModalParam(entry);
    setModalTitle(`Log-Eintrag ${_.isNil(entry) ? "erfassen" :"bearbeiten"}`);
    setModalOpen(true);
  }

  const stopEdit = () => setModalOpen(false);

  // wait for user to load
  if(_.isNil(currentUser)) {
    return <Loader text="laden ..." />;
  }

  // gather log entries
  let rows = (SC.log || [])
    .map((entry:any) => {
      // enrich a little
      entry.__isRobotEntry = (entry.Operator || 'robot').trim().toLowerCase() === 'robot';
      return entry;
    })
    .filter((entry:any) => {
      if(entry.__isRobotEntry && !filterValues.includes("robot")) {
        return false;
      }
      else if(!entry.__isRobotEntry && !filterValues.includes("operator")) {
        return false;
      }
      else {
        return true;
      }
    })
    .sort((a:any, b:any) => (new Date(a.CreateDate) > (new Date(b.CreateDate)) ? -1 :1))
    .map((entry:any, index:number) => {
      let icon = Icons.User;
      if(entry.ShowWarning) {
        icon = Icons.ExclamationTriangle;
      }
      else if(entry.__isRobotEntry) {
        icon = Icons.Robot;
      }
      let editButton = null;
      if(currentUser.email === entry.Operator) {
        editButton = <span className={CSS.edit}><Icon icon={Icons.Edit} onClick={() => edit(entry)}></Icon></span>
      }
      const operator = (entry.Operator || 'robot@appentura.ch').split('@')[0];
      const kind = entry.Type ? `${entry.Type} - ` : '';
      
      let rowClass = "";
      if(expandable && index > 2 && !expanded && !entry.ShowWarning) {
        rowClass = CSS.hidden;
      }
      if(entry.ShowWarning) {
        rowClass = CSS.warning;
      }
      return <Tr key={index} className={rowClass}>
        <Td><Icon icon={icon} /></Td>
        <Td>{Util.printDateAndTime(entry.CreateDate)}</Td>
        <Td>{operator}</Td>
        <Td>{kind}{entry.Text}</Td>
        <Td>{editButton}</Td>
      </Tr>
    })
  // expand button
  let expandConsole = null;
  if(expandable) {
    expandConsole = (
      <Row align="center">
        <Button size="small" onClick={() => setExpanded(!expanded)}>{expanded ? "  -  " : "  +  "}</Button>
      </Row>
    )
  }
  // modal
  const modal = (
    <Modal isOpen={modalOpen} title={modalTitle} onClose={stopEdit}>
      <LogEdit entry={modalParam} stopEdit={stopEdit} />
    </Modal>
  )

  // render
  if(headless) {
    return <>
      <div className={CSS.container}>
        <Table fontSize="small">
          {rows}
        </Table>
        {expandConsole}
      </div>
      {modal}
    </>
  }
  else {
    return <>
      <CardSection className={CSS.container} title="Log">
        <div className={CSS.actions}>
          <Button size="small" onClick={() => edit(null)}>Eintrag erfassen</Button>
        </div>
        <Switchboard selectedValues={filterValues} items={filterItems} onChange={v => setFilterValues(v)} />
        <Table>
          {rows}
        </Table>
        {expandConsole}
      </CardSection>
      {modal}
    </>;
  }
}
