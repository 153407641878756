import React, { useEffect, useState } from "react";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";

// selfie type
export class Selfie {
  adventureImage: DA.AdventureImage;
  adventure: TS.Adventure;
  
  constructor(adventure:TS.Adventure, adventureImage:DA.AdventureImage) {
    this.adventure = adventure;
    this.adventureImage = adventureImage;
  }

  public isEqual(selfie:Selfie) : boolean {
    return this.adventure._id === selfie.adventure._id && this.adventureImage._id === selfie.adventureImage._id;
  }
}

// the filter type
export class SelfieFilter {
  marketingAllowed: "all"|"yes"|"no";
  shared: "all"|"yes"|"no";
  yearFrom: number;
  monthFrom: number;
  yearTo: number;
  monthTo: number;
  minRating: number; // 0|1|2|3|4|5;
  testimonial: "all"|"yes"|"no";
  bookmarked: "all"|"yes"|"no";
  activityId: string|null;
  testimonialText: string;

  constructor() {
    this.marketingAllowed = "yes";
    this.shared = "all"; 
    this.yearFrom = this.yearTo = (new Date()).getFullYear();
    this.monthFrom = this.monthTo = (new Date()).getMonth();
    this.minRating = 4;
    this.testimonial = "all";
    this.bookmarked = "all";
    this.activityId = null;
    this.testimonialText = "";
  }

  private get dateFrom() : Date {
    return new Date(this.yearFrom, this.monthFrom, 1);
  }

  private get dateTo() : Date {
    const dateTo = new Date(this.yearTo, this.monthTo, 1);
    dateTo.setMonth(dateTo.getMonth() + 1);
    return dateTo;
  }

  
  public toMongoFilter(): any {
    const f:any = {};

    // date range
    f.CreateDate = { 
      $gte: this.dateFrom,
      $lt: this.dateTo
    }
    // marketing allowed?
    if(this.marketingAllowed !== "all") {
      f.MarketingAllowed = this.marketingAllowed === "yes" ? true : false;
    }  
    // already shared?
    if(this.shared !== "all") {
      f["Posts.0"] = {$exists:this.shared === "yes" ? true : false}
    }
    return f;
  }
}

// context type
type SelfieContextType = {
  // values
  marketingChannels: DA.MarketingChannel[],
  activities: TS.Activity[],
  selfies: Selfie[],
  filter: SelfieFilter,
  loading: boolean,
  selected: Selfie[],
  // functions
  search: () => void,
  getActivityNames: (adventure:TS.Adventure) => string,
  toggleSelect: (selfie: Selfie) => void,
  clearSelected: () => void,
  isSelected: (selfie: Selfie) => boolean,
}
// context
export const SelfieContext = React.createContext<SelfieContextType>({} as SelfieContextType);


// provider type
type SelfieProviderProps = {
  children: React.ReactNode|React.ReactNode[]
}
// provider
export function SelfieProvider({children}: SelfieProviderProps) {
  // state
  const [marketingChannels, setMarketingChannels] = useState<DA.MarketingChannel[]>([]);
  const [activities, setActivities] = useState<TS.Activity[]>([]);
  const [selfies, setSelfies] = useState<Selfie[]>([]);
  const [selected, setSelected]  = useState<Selfie[]>([]);
  const [filter] = useState<SelfieFilter>(new SelfieFilter());
  const [loading, setLoading] = useState<boolean>(false);

  // mount
  useEffect(() => {
    const load = async() => {
      setMarketingChannels(await DA.MarketingChannelRepository.findAll());
      setActivities(await TS.Activity.allActiveMain());
    }
    load();
  }, [])

  // provider value
  const value = {
    // props
    marketingChannels,
    selfies,
    selected,
    filter,
    loading,
    activities,

    // functions
    search: async() => {
      setLoading(true);
      const adventureImages = await DA.AdventureImageRepository.search(filter.toMongoFilter());
      const adventureIds = adventureImages.map(ai => ai.AdventureId);
      const adventures = await TS.Adventure.search({_id:{$in:adventureIds}});
      const selfies: Selfie[] = adventureImages.map(ai => {
        return new Selfie(adventures.find(a => a._id === ai.AdventureId)!, ai);
      }).filter(s => {
        // has required rating?
        if(filter.minRating > 0) {
          if(s.adventure.Rating === null) {
            return false;
          }
          if(s.adventure.Rating.Rating < filter.minRating) {
            return false;
          }
        }
        // has testimonial?
        if(filter.shared !== "all") {
          if(filter.shared === "yes" && s.adventureImage.Posts.length === 0) {
            return false;
          }
          if(filter.shared === "no" && s.adventureImage.Posts.length > 0) {
            return false;
          }
        }
        // bookmarked
        if(filter.bookmarked !== "all") {
          if(filter.bookmarked === "yes" && !s.adventureImage.Bookmarked) {
            return false;
          }
          if(filter.bookmarked === "no" && s.adventureImage.Bookmarked) {
            return false;
          }
        }
        // contains activity?
        if(filter.activityId) {
          return s.adventure.Activities.some(a => a.ActivityId === filter.activityId);
        }
        // testimonial text
        if(filter.testimonialText.trim().length > 0) {
          let containsText = false;
          
    
          if(s.adventure.Rating) {
            const tokens = filter.testimonialText.split(',').map(token => token.trim().toLowerCase()).filter(token => token.length > 0);
            const comment = s.adventure.Rating.Comment.toLowerCase().trim();
            const feedback = s.adventure.Rating.Feedback.toLowerCase().trim();

            let containsText_comment = tokens.some(token => comment.includes(token));
            let containsText_feedback = tokens.some(token => feedback.includes(token));
            return containsText_comment || containsText_feedback;
          }
          return containsText;
        }
        // ok
        return true;
      })
      setSelfies(selfies);
      setLoading(false);
    },
    getActivityNames: (adventure:TS.Adventure) => {
      const names = adventure.Activities.map(aa => activities.find(a => a._id === aa.ActivityId)).filter(a => a !== undefined).map(a => a!.title.de).join(', ');
      return names;
    },
    toggleSelect: (selfie: Selfie) => {
      const updated = selected.map(s => s);
      const index = updated.findIndex(s => s.isEqual(selfie));
      if(index === -1) {
        updated.push(selfie);
      }
      else {
        updated.splice(index, 1);
      }
      setSelected(updated);
    },
    clearSelected: () => {
      setSelected([]);
    },
    isSelected: (selfie: Selfie) => {
      return selected.findIndex(s => s.isEqual(selfie)) !== -1;
    }
  }

  // render
  return (
    <SelfieContext.Provider value={value}>
      {children}
    </SelfieContext.Provider>
  )
}

