import React, {useContext, useState} from "react";
import {ConfirmButton} from "../../../../controls";
import {SurpriseContext} from "../../Surprise.Context";

export default function SendSaleMail() {
  // context
  const SC = useContext(SurpriseContext);

  // state
  const [info, setInfo] = useState(null);
  const [busy, setBusy] = useState(false);

  // event handlers
  const sendMail = async() => {
    setBusy(true);
    const result = await SC.sendSaleMail();
    setBusy(false);
    if(result.success) {
      setInfo("Das Mail wurde erfolgreich verschickt.");
    }
    else {
      setInfo("Das Mail konnte nicht verschickt werden. Fehler: " + JSON.stringify(result.error));
    }
  }

  // render
  return (
    <div>
      <ConfirmButton onConfirm={sendMail} busy={busy}>Verkaufsmail senden</ConfirmButton>
      {info ? <div style={{fontSize:"10px", padding:"0 4px 0 4px"}}>{info}</div> : null}
    </div>
  );
}
