import _ from "lodash";
import Api from "../util/api2";

export class ShopProperty {
  // key:string = "";
  // value:any = "";

  // static fromDb(obj: any): ShopProperty {
  //   const shopProperty = new ShopProperty();
  //   shopProperty.key = obj.key;
  //   shopProperty.value = obj.value;
  //   return shopProperty;
  // }

  // toDb(): any {
  //   return {
  //     key: this.key,
  //     value: this.value
  //   }
  // }

  /***
  * @param {string} country
  */
  static async getInfoHeader(country: string): Promise<any> {
    const key = ShopProperty.getInfoHeaderKey(country);
    const defaultValue = {text:"Text", description:"Beschreibung", show:false}
    const data = await ShopProperty.getShopProperty(key, defaultValue);
    return data.value;
  }

  /**
   * @param {string} country 
   * @param {any} value
   */
  static async updateInfoHeader(country:string, value:any): Promise<any> {
    const key = ShopProperty.getInfoHeaderKey(country);
    const shopProperty = await ShopProperty.getShopProperty(key, {});
    const payload = {id:shopProperty._id, set:{value}};
    const result = await Api.post("shopProperties", "update", payload);
    return _.get(result, "data.item.value");
  }

  /**
   * @param {string} key
   * @param {any} defaultValue 
   */
  static async getShopProperty(key:string, defaultValue:any): Promise<any> {
    const result = await Api.post("shopProperties", "search", {filter:{key:key}});
    const items = result.data.items || [];
    let item = items.length > 0 ? items[0] : null;
    // create one if necessary
    if(item === null) {
      await Api.post("shopProperties", "create", {
        key:key,
        value: defaultValue
      })
      item = await ShopProperty.getShopProperty(key, defaultValue);
    }
    // and return
    return item;
  }

  private static getInfoHeaderKey(country: string): string {
    return `INFOHEADER_${country.toUpperCase()}`;
  }

}