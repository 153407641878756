import React from "react";
import { LinkButton } from ".";

type CsvDownloadProps = {
  children:React.ReactNode|React.ReactNode[],
  csv: string,
  filename: string,
  size?: "small"|"medium",
  disabled?: boolean
}
export function CsvDownload({children, csv, filename, size, disabled} : CsvDownloadProps) {
  // set defaults
  size = size || "small";
  disabled = disabled || false;
  const defaultText = "CSV herunterladen";
  
  // render
  if(disabled) {
    return <LinkButton to="#" disabled={disabled}>{children || defaultText}</LinkButton>
  }
  else {
    return (
      <LinkButton to={"data:text/csv;charset=utf-8," + encodeURIComponent(csv)} download={filename}>
        {children || defaultText}
      </LinkButton>
    );
  }
}