import React from "react";
import CSS from "./Table.module.scss";

type TableProps = {
  children: React.ReactNode|React.ReactNode[]
}
export function Table({children} : TableProps) {
  return (
    <table className={CSS.table}><tbody>{children}</tbody></table>
  )
}


type RowProps = {
  label: string,
  children: React.ReactNode|React.ReactNode[]
}
export function TableRow({label, children} : RowProps) {
  return (
    <tr>
      <td>{label}</td>
      <td>{children}</td>
    </tr>
  )
}