import React, { useContext, useEffect, useState } from "react";
// types
import * as Cms from "../../../../types/Cms";
// contexts and components
import { PageContext } from "../Page.Context";
import { TextInput, Form } from "../../../controls";
import * as Selectors from "../Selectors";
import * as Preview from "../Preview";

// styling and images
// import CSS from "./SimpleHeader.module.scss";


type SimpleHeaderProps = {
  instance?: Cms.SimpleHeaderModule,
  closeDialog: () => void,
}
export function SimpleHeader({instance, closeDialog}:SimpleHeaderProps) {
  // context
  const PC = useContext(PageContext);
  
  // state
  const [entity, setEntity] = useState<Cms.SimpleHeaderModule|null>(null);
  const [busy, setBusy] = useState<boolean>(false);

  // mount
  useEffect(() => {
    let entity: Cms.SimpleHeaderModule;
    if(instance) {
      entity = instance;
    }
    else {
      entity = new Cms.SimpleHeaderModule();
    }
    setEntity(entity)
  }, [])

  // user wants to cancel
  const onCancel = () => {
    closeDialog();
  }

  // user wants to save
  const onSave = async(formResult:any) => {
    setBusy(true);
    const updatedEntity = formResult.merge(entity);
    
    if(formResult.changeset.countries) {
      // TODO we need to do this because formResult.merge does not seem to handle arrays correctly
      updatedEntity.countries = formResult.changeset.countries;
    }

    //return;
    await PC.upsertModule(updatedEntity);
    // close dialog
    closeDialog();
  }

  
  // TODO <Alert /> needs content
  return (
    <Form entity={entity} onCancel={onCancel} onSave={onSave} busy={false}>
      <Selectors.BaseProperties busy={busy} />
      
      <TextInput label="Button Text" path="elements.buttonText" placeholder="Text auf Button" disabled={busy} />
      <TextInput label="Button Url" path="elements.buttonHref" placeholder="Href auf Button" disabled={busy} />
    </Form>
  )
}


type SimpleHeaderPreviewType = {
  instance: Cms.SimpleHeaderModule,
  expanded?: boolean,
  onClickAction: (module:Cms.Module, action:"edit"|"delete"|"duplicate") => void
}
export function SimpleHeaderPreview({instance, expanded, onClickAction}:SimpleHeaderPreviewType) {
  return (
    <Preview.Container expanded={expanded} module={instance} title={Cms.SimpleHeaderModule.info.title} onClickAction={onClickAction}>
      <Preview.Table>
        <Preview.TableRow label="Button">
          <Preview.Button text={instance.elements.buttonText} href={instance.elements.buttonHref} />
        </Preview.TableRow>
      </Preview.Table>
    </Preview.Container>
  )
}