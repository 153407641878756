import React, { useContext, useEffect, useState } from "react";
// types
import * as Cms from "../../../../types/Cms";
// contexts and components
import { PageContext } from "../Page.Context";
import { Form } from "../../../controls";
import * as Selectors from "../Selectors";
import * as Preview from "../Preview";

type TestimonialProps = {
  instance?: Cms.TestimonialModule,
  closeDialog: () => void,
}

export function Testimonial({ instance, closeDialog }: TestimonialProps) {
  // context
  const PC = useContext(PageContext);

  // state
  const [entity, setEntity] = useState<Cms.TestimonialModule | null>(null);
  const [busy, setBusy] = useState<boolean>(false);

  // mount
  useEffect(() => {
    let entity: Cms.TestimonialModule;
    if (instance) {
      entity = instance;
    }
    else {
      entity = new Cms.TestimonialModule();
    }
    setEntity(entity)
  }, [])

  // user wants to cancel
  const onCancel = () => {
    closeDialog();
  }

  // user wants to save
  const onSave = async (formResult: any) => {
    setBusy(true);
    const updatedEntity = formResult.merge(entity);

    if (formResult.changeset.countries) {
      // TODO we need to do this because formResult.merge does not seem to handle arrays correctly
      updatedEntity.countries = formResult.changeset.countries;
    }

    await PC.upsertModule(updatedEntity);
    // close dialog
    closeDialog();
  }


  // render
  return (
    <Form entity={entity} onCancel={onCancel} onSave={onSave} busy={false}>
      <Selectors.BaseProperties busy={busy} />
    </Form>
  )
}


type TestimonialPreviewProps = {
  instance: Cms.TestimonialModule,
  expanded?: boolean,
  onClickAction: (module: Cms.Module, action: "edit" | "delete" | "duplicate") => void
}
export function TestimonialPreview({ instance, expanded, onClickAction }: TestimonialPreviewProps) {
  return (
    <Preview.Container expanded={expanded} module={instance} title={Cms.TestimonialModule.info.title} onClickAction={onClickAction}>
    </Preview.Container>
  )
}
