import React from "react";
import {Link} from "react-router-dom";

import CSS from "./LinkButton.module.scss";

type LinkButtonProps = {
  children:React.ReactNode|React.ReactNode[],
  to: string,
  target?: "_self"|"_blank"|"_parent"|"_top",
  size?: "small"|"medium",
  disabled?: boolean,
  download?: string // filename if to is data:....
}

/**
 * Renders a link as a button
 * @param {LinkButtonProps} props 
 */
export function LinkButton({children, to, size, disabled, target, download} : LinkButtonProps) {
  // defaults
  target = target || "_self";
  size = size || "small";
  disabled = disabled || false;

  // pre-render
  const content = (
    <div className={`${CSS.container} ${disabled ? CSS.disabled : ""} ${CSS[size]}`}>
      <div className={CSS.inner}>
        {children}
      </div>
    </div>
  );

  // render
  if(disabled) {
    return content;
  }
  else {
    if(to.startsWith("http") || to.startsWith("localhost") || to.startsWith("sms")) {
      return <a href={to} target={target} rel="noopener noreferrer">{content}</a>
    }
    else if (to.startsWith("data")) {
      return <a href={to} download={download}>{content}</a>
    }
    else {
      return <Link to={to} target={target}>{content}</Link>
    }
  }
}
