import React, { useContext, useEffect, useState } from "react";
import * as DA from "../../../types/DataAccess";
import Config from './../../../config'
import { ProviderContext } from "./Provider.Context";
import { Button, Card, List, ListColumn, Loader, DriveLink, DriveUpload, DriveDelete, Form, TextInput, Validators, TextArea, Validation, Modal } from "../../controls";


const columns: ListColumn[] = [
  {
    header: "Name",
    path: "driveTitle",
    sort: (a, b) => a.driveTitle.localeCompare(b.driveTitle),
    print: (v:DA.ProviderDocument) => <DriveLink driveId={v.driveId!}>{v.driveTitle}</DriveLink>
  },
  {
    header: "Notizen",
    path: "notes",
    sort: (a:DA.ProviderDocument, b:DA.ProviderDocument) => a.notes.localeCompare(b.notes),
    print: (v:DA.ProviderDocument) => v.notes.split('\n').map((s, i) => <div key={i}>{s}</div>)
  }
];

export default function ProviderDocuments() {
  // context
  const PC = useContext(ProviderContext);
  // state
  const [items, setItems] = useState<DA.ProviderDocument[]>([]);
  const [modalAction, setModalAction] = useState<"create"|"update"|"delete"|undefined>();
  const [modalParam, setModalParam] = useState<DA.ProviderDocument|null>(null);
  const [modalTitle, setModalTitle] = useState<string>("");

  // mount
  useEffect(() => {
    load();
  }, [PC.provider._id]);

  // loads items
  const load = async() => {
    if(PC.provider && PC.provider._id) {
      const items = await DA.ProviderDocumentRepository.findByProviderId(PC.provider._id);
      setItems(items);
    }
  }

  // user wants to create
  const onClickCreate = () => {
    setModalTitle("Dokument erfassen");
    setModalParam(null);
    setModalAction("create");
  }

  // user wants to edit
  const onClickEdit = (doc:DA.ProviderDocument) => {
    setModalTitle("Dokument bearbeiten");
    setModalParam(doc);
    setModalAction("update");
  }

  // user wants to delete
  const onClickDelete = (doc:DA.ProviderDocument) => {
    setModalTitle("Dokument löschen");
    setModalParam(doc);
    setModalAction("delete");
  }

  // closes dialog
  const closeDialog = () => setModalAction(undefined);

  
  // pre-render modal dialog
  let modalContent = null;
  switch(modalAction) {
    case "create":
      modalContent = <Create loadList={load} closeDialog={closeDialog} />
      break;
    case "update":
      modalContent = <Edit document={modalParam!} loadList={load} closeDialog={closeDialog} />
      break;
    case "delete":
      modalContent = <Delete document={modalParam!} loadList={load} closeDialog={closeDialog} />
      break;
  }
  // render
  return <>
    <Card title="Dokumente" collapsible={true}>
      <List 
        columns={columns} 
        items={items} 
        getKey={item => item._id} 
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
        onClickCreate={onClickCreate}
        hideSearch 
        hideFooter 
      />
    </Card>
    <Modal isOpen={modalAction !== undefined} title={modalTitle} onClose={closeDialog}>
      {modalContent}
    </Modal>
  </>
}

type CreateProps = {
  loadList: () => void,
  closeDialog: () => void,
}
function Create({loadList, closeDialog}:CreateProps) {
  // context
  const PC = useContext(ProviderContext);
  // state 
  const [isBusy, setIsBusy] = useState(false);
  const [title, setTitle] = useState<string>("");
  const [titleValid, setTitleValid] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>("");
  const [extension, setExtenion] = useState<string>("");
  
  const onCancel = () => {
    closeDialog();
  }

  const onChangeTitle = (value:string, validation:Validation ) => {
    setTitle(value);
    setTitleValid(validation.valid);
  }

  const onFileSelected = (e:any) => {
    // a local file was selected
    if(e.source === "local") {
      const file = e.file;
      // get the extension (we make that the input's postfix)
      const filenameParts = file.name.split('.');
      let extension = "";
      if(filenameParts.length > 1) {
        extension = '.' + filenameParts[filenameParts.length - 1];
      }
      setExtenion(extension);
    }

    // a drive file was selected
    if(e.source === "drive") {
      // let file = e.file
      // We do not support this yet
    }
  }

  const onUploadStarted = () => {
    setIsBusy(true);
  }

  const onUploadFailed = () => {
    setIsBusy(false);
    alert("Upload fehlgeschlagen");
  }

  const onUploadComplete = async(file:any) => {
    // create the document entry
    const driveId = file.id;
    const document = DA.ProviderDocumentRepository.make();
    document.provider_id = PC.provider._id!;
    document.driveId = driveId;
    document.driveTitle = title + extension;
    document.notes = notes;
    await DA.ProviderDocumentRepository.create(document);
    // reload list and pop dialog
    loadList();
    closeDialog();
  }


  // pre-render
  let content = null;
  if(isBusy) {
    content = <Loader />;
  }
  else {
    content = (
      <DriveUpload 
        clientId={Config.drive.clientId}
        folder={Config.drive.providerDocsRootFolder.id}
        subfolderName={PC.provider._id}
        labelUpload="Dokument speichern"
        rename={() => title + extension}
        describe={() => "Dokument für " + PC.provider.name}

        onUploadStarted={onUploadStarted}
        onUploadComplete={onUploadComplete}
        onUploadFailed={onUploadFailed}
        onFileSelected={onFileSelected}
        
        disableUpload={!titleValid}
        disableDriveFiles={true}
      >
        <TextInput value={title} onChange={onChangeTitle} label="Titel" validate={Validators.isRequired("Bitte Name für das File eingeben")} />
        <TextArea value={notes} onChange={setNotes} label="Notizen" />
        <Button onClick={onCancel} size="small" intent="cancel">abbrechen</Button>

      </DriveUpload>
    );
  }
  // render
  return content;
}

type EditProps = {
  document: DA.ProviderDocument,
  loadList: () => void,
  closeDialog: () => void,
}
function Edit({document, loadList, closeDialog}: EditProps) {
  // state
  const [entity] = useState<any>(document);

  const onCancel = () => {
    closeDialog();
  }

  const onSave = async(formResult:any) => {
    if(formResult.changeset.notes) {
      await DA.ProviderDocumentRepository.update(document._id!, formResult.changeset);
      loadList();
    }
    closeDialog();
  }

  return (
    <Form entity={entity} onCancel={onCancel} onSave={onSave}>
      <TextArea label="Notizen" path="notes" />
    </Form>
  ) 
}

type DeleteProps = {
  document: DA.ProviderDocument,
  loadList: () => void,
  closeDialog: () => void,
}
function Delete({document, loadList, closeDialog}: DeleteProps) {
  // state
  const [isBusy, setIsBusy] = useState(false);
  
  const onCancel = () => {
    closeDialog();
  }

  const onFileDeleted = async() => {
    setIsBusy(true);
    await DA.ProviderDocumentRepository.remove(document._id!);
    loadList();
    closeDialog();
  }

  const onFileDeleteFailed = () => {
    alert("Das Dokument konnte nicht gelöscht werden");
    closeDialog();
  }


  // pre-render
  let content = null;
  if(isBusy) {
    return <Loader />;
  }
  else {
    content = (
      <DriveDelete 
        labelDelete="löschen"
        clientId={Config.drive.clientId}
        fileId={document.driveId!}
        onDeleteCompleted={onFileDeleted}
        onDeleteFailed={onFileDeleteFailed}
        onCancel={onCancel}
      >
        <Button onClick={onCancel} size="small" intent="cancel">abbrechen</Button>&nbsp;
      </DriveDelete>
    );
  }
  // render
  return content;
}