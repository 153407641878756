import React, { useContext } from "react";
import Config from '../../../config'
import * as TS from "../../../types";

import { Button, Card, DriveDelete } from "../../controls";
import { VendorInvoicesContext, VendorInvoiceExtended } from "./VendorInvoices.Context";

type DeleteInvoiceProps = {
  invoiceToDelete: VendorInvoiceExtended,
  closeDialog: () => void,
}
export default function DeleteInvoice({invoiceToDelete, closeDialog}: DeleteInvoiceProps) {
  // context
  const VIC = useContext(VendorInvoicesContext);

  // attempt to delete the file from drive failed
  const onFileDeleteFailed = async(info:any) => {
    let invoice_id = info.data._id
    await TS.VendorInvoice.removeById(invoice_id);
    // TODO instead of going straight back to the list we might want to display a link to the file that could not be displayed
    VIC.reloadDataset();
    closeDialog();
  }

  // successfully deleted file from drive
  const onFileDeleted = async(info:any) => {
    // once a file is deleted from drive we also delete the db entry
    let invoice_id = info.data._id;
    await TS.VendorInvoice.removeById(invoice_id);
    VIC.reloadDataset();
    closeDialog();
  }

  return (
    <Card>
      {`Rechnung von '${invoiceToDelete.provider.name}' über CHF ${invoiceToDelete.amount.toFixed(2)} löschen.`}
      <div style={{display:"flex", flexDirection:"row", paddingTop:"16px"}}>
        <Button size="small" intent="cancel" onClick={closeDialog}>abbrechen</Button>
        <DriveDelete 
          labelDelete="löschen"
          clientId={Config.drive.clientId}
          fileId={invoiceToDelete.driveId}
          onDeleteCompleted={onFileDeleted}
          data={invoiceToDelete}
          onCancel={closeDialog}
          onDeleteFailed={onFileDeleteFailed}>{null}</DriveDelete>
      </div>
    </Card>
  )
}