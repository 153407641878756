import React, {useState, useEffect} from "react"
import _ from "lodash"
import Api from "../../../../util/api";

import {Form, Checkbox, Loader, TextInput, TextArea, Card} from "../../../controls"

import "./Message.scss"

function Message({propertyKey, title}) {
  // state
  const [data, setData] = useState(null)
  const [busy, setBusy] = useState(true)

  // mount
  useEffect(() => {
    if(propertyKey && title) {
      load()
    }
  }, [propertyKey, title])

  const load = async() => {
    // get data from API
    const allprops = await Api.get('surpriseproperties')

    // prepare data
    let data_default = {key:propertyKey, value:{title:"", message:"", show:false}}
    let data_existing = allprops.find(p => p.key === propertyKey)
    if(data_existing && data_existing.value) {
      data_default.value= {
        // standard
        title: data_existing.value.title || '',
        message: data_existing.value.message || '',
        show: data_existing.value.show || false
      }
    }
    // update state
    setData(data_default)
    setBusy(false)

  }

  // save
  const onSave = async(result) => {
    setBusy(true)

    // gather data to save
    let updated_data = {}
    _.merge(updated_data, data)
    _.merge(updated_data, result.changeset)

    // save 
    await Api.put('surpriseproperties', updated_data, "key")

    // done
    setData(updated_data)
    setBusy(false)
  }

  // render
  let content = null
  if(busy || data === null) {
    content = <Loader />
  }
  else {
    content = (
      <Form entity={data} onSave={onSave} onCancel={() => {}} labelSave="speichern" labelCancel="zurücksetzen">
        <TextInput path="value.title" label="Titel" />
        <TextArea path="value.message" label="Mitteilung" />
        <Checkbox path="value.show" label="Anzeigen" secondaryLabel="auf der Einlöseseite anzeigen" />
      </Form>
    )
  }
  return (
    <Card title={title}>
      {content}
    </Card>
  )
}

export default Message

