import React, { useState } from "react";
// import Code from "../Code";
// import Notes from "../Notes";
import { Card, MapLink, Heading } from "../../../controls";

export default function MapLinkDemo() {
  return <>
    <Heading>MapLink</Heading>
    
    <Card>
      <div>
        <MapLink lat={46.91880131681693} lng={7.436271416095318} zoom={8}>Gurten (Zoom: 8)</MapLink>
      </div>
      <div>
        <MapLink lat={46.91880131681693} lng={7.436271416095318} zoom={2}>Gurten (Zoom: 2)</MapLink>
      </div>
    </Card>
  </>
}


