import React, {useContext} from "react";
import Util from "../../../../../util/util";
import {SurpriseContext} from "../../Surprise.Context";
import {Alert, Link, Table} from '../../../../controls';
import {ShippingStatus, CoverletterLinks} from "../../Common";
import {Activities, ActivityPackages, Address, Text, Doner, Row} from "../Items";
import CSS from "./Ordered.Status.module.scss";

export default function Ordered() {
  // context 
  const SC = useContext(SurpriseContext);
  
  // render
  return (
    <div className={CSS.container}>
      <Table fontSize="small">
        <Doner />
        <Row label="Bestelldatum">{Util.printDateAndTime(SC.surprise.CreateDate)}</Row>
        <Activities />
        <ActivityPackages />
        <Text label="Versandart" path="shippingInfo.packaging.name" placeholder="unbekannte Versandart" />
        <Text label="Bestellkommentar" path="surprise.Order.Comments" placeholder="-" />
        <Row label="Gutschein PDF"><TicketLinks ticketGroup={SC.shippingInfo.tickets.pdf} /></Row>
        <Row label="Gutschein HTML"><TicketLinks ticketGroup={SC.shippingInfo.tickets.html} /></Row>
        <Row label="Gutschein Karte"><TicketLinks ticketGroup={SC.shippingInfo.tickets.card} /></Row>
        <Row label="Begleitbrief">
          <CoverletterLinks coverletters={SC.shippingInfo.coverletters} isDirectShipping={SC.shippingInfo.addresses.areEqual === false} />
        </Row>
        <Address label="Versandadresse" lines={SC.shippingInfo.addresses.shipping} />
        <Address label="Rechnungsadresse" lines={SC.shippingInfo.addresses.invoice} />
        <Row label="Versandstatus"><ShippingStatus /></Row>
      </Table>
      <div className={CSS.image}>
        <img src={SC.shippingInfo.packaging.imageUrl} alt={SC.shippingInfo.packaging.name}/>
      </div>
    </div>
  );
}

function TicketLinks({ticketGroup}:any) {
  return <><Link to={ticketGroup.primary.url} target="_blank">{ticketGroup.primary.label}</Link> (oder <Link to={ticketGroup.secondary.url} target="_blank">{ticketGroup.secondary.label}</Link>)</>
}
