import React from "react"
import { Button, Card, Heading } from "../controls"
import "./Logout.scss";

function Logout({onLoggedOut}) {
  const onClick = (e) => {
    window.localStorage.removeItem('appentura_token')
    window.localStorage.removeItem('appentura_token_exp')
    window.localStorage.removeItem('appentura_user')
    onLoggedOut()
  }
  
  return (
    <div id="v-logout">
      <Heading>LOGOUT</Heading>
      <Card>
        <Button onClick={onClick}>Klicken zum ausloggen</Button>
      </Card>
    </div>
  )

}
export default Logout