import { ProviderLocation } from "../ProviderLocation/ProviderLocation";

export interface Provider {
  // properties
  _id?: string;
  name: string;
  isActive: boolean; // default: true
  notes: string;
  colorRating: "green"|"orange"|"red"|null; // default: null;
  booking: ProviderBookingInfo[]; // TODO while the schema supports multiple (i.e. an array of) 'booking' items the front-end currently only offers one ... hence the booking will always overwrite the booking[0] item
  addresses: ProviderAddress[]; //
  contacts: ProviderContact[]; //
  links: ProviderLink[]; // = [];
  bankAccounts: ProviderBankAccount[]; // = [];
  collectiveInvoice: ProviderCollectiveInvoice;
  //locations: ProviderLocation[]; // = [];
}

export type ProviderBookingInfoKind = "email"|"web"|"phone";

export interface ProviderBookingInfo {
  _id: string;
  kind: ProviderBookingInfoKind;
  value: string; // url, email, phone number ...
  notes?: string;
  order: number; // default: 1
}

export interface ProviderLink {
  _id: string|undefined;
  title: string;
  url: string;
}

export interface ProviderCollectiveInvoice {
  isActive: boolean; // default: false
  email: string|null;
  currency: "CHF"|"EUR";
}

export interface ProviderBankAccount {
  _id?: string;
  bankName: string;
  accountNumber: string;    // bank account number (IBAN or ESR Teilnehmernummer)
  currency: string;         // ISO 4217 currency code
  isActive: boolean; // default: true
  isDefault: boolean; // default: false;
}

export type ProviderContactKind = "allgemein"|"meteo";

export interface ProviderContact {
  _id?: string;
  email: string;
  firstName: string;
  fix: string;
  function: string;
  kind: ProviderContactKind; // default: "allgemein"; 
  lastName: string;
  mobile: string;
  notes: string;
  title: string;
  url: string;
}

export enum ProviderAddressKind {
  Billing = "billing",
  Location = "location"
}

export const ProviderAddressKindLabels = {
  [ProviderAddressKind.Billing]: "Rechnungsadresse",
  [ProviderAddressKind.Location]: "Standortadresse"
}

export interface ProviderAddress {  
  _id?: string;
  city: string;
  companyName: string;
  country: string;
  countryCode: string;
  firstName: string;
  houseNumber: string;
  kind: ProviderAddressKind; // default: ProviderAddressKind.Location;
  lastName: string;
  notes: string;
  street: string;
  supplement: string; // things like 'c/o', or 'dept.'
  zip: string;
}