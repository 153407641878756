import { useEffect, useState } from "react";
import * as DA from "../../../types/DataAccess";
import * as BL from "../../../types/BusinessLogic";
import { Dropdown, DropdownOption, Td, Tr } from "../../controls";
import { FormData } from "./VendorInvoice";

type PaymentTypeProps = {
  formData: FormData,
  paymentTypes: DA.PaymentType[],
  onChange: (paymentType: DA.PaymentType|null) => void
}
export default function PaymentType({formData, paymentTypes, onChange} : PaymentTypeProps) {
  // state
  const [options, setOptions] = useState<DropdownOption[]>([]);
  
  // mount / paymentKind changed
  useEffect(() => {
    if(formData.paymentTypeKind) {
      const filteredTypes = paymentTypes.filter(opt  => opt.kind === formData.paymentTypeKind);
      const options: DropdownOption[] = filteredTypes.map(item => {
        return {
          label: `${item.accountingId}-${item.name} [${BL.PaymentType.printKind(item.kind)}]`,
          value: item._id
        } 
      });
      setOptions(options);
      if(formData.isUpdate === false) {
        if(formData.paymentTypeKind === "bank") {
          // payment via bank get their payment type set when we move it to "Zahlungsausgang"
          onChange(null);
        }
        else {
          onChange(filteredTypes[0]);
        }
      }
    }
  }, [formData.paymentTypeKind])


  // render null if not creditcard or employee
  if(formData.paymentTypeKind !== "employee" && formData.paymentTypeKind !== "creditcard") {
    return null;
  }
  
  // render
  return (
    <Tr>
      <Td label>Zahlungsmittel</Td>
      <Td>
      <Dropdown
        options={options}
        value={formData.paymentType ? formData.paymentType._id : ""}
        onChange={(id) => {
          const paymentType = paymentTypes.find(o => o._id === id);
          if(paymentType) {
            onChange(paymentType);
          }
        }}
      />
      </Td>
    </Tr>
  )
}
