import React from "react";
import DashboardPanel from "./DashboardPanel";
import { Button, Loader, Text, Table, Tr, Td } from '../../controls';
import iconCoins from './../../../assets/images/icon_coins.svg'

type SalesProps = {
  data:any, dataStatus:any, requestData:any
}
export default function Sales({data, dataStatus, requestData} : SalesProps) {
  const loadData = async() => {
    await requestData();
  }

  // prepare content to render
  let content = null;
  if(dataStatus === "notloaded") {
    content = (
      <>
        <Text>Daten wurden noch nicht geladen.</Text> <Button size="small" onClick={loadData}>Daten laden</Button>
      </>
    )
  }
  else if(dataStatus === "loading") {
    content = <Loader />
  }
  else {
    let sales = data;
    let row = (heading:string, data:any, key:string) => {
      return (
        <Tr key={key}>
          <Td label>{heading}</Td>
          <Td align="right">{data.count}</Td>
          <Td align="right">{data.sales.toFixed(0)}</Td>
          <Td align="right">{(data.count === 0 ? 0 : data.sales / data.count).toFixed(0)}</Td>
        </Tr>
      )
    }
    content = (
      <Table>
        <Tr>
          <Td></Td>
          <Td label align="right">Verkäufe</Td>
          <Td label align="right">Wert</Td>
          <Td label align="right">ø</Td>
        </Tr>
        {row("diesen Monat", sales.month, "month")}
        {row("dieses Jahr", sales.year, "year")}
        {row("total", sales.allTime, "total")}
      </Table>
    )
  }

  // render
  return (
    <DashboardPanel title="Umsatz" smallOnDesktop={true} smallOnMobile={false} icon={iconCoins} iconRounded={false}>
      {content}
    </DashboardPanel>
  )
}