import _ from "lodash";
import React, {useEffect, useState, useContext} from "react"
// types
import * as TS from "../../../types";
// components and contexts
import { Alert, Loader, Value, Form, TextInput, TextArea, Validators, CardSection, Modal } from "../../controls";
import { ActivityPackageContext } from "./ActivityPackage.Context";

/*
type TextFieldDefintion = {
  label:string, 
  path:string, 
  placeholder:string, 
  explanation?: string, 
  rows?:number,
  validate?:(s:string) => any, 
}
*/

export default function Texts() {
  // state 
  const [mode, setMode] = useState<"edit"|"view">("view");
  /*
  const [fieldsToEdit] = useState<TextFieldDefintion[]>([
    {label:"Titel", path:"title", validate:Validators.isRequired("bitte Titel eingeben"), placeholder:"noch kein Titel"},
    {label:"URL-Erweiterung", path:"slug", explanation: "URL-Erweiterung der Aktivität, z.B. dinner-in-the-dark (nur Buchstaben, Zahlen und Bindestriche)", validate:Validators.isSlug(), placeholder:"noch keine URL-Erweiterung"},
    {label:"kurze Beschreibung", path:"shortDescription.de", rows:2, placeholder:"noch keine Kurzbeschreibung"},
    {label:"lange Beschreibung", path:"longDescription.de", rows:4, placeholder:"noch keine Beschreibung"},
  ]);
  */

  // context
  const AC = useContext(ActivityPackageContext);

  // user wants to edit an item
  const onClickEdit = () => {
    if(AC && AC.activityPackage) {
      setMode("edit");
    }
  }

  // closes dialog
  const closeDialog = () => setMode("view");

  // saves changes
  const save = async(): Promise<TS.ResultOld> => {
    if(AC && AC.activityPackage) {
      const result:TS.ResultOld = await AC.save();
      return result;
    }
    else {
      return new TS.ResultOld(false, {message:"not ready", name:""}, null)
    }
  }

  // no data? render loader
  if(!AC || !AC.activityPackage) {
    return <Loader />
  }

  // render
  return <>
    <CardSection title="Texte">
      <Value onClick={onClickEdit}>
        <Value label="Titel">{AC.activityPackage.title}</Value>
        <Value label="Url-Erweiterung">{AC.activityPackage.slug}</Value>
        <Value label="kurze Beschreibung"><Paragraphs text={AC.activityPackage.shortDescription} /></Value>
        <Value label="lange Beschreibung"><Paragraphs text={AC.activityPackage.longDescription} /></Value>
      </Value>
    </CardSection>
    <Modal isOpen={mode==="edit"} title={`Aktivitäts-Paket - ${AC.activityPackage.title}`} onClose={closeDialog}>
      <Edit activityPackage={AC.activityPackage} save={save} closeDialog={closeDialog} />
    </Modal>
  </>
}

// TODO make this a control component
type ParagraphsProps = {
  text:string
}
function Paragraphs({text}:ParagraphsProps) {
  const paragraphs = text.split('\n').map((p:string, index:number) => <p key={index}>{p}</p>);
  return <>
    {paragraphs}
  </>
}


type EditProps = {
  activityPackage: TS.ActivityPackage,
  save:() => Promise<TS.ResultOld>,
  closeDialog: VoidFunction
}
function Edit({activityPackage, save, closeDialog}:EditProps) {
  // state
  const [entity, setEntity] = useState({title:"", slug:"", shortDescription:"", longDescription:""});
  const [error, setError] = useState<string|undefined>();
  const [busy, setBusy] = useState(false);

  // mount
  useEffect(() => {
    setEntity({title:activityPackage.title, slug:activityPackage.slug, shortDescription:activityPackage.shortDescription, longDescription:activityPackage.longDescription});
  }, []);

  // save the update
  const onSave = async(formResult:any) => {
    setBusy(true);
    // update props
    activityPackage.title = formResult.changeset.title || activityPackage.title;
    activityPackage.slug = formResult.changeset.slug || activityPackage.slug;
    activityPackage.shortDescription = formResult.changeset.shortDescription || activityPackage.shortDescription;
    activityPackage.longDescription = formResult.changeset.longDescription || activityPackage.longDescription;
    // attempt to save
    const result = await save()
    if(result.success) {
      closeDialog();
    }
    else {
      setEntity(formResult.merge(entity));
      setBusy(false);
      setError(result.error ? result.error.message : "unbekannter Fehler");
    }
  }

  // cancel
  const onCancel = () => {
    closeDialog()
  }

  // pre-render
  let errorAlert = null;
  if(error) {
    errorAlert = <Alert intent="error" title="Fehler beim Speichern" size="medium">{error}</Alert>
  }

  // render
  return (
    <Form 
      entity={entity} 
      onCancel={onCancel} 
      onSave={onSave}
      busy={busy}
    >
      <TextInput 
        label="Titel" 
        path="title" 
        disabled={busy} 
        validate={Validators.isRequired("Name eingeben")} 
        explanation="wird auf der Webseite als Titel des Pakets verwendet" 
      />
      <TextInput 
        label="Url-Erweiterung" 
        path="slug" 
        disabled={busy} 
        validate={Validators.isSlug()} 
        explanation="z.B. 'geheim-agent'" 
      /> 
      <TextArea 
        label="kurze Beschreibung"
        path="shortDescription"
        disabled={busy}
        validate={Validators.isRequired("bitte eine kurze Beschreibung eingeben")}
        showCharacterCount={true}
        rows={2}
      />
      <TextArea 
        label="lange Beschreibung"
        path="longDescription"
        disabled={busy}
        validate={Validators.isRequired("bitte eine lange Beschreibung eingeben")}
        showCharacterCount={false}
        rows={6}
      />
      {errorAlert}
    </Form>
  );
}