import React, {useContext, useState, useEffect} from "react";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";
import { Form, Checkbox, TextInput, Validators } from "../../controls";

type UsersEditProps = {
  user?: DA.User,
  onUpdate: () => void,
  onCancel: () => void,
}
export default function UsersEdit({user, onUpdate, onCancel} : UsersEditProps) {
  // state
  const [busy, setBusy] = useState(false);
  const [entity, setEntity] = useState<any>();

  // mount
  useEffect(() => {
    if(user) {
      setEntity(user);
    }
    else {
      setEntity(DA.UserRepository.make());
    }
  }, [])

  // user wants to save
  const onSave = async(formResult:any) => {
    setBusy(true);
    if(entity._id) {
      // update
      const changeset = formResult.changeset;
      await DA.UserRepository.update(entity._id, changeset);
    }
    else {
      // create
      const newUser = formResult.merge(entity);
      await DA.UserRepository.create(newUser);
    }
    onUpdate();
    setBusy(false);
  }

  // no entity? no render
  if(!entity) {
    return null;
  }


  // render
  return (
    <div id="v-region-edit">
      <Form 
        entity={entity} 
        onSave={onSave} onCancel={onCancel}
        busy={busy}
      >
        <TextInput label="E-Mail" placeholder="milly@appentura.ch" path="email" validate={Validators.isAppenturaEmail()} />
        <TextInput label="Vorname" placeholder="Milly" path="firstName" validate={Validators.isRequired("Bitte Vornamen eingeben")} />
        <TextInput label="Name" placeholder="Malone" path="lastName" validate={Validators.isRequired("Bitte Namen eingeben")} />
        <TextInput label="Slack Id" placeholder="ABCDE12345" path="slackId" />
        <Checkbox label="Superuser" path="isSuperuser" />
        <Checkbox label="Aktiv" path="isActive" />
      </Form>
    </div>
  )
}