import React, {useContext, useState, useEffect} from "react";
import _ from "lodash";

import { FormContext } from "./FormContext";
import FieldValidation from "./FieldValidation";
import { Label } from ".";
import { Validation } from "./Validation";

type ProxyProps = {
  path: string,
  children: React.ReactNode|React.ReactNode[],
  proxiedValue: any,
  validate?: (v:any) => Validation,
  label?: string,
  className?: string
}
export function Proxy({path, children, proxiedValue, validate, label, className}: ProxyProps) {
  // state
  const [id] = useState(Date.now);
  const [value, setValue] = useState(null);
  const [validation, setValidation] = useState<Validation>(new Validation(true));

  // context
  const form = useContext(FormContext);

  // mount
  useEffect(() => {
    if(form.entity && path) {
      let v = _.get(form.entity, path); // TODO what if no value is found?
      setValue(v);
    }
  }, [form.entity, setValue, path])

  // form.resetIndex changed -> reset the item
  useEffect(() => {
    if(form.resetIndex !== 0) {
      setValue(form.getOldValue(path))
    }
  }, [form.resetIndex, path, form])

  // proxied value changes
  useEffect(() => {
    let value = proxiedValue;
    let validation = validate ? validate(value) : new Validation(true);
    form.onFieldChange(path, value, validation);
    setValue(value);
    setValidation(validation);
  }, [proxiedValue])

  // render 
  return (
    <div className={className || ""}>
      {label ? <Label label={label}></Label> : null}
      {children}
      <FieldValidation validation={validation} />
    </div>
  )
}
