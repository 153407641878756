import _ from "lodash";
import React, { useContext, useState } from "react";
import * as TS from "../../../types";
import { Blurhash } from "react-blurhash";
import { ActivityContext } from "./ActivityContext";
import { CardSection, Icon, Icons, ConfirmButton, Button, Link, Alert, Loader, Modal, Form, CloudStorageUploader, TextInput, Checkbox, Circle, Label, BlurhashCreator, AsyncImage } from "../../controls";


import CSS from "./Activity.Images.module.scss";

export default function Images() { 
  // state
  const [imageToEdit, setImageToEdit] = useState<TS.ActivityImage|null>(null);

  // context
  const AC = useContext(ActivityContext)!;

  // image uploaded
  const onUploadComplete = async(imageUrl:string) => {
    await AC.addImage(imageUrl);
  }

  // updates image
  const updateImage = async(image:any) => {
    await AC.updateImage(image);
    stopEdit();
  }

  // deletes image
  const deleteImage = async(image:any) => {
    await AC.deleteImage(image);
  }

  const stopEdit = () => {
    setImageToEdit(null);
  }

  const startEdit = (image?:TS.ActivityImage) => {
    if(!image) {
      image = new TS.ActivityImage();
    }
    setImageToEdit(image)
  }

  // user wants to create blurhash
  const onClickCreateBlurhash = async(image: TS.ActivityImage) => {
    const blurhash = await BlurhashCreator.create(image.url);
    image.blurhash = blurhash;
    updateImage(image);
  }

  // not ready? display loader
  if(AC.activity === null) {
    return <Loader text="Lade Bilddaten ..." />
  }

  // pre-render
  const images = AC.activity.images.sort((a,b) => a.default && !b.default ? -1 : 1).map(image => {
    const isDefault = image.default ? <div className={CSS.default}><Icon icon={Icons.Star} /></div> : null;
    let blurhash = null;
    if(image.blurhash) {
      blurhash = (
        <div className={CSS.blurhash}>
          <div className={CSS.blurhash_small}>
            <Blurhash hash={image.blurhash} width={18} height={18} resolutionX={32} resolutionY={32} punch={1} />
          </div>
          <div className={CSS.blurhash_large}>
            <Blurhash hash={image.blurhash} width={160} height={160} resolutionX={32} resolutionY={32} punch={1} />
          </div>
        </div>
      );
    }
    else {
      blurhash = <Button size="small" onClick={() => onClickCreateBlurhash(image)}>Blurhash erzeugen</Button>
    }
    return (
      <div key={image._id} className={CSS.activity_image} >
        <div className={CSS.image}>
          <Link target="_blank" to={image.url}>
            <AsyncImage src={image.url} alt={image._id} blurhash={image.blurhash} showInfo className={CSS.image} />
            {isDefault}
          </Link>
        </div>
        {blurhash}
        
        <div className={CSS.actions}>
          <ConfirmButton onConfirm={() => deleteImage(image)}><Icon icon={Icons.Trash} /></ConfirmButton>
          <Button onClick={() => startEdit(image)} size="small"><Icon icon={Icons.Edit} /></Button>
        </div>
      </div>
    )
  })
  // render
  return <>
    <CardSection title="Bilder">
      <div className={CSS.container}>
        {images}
        <div className={CSS.activity_add}>
          <Circle onClick={() => startEdit()}><Icon icon={Icons.Plus} /></Circle>
        </div>
      </div>
    </CardSection>
    <Modal 
      isOpen={imageToEdit !== null}
      title={imageToEdit && imageToEdit._id ? "Bild bearbeiten" : "Bild hinzufügen"}
      onClose={stopEdit}
    >
      <ImageEdit image={imageToEdit!} onCancel={stopEdit} updateImage={updateImage} />
    </Modal>
  </>
}



type ImageEditProps = {
  image: TS.ActivityImage,
  updateImage: (image:TS.ActivityImage) => void,
  onCancel: () => void,
}
function ImageEdit({image, updateImage, onCancel} : ImageEditProps) {
  // state
  const [entity] = useState({
    url: image.url,
    blurhash: image.blurhash || "",
    seo: {de: image.seo.de, en: image.seo.en}, 
    source: image.source,
    default:image.default?true:false
  });
  
  // form save
  const save = (formResult: any) => {
    const updatedEntity = formResult.merge(entity);
    image.seo.de = updatedEntity.seo.de;
    image.source = updatedEntity.source;
    image.default = updatedEntity.default;
    image.url = updatedEntity.url;
    image.blurhash = updatedEntity.blurhash;
    updateImage(image);
  }

  // render
  return (
    <div>
      <Form entity={entity} onSave={save} onCancel={onCancel} labelSave="speichern" labelCancel="abbrechen">
        <CloudStorageUploader kind="image" label="Bild" path="url" pathBlurhash="blurhash" folder="activities" disableUrlEditing={true} />
        <TextInput label="SEO" path="seo.de" />
        <TextInput label="Quelle" path="source" />
        <Checkbox label="Default" secondaryLabel="ist default" path="default" />
      </Form>

      <Alert title="Keine Bilder mit Text" intent="warning" size="small">
        Keine Bilder mit integriertem Text hochladen. Im Shop werden noch weitere visuelle Elemente über den Aktivitätsbildern angezeigt (z.B. 'Neu', oder Button um zu Favoriten hinzuzufügen). Enthält das Bild Text, besteht die Gefahr, dass der Text überlagert wird.
      </Alert>

      <Alert title="Bildformat und Quellenangabe" intent="info" size="small">
        <ul>
          <li>Bilder müssen quadratisch sein</li>
          <li>Bilder sollten mindestes 512px x 512px und nicht grösser als 1024px x 1024px Pixel sein</li>
          <li>zur Bildbearbeitung kann <Link to="https://picresize.com" target="_blank">PicResize.com</Link> verwendet werden</li>
          <li>Originalbild bitte in Drive ablegen</li>
          <li>im Feld Quelle angeben, woher das Bild stammt (z.B. Pixabay, Pexels, etc.) und wo das Bild im Drive abgelegt ist (Link zum Bild)</li>
        </ul>
      </Alert>
    </div>
  )
}
