import _ from "lodash";
import React, {useState, useEffect} from "react";
import * as TS from "../../../types";
import * as BL from "../../../types/BusinessLogic";
import Util from "../../../util/util";
// components & subviews
import {Alert, Breadcrumb, Button, Card, Heading, Icon, Icons, Link, LinkButton, Loader, Table, Td, Tr, Value} from "../../controls";
// styling
import "./IncidentProcessing.List.scss";


export default function IncidentProcessing() {
  // state
  const [adventures, setAdventures] = useState<BL.IncidentManagementAdventure[]|null>(null);
  const [recent, setRecent] = useState<BL.IncidentManagementAdventure[]|null>(null);
  const [numberOfItems] = useState(10);

  // mount
  useEffect(() => {
    load(1);
  }, []);

  // loads data
  const load = async(maxRating:number) => {
    const adventures = await BL.IncidentManagement.getAdventuresToProcess(maxRating || 1, numberOfItems);
    const recent = await BL.IncidentManagement.getRecentlyProcessedAdventures(5);
    setAdventures(adventures);
    setRecent(recent);
  }

  // pre-render
  let content = null;
  if(_.isNil(adventures) || _.isNil(recent)) {
    content = <Loader text="lade Überraschungen..." />
  }
  else {
    // alert
    let alert;
    if(adventures.length === 0) {
      alert = <Alert size="small" intent="info" title="Keine Überraschung zu verarbeiten">Es sind keine Überraschungen vorhanden, die noch nicht verarbeitet wurden.</Alert>
    }
    else {
      alert = <Alert size="small" intent="info">Es werden nur die neuesten {numberOfItems} bewerteten Überraschungen angezeigt</Alert>
    }

    // assemble
    content = <>
      <Filter load={load} />
      <Card id="v-incidentprocessing-list" title="Überraschungen mit Kundenbewertung">
        <List adventures={adventures} />
        {alert}
      </Card>

      <Card id="v-incidentprocessing-recent" title="Kürzlich verarbeitet" collapsible={true} expanded={false}>
        <List adventures={recent} />
      </Card>
    </>
  }
  // render
  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}]} location="Bewertungen verarbeiten" />
    <Heading>Bewertungen verarbeiten</Heading>
    {content}
  </>
}

type FilterProps = {
  load: (maxRating:number) => void;
}
function Filter({load}: FilterProps) {
  // state
  const [maxRating, setMaxRating] = useState(1);
  
  // rating changed by user
  const onChangeMaxRating = (maxRating:number) => {
    setMaxRating(maxRating);
  }

  // apply filter
  const onClickFilter = () => {
    load(maxRating);
  }

  // render
  const stars = [1, 2, 3, 4, 5].map(r => {
    const icon = maxRating >= r ? Icons.StarFull : Icons.StarEmpty;
    return <Icon key={r} icon={icon} onClick={() => onChangeMaxRating(r)} />
  });
  return <Card id="v-incidentprocessing-list-filter" title="Filter">
    <Value label="maximale Sterne in einer der drei Bewertungen (Allgemein, Ablauf, Anbieter">
      <div className="stars">{stars}</div>
    </Value>
    <Button size="small" onClick={onClickFilter}>anzeigen</Button>
  </Card>
}

type ListProps = {
  adventures: BL.IncidentManagementAdventure[];
}
function List({adventures}: ListProps) {
  // items
  const rows = (adventures || []).map(a => {
    return <tr key={a._id}>
      <Td className="surprise-link">
        {`${a.reserverName} für ${a.receiverName} [${Util.shortObjectId(a._id, 0, 4)}]`}
        <Link to={`/surprise/${a._id}`} target="_blank">&nbsp;<Icon icon={Icons.ExternalLink} />&nbsp;</Link>
      </Td>
      <Td>{a.rating.overall}</Td>
      <Td>{a.rating.planning}</Td>
      <Td>{a.rating.provider}</Td>
      <Td>{a.rating.comment || ""}</Td>
      <Td>
        <LinkButton size="small" to={`/incidents/process/${a._id}`}><Icon icon={Icons.Edit} /></LinkButton> 
      </Td>
    </tr>
  })
  if(rows.length > 0) {
    let table = <Table>
        <Tr>
          <Td label>Überraschung</Td>
          <Td label colSpan={5}>Bewertung</Td>
        </Tr>
        <Tr className="secondary">
          <Td></Td>
          <Td secondaryLabel>Allgemein</Td>
          <Td secondaryLabel>Ablauf</Td>
          <Td secondaryLabel>Anbieter</Td>
          <Td secondaryLabel>Kommentar</Td>
          <Td></Td>
        </Tr>
      
        {rows}
    </Table>
    return table;
  }
  return null;
}
