import _ from "lodash";
import React, { useContext, useState } from "react";
import { SurpriseContext } from "../Surprise.Context";
import { CardSection, Modal, Value } from "../../../controls";
import GenericEdit from "../Generic.Edit";


export default function Comments() {  
  // render
  return (
    <CardSection title="Kommentare">
      <Comment label="Bestell-Kommentar" path="Order.Comments" />
      <Comment label="Termin-Kommentar" path="ScheduleComments" />
      <Comment label="Bestätigungs-Kommentar" path="AcceptComments" />
      <Comment label="Anlass" path="Order.Occasion" />
    </CardSection>
  );
}

type CommentProps = {
  label: string,
  path: string
}
function Comment({label, path} : CommentProps) {
  // context
  const SC = useContext(SurpriseContext);
  // state
  const [mode, setMode] = useState<"view"|"edit">("view");

  // edit button even handler
  const onEdit = () => {
    setMode("edit");
  }
  // stops edit
  const stopEdit = () => setMode("view");

  // items to render
  const comment = _.get(SC.surprise, path) || "";
  const commentLines = comment.split("\n").map((line:string, index:number) => <p key={index}>{line}</p>);
    
  // render
  return <>
    <Value label={label} size="small" onClick={onEdit}>{commentLines}</Value>
    <Modal isOpen={mode==="edit"} title={`${label} bearbeiten`} onClose={stopEdit}>
      <GenericEdit path={path} label={label} kind="textarea" stopEdit={stopEdit} />
    </Modal>
  </> 
}