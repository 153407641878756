import React, {useContext, useState} from "react";
import {ConfirmButton} from "../../../../controls";
import {SurpriseContext} from "../../Surprise.Context";

export default function PriorityFlag() {
  // context
  const SC = useContext(SurpriseContext);

  // state
  const [busy, setBusy] = useState(false);

  // event handler
  const togglePriority = async() => {
    setBusy(true);
    SC.updateAtPath("HasPriority", !SC.surprise.HasPriority, false);
    setBusy(false);
  }

  // render
  const hasPriority = SC.surprise.HasPriority
  return (
    <>
      <div className="subheading">Prioritätsflag</div>
      <div>
        Manche Leute haben etwas Pech und versuchen zum Teil 4-5 Mal die Überraschung einzulösen. Das Wetter war jeweils schlecht, der Anbieter ausgebucht etc. 
      </div>
      <div>
        Solche Fälle sollten prioritär behandelt werden. Hier können sie entsprechend markiert werden.
      </div>
      <div>
        <ConfirmButton onConfirm={togglePriority} busy={busy}>{hasPriority ? "Flag entfernen" : "Flag setzen"}</ConfirmButton>
      </div>
    </>
  )
}
