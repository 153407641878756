import React, {useContext, useState} from "react";
import { Alert, ConfirmButton } from "../../../../controls";
import { SurpriseContext } from "../../Surprise.Context";

export default function ResetSteps() {
  // context
  const SC = useContext(SurpriseContext);

  // state
  const [busy, setBusy] = useState(false);

  // event handler
  const resetStepIndices = async() => {
    setBusy(true);
    await SC.updateAtPath("CurrentStep", 0, false);
    await SC.updateAtPath("MaxStep", 0, false)
    setBusy(false);
    window.location.reload();
  }

  // render
  return (
    <>
    <div className="subheading">Schritt-Indizes zurücksetzen</div>
    <div>
      Setzt den Index des gegenwärtigen (<code>CurrentStep</code>) und des "höchsten" (<code>MaxStep</code>) Schrittes auf 0.
      <Alert intent="info" size="small">
        <ul>
          <li><code>CurrentStep: {SC.surprise.CurrentStep}</code></li>
          <li><code>MaxStep: {SC.surprise.MaxStep}</code></li>
        </ul>
      </Alert>
      <div>
        <ConfirmButton onConfirm={resetStepIndices} busy={busy}>Schritt-Indizes zurücksetzen</ConfirmButton>
      </div>
    </div>
    </>
  )
}