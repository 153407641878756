import React, { useEffect, useState } from "react";
import { DriveUploadHelper } from "./DriveUpload.Helper";
// components
import { Button } from ".";
// styling
import CSS from "./DriveUpload.module.scss";

type DriveUploadProps = {
  clientId: string,
  folder: string,
  subfolderName?: string,

  disableDriveFiles?: boolean,
  disableLocalFiles?: boolean,
  disableUpload?: boolean,

  labelUpload: string,

  onFileSelected?: (info:{file:any, source:"local"|"drive"}) => void,
  onUploadStarted?: () => void,
  onUploadComplete?: (file:any) => void,
  onUploadFailed?: (err:any) => void,

  rename?: (s:string) => string,
  describe?: (s:string) => string,

  children?: React.ReactNode|React.ReactNode[]
}
export function DriveUpload({ clientId, folder, subfolderName, rename, describe, disableDriveFiles, disableLocalFiles, disableUpload, onFileSelected, onUploadStarted, onUploadComplete, onUploadFailed, labelUpload, children } : DriveUploadProps) {
  // default values
  labelUpload = labelUpload || "File hochladen";
  // state
  const [signedIn, setSignedIn] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<any|null>(null);
  const [source, setSource] = useState<"local"|"drive">("local");
  const [access_token, setAcccess_token] = useState<string>("");

  // mount
  useEffect(() => {
    loadScript();
  }, []);
  
  // loads client
  const loadScript = () => {
    // note: we have the script included in index.html
    (window as any).gapi.load('client:auth2', initScript);
    // load the picker
    (window as any).gapi.load('picker', () => {});
  }

  // inits script
  const initScript = () => {
    // Google Drive API Stuff
    let DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v2/rest"]; // Array of API discovery doc URLs for APIs used by the quickstart
    let SCOPES = "https://www.googleapis.com/auth/drive"; // Authorization scopes required by the API; multiple scopes can be included, separated by spaces. We give full access

    // callback function used by the api to inform about signin status
    let updateSigninStatus = (signedIn:any) => { 
      let googleAuth = (window as any).gapi.auth2.getAuthInstance();
      let currentUser = googleAuth.currentUser.get();
      let authResponse = currentUser.getAuthResponse();
      setSignedIn(signedIn);
      setAcccess_token(authResponse.access_token);
      //this.setState({...this.state, signedIn, access_token:authResponse.access_token})
      
    }

    // init the gapi client
    (window as any).gapi.client.init({
      discoveryDocs: DISCOVERY_DOCS,
      clientId: clientId,
      scope: SCOPES
    }).then(function () {
      // Listen for sign-in state changes.
      (window as any).gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
      // Handle the initial sign-in state.
      updateSigninStatus((window as any).gapi.auth2.getAuthInstance().isSignedIn.get())
    });
  }

  // authorize with google dirve
  const onAuthorize = () => {
    (window as any).gapi.auth2.getAuthInstance().signIn()
  }

  // signout from google drive
  /*
  const onSignOut = () => {
    (window as any).gapi.auth2.getAuthInstance().signOut();
  }
  */

  // local file selected
  const onLocalFileSelected = (e:any) => {
    const selectedFile = e.target.files[0];
    const source = "local";
    setSource(source);
    setSelectedFile(selectedFile);
    if(onFileSelected) {
      onFileSelected({source: source, file: selectedFile});
    }
  }

  // drive file selected
  const onDriveFileSelected = (data:any) => {
    if (data[(window as any).google.picker.Response.ACTION] === (window as any).google.picker.Action.PICKED) {
      const selectedFile = data[(window as any).google.picker.Response.DOCUMENTS][0];
      const source = "drive";
      setSource(source);
      setSelectedFile(selectedFile);
      if(onFileSelected) {
        onFileSelected({source: source, file: selectedFile});
      }
    }
  }

  // user wants to upload file
  const onUploadButtonClicked = (e:any) => {
    switch(source) {
      case 'drive':
        moveDriveFile();
        break
      case 'local':
      default:
        uploadLocalFile();
        break
    }
  }

  const moveDriveFile = () => {
    // scope a few values
    const file = selectedFile;
    const fileId = selectedFile.id;
    const addParents = folder;
    let title = selectedFile.title;
    if(rename) {
      title = rename(selectedFile.title);
    }

    // get the file to get its parent folders and then remove those and add the target folder
    if(onUploadStarted) {
      onUploadStarted()
    }
    (window as any).gapi.client.drive.files.get({fileId})
      .then((response:any) => {
        // get the current parents, so we can remove them
        let removeParents = response.result.parents.map((p:any) => p.id).join(',');

        // move the file by updating its parents
        (window as any).gapi.client.drive.files.update({fileId, addParents, removeParents, title, fields:'id, parents'})
          .then((response:any) => {
            if(onUploadComplete) {
              onUploadComplete(file);
            }
          })
          .catch((err:any) => {
            if(onUploadFailed) {
              onUploadFailed(err)
            }
            
          })
      })
      .catch((err:any) => {
        if(onUploadFailed) {
          onUploadFailed(err)
        }
      })
  }

  // uploads a local file
  const uploadLocalFile = () => {
    // some constants needed to enable multipart upload
    const boundary = '-------314159265358979323846';
    const delimiter = "\r\n--" + boundary + "\r\n";
    const close_delim = "\r\n--" + boundary + "--";

    // set / change file title
    let fileTitle = selectedFile.name;
    if(rename) {
      fileTitle = rename(selectedFile.name);
    }
    // set / change file description
    let description = ''
    if(describe) {
      description = describe(selectedFile.name)
    }

    // scope a few thing
    // let onUploadStarted = this.props.onUploadStarted || (() => {})
    // let onUploadComplete = this.props.onUploadComplete || (() => {})
    // let onUploadFailed = this.props.onUploadFailed || (() => {})
    
    //let folderId =

    // we might have to create a subfolder
    let targetFolderPromise
    if(subfolderName) {
      targetFolderPromise = createFolder(subfolderName, folder)
    }
    else {
      targetFolderPromise = new Promise((resolve) => { resolve({folderId:folder})})
    }

    // run the target folder promise
    targetFolderPromise
      .then((result:any) => {
        let folderId = result.folderId;
        // prepare the reader
        let reader = new FileReader();
        reader.onload = (e:any) => {
          if(onUploadStarted) {
            onUploadStarted();
          }
          // https://developers.google.com/drive/v2/reference/files/insert
          let contentType = selectedFile.type || 'application/octet-stream';
          let metadata:any = {
            'title':fileTitle,
            'mimeType':contentType,
            'description':description
          }
          if(folderId) {
            metadata.parents = [{'id':folderId}]
          }

          let base64data = btoa(reader.result as string);

          let multipartRequestBody =
            delimiter +
            'Content-Type: application/json\r\n\r\n' +
            JSON.stringify(metadata) +
            delimiter +
            'Content-Type: ' + contentType + '\r\n' +
            'Content-Transfer-Encoding: base64\r\n' +
            '\r\n' +
            base64data +
            close_delim;

          let request = (window as any).gapi.client.request({
            'path': '/upload/drive/v2/files',
            'method': 'POST',
            'params': {'uploadType': 'multipart'},
            'headers': {
              'Content-Type': 'multipart/mixed; boundary="' + boundary + '"'
            },
            'body': multipartRequestBody});

          request.execute((file:any) => {
            let success = true // TODO hardcoded, how to recognize failed uploads?
            if(success) {
              if(onUploadComplete) {
                onUploadComplete(file);
              }
            }
            else {
              let errorInfo = {}
              if(onUploadFailed) {
                onUploadFailed(errorInfo)
              }
            }
          })

        }

        // use the reader
        reader.readAsBinaryString(selectedFile)
      })
      .catch((err:any) => {
        // TODO do something
        console.error('error:', err)
      })



  }

  const onShowPicker = (e:any) => {
    let picker = new (window as any).google.picker.PickerBuilder()
      .addView((window as any).google.picker.ViewId.DOCS) // PDFS if only PDFs
      .setOAuthToken(access_token)
      //.setDeveloperKey(developerKey)
      .setCallback(onDriveFileSelected)
      .build()
    picker.setVisible(true);
  }

  // creates a folder in google drive
  const createFolder = (name:any, parentFolderId:any) => {
    /*
     https://developers.google.com/drive/v2/web/folder
     In the Drive API, a folder is essentially a file — one identified by the special folder MIME type application/vnd.google-apps.folder. You can create a new folder by inserting a file with this MIME type and a folder title. Do not include an extension when setting a folder title.
     */

    return new Promise((resolve, reject) => {
      // get all files in the parent folder, and try to find a folder of that name
      DriveUploadHelper.getFiles(parentFolderId)
        .then((result:any) => {
          // TODO check if result.error ...
          // find folder of the name
          let folder = result.items.find((file:any) => file.mimeType.toLowerCase() === "application/vnd.google-apps.folder" && file.title.toLowerCase() === name.toLowerCase())
          if(folder) {
            // we found a folder of that name ... hurray
            resolve({folderId:folder.id})
          }
          else {
            // no folder of that name found ... SAD!
            let request = (window as any).gapi.client.request({
              'path': '/drive/v2/files', //'/drive/v2/files',
              'method': 'POST',
              'headers': {
                'Content-Type': 'application/json'
              },
              'body': {
                'title': name,
                'mimeType': 'application/vnd.google-apps.folder',
                'parents': [{'id': parentFolderId}]
              }
            });
            request.execute((response:any) => {
              if(!response.error) {
                let folderId = response.id
                resolve({folderId:folderId})
              }
              else {
                reject({error:response.error})
              }
            })
          }
        })
        .catch((err:any) => {
          console.error('error:', err) // TODO do something
        })
    })
  }

  /*
  const getFiles = (folderId:any) => {
    return new Promise((resolve, reject) => {
      let request = (window as any).gapi.client.drive.files.list({
        'q': `trashed=false and '${folderId}' in parents`
      })
      request.execute((response:any) => {
        if(!response.error) {
          resolve({items:response.items})
        }
        else {
          reject({error:response.error})
        }
      })
    })
  }
  */

  // pre-render
  let selectedFileLink = null;
  if(selectedFile) {
    if(source === "local") {
      selectedFileLink = <span>{selectedFile.name} (lokal)</span>
    }
    else {
      selectedFileLink = (
        <a href={selectedFile.url} target="_blank" rel="noopener noreferrer">
          <span>{selectedFile.name} (Drive)</span>
        </a>
      ) 
    }
  }
  
  let buttonSignIn = <Button onClick={onAuthorize} size="small">autorisieren</Button>
  let buttonSelectId = `filepicker_${Date.now()}`;
  let buttonSelectLocalFile = null
  if(!disableLocalFiles) {
    buttonSelectLocalFile = (
      <div className={CSS.select}>
        <input type="file" onChange={onLocalFileSelected} id={buttonSelectId} />
        <label htmlFor={buttonSelectId}>Lokales File wählen</label>
      </div>
    )
  }
  let buttonSelectDriveFile = null
  if(!disableDriveFiles) {
    buttonSelectDriveFile = (
      <div className={CSS.select} onClick={onShowPicker}>
        <label>File aus Drive wählen</label>
      </div>
    )
  }
  //let uploadDisabled = !this.props.uploadEnabled
  let buttonUpload = <Button size="small" onClick={onUploadButtonClicked} disabled={disableUpload}>{labelUpload}</Button>

  let selectedFileDiv = null;
  if(selectedFileLink) {
    selectedFileDiv = (
      <div className={CSS.selectedFile}>
        {selectedFileLink}
      </div>
    )
  }
  return (
    <div className={CSS.container}>
      {signedIn ? null : buttonSignIn}
      <div className="selectButtons">
        {signedIn ? buttonSelectLocalFile : null}&nbsp;
        {signedIn ? buttonSelectDriveFile : null}
      </div>
      {selectedFileDiv}
      {children}
      {selectedFile ? buttonUpload : null}
    </div>
  )
}

/*

!!! allowDriveFiles -> disableDriveFiles
!!! allowLocalFiles -> disableLocalFiles
!!! uploadEnabled -> disableUpload
!!! uploadDisabled ????

      */