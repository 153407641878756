import { ProviderDocumentMapper } from "./ProviderDocument.Mapper";
import { ProviderDocument } from "./ProviderDocument";
import { RepositoryFactory } from "../_Common/RepositoryFactory";

export class ProviderDocumentRepository {
  private static mapper = new ProviderDocumentMapper();
  
  // from factory
  static make = RepositoryFactory.make<ProviderDocument, ProviderDocumentMapper>(this.mapper);
  static search = RepositoryFactory.search<ProviderDocument, ProviderDocumentMapper>(this.mapper);
  static create = RepositoryFactory.create<ProviderDocument, ProviderDocumentMapper>(this.mapper);
  static update = RepositoryFactory.update<ProviderDocument, ProviderDocumentMapper>(this.mapper);
  static remove = RepositoryFactory.remove<ProviderDocument, ProviderDocumentMapper>(this.mapper);
  static findById = RepositoryFactory.findById<ProviderDocument, ProviderDocumentMapper>(this.mapper);

  /**
   * returns all locations for a given provider
   * @param providerId 
   * @returns {Promise<ProviderDocument[]>}
   */
  static findByProviderId = async(providerId:string) : Promise<ProviderDocument[]> => {
    const locations = await this.search({provider_id:providerId});
    return locations;
  } 
}

