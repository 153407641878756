import environment  from './environment'

// Note: The OAuth2 Client Id is set a teamappentura@gmail.com's GoogleAPI developer console: https://console.developers.google.com/apis/credentials?project=appentura-1299
//const oAuthClientId = "515295587884-snm57r4a8qu869jfqlelvit7s86no37c.apps.googleusercontent.com"
const oAuthClientId = "1001489985222-hoohc709llujj9d5b13v4hht8q8188vv.apps.googleusercontent.com"

const config = {
  development: {
    oAuthClientId:oAuthClientId
  },
  staging: {
    oAuthClientId:oAuthClientId
  },
  production: {
    oAuthClientId:oAuthClientId
  }
}

export default config[environment]