
import { useContext, useEffect } from "react";
import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { SortableListContext, SortableListProvider } from "./SortableList.Context";
import CSS from "./SortableList.module.scss";

type SortableListProps = {
  children: React.ReactNode[],
  disabled?: boolean,
  noItemsText?: string,
  onDrop: (id:string, targetIndex:number) => void
}
export function SortableList({children, disabled, noItemsText, onDrop} : SortableListProps) {

  return (
    <SortableListProvider>
      <Content children={children} disabled={disabled} onDrop={onDrop} noItemsText={noItemsText} />
    </SortableListProvider>
  )
}

function Content({children, disabled, noItemsText, onDrop} : SortableListProps) {
  // context
  const SLC = useContext(SortableListContext);

  // disabled changes
  useEffect(() => {
    SLC.setDisabled(disabled || false);
  }, [disabled]);

  // pre-render items
  const items:React.ReactNode[] = [];
  for(let i=0; i<children.length; i++) {
    if(i===0) {
      items.push(<Target onDrop={onDrop} index={0} key={`target_${i}`} />);
    }
    items.push(children[i]);
    items.push(<Target onDrop={onDrop} index={i + 1} key={`target_${i+1}`} />);
  }

  // no items?
  let noItems = noItemsText && items.length === 0 ? <div>{noItemsText}</div> : null;

  // render
  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <div className={CSS.list}>
          {noItems}
          {items}
        </div>
      </DndProvider>
    </div>
  )
}

type TargetProps = {
  index:number,
  onDrop: (id:string, targetIndex:number) => void
}
function Target({index, onDrop} : TargetProps) {
  const [collectedProps, refDrop] = useDrop(() => (
    {
      accept:"BOX",
      drop: (item:any, monitor) => {
        onDrop(item.id, index);
      },
      collect: (monitor:any) => {
        return {
          isOver: monitor.isOver()
        }
      }
    }
  ))

  return (
    <div ref={refDrop} className={`${CSS.target} ${collectedProps.isOver ? CSS.hover : ""}`}>
    </div>
  )
}