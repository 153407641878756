type UploadFileParams = {
  selectedFile: File,
  fileTitle: string,
  description?: string,
  folderId: string,
  subfolderName?: string,
  onUploadStarted?: () => void
}

type MoveFileParams = {
  fileTitle: string,
  fileId: string,
  folderId: string,
  description?: string,
  onUploadStarted?: () => void,
}

// uploads a local file
export class DriveUploadHelper {

  static moveDriveFile = async(options: MoveFileParams): Promise<{success:boolean, fileId:string}> => {
    // scope a few values
    const fileId = options.fileId;
    const addParents = options.folderId;
    const title = options.fileTitle;
    
    // inform that upload has started
    if(options.onUploadStarted) {
      options.onUploadStarted();
    }

    try {
      // get the file to get its parent folders and then remove those and add the target folder
      let responseFile = await (window as any).gapi.client.drive.files.get({fileId});
      // get the current parents, so we can remove them
      let removeParents = responseFile.result.parents.map((p:any) => p.id).join(',');
      let responseMove = await (window as any).gapi.client.drive.files.update({fileId, addParents, removeParents, title, fields:'id, parents'});
      //console.log("responseMove", responseMove);
    }
    catch(err) {
      return {success: false, fileId: options.fileId}
    }

    return {success: true, fileId: options.fileId};
  }

  static uploadLocalFile = async(options: UploadFileParams) : Promise<{success:boolean, fileId?:string}> => {
    
    // folder id (might change if we need to create a subfolder)
    let folderId = options.folderId; 

    // we might have to create a subfolder
    if(options.subfolderName) {
      // TODO try catch
      const subfolderResult = await DriveUploadHelper.createFolder(options.subfolderName, options.folderId);
      folderId = subfolderResult.folderId;
    }

    // get file data the reader promise
    // TODO try catch
    try {
      const driveFile:any = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async() => {
          // send start event to listeners
          if(options.onUploadStarted) {
            options.onUploadStarted();
          }
          // attempt to read the file's data
          let base64data;
          try {
            base64data = btoa(reader.result as string);
          }
          catch(err) {
            reject(err);
          }
  
          // prepare for file upload, see: https://developers.google.com/drive/v2/reference/files/insert
          // some constants needed to enable multipart upload
          const boundary = '-------314159265358979323846';
          const delimiter = "\r\n--" + boundary + "\r\n";
          const close_delim = "\r\n--" + boundary + "--";
          let contentType = options.selectedFile.type || 'application/octet-stream';
          let metadata:any = {
            'title': options.fileTitle,
            'mimeType': contentType,
            'description': options.description || ""
          }
          if(folderId) {
            metadata.parents = [{'id':folderId}]
          }
          let multipartRequestBody =
            delimiter +
            'Content-Type: application/json\r\n\r\n' +
            JSON.stringify(metadata) +
            delimiter +
            'Content-Type: ' + contentType + '\r\n' +
            'Content-Transfer-Encoding: base64\r\n' +
            '\r\n' +
            base64data +
            close_delim;
          let request = (window as any).gapi.client.request({
            'path': '/upload/drive/v2/files',
            'method': 'POST',
            'params': {'uploadType': 'multipart'},
            'headers': {
              'Content-Type': 'multipart/mixed; boundary="' + boundary + '"'
            },
            'body': multipartRequestBody});
  
          // execute upload
          request.execute((file:any) => {
            if(file.error) {
              console.error(file);
              console.error(file.error);
              reject(file.error);
            }
            else {
              resolve(file);
            }
          })
        }
  
        // use the reader
        reader.readAsBinaryString(options.selectedFile)
      });
      // done
      return {success:true, fileId:driveFile.id};
    }
    catch(err) {
      // done with error
      return {success:false};
    }
  

  }


  // creates a folder in google drive
  static createFolder = (name:any, parentFolderId:any) : Promise<any> =>  {
    /*
     https://developers.google.com/drive/v2/web/folder
     In the Drive API, a folder is essentially a file — one identified by the special folder MIME type application/vnd.google-apps.folder. You can create a new folder by inserting a file with this MIME type and a folder title. Do not include an extension when setting a folder title.
     */

    //let getFiles = getFiles;

    return new Promise((resolve, reject) => {
      // get all files in the parent folder, and try to find a folder of that name
      DriveUploadHelper.getFiles(parentFolderId)
        .then((result:any) => {
          // TODO check if result.error ...
          // find folder of the name
          let folder = result.items.find((file:any) => file.mimeType.toLowerCase() === "application/vnd.google-apps.folder" && file.title.toLowerCase() === name.toLowerCase())
          if(folder) {
            // we found a folder of that name ... hurray
            resolve({folderId:folder.id})
          }
          else {
            // no folder of that name found ... SAD!
            let request = (window as any).gapi.client.request({
              'path': '/drive/v2/files', //'/drive/v2/files',
              'method': 'POST',
              'headers': {
                'Content-Type': 'application/json'
              },
              'body': {
                'title': name,
                'mimeType': 'application/vnd.google-apps.folder',
                'parents': [{'id': parentFolderId}]
              }
            });
            request.execute((response:any) => {
              if(!response.error) {
                let folderId = response.id
                resolve({folderId:folderId})
              }
              else {
                reject({error:response.error})
              }
            })
          }
        })
        .catch((err:any) => {
          console.error('error:', err) // TODO do something
        })
    })
  }

  static getFiles = (folderId:any) => {
    return new Promise((resolve, reject) => {
      let request = (window as any).gapi.client.drive.files.list({
        'q': `trashed=false and '${folderId}' in parents`
      })
      request.execute((response:any) => {
        if(!response.error) {
          resolve({items:response.items})
        }
        else {
          reject({error:response.error})
        }
      })
    })
  }

}