/*
We use Search.ch Timetable API to get platform information for public transport connections.
Documentation:
- https://timetable.search.ch/api/help
- https://timetable.search.ch/api/help.de.html#stationboard specifically
*/

import Util from "../util/util";

async function getConnections(nameOfStation:string, when:Date) {
  // note to self: transportation_types	| optional |	Erlaubte Transportmittel, komma-separiert (train,tram,bus,ship,cableway)
  const url = encodeURI(`https://fahrplan.search.ch/api/stationboard.json?stop=${nameOfStation}&date=${Util.printDate(when)}&time=${Util.printTime(when)}&limit=10&show_tracks=1`);
  const response = await fetch(url, {method: 'GET'});
  const data = await response.json();
  return data.connections || [];
}

async function getRoutes(fromStation:string, toStation:String, when:Date) {
  const url = encodeURI(`https://timetable.search.ch/api/route.json?from=${fromStation}&to=${toStation}&date=${Util.printDate(when)}&time=${Util.printTime(when)}`);
  const response = await fetch(url, {method: 'GET'});
  const data = await response.json();
  return data.connections || [];
}

export default {
  getConnections,
  getRoutes
}

/* getConnections() example result :
connections: [
  {
    *G: "S"
    *L: "8"
    color: "039~fff~"
    line: "S8"
    number: "S8 8067"
    operator: "RBS"
    terminal: {
      id: "8508059"
      lat: 47.047605
      lon: 7.507791
      name: "Jegenstorf"
      x: 605255
      y: 2
    }
    id: "8508059"
    lat: 47.047605
    lon: 7.507791
    name: "Jegenstorf"
    x: 605255
    y: 210733
    __proto__: Object
    time: "2020-09-10 06:52:00"
    track: "24"
    type: "strain"
    type_name: "S-Train"

  }
]
*/