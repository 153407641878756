import _ from "lodash";
import React, {useContext, useState} from "react";
import Util from "../../../../util/util";
import {SurpriseContext} from "../Surprise.Context";
import { Value, Table, Tr, Td, MapLink, CardSection, Modal, Alert } from "../../../controls";
// controls & subviews
import GenericEdit from "../Generic.Edit";
import UserStartLocationEdit from "./UserStartLocation.Edit";

function Override() {
  // state
  const [mode, setMode] = useState<"view"|"start"|"end"|"location">("view");
  // context
  const SC = useContext(SurpriseContext);
  
  // event handlers
  const editStart = () => setMode("start");
  const editEnd = () => setMode("end");
  const editStartLocation = () => setMode("location");
  const stopEdit = () => setMode("view");

  // pre-render
  let modalContent = null;
  let modalTitle = "";
  switch(mode) {
    case "start":
      modalContent = <GenericEdit path="StartTime" label="Start" kind="datetimeinput" stopEdit={stopEdit} />;
      modalTitle = "Start-Zeit bearbeiten";
      break;
    case "end":
      modalTitle = "Ende-Zeit bearbeiten";
      modalContent = <GenericEdit path="EndTime" label="End" kind="datetimeinput" stopEdit={stopEdit} />;
      break;
    case "location":
      modalTitle = "Start-Ort bearbeiten";
      modalContent = <UserStartLocationEdit stopEdit={stopEdit} />
      break;
  }
  
  // render
  let startLocation = SC.surprise.UserStartLocation || {Lat:0, Lng:0};
  let startLocationText = SC.surprise.UserStartLocationText || "-";
  return <>
    <CardSection title="Werte übersteuern">
      <Alert intent="info" size="small">
        Manchmal ist es notwendig, den Start oder das Ende zu übersteuern. Das kannst du hier tun ohne dabei den Planungsvorschlag zu ändern.
      </Alert>
      <Table>
        <Tr>
          <Td>
            <Value label="Start" onClick={editStart}>
              {_.isNil(SC.surprise.StartTime) ? "noch keine Startzeit" : Util.printDateAndTime(SC.surprise.StartTime)}
            </Value>
          </Td>
          <Td>
            <Value label="Ende" onClick={editEnd}>
              {_.isNil(SC.surprise.EndTime) ? "noch keine Ende-Zeit" : Util.printDateAndTime(SC.surprise.EndTime)}
            </Value>
          </Td>
        </Tr>
        <Tr>
          <Td colSpan={2}>
            <Value label="Start-Ort" onClick={editStartLocation}>
              <MapLink lat={startLocation.Lat} lng={startLocation.Lng} zoom={8}>{startLocationText}</MapLink>
            </Value>
          </Td>
        </Tr>
      </Table>
    </CardSection>
    <Modal isOpen={mode !== "view"} title={modalTitle} onClose={stopEdit}>
      {modalContent}
    </Modal>
  </>
}

export default Override