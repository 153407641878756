import { PaymentTypeMapper } from "./PaymentType.Mapper";
import { PaymentType, PaymentTypeKind } from "./PaymentType";
import { RepositoryFactory } from "../_Common/RepositoryFactory";


export class PaymentTypeRepository {
  private static mapper = new PaymentTypeMapper();
  
  // from factory
  static search = RepositoryFactory.search<PaymentType, PaymentTypeMapper>(this.mapper);
  static create = RepositoryFactory.create<PaymentType, PaymentTypeMapper>(this.mapper);
  static update = RepositoryFactory.update<PaymentType, PaymentTypeMapper>(this.mapper);
  static remove = RepositoryFactory.remove<PaymentType, PaymentTypeMapper>(this.mapper);

  /**
   * Creates a new payment type object
   * @param kind 
   * @returns 
   */
  static make(kind:PaymentTypeKind): PaymentType {
    const paymentType: PaymentType = {
      _id: undefined,
      name: "",
      kind: kind,
      accountingId: "0",
      isActive: true,
      bank: null,
      creditcard: null,
      employee: null
    }
    switch(kind) {
      case "bank": 
        paymentType.bank = { name: "", iban: "", bic: "", currency: "" };
        break;
      case "employee": 
        paymentType.employee = { fullName: "" };
        break;
      case "creditcard":
        paymentType.creditcard = { cardNumber: "" };
        break;
    }
    return paymentType;
  }

  static makeFromDb(obj:any): PaymentType {
    return this.mapper.fromDb(obj);
  }

  /**
   * Finds all payment types
   * @returns {Promise<PaymentType[]>}
   */
  static async findAll(): Promise<PaymentType[]> {
    const paymentTypes = await PaymentTypeRepository.search({});
    return paymentTypes;
  }
}

