import { useContext } from "react";
import * as S from "../../../../types/AdventureSearch";
import { SearchContext } from "./Search.Context";
import { Button, Checkbox, Icon, Icons, Row } from "../../../controls";

export default function SearchColumns() {
  // context
  const Search = useContext(SearchContext);

  // user wants to select all
  const onSelectAll = () => {
    Search.toggleColumns(true);
  }
  // user wants to select none
  const onDeselectAll = () => {
    Search.toggleColumns(false);
  }

  // pre-render items
  const items = S.ResultColumns.sort((a,b) => a.header.localeCompare(b.header)).map((rc, index) => {
    let checked = Search.columns.includes(rc);
    return (
      <Checkbox key={index} value={checked} secondaryLabel={rc.header} onChange={v => Search.toggleColumn(rc, v)} />
    )
  })

  // render
  return (
    <div>
      <Row>
        <Button size="small" onClick={onSelectAll} inverted intent="ok">
          <Icon icon={Icons.Check} />
        </Button>
        <Button size="small" onClick={onDeselectAll} inverted intent="cancel">
          <Icon icon={Icons.Trash} />
        </Button>
      </Row>
      {items}
    </div>
  );
}