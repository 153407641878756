import _ from "lodash";
import React, { useState, useEffect } from "react";
// types
import * as TS from "../../../../types";
// components
import { Button } from "../../../controls";
import OutlineRoute from "./OutlineRoute";
// styling
import "./OutlineRoutes.scss";

type OutlineRoutesProps = {
  routes:Array<TS.AdventureOutlineRoute>,
  recalculateFromRoute:(route:TS.AdventureOutlineRoute) => void
}
export default function OutlineRoutes({routes, recalculateFromRoute}:OutlineRoutesProps) {
  // state
  const [ready, setReady] = useState<boolean>(false);
  const [displayedRoute, setDisplayedRoute] = useState<any|null>(null);

  // mount
  useEffect(() => {
    if(routes.length > 0) { 
      let displayedRoute = routes.find(r => r.selected) || routes[0];
      setDisplayedRoute(displayedRoute);
      setReady(true);
    }
  }, [routes]);

  // no routes? not ready? do not render anything
  if(!routes || routes.length === 0 || !ready) {
    return null;
  }

  // user wants to select a different route
  const onClickUseRoute = async() => {
    recalculateFromRoute(displayedRoute);
    /*
    if(AC) {
      await AC.recalculateOutline(displayedRoute);
      await AC.load(AC.adventureInfo.id);
    }
    */
  }

  // pre-render
  const tabItems = routes.map((route, index) => {
    
    let active = "";
    if(displayedRoute !== null) {
      active = String(displayedRoute._id) === String(route._id) ? "active" : "";
    }
    const selected = route.selected ? "selected" : "";
    return <div key={String(route._id)} className={`c-outlineroutes-tab ${active} ${selected}`} onClick={() => setDisplayedRoute(route)}>{index}</div>
  });
  
  // render
  return <div className="c-outlineroutes">
    <div className="c-outlineroutes-header">
      <div className="c-outlineroutes-tabs-title">Varianten:</div>
      <div className="c-outlineroutes-tabs">{tabItems}</div>
    </div>
    
    <div className="c-outlineroutes-content">
      <OutlineRoute route={displayedRoute} />
      <Button size="small" onClick={onClickUseRoute} disabled={displayedRoute.selected}>
        {displayedRoute.selected ? "diese Variante wird verwendet" : "diese Variante verwenden"}
      </Button>
    </div>
  </div>
}