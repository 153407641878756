import React, { useEffect } from "react";
import { Card, Link } from ".";

import CSS from "./Breadcrumb.module.scss"


type BreadcrumbProps = {
  links: {to:string, title: string}[],
  location: string,
  browserTitle?: string
}

/**
 * Displays a breadcrumb trail of the current page
 * @param props: BreadcrumbProps 
 * @returns 
 */
export function Breadcrumb({links, location, browserTitle} : BreadcrumbProps) {
  useEffect(() => {
    window.document.title = `${browserTitle || location} - ${"Appentura"}`;
  }, [browserTitle, location])
  
  // render
  return (
    <Card className={CSS.container}>
      {links.map((link, index) => <span className={CSS.link} key={index}><Link to={link.to}>{link.title}</Link></span>)}
      <span className={CSS.location}>{location}</span>
    </Card>
  )
}
