import React from "react";
import Code from "../Code";
import Notes from "../Notes";
import {Card, Heading, Icon, Icons} from "../../../controls";

export default function IconDemo() {
  // render
  const icons = [];
  for(let prop in Icons) {
    icons.push(
      <div key={prop} className="icon-demo-item">
        <Icon icon={Icons[prop]} />
        <div><Code inline>Icons.{prop}</Code></div>
      </div>
    )
  }
  return <Card>
    <Heading>Icon</Heading>
    <div className="icon-demo-items">{icons}</div>
    <Notes>
      To add more icons, edit /enumerations/Icons.Enumerations
      <ol>
        <li>import an icon</li>
        <li>add to library</li>
        <li>add entry to exported dictionary</li>
      </ol>
    </Notes>
    <Icon icon={Icons.Coffee} className="icon_example" />
    <Code>{`<Icon icon={Icons.Table} className="icon_example" />`}</Code>
    
  </Card>
}
