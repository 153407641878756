import React, {useState} from "react";
import * as Cms from "../../../types/Cms";

// the context's type
export type PageContextType = {
  // properties
  page: Cms.Page|null,
  // functions
  load: (id:string) => Promise<void>,
  update: (changeset:any) => Promise<any>,
  duplicate: () => Promise<void>,
  upsertModule: (module:Cms.Module) => Promise<any>,
  removeModule: (module:Cms.Module) => Promise<any>,
  duplicateModule: (module:Cms.Module) => Promise<any>,
  moveModule: (moduleId: string, destinationIndex: number) => Promise<any>,
  
}
// context
const PageContext = React.createContext<PageContextType>({} as PageContextType);

// provider
type PageProviderProps = {
  children: React.ReactNode|Array<React.ReactNode>
}
function PageProvider({children}: PageProviderProps) {
  // state
  const [page, setPage] = useState<Cms.Page|null>(null);

  // provider value
  const value = {
    // props
    page,

    // functions
    load: async(id:string) => {
      const page = await Cms.Page.getOneById(id);
      setPage(page);
    },
    update: async(changeset:any) => {
      if(page && page._id) {
        await Cms.Page.update(page._id, changeset);
        setPage(await Cms.Page.getOneById(page._id));
      }
    },
    duplicate: async() => {
      if(page) {
        const duplicate = await Cms.Page.duplicate(page);
        setPage(duplicate);
      }
    },
    upsertModule: async(module:Cms.Module)  =>  {
      if(page && page._id) {
        if(!module._id) {
          module.position = page.modules.length;
        }
        await Cms.Page.upsertModule(page._id, module);
        setPage(await Cms.Page.getOneById(page._id));
      }
    },
    removeModule: async(module:Cms.Module) => {
      if(page && page._id) {
        await Cms.Page.removeModule(page._id, module);
        setPage(await Cms.Page.getOneById(page._id));
      }
    },
    duplicateModule: async(module:Cms.Module) => {
      if(page && page._id) {
        await Cms.Page.duplicateModule(page._id, module);
        setPage(await Cms.Page.getOneById(page._id));
      }
    },
    moveModule: async(moduleId: string, destinationIndex: number) => {
      if(page && page._id) {
        const reloadedPage = await Cms.Page.getOneById(page._id); // TODO I don't get it either, if I use the page in the state I don't get modules created in the same session ...
        const updatedModules = reloadedPage!.modules.map(m => m).sort((a, b) => a.position - b.position);
        // get source and target indices
        const indexFrom = updatedModules.findIndex(item => item._id === moduleId); // result.source.index;
        // remove element and move to new position
        const element = updatedModules.splice(indexFrom, 1)[0]; // splice returns the removed elements as an array, since we only remove one item, we can get it from index 0
        updatedModules.splice(destinationIndex, 0, element);
        // update position property on items
        updatedModules.forEach((item, index) => item.position = index);
        // save and update state
        await Cms.Page.update(page!._id!, {modules: updatedModules});
        setPage(await Cms.Page.getOneById(page._id));
      }
    }
  }
  return (
    <PageContext.Provider value={value}>
      {children}
    </PageContext.Provider>
  )
}

export {PageContext, PageProvider}
