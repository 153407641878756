import _, { upperFirst } from "lodash";
import React, {useEffect, useState, useContext, useRef} from "react";
import Api from "../../util/api";
import { FormContext } from "./FormContext";
import { Loader, Label, Alert, PdfLink, Validation, ValidationError } from ".";
import { BlurhashCreator } from "./Util";
import { getBase64Strings } from "exif-rotate-js";
import CSS from "./CloudStorageUploader.module.scss";

type Folder = "activities"|"adventures"|"shippingtypes"|"cms"|"misc"|"charities"|"testimonials";
type Kind = "image"|"pdf";

type CloudStorageUploaderProps = {
  kind: Kind,
  folder: Folder,
  prefix?: string, 
  path?: string,
  pathBlurhash?: string, 
  value?: string,
  label?: string, 
  disableUrlEditing?: boolean,
  validate?: (v:string) => Validation,
  onChange?: (url:string, validation:Validation, blurhash?:string) => void
}
export function CloudStorageUploader({kind, folder, prefix, path, pathBlurhash, value, label, disableUrlEditing, validate, onChange}: CloudStorageUploaderProps) {
  // get form context
  const FC = useContext(FormContext);

  // if we have a form context, we render with form, if not without
  if(FC && path) {
    // warn about value not being used if provided
    if(value) {
      console.warn("since CloudStorageUploader is inside a FormContext and a 'path' was provided, the provided 'value' will be ignored");
    }
    // render the form control
    return <FormControl kind={kind} folder={folder} prefix={prefix} path={path} pathBlurhash={pathBlurhash} label={label} disableUrlEditing={disableUrlEditing} validate={validate} onChange={onChange} />
  }
  else if(value !== undefined) {
    // a value was provided
    return <Widget kind={kind} folder={folder} prefix={prefix} value={value} label={label} disableUrlEditing={disableUrlEditing} onChange={onChange} validate={validate} />
  }

  // something is missing
  return (
    <Alert intent="error" size="small" title="Implementation Error">
      Expected the path property (if control is inside a Form) or the value property.<br/>
      Inside a Form: {FC ? "yes" : "no"}
    </Alert>
  )
}


type FormControlProps = {
  kind: Kind,
  folder: Folder,
  prefix?: string, 
  path?: string, 
  pathBlurhash?: string,
  label?: string, 
  disableUrlEditing?: boolean,
  validate? : (v:any) => Validation,
  onChange?: (url:string, validation:Validation, blurhash?:string) => void
}
function FormControl({kind, folder, prefix, path, pathBlurhash, label, validate, onChange, disableUrlEditing}: FormControlProps) {
  // context
  const FC = useContext(FormContext);
  // state
  const [initialValue, setInitialValue] = useState<string>("");
  const [initialBlurhash, setInitialBlurhash] = useState<string|undefined>(undefined);

  // mount
  useEffect(() => {
    if(FC.entity && path) {
      // setting initial value
      const value = _.get(FC.entity, path); // TODO what if no value is found?
      const valueBlurhash = pathBlurhash ? _.get(FC.entity, pathBlurhash) : undefined;
      setInitialValue(value || "");
      setInitialBlurhash(valueBlurhash);
    }
  }, [FC.entity, validate, path])

  // form.resetIndex changed -> reset the item
  useEffect(() => {
    if(FC.resetIndex !== 0) {
      setInitialValue(FC.getOldValue(path) || "");
      setInitialValue(FC.getOldValue(pathBlurhash) || undefined);
    }
  }, [FC.resetIndex, path, FC])

  // handler
  const onWidgetChange = (value:string, validation:Validation, blurhash?:string) => {
    FC.onFieldChange(path, value, validation);
    FC.onFieldChange(pathBlurhash, blurhash, new Validation(true));
    if(onChange) {
      onChange(value, validation, blurhash);
    }
  }
  
  // render
  return <Widget kind={kind} folder={folder} prefix={prefix} value={initialValue} valueBlurhash={initialBlurhash} label={label} validate={validate} onChange={onWidgetChange} disableUrlEditing={disableUrlEditing} />
}



type WidgetProps = {
  kind: Kind,
  folder: Folder
  prefix?: string,
  value: string,
  valueBlurhash?:string,
  label?: string, 
  disableUrlEditing?: boolean,
  validate?: (v:any) => Validation,
  onChange?: (value:string, validation:Validation, blurhash?:string) => void,
}
function Widget({kind, folder, prefix, value, valueBlurhash, label, disableUrlEditing, validate, onChange}: WidgetProps) {
  // defaults
  disableUrlEditing = disableUrlEditing ? true : false;
  // state
  const [mode, setMode] = useState<"ready"|"done"|"reading"|"uploading">("ready");
  const [imageUrl, setImageUrl] = useState<string>(value);
  const [blurhash, setBlurhash] = useState<string|undefined>(valueBlurhash);
  const [validation, setValidation] = useState<Validation>(new Validation(true));

  // refs
  const fileInfo = useRef({
    prefix:prefix || "",
    name: "",
    size: 0,
    type: "",
    timestampName: true,
  });
  const refFileData = useRef<string|ArrayBuffer|null>(null);
  const reader = useRef(new FileReader());

  // mount
  useEffect(() => {
    if(reader && reader.current) {
      // attach event listener to file reader
      reader.current.addEventListener("load", onReaderLoad);
    }
  }, []);

  // external value changes
  useEffect(() => {
    const validation = validate ? validate(value) : new Validation(true);
    setValidation(validation);
    setImageUrl(value);
  }, [value]);

  // selected value changes
  useEffect(() => {
    // inform onChange listeners
    if(onChange) {
      onChange(imageUrl, validation, blurhash);
    }
  }, [imageUrl, validation, blurhash])

  // selected file changed
  const onFileChange = async(e:any) => {
    setMode("reading");
    const file = e.target.files[0];
    
    if(file && reader && fileInfo.current) {
      fileInfo.current = {
        prefix: prefix || "",
        name: file.name,
        size: file.size,
        type: file.type,
        timestampName: true,
      };
      if(kind === "image") {
        const data = await getBase64Strings(e.target.files, {maxSize: 1024});
        refFileData.current = data[0];
        doUpload();
      }
      else {
        reader.current.readAsDataURL(file);
      }
    }
  }

  // reader did load, upload the file
  const onReaderLoad = async() => {
    const data = reader.current.result;
    refFileData.current = data;
    doUpload();
  }

  // user changes the file url manually
  const onTextInputChange = (e:any) => {
    const v = e.target.value;
    const validation = validate ? validate(value) : new Validation(true);
    setValidation(validation);
    setImageUrl(v);
  }

  const doUpload = async () => {
    setMode("uploading");
    // upload file
    const apiEndpoint = `uploads/${kind}`;
    const payload = {data:refFileData.current, file: fileInfo.current, folder};
    const response = await Api.post(apiEndpoint, payload);
    const imageUrl = response.url;
    // create blurhash if image
    let blurhash:string|undefined;
    if(kind === "image") {
      blurhash = await BlurhashCreator.create(imageUrl);
    }
    // upload done
    const validation = validate ? validate(imageUrl) : new Validation(true);
    setValidation(validation);
    setImageUrl(imageUrl);
    setBlurhash(blurhash);
    setMode("done");
  } 

  // pre-render
  let uploadInfo = null;
  const kindDescription = kind === "image" ? "Bild" : "PDF";
  switch(mode) {
    case 'reading':
      uploadInfo = <Loader text={`lese ${kindDescription} ...`}/>;
      break;
    case 'uploading':
      uploadInfo = <Loader text={`lade ${kindDescription} hoch ...`} />;
      break;
    case 'ready':
    case 'done':
    default:   
      uploadInfo = (
        <label className={CSS.upload}>
          <input type="file" onChange={onFileChange} />
          {kindDescription} hochladen
        </label>
      );
      break;
  }
  // pre-render manual editing of url
  let urlInput = null;
  if(!disableUrlEditing) {
    urlInput = <input disabled={mode === "reading" || mode === "uploading"} type="text" value={value || ""} onChange={onTextInputChange} placeholder="Bild URL" autoComplete="off" />
  } 
  else {
    urlInput = <div>{value || "kein File vorhanden"}</div>
  }
  // pre-render image (if images was uploaded)
  let image = null;
  if(kind=="image") {
    if(imageUrl.trim().length > 0) {
      image = (
        <>
          <div className={CSS.image}><img src={imageUrl} alt="uploaded item" /></div>
        </>
      );
    }
  }
  // pre-render link to pdf (if pdf was uploaded)
  let pdf = null;
  if(kind === "pdf") {
    if(imageUrl.trim().length > 0) {
      pdf = <div className={CSS.pdfLink}>
        <PdfLink to={imageUrl}>Hochgeladenes Pdf</PdfLink>
      </div>
    }
  }
  // render
  return (
    <div className={CSS.container}>
      <Label label={label}>
        {image}
        {pdf}
        {uploadInfo}
        {urlInput}
        <ValidationError validation={validation} />
      </Label>
    </div>
  )
}
