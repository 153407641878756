import React, {useState, useEffect} from 'react';
import * as BL from "../../../types/BusinessLogic";
import Util from '../../../util/util';

import {Breadcrumb, ConfirmButton, Card, Heading, Link, Loader} from "../../controls";

export default function SanityCheck() {
  // state
  const [data, setData] = useState<BL.SanityCheckActivityResult|null>(null);
  const [busy, setBusy] = useState(false);

  // mount
  useEffect(() => {
    const load = async() => {
      setData(await BL.SanityCheck.getActivitySanity());
    };
    load();
  }, [])

  const updateActivitySalesRankings = async() => {
    setBusy(true);
    await BL.Activity.updateSalesRankings();
    setData(await BL.SanityCheck.getActivitySanity());
    setBusy(false);
  }

  // render 
  let content = null;
  if(!data) {
    content = <Loader />
  }
  else {
    // sales rankings
    const salesRankings = (
      <Card title="Verkaufsrang">
        <div>
          {`Verkaufsrang der Aktivitäten wurde das letzte mal am ${Util.printDateAndTime(data.lastSalesRankUpdate)} eruiert.`}
        </div>
        <div>
          <ConfirmButton onConfirm={updateActivitySalesRankings} busy={busy}>jetzt updaten</ConfirmButton>
        </div>
      </Card>
    );

    // tag issues
    let lis = data.tagIssues
    .sort((a,b) => {
      return a.activity.title.de.localeCompare(b.activity.title.de);
    })
    .map((issue, index) => {
      return (
        <li key={index}>
          <Link to={`/activities/${issue.activity._id}`}>{issue.activity.title.de}</Link> {issue.issue}
        </li>
      )
    })
    const tagIssues = (
      <Card title="Tags">
        {lis.length === 0 ? <div>Alles in Ordnung <span role="img" aria-label="smile">😎</span></div> : <ul>{lis}</ul>}
      </Card>
    )

    // assemble content
    content = <>{salesRankings}{tagIssues}</>
  }
  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}]} location="Datenzustand" />
    <Heading>Datenzustand Aktivitäten</Heading>
    {content}
  </>
}