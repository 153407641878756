import _ from "lodash";
import uniqid from 'uniqid';
import Api from "../../util/api2";
import { Module } from "./Module";

export interface PageResult {
  success: boolean,
  page: Page,
  errorMessage: string
}

export interface PageMetaTag {
  name: string;
  content: string;
}


export class Page {
  _id: string|undefined;
  title: string;
  path: string;
  tags: string[] = [];
  countries: string[] = [];
  isPublished: boolean = false;
  metaTags: PageMetaTag[] = [];
  hideHeader: boolean = false;
  hideFooter: boolean = false;
  modules: Module[] = [];

  constructor(title:string, path:string) {
    this.title = title;
    this.path = path;
  }

  getMetaTagContent(name:string) {
    const meta = (this.metaTags || []).find(mt => mt.name === name);
    return _.isNil(meta) ? "" : meta.content || "";
  }
  
  static async getAll(): Promise<Page[]> {
    const apiResult = await Api.post("pages", "search", {filter:{}, projection:{}});
    const pages = (apiResult.data.items || []).map((dbo:any) => Page.fromDb(dbo));
    return pages;
  }

  static async getByPublishedStatus(onlyPublished:boolean) {
    let filter = {};
    if(onlyPublished) {
      filter = {isPublished:true};
    }
    const apiResult = await Api.post("pages", "search", {filter:filter, projection:{}});
    const pages = (apiResult.data.items || []).map((dbo:any) => Page.fromDb(dbo));
    return pages;
  }

  static async getOneById(id:string): Promise<Page|null> {
    const apiResult = await Api.post("pages", "search", {filter:{_id:id}, projection:{}});
    const dbo = apiResult.data.items[0];
    if(dbo) {
      return Page.fromDb(dbo);
    }
    else {
      return null;
    }
  }

  
  static async create(title:string, path:string): Promise<PageResult> {
    let page = new Page(title, path);
    page.countries = ["DE", "CH"];

    let pageResult:PageResult = {
      success: true, page:page, errorMessage: ""
    }
    
    const payload = Page.toDb(page);
    
    const apiResult = await Api.post("pages", "create", payload);
    if(apiResult.success) {
      pageResult.page = Page.fromDb(apiResult.data.item); 
    }
    else {
      pageResult.errorMessage= apiResult.error.message; 
      pageResult.success = false;
    }

    return pageResult
  }

  static async update(id:string, changeset:any): Promise<Page> {
    const payload = {id:id, set:changeset};
    const apiResult = await Api.post("pages", "update", payload);
    // TODO should check for result.success
    const dbo = apiResult.data.item;
    const page = Page.fromDb(dbo);
    return page;
  }

  static async upsertModule(pageId:string, module:Module): Promise<Page> {
    const payload = {
      id:pageId, arrayName:"modules", item:module
    }
    const apiResult = await Api.post("pages", "upsertInArray", payload);
    // TODO should check for result.success
    const dbo = apiResult.data.item;
    const page = Page.fromDb(dbo);
    return page;
  }

  static async removeModule(pageId:string, module:Module): Promise<Page> {
    const payload = {
      id:pageId, arrayName:"modules", itemId: module._id
    }
    const apiResult = await Api.post("pages", "deleteInArray", payload);
    // TODO should check for result.success
    const dbo = apiResult.data.item;
    const page = Page.fromDb(dbo);
    return page;
  }

  static async duplicateModule(pageId:string, moduleToDuplicate:Module): Promise<Page> {
    const duplicate = _.cloneDeep(moduleToDuplicate);
    delete duplicate._id;
    duplicate.position = moduleToDuplicate.position + 0.1; // just a quick and dirty fix
    const updatedPage = await Page.upsertModule(pageId, duplicate);
    return updatedPage;
  }

  static async duplicate(page: Page) : Promise<Page> {
    const page2 = _.cloneDeep(page) as Page;
    const duplicateId = uniqid();
    page2._id = undefined;
    page2.title = `${page.title}_copy_${duplicateId}`;
    page2.path = `${page.path}_copy_${duplicateId}`;
    page2.modules.forEach(m => m._id = undefined);
    const payload = Page.toDb(page2);
    
    // TODO should maybe check for result.success
    const apiResult = await Api.post("pages", "create", payload);
    return Page.fromDb(apiResult.data.item); 
  }
  

  private static fromDb(dbo:any): Page {
    let page = new Page(dbo.title, dbo.path);

    page._id = dbo._id;
    page.countries = dbo.countries || [];
    page.isPublished = dbo.isPublished || false;
    page.hideHeader = dbo.hideHeader || false;
    page.hideFooter = dbo.hideFooter || false;
    page.metaTags = (dbo.metaTags || []).map((mt:any) => {
      return {
        name: mt.name || "",
        content: mt.content || ""
      }
    });
    page.tags = dbo.tags || [];
    page.modules = (dbo.modules || []).map((m:any) => {
      return m as Module
    })
    
    return page;
  }

  
  private static toDb(page:Page): any {
    return {
      _id: page._id,
      title: page.title,
      path: page.path,
      isPublished: page.isPublished,
      hideHeader: page.hideHeader,
      hideFooter: page.hideFooter,
      countries: page.countries,
      tags: page.tags,
      metaTags: page.metaTags.map(mt => {
        return {
          name: mt.name,
          content: mt.content
        }
      }),
      modules: page.modules.map(module => module) // TODO not sure if this works properly if we use .toDb() not only for creating a new page
    }
  }
  
}
