import surprise_event_message from "./icon_event_message_white.svg"
import surprise_event_selfie from "./icon_event_selfie_white.svg"
import surprise_event_start from "./icon_event_start_white.svg"
import surprise_event_finish from "./icon_event_finish_white.svg"
import surprise_event_activity from "./icon_event_activity_white.svg"
import appentura_white from "./appentura_white.svg";
import appentura_black from "./appentura_black.svg";
import appentura_white_staging from "./appentura_white_staging.svg";

const images = {
  logo: {
    white:appentura_white,
    black:appentura_black,
    white_staging:appentura_white_staging
  },
  surprise: {
    event: {
      activity:surprise_event_activity,
      finish:surprise_event_finish,
      message:surprise_event_message,
      selfie:surprise_event_selfie,
      start:surprise_event_start
    }
  }
}

export default images