import _ from "lodash";
import React, { useContext, useState, useEffect } from "react";
import * as TS from "../../../../types";
import * as DataAccess from "../../../../types/DataAccess";
import { Actions, Button, Icon, Icons, List, Loader, Modal, AddRemoveItem, AddRemove, CardSection } from "../../../controls";
import { ActivityContext } from "../ActivityContext";

export default function WeatherConditions() {
  // state
  const [mode, setMode] = useState<"edit"|"view">("view");

  // context
  const AC = useContext(ActivityContext)!;

  // user wants to edit
  const startEdit = () => setMode("edit");
  // stops edit mode
  const stopEdit = () => setMode("view");

  // saves changes
  const saveEdit = async(updatedConditions: TS.ActivityRegionVariantWeatherCondition[]) => {
    const changeset = {weatherConditions: updatedConditions.map(wc => wc.toDb())};
    await AC.updateRegionVariant(AC.regionVariant!._id!, changeset);
  }

  // render
  return <>
    <CardSection title="Wetterbedingungen">
      <div id="v-activity-regionvariant-weatherconditions">
        <List 
          columns={[{header:"Wetterbedingung", path:"title.de"}]} 
          items={AC.regionVariant!.weatherConditions} 
          getKey={v => v._id}
          hideHeader={true} hideFooter={true} hideSearch={true} 
        />
        <Button onClick={startEdit} size="small"><Icon icon={Icons.Edit} /> bearbeiten</Button>
      </div>
    </CardSection>
    <Modal isOpen={mode==="edit"} title="Regionsvariante - Wetterbedingungen" onClose={stopEdit}>
      <Edit conditions={AC.regionVariant!.weatherConditions} saveEdit={saveEdit} stopEdit={stopEdit} />
    </Modal>
  </>
}


type EditProps = {
  conditions: TS.ActivityRegionVariantWeatherCondition[],
  saveEdit: (conditions: TS.ActivityRegionVariantWeatherCondition[]) => void,
  stopEdit: () => void,
}
function Edit({conditions, saveEdit, stopEdit} : EditProps) {
  // state
  const [items, setItems] = useState<AddRemoveItem[]>([]);
  const [busy, setBusy] = useState<boolean>(false);
  const [catalogue, setCatalogue] = useState<DataAccess.WeatherCondition[]>([]);

  // mount
  useEffect(() => {
    const load = async() => {
      const catalogue: DataAccess.WeatherCondition[] = await DataAccess.WeatherConditionRepository.searchAll();
      const items: AddRemoveItem[] = catalogue.map(catalogueItem => {
        const selected = conditions.some(item => item.weatherCondition_id === catalogueItem._id!);
        return {
          selected,
          label: catalogueItem.title.de,
          id: catalogueItem._id!
        }
      })
      setItems(items);
      setCatalogue(catalogue);
    };
    load();
  }, []);

  // save the update
  const onSave = async() => {
    setBusy(true);
    const updatedConditions: TS.ActivityRegionVariantWeatherCondition[] = items
      .filter(item => item.selected)
      .map(item => {
        // find item in catalogue
        const catalogueItem = catalogue.find(ci => ci._id === item.id);
        // create instance of region variant weather condition
        const wc = new TS.ActivityRegionVariantWeatherCondition();
        wc.title = TS.Translatable.fromDb(catalogueItem!.title);
        wc.weatherCondition_id =catalogueItem!._id;
        // add to collection
        return wc;
      });
    await saveEdit(updatedConditions); 
    setBusy(false);
    stopEdit();
  }

  // cancel
  const onCancel = () => {
    stopEdit();
  }

  // still loading data?
  if(catalogue === null) {
    return <Loader text="lade Katalog der Wetterbedingungen" />
  }

  // render
  return (
    <div>
      <AddRemove items={items} maxHeight={300} />
      <Actions align="center" onSave={onSave} onCancel={onCancel} busy={busy} />
    </div>
  )
}
