import React, {useContext, useState, useEffect} from "react"
import * as TS from "../../../../types";
import { Alert, Value, Form, TextArea, CardSection, Modal } from "../../../controls";
import { ActivityContext } from "../ActivityContext";


export default function AvailableAreas() {
  // state
  const [mode, setMode] = useState<"edit"|"view">("view");
  // context
  const AC = useContext(ActivityContext)!;

  // user wants to edit
  const onClickEdit = () => {
    setMode("edit");
  }

  // stops edit
  const stopEdit = () => setMode("view");

  // saves changes
  const saveEdit = async(changeset:any) => {
    await AC.updateRegionVariant(AC.regionVariant!._id!, changeset);
    stopEdit();
  }

  // subactivity? do not display available areas
  if(AC.activity!.isSubactivity) {
    return null;
  }
  
  // render
  return <>
    <CardSection title="Verfügbare Gebiete">
      <div id="v-regionvariant-availableareas">
        <Value 
          label="Verfügbare Gebiete" placeholder="keine Angaben" 
          explanation={`Falls die Aktivität nur in gewissen Gebieten dieser Region angeboten wird, kann das hier beschrieben werden: z.B. "nur verfügbar in den PLZ 3097, 3098" oder "kann nur im Kreis 5 durchgeführt werden".`} 
          onClick={onClickEdit}
        >
            {AC.regionVariant!.availableAreas}
        </Value>
        <Alert intent="warning" title="Keine Hinweise auf die Aktvität" size="small">
          Wird bei der Einlösung UND im Shop angezeigt. Deshalb keinerlei Hinweise auf die Überraschung einfügen (z.B. auf keinen Fall "der Flug startet in Belp")
        </Alert>
      </div>
      <Modal isOpen={mode==="edit"} title="Regionsvariante - Verfügbare Gebiete" onClose={stopEdit}>
        <Edit regionVariant={AC.regionVariant!} saveEdit={saveEdit} onCancel={stopEdit} />
      </Modal>
    </CardSection>
  </>
}


type EditProps = {
  regionVariant: TS.ActivityRegionVariant,
  saveEdit: Function,
  onCancel: () => void
}
function Edit({regionVariant, saveEdit, onCancel} : EditProps) {
  // state
  const [entity, setEntity] = useState<{availableAreas:string}>({availableAreas:""});
  const [busy, setBusy] = useState(false);

  // mount
  useEffect(() => {
    const entity = {availableAreas: regionVariant.availableAreas};
    setEntity(entity);
  }, []);

  // save the update
  const onSave = async(formResult: any) => {
    setBusy(true);
    await saveEdit(formResult.changesetFlat); 
    setBusy(false);
  }

  // render
  return (
    <Form onCancel={onCancel} onSave={onSave} entity={entity} busy={busy}>
      <TextArea path="availableAreas" label="verfügbare Gebiete" />
    </Form>
  ); 
}