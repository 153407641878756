import React from "react"
import { Link as RRLink } from "react-router-dom" // for version 2
import CSS from "./Link.module.scss";

type LinkProps = {
  to: string,
  className?: string,
  target?: "_blank"|"_self"|"_parent"|"_top",
  children: React.ReactNode|React.ReactNode[],
  download?: string,
}
export function Link({to, target, className, children, download}: LinkProps) {
  // defaults
  target = target || "_self";

  if(to.startsWith("http") || to.startsWith("localhost") || to.startsWith("mailto") || to.startsWith("data") || to.startsWith("sms") || to.startsWith("tel")) {
    return <a href={to} className={`${CSS.anchor} ${className || ""}`} target={target} rel="noopener noreferrer" download={download}>{children}</a>
  }
  else {
    return <RRLink to={to} className={`${CSS.anchor} ${className || ""}`} target={target}>{children}</RRLink>
  }
}
