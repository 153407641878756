import environment  from './environment'; // NOTE: the APPENTURA_ENV variable is delivered by Webpack via a plugin (see plugins section in webpack.config.js'. There are three environments: development, staging, production

/*
const config_old = {
  development: {
    url:'http://localhost:3001'
  },
  staging: {
    url:'https://shop-staging.appentura.ch'
  },
  production: {
    url:'https://shop.appentura.ch'
  }
}
*/

const config_ch = {
  development: {
    url:'http://localhost:3020/shop'
  },
  staging: {
    url:'https://ch.appentura.dev/shop'
  },
  production: {
    url:'https://appentura.ch/shop'
  }
}

const config_de = {
  development: {
    url:'http://localhost:3021/shop'
  },
  staging: {
    url:'https://de.appentura.dev/shop'
  },
  production: {
    url:'https://appentura.ch/shop'
  }
}

const config = {
  ch: config_ch[environment],
  de: config_de[environment],
}

export default config;


//export default config[environment]