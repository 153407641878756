import React, { useState } from "react";
import * as DA from "../../../types/DataAccess";
import { Form, TextInput, NumberInput, Dropdown, DropdownOption, Alert, Validators, Validation } from "../../controls";

const currencyOptions: DropdownOption[] = [
  {label:"CHF", value:"CHF"},
  {label:"EUR", value:"EUR"}
];

type RegionEditProps = {
  region:DA.Region,
  onUpdate: () => void,
  stopEdit: () => void,
}
export default function RegionEdit({region, onUpdate, stopEdit} : RegionEditProps) {
  // state
  const [error, setError] = useState<any>(null);
  const [busy, setBusy] = useState<boolean>(false);
  const [entity] = useState<DA.Region>(region);

  // user cancels
  const onCancel = () => { 
    stopEdit();
  };

  // user wants to save
  const onSave = async(formResult:any) => {
    setBusy(true);
    // save
    let result: DA.Result<DA.Region>;
    if(entity._id) {
      // update
      result = await DA.RegionRepository.update(entity._id, formResult.changeset);
    }
    else {
      // create
      const newRegion = formResult.merge(entity);
      result = await DA.RegionRepository.create(newRegion);
    }
    // pop dialog on success, display error if not
    if(result.success) {
      onUpdate();
      stopEdit();
    }
    else {
      setBusy(false);
      setError(result.error);
    }

  }


  // render
  let errorAlert = null;
  if(error) {
    errorAlert = <Alert title="Region konnte nicht gespeichert werden" intent="error" size="medium">{error.message}</Alert>
  }
  return (
    <Form entity={entity} onSave={onSave} onCancel={onCancel} labelSave="speichern" labelCancel="abbrechen" busy={busy}>
      <TextInput label="Code" disabled={(entity._id ? true: false) || busy} path="code" validate={codeValidator} placeholder="LAND-REGION" explanation="" />
      <TextInput label="Name" path="title.de" disabled={busy} validate={Validators.isRequired("Bitte Namen eingeben")} placeholder="Name der Region" explanation="" />
      <Dropdown label="Währung" path="currencyCode" options={currencyOptions} />
      <NumberInput path="position" label="Position" validate={positionValidator} />
      {errorAlert}
    </Form>
  )
}

function codeValidator(v:string): Validation {
  // eslint-disable-next-line
  const re_code = /^[A-Z]+\-[A-Z]+$/;
  const valid = re_code.test(String(v));
  return new Validation(valid, "Code muss im Format LAND-REGION angebeben werden");
}

function positionValidator(v:number): Validation {
  return new Validation(v > 0, "Position muss grösser als 0 sein");
}