import React from "react";
import { FormProvider } from "./FormContext";
import FormActions from "./FormActions";
import Entity from "./Entity";

type FormProps = {
  children:any,
  entity: any,

  onSave?: any,
  onCancel?: any,
  onOk?: any,

  labelSave?: string,
  labelCancel?: string,
  labelOk?: string,

  busy?: boolean,

  layout?: "vertical"|"horizontal"
}

export function Form({children, entity, onSave, onCancel, labelSave, labelCancel, busy, layout, onOk, labelOk} : FormProps ) {
  // defaults
  layout = layout || "vertical";

  // create entity container
  let entityContainer = entity ? <Entity entity={entity} /> : null;

  // render
  return (
    <FormProvider>
      <div className={`${layout}`}>
        {entityContainer}
        <div>
          {children}
        </div>
        <FormActions onSave={onSave} onCancel={onCancel} labelCancel={labelCancel} labelSave={labelSave} onOk={onOk} labelOk={labelOk} busy={busy} />
      </div>
    </FormProvider>
  )
}