import {useContext, useEffect, useRef} from "react"

import {FormContext} from "./FormContext"

function Entity({entity}) {
  // context
  const form = useRef(useContext(FormContext))

  // mount
  useEffect(() => {
    form.current.init(entity)
  }, [entity])

  // render
  return null
}

export default Entity