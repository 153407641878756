import _ from "lodash";
import React, {useContext} from "react";
import { Link } from "../../../../controls";
import { SurpriseContext } from "../../Surprise.Context";
import Row from "./Row";

export default function Donee() {
  // context 
  const SC = useContext(SurpriseContext);
  
  // get values
  const s = SC.surprise;
  let name = _.get(s, "RecieverName") || "Unbekannt";
  let realName = _.get(s, "RecieverRealFirstName");
  if(realName) {
    name = `${realName} / "${name}"`
  }

  // render
  const mailLink = <Link to={`mailto:${_.get(s, "RecieverEmail")}`}>{_.get(s, "RecieverEmail")}</Link>;
  const smsLink =  <Link to={`sms:${_.get(s, "RecieverPhone")}`}>{_.get(s, "RecieverPhone")}</Link>;
  return <Row label="Beschenkter">{name} ({mailLink}, {smsLink})</Row>
}
