import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Dropdown, DropdownOption, TextInput, DropdownOptions, Icon, Icons } from "../../controls";
import { SelfieContext } from "./Selfie.Context";

import CSS from "./Selfie.module.scss";

// options for dropdowns
const yearOptions: DropdownOption[] = _.range(2015, (new Date).getFullYear() + 1).map(year => {return {label:year.toString(), value:year}});
const marketingAllowedOptions: DropdownOption[] = [
  {label: "alle", value: "all"},
  {label: "freigegeben", value: "yes"},
  {label: "nicht freigegeben", value: "no"}
];
const sharedOptions: DropdownOption[] = [
  {label: "alle", value: "all"},
  {label: "bereits geteilt", value: "yes"},
  {label: "noch nicht geteilt", value: "no"}
];
const ratingOptions: DropdownOption[] = [
  {label: "alle", value: 0},
  {label: "★", value: 1},
  {label: "★★", value: 2},
  {label: "★★★", value: 3},
  {label: "★★★★", value: 4},
  {label: "★★★★★", value: 5},
];
const testimonialOptions: DropdownOption[] = [
  {label: "alle", value:"all"},
  {label: "hat Testimonial", value:"yes"},
  {label: "kein Testimonial", value:"no"},
];
const bookmarkedOptions: DropdownOption[] = [
  {label: "alle", value:"all"},
  {label: "ja", value:"yes"},
  {label: "nein", value:"no"},
];

export default function Filter() {
  // context
  const SC = useContext(SelfieContext);

  useEffect(() => {
    SC.search();
  }, []) 


  // render
  return (
    <Card className={CSS.filter}>
      <div className={CSS.row}>
        <Dropdown label="Bookmark" options={bookmarkedOptions} value={SC.filter.bookmarked} onChange={(v:any) => SC.filter.bookmarked = v} />
        <Dropdown label="Bewertung mindestens" options={ratingOptions} value={SC.filter.minRating} onChange={v => SC.filter.minRating = Number(v)} />
        <Dropdown label="Testiominal" options={testimonialOptions} value={SC.filter.testimonial} onChange={(v:any) => SC.filter.testimonial = v} />
        <Dropdown label="bisherige Verwendung" options={sharedOptions} value={SC.filter.shared} onChange={(v:any) => SC.filter.shared = v} />
        <Dropdown label="Marketing freigegeben" options={marketingAllowedOptions} value={SC.filter.marketingAllowed} onChange={(v:any) => SC.filter.marketingAllowed = v} />
        <div>
          <Dropdown label="von Anfang" options={DropdownOptions.Months} value={SC.filter.monthFrom} onChange={v => SC.filter.monthFrom = Number(v)} />
          <Dropdown options={yearOptions} value={SC.filter.yearFrom} onChange={v => SC.filter.yearFrom = Number(v)} />
        </div>
        <div>
          <Dropdown label="bis Ende" options={DropdownOptions.Months} value={SC.filter.monthTo} onChange={v => SC.filter.monthTo = Number(v)} />
          <Dropdown options={yearOptions} value={SC.filter.yearTo} onChange={v => SC.filter.yearTo = Number(v)} />
        </div>
      </div>
      <div className={CSS.row}>
        <Activities />
        <TextInput label="Text in Kommentar oder Feedback" explanation="um nach mehreren Begriffen zu suchen, diese mit Komma separieren" value={SC.filter.testimonialText} onChange={s => SC.filter.testimonialText = s} />
      </div>
      <div>
        <Button onClick={() => SC.search()}><Icon icon={Icons.Search} /> suchen</Button>
      </div>
    </Card>
  )
}

function Activities() {
  // context
  const SC = useContext(SelfieContext);
  // state
  const [dropdownOptions, setDropdownOptions] = useState<DropdownOption[]>([]);

  // user selects item
  const onChange = (v:string) => {
    if(v === "") {
      SC.filter.activityId = null;
    }
    else {
      SC.filter.activityId = v;
    }
  }

  // mount
  useEffect(() => {
    const dropdownOptions = SC.activities.map(a => {
      return {
        label: a.title.de,
        value: a._id
      }
    });
    dropdownOptions.unshift({value:"", label:"-"});
    setDropdownOptions(dropdownOptions);
  }, [SC.activities]);

  // render
  return <Dropdown label="Aktivität" options={dropdownOptions} value={SC.filter.activityId || ""} onChange={onChange} />
}