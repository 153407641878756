import _ from "lodash";
import React, { useContext, useState, useEffect } from 'react';
import { ObjectID } from "bson"
import { GMAP_TRAVEL_MODES, TICKER_TRAVEL_MODES} from '../../../../config/constants';
import * as TS from "../../../../types";
import * as BL from "../../../../types/BusinessLogic";

// context
import { StepsContext } from "./Steps.Context";
import { SurpriseContext } from "../Surprise.Context";
import { FormContext } from "../../../controls/FormContext";

// controls
import { Button, ConfirmButton, Coordinates, DateInput, Dropdown, Form, CloudStorageUploader, Link, TextArea, TextInput, Proxy, Modal, Validators, PdfLink, DropdownOption, Label } from "../../../controls";
import Section from "./Step.Section";


type EditProps = {
  step: any
}
export default function Edit({step}: EditProps) {
  // context
  const STC = useContext(StepsContext);
  const SC = useContext(SurpriseContext);
  
  // state
  const [entity, setEntity] = useState<any>(null);
  const [optionsTravelMode, setOptionsTravelMode] = useState<any[]>([]);
  const [tickerTravelOptions, setTickerTravelOptions] = useState<any[]>([]);
  const [activityOptions, setActivityOptions] = useState<DropdownOption[]>([]);
  const [hasAlertDate, setHasAlertDate] = useState<boolean>(!_.isNil(step.AlertDate));
  
  // saves changes
  const onSave = async(formResult:any) => {
    let updated_entity: any = {};
    _.merge(updated_entity, step);
    _.merge(updated_entity, entity);
    _.merge(updated_entity, formResult.changeset);
    updated_entity.Instructions = updated_entity.InstructionsString.split("\n");
    updated_entity.ActivityId = updated_entity.ActivityId.length > 0 ? updated_entity.ActivityId : null;
    updated_entity.TravelMode = updated_entity.TravelMode.length > 0 ? updated_entity.TravelMode : null;
    updated_entity.TickerMessage = updated_entity.TickerMessage.trim().length > 0 ? updated_entity.TickerMessage.trim() : null;
    updated_entity.TickerTravel = updated_entity.TickerTravel.length > 0 ? updated_entity.TickerTravel : null;
    updated_entity.AlertDate = hasAlertDate ? updated_entity.AlertDate : null;
    updated_entity.Links = STC.currentStep.Links;
    updated_entity.Attachments = STC.currentStep.Attachments;
    delete updated_entity.InstructionsString;
    await STC.saveStep(updated_entity, false);
  }

  // mount 
  useEffect(() => {
    // activity options
    const activityOptions = SC.activityCatalogue
      .filter(a => BL.Activity.isActive(a))
      .map(a => {
        return {value:a._id, label:a.title.de} as DropdownOption;
      })
      .sort((a, b) => a.label.localeCompare(b.label));
    activityOptions.unshift({label:"-", value:""});
    setActivityOptions(activityOptions);
    // travel options
    const optionsTravelMode = GMAP_TRAVEL_MODES.map(m => {return {value:m.value || "", label:m.label}}).sort((a, b) => a.label.localeCompare(b.label));
    setOptionsTravelMode(optionsTravelMode);
    // gather ticker travel options
    const tickerTravelOptions = TICKER_TRAVEL_MODES.map(m => {return {value:m.value || "", label:m.label}}).sort((a, b) => a.label.localeCompare(b.label));
    setTickerTravelOptions(tickerTravelOptions);
    // create entity for form
    // TODO could we not simply clone the step?
    setEntity({
      _id: step._id,
      Title:step.Title, 
      InstructionsString: (step.Instructions || []).join("\n"),
      StartCoordinates: step.StartCoordinates,
      EndCoordinates: step.EndCoordinates,
      TravelMode: step.TravelMode || "",
      ImageUrl: step.ImageUrl || "",
      ImageText: step.ImageText || "",
      Attachments: (step.Attachments || []).map((a:any) => a),
      Links: (step.Links || []).map((a:any) => a),
      ActivityId: step.ActivityId || "",
      TickerTravel: step.TickerTravel || "",
      TickerMessage: step.TickerMessage || "",
      AlertDate: step.AlertDate || SC.surprise.StartTime
    })
  }, []);
  
  // render
  if(!STC.currentStep) {
    return null;
  }
  if(!entity) {
    return null
  }
  return (
    <div className="content-edit">
      <Form entity={entity} onSave={onSave} onCancel={() => STC.setCurrentStep(null)} labelCancel="abbrechen" labelSave="speichern">
        <TextInput path="Title" label="Titel" placeholder="Bitte eine Überschrift eingeben" />
        <TextArea path="InstructionsString" label="Anweisungen" placeholder="Bitte Anweisungen eingeben" />
        <Section title="Anreise">
          <div className="coordinates">
            <Coordinates label="Start" path="StartCoordinates" pathLat="Lat" pathLng="Lng" />
            <Coordinates label="Ende" path="EndCoordinates" pathLat="Lat" pathLng="Lng" />
          </div>
          <Dropdown label="Transportmittel" path="TravelMode" options={optionsTravelMode} />
          <StepCoordinatesCopier />
        </Section>
        <Section title="Bild" className="image">
          <CloudStorageUploader kind="image" folder="adventures" prefix={SC.surprise._id} path="ImageUrl" />
          {/* TODO: add limit of 32 text length on textinpu */}
          <TextInput path="ImageText" label="Bildtext" placeholder="Dieser Text wird auf dem Bild platziert"/>
        </Section>
        <Section title="Anhänge" className="attachments">
          <StepAttachments step={step} />
        </Section>
        <Section title="Links" className="links">
          <StepLinks />
        </Section>
        <Section title="Aktivität" className="activity">
          <Dropdown label="Verbunden Aktivität" path="ActivityId" options={activityOptions} />
        </Section>
        <Section title="Slack Alert" className="slack">
          <input type="checkbox" onChange={e => setHasAlertDate(e.target.checked)}  checked={hasAlertDate} />
          <DateInput label="Slack Alert wenn nicht erreicht bis" disabled={!hasAlertDate} path="AlertDate" kind="datetime" />
        </Section>
        <Section title="Live-View" className="ticker">
          <TextInput path="TickerMessage" placeholder="Text der im Live-View angezeigt wird" label="Text" />
          <Dropdown label="Reisemodus" path="TickerTravel" options={tickerTravelOptions} />
        </Section>
      </Form>
    </div>
  )
}

type StepCoordinatesCopierProps = {}
function StepCoordinatesCopier({}:StepCoordinatesCopierProps) {
  // context
  const FC = useContext(FormContext);
  const SC = useContext(SurpriseContext);
  // state
  const [selectedStepId, setSelectedStepId] = useState<any>(null);
  const [options, setOptions] = useState<DropdownOption[]>([]);

  // create options when steps change
  useEffect(() => {
    const options: DropdownOption[] = [];
    for(let i=0; i < SC.steps.length; i+=1) {
      const step = SC.steps[i];
      if(step.StartCoordinates && step.EndCoordinates) {
        options.push({
          label: `${i + 1} - ${step.Title}`,
          value: step._id,
        })
      }
    }
    if(options.length > 0) {
      setSelectedStepId(options[0].value);
    }
    setOptions(options);
  }, SC.steps);


  // user wants to copy coordinates from another step
  const onClickCopyCoordinates = () => {
    const step = SC.steps.find((s:any) => s._id === selectedStepId);
    if(step) {
      FC.onFieldChange("StartCoordinates", step.StartCoordinates, {valid:true});
      FC.onFieldChange("EndCoordinates", step.EndCoordinates, {valid:true});
      FC.onFieldChange("TravelMode", step.TravelMode, {valid:true});
    }
  }
  
  // render
  return <>
    <Label label="Koordinaten und Transportmittel aus Schritt übernehmen"/>
    <div className="coordinates-copier">
      
      <div className="coordinates-copier-label">aus Schritt</div>
      <Dropdown options={options} value={selectedStepId} size="small" onChange={v => setSelectedStepId(v)} />
      <Button size="small" onClick={onClickCopyCoordinates}>übernehmen</Button>
    </div>
  </>
}

type StepAttachmentsProps = {
  step:any
}
function StepAttachments({step}: StepAttachmentsProps) {
  // context
  const STC = useContext(StepsContext);
  // state
  const [attachments, setAttachments] = useState<TS.AdventureStepAttachment[]>([]);
  const [attachmentToEdit, setAttachmentToEdit] = useState<TS.AdventureStepAttachment|null>(null);

  // update attachments in state when selected step changes
  useEffect(() => {
    if(STC.currentStep) {
      setAttachments(STC.currentStep.Attachments);
    }
  }, [STC.currentStep]);

  // updates attachments in entity and in this component's state
  const updateAttachments = (attachments:TS.AdventureStepAttachment[]) => {
    STC.currentStep.Attachments = attachments;
    setAttachments(attachments);
  }

  // "saves" attachment (does not really save, just adds to collection)
  const saveAttachment = (attachmentToSave:TS.AdventureStepAttachment) => {
    if(!attachmentToSave._id) {
      attachmentToSave._id = (new ObjectID()).toString();
      const updatedAttachments = STC.currentStep.Attachments.map((attachment:TS.AdventureStepAttachment) => attachment);
      updatedAttachments.push(attachmentToSave);
      updateAttachments(updatedAttachments);
    }
    else {
      const updatedAttachments = STC.currentStep.Attachments.map((attachment:TS.AdventureStepAttachment) => attachmentToSave._id === attachment._id ? attachmentToSave : attachment);
      updateAttachments(updatedAttachments);
    }
  }

  // removes an attachment
  const removeAttachment = (attachmentToRemove:TS.AdventureStepAttachment) => {
    const updatedAttachments = STC.currentStep.Attachments.filter((attachment:TS.AdventureStepLink) => attachment._id !== attachmentToRemove._id);
    updateAttachments(updatedAttachments);
  }

  // shows edit dialog
  const showEdit = (attachment: TS.AdventureStepAttachment) => {
    setAttachmentToEdit(attachment);
  }

  // shows add dialog
  const showAdd = () => {
    const attachment: TS.AdventureStepAttachment = {Title:"", Url:""}
    setAttachmentToEdit(attachment);
  }
  const stopEdit = () => setAttachmentToEdit(null); 

  // do not render if no current step is set
  if(!STC.currentStep) {
    return null;
  }

  // render 
  const rows = attachments.map((a:any) => {
    return (
      <tr key={a._id || a._tempId}>
        <td>
          <PdfLink to={a.Url}>{a.Title}</PdfLink>
        </td>
        <td>
          <Button size="small" onClick={() => showEdit(a)}>bearbeiten</Button>
        </td>
        <td>
          <ConfirmButton onConfirm={() => removeAttachment(a)}>löschen</ConfirmButton>
        </td>
      </tr>
    )
  })
  return <>
    <table><tbody>
      {rows}
      <tr key="add">
        <td></td>
        <td colSpan={2}>
          <Button size="small" onClick={() => showAdd()}>hinzufügen</Button>
        </td>
      </tr>
    </tbody></table>
    <Modal isOpen={attachmentToEdit !== null} title="Attachment" onClose={stopEdit}>
      <EditAttachment attachment={attachmentToEdit!} saveAttachment={saveAttachment} stopEdit={stopEdit} />
    </Modal>
  </>
}

type EditAttachmentProps = {
  attachment: TS.AdventureStepAttachment,
  saveAttachment: (attachment:TS.AdventureStepAttachment) => void,
  stopEdit: () => void,
}
function EditAttachment({attachment, saveAttachment, stopEdit}: EditAttachmentProps) {
  // context
  const SC = useContext(SurpriseContext);
  // state
  const [entity] = useState({...attachment});

  // saves changes
  const onSave = (result:any) => {
    // update attachment
    let updatedAttachment = {}
    _.merge(updatedAttachment, entity);
    _.merge(updatedAttachment, result.changeset);
    // dispatch attachments
    saveAttachment(updatedAttachment as TS.AdventureStepAttachment);
    // pop dialog
    stopEdit();
  }

  // render
  return (
    <Form entity={entity} onSave={onSave} onCancel={stopEdit}>
      <TextInput path="Title" label="Titel" placeholder="Titel eingeben" validate={Validators.isRequired("Bitte einen Titel eingeben")} />
      <CloudStorageUploader path="Url" kind="pdf" folder="adventures" prefix={SC.surprise._id} disableUrlEditing={true} validate={Validators.isRequired("Bitte File hochladen")} />
    </Form>
  )
}


function StepLinks() {
  // context
  const STC = useContext(StepsContext);
  // state
  const [links, setLinks] = useState<TS.AdventureStepLink[]>([]);
  const [linkToEdit, setLinkToEdit] = useState<TS.AdventureStepLink|null>(null);

  // update links in state when selected step changes
  useEffect(() => {
    if(STC.currentStep) {
      setLinks(STC.currentStep.Links);
    }
  }, [STC.currentStep]);


  const updateLinks = (links:TS.AdventureStepLink[]) => {
    STC.currentStep.Links = links;
    setLinks(links);
  }

  // "saves" link (does not really save, just adds to collection)
  const saveLink = (linkToSave:TS.AdventureStepLink) => {
    if(!linkToSave._id) {
      linkToSave._id = (new ObjectID()).toString();
      const updatedLinks = STC.currentStep.Links.map((link:TS.AdventureStepLink) => link);
      updatedLinks.push(linkToSave);
      updateLinks(updatedLinks);
    }
    else {
      const updatedLinks = STC.currentStep.Links.map((link:TS.AdventureStepLink) => linkToSave._id === link._id ? linkToSave : link);
      updateLinks(updatedLinks);
    }
  }

  // remove a link
  const removeLink = (linkToRemove:TS.AdventureStepLink) => {
    const updatedLinks = STC.currentStep.Links.filter((link:TS.AdventureStepLink) => link._id !== linkToRemove._id);
    updateLinks(updatedLinks);
  }

  // shows edit dialog
  const showEdit = (link:any) => {
    setLinkToEdit(link);
  }

  // shows add dialog
  const showAdd = () => {
    const link: TS.AdventureStepLink = {Title:"", Url:""}
    setLinkToEdit(link);
  }

  const stopEdit = () => setLinkToEdit(null);

  // do not render if no current step is set
  if(!STC.currentStep) {
    return null;
  }

  // render
  const rows = links.map(link => {
    return (
      <tr key={link._id}>
        <td>
          <Link to={link.Url} target="_blank">{link.Title}</Link>
        </td>
        <td>
          <Button size="small" onClick={() => showEdit(link)}>bearbeiten</Button>
        </td>
        <td>
          <ConfirmButton onConfirm={() => removeLink(link)}>löschen</ConfirmButton>
        </td>
      </tr>
    )
  })
  return <>
    <table><tbody>
      {rows}
      <tr key="add">
        <td></td>
        <td colSpan={2}>
          <Button size="small" onClick={() => showAdd()}>hinzufügen</Button>
        </td>
      </tr>
    </tbody></table>
    <Modal isOpen={linkToEdit!==null} title="Link" onClose={stopEdit}>
      <EditLink link={linkToEdit!} saveLink={saveLink} stopEdit={stopEdit} />
    </Modal>
  </>
}

type EditLinkProps = {
  link: TS.AdventureStepLink,
  saveLink: (linkToSave:TS.AdventureStepLink) => void,
  stopEdit: () => void,
}
function EditLink({link, saveLink, stopEdit}: EditLinkProps) {

  // state
  const [entity] = useState({...link});

  // saves changes
  const onSave = (result:any) => {
    // update link
    let updatedLink = {}
    _.merge(updatedLink, entity);
    _.merge(updatedLink, result.changeset);
    // save it
    saveLink(updatedLink as TS.AdventureStepLink);
    // pop dialog
    stopEdit();
  }

  // render
  return (
    <Form entity={entity} onSave={onSave} onCancel={stopEdit}>
      <TextInput path="Title" label="Titel" placeholder="Titel eingeben" validate={Validators.isRequired("Bitte einen Titel eingeben")} />
      <TextInput path="Url" label="Url" placeholder="Url eingeben" validate={Validators.isRequired("Bitte eine Url eingeben")} />
    </Form>
  )
}

