// 3rd party
import React, {useEffect, useState} from 'react';
import {
  BrowserRouter,
  Route,
  Redirect,
  Switch,
  useLocation
} from 'react-router-dom';
import Helmet from "react-helmet"

// configuration
import config from "./config";

// components
import Sidebar from "./components/Sidebar";
import Login from "./components/views/Login";
import Logout from "./components/views/Logout";
import NotFound from "./components/views/NotFound";

// actual views
import Activities from "./components/views/Activity/List"
import Activity from "./components/views/Activity/Activity"
import ActivityCreate from "./components/views/Activity/Create";
import ActivityPackages from "./components/views/ActivityPackage/ActivityPackage.List";
import ActivityPackage from "./components/views/ActivityPackage/ActivityPackage.Item";
import AdventureStepTemplates from "./components/views/Templates/AdventureStepTemplate.List";
import AdventureTemplates from "./components/views/Templates/AdventureTemplate";
import BulkOrders from "./components/views/BulkOrders/BulkOrder.List";
import BulkOrder from "./components/views/BulkOrders/BulkOrder.Item";
import BulkOrderCreate from "./components/views/BulkOrders/BulkOrder.Create";
import Charities from "./components/views/Charity/Charity.List";
import CollectiveInvoices from "./components/views/CollectiveInvoices/CollectiveInvoices";
import Conditions from "./components/views/Conditions/Conditions.List"
import DataWarehouse from "./components/views/Statistics/DataWarehouse";
import Dunning from "./components/views/Dunning/Dunning.List";
import Dashboard from "./components/views/Dashboard/Dashboard"
import Equipment from "./components/views/Equipment/Equipment.List"
import Esr from "./components/views/Tools/Esr"
import IncidentCategoryList from "./components/views/Incidents/IncidentCategory.List";
import IncidentProcessingList from "./components/views/Incidents/IncidentProcessing.List";
import IncidentProcessingItem from "./components/views/Incidents/IncidentProcessing.Item";
import Invoices from "./components/views/Tools/Invoices"
import Live from "./components/views/Live/Live"
import Mailfail from "./components/views/Mailfail/Mailfail";
import MailTemplates from "./components/views/Resources/MailTemplates"
import Manuals from "./components/views/Resources/Manuals";
import MarketingChannels from "./components/views/MarketingChannels/MarketingChannel.List";
import MassMailer from "./components/views/Tools/MassMailer"
import MeCreate from "./components/views/Me/Create";
import MeteoCheck from "./components/views/MeteoCheck/List"
import OfflineSoldAdventures from "./components/views/Surprise/OfflineSoldAdventures/OfflineSoldAdventure.List"
import PaymentTypes from "./components/views/PaymentTypes/PaymentTypes.List"
import PackagingTypes from "./components/views/PackagingType/PackagingType.List"
import Pages from "./components/views/Cms/Page.List"
import PagesEdit from "./components/views/Cms/Page"
import Promotions from "./components/views/Surprise/Promotions/Promotions"
import Providers from "./components/views/Provider/Providers.List"
import Provider from "./components/views/Provider/Provider";
import Regions from "./components/views/Region/Region.List"
import SanityCheck from "./components/views/Tools/SanityCheck"
import Shop from "./components/views/Shop/Shop";
import Selfies from "./components/views/Selfies/Selfie.List";
import Shipping from "./components/views/Shipping/Shipping.View";
import Statistics from "./components/views/Statistics/Statistics"
import Surprise from "./components/views/Surprise/Surprise.View"
import SurpriseProperties from "./components/views/Surprise/Properties/SurpriseProperties"
import SurpriseFix from "./components/views/Surprise/Fix/SurpriseFix"
import Surprise_Search from "./components/views/Surprise/SearchNew/Search";
import Surprise_Data from "./components/views/Surprise/Surprise.Data"
import Testimonials from "./components/views/Testimonial/Testimonial.List"
import Users from "./components/views/Users/Users.List";
import VendorInvoices from "./components/views/VendorInvoices/VendorInvoices";
import Vouchers from "./components/views/Vouchers/Vouchers.List";
import WeatherConditions from "./components/views/WeatherConditions/WeatherConditions.List"

// other
import Kitchensink from "./components/views/Kitchensink";
import UpdateHint from "./UpdateHint";

// css and themes
import './App.scss'


function App() {
  // state
  const [isLoggedIn, setIsLoggedIn] = useState(getIsLoggedIn());

  // mount
  useEffect(() => {
    console.info('environment:', config.environment);
  }, []);

  // render
  if(!isLoggedIn) {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={props => <Login onLogin={() => setIsLoggedIn(true)} {...props} /> } />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    )
  }
  else {
    return (
      <BrowserRouter>
        <UpdateHint />
        <div id="aat-v-app">  
          <Helmet>
            <title>Appentura</title>
          </Helmet>
          <div>
            <Sidebar/>
          </div>
          <div id="aat-v-app-content">
            <Switch>
              <Route exact path="/" component={Dashboard} />

              <Route exact path="/activities" component={Activities} />
              <Route exact path="/activities/create" component={ActivityCreate} />
              <Route exact path="/activities/:id" component={Activity} />

              <Route exact path="/activitypackages" component={ActivityPackages} />
              <Route exact path="/activitypackages/:id" component={ActivityPackage} />
              
              <Route exact path="/adventuresteptemplates" component={AdventureStepTemplates} />
              <Route exact path="/adventuretemplates" component={AdventureTemplates} />
              <Route exact path="/adventuretemplates/:id" component={AdventureTemplates} />

              <Route exact path="/charities" component={Charities} />

              <Route exact path="/regions" component={Regions} />
              
              <Route exact path="/conditions" component={Conditions} />
              <Route exact path="/conditions/:id" component={Conditions} />

              <Route exact path="/collectiveInvoices" component={CollectiveInvoices} />

              <Route exact path="/dunning" component={Dunning} />

              <Route exact path="/equipment" component={Equipment} />
              <Route exact path="/equipment/:id" component={Equipment} />

              <Route exact path="/esr" component={Esr} />

              <Route exact path="/incidents/categories" component={IncidentCategoryList} />
              <Route exact path="/incidents/process" component={IncidentProcessingList} />
              <Route exact path="/incidents/process/:id" component={IncidentProcessingItem} />

              <Route exact path="/invoices" component={Invoices} />

              <Route exact path="/live" component={Live} />

              <Route exact path="/logout" render={props => <Logout onLoggedOut={() => setIsLoggedIn(false)} {...props} /> } />

              <Route exact path="/massmailer" component={MassMailer} />

              <Route exact path="/mailfail" component={Mailfail} />

              <Route exact path="/marketingchannels" component={MarketingChannels} />
              <Route exact path="/marketingchannels/:id" component={MarketingChannels} />

              <Route exact path="/meteocheck" component={MeteoCheck} />

              <Route exact path="/mecreate" component={MeCreate} />

              <Route exact path="/offlinesoldadventures" component={OfflineSoldAdventures} />

              <Route exact path="/packaging" component={PackagingTypes} />

              <Route exact path="/pages/" component={Pages} />
              <Route exact path="/pages/:id" component={PagesEdit} />

              <Route exact path="/paymenttypes" component={PaymentTypes} />
              
              <Route exact path="/promotionsurprises" component={Promotions} />

              <Route exact path="/providers" component={Providers} />
              <Route exact path="/providers/:id" component={Provider} />

              <Route exact path="/resources/mailtemplates" component={MailTemplates} />
              <Route exact path="/resources/manuals" component={Manuals} />

              <Route exact path="/sanitycheck" component={SanityCheck} />

              <Route exact path="/shipping" component={Shipping} />

              <Route exact path="/shop" component={Shop} />

              <Route exact path="/selfies" component={Selfies} />

              <Route exact path="/statistics" component={Statistics} />
              <Route exact path="/statistics/datawarehouse" component={DataWarehouse} />

              <Route exact path="/surprise/:id" component={Surprise} />
              <Route exact path="/surprisefix/:id" component={SurpriseFix} />

              <Route exact path="/surprises/search" component={Surprise_Search} />
              <Route exact path="/surprises/search/:prefab" component={Surprise_Search} />

              <Route exact path="/surprise/:id/data" component={Surprise_Data} />

              <Route exact path="/surpriseproperties" component={SurpriseProperties} />
              
              <Route exact path="/testimonials" component={Testimonials} />

              <Route exact path="/bulkorders" component={BulkOrders} />
              <Route exact path="/bulkorders/create" component={BulkOrderCreate} />
              <Route exact path="/bulkorders/:id" component={BulkOrder} />

              <Route exact path="/users" component={Users} />

              <Route exact path="/vouchers" component={Vouchers} />

              <Route exact path="/vendorinvoices" component={VendorInvoices} />

              <Route exact path="/weatherconditions" component={WeatherConditions} />
              <Route exact path="/weatherconditions/:id" component={WeatherConditions} />

              <Route exact path="/kitchensink" component={Kitchensink} />

              <Route component={NotFound} />
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    )
  }
}

function getIsLoggedIn(): boolean {
  try {
    // get token and user from local storage
    const token = window.localStorage.getItem('appentura_token') || "";
    const token_exp = window.localStorage.getItem('appentura_token_exp') || "";
    const user = JSON.parse(window.localStorage.getItem('appentura_user') || "") || "";

    // for the user to be considered to be logged in, he needs to to have the tree values from above
    let isLoggedIn = token !== "" && user !== "" && token_exp !== "";

    // also, the token may not be expired ... we add an additonal 'negative' window of 15 minutes ... to prevent user from GETing an entity before the login expires and POST or PUTing after it expires
    let threshold = 14 * 60 * 1000
    if(Date.now() > (Number(token_exp) - threshold)) {
      isLoggedIn = false;
    }

    return isLoggedIn;
  }
  catch(err) {
    // TODO display error
    return false
  }
}


export default App
