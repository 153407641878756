import _ from "lodash";
import React, {useContext} from "react";
import {SurpriseContext} from "../../Surprise.Context";
import {Link} from "../../../../controls";
import Row from "./Row";

export default function Activities() {
  // context 
  const SC = useContext(SurpriseContext);
  // render
  const items = [];
  (SC.surprise.Activities || []).forEach(a => {
    items.push(
      <div key={a.ActivityId}>
        <Link to={`/activities/${a.ActivityId}`} target="_blank">{a.Title}</Link>, {_.get(a, "Configuration.ParticipantCount") || "?"} Personen in {_.get(a, "RegionCode") || "?"}
      </div>
    );
  })

  return <Row label="Aktivitäten">{items}</Row>
}
