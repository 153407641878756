import React, {useContext} from "react";
import {SurpriseContext} from "../Surprise.Context";
import * as TS from "../../../../types";

// controls
import { Card } from "../../../controls";
// subviews
import Log from "./Log.View";
import StatusInfo from "./StatusInfo.View";
import Selfies from "./Selfies/Selfies.View";

// styling
import "./Infos.View.scss";
import Status from "./Status";

export default function Infos() {
  // context 
  const SC = useContext(SurpriseContext);
    
  // render
  return (
    <Card id="v-surprise-infos">
      <StatusInfo />
      {getContent(SC.surprise.Status)}
      <Log />
      <Selfies />
    </Card>
  )
}

function getContent(status) {
  switch(status) {
    case TS.AdventureStatus.OrderCancelled:
      return <Status.OrderCanceled />
    case TS.AdventureStatus.Ordered:
      return <Status.Ordered />
    case TS.AdventureStatus.DateSelected:
      return <Status.DateSelected />
    case TS.AdventureStatus.ConditionsSent:
      return <Status.ConditionsSent />
    case TS.AdventureStatus.ConditionsAccepted:
      return <Status.ConditionsAccepted />
    case TS.AdventureStatus.Ready:
      return <Status.Ready />
    case TS.AdventureStatus.Finished:
      return <Status.Finished />
    case TS.AdventureStatus.Reviewed:
      return <Status.Reviewed />
    case TS.AdventureStatus.Archived:
      return <Status.Archived />
    case TS.AdventureStatus.Locked:
      return <Status.Locked />
    case TS.AdventureStatus.Prepaid:
      return <Status.Prepaid />
    case TS.AdventureStatus.BuddySelected:
      return <Status.BuddySelected />
    default:
      return <Status.Unknown />
  }
}

