import React, {useState} from "react";
import { Icon, Icons } from ".";
import CSS from "./ConfirmButton.module.scss";
import { Base } from "./internal";

type ConfirmButtonProps = {
  children: React.ReactNode|React.ReactNode[],
  data?: any, // any kind of data, will be passed by onCancel and onConfirm
  onConfirm?: (data?:any) => void,
  onCancel?: (data?:any) => void,
  size?: "small"|"medium",
  intent?: "confirm"|"cancel"|"delete",
  inverted?: boolean,
  disabled?: boolean,
  busy?: boolean
}

/**
 * A button that makes the user confirm the action
 * @param {ConfirmButtonProps} props
 */
export function ConfirmButton({children, onConfirm, onCancel, data, size, disabled, busy, intent, inverted} : ConfirmButtonProps) {
  // defaults
  size = size || "small";
  intent = intent || "confirm";
  if(inverted) {
    intent += "_inverted";
  }
  busy = busy ? true : false;
  disabled = disabled ? true : false;
  
  // state
  const [isActive, setIsActive] = useState(false);

  // activate
  const activate = () => {
    if(!disabled && !busy) {
      setIsActive(true);
    }
  }
  // confirm the action
  const confirm = () => {
    if(!disabled && !busy) {
      setIsActive(false);
      if(onConfirm) {
        onConfirm(data)
      }
    }
  }

  // cancel the action
  const cancel = () => {
    if(!disabled && !busy) {
      setIsActive(false);
      if(onCancel) {
        onCancel(data);
      }
    }
  }

  // render
  return (
    <Base disabled={disabled}>
      <div className={`${CSS.inner} ${isActive ? CSS.active : ""} ${disabled ? CSS.disabled : ""} ${size ? CSS[size] : ""} ${busy ? CSS.busy : ""} ${CSS["intent_" + intent]}`}>
        <div className={CSS.main} onClick={activate}>{children}</div>
        <div className={CSS.yes} onClick={confirm}><Icon icon={Icons.Confirm} /></div>      
        <div className={CSS.no} onClick={cancel}><Icon icon={Icons.Cancel} /></div>
        <div className={CSS.cog}><Icon icon={Icons.CircleNotch} /></div>
      </div>
    </Base>
  )
  
}