import React, {useEffect, useState} from "react";

import CSS from "./Alert.module.scss";
import { Icon, Icons } from ".";

type AlertProps = {
  title?: string,
  size?: "small"|"medium"|"large",
  intent?: "info"|"error"|"warning"|"hint",
  children: React.ReactNode|React.ReactNode[]
}
export function Alert({title, size, intent, children} : AlertProps) {
  // defaults
  intent = intent || "info";
  size = size || "medium";

  // state
  const [icon, setIcon] = useState(Icons.Info);

  // mount
  useEffect(() => {
    switch(intent) {
      case "error":
        setIcon(Icons.Bomb);
        break;
      case "warning":
        setIcon(Icons.Exclamation);
        break;
      case "hint":
        setIcon(Icons.Lightbulb);
        break;
      default:
        setIcon(Icons.Info);
        break;
    }
  });

  // pre-render
  const divTitle = title ? <div className={CSS.title}>{title}</div> : null;

  // render
  return (
    <div className={`${CSS.alert} ${CSS[size]} ${CSS[intent]}`}>
      <div className={CSS.left}>
        <div className={CSS.icon}>
          <Icon icon={icon} />
        </div>
      </div>
      <div className={CSS.right}>
        {divTitle}
        <div className={CSS.text}>{children}</div>
      </div>
    </div>
  ) 
}
