import Util from './../../../util/util'
import { Td, DateInput, Tr } from "../../controls";
import { FormData } from "./VendorInvoice";

type DatesProps = {
  formData: FormData,
  disabled?: boolean,
  onChangeInvoiceDate: (date:Date) => void,
  onChangeDueDate: (date: Date) => void,
}
export default function Dates({formData, disabled, onChangeDueDate, onChangeInvoiceDate}: DatesProps) {
  let contentInvoiceDate = null;
  let contentDueDate = null;
  if(formData.isReadOnly) {
    contentInvoiceDate = Util.printDate(formData.invoiceDate);
    contentDueDate = Util.printDate(formData.dueDate);
  }
  else {
    contentInvoiceDate = <DateInput value={formData.invoiceDate} onChange={v => onChangeInvoiceDate(v!)} disabled={disabled} />;
    contentDueDate = <DateInput value={formData.dueDate} onChange={v => onChangeDueDate(v!)} disabled={disabled} />;
  }

  return <>
    <Tr>
      <Td label>Rechnungsdatum</Td>
      <Td>{contentInvoiceDate}</Td>
    </Tr>
    <Tr>
      <Td label>Fälligkeitsdatum</Td>
      <Td>{contentDueDate}</Td>
    </Tr>
  </>
}
