import _ from "lodash";
import React, { useEffect, useState } from "react";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";
import {  Loader, List, Breadcrumb, Heading, Card, Icon, Icons, Modal } from "../../controls";
import UserEdit from "./Users.Edit";

const columns = [
  { path: "email", header: "E-Mail", key: "email", print: (v:DA.User) => v.email, sort: (a:DA.User, b:DA.User) => a.email.localeCompare(b.email) },
  { path: "firstName", header: "Vorname", key: "firstName", print: (v:DA.User) => v.firstName, sort: (a:DA.User, b:DA.User) => a.firstName.localeCompare(b.firstName) },
  { path: "lastName", header: "Name", key: "lastName", print: (v:DA.User) => v.lastName, sort: (a:DA.User, b:DA.User) => a.lastName.localeCompare(b.lastName) },
  { path: "slackId", header: "Slack Id", key: "slackId", print: (v:DA.User) => v.slackId, sort: (a:DA.User, b:DA.User) => (a.slackId || "").localeCompare(b.slackId || "")},
  { 
    path: "isSuperuser",
    header: "Superuser", 
    key: "isSuperuser", 
    print: (v:DA.User) => v.isSuperuser ? <Icon icon={Icons.Check} /> : "", 
    sort:(a:DA.User, b:DA.User) => {
      const v_a = a.isSuperuser ? 1 : 0;
      const v_b = b.isSuperuser ? 1 : 0;
      return v_a-v_b;
    }
  },
  { 
    path: "isActive",
    header: "aktiv", 
    key: "isActive", 
    print: (v:DA.User) => v.isActive ? <Icon icon={Icons.Check} /> : "", 
    sort:(a:DA.User, b:DA.User) => {
      const v_a = a.isActive ? 1 : 0;
      const v_b = b.isActive ? 1 : 0;
      return v_a-v_b;
    }
  }
];

export default function UsersList() {
  // state
  const [users, setUsers] = useState<DA.User[]|null>(null);
  const [userToEdit, setUserToEdit] = useState<DA.User|undefined>();
  const [mode, setMode] = useState<"view"|"edit">("view");
  
  // mount
  useEffect(() => {
    load();
  }, []);

  // loads data
  const load = async() => {
    const users = await DA.UserRepository.findAll();
    //const data = users.map(u => u.data);
    setUsers(users);
  };

  const reload = () => {
    load();
    setMode("view");
  }

  // user wants to edit a user
  const startEdit = (id:string|undefined) => {
    const userToEdit = id ? users!.find(user => user._id === id) : undefined;
    setUserToEdit(userToEdit);
    setMode("edit");
  }

  const stopEdit = () => {
    setUserToEdit(undefined);
    setMode("view");
  }

  // pre-render
  let content;
  if(_.isNil(users)) {
    content = <Loader text="lade Benutzerdaten" />
  }
  else {
    content = (
      <List 
        columns={columns} 
        items={users} 
        getKey={item => item._id} 
        onRowClick={(item) => startEdit(item._id)} 
        onClickCreate={startEdit}
      />
    );
  }
  // render
  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}]} location="Benutzerverwaltung" />
    <Heading>Benutzerverwaltung</Heading>
    <Card>
      {content}
    </Card>
    <Modal
      isOpen={mode !== "view"}
      title={userToEdit ? "Benutzer bearbeiten" : "Benutzer erstellen"}
      onClose={() => setMode("view")}
    >
      <UserEdit 
        user={mode === "edit" ? userToEdit! : undefined} 
        onUpdate={reload} 
        onCancel={stopEdit}
      />
    </Modal>
  </>
}
