import React, { useEffect, useState } from "react";
import CSS from "./Tabs.module.scss";

export type TabsItem = {
  label: string,
  value: any
}

type TabsProps = {
  items: TabsItem[],
  onChange: (item: TabsItem) => void
}
/**
 * @param {TabsProps} props
 */
export function Tabs({items, onChange} : TabsProps) {
  // state
  const [activeItem, setActiveItem] = useState(items[0]);

  // mount
  useEffect(() => {
    
  }, []);

  // user clicks
  const click = (item: TabsItem) => {
    onChange(item);
    setActiveItem(item);
  }

  // pre-render
  const tabs = items.map((item, index) => {
    return <div key={index} className={`${CSS.tab} ${item === activeItem ? CSS.active : ""}`} onClick={() => click(item)}>{item.label}</div>
  })
  // render
  return (
    <div className={CSS.container}>
      {tabs}
    </div>
  )
}