import _ from "lodash";
import React, { useState, useEffect } from "react";
import * as DA from "../../../types/DataAccess";
import { Form, Dropdown, Loader, NumberInput, TextArea, Validators, Validation, Alert } from "../../controls";

type PopulateProps = {
  bulkOrder: any;
  closeDialog: () => void;
}
export default function Populate({bulkOrder, closeDialog}: PopulateProps) {
  // state
  const [entity, setEntity] = useState<any>(null);
  const [activities, setActivities] = useState<any[]>([]);
  const [ready, setReady] = useState<boolean>(false);
  const [busy, setBusy] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string|null>(null);
  // mount
  useEffect(() => {
    const load = async() => {
      // get all active main activities
      const allActiveMain = await DA.ActivityRepository.getAllActiveMain();
      // create an item for each active region variant. Each item: {label:<string>, value:"<id of activity>|<id of region variant>""}
      const items:any = [];
      allActiveMain.forEach((a:any) => {
        a.regionVariants.forEach((rv:any) => {
          items.push({label:`${a.title.de} (${rv.regionCode})`, value:`${a._id}|${rv._id}`})
        })
      })
      // sort the whole thing
      const itemsSorted = items.sort((a:any, b:any) => a.label.localeCompare(b.label));
      // add nil item
      itemsSorted.unshift({label:"Aktivität wählen", value:""});

      // set state
      setActivities(itemsSorted);
      setEntity({participants: 1, names: "", region:"CH-BE", activity:""});
      setReady(true);
    }
    load();
  }, [])

  // aborts
  const cancel = () => {
    closeDialog();
  }

  // saves data
  const save = async(formResult:any) => {
    let updatedEntity:any = {};
    _.merge(updatedEntity, entity);
    _.merge(updatedEntity, formResult.changeset);

    const [activityId, regionVariantId] = updatedEntity.activity.split('|');
    const names = updatedEntity.names.split(',');
    const participants = updatedEntity.participants;

    setBusy(true);
    const result:any = await DA.BulkOrderRepository.simulateBuddyProcess(bulkOrder._id, activityId, regionVariantId, participants, names)
    if(result.success) {
      closeDialog();
    }
    else {
      setEntity(updatedEntity);
      setErrorMessage(result.message);
      setBusy(false);
    }
  }
    
  // render
  let content = null;
  let errorAlert = null;
  if(!ready || busy) {
    content = <Loader />
  }
  else {
    if(errorMessage) {
      errorAlert = <><br /><Alert intent="error" title="Fehler beim Vorabfüllen">{errorMessage}</Alert></>
    }
    content = <Form entity={entity} onCancel={cancel} onSave={save} busy={busy}>
      <NumberInput label="Anzahl Teilnehmer" path="participants" validate={Validators.isCount("Anzahl Teilnehmer eingeben (ganze Zahl)")} />
      <TextArea label="Namen der Beschenkten (Komma-separierte Liste oder ein einziger Name)" path="names" rows={3} validate={getNameValidator(bulkOrder.adventures.length)} />
      <Dropdown label="Aktivität" path="activity" options={activities} validate={Validators.isRequired("Aktivität wählen")}  />
      {errorAlert}
    </Form>
  }
  // render
  return <>
    <div id="v-bulkorder-populate">
      {content}
    </div>
  </>
}

function getNameValidator(numberOfAdventures:number) {
  return (v:any) => {
    const names = v.split(",").map((n:any) => n.trim()).filter((n:any) => n.length > 0);
    const valid = names.length === numberOfAdventures || names.length === 1;
    const message = names.length === 0 ? "Bitte mindestens einen Namen eingeben" : `Die Anzahl der Namen (${names.length}) entspricht nicht der Anzahl der Abenteuer (${numberOfAdventures})`;
    return new Validation(valid, message);
  }
}