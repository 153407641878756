import _ from "lodash";
import React, {useState, useContext} from "react";
import * as DA from "../../../types/DataAccess";
// components & contexts
import { ProviderContext } from "./Provider.Context";
import { Button, Card, Form, Dropdown, List, TextInput, TextArea, Icon, Icons, Modal } from "../../controls";

const addressKinds = [
  {value:DA.ProviderAddressKind.Billing, label:DA.ProviderAddressKindLabels[DA.ProviderAddressKind.Billing], default:true},
  {value:DA.ProviderAddressKind.Location, label:DA.ProviderAddressKindLabels[DA.ProviderAddressKind.Location]}
];

const addressColumns = [
  {path:"kind", header: "Typ", 
    print: (v:any) => {
      const kind = addressKinds.find(ak => ak.value === v.kind) || addressKinds.find(ak => ak.default) || {label:"???"};
      return kind.label;
    }
  },
  {path:"companyName", header:"Firmenname"},
  {path:"supplement", header:"Adresszusatz"},
  {path:"street", header:"Strasse"},
  {path:"houseNumber", header:"Hausnummer"},
  {path:"zip", header:"PLZ"},
  {path:"city", header:"Ort"},
  {path:"country", header:"Land"},
  {path:"countryCode", header:"Ländercode (ISO 3166)"},
  {path:"notes", header:"Notizen"}
];


export default function ProviderAddresses() {
  // state
  const [addressToEdit, setAddressToEdit] = useState<DA.ProviderAddress|null>(null);
  
  // context
  const PC = useContext(ProviderContext);

  // user wants to create
  const onClickCreate = () => {
    // create an empty address
    const address: DA.ProviderAddress =  DA.ProviderRepository.makeAddress();
    // open dialog
    setAddressToEdit(address);
  }

  // user wants to edit
  const onClickEdit = (address:DA.ProviderAddress) => {
    setAddressToEdit(address);
  }

  // user wants to delete
  const onClickDelete = async(address:DA.ProviderAddress) => {
    await PC.deleteAddress(address);
    await PC.reload();
  }

  // stops editing, closes modal
  const stopEdit = () => setAddressToEdit(null);
 
  // render
  return <>
    <Card title="Adressen" expanded={false} collapsible={true}>
      <List 
        columns={addressColumns} 
        items={PC.provider.addresses}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
        hideFooter={true}
        hideSearch={true}
        getKey={item => item._id}
      />
      <Button onClick={onClickCreate} size="small"><Icon icon={Icons.Plus} /></Button>
    </Card>
    <Modal isOpen={addressToEdit !== null} title="Adresse" onClose={stopEdit}>
      <Edit address={addressToEdit!} closeDialog={stopEdit} />
    </Modal>
  </>
}

type EditProps = {
  address: DA.ProviderAddress, 
  closeDialog: () => void,
}
function Edit({address, closeDialog}:EditProps) {
  // context
  const PC = useContext(ProviderContext);

  // state
  const [busy, setBusy] = useState<boolean>(false);
  const [entity] = useState<DA.ProviderAddress>(address);

  // user cancels
  const onCancel = () => { 
    closeDialog();
  };

  // user wants to save
  const onSave = async(formResult:any) => {
    setBusy(true);
    const addressToUpsert = formResult.merge(entity);
    await PC.upsertAddress(addressToUpsert);
    await PC.reload();
    closeDialog();
  }

  // render
  return <>
    <Form 
      entity={entity} 
      onSave={onSave} onCancel={onCancel}
      busy={busy}
    >
      <Dropdown label="Typ" path="kind" options={addressKinds} />
      <TextInput label="Firmenname" path="companyName" />
      <TextInput label="Adresszusatz" path="supplement" />
      <TextInput label="Strasse" path="street" />
      <TextInput label="Hausnummer" path="houseNumber" />
      <TextInput label="PLZ" path="zip" />
      <TextInput label="Stadt" path="city" />
      <TextInput label="Land" path="country" />
      <TextInput label="Ländercode (ISO 3166)" path="countryCode" />
      <TextArea label="Notizen" path="notes" />
    </Form>
  </>
}
