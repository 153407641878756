import React, {useEffect, useState} from "react";
import * as TS from "../../../types";
// components
import DashboardPanel from "./DashboardPanel";
// images and styling
import CSS from "./Mailfails.module.scss";
import iconMailfail from './../../../assets/images/icon_mailfail.png';
import { Link } from "../../controls";


export default function Mailfails() {
  // state
  const [items, setItems] = useState<any[]>([]);

  // mount
  useEffect(() => {
    load();
  }, []);

  // loads unresolved
  const load = async() => {
    setItems(await TS.Mailfail.getUnresolved());
  }

  let content = "bubu";

  // no items? do not display the panel
  if(items.length === 0) {
    return null;
  }

  // we have unresolved mailfails
  return (
    <DashboardPanel title="Mailfail" icon={iconMailfail} iconRounded={false} smallOnDesktop={false} smallOnMobile={false}>
      <div className={CSS.container}>
        <div className={CSS.info}>
          <span className={CSS.count}>{items.length}</span> E-Mails konnten nicht versendet werden.
        </div>
        <Link to="/mailfail">Zur Liste der Mailfails</Link>
      </div>
    </DashboardPanel>
  )
}
