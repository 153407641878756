import React, {useContext, useState, useEffect} from "react"
import * as DA from "../../../types/DataAccess";

import { Value, Button, Icon, Icons, Loader, CardSection, Modal } from "../../controls";
import { ActivityContext } from "./ActivityContext";

import CSS from "./Activity.Execution.Availability.module.scss";

const hours:string[] = [];
for(let hour=0; hour <= 23; hour +=1) {
  hours.push(`${hour}`);
}

export default function AvailableHours() {
  // state 
  const [mode, setMode] = useState<"edit"|"view">("view");

  // context
  const AC = useContext(ActivityContext)!;

  // user wants to edit
  const onClickEdit = () => {
    setMode("edit");
  }

  // stops edit mode
  const stopEdit = () => setMode("view");

  // saves changes
  const saveEdit = async(availableHours:number[]) => {
    const changeset = {availableHours};
    await AC.update(changeset);
    stopEdit();
  }

  // not loaded?
  if(AC.activity === null) {
    return <Loader text="lade Aktivität ..." />
  }

  
  // pre-reender
  const divHours: any[] = [];
  hours.forEach((hour, index) => {
    const active = AC.activity!.availableHours.includes(index);
    const icon = active ? <Icon icon={Icons.Check} /> : null;
    divHours.push(
      <div key={index} className={CSS.matrix_item}>
        <div key={"lbl_" + index} className={CSS.label}>{hour}</div>
        <div key={"val_" + index} className={`${CSS.status} ${active ? CSS.active : ""}`}>{icon}</div>
      </div>
    );
  })
  // render
  return <>
    <CardSection title="Verfügbare Tageszeiten">
      <div className={CSS.availability}>
        <Value onClick={onClickEdit}>
          <div className={CSS.matrix} onClick={onClickEdit}>
            {divHours}
          </div>
        </Value>
      </div>
    </CardSection>
    <Modal
      isOpen={mode==="edit"}
      title="Verfügbare Tageszeiten"
      onClose={stopEdit}
      wide={true}
    >
      <Edit 
        activity={AC.activity2!}
        onSave={saveEdit}  
        onCancel={stopEdit} 
      />
    </Modal>
  </>
}


type EditProps = {
  activity: DA.Activity,
  onSave: (hours:number[]) => void,
  onCancel: () => void,
}
function Edit({activity, onSave, onCancel} : EditProps) {
  // state
  const [availableHours, setAvailableHours] = useState<number[]>([]);
  const [busy, setBusy] = useState(false);

  // mount
  useEffect(() => {
    setAvailableHours(activity.availableHours.map(h => h));
  }, []);

  const toggleHour = (hourToToggle:number) => {
    let updatedHours;
    if(availableHours.includes(hourToToggle)) {
      updatedHours = availableHours.filter(m => m !== hourToToggle);
    } 
    else {
      availableHours.push(hourToToggle);
      updatedHours = availableHours.map(m => m).sort((a,b) => a - b);
    }
    setAvailableHours(updatedHours);
  }

  // save the update
  const _onSave = async() => {
    setBusy(true);
    await onSave(availableHours); 
    setBusy(false);
  }

  // pre-reender
  const divHours: any[] = [];
  hours.forEach((hour:string, index:number) => {
    const active = availableHours.includes(index);
    const icon = active ? <Icon icon={Icons.Check} /> : "";
    divHours.push(
      <div key={index} className={CSS.matrix_item}>
        <div key={`a_${index}`} className={CSS.label}>{hour}</div>
        <div key={`b_${index}`} className={`${CSS.status} ${active ? CSS.active : ""}`} onClick={() => toggleHour(index)}>{icon}</div>
      </div>
    );
  })

  const trHours: any[] = [];
  const trIcons: any[] = [];
  hours.forEach((hour:string, index:number) => {
    const active = availableHours.includes(index);
    const icon = active ? <Icon icon={Icons.Check} /> : "";
    trHours.push(<td key={`a_${index}`} className={active ? CSS.active : ""} onClick={() => toggleHour(index)}>{hour}</td>)
    trIcons.push(<td key={`b_${index}`} className={active ? CSS.active : ""} onClick={() => toggleHour(index)}>{icon}</td>)
  })

  // render
  return (
    <div className={CSS.edit}>
      <div className={CSS.availability}>
        <div className={CSS.matrix}>
          {divHours}
        </div>
      </div>
      <div className={CSS.actions}>
        <Button onClick={onCancel} intent="cancel" busy={busy}>abbrechen</Button>
        <Button onClick={_onSave} intent="confirm" busy={busy}>speichern</Button>
      </div>
    </div>
  ); 
}