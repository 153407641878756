import { useContext, useEffect, useState } from "react";
import { Button, Dropdown, DropdownOption, Row, Modal, useModal, Icon, Icons, Link, LinkButton } from "../../../controls";
import { SearchContext, SearchSort, SortFields } from "./Search.Context";
import SearchColumns from "./Search.Columns";
import CSS from "./Search.module.scss";

export default function SearchActions(props:any) {
  // context
  const Search = useContext(SearchContext);

  // state
  const [sortingOptions] = useState<DropdownOption[]>(SortFields.map(sf => {return {value:sf.field, label:sf.label}}).sort((a,b) => a.label.localeCompare(b.label)));
  const [sortingDirections] = useState<DropdownOption[]>([
    {value:"asc", label:"↑"},
    {value:"desc", label:"↓"}
  ]);
  const [modal, setModal] = useModal<"editColumns">();

  // execute search
  const onClickSearch = () => {
    Search.search();
  }

  // execute reset
  const onClickReset = () => {
    Search.reset();
  }

  // change sort
  const onChangeSortField = (field:string) => {
    const updatedSort: SearchSort = {...Search.sort, field};
    Search.search(updatedSort);
  }
  const onChangeSortDirection = (direction:string) => {
    const updatedSort: SearchSort = {...Search.sort, direction:direction as "asc"|"desc"};
    Search.search(updatedSort)
  }

  // user wants to edit columns
  const onClickEditColumns = () => {
    setModal("editColumns", "Spalten bearbeiten");
  }

  // pre-render modal content
  let modalContent = null;
  if(modal.action === "editColumns") {
    modalContent = <EditColumns closeDialog={modal.onClose} />;
  }
  
  // render
  return <div className={CSS.actions}>
    <Row align="center" xalign="center">
      <Icon icon={Icons.Sort} />
      <Dropdown options={sortingOptions} value={Search.sort.field} onChange={onChangeSortField} />
      <Dropdown options={sortingDirections} value={Search.sort.direction} onChange={onChangeSortDirection} />
      &nbsp;|&nbsp;
      <Button onClick={onClickReset} intent="cancel" busy={Search.isBusy}>reset</Button>
      <Button onClick={onClickSearch} busy={Search.isBusy}>suchen</Button>
    </Row>
    <Row align="center">
      <Button onClick={onClickEditColumns} size="small">
        Spalten <Icon icon={Icons.List} />
      </Button>
      <CsvExport />
    </Row>
    <Modal {...modal}>
      {modalContent}
    </Modal>
  </div>
}




type EditColumnsProps = {
  closeDialog: () => void,
}
function EditColumns({ closeDialog }: EditColumnsProps) {
  // render
  return <div>
    <SearchColumns />
    <Row align="center">
      <Button intent="ok" onClick={closeDialog}>ok</Button>
    </Row>
  </div>

}

function CsvExport() {
  // context
  const Search = useContext(SearchContext);

  // state
  const [busy, setBusy] = useState<boolean>(false);

  // user wants to export
  const onClickGenerate = async() => {
    setBusy(true);
    await Search.createCsv();
    setBusy(false);
  }

  // render
  if(Search.csv === null) {
    return (
      <Button onClick={onClickGenerate} size="small" busy={busy}>
        CSV erzeugen <Icon icon={Icons.FileDownload} />
      </Button>
    );
  }
  else {
    return <LinkButton to={"data:text/csv;charset=utf-8," + encodeURIComponent(Search.csv)} download="surprise_export.csv">CSV herunterladen&nbsp;<Icon icon={Icons.FileDownload} /></LinkButton>
  }
}