import _ from "lodash";
import React from "react";
import * as TS from "../../../../types";

type BuddyUpsellsProps = {
  adventure: any
}
function BuddyUpsells({adventure}: BuddyUpsellsProps) {
  const buddyUpsells = TS.AdventureOld.getBuddyUpsells(adventure);

  if(buddyUpsells.length > 0) {
    return buddyUpsells.map((bus:any) => {
      const payerName = [_.get(bus, "payer.firstName"), _.get(bus, "payer.lastName")].filter(s => !_.isNil(s)).join(" ").trim();
      const payerEmail = _.get(bus, "payer.email");
      const payer = [];
      if(payerName.length > 0) {
        payer.push(payerName);
      }
      if(payerEmail) {
        payer.push(`<${payerEmail}>`);
      }
      return <div key={bus._id}>{bus.currency} {bus.amount.toFixed(2)}, Käufer: {payer.join(" ")}</div>
    });
  }
  else {
    return "-";
  }
}

export default BuddyUpsells