import * as DA from "../../../types/DataAccess";
import { Switchboard, Td, Tr } from "../../controls";
import { FormData } from "./VendorInvoice";


type PaymentTypeKindProps = {
  formData: FormData,
  disabled?: boolean,
  onChange: (kind: DA.PaymentTypeKind) => void
}
export default function PaymentTypeKind({formData, disabled, onChange} : PaymentTypeKindProps) {
  // if we already have an invoice this can no longer be changed
  if(formData.isUpdate) {
    return null;
  }

  // no invoice yet, the user may select
  return (
    <Tr>
      <Td label>Zahlungsart</Td>
      <Td>
        <Switchboard 
          items={[
            {label:"Rechnung", value:"bank"},
            {label:"Kartenzahlung", value:"creditcard"},
            {label:"Vorauszahlung", value:"employee"}
          ]} 
          selectedValues={[formData.paymentTypeKind]} 
          minItems={1} maxItems={1} 
          onChange={v => onChange(v[0])}
          disabled={disabled}
        />
      </Td>
    </Tr>
  )
}
