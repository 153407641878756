export enum AdventureStatus {
  OrderCancelled = 1,
  Ordered = 2,
  DateSelected = 3,
  ConditionsSent = 4,
  ConditionsAccepted = 5,
  Ready = 6,
  Finished = 7,
  Reviewed = 8,
  Archived = 9,
  Locked = 10,
  Prepaid = 11,
  BuddySelected = 12
}

export const AdventureStatiOrdered: AdventureStatus[] = [
  AdventureStatus.Prepaid,
  AdventureStatus.BuddySelected,
  AdventureStatus.OrderCancelled,
  AdventureStatus.Ordered,
  AdventureStatus.DateSelected,
  AdventureStatus.ConditionsSent,
  AdventureStatus.ConditionsAccepted,
  AdventureStatus.Ready,
  AdventureStatus.Finished,
  AdventureStatus.Reviewed,
  AdventureStatus.Archived,
  AdventureStatus.Locked
]