import { WeatherConditionMapper } from "./WeatherCondition.Mapper";
import { WeatherCondition } from "./WeatherCondition";
import { RepositoryFactory } from "../_Common/RepositoryFactory";


export class WeatherConditionRepository {
  // mapper
  private static mapper = new WeatherConditionMapper();
  
  // factory methods
  static make = RepositoryFactory.make<WeatherCondition, WeatherConditionMapper>(this.mapper);
  static search = RepositoryFactory.search<WeatherCondition, WeatherConditionMapper>(this.mapper);
  static create = RepositoryFactory.create<WeatherCondition, WeatherConditionMapper>(this.mapper);
  static update = RepositoryFactory.update<WeatherCondition, WeatherConditionMapper>(this.mapper);
  static findById = RepositoryFactory.findById<WeatherCondition, WeatherConditionMapper>(this.mapper);
  // additional
  static async searchAll(): Promise<WeatherCondition[]> {
    return WeatherConditionRepository.search({});
  }
}

