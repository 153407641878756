import _ from "lodash";
import moment from "moment";
import Api from "../../util/api2";
import * as TS from "..";

export type DunningLevel = 0|1|2|3;

export class Dunning {
  static async getUnpaidSurprises(level: DunningLevel): Promise<any> {
    // get unpaid surprises
    const filter = {$and:[
      {PaymentDate:{$eq:null}},
      {PaymentType:"transfer"},
      {Status:{$ne:TS.AdventureStatus.OrderCancelled}},
      {Status:{$ne:TS.AdventureStatus.Archived}},
      {Status:{$ne:TS.AdventureStatus.Locked}},
      {"Order.Prepaid.Value":{$exists:false}},
      {IsSoldOffline:{"$ne":true}},
      {IsTest:false}, 
      {PaymentReminderLevel:level},
      {DunningBlock:{$ne:true}}
    ]}
    const unpaidSurprises = await TS.AdventureOld.search(filter, {});
    return unpaidSurprises;
  }

  static async getOverdueSurprises(level: DunningLevel): Promise<any> {
    // get unpaid
    const unpaidSurprises = await Dunning.getUnpaidSurprises(level);
  
    // depending on the level we have varying grace periods
    // - level 0: 20 days after order (CreateDate)
    // - level 1: 14 days after first reminder (PaymentReminderSentDate)
    // - level 2: 14 days after second reminder (reminder date 2)
    const overdueSurprises = unpaidSurprises.filter((s:any) => {
      let result = false;
      switch(s.PaymentReminderLevel) {
        case 0:
          const daysSinceOrder = moment(new Date()).diff(moment(s.CreateDate), "days");
          result = daysSinceOrder > (20 + 14);
          break;
        case 1:
          const daysSinceFirstReminder = moment(new Date()).diff(moment(s.PaymentReminderSentDate));
          result = daysSinceFirstReminder > 14;
          break;
        case 2:
          const daysSinceSecondReminder = moment(new Date()).diff(moment(s.PaymentReminder2SentDate));
          result = daysSinceSecondReminder > 14;
          break;
        case 3:
          result = true;
          //const daysSinceSecondReminder = moment(new Date()).diff(moment(s.PaymentReminder2SentDate));
          //result = daysSinceSecondReminder > 14;
          break;
        default: 
          result = false
          break;
      }
      return result;
    });
  
    // add some additional 
  
    // done
    return overdueSurprises
  }

  static async getDunningBlockedSurprises(): Promise<any> {
    // get unpaid items of the requested dunning level
    const filter = {DunningBlock:true}
    const dunningBlockedSurprises = await TS.AdventureOld.search(filter, {});
    return dunningBlockedSurprises;
  }

  static async getLockedSurprises(): Promise<any> {
    const filter = {Status:TS.AdventureStatus.Locked}
    const lockedSurprises = await TS.AdventureOld.search(filter, {});
    return lockedSurprises;
  }

  /**
   * Surprises that received dunning level 3 TODAY (and need to have physical letter sent)
   */
  static async getMailboxSurprises(): Promise<any> {
    const level3 = await Dunning.getUnpaidSurprises(3);
    const today = moment(new Date()).hours(0).minutes(0).seconds(0).milliseconds(0);
    const mailboxSurprises = level3.filter((s:any) => {
      return _.isNil(s.PaymentReminder3SentDate) === false && moment(s.PaymentReminder3SentDate) > today;
    });
    return mailboxSurprises;
  }

  static async setDunningBlock(surprise:any, active:boolean): Promise<void> {
    // set/unset dunning block
    const DunningBlock = active ? true : false;
    const DunningBlockDate = DunningBlock ? null : new Date(); // reset date to null when unblocking
    const changeset = { DunningBlock, DunningBlockDate };
    await TS.AdventureOld.update(surprise, changeset);
    // write log entry
    const logEntry = {
      CreateDate: new Date(),
      Text: `Mahnsperre ${DunningBlock ? "gesetzt" : "aufgehoben"}`,
      Operator: "robot",
      Type: "mahnsperre",
      ShowWarning: false
    }
    await TS.AdventureOld.upsertLog(surprise._id, logEntry);
  }
  
  static async increaseLevel(surprise:any): Promise<void> {
    await Api.post("adventures", "increasePaymentReminderLevel", {adventureId:surprise._id});
  }
  
  static async lockSurprise(surprise:any): Promise<void> {
    // change status to LOCKED
    // TODO should use Adventure Type to make sure the business rule OldStatus <- Status is followed so we don't have to implement it here
    const changeset = {
      OldStatus: surprise.Status,
      Status: TS.AdventureStatus.Locked
    }
    await TS.AdventureOld.update(surprise, changeset);
    // write log entry
    const logEntry = {
      CreateDate: new Date(),
      Text: `Überraschung nach mehrfacher Mahnung gesperrt.`,
      Operator: "robot",
      Type: "dunning_lock",
      ShowWarning: false
    }
    await TS.AdventureOld.upsertLog(surprise._id, logEntry);
  }
  
} 
