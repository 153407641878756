import { AdventureTaskMapper } from "./AdventureTask.Mapper";
import { AdventureTask } from "./AdventureTask";
import { RepositoryFactory } from "../_Common/RepositoryFactory";


export class AdventureTaskRepository {
  private static mapper = new AdventureTaskMapper();
  
  // factory methods
  static make = RepositoryFactory.make<AdventureTask, AdventureTaskMapper>(this.mapper);
  static search = RepositoryFactory.search<AdventureTask, AdventureTaskMapper>(this.mapper);
  static create = RepositoryFactory.create<AdventureTask, AdventureTaskMapper>(this.mapper);
  static update = RepositoryFactory.update<AdventureTask, AdventureTaskMapper>(this.mapper);
  static remove = RepositoryFactory.remove<AdventureTask, AdventureTaskMapper>(this.mapper);

  // additional
  static async findByAdventure(adventureId:string): Promise<AdventureTask[]> {
    const tasks = await AdventureTaskRepository.search({adventureId});
    return tasks;
  }
  public static async findOpen() : Promise<AdventureTask[]> {
    const tasks = await AdventureTaskRepository.search({dateCompleted:null});
    return tasks;
  }
}

