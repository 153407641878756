import _ from "lodash";
import React, {useContext, useState} from "react";
import {ConfirmButton} from "../../../../controls";
import {SurpriseContext} from "../../Surprise.Context";
import * as TS from "../../../../../types";

export default function SendScheduledWithoutPayment() {
  // context
  const SC = useContext(SurpriseContext);

  // state
  const [info, setInfo] = useState(null);
  const [busy, setBusy] = useState(false);

  // event handlers
  const sendMail = async() => {
    setBusy(true);
    const result = await SC.sendScheduledWithoutPaymentMail();
    setBusy(false);
    if(result.success) {
      setInfo("Das Mail wurde erfolgreich verschickt.");
    }
    else {
      setInfo("Das Mail konnte nicht verschickt werden. Fehler: " + JSON.stringify(result.error));
    }
  }

  // render
  if(_.isNil(SC.surprise.PaymentDate) && SC.surprise.PaymentType === 'transfer' && SC.surprise.Status >= TS.AdventureStatus.DateSelected) {
    return (
      <div>
        <ConfirmButton onConfirm={sendMail} busy={busy}>Zahlungsaufforderung nach Einlösung</ConfirmButton>
        {info ? <div style={{fontSize:"10px", padding:"0 4px 0 4px"}}>{info}</div> : null}
      </div>
    )
  }
  else {
    return null
  }
}
