import { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import api from "./../../util/api";
import CSS from "./Login.module.scss";
import images from "./../../images"

type LoginProps = {
  onLogin: () => void
}
export default function Login({onLogin}: LoginProps) {
  // state
  const [loginFailed, setLoginFailed] = useState<boolean>(false);
  const [authError, setAuthError] = useState<string|null|undefined>(null);

  const loadUser = async(id_token:string) => {
    const url = `auth/loginWithIdToken/${encodeURIComponent(id_token)}`;

    try {
      const data = await api.get(url, null, '');
      if(data.error) {
        setAuthError(data.error.message);
      }
      else {
        window.localStorage.setItem('appentura_token', data.token)
        window.localStorage.setItem('appentura_token_exp', data.token_exp)
        window.localStorage.setItem('appentura_user', JSON.stringify(data.user))

        onLogin();
      }
    }
    catch (e:any) {
      console.error(e);
      setAuthError(e.message || "Unknown error");
    }


  }

  // pre-render
  let errorMessage = null;
  if(loginFailed) {
    errorMessage = (
      <div className={CSS.error}>Fehler beim Login mit Google</div>
    );
  }
  if(authError) {
    errorMessage = (
      <div className={CSS.error}>Fehler beim Authentifizieren: {authError}</div>
    );
  }

  // render
  return <>
    <div className={CSS.container}>
      <div className={CSS.inner}>
        <div className={CSS.logo}>
          <img src={images.logo.white} alt="appentura logo" />
        </div>
        <div className={CSS.info}>
          mit deinem @appentura Account anmelden
        </div>
        <GoogleLogin
          onSuccess={credentialResponse => {
            let id_token = credentialResponse.credential;
            loadUser(id_token!);
            console.log(credentialResponse);
            //onLogin();
          }}
          onError={() => {
            setLoginFailed(true);
          }}
        />
        {errorMessage}
      </div>
    </div>
  </>;
}



type FooProps = {
  isSet:boolean
}
function Foo({isSet}:FooProps) {

  // return
  return <div>asdfsa</div>
}