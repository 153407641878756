import React, { useEffect, useState } from "react";
import _ from "lodash";
import Util from "../../../util/util";
import * as DA from "../../../types/DataAccess";
// components
import { Breadcrumb, Heading, Card, List, ListColumn } from "../../controls";
import { Redirect } from 'react-router-dom';

// list columns
const columns: ListColumn[] = [
  {
    header: "Firma", path: "firma", 
    print: (v:any) => v.__company,
    sort: (a:any, b:any) => a.__company.localeCompare(b.__company)
  },
  {
    header: "erstellt am", path: "createdAt",
    print: (v:any) => Util.printDate(v.createdAt),
    sort: (a:any, b:any) => Util.printSortableDate(a.createdAt).localeCompare(Util.printSortableDate(b.createdAt))
  }
];

function BulkOrderList() {
  // state management
  const [items, setItems] = useState<any>(null);
  const [redirectToCreate, setRedirectToCreate] = useState<boolean>(false);

  // mount
  useEffect(() => {
    load()
  }, []);

  // loads all items
  const load = async() => {
    let items = await DA.BulkOrderRepository.findAll();
    items.forEach((item:any) => {
      item.__company = _.get(item, "customer.company");
    })
    setItems(items);
  }

  // redirect to create?
  if(redirectToCreate) {
    return <Redirect to="/bulkorders/create" />
  }

  // render
  return <>
    <Breadcrumb 
      links={[ {title:'Home', to:'/'}]}
      location="Sammelbestellungen" 
    />
    <Heading>Sammelbestellungen</Heading>
    <Card>
      <List 
        onClickCreate={() => setRedirectToCreate(true)}
        columns={columns} 
        items={items} 
        getKey={v => v._id} 
        rowTo={(v) => `/bulkorders/${v._id}`}
      />
    </Card>
  </>
}

export default BulkOrderList