import _ from "lodash";
import React, {useContext, useEffect, useState} from "react";
import * as TS from "../../../../types";
import * as BL from "../../../../types/BusinessLogic";
import {SurpriseContext} from "../Surprise.Context";
import {Checkbox, Form, TextArea, Validators} from "../../../controls";

type LogEditProps = {
  entry: any,
  stopEdit: () => void,
}
export default function LogEdit({entry, stopEdit} : LogEditProps) {
  // context
  const SC = useContext(SurpriseContext);

  // state
  const [entity, setEntity] = useState<any|null>(null);

  // mount
  useEffect(() => {
    const load = async() => {
      const currentUser = await BL.User.getCurrent();
      if(entry) {
        setEntity(_.clone(entry));
      }
      else {
        setEntity({
          CreateDate: new Date(),
          Text: "",
          Operator: currentUser.email,
          Type: null,
          ShowWarning: false
        });
      }
    }
    load();
  }, []);

  // form save event handler
  const save = async(formData:any) => {
    // get data
    let updatedEntry = {};
    _.merge(updatedEntry, entity);
    _.merge(updatedEntry, formData.changeset);
    // save
    await TS.AdventureOld.upsertLog(SC.surprise._id, updatedEntry);
    
    // update log in context
    SC.loadLog();
    stopEdit();
    
  }

  // form cancel event handler
  const cancel = () => stopEdit();
  
  // render
  return (
    <div id="v-surprise-log-edit">
      <Form entity={entity} 
        onSave={save} onCancel={cancel}
        busy={false}
      >
        <TextArea label="Eintrag" path="Text" validate={Validators.isRequired()} />
        <Checkbox label="als Warnung anzeigen" path="ShowWarning" />
      </Form>
    </div>
  );
}