import _ from "lodash";
import React, { useState, useEffect } from "react";
import {Redirect} from 'react-router-dom';
import Util from "../../../util/util";
import * as TS from "../../../types";
import * as BL from "../../../types/BusinessLogic";
import * as DA from "../../../types/DataAccess";
// components & subviews
import {Alert, Breadcrumb, Button, Card, ConfirmButton, Form, Heading, Icon, Icons, Link, Loader, TextArea, Validators, Dropdown, Modal} from "../../controls";
// styling
import "./IncidentProcessing.Item.scss";

export default function IncidentProcessingItem(props:any) {
  // state
  const [surpriseId, setSurpriseId] = useState<string|null>(null);
  const [data, setData] = useState<any>(null);
  const [redirectToList, setRedirectToList] = useState<boolean>(false);
  const [incidentToEdit, setIncidentToEdit] = useState<any|null>(null);

  // mount to get route id
  useEffect(() => {
    const surpriseId = Util.getRouteId(props);
    setSurpriseId(surpriseId);
  }, []);
  // mount to load
  useEffect(() => {
    if(surpriseId !== null) {
      load(surpriseId);
    }
  }, [surpriseId]);

  // loads data
  const load = async(surpriseId:any) => {
    setData(null);
    const surprise = await TS.Adventure.load(surpriseId);
    const incidents = await BL.IncidentManagement.getIncidentsForAdventure(surpriseId);
    const categories = await DA.IncidentCategoryRepository.searchAll();
    const currentUser = await BL.User.getCurrent();
    const providers = await BL.IncidentManagement.getProvidersForAdventure(surpriseId);
    setData({surprise, incidents, categories, currentUser, providers});
  }

  // reloads data (only incident items)
  const reload = async(surpriseId:any) => {
    const updatedData = {...data};
    setData(null);
    updatedData.incidents = await BL.IncidentManagement.getIncidentsForAdventure(surpriseId);
    setData(updatedData);
  }

  // user wants to add/edit entry
  const onClickAddEdit = (incident:any) => {
    incident = incident || {
      surpriseId: data.surprise._id,
      categoryId: "", 
      subcategoryId: "",
      blamedProviderId: null,
      blamedProviderName: null,
      notes: "",
      operator: data.currentUser.email
    };
    setIncidentToEdit(incident);
    // MC.push(title, <Edit incident={incident} categories={data.categories} providers={data.providers} reload={() => reload(surpriseId)} closeDialog={closeDialog} />);
  }

  // user wants to delete an entry
  const onClickDelete = async(incident:any) => {
    await DA.IncidentRepository.remove(incident._id);
    load(surpriseId);
  }

  // user wants to finalize
  const onClickFinalize = async() => {
    await BL.IncidentManagement.finalize(data.surprise._id);
    setRedirectToList(true);
  }

  // closes dialog
  const closeDialog = () => {
    setIncidentToEdit(null);
  }

  // redirect instead of render?
  if(redirectToList) {
    return <Redirect to="/incidents/process" />
  }

  // pre-render
  let content;
  if(_.isNil(data)) {
    content = <Loader text="... lade Daten" />
  }
  else {
    const linkToSurprise =  <Link to={`/surprise/${data.surprise._id}`}>{data.surprise.ReserverName} für {data.surprise.RecieverName} [{Util.shortObjectId(data.surprise._id, 0, 4)}]</Link>;
    let list = null;
    if(data.incidents.length === 0) {
      list = <Alert intent="info" size="medium" title="Noch kein Eintrag">Es ist noch kein Eintrag vorhanden</Alert>
    }
    else {
      let rows = data.incidents.map((f:any) => {
        const category = data.categories.find((c:any) => c._id === f.categoryId);
        const subcategory = category.subcategories.find((sc:any) => sc._id === f.subcategoryId);
        return <tr key={f._id}>
          <td>{category.title} &gt; {subcategory.title}</td>
          <td>{f.blamedProviderName || ""}</td>
          <td>{_.get(f, "notes") || ""}</td>
          <td>{f.operator.split("@")[0]}</td>
          <td>
            <ConfirmButton onConfirm={() => onClickDelete(f)}><Icon icon={Icons.Trash} /></ConfirmButton>
          </td>
          <td>
            <Button onClick={() => onClickAddEdit(f)} size="small"><Icon icon={Icons.Edit} /></Button>
          </td>
        </tr>
      });
      list = <table className="incidents">
        <thead>
          <tr>
            <td>Kategorie &gt; Unterkategorie</td>
            <td>Anbieter</td>
            <td>Notizen</td>
            <td>Operator</td>
            <td></td>
            <td></td>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    }
    content = <>
      <Heading>Zwischenfälle vearbeiten für Überraschung {linkToSurprise}</Heading>
      <Card id="v-processincidents-item">
        <CustomerFeedback surprise={data.surprise} />
        {list}
        <Button size="small" onClick={onClickAddEdit}><Icon icon={Icons.Plus} /> Eintrag hinzufügen</Button>  
      </Card>
      <Card id="v-processincidents-finalize" title="Abschliessen">
        <ConfirmButton onConfirm={onClickFinalize}><Icon icon={Icons.Lock} /> abschliessen</ConfirmButton>
        <br/><br/>
        <Alert intent="hint" size="small">
          Markiert die Bewertung der Überraschung als abgearbeitet. Sie erscheint dann nicht mehr in der Liste der ausstehenden Zwischenfälle. 
        </Alert>
      </Card>
      <Modal isOpen={incidentToEdit !== null} title="Zwischenfall" onClose={closeDialog}>
        <Edit incident={incidentToEdit} categories={data.categories} providers={data.providers} reload={() => reload(surpriseId)} closeDialog={closeDialog} />
      </Modal>
    </>
  }
  
  // render
  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}, {title:"Bewertungen", to:"/incidents/process"}]} location="Bewertung bearbeiten" />
    {content}
  </>
}
type CustomerFeedbackProps = {
  surprise: any,
}
function CustomerFeedback({surprise} : CustomerFeedbackProps) {
  return <table className="rating"><tbody>
  <tr>
    <td>Allgemein</td>
    <td><Stars count={_.get(surprise, "Rating.Rating") || 0} /></td>
  </tr>
  <tr>
    <td>Organisation</td>
    <td><Stars count={_.get(surprise, "Rating.Rating2") || 0} /></td>
  </tr>
  <tr>
    <td>Anbieter</td>
    <td><Stars count={_.get(surprise, "Rating.Rating3") || 0} /></td>
  </tr>
  <tr>
    <td>Kommentar</td>
    <td>{(_.get(surprise, "Rating.Comment") || "").split('\n').map((l:any, i:number) => <p key={i}>{l}</p>)}</td>
  </tr>
  <tr>
    <td>Feedback</td>
    <td>{(_.get(surprise, "Rating.Feedback") || "").split('\n').map((l:any, i:number) => <p key={i}>{l}</p>)}</td>
  </tr>
</tbody></table>
  
}

type StarsProps = {
  count: number
}
function Stars({count} : StarsProps) {
  // render
  const stars = [1, 2, 3, 4, 5].map(r => {
    const icon = count >= r ? Icons.StarFull : Icons.StarEmpty;
    return <Icon key={r} icon={icon} />
  });
  return <div className="stars">{stars}</div>
}

type EditProps = {
  incident: DA.Incident,
  categories: DA.IncidentCategory[],
  providers: any,
  reload: () => void,
  closeDialog: VoidFunction
}
function Edit({incident, categories, providers, reload, closeDialog} : EditProps) {
  // state
  const [busy, setBusy] = useState<boolean>(false);
  const [options, setOptions] = useState<any>(null);
  const [entity, setEntity] = useState<any>(null);

  // mount
  useEffect(() => {
    // options
    const options:any = {providers: [], categories: []};

    // create provider options
    providers.forEach((p:any) => {
      options.providers.push({value:p.id, label:p.name});
    })
    options.providers.unshift({value:"", label:"kein Anbieter"});

    // create category options
    categories.forEach((c:any) => {
      (c.subcategories || []).forEach((sc:any) => {
        options.categories.push({value:`${c._id}-${sc._id}`, label:`${c.title} > ${sc.title}`});
      })
    });
    options.categories.unshift({value:"", label:"Kategorie wählen ..."})

    // create entity (we do this so we can join and later split category and subcategoryid)
    const entity:any = {...incident};
    entity.blamedProviderId = entity.blamedProviderId || ""; // <select> does not like null values
    if(incident._id) {
      entity.combinedCategoryId = `${_.get(incident, "categoryId")}-${_.get(incident, "subcategoryId")}`;
    }
    else {
      entity.combinedCategoryId = options.categories[0].value;
    }
    
    // update the state
    setEntity(entity);
    setOptions(options);
  }, []);

  // cancel
  const onCancel = () => {
    closeDialog();
  }

  // save
  const onSave = async(formResult:any) => {
    setBusy(true);
    const toSave = formResult.merge(entity);
    const catids = toSave.combinedCategoryId.split('-');
    delete toSave.combinedCategoryId;
    toSave.categoryId = catids[0];
    toSave.subcategoryId = catids[1];
    if(_.isNil(toSave.blamedProviderId) || toSave.blamedProviderId === "") {
      toSave.blamedProviderId = null;
    }
    else {
      toSave.blamedProviderName = providers.find((p:any) => p.id === toSave.blamedProviderId).name;
    }
    
    if(incident._id) {
      await DA.IncidentRepository.update(incident._id, toSave);
    }
    else {
      await DA.IncidentRepository.create(toSave);
    }
    reload(); 
    closeDialog();
  }

  // pre-render
  if(_.isNil(options)) {
    return <Loader />
  }
  // render
  return <>
    <Form onCancel={onCancel} onSave={onSave} entity={entity} busy={busy}>
      <Dropdown path="combinedCategoryId" label="Kategorie und Unterkategorie" options={options.categories} disabled={busy} validate={Validators.isRequired("bitte eine Kategorie und Unterkategorie wählen")} />
      <Dropdown path="blamedProviderId" label="Verantwortlicher Anbieter (optional)" options={options.providers} disabled={busy} />
      <TextArea path="notes" label="Notizen" disabled={busy} />
    </Form>
  </>
}