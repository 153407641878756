import _ from "lodash";
import React, { useState, useEffect } from "react";
import * as TS from "../../../types";

// components & subviews
import { Breadcrumb, Card, Loader, Heading, Icon, Icons, Modal, List, ListColumn } from "../../controls";
import Create from "./ActivityPackage.Create";

const columns: ListColumn[] = [
  {
    key: "title",
    header:"Titel",
    path:"title",
    print: (item:ActivityPackageListItem) => item.title,
    sort: (a:ActivityPackageListItem, b:ActivityPackageListItem) => a.title.localeCompare(b.title)
  },
  {
    key: "slug",
    header: "Url-Erweiterung",
    path: "slug",
    print: (item:ActivityPackageListItem) => item.slug,
    sort: (a:TS.ActivityPackage, b:TS.ActivityPackage) => a.slug.localeCompare(b.slug)
  },
  {
    key: "actvities",
    header: "Aktivitäten",
    path: "activities",
    print: (item:ActivityPackageListItem) => item.activities,
    sort: (a:ActivityPackageListItem, b:ActivityPackageListItem) => a.activities.localeCompare(b.activities)
  },
  {
    key: "isActive",
    header: "aktiv",
    path: "isActive",
    print: (item:ActivityPackageListItem) => item.isActive ? <Icon icon={Icons.Check} /> : <Icon icon={Icons.Cancel} />,
    sort: (a:ActivityPackageListItem, b:ActivityPackageListItem) => a.isActiveSortable > b.isActiveSortable ? 1 : -1
  }
];

type ActivityPackageListItem = {
  id:string, title: string, slug: string, activities: string, isActive: boolean, isActiveSortable: number
}
// the component
export default function ActivityPackageList() {
  // state
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [items, setItems] = useState<ActivityPackageListItem[]>([]);
  const [mode, setMode] = useState<"view"|"create">();
  
  // mount
  useEffect(() => {
    load()
  }, [])

  // loads data
  const load = async() => {
    const packages = await TS.ActivityPackage.loadAll();
    const activityCatalogue = await TS.Activity.allActiveMain();
    const items:ActivityPackageListItem[] = packages.map(p => {
      const activities = p.activities
        .map(pa => {
          const catalogueActivity = activityCatalogue.find(ca => ca._id === pa.activityId);
          return catalogueActivity?.title.de || "???";
        })
        .join(", ");
      return {id:p._id || "", title:p.title, slug:p.slug, activities, isActive: p.isActive, isActiveSortable: p.isActive ? 1 : 0};
    })

    setIsLoading(false);
    setItems(items);
  }

  // user wants to create a new one
  const onClickCreate = () => setMode("create");
  const closeDialog = () => setMode("view");

  // user wants to delete
  const onDelete = async(id:any) => {
    if(id) {
      await TS.ActivityPackage.deleteById(id);
      load();
    }
  }
  
  // pre-render
  let content = null;
  if(isLoading) {
    content = <Loader />;
  }
  else {
    content = <>
      <List 
        columns={columns} 
        items={items} 
        onClickCreate={onClickCreate}
        onClickDelete={(item) => onDelete(item.id)} 
        getKey={(item) => item.id}  
        hideFooter={false}
        hideSearch={false}
        rowTo={(item) => `/activitypackages/${item.id}`}
      />
      <Modal isOpen={mode==="create"} title="Neues Paket erfassen" onClose={closeDialog}>
        <Create closeDialog={closeDialog} />
      </Modal>
    </>
  }
  // render
  
  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}]} location="Aktivitäts-Pakete" />
    <Heading>Aktivitäts-Pakete</Heading>
    <Card id="v-packagingtype-list">
      {content}
    </Card>
  </>
}