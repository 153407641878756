import { ActivityInstructionMapper } from "./ActivityInstruction.Mapper";
import { ActivityInstruction, ActivityInstructionTravel } from "./ActivityInstruction";
import { RepositoryFactory } from "../_Common/RepositoryFactory";


export class ActivityInstructionRepository {
  // mapper
  private static mapper = new ActivityInstructionMapper();
  
  // factory methods
  private static _make = RepositoryFactory.make<ActivityInstruction, ActivityInstructionMapper>(this.mapper);
  static search = RepositoryFactory.search<ActivityInstruction, ActivityInstructionMapper>(this.mapper);
  static create = RepositoryFactory.create<ActivityInstruction, ActivityInstructionMapper>(this.mapper);
  static update = RepositoryFactory.update<ActivityInstruction, ActivityInstructionMapper>(this.mapper);
  static remove = RepositoryFactory.remove<ActivityInstruction, ActivityInstructionMapper>(this.mapper);
  static findById = RepositoryFactory.findById<ActivityInstruction, ActivityInstructionMapper>(this.mapper);

  /**
   * Creates a new activity instruction
   */
  static make(activityId: string) : ActivityInstruction {
    const activityInstruction: ActivityInstruction = this._make();
    activityInstruction.activityId = activityId;
    return activityInstruction;
  }

  /**
   * Creates a new activity instruction travel
   * @returns {ActivityInstructionTravel}
   */
  static makeTravel() : ActivityInstructionTravel {
    return {
      mode: "WALKING",
      start: { lat: 0, lng: 0 },
      destination: { lat: 0, lng: 0 }
    }
  }

  /**
   * Returns all activity instructions
   * @returns {Promise<ActivityInstruction[]>}
   */
  static async findAll(): Promise<ActivityInstruction[]> {
    return ActivityInstructionRepository.search({});
  }
}

