import { PackagingTypeMapper } from "./PackagingType.Mapper";
import { PackagingType, PackagingTypeDeliveryMethod } from "./PackagingType";
import { RepositoryFactory } from "../_Common/RepositoryFactory";


export class PackagingTypeRepository {
  private static mapper = new PackagingTypeMapper();
  
  // from factory
  static make = RepositoryFactory.make<PackagingType, PackagingTypeMapper>(this.mapper);
  static search = RepositoryFactory.search<PackagingType, PackagingTypeMapper>(this.mapper);
  static create = RepositoryFactory.create<PackagingType, PackagingTypeMapper>(this.mapper);
  static update = RepositoryFactory.update<PackagingType, PackagingTypeMapper>(this.mapper);
  static remove = RepositoryFactory.remove<PackagingType, PackagingTypeMapper>(this.mapper);
  static findById = RepositoryFactory.findById<PackagingType, PackagingTypeMapper>(this.mapper);

  /**
   * Finds all packaging types
   * @returns {Promise<PackagingType[]>}
   */
  static async findAll(): Promise<PackagingType[]> {
    return PackagingTypeRepository.search({});
  }

  /**
   * Finds all active packaging types
   * * @returns {Promise<PackagingType[]>}
   */
  static async findAllActive(): Promise<PackagingType[]> {
    return PackagingTypeRepository.search({isActive:true});
  }

  /**
   * Returns all available delivery methods with their labels
   */
  static get deliveryMethods() : {value:PackagingTypeDeliveryMethod, label: string}[] {
    return [
      {value: "letter", label: "Brief"},
      {value: "parcel", label: "Paket"},
      {value: "digital", label: "Digital"},
      {value: "virtual", label: "Virtuell (via Whatsapp, SMS, E-Mail, etc.)"}
    ]
  }
}

