import Api from "../../util/api2";

export type DataWarehouseInfo = {
  adventuresUpdatedOn: Date;
  vouchersRelevantToSalesUpdatedOn: Date;
};

type GetInfoProps = {
  success: boolean,
  info: DataWarehouseInfo
};

export class DataWarehouse {
  static async getInfo(): Promise<DataWarehouseInfo> {
    const result: GetInfoProps = await Api.post("dataWarehouse", "getInfo", {});
    return result.info;
  }

  static async updateAdventures(): Promise<any> {
    const result = await Api.post("dataWarehouse", "updateAdventures", {});
    return result;
  }
  
  static async updateVouchersRelevantToSales(): Promise<any> {
    const result = await Api.post("dataWarehouse", "updateVouchersRelevantToSales", {});
    return result;
  }
}



