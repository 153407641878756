import _ from "lodash"
import React, {useContext, useState, useEffect} from "react"
import { FormContext } from "../../../controls/FormContext";
import { Validation } from "../../../controls";


//import { FormContext } from "./FormContext"

import CSS from "./CountrySelector.module.scss";

type CountrySelectorProps = {
  path:string
}
export function CountrySelector({path}:CountrySelectorProps) {
  // state
  const [values, setValues] = useState<string[]>([]);

  // context
  const form = useContext(FormContext);

  // mount
  useEffect(() => {
    if(form.entity && path) {
      let _values = _.get(form.entity, path) || [];
      setValues(_values);
      form.onFieldChange(path, _values, new Validation(true))
    }
  }, [form.entity, setValues, path])

  // form.resetIndex changed -> reset the item
  useEffect(() => {
    if(form.resetIndex !== 0) {
      setValues(form.getOldValue(path))
    }
  }, [form.resetIndex, path, form]) // TODO why do we not get a warning here for not including setValues in the dependency array?

  // handler
  const onChange = (country:string, checked:boolean) => {
    // pessimitically remove the item
    let _values = values.filter(v => v !== country);
    // add it if needed
    if(checked) {
      _values.push(country);
    }
    
    // inform the form context
    form.onFieldChange(path, _values, new Validation(true))
    // update the state
    setValues(_values);
  }

  // pre-render
  const countries = ["CH", "DE"];
  const items = countries.map((country:string) => {
    const checked = values.includes(country);
    return (
      <label key={country}>
        <input type="checkbox" checked={checked} onChange={(e) => { onChange(country, e.target.checked)}} />
        <span>{country}</span>
      </label>
    )
  })
  // render 
  return (
    <div className={CSS.container}>
      <div className={CSS.label}>Länder</div>
      <div className={CSS.items}>
        {items}
      </div>
    </div>
  )
}

