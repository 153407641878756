import React, {useState} from "react";
import Code from "../Code";
// import Notes from "../Notes";
import { Card, Heading, Value } from "../../../controls";

export default function ValueDemo() {
  const [isOn, setIsOn] = useState(false);
  // render
  return <>
    <Heading>Value</Heading>
    <Card>
      <Value label="Placeholder if no value" placeholder="Placeholder"></Value>
      <Code>{`<Value label="Test" placeholder="Placeholder"></Value>`}</Code>

      <Value label="No placeholder if value provided" placeholder="Placeholder">Hey there</Value>
      <Code>{`<Value label="Test" placeholder="Placeholder">Hey there</Value>`}</Code>

      <Value label="With onClick Callback" onClick={() => alert("Hey there")}>Hey there</Value>
      <Code>{`<Value label="Test" onClick={() => alert("Hey there")}>Hey there</Value>`}</Code>

      <Value label="Disabled" disabled onClick={() => alert("Hey there")}>Hey there</Value>
      <Code>{`<Value label="Test" disabled onClick={() => alert("Hey there")}>Hey there</Value>`}</Code>

      <Value label="Explanation displayed" disabled onClick={() => alert("Hey there")} explanation="Call me, when nobody pick it up">Hey there</Value>
      <Code>{`<Value label="Test" disabled onClick={() => alert("Hey there")} explanation="Call me, when nobody pick it up">Hey there</Value>`}</Code>
    </Card>
  </>
}
