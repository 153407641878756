import React, {useContext, useState} from "react";
import {SurpriseContext} from "../Surprise.Context";
import "./StatusInfo.View.scss";

export default function StatusInfo() {
  // context 
  const SC = useContext(SurpriseContext);
  // state
  // TODO move this to constants.js
  const [texts] = useState({
    1: "Die Bestellung der Überraschung wurde abgebrochen.",
    2: "Die Überraschung wurde bestellt. Eventuell muss der Gutschein (im Brief, Schatzkiste, etc) noch verschickt werden.",
    3: "Der Überraschte hat ein Datum für sein Abenteuer gewählt. Sicherstellen, dass alle Aktivitäten für das Wunschdatum möglich sind.",
    4: "Das Datum der Überraschung ist machbar oder wurde entsprechende von Dir angepasst. Jetzt gilt es, darauf zu warten, dass der beschenkte das Datum akzeptiert.",
    5: "Der Beschenkte hat das von uns vorgeschlagene Datum akzeptiert. Jetzt kannst du daran gehen, Aktivitäten zu buchen und die Schritte zu beschreiben.",
    6: "Die Überraschung ist vollständig organisiert. Alle Buchungen sind getätigt, alle Schritt-Texte geschrieben und der Beschenkte ist informiert ... Zeit zurückzulehnen.",
    7: "Die Überraschung wurde durchgeführt.",
    8: "Die Überraschung wurde durchgeführt und vom Beschenkten bewertet.",
    9: "Die Überraschung wurde archiviert.",
    10: "Die Überraschung wurde gesperrt.",
    11: "Diese Überraschung ist eine Wertgutschrift. Der Beschenkte muss einen Buddy wählen, welcher den Inhalt der Überraschung bestimmen darf."
  })

  
  // render
  let text = "";
  if(SC.surprise.IsSoldOffline) {
    const info = SC.surprise.SoldOfflineInfo
    text = `Die Überraschung ${info.IsSold ? 'wurde' : 'wird'} in einem Laden (${info.Store || 'unbekannt'}) zum Kauf angeboten.`
  }
  else {
    text = texts[SC.surprise.Status];
  }
  return (
    <div id="v-surprise-info-status">
      {text}
    </div>
  );
}
