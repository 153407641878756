import { AdventureStepTemplate, AdventureStepTemplateCoordinates } from "./AdventureStepTemplate";
import { Mapper } from "../_Common/Mapper";

export class AdventureStepTemplateMapper extends Mapper<AdventureStepTemplate> {
  public apiEndpoint = "adventureStepTemplates";

  public toDb(adventureStepTemplate: AdventureStepTemplate): any {
    return {
      _id: adventureStepTemplate._id,
      AdventureTemplateId: adventureStepTemplate.AdventureTemplateId,
      AdventureTemplatePosition: adventureStepTemplate.AdventureTemplatePosition,
      Title: adventureStepTemplate.Title,
      Instructions: adventureStepTemplate.Instructions,
      ImageUrl: adventureStepTemplate.ImageUrl,
      ImageText: adventureStepTemplate.ImageText,
      Name: adventureStepTemplate.Name,
      StartCoordinates: adventureStepTemplate.StartCoordinates ? this.toDb_coordinates(adventureStepTemplate.StartCoordinates) : null,
      EndCoordinates: adventureStepTemplate.EndCoordinates ? this.toDb_coordinates(adventureStepTemplate.EndCoordinates) : null,
      TravelMode: adventureStepTemplate.TravelMode,
      ActivityId: adventureStepTemplate.ActivityId,
      TickerTravel: adventureStepTemplate.TickerTravel,
      TickerMessage: adventureStepTemplate.TickerMessage
    }
  }

  public toDb_coordinates(coordinates: AdventureStepTemplateCoordinates): any {
    return {
      Lat: coordinates.Lat,
      Lng: coordinates.Lng
    }
  }


  public fromDb(obj:any): AdventureStepTemplate {
    return {
      _id: obj._id,
      AdventureTemplateId: obj.AdventureTemplateId || null,
      AdventureTemplatePosition: obj.AdventureTemplatePosition || 0,
      Title: obj.Title || "",
      Instructions: (obj.Instructions || []).map((s:any) => String(s).trim()),
      ImageUrl: obj.ImageUrl || null,
      ImageText: obj.ImageText || null,
      Name: obj.Name || "",
      StartCoordinates: obj.StartCoordinates ? this.fromDb_coordinates(obj.StartCoordinates) : null,
      EndCoordinates: obj.EndCoordinates ? this.fromDb_coordinates(obj.EndCoordinates) : null,
      TravelMode: obj.TravelMode || null,
      ActivityId: obj.ActivityId || null,
      TickerTravel: obj.TickerTravel || null,
      TickerMessage: obj.TickerMessage || "",
    }
  }

  public fromDb_coordinates(obj:any): AdventureStepTemplateCoordinates {
    const Lat: number = obj ? obj.Lat : 0;
    const Lng: number = obj ? obj.Lng : 0;
    return { Lat, Lng };
  }

}