import React, { useContext, useEffect, useState } from "react";
// types
import * as Cms from "../../../../types/Cms";
// contexts and components
import { PageContext } from "../Page.Context";
import { TextInput, TextArea, Form, Paragraphs, CloudStorageUploader, Dropdown } from "../../../controls";
import * as Selectors from "../Selectors";
import * as Preview from "../Preview";

// styling and images
import CSS from "./Basic.module.scss";
// import exampleImage from "./media/example_1_256x256.jpg"

const layoutOptions:any = [
  {value:"stacked", label:"Gestapelt"},
  {value:"beside", label:"Nebeneinander"}
];

type NewsletterProps = {
  instance?:Cms.NewsletterModule,
  closeDialog: () => void,
}

export function Newsletter({instance, closeDialog}:NewsletterProps) {
  // context
  const PC = useContext(PageContext);
  
  // state
  const [entity, setEntity] = useState<Cms.NewsletterModule|null>(null);
  const [busy, setBusy] = useState<boolean>(false);

  // mount
  useEffect(() => {
    let entity: Cms.NewsletterModule;
    if(instance) {
      entity = instance;
    }
    else {
      entity = new Cms.NewsletterModule();
    }
    setEntity(entity)
  }, [])

  // user wants to cancel
  const onCancel = () => {
    closeDialog();
  }

  // user wants to save
  const onSave = async(formResult:any) => {
    setBusy(true);
    const updatedEntity = formResult.merge(entity);
    
    if(formResult.changeset.countries) {
      // TODO we need to do this because formResult.merge does not seem to handle arrays correctly
      updatedEntity.countries = formResult.changeset.countries;
    }

    await PC.upsertModule(updatedEntity);
    // close dialog
    closeDialog();
  }
  
  // render
  return (
    <Form entity={entity} onCancel={onCancel} onSave={onSave} busy={false}>
      <Selectors.BaseProperties busy={busy} />
      <TextInput label="Tag" path="elements.tag" placeholder="Tag eingeben" disabled={busy} />
    </Form>
  )
}


type NewsletterPreviewProps = {
  instance: Cms.NewsletterModule,
  expanded?: boolean,
  onClickAction: (module:Cms.Module, action:"edit"|"delete"|"duplicate") => void
}
export function NewsletterPreview({instance, expanded, onClickAction} : NewsletterPreviewProps) {
  return (
    <Preview.Container expanded={expanded} module={instance} title={Cms.NewsletterModule.info.title} onClickAction={onClickAction}>
      <Preview.Table>
        <Preview.TableRow label="Tag">{instance.elements.tag || "-"}</Preview.TableRow>
      </Preview.Table>
    </Preview.Container>
  )
}
