import React, { useEffect, useState } from "react";
import * as DA from "../../../types/DataAccess";
import { Breadcrumb, Card, Heading, List, ListColumn, Loader, Modal } from "../../controls";

import Edit from "./Testimonial.Edit";

const columns: ListColumn[] = [
  {header:"Name", path:"name", key:"name", print: v => v.name, sort: (a, b) => a.name.localeCompare(b.name)},
  {header:"Region", path:"region", key:"region", print: v => v.regionCode, sort: (a, b) => a.regionCode.localeCompare(b.regionCode)}
];

export default function TestimonialList() {
  // state
  const [testimonials, setTestimonials] = useState<DA.Testimonial[]|null>(null);
  const [itemToEdit, setItemToEdit] = useState<DA.Testimonial|null>(null);

  // mount
  useEffect(() => {
    load();
  }, []);

  // loads testimonials
  const load = async() => {
    const testimonials = await DA.TestimonialRepository.findAll();
    setItemToEdit(null);
    setTestimonials(testimonials);
  }

  // user wants to edit
  const onClickEdit = (testimonial: DA.Testimonial) => {
    setItemToEdit(testimonial);
  }

  // user wants to create a new one
  const onClickCreate = () => {
    const testimonial = DA.TestimonialRepository.make();
    setItemToEdit(testimonial);
  }

  // pre-render
  let content = null;
  if(testimonials) {
    content = <List columns={columns} items={testimonials} onRowClick={(item) => onClickEdit(item)} getKey={item => item._id} onClickCreate={onClickCreate} />;
  }
  else {
    content = <Loader />;
  }
  return (
    <>
      <Breadcrumb links={[ {title:'Home', to:'/'}]} location="Testimonials" />
      <Heading>Testimonials</Heading>
      <Card>
        {content}
      </Card>
      <Modal isOpen={itemToEdit !== null} title="Testimonial" onClose={() => setItemToEdit(null)}>
        <Edit testimonial={itemToEdit!} onUpdate={load} onCancel={() => setItemToEdit(null)} />
      </Modal>
    </>
  )
  
}