import { Td, NumberInput, Tr, Validators } from "../../controls";
import { FormData } from "./VendorInvoice";

type AmountProps = {
  formData: FormData,
  disabled: boolean,
  onChange: (amount:number) => void
}
export default function Amount({formData, disabled, onChange} : AmountProps) {
  let content = null;

  if(formData.isReadOnly) {
    content = <span>{formData.amount.toFixed(2)}</span>;
  }
  else {
    content = (
      <NumberInput 
        value={formData.amount} 
        onChange={(value, validation) => onChange(value)}
        validate={Validators.isGreaterThan0("muss grösser als 0 sein")} 
        disabled={disabled}
      />
    );
  }
  return (
    <Tr>
      <Td label>Betrag</Td>
      <Td>{content}</Td>
    </Tr>
  )
}
