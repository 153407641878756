// libs
import React, {useState, useEffect} from "react";
import moment from "moment"
import { initializeApp } from "firebase/app"
import { getDatabase, ref, onValue, onChildAdded } from "firebase/database"
// config, data,util
import config from "./../../../config"
import Api from "./../../../util/api"
import { DATE_FORMATS } from "../../../config/constants"
import * as TS from "../../../types";
// components
import {Button, Breadcrumb, Card, Icon, Icons, Link, Switchboard } from "../../controls";
// styling & images
import "./Live.scss";
import images from "./../../../images";

// initialize firebase
const TICKER_APP_NAME = config.firebase.ticker.projectId // we use the project name as the app name
const firebaseApp = initializeApp(config.firebase.ticker, TICKER_APP_NAME);

export default function Live() {
  // state
  const [events] = useState([]);
  const [initialDataProcessed] = useState({}); // {<surprise_id>:<bool>}
  const [surprises, setSurprises] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(moment());
  const [solo, setSolo] = useState(null); // surprise id if one should be displayed alone
  const [alertsToShow, setAlertsToShow] = useState(["relevant"]); // "relevant"|"all"

  // mount
  useEffect(() => {
    const load = async() => {
      const surprises = await loadSurprises();
      setSurprises(surprises);
    }
    load();
    
  }, [])

  // surprises changed
  useEffect(() => {
    surprises.forEach(surprise => {
      if(surprise.LiveId) {
        const db = getDatabase(firebaseApp);

        // initial load
        const ref_surprise = ref(db, `surprise/${surprise.LiveId}`);
        onValue(ref_surprise, snapshot => {
          // get the events
          const data = snapshot.val();
          if(data) {  
            for(let key in data.events) {
              processEvent(data.events[key], key, surprise);
            }
            // mark this surprise as having its initial data processed
            initialDataProcessed[surprise._id] = true;
            
            // update state
            setLastUpdate(moment()); // to enforce re-rendering
          }
        }, {onlyOnce:true});

        // event added
        const ref_surprise_events = ref(db, `surprise/${surprise.LiveId}/events`);
        onChildAdded(ref_surprise_events, snapshot => {
          if(initialDataProcessed[surprise._id]) {
            const key = snapshot.key;
            if(events.some(e => e.key === key) === false) {
              const event = snapshot.val();
              if(processEvent(event, key, surprise)) {
                setLastUpdate(moment()); // to enforce re-rendering
              }
            }
          }
        })
      }
    })

    
  }, [surprises])

  // procesess event
  const processEvent = (event, key, surprise) => {
    // already collected? abort
    if(events.some(e => e.key === key)) {
      return false;
    }

    // unwrapp
    const unwrapped = JSON.parse(JSON.stringify(event));
    unwrapped.surprise = {_id:surprise._id, RecieverName:surprise.RecieverName}
    unwrapped.key = key
    unwrapped.when = new Date(event.when)
    
    // update surprise progression
    if(unwrapped.what === "step") {
      const currentStep = Number(unwrapped.step);
      surprise.CurrentStep = currentStep + 1;
      if(surprise.CurrentStep > surprise.MaxStep) {
        surprise.MaxStep = surprise.CurrentStep
      }
    }

    // add to events
    events.push(unwrapped);

    // done
    return true;

  }

  // pre-render
  
  let content = <>
    <Card>
      <div id="v-live-meta">
        letztes Update:<span className="when">{lastUpdate.format("hh:mm:ss")}</span>
      </div>
    </Card>

    <Card title="Alerts">
      <Alerts surprises={surprises} alertsToShow={alertsToShow} setAlertsToShow={(v) => setAlertsToShow(v)} />
    </Card>

    <Card title="Events">
      <div id="v-live-content">
        <Surprises surprises={surprises} solo={solo} showAll={() => setSolo(null)} showOne={id => setSolo(id)} />
        <Events events={events} solo={solo} />
      </div>
    </Card>
  </>
  // render
  return (
    <div id="v-live">

      <Breadcrumb links={[ {title:'Home', to:'/'}]}
                  location="Live" />
      {content}
    </div>
  )
}

function Surprises({surprises, solo, showAll, showOne}) {
  // render items
  let items = surprises
    .filter(surprise => {
      if(solo) {
        return surprise._id === solo
      }
      else {
        return true
      }
    })
    .sort((a, b) => {
      return a.StartTime > b.StartTime ? 1 : -1
    })
    .map(surprise => {
      let steps = (surprise.Steps || []).map((step, index) => {
        let inner = index === (surprise.CurrentStep - 1) ? <span className="step-circle-current"></span> : null
        return (
          <span key={step._id} className={`step-circle${index < surprise.MaxStep ? '-filled':''}`}>{inner}</span>
        )
      })

      return (
        <div key={surprise._id} className="surprise">
          <UserCircle surprise={surprise} size="regular" onClick={() => showOne(surprise._id)} /> 
          <div className="info">
            <div className="link-admin">
              <Link to={`surprise/${surprise._id}`} target="_blank">
                {surprise.RecieverName}
              </Link>
            </div>
            <div className='start-and-link-live'>
              <div className="start">
                Start: <span className="when">{surprise.StartedYesterday ? 'gestern ':''}{surprise.StartTime.format(DATE_FORMATS.TIME)}</span>
              </div>
              <div className='link-live'>
                <Link to={config.surprise.ticker_url(surprise)}>Live-View</Link>
              </div>
            </div>


          </div>
          <div className="steps">
            {steps}
          </div>
        </div>
      )
    })

  if(items.length === 0) {
    items = (
      <div className="no-surprises">keine aktive Überraschungen</div>
    )
  }

  // pre-render solo off
  let soloOff = null;
  if(solo) {
    soloOff = (
      <div className="solo-off">
        <Button onClick={() => showAll()} size="small">alle anzeigen</Button>
      </div>
    )
  }

  // render
  return (
    <div id="v-live-surprises">
      {items}
      {soloOff}
    </div>
  )
  
}

function Events({events, solo}) {
  const items = events
    .filter(event => event.what !== 'step')
    .filter(event => event.what !== 'activity')
    .filter(event => event.what !== 'announcement')
    .filter(event => event.what !== 'travel')
    .filter(event => {
      if(solo) {
        return event.surprise._id === solo
      }
      else {
        return true
      }
    })
    .sort((a,b) => a.when > b.when ? -1 : 1)
    .map(event => {
      let content
      let icon
      let additional = null
      switch(event.what) {
        case "instruction":
          content = `(${event.step + 1}) - ${event.text}`
          icon = null
          break;
        case "start":
          content = 'Start'
          icon = images.surprise.event.start
          break
        case "end":
          content = 'Ende'
          icon = images.surprise.event.finish
          break
        case "message":
          content = `${event.surprise.RecieverName}: "${event.text}"`
          icon = images.surprise.event.message
          break
        case "selfie":
          content = <Link to={event.image} target="_blank"><img src={event.imageThumbnail || event.image} alt={`image_${event.key}`} /></Link>
          icon = images.surprise.event.selfie
          additional = (
            <div className="marketing-flag">
              <Icon intent={event.marketingAllowed ? "confirm" : "error"} icon={event.marketingAllowed ? Icons.ThumbsUp : Icons.ThumbsDown} />
              <span className="text">
                {event.marketingAllowed ? "Marketing erlaubt" : "Kein Marketing"}
              </span>
            </div>
          )
          break
        default:
          content = 'unbekannter Event'
          icon = images.surprise.event.activity
          break
      }
      let icon_div = null
      if(icon) {
        icon_div = (
          <div className="icon" style={{backgroundColor:stringToColor(event.surprise.RecieverName || '?????????')}}>
            <img src={icon} alt={"event icon"} />
          </div>
        )
      }
      return (
        <div className="event" key={event.key}>
          <UserCircle surprise={event.surprise} size="regular" />
          <div className="content">
            <div className="what">
              {content}
            </div>
            {additional}
            <div className="when">
              {moment(event.when).format(DATE_FORMATS.TIME)}
            </div>
          </div>
          {icon_div}
        </div>
      )

    })

  return (
    <div id="v-live-events">
      {items}
    </div>
  )
}

function Alerts({surprises, alertsToShow, setAlertsToShow}) {
  let alerts = [];
  surprises.forEach(surprise => {
    // add start alert (but only if there are are ANY steps in the first place)
    if((surprise.Steps|| []).length > 0) {
      alerts.push({
        kind:'start',
        date:moment(surprise.StartTime),
        step:surprise.Steps[0],
        stepIndex:0,
        surprise:surprise
      })
    }
    
    // add step alerts
    surprise.Steps
      .forEach((step, index) => {
        if(step.AlertDate !== null) {
          alerts.push({
            kind:'step',
            date:moment(step.AlertDate),
            step:step,
            stepIndex:index,
            surprise: surprise
          })
        }
      })
  })

  let isRelevant = (alert) => {
    let relevant = false
    switch(alert.kind) {
      case 'step':
        relevant = (alert.stepIndex >= alert.surprise.CurrentStep) && (alert.stepIndex >= alert.surprise.MaxStep)
        break
      case 'start':
        relevant = alert.surprise.CurrentStep === 0
        break
      default:
        relevant = false
        break
    }
    return relevant

  }

  let isInTimeframe = (alert) => {
    let inTimeframe = false
    switch(alert.kind) {
      case 'step':
      case 'start':
        inTimeframe = alert.date <= moment().add(1, 'hours')
        break
      default:
        inTimeframe = false
        break
    }
    return inTimeframe
  }

  let items = alerts
    .filter(alert => {
      if(alertsToShow.includes("all")) {
        return true
      }
      else {
        // exclude does that are no longer relevant
        return isRelevant(alert) && isInTimeframe(alert)
      }
    })
    .sort((a, b) => a.date > b.date ? 1 : -1)
    .map(alert => {
      let css_relevant = isRelevant(alert) ? 'active' : 'inactive'
      let title
      if(alert.kind === "start") {
        title = `Start`
      }
      else {
        title = `${alert.stepIndex + 1}) ${alert.step.Title}`
      }
      return (
        <div className="alert" key={alert.step._id}>
          <UserCircle surprise={alert.surprise} size="small" />
          <span className={`when ${css_relevant}`}>{moment(alert.date).format(DATE_FORMATS.DATE_AND_TIME)}</span>
          <Link className={`step ${css_relevant}`} to={`surprise/${alert.surprise._id}`} target="_blank">
            {title}
          </Link>
        </div>
      )
    })

  const switchboardItems = [
    {value:"relevant", label: "in der nächsten Stunden"},
    {value:"all", label: "alle"}
  ];

  return (
    <div id="v-live-alerts">
      <Switchboard items={switchboardItems} selectedValues={alertsToShow} onChange={v => setAlertsToShow(v)} minItems={1} maxItems={1} />
      {items}
    </div>
  )
}

function UserCircle({surprise, size, onClick}) {
  const username = surprise.RecieverName || "????????";
  const username_short = username.substr(0, 2);
  // render
  return (
    <span className={`user-circle ${size}`} style={{backgroundColor:stringToColor(username)}} onClick={onClick} data-surprise={surprise._id}>
      {username_short}
    </span>
  )

}

async function loadSurprises() {

  let yesterday = moment(`${moment().format('YYYY-MM-DD')}T23:59:59.999`).add(-1, 'days')
  let tomorrow = moment(`${moment().format('YYYY-MM-DD')}T00:00:00.000`).add(1, 'days')
  let filter = {
    "$and":[
      {Status: TS.AdventureStatus.Ready},
      {IsTest:false},
      {"$or":[
          {"$and":[
              {StartTime:{"$gt":yesterday.toISOString()}},
              {StartTime:{"$lt":tomorrow.toISOString()}}
            ]},
          {"$and":[
              {EndTime:{"$gt":yesterday.toISOString()}},
              {EndTime:{"$lt":tomorrow.toISOString()}}
            ]}
        ]},
    ]

  }
  let projection = {
    StartTime:1,
    RecieverName:1,
    Steps:1,
    CurrentStep:1,
    MaxStep:1,
    LiveId:1
  }
  let result = await Api.post('adventures/search', {filter, projection})
  let today = moment()
  let surprises = result.data.map(surprise => {
    surprise.StartTime = moment(surprise.StartTime)
    surprise.StartedYesterday = surprise.StartTime.date() !== today.date() // Note: while mathematically incorrect this is good enough an evaluation for our purposes
    return surprise
  })


  return surprises

}



function stringToColor(s) {
  let hash = 0;
  for (let i = 0; i < s.length; i++) {
    hash = s.charCodeAt(i) + ((hash << 5) - hash)
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2)
  }
  return colour
}










