import React, { useState } from "react";
import _ from "lodash";
import Code from "../Code";
import Notes from "../Notes";
import { Card, Form, TextInput, CloudStorageUploader, Heading } from "../../../controls";

export default function CloudStorageUploaderDemo() {
  return <>
    <Heading>CloudStorageUploader</Heading>
    <Card title="Notes">
      Can be used to upload PDFs or Images to Google Cloud storage. Set the <code>kind</code> property to either <code>"pdf"</code> or <code>"image"</code>
    </Card>
    <WithForm />
    <WithoutForm />
    <DisableUrlEditing />
  </>
}

function WithForm() {
  const [entity] = useState({url:"https://storage.googleapis.com/appentura/shippingtypes/1574672831074_Metallbox.jpg", alt: null});

  const save = async(formData:any) => {
    let newEntity = {}
    _.merge(newEntity, entity)
    _.merge(newEntity, formData.changeset)
  }

  return <>
    <Card title="Inside Form">
      <Form entity={entity} onSave={save}>
        <TextInput label="Alt" path="alt" />
        <CloudStorageUploader kind="image" folder="misc" prefix="test" path="url" label="Image hochladen" disableUrlEditing={false} />
      </Form>
    </Card>
  </>
}

function WithoutForm() {
  const [imageUrl, setImageUrl] = useState<string>("https://storage.googleapis.com/appentura/activities/1582874398746_3-Gang_Wok_Dinner_Shop3.jpeg")

  return (
    <Card title="Without Form">
      <CloudStorageUploader kind="image" folder="misc" prefix="test" onChange={v => setImageUrl} value={imageUrl} />
    </Card>
  )
}

function DisableUrlEditing() {
  const [imageUrl, setImageUrl] = useState<string>("https://storage.googleapis.com/appentura/activities/1582874398746_3-Gang_Wok_Dinner_Shop3.jpeg")

  return (
    <Card title="Disable Url Editing">
      <Notes>
        You can disable url editing by setting the <Code inline={true}>disableUrlEditing</Code> property to <Code inline={true}>true</Code>
      </Notes>
      <CloudStorageUploader kind="image" folder="misc" prefix="test" onChange={v => setImageUrl} value={imageUrl} />
    </Card>
  )
}
