import _ from "lodash";
import React, { useContext } from "react";
import { Link } from "../../../../controls";
import { SurpriseContext } from "../../Surprise.Context";
import Row from "./Row";

export default function Buddy() {
  // context 
  const SC = useContext(SurpriseContext);
  // render
  const s = SC.surprise;
  const mailLink = <Link to={`mailto:${_.get(s, "Order.Prepaid.BuddyEmail")}`}>{_.get(s, "Order.Prepaid.BuddyEmail")}</Link>;
  return <Row label="Buddy">{_.get(s, "Order.Prepaid.BuddyName") || "Unbekannt"} ({mailLink})</Row>
}
