import React, {useContext, useState, useEffect} from "react";
import _ from "lodash";

import { FormContext } from "./FormContext";
import { Alert, Label, ValidationError } from ".";
import { Validation } from "./Validation";

import CSS from "./TextInput.module.scss";


type TextInputProps = {
  path?: string,
  value?: string,
  label?: string,
  explanation?: string,
  placeholder?: string,
  disabled?: boolean,
  validate?: any,
  onChange?: (value:string, validation:Validation) => void,
  onBlur?: (e:React.FocusEvent<HTMLInputElement>) => void
}
export function TextInput({path, value, label, explanation, placeholder, disabled, validate, onChange, onBlur} : TextInputProps) {
  // get context
  const form = useContext(FormContext);

  // if we have a form context, we render with form if not without
  if(form && path) {
    // warn about value not being used if provided
    if(value) {
      console.warn("since TextInput is inside a FormContext and a 'path' was provided, the provided 'value' will be ignored");
    }
    // render the form control
    return FormControl({path, label, explanation, placeholder, disabled, validate, onChange, onBlur});
  }
  else if(value !== undefined) {
    // a value was provided
    return Widget({value, label, explanation, placeholder, disabled, validate, onChange, onBlur});
  }
  // something is missing
  return (
    <Alert intent="error" size="small" title="Implementation Error">
      Expected the path property (if control is inside a Form) or the value property.
    </Alert>
  )
}


type FormControlProps = {
  path: string,
  label?: string,
  explanation?: string,
  placeholder?: string,
  disabled?: boolean,
  validate?: any,
  onChange?: (value:any, validation:Validation) => void,
  onBlur?: (e:React.FocusEvent<HTMLInputElement>) => void
}
function FormControl({path, label, explanation, placeholder, disabled, validate, onChange, onBlur}:FormControlProps) {
  // state
  const [initialValue, setInitialValue] = useState<string>("");

  // context
  const form = useContext(FormContext);

  // mount
  useEffect(() => {
    // TODO we always get a form and and entity (ensured in <TextInput />) ... no need to check this
    if(form.entity && path) {
      // setting initial value
      const value = _.get(form.entity, path); // TODO what if no value is found?
      setInitialValue(value);
    }
  }, [form.entity, path])

  // form.resetIndex changed -> reset the item
  useEffect(() => {
    if(form.resetIndex !== 0) {
      setInitialValue(form.getOldValue(path))
    }
  }, [form.resetIndex, path, form])

  // to make sure the displayed value also changes when changed externally (i.e. another TextInput serving the same path or when manually trigger an onChangeField event on FormContext)
  useEffect(() => {
    const newValue = form.getNewValue(path);
    if(newValue) {
      if(form.getNewValue(path) !== initialValue) {
        setInitialValue(newValue);
      }
    }
  }, [form.version]);

  // handler
  const onWidgetChange = (value:string, validation: Validation) => {
    form.onFieldChange(path, value, validation)
    if(onChange) {
      onChange(value, validation);
    }
  }

  // render
  return <Widget value={initialValue} label={label} explanation={explanation} placeholder={placeholder} disabled={disabled} validate={validate} onChange={onWidgetChange} onBlur={onBlur} />
}

type WidgetProps = {
  value: string
  label?: string,
  explanation?: string,
  placeholder?: string,
  disabled?: boolean,
  validate?: (v:string) => Validation,
  onChange?: (value:any, validation: Validation) => void,
  onBlur?: (e:React.FocusEvent<HTMLInputElement>) => void
}
function Widget({value, label, explanation, placeholder, disabled, validate, onChange, onBlur}:WidgetProps) {
  // state
  const [enteredValue, setEnteredValue] = useState<string>(value);
  const [validation, setValidation] = useState<Validation>(new Validation(true));

  // value changes
  useEffect(() => {
    const validation = validate && disabled !== true ? validate(value) : new Validation(true);
    setValidation(validation);
    setEnteredValue(value);
  }, [value, disabled]);

  // handler
  const _onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    // get updated value and validation
    const enteredValue = e.target.value;
    const validation = validate && disabled !== true ? validate(enteredValue) : new Validation(true);
    
    // update state
    setEnteredValue(enteredValue);
    setValidation(validation);
    // inform onChange listeners
    if(onChange) {
      onChange(enteredValue, validation);
    }
  }

  // control blurs
  const _onBlur = (e:React.FocusEvent<HTMLInputElement>) => {
    if(onBlur) {
      onBlur(e);
    }
  }

  // render
  return (
    <div className={CSS.container}>
      <Label label={label} explanation={explanation}>
        <input type="text" value={enteredValue} disabled={disabled} onChange={_onChange} placeholder={placeholder || ""} onBlur={_onBlur} />
        <ValidationError validation={validation} />
      </Label>
    </div>
  )
}