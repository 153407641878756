import _ from "lodash";
import React, {useContext, useEffect, useState} from "react";
import {SurpriseContext} from "../../Surprise.Context";
import {Rating} from "../../../../controls";
import "./Review.scss";

export default function Review() {
  // context 
  const SC = useContext(SurpriseContext);

  // state
  const [data, setData] = useState(null);
  
  // mount (upon change of surprise rating)
  useEffect(() => {
    const rating = SC.surprise.Rating;
    if(!_.isNil(rating)) {
      const stars = [];
      if(rating.Rating) stars.push({title:'Allgemein', rating:rating.Rating});
      if(rating.Rating2) stars.push({title:'Ablauf', rating:rating.Rating2});
      if(rating.Rating3) stars.push({title:'Anbieter', rating:rating.Rating3});

      const starsSum = stars.reduce((acc, val) => {return acc + val.rating}, 0);
      const starsAvg = Math.round((stars.length > 0 ? starsSum / stars.length : 0) * 10) / 10;
      const starsText = `${starsAvg} ${stars.length > 1 ? ` (${stars.map(s => `${s.title}: ${s.rating}`).join(', ')})` : ``}`;

      const comment = (rating.Comment || "").trim()
      const feedback = (rating.Feedback || "").trim()

      setData({stars, starsAvg, starsText, comment, feedback});
    }
  }, [SC.surprise.Rating]);

  // nothing to render
  if(_.isNil(data)) {
    return null;
  }

  // render
  return (
    <div className="c-surprise-contextual-review">
      <Rating value={data.starsAvg} max={5} />
      <div className="c-surprise-contextual-review-details">
        {data.starsText}
      </div>
      {data.comment ? <Quote title="Bewertung" text={data.comment} /> : null}
      {data.feedback ? <Quote title="Feedback" text={data.feedback} /> : null}
    </div>
  );
}

function Quote({title, text}) {
  return (
    <div className="c-surprise-contextual-review-quote">
      <div className="title">{title}</div>
      <div className="text">"{text}"</div>
    </div>
  )
}