import React, { useState, useEffect } from "react"

// util & types
import Api from '../../../../util/api';
import * as TS from "../../../../types";
import * as DA from "../../../../types/DataAccess";
import * as BL from "../../../../types/BusinessLogic";

// context & components
import { Button, Loader, Row } from "../../../controls";

// css
import CSS from "./BookingReminder.module.scss";

// component
type BookingReminderProps = {
  requestId:any,
  onDone:any,
  closeDialog: () => void,
}
export default function BookingReminder({requestId, onDone, closeDialog} : BookingReminderProps) {
  // state
  const [mode, setMode] = useState('busy'); // busy, edit, preview
  const [valid, setValid] = useState(true);
  const [email, setEmail] = useState<any>({});
  const [emailSubject, setEmailSubject] = useState("");
  
  // mount
  useEffect(() => {
    loadRequest(requestId);
  }, [])

  // loads request
  const loadRequest = async(requestId:any) => {
    setMode("busy")
    // get current user
    const currentUser = await BL.User.getCurrent();
    // get request from db
    const request: DA.BookingRequest|null = await DA.BookingRequestRepository.findById(requestId);
    if(request) {
      // assemble data for state
      const email:any = {}
      email.to = request.request!.email!.to
      email.from = `${currentUser.firstName} von Appentura<${currentUser.email}>`
      email.subject = `REMINDER: ${request.request!.email!.subject}`

      const email_body_intro = [
        "Guten Tag",
        "Leider haben wir zu unserer Anfrage noch keine Rückmeldung erhalten.",
        "Vielen Dank für die schnelle Antwort.",
        "Beste Grüsse",
        email.from,
        "--------",
      ].map(l => `<p>${l}</p>`).join("");

      email.body = `${email_body_intro}${request.request!.email!.body}`

      // update state
      setValid(true);
      setEmail(email);
      setEmailSubject(email.subject);
      setMode('edit');
    }
    else {
      alert("Buchungsanfrage nicht gefunden");
    } 
  }

  // saves request
  const save = async() => {
    const currentUser = await BL.User.getCurrent();
    email.subject = emailSubject
    let data = {
      requestId,
      operator:currentUser.email,
      email:email
    }
    // post
    setMode("busy")
    await Api.post('bookingrequests/reminder', data);
    // close dialog and inform opener
    closeDialog();
    onDone();
  }


  // render
  let content = null
  if(mode === "edit") {
    content = (
      <>
        <div>
          <table>
            <tbody>
            <tr>
              <td>Betreff</td>
              <td>
                <input 
                  type="text" 
                  onChange={e => {
                    let subject = e.target.value
                    let valid = subject.trim().length > 0
                    setValid(valid)
                    setEmailSubject(subject)
                  }} 
                  value={emailSubject} 
                />
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <br />
        <Row align="center">
          <Button onClick={closeDialog} intent="cancel">abbrechen</Button>
          <Button onClick={() => setMode("preview")} disabled={!valid}>weiter</Button>
        </Row>
      </>
    )
  }
  else if(mode === "preview") {
    content = (
      <>
        <table>
          <tbody>
          <tr>
            <td>To:</td>
            <td>{email.to}</td>
          </tr>
          <tr>
            <td>From:</td>
            <td>{email.from}</td>
          </tr>
          <tr>
            <td>Subject:</td>
            <td>{emailSubject}</td>
          </tr>
          <tr>
            <td>Body:</td>
            <td>
              <div dangerouslySetInnerHTML={{__html:email.body}} />
            </td>
          </tr>
          </tbody>
        </table>

        <Row align="center">
          <Button onClick={() => setMode("edit")}>zurück</Button>
          <Button onClick={() => save()} disabled={false}>verschicken</Button>
        </Row>
      </>
    )


  }
  else {
    content = <Loader text="lade ..." />
  }

  return (
    <div className={CSS.container}>
      {content}
    </div>
  )

}

