import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Icon, Icons, Loader, Checkbox, Row, Modal } from "../../../controls";
import * as TS from "../../../../types";
import * as DA from "../../../../types/DataAccess";

type AddStepProps = {
  index: number, 
  insertSteps: (index:number, steps:any[]) => void
}
export default function AddStep({index, insertSteps}: AddStepProps) {
  // state
  const [showSelectTemplate, setShowSelectTemplate] = useState<boolean>(false);

  // user wants to select a template
  const openSelectTemplate = () => {
    setShowSelectTemplate(true);
    //MC.push("Aus Vorlage einfügen", <SelectTemplate onSelected={onTemplateSelected} />);
  }

  const closeSelectTemplate = () => setShowSelectTemplate(false);

  const onAddNewStep = () => {
    const step = { Title:"Neuer Schritt", Instructions:[], Attachments:[]}
    insertSteps(index, [step]);
  }

  const onTemplateSelected = (template: any) => {
    // TODO move this to TS.AdventureStep (its currently an interface fix that)
    const stepFromTemplate = (template: DA.AdventureStepTemplate) : TS.AdventureStep => {
      return {
        ActivityId: template.ActivityId || undefined,
        Attachments: [],
        EndCoordinates: template.EndCoordinates || undefined,
        HasSelfieButton: false,
        ImageUrl: template.ImageUrl || undefined,
        ImageText: template.ImageText || undefined,
        Instructions: template.Instructions,
        Links: [],
        SentAlert: false,
        StartCoordinates: template.StartCoordinates || undefined,
        Title: template.Title,
        TickerMessage: template.TickerMessage,
        TickerSent: false,
        TickerTravel: template.TickerTravel || undefined,
        TravelMode: template.TravelMode ? template.TravelMode : undefined,
      }
    }
    const steps = template.steps
      .sort((a:any, b:any) => a.AdventureTemplatePosition > b.AdventureTemplatePosition ? 1 : -1)
      .map((step:any) => {
        return stepFromTemplate(step as DA.AdventureStepTemplate);
        /*
        let stepToAdd = _.cloneDeep(step)
        delete stepToAdd._id
        delete stepToAdd.AdventureTemplate
        delete stepToAdd.AdventureTemplatePosition
        delete stepToAdd.createdAt
        delete stepToAdd.updatedAt
        return stepToAdd
        */
      });
    insertSteps(index, steps)
  }


  return <>
    <Row align="center">
      <Button onClick={openSelectTemplate} size="small" inverted><Icon icon={Icons.Clone} />&nbsp;aus Vorlage</Button>
      <Button onClick={onAddNewStep} size="small" inverted><Icon icon={Icons.Plus} />&nbsp;neuer Schritt</Button>
    </Row>
    <Modal isOpen={showSelectTemplate} title="Aus Vorlage einfügen" onClose={closeSelectTemplate}>
      <SelectTemplate onSelected={onTemplateSelected} closeDialog={closeSelectTemplate} />
    </Modal>
  </>
}

type SelectTemplateProps = {
  onSelected: Function
  closeDialog: () => void
}
function SelectTemplate({onSelected, closeDialog}: SelectTemplateProps) {
  // state
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState<Template[]>([]);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [includeAdventureTemplates, setIncludeAdventureTemplates] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // mount
  useEffect(() => {
    const load = async(includeAdventureTemplates:boolean = false) => {
      setIsLoading(true);
      const templates = await getTemplates(includeAdventureTemplates);
      setTemplates(templates);
      setSearchResult(templates);
      setIsLoading(false);
    }
    load(includeAdventureTemplates);
  }, [includeAdventureTemplates]);

  // searchText changed
  useEffect(() => {
    if(templates) {
      const parts = searchText.toLowerCase().split(' ')
      let result = templates.filter((template:any) => {
        let name = template.name.toLowerCase();
        return parts.some(part => name.includes(part));
      })
      setSearchResult(result);
    }
  },[searchText]);

  // user selects template
  const onSelect = async(template:any) => {
    // get steps from template
    // insert
    onSelected(template);
    // close dialog
    closeDialog();
  }

  // render loading
  if(isLoading) {
    return <Loader text="lade Vorlagen ..." />
  }

  // collect rows to render
  const rows = searchResult.map(item => {
    return <tr key={item.id}>
      <td>
        <Button size="small" onClick={() => onSelect(item)}>
          <Icon icon={Icons.Plus} />
        </Button>
      </td>
      <td>
        <div onClick={() => onSelect(item)}>{item.name}</div>
      </td>
    </tr>
  });
  // render
  return (
    <div className="c-surprise-step-selecttemplate">
      <div className="search-text">
        <input type="text" value={searchText} onChange={e => setSearchText(e.target.value)} placeholder="Suchbegriff eingeben" />
      </div>
      <div className="search-result">
        <table><tbody>{rows}</tbody></table>
      </div>
      <div>
        <Checkbox label="Überraschungs-Vorlagen anzeigen" secondaryLabel="nebst Schritt- auch Überraschungs-Vorlagen anzeigen" onChange={(checked) => setIncludeAdventureTemplates(checked)} value={includeAdventureTemplates} />
      </div>
      <Row align="center">
        <Button intent="cancel" onClick={closeDialog}>abbrechen</Button>
      </Row>
    </div>
  )
}


type Template = {
  id: string,
  name: string, 
  steps: DA.AdventureStepTemplate[]
}

/** returns AdventureTemplate and AdventureStepTemplates combined */
async function getTemplates(loadAdventureTemplates:boolean = false): Promise<Template[]> {
  
  const groups: Template[] = [];
  
  // get adventure templates and their steps
  if(loadAdventureTemplates) {
    const adventureTemplates = await DA.AdventureTemplateRepository.findAll();
    for(const template of adventureTemplates) {
      const steps = await DA.AdventureStepTemplateRepository.findByAdventureTemplate(template._id!);
      groups.push({
        id: template._id!,
        name: template.Name,
        steps
      })
    }
  }

  // get standalone template steps
  const adventureStepTemplates = await DA.AdventureStepTemplateRepository.findStandalone();
  adventureStepTemplates.forEach(st => {
    groups.push({
      id: st._id!,
      name: st.Name,
      steps: [st]
    })
  })

  // sort by name
  const sortedGroups = groups.sort((a,b) => a.name.localeCompare(b.name));

  // done
  return sortedGroups;
}
