// TODO because I am too afraid to set all existing adventures to have a ShippingDate we sort by a threshold date
let shippingThresholdCreateDate = new Date("2017-02-11T00:00:00Z")

// number of days until a bill is overdue
let numberOfDaysUntilOverdue = 20

export default {
  ShippingThresholdCreateDate:shippingThresholdCreateDate,
  NumberOfDaysUntilOverdue:numberOfDaysUntilOverdue
}
