import React, {useContext} from "react";
import {SurpriseContext} from "../../Surprise.Context";
import Row from "./Row";

export default function TransportRow() {
  // context 
  const SC = useContext(SurpriseContext);
  // render
  const meansOfTransport = (SC.surprise.TransportOptions || ["unbekannt"])[0];
  return <Row label="Transportmittel">{meansOfTransport}</Row>
}
