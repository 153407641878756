import React, { useState } from "react";

// context type
export type SortableListContextType = {
  // properties
  disabled: boolean,
  // functions
  setDisabled: (disabled:boolean) => void
}
// context
const SortableListContext = React.createContext<SortableListContextType>({} as SortableListContextType);

// provider
type SortableListProviderProps = {
  children:React.ReactNode|React.ReactNode[]
}
function SortableListProvider({children}: SortableListProviderProps) {
  // state
  const [disabled, setDisabled] = useState<boolean>(false);
  // provided value
  const value = {
    // props
    disabled,
    // functions
    setDisabled: (disabled:boolean) => {
      setDisabled(disabled);
    }
  }
  // render
  return (
    <SortableListContext.Provider value={value}>
      {children}
    </SortableListContext.Provider>
  )
}

export { SortableListContext, SortableListProvider }