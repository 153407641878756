import { Charity } from "./Charity";
import { Mapper } from "../_Common/Mapper";
import { TranslatableMapper } from "../_Common/TranslatableMapper";


export class CharityMapper extends Mapper<Charity> {
  public apiEndpoint = "charities";

  public toDb(charity: Charity):any {
    return {
      _id: charity._id,
      name: TranslatableMapper.toDb(charity.name),
      description: TranslatableMapper.toDb(charity.description),
      imageUrl: charity.imageUrl,
      isActive: charity.isActive
    }
  }

  public fromDb(obj:any): Charity {
    return {
      _id: obj._id || undefined,
      description: TranslatableMapper.fromDb(obj.description),
      name: TranslatableMapper.fromDb(obj.name),
      imageUrl: obj.imageUrl || "",
      isActive: obj.isActive ? true : false,
    }
  }

}