import React, {useEffect, useState} from "react";
import _ from "lodash";
import * as TS from "../../../types";
import * as BL from "../../../types/BusinessLogic";
import * as DA from "../../../types/DataAccess";
import Util from "../../../util/util";
// components
import { Link, Loader, Alert } from "../../controls";
import DashboardPanel from "./DashboardPanel";
// images and styling
import CSS from "./BookingRequests.module.scss";
import iconBooking from './../../../assets/images/icon_booking.svg'

// locally used type
interface LwUser {
  value: string;
  label: string;
}

export default function BookingRequests() {
  const [openRequests, setOpenRequests] = useState<any[]|null>(null);
  const [forUser, setForUser] = useState<string>("all@appentura.ch");
  const [users, setUsers] = useState<LwUser[]>([]);
  
  useEffect(() => {
    const load = async() => {
      // users and current user
      const allUsers = await DA.UserRepository.findAll()
      const currentUser = await BL.User.getCurrent();
      const users: LwUser[] = allUsers
        .filter(u => u.email !== currentUser.email)
        .map(u => {
          return { value:u.email, label:u.firstName }
        })
        .sort((a,b) => a.label.localeCompare(b.label))
      users.unshift({value:"nobody@appentura.ch", label:"☆ Kein Operator"});
      users.unshift({value:"all@appentura.ch", label:"☆ Alle"});
      users.unshift({value:currentUser.email, label:"☆ Meine"});
      
      // open requests
      const openRequests = await BL.Dashboard.getOpenBookingRequests();

      // update state
      setUsers(users);
      setForUser(currentUser.email);
      setOpenRequests(openRequests);
    }
    load();
  }, [])

  // render
  let content = null
  if(openRequests === null) {
    content = <Loader text="lade ..."/> 
  }
  else {
    // user select dropdown
    const selectUserOptions = users.map(u => <option key={u.value} value={u.value}>{u.label}</option>)
    let selectUser = (
      <div className="select-wrapper">
        <select onChange={e => setForUser(e.target.value)} value={forUser}>
          {selectUserOptions}
        </select>
      </div>
    )

    // Open Booking Requests
    let rows = openRequests
    .filter(item => {
      if(forUser === "all@appentura.ch") {
        return true
      }
      else if(forUser === "nobody@appentura.ch") {
        return _.isNil(_.get(item, "adventure.Operator"))
      }
      else {
        return item.adventure.Operator === forUser;
      }
    })
    .sort((a, b) => {
      let date_a = new Date(_.get(a, "adventure.StartTime") || 0)
      let date_b = new Date(_.get(b, "adventure.StartTime") || 0)
      return date_a > date_b ? 1 : -1
    })
    .map(item => {
      let start = _.get(item, "adventure.StartTime")

      return (
      <tr key={item.bookingRequest._id}>
        <td>{start ? Util.printDate(start) : "keine Startdatum"}</td>
        <td>{TS.Adventure.getShortId(item.adventure._id, true)}</td>
        <td>
          <Link to={`surprise/${item.adventure._id}`}>
            {_.get(item, "adventure.RecieverName") || "unbekannt"} 
          </Link>
        </td>
        <td>{_.get(item, "adventure.Operator") || "kein Operator"}</td>
        <td>{_.get(item, "bookingRequest.activity.name") || "unbekannte Aktvitiät"}</td>
      </tr>
      )
    })
    let items = null
    if(rows.length > 0) {
      items = <table><tbody>{rows}</tbody></table>
    }
    else {
      items = content = (
        <Alert intent="info" title="Keine Buchungsanfrage" size="medium">
          Es sind keine Buchungsanfragen für diesen Benutzer vorhanden
        </Alert>
      )
    }
    content = <>
      {selectUser}
      {items}
    </>
  }

  return (
    <DashboardPanel title="Offene Buchungsanfragen" icon={iconBooking} iconRounded={false} smallOnDesktop={false} smallOnMobile={false}>
      <div className={CSS.container}>{content}</div>
    </DashboardPanel>
  )
}