import Icons from "../components/controls/enumerations/Icons.Enumeration";

// moment.js date formats
const DATE_FORMATS = {
  DATE:"DD.MM.YYYY",
  TIME:"HH:mm",
  DATE_AND_TIME:"DD.MM.YYYY, HH:mm",
  TIME_AND_DATE:"HH:mm, DD.MM.YYYY",
  DATE_AND_TIME_WITHOUT_YEAR:"DD.MM, HH:mm",
  DATE_AND_TIME_SORTABLE:"YYYYMMDD HH:mm:ss"
}

const MONTHS = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"]

const WEEKDAYS = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"]
const WEEKDAYS_SHORT = ["Mo", "Di", "Mi", "Do", "Fr", "Sa"]

// Note: urlExtension is used in the google maps url
const GMAP_TRAVEL_MODES = [
  {value:"DRIVING", label:"Auto", icon:"car", icon2: Icons.Car, urlExtension:'/data=!3m1!4b1!4m2!4m1!3e0'},
  {value:"TRANSIT", label:"ÖV", icon:"subway-variant", icon2: Icons.Bus, urlExtension:'/data=!3m1!4b1!4m2!4m1!3e3'},
  {value:"WALKING", label:"zu Fuss", icon:"walk", icon2: Icons.Walking, urlExtension:'/data=!3m1!4b1!4m2!4m1!3e2'},
  {value:"BICYCLING", label:"Fahrrad", icon:"bike", icon2: Icons.Bicycle, urlExtension:'/data=!3m1!4b1!4m2!4m1!3e1'},
  {value:null, label:"-", icon:'window-close', icon2: Icons.Exclamation, urlExtension:''} // we use need this so we can decide if we want to display travel events in the ticker (i.e. travel mode can be selected even if there are no coordinates)
]

const GMAP_TRAVEL_MODES_OBJ = {
  DRIVING: {value:"DRIVING", label:"Auto", icon:"car", icon2: Icons.Car, urlExtension:'/data=!3m1!4b1!4m2!4m1!3e0'},
  TRANSIT: {value:"TRANSIT", label:"ÖV", icon:"subway-variant", icon2: Icons.Bus, urlExtension:'/data=!3m1!4b1!4m2!4m1!3e3'},
  WALKING: {value:"WALKING", label:"zu Fuss", icon:"walk", icon2: Icons.Walking, urlExtension:'/data=!3m1!4b1!4m2!4m1!3e2'},
  BICYCLING: {value:"BICYCLING", label:"Fahrrad", icon:"bike", icon2: Icons.Bicycle, urlExtension:'/data=!3m1!4b1!4m2!4m1!3e1'},
}

const TICKER_TRAVEL_MODES = [
  {value:"car", icon:"car", icon2: Icons.Car, label:"Auto"},
  {value:"train", icon:"train", icon2: Icons.Train, label:"Zug"},
  {value:"tram", icon:"tram", icon2: Icons.Subway, label:"Tram"},
  {value:"bus", icon:"bus", icon2: Icons.Bus, label:"Bus"},
  {value:"bike", icon:"bike", icon2: Icons.Bicycle, label:"Velo"},
  {value:"walk", icon:"walk", icon2: Icons.Walking, label:"zu Fuss"},
  {value:null, icon:'window-close', icon2: Icons.Exclamation, label:"-"}
]

// https://developers.google.com/maps/documentation/javascript/reference/directions#VehicleType
// NOT USED ANYWHERE ... Logic/Steps.ts has a method return these values
const GMAP_VEHICLES = {
  RAIL: {label: "Zug", tickerTravel: "train", accusativeLabel:"den Zug" },
  HEAVY_RAIL: {label: "Zug", tickerTravel: "train", accusativeLabel:"den Zug" },
  COMMUTER_TRAIN: {label: "Zug", tickerTravel: "train", accusativeLabel:"den Zug"},
  HIGH_SPEED_TRAIN: {label: "Zug", tickerTravel: "train", accusativeLabel:"den Zug"},
  LONG_DISTANCE_TRAIN: {label: "Zug", tickerTravel: "train", accusativeLabel:"den Zug"},
  BUS: {label: "Bus", tickerTravel: "bus", accusativeLabel:"den Bus"},
  TRAM: {label: "Tram", tickerTravel: "tram", accusativeLabel:"das Tram"}
}

const COUNTRIES = [
  {value: "CH", label: "Schweiz"},
  {value: "DE", label: "Deutschland"}
];

const PAYMENTSOURCES = {
  BUDDY_UPSELL: "buddy_upsell"
}



export { 
  MONTHS, 
  WEEKDAYS, 
  WEEKDAYS_SHORT, 

  DATE_FORMATS, 
  
  GMAP_TRAVEL_MODES, 
  GMAP_TRAVEL_MODES_OBJ,
  GMAP_VEHICLES, 
  TICKER_TRAVEL_MODES, 
  
  COUNTRIES,
  
  PAYMENTSOURCES 
}