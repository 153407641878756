import { AdventureTemplateMapper } from "./AdventureTemplate.Mapper";
import { AdventureTemplate } from "./AdventureTemplate";
import { RepositoryFactory } from "../_Common/RepositoryFactory";


export class AdventureTemplateRepository {
  // mapper
  private static mapper = new AdventureTemplateMapper();
  
  // factory methods
  static make = RepositoryFactory.make<AdventureTemplate, AdventureTemplateMapper>(this.mapper);
  static search = RepositoryFactory.search<AdventureTemplate, AdventureTemplateMapper>(this.mapper);
  static create = RepositoryFactory.create<AdventureTemplate, AdventureTemplateMapper>(this.mapper);
  static update = RepositoryFactory.update<AdventureTemplate, AdventureTemplateMapper>(this.mapper);
  static findById = RepositoryFactory.findById<AdventureTemplate, AdventureTemplateMapper>(this.mapper);
  
  // additional
  static async findAll(): Promise<AdventureTemplate[]> {
    return AdventureTemplateRepository.search({});
  }
}

