import React, { useState } from "react";
import Code from "../Code";
import { Card, Heading, Form, TextInput, Validators } from "../../../controls";

export default function TextInputDemo() {
  const [road, setRoad] = useState<string>("Abbey Road");
  const [city, setCity] = useState<string>("");
  const [region, setRegion] = useState<string>("");
  const [formData, setFormData] = useState<string>("");

  const [entity] = useState({name: "Paul", region: "a", video: ""})


  // form save
  const onSave = (formData:any) => {
    setFormData(JSON.stringify(formData))
  }

  // validates region
  const validateRegion = (v:string) => {
    if(v.trim().length === 3) {
      return {valid: true};
    }
    else {
      return {valid: false, message: "needs to be 3 characters long"}
    }
  }

  // render

  return <>
    <Heading>TextInput</Heading>
    
    <Card title="In Form">
      <Form busy={false} entity={entity} onSave={onSave}>
        <TextInput path="name" label="Name" />
        <TextInput path="name" label="Name copy" />
        <TextInput path="region" label="Region" explanation="must be 3 characters" validate={validateRegion} />
        <TextInput path="video" label="YouTube Video" validate={Validators.isYouTubeVideoId()} />
      </Form>
      <div>
        <div>formData:</div>
        <Code>
          {formData}
        </Code>
      </div>
    </Card>

    <Card title="Without Form">
      <TextInput label="Road" value={road} onChange={(v:string) => setRoad(v)} />
      <div>entered road: {road}</div>
    </Card>

    <Card title="Explanation and Placeholder">
      <TextInput label="City" explanation="please enter the name of a city" placeholder="name of city (e.g. London)" value={city} onChange={(v:string) => setCity(v)} />
    </Card>

    <Card title="Validation">
      <TextInput label="Region" explanation="region must be 3 characters long" value={region} onChange={(v:string) => setRegion(v)} validate={validateRegion} />
    </Card>

    <Card title="Incomplete Implementation">
      <TextInput label="Something Missing" />
    </Card>

  </>
}
