import moment from "moment";
import qs from "qs"
import "moment/locale/de"
import {DATE_FORMATS} from "./../config/constants";

function shortObjectId(objectId, front, back) {
  let s_front = objectId.substr(0, front)
  let s_back = objectId.substr(objectId.length - back)
  if(front > 0 && back > 0) {
    s_front += '...'
  }
  return `${s_front}${s_back}`
}

// get a unique key for react
const _key = {
  key:0
}
function getKey(prefix) {
  _key.key += 1
  return `${prefix || ''}${_key.key}`
}

// gets :id from url for both react-router v3 and v4
// TODO since we switched to v4 we could get rid of this
function getRouteId(props) {
  let id = props.match ? props.match.params.id : props.params.id
  if(!id || id.toLowerCase() === 'new') {
    id = null
  }
  return id
}

// gets :<paramName> from url for both react-router v3 and v4
function getRouteParam(props, paramName) {
  let v = props.match ? props.match.params[paramName] : props.params[paramName]
  if(!v) {
    v = null
  }
  return v
}

function getRouteQuery(props, queryName) {
  // router v3 has query string object, v4 only has a search string
  let query =  props.location.query ? props.location.query : qs.parse(props.location.search, {ignoreQueryPrefix:true})
  let v = query[queryName]
  if(!v) {
    v = null
  }
  return v
}

function printDate(date, options) {
  options = options || {}
  if(options.dayName) {
    moment.locale("de")
    return moment(date).format("dddd, DD.MM.YYYY")
  }
  else {
    return moment(date).format("DD.MM.YYYY")
  }
}

function printTime(date) {
  return moment(date).format(DATE_FORMATS.TIME)
}

function printDateAndTime(date) {
  return moment(date).format(DATE_FORMATS.DATE_AND_TIME)
}

function printDateAndTimeWithoutYear(date) {
  return moment(date).format(DATE_FORMATS.DATE_AND_TIME_WITHOUT_YEAR)
}

function printTimeAndDate(date) {
  return moment(date).format(DATE_FORMATS.TIME_AND_DATE)
}

function printSortableDate(date) {
  return moment(date).format(DATE_FORMATS.TIME_AND_DATE_SORTABLE)
}

function printDatePrefix(date) {
  return moment(date).format("YYYYMMDD")
}
function getCalendarDateNumber(date) {
  return Number(moment(date).format("YYYYMMDD"));
}

function printCurrency(amount, currency) {
  return `${currency ? "CHF ":""}${Number(amount).toFixed(2)}`
}
function getNextBusinessDay() {
  let today = moment()
  if(today.day() > 4) {
    return today.add(8-today.day(), "days").toDate()
  }
  else {
    return today.add(1, "days").toDate()
  }
}

function sortDate(a, b) {
  const diff = (moment(a)).diff(moment(b));
  return diff;
}

export default {
  getKey, 
  shortObjectId, 
  getRouteId, 
  getRouteParam, 
  getRouteQuery, 
  getCalendarDateNumber,
  printDate, 
  printTime, 
  printDateAndTime, 
  printTimeAndDate, 
  printDateAndTimeWithoutYear, 
  printDatePrefix, 
  printSortableDate,
  printCurrency,
  getNextBusinessDay,

  sortDate
  
}



