import React, { useContext, useEffect, useState } from "react"

import * as DA from "../../../types/DataAccess";
import * as BL from "../../../types/BusinessLogic";

import { DateInput, Dropdown, Button, ConfirmButton, Loader, DropdownOption } from "../../controls";
import { VendorInvoicesContext } from "./VendorInvoices.Context";

type ActionsProps = {
  paymentType: DA.PaymentType,
  onChangePaymentOption: any,
  paymentDate: Date,
  listMode: any,
  onChangePaymentDate: any,
  onClickAddToOutgoing: any,
  onClickRemoveFromOutgoing: any,
  onClickPaid: any
}
export default function Actions({paymentType, onChangePaymentOption, listMode, onClickAddToOutgoing, onClickRemoveFromOutgoing, onClickPaid, onChangePaymentDate, paymentDate} : ActionsProps) {
  // context
  const VIC = useContext(VendorInvoicesContext);
  // state
  const [dropdownOptionsPayment, setDropdownOptionsPayment] = useState<DropdownOption[]>([]);

  // mount (payment options changed)
  useEffect(() => {
    if(VIC.isMounted) {
      const dropdownOptionsPayment: DropdownOption[] = VIC.paymentTypes.map((item:any) => {
        return {
          label: `${item.accountingId}-${item.name} [${BL.PaymentType.printKind(item.kind)}]`,
          value: item._id
        }
      });
      setDropdownOptionsPayment(dropdownOptionsPayment);
    }
  }, [VIC.isMounted]);


  // only display if invoices selected
  if(VIC.data.selectedCount === 0) {
    return null;
  }

  // hide if context is gathering data
  if(VIC.status === "loading") {
    return null;
  }

  // actions if we display unpaid invoices
  let actions_unpaid_actions = null
  switch(paymentType.kind) {
    case 'bank':
      actions_unpaid_actions = (
        <div>
          <Button size="small" onClick={onClickAddToOutgoing} disabled={VIC.data.sepaDisabled}>Zahlungs-File Erzeugen / in Zahlungsausgang</Button>
          {VIC.data.sepaDisabled ? <div style={{color:"orange"}}>nicht alle gewählten Rechnungen können automatisch verarbeitet werden</div> : null}
        </div>
      )
      break
    case 'creditcard':
    case 'employee':
    default:
      actions_unpaid_actions = (
        <ConfirmButton onConfirm={onClickPaid}>als bezahlt markieren</ConfirmButton>
      )
      break
  }
  let actions_unpaid = listMode !== "unpaid" ? null : (
    <table><tbody>
      <tr>
        <td>Zahlungsdatum</td>
        <td>
          <DateInput value={paymentDate} onChange={onChangePaymentDate} />
        </td>
      </tr>
      <tr>
        <td>Zahlungsart</td>
        <td>
        <Dropdown 
          options={dropdownOptionsPayment} 
          value={paymentType._id} 
          onChange={(id:any) => {
            onChangePaymentOption(VIC.paymentTypes.find((item:any) => item._id === id))
          }} 
        />
        </td>
      </tr>
      <tr>
        <td></td>
        <td>
          {actions_unpaid_actions}
        </td>
      </tr>
    </tbody></table>
  )

  // actions if we display outgoing invoices
  let actions_outgoing = listMode !== "outgoing" ? null : (
    <table><tbody>
      <tr>
        <td>Datum</td>
        <td><DateInput value={paymentDate} onChange={onChangePaymentDate} /></td>
      </tr>
      <tr>
        <td></td>
        <td><ConfirmButton onConfirm={onClickPaid}>als bezahlt markieren</ConfirmButton></td>
      </tr>
      <tr>
        <td></td>
        <td><ConfirmButton onConfirm={onClickRemoveFromOutgoing}>aus Zahlungsausgang entfernen</ConfirmButton></td>
      </tr>
    </tbody></table>
  )

  // render
  return (
    <div id="aat-v-vendorinvoices-actions">
      {actions_unpaid}
      {actions_outgoing}
    </div>
  )
}