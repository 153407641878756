import React from "react"
import {Card, Heading} from "../../controls";
import PublishCatalogue from "./PublishCatalogue"

function Cache() {
  return (
    <>
      <Heading>Cache</Heading>
      <Card>
        <PublishCatalogue />
      </Card>
    </>
  )
}

export default Cache