import React from "react"
import { TextInput } from "../../../controls";
import { CountrySelector } from ".";
import { ThemeSelector } from ".";

type BasePropertiesProps = {
  busy:boolean
}
export function BaseProperties({busy} : BasePropertiesProps) {
  return <>
      <CountrySelector path="countries" /> 
      <ThemeSelector />
      <TextInput label="Anchor" path="anchor" placeholder="Anchor eingeben" disabled={busy} />
  </>
}

