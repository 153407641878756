import React, {useState, useEffect, useContext} from "react";
import Util from "../../../util/util";
import * as DataAccess from "../../../types/DataAccess";
// components
import { Breadcrumb, Card, Heading, List, ListColumn, Modal, useModal } from "../../controls";
import WeatherConditionsItem from "./WeatherConditions.Item";

export default function WeatherConditionsList(props:any) {
  // state
  const [modal, setModal] = useModal<"edit">();
  const [items, setItems] = useState<DataAccess.WeatherCondition[]>([]);
  const [routeId] = useState(Util.getRouteId(props));
  const [columns] = useState<ListColumn[]>([
    {header:"Bedingung", path:"title", print: (v:DataAccess.WeatherCondition) => v.title.de, sort: (a: DataAccess.WeatherCondition, b: DataAccess.WeatherCondition) => a.title.de.localeCompare(b.title.de)}
  ]);

  // mount
  useEffect(() => {
    load();
  }, [])

  // mount with route id
  useEffect(() => {
    if(routeId) {
      startEdit(routeId);
    }
  }, [routeId]);

  // loads all items
  const load = async() => {
    setItems(await DataAccess.WeatherConditionRepository.searchAll());
    modal.onClose();
  }

  // onRowClick
  const startEdit = (id?:string) => {
    setModal("edit", id ? "Wetterbedingung bearbeiten" : "Wetterbedingung erstellen", id);
  }

  // items updated
  const onUpdate = async() => {
    setItems([]);
    load();
  }

  // render
  return <>
    <Breadcrumb 
      links={[ {title:'Home', to:'/'}]}
      location="Wetterbedingungen" 
    />
    <Heading>Wetterbedingungen</Heading>
    <Card>
      <List onClickCreate={() => startEdit(undefined)} columns={columns} items={items} getKey={v => v._id} onRowClick={item => startEdit(item._id)} />
    </Card>
    <Modal {...modal}>
      { modal.action === "edit" && <WeatherConditionsItem id={modal.params} onUpdate={onUpdate} onCancel={modal.onClose} /> }
    </Modal>

  </>
}