import { AdventureStepTemplateMapper } from "./AdventureStepTemplate.Mapper";
import { AdventureStepTemplate } from "./AdventureStepTemplate";
import { RepositoryFactory } from "../_Common/RepositoryFactory";


export class AdventureStepTemplateRepository {
  // mapper
  private static mapper = new AdventureStepTemplateMapper();
  
  // factory methods
  static make = RepositoryFactory.make<AdventureStepTemplate, AdventureStepTemplateMapper>(this.mapper);
  static search = RepositoryFactory.search<AdventureStepTemplate, AdventureStepTemplateMapper>(this.mapper);
  static create = RepositoryFactory.create<AdventureStepTemplate, AdventureStepTemplateMapper>(this.mapper);
  static update = RepositoryFactory.update<AdventureStepTemplate, AdventureStepTemplateMapper>(this.mapper);
  static remove = RepositoryFactory.remove<AdventureStepTemplate, AdventureStepTemplateMapper>(this.mapper);
  static findById = RepositoryFactory.findById<AdventureStepTemplate, AdventureStepTemplateMapper>(this.mapper);

  /**
   * Returns all AdventureStepTemplates
   * @returns {Promise<AdventureStepTemplate[]>}
   */
  static async findAll(): Promise<AdventureStepTemplate[]> {
    return AdventureStepTemplateRepository.search({});
  }


  /**
   * returns all AdventureStepTemplates that are not associated with an AdventureTemplate
   * @returns {Promise<AdventureStepTemplate[]>}
   */
  static async findStandalone(): Promise<AdventureStepTemplate[]> {
    return await this.search({AdventureTemplateId:null});
  }

  /**
   * Returns all AdventureStepTemplates associated with the given AdventureTemplate
   * @param adventureTemplateId 
   * @returns {Promise<AdventureStepTemplate[]>}
   */
  static async findByAdventureTemplate(adventureTemplateId:string): Promise<AdventureStepTemplate[]> {
    return await this.search({AdventureTemplateId:adventureTemplateId});
  }
}

