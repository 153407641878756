import _ from "lodash";
import React, {useEffect, useState, useContext } from "react";
import * as TS from "../../../../types";
import * as BL from "../../../../types/BusinessLogic";
import TrackVisibility from "react-on-screen";
import { SurpriseContext } from "../Surprise.Context";
import Warning from "./Warning.Control";

import CSS from "./Warnings.View.module.scss";
import { Icon, Icons } from "../../../controls";

export default function Warnings() {
  // context
  const SC = useContext(SurpriseContext);
  // state
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [warnings, setWarnings] = useState<BL.AdventureWarning[]>([]);
  const [mode, setMode] = useState<"full"|"compact">("full");

  // get warnings when adventure changes
  useEffect(() => {
    const warnings = BL.AdventureWarning.getWarnings(SC.surprise, SC.log);
    setWarnings(warnings);
  }, [SC.surprise, SC.log]);

  // is called when visibility changes
  const onListVisibilityChanged = (visibility:any) => {
    setNotificationVisible(!visibility);
  }

  // render
  const notification = (notificationVisible && warnings.length > 0) ? <WarningsInternal mode={mode} setMode={setMode} fixed={true} warnings={warnings}/> : null;
  return (
    <div className={CSS.container}>
      <TrackVisibility partialVisibility={true}>
        <WarningsInternal mode={mode} setMode={setMode} warnings={warnings} onVisibilityChanged={onListVisibilityChanged} />
      </TrackVisibility>
      {notification}
    </div>
  )
}

type WarningsInternalProps = {
  warnings: any[],
  isVisible?: boolean,
  fixed?: boolean,
  onVisibilityChanged?: Function,
  mode: "full"|"compact",
  setMode: (mode:"full"|"compact") => void
}
function WarningsInternal({mode, setMode, warnings, isVisible, onVisibilityChanged, fixed}: WarningsInternalProps) {
  // state
  
  // fire onVisiblityChanged if visibilty changes
  useEffect(() => {
    if(onVisibilityChanged) {
      onVisibilityChanged(isVisible);
    }
  }, [isVisible])

  // render
  if(warnings.length === 0) {
    return null;
  }
  if(mode === "full") {
    const renderedWarnings = warnings.map((w, index) => <Warning key={index} kind={w.kind}>{w.content}</Warning>);
    return (
      <div className={fixed ? CSS.containerFixed : CSS.container}>
        {renderedWarnings}
        <Toggle onClick={() => setMode("compact")}>
          <Icon icon={Icons.ChevronUp} />
        </Toggle>
      </div>
    )
  }
  else {
    return (
      <div className={`${fixed ? CSS.containerFixed : CSS.container} ${CSS.compact}`}>
        {warnings.length} Warnung{warnings.length > 0 ? "en" : ""}
        <Toggle onClick={() => setMode("full")}>
          <Icon icon={Icons.ChevronDown} />
        </Toggle>
      </div>
    )
  }
  
}

type ToggleProps = {
  onClick: () => void,
  children: React.ReactNode|React.ReactNode[]
}
function Toggle({onClick, children}:ToggleProps) {
  return (
    <div className={CSS.toggle} onClick={onClick}>
      {children}
    </div>
  )
}