import React, {useContext, useEffect, useState} from 'react'
import _ from "lodash"
import * as TS from "../../../../types";
import * as BL from "../../../../types/BusinessLogic";

// contexts & components
import { SurpriseContext } from "../Surprise.Context";
import { CardSection, Table, Tr, Td, Link, Loader } from "../../../controls";

export default function AppenturaMe() {
  // context
  const SC = useContext(SurpriseContext);
  // state
  const [closestRegion, setClosestRegion] = useState<{code:string, distance:number}|null>(null);

  // mount
  useEffect(() => {
    if(SC.surprise && SC.surprise.UserStartLocation) {
      const location = SC.surprise.UserStartLocation;
      const closestRegion = BL.Me.getClosestRegion(location.Lat, location.Lng);
      setClosestRegion(closestRegion);
    }
  }, [SC.surprise, SC.surprise.UserStartLocation]);

  // render nothing if this is not appentura.me
  if(!SC.surprise.IsMe) {
    return null;
  }

  // calculate a few things
  // render
  const sod = (s?:string) => (s || "").trim().length > 0 ?  (s || "").trim() : "-";
  const adventure = TS.Adventure.fromDb(SC.surprise);
  const meOrder = adventure.Order.Me!; // SC.surprise.Order.Me as TS.AdventureOrderMe;
  let accommodation = "-";
  if(meOrder.DurationInDays > 1) {
    const overnightStays = meOrder.DurationInDays - 1;
    const budgetPerNight = meOrder.AccommodationPrice || "unbekannt"
    const accommodationKind = meOrder.Accommodation;
    accommodation = `${overnightStays} ${overnightStays === 1 ? "Nacht" : "Nächte"} (Typ: "${accommodationKind}", Budget: ${budgetPerNight} Person/Nacht')`
  }
  
  return (
    <CardSection title="Appentura.Me">
      <Table>
        <Tr>
          <Td label>Shop</Td>
          <Td>{SC.surprise.Order.ShopCountry}</Td>
        </Tr>  
        <Tr>
          <Td label>Paket</Td>
          <Td>{meOrder.PackageName || "-"}</Td>
        </Tr>
        <Tr>
          <Td label>Teilnehmer</Td>
          <Td>{meOrder.ParticipantCount}</Td>
        </Tr>  
        <Tr>
          <Td label>Budget</Td>
          <Td>{meOrder.ParticipantCount} * {meOrder.ParticipantBudget} = {meOrder.ParticipantCount * meOrder.ParticipantBudget} {SC.surprise.Currency}</Td>
        </Tr>  
        <Tr>
          <Td label>Dauer</Td>
          <Td>{((meOrder.DurationInDays))} {meOrder.DurationInDays === 2 ? "Tage" : "Tag"}</Td>
        </Tr>
        <Tr>
          <Td label>Übernachtung(en)</Td>
          <Td>{accommodation}</Td>
        </Tr>
        <Tr>
          <Td label>Aktivitäts-Arten</Td>
          <Td>{sod((meOrder.ActivityTypes || []).join(", "))}</Td>
        </Tr>
        <Tr>
          <Td label>Ängste</Td>
          <Td>{adventure.TakeIntoAccount.Anxieties.join(", ")}</Td>
        </Tr>
        <Tr>
          <Td label>Allergien</Td>
          <Td>{adventure.TakeIntoAccount.Allergies.join(", ")}</Td>
        </Tr>
        <Tr>
          <Td label>Ausschlüsse</Td>
          <Td>{sod(SC.surprise.ScheduleComments)}</Td>
        </Tr>  
        <Tr>
          <Td label>empfohlene Region</Td>
          <Td>{closestRegion ? `${closestRegion.code} (${closestRegion.distance.toFixed(1)} km entfernt)` : "-"}</Td>
        </Tr>
        <History />
      </Table>
    </CardSection>
  )
}

function History() {
  // context
  const SC = useContext(SurpriseContext);
  // state
  const [mode, setMode] = useState<"loading"|"loaded">("loading");
  const [data, setData] = useState<any>([]);

  // mount
  useEffect(() => {
    load();
  }, []);

  // loads data
  const load = async() => {
    setMode("loading");
    const data = await TS.AdventureOld.loadCustomerHistory(SC.surprise.RecieverEmail, SC.surprise._id);
    setData(data);
    setMode("loaded");
  }

  // pre-render
  let content = null;
  if(mode === "loading") {
    // currently loading
    content = (
      <Tr><Td colSpan={2}>
        <Loader text="lade Daten ..." />
      </Td></Tr>
    )
  }
  else {
    // got data, prepare it
    const makeList = (activities:any[]) => {
      return activities.map((activity:any, index:number) => {
        const adventureLinks = activity.adventureIds.map((adv_id:string) => <Link to={`/surprise/${adv_id}`} key={adv_id}>{TS.Adventure.getShortId(adv_id, true)}</Link>)
        return <span key={index}>{activity.title} {adventureLinks} </span>
      })
    }
    const receivedActivities = makeList(data.activities.received);
    const reservedActivities = makeList(data.activities.reserved);
    content = (
      <>
        {/*}
        <Tr>
          <Td label>verschenkte Aktivitäten</Td>
          <Td>{reservedActivities}</Td>
        </tr>
        */}
        <Tr>
          <Td label>{SC.surprise.RecieverEmail} hat schon erlebt:</Td>
          <Td>{receivedActivities.length > 0 ? receivedActivities : "-"}</Td>
        </Tr>
      </>
    )
  }

  // render
  return content

}
