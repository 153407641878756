import React, {useState} from "react"
import _ from "lodash"

// context
const FormContext = React.createContext()

// provider
const FormProvider = (props) => {
  const [entity, setEntity] = useState()

  const [isValid, setIsValid] = useState(true)
  const [isDirty, setIsDirty] = useState(false)

  const [fields] = useState({}) // {<path>:{oldValue:<v1>, newValue:<v2>}}
  
  const [invalidFields, setInvalidFields] = useState({}) // {<path>:<validation object>}
  const [dirtyFields, setDirtyFields] = useState(new Set()) // [<path>]

  const [resetIndex, setResetIndex] = useState(0) // if this is changed, form elements will reset their value
  const [version, setVersion] = useState(0); // increments each time a field changes
  
  // TODO rename to updateField
  const onFieldChange = (path, value, validation) => {
    // don't have that field yet? add it
    if(_.isNil(fields[path])) {
      fields[path] = {
        oldValue:_.get(entity, path)
      }
    }

    // update value
    fields[path].newValue = value

    // update validity of form ... `.isValid` or `.valid` ... we don't care, give the user some slack
    if(validation.isValid || validation.valid) {
      delete invalidFields[path]
    }
    else {
      invalidFields[path] = validation
    }
    setIsValid(_.keys(invalidFields).length === 0)

    // dirty?
    if(fields[path].oldValue !== fields[path].newValue) {
      dirtyFields.add(path)
    }
    else {
      dirtyFields.delete(path)
    }
    setIsDirty(dirtyFields.size > 0);
    setVersion(version + 1);
  }

  const reset = () => {
    dirtyFields.clear()
    setResetIndex(resetIndex + 1)
  }

  const bake = () => {
    // TODO should this be possible if not valid?

    // result contains the changeset and ...
    let changeset = {};
    let changesetArray = [];
    let changesetFlat = {};
    
    _.forOwn(fields, (_value, key) => {
      // get rid of changes by baking new values into old values
      fields[key].oldValue = fields[key].newValue
      // add to changeset if
      if(dirtyFields.has(key)) {
        _.set(changeset, key, fields[key].newValue);
        changesetArray.push({path:key, value:fields[key].newValue});
        changesetFlat[key] = fields[key].newValue;
      }
    })

    // create merge function
    const merge = (entity) => {
      let updatedEntity = {};
      _.merge(updatedEntity, entity);
      _.merge(updatedEntity, changeset);
      return updatedEntity;
    }

    // update dirty state
    dirtyFields.clear()
    setIsDirty(false)

    // done
    return {
      changeset,
      changesetArray,
      changesetFlat,
      merge
    }
  }

  const init = (entity) => {
    setEntity(entity)
    setDirtyFields(new Set())
    setInvalidFields(new Set())
    setIsDirty(false)
    setIsValid(true)
  }

  const getNewValue = (path) => {
    let obj = _.get(fields, path)
    if(obj) {
      return _.get(fields, path).newValue
    }
    else {
      return _.get(entity, path) 
    }
  }

  const getOldValue = (path) => {
    let obj = _.get(fields, path)
    if(obj) {
      return _.get(fields, path).oldValue
    }
    else {
      return _.get(entity, path) 
    }
  }

  const getInvalids = () => {
    let messages = []
    _.forOwn(invalidFields, (value, key) => {
      messages.push({
        path:key,
        message:value.message
      })
    })
    return messages
  }

  // the values and functions provided
  let providerValue = {
    // values
    entity,
    isValid,
    isDirty,
    resetIndex,
    version,

    // functions
    onFieldChange,
    bake,
    init,
    reset,
    getNewValue,
    getOldValue,
    getInvalids
  }


  // render using the context
  return (
    <FormContext.Provider value={providerValue}>
      {props.children}
    </FormContext.Provider>
  )
}

export {FormContext, FormProvider}
