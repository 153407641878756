import _ from "lodash";
import React, {useState, useEffect, useContext} from "react";
import {SurpriseContext} from "../Surprise.Context";
import {Form, TextInput, Validators, Table, Tr, Td} from "../../../controls";

type AddressEditProps = {
  which: string,
  stopEdit: () => void,
}
export default function AddressEdit({which, stopEdit} : AddressEditProps) {
  // context
  const SC = useContext(SurpriseContext);
  // state
  const [entity, setEntity] = useState(null);
  
  // mount
  useEffect(() => {
    const address = _.clone(_.get(SC.surprise, `Order.${which}`));
    _.forOwn(address, (v, key) => address[key] = v || ""); // form does not like null values
    setEntity(_.clone(address));
  }, []);

  // form save event handler
  const save = async(formData:any) => {
    // make updated adresse object
    let updatedAddress = {};
    _.merge(updatedAddress, entity);
    _.merge(updatedAddress, formData.changeset);
    // save
    //await SC.updateAddress(which, updatedAddress);
    const path = `Order.${which}`;
    await SC.updateAtPath(path, updatedAddress, true);
    stopEdit();
  }

  // form cancel event handler
  const cancel = () => stopEdit();
  
  // render
  return (
    <Form entity={entity} onSave={save} onCancel={cancel} labelSave="speichern" labelCancel="abbrechen">
      <Table>
        <Tr>
          <Td><TextInput label="Vorname" path={"FirstName"} validate={Validators.isRequired()} /></Td>
          <Td><TextInput label="Nachname" path={"LastName"} validate={Validators.isRequired()} /></Td>
        </Tr>
        <Tr>
          <Td colSpan={2}><TextInput label="Firma" path={"Company"} /></Td>
        </Tr>
        <Tr>
          <Td colSpan={2}><TextInput label="Adresszeile 1" path={"Address1"} validate={Validators.isRequired()} /></Td>
        </Tr>
        <Tr>
          <Td colSpan={2}><TextInput label="Adresszeile 2" path={"Address2"} /></Td>
        </Tr>
        <Tr>
          <Td><TextInput label="PLZ" path={"Zip"} validate={Validators.isRequired()} /></Td>
          <Td><TextInput label="Ort" path={"City"} validate={Validators.isRequired()} /></Td>
        </Tr>
        <Tr>
          <Td colSpan={2}><TextInput label="Land" path={"Country"} /></Td>
        </Tr>
      </Table>
    </Form>
  );
}
