import _ from "lodash";
import React, { useEffect, useState } from "react";
import Config from "../../../config";
import Util from "../../../util/util";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";

// components and context
import { Button, Breadcrumb, Card, Link, Loader, Heading, Modal, ConfirmButton, Table, Tr, Td } from "../../controls";
// subviews
import Populate from "./BulkOrder.Populate";
import Export from "./BulkOrder.Export";
// styling
import CSS from "./BulkOrder.Item.module.scss";

// the component
export default function BulkOrder(props:any) {
  // state
  const [id] = useState(Util.getRouteId(props));
  const [bulkOrder, setBulkOrder] = useState<DA.BulkOrder|null>(null);
  const [modal, setModal] = useState<"none"|"populate">("none"); 

  // mount
  useEffect(() => {
    const load = async() => {
      if(id) {
        setBulkOrder(await DA.BulkOrderRepository.findById(id));
      }
    }
    load();
  }, [id]);

  // starts populating data
  const startPopulating = (id:string) => {
    setModal("populate");
  }

  // closes modal
  const closeDialog = () => setModal("none");

  // render
  let content = null;
  if(_.isNil(bulkOrder)) {
    content = <Loader />
  }
  else {
    const row = (key:any, label:any, value:any) => <Tr key={key}><Td label>{label}</Td><Td>{value}</Td></Tr>
    const address = (key:any, address:any) => {
      let kind:any = {invoice:"Rechnungsadresse", shipping:"Versandadresse"}
      let value = (
        <>
          <div>{address.company || ""}</div>
          <div>{address.firstName} {address.lastName}</div>
          <div>{address.street1}</div>
          <div>{address.street2}</div>
          <div>{address.zip} {address.city}</div>
        </>
      )
      return row(key, kind[address.kind], value)
    }
    const addresses = bulkOrder.addresses.map((a, index:number) => address(`address_${index}`, a))
    
    content = <>
      <Table>
        {row('company', 'Firma', bulkOrder.customer.company)}
        {row('contact', 'Kontakt', `${bulkOrder.customer.contactName}, ${bulkOrder.customer.contactEmail}, ${bulkOrder.customer.contactPhone}`)}
        {addresses}
        {row('costs', 'Verarbeitungspauschale', `CHF ${bulkOrder.fees.processing + bulkOrder.fees.shipping + bulkOrder.fees.packaging}`)}
        {row('itemCount', 'Anzahl & Wert', `${bulkOrder.item.count} * CHF ${bulkOrder.item.value} = CHF ${bulkOrder.item.count * bulkOrder.item.value} `)}
        {row('packaging', 'Verpackung', bulkOrder.item.packaging)}
        {row('from', 'überreicht von ...', bulkOrder.item.from)}
        {row('message', 'Widmung', bulkOrder.item.message)}
        {row('hideValue', "Wert verborgen", bulkOrder.item.hideValue ? "ja" : "nein")}
        {row('surprises', 'Überraschungen', <BulkOrderItems adventureIds={bulkOrder.adventures} />)}
        {row('notes', 'Notizen', bulkOrder.notes || "")}
      </Table>
      <br />
      <hr />
      <br />
      <div>
        <Export bulkOrder={bulkOrder} />
        <br />
      </div>
      <div>
        <Button size="small" onClick={startPopulating}>vorabfüllen</Button>
      </div>
      <Modal isOpen={modal==="populate"} title="Überraschungen vorabfüllen" onClose={closeDialog}>
        <Populate bulkOrder={bulkOrder} closeDialog={closeDialog} />
      </Modal>
    </>
  }
  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}, {title:"Sammelbestellungen", to:"/bulkorders"}]} location="Sammelbestellung" />
    <Heading>Sammelbestellung</Heading>
    <Card className={CSS.container}>
      {content}
    </Card>
  </>
}

type BulkOrderItemsProps = {
  adventureIds: string[]
}
function BulkOrderItems({adventureIds}: BulkOrderItemsProps) {
  // state
  const [items, setItems] = useState<any>(null);
  const [selectedAction, setSelectedAction] = useState<"not_sold"|"sold">("not_sold");
  const [busy, setBusy] = useState(false);

  //  mount with adventure ids
  useEffect(() => {
    if(adventureIds) {
      load();
    }
  }, [adventureIds]);

  // loads data
  const load = async() => {
    const filter = {_id:{$in:adventureIds}};
    const projection = {Status:1, _id:1, "Order.Prepaid":1};
    const result = await TS.AdventureOld.search(filter, projection);
    result.forEach((r:any) => {
      r.__selected = false;
      r.__inWarehouseSort = _.get(r, "Order.Prepaid.InWarehouse") === true ? 1 : -1
    });
    const resultSorted = result.sort((a:any, b:any) => a.__inWarehouseSort > b.__inWarehouseSort ? 1 : -1);
    setItems(resultSorted);
  }

  // execute action
  const onExecuteAction = async() => {
    const selectedItems = items.filter((item:any) => item.__selected);
    if(selectedItems.length > 0) {
      setBusy(true);
      const inWarehouse = selectedAction === "not_sold" ? true : false;
      const changeset = {"Order.Prepaid.InWarehouse":inWarehouse};
      for(const selectedItem of selectedItems) {
        await TS.AdventureOld.update(selectedItem, changeset);
      }
      await load();
      setBusy(false);
    }
  }

  // toggle all
  const onToggleAll = (e:any) => {
    const updatedItems = items.map((item:any) => {
      item.__selected = e.target.checked;
      return item;
    })
    setItems(updatedItems);
  }

  // render
  if(_.isNil(items)) {
    return <Loader />
  }
  // adventures
  const rows = items.map((item:any) => <BulkOrderItem key={item._id} adventure={item} />)
  // action options
  const actionOptions = <>
    <option value="not_sold">nicht verkauft</option>
    <option value="sold">verkauft</option>
  </>
  // render the table and actions
  return <> 
    <div className="toggle">
      <label>
        <input type="checkbox" onChange={onToggleAll} />
        <span>alle wählen / abwählen</span>
      </label>
    </div>
    <Table className="items">{rows}</Table>
    <div className="actions">

    </div>
    <div className="actions">
      gewählte als <select value={selectedAction} onChange={e => setSelectedAction(e.target.value as "sold"|"not_sold")}>{actionOptions}</select> markieren <ConfirmButton busy={busy} onConfirm={onExecuteAction}>ausführen</ConfirmButton>
    </div>
  </>;
}

type BulkOrderItemProps = {
  adventure:any
}
function BulkOrderItem({adventure}: BulkOrderItemProps) {
  const [isSelected, setIsSelected] = useState(adventure.__selected);

  // checkbox click event handler
  const onChangeSelected = (e:any) => {
    const checked = e.target.checked;
    adventure.__selected = checked;
    setIsSelected(checked);
  }

  // __selected flag changed
  useEffect(() => {
    setIsSelected(adventure.__selected);
  }, [adventure.__selected]);

  // render
  const ticketLink_prepaid = `${Config.api.ApiRoot}/v1/certificate/${adventure._id}?kind=flyer_prepaid&o=pdf`;
  const ticketLink_prepaid_a4_html = `${Config.api.ApiRoot}/v1/certificate/${adventure._id}?kind=standard_prepaid&o=html`;
  const ticketLink_regular = `${Config.api.ApiRoot}/v1/certificate/${adventure._id}?kind=flyer&o=pdf`;
  return (
    <Tr>
      <Td><input type="checkbox" checked={isSelected} onChange={onChangeSelected} /></Td>
      <Td><Link to={`/surprise/${adventure._id}`} target="_blank">{adventure._id}</Link></Td>
      <Td><Link to={ticketLink_prepaid} target="_blank">Prepaid-Ticket (A6, PDF)</Link></Td>
      <Td><Link to={ticketLink_prepaid_a4_html} target="_blank">Prepaid-Ticket (A4, HTML)</Link></Td>
      <Td><Link to={ticketLink_regular} target="_blank">Ticket drucken</Link></Td>
      <Td>{TS.Adventure.getStatusName(adventure.Status)}</Td>
      <Td>{_.get(adventure, "Order.Prepaid.InWarehouse") === true ? "noch nicht verkauft" : "verkauft"}</Td>
    </Tr>
  )
}