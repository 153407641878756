import React, { ChangeEventHandler, useEffect, useState } from "react";
import expand from "string-template"
import Api from './../../../util/api'
import { Button, Breadcrumb, Card, Loader } from '../../controls'
import './MassMailer.scss'

const numberOfPreviewItems = 2;

export default function MassMailer() {
  // state
  const [templates, setTemplates] = useState<any[]>([]);
  const [templateToEdit, setTemplateToEdit] = useState<any|null>(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>("");
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
  const [columns, setColumns] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [reader] = useState(new FileReader());

  // mount
  useEffect(() => {
    reader.addEventListener("load", onReaderLoad);
    loadTemplates();
  }, []);


  // loads templates
  const loadTemplates = async() => {
    // get all templates
    const templates = await Api.get('mailtemplates');
    setTemplates(templates);
    setSelectedTemplateId(templates.length > 0 ? templates[0]._id : '');
  }
  

  // event handler: reader did load
  const onReaderLoad= () => {
    let doc = reader.result;
    if(doc) {
      let lines = String(doc).split('\n');
      let columns = lines[0].split(';')
      let items = lines
        .filter((_line, index) => index > 0)               // the first line is the line with the column names, drop it
        .filter((line) => (line || '').trim().length > 0) // make sure no empty lines are used
        .map(line => {
          let parts = line.split(';');
          let item:any = {};
          for(let i=0; i<columns.length; i+=1) {
            item[columns[i].trim()] = parts[i];
          }
          return item;
        })
  
      setColumns(columns);
      setItems(items);
      setIsLoadingFile(false);
    }
  }

  // event handler: selected file changed
  const onFileChange:ChangeEventHandler<HTMLInputElement> = (e) => {
    if(e.target.files) {
      setIsLoadingFile(true);
      const file = e.target.files[0];
      if(file) {
        reader.readAsText(file);
      }
    }
  }

  // render
  return (
    <div id="v-massmailer">
      <Breadcrumb links={[ {title:'Home', to:'/'}]}
                  location="Massenmails" />
      <Card title="Massenmails">
        <Data isLoadingFile={isLoadingFile} columns={columns} items={items} onFileChange={onFileChange} />
        <Templates 
          templates={templates} 
          templateToEdit={templateToEdit}
          selectTemplate={(template:any) => setTemplateToEdit(template)}
          selectTemplateId={(id:string) => setSelectedTemplateId(id)}
          loadTemplates={loadTemplates}
        />
        <Send
          templates={templates}
          items={items}
          selectedTemplateId={selectedTemplateId}
        />
      </Card>
    </div>
  )
}

type DataProps = {
  isLoadingFile:boolean,
  columns:any[],
  items:any[],
  onFileChange:ChangeEventHandler<HTMLInputElement>
}
function Data({isLoadingFile, columns, items, onFileChange}: DataProps) {
  // pre-render
  let content;
  if(isLoadingFile) {
    content = <Loader />
  }
  else {
    let _columns = columns.join(', ');
    if(columns.length === 0) {
      _columns = "noch kein .CSV eingelesen";
    }
    let entries_count = items.length;
    content = (
      <div>
        <input type="file" onChange={onFileChange} name="file-selector" />
        <div><b>Spalten</b></div>
        <div>{_columns}</div>
        <div><b>Einträge</b></div>
        <div>{entries_count > 0 ? `${entries_count} Einträge`: `0 Einträge oder noch kein File geladen`}</div>
      </div>
    )
  }
  return (
    <section>
      <h4>Daten laden</h4>
      <div>
        {content}
      </div>
    </section>
  )
}

type TemplatesProps = {
  templates: any[],
  templateToEdit: any,
  selectTemplate: (template:any) => void,
  selectTemplateId: (templateId:string) => void,
  loadTemplates: () => void,
}
function Templates({templates, selectTemplate, selectTemplateId, templateToEdit, loadTemplates} : TemplatesProps) {
  // state
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>("");
  
  // event handlers
  const onClickCreateTemplate = (e:any) => {
    const templateToEdit = {name:'Neue Vorlage', to:'', subject:'', body:''};
    selectTemplate(templateToEdit);
  }
  const onClickEditTemplate = (e:any) => {
    const templateToEdit = templates.find(template => selectedTemplateId === template._id);
    selectTemplate(templateToEdit);
  }
  const onChangeTemplateElement = (e:any) => {
    const value = e.target.value;
    const field = e.target.getAttribute('data-field');
    const updatedTemplate = {...templateToEdit};
    updatedTemplate[field] = value;
    selectTemplate(updatedTemplate);
  }
  let onClickSaveTemplate = (_e:any) => {
    saveTemplate(templateToEdit);
  }
  let onClickAbortTemplate = (_e:any) => {
    selectTemplate(null);
  }
  let onChangeSelectedTemplate = (e:any) => {
    const selectedTemplateId = e.target.value;
    setSelectedTemplateId(selectedTemplateId);
  }

  

  // saves template
  const saveTemplate = async(template:any) => {
    let verb = template._id ? Api.put : Api.post
    await verb('mailtemplates', template);
    loadTemplates();
  }

  // form to edit / create template
  let template_edit = null
  if(templateToEdit) {
    template_edit = (
      <div>
        <label>Name:</label>
        <input type="text" value={templateToEdit.name} data-field="name" onChange={onChangeTemplateElement} />
        <label>To:</label>
        <input type="text" value={templateToEdit.to} data-field="to" onChange={onChangeTemplateElement} />
        <label>Subject:</label>
        <input type="text" value={templateToEdit.subject} data-field="subject" onChange={onChangeTemplateElement} />
        <label>Body:</label>
        <textarea value={templateToEdit.body} data-field="body" onChange={onChangeTemplateElement} />
        <Button onClick={onClickSaveTemplate}>speichern</Button>
        <Button onClick={onClickAbortTemplate} intent="cancel">abbrechen</Button>
      </div>
    )
  }

  return (
    <section>
      <h4>Vorlage wählen</h4>
      <div>
        <select value={selectedTemplateId} onChange={onChangeSelectedTemplate}>
          {templates.map(template => (<option key={template._id} value={template._id}>{template.name}</option>))}
        </select>
        <Button size="small" onClick={onClickEditTemplate}>bearbeiten</Button>
        <Button size="small" onClick={onClickCreateTemplate}>neu</Button>
      </div>
      {template_edit}
    </section>
  )
}

type SendProps = {
  templates: any[],
  items: any[],
  selectedTemplateId: string,
}
function Send({templates, items, selectedTemplateId} : SendProps) {
  // state
  const [isSendingMails, setIsSendingMails] = useState<boolean>(false);
  const [preview, setPreview] = useState<any[]>([]);
  const [from, setFrom] = useState<string>("Stefan Flück <stefan@appentura.ch>");
  const [withSelfies, setWithSelfies] = useState<boolean>(false);

  // event handlers
  const onChangeFrom = (e:any) => {
    const from = e.target.value;
    setFrom(from);
  }
  const onChangeSelfiesAttachment = (e:any) => {
    const withSelfies = e.target.checked;
    setWithSelfies(withSelfies);
  }

  const onClickPreview = (e:any) => {
    let template = templates.find((template:any) => template._id === selectedTemplateId)
    let preview = items
      .filter((item:any, index) => {
        return index < numberOfPreviewItems
      })
      .map((item:any) => {
        return {
          to:expand(template.to, item),
          subject:expand(template.subject, item),
          body:expand(template.body, item)
        }
      })
    setPreview(preview);
  }

  const onClickSend = (e:any) => {
    setIsSendingMails(true);
    let template = templates.find(template => template._id === selectedTemplateId)
    // let items = this.state.items
    // let from = this.state.from
    // let withSelfies = this.state.withSelfies
    Api.post('mail/massmail', {template, items, from, withSelfies})
      .then(result => {
        setIsSendingMails(false);
        alert('Mail-Versand abgeschlossen.')
      })
      .catch(err => {
        setIsSendingMails(false);
        console.error('error', err)
        alert(`Fehler beim Mail-Versand: ${JSON.stringify(err)}`)
      })
  }

  

  // pre-render
  let content;
  let preview_items = preview.map((p, index) => {
    let body_paragraphs = p.body.split('\n').map((line:any, index:number) => <p key={index}>{line}</p>)
    return (
      <div key={index} className="preview-item">
        <p>TO: {p.to}</p>
        <p>SUBJ: {p.subject}</p>
        <div>{body_paragraphs}</div>
      </div>
    )
  })
  if(preview_items.length > 0) {
    content = (
      <div className="preview">
        {preview_items}
        <div>
          {numberOfPreviewItems} von {items.length}
        </div>
      </div>
    )
  }
  let loader = isSendingMails ? <Loader /> : null
  return (
    <section>
      <h4>Versenden</h4>
      <div>
        <label>From:</label>
        <input type="text" value={from} placeholder="Stefan Flück <stefan@appentura.ch>" onChange={onChangeFrom} />
        <label className="inline">Attach Selfies:</label>
        <input type="checkbox" value={withSelfies ? "checked" : ""} onChange={onChangeSelfiesAttachment} />
      </div>
      <Button onClick={onClickPreview} disabled={items.length === 0}>Vorschau</Button>
      <Button onClick={onClickSend} disabled={items.length === 0}>senden</Button>
      <div>{loader}</div>
      <div>
        {content}
      </div>
    </section>
  )
}