import React, {useContext} from "react";
import Util from "../../../util/util";
import { ShippingContext } from "./Shipping.Context";
import { Card, Link} from "../../controls";

import CSS from "./Shipping.Labels.module.scss";

export default function Labels() {
  // context
  const SC = useContext(ShippingContext);
  
  // render
  let filename = `Versand ${Util.printDatePrefix(new Date())}.csv`
  return (
    <Card title="Paket-Etiketten" collapsible={false} expanded={true} className={CSS.container}>
      <div className="link">
        1. <Link to={"data:text/csv;charset=utf-8," + encodeURIComponent(SC.labelsCsv)} download={filename} >CSV herunterladen</Link>
      </div>
      <div className="link">
        2. <Link to="https://service.post.ch/vgkklp2/avgk/secured/ErweiterterProzess/ShowAdressenImportieren" target="_blank">Post.ch - Adressen hochladen</Link>
      </div>
    </Card>
  );
}
