import React from "react"

import "./NotFound.scss"

class NotFound extends React.Component {
  render() {
    return (
      <div id="aat-v-notfound">
        <h1>404</h1>
      </div>
    )
  }
}

export default NotFound