import _ from "lodash";
import React, { useState, useEffect, useContext } from "react";
import { SurpriseContext } from "../Surprise.Context";
import { Coordinates, Form, TextInput } from "../../../controls";

type UserStartLocationEditProps = {
  stopEdit: () => void
}
export default function UserStartLocationEdit({stopEdit}: UserStartLocationEditProps) {
  // context
  const SC = useContext(SurpriseContext);
  // state
  const [entity, setEntity] = useState<any|null>(null);
  
  // mount
  useEffect(() => {
    setEntity({
      UserStartLocation: SC.surprise.UserStartLocation || {Lat:0, Lng:0},
      UserStartLocationText: SC.surprise.UserStartLocationText || ""
    })
  }, []);

  // form save event handler
  const save = async(formResult: any) => {
    if(formResult.changeset.UserStartLocation) {
      await SC.updateAtPath("UserStartLocation", formResult.changeset.UserStartLocation, true);
    }
    if(formResult.changeset.UserStartLocationText) {
      await SC.updateAtPath("UserStartLocationText", formResult.changeset.UserStartLocationText, true);
    }
    stopEdit();
  }

  // form cancel event handler
  const cancel = () => stopEdit();

  // render
  return (
    <div id="v-comments-edit">
      <Form entity={entity} onSave={save} onCancel={cancel} labelSave="speichern" labelCancel="abbrechen">
        <Coordinates label="Koordinaten" path="UserStartLocation" pathLat="Lat" pathLng="Lng" />
        <TextInput label="Bezeichnung" path="UserStartLocationText" />
      </Form>
    </div>
  );
}
