import moment from "moment";
import _ from "lodash";
import { Adventure } from "../Adventure";

export type AdventureWarningKind = "info"|"priority"|"warning"|"allergy"|"anxiety"|"critical";

export class AdventureWarning {
  kind: AdventureWarningKind;
  content: string;
  
  constructor(kind:AdventureWarningKind, content:string) {
    this.kind = kind;
    this.content = content;
  }

  public static getWarnings(surprise:any, log:any[]): AdventureWarning[] {
    const adventure: Adventure = Adventure.fromDb(surprise);
    const warnings:AdventureWarning[] = [];

    // home delivery
    const containsHomeDelivery = surprise.Activities.some((a:any) => a.IsHomeDelivery)
    if(containsHomeDelivery) {
      warnings.push({
        kind:"info",
        content:"Diese Überraschungen beinhaltet eine Heimlieferung."
      })
    }

    // online event
    const containsOnlineEvent = surprise.Activities.some((a:any) => a.IsOnlineEvent)
    if(containsOnlineEvent) {
      warnings.push({
        kind:"info",
        content:"Diese Überraschungen beinhaltet einen Online-Event."
      })
    }

    // priority
    if(surprise.HasPriority) {
      warnings.push({
        kind:"priority",
        content:"Dieser Überraschung sollte Vorrang gegeben werden"
      })
    }

    // payment warning
    if((surprise.PaymentType || '') === "transfer" && (!surprise.PaymentDate) && (surprise.Status > 2)) {
      warnings.push({
        kind:"warning",
        content:"Diese Überraschung wurde noch nicht bezahlt. Bitte sprich dich mit dem CFO (Stefan) ab, bevor du diese Überraschung organisierst."
      })
    }

    // start time warning
    if(surprise.StartTime && surprise.UserStartTime) {
      if(moment(surprise.UserStartTime).format("DD.MM.YYYY") !== moment(surprise.StartTime).format("DD.MM.YYYY")) {
        warnings.push({
          kind: 'warning',
          content: "Wunsch- und Start-Datum stimmen nicht überein!"
        })
      }
    }

    // order comment
    let orderComments = (_.get(surprise, "Order.Comments") || "").trim()
    if(orderComments.length > 0) {
      warnings.push({
        kind:'info',
        content:`Bestell-Kommentar: ${orderComments}`
      })
    }

    // scheduling comment
    if(surprise.ScheduleComments && surprise.ScheduleComments.trim().length > 0) {
      warnings.push({
        kind:'info',
        content:`Termin-Kommentar: ${surprise.ScheduleComments}`
      })
    }

    // accept comment
    if(surprise.AcceptComments && surprise.AcceptComments.trim().length > 0) {
      warnings.push({
        kind:'info',
        content:`Bestätigungs-Kommentar: ${surprise.AcceptComments}`
      })
    }

    // allergies
    if(adventure.TakeIntoAccount.Allergies.length > 0) {
      warnings.push({kind:"allergy", content: "Allergien: " +  adventure.TakeIntoAccount.Allergies.join(', ')});
    }
    // anxieties
    if(adventure.TakeIntoAccount.Anxieties.length > 0) {
      warnings.push({kind: "anxiety", content: "Ängste: " +  adventure.TakeIntoAccount.Anxieties.join(', ')});
    }

    // Begleitperson
    if(surprise.AdditionalRecieverEmail && surprise.AdditionalRecieverEmail.trim().length > 0) {
      warnings.push({
        kind:'info',
        content:`Begleitperson: eine Begleitperson wurde vom Beschenkten hinzugefügt (${surprise.AdditionalRecieverEmail})`
      })
    }

    // log entries
    (log || []).forEach((logEntry:any) => {
      if(logEntry.ShowWarning) {
        warnings.push({
          kind:'info',
          content:`Log: ${logEntry.Text}`
        })
      }
    })

    // missing region
    let missingRegion = false
    if(surprise.IsSoldOffline) {
      missingRegion = !surprise.SoldOfflineInfo.RegionCode && !surprise.Region
    }
    else {
      missingRegion = !surprise.Region
    }
    if(missingRegion) {
      warnings.push({
        kind:'warning',
        content:'Der Überraschung ist keine Region zugewiesen'
      })
    }

    // dunning block
    if(surprise.DunningBlock) {
      warnings.push({
        kind:"info",
        content: "Mahnsperre aktiv"
      })
    }

    // current step and / or > number of Steps
    // (this might happen if the adventure was active, went up to step 10, then something went wrong, adventure was set back to a state before ready, steps got changed resulting in smaller number of steps. Example: /surprise/619372fcf1a5d700173cef5d)
    let numberOfSteps = (surprise.Steps || []).length;
    let currentStep = surprise.CurrentStep || 0; // NOTE: fun fact, this is NOT Zero based. 0=no step reached yet, 1 = step with index 0
    let maxStep = surprise.MaxStep || 0;
    if(currentStep !== 0 && maxStep !== 0) {
      if(currentStep > numberOfSteps || maxStep > numberOfSteps)  {
        warnings.push({
          kind:"critical",
          content: `Der Index des gegenwärtigen (CurrentStep: ${currentStep}) oder maximalen (MaxStep: ${maxStep}) Schrittes liegt ausserhalb des zulässigen Bereichs (0-${numberOfSteps}). Du kannst das unter "Aktionen > Anderes > Schritt-Indizes zurücksetzen" korrigieren.`
        })
      }
    }

    // 100% percent voucher
    const voucher = surprise.Order.Voucher;
    if(voucher && voucher.Kind === "percentage" && voucher.Value === 100) {
      warnings.push({
        kind: "critical",
        content: "Diese Überraschung wurde mit einem 100% Gutschein bezahlt. Unbedingt zusammen mit CMO und / oder CFO sicherstellen, dass das geplante Budget nicht überschritten wurde."
      })
    }

    // done
    return warnings;
  }

  
}