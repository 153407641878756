import { AdventureOutline, AdventureOutlineHelper } from "../AdventureOutline";
import { Adventure } from "./Adventure";
import { AdventureStatus } from "./AdventureStatus";
import * as DA from "../DataAccess";

export type AdventureAuditItemSeverity = 3 | 2 | 1;

export class AdventureAuditItem {
  title: string;
  description: string = "";
  hint: string|null = null;
  severity: AdventureAuditItemSeverity;
  passed: boolean = true;
  relatedLink: {title:string, url:string}|null = null;

  constructor(title: string, severity: AdventureAuditItemSeverity) {
    this.title = title;
    this.severity = severity;
  }

  get severityString() {
    switch(this.severity) {
      case 3:
        return "High";
      case 2:
        return "Medium";
      case 1:
        return "Low";
    }
  }
}

export class AdventureAudit {
  private static _relevantAdventureStati = [AdventureStatus.ConditionsSent, AdventureStatus.ConditionsAccepted, AdventureStatus.Ready];
  
  private _adventure: Adventure;
  private _activityCatalogue: Array<DA.Activity>;
  private _items: Array<AdventureAuditItem> = [];
  private _outline: AdventureOutline|null = null;

  constructor(adventure: Adventure, activityCatalogue: Array<DA.Activity>) {
    this._adventure = adventure;
    this._activityCatalogue = activityCatalogue;
  }

  // getters
  get items() {
    return this._items;
  }
  get failedItems() {
    return this._items.filter(item => !item.passed);
  }

  static isRelevantStatus(status: AdventureStatus):boolean {
    return AdventureAudit._relevantAdventureStati.includes(status);
  }

  /**
   * Executes the audit.
   * Note only runs if the adventure is in a relevant status.
   */
  async run() {
    if(AdventureAudit.isRelevantStatus(this._adventure.Status)) {
      // load necessary data
      const outline = await AdventureOutlineHelper.loadOutline(this._adventure._id!);
      this._outline = outline || null;
      // TODO should we add a warning if the outline is not found?

      // run audits
      await this._auditTravelTime();
      await this._activityOrder_flanierenDinieren();
      await this._activityTime();
    }
  }

  /**
   * Compares the total time of travel to the total time of activities.
   */
  private async _auditTravelTime() {
    // no outline? abort
    if(!this._outline) {
      return;
    }

    // helper function to convert seconds to hours and minutes
    const secondsToHoursAndMinutes = (seconds: number): string => {
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const minutesLeft = minutes % 60;
      const hoursString = hours > 0 ? `${hours} Std. ` : "";
      const minutesString = minutesLeft > 0 ? `${minutesLeft} Min.` : "";
      return `${hoursString}${minutesString}`.trim();
    };

    // get total travel and activity time
    let activityTime = 0;
    let travelTime = 0;
    this._outline.activities.forEach(activity => {
      const activeRoute = activity.routes.find(route => route.selected) || activity.routes[0];
      if(activeRoute) {
        travelTime += activeRoute.duration;
      }
      activityTime += activity.duration;
    });

    // compare the two
    const item = new AdventureAuditItem("Anteil Reisezeit", 2);
    item.description = `Die gesamte Reisezeit ist ${secondsToHoursAndMinutes(travelTime)}, die gesamte Aktivitätszeit ist ${secondsToHoursAndMinutes(activityTime)}`  
    if(travelTime > activityTime) {
      item.passed = false;
      item.hint = "Die Reiszeit sollte nicht länger sein als die Aktivitätszeit.";
    }
    this._items.push(item);
  }

  /**
   * makes sure that the activities are in an order that makes sense
   */
  private async _activityOrder_flanierenDinieren() {
    const flanierenDinierenId = "5a045f705526b40012648470";
    const correctOrder = [
      "5ec35c9a105c6b0016f3a8c9", // apéro (optional)
      "5ec35de5105c6b0016f3a8e8", // vorspeise
      "5ec35ee3105c6b0016f3a907", // hauptgang
      "5ec35f5d105c6b0016f3a926", // dessert
    ];

    // check if the adventure is a Flanieren & Dinieren adventure
    if(!this._adventure.Activities.map(activity => activity.ActivityId).includes(flanierenDinierenId)) {
      // not a flanieren & dinieren adventure, abort
      return;
    }

    // steps defined?
    if(this._adventure.Steps.length === 0) {
      return;
    }

    // get order of (relevant) activities
    const actualOrder = this._adventure.Steps
      .filter(step => step.ActivityId !== undefined) // not all steps have an activity
      .filter(step => correctOrder.includes(step.ActivityId!)) // only relevant activities
      .map(step => step.ActivityId);

    // check if the order is correct
    const audit = new AdventureAuditItem("Flanieren & Dinieren", 1);
    audit.description = "Die Aktivitäten sind in der richtigen Reihenfolge.";
    audit.hint = "Die Aktivitäten sollten in folgender Reihenfolge stattfinden: Apéro (optional), Vorspeise, Hauptgang, Dessert.";
    const indexOffset = correctOrder.length - actualOrder.length; // since apéro is optional, we need an offset on the correct order
    for(let i = 0; i < actualOrder.length; i++) {
      if(actualOrder[i] !== correctOrder[i + indexOffset]) {
        audit.passed = false;
        audit.description = "Die Aktivitäten sind nicht in der richtigen Reihenfolge.";
      }
    }
    
    // add item to collection
    this._items.push(audit);

  }

  /**
   * Checks if the activity times are sensible.
   * To do so we look at the activity's available hours as defined in the activity catalogue.
   */
  private async _activityTime() {
    // no outline? abort
    if(!this._outline) {
      return;
    }

    // check each activity
    this._outline!.activities.forEach(outlineActivity => {
      // get activity from catalogue
      const activity = this._activityCatalogue.find(entry => entry._id === outlineActivity.activityId);
      if(activity) {
        const plannedHour = new Date(outlineActivity.startTime).getHours(); 
        const auditItem = new AdventureAuditItem(`Zeitpunkt der Aktivität '${activity.title.de}'`, 1);
        auditItem.description = `Die Aktivität ist um ${plannedHour} Uhr verfügbar.`;
        auditItem.relatedLink = {
          title: activity.title.de,
          url: `/activities/${activity._id!}`
        }
        if(!activity.availableHours.includes(plannedHour)) {
          auditItem.passed = false;
          auditItem.description = `Die Aktivität ist nicht zu dieser Zeit verfügbar.`;
          if(activity.availableHours.length > 0) {
            auditItem.hint = `Die Aktivität ist für ${plannedHour} geplant, ist aber nur zu folgenden Zeiten verfügbar: ${activity.availableHours.join(", ")}`;
          } else {
            auditItem.hint = "Für die Aktivität wurden keine Verfügbarkeitszeiten definiert."
          }
        }
        this._items.push(auditItem);
      }
    });
  }
}

/**
 * Other possible audits:
  - reisen: hin und her (z.b. Winterthur -> Baar -> Winterthur)
- viel umsteigen: wenn Reise mehr als 7 etappen
- reisekarte
- text hinweise
- ÖV braucht genaue Adresse (nicht einfach nur Stadt)
- wenn Ende der Ü spät und am Arsch der Welt
- spezifisch: kulinarik trail flims müssen die Rätsel am Wochenende an einem anderen Ort abgeholt werden, je nach Standort an anderem Ort
 * 
 */