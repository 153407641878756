import React, {useEffect, useState} from 'react';
import * as TS from "../../../../types";

import CSS from "./StateVector.module.scss";

type StateVectorProps = {
  activeStatus: TS.AdventureStatus
}
function StateVector({activeStatus}: StateVectorProps) {
  // state  
  const [items, setItems] = useState<any>([]);

  // mount
  useEffect(() => {
    let arr = TS.AdventureStatiOrdered
      .map((status: TS.AdventureStatus, index:number) => {
        const isFirst = index === 0;
        const isLast = index === TS.AdventureStatiOrdered.length - 1;
        const isSelected = status === activeStatus;
        
        return(
          <div className={CSS.item} key={status}>
            <div className={`${CSS.line} ${isFirst ? CSS.first : ""} ${isLast ? CSS.last : ""}`}></div>
            <div className={`${CSS.circle} ${isSelected ? CSS.selected : ""}`}></div>
            <div className={CSS.text}>{TS.Adventure.getStatusName(status)}</div>
          </div>
        )
    })
    setItems(arr)
  }, [activeStatus])
  
  // render
  return (
    <div className={CSS.container}>
      {items}
    </div>
  )
}


export default StateVector

