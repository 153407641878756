import React, { useState } from "react";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";


// context
type ActivityPackageContextType = {
  loaded:boolean,
  activityPackage?: TS.ActivityPackage, 
  activityCatalogue: Array<TS.Activity>,
  regionCatalogue: Array<DA.Region>

  //addActivity:(activityId:String) => void,
  load:(id:string) => void,
  save:() => Promise<TS.ResultOld>
}
const ActivityPackageContext = React.createContext<ActivityPackageContextType|undefined>(undefined);

// provider
type ActivityPackageProviderProps = {
  children: React.ReactNode|Array<React.ReactNode>
}
function ActivityPackageProvider(props:ActivityPackageProviderProps) {
  // state
  const [loaded, setLoaded] = useState<boolean>(false);
  const [activityPackage, setActivityPackage] = useState<TS.ActivityPackage|undefined>();
  const [activityCatalogue, setActivityCatalogue] = useState<Array<TS.Activity>>([]);
  const [regionCatalogue, setRegionCatalogue] = useState<Array<DA.Region>>([]);

  // provider value
  const value = {
    // props
    loaded,
    //activities,
    activityPackage,
    activityCatalogue,
    regionCatalogue,

    // functions
    load: async(id:string) => {
      const activityPackage = await TS.ActivityPackage.loadById(id);
      setActivityCatalogue(await TS.Activity.allActiveMain());
      setRegionCatalogue( await DA.RegionRepository.findAll());

      if(activityPackage) {
        setActivityPackage(activityPackage);
      }
      setLoaded(true);
      
      // TODO deal with not finding the requested pacakge
      //setActivityPackage(activityPackage);
    },
    save: async() => {
      if(activityPackage) {
        const result:any = await activityPackage.save();
        if(result.success) {
          setActivityPackage(result.data);
        }
        else {
          // TODO
        }
        return result;
      }
    }
  }

  // render
  return (
    <ActivityPackageContext.Provider value={value}>
      {props.children}
    </ActivityPackageContext.Provider>  
  )
}

export {ActivityPackageContext, ActivityPackageProvider};
