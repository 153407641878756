import api from './api'
import appentura from './appentura'
import auth from './auth'
import drive from './drive'
import environment from './environment'
import firebase from './firebase'
import meta from './meta'
import misc from './misc'
import shop from './shop'
import site from "./site"
import surprise from './surprise'
import web from "./web"

export default {
  appentura,
  api,
  auth,
  drive,
  environment,
  firebase,
  meta,
  misc,
  shop,
  site,
  surprise:surprise[environment],
  web
}

// TODO all exports should be done like surprise, using the environemnt ... that way each config can be used explicitly