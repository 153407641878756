import { IncidentCategory, IncidentCategorySubcategory } from "./IncidentCategory";
import { Mapper } from "../_Common/Mapper";

export class IncidentCategoryMapper extends Mapper<IncidentCategory> {
  public apiEndpoint = "incidentCategories";

  public toDb(incidentCategory: IncidentCategory): any {
    const subcategories = incidentCategory.subcategories.map(s => this.subcategoryToDb(s));
    return {
      _id: incidentCategory._id,
      title: incidentCategory.title,
      subcategories: subcategories
    }
  }

  private subcategoryToDb(subcategory: IncidentCategorySubcategory) {
    return {
      _id: subcategory._id,
      title: subcategory.title
    }
  }

  public fromDb(obj:any): IncidentCategory {
    const subcategories = (obj.subcategories || []).map((s:any) => this.subcategoryFromDb(s));
    return {
      _id: obj._id || undefined,
      title: obj.title || "",
      subcategories: subcategories,
    }
  }

  private subcategoryFromDb(obj:any): IncidentCategorySubcategory {
    return {
      _id: obj._id,
      title: obj.title || ""
    }
  }

}