import _ from "lodash";
import React, { useState, useEffect, useContext, useRef } from "react";
import { Form, DateInput } from "../../../controls";

type StartTimeProps = {
  onChange: any,
  initialValue: any,
  stopEdit: () => void,
  label: string
}
export default function StartTime({onChange, initialValue, stopEdit, label} : StartTimeProps) {
  // state
  const [data] = useState({value: initialValue || new Date()});
  
  // mount
  useEffect(() => {
  }, []);

  // 
  const save = async(formResult : any) => {
    let updated_data: any = {}
    _.merge(updated_data, data)
    _.merge(updated_data, formResult.changeset)

    if(onChange) {
      onChange(updated_data.value);
    }
    else {
      console.info("no onChange function provided")
    }
    
    stopEdit();
  }

  
  // render
  return (
    <div>
      <Form 
        entity={data} 
        onSave={save} 
        onCancel={stopEdit}
        busy={false}
      >
        <DateInput 
          label={label}
          path="value" 
          kind="datetime" 
          disableCalendar={true} 
        />
      </Form>
    </div>
  );
}
