import { IncidentCategoryMapper } from "./IncidentCategory.Mapper";
import { IncidentCategory, IncidentCategorySubcategory} from "./IncidentCategory";
import { RepositoryFactory } from "../_Common/RepositoryFactory";


export class IncidentCategoryRepository {
  // mapper
  private static mapper = new IncidentCategoryMapper();
  
  // factory methods
  static make = RepositoryFactory.make<IncidentCategory, IncidentCategoryMapper>(this.mapper);
  static search = RepositoryFactory.search<IncidentCategory, IncidentCategoryMapper>(this.mapper);
  static create = RepositoryFactory.create<IncidentCategory, IncidentCategoryMapper>(this.mapper);
  static update = RepositoryFactory.update<IncidentCategory, IncidentCategoryMapper>(this.mapper);

  // additional
  static makeSubcategory(): IncidentCategorySubcategory {
    return {
      title: "",
    }
  }
  static async searchAll(): Promise<IncidentCategory[]> {
    return IncidentCategoryRepository.search({});
  }
}

