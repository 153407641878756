import React from "react";
import Code from "../Code";
import Notes from "../Notes";
import {AddRemove, AddRemoveItem, Card, Heading} from "../../../controls";

export default function ActionsDemo() {
  const items: AddRemoveItem[] = [
    {id: "1", label: "Apple", selected: false},
    {id: "2", label: "Pear", selected: true},
    {id: "3", label: "Pineapple", selected: false},
    {id: "4", label: "Penpineapplepen", selected: false},
    {id: "5", label: "Coconut", selected: false},
    {id: "6", label: "Strawberry", selected: true},
    {id: "8", label: "Mango", selected: false},
    {id: "9", label: "Caramel", selected: false},
    {id: "10", label: "Mint", selected: true},
    {id: "11", label: "Chocolate", selected: false},
  ]

  return (
    <Card>
      <Heading>AddRemove</Heading>
      <Notes>
        Displays two columns, giving the user the ability to moveelements from one column to the other
      </Notes>
      
      
      <h2>Without maxHeight</h2>
      <AddRemove items={items} />
      <h2>With maxHeight of 100px</h2>
      <Code>
        {`
        <AddRemove items={items} maxHeight={100} />
        `}
      </Code>
      <AddRemove items={items} maxHeight={100} />
    </Card>
  )
}
