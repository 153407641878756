import { Result } from "../Result";
import * as Api from "../../util/ApiTS";
import ApiOld from "../../util/api";
import { VendorInvoiceProvider, VendorInvoiceAllocatedAmount, VendorInvoicePayment } from "..";
import * as DA from "../DataAccess";
import moment from "moment";
import _ from "lodash";

export class VendorInvoice {
  public static ApiEndpoint = "vendorinvoices";

  // id
  _id: string|undefined;

  /// required
  // amount
  amount: number;
  // document in google drive
  driveId: string;
  driveTitle: string;
  // provider
  provider: VendorInvoiceProvider;
  // notes
  notes: string = "";
  
  /// optional
  // dates (note: there can be multiple payment dates, see Payments further down)
  invoiceDate: Date|null = null; // date invoice was issued
  dueDate: Date|null = null; // date the invoice is due 
  // reference number / payment purpose - usually only one of the two would be used
  referenceNumber: string = "";
  paymentPurpose: string = "";
  // payments (multiple because invoices might be paid in part)
  payments: VendorInvoicePayment[] = [];
  // allocated amounts (i.e. surprises and amounts)
  allocatedAmounts: VendorInvoiceAllocatedAmount[] = [];
  
  // constructor
  constructor(provider:VendorInvoiceProvider, amount:number, driveId:string, driveTitle:string) {
    this.amount = amount;
    this.provider = provider;
    this.driveId = driveId;
    this.driveTitle = driveTitle;
  }

  /**
   * Serializes the invoices
   */
  public toDb():any {
    return {
      _id: this._id,
      amount: this.amount,
      driveId: this.driveId,
      driveTitle: this.driveTitle,
      provider: this.provider.toDb(),
      notes: this.notes,
      invoiceDate: this.invoiceDate,
      dueDate: this.dueDate,
      referenceNumber: this.referenceNumber,
      paymentPurpose: this.paymentPurpose,
      payments: this.payments.map(payment => payment.toDb()),
      allocatedAmounts: this.allocatedAmounts.map(amount => amount.toDb())
    }
  }

  /**
   * returns true if invoice is currently lined up for being paid
   */
  get isOutgoing(): boolean {
    if(this.payments.length > 0) {
      if(this.payments.some(p => p.paymentDate === null)) {
        return true;
      }
    }
    return false;
  }

  /**
   * returns true if invoice is fully paid
   */
  get isPaid(): boolean {
    let amountPaid = this.payments.reduce((acc, curr) => { return acc + curr.amount}, 0);
    return amountPaid === this.amount;  // TODO what about those have have amountPaid > invoice.amount?
  }

  // creates item in db
  static async create(vendorInvoice: VendorInvoice): Promise<Result<VendorInvoice>> {
    vendorInvoice._id = undefined;
    const result = await Api.post(this.ApiEndpoint, "create", vendorInvoice.toDb());
    if(result.success) {
      return {
        success: true,
        data: VendorInvoice.fromDb(result.data.item)
      }
    }
    else {
      return Result.fromDb(result);
    }
  }
  
  // creates class instance from pojo
  public static fromDb(obj:any) : VendorInvoice {
    const provider: VendorInvoiceProvider = VendorInvoiceProvider.fromDb(obj.provider);
    const vi = new VendorInvoice(provider, obj.amount, obj.driveId, obj.driveTitle);
    const payments = obj.payments.map((obj_p:any) => VendorInvoicePayment.fromDb(obj_p));
    const allocatedAmounts = obj.allocatedAmounts.map((obj_aa:any) => VendorInvoiceAllocatedAmount.fromDb(obj_aa));
    vi._id = obj._id;
    vi.notes = obj.notes || "";
    vi.invoiceDate = obj.invoiceDate ? new Date(obj.invoiceDate) : null;
    vi.dueDate = obj.dueDate ? new Date(obj.dueDate) : null;
    vi.referenceNumber = obj.referenceNumber || "";
    vi.paymentPurpose = obj.paymentPurpose || "";
    vi.payments = payments;
    vi.allocatedAmounts = allocatedAmounts;

    return vi;
  }


  static async findUnpaid(): Promise<VendorInvoice[]> {
    const match = {__amountOutstanding:{"$ne":0}};
    const aggregation = this.getFindAggregation(match);
    const result = await Api.post(this.ApiEndpoint, "aggregate", {aggregation});
    const invoices = result.data.map((item:any) => VendorInvoice.fromDb(item));
    return invoices;
  }

  static async findUnpaidByDate(date: Date) {
    // unbezahlt oder Belegdatum vor Stichdatum, Zahlungsdatum nach dem Stichdatum
    const match = {"__invoiceDateSimple":{"$lt":moment(date).format("YYYYMMDD")}};
    const aggregation = this.getFindAggregation(match);
    let result = await Api.post(this.ApiEndpoint, "aggregate", {aggregation});
    // if I could do the  'Zahlungsdatum nach dem Stichdatum' bit via aggregation I would ... since I am too much of a blockhead I do a hack instead
    let data = result.data.filter((invoice:any) => {
      if((invoice.payments || []).length > 0) {
        return moment(invoice.payments[0].paymentDate).format("YYYYMMDD") > moment(date).format("YYYYMMDD")
      }
      return false;
    })
    const invoices = data.map((item:any) => VendorInvoice.fromDb(item));
    return invoices;
  }

  static async findPaid() {
    const match = {__amountOutstanding:0, "payments.paymentDate":{"$ne":null}};
    const aggregation = this.getFindAggregation(match);
    let result = await Api.post(this.ApiEndpoint, "aggregate", {aggregation});
    const invoices = result.data.map((item:any) => VendorInvoice.fromDb(item));
    return invoices;
  }

  static async findOutgoing() {
    const match = {"payments.paymentDate":null};
    const aggregation = this.getFindAggregation(match);
    let result = await Api.post(this.ApiEndpoint, "aggregate", {aggregation});
    const invoices = result.data.map((item:any) => VendorInvoice.fromDb(item));
    return invoices;
  }

  static async findByProviderId(providerId: string) {
    const filter = {"provider._id": providerId};
    const invoices = await VendorInvoice.search(filter);
    return invoices;
  }

  static async findOneById(id:string) : Promise<VendorInvoice|null> {
    const searchResult = await VendorInvoice.search({_id:id});
    return searchResult[0] || null;
  }

  static async getAllocatedAmountsBySurprise(surprise_id:string): Promise<any> {
    let filter = {"allocatedAmounts.surprise_id":surprise_id}
    let projection = {}
    let result = await ApiOld.post('vendorinvoices/search', {filter, projection})
    if(result.success) {
      return result.data
    }
    else {
      // TODO properly handle result errors
      console.error(result.error)
      return []
    }
  }

  static async search(filter:any): Promise<VendorInvoice[]> {
    const projection = {};
    const result = await Api.post(this.ApiEndpoint, "search", {filter, projection});
    if(result.success) {
      const eqs: VendorInvoice[] = result.data.items.map((obj:any) => {
        return VendorInvoice.fromDb(obj);
      })
      return eqs;
    }
    else {
      console.error(result.error);
      return [];
    }
  }
  
  static async update(id:string, changeset:any) : Promise<Result<VendorInvoice>> {
    const result = await Api.post(this.ApiEndpoint, "update", {id:id, set:changeset});
    if(result.success) {
      return {
        success: true,
        data: VendorInvoice.fromDb(result.data.item)
      }
    }
    else {
      return Result.fromDb(result);
    }
  }
  

  /**
   * Allocates an amount to a vendor invoice
   * @param {*} vendorInvoice_id 
   * @param {*} surprise_id 
   * @param {*} bookingText 
   * @param {*} amount 
   */
  static async allocateAmount(vendorInvoice_id:any, surprise_id:any, bookingText:any, amount:any): Promise<any> {
    let items = [{surprise_id, bookingText:bookingText, amount:amount}] // the route supports adding several items at once
    let result = await ApiOld.putSimple(`vendorinvoices/${vendorInvoice_id}/allocatedamounts/`, {items})
    if(result.success) {
      return result.data
    }
    else {
      // TODO properly handle result errors
      console.error(result.error)
      return undefined;
    }
  }

  static async deallocateAmount(vendorInvoice_id:any, allocation_id:any): Promise<any> {
    let result = await ApiOld.delete(`vendorinvoices/${vendorInvoice_id}/allocatedamounts/${allocation_id}`)
    if(result.success) {
      return result.data
    }
    else {
      // TODO properly handle result errors
      console.error(result.error)
      return undefined;
    }
  }
  

  /*
  static async create(vendorInvoice:VendorInvoice): Promise<Result<VendorInvoice>> {
    vendorInvoice._id = undefined;
    const result = await Api.post(this.ApiEndpoint, "create", vendorInvoice.toDb());
    if(result.success) {
      return {
        success: true,
        data: VendorInvoice.fromDb(result.data.item)
      }
    }
    else {
      return Result.fromDb(result);
    }
  }
  */

  /*
  static async remove(vendorInvoice:VendorInvoice): Promise<Result<VendorInvoice>> {
    const apiResult = await Api.post(this.ApiEndpoint, "delete", {id:vendorInvoice._id});
    if(apiResult.success) {
      return {
        success:true, data: vendorInvoice
      }
    }
    else {
      return Result.fromDb(apiResult);
    }
  }
  */

  static async removeById(id:string): Promise<boolean> {
    const apiResult = await Api.post(this.ApiEndpoint, "delete", {id:id});
    return apiResult.success;
  }

  private static getFindAggregation(match:any): any {
    return [
      // add amountPayed and amountOutstanding fields
      {
        "$addFields": {
          __amountPaid: {"$sum": "$payments.amount"},
          __amountOutstanding: {"$subtract": ["$amount", {"$sum":"$payments.amount"}]},
          __paymentsCount: {"$size":{"$ifNull":[ "$payments", [] ]}},
          __invoiceDateSimple: {"$dateToString":{format: "%Y%m%d", date: "$invoiceDate"}},
          //__paymentDate: {"$dateToString":{format: "%Y%m%d", date: "$payments.paymentDate.0"}},

        }
      },
      // filter by adding a match pass
      {
        "$match":match
      }  
    ];
  }
}
