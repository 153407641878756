import React, { useEffect, useState } from "react";
import { Button, Breadcrumb, Heading, Icon, Icons, List, Modal, Loader, Card } from "../../controls";
import Edit from "./PaymentTypes.Edit";
import * as DA from "../../../types/DataAccess";

const kinds: Record<DA.PaymentTypeKind, any> = {
  bank:{
    titles: {heading: "Bankkonten", create: "Neues Bankkonto", edit: "Bankkonto bearbeiten"},
    columns: [
      {key:'accountingId', header:"Fibu-Kontonummer", print: (v:any) => v.accountingId, sort: (a:any, b:any) => a.accountingId.localeCompare(b.accountingId)},
      {key:'name', header:"Bezeichnung", print: (v:any) => v.name, sort: (a:any, b:any) => a.name.localeCompare(b.name)},
      {key:'bank.name', header:"Name der Bank", print: (v:any) => v.bank.name, sort: (a:any, b:any) => a.bank.name.localeCompare(b.bank.name)},
      {key:'bank.bic', header:"BIC (SWIFT-Code)", print: (v:any) => v.bank.bic, sort: (a:any, b:any) => a.bank.bic.localeCompare(b.bank.bic)},
      {key:'bank.iban', header:"IBAN", print: (v:any) => v.bank.iban, sort: (a:any, b:any) => a.bank.iban.localeCompare(b.bank.iban)},
      {key:'bank.currency', header:"Währung", print: (v:any) => v.bank.currency, sort: (a:any, b:any) => a.bank.currency.localeCompare(b.bank.currency), isCurrency:true}
    ]
  },
  creditcard:{
    titles: {heading: "Kreditkarten", create: "Neue Kreditkarte", edit: "Kreditkarte bearbeiten"},
    columns: [
      {key:'accountingId', header:"Fibu-Kontonummer", print: (v:any) => v.accountingId, sort: (a:any, b:any) => a.accountingId.localeCompare(b.accountingId)},
      {key:'name', header:"Bezeichnung", print: (v:any) => v.name, sort: (a:any, b:any) => a.accountingId.localeCompare(b.accountingId)},
      {key:'creditcard.cardNumber', header:"Kartennummer", print: (v:any) => v.creditcard.cardNumber, sort: (a:any, b:any) => a.creditcard.cardNumber.localeCompare(b.creditcard.cardNumber)}
    ]
  },
  employee:{
    titles: {heading: "Mitarbeiter (Vorauszahlungen)", create: "Neuer Mitarbeiter für Vorauszahlung", edit: "Mitarbeiter für Vorauszahlung bearbeiten"},
    columns: [
      {key:'accountingId', header:"Fibu-Kontonummer", print: (v:any) => v.accountingId, sort: (a:any, b:any) => a.accountingId.localeCompare(b.accountingId)},
      {key:'name', header:"Bezeichnung", print: (v:any) => v.name, sort: (a:any, b:any) => a.name.localeCompare(b.name)},
      {key:'employee.fullName', header:"Name des Mitarbeiters", print: (v:any) => v.employee.fullName, sort: (a:any, b:any) => a.employee.fullName.localeCompare(b.employee.fullName)}
    ]
  }
};

type GroupedData = {
  bank: DA.PaymentType[], 
  creditcard: DA.PaymentType[], 
  employee: DA.PaymentType[]
}

export default function PaymentTypesList() {
  // state
  const [data, setData] = useState<GroupedData|null>(null);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modalParam, setModalParam] = useState<DA.PaymentType>();

  // mount
  useEffect(() => {
    load();
  }, []);

  // loads payment types
  const load = async() => {
    const all = await DA.PaymentTypeRepository.findAll()
    const data:GroupedData = {
      bank: all.filter(p => p.kind === "bank"),
      creditcard: all.filter(p => p.kind === "creditcard"),
      employee: all.filter(p => p.kind === "employee")
    }
    setData(data);
  }

  // user wants to edit
  const onClickEdit = (paymentType: DA.PaymentType) => {
    // get the title for the dialog
    const title = kinds[paymentType.kind].titles.edit;
    // set modal stuff
    setModalTitle(title);
    setModalParam(paymentType);
    setModalShow(true);
  }

  // user wants to create a new one
  const onClickCreate = (kind: DA.PaymentTypeKind) => {
    // get the title for the dialog
    const title = kinds[kind].titles.create;
    // create an empty payment type object
    const paymentType = DA.PaymentTypeRepository.make(kind);

    // set modal stuff
    setModalTitle(title);
    setModalParam(paymentType);
    setModalShow(true);
  }

  // user wants to delete
  const onClickDelete = async(paymentType:any) => {
    await DA.PaymentTypeRepository.remove(paymentType._id);
    load();
  }

  // closes modal dialog
  const closeDialog = () => {
    setModalShow(false);
  }

  // render modal content
  let modalContent = null;
  if(modalShow && modalParam) {
    modalContent = <Edit paymentType={modalParam} fields={kinds[modalParam.kind].columns} onUpdate={load} closeDialog={closeDialog} />
  }

  // render
  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}]} location="Zahlungsarten" />
    <Heading>Zahlungsarten</Heading>
    <Group kind="bank" kinds={kinds} data={data} onClickCreate={onClickCreate} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
    <Group kind="creditcard" kinds={kinds} data={data} onClickCreate={onClickCreate} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
    <Group kind="employee" kinds={kinds} data={data} onClickCreate={onClickCreate} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
  
    <Modal isOpen={modalShow} title={modalTitle} onClose={closeDialog}>
      {modalContent}
    </Modal>
  </>
}

type GroupProps = {
  kinds: any,
  kind: DA.PaymentTypeKind,
  data: GroupedData|null,
  onClickCreate: any,
  onClickEdit: any,
  onClickDelete: any
}
function Group({kinds, kind, data, onClickCreate, onClickEdit, onClickDelete} : GroupProps) {
  // pre-render
  let content;
  if(!data) {
    content = <Loader />
  }
  else {
    content = <>
      <List columns={kinds[kind].columns} items={data[kind]} getKey={item => item._id} onClickEdit={(item) => onClickEdit(item)} onClickDelete={(item) => onClickDelete(item)} hideSearch={true} hideFooter={true}/>
      <Button onClick={() => onClickCreate(kind)} size="small"><Icon icon={Icons.Plus} /></Button>
    </>
  }
  // render 
  return <Card title={kinds[kind].titles.heading}>
    {content}
  </Card>
}