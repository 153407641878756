import _ from "lodash";
import Api from "../../util/api"
import Api2 from "../../util/api2"

export class AdventureMail {
  static async sendSaleMail(adventureId:string): Promise<any> {
    try {
      await Api.putSimple(`adventures/mail/sale/${adventureId}`)
      return {success:true}
    }
    catch(error) {
      console.error(error);
      return {success:false, error};
    }
  }

  static async sendAcceptMail(adventureId:string): Promise<any> {
    try {
      await Api.putSimple(`adventures/mail/acceptreminder/${adventureId}`);
      return {success:true};
    }
    catch(error) {
      console.error(error);
      return {success:false, error};
    }
  }

  static async sendScheduledWithoutPaymentMail(adventureId:string): Promise<any> {
    try {
      await Api.putSimple(`adventures/mail/scheduledwithoutpayment/${adventureId}`);
      return {success:true};
    }
    catch(error) {
      console.error(error);
      return {success:false, error};
    }
  }

  static async sendPostponedMail(adventureId:string): Promise<any> {
    try {
      await Api.putSimple(`adventures/mail/postponed/${adventureId}`)
      return {success:true};
    }
    catch(error) {
      console.error(error);
      return {success:false, error};
    }
  }

  static async sendReviewTest(adventureId:string): Promise<any> {
    try {
      await Api.putSimple(`adventures/mail/review_test/${adventureId}`)
      return {success:true};
    }
    catch(error) {
      console.error(error);
      return {success:false, error};
    }
  }


}