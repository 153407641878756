import StepText from "./StepText";

/**
 * Used to group step text of the same meaning, i.e. variations of the same text.
 * Using the getText() method will round-robin the available texts
 */
class StepTextGroup {
  private _texts: Array<StepText> = [];
  private _index: number = 0; // used to round-robin the texts

  constructor(texts: Array<StepText>) {
    this._texts = texts;
  }

  getText(numberOfPeople: number): string {
    const stepText = this._texts[this._index];
    if(!stepText) {
      
    }
    // increase index to round-robin, making sure we do not go beyond max-index
    this._index += 1;
    if(this._index > (this._texts.length - 1)) {
      this._index = 0;
    }
    // get the text depending on the number of people
    let text = stepText.getText(numberOfPeople);
    if(!text) {
      
    }
    return text;
  }
}

export default StepTextGroup