import _ from "lodash";
import React, { useState } from 'react';
import * as DA from "../../../../types/DataAccess";

// components
import { Button, Icon, Icons, Link, CardSection, Modal, Table, Tr, Td } from "../../../controls";
import Edit from "./Activities.Edit";
// styling
import CSS from './Activities.module.scss'

type ActivitiesProps = {
  surprise:any,
  regions: DA.Region[]
}
function Activities({surprise, regions} : ActivitiesProps) {

  const [mode, setMode] = useState<"edit"|"view">("view");

  // user wants to edit
  const startEdit = () => setMode("edit");
  // stops edit mode
  const stopEdit = () => setMode("view");


  // gets name of region
  const getRegionName = (regionCode:string) => {
    const region = regions.find(r => r.code.toUpperCase() === (regionCode || "").toUpperCase());
    if(region) {
      return region.title.de
    }
    else {
      return "unbekannte Region"
    }
  } 


  // collection rows
  const rows: any[] = [];
  let total:number = 0;
  (surprise.Activities || [])
    .map((activity:any) => {
      // TODO all this mapping should be done inside the typescript model
      // older surprises do only have an array of activity ids
      if(_.isString(activity)) {
        return {
          __regionName: "unbekannte Region",
          ActivityId: activity,
          Title: activity,
          Configuration: {
            ParticipantCount: "??",
            Price: "??"
          }
        }
      }
      else {
        activity.__regionName = getRegionName(activity.RegionCode);
        return activity;
      }
    })
    .forEach((activity:any) => {
      // activity name and link
      rows.push(
        <Tr key={`${activity.ActivityId}_activity`}>
          <Td colSpan={4}>
            <Link to={`/activities/${activity.ActivityId}`} target="_blank">
              {activity.Title}
            </Link>
          </Td>
        </Tr>
      )
      // region
      rows.push(
        <Tr key={`${activity.ActivityId}_region`}>
          <Td><Icon icon={Icons.Map} /></Td>
          <Td colSpan={4}>{activity.__regionName}</Td>
        </Tr>
      )
      // participants
      rows.push(
        <Tr key={`${activity.ActivityId}_participants`}>
          <Td><Icon icon={Icons.UserFriends}/></Td>
          <Td colSpan={4}>{activity.Configuration.ParticipantCount} Teilnehmer</Td>
        </Tr>
      )
      // base price
      total += Number(activity.Configuration.Price);
      rows.push(
        <Tr key={`${activity.ActivityId}_baseprice`}>
          <Td><Icon icon={Icons.MoneyBill} /></Td>
          <Td className="number">{activity.Configuration.ParticipantCount} &times;</Td>
          <Td>{activity.Title}</Td>
          <Td className="number">{activity.Configuration.Price} CHF</Td>
        </Tr>
      );
      // options
      (activity.Options || []).forEach((option:any, index:number) => {
        total += Number(option.UnitPrice * option.Count);
        rows.push(
          <Tr key={`${activity.ActivityId}_option_${index}`}>
            <Td></Td>
            <Td className="number">{option.Count} &times;</Td>
            <Td>{option.Title} à {Number(option.UnitPrice).toFixed(2)}</Td>
            <Td className="number">{Number(option.UnitPrice * option.Count)} CHF</Td>
          </Tr>
        )
      });
      // optional activities (only those that were not added doing the activity fix)
      (activity.Subactivities || [])
        .filter((sa:any) => sa.IsOption)
        .filter((sa:any) => !(sa.IsOptionReplacement === true))
        .forEach((sa:any, index:number) => {
          total += Number(sa.Price);
          rows.push (
            <Tr key={`${activity.ActivityId}_optionalsubactivity_${index}`}>
              <Td></Td>
              <Td className="number">{sa.ParticipantCount} &times;</Td>
              <Td>{sa.Title}</Td>
              <Td className="number">{Number(sa.Price)} CHF</Td>
            </Tr>
          )
        });
      
    });
  // total
  rows.push(
    <Tr key={"total_divider"}>
      <Td colSpan={4}><hr /></Td>
    </Tr>
  )
  rows.push(
    <Tr key={"total"}>
      <Td colSpan={3} label>Total</Td>
      <Td align='right'>{total} CHF</Td>
    </Tr>
  )
  
  // render
  return <>
    <CardSection title="Aktivitäten" className={CSS.container}>
      <table><tbody>{rows}</tbody></table>
      <Button size="small" onClick={startEdit}>Aktivitäten bearbeiten</Button>
    </CardSection>
    <Modal isOpen={mode==="edit"} title="Aktivitäten bearbeiten" onClose={stopEdit}>
      <Edit stopEdit={stopEdit} />
    </Modal>
  </>
}

export default Activities