import React from "react";
//import {SurpriseContext} from "../../Surprise.Context";
import {Buddy, Donee, Doner, Text} from "../Items";
import { Table } from "../../../../controls";

export default function BuddySelected() {
  // render
  return (
    <Table fontSize="small">
      <Doner />
      <Donee />
      <Buddy />
      <Text label="Kommentar vom Beschenkten an Buddy" path="surprise.Order.Prepaid.BuddySelectionComment" placeholder="-" />
    </Table>
  );
}