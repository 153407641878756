import React, { useState, useEffect } from "react";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";
import { Dropdown, DropdownOption, Alert, Loader } from "../../controls";


type ProviderBankAccountProps = {
  provider: DA.Provider,
  accountId: string,
  disabled?: boolean,
  onChange: (account: DA.ProviderBankAccount) => void,
}
export default function ProviderBankAccount({provider, accountId, disabled=false, onChange}: ProviderBankAccountProps) {
  // state
  const [accounts] = useState<DA.ProviderBankAccount[]>(provider.bankAccounts);


  /*
  // render null if no info is required
  if(!isBankingInfoRequired) {
    return null;
  }
  */


  // render warning if no banking info is available
  if(accounts.length === 0) {
    return <Alert intent="warning">keine aktiven Bankverbindungen hinterlegt</Alert>;
  }

  // render
  const options:DropdownOption[] = accounts.map(account => {
    return { 
      value: account._id, 
      label: `${account.bankName} ${account.accountNumber || "kein Kontonummer"}`
    }
  });
  return (
    <div>
      <Dropdown 
        //value={entity.account}
        value={accountId}
        options={options}
        onChange={(accountId:string) => {
          const account = accounts.find(a => a._id === accountId);
          onChange(account!);
        }}
        disabled={disabled}
      />
    </div>
  )
}