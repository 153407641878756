import * as DA from "../DataAccess";
import Api from "../../util/api2";

export interface IncidentManagementAdventure {
  _id: string;
  activities: {title:string}[];
  rating: {
    comment: string,
    feedback: string,
    overall: number,
    planning: number,
    provider: number,
  },
  receiverName: string;
  reserverName: string;
}

export interface IncidentManagementProvider {
  id: string;
  name: string;
}

export class IncidentManagement {
  /**
   * Returns all incidents related to an adventure
   * @param adventureId id of adventure
   * @returns 
   */
  static async getIncidentsForAdventure(adventureId:string): Promise<DA.Incident[]> {
    const items = await DA.IncidentRepository.search({surpriseId: adventureId});
    return items;
  }

  /**
   * Returns adventures that need to be processed
   * @param maxRating 
   * @param numberOfItems 
   * @returns 
   */
  static async getAdventuresToProcess(maxRating:number, numberOfItems:number): Promise<IncidentManagementAdventure[]> {
    // TODO this should use repositories from DataAccess to get the data and the api should not really have that action (getSurpriseToProcess). we cannot do this at this point, since the api search action does not support 'limit'
    const payload = {maxRating, numberOfItems};
    const result = await Api.post("incidents", "getSurprisesToProcess", payload);
    return result.data.items.map((obj:any) => obj as IncidentManagementAdventure);
  }

  /**
   * Returns recently processed adventures
   * @param numberOfItems 
   * @returns 
   */
  static async getRecentlyProcessedAdventures(numberOfItems:number): Promise<IncidentManagementAdventure[]> {
    // TODO this should use repositories from DataAccess to get the data and the api should not really have that action (getRecentlyProcessedSurprises). we cannot do this at this point, since the api search action does not support 'limit'
    numberOfItems = numberOfItems || 5;
    const result = await Api.post("incidents", "getRecentlyProcessedSurprises", {numberOfItems});
    return result.data.items.map((obj:any) => obj as IncidentManagementAdventure);
  }

  /**
   * Marks a surprise as its rating having been processed. It will no longer appear when calling getSurprisesToProcess
   * @param {*} adventureId 
   */
  static async finalize(adventureId:string): Promise<void> {
    // TODO this should not use the Api but DataAccess to write data
    const changeset = {"Rating.ProcessedOn": new Date()};
    const payload = {id:adventureId, set:changeset};
    await Api.post("adventures", "update", payload);
  }


  /**
   * Returns all providers related to an adventure
   * @param adventureId 
   * @returns 
   */
  static async getProvidersForAdventure(adventureId:string) : Promise<IncidentManagementProvider[]> {
    // TODO this should not use the Api but DataAccess to read data
    // get booking requests that were accepted
    const filter = {adventure_id:adventureId, "response.accepted":true};
    const projection = {provider:1};
    const result = await Api.post("bookingrequests", "search", {filter, projection});
    const providers:IncidentManagementProvider[] = [];
    result.data.items.forEach((bookingRequest:any) => {
      if(!providers.some(p => p.id === bookingRequest.provider.id)) {
        providers.push(bookingRequest.provider);
      }
    });
    return providers;
  }
}

