import _ from "lodash";
import React, {useContext, useState, useEffect} from "react";
import * as TS from "../../../../types";
import {SurpriseContext} from "../Surprise.Context";
import {Button, Card, ConfirmButton, Icon, Icons} from '../../../controls';
import "./Conditions.View.scss";

type ConditionsProps = {
  expanded: boolean
}
export default function Conditions({expanded}: ConditionsProps) {
  // context
  const SC = useContext(SurpriseContext);
  // state
  const [conditions, setConditions] = useState<any[]>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [busy, setBusy] = useState(false);

  // conditions change
  useEffect(() => {
    setConditions(SC.surprise.Conditions);
    setHasChanges(false);
  }, [SC.surprise.Conditions])

  // saves changes
  const save = async() => {
    setBusy(true);
    const conditionsToSave = conditions.filter((condition:any) => condition.Text.trim().length > 0);
    await SC.updateAtPath("Conditions", conditionsToSave, true);
    // Note: thanks to the SurpriseContext and the useEffect above, this will automatically update our state
    setBusy(false);
  }

  // adds an item 
  const add = () => {
    const updatedConditions:any[] = conditions.map(c => c);
    updatedConditions.unshift({Text:"", UserAccepted:false, _tempId: _.uniqueId()})
    // update state
    setConditions(updatedConditions);
    setHasChanges(true);
  }

  // adds conditions from activities
  const addFromActivities = async() => {
    // get a copy of surprise's conditions
    const updatedConditions = conditions.map(c => c);
    // get from activities and filter those we already have and unnecessary items and then add them
    const conditionsFromActivities = await TS.AdventureOld.getConditionsFromActivities(SC.surprise);
    conditionsFromActivities
      .filter((condition:any) => {
        // get rid of those we already have
        // TODO this should be done in Logic ... however, we'd have to send it the ones we already have
        const found = updatedConditions.some(sc => {
          return condition.trim().toLowerCase() === sc.Text.trim().toLowerCase();
        });
        return !found;
      })
      .filter((condition:any) => {
        // remove unnecessary items
        // TODO this is a quick & dirty fix ... at some point in the future users need to be able to mark if conditions, weatherconditions, equipment need to be included in surprises
        return condition.trim().toLowerCase() !== "wetterunabhängig" && condition.trim().toLowerCase() !== "keine besonderen anforderungen";
      })
      .forEach((condition:any, index:number) => {
        updatedConditions.push({Text:condition, UserAccepted:false, _tempId:_.uniqueId()})
      })
    // update state
    setConditions(updatedConditions);
    setHasChanges(true);
  }

  // moves an item
  const move = (condition:any, direction:"up"|"down") => {
    const info = {
      from: conditions.indexOf(condition),
      to: 0,
      execute: false
    }

    if(direction === "up") {
      info.to = info.from - 1;
      info.execute = info.to >= 0;
    }
    else {
      info.to = info.from + 1;
      info.execute = info.to < conditions.length
    }

    if(info.execute) {
      const updatedConditions = conditions.map(c => c);
      updatedConditions.splice(info.to, 0, updatedConditions.splice(info.from, 1)[0]);
      setConditions(updatedConditions);
      setHasChanges(true);
    }
  }

  // removes an item
  const remove = (conditionToRemove:any) => {
    const updatedConditions = conditions.filter(condition => condition !== conditionToRemove);
    setConditions(updatedConditions);
    setHasChanges(true);
  }

  // render
  const conditionComponents = conditions.map((condition, index) => 
    <Condition isFirst={index === 0} isLast={index === conditions.length - 1} key={condition._id || condition._tempId} condition={condition} move={move} remove={remove} onChangeText={() => setHasChanges(true)} />
  );
  let summaryContent = `${conditions.length} ${conditions.length === 1 ? 'Bedingung' : 'Bedingungen'} definiert`
  return (
    <Card title="Bedingungen" icon={Icons.ChecklistIncomplete} collapsible={true} expanded={expanded} summaryContent={summaryContent}>
      <div>
        <Button size="small" onClick={add}>Bedingung hinzufügen</Button>
        <Button size="small" onClick={addFromActivities}>aus Aktivitäten übernehmen</Button>
      </div>
      <br />
      <div>
        {conditionComponents}
      </div>
      <div>
        <Button onClick={save} disabled={!hasChanges} busy={busy}>Speichern</Button>
      </div>
    </Card>
  );
}

type ConditionProps = {
  condition: any,
  isFirst: boolean,
  isLast: boolean,
  move: (condition:any, direction:"up"|"down") => void,
  remove: (condition:any) => void,
  onChangeText: any
}
function Condition({condition, isFirst, isLast, move, remove, onChangeText}: ConditionProps) {
  const [text, setText] = useState(condition.Text);

  // prepare items to render
  //const up = !isFirst ? <Icon onClick={() => move(condition, "up")} className="upDown" as="span" icon="arrow-up" /> : <Icon icon="arrow-up" as="span" className="upDownDisabled" />;
  //const down = !isLast ?  <Icon onClick={() => move(condition, "down")} className="upDown" as="span" icon="arrow-down" /> : <Icon icon="arrow-down" as="span" className="upDownDisabled" />;
  const up = <Button size="small" onClick={() => move(condition, "up")} disabled={isFirst}><Icon icon={Icons.ArrowUp} /></Button>
  const down = <Button size="small" onClick={() => move(condition, "down")} disabled={isLast}><Icon icon={Icons.ArrowDown} /></Button>
  const trash = <ConfirmButton onConfirm={() => remove(condition)}><Icon icon={Icons.Trash} /></ConfirmButton>;
  

  const change = (e:any) => {
    setText(e.target.value);
    condition.Text = e.target.value;
    onChangeText();
  }

  // render
  return (
    <div className="c-surprise-condition">
      <div className="actions">
        {up}{down}{trash}
      </div>
      <div className="text">
        <input type="text" value={text} onChange={change} />
      </div>
    </div>
  )
}