import React, {useState, useEffect, useContext} from "react";
import { COUNTRIES } from "../../../config/constants";
import * as DA from "../../../types/DataAccess";

// components & subviews
import { Breadcrumb, Card, Heading, Icon, Icons, Modal, List, ListColumn, Loader } from "../../controls";
import Edit from "./PackagingType.Edit";

// list columns
const columns: ListColumn[] = [
  {header:'Code', path:'code', print:v => v.code, sort: (a,b) => a.code.localeCompare(b.code)},
  {header:'Name', path:'title.de', print:v => v.title.de, sort: (a,b) => a.title.de.localeCompare(b.title.de)},
  {header:'Land', path:'country', 
    print:(item) => {
      const country = COUNTRIES.find(c => c.value === item.country);
      return country ? country.label : "???"
    },
    sort: (a,b) => {
      const country_a = COUNTRIES.find(c => c.value === a.country);
      const country_b = COUNTRIES.find(c => c.value === b.country);
      const label_a = country_a ? country_a.label : "???";
      const label_b = country_b ? country_b.label : "???";
      return label_a.localeCompare(label_b);
    }
  },
  {header:'Versandart', path:'deliveryMethod', 
    print:(item) => {
      const deliveryMethod = DA.PackagingTypeRepository.deliveryMethods.find(dm => dm.value === item.deliveryMethod);
      return deliveryMethod ? deliveryMethod.label : "???"
    },
    sort:(a,b) => {
      const dm_a = DA.PackagingTypeRepository.deliveryMethods.find(dm => dm.value === a.deliveryMethod);
      const label_a = dm_a ? dm_a.label : "???";
      const dm_b = DA.PackagingTypeRepository.deliveryMethods.find(dm => dm.value === b.deliveryMethod);
      const label_b = dm_b ? dm_b.label : "???";
      return label_a.localeCompare(label_b);
    }
  },
  {header: "Aktiv", path:'isActive', align:"center",
    print:(e) => {return e.isActive ? <Icon icon={Icons.Check} />:' '},
    sort:(a,b) => {
      const v_a = a.isActive ? 1 : 0;
      const v_b = b.isActive ? 1 : 0;
      return v_a > v_b ? 1 : -1;
    }
  },
  {
    header:"Position", path:"position", print: v => v.position, sort: (a, b) => a.position > b.position ? 1 : -1
  }
];

// the component
export default function PackagingTypeList() {
  // state
  const [packagingTypes, setPackagingTypes] = useState<DA.PackagingType[]|null>(null);
  const [packagingTypeToEdit, setPackagingTypeToEdit] = useState<DA.PackagingType|null>(null);

  // mount
  useEffect(() => {
    load()
  }, [])

  // loads data
  const load = async() => {
    setPackagingTypes(await DA.PackagingTypeRepository.findAll());
  }

  // user wants to edit
  const onClickEdit = (packagingType: DA.PackagingType) => {
    setPackagingTypeToEdit(packagingType);
  }

  // user wants to create a new one
  const onClickCreate = () => {
    setPackagingTypeToEdit(DA.PackagingTypeRepository.make());
  }

  // closes modal
  const closeDialog = () => setPackagingTypeToEdit(null);

  // render
  let content = null;
  if(packagingTypes === null) {
    content = <Loader />
  }
  else {
    content = <List columns={columns} items={packagingTypes} onRowClick={(item) => onClickEdit(item)} getKey={item => item._id} onClickCreate={onClickCreate} />
  }
  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}]} location="Verpackungen" />
    <Heading>Verpackungen</Heading>
    <Card>
      {content}
    </Card>
    <Modal isOpen={packagingTypeToEdit !== null} onClose={closeDialog} title="Verpackungsart">
      <Edit packagingType={packagingTypeToEdit!} onUpdate={load} closeDialog={closeDialog} />
    </Modal>
  </>
}