/**
 * Stolen from https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 * @param {*} pathOrLabel 
 */

let _idCounter = 0;

function getId(pathOrLabel?:string) {
  _idCounter +=1
  const hashSource = `${pathOrLabel || Date.now()}_${_idCounter}`
  let hash = 0, i, chr
  //if ((pathOrLabel || Date.now().toString()).length === 0) return hash
  for (i = 0; i < hashSource.length; i++) {
    chr   = hashSource.charCodeAt(i)
    hash  = ((hash << 5) - hash) + chr
    hash |= 0 // Convert to 32bit integer
  }
  return String(hash)
}

function getZIndex(el:any): any {
  let style = window.getComputedStyle(el);
  let zIndex = style.zIndex;
  if(zIndex === 'auto' && el.parentElement){
    return getZIndex(el.parentElement)
  }
  else {
    return zIndex;
  }
}

export default {
  getId,
  getZIndex
}