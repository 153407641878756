import React, { useContext, useState } from "react";
import * as TS from "../../../types";
import { ActivityContext } from "./ActivityContext";
import { Form, TextInput, Validators, CardSection, Icon, Icons, ConfirmButton, Alert, Loader, Modal } from "../../controls";

import CSS from "./Activity.Videos.module.scss";

export default function Videos() {
  // state
  const [videoToEdit, setVideoToEdit] = useState<TS.ActivityVideo|null>(null);
  // context
  const AC = useContext(ActivityContext)!;

  // updates / creates video entry
  const updateVideo = async(video:TS.ActivityVideo) => {
    await AC.upsertVideo(video)
    stopEdit();
  }

  // user wants to delete video
  const deleteVideo = async(video:TS.ActivityVideo) => {
    await AC.deleteVideo(video);
  }

  // starts create/edit mode
  const startEdit = (video?:TS.ActivityVideo) => {
    setVideoToEdit(video || new TS.ActivityVideo());
  }

  // stops edit mode
  const stopEdit = () => setVideoToEdit(null);

  // render loading
  if(AC.activity === null) {
    return <Loader text="lade Aktivität ..." />
  }

  // pre-render
  const videos = AC.activity.videos.map(video => {
    const src = getEmbedUrl(video.url);
    return (
      <div key={video._id} className={CSS.activity_video}>
        <div className={CSS.activity_video_inner}>
          <iframe width="280" height="158" src={src} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title={video._id}></iframe>
          <div className={CSS.activity_video_actions}>
            <ConfirmButton onConfirm={() => deleteVideo(video)}><Icon icon={Icons.Trash} /></ConfirmButton>
          </div>
        </div>
      </div>
    )
  })

  // render
  return <>
    <CardSection title="Videos" className={CSS.container}>
      <div className={CSS.list}>
        {videos}
        <div className={CSS.activity_video_add} onClick={() => startEdit()}><div className={CSS.icon}><Icon icon={Icons.Plus} /></div></div>
      </div>
      <Alert intent="info" title="Videoquellen und Format des Links" size="small">
        <ul>
          <li>nur YouTube Videos verlinken</li>
          <li>die von YouTube zur Verfügung gestellte Url verwenden - d.h. beim YouTube Video auf 'teilen' klicken und die Url kopieren, die angezeigt wird (sollte etwa so aussehen: https://youtu.be/xxxxx)</li>
        </ul>
      </Alert>
    </CardSection>
    <Modal
      isOpen={videoToEdit !== null}
      title="Video"
      onClose={stopEdit}
    >
      <Edit 
        video={videoToEdit!} 
        updateVideo={updateVideo} 
        onCancel={stopEdit} 
      />
    </Modal>
  </>
}

function getEmbedUrl(url:string) : string {
  const parts = url.split("/");
  const videoId = parts[parts.length - 1];
  return `https://www.youtube.com/embed/${videoId}`;
}


type EditProps = {
  video: TS.ActivityVideo,
  updateVideo: (video: TS.ActivityVideo) => void,
  onCancel: () => void
}
function Edit({video, updateVideo, onCancel} : EditProps) {
  // state
  const [entity] = useState({
    url: video.url || ""
  });
  
  // form save
  const save = (formResult:any) => {
    const updatedEntity = formResult.merge(entity);
    video.url = updatedEntity.url;
    updateVideo(video);
  }

  // render
  return (
    <div>
      <Form entity={entity} onSave={save} onCancel={onCancel} labelSave="speichern" labelCancel="abbrechen">
        <TextInput label="Link" path="url" validate={Validators.isYouTubeUrl()} />
      </Form>
    </div>
  )
}
