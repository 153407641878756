import React from "react";
import { Validation } from "./Validation";

type FieldValidationProps = {
  validation: Validation
}
function FieldValidation({validation}: FieldValidationProps) {
  if(validation.valid) {
    return null
  }

  return (
    <div className="validation">
      {validation.message}
    </div>
  )
}

// TODO do we ever ever ever use this?
function isRequired(message: string): (v:any) => Validation {
  return (v: string) => {
    const valid = v !== null && v !== undefined && String(v).trim().length > 0;
    return new Validation(valid, message || "Bitte einen Wert eingeben");
  }
}

export { isRequired }

export default FieldValidation