import React, { useContext, useState, useEffect } from "react";
import { Form, Button, ConfirmButton, Card, TextInput, Validators, Dropdown, Modal, TextArea, Icon, Icons, Checkbox, Row, Table, Tr, Td } from "../../controls";
import { PageContext } from "./Page.Context";

// TODO not dry ... also used in Page.Properties.tsx
const DIRECTORY_OPTIONS = [
  {value:"/lp", label:"Landing Page (/lp)"},
  //{value:"/p/magazin", label:"Magazin (/magazin)"},
  //{value:"/p/firmenkunden", label:"Firmenkunden"}
]

export default function PageProperties() {
  const [mode, setMode] = useState<"edit"|"view">("view");

  // context
  const PC = useContext(PageContext);

  // user wants to edit
  const startEdit = () => setMode("edit");
  // stops edit mode
  const stopEdit = () => setMode("view");

  // user wants to duplicate the page
  const onClickDuplicate = async() => {
    await PC.duplicate();
  }

  // pre-render
  if(!PC.page) {
    return null;
  }
  // render
  return <>
    <Card title="Eigenschaften" collapsible={true} expanded={true}>
      <Table>
        <Tr>
          <Td secondaryLabel>Publiziert</Td>
          <Td>{PC.page.isPublished ? <Icon icon={Icons.Check} /> : <Icon icon={Icons.Cancel} />}</Td>
        </Tr>
        <Tr>
          <Td secondaryLabel>Titel</Td>
          <Td>{PC.page.title}</Td>
        </Tr>
        <Tr>
          <Td secondaryLabel>Pfad</Td>
          <Td>{PC.page.path}</Td>
        </Tr>
        <Tr>
          <Td secondaryLabel>Länder</Td>
          <Td>{(PC.page.countries || []).join(", ")}</Td>
        </Tr>
        <Tr>
          <Td secondaryLabel>Header verbergen</Td>
          <Td>{PC.page.hideHeader ? <Icon icon={Icons.Check} /> : <Icon icon={Icons.Cancel} />}</Td>
        </Tr>
        <Tr>
          <Td secondaryLabel>Footer verbergen</Td>
          <Td>{PC.page.hideFooter ? <Icon icon={Icons.Check} /> : <Icon icon={Icons.Cancel} />}</Td>
        </Tr>
        <Tr>
          <Td secondaryLabel>Tags</Td>
          <Td>{(PC.page.tags || []).join(', ')}</Td>
        </Tr>
        <Tr>
          <Td secondaryLabel>Beschreibung</Td>
          <Td>{PC.page.getMetaTagContent("description")}</Td>
        </Tr>
      </Table>

      <Row align="left">
        <ConfirmButton onConfirm={onClickDuplicate}><Icon icon={["far", "clone"]} /> duplizieren</ConfirmButton>
        <Button size="small" onClick={startEdit}><Icon icon={["fas", "pencil-alt"]} /> bearbeiten</Button>
      </Row>
      {/*
      
      <Alert intent="hint" size="small">
        <strong>Titel</strong> - der Titel der im Browser-Fenster und ihn Suchresultaten angezeigt wird<br/>
        <strong>Url-Erweiterung</strong> - letzer Teil der Url - z.B. 'neue-angebote' in der Url 'www.appentura/p/neue-angebote'<br/>
        <strong>Beschreibung</strong> - wird in Suchmaschinen als Zusammenfassung angezeigt
      </Alert>
      */}
    </Card>
    <Modal isOpen={mode==="edit"} title="Eigenschaften bearbeiten" onClose={stopEdit}>
      <Edit stopEdit={stopEdit} />
    </Modal>
  </>
}

type EditProps = {
  stopEdit: () => void
}
function Edit({stopEdit} : EditProps) {
  // context
  const PC = useContext(PageContext);

  // state
  const [busy, setBusy] = useState(false);
  const [entity, setEntity] = useState<any>(null);
  const [directoryOptions] = useState(DIRECTORY_OPTIONS);
  //const [error, setError] = useState<any>(null);

  // mount
  useEffect(() => {
    const countries = PC.page!.countries || [];
    // TODO this is a hack and won't work once we have more directories
    const directory = "/lp";
    const slug = PC.page!.path.split("/").slice(2).join("/");
    setEntity({
      title:PC.page!.title,
      path:PC.page!.path,
      directory,
      slug,
      description: PC.page!.getMetaTagContent("description"),
      isPublished: PC.page!.isPublished,
      hideHeader: PC.page!.hideHeader,
      hideFooter: PC.page!.hideFooter,
      tags:(PC.page!.tags || []).join(", "),
      country_ch: countries.includes("CH"),
      country_de: countries.includes("DE")
    })
  }, []);

  // user wants to abort
  const onCancel = () => {
    stopEdit();
  }

  // user wants to save
  const onSave = async(formResult:any) => {
    // attempt to save
    setBusy(true);

    const changeset = formResult.changeset;
    const merged = formResult.merge(entity);

    // description meta tag needs special treatment
    if(changeset.description) {
      const metaTags = (PC.page!.metaTags || []).filter((mt:any) => mt.name !== "description");
      metaTags.push({name:"description", content:changeset.description});
      changeset.metaTags = metaTags;
      delete changeset.description;
    }

    // tags need special treament
    if(changeset.tags) {
      const tagArray = changeset.tags.split(",").map((s:string) => s.trim().toLowerCase()).filter((s:string) => s !== "");
      changeset.tags = tagArray;
    }

    // the path needs special treatment
    changeset.path = `${merged.directory}/${merged.slug}`; 
    delete changeset.directory;
    delete changeset.slug;

    // update the countries
    if(changeset.country_ch !== undefined || changeset.country_de !== undefined) {
      changeset.countries = [];
      if(merged.country_ch) {
        changeset.countries.push("CH")
      }
      if(merged.country_de) {
        changeset.countries.push("DE");
      }
      delete changeset.country_ch;
      delete changeset.country_de;
    }
    
    // update
    await PC.update(changeset);

    // close dialog
    stopEdit();
  }

  
  // render
  return <>
    <Form busy={busy} entity={entity} 
      onCancel={onCancel} onSave={onSave}
    >
      <Checkbox label="Publiziert" path="isPublished" disabled={busy} explanation="Ob die Seite sichtbar ist." />
      <Checkbox label="Schweiz" path="country_ch" disabled={busy} explanation="sichtbar auf appentura.ch" />
      <Checkbox label="Deutschland" path="country_de" disabled={busy} explanation="sichtbar auf appentura.de" />
      <Checkbox label="Header verbergen" path="hideHeader" disabled={busy} explanation="wenn aktive, wird der Header nicht angezeigt" />
      <Checkbox label="Footer verbergen" path="hideFooter" disabled={busy} explanation="wenn aktive, wird der Footer nicht angezeigt" />
      <TextInput label="Title" path="title" disabled={busy} validate={Validators.isRequired("Überschrift eingeben")} explanation="Überschrift der Seite" />
      <Dropdown label="Pfad (Basis-URL)" path="directory" options={directoryOptions} />
      <TextInput label="Url Erweiterung" path="slug" disabled={busy} validate={Validators.isSlug({allowEmpty:false})} placeholder="Url-Erweiterung eingeben" explanation="URL-Erweiterung, z.B. neue-angebote. Darf nur Buchstaben, Zahlen und Bindestrich enthalten" />  
      <TextInput label="Tags" path="tags" disabled={busy} />
      <TextArea disabled={busy} path="description" label="Beschreibung" explanation="Wird in Suchmaschinen als Zusammenfassung angezeigt. Auf dem Desktop werden ca. 150 Zeichen angezeigt, auf Mobile ca. 100" rows={3} showCharacterCount={true} />
      {/*errorAlert*/}
    </Form>
  </>
}