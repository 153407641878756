import React, {useContext, useState, useEffect, useRef} from 'react';
// context
import {StepsContext} from "./Steps.Context";
// subviews
import Read from "./Step.Read";
import Edit from "./Step.Edit";
import Move from "./Step.Move";
import AddStep from "./Step.Add";
// styling
import './Step.Control.scss'

// the component
export default function Step({step, index, isFirst, isLast, movable, insertSteps}) {
  // context
  const STC = useContext(StepsContext);
  // state
  const [isActive, setIsActive] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  // refs
  const refContainer = useRef();

  // mount
  useEffect(() => {
    setIsMounted(true);
  }, []);
  
  // side effect: active step changes
  useEffect(() => {
    setIsActive(step === STC.currentStep);
  }, [STC.currentStep]);
  
  // side effect: active state changes
  useEffect(() => {
    if(isMounted && refContainer.current) {
      window.scrollTo({top:refContainer.current.offsetTop - 20, behavior:"smooth"});
    }
  }, [isActive]);

  // render
  if(movable) {
    return <Move step={step} index={index} isFirst={isFirst} isLast={isLast} />
  }
  else {
    return <>
      <div className="c-surprise-step" ref={refContainer}>
        <div className="actions">
          <span className="index">{index + 1}</span>
        </div>
        {isActive ? <Edit step={step} /> : <Read step={step} movable={false} />}
      </div>
      <AddStep index={index + 1} insertSteps={insertSteps} />
    </>
  }
}
