import React, {useContext, useState} from "react";
import * as TS from "../../../../../types";
import * as BL from "../../../../../types/BusinessLogic";
import {CardSection, ConfirmButton} from "../../../../controls";
import {SurpriseContext} from "../../Surprise.Context";

export default function Archive() {
  // context
  const SC = useContext(SurpriseContext);
  // state
  const [busy, setBusy] = useState(false);

  // event handlers
  const archive = async() => {
    setBusy(true);
    const currentUser = await BL.User.getCurrent();
    const logEntry = { Type:"archived", Text:`archiviert durch ${currentUser.email}`, Operator:"robot"};
    const nextStatus = TS.AdventureStatus.Archived;
    await SC.updateStatus(nextStatus, false, logEntry);
    setBusy(false);
  }
  const dearchive = async() => {
    setBusy(true);
    const currentUser = await BL.User.getCurrent();
    const logEntry = { Type:"dearchived", Text:`de-archiviert durch ${currentUser.email}`, Operator:"robot"};
    const nextStatus = SC.surprise.OldStatus || TS.AdventureStatus.Ordered;
    await SC.updateStatus(nextStatus, false, logEntry);
    setBusy(false);
  }
  
  // render
  if(SC.surprise.Status === TS.AdventureStatus.Archived) {
    let status = SC.surprise.OldStatus || TS.AdventureStatus.Ordered;
    let label = `de-archivieren (zurück zu Status '${TS.Adventure.getStatusName(status)}')`
    return (
      <CardSection title="De-Archivieren">
        <ConfirmButton labelConfirm="sicher?" onConfirm={dearchive} disabled={busy}>{label}</ConfirmButton>
      </CardSection>
    )
  }
  else {
    return (
      <CardSection title="Archivieren">
        <ConfirmButton onConfirm={archive} busy={SC.busy}>archivieren</ConfirmButton>
      </CardSection>
    );
  }
}
