import React, { useContext, useEffect, useState } from "react";
// types
import * as Cms from "../../../../types/Cms";
// contexts and components
import { PageContext } from "../Page.Context";
import { Alert, Dropdown, TextInput, TextArea, Form, Paragraphs } from "../../../controls";
import * as Selectors from "../Selectors";
import * as Preview from "../Preview";

const layoutOptions:any = [
  {value:"stacked", label:"Gestapelt"},
  {value:"beside", label:"Nebeneinander"}
];

const orientationOptions:any = [
  {value:"landscape", label:"Breit"},
  {value:"portrait", label:"Hoch"}
];


type VideoProps = {
  instance?: Cms.VideoModule,
  closeDialog: () => void
}
export function Video({instance, closeDialog} : VideoProps) {
  // context
  const PC = useContext(PageContext);
  
  // state
  const [entity, setEntity] = useState<Cms.VideoModule|null>(null);
  const [busy, setBusy] = useState<boolean>(false);

  // mount
  useEffect(() => {
    let entity: Cms.VideoModule;
    if(instance) {
      entity = instance;
    }
    else {
      entity = new Cms.VideoModule();
    }
    setEntity(entity)
  }, [])

  // user wants to cancel
  const onCancel = () => {
    closeDialog();
  }

  // user wants to save
  const onSave = async(formResult:any) => {
    setBusy(true);
    const updatedEntity = formResult.merge(entity);
    
    if(formResult.changeset.countries) {
      // TODO we need to do this because formResult.merge does not seem to handle arrays correctly
      updatedEntity.countries = formResult.changeset.countries;
    }

    await PC.upsertModule(updatedEntity);
    // close dialog
    closeDialog();
  }

  
  // render
  return (
    <Form entity={entity} onCancel={onCancel} onSave={onSave} busy={false}>
      <Selectors.BaseProperties busy={busy} />
      <TextInput label="Titel" path="elements.title" placeholder="Titel eingeben" disabled={busy} />
      <TextInput label="Untertitel" path="elements.subtitle" placeholder="Untertitel eingeben" disabled={busy} />
      <TextArea label="Text" path="elements.text" placeholder="Text eingeben" disabled={busy} />
      <TextInput label="YouTube Video Id" path="elements.youtubeId" placeholder="" disabled={busy} />
      <TextInput label="Vimeo Video Id" path="elements.vimeoId" placeholder="" disabled={busy} />
      <TextInput label="Button Text" path="elements.buttonText" placeholder="Text auf Button" disabled={busy} />
      <TextInput label="Button Url" path="elements.buttonHref" placeholder="Href auf Button" disabled={busy} />
      <Dropdown label="Layout" path="elements.layout" options={layoutOptions} />
      <Alert intent="info" size="small">
        Entweder YouTube oder Vimeo Video-Id eingeben - sind beide vorhanden, wird die YouTube Video Id verwendet
      </Alert>
    </Form>
  )
}


type VideoPreviewProps = {
  instance: Cms.VideoModule,
  expanded?: boolean,
  onClickAction: (module:Cms.Module, action:"edit"|"delete"|"duplicate") => void
}
export function VideoPreview({instance, expanded, onClickAction} : VideoPreviewProps) {
  return (
    <Preview.Container expanded={expanded} module={instance} title={Cms.VideoModule.info.title} onClickAction={onClickAction}>
      <Preview.Table>
        <Preview.TableRow label="Titel">{instance.elements.title || "-"}</Preview.TableRow>
        <Preview.TableRow label="Untertitel">{instance.elements.subtitle || "-"}</Preview.TableRow>
        <Preview.TableRow label="Text">
          <Paragraphs text={instance.elements.text || "-"} />
        </Preview.TableRow>
        <Preview.TableRow label="Layout">
          {instance.elements.layout}
        </Preview.TableRow>
        <Preview.TableRow label="Video">
          <Preview.Video youtubeId={instance.elements.youtubeId} vimeoId={instance.elements.vimeoId} />
        </Preview.TableRow>
        <Preview.TableRow label="Button">
          <Preview.Button text={instance.elements.buttonText} href={instance.elements.buttonHref} />
        </Preview.TableRow>
      </Preview.Table>
    </Preview.Container>
  )
}
