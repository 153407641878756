import React, { useContext, useEffect, useState } from "react";
import * as DA from "../../../../types/DataAccess";
import { Button, ClipboardButton, Loader, Row, Switchboard } from "../../../controls";
import { BookingRequestContext } from "./BookingRequest.Context";
import ServiceItems from "./BookingRequest.ServiceItems";


type FormWebProps = {
  adventureInfo:any, activityInfo:any, 
  currentUser: DA.User,
  provider: DA.Provider,
  reloadBookingRequests: Function,
  closeDialog: () => void
}
export default function FormWeb({adventureInfo, activityInfo, currentUser, provider, reloadBookingRequests, closeDialog}: FormWebProps) {
  // context
  //const AC = useContext(AssistantContext);
  const BRC = useContext(BookingRequestContext);
  

  // mount
  useEffect(() => {
    const load = async() => {
      await BRC.load(adventureInfo, activityInfo, provider); 
      BRC.changePaymentType("cc");
    }
    load();
  }, [provider]);

  // save
  const save = async() => {
    await BRC.save("web", null);
    reloadBookingRequests();
    closeDialog();
  }

  // render loading
  if(!BRC.isLoaded) {
    return <Loader />;
  }

  // render
  const surpriseId4 = adventureInfo.id4;
  let t1 = "Wir sind ein Startup aus Bern und bieten Überraschungs-Erlebnisse. Der Kunde wurde beschenkt und wird per Smartphone durch seine Überraschung geführt. Ihr Ticket für diese Buchung wird er per Smartphone vorweisen.";
  let tExtended = `Die Rechnung für diese Buchung schicken Sie bitte an uns (Referenz-Nummer ${surpriseId4}). Zusätzliche Leistungen / Konsumationen kassieren Sie bitte direkt vor Ort ein. Vielen Dank für einen kurze Bestätigung.`;
  let t3 = "Beste Grüsse";
  let t4 = `${currentUser.firstName} ${currentUser.lastName}, ${currentUser.email}`;
  let texts = BRC.paymentType === "invoice" ? [t1, tExtended, t3, t4] : [t1, t3, t4];
  let clipboard = texts.join('\n');
  let paragraphs = texts.map((text, index) => <p key={index}>{text}</p>);
  return (
    <>
      <div className="booking-form">
        <ServiceItems />
        <div className="webCopyPaste">
          <div className="title">
            Hinweistext für die Online-Buchung
          </div>
          <Switchboard 
            selectedValues={[BRC.paymentType]}
            onChange={values => BRC.changePaymentType(values[0])} 
            minItems={1} maxItems={1}
            items={[
              {value:"invoice", label:"Rechnung"},
              {value:"cc", label:"Kreditkarte"},
            ]}
          />
          <div className="clipboardtext">
            {paragraphs}
          </div>
          <ClipboardButton text={clipboard} size="small">{null}</ClipboardButton>
          <br />
        </div>
      </div>
      <Row align="center">
        <Button onClick={closeDialog} intent="cancel">abbrechen</Button>
        <Button onClick={save}>speichern</Button>
      </Row>
    </>
  )
}