import React from "react";

import CSS from "./Label.module.scss";

type LabelProps = {
  label?: string,
  explanation?: string,
  children?:React.ReactNode|React.ReactNode[]
}

export function Label({label, explanation, children} : LabelProps) {
  // pre-render
  const divLabel = label ? <div className={CSS.label}>{label}</div> : null;
  
  const divChildren = children ? <div className={CSS.children}>{children}</div> : null;
  const divExplanation = explanation ? <div className={CSS.explanation}>{explanation}</div> : null;

  // render
  return (
    <div className={CSS.container}>
      <label>
        {divLabel}
        {divChildren}
        {divExplanation}
      </label>
    </div>
  )
}