export class Result<T> {
  success: boolean = true;
  data?: T;
  error?: {
    message: string,
    code?: string
  };

  public static fromDb<T>(obj:any) : Result<T> {
    const result = new Result<T>();
    result.success = obj.success || false;
    result.data = obj.data as T || undefined;
    result.error = obj.error || undefined;
    if(!result.success) {
      if(!result.error) {
        result.error = { 
          message: "unknown error"
        }
      }
      if(!result.error.message) {
        result.error.message = "unknown error"
      }
    }
    return result;
  }
}

export class ResultOld {
  success: boolean;
  error: Error | null;
  data: any | null;

  constructor(success: boolean, error: Error | null, data: any | null) {
    this.success = success;
    this.error = error;
    this.data = data;
  }

  static fromApiResult(apiResult:any):ResultOld {
    if(apiResult.success) {
      let data = apiResult.data || null;
      return new ResultOld(true, null, data);
    }
    else {
      let error = new Error(apiResult.error.message);
      return new ResultOld(false, error, null);
    }
  }
}