import _ from "lodash";
import React, {useEffect, useState} from "react";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";

// context
type ActivityContextType = {
  loaded: boolean,
  activity: TS.Activity|null,
  activity2: DA.Activity|null,
  regionVariant: TS.ActivityRegionVariant|null,
  instructions: any,
  regions: DA.Region[],
  
  load: (activityId: string) => void,
  update: (changset:any) => void,
  upsertStory: (story:TS.ActivityStory) => void,
  deleteStory: (story:TS.ActivityStory) => void,
  addSubactivity: (subactivityId: string) => void,
  removeSubactivity: (subactivityId: string) => void,
  addImage: (imageUrl:string) => void,
  updateImage: (image: TS.ActivityImage) => void,
  deleteImage: (image: TS.ActivityImage) => void,
  updateRegionVariant: (regionVariantId:string, changeset:any) => void,
  upsertVideo: (video: TS.ActivityVideo) => void,
  deleteVideo: (video: TS.ActivityVideo) => void,
  setRegionVariant: (rv: TS.ActivityRegionVariant) => void,
  addRegionVariant: (regionCode: string, loadRegionVariant: boolean) => void,
  copyRegionVariant: (sourceRegionCode: string, targetRegionCode: string) => void
}
const ActivityContext = React.createContext<ActivityContextType|undefined>(undefined);

// provider
type ActivityProviderProps = {
  children: React.ReactNode|React.ReactNode[]
}
function ActivityProvider(props:ActivityProviderProps) {
  // state
  const [loaded, setLoaded] = useState<boolean>(false);
  const [activity, setActivity] = useState<TS.Activity|null>(null);
  const [activity2, setActivity2] = useState<DA.Activity|null>(null);
  const [regionVariant, setRegionVariant] = useState<TS.ActivityRegionVariant|null>(null);
  const [instructions] = useState<any|null>(null); // TODO is this ever used?
  const [regions, setRegions] = useState<DA.Region[]>([]);
  
  // mount
  useEffect(() => {}, [])
  
  // provider value
  const value = {
    // props
    loaded,
    activity,
    activity2,
    regionVariant,
    instructions,
    regions,

    // functions
    load: async(activityId: string) => {
      const regions = await DA.RegionRepository.findAll();
      const activity = await TS.Activity.findOneById(activityId);
      const activity2 = await DA.ActivityRepository.findById(activityId);
      setRegions(regions);
      setActivity(activity);
      setActivity2(activity2);
      setLoaded(true);
      // TODO what if activity with that id cannot be found?
    },
    update: async(changeset: any) => {
      const updatedActivity = await TS.Activity.update(activity!._id, changeset);
      const result = await DA.ActivityRepository.update(activity!._id, changeset);
      setActivity(updatedActivity);
      console.log(updatedActivity);
      setActivity2(result.data);
    },
    // images
    addImage: async(imageUrl:string) => {
      const updatedActivity = await TS.Activity.addImage(activity!._id, imageUrl);
      setActivity(updatedActivity);
    },
    updateImage: async(image: TS.ActivityImage) => {
      const updatedActivity = await TS.Activity.updateImage(activity!._id, image);
      setActivity(updatedActivity);
    },
    deleteImage: async(image: TS.ActivityImage) => {
      const updatedActivity = await TS.Activity.deleteImage(activity!._id, image);
      setActivity(updatedActivity);
    },

    // videos
    upsertVideo: async(video: TS.ActivityVideo) => {
      await TS.Activity.upsertVideo(activity!._id, video);
      const updatedActivity = await TS.Activity.findOneById(activity!._id);
      setActivity(updatedActivity);
    },
    deleteVideo: async(video: TS.ActivityVideo) => {
      await TS.Activity.deleteVideo(activity!._id, video);
      const updatedActivity = await TS.Activity.findOneById(activity!._id);
      setActivity(updatedActivity);
    },

    // stories
    upsertStory: async(story:TS.ActivityStory) => {
      const updatedActivity = await TS.Activity.upsertStory(activity!._id, story);
      setActivity(updatedActivity);
    },
    deleteStory: async(story:TS.ActivityStory) => {
      const updatedActivity = await TS.Activity.deleteStory(activity!._id, story);
      setActivity(updatedActivity);
    },

    // subactivities
    addSubactivity: async(subactivityId:string) => {
      await TS.Activity.addSubactivity(activity!._id, subactivityId);
      const updatedActivity = await TS.Activity.findOneById(activity!._id);
      setActivity(updatedActivity);
    },
    removeSubactivity: async(subactivityId:string) => {
      await TS.Activity.removeSubactivity(activity!._id, subactivityId);
      const updatedActivity = await TS.Activity.findOneById(activity!._id);
      setActivity(updatedActivity);
    },

    // region variants
    setRegionVariant: (regionVariant: TS.ActivityRegionVariant) => {
      setRegionVariant(regionVariant);
    },
    updateRegionVariant: async(regionVariantId:string, changeset:any) => {
      await DA.ActivityRepository.updateRegionVariant(activity!._id, regionVariantId, changeset);
      const updatedActivity = await TS.Activity.findOneById(activity!._id);
      const updatedRegionVariant = updatedActivity!.regionVariants.find((rv:any) => rv._id === regionVariantId);
      setActivity(updatedActivity);
      setRegionVariant(updatedRegionVariant!);
    },
    copyRegionVariant: async(sourceRegionCode: string, targetRegionCode: string) => {
      await DA.ActivityRepository.copyRegionVariant(activity!._id, sourceRegionCode, targetRegionCode);
      const updatedActivity = await TS.Activity.findOneById(activity!._id);
      const updatedRegionVariant = updatedActivity!.regionVariants.find((rv:any) => rv.regionCode === targetRegionCode);
      setActivity(updatedActivity);
      setRegionVariant(updatedRegionVariant!);
    },
    addRegionVariant: async(regionCode: string, loadRegionVariant: boolean) => {
      const updatedActivity = await TS.Activity.addRegionVariant(activity!._id, regionCode);
      setActivity(updatedActivity);
      if(loadRegionVariant) {
        const regionVariant = updatedActivity.regionVariants.find((rv:any) => rv.regionCode === regionCode);
        setRegionVariant(regionVariant!);
      }
    }
  }

  return (
    <ActivityContext.Provider value={value}>
      {props.children}
    </ActivityContext.Provider>
  )
}

export {ActivityContext, ActivityProvider}