import _ from "lodash";
import React, {useContext} from "react";
import Row from "./Row";
import {SurpriseContext} from "../../Surprise.Context";

export default function Text({label, path, placeholder}) {
  // context 
  const SC = useContext(SurpriseContext);
  // get the text
  const text = _.get(SC, path) || "";
  // render
  const lines = (text || "").split("\n").filter(line => line.trim().length > 0)
  let content;
  if(lines.length === 0) {
    content = placeholder || "";
  }
  else if(lines.length === 1) {
    content = lines[0];
  }
  else {
    content = lines.map((line, index) => <p key={index}>{line}</p>)
  }
  
  return (
    <Row label={label}>{content}</Row>
  )
}
