import { Module, ModuleInfo, Theme } from ".";
import uniqid from 'uniqid';

export class CardSliderModuleItem {
  id:  string;
  title: string;
  imageUrl: string;
  pricePerPerson: number;
  buttonLabel: string;
  slug: string;
  position: number;

  constructor() {
    this.id = uniqid(); // NOTE: we really only need this id to manage adding and removing items ... (ObjectId is not an option inside browser)
    this.title = "";
    this.imageUrl = "";
    this.pricePerPerson = 5;
    this.buttonLabel = "Schenken";
    this.slug = "";
    this.position = 0;
  }
}

export class CardSliderModule implements Module {
  readonly code: string;

  _id?: string;
  countries: string[];
  position: number;
  theme: Theme;
  anchor?: string;

  elements: {
    title?:string,
    items: CardSliderModuleItem[]
  };

  constructor() {
    this.code = CardSliderModule.info.code;
    this.theme = "primary";
    this.countries = ["CH", "DE"];
    this.position = 0;
    this.elements = {
      title: "",
      items:[]
    }
  }

  static get info(): ModuleInfo {
    return {
      code: "cardslider",
      title: "Card Slider",
      description: "Sammlung von Aktivitäten mit bearbeitbaren Elementen"
    }
  }
}