import _ from "lodash";
import React, {useContext, useState} from "react";
import Util from "../../../../util/util";
import * as BL from "../../../../types/BusinessLogic";
import {SurpriseContext} from "../Surprise.Context";
import { CardSection, Modal, Value } from "../../../controls";
import GenericEdit from "../Generic.Edit";
import BuddyUpsells from "./BuddyUpsells.View";


export default function PurchaseInfo() {
  // context
  const SC = useContext(SurpriseContext);
  // render
  return (
    <CardSection title="Verkaufsinformationen">
      <Row label={"Verkaufsdatum"} path={"CreateDate"} kind="dateinput" format={Util.printDateAndTime} canEdit={false} />
      <Row label={"Verkaufspreis (CHF)"} path={"Price"} kind="numberinput" format={Util.printCurrency} canEdit={true} />
      <Value label="Buddy Upsells">
        <BuddyUpsells adventure={SC.surprise} />
      </Value>
      <VoucherUsed />
    </CardSection>
  );
}


type RowProps = {
  label: string,
  path: string,
  kind: any,
  format?: any,
  canEdit:boolean
}
function Row({label, path, kind, format, canEdit} : RowProps) {
  // state
  const [mode, setMode] = useState<"edit"|"view">("view");
  // context
  const SC = useContext(SurpriseContext);

  // edit button even handler
  const onEdit = () => setMode("edit");
  const stopEdit = () => setMode("view");

  // render
  return <>
    <Value label={label} onClick={canEdit ? onEdit : undefined}>{format(_.get(SC.surprise, path) || "")}</Value>
    <Modal isOpen={mode==="edit"} title={`${label} bearbeiten`} onClose={stopEdit}>
      <GenericEdit path={path} label={label} kind={kind} stopEdit={stopEdit} />
    </Modal>
  </>

}

function VoucherUsed() {
  // context
  const SC = useContext(SurpriseContext);
  // pre-render
  let content = "-";
  let voucher = _.get(SC.surprise, "Order.Voucher");
  if(voucher) {
    const kindLabel = BL.Voucher.printKind(voucher.kind);
    if(voucher.kind === "percentage") {
      content = `${voucher.Code}, ${voucher.Value} ${kindLabel}`;
    }
    else {
      content = `${voucher.Code}, ${kindLabel} ${voucher.Value}`;
    }
  }
  // render
  return <Value label="Promocode">{content}</Value>
  
}