import _ from "lodash";
import React, { useContext, useState, useEffect } from "react"
// types
import * as DA from "../../../types/DataAccess";
// components and contexts
import { Checkbox, Form, Icon, Icons, Modal, Loader, CardSection, Table, Tr, Td } from "../../controls";
import { ActivityContext } from "./ActivityContext";


type Field = {
  path: string, label: string, explanation?: string
}

export default function ShopProperties() {
  // state 
  const [fields, setFields] = useState<Field[]>([]);
  const [mode, setMode] = useState<"edit"|"view">("view");

  // context
  const AC = useContext(ActivityContext)!;

  // mount 
  useEffect(() => {
    setFields([
      {path:"isHomeDelivery", label:"Heimlieferung", explanation: "die 'Aktivität' wird zum Beschenkten geliefert, z.B. Essen, Blumen, etc"},
      {path:"isOnlineEvent", label:"Online-Event", explanation: "findet Online statt (z.B. Konzert via Video-Konferenz"},
      {path:"isFeatured", label:"Featured"},
      {path:"isFresh", label:"Neu"},
      {path:"isBestseller", label:"Bestseller"},
      {path:"upsellAsFood", label:"als Essen empfehlen"},
    ])
  }, []);

  // user wants to edit
  const startEdit = () => {
    setMode("edit");
  }

  // stops editing
  const stopEdit = () => {
    setMode("view");
  }

  // saves changes
  const saveEdit = async(changeset:any) => {
    await AC.update(changeset);
    setMode("view");
  }

  // render not loaded
  if(AC.activity === null) {
    return <Loader text="lade Aktivität ..." />
  }

  // subactivity? no render
  if(AC.activity.isSubactivity) {
    return null;
  }

  // pre-reender
  const items = fields.map(field => {
    const active = _.get(AC.activity, field.path) || false;
    return <Tr key={field.path} className={active ? "active":"inactive"}>
      <Td><Icon icon={active ? Icons.Check : Icons.Cancel} intent={active ? "confirm" : undefined} /></Td>
      <Td>{field.label}</Td>
      <Td><Icon icon={Icons.Edit} intent="confirm" /></Td>
    </Tr>
  })
  // render
  return <>
    <CardSection title="Eigenschaften">
      <Table onClick={startEdit}>
        {items}
      </Table>
    </CardSection>
    <Modal 
      isOpen={mode === "edit"}
      title="Aktivität - Shop Eigenschaften"
      onClose={stopEdit}
    >
      <Edit 
        activity={AC.activity2!} 
        fields={fields}
        saveEdit={saveEdit}  
        onCancel={stopEdit}
      />
    </Modal>
  </>
}


type EditProps = {
  fields: Field[],
  activity: DA.Activity,
  saveEdit: Function,
  onCancel: () => void,
}
function Edit({fields, activity, saveEdit, onCancel} : EditProps) {
  // state
  const [entity, setEntity] = useState<any|null>(null);
  const [busy, setBusy] = useState(false);

  // mount
  useEffect(() => {
    const entity:any = {};
    //const serializedActivity = activity.toDb();
    fields.map(field => entity[field.path] = (activity as any)[field.path] ? true : false);
    setEntity(entity);
  }, []);

  // save the update
  const onSave = async(formResult:any) => {
    setBusy(true);
    await saveEdit(formResult.changesetFlat); 
    setBusy(false);
  }

  // entity not yet set?
  if(entity === null) {
    return null;
  }

  // render
  const checkboxes = fields.map(field => <Checkbox key={field.path} path={field.path} label={field.label} explanation={field.explanation} />);
  return (
    <Form onCancel={onCancel} onSave={onSave} entity={entity} busy={busy}>
      {checkboxes}
    </Form>
  ); 
}