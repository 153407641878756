import axios from "axios";
import ApiConfig from "../config/api";

class ApiResult {
  success: boolean = true;
  data?: any;
  error?: any;

  public static fromDb(obj:any) : ApiResult {
    const result = new ApiResult();
    result.success = obj.success || false;
    result.data = obj.data || undefined;
    result.error = obj.error || undefined;
    if(!result.success) {
      result.error = {message:"unknown error (api returned failure without error information)"}
    }
    return result;
  }
}

function post(endpoint: string, action: string, payload: any) : Promise<ApiResult> {
  const url = `${ApiConfig.ApiRoot}/admin/${endpoint}`;
  const data = {action, payload};

  return new Promise((resolve, reject) => {
    const token = window.localStorage.getItem('appentura_token');
    let validatedStatus: number;

    // create request
    const request = axios({
      url,
      method: "POST",
      data,
      headers: {'x-access-token': token},
      validateStatus: function(status) {
        validatedStatus = status
        return ((status >= 200 && status < 300) || status === 401 || status === 404) // || (status >= 400 && status < 500) //)
      }
    })

    // execute request
    request
      .then((result) => {
        switch(validatedStatus) {
          case 401:
            console.error("NOT AUTHORIZED:", result);
            // alert("YOU ARE NOT AUTHORIZED TO USE THIS RESOURCE - PLEASE LOGIN")
            reject({
              success: false,
              error:{message:"you are not authorized", data: result}
            })
            break;
          case 404:
            reject(result)
            reject({
              success:false,
              error:{message:"unknown", data:result}
            })
            break;
          default:
            // note: data is what axios delivers ... it should be structured : {success:<bool>, data:<obj>}
            resolve(result.data)
            break
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

export {
  post
}
//export default module;