import _ from "lodash";
import { Provider, ProviderAddress, ProviderAddressKind, ProviderBankAccount, ProviderBookingInfo, ProviderBookingInfoKind, ProviderCollectiveInvoice, ProviderContact, ProviderLink } from "./Provider";
import { Mapper } from "../_Common/Mapper";

export class ProviderMapper extends Mapper<Provider> {
  public apiEndpoint = "providers";

  public toDb(provider: Provider): any {
    return {
      _id: provider._id,
      name: provider.name,
      isActive: provider.isActive,
      notes: provider.notes,
      colorRating: provider.colorRating,
      booking: (provider.booking || []).map((b:ProviderBookingInfo) => this.toDb_bookingInfo(b)),
      addresses: (provider.addresses || []).map((a:ProviderAddress) => this.toDb_address(a)),
      contacts: (provider.contacts || []).map((c:ProviderContact) => this.toDb_contact(c)),
      links: (provider.links || []).map((l:ProviderLink) => this.toDb_providerLink(l)),
      bankAccounts: (provider.bankAccounts || []).map((b:ProviderBankAccount) => this.toDb_bankAccount(b)),
      collectiveInvoice: this.toDb_collectiveInvoice(provider.collectiveInvoice),
    }
  }

  private toDb_bookingInfo(bookingInfo: ProviderBookingInfo): any {
    return {
      _id: bookingInfo._id,
      kind: bookingInfo.kind,
      value: bookingInfo.value,
      notes: bookingInfo.notes || "",
      order: bookingInfo.order
    }
  }

  private toDb_address(address: ProviderAddress): any {
    return {
      _id: address._id,
      city: address.city,
      companyName: address.companyName,
      country: address.country,
      firstName: address.firstName,
      houseNumber: address.houseNumber,
      kind: address.kind,
      lastName: address.lastName,
      notes: address.notes,
      street: address.street,
      supplement: address.supplement,
      zip: address.zip,
    }
  }

  private toDb_contact(contact: ProviderContact): any {
    return {
      _id: contact._id,
      email: contact.email,
      firstName: contact.firstName,
      fix: contact.fix,
      function: contact.function,
      kind: contact.kind,
      lastName: contact.lastName,
      mobile: contact.mobile,
      notes: contact.notes,
      title: contact.title,
      url: contact.url,
    }
  }

  private toDb_providerLink(providerLink: ProviderLink): any {
    return {
      _id: providerLink._id,
      title: providerLink.title,
      url: providerLink.url,
    }
  }

  private toDb_bankAccount(bankAccount: ProviderBankAccount): any {
    return {
      _id: bankAccount._id,
      bankName: bankAccount.bankName,
      accountNumber: bankAccount.accountNumber,
      currency: bankAccount.currency,
      isActive: bankAccount.isActive,
      isDefault: bankAccount.isDefault,
    }
  }

  private toDb_collectiveInvoice(collectiveInvoice: ProviderCollectiveInvoice): any {
    return {  
      isActive: collectiveInvoice.isActive,
      email: collectiveInvoice.email,
      currency: collectiveInvoice.currency,
    }
  }

  public fromDb(obj: any): Provider {
    return {
      _id: obj._id,
      name:  obj.name,
      isActive: obj.isActive ? true: false,
      notes: obj.notes || "",
      colorRating: obj.colorRating || null,
      booking: (obj.booking || []).filter((b:any) => !_.isNil(b)).map((b:any) => this.fromDb_bookingInfo(b)),
      addresses: (obj.addresses || []).map((a:any) => this.fromDb_address(a)),
      contacts: (obj.contacts || []).map((c:any) => this.fromDb_contact(c)),
      links: (obj.links || []).map((l:any) => this.fromDb_providerLink(l)),
      bankAccounts: (obj.bankAccounts || []).map((b:any) => this.fromDb_bankAccount(b)),
      collectiveInvoice: this.fromDb_collectiveInvoice(obj.collectiveInvoice),
    };
  }

  private isProviderAddressKind(v:string) {
    return Object.values(ProviderAddressKind).some(e => e === v);
  }

  public fromDb_address(obj: any): ProviderAddress {
    return {
      _id: obj._id,
      kind: this.isProviderAddressKind(obj.kind) ? obj.kind : ProviderAddressKind.Location,
      firstName: obj.firstName || "",
      lastName: obj.lastName || "",
      supplement: obj.supplement || "",
      companyName: obj.companyName || "",
      street: obj.street || "",
      houseNumber: obj.houseNumber || "",
      zip: obj.zip || "",
      city: obj.city || "",
      country: obj.country || "",
      countryCode: obj.countryCode || "",
      notes: obj.notes || "",
    }
  }

  public fromDb_contact(obj: any): ProviderContact {
    return {
      _id: obj._id,
      email: obj.email || "",
      firstName: obj.firstName || "",
      fix: obj.fix || "",
      function: obj.function || "",
      kind: obj.kind || "",
      lastName: obj.lastName || "",
      mobile: obj.mobile || "",
      notes: obj.notes || "",
      title: obj.title || "",
      url: obj.url || "",
    }
  }

  public fromDb_bankAccount(obj: any): ProviderBankAccount {
    return {
      _id: obj._id || undefined,
      accountNumber: obj.accountNumber || "",
      bankName: obj.bankName || "",
      currency: obj.currency || "CHF",
      isActive: obj.isActive ? true : false,
      isDefault: obj.isDefault ? true : false,
    }
  }

  private isProviderBookingInfoKind (v:ProviderBookingInfoKind):boolean {
    return v === "email" || v === "web" || v === "phone";
  }

  public fromDb_bookingInfo(obj: any): ProviderBookingInfo {
    return {
      _id: obj._id,
      kind: this.isProviderBookingInfoKind(obj.kind) ? obj.kind : "email",
      value: obj.value,
      notes: obj.notes || "",
      order: obj.order || 1
    }
  }

  public fromDb_providerLink(obj: any): ProviderLink {
    return {
      _id: obj._id,
      title: obj.title || "",
      url: obj.url || ""
    }
  }

  public fromDb_collectiveInvoice(c: any): ProviderCollectiveInvoice {
    return {
      isActive: _.get(c, "isActive") || false,
      email: _.get(c, "email") || null,
      currency: _.get(c, "currency") || "CHF"
    }
  }
}