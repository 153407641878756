import React, {useContext} from "react";
import { Card, Icons } from "../../../controls";
import { SurpriseContext } from "../Surprise.Context";
// subviews
import Documents from "./Documents";
import Receipts from "./Receipts";
import Invoices from "./Invoices";

type FilesProps = {
  expanded: boolean
}
export default function Files({expanded}: FilesProps) {
  // context
  const SC = useContext(SurpriseContext);

  // render
  return (
    <Card title="Rechnungen & Dokumente" icon={Icons.FileAlt} collapsible={true} expanded={expanded || false}>
      <Invoices surprise={SC.surprise} />
      <Documents adventureId={SC.surprise._id} />
      <Receipts surprise={SC.surprise} />
    </Card>
  );
}
