import React from "react";
import { Button, Icon, Icons, Modal, Row, useModal } from "../../controls";

import * as Cms from "../../../types/Cms";
import * as Modules from "./Modules"

import CSS from "./Page.AddModule.module.scss";

export default function AddModule() {
  // state
  const [modal, setModal] = useModal<"selectModule" | "createModule">();

  // user wants to select a module
  const onClickAdd = async () => {
    setModal("selectModule", "Modul wählen");
  }

  const selectModule = (code: string) => {
    setModal("createModule", "Modul erzeugen", code);
  }

  // pre-render modal content
  let modalContent = null;
  if (modal.action === "selectModule") {
    modalContent = <Selection closeDialog={modal.onClose} onSelectModule={selectModule} />
  }
  else if (modal.action === "createModule") {
    switch (modal.params) {
      case Cms.HeroModule.info.code:
        modalContent = <Modules.Hero closeDialog={modal.onClose} />
        break;
      case Cms.AccordionModule.info.code:
        modalContent = <Modules.Accordion closeDialog={modal.onClose} />
        break;
      case Cms.CardSliderModule.info.code:
        modalContent = <Modules.CardSlider closeDialog={modal.onClose} />
        break;
      case Cms.IconTilesModule.info.code:
        modalContent = <Modules.IconTiles closeDialog={modal.onClose} />
        break;
      case Cms.SimpleHeaderModule.info.code:
        modalContent = <Modules.SimpleHeader closeDialog={modal.onClose} />
        break;
      case Cms.CustomFooterModule.info.code:
        modalContent = <Modules.CustomFooter closeDialog={modal.onClose} />
        break;
      case Cms.TestimonialModule.info.code:
        modalContent = <Modules.Testimonial closeDialog={modal.onClose} />
        break;
      case Cms.TestimonialCarouselModule.info.code:
        modalContent = <Modules.TestimonialCarousel closeDialog={modal.onClose} />
        break;
      case Cms.TrustBannerModule.info.code:
        modalContent = <Modules.TrustBanner closeDialog={modal.onClose} />
        break;
      case Cms.LogosModule.info.code:
        modalContent = <Modules.Logos closeDialog={modal.onClose} />
        break;
      case Cms.NumberedTextListModule.info.code:
        modalContent = <Modules.NumberedTextList closeDialog={modal.onClose} />
        break;
      case Cms.ActivitiesStaticModule.info.code:
        modalContent = <Modules.ActivitiesStatic closeDialog={modal.onClose} />
        break;
      case Cms.VideoModule.info.code:
        modalContent = <Modules.Video closeDialog={modal.onClose} />;
        break;
      case Cms.NewsletterModule.info.code:
        modalContent = <Modules.Newsletter closeDialog={modal.onClose} />;
        break;
      case Cms.BasicModule.info.code:
      default:
        modalContent = <Modules.Basic closeDialog={modal.onClose} />;
        break;
    }
  }
  // render
  return <>
    <Button size="small" onClick={onClickAdd}><Icon icon={Icons.Plus} /> Modul hinzufügen</Button>
    <Modal {...modal}>
      {modalContent}
    </Modal>
  </>
}

type SelectionProps = {
  closeDialog: () => void,
  onSelectModule: (code: string) => void,
}
function Selection({ closeDialog, onSelectModule }: SelectionProps) {
  // pre-render pickers
  const items: any[] = [
    <ModulePicker key="hero" moduleInfo={Cms.HeroModule.info} onClick={onSelectModule} />,
    <ModulePicker key="trustbanner" moduleInfo={Cms.TrustBannerModule.info} onClick={onSelectModule} />,
    <ModulePicker key="simpleheader" moduleInfo={Cms.SimpleHeaderModule.info} onClick={onSelectModule} />,
    <ModulePicker key="basic" moduleInfo={Cms.BasicModule.info} onClick={onSelectModule} />,
    <ModulePicker key="cardslider" moduleInfo={Cms.CardSliderModule.info} onClick={onSelectModule} />,
    <ModulePicker key="accordion" moduleInfo={Cms.AccordionModule.info} onClick={onSelectModule} />,
    <ModulePicker key="icontiles" moduleInfo={Cms.IconTilesModule.info} onClick={onSelectModule} />,
    <ModulePicker key="testimonial" moduleInfo={Cms.TestimonialModule.info} onClick={onSelectModule} />,
    <ModulePicker key="testimonialcarousel" moduleInfo={Cms.TestimonialCarouselModule.info} onClick={onSelectModule} />,
    <ModulePicker key="activities_static" moduleInfo={Cms.ActivitiesStaticModule.info} onClick={onSelectModule} />,
    <ModulePicker key="logos" moduleInfo={Cms.LogosModule.info} onClick={onSelectModule} />,
    <ModulePicker key="numbered_textlist" moduleInfo={Cms.NumberedTextListModule.info} onClick={onSelectModule} />,
    <ModulePicker key="video" moduleInfo={Cms.VideoModule.info} onClick={onSelectModule} />,
    <ModulePicker key="newsletter" moduleInfo={Cms.NewsletterModule.info} onClick={onSelectModule} />,
    <ModulePicker key="customfooter" moduleInfo={Cms.CustomFooterModule.info} onClick={onSelectModule} />,
  ]
  // render
  return <div>
    <div>
      {items}
    </div>
    <Row align="center">
      <Button intent="cancel" onClick={closeDialog}>abbrechen</Button>
    </Row>
  </div>

}


type ModulePickerProps = {
  moduleInfo: Cms.ModuleInfo,
  onClick: (code: string) => void
}
function ModulePicker({ moduleInfo, onClick }: ModulePickerProps) {
  return (
    <div className={CSS.picker} onClick={() => onClick(moduleInfo.code)}>
      <div className={CSS.inner}>
        <div className={CSS.title}>{moduleInfo.title}</div>
        <div className={CSS.description}>{moduleInfo.description}</div>
      </div>
    </div>
  )
}