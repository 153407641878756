import React from "react";
import DashboardPanel from "./DashboardPanel";
import { Button, Loader, Table, Tr, Td, Text } from '../../controls';

// images and css
import iconFinish from './../../../assets/images/icon_finish.svg';

type CompletedAdventuresProps = {
  data:any, dataStatus:any, requestData:any
}
export default function CompletedAdventures({data, dataStatus, requestData} : CompletedAdventuresProps) {
  // initializes data loading
  const loadData = async() => {
    await requestData();
  }

  // render content
  let content = null;
  if(dataStatus === "notloaded") {
    content = <>
      <Text>Daten wurden noch nicht geladen.</Text> <Button size="small" onClick={loadData}>Daten laden</Button>
    </>
  }
  else if(dataStatus === "loading") {
    content = <Loader />
  }
  else {
    content = (
      <Table>
        <Tr>
          <Td label>diesen Monat</Td>
          <Td align="right">{data.month.count}</Td>
        </Tr>
        <Tr>
          <Td label>dieses Jahr</Td>
          <Td align="right">{data.year.count}</Td>
        </Tr>
        <Tr>
          <Td label>Total</Td>
          <Td align="right">{data.allTime.count}</Td>
        </Tr>
      </Table>
    )
  }

  // render
  return (
    <DashboardPanel id="dashboard-executions" title="Durchführungen" smallOnMobile={true} smallOnDesktop={true} icon={iconFinish} iconRounded={false}>
      {content}
    </DashboardPanel>
  )
}