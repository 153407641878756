import _ from "lodash";
import React, { useEffect, useState, useContext } from "react";
import * as TS from "../../../types";
import { Button, ConfirmButton, Form, Icon, Icons, SearchableDropdown, Validators, Link, TextInput, TextArea, NumberInput, CardSection, CloudStorageUploader, Modal } from "../../controls";
import { ActivityPackageContext } from "./ActivityPackage.Context";


export default function ActivityPackageActivities() {
  // context
  const AC = useContext(ActivityPackageContext);
  // state
  const [modalAction, setModalAction] = useState<"create"|"edit"|"none">("none");
  const [modalParam, setModalParam] = useState<TS.ActivityPackageActivity|undefined>();
  const [modalTitle, setModalTitle] = useState<string>("");

  // no context? no render
  if(!AC || !AC.activityPackage) {
    return null;
  }

  // adds an activity
  const saveActivity = async(activityId:string, title:string, description:string, imageUrl:string, sortIndex:number) => {
    if(AC.activityPackage) {
      const activities = AC.activityPackage.activities.filter(a => a.activityId !== activityId);
      activities.push({
        activityId, title, description, image:{url:imageUrl}, sortIndex
      });
      AC.activityPackage.activities = activities;
      await AC.save();
    }
  }

  // removes an activity
  const removeActivity = async(activityId:string) => {
    if(AC.activityPackage) {
      const activities = AC.activityPackage.activities.filter(a => a.activityId !== activityId);
      AC.activityPackage.activities = activities;
      await AC.save();   
    }
  }

  // user wants to edit an activity
  const onClickEdit = (packageActivity: TS.ActivityPackageActivity) => {
    setModalAction("edit");
    setModalParam(packageActivity);
    setModalTitle("Aktivität hinzufügen");
  }

  // user wants to add an activity
  const onClickAdd = () => {
    setModalAction("create");
    setModalParam(undefined);
    setModalTitle("Aktivität hinzufügen");
  }

  // closes dialog
  const closeDialog = () => setModalAction("none");

  // pre-render
  const activities: Array<TS.Activity> = [];
  const rows = AC.activityPackage.activities
  .sort((a,b) => a.sortIndex - b.sortIndex)
  .map((a:any) => {
    // find activity in catalogue
    const activity = AC.activityCatalogue.find(ca => ca._id == a.activityId);
    if(activity) {
      activities.push(activity);
    }
    // render row
    return <tr key={a.activityId}>
      <td>
        <div className="image-container">
          <img src={a.image.url} alt={a.title} />
        </div>
      </td>
      <td>
        <Link to={`/activities/${a.activityId}`} target="_blank">
          {activity ? activity.title.de : a.activityId}
        </Link>
      </td>
      <td>{a.title}</td>
      <td>{a.description.split("\n").map((l:string,index:number) => <p key={index}>{l}</p>)}</td>
      <td>
        <ConfirmButton onConfirm={() => removeActivity(a.activityId)}><Icon icon={Icons.Trash} /></ConfirmButton>
      </td>
      <td>
        <Button onClick={() => onClickEdit(a)} size="small"><Icon icon={Icons.Edit} /></Button>
      </td>
    </tr>
  })

  // render
  return <>
    <CardSection title="Aktivitäten">
      <div id="v-activitypackage-edit-actitivies">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Aktivität</th>
              <th>Titel</th>
              <th>Beschreibung</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
        <Button size="small" onClick={onClickAdd}><Icon icon={Icons.Plus} /> hinzufügen</Button>
      </div>
    </CardSection>
    <Modal isOpen={modalAction !== "none"} title={modalTitle} onClose={closeDialog}>
      <Edit 
        save={saveActivity} 
        activityCatalogue={AC.activityCatalogue} 
        packageActivity={modalParam} 
        closeDialog={closeDialog}
      />
    </Modal>
  </>
}


type EditProps = {
  save: (activityId:string, title:string, description:string, imageUrl:string, sortIndex:number) => Promise<void>,
  packageActivity?: TS.ActivityPackageActivity,
  activityCatalogue: Array<TS.Activity>,
  closeDialog: VoidFunction
}
function Edit({save, packageActivity, activityCatalogue, closeDialog} : EditProps) {
  // state
  const [busy, setBusy] = useState(false);
  const [entity, setEntity] = useState({activityId:"", title:"", description:"", sortIndex:0, imageUrl:""})
  const [dropdownOptions, setDropdownOptions] = useState<Array<{label:string, value:string}>>([]);
  
  // mount
  useEffect(() => {
    if(packageActivity) {
      setEntity({
        activityId: packageActivity.activityId,
        sortIndex: packageActivity.sortIndex,
        title: packageActivity.title, 
        description: packageActivity.description, 
        imageUrl: packageActivity.image.url || ""
      });
    }
    setDropdownOptions(activityCatalogue.map(a => {
      return {label:a.title.de, value:a._id};
    }));
  }, []);

  // selected activity
  const onChangeSelectedActivity = (e:any) => {
    const activityId = e.value;
    const activity = activityCatalogue.find(a => a._id === activityId);
    if(activity) {
      const defaultImage = activity.getDefaultImage();
      setEntity({...entity, activityId, imageUrl: _.get(defaultImage, "url") || ""});
    }
  }

  // saves the entry
  const onSave = async(formResult:any) => {
    setBusy(true);
    const activityId = _.get(formResult, "changesetFlat.activityId");
    const title = _.get(formResult, "changesetFlat.title");
    const description = _.get(formResult, "changesetFlat.description");
    const imageUrl = _.get(formResult, "changesetFlat.imageUrl");
    const sortIndex = Number(_.get(formResult, "changesetFlat.sortIndex") || 0);
    await save(activityId, title, description, imageUrl, sortIndex);
    setBusy(false);
    closeDialog();
  }

  // cancel
  const onCancel = () => {
    closeDialog()
  }

  // render
  return (
    <div id="v-activitypackage-activities-edit">
    <Form 
      entity={entity}
      onCancel={onCancel} onSave={onSave}
      busy={busy} 
    >
      <SearchableDropdown 
        label="Aktivität" 
        path="activityId" 
        options={dropdownOptions} 
        validate={Validators.isRequired("Bitte eine Aktivität wählen")} 
        transform={undefined} 
        focusOnMount={true} 
        onChange={onChangeSelectedActivity}
      />
      <TextInput 
        label="Titel" 
        path="title" 
        disabled={busy} 
        validate={Validators.isRequired("Titel eingeben")} 
        explanation="wird auf der Webseite als Titel der Aktivität verwendet" 
      />
      <TextArea 
        label="Beschreibung"
        path="description"
        disabled={busy}
        validate={Validators.isRequired("bitte eine kurze Beschreibung eingeben")}
        explanation="wird auf der Webseite als Beschreibung der Aktität verwendet"
        rows={3}
      />
      <CloudStorageUploader
        kind="image" 
        label="Bild" 
        path="imageUrl"
        folder="activities" 
        prefix="" 
        disableUrlEditing={true}
      />
      <NumberInput 
        label="Sortier-Index"
        path="sortIndex"
      />
      <br /><br /><br />
    </Form>
    </div>
  )
}

