import React, { useState, useEffect } from "react";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";
// components & subviews
import { Breadcrumb, Card, Heading, Icon, Icons, Modal, List, ListColumn, ListFilter } from "../../controls";
import Edit from "./Vouchers.Edit";

// list columns
const columns: ListColumn[] = [
  {
    header:"Code",
    path:"code"
  },
  {
    header:"Wert",
    path:"value",
    print: (v:DA.Voucher) => `${v.kind === "percentage" ? v.value + "%" : v.value.toFixed(2)}`
  },
  {
    header:"Typ",
    path:"kind",
    print: (v:DA.Voucher) => v.kind === "percentage" ? "Prozent" : "Betrag"
  },
  {
    header:"Anwendungen",
    path:"numberOfUses",
    sort: (a:DA.Voucher, b:DA.Voucher) => a.numberOfUses - b.numberOfUses,
    align: "center"
  },
  {
    header:"Einmalig",
    path:"singleUse",
    print: (v:DA.Voucher) => v.singleUse ? <Icon icon={Icons.Check} /> : null,
    printCsv: (v:DA.Voucher) => v.singleUse ? "ja" : "nein",
    sort: (a:DA.Voucher, b:DA.Voucher) => (a.singleUse ? 1 : -1) - (b.singleUse ? 1 : -1),
    align: "center"
  },
  {
    header:"zählt z. Min-Wert",
    path:"countsTowardsMinValue",
    print: (v:DA.Voucher) => v.countsTowardsMinValue ? <Icon icon={Icons.Check} /> : null,
    printCsv: (v:DA.Voucher) => v.countsTowardsMinValue ? "ja" : "nein",
    sort: (a:DA.Voucher, b:DA.Voucher) => (a.countsTowardsMinValue ? 1 : -1) - (b.countsTowardsMinValue ? 1 : -1),
    align: "center"
  },
  {
    header:"Umsatzrelevant",
    path:"relevantToSales",
    print: (v:DA.Voucher) => v.relevantToSales ? <Icon icon={Icons.Check} /> : null,
    printCsv: (v:DA.Voucher) => v.relevantToSales ? "ja" : "nein",
    sort: (a:DA.Voucher, b:DA.Voucher) => (a.relevantToSales ? 1 : -1) - (b.relevantToSales ? 1 : -1),
    align: "center"
  },
  {
    header: "Notizen",
    path: "notes",
    hidden: true
  }
];

// the component
export default function VouchersList() {
  // state
  const [itemIdToEdit, setItemIdToEdit] = useState<string|undefined>();
  const [mode, setMode] = useState<"edit"|"view">("view");
  const [vouchers, setVouchers] = useState<DA.Voucher[]|null>(null);
  const [filters] = useState<ListFilter[]>([
    {
      label:"inaktive anzeigen (abgelaufende, einmalige verwendete)", 
      kind:"checkbox", 
      initialValue: false,
      onChange: (checked:any) => { load(checked as boolean)}
    }
  ]);

  // mount
  useEffect(() => {
    load(false)
  }, [])

  // loads data
  const load = async(showInactive:boolean) => {
    setVouchers(null);
    const allVouchers = await DA.VoucherRepository.findAll();
    const vouchers: DA.Voucher[] = allVouchers.filter(voucher => {
      if(showInactive) {
        return true;
      }
      if(voucher.singleUse && voucher.numberOfUses > 0) {
        return false;
      }
      if(new Date(voucher.validTo) < new Date()) {
        return false;
      }
      return true;
    });
    setVouchers(vouchers);
  }


  // stops editing
  const stopEdit = () => {
    setMode("view");
    setItemIdToEdit(undefined);
  }

  // user wants to create or update
  const startEdit = (voucher?:any) => {
    setMode("edit");
    setItemIdToEdit(voucher ? voucher._id : "");
  }

  // user updated something (edit or create)
  const onUpdate = async() => {
    stopEdit();
    setVouchers(null);
    await load(false);
  }

  // user wants to delete
  const onDelete = async(voucher:any) => {
    const updatedVouchers = vouchers!.filter(v => v._id !== voucher._id);
    await DA.VoucherRepository.remove(voucher);
    setVouchers(updatedVouchers);
  }


  // render
  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}]} location="Promocodes" />
    <Heading>Promocodes</Heading>
    <Card>
      <List 
        columns={columns} 
        items={vouchers} 
        filters={filters} 
        onClickCreate={() => startEdit()} 
        onRowClick={item => startEdit(item)} 
        getKey={item => item._id} 
        onClickDelete={onDelete} 
        showCsvExport={true} 
        csvExportFilename="Promocodes" 
      />
    </Card>
    <Modal isOpen={mode === "edit"} onClose={stopEdit} title="Promocode">
      <Edit id={itemIdToEdit} onUpdate={onUpdate} onCancel={stopEdit} />
    </Modal>
  </>
}