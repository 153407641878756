import _ from "lodash";
import React, {useContext, useState, useEffect, useRef, MutableRefObject} from "react"
import util from "./_util"

import {FormContext} from "./FormContext";
import FieldValidation from "./FieldValidation";
import {Validation} from "./Validation";
import { Alert, Label, DropdownOption } from ".";

import CSS from "./SearchableDropdown.module.scss";


type SearchableDropdownProps = {
  validate?: any, 
  path?:string, 
  value?:any,
  label?:string, 
  options: DropdownOption[], 
  transform?: any,
  focusOnMount?: boolean, 
  disabled?: boolean,
  onChange?: (value:any, validation:Validation) => void,
}
export function SearchableDropdown({validate, path, value, label, options, transform, focusOnMount = false, onChange, disabled=false} : SearchableDropdownProps) {
  // get context
  const form = useContext(FormContext);

  // if we have a form context, we render with form if not without
  if(form && path) {
    // warn about value not being used if provided
    if(value) {
      console.warn("since SearchableDropdown is inside a FormContext and a 'path' was provided, the provided 'value' will be ignored");
    }
    // render the form control
    return FormControl({path, label, focusOnMount, validate, transform, options, onChange, disabled});
  }
  else if(value !== undefined) {
    // TODO do we not also need the onChange if we have a widget? (same is true for all other controls that support form/widget mechanism)
    // a value was provided
    return Widget({label, value, focusOnMount, validate, transform, options, onChange, disabled })
  }
  // something is missing
  return (
    <Alert intent="error" size="small" title="Implementation Error">
      Expected the path property (if control is inside a Form) or the value property.
    </Alert>
  )
}

type FormControlProps = {
  path:string,
  label?: string,
  options: DropdownOption[], 
  focusOnMount?: boolean, 
  transform?: any,
  validate?: any,
  disabled?: boolean,
  onChange?: (value:any, validation:Validation) => void,
}
function FormControl({path, label, options, focusOnMount, validate, transform, disabled, onChange} : FormControlProps) {
  // state
  const [initialValue, setInitialValue] = useState<string>("");

  // context
  const form = useContext(FormContext);

  // mount
  useEffect(() => {
    // TODO we always get a form and and entity (ensured in <TextInput />) ... no need to check this
    if(form.entity && path) {
      // setting initial value
      const value = _.get(form.entity, path); // TODO what if no value is found?
      setInitialValue(value);
    }
  }, [form.entity, validate, path])

  // form.resetIndex changed -> reset the item
  useEffect(() => {
    if(form.resetIndex !== 0) {
      setInitialValue(form.getOldValue(path))
    }
  }, [form.resetIndex, path, form])

  // to make sure the displayed value also changes when changed externally (i.e. another TextInput serving the same path or when manually trigger an onChangeField event on FormContext)
  useEffect(() => {
    const newValue = form.getNewValue(path);
    if(newValue) {
      if(form.getNewValue(path) !== initialValue) {
        setInitialValue(newValue);
      }
    }
  }, [form.version]);

  // handler
  const onWidgetChange = (value:string, validation: Validation) => {
    form.onFieldChange(path, value, validation)
    if(onChange) {
      onChange(value, validation);
    }
  }

  // render
  return <Widget value={initialValue} label={label} options={options} transform={transform} focusOnMount={focusOnMount} validate={validate} disabled={disabled} onChange={onWidgetChange}/>
}

type WidgetProps = {
  value: any,
  label?: string,
  focusOnMount?: boolean, 
  validate?: any,
  options: DropdownOption[], 
  transform?: any,
  disabled?: boolean,
  onChange?: (value:any, validation: Validation) => void
}
function Widget({value, label, options, focusOnMount=false, validate, transform, disabled, onChange} : WidgetProps) {
  // state
  const [enteredValue, setEnteredValue] = useState<string>(value); // TODO do we need this?
  const [validation, setValidation] = useState(new Validation(true));
  const [searchText, setSearchText] = useState("")
  const [searchResult, setSearchResult] = useState<any[]>([]);

  const [selectInfo, setSelectInfo] = useState({index:0, value:value})
  
  const [selectStyle, setSelectStyle] = useState({})

  // refs
  const refInput = useRef<HTMLInputElement | null>(null);
  const refLabel = useRef<HTMLLabelElement | null>(null);


  // setup when refs are available
  useEffect(() => {
    if(refInput && refLabel && refInput.current && refLabel.current) {
      // create the style for the <select> element
      const zIndexInput = util.getZIndex(refInput.current)
      const zIndex = isNaN(zIndexInput) ? 1 : Number(zIndexInput) + 1
      const heightInput = refInput.current.offsetHeight
      const heightLabel = refLabel.current.offsetHeight
      const selectStyle = {
        zIndex:zIndex,
        top:heightInput + heightLabel + 2 ,
        left:0
      }
      if(focusOnMount && refInput && refInput.current) {
        refInput.current.focus();
      }
      // update state
      setSelectStyle(selectStyle)
    }
  }, [refInput, refLabel])

  // value changes externally
  useEffect(() => {
    const validation = validate ? validate(value) : new Validation(true);
    const option = options.find(o => o.value === value);
    setSearchText(option ? option.label : "");
    setValidation(validation);
    setEnteredValue(value);
  }, [value])

  // mount
  /*
  useEffect(() => {
    if(form.entity && path) {
      let value = _.get(form.entity, path) // TODO what if no value is found?
      let validation = validate ? validate(value) : new Validation(true);

      let selectedItem = (options || []).find(item => item.value === value)
      if(selectedItem) {
        setSearchText(selectedItem.label)
      }
      //setValue(value)
      setValidation(validation)
      form.onFieldChange(path, value, validation)
    }
  }, [form.entity, validate, path])
  

  // form.resetIndex changed -> reset the item
  useEffect(() => {
    if(form.resetIndex !== 0) {
      let oldValue = form.getOldValue(path)
      let oldItem = options.find(o => o.value === oldValue)
      if(oldItem) {
        setSearchText(oldItem.label)
      }
    }
  }, [form.resetIndex, path, form])
  */
  

  const selectItem = (selectedItem:any) => {
    let value = selectedItem ? selectedItem.value : null
    let label = selectedItem ? selectedItem.label : ""
    let validation = validate ? validate(value) : new Validation(true);

    setEnteredValue(value);
    setValidation(validation);
    setSearchText(label); 
    setSearchResult([]);

    // inform listeners
    if(onChange) {
      onChange(value, validation);
    }

    // remove focus
    refInput.current?.blur();
    
    /*
    //form.onFieldChange(path, value, validation)
    */
  }

  const onChangeSelect = (e: any) => {
    let selectedItem = searchResult.find(r => r.value === e.target.value)
    selectItem(selectedItem)
  }

  const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sr = options.filter(o => o.label.toLowerCase().includes(e.target.value.trim().toLowerCase())).sort((a,b) => a.label.localeCompare(b.label) )
    setSearchResult(sr)
    setSearchText(e.target.value)
    if(sr.length > 0) {
      setSelectInfo({index:0, value:sr[0].value})
    }
  }

  const onBlurSearchText = (e: React.FocusEvent<HTMLInputElement>) => {
    // TODO handle on blur
    /*
    let value = form.getNewValue(path)
    let selectedItem = (options || []).find(item => item.value === value)
    setSearchText(selectedItem ? selectedItem.label : "")
    */
  }

  const onKeyDownSearchText = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch(e.keyCode) {
      // up arrow
      case 38:
        e.preventDefault() // we do not want the down arrow to move the cursor to the end of the text input
        if(selectInfo.index > 0) {
          let index = selectInfo.index - 1
          let value = searchResult[index].value
          setSelectInfo({index, value})
        }
        break
      // down arrow
      case 40:
        e.preventDefault() // we do not want the up arrow to move the cursor to the beginning of the text input
        if(selectInfo.index < (searchResult.length -1)) {
          let index = selectInfo.index + 1
          let value = searchResult[index].value
          setSelectInfo({index, value})
        }
        break
      // enter
      case 13:
        e.preventDefault()
        if(searchResult.length > 0) {
          selectItem(searchResult[selectInfo.index])
        }
        break
      default:
        break
    }

  }


  // render 
  let opts = searchResult.map(o => {
    let label = transform ? transform(o) : o.label || o.value
    return <option value={o.value} key={o.key || o.value}>{label}</option>
  })
  let select = opts.length === 0 ? null : (
    <select 
      size={4} 
      value={selectInfo.value || ""} 
      onChange={()=>{/* we use onClick instead */}}
      onClick={onChangeSelect}
      style={selectStyle}
      disabled={disabled}
    >
      {opts}
    </select>
  )
  
  return (
    <div className={CSS.container}>
      <Label label={label}>
        <input 
          type="text" ref={refInput} 
          value={searchText}
          onChange={onChangeSearchText}
          onBlur={onBlurSearchText}
          onKeyDown={onKeyDownSearchText}
          autoComplete="off"
          disabled={disabled}
        />
      </Label>
      {select}
      <FieldValidation validation={validation} />
    </div>
  )
}