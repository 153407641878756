import _ from "lodash";
import React, { useContext, useState } from 'react';
import * as TS from "../../../../types";
import StepsLogic from "../../../../types/Automatization/Steps";
import {SurpriseContext} from "../Surprise.Context";
import {StepsProvider} from "./Steps.Context";
import Step from './Step.Control';
import AddStep from "./Step.Add";
import { Button, Card, ConfirmButton, Alert, Icon, Icons, Row, Modal } from '../../../controls';

import './Steps.View.scss'

type StepsProps = {
  expanded?:boolean
}
export default function Steps({expanded=false} : StepsProps) {
  // context
  const SC = useContext(SurpriseContext);
  // state
  const [movable, setMovable] = useState(false);
  
  // saves the changes from SC.steps to the surprise
  const save = async() => {
    setMovable(false);
    await SC.updateAtPath("Steps", SC.steps, true);
  }

  // inserts steps at index
  const insertSteps = (index:number, steps:any) => {
    SC.steps = SC.steps || [];
    SC.steps.splice(index, 0, ...steps);
    save();
  }

  // render
  const steps = (SC.steps || []).map((step:any, index:number) => {
    return (
      <Step movable={movable} key={step._id || index} step={step} index={index} isFirst={index === 0} isLast={index === SC.steps.length - 1} insertSteps={insertSteps} />
    )
  })
  let actionsTop = null;
  let actionsBottom = null;
  let noSteps = null;
  if(movable) {
    actionsTop = null;
    actionsBottom = <MoveActions save={save} cancel={() => setMovable(false)} />
  }
  else {
    actionsTop = <StandardActions makeMovable={() => setMovable(true)} insertSteps={insertSteps}/>
    actionsBottom = actionsTop;
  }
  if(steps.length === 0) {
    noSteps = (
      <Alert key="nosteps" intent="info" title="Keine Schritte vorhanden" size="medium">
        Es sind noch Schritte vorhanden. Füge einen hinzu, verwende eine Vorlage, oder übernimm die Schritte einer bestehenden Überraschung.     
      </Alert>
    )
    actionsBottom = null;
  }
  
  return(
    <StepsProvider>
      <Card id="v-surprise-steps" title="Schritte" icon={Icons.Steps} expanded={expanded} collapsible={true} summaryContent={`${steps.length === 0 ? "noch keine" : steps.length} ${steps.length === 1 ? 'Schritt' : 'Schritte'} definiert`}>
        {actionsTop}
        {steps}
        {noSteps}
        {actionsBottom}
      </Card>
    </StepsProvider>
  )
}

type MoveActionsProps = {
  save: () => void,
  cancel: () => void,
}
function MoveActions({save, cancel} : MoveActionsProps) {
  return (
    <div className="move-actions">
      <Button intent="cancel" onClick={cancel}>abbrechen</Button>
      <Button onClick={save}>speichern</Button>
    </div>
  )
}

type StandardActionsProps = {
  makeMovable: any,
  insertSteps: any,
}
function StandardActions({makeMovable, insertSteps} : StandardActionsProps) {
  // context
  const SC = useContext(SurpriseContext);

  // state
  const [modalAction, setModalAction] = useState<"none"|"copyFromAdventure"|"alert">("none");
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modalParams, setModalParams] = useState<any>();


  // shows surprise selection dialog
  const selectSurprise = () => {
    setModalTitle("Schritte aus Überraschung übernehmen");
    setModalAction("copyFromAdventure");
    //MC.push("Schritte aus Überraschung übernehmen", <SelectSurprise closeModal={closeModal} />)
  }

  // create from outline
  const createFromOutline = async() => {
    // steps from outline
    const stepsResult = await StepsLogic.getStepsFromOutline(SC.surprise);
    if(stepsResult.warnings.length > 0) {
      setModalAction("alert");
      setModalTitle("Achtung");
      setModalParams({
        title:"Fehlende Daten",
        intent: "warning",
        content: stepsResult.warnings.join("\n")
      })
      // MC.push("Achtung", <Alert title="Fehlende Daten" intent="warning" size="medium">{stepsResult.warnings.join("\n")}</Alert>);
    }
    if(stepsResult.errors.length > 0) {
      setModalAction("alert");
      setModalTitle("Fehler");
      setModalParams({
        title:"Fehler beim Erzeugen der Schritte", 
        intent:"error", 
        content: stepsResult.errors.join("\n")
      });
      //MC.push("Fehler", <Alert title="Fehler beim Erzeugen der Schritte" intent="error" size="medium">{}</Alert>);
    }
    
    // add existing steps to created steps
    (SC.surprise.Steps || []).forEach((step:any) => stepsResult.steps.push(step));
    // update
    SC.updateAtPath("Steps", stepsResult.steps, true);
    //SC.setSteps(steps);
  }

  // deletes all steps
  const deleteSteps = async() => {
    const steps:any[] = [];
    SC.updateAtPath("Steps", steps, true);
  }

  // closes modal dialog
  const closeModal = () => setModalAction("none");

  // pre render modal
  let modalContent = null;
  switch(modalAction) {
    case "copyFromAdventure":
      modalContent = <SelectSurprise closeModal={closeModal} />;
      break;
    case "alert":
      modalContent = <Alert title={modalParams.title} intent={modalParams.intent} size="medium">{modalParams.content}</Alert>
      break;
  }

  // render
  return <>
    <div className="standard-actions">
      <Row align="center">
        <Button size="small" onClick={createFromOutline}>
          <Icon icon={Icons.Magic} />&nbsp;aus Planungsassistent erzeugen
        </Button>
        <Button size="small" onClick={selectSurprise}>
          <Icon icon={Icons.Clone} />&nbsp;aus Überraschung übernehmen
        </Button>
      </Row>
      <Button size="small" onClick={makeMovable}>
        <Icon icon={Icons.ArrowsAltV} />&nbsp;Reihenfolge ändern
      </Button>
      <ConfirmButton onConfirm={deleteSteps}><Icon icon={Icons.Trash} />&nbsp;Alle Schritte löschen</ConfirmButton>
      <AddStep index={0} insertSteps={insertSteps} />
    </div>

    <Modal isOpen={modalAction!=="none"} title={modalTitle} onClose={closeModal}>
      {modalContent}
    </Modal>
  </>
}

type SelectSurpriseProps = {
  closeModal: () => void,
}
function SelectSurprise({closeModal} : SelectSurpriseProps) {
  // context
  const SC = useContext(SurpriseContext);
  // state
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  // searches adventures
  const search = async() => {
    if(searchText.trim().length < 4) {
      setSearchResult([]);
    }
    else {
      let dbresult = await TS.AdventureOld.searchByPartialId(searchText, {RecieverName:1, ReserverName:1, Steps:1})
      let result = dbresult.map((s:any) => {
        return { _id:s._id, label:`${s.RecieverName} von ${s.ReserverName}`, Steps:s.Steps}
      })
      setSearchResult(result);
    }
  }

  // copies from adventure
  const copyFromAdventure = async(adventure:any, position:any) => {
    // TODO move this to logic or context
    // get steps from adventure
    let adventureSteps = (adventure.Steps || [])
      .map((step:any) => {
        let stepToAdd = _.cloneDeep(step)
        delete stepToAdd._id
        stepToAdd.TickerSent = false
        stepToAdd.SentAlert = false
        stepToAdd.AlertDate = null
        stepToAdd.AvailableDate = null
        stepToAdd.ReservationCode = null
        stepToAdd.Attachments = []
        stepToAdd.Links = []
        return stepToAdd
      })
    // concat with existing steps
    let updatedSteps = [];
    if(position === "start") {
      updatedSteps = (adventureSteps || []).concat(SC.surprise.Steps || []);
    }
    else {
      updatedSteps = (SC.surprise.Steps || []).concat(adventureSteps || []);
    }
    // save 
    await SC.updateAtPath("Steps", updatedSteps, true);
    // close dialog
    closeModal();
  }

  // collect rows to render
  const rows = searchResult.map((item:any) => {
    return <tr key={item._id}>
      <td>{item.label}</td>
      <td>
        <Button size="small" onClick={() => copyFromAdventure(item, "start")}>
          oben einfügen <Icon icon={Icons.ArrowUp}/>
        </Button>
      </td>
      <td>
        <Button size="small" onClick={() => copyFromAdventure(item, "end")}>
          am Ende einfügen<Icon icon={Icons.ArrowDown} />
        </Button>
      </td>
    </tr>
  });
  if(rows.length === 0) {
    rows.push(<tr><td>keine Überraschung gefunden</td></tr>);
  }
  // render
  return (
    <div className="c-surprise-step-selectadventure">
      <div className="search-text">
        <input type="text" value={searchText} onKeyPress={e => e.which === 13 ? search() : null} onChange={e => setSearchText(e.target.value)} placeholder="mindestens 4 Zeichen einer Überraschungs-Id eingeben" />
        <Button onClick={search}><Icon icon={Icons.Search} /></Button>
      </div>
      <div className="search-result">
        <table><tbody>{rows}</tbody></table>
      </div>
      <Row align="center">
        <Button intent="cancel" onClick={closeModal}>abbrechen</Button>
      </Row>
    </div>
  )
}
