import React, { useState, useEffect } from "react";
import Api from '../../../../util/api';
import Util from '../../../../util/util';
import * as TS from "../../../../types";
import * as DA from "../../../../types/DataAccess";
import * as BL from "../../../../types/BusinessLogic";

// context & components
import AssistantLogic from "./Assistant.Logic"
import { Button, Loader, Icon, Icons, Table, Tr, Td, TextInput, TextArea, NumberInput, Validators, Validation, Row } from "../../../controls"

// css
import CSS from "./BookingResponse.module.scss"

// component
type BookingResponseProps = {
  requestId: any,
  onDone: any,
  closeDialog: () => void,
}
export default function BookingResponse({requestId, onDone, closeDialog} : BookingResponseProps) {
  // state
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [request, setRequest] = useState<DA.BookingRequest|null>(null)
  const [displayEmail, setDisplayEmail] = useState(false)
  const [contact, setContact] = useState("")
  const [comment, setComment] = useState("")
  const [accepted, setAccepted] = useState<any>(null);
  const [collectiveInvoiceValue, setCollectiveInvoiceValue] = useState<number>(0);
  
  // mount
  useEffect(() => {
    loadRequest(requestId)
  }, [requestId])

  // loads request
  const loadRequest = async(requestId:string) => {
    setIsLoading(true)
    // get request from db
    const request = await DA.BookingRequestRepository.findById(requestId);
    if(request) {
      // prepare state values
      let comment = "";
      let contact = "";
      let accepted = null;
      let collectiveInvoiceValue = 0;
      let isValid = true;
      if(request.response) {
        comment = request.response.comment || "";
        contact = request.response.contact || "";
        accepted = request.response.accepted
      }
      if(request.collectiveInvoice) {
        collectiveInvoiceValue = request.collectiveInvoice.amount;
        isValid = collectiveInvoiceValue > 0;
      }
      // update the state
      setRequest(request);
      setComment(comment);
      setContact(contact);
      setAccepted(accepted);
      setCollectiveInvoiceValue(collectiveInvoiceValue);
      setIsValid(isValid);
      setIsLoading(false);
    }
  }

  // saves request
  const saveRequest = async() => {
    setIsLoading(true);
    
    // save the response (the api route we use here also creates a log entry in the adventure. Should refactors this all some point to save the response and changes to collectiveInvoice value at once)
    const currentUser = await BL.User.getCurrent();
    let data = {
      requestId:requestId,
      response: {
        operator:currentUser.email, 
        comment, 
        contact, 
        accepted
      }
    }
    // TODO use DataAccess instead of old Api
    await Api.post(`bookingrequests/response`, data);

    // save changes to collective invoice value
    // TODO this should not be possible if there is a collective invoice with this booking request in it
    // TODO this should use BL instead of being coded in here
    if(request!.collectiveInvoice) {
      if(request!.collectiveInvoice.amount !== collectiveInvoiceValue) {
        const text = request!.collectiveInvoice.text;
        const collectiveInvoice_id = request!.collectiveInvoice.collectiveInvoice_id
        const changeset: Partial<DA.BookingRequest> = {collectiveInvoice:{amount:collectiveInvoiceValue, text, collectiveInvoice_id}};
        await DA.BookingRequestRepository.update(request!._id!, changeset);
      }
    }
    
    // inform host (will close this dialog)
    closeDialog();
    onDone();
  }

  // render
  let content
  if(isLoading || request === null) {
    content = <Loader text="lade ..."/>
  }
  else {
    const services = request.services.map((s:any, index:number) => {
      return (
        <div key={index}>- {s.key}: {s.value}</div>
      )
    })

    let tr_email = null
    if(request.request.kind === 'email') {
      if(displayEmail) {
        tr_email = (
          <Tr>
            <Td label>Versendetes E-Mail</Td>
            <Td>
              <Table className={CSS.email}>
                <tbody>
                <Tr>
                  <Td colSpan={2}>
                    <Button size="small" inverted onClick={() => setDisplayEmail(false)}>E-Mail ausblenden ...</Button>
                  </Td>
                </Tr>
                <Tr>
                  <Td>To:</Td>
                  <Td>{request.request.email!.to}</Td>
                </Tr>
                <Tr>
                  <Td>From:</Td>
                  <Td>{request.request.email!.from}</Td>
                </Tr>
                <Tr>
                  <Td>Subject:</Td>
                  <Td>{request.request.email!.subject}</Td>
                </Tr>
                <Tr>
                  <Td>Body:</Td>
                  <Td><div dangerouslySetInnerHTML={{__html:request.request.email!.body}} /></Td>
                </Tr>
                </tbody>
              </Table>
            </Td>
          </Tr>
        )
      }
      else {
        tr_email = (
          <Tr>
            <Td label>Versendetes E-Mail</Td>
            <Td>
              <Button size="small" inverted onClick={() => setDisplayEmail(true)}>E-Mail anzeigen ...</Button>
            </Td>
          </Tr>
        )
      }
    }

    // collective invoice?
    let trCollectiveInvoice = null;
    if(request.collectiveInvoice) {
      trCollectiveInvoice = (
        <Tr>
          <Td label>Betrag Monatsabrechnung</Td>
          <Td>
            <NumberInput 
              value={collectiveInvoiceValue} 
              validate={Validators.isPrice("bitte Betrag eingeben")} 
              onChange={(v:number, validation:Validation) => {
                setCollectiveInvoiceValue(v);
                setIsValid(validation.valid);
              }} 
            />
          </Td>
        </Tr>
      )
    }

    // assemble content
    content = <>
      <div className={CSS.info}>
        <Table>
          <Tr>
            <Td label>Art der Anfrage</Td>
            <Td>{AssistantLogic.getBookingKindTitle(request.request.kind)}</Td>
          </Tr>
          <Tr>
            <Td label>Datum der Anfrage</Td>
            <Td>{Util.printDateAndTime(request.request.createdOn)}</Td>
          </Tr>
          <Tr>
            <Td label>Aktivität</Td>
            <Td>{request.activity.name}</Td>
          </Tr>
          <Tr>
            <Td label>Region</Td>
            <Td>{request.activity.regionCode || 'unbekannt'}</Td>
          </Tr>
          <Tr>
            <Td label>Anbieter</Td>
            <Td>{request.provider.name}</Td>
          </Tr>
          <Tr>
            <Td label>Angefragt durch</Td>
            <Td>{request.request.createdBy}</Td>
          </Tr>
          <Tr>
            <Td label>Zusatztext</Td>
            <Td>{request.request.comment || ""}</Td>
          </Tr>
          <Tr>
            <Td label>Angefragte Leistungen</Td>
            <Td>
              {services}
            </Td>
          </Tr>
          {tr_email}
        </Table>
      </div>
      <div className={CSS.form}>
        <Table>
          <Tr>
            <Td label>Kontaktperson</Td>
            <Td>
              <TextInput value={contact} onChange={s => setContact(s)} />
            </Td>
          </Tr>
          <Tr>
            <Td label>Kommentar</Td>
            <Td>
              <TextArea value={comment} onChange={s => setComment(s)} />
            </Td>
          </Tr> 
          {trCollectiveInvoice}
          <Tr>
            <Td label>Status</Td>
            <Td>
              <div className={CSS.accepted_declined}>
                <div onClick={() => setAccepted(true)} className={`${CSS.accepted} ${accepted === true ? CSS.active : CSS.inactive}`}>
                  <Icon icon={Icons.ThumbsUp} />
                  <span>akzeptiert</span>
                </div>
                <div onClick={() => setAccepted(false)} className={`${CSS.declined}  ${accepted === false ? CSS.active : CSS.inactive}`}>
                  <Icon icon={Icons.ThumbsDown} />
                  <span>abgelehnt</span>
                </div>
              </div>
            </Td>
          </Tr>
        </Table>
      </div>
      <Row align="center">
        <Button onClick={closeDialog} intent="cancel">abbrechen</Button>
        <Button onClick={() => saveRequest()} disabled={accepted === null && isValid === false}>speichern</Button>
      </Row>
    </>
  }
  return (
    <div className={CSS.container}>
      {content}
    </div>
  )
}

