import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Dropdown, DropdownOption, DateInput, NumberInput, Label, Row } from "../../controls";
import { VendorInvoicesContext } from "./VendorInvoices.Context";

type VendorInvoicesPostfilterProps = {
  
}
export default function VendorInvoicesPostfilter({} : VendorInvoicesPostfilterProps) {
  // context
  const VIC = useContext(VendorInvoicesContext);

  // state
  const [providerOptions, setProviderOptions] = useState<Array<DropdownOption>>([]);
  //const [selectedProviderId, setSelectedProviderId] = useState<string|null>(null);

  // mount
  useEffect(() => {
    setProviderOptions([
      {label:"alle", value:""},
    ]);
  }, []);
  // dataset changed
  useEffect(() => {
    if(VIC.data.invoices) {
      let providerOptions = _.uniqBy(VIC.data.invoices, "provider._id")
        .map(i => i.provider)
        .sort((a,b) => a.name.localeCompare(b.name))
        .map(p => ({label:p.name, value:p._id}));
        
      setProviderOptions([
        {label:"alle", value:""},
        ...providerOptions
      ]);
    }
  }, [VIC.data.invoices]);

  // user selected a provider
  const onSelectProvider = (e:string) => {
    let providerId = e !== "" ? e : null;
    VIC.setPostfilter({...VIC.postfilter, providerId});
  }

  // render loading
  if(VIC.status === "loading") {
    return null;
  }

  // render
  return (
    <div>
      <Dropdown label="Anbieter" options={providerOptions} onChange={onSelectProvider} value={VIC.postfilter.providerId || ""} />
      <div>
        <Label label="Betrag" />
        <Row>
          <Dropdown 
            options={[
              {label:"alle", value:""},
              {label:"größer als", value:"gt"},
              {label:"kleiner als", value:"lt"},
              {label:"gleich", value:"eq"},
            ]}
            onChange={(e) => VIC.setPostfilter({...VIC.postfilter, amountCompare:e as "gt"|"lt"|"eq"|""})}
            value={VIC.postfilter.amountCompare}
          />
          &nbsp;
          <NumberInput 
            value={VIC.postfilter.amount} 
            onChange={(e) => VIC.setPostfilter({...VIC.postfilter, amount:e})} 
            disabled={VIC.postfilter.amountCompare === ""}
          />
        </Row>
      </div>
      <div>
        <Label label="Datum" />
        <Row>
          <Dropdown 
            options={[
              {label:"-", value:""},
              {label:"Belegdatum", value:"invoice"},
              {label:"Fälligkeitsdatum", value:"due"},
              {label:"Zahlungsdatum", value:"payment"},
            ]}
            value={VIC.postfilter.dateType}
            onChange={(e) => VIC.setPostfilter({...VIC.postfilter, dateType:e as "invoice"|"due"|"payment"|""})}
          />
          <Dropdown 
            options={[
              {label:"vor dem", value:"before"},
              {label:"nach dem", value:"after"},
              {label:"am", value:"on"},
            ]}
            value={VIC.postfilter.dateCompare}
            onChange={(e) => VIC.setPostfilter({...VIC.postfilter, dateCompare:e as "before"|"after"|"on"})}
            disabled={VIC.postfilter.dateType === ""}
          />
          <DateInput 
            value={VIC.postfilter.dateDate}
            onChange={(e) => VIC.setPostfilter({...VIC.postfilter, dateDate:e as Date})}
            disabled={VIC.postfilter.dateType === ""}
          />
        </Row>
      </div>
      
    </div>
  )

}