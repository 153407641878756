import React, { useRef, useEffect, useState } from "react";
import Util from "../../../util/util";
import { Button, ClipboardButton, Row, Table, Td, Tr } from "../../controls";
import CSS from "./Mailfail.Details.module.scss";

type MailfailDetailsProps = {
  item:any,
  closeDialog: VoidFunction
}
export default function MailfailDetails({item, closeDialog}:MailfailDetailsProps) {
  // state
  const [bodyElement, setBodyElement] = useState(null);
  // ref
  const refBodyContainer = useRef<any>();

  // body container ref changes
  useEffect(() => {
    setBodyElement(refBodyContainer.current);
  }, [refBodyContainer.current])

  return (   
    <div className={CSS.container}>
      <Table>
        <Tr>
          <Td label align="right">Datum</Td>
          <Td>{Util.printDateAndTime(item.createdAt)}</Td>
          <Td></Td>
        </Tr>
        <Tr>
          <Td label align="right">Empfänger</Td>
          <Td>{item.mail.to}</Td>
          <Td><ClipboardButton text={item.mail.to} size="small" /></Td>
        </Tr>
        <Tr>
          <Td label align="right">Betreff</Td>
          <Td>{item.mail.subject}</Td>
          <Td><ClipboardButton text={item.mail.subject} size="small" /></Td>
        </Tr>
        <Tr>
          <Td label align="right">Inhalt</Td>
          <Td>
            <div dangerouslySetInnerHTML={{__html:item.mail.html}} ref={refBodyContainer}></div>
          </Td>
          <Td><ClipboardButton element={bodyElement} text={item.mail.html} size="small" /></Td>
        </Tr>
      </Table>
      <Row align="center">
        <Button onClick={closeDialog}>Dialog schliessen</Button>
      </Row>
    </div>
  )
}