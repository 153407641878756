import { CharityMapper } from "./CharityMapper";
import { Charity } from "./Charity";
import { RepositoryFactory } from "../_Common/RepositoryFactory";


export class CharityRepository {
  private static mapper = new CharityMapper();
  
  static make = RepositoryFactory.make<Charity, CharityMapper>(this.mapper);
  static search = RepositoryFactory.search<Charity, CharityMapper>(this.mapper);
  static create = RepositoryFactory.create<Charity, CharityMapper>(this.mapper);
  static update = RepositoryFactory.update<Charity, CharityMapper>(this.mapper);
}

