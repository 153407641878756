import * as Api from "../../util/ApiTS";
import ApiOld from "../../util/api";
import * as _ from "lodash";
import { AdventureStatus } from "./AdventureStatus";
import { PAYMENTSOURCES } from "../../config/constants";

// TODO eliminate all uses of this class
export class AdventureOld {
  // TODO this is just a hack ... replace with proper logic
    static async load_new(id:string, projection:any): Promise<any> {
      const apiResult = await Api.post("adventures", "search", {filter:{_id:id}, projection:projection || {}});
      if(apiResult.success) {
        if(apiResult.data.items.length === 0) {
          return {success:false, error:{message:"Keine Überraschung mit dieser Id gefunden"}}
        }
        else {
          const surprise = apiResult.data.items[0]; 
          // enrich the data
          surprise.__id4 = surprise._id.substring(surprise._id.length - 4);
          // return data
          return {success:true, surprise};
        }
      }
      else {
        return apiResult;
      }
    }
  
    static async loadValueAtPath(surpriseId:string, path:string): Promise<any> {
      try {
        //const projection = _.set({}, path, 1)
        const projection:any = {};
        projection[path] = 1;
        const result = await AdventureOld.load_new(surpriseId, projection);
        if(result.success) {
          return _.get(result.surprise, path);
        }
        else {
          throw new Error(result.error.message);
        }
      }
      catch(error) {
        throw error;
      }
    }
  
    static async load(surprise_id:string): Promise<any> {
      let surprise = await ApiOld.get('adventures', surprise_id)
      // get short url if none is set
      if(!surprise.ShortUrl) {
        let surl = await ApiOld.get(`adventures/shorturl/${surprise_id}`)
        surprise.ShortUrl = surl.shortUrl
      }
      return surprise
    }
  
  
    static async needsActivitiesFix(adventure:any): Promise<boolean> {
      const result = await AdventureOld.getActivityFixingInfo(adventure);
      return result.activitiesThatNeedFixing.length > 0
    }
  
    static async getActivityFixingInfo(adventure:any): Promise<any> {
      let filter, projection, apiResult
      
      // get related activities
      const activityIds = adventure.Activities.map((a:any) => a.ActivityId);
      filter = {_id:{$in:activityIds}};
      projection = {};
      apiResult = await Api.post("activities", "search", {filter, projection});
      const activities = apiResult.data.items
    
      // gather all activities that need fixing
      const activitiesThatNeedFixing:any[] = []
      activities.forEach((activity:any) => {
        const subactivities = activity.subactivities || [];
        const adventureActivity = adventure.Activities.find((a:any) => a.ActivityId === activity._id);
        const adventureSubactivities = adventureActivity.Subactivities || [];
        // if the activity has subactivities, but the adventure has not, we can assume that we are missing data
        if(subactivities.length > 0) {
          if(adventureSubactivities.length === 0) {
            activitiesThatNeedFixing.push(activity)
          }
        } 
      })
    
      // gather all relevant subactivities
      const subactivityIds = activitiesThatNeedFixing.reduce((prev, curr) => {
        const ids = curr.subactivities.map((sa:any) => sa.activity_id)
        return prev.concat(ids);
      }, [])
      filter = {_id:{$in:subactivityIds}};
      projection = {};
      apiResult = await Api.post("activities", "search", {filter, projection});
      const subactivities = apiResult.data.items
    
      // add subactivities to activities
      activitiesThatNeedFixing.forEach(activity => {
        const sa_ids = activity.subactivities.map((sa:any) => sa.activity_id);
        activity.__subactivities = subactivities.filter((sa:any) => sa_ids.includes(sa._id));
      })
    
      // done
      return {adventure, activitiesThatNeedFixing}
    }
  
    /**
     * Adventure that were sold offline have no given region. Without a region, planning cannot be done. Moreover, we cannot simply assign a region, since it depends on where there receiver wants the adventure to take place
     */
    static async needsSoldOfflineFix(adventure:any): Promise<boolean> {
      if(adventure.IsSoldOffline) {
        const activitiesWithoutRegion = adventure.Activities.filter((a:any) => _.isNil(a.RegionCode) || _.isNil(a.RegionVariantId));
        return activitiesWithoutRegion.length > 0;
      }
      return false;
    }
  
    static async fixSoldOffline(adventure:any, regionCode:any): Promise<void> {
      const activities = await AdventureOld.getActivities(adventure);
      adventure.Activities.forEach((adventureActivity:any) => {
        const activity = activities.find((a:any) => String(a._id) === String(adventureActivity.ActivityId));
        if(activity) {
          const regionVariant = activity.regionVariants.find((rv:any) => rv.regionCode === regionCode);
          if(regionVariant) {
            adventureActivity.RegionCode = regionCode;
            adventureActivity.RegionVariantId = regionVariant._id;
          }
        }
      });
      const payload = {id:adventure._id, set:{Activities:adventure.Activities, Price:_.get(adventure, "SoldOfflineInfo.Price")}};
      await Api.post("adventures", "update", payload);
    }
  
    static async getActivities(adventure:any): Promise<any> {
      const activityIds = adventure.Activities.map((a:any) => a.ActivityId);
      const result = await Api.post("activities", "search", {filter:{_id:{$in:activityIds}}}); 
      return result.data.items;
    }
  
    static async searchByPartialId(partialId:string, projection:any): Promise<any> {
      let filter = {_id_string:{"$regex":`${partialId.trim()}$`, "$options":"i"}}
      let result =  await ApiOld.post('adventures/search', {filter, projection})
      return result.data || [];
    }
  
    static async fixActivities(adventure:any, itemsToExclude:any): Promise<void> {
      const fixingInfo:any = await AdventureOld.getActivityFixingInfo(adventure);
      const payloads:any[] = [];
      fixingInfo.activitiesThatNeedFixing.forEach((activity:any) => {
        const advActivity = adventure.Activities.find((a:any) => a.ActivityId === activity._id);
        const subactivities = activity.__subactivities
        .filter((sa:any) => {
          const exclude = itemsToExclude.some((item:any) => String(item.activityId) === String(activity._id) && String(item.subactivityId) === String(sa._id));
          return !exclude
        })
        .map((sa:any) => {
          // get the price
          let price = 0;
          const regionVariant = (sa.regionVariants || []).find((r:any) => r.regionCode === advActivity.RegionCode);
          if(regionVariant) {
            const priceConfiguration = (regionVariant.priceConfigurations || []).find((pc:any) => pc.participantCount === advActivity.Configuration.ParticipantCount);
            if(priceConfiguration) {
              price = priceConfiguration.price;
            }
          }
          // create the payload value
          return {
            ActivityId:sa._id,
            Title:sa.title.de,
            ParticipantCount:advActivity.Configuration.ParticipantCount,
            Price:price,
            IsOption:sa.isOptionalSubactivity ? true : false,
            IsOptionReplacement:sa.isOptionalSubactivity ? true : false // was used to replace an option
          }
        })
        const payload:any = {adventureId:adventure._id, activityId:activity._id, subactivities:subactivities};
        payloads.push(payload)
      })
  
      // execute patches
      for(let i=0; i<payloads.length; i+=1) {
        const payload:any = payloads[i];
        await Api.post("adventures", "setSubactivities", payload);
      }
    }


  /**
   * Returns all adventures that are currently alive. I.e. they are somewhere between being activated by the donee and being ready.
   */
  static async getLiving(projection:any): Promise<any> {
    try {
      const stati = [AdventureStatus.DateSelected, AdventureStatus.ConditionsSent, AdventureStatus.ConditionsAccepted, AdventureStatus.Ready];
      const filter = {Status:{"$in":stati}};
      const payload = {filter, projection: projection || {}};
      const result = await Api.post("adventures", "search", payload);
      if(result.success) {
        return result.data.items;
      }
      else {
        throw result.error;
      }
    }
    catch(error) {
      throw error;
    }
  }

  /**
   * Returns all adventures that have one of the ids passed.
   */
  static async getWithIds(ids:string[], projection:any): Promise<any> {
    try {
      const filter = {_id:{"$in":ids}};
      const payload = {filter, projection: projection || {}};
      const result = await Api.post("adventures", "search", payload);
      if(result.success) {
        return result.data.items;
      }
      else {
        throw result.error;
      }
    }
    catch(error) {
      throw error;
    }
  }

  static async getConditionsFromActivities(surprise:any): Promise<any> {
    // get related activities
    const activityIds = surprise.Activities.map((a:any) => a.ActivityId);
    const activitiesResult = await Api.post("activities", "search", {filter:{_id:{$in:activityIds}}, projection:{}});
    const activities = activitiesResult.data.items;
  
    // we want them grouped by type, hence we create three arrays which we concat at the end
    let conditions:any[] = [];
    let weatherConditions:any[] = [];
    let equipment:any[] = [];
    (surprise.Activities || []).forEach((adventureActivity:any) => {
      const activity = activities.find((item:any) => String(item._id) === adventureActivity.ActivityId);
      if(activity) {
        const regionVariant = activity.regionVariants.find((item:any) => item._id === String(adventureActivity.RegionVariantId));
        if(regionVariant) {
          const activityConditions = (regionVariant.conditions || []).map((c:any) => _.get(c, "title.de")).filter((c:any) => c !== undefined);
          const activityWeatherConditions = (regionVariant.weatherConditions || []).map((c:any) => _.get(c, "title.de")).filter((c:any) => c !== undefined);
          const activityEquipment = (regionVariant.equipment || []).map((e:any) => _.get(e, "title.de")).filter((eq:any) => eq !== undefined);
  
          conditions = conditions.concat(activityConditions);
          weatherConditions = weatherConditions.concat(activityWeatherConditions);
          equipment = equipment.concat(activityEquipment);
        }
      }
    })

    // concat and make sure we have unique items
    const all: any[] = conditions.concat(weatherConditions).concat(equipment); 
    const conditionsFromActivities:any = _.uniq(all);
    return conditionsFromActivities;
  }

  // TODO replace all calls to this with Adventure.addLogEntry()
  static async upsertLog(adventureId:string, entry:any): Promise<any> {
    const payload = {adventureId, entry};
    const apiResult = await Api.post("adventures", "upsertLog", payload);
    return apiResult;
  }

  /**
   * Gets Buddy Upsells
   * @param {*} adventure 
   */
  static getBuddyUpsells(adventure:any): any {
    return (adventure.Payments || []).filter((payment:any) => payment.source === PAYMENTSOURCES.BUDDY_UPSELL);
  }


  static async loadCustomerHistory(customerEmail:any, excludeAdventureId:any): Promise<any> {
    customerEmail = customerEmail.trim().toLowerCase();
    
    const filter = {$or:[
      {"ReserverEmail":{"$regex":`${customerEmail}`, "$options":"i"}},
      {"RecieverEmail":{"$regex":`${customerEmail}`, "$options":"i"}},
    ]}
    const all = await AdventureOld.search(filter, {});
    // filter out useless ones (archived, etc)
    const relevant = all
    .filter((s:any) => {
      if(excludeAdventureId) {
        return String(s._id) !== excludeAdventureId;
      }
      return true;
    })
    .filter((s:any) => {
      return [AdventureStatus.Archived, AdventureStatus.Locked, AdventureStatus.OrderCancelled].includes(s.Status) === false
    })
    // group by received, reserved
    const receivedAdventures = relevant.filter((s:any) => (s.RecieverEmail || "").trim().toLowerCase() === customerEmail);
    const reservedAdventures = relevant.filter((s:any) => (s.ReserverEmail || "").trim().toLowerCase() === customerEmail);

    // collect activities
    const activitiesFromAdventures = (adventures:any) => {
      const activities:any[] = [];
      adventures.forEach((adv:any) => {
        (adv.Activities || []).forEach((a:any) => {
          let item = activities.find(item => item.title === (a.Title || a.ActivityId));
          if(!item) {
            item = {
              title: a.Title || a.ActivityId,
              id: a.ActivityId,
              adventureIds: []
            }
            activities.push(item);
          }
          item.adventureIds.push(adv._id);
        })
      })
      return activities;
    }
    const receivedActivities = activitiesFromAdventures(receivedAdventures);
    const reservedActivities = activitiesFromAdventures(reservedAdventures);

    return {
      adventures: {
        received: receivedAdventures, 
        reserved: reservedAdventures
      },
      activities: {
        received: receivedActivities,
        reserved: reservedActivities
      }
    }

  }

  static async search(filter:any, projection:any): Promise<any> {
    const apiResult = await Api.post("adventures", "search", {filter, projection});
    if(apiResult.success) {
      return apiResult.data.items;
    }
    else {
      console.error(apiResult.error);
      return [];
    }
  }


  /**
   * Returns updated aventure
   * @param {*} adventure 
   * 
   */
  static async resetMeteochecks(adventure:any): Promise<any> {
    const meteochecks = adventure.Meteochecks || [];
    for(let meteocheck of meteochecks) {
      if(meteocheck.passed) {
        const activity = adventure.Activities.find((a:any) => String(a.ActivityId) === String(meteocheck.activityId));
        const payload = {
          adventureId: adventure._id,
          activityId: meteocheck.activityId,
          activityTitle: activity ? activity.Title : "unbekannt",
          providerId: meteocheck.providerId,
          notes: "manuell zurückgesetzt (entweder explizit oder durch Statusänderung)",
          passed: false
        }
        await Api.post("meteocheck", "setMeteoCheck", payload);
      }
      
    }
    // get and return the updated adventure
    const updatedAdventure = await AdventureOld.findOne({_id:adventure._id}, {});   
    return updatedAdventure;

  }

  static async findOne(filter:any, projection:any): Promise<any> {
    const apiResult = await Api.post("adventures", "search", {filter, projection});
    if(apiResult.success) {
      return apiResult.data.items[0];
    }
    else {
      console.error(apiResult.error);
      return null;
    }
  }


  static async updatePaymentReminderLevel(adventureId:string, level:any): Promise<void> {
    // TODO why are we not returning {success:true/false, error:error/undefined}?
    try {
      await ApiOld.putSimple(`adventures/dunning/${adventureId}/${level}`);
    }
    catch(error) {
      console.error(error);
      throw error;
    }
  }

  /**
   * Updates the status using server-side logic.
   * In some cases (depending on server-side implementation) this has side-effects like sending e-mails
   */
  static async updateStatusServerSide(adventureId:string, nextStatus:any, logEntry:any): Promise<any> {
    try {
      await ApiOld.putSimple(`adventures/status/${adventureId}/${nextStatus}`)
      // log?
      if(logEntry) {
        await AdventureOld.upsertLog(adventureId, logEntry);
      }
      return {success:true}
    }
    catch(error) {
      console.error(error);
      return {success:false, error};
    }
  }

  // TODO this should really only take the surprise._id, not the surprise object
  static async update(surprise:any, changeset:any): Promise<any> {
    const payload = {id:surprise._id, set:changeset};
    const apiResult = await Api.post("adventures", "update", payload);
    return apiResult;
  } 
}