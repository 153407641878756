import { encode } from "blurhash";

export class BlurhashCreator {
   
  private static async loadImage(path:string) {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.crossOrigin = 'Anonymous' // to avoid CORS if used with Canvas
      img.src = path
      img.onload = () => {
        resolve(img)
      }
      img.onerror = e => {
        reject(e)
      }
    })
  }
  
  private static getImageData(image:any) : any {
    const canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext("2d");
    context!.drawImage(image, 0, 0);
    return context!.getImageData(0, 0, image.width, image.height);
  }
  
  static async create(imageUrl:string) : Promise<string> {
    let image: any;
    try {
      image = await this.loadImage(imageUrl);
    }
    catch(err) {
      console.error("ERROR", err);
    }
    const imageData = this.getImageData(image);
    return encode(imageData.data, imageData.width, imageData.height, 4, 4);
  }
}

 
