import environment  from './environment'

const config = {
  development: {
    ApiRoot: 'http://localhost:8090',
    LoginCallbackUrl: {
      Google: 'http://localhost:3000/login/callback/google'
    }
  },
  staging: {
    ApiRoot: 'https://api-staging.appentura.io', //'https://appentura-api-staging.herokuapp.com',
    LoginCallbackUrl: {
      Google: 'https://admin-staging.appentura.ch/login/callback/google'
    }
  },
  production: {
    ApiRoot: 'https://api.appentura.io', // 'https://appentura-api.herokuapp.com',
    LoginCallbackUrl: {
      Google: 'https://admin.appentura.ch/login/callback/google'
    }
  }
}

const actual_config = config[environment]

export {actual_config as default}