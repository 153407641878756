import React, { useEffect, useState } from "react";

import * as TS from "../../../types";
// components
import { Link, Stars} from "../../controls";
import DashboardPanel from "./DashboardPanel"
// styling & images
import CSS from "./LatestRating.module.scss";
import iconStar from './../../../assets/images/icon_star.svg';
import _ from "lodash";

export default function LatestRating() {
  const [adventure, setAdventure] = useState<TS.Adventure|null>(null);

  // mount  
  useEffect(() => {
    const load = async() => {
      try {
        const lastWeek: Date = new Date();
        lastWeek.setDate(lastWeek.getDate() - 7);
        const adventureFilter = {
          $and: [
            { Status: TS.AdventureStatus.Reviewed },
            { "Rating.Rating": 5},
            { StartTime: { $gt: lastWeek } }
          ]
        }
        const adventures:Array<TS.Adventure> = await TS.Adventure.search(adventureFilter);
        const adventuresFiltered = adventures.filter(a => a.Rating!.Comment.trim().length > 0);
        const adventure = _.sample(adventuresFiltered);
        if(adventure) {
          setAdventure(adventure);
        }
      }
      catch(err) {
        console.error(err);
      }
    }
    load();
  }, []);

  
  // render null if nothing found
  if(!adventure) {
    return null;
  }

  // pre-render activities
  const activityNames = adventure.Activities.map(activity => activity.Title).join(', ').trim();
  const spanActivities = activityNames.length > 0 ? <span className={CSS.activities}> ({activityNames})</span> : null;
  // render
  return (
    <DashboardPanel icon={iconStar} iconRounded={false} smallOnDesktop={false} smallOnMobile={false}>
      <div className={CSS.container}>
        <div className={CSS.inner}>
          <div className={CSS.quote}>
            <Stars max={5} value={adventure.Rating!.Rating} />
            <div className={CSS.quote_text}>
              {adventure.Rating!.Comment}
            </div>
          </div>
          <div className={CSS.name_and_activities}>
            <Link to={`/surprise/${adventure._id}`} target="_blank">
              {adventure.RecieverName!}{spanActivities}
            </Link>
          </div>
        </div>
      </div>
    </DashboardPanel>
  )
}