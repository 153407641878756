import React, { useContext } from "react";
import { Checkbox } from "../../../../controls";
import { SearchContext } from "../Search.Context";
import FilterGroup from "../Search.FilterGroup";

export default function Misc() {
  // context
  const Search = useContext(SearchContext);

  // onChange
  const onChange = (property:"onlyMe"|"hasLiveId"|"hasOrderComments"|"includeTests"|"isCurrentlyActive"|"hidePromotional", checked:boolean) => {
    const updatedFilter = Search.filter.clone();
    updatedFilter[property] = checked;
    Search.reset(updatedFilter);
  }

  // render
  return (
    <FilterGroup title="Anderes">
      <Checkbox secondaryLabel="Tests anzeigen" value={Search.filter.includeTests} onChange={checked => onChange("includeTests", checked)} />
      <Checkbox secondaryLabel="zur Zeit aktive" value={Search.filter.isCurrentlyActive} onChange={checked => onChange("isCurrentlyActive", checked)} />
      <Checkbox disabled={true} secondaryLabel="aus Promotionen verbergen" value={Search.filter.hidePromotional} onChange={checked => onChange("hidePromotional", checked)} /> {/* we never really want to display promotional items */}
      <Checkbox secondaryLabel="nur solche mit LiveId" value={Search.filter.hasLiveId} onChange={checked => onChange("hasLiveId", checked)} />
      <Checkbox secondaryLabel="nur solche mit Bestellkommentar" value={Search.filter.hasOrderComments} onChange={checked => onChange("hasOrderComments", checked)} />
      <Checkbox secondaryLabel="nur Appentura ME" value={Search.filter.onlyMe} onChange={checked => onChange("onlyMe", checked)} />
    </FilterGroup>
  )
}
