import React from "react"
import {Breadcrumb} from "../../controls"
import Resources from "./Resources"


class Manuals extends React.Component {
  constructor(props) {
    super(props)

    this.resources = [
      {
        title:"Textbausteine Erlebnis-Schritte",
        description:"Für die Erfassung von Erlebnis-Schritten nützliche Textbausteine.",
        url:"https://docs.google.com/document/d/17XZLVRIF_jOQj_JUNHXZb1IK_h7F_dm5Rnmkmgs4gkw"
      },
      {
        title:"Checkliste Überraschung freigeben",
        description:"Checkliste, welche man durchgehen sollte, bevor man eine Überraschung in den Status 'Bereit' setzt.",
        url:"https://docs.google.com/document/d/1jECbUqE2beAogvATrkI1L8yTZaw_jmZbTisuau_BM5Y"
      },
      {
        title:"Anleitung Überraschung planen",
        description:"Was es alles beim Planen einer Überraschung zu beachten gibt und wie man am besten dabei vorgeht.",
        url:"https://docs.google.com/document/d/1BoSZHajMJ7KO35bbPJCSLrfub7g38syhleRbfi8BARI"
      }
    ]
  }

  render() {
    return (
      <React.Fragment>
        <Breadcrumb links={[ {title:'Home', to:'/'}]}
                    location='Anleitungen & Checklisten' />
        <Resources title="Anleitungen & Checklisten" resources={this.resources} />
      </React.Fragment>
    )
  }
}

export default Manuals