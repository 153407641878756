import React, { useEffect, useState } from "react";
import { Button, Icon, Icons } from ".";
import CSS from "./AddRemove.module.scss";

export type AddRemoveItem = {
  id: string,
  label: string,
  selected: boolean
}

type AddRemoveProps = {
  items: AddRemoveItem[],
  maxHeight?: number
}
export function AddRemove({items, maxHeight} : AddRemoveProps) {
  // state
  const [data, setData] = useState<{available:AddRemoveItem[], selected:AddRemoveItem[]}>({available:[], selected: []});

  // mount
  useEffect(() => {
    init();
  }, [items]);

  // sets data
  const init = () => {
    const data = {
      selected: items.filter(item => item.selected).sort((a,b) => a.label.localeCompare(b.label)),
      available: items.filter(item => item.selected === false).sort((a,b) => a.label.localeCompare(b.label))
    }
    setData(data);
  }

  // toggles item
  const onToggle = (item:AddRemoveItem) => {
    item.selected = !item.selected;
    init();
  }

  // pre-render
  const selected = data.selected.map(item => <Item onToggle={onToggle} key={item.id} item={item} />);
  const available = data.available.map(item => <Item onToggle={onToggle} key={item.id} item={item} />);
  const style = maxHeight ? {maxHeight:`${maxHeight}px`} : undefined;

  // render
  return (
    <div className={CSS.container}>
      <div className={CSS.selected} style={style}>{selected}</div>
      <div className={CSS.available} style={style}>{available}</div>
    </div>
  )
}

type ItemProps = {
  item: AddRemoveItem,
  onToggle: (item:AddRemoveItem) => void
}
function Item({item, onToggle} : ItemProps) {
  if(item.selected) {
    return (
      <div className={CSS.item}>
        <div className={CSS.label}>{item.label}</div>
        <Button size="small" intent="delete" onClick={() => onToggle(item)}><Icon icon={Icons.Trash} /></Button>
      </div>
    )
  }
  else {
    return (
      <div className={CSS.item}>
        <Button size="small" intent="ok" onClick={() => onToggle(item)}><Icon icon={Icons.Plus} /></Button>
        <div className={CSS.label}>{item.label}</div>
      </div>
    )
  }
}