import React, {useContext, useState} from "react";
import * as TS from "../../../../../types";
import * as DA from "../../../../../types/DataAccess";
import { Alert, ConfirmButton, Icon, Icons, Table, Tr, Td, CardSection} from "../../../../controls";
import { SurpriseContext } from "../../Surprise.Context";

export default function Cancel() {
  // context
  const SC = useContext(SurpriseContext);

  // constants
  
  // state
  const [selectedReason, setSelectedReason] = useState<TS.AdventureCancelReason>("other");
  const [note, setNote] = useState("");
  const [busy, setBusy] = useState(false);
  const [createdTasks, setCreatedTasks] = useState<DA.AdventureTask[]|null>(null);
  const [deleteSteps] = useState<boolean>(false); // just a stub to make implementation easier if we want the operator to decide if steps should be deleted

  // event handlers
  const cancelAdventure = async() => {
    setBusy(true);
    const cancelResult = await TS.Adventure.cancelAventure(SC.surprise._id, selectedReason, note, deleteSteps);
    SC.load(SC.surprise._id);
    // TODO what did this do? await SC.resetMeteochecks();
    setSelectedReason("other");
    setNote("");
    setCreatedTasks(cancelResult.createdTasks);
    setBusy(false);    
  }

  // we created tasks? the state has changed to ordered, so we only render the tasks to inform user (and nothing else)
  if(createdTasks) {
    return <CreatedTasks tasks={createdTasks} />;
  }

  // not in the right status? render nothing.
  if([TS.AdventureStatus.OrderCancelled, TS.AdventureStatus.Ordered, TS.AdventureStatus.Prepaid].includes(SC.surprise.Status)) {
    return null;
  }

  // render
  const options = TS.AdventureCancelReasons.map((reason, index) => {
    return <option key={index} value={reason.reason}>{reason.label}</option>
  });
  const disabled = TS.AdventureCancelReasons.find(r => r.reason === selectedReason)!.needsNote ? note.trim().length === 0 : false;
  const warning = disabled ? <Icon icon={Icons.ExclamationTriangle} /> : null
  
  return (
    <CardSection title="Absagen">
      <Table>
        <Tr>
          <Td label>Grund</Td>
          <Td>
            <select onChange={e => setSelectedReason(e.target.value as TS.AdventureCancelReason)} value={selectedReason} style={{outline:0, width:"100%"}}>
              {options}
            </select>
          </Td>
        </Tr>
        <Tr>
          <Td label style={{textAlign:"right"}}>{warning}</Td>
          <Td>
            <input type="text" value={note} onChange={e => setNote(e.target.value)} placeholder="weitere Informationen" style={{outline:"none", width:"100%"}} />
          </Td>
        </Tr>
        <Tr>
          <Td></Td>
          <Td>
            <Alert size="small" intent="hint">
              der optionale Text "weitere Informationen" wird zusammen mit dem gewählten Grund in den E-Mails an Kunden und Anbieter ausgegeben. z.B. "Grund: Wetter unsicher (es hagelt Hunde und Katzen)" 
            </Alert>
          </Td>
        </Tr>
        <Tr>
          <Td></Td>
          <Td>
            <ConfirmButton onConfirm={cancelAdventure} disabled={disabled} busy={busy}>Überraschung absagen</ConfirmButton>
          </Td>
        </Tr>
        <Tr>
          <Td></Td>
          <Td>
            <Alert intent="info" size="small">
              <ul>
                <li>Die Überraschung wird auf 'Bestellt' zurückgesetzt und der Kunde muss erneut ein Datum wählen.</li>
                <li>Alle Meteochecks werden gelöscht</li>
                <li>Der Schrittfortschritt wird auf den ersten Schritt zurückgesetzt</li>
                <li>{deleteSteps ? "Alle Schritte werden gelöscht" : "Schritte werden NICHT gelöscht"}</li>
                <li>Der Kunde erhält ein E-Mail mit der Mitteilung, dass die Überraschung abgesagt wurde und einem Link auf ihre Überraschung, damit sie wieder einen Termin wählen können.</li>
                <li>Alle bestätigten Buchungsanfragen werde auf 'abgelehnt' gesetzt</li>
                <li>Anbieter werden wenn möglich via E-Mail informiert</li>
                <li>Falls ein Anbieter nicht via E-Mail benachrichtig werden kann, wir eine entsprechende Aufgabe erstellt (sichtbar im Dashboard und auf der Ansicht der Überraschung).</li>
              </ul>
            </Alert>
          </Td>
        </Tr>
      </Table>
    </CardSection>
  );
}

type CreatedTasksProps = { 
  tasks: DA.AdventureTask[]|null
}
function CreatedTasks({tasks}: CreatedTasksProps) {
  if(!tasks) {
    return null;
  }

  const items = tasks.sort((a,b) => (a.dateCompleted ? 1:0) > (b.dateCompleted ? 1:0) ? -1 : 1).map((task, index) => {
    return (
      <div key={index}>
        <Icon intent={task.dateCompleted ? "confirm" : "warn"} icon={task.dateCompleted ? Icons.CheckCircleSolid : Icons.Circle} /> {task.title}
      </div>
    );
  })
  return (
    <CardSection title="Überraschung Absagen: erstellte Aufgaben">
      <div>folgende Aufgaben wurden erstellt (<Icon icon={Icons.CheckCircleSolid} intent="confirm" />=automatisch erledigt, <Icon icon={Icons.Circle} intent="warn" />=noch zu erledigen):</div>
      <br />
      <div>{items}</div>
    </CardSection>
  )
}
