import _ from "lodash";
import React, {useEffect, useState, useContext} from "react";
import * as DA from "../../../types/DataAccess";

import { Alert, Card, Loader, Value, Form, TextInput, TextArea, Validators, Link, Modal } from "../../controls";
import { ActivityContext } from "./ActivityContext";

type Field = {
  label: string, path: string, validate?: any, placeholder?: string, explanation?: string, linebreaks?: boolean, hideForSubactivities?: boolean, wide?: boolean
}
export default function Texts() {
  // state 
  const [fieldsToEdit, setFieldsToEdit] = useState<Field[]>([]);
  const [fieldToEdit, setFieldToEdit] = useState<Field|null>(null);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  // context
  const AC = useContext(ActivityContext)!;

  // mount
  useEffect(() => {
    if(AC && AC.activity && !isInitialized) {
      init();
    }
  }, [AC.activity])

  // sets up fields that can be edited and gets reserved slugs from db to create a validate function for the slug field
  const init = async() => {
    const reservedSlugs = await DA.ActivityRepository.getReservedSlugs(AC.activity2!._id!);
    const slugPattern = /^[0-9A-Za-z/-]+$/i;
    const validateSlug = (s:string) => {
      if(s.trim().length === 0) {
        return {valid: false, message: "Bitte URL Erweiterung eingeben" };
      }
      if(!slugPattern.test(s)) {
        return {valid: false, message: "Nur Buchstaben, Zahlen und Bindestriche"};
      }
      if(reservedSlugs.includes(s.trim().toLowerCase())) {
        return {valid: false, message: "Diese URL Erweiterung ist bereits vergeben"};
      }
      return {valid: true};
    }
    setFieldsToEdit([
      {label:"Titel", path:"title.de", validate:Validators.isRequired("bitte Titel eingeben"), placeholder:"noch kein Titel"},
      {label:"URL-Erweiterung", path:"slug.de", explanation: "URL-Erweiterung der Aktivität, z.B. dinner-in-the-dark (nur Buchstaben, Zahlen und Bindestriche)", validate:validateSlug, hideForSubactivities:true, placeholder:"noch keine URL-Erweiterung"},
      {label:"kurze Beschreibung", path:"shortDescription.de", placeholder:"noch keine Kurzbeschreibung", wide:true},
      {label:"lange Beschreibung", path:"longDescription.de", linebreaks:true, hideForSubactivities:true, placeholder:"noch keine Beschreibung", wide:true},
      {label:"Notizen", path:"comments", placeholder:"-", linebreaks:true, wide:true},
      {label:"Buchbarkeitshinweis", path:"availableTimes", explanation:"Angaben zu verfügbaren Wochentagen und Tageszeiten. z.B.: 'kann nur an Sonntagen durchgeführt werden'. Wird bei der Einlösung & im Shop unter 'Saison & Wetterbedingungen' angezeigt.", placeholder:"-"},
    ]);
    setIsInitialized(true);
  }

  // user wants to edit an item
  const startEdit = (field:Field) => setFieldToEdit(field);

  // stops editing
  const stopEdit = () => setFieldToEdit(null);

  // saves changes
  const saveEdit = async(changeset:any) => {
    await AC.update(changeset);
    stopEdit();
  }

  // pre-render
  let content;
  if(!isInitialized) {
    content = <Loader text="lade reservierte URL-Erweiterungen" />
  }
  else {
    const hiddenFields:Field[] = [];
    const fields = fieldsToEdit
      .filter(field => {
        // some fields are not necessary for subactivities
        if(AC.activity!.isSubactivity && field.hideForSubactivities) {
          hiddenFields.push(field);
          return false;
        }
        return true;
      })
      .map(field => {
        const value: string = (_.get(AC.activity!, field.path) || "");
        let text: string|any[];
        if(field.linebreaks) {
          text = value.split('\n').map((line, index) => <div key={index}>{line}</div>);
        }
        else {
          text = value;
        }
        return <Value key={field.path} onClick={() => startEdit(field)} {...field} placeholder={field.placeholder}>{text}</Value>
      });
    let subactivityAlert = null;
    if(hiddenFields.length > 0) {
      subactivityAlert = <Alert intent="info" size="small" title="Nicht verwendete Texte">
        {hiddenFields.map(hf => `'${hf.label}'`).join(', ')} werden bei Unteraktivitäten nicht benötigt
      </Alert>
    }
    content = <>
      {fields}
      {subactivityAlert}
    </>
  }
  // render
  return <>
    <Card title="Texte" expanded={false} collapsible={true}>
      {content}
      <Alert intent="info">
        <ul>
          <li>"kurze Beschreibung" und "lange Beschreibung" verstehen <Link to="https://www.markdownguide.org/basic-syntax/">Markdown</Link> (z.B. **fett**)</li>
          <li>um einen Link zu machen der auf der Domain bleibt, einfach wie folgt den Pfad angeben: [FAQ](/faq) (mit dem slash navigiert es einfach auf der Seite auf der man sich befindet so muss man keine Domaine)</li>
        </ul>
      </Alert>
    </Card>
    <Modal 
      isOpen={fieldToEdit !== null} 
      title={`Aktivität - ${fieldToEdit && fieldToEdit.label}`} 
      onClose={stopEdit}>
      <Edit 
        activity={AC.activity2!} 
        saveEdit={saveEdit} 
        onCancel={stopEdit}
        {...fieldToEdit!} 
      />
    </Modal>
  </>
}

type EditType = {
  saveEdit: Function,
  activity: DA.Activity,
  path: string, 
  label: string,
  explanation?: string,
  linebreaks?: boolean, 
  validate?: any,
  onCancel: () => void
}
function Edit({saveEdit, activity, path, label, explanation, linebreaks, validate, onCancel} : EditType) {
  // state
  const [entity, setEntity] = useState(null);
  const [busy, setBusy] = useState(false);

  // mount
  useEffect(() => {
    const entity:any = {};
    entity[path] = _.get(activity, path) || "";
    setEntity(entity);
  }, []);

  // save the update
  const onSave = async(formResult:any) => {
    setBusy(true);
    await saveEdit(formResult.changesetFlat); // make sure to pass the flat changeset, lest you overwrite sibling data
    setBusy(false);
  }


  // render
  return (
    <Form onCancel={onCancel} onSave={onSave} entity={entity} busy={busy}>
      {linebreaks ? <TextArea label={label} path={path} disabled={busy} validate={validate} explanation={explanation} /> : <TextInput label={label} path={path} disabled={busy} validate={validate} explanation={explanation} />}
    </Form>
  );
}