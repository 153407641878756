import { ActivityInstruction, ActivityInstructionCoordinates, ActivityInstructionTravel } from "./ActivityInstruction";
import { Mapper } from "../_Common/Mapper";
import { TranslatableMapper } from "../_Common/TranslatableMapper";


export class ActivityInstructionMapper extends Mapper<ActivityInstruction> {
  public apiEndpoint = "activityInstructions";

  public toDb(activityInstruction: ActivityInstruction): any {
    return {
      _id: activityInstruction._id,
      activityId: activityInstruction.activityId,
      providerId: activityInstruction.providerId,
      title: TranslatableMapper.toDb(activityInstruction.title),
      text: TranslatableMapper.toDb(activityInstruction.text),
      position: activityInstruction.position,
      travel: activityInstruction.travel ? this.toDb_travel(activityInstruction.travel) : null
    }
  }

  private toDb_coordinates(coordinates: ActivityInstructionCoordinates): any {
    return {
      lat: coordinates.lat || 0,
      lng: coordinates.lng || 0
    }
  }

  private toDb_travel(travel: ActivityInstructionTravel): any {
    return {
      mode: travel.mode,
      start: this.toDb_coordinates(travel.start),
      destination: this.toDb_coordinates(travel.destination)
    }
  }

  public fromDb(obj:any): ActivityInstruction {
    return {
      _id: obj._id,
      activityId: obj.activityId || "",
      providerId: obj.providerId || null,
      title: obj.title ? TranslatableMapper.fromDb(obj.title) : TranslatableMapper.fromDb({}),
      text: obj.text ? TranslatableMapper.fromDb(obj.text) : TranslatableMapper.fromDb({}),
      position: obj.position || 1,
      travel: obj.travel ? this.fromDb_travel(obj.travel) : null,
    }
  }

  private fromDb_coordinates(obj:any): ActivityInstructionCoordinates {
    const lat = obj ? obj.lat || 0 : 0;
    const lng = obj ? obj.lng || 0 : 0;
    return {
      lat, lng
    }
  }

  private fromDb_travel(obj:any): ActivityInstructionTravel|null {
    if(obj) {
      return {
        mode: obj.mode || "WALKING",
        start: this.fromDb_coordinates(obj.start),
        destination: this.fromDb_coordinates(obj.destination)
      }
    }
    return null;
  } 

}