import moment from "moment";
import { AdventureStatus } from "../Adventure"; 
import Config from "../../config";

export type FilterDunningProp = "unpaid"|"overdue"|"level0"|"level1"|"level2"|"level3";
export type FilterDateRange = {
  active: boolean, from: Date, to: Date, field: string
}
export type PaymentStatusDate = {
  active: boolean, date: Date
}
export class Filter {
  stati: AdventureStatus[] = [];
  activityName: string = "";
  isNotShipped: boolean = false;
  id: string = "";
  region: string = "";
  operator: string = "";
  transportOption: ""|"eigenes Auto"|"oeffentlicher Verkehr" = "";
  nameOrEmailContains: string = "";
  soldOffline: ""|"excluded"|"only"|"onlyNotSold" = "";
  dunning: any = {}; // TODO to be frank, I do not know how to type this properly (would want to restrict keys to FilterDunningProp)
  dateOrdered: FilterDateRange = {active:false, from: new Date(), to: new Date(), field: "CreateDate"};
  dateActivated: FilterDateRange = {active:false, from: new Date(), to: new Date(), field: "ScheduleDate"};
  dateStartUser: FilterDateRange = {active:false, from: new Date(), to: new Date(), field: "UserStartTime"};
  dateStart: FilterDateRange = {active:false, from: new Date(), to: new Date(), field: "StartTime"};
  onlyMe: boolean = false;
  hasLiveId: boolean = false;
  hasOrderComments: boolean = false;
  includeTests: boolean = false;
  isCurrentlyActive: boolean = false;
  hidePromotional: boolean = true; // by default we do NOT want promotional items to be listed
  onlyPrepaid: boolean = false;
  includePrepaidInWarehouse: boolean = false;
  paymentStatusUnpaid: PaymentStatusDate = {active:false, date: new Date()};
  paymentStatusPrepaid: PaymentStatusDate = {active:false, date: new Date()};

  /**
   * Converts to mongo filter object
   * @returns mongo filter
   */
  public toMongoFilter(): Object {
    
    // collects filters that are checked against and
    const and:any[] = [];

    // with _id ending
    if(this.id.trim().length > 0) {
      and.push({_id_string:{"$regex":`${this.id.trim()}$`, "$options":"i"}})
    }

    // status filter
    const statusOr:any[] = [];
    this.stati.forEach((s:AdventureStatus) => {
      statusOr.push({"Status":s});
    })
    if(statusOr.length > 0) {
      and.push({"$or":statusOr})
    }

    // shipping filter
    if(this.isNotShipped) {
      and.push({
        "$and":[
          {"ShippingDate":{"$eq":null}}
        ]
      });
    }

    // activity filter
    // TODO we are using $regex here ... we could set a text index on Activities.Title and Coments in the database instead and use a $text search
    if(this.activityName.trim().length > 0) {
      const filterActivity = {"$or": [
        {"Activities.Title":{"$regex":`.*${this.activityName.trim()}.*`, "$options":"i"}},
        {"Comments":{"$regex":`.*${this.activityName.trim()}.*`, "$options":"i"}}
      ]}
      and.push(filterActivity)
    }

    // region filter
    if(this.region.length > 0) {
      and.push({"Activities.RegionCode": this.region})
    }

    // operator filter
    if(this.operator.length > 0) {
      and.push({"Operator": this.operator})
    }
    
    // transport options filter
    if(this.transportOption.length > 0) {
      //and.push({"TransportOptions": this.transportOption})

      and.push({"TransportOptions":{$all: [this.transportOption]}})
    }

    // name / e-mail of reserver or receiver containing
    if(this.nameOrEmailContains.trim().length > 0) {
      const nameOrEmailContains = this.nameOrEmailContains.trim();
      and.push({
        "$or":[
          {"ReserverName":{"$regex":`${nameOrEmailContains}`, "$options":"i"}},
          {"RecieverName":{"$regex":`${nameOrEmailContains}`, "$options":"i"}},
          {"ReserverEmail":{"$regex":`${nameOrEmailContains}`, "$options":"i"}},
          {"RecieverEmail":{"$regex":`${nameOrEmailContains}`, "$options":"i"}},
          {"RecieverRealFirstName":{"$regex":`${nameOrEmailContains}`, "$options":"i"}}
        ]
      })
    }

    // sold offline
    switch(this.soldOffline) {
      case "excluded":
        and.push({"IsSoldOffline":{"$ne":true}})
        break;
      case "only":
        and.push({"IsSoldOffline":true})
        break;
      case "onlyNotSold":
        and.push({"$and":[
          {"IsSoldOffline":true},
          {"SoldOfflineInfo.IsSold":{"$ne":true}}
        ]})
        break;
      default:
        break
    }

    // dunning filter: unpaid with payment type 'transfer'
    if(this.dunning.unpaid) {
      and.push({
        "$and": [
          {"PaymentType": "transfer"},
          {"PaymentDate": null}
        ]
      })
    }
    if(this.dunning.overdue) {
      let daysUntilOverdue = Config.misc.NumberOfDaysUntilOverdue
      let dueDate = moment().add(-daysUntilOverdue, "days").toDate()
      and.push({
        "$and": [
          {"PaymentType": "transfer"},
          {"PaymentDate": null},
          {"CreateDate": {"$lt":dueDate.toUTCString()}}
        ]
      })
    }
    let dunningLevelOr = [];
    if(this.dunning.level0) {
      dunningLevelOr.push({
        "PaymentReminderLevel":0
      })
    }
    if(this.dunning.level1) {
      dunningLevelOr.push({
        "PaymentReminderLevel":1
      })
    }
    if(this.dunning.level2) {
      dunningLevelOr.push({
        "PaymentReminderLevel":2
      })
    }
    if(this.dunning.level3) {
      dunningLevelOr.push({
        "PaymentReminderLevel":3
      })
    }
    if(dunningLevelOr.length > 0) {
      and.push({"$or":dunningLevelOr})
    }

    // dates filter
    const addDateFilter = (filterDate: FilterDateRange) => {
      if(filterDate.active) {
        let filter_from: any = {};
        let filter_to: any = {};
        filter_from[filterDate.field] = {"$gte": filterDate.from.toISOString()};
        filter_to[filterDate.field] = {"$lt": moment(filterDate.to).add(1, 'days').toISOString()};
        and.push({"$and":[filter_from, filter_to]})
      }
    }
    addDateFilter(this.dateActivated);
    addDateFilter(this.dateOrdered);
    addDateFilter(this.dateStart);
    addDateFilter(this.dateStartUser);

    // appentura.me
    if(this.onlyMe) {
      and.push({"IsMe":true})
    }

    // live-id
    if(this.hasLiveId) {
      and.push({"LiveId":{"$ne":null}})
    }

    // order comments
    if(this.hasOrderComments) {
      // eslint-disable-next-line
      and.push({"Order.Comments":{"$regex":"^(?!\s+$).+"}})
    }

    // only those currently active
    if(this.isCurrentlyActive) {
      and.push({"$and":
        [
          {"Status": AdventureStatus.Ready},
          {"StartTime": {"$lt":new Date().toUTCString()}}
        ]
      })
    }

    // hide promotional items
    if(this.hidePromotional) {
      and.push({"$or":[{"PromoName":{"$exists":false}}, {"PromoName":{"$eq":null}}]});
    }

    // prepaid only
    if(this.onlyPrepaid) {
      and.push({"Order.Prepaid.Value":{$exists:true}});
    }

    // prepaid in warehouse (i.e. not yet sold)
    if(this.includePrepaidInWarehouse === false) {
      and.push({"Order.Prepaid.InWarehouse":{"$ne":true}});
    }

    // not paid in relation to date
    if(this.paymentStatusUnpaid.active) {
      // not paid or payment date after given date, order date before given date, no premade
      const targetDate = this.paymentStatusUnpaid.date; // getDate(moment(state.maskPaymentStatus.dateUnpaid));
      and.push({"PaymentType": "transfer"});
      and.push({"$or":[
        {"PaymentDate":null},
        {"PaymentDate":{"$gt":targetDate.toISOString()}}
      ]});
      and.push({"CreateDate":{"$lt":targetDate.toISOString()}});
      and.push({"IsSoldOffline":{"$ne":true}});
    }

    // paid in relation to date
    if(this.paymentStatusPrepaid.active) {
      // not executed before selected date, paid before selected date, no premade
      const targetDate = this.paymentStatusPrepaid.date;
      and.push({"$or":[
        {"StartTime":null},
        {"StartTime":{"$gt":targetDate.toISOString()}}
      ]});
      and.push({"PaymentDate":{"$ne":null}});
      and.push({"PaymentDate":{"$lt":targetDate.toISOString()}});
      and.push({"IsSoldOffline":{"$ne":true}});
    }


    // build the filter
    let filter:any = {};
    if(and.length > 0) {
      // some filters are only set if at least one other filter is set ... hence we do not set them before
      if(!this.includeTests) {
        and.push({"IsTest":{"$ne":true}})
      }
      // our collected filters are grouped in an `$and` mongo filter
      filter["$and"] = and
    }
    else {
      filter = {};
    }

    return filter;
  }

  /**
   * Clones the filter
   * @returns clone of this instance
   */
  public clone(): Filter {
    const filter = new Filter();
    filter.stati = this.stati.map(s => s);
    filter.activityName = this.activityName;
    filter.isNotShipped = this.isNotShipped;
    filter.id = this.id;
    filter.region = this.region;
    filter.operator = this.operator;
    filter.transportOption = this.transportOption;
    filter.nameOrEmailContains = this.nameOrEmailContains;
    filter.soldOffline = this.soldOffline;
    filter.dunning = this.dunning;
    filter.dateActivated = this.dateActivated;
    filter.dateOrdered = this.dateOrdered;
    filter.dateStart = this.dateStart;
    filter.dateStartUser = this.dateStartUser;
    filter.onlyMe = this.onlyMe;
    filter.hasLiveId = this.hasLiveId;
    filter.hasOrderComments = this.hasOrderComments;
    filter.includeTests = this.includeTests;
    filter.isCurrentlyActive = this.isCurrentlyActive;
    filter.hidePromotional = this.hidePromotional;
    filter.onlyPrepaid = this.onlyPrepaid;
    filter.includePrepaidInWarehouse = this.includePrepaidInWarehouse;
    filter.paymentStatusPrepaid = this.paymentStatusPrepaid;
    filter.paymentStatusUnpaid = this.paymentStatusUnpaid;
    return filter;
  }
}