import React, { useContext, useEffect, useState } from "react";
// types
import * as Cms from "../../../../types/Cms";
// contexts and components
import { PageContext } from "../Page.Context";
import { Form, Checkbox, Icon, Icons } from "../../../controls";
import * as Selectors from "../Selectors";
import * as Preview from "../Preview";

// styling and images
// import CSS from "./CustomFoooter.module.scss";


type CustomFooterProps = {
  instance?: Cms.CustomFooterModule,
  closeDialog: () => void,
}
export function CustomFooter({instance, closeDialog}:CustomFooterProps) {
  // context
  const PC = useContext(PageContext);
  
  // state
  const [entity, setEntity] = useState<Cms.CustomFooterModule|null>(null);
  const [busy, setBusy] = useState<boolean>(false);

  // mount
  useEffect(() => {
    let entity: Cms.CustomFooterModule;
    if(instance) {
      entity = instance;
    }
    else {
      entity = new Cms.CustomFooterModule();
    }
    setEntity(entity)
  }, [])

  // user wants to cancel
  const onCancel = () => {
    closeDialog();
  }

  // user wants to save
  const onSave = async(formResult:any) => {
    setBusy(true);

    const updatedEntity = formResult.merge(entity);
    if(formResult.changeset.countries) {
      // TODO we need to do this because formResult.merge does not seem to handle arrays correctly
      updatedEntity.countries = formResult.changeset.countries;
    }

    // save to db
    await PC.upsertModule(updatedEntity);

    // close dialog
    closeDialog();
  }

  
  // TODO <Alert /> needs content
  return (
    <Form entity={entity} onCancel={onCancel} onSave={onSave} busy={false}>
      <Selectors.BaseProperties busy={busy} />

      <Checkbox label="Logo" path="elements.showLogo" disabled={busy} />
      <Checkbox label="Kontakt" path="elements.showContact" disabled={busy} />
      <Checkbox label="Newsletter abonnieren" path="elements.showNewsletterSubscription" disabled={busy} />
      <Checkbox label="Zahlungsmöglichkeiten" path="elements.showPaymentOptions" disabled={busy} />
      <Checkbox label="Social" path="elements.showSocial" disabled={busy} />
      <Checkbox label="Primäre Links" path="elements.showPrimaryLinks" disabled={busy} />
      <Checkbox label="Sekundäre Links" path="elements.showSecondaryLinks" disabled={busy} />
    </Form>
  )
}


type CustomFooterPreviewType = {
  instance: Cms.CustomFooterModule,
  expanded?: boolean,onClickAction: (module:Cms.Module, action:"edit"|"delete"|"duplicate") => void
}
export function CustomFooterPreview({instance, expanded, onClickAction}:CustomFooterPreviewType) {
  const row = (label:string, value:boolean) => {
    return (
      <Preview.TableRow label={label}>
        <Icon icon={value ? Icons.Check : Icons.Minus} />
      </Preview.TableRow>
    )
  }
  return (
    <Preview.Container expanded={expanded} module={instance} title={Cms.CustomFooterModule.info.title} onClickAction={onClickAction}>
      <Preview.Table>
        { row("Logo", instance.elements.showLogo) }
        { row("Kontakt", instance.elements.showContact) }
        { row("Newsletter abonnieren", instance.elements.showNewsletterSubscription) }
        { row("Zahlungsmöglichkeiten", instance.elements.showPaymentOptions) }
        { row("Social", instance.elements.showSocial) }
        { row("Primäre Links", instance.elements.showPrimaryLinks) }
        { row("Sekundäre Links", instance.elements.showSecondaryLinks) }
      </Preview.Table>
    </Preview.Container>
  )
}