import _ from "lodash";
import React, {useState, useEffect, useContext} from "react";
import {ProviderContext} from "./Provider.Context";
import * as DA from "../../../types/DataAccess";
import * as BL from "../../../types/BusinessLogic";
// components
import { Button, Card, Form, Dropdown, List, TextInput, TextArea, Icon, Icons, Modal } from "../../controls";

const contactColumns = [
  //{path:"kind", header: "Typ", print: (v:TS.ProviderContact) => TS.Provider.getContactKindLabel(v.kind)},
  {path:"kind", header: "Typ", print: (v:DA.ProviderContact) => BL.Provider.getContactKindLabel(v.kind)},
  {path:"title", header: "Titel"},
  {path:"function", header: "Funktion"},
  {path:"firstName", header: "Vorname"},
  {path:"lastName", header: "Name"},
  {path:"email", header: "E-Mail"},
  {path:"mobile", header: "Mobile Phone"},
  {path:"fix", header: "Fix Phone"},
  {path:"url", header: "Url"},
  {path:"notes", header: "Notizen"}
];

const contactKinds = [
  {value:'allgemein', label: BL.Provider.getContactKindLabel("allgemein"), default:true},
  {value:'meteo', label: BL.Provider.getContactKindLabel("meteo")}
];


export default function ProviderContacts() {
  // state
  const [contactToEdit, setContactToEdit] = useState<DA.ProviderContact|null>(null);
  // context
  const PC = useContext(ProviderContext);

  // user wants to create
  const onClickCreate = () => {
    const contact:DA.ProviderContact = DA.ProviderRepository.makeContact();
    setContactToEdit(contact);
  }

  // user wants to edit
  const onClickEdit = (contact:DA.ProviderContact) => {
    setContactToEdit(contact);
  }

  // user wants to delete
  const onClickDelete = async(contact:DA.ProviderContact) => {
    await PC.deleteContact(contact);
    await PC.reload();
  }

  // closes dialog
  const closeDialog = () => setContactToEdit(null);
 
  // render
  return <>
    <Card title="Kontakte" expanded={false} collapsible={true}>
      <List 
        columns={contactColumns} 
        items={PC.provider.contacts}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
        hideFooter={true}
        hideSearch={true}
        getKey={item => item._id}
      />
      <Button onClick={onClickCreate} size="small"><Icon icon={Icons.Plus} /></Button>
    </Card>
    <Modal isOpen={contactToEdit !== null} title="Kontakt" onClose={closeDialog}>
      <Edit contact={contactToEdit!} closeDialog={closeDialog} />
    </Modal>
  </>
}

type EditProps = {
  contact: DA.ProviderContact,
  closeDialog: () => void,
}
function Edit({contact, closeDialog} : EditProps) {
  // context
  const PC = useContext(ProviderContext);

  // state
  const [busy, setBusy] = useState<boolean>(false);
  const [entity, setEntity] = useState<DA.ProviderContact|null>(null);

  // mount
  useEffect(() => {
    // const entity:any = {};
    // _.forOwn(contact, (value, key) => {
    //   entity[key] = value;
    // });
    setEntity(contact);
  }, []);

  // user wants to save
  const onSave = async(formResult:any) => {
    setBusy(true);
    const contactToUpsert = formResult.merge(entity);
    await PC.upsertContact(contactToUpsert);
    await PC.reload();
    closeDialog();
  }

  // render
  return <>
    <Form 
      entity={entity} 
      onSave={onSave} 
      onCancel={closeDialog}
      busy={busy}
    >
      <Dropdown label="Typ" path="kind" options={contactKinds} />
      <TextInput label="Titel" path="title" />
      <TextInput label="Funktion" path="function" />
      <TextInput label="Vorname" path="firstName" />
      <TextInput label="Name" path="lastName" />
      <TextInput label="E-Mail" path="email" />
      <TextInput label="Mobile Phone" path="mobile" />
      <TextInput label="Fix Phone" path="fix" />
      <TextInput label="Url" path="url" />
      <TextArea label="Notizen" path="notes" />
    </Form>
  </>
}
