import React, { useContext, useState } from "react";
import Util from "../../../util/util";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";
import { Card, Modal, Loader, Dropdown, DropdownOption, DropdownOptions, Tr, Td, Table, Alert, Button, Checkbox, Link, Icon, Icons } from "../../controls";
import { CollectiveInvoicesContext } from "./CollectiveInvoices.Context";

import CSS from "./CollectiveInvoices.module.scss";
import { ProviderLink } from "../../common/";


const currentYear = (new Date()).getFullYear();
const yearOptions: DropdownOption[] = [
  {label: String(currentYear- 1), value:currentYear-1},
  {label: String(currentYear), value:currentYear}
];

export default function CollectiveInvoicesCreate() {
  // context
  const CIC = useContext(CollectiveInvoicesContext);

  // pre-render content
  let content = <Loader />;
  if(CIC.isLoaded) {
    content = (
      <div>
        <div className={CSS.period}>
          <Dropdown label="Monat" value={CIC.period.month} options={DropdownOptions.Months} onChange={(v)=>{CIC.changePeriod({year:CIC.period.year, month:Number(v)})}}  />
          <Dropdown label="Jahr" value={CIC.period.year} options={yearOptions} onChange={(v)=>{CIC.changePeriod({year:Number(v), month:CIC.period.month})}} />
        </div>
        <Alert intent="info" size="small">
          Berücksichtigt werden Buchunganfragen, welche:
          <ul>
            <li>bestätigt wurden</li>
            <li>einen Abrechnungs-Wert zugewiesen haben</li>
            <li>zu Überraschungen gehören, welche abgeschlossen sind</li>
            <li>zu Überraschungen gehören, deren Startdatum innerhalb oder vor dem gewählten Monat liegen</li>
          </ul>
        </Alert>
        <OpenBookingRequests />
      </div>
    )
  }
  // render
  return (
    <Card>
      {content}
    </Card>
  );
}

/**
 * Lists open booking request grouped by provider
 * @returns 
 */
function OpenBookingRequests() {
  // state
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalParam, setModalParam] = useState<DA.Provider>(DA.ProviderRepository.make());

  // context
  const CIC = useContext(CollectiveInvoicesContext);

  // user wants to create an invoice
  const onClickCreate = (provider: DA.Provider) => {
    setModalOpen(true);
    setModalParam(provider);
  }

  const closeDialog = () => setModalOpen(false);

  // pre-render
  let content = null;
  const rows:any[] = [];
  CIC.data.providers.forEach(provider => {
    const bookingRequests = CIC.data.bookingRequests.filter(b => b.provider.id === provider._id);
    if(bookingRequests.length > 0) {
      const total = bookingRequests.reduce((prev, curr) => {
        return prev + curr.collectiveInvoice!.amount;
      }, 0);
      rows.push(
        <Tr key={provider._id}>
          <Td>
            <ProviderLink provider={provider} />
          </Td>
          <Td align="right">{bookingRequests.length}</Td>
          <Td align="right">{provider.collectiveInvoice.currency} {total}</Td>
          <Td><Button size="small" onClick={() => onClickCreate(provider)}>Rechnung erzeugen</Button></Td>
        </Tr>
      )
    }
  })
  if(rows.length > 0) {
    content = <>
      <Table>
        <Tr>
          <Td label>Anbieter</Td>
          <Td label align="right">Buchungsanfragen</Td>
          <Td label align="right">Gesamtbetrag</Td>
          <Td> </Td>
        </Tr>
        {rows}
      </Table>
      <Modal isOpen={modalOpen} title={`Monatsabrechnung für ${modalParam.name} erstellen. Periode: ${CIC.period.month}/${CIC.period.year}`} onClose={closeDialog}>
        <CreateDialog provider={modalParam} closeDialog={closeDialog} />
      </Modal>
    </>
  }
  else {
    content = <Alert size="small" intent="warning">Keine relevanten Buchungsanfragen gefunden</Alert>
  }
  // render
  return (
    <div>
      {content}
    </div>
  )
}


type CreateProps = {
  provider: DA.Provider,
  closeDialog: () => void,
}
function CreateDialog({provider, closeDialog}: CreateProps) {
  // context
  const CIC = useContext(CollectiveInvoicesContext);

  // state
  const [excluded, setExcluded] = useState<string[]>([]);

  // context no yet ready
  if(!CIC.isLoaded) {
    return <Loader />;
  }

  // user wants to create the invoice
  const onClickCreate = async() => {
    // get booking requests to include
    // - belonging to this provider
    // - not excluded by user
    const bookingRequests = CIC.data.bookingRequests
      .filter(b => b.provider.id === provider._id)
      .filter(b => !excluded.includes(b._id!))
    // create
    await CIC.createInvoice(provider, bookingRequests);
    // close dialog
    closeDialog();
  }

  // user clicks checkbox
  const onClickCheckbox = (br:DA.BookingRequest, checked:boolean) => {
    const updated = excluded.filter(s => String(s) !== String(br._id));
    if(!checked) {
      updated.push(br._id!);
    }
    setExcluded(updated);
  }

  // pre-render content
  const bookingRequests = CIC.data.bookingRequests.filter(b => b.provider.id === provider._id);
  const rows = bookingRequests.map(b => {
    return <Tr key={b._id}>
      <Td><Checkbox value={excluded.includes(b._id!) === false} onChange={(c) => {onClickCheckbox(b, c)}} /></Td>
      <Td><Link to={`/surprise/${b.adventure_id}`}>{TS.Adventure.getShortId(b.adventure_id)}</Link></Td>
      <Td>{Util.printDate(b.activity.date || b.adventure_date)}</Td>
      <Td>{b.collectiveInvoice!.text}</Td>
      <Td align="right">{provider.collectiveInvoice.currency} {b.collectiveInvoice!.amount}</Td>
    </Tr>
  });
  const total = bookingRequests.reduce((prev, curr) => {
    if(!excluded.includes(curr._id!)) {
      return prev + curr.collectiveInvoice!.amount;
    }
    else {
      return prev;
    }
  }, 0);
  // render
  return <>
    <Table>
      {rows}
      <Tr>
        <Td colSpan={4} label>Total</Td>
        <Td align="right">{provider.collectiveInvoice.currency} {total}</Td>
      </Tr>
    </Table>
    <Button onClick={onClickCreate}>Rechnung erzeugen</Button>
  </>
}