import React, { useEffect, useState, useContext } from "react";
import _ from "lodash";
import Api from "../../../util/api2";
import Util from "../../../util/util";

// components
import { Button, Breadcrumb, Card, Link, Loader, Icon, Icons, Modal } from "../../controls";
import Check from "./Check";
import AddLog from "./AddLog";

// styling
import "./List.scss"

// the component
export default function List() {
  // state
  const [data, setData] = useState<any>(null);
  const [modalAction, setModalAction] = useState<"addLog"|"complete"|"none">("none");
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modalParam, setModalParam] = useState<any>();
  
  // mount
  useEffect(() => {
    load();
  }, []);

  // loads relevant dat
  const load = async() => {
    setData(null);
    const apiResult = await Api.post("meteocheck", "getList", {}); 
    setData(apiResult.data);
  };

  // user wants to add log entry
  const onClickAddLog = (item:any, passed:boolean) => {
    const title = passed ? "Meteo-Check zurücksetzen" : "Meteo-Check abgeschlossen";
    setModalTitle(title);
    setModalAction("addLog");
    setModalParam(item);
  } 

  // user wants to complete meteo check
  const onClickCompleted = (item:any) => {
    setModalTitle("Meteocheck abgeschlossen");
    setModalAction("complete");
    setModalParam(item);
  }

  // close dialog
  const closeDialog = () => setModalAction("none");

  // pre-render
  let content = null
  if(data === null) {
    content = <Loader />
  }
  else {
    const rows:any = {
      todo:[],
      done:[],
      lastTodoDate:null,
      lastDoneDate:null
    }

    data.adventures
    .map((a:any) => {
      a.datenumber = Util.getCalendarDateNumber(a.start);
      return a;
    })
    .sort((a:any, b:any) => a.datenumber > b.datenumber ? 1 : -1)
    .forEach((adv:any) => {
      const activityRows:any = {
        todo:[],
        done:[]
      }
      adv.activities.forEach((a:any) => {
        let providerInfo = [];
        let latestMeteocheck = (a.meteochecks || []).sort((a:any, b:any) => a.date > b.date ? -1 : 1)[0];
        let passed = latestMeteocheck ? latestMeteocheck.passed : false;
        if(a.provider) {
          providerInfo.push(a.provider.name);
        }
        
        let item = {
          passed:!passed,
          adventureId:adv._id,
          activityId:a._id,
          activityTitle:a.title,
          providerId:_.get(a, "provider._id") || null,
          providerName:_.get(a, "provider.name") || null,
        }

        let row = (
          <tr key={`${adv._id}.${a._id}`} className="activity">
            <td><Icon icon={Icons.Hiking} /></td>
            <td><Link to={`activities/${a._id}`}>{a.title}</Link></td>
            <td><Icon icon={Icons.Building} /></td>
            <td><ProviderInfo provider={a.provider} /></td>
            <td>
              {passed ? null : <Button size="small" onClick={() => onClickAddLog(item, false)}>Log hinzufügen</Button>}
              <Button size="small" onClick={() => onClickCompleted(item)}>{passed ? "zurücksetzen" : "abgeschlossen"}</Button>
            </td>
          </tr>
        )

        if(passed) {
          activityRows.done.push(row);
          activityRows.done.push(
            <tr key={`${adv._id}.${a._id}_operator`} className="activity">
              <td colSpan={2}></td>
              <td><Icon icon={Icons.User} /></td>
              <td>{latestMeteocheck.operator}</td>
              <td></td>
            </tr>
          )
          if((latestMeteocheck.notes || "").trim().length > 0) {
            activityRows.done.push(
              <tr key={`${adv._id}.${a._id}_notes`} className="activity">
                <td colSpan={2}></td>
                <td><Icon icon={Icons.FileAlt} /></td>
                <td>{latestMeteocheck.notes}</td>
                <td></td>
              </tr>
            )
          }
        }
        else {
          activityRows.todo.push(row);
          // meteocheck log entries (if any)
          if(adv.meteocheckLogs.length > 0) {    
            activityRows.todo.push(
              <tr key={`${adv._id}.${a._id}_operator`} className="activity">
                <td colSpan={2}></td>
                <td><Icon icon={Icons.List} /></td>
                <td><LogEntries logs={adv.meteocheckLogs} /></td>
                <td></td>
              </tr>
            )
          } 
        }
      })

      const dateRow = (
        <tr key={`date_${adv.datenumber}`} className="date">
          <td colSpan={5}>{Util.printDate(adv.start)}</td>
        </tr>
      )
      const adventureRow = (
        <tr key={adv._id} className="adventure">
          <td colSpan={5}><Link to={`surprise/${adv._id}`}>{`${adv.reserverName} für ${adv.receiverName}`}</Link></td>
        </tr>
      )

      if(activityRows.todo.length > 0) {
        if(rows.lastTodoDate !== adv.datenumber) {
          rows.todo.push(dateRow);
          rows.lastTodoDate = adv.datenumber;
        }
        rows.todo.push(adventureRow);
        rows.todo.push(activityRows.todo);
      }
      if(activityRows.done.length > 0) {
        if(rows.lastDoneDate !== adv.datenumber) {
          rows.done.push(dateRow);
          rows.lastDoneDate = adv.datenumber;
        }
        rows.done.push(adventureRow);
        rows.done.push(activityRows.done);
      }
    })

    if(rows.done.length === 0) rows.done.push(<tr key="none"><td>kein Eintrag</td></tr>)
    if(rows.todo.length === 0) rows.todo.push(<tr key="none"><td>kein Eintrag</td></tr>)

    let modalContent = null;
    switch(modalAction) {
      case "addLog":
        modalContent = <AddLog data={modalParam} onSave={() => load()} closeDialog={closeDialog} />
        break;
      case "complete":
        modalContent = <Check data={modalParam} onSave={() => load()} closeDialog={closeDialog} />
        break;
    }

    content = <>
      <Card title="Fehlende Meteo-Checks">
        <div className="weatherservices">
          <div className="weatherservices-title">Wetterdienste</div>
          <ul>
            <li><Link to="https://www.srf.ch/meteo" target="_blank">srf.ch/meteo</Link></li>
            <li><Link to="https://www.meteoschweiz.admin.ch/home.html?tab=overview" target="_blank">meteoschweiz.admin.ch</Link></li>
          </ul>
        </div>
        <table><tbody>
          {rows.todo}
        </tbody></table>
      </Card>
      <Card title="Abgeschlossene Meteo-Checks">
        <table><tbody>
          {rows.done}
        </tbody></table>
      </Card>
      <Modal isOpen={modalAction !== "none"} title={modalTitle} onClose={closeDialog}>
        {modalContent}
      </Modal>
    </>
  }

  // render
  return (
    <div id="v-meteocheck-list">
      <Breadcrumb links={[ {title:'Home', to:'/'}]} location="Meteocheck" />
      {content}
    </div>
  );
}

function LogEntries({logs} : any) {
  let entries = logs.sort((a:any, b:any) => a.CreateDate > b.CreateDate ? -1 : 1).map((log:any, index:number) => {
    let name = (log.Operator || "").split('@')[0];
    return <div key={index}>{Util.printDateAndTime(log.CreateDate)}, {_.upperFirst(name)}: {log.Text}</div>
  })
  return (
    <div>
      {entries.length === 0 ? "keine Logeinträge" : entries}
    </div>
  )
}

function ProviderInfo({provider}:any) {
  // no provider
  if(!provider) {
    return <div>Kein bestätigte Buchung vorhanden</div>;
  }

  // meteo contacts
  const meteoContacts = _.get(provider, "meteoContacts") || []
  let contacts = null
  if(meteoContacts.length > 0) {
    const items = meteoContacts.map((contact:any, index:number) => {
      return (
        <div key={index} className="meteocontact">
          {contact.fix ? <span><Link to={`tel:${contact.fix}`}>{contact.fix}</Link></span> : null}
          {contact.mobile ? <span><Link to={`tel:${contact.mobile}`}>{contact.mobile}</Link></span> : null}
          {contact.url ? <span><Link to={contact.url}>{contact.url}</Link></span> : null}
          {contact.email ? <span><Link to={`mailto:${contact.email}`}>{contact.email}</Link></span> : null}
        </div>
      )
    })
    contacts = <div>{items}</div>
  }
  else {
    contacts = <div><Icon icon={Icons.ExclamationTriangle} /> Für diesen Anbieter ist noch kein Meteo-Kontakt erfasst. Diesen bitte erfassen.</div>
  }
  
  // render
  return <>
    <div><Link to={`providers/${provider._id}`} target="_blank">{provider.name}</Link></div>
    {contacts}
  </>
}
