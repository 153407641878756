import _ from "lodash"
import React, { useState, useEffect } from "react";
import { Form, Coordinates, TextInput, Validators } from "../../../controls"
// types
import * as TS from "../../../../types";

type LocationProps = {
  onChange: (location: TS.AdventureOutlineLocation) => void,
  stopEdit: () => void,
}
export default function Location({onChange, stopEdit} : LocationProps) {
  // state
  const [data] = useState<{text: string, coordinates:{lng:number, lat:number}}>({text:"", coordinates:{lat:0, lng:0}});
  
  // mount
  useEffect(() => {
  }, []);

  // 
  const save = async(formResult:any) => {
    let updated_data: any = {};
    _.merge(updated_data, data)
    _.merge(updated_data, formResult.changeset)
    
    const location: TS.AdventureOutlineLocation = {
      text: updated_data.text, 
      lat: updated_data.location.lat,
      lng: updated_data.location.lng,
    }

    onChange(location);

    stopEdit();
  }

  
  // render
  return (
    <div>
      <Form 
        entity={data} 
        onSave={save} onCancel={stopEdit}
        labelSave="übernehmen"
        busy={false}
      >
        <TextInput 
          path="text" 
          label="Bezeichung" placeholder="Bezeichung des Startortes" 
          validate={Validators.isRequired("Bitte eine Bezeichnung eingeben")}
          disabled={false}
        />
        <Coordinates label="Koordinaten" path="location" pathLat="lat" pathLng="lng" required={true} locationValidator={Validators.isLocationInDACH} />
      </Form>
    </div>
  );
}
