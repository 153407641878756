import React from "react";
import Code from "../Code";
import Notes from "../Notes"
import { Card, DriveLink, Heading } from "../../../controls";

export default function DriveLinkDemo() {
  return <>
    <Heading>DriveLink</Heading>
    <Notes>
      Google Drive links automatillally have <Code>{`target=_blank`}</Code>
    </Notes>
    <Card>
      <DriveLink driveId="1SgwHki4hhPTI22SQ9RBRE-RSO4q7BZtxwUCnlUPjlxk/edit">Example Document</DriveLink>
    </Card>
  </>
}


// return <Card>
    

// <div>
//   
// </div>
// </Card>