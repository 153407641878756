import Api from "../../../util/api2"; 
import { AdventureImageMapper } from "./AdventureImage.Mapper";
import { AdventureImage, AdventureImagePost } from "./AdventureImage";
import { RepositoryFactory } from "../_Common/RepositoryFactory";

export class AdventureImageRepository {
  private static mapper = new AdventureImageMapper();
  
  // factory methods
  static search = RepositoryFactory.search<AdventureImage, AdventureImageMapper>(this.mapper);
  static create = RepositoryFactory.create<AdventureImage, AdventureImageMapper>(this.mapper);
  static update = RepositoryFactory.update<AdventureImage, AdventureImageMapper>(this.mapper);
  static remove = RepositoryFactory.remove<AdventureImage, AdventureImageMapper>(this.mapper);
  static findById = RepositoryFactory.findById<AdventureImage, AdventureImageMapper>(this.mapper);

  /**
   * Makes a new AdventureImage object
   * @returns {AdventureImagePost}
   */
  static makePost(): AdventureImagePost {
    return this.mapper.fromDb_post({});
  }

  /**
   * Returns all AdventureImages
   * @returns {Promise<AdventureImage[]>}
   */
  static async findAll(): Promise<AdventureImage[]> {
    return await this.search({});
  }

  /**
   * Finds all AdventureImages for a given adventure
   * @param adventureId 
   * @returns {Promise<AdventureImage[]>} 
   */
  static async findForAdventure(adventureId:string) : Promise<AdventureImage[]> {
    return await this.search({AdventureId:adventureId});
  }

  /**
   * Upserts a post for a given adventure image
   * @param adventureImage 
   * @param post 
   */
  static async upsertPost(adventureImage: AdventureImage, post:AdventureImagePost) {
    // upsert
    const payload = {
      arrayName: "Posts",
      id: adventureImage._id,
      item: this.mapper.toDb_post(post)
    }
    const result = await Api.post("adventureImages", "upsertInArray", payload);
    // update posts collection
    const updatedPosts: AdventureImagePost[] = result.data.item.Posts.map((obj:any) => this.mapper.fromDb_post(obj));
    adventureImage.Posts = updatedPosts;
  }

  /**
   * Deletes a post for a given adventure image
   * @param adventureImage 
   * @param post 
   */
  static async deletePost(adventureImage: AdventureImage, post:AdventureImagePost) {
    // delete
    const payload = {
      arrayName: "Posts",
      id: adventureImage._id,
      itemId: post._id
    }
    const result = await Api.post("adventureImages", "deleteInArray", payload);
    // update posts collection
    const updatedPosts: AdventureImagePost[] = result.data.item.Posts.map((obj:any) => this.mapper.fromDb_post(obj));
    adventureImage.Posts = updatedPosts;
  }
}

