// libs
import React, { useState, useEffect } from "react";
import {Api} from "../../../util";
// types
import * as TS from "../../../types";
// components
import DashboardPanel from "./DashboardPanel"
import { Loader, Link, Table, Tr, Td, Text, Icons, Icon } from '../../controls'
import iconTravel from './../../../assets/images/icon_travel.svg'
import { IconProp } from "@fortawesome/fontawesome-svg-core";


export default function Active() {
  // state
  const [current, setCurrent] = useState<any|null>(null);

  // mount
  useEffect(() => {
    const load = async() => {
      // get data from api
      const result = await Api.post('adventures/search', {filter:{Status:TS.AdventureStatus.Ready, IsTest:false}, projection:{RecieverName:1, ReserverName:1, StartTime:1, EndTime:1}})

      // prepare the data and update state
      const today = new Date();
      const current: {ready:any[], active:any[], today:any[]} = {
        ready:[],
        active:[],
        today:[]
      }
      let start: Date;
      let end: Date;

      if(result.data) {
        result.data.forEach((s:any) => {
          current.ready.push(s);
          start = new Date(s.StartTime);
          end = new Date(s.EndTime);
          if((start <= today) && (end >= today)) {
            current.active.push(s);
          }
          else if(start.getDate() === today.getDate() && start.getMonth() === today.getMonth() && start.getFullYear() === today.getFullYear()) {
            if(start > today) {
              current.today.push(s);
            }
          }
        })
        setCurrent(current);
      }
      
    }
    load();
  }, [])

  // render
  let content = null
  if(current === null) {
    content = <Loader text="lade aktive Überraschungen ..."/>
  }
  else {
    const createSummary = (items:any, adj:string, icon: IconProp) => {
      const noun = items.length === 1 ? "Überraschung" : "Überraschungen"
      const verb = items.length === 1 ? "ist" : "sind"
      const sentence = `${items.length} ${noun} ${verb} ${adj}`
      return (
        <Text><Icon icon={icon} /> {sentence}</Text>
      )
    }

    let createTable = (items:any[]) => {
      let rows = items.map((s,index) => {
        const link = (
          <Link to={`/surprise/${s._id}`} target="_blank">
            {`${s.ReserverName || "unbekannt"} für ${s.RecieverName || "unbekannt"}`}
          </Link>
        );

        return (
          <Tr key={index}>
            <Td>- {link}</Td>
          </Tr>
        )
      })

      let table = null
      if(rows.length > 0 ) {
        table = (
          <Table>{rows}</Table>
        )
      }
      return table
    }

    content = (
      <div>
        {createSummary(current.active, 'aktiv', Icons.PersonWalking)}
        {createTable(current.active)}
        <br/>
        {createSummary(current.today, 'für heute noch geplant', Icons.Clock)}
        {createTable(current.today)}
      </div>
    )
  }
  return (
    <DashboardPanel title="Laufende" smallOnDesktop={true} smallOnMobile={false} icon={iconTravel} iconRounded={false}>
      {content}
    </DashboardPanel>
  )
}