import React, { useEffect, useState } from "react";
import { Breadcrumb, Heading, List, ListColumn, Loader, Card, Modal, useModal } from "../../controls";
import Edit from "./Region.Edit";
import * as DA from "../../../types/DataAccess";

const columns:ListColumn[] = [
  { header: "Code", path: "code" },
  { header: "Name", path: "name", print: (r:DA.Region) => r.title.de, sort: (a:DA.Region, b:DA.Region) => a.title.de.localeCompare(b.title.de)},
  { header: "Währung", path:"currencyCode" },
  { header: "Position", path:"position", sort: (a,b) => a > b ? 1 : -1 }
]

export default function RegionList() {
  // state
  const [loaded, setLoaded] = useState<boolean>(false);
  const [regions, setRegions] = useState<DA.Region[]>([]);
  const [modal, setModal] = useModal<"edit">();

  // mount
  useEffect(() => {
    load();
  }, []);

  // loads regions
  const load = async() => {
    const regions = await DA.RegionRepository.findAll();
    setRegions(regions);
    setLoaded(true);
  }

  // user wants to edit
  const onClickEdit = (region: DA.Region) => {
    setModal("edit", "Region bearbeiten", {region});
  }

  // user wants to create a new one
  const onClickCreate = () => {
    const region = DA.RegionRepository.make();
    region.position = 99;
    setModal("edit", "Neue Region erstellen", {region});
  }

  // render
  let content = null;
  if(!loaded) {
    content = <Loader />
  }
  else {
    content = <>
      <List 
        columns={columns} 
        items={regions} 
        onRowClick={(item) => onClickEdit(item)} 
        getKey={item => item.code} 
        showCsvExport={true} 
        onClickCreate={onClickCreate}
      />
    </>
  }
  return (
    <>
      <Breadcrumb links={[ {title:'Home', to:'/'}]} location="Regionen" />
      <Heading>Regionen</Heading>
      <Card>
        {content}
      </Card>
      <Modal {...modal}>
        {modal.action === "edit" && <Edit {...modal.params} onUpdate={load} stopEdit={modal.onClose} />}
      </Modal>
    </>
  )
}