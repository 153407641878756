import { BulkOrder, BulkOrderAddress, BulkOrderCustomer, BulkOrderFees, BulkOrderItem } from "./BulkOrder";
import { Mapper } from "../_Common/Mapper";


export class BulkOrderMapper extends Mapper<BulkOrder> {
  public apiEndpoint = "bulkorders";

  public toDb(bulkOrder: BulkOrder): any {
    return {
      _id: bulkOrder._id,
      fees: this.toDb_bulkOrderFees(bulkOrder.fees),
      item: this.toDb_bulkOrderItem(bulkOrder.item),
      customer: this.toDb_bulkOrderCustomer(bulkOrder.customer),
      addresses: bulkOrder.addresses.map(a => this.toDb_bulkOrderAddress(a)),
      adventures: bulkOrder.adventures,
      paidOn: bulkOrder.paidOn,
      notes: bulkOrder.notes,
      // createdOn: bulkOrder.createdOn is automatically set by mongoose as createdAt
    }
  }

  private toDb_bulkOrderFees(fees: BulkOrderFees): any {
    return {
      packaging: fees.packaging,
      shipping: fees.shipping,
      processing: fees.processing
    }
  }

  private toDb_bulkOrderItem(item: BulkOrderItem): any {
    return {
      count: item.count,
      value: item.value,
      packaging: item.packaging, 
      from: item.from ,
      message: item.message,
      hideValue: item.hideValue
    }
  }

  private toDb_bulkOrderCustomer(customer: BulkOrderCustomer): any {
    return {
      company: customer.company,
      contactName: customer.contactName,
      contactPhone: customer.contactPhone,
      contactEmail: customer.contactEmail
    }
  }

  private toDb_bulkOrderAddress(address: BulkOrderAddress): any {
    return {
      kind: address.kind,
      company: address.company,
      firstName: address.firstName,
      lastName: address.lastName,
      street1: address.street1,
      street2: address.street2,
      zip: address.zip,
      city: address.city,
      country: address.country,
      email: address.email
    }
  }



  public fromDb(obj: any): BulkOrder {
    return {
      _id: obj._id,
      fees: this.fromDb_bulkOrderFees(obj.fees || {}),
      item: this.fromDb_bulkOrderItem(obj.item || {}),
      customer: this.fromDb_bulkOrderCustomer(obj.customer || {}),
      addresses: (obj.addresses || []).map((a:any) => this.fromDb_bulkOrderAddress(a)),
      adventures: obj.adventures || [],
      paidOn: obj.paidOn || null,
      notes: obj.notes || null,
      createdAt: obj.createdAt || new Date(),
    }
  }

  private fromDb_bulkOrderFees(obj: any): BulkOrderFees {
    return {
      packaging: obj.packaging || 0,
      shipping: obj.shipping || 0,
      processing: obj.processing || 0
    }
  }

  private fromDb_bulkOrderItem(obj: any): BulkOrderItem {
    return {
      count: obj.count || 0,
      value: obj.value || 0,
      packaging: obj.packaging || "METALLBOX", // TODO this is hardcoded
      from: obj.from || null,
      message: obj.message || null,
      hideValue: obj.hideValue || false,
    }
  }

  private fromDb_bulkOrderCustomer(obj: any): BulkOrderCustomer {
    return {
      company: obj.company || "",
      contactName: obj.contactName || "",
      contactPhone: obj.contactPhone || "",
      contactEmail: obj.contactEmail || ""
    }
  }

  private fromDb_bulkOrderAddress(obj: any): BulkOrderAddress {
    return {
      kind: obj.kind || null,
      company: obj.company || "",
      firstName: obj.firstName || "",
      lastName: obj.lastName || "",
      street1: obj.street1 || "",
      street2: obj.street2 || "",
      zip: obj.zip || "",
      city: obj.city || "",
      country: obj.country || "",
      email: obj.email || ""
    }
  }
}