import environment  from './environment'

const config = {
  development: {
    url:'http://localhost:3000'
  },
  staging: {
    url:'https://admin-staging.appentura.ch'
  },
  production: {
    url:'https://admin.appentura.ch'
  }
}

// NOTE: the APPENTURA_ENV variable is delivered by Webpack via a plugin (see plugins section in webpack.config.js'. There are three environments: development, staging, production
export default config[environment]