import _ from "lodash";
import React, {useEffect, useState} from "react";
import { Button, Card, ConfirmButton, Icon, Icons, Link, LinkButton, Loader, Row } from ".";
import CSS from "./List.module.scss";


// TODO the key property of ListColumn is not really necessary, absolutely sufficient to use the index from array functions as the key for table cells
export type ListColumn = {
  header: string,
  key?: string,
  path: any, // TODO
  print?: (v:any) => React.ReactNode|React.ReactNode[]|null,
  printCsv?: (v:any) => string,
  sort?: (a:any, b:any) => number,
  align?:"left"|"center"|"right",
  hidden?: boolean // only used in csv export
}

export type ListFilter = {
  label: string,
  onChange:(value:any) => void,
  kind:"checkbox",
  initialValue: any
}

type ListProps = {
  columns: Array<ListColumn>,
  filters?: Array<ListFilter>,
  items: Array<any>|null,
  getKey: (v:any) => string,
  onRowClick?: (v:any) => void, // TODO rename onClickRow
  onClickEdit?: (v:any) => void,
  onClickDelete?: (v:any) => void,
  onClickCreate?: Function,
  hideSearch?: boolean,
  hideHeader?: boolean,
  hideFooter?: boolean,
  showCsvExport?: boolean,
  csvExportFilename?: string,
  sortColumnKey?: string,
  filter?: (v:any) => boolean,
  rowTo?: (v:any) => string
}
export function List({columns, items, filters, getKey, onRowClick, hideSearch, hideHeader, hideFooter, showCsvExport, csvExportFilename, sortColumnKey, filter, onClickEdit, onClickDelete, onClickCreate, rowTo}: ListProps) {
  // state
  const [sortInfo, setSortInfo] = useState({
    index:0,
    direction: 1
  })
  const [searchText, setSearchText] = useState("");
  const [csv, setCsv] = useState<string|null>(null);
  const [filterValues, setFilterValues] = useState<{index:number, value:any}[]>((filters || []).map((f, index) => {
    return {index, value:f.initialValue};
  }));

  // mount 
  useEffect(() => {
    if(sortColumnKey) {
      const index = columns.findIndex(c => c.key === sortColumnKey);
      if(index >= 0) {
        setSortInfo({index, direction:1});
      }
    }
  }, [sortColumnKey]);

  // mount (filters)
  useEffect(() => {
    const filterValues = (filters || []).map((f, index) => {
      return {index, value:f.initialValue};
    })
    setFilterValues(filterValues);
  }, [filters])


  /**
   * Changes sort column and direction
   * @param {number} index 
   */
  const onHeaderClick = (index:number) => {
    if(index === sortInfo.index) {
      setSortInfo({index, direction: -1 * sortInfo.direction})
    }
    else {
      setSortInfo({index, direction: 1});
    }
  }

  /**
   * User did input text in search field
   * @param {any} e 
   */
  const onChangeSearchText = (e:any) => {
    setSearchText(e.target.value);
    setCsv(null);
  }

  /**
   * User wants to create csv
   */
  const onClickCreateCsv = () => {
    const csv = _createCsv(columns, itemsToDisplay);
    setCsv(csv);
  }

  // render loading
  if(!columns || !items) {
    return <Loader text="lade Daten ..." />
  }

  // render
  const headerCells = columns.filter(column => !column.hidden).map((column, index) => <td key={column.key || index} onClick={() => onHeaderClick(index)}>{column.header}</td>);
  if(onClickDelete) {
    headerCells.push(<td key="action.delete"></td>)
  }
  if(onClickEdit) {
    headerCells.push(<td key="action.edit"></td>)
  }
  // filter and sort the items
  const itemsToDisplay = items
    .filter(item => {
      let s = searchText.trim().toLowerCase();
      if(s.length > 0) {
        return columns.some(c => _.isString(_print(item, c)) && _print(item, c).toLowerCase().includes(s));
      }
      else {
        return true;
      }
    })
    .filter(item => {
      if(!filter) {
        return true;
      }
      return filter(item);
    })
    .sort((a, b) => {
      const sortColumn = columns[sortInfo.index];
      if(sortColumn.sort) {
        return sortColumn.sort(a, b) * sortInfo.direction;
      }
      else {
        return _print(a, sortColumn).localeCompare(_print(b, sortColumn)) * sortInfo.direction;
      }
    });

  const itemRows = itemsToDisplay
    .map(item => {
      const cells = columns.filter(column => !column.hidden).map((column, index) => {
        // 
        // create cells
        const align = column.align || "left";
        const css = CSS[align]; // column.align || "left";
        if(rowTo) {
          return <td key={column.key || index} className={css}>
            <Link to={rowTo(item)}>
              {_print(item, column)}
            </Link>
          </td>
        }
        else if(onRowClick) {
          return <td key={column.key || index} onClick={() => onRowClick(item)}  className={css}>{_print(item, column)}</td>  
        }
        else {
          return <td key={column.key || index} className={css}>{_print(item, column)}</td>
        }
      })
      if(onClickDelete) {
        cells.push(<td key="action.delete"><ConfirmButton onConfirm={() => onClickDelete(item)}><Icon icon={Icons.Trash} /></ConfirmButton></td>);
      }
      if(onClickEdit) {
        cells.push(<td key="action.edit"><Button size="small" onClick={() => onClickEdit(item)}><Icon icon={Icons.Edit} /></Button></td>);
      }
      // create row
      return <tr key={getKey(item)}>{cells}</tr>
      
    })
  let searchReset = null;
  if(items.length > itemRows.length) {
    searchReset = <> - <span className={CSS.reset} onClick={() => setSearchText("")}>Suche zurücksetzen</span></>
  }

  // display search panel?
  let search = null;
  if(!hideSearch) {
    search = (
      <div className={CSS.search}>
        <input type="text" value={searchText} onChange={onChangeSearchText} placeholder="Suchbegriff eingeben" />
      </div>
    )
  }
  // display header?
  let header = null;
  if(!hideHeader) {
    header = <thead><tr>{headerCells}</tr></thead>
  }
  // display footer?
  let footer = null;
  if(!hideFooter) {
    footer = (
      <div className={CSS.footer}>
        {itemRows.length} von {items.length} Datensätzen {searchReset}
      </div>
    )
  }

  // display create button?
  let createButton = null;
  if(onClickCreate) {
    createButton = <Button size="small" onClick={() => onClickCreate()}><Icon icon={Icons.Plus} /> Eintrag hinzufügen</Button>
  }
  // display csv export?
  let csvExport = null;
  if(showCsvExport) {
    if(csv) {
      csvExport = <LinkButton download={csvExportFilename} to={"data:text/csv;charset=utf-8," + encodeURIComponent(csv)}><Icon icon={Icons.ArrowDown} /> CSV herunterladen</LinkButton>;
    }
    else {
      csvExport = <Button size="small" onClick={onClickCreateCsv}><Icon icon={Icons.Table} /> CSV erzeugen</Button>
    }
  }

  // filters?
  let filtersDiv = null;
  if(filters) {
    const filterDivs = filters.map((filter, index) => {
      if(filter.kind === "checkbox") {
        return (
          <div key={index} className={CSS.checkbox_filter}>
            <label>
              <input 
                type="checkbox" 
                checked={filterValues.find(fv => fv.index === index)!.value ? true : false} 
                onChange={(e:any) => {
                  const checked = e.target.checked ? true : false;
                  filter.onChange(checked);
                  const updatedFilterValues = filterValues.map(fv => {
                    if(fv.index === index) {
                      fv.value = checked;
                    }
                    return fv;
                  })
                  setFilterValues(updatedFilterValues);
                }} 
              />
              <span>{filter.label}</span>
            </label>
            
        </div>)
      }
      else {
        return <div key={index}>unhandled filter kind '${filter.kind}'</div>
      }
    })
    filtersDiv = <div className={CSS.filters}>{filterDivs}</div>
  }
  
  // render
  return (
    <div className={CSS.container}>
      <Row className={CSS.actions}>
        {csvExport}{createButton}
      </Row>
      {filtersDiv}
      {search}
      <table>
      {header}
      <tbody>{itemRows}</tbody>
      </table>
      {footer}
    </div>
  )
}

function _print(item:any, column:ListColumn) {
  let value;
  if(column.print) {
    value = column.print(item);
  }
  else if(column.path) {
    value =  _.get(item, column.path);
  }
  else {
    value = "ERROR - neither print nor path provided by column definition"
  }
  return value || "";
}

function _createCsv(columns:ListColumn[], items:any[]): string {
  const lines:string[] = [];
  // add header line
  lines.push(columns.map(column => column.header).join(";"));
  // add items
  items.forEach(item => {
    const values = columns.map(column => {
      // get value and get rid of ; and linebreaks
      let value = _.get(item, column.path);
      if(column.printCsv) {
        value = column.printCsv(item);
      }
      let valueFixed = String(value || "").trim().replace(';',':').replace('\n',"/");
      return valueFixed
    });
    lines.push(values.join(";"));
  })
  // create the csv and return it
  const csv = lines.join("\n");
  return csv;
}