import React from "react";
import "./Step.Section.scss";

type SectionProps = {
  title?:string,
  children:React.ReactNode|React.ReactNode[],
  className?:string
}
export default function Section({title, children, className} : SectionProps) {
  // title?
  let titleDiv = null;
  if(title && title.trim().length > 0) {
    titleDiv = <div className="section-title">{title}</div>
  }
  // render
  return (
    <div className={`c-step-section ${className || ""}`}>
      {titleDiv}
      {children}
    </div>
  )
}