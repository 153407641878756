import _ from "lodash";
import React, {useContext, useState} from "react";
import {SurpriseContext} from "../Surprise.Context";
import { CardSection, Modal, Validators, Value } from "../../../controls";
import GenericEdit from "../Generic.Edit";

export default function Doner() {
  // render
  return (
    <CardSection title="Schenker">
      <Row label={"Name"} path={"ReserverName"} kind="textinput" />
      <Row label={"E-Mail"} path={"ReserverEmail"} kind="textinput" validate={Validators.isEmail("bitte gültige E-Mail Adresse eingeben")} />
    </CardSection>
  );
}


type RowProps = {
  label: string,
  path: string,
  kind: any,
  validate?: any
}
function Row({label, path, kind, validate} : RowProps) {
  // state
  const [mode, setMode] = useState<"edit"|"view">("view");
  // context
  const SC = useContext(SurpriseContext);

  // edit button even handler
  const startEdit = () => setMode("edit");
  const stopEdit = () => setMode("view");
  
  // render
  return <>
    <Value label={label} onClick={startEdit}>{_.get(SC.surprise, path) || ""}</Value>
    <Modal isOpen={mode==="edit"} title={`${label} bearbeiten`} onClose={stopEdit}>
      <GenericEdit path={path} label={label} kind={kind} validate={validate} stopEdit={stopEdit} />
    </Modal>
  </> 
}