import React, { useState, useEffect, useContext } from "react";
import { SurpriseContext } from "../Surprise.Context";
import { Form, Dropdown, Loader } from "../../../controls";
import * as TS from "../../../../types";
import * as DA from "../../../../types/DataAccess";

type Selectable = {
  value:string, label:string
}

type OperatorEditProps = {
  stopEdit: () => void,
}
export default function OperatorEdit({stopEdit} : OperatorEditProps) {
  // context
  const SC = useContext(SurpriseContext);
  // state
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [entity, setEntity] = useState<any|null>(null);
  const [operators, setOperators] = useState<Selectable[]>([])
  
  // mount
  useEffect(() => {
    const load = async () => {
      const activeUsers = await DA.UserRepository.search({isActive:true});
      const operators: Selectable[] = activeUsers.map(user => {
        return { value: user.email, label: user.firstName};
      })
      operators.unshift({value:"-", label:"kein Operator zugewiesen"});
      
      setEntity({email: SC.surprise.Operator || "-"});
      setOperators(operators);
      setIsLoading(false);
    }
    
    load();
  }, []);


  // form save event handler
  const save = async({changeset}:any) => {
    // save
    const email = changeset.email.trim() !== "-" ? changeset.email.trim() : null;
    await SC.updateAtPath("Operator", email, false);
    stopEdit();
  }

  // render
  if(isLoading) {
    return <Loader text="lade Benutzerdaten ..." />
  }
  else {
    return (
      <div>
        <Form entity={entity} 
          onSave={save} 
          onCancel={stopEdit}
          busy={false}
        >
          <Dropdown path="email" label="Operator" options={operators} />
        </Form>
      </div>
    );
  }
}
