import _ from "lodash";
import React, { useState, useEffect } from "react";
import * as DataAccess from "../../../types/DataAccess";
// components & subviews
import {Alert, Breadcrumb, Button, Card, Form, Heading, Icon, Icons, Loader, TextInput, Value, Validators, Modal} from "../../controls";

// styling
import "./IncidentCategory.List.scss";


// the component
export default function IncidentCategoryList() {
  // state
  const [categories, setCategories] = useState<DataAccess.IncidentCategory[]|null>(null);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [categoryToEdit, setCategoryToEdit] = useState<DataAccess.IncidentCategory|null>(null);
  const [subcategoryToEdit, setSubcategoryToEdit] = useState<DataAccess.IncidentCategorySubcategory|null>(null);

  // mount
  useEffect(() => {
    load();
  }, []);

  // load data
  const load = async() => {
    setCategories(null);
    const categories = await DataAccess.IncidentCategoryRepository.searchAll();
    setCategories(categories);
  }
  
  // user wants to add / update a category
  const onClickAddUpdateCategory = (category: DataAccess.IncidentCategory|null) => {
    const title = category ? "Kategorie bearbeiten" : "Neue Kategorie erfassen";
    category = category || DataAccess.IncidentCategoryRepository.make(); 
    setModalTitle(title);
    setCategoryToEdit(category);
  }

  // user wants to add / update subcategory
  const onClickAddUpdateSubcategory = (category: DataAccess.IncidentCategory, subcategory:DataAccess.IncidentCategorySubcategory|null) => {
    const title = subcategory ? "Unterkategorie bearbeiten" : "Neue Unterkategorie erfassen";
    subcategory = subcategory || DataAccess.IncidentCategoryRepository.makeSubcategory();
    setModalTitle(title);
    setCategoryToEdit(category);
    setSubcategoryToEdit(subcategory!);
  }

  // closes dialog
  const closeDialog = () => {
    setCategoryToEdit(null);
    setSubcategoryToEdit(null);
  }
  

  // pre-render
  let content;
  if(_.isNil(categories)) {
    content = <Loader text="lade Kategorien ..." />;
  }
  else {
    const addButton = <Button size="small" onClick={() => onClickAddUpdateCategory(null)}><Icon icon={Icons.Plus} />&nbsp;Kategorie hinzufügen</Button>;
    if(categories.length === 0) {
      content = <>
      <Alert size="medium" title="Keine Kategorien" intent="warning">
        Es wurden noch keine Kategorien definiert.
      </Alert>
      {addButton}
      </>
    }
    else {
      const rows:any[] = [];
      categories.forEach(category => {
        rows.push(
          <tr key={category._id}>
            <td>{category.title}</td>
            <td>
              <Button size="small" onClick={() => onClickAddUpdateCategory(category)}><Icon icon={Icons.Edit} /></Button>
            </td>
            <td>
              <Button size="small" onClick={() => onClickAddUpdateSubcategory(category, null)}><Icon icon={Icons.Plus} />&nbsp;Unterkategorie</Button>
            </td>
          </tr>
        );
        category.subcategories.forEach((subcategory:any) => {
          rows.push(
            <tr key={subcategory._id}>
              <td><Icon icon={Icons.Minus} />&nbsp;{subcategory.title}</td>
              <td>
                <Button size="small" onClick={() => onClickAddUpdateSubcategory(category, subcategory)}><Icon icon={Icons.Edit} /></Button>
              </td>
              <td></td>
            </tr>
          );
        })
      });
      content = <>
        <table><tbody>{rows}</tbody></table>
        {addButton}
      </>
    }
  }
  // modal content
  let modalContent = null;
  let displayModal = false;
  if(categoryToEdit !== null) {
    if(subcategoryToEdit !== null) {
      modalContent = <EditSubcategory category={categoryToEdit} subcategory={subcategoryToEdit} load={load} closeDialog={closeDialog} />;
    } else {
      modalContent = <EditCategory category={categoryToEdit} load={load} closeDialog={closeDialog} />;
    }
    displayModal = true;
  }
  // render
  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}]} location="Kategorien zur Auswertung von Bewertungen" />
    <Heading>Kategorien zur Auswertung von Bewertungen</Heading>
    <Card id="v-incidentcategory-list">
      {content}
    </Card>
    <Modal isOpen={displayModal} title={modalTitle} onClose={closeDialog}>
      {modalContent}
    </Modal>
  </>
}

type EditCategoryProps = {
  category: DataAccess.IncidentCategory,
  load: () => {},
  closeDialog: VoidFunction,
}
function EditCategory({category, load, closeDialog} : EditCategoryProps) {
  // state
  const [busy, setBusy] = useState(false);

  // cancel
  const onCancel = () => {
    closeDialog();
  }

  // save
  const onSave = async(formResult:any) => {
    setBusy(true);
    if(category._id) {
      await DataAccess.IncidentCategoryRepository.update(category._id, formResult.changeset);
    }
    else {
      category.title = formResult.merge(category).title;
      await DataAccess.IncidentCategoryRepository.create(category);
    }
    load(); 
    closeDialog();
  }

  // render
  return <>
    <Form onCancel={onCancel} onSave={onSave} entity={category} busy={busy}>
      <TextInput path="title" label="Name der Kategorie" validate={Validators.isRequired("bitte einen Namen für die Kategorie eingeben")} disabled={busy} />
    </Form>
  </>
}

type EditSubcategoryProps = {
  category: DataAccess.IncidentCategory,
  subcategory: DataAccess.IncidentCategorySubcategory,
  load: () => {},
  closeDialog: VoidFunction
}
function EditSubcategory({category, subcategory, load, closeDialog} : EditSubcategoryProps) {
  // state
  const [busy, setBusy] = useState(false);

  // cancel
  const onCancel = () => {
    closeDialog();
  }

  // save
  const onSave = async(formResult:any) => {
    setBusy(true);
    if(subcategory._id) {
      subcategory.title = formResult.merge(subcategory).title;
      await DataAccess.IncidentCategoryRepository.update(category._id!, {subcategories:category.subcategories});
    }
    else {
      subcategory.title = formResult.merge(subcategory).title;
      const subcategories = category.subcategories.map((sc:any) => sc);
      subcategories.push(subcategory);
      await DataAccess.IncidentCategoryRepository.update(category._id!, {subcategories});
    }
    load(); 
    closeDialog();
  }

  // render
  return <>
    <Form onCancel={onCancel} onSave={onSave} entity={subcategory} busy={busy}>
      <Value label="Kategorie">{category.title}</Value>
      <TextInput path="title" label="Name der Unterkategorie" validate={Validators.isRequired("bitte einen Namen für die Unterkategorie eingeben")} disabled={busy} />
    </Form>
  </>
}