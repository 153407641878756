import React, { useContext, useState } from "react";
import * as TS from "../../../../types";
import { Button, ConfirmButton, CardSection, Icon, Icons, Modal, Form, NumberInput, Validators, Alert, Table, Tr, Td } from "../../../controls";
import { ActivityContext } from "../ActivityContext";

function PriceConfigurations() {
  // context
  const AC = useContext(ActivityContext)!;

  // state
  const [busy, setBusy] = useState(false);
  const [configurationToEdit, setConfigurationToEdit] = useState<TS.ActivityRegionVariantPriceConfiguration|null>(null);

  // user wants to edit an item
  const onClickEdit = (configuration:TS.ActivityRegionVariantPriceConfiguration) => {
    setConfigurationToEdit(configuration);
    //MC.push("Preiskonfiguration bearbeiten", <Edit priceConfiguration={configuration} saveEdit={saveEdit} />);
  }
  const stopEdit = () => setConfigurationToEdit(null);

  // user wants to delete an item
  const onClickDelete = async(configurationToDelete: any) => {
    const priceConfigurations = AC.regionVariant!.priceConfigurations.filter(pc => pc._id !== configurationToDelete._id);
    await AC.updateRegionVariant(AC.regionVariant!._id!, {priceConfigurations: priceConfigurations.map(pc => pc.toDb())});
  }

  // user wants to add an item
  const onClickAdd = async() => {
    // create a new config - if we already have configs, the newly created one attempts to make sense of the existing ones and add a reasonable next item
    const priceConfigurations = AC.regionVariant!.priceConfigurations.map(pc => pc);
    const newConfig = new TS.ActivityRegionVariantPriceConfiguration();
    newConfig.participantCount = 1;
    newConfig.price = 10;
    if(priceConfigurations.length > 0) {
      const last = priceConfigurations.sort((a,b) => a.participantCount - b.participantCount)[priceConfigurations.length - 1];
      newConfig.participantCount = last.participantCount + 1;
      newConfig.price = newConfig.participantCount * Math.round(last.price / last.participantCount);
    }
    priceConfigurations.push(newConfig);

    // save
    setBusy(true);
    await AC.updateRegionVariant(AC.regionVariant!._id!, {priceConfigurations: priceConfigurations.map(pc => pc.toDb())});
    setBusy(false);
  }

  // updates an existing price configuration
  const saveEdit = async(configurationToUpdate: TS.ActivityRegionVariantPriceConfiguration) => {
    const priceConfigurations = AC.regionVariant!.priceConfigurations.map(pc => {
      return pc._id === configurationToUpdate._id ? configurationToUpdate : pc; 
    });
    // save
    await AC.updateRegionVariant(AC.regionVariant!._id!, {priceConfigurations: priceConfigurations.map(pc => pc.toDb())});
  }

  // pre-render configurations
  const rows = AC.regionVariant!.priceConfigurations.map(pc => 
    <Tr key={pc._id}>
      <Td>{pc.participantCount}</Td>
      <Td>{pc.price}</Td>
      <Td><Button size="small" onClick={() => onClickEdit(pc)} busy={busy} ><Icon icon={Icons.Edit} /></Button></Td>
      <Td><ConfirmButton busy={busy} onConfirm={() => onClickDelete(pc)} ><Icon icon={Icons.Trash} /></ConfirmButton></Td>
    </Tr>
  );

  // pre-render issues
  const issues = AC.regionVariant ? <Issues regionVariant={AC.regionVariant} /> : null;

  // render
  return <>
    <CardSection title="Preiskonfigurationen">
      <div>
        <Table>
          <Tr>
            <Td>Teilnehmer</Td><Td>Preis</Td><Td></Td><Td></Td>
          </Tr>
          {rows}
        </Table>
      </div>
      <Button size="small" busy={busy} onClick={onClickAdd}>Konfiguration hinzufügen</Button>
      {issues}
    </CardSection>
    <Modal isOpen={configurationToEdit !== null} title="Preiskonfiguration bearbeiten" onClose={stopEdit}>
      <Edit priceConfiguration={configurationToEdit!} saveEdit={saveEdit} stopEdit={stopEdit} />
    </Modal>
  </>
}

type IssuesProps = {
  regionVariant: TS.ActivityRegionVariant
}
function Issues({regionVariant} : IssuesProps) {
  const issues = regionVariant.validatePriceConfigurations();
  // no issues? no render
  if(issues.valid) {
    return null;
  }
  // render issues
  const items = issues.issues.map((issue, index) => <li key={index}>{issue}</li>);
  return (
    <Alert title="Fehler in den Preiskonfigurationen" intent="error" size="small">
      <ul>{items}</ul>
    </Alert>
  )
}

type EditProps = {
  priceConfiguration: TS.ActivityRegionVariantPriceConfiguration,
  saveEdit: (updatedPriceConfiguration: TS.ActivityRegionVariantPriceConfiguration) => void,
  stopEdit: () => void,
}
function Edit({priceConfiguration, saveEdit, stopEdit} : EditProps) {
  // state
  const [entity] = useState<any>(priceConfiguration.toDb());
  const [busy, setBusy] = useState(false);

  // user aborts
  const onCancel = () => {
    stopEdit();
  }

  // user wants to save
  const onSave = (formResult:any) => {
    setBusy(true);
    const updatedEntity = formResult.merge(entity);
    const updatedPriceConfiguration = TS.ActivityRegionVariantPriceConfiguration.fromDb(updatedEntity);
    saveEdit(updatedPriceConfiguration);
    stopEdit();
  }

  // render
  return <>    
    <Form entity={entity} onCancel={onCancel} onSave={onSave} busy={busy}>
      <NumberInput disabled={busy} label="Anzahl Teilnehmer" path="participantCount" validate={Validators.isCount("Anzahl Teilnehmer eingeben (ganze Zahl)")} />
      <NumberInput disabled={busy} label="Preis" path="price" validate={Validators.isPrice("Preis eingeben")} />
    </Form>
  </>
}

export default PriceConfigurations;