import React, {useContext, useState, useEffect} from "react";
import _ from "lodash";

import DateTimePicker from "react-datetime-picker";
import DatePicker from "react-date-picker";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import 'react-date-picker/dist/DatePicker.css';

import { FormContext } from "./FormContext";
import { Alert, Label, ValidationError } from ".";
import { Validation } from "./Validation";

import CSS from "./DateInput.module.scss";
import './DateInput.scss';

type RdpValuePiece = Date | null;
type RdpValue = RdpValuePiece | [RdpValuePiece, RdpValuePiece];

type DateInputProps = {
  path?: string,
  value?: Date|null,
  label?: string,
  explanation?: string,
  validate?: (value:Date|null) => Validation,
  disabled?: boolean,
  kind?: "date"|"datetime",
  nullable?: boolean,
  onChange?: (value:Date|null, validation:Validation) => void,
  disableCalendar?: boolean
}
export function DateInput({path, value, label, explanation, validate, disabled, kind, nullable, onChange, disableCalendar}:DateInputProps) {
  // get the context
  const form = useContext(FormContext);

  // if we have a form context, we render with form if not without
  if(form && path) {
    // warn about value not being used if provided
    if(value) {
      console.warn("since DateInput is inside a FormContext and a 'path' was provided, the provided 'value' will be ignored");
    }
    // render the form control
    return FormControl({path, label, explanation, validate, nullable, kind, onChange, disableCalendar}); 
  }
  else if(value !== undefined) {
    // a value was provided
    return Widget({value, label, explanation, validate, disabled, kind, nullable, onChange, disableCalendar});
  }
  // something is missing
  return (
    <Alert intent="error" size="small" title="Implementation Error">
      Expected the path property (if control is inside a Form) or the value property.
    </Alert>
  )
}

type FormControlProps = {
  path:string,
  label?:string,
  explanation?:string,
  kind?: "date"|"datetime",
  nullable?:boolean,
  validate?: (value:Date|null) => Validation,
  onChange?:(v:any, validation:Validation) => void,
  disableCalendar?: boolean
}
function FormControl({path, label, explanation, nullable, kind, validate, onChange, disableCalendar}:FormControlProps) {
  // state
  const [initialValue, setInitialValue] = useState<Date|null>(null);
  // context
  const FC = useContext(FormContext);
  
  // mount
  useEffect(() => {
    const value = _.get(FC.entity, path);
    setInitialValue(value);
  }, [FC.entity, validate, path]);

  // form.resetIndex changed -> reset the item
  useEffect(() => {
    if(FC.resetIndex !== 0) {
      setInitialValue(FC.getOldValue(path));
    }
  }, [FC.resetIndex, path, FC])

  // on change handler
  const onWidgetChange = (value:Date|null, validation: Validation) => {
    FC.onFieldChange(path, value, validation);
    if(onChange) {
      onChange(value, validation);
    }
  }

  // render 
  return (
    <Widget 
      value={initialValue} 
      label={label} 
      explanation={explanation} 
      kind={kind} 
      validate={validate} 
      onChange={onWidgetChange} 
      nullable={nullable} 
      disableCalendar={disableCalendar} 
    />
  );
}

type WidgetProps = {
  value: Date|null,
  label?: string,
  explanation?: string,
  kind?: "date"|"datetime",
  nullable?: boolean,
  validate?: (v:Date|null) => Validation,
  disabled?: boolean,
  onChange?: (v:any, validation: Validation) => void,
  disableCalendar?: boolean
}
function Widget({value, label, explanation, kind, nullable, disabled, validate, onChange, disableCalendar}: WidgetProps) {
  // state
  const [validation, setValidation] = useState<Validation>(new Validation(true));
  const [rdpValue, setRdpValue] = useState<RdpValue>(value);

  // mount (and value changed from outside)
  useEffect(() => {
    setRdpValue(value);
    setValidation(validateDate(value));
  }, [value]);

  /**
   * helper function to extract a date from a RdpValue
   * @param value 
   * @returns a date or null if the value is null or a tuple
   */ 
  const getDateFromRdpValue = (value:RdpValue):Date|null => {
    if (value instanceof Date || value === null) {
      // If the value is already a Date or null, return it as is
      return value;
    } else {
      // If the value is a tuple, recursively extract the first element and check its type
      const [firstElement] = value;
      return firstElement;
    }
  }

  /**
   * date input change handler
   * @param value 
   */
  const onDateInputChange = (value:RdpValue) => {
    // get the date and validate
    const date = getDateFromRdpValue(value);
    const validation = validateDate(date);
    // update state
    setValidation(validation);
    setRdpValue(value);
    // any listeners? inform them
    if(onChange) {
      onChange(value, validation);
    }
  }

  /**
   * validates a date
   * @param value 
   * @returns 
   */
  const validateDate = (value:Date|null): Validation => {
    // null value but not nullable?
    if(value === null && !nullable) {
      return new Validation(false, "Bitte ein Datum eingeben");
    }

    // do we have a validation method?
    if(validate) { 
      return validate(value);
    }

    // all fine
    return new Validation(true);
  }

  // render
  let datepicker = null;
  if(kind === "datetime") {
    datepicker = <DateTimePicker 
      onChange={onDateInputChange} 
      value={rdpValue} 
      disableClock={true}
      format="dd.MM.yyyy HH:mm"
      required={nullable ? false : true}
      disabled={disabled}
      disableCalendar={disableCalendar}
    />
  } 
  else {
    datepicker = <DatePicker 
      onChange={onDateInputChange} 
      value={rdpValue} 
      format="dd.MM.yyyy"
      required={nullable ? false : true}
      disabled={disabled}
      disableCalendar={disableCalendar}
    />
  }
  return (
    <div className={CSS.container} id="test2">
      <Label label={label} explanation={explanation}>
        {datepicker}
      </Label>
      <ValidationError validation={validation} />
    </div>
  )
}