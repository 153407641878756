import React, { useContext } from "react";
import { TextInput  } from "../../../../controls";
import { SearchContext } from "../Search.Context";
import FilterGroup from "../Search.FilterGroup";

export default function Activity() {
  // context
  const Search = useContext(SearchContext);

  // onChange
  const onChange = (activityName:string) => {
    const updatedFilter = Search.filter.clone();
    updatedFilter.activityName = activityName;
    Search.reset(updatedFilter);
  }

  // render
  return (
    <FilterGroup title="Aktivität">
      <TextInput placeholder="Name der Aktivität" onChange={onChange} value={Search.filter.activityName} />
    </FilterGroup>
  )
}