import _ from "lodash";
import React, { useEffect, useState } from "react";
import * as S from "../../../../types/AdventureSearch";

// sort type
export type SearchSort = {field:string, direction: "asc"|"desc"};
// sort fields
export type SearchSortField = {field:string, label:string};
export const SortFields:SearchSortField[] = [
  {field:"CreateDate", label: "Bestelldatum"},
  {field:"StartTime", label: "Erlebnisdatum"},
  {field:"Operator", label: "Operator"},
  {field:"HasPriority", label: "hat Priorität"},
  {field:"Order.Packaging.Code", label: "Versandart"},
  {field:"UserStartTime", label: "Wunschdatum"},
];

// context type
type SearchContextType = {
  // values
  filter: S.Filter,
  columns: S.ResultColumn[],
  loadedItems: S.ResultItem[],
  count: number,
  currentPage: number,
  sort: SearchSort,
  isBusy: boolean,
  csv: string|null,

  // functions
  search: (sort?:SearchSort) => Promise<void>,
  page: (what:"next"|"all") => Promise<void>,
  reset: (filter?:S.Filter) => void,
  toggleColumn: (column: S.ResultColumn, checked: boolean) => void,
  toggleColumns: (checked: boolean) => void,
  createCsv: () => Promise<void>,
}

// context
export const SearchContext = React.createContext<SearchContextType>({} as SearchContextType);

// provider state type
type SearchProviderData = {
  count: number,
  loadedItems: S.ResultItem[],
  currentPage: number,
  csv: string|null
}
// provider type
type SearchProviderProps = {
  children: React.ReactNode|React.ReactNode[],
  prefabName: any,
}
// provider
export function SearchProvider({children, prefabName}: SearchProviderProps) {
  // state
  const [data, setData] = useState<SearchProviderData>({count:0, loadedItems:[], currentPage:0, csv:null})
  const [filter, setFilter] = useState<S.Filter>(new S.Filter());
  const [columns, setColumns] = useState<S.ResultColumn[]>([]);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [sort, setSort] = useState<SearchSort>({field:"CreateDate", direction:"desc"});
  
  // mount
  useEffect(() => {
    const loadPrefabData = async(filter:S.Filter) => {
      setIsBusy(true);
      const updatedData = await search(filter, sort);
      setData(updatedData);
      setIsBusy(false);
    }
    const prefab = getPrefab(prefabName);
    setColumns(prefab.columns);
    const filter = prefab.filter;
    setFilter(filter);
    if(prefab.loadData) {
      loadPrefabData(filter);
    }
  }, [prefabName, sort])

  // provider value
  const value = {
    // props
    filter,
    columns,
    loadedItems: data.loadedItems, 
    count: data.count, // TODO rename to totalItemsCount
    isBusy,
    sort,
    currentPage: data.currentPage,
    csv: data.csv,

    // functions
    search: async(updatedSort?:SearchSort) =>  {
      updatedSort = updatedSort || sort;
      setIsBusy(true);
      const updatedState = await search(filter, updatedSort);
      setData(updatedState);
      setIsBusy(false);
      if(sort) {
        setSort(updatedSort);
      }
    },
    page: async(what:"next"|"all") => {
      // get pages we want to load
      let pages = [];
      let updatedPage = data.currentPage;
      if(what === "next") {
        // next page
        updatedPage = data.currentPage + 1;
        pages = [updatedPage];
      }
      else {
        // all remaining pages
        const maxPage = Math.floor(data.count / S.Searcher.PAGE_SIZE);
        for(let i=data.currentPage + 1; i <= maxPage; i++) {
          updatedPage = i;
          pages.push(i);
        }
      }
      // load items
      setIsBusy(true);
      const additionalItems = await S.Searcher.search(filter, pages, sort.field, sort.direction);
      const updatedItems = data.loadedItems.map(item => item);
      additionalItems.forEach(item => updatedItems.push(item));
      setData({...data, loadedItems: updatedItems, currentPage:updatedPage});
      setIsBusy(false);
    },
    createCsv: async() => {
      const csv = await S.Exporter.createCsv(filter);
      setData({...data, csv:csv});
    },
    reset: (filter?:S.Filter) => {
      setData({count:0, loadedItems:[], currentPage:0, csv:null});
      setFilter(filter || new S.Filter());
    },
    toggleColumns: (checked:boolean) => {
      if(checked) {
        const updatedColumns = S.ResultColumns.map(c => c);
        setColumns(updatedColumns);
      }
      else {
        setColumns([]);
      }
    },
    toggleColumn: (column: S.ResultColumn, checked:boolean) => {
      const updatedColumns = columns.filter(c => c !== column);
      if(checked) {
        updatedColumns.push(column);
      }
      setColumns(updatedColumns);
    }
    // loadPrefab: (prefab:string) => {
    //   const defaults = getPrefab(prefab);
    //   setColumns(defaults.columns);
    //   const filter = defaults.filter;
    //   setFilter(filter);
    // }

  }

  // render
  return (
    <SearchContext.Provider value={value}>
      {children}
    </SearchContext.Provider>
  )
}

async function search(filter: S.Filter, sort: SearchSort): Promise<SearchProviderData> {
  const count = await S.Searcher.count(filter);
  const items = await S.Searcher.search(filter, [0], sort.field, sort.direction);
  return {count, loadedItems:items, currentPage:0, csv:null};
}

function getPrefab(name?: string): {columns:S.ResultColumn[], filter:S.Filter, loadData:boolean} {
  const filter = new S.Filter();
  let columns = S.ResultColumnHelper.getColumnsByKeys(["shortId", "ReserverName", "RecieverName"])
  let loadData = true;

  switch(name) {
    case "notfinished":
      filter.stati = [1];
      columns = S.ResultColumnHelper.getColumnsByKeys(["shortId", "ReserverName", "RecieverName"])
      break;
    case "ordered":
      filter.stati = [2];
      columns = S.ResultColumnHelper.getColumnsByKeys(["shortId", "CreateDate", "ReserverName", "RecieverName", "Order.Packaging.Code"]);
      break;
    case "scheduled":
      filter.stati = [3];
      columns = S.ResultColumnHelper.getColumnsByKeys(["HasPriority", "UserStartTime", "ScheduleDate", "Operator", "ReserverName", "RecieverName", "ActivityTitles"]);
      break;
    case "organized":
      filter.stati = [4];
      columns = S.ResultColumnHelper.getColumnsByKeys(["HasPriority", "StartTime", "ScheduleDate", "Operator", "ReserverName", "RecieverName", "ActivityTitles"]);
      break;
    case "accepted":
      filter.stati = [5];
      columns = S.ResultColumnHelper.getColumnsByKeys(["HasPriority", "StartTime", "ScheduleDate", "Operator", "ReserverName", "RecieverName", "ActivityTitles"]);
      break;
    case "ready":
      filter.stati = [6];
      columns = S.ResultColumnHelper.getColumnsByKeys(["HasPriority", "StartTime", "Operator", "ReserverName", "RecieverName", "ActivityTitles"]);
      break;
    case "finished,reviewed":
      filter.stati = [7, 8];
      columns = S.ResultColumnHelper.getColumnsByKeys(["HasPriority", "StartTime", "Operator", "ReserverName", "RecieverName", "ActivityTitles", "Rating.Rating", "Rating.Rating2", "Rating.Rating3"]);
      break;
    case "shipping":
      filter.stati = [2, 3, 4, 5, 6];
      filter.isNotShipped = true;
      columns = S.ResultColumnHelper.getColumnsByKeys(["shortId", "ReserverName", "RecieverName", "Order.Packaging.Code"]);
      break;
    case "all":
      filter.stati = [1,2,3,4,5,6,7,8,9,10,11,12]; 
      columns = S.ResultColumnHelper.getColumnsByKeys(["HasPriority", "StartTime", "Operator", "ReserverName", "RecieverName", "ActivityTitles"]);
      break;
    default:
      loadData = false;
      break;
  }

  return {columns, filter, loadData};
}


