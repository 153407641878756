import { Module, ModuleInfo, Theme } from ".";
export class LogosModule implements Module {
  readonly code: string;

  _id?: string;
  countries: string[];
  position: number;
  theme: Theme;
  anchor?: string;

  elements: {
    title: string;
  }

  constructor() {
    this.code = LogosModule.info.code;
    this.countries = ["CH", "DE"];
    this.position = 0;
    this.theme = "primary";
    this.elements = {
      title: "",
    }
  }

  static get info(): ModuleInfo {
    return {
      code: "logos",
      title: "Logos",
      description: "Statische Logos"
    }
  }
}