import * as DA from "../DataAccess";
import * as TS from "../../types";

type BookingRequestWithValue = {
  bookingRequest: DA.BookingRequest,
  value: number
}

export class BookingRequest {
  public static async getForProviderWithValues(providerId:string): Promise<BookingRequestWithValue[]> {
    // get all relevant booking requests
    const bookingRequests = await DA.BookingRequestRepository.search({"provider.id":providerId, "response.accepted":{$exists:true}});

    // get all related adventures
    const adventureIds = bookingRequests.map(bookingRequest => bookingRequest.adventure_id);
    const adventures = await TS.Adventure.search({_id:{$in:adventureIds}});
    
    // calculate the value of each booking request and map
    return bookingRequests.map(bookingRequest => {
      let value = 0;
      // find the adventure
      const adventure = adventures.find(adventure => adventure._id === bookingRequest.adventure_id);
      if(adventure) {
        // find the activity
        const activity = adventure.Activities.find(a => a.ActivityId === bookingRequest.activity.id);
        if(activity && activity.Price) {
          value = activity.Price;
        }
      } 
      // return the booking request with the value
      return {
        bookingRequest, value
      }
    });
  }
}