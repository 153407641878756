import _ from "lodash"
import React, {useContext} from "react";
import Util from "../../../../util/util";
import { SurpriseContext } from "../Surprise.Context";
import { Card, CardSection, Icon, Icons } from "../../../controls";

import "./Weather.View.scss"

export default function Weather({expanded}) {
  // context
  const SC = useContext(SurpriseContext);
  // dictionaries
  let groupNames = {purchase:"Einkauf", schedule:"Einlösung", purchase_schedule_me:"Kauf und Einlösung Appentura.me"}
  let groupSorts = {purchase:1, schedule:2, purchase_schedule_me:3}
  // translation of weather mains (see https://openweathermap.org/weather-conditions)
  let mainTranslate = {thunderstorm:"Gewitter", drizzle:"Niesel", rain:"Regen", snow:"Schnee", mist:"Dunst", smoke:"Rauch", haze:"Dunst", fog:"Nebel", sand:"Sand", dust:"Staub", ash:"Asche", squall:"Böen", tornado:"Tornado", clear:"Klar", clouds:"Wolken"}
  let groups = _
    .chain(SC.surprise.Weather || [])
    .groupBy("event")
    .toPairs()
    .value()

  let groups_jsx = groups
    .sort((a,b) => {
      return (groupSorts[a.event] || 999999) > (groupSorts[b.event] || 999999) ? -1 : 1
    })
    .map(group => {
      let items = group[1]
        .sort((a, b) => {
          return new Date(a.date) > new Date(b.date) ? 1 : -1
        })
        .map(weather => {
          let weathers = weather.data.weather.map((w, index) => {
            return (
              <div key={index} className="v-surprise-weather-weathers-item" title={`${w.main} (${w.description})`}>
                <div>{mainTranslate[w.main.toLowerCase()] || w.main}</div>
                <div>
                  <img src={`http://openweathermap.org/img/w/${w.icon}.png`} alt={`${w.description}`} />
                </div>
              </div>
            )
          })
          return (
            <div key={weather._id} className="v-surprise-weather-entry">
              <div>
                <div>
                  <Icon icon={Icons.Calendar} />&nbsp;
                  {Util.printDateAndTime(weather.date)}
                </div>
                <div>
                  <Icon icon={Icons.MapMarker} />&nbsp;
                  {`${weather.data.city} (${weather.data.country})`}
                </div>
                <div>
                <Icon icon={Icons.ThermometerHalf} />&nbsp;
                  {`${Math.round(weather.data.temperature)}°C`}
                </div>
              </div>

              <div className="v-surprise-weather-weathers">
                {weathers}
              </div>
            </div>
          )
        })
 
      return (
        <CardSection title={groupNames[group[0]] || "Unbekannter Event"} key={group[0]}>
          {items}
        </CardSection>
      )
    })
 
  let content = groups_jsx
  if(groups_jsx.length === 0) {
    content = <div>für diese Überraschung sind keine Wetterdaten vorhanden</div>
  }
  return (
    <Card 
      id="v-surprise-weather" 
      collapsible={true} 
      expanded={expanded} 
      title="Wetter"
      icon={Icons.Weather}
    >
      {content}
    </Card>
  )
}

