import React from "react";
import { Icon, Icons, Link } from ".";
import CSS from "./MapLink.module.scss";

type MapLinkProps = {
  lat:number,
  lng:number,
  zoom?:number,
  children?:React.ReactNode|Array<React.ReactNode>
}
export function MapLink({lat, lng, zoom, children} : MapLinkProps) {
  const anchorText = children || `${lat}, ${lng}`;	
  return <div className={CSS.container}>
    <Link to={`https://maps.google.com/?q=${lat},${lng}&z=${zoom || '8'}`} target="_blank">
      <span className={CSS.text}>{anchorText}</span><Icon icon={Icons.MapMarked} />
    </Link>
  </div>
}
