import React, {useContext, useState} from "react";
import * as TS from "../../../../../types";
import * as BL from "../../../../../types/BusinessLogic";
import { ConfirmButton, Alert, Table, Tr, Td, CardSection } from "../../../../controls";
import { SurpriseContext} from "../../Surprise.Context";

export default function ChangeStatus() {
  // context
  const SC = useContext(SurpriseContext);

  // state
  const [nextStatus, setNextStatus] = useState(2);
  const [note, setNote] = useState("");
  const [busy, setBusy] = useState(false);

  // event handler
  const saveStatus = async() => {
    setBusy(true);
    const currentUser = await BL.User.getCurrent();
    const prevStatus = SC.surprise.Status || TS.AdventureStatus.Ordered;
    const logEntry = { Type:"status", Text:`Status manuell geändert von '${TS.Adventure.getStatusName(prevStatus)}' zu '${TS.Adventure.getStatusName(nextStatus)}'. Grund: ${note}`, Operator: currentUser.email};
    await SC.updateStatus(nextStatus, false, logEntry);
    setNote("");
    setBusy(false);
  }

  // render
  const disabled = busy || note.trim().length === 0;
  const statusOptions = [11,2,3,4,5,6,7,8,9,10].map(option => {
    return <option key={option} value={option}>{TS.Adventure.getStatusName(option)}</option>
  })
  return (
    <CardSection title="Status setzen">

      <Table>
        <Tr>
          <Td label>Status</Td>
          <Td>
            <select onChange={e => setNextStatus(e.target.value)} value={nextStatus} style={{outline:0}}>
              {statusOptions}
            </select>
          </Td>
        </Tr>
        <Tr>
          <Td label>Grund</Td>
          <Td>
            <input type="text" onChange={e => setNote(e.target.value)} value={note} placeholder="bitte einen Grund eingeben" style={{width:"100%"}} />
          </Td>
        </Tr>
        <Tr>
          <Td></Td>
          <Td>
            <ConfirmButton onConfirm={saveStatus} disabled={disabled}>Status setzen</ConfirmButton>
          </Td>
        </Tr>
        <Tr>
          <Td></Td>
          <Td>
            <Alert intent="hint" size="small">
              Achtung: es werden keine Nebeneffekte ausgeführt (z.B. E-Mail Versand)
            </Alert>
          </Td>
        </Tr>
      </Table>
      
    </CardSection>
  )
}
