import environment  from './environment'

const receiptRootFolders = {
  live: {
    id: "0B_JDHwSEOwhPflNDV1BDa2NUaWc1TDItUkNOOXdFOURkM0FTZ20zeHF3Qlk4R0phZFE3dzg",
    path:"Finance > Belege"
  },
  test: {
    id: "0Bw79iBczf9jdSjNaOGdocWtJU2s",
    path:"Entwicklung > UploadTests > Belege"
  }
}

const surpriseDocsRootFolders = {
  live: {
    id: "0Bw79iBczf9jdb2dQcUJsd2FzUnM",
    path:"Operations > Dokumenten-Ablage für Überraschungen"
  },
  test: {
    id: "0Bw79iBczf9jdUWxEbVNxRW5Zbmc",
    path:"Entwicklung > UploadTests > Surprises"
  }
}

const providerDocsRootFolders = {
  live: {
    id:"1YY3Ub3RdgAaBu6xBZ9Z_A7nebj8-2k-S",
    path:"Operations > Dokumente-Ablage für Anbieter"
  },
  test: {
    id: "1r27aug3JGUoN8Pf8Rh6CNkq_o31W9Qvd",
    path: "Entwicklung > UploadTests > Anbieter"
  }
}

const receiptFolders = {
  live: [
    {
      year:2015,
      id:"0B_JDHwSEOwhPfjFST0NCNW56a1ZjVy1ZNkZoR3FILWhESk5yU2xjdkNxYXpORlBjTFRmLUE",
      path:`${receiptRootFolders.live} > 2015`
    },
    {
      year:2016,
      id:"0B_JDHwSEOwhPdlI4WFBSZllVNlE",
      path:`${receiptRootFolders.live} > 2016`
    },
    {
      year:2017,
      id:"0B8br-Tsn1zOkR2hvZDYyN1JCQU0",
      path:`${receiptRootFolders.live} > 2017`
    },
    {
      year:2018,
      id:"13LCFH6k9y5QfbxMvIo-s2iAmEtrYlr-A",
      path:`${receiptRootFolders.live} > 2018`
    },
    {
      year: 2019,
      id: "15WvqSr9rXVAXc7fwqkwAf7eE1M2COSXI",
      path: `${receiptRootFolders.live} > 2019`
    },
    {
      year: 2020,
      id: "1h3pjLTDbelMqlZZ3NWavjqwOJafmjk-8",
      path: `${receiptRootFolders.live} > 2020`
    },
    {
      year: 2021,
      id: "1KqoEgU8tRzUTDOplDgpNgVBynhgqYTTm",
      path: `${receiptRootFolders.live} > 2021`
    },
    {
      year: 2022,
      id: "1GnzaAg6zYMFKs3MdCnRiIKwo-7loRfzM",
      path: `${receiptRootFolders.live} > 2022`
    },
    {
      year: 2023,
      id: "1tMpM82AsqN_DTrHZKg1yUlx-iHI1ioBO",
      path: `${receiptRootFolders.live} > 2023`
    },
    {
      year: 2024,
      id: "1pWgvgHNkwuQlc01RjFJAJgvX2mpCSqnz",
      path: `${receiptRootFolders.live} > 2024`
    },
    {
      year: 2025,
      id: "1Q7HT_0sjjxtp0FXeUZHMS6fp3MvpIBOi",
      path: `${receiptRootFolders.live} > 2024`
    }
  ],
  test: [
    {
      year:2016,
      id:"0Bw79iBczf9jdRDNUYXp4OHVZc3c",
      path:`${receiptRootFolders.test} > 2016`
    },
    {
      year:2017,
      id:"0Bw79iBczf9jddl9ZOTkweEhocDA",
      path:`${receiptRootFolders.test} > 2017`
    },
    {
      year:2018,
      id:"1zWq9noFR0Ff03uuwAmfe9w3w1Ty5Nba_",
      path:`${receiptRootFolders.test} > 2018`
    },
    {
      year:2019,
      id:"19hDfH2jTgYbuMh0nr4D7zB6UOGPF6JOC",
      path:`${receiptRootFolders.test} > 2019`
    },
    {
      year:2020,
      id:"1DGSHUYkY8ddPHRuiyIwHatKSfUxTFlz8",
      path:`${receiptRootFolders.test} > 2020`
    },
    {
      year:2021,
      id:"1k6yfG951O_LsSEAty3aTLBpNUHNy0Jfn",
      path:`${receiptRootFolders.test} > 2021`
    },
    {
      year:2022,
      id:"1nE5l54A8ehtJpTSrO-0EvraDQgg4_0BO",
      path:`${receiptRootFolders.test} > 2022`
    },
    {
      year:2023,
      id:"1mh7aTrCL10Gz6_-RtSHfZuSJaKBmXbS8",
      path:`${receiptRootFolders.test} > 2023`
    },
    {
      year:2024,
      id:"1OGfcSJNu8ywctXxr86hMJmmYQUfr-cna",
      path:`${receiptRootFolders.test} > 2024`
    },
    {
      year:2025,
      id:"1dHX6MZ5Fq2frM4-LrhKEGpgDHEFZQXaW",
      path:`${receiptRootFolders.test} > 2025`
    }
  ]
}


// Drive Access Configuration: Google Developer Console (https://console.developers.google.com)
// Project: Appentura (https://console.developers.google.com/apis/credentials?project=appentura-141207)
// Type: OAuth-2.0-Client-ID
// Access: martin@appentura.ch or stefan@appentura.ch
// Instructions: https://developers.google.com/drive/v3/web/quickstart/js#step_1_turn_on_the_api_name
const CLIENT_ID = "1001489985222-hoohc709llujj9d5b13v4hht8q8188vv.apps.googleusercontent.com" // martin@appentura.ch
//const CLIENT_ID = "68324769109-pb0kk08l3ah6rjijtenjcvb2k805cilh.apps.googleusercontent.com" // martin.imfeld@appentura.ch
//const CLIENT_ID = "515295587884-snm57r4a8qu869jfqlelvit7s86no37c.apps.googleusercontent.com" // teamappentura@gmail.com
const config = {
  development: {
    clientId: CLIENT_ID,
    receiptRootFolder: receiptRootFolders.test,
    receiptFolders: receiptFolders.test,
    surpriseDocsRootFolder: surpriseDocsRootFolders.test,
    providerDocsRootFolder: providerDocsRootFolders.test
  },
  staging: {
    clientId: CLIENT_ID,
    receiptRootFolder: receiptRootFolders.test,
    receiptFolders: receiptFolders.test,
    surpriseDocsRootFolder: surpriseDocsRootFolders.test,
    providerDocsRootFolder: providerDocsRootFolders.test
  },
  production: {
    clientId: CLIENT_ID,
    receiptRootFolder: receiptRootFolders.live,
    receiptFolders: receiptFolders.live,
    surpriseDocsRootFolder: surpriseDocsRootFolders.live,
    providerDocsRootFolder: providerDocsRootFolders.live
  }
}

export default config[environment]