import React, { useState } from "react";
import Code from "../Code";
import Notes from "../Notes";
import { Card, Link, Heading } from "../../../controls";

export default function LinkDemo() {
  return <Card>
    <Heading>Link</Heading>
    <p>External links will automatillally have <Code>{`target=_blank`}</Code></p>
    <div>
      <Link to="/providers">Providers</Link>
    </div>
    <div>
      <Link to="https://www.appentura.ch">Appentura</Link>
    </div>
  </Card>
}

