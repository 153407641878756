import React, {useContext, useState} from "react";
import Config from "../../../../../config";
import * as TS from "../../../../../types";
import * as BL from "../../../../../types/BusinessLogic";
import { ConfirmButton } from "../../../../controls";
import { SurpriseContext } from "../../Surprise.Context";

export default function LiveId() {
  // context
  const SC = useContext(SurpriseContext);

  // state
  const [busy, setBusy] = useState(false);

  // event handler
  const onClickReregister = async() => {
    setBusy(true);
    
    // purge the current live id
    await SC.updateAtPath("LiveId", null, false);
    // update the status to enforce registering live id
    const currentUser = await BL.User.getCurrent();
    const logEntry = { Type:"status", Text:`Status manuell auf READY gesetzt um Registrierung der LiveId zu provozieren.`, Operator: currentUser.email};
    await SC.updateStatus(TS.AdventureStatus.Ready, true, logEntry);
    
    setBusy(false);
  }

  // we do not render this in production
  if(Config.environment === "production") {
    return null;
  }

  // render
  return (
    <>
      <div className="subheading">Live Id neu registrieren</div>
      <div>
        Wurde die LiveId in einer anderen Umgebung (Development, Staging, Production) gesetzt, ist die Live-View nicht verfügbar.<br/>
        Um testen zu können, kann hier die Live-Id neu gesetzt werden.
      </div>
      <div>
        Achtung: dies wird erreicht, indem die gegenwärtige Live-Id auf null UND der Status auf 'Bereit' gesetzt wird.
      </div>
      <div>
        <ConfirmButton onConfirm={onClickReregister} busy={busy}>LiveId neu setzen</ConfirmButton>
      </div>
    </>
  )
}