import React, { useEffect, useState, useContext } from "react";
import * as TS from "../../../types"
import { Alert, Link, Loader, Icon, Icons, CardSection, TextInput, Label } from "../../controls";
import { ActivityContext } from "./ActivityContext";

import CSS from "./Activity.Execution.Subactivities.module.scss";


export default function Subactivities() {
  // context
  const AC = useContext(ActivityContext)!;
  
  // state
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [catalogue, setCatalogue] = useState<TS.Activity[]>([]); // possible subactivities
  //const [data, setData] = useState<any>(null)
  const [search, setSearch] = useState<{text:string, result:TS.Activity[]}>({text:"", result:[]});
  const [activityId] = useState(AC.activity!._id)
  
  // mount
  useEffect(() => {
    //load();
    onSearch("");
  }, [])

  useEffect(() => {
    const loadCatalogue = async() => {
      const items = await TS.Activity.search({"isSubactivity":true});
      const catalogue = items.sort((a,b) => a.title.de.localeCompare(b.title.de));
      setCatalogue(catalogue);
      setIsLoaded(true);
    }
    loadCatalogue();
  }, [])

  // executes search
  const onSearch = (s:string) => {
    let text = s.trim().toLowerCase();
    let result: TS.Activity[] = [];
    if(text.trim().length > 0) {
      result = catalogue.filter(a => a.title.de.toLowerCase().includes(text));
    }
    setSearch({text, result}); 
  }

  const onClickAdd = async(e:any) => {
    await AC.addSubactivity(e.target.dataset.id);
  }

  const onClickRemove = async(e:any) => {
    await AC.removeSubactivity(e.target.dataset.id);
  }

  const onBlurSearchText = (e:any) => {
    if(!(e.relatedTarget && e.relatedTarget.dataset.noblur)) {
      if(search.result.length > 0 || search.text.length > 0) {
        onSearch("");
      }
    }
  }

  // render loading data
  if(!isLoaded || AC.activity === null) {
    return <Loader text="lade Daten ..." />
  }

  // render nothing if the activity is a subactivity
  if(AC.activity!.isSubactivity) {
    // it's not possible to assign a subactivity to a subactivity
    return null;
  }

  // pre-render search result
  const searchResultItems = search.result.map(a => {
    let spanOptional = null
    if(a.isOptionalSubactivity) {
      spanOptional = <span> (optional)</span>
    }
    return (
      <div className={CSS.item} key={a._id || 'part'}>
        <span className={`${CSS.button} ${CSS.add}`} onClick={onClickAdd} data-id={a._id} data-noblur tabIndex={0}>+</span>
        <span className={CSS.title}>{a.title.de}</span>
        {spanOptional}
      </div>
    )
  })

  // pre-render included subactivities
  const subactivities: {id:string, title:string, isOptionalSubactivity:boolean}[] = AC.activity!.subactivities // (data.subactivities || [])
    .map(sa => {
      // get activity from catalogue
      const a = catalogue.find(c => c._id === sa.activity_id);
      if(!a) {
        // TODO can this ever happen? deal with it if so ... FUD: what if the item is no longer part of the catalogue of subactivities? does it matter in the great scheme of things'
        return {id:sa.activity_id, title: "unbekannte Aktivität", isOptionalSubactivity: false};
      }
      else {
        return {id:sa.activity_id, title: a.title.de, isOptionalSubactivity: a.isOptionalSubactivity};
      }
    })
    .sort((a, b) => {return a.title.localeCompare(b.title)})
  const subactivityDivs = [];
  subactivities.forEach(a => {
    let spanOptional = null
    if(a.isOptionalSubactivity) {
      spanOptional = <span> (optional)</span>
    }
    subactivityDivs.push(
      <div className={CSS.item} key={a.id}>
        <span className={`${CSS.button} ${CSS.remove}`} onClick={onClickRemove} data-id={a.id} data-noblur tabIndex={0}>-</span>
        <span className={CSS.title}><Link to={`/activities/${a.id}`} target="_blank">{a.title}</Link></span>
        {spanOptional}
      </div>
    )
  })
  if(subactivityDivs.length === 0) {
    subactivityDivs.push(<div key="none">keine zugewiesene Unteraktivität</div>);
  }

  // content
  const content = (
    <>
      <div className={CSS.list}>
        <Label label="Beinhaltete Aktivitäten / Bestandteile" />
        {subactivityDivs}
      </div>
      <Issues activityId={activityId} />
      <div className={CSS.filter}>
        <TextInput label="Hinzufügen" placeholder="Name der Aktvität, die hinzugefügt werden soll" onChange={onSearch} value={search.text} onBlur={onBlurSearchText} />
      </div>
      <div className={CSS.list}>
        {searchResultItems}
      </div>
    </>
  )
  
  // render
  return (
    <CardSection title="Beinhaltete Aktivitäten">
      <div className={CSS.container}>
        {content}
      </div>
    </CardSection>
  ) 
}

type IssuesProps = {
  activityId: string
}
function Issues({activityId} : IssuesProps) {
  // state
  const [issues, setIssues] = useState<any[]>([]);

  // mount
  useEffect(() => {
    const load = async() => {
      setIssues(await TS.Activity.getSubactivityIssues(activityId));
    }
    load();
  }, [activityId]);

  // no issues? no need to render anything ...
  if(issues.length === 0) { 
    return null; 
  }

  // render
  const items = (issues || []).map((issue, index) => {
    return (
      <div key={index}>
        <Icon icon={Icons.ExclamationTriangle} />&nbsp;{issue}
      </div>
    )
  })
  // TODO use <Alert /> instead
  return (
    <Alert title="Möglicherweise fehlende Daten" size="small" intent="warning">
      {items}
    </Alert>
  )
}