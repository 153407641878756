import _ from "lodash";
import { AdventureDocument } from "./AdventureDocument";
import { Mapper } from "../_Common/Mapper";

export class AdventureDocumentMapper extends Mapper<AdventureDocument> {
  // note: the db collection is named 'surpriseDocuments' and the mongoose model 'SurpriseDocument'. The api action's name is 'adventureDocuments'
  public apiEndpoint = "adventureDocuments";

  public toDb(document: AdventureDocument): any {
    return {
      _id: document._id,
      surprise_id: document.surprise_id,
      driveId: document.driveId,
      driveTitle: document.driveTitle,
      documentDate: document.documentDate || null,
      notes: document.notes
    }
  }

  public fromDb(obj: any): AdventureDocument {
    return {
      _id: obj._id || undefined,
      surprise_id: obj.surprise_id || null,
      driveId: obj.driveId || "",
      driveTitle: obj.driveTitle || "",
      documentDate: obj.documentDate ? new Date(obj.documentDate) : null,
      notes: obj.notes || ""
    }
  }
}