import React, {useEffect, useContext} from "react";
import Util from "../../../util/util"
import { Alert, Card, Heading, Loader, Breadcrumb } from "../../controls";
import { ProviderContext, ProviderProvider } from "./Provider.Context";

import Name from "./Provider.Name";
import Rating from "./Rating";
import BookingSettings from "./Provider.BookingSettings";
import BookingRequests from "./Provider.BookingRequests";
import Notes from "./Provider.Notes";
import DefaultLocation from "./DefaultLocation";
import AssignedActivities from "./AssignedActivities";
import Actions from "./Provider.Actions";
import Contacts from "./Provider.Contacts";
import Addresses from "./Provider.Addresses";
import BankAccounts from "./Provider.BankAccounts";
import Links from "./Provider.Links";
import MonthlyStatements from "./Provider.MonthlyStatements";
import Documents from "./Provider.Documents"; // TODO Documents needs refactoring
import Invoices from "./Provider.Invoices";
import PriceLists from "./Provider.PriceLists";

function Provider(props) {
  // context
  const PC = useContext(ProviderContext);

  // mount
  useEffect(() => {
    // get id from query
    const id = Util.getRouteId(props);
    // and load
    PC.load(id);
  }, []);


  // pre-render
  let archivedAlert = null;
  let breadcrumb_location = "Anbieter";
  let content = <Loader />
  if(PC.provider) {
    breadcrumb_location = PC.provider.name;
    if(PC.provider.isActive === false) {
      archivedAlert = <Alert intent="warn" size="large" title="Anbieter archviert">Dieser Anbieter wurde archiviert. Um ihn wieder zu aktivieren unter <a href="#actions">'Aktionen'</a> den entsprechenden Button verwenden.</Alert>
    }
    content = <>
      <Heading>{`Anbieter: ${PC.provider.name || ''}`}</Heading>
      {archivedAlert}
      <Card>
        <Name />
        <Rating />
      </Card>
      <BookingSettings />
      <BookingRequests providerId={PC.provider._id} />
      <Card title="Standort & Aktivitäten" expanded={false} collapsible={true}>
        <DefaultLocation provider={PC.provider} />
        <AssignedActivities providerId={PC.provider._id} />
      </Card>
      <PriceLists providerId={PC.provider._id} />
      <Contacts />
      <Addresses />
      <Links />
      <BankAccounts />
      <MonthlyStatements />
      <Invoices providerId={PC.provider._id} />
      <Documents />
      <Notes />
      <div id="actions"></div>
      <Actions />
    </>
  }
  // render
  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}, {title:"Anbieter", to:'/providers'}]} location={breadcrumb_location} />
    {content}
  </>
}

export default (props) => <ProviderProvider><Provider {...props} /></ProviderProvider>;
