import _ from "lodash";
import React, { useState, useEffect } from "react";
import * as Cms from "../../../types/Cms";

// components & subviews
import { Breadcrumb, Card, Heading, Modal, List, ListColumn, ListFilter} from "../../controls";
import Create from "./Page.Create";

// list columns
const columns: ListColumn[] = [
  {
    header:"Title",
    path:"title",
    print: (v:any) => v.title,
    sort: (a:any,b:any) => a.title.localeCompare(b.title)
  },
  {
    header:"Pfad",
    path:"path",
    print: (v:any) => v.path,
    sort: (a:any,b:any) => a.path.localeCompare(b.path)
  },
  {
    header:"publiziert",
    path:"isPublished",
    print: (v:any) => v.isPublished ? "ja" : "nein",
    //sort: (a:any,b:any) => (a.isPublished ? 1 : 0 > b.isPublished ? 1: 0) ? 1 : -1
  },
  {
    header: "Länder",
    path: "countries",
    print: (v:any) => v.countries.sort((a:string, b:string) => a.localeCompare(b)).join(", "),
    //sort: (a:any, b:any) => a.countries.join(", ").localeCompare(b.countries.join(", "))
  }
];


// the component
export default function PageList() {
  // state
  const [pages, setPages] = useState<Cms.Page[]|null>(null);
  const [mode, setMode] = useState<"view"|"create">("view");
  // filters
  const [filters] = useState<ListFilter[]>([
    {
      label:"nur publizierte anzeigen", 
      kind:"checkbox", 
      initialValue: true,
      onChange: (checked:any) => { 
        load(checked as boolean);
      }
    }
  ]);

  // mount
  useEffect(() => {
    load(true)
  }, [])

  // loads data
  const load = async(onlyPublished:boolean) => {
    setPages(null);
    const pages: Cms.Page[] = await Cms.Page.getByPublishedStatus(onlyPublished);
    setPages(pages);
  }

  // user wants to create a new one
  const onClickCreate = () => setMode("create");
  // user wants to close dialog
  const closeDialog = () => setMode("view");

  // render
  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}]} location="Seiten" />
    <Heading>Seiten</Heading>
    <Card>
      <List 
        columns={columns} 
        items={pages} 
        rowTo={page => `/pages/${page._id}`} 
        getKey={item => item._id} 
        onClickCreate={onClickCreate}
        filters={filters}
      />
    </Card>
    <Modal isOpen={mode==="create"} title="Neue Seite erfassen" onClose={closeDialog}>
      <Create closeDialog={closeDialog} />
    </Modal>
  </>
}