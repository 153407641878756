import React, {useContext, useState} from "react";
import { ProviderContext } from "./Provider.Context";
import * as DA from "../../../types/DataAccess";
import { Alert, Button, Card, ConfirmButton, Icon, Icons, Link, Loader } from "../../controls";

export default function ProviderActions() {
  // context
  const PC = useContext(ProviderContext);

  // state
  const [busy, setBusy] = useState<boolean>(false);
  const [loginLink, setLoginLink] = useState<string|null>(null);
  const [error, setError] = useState<string|null>(null);

  // user wants to change archive state
  const onClickArchive = async() => {
    const isActive = !PC.provider.isActive;
    setBusy(true);
    await PC.update({isActive});
    setBusy(false);
  }

  // user wants to create login link
  const onClickCreateLoginLink = async() => {
    setBusy(true);
    if(PC.provider) {
      const loginLink = await DA.ProviderRepository.fetchLoginLink(PC.provider);
      if(loginLink === "") {
        setError("Fehler beim Erzeugen des Login Links. Für den Anbieter ist keine E-Mail für Buchungsanfragen hinterlegt.");
      }
      else {
        setError(null);
        setLoginLink(loginLink);
      } 
    }
    setBusy(false);
  }


  // pre-render
  let content = null;
  if(busy || !PC.provider) {
    content = <Loader />;
  }
  else {
    let linkToPartnerPortal = null;
    let errorAlert = null;
    if(loginLink) {
      linkToPartnerPortal = <Link target="_blank" to={loginLink}>{loginLink}</Link>;
    }
    if(error) {
      errorAlert = <Alert intent="error" title="Fehler">{error}</Alert>
    }

    content = <>
      <Button size="small" onClick={onClickCreateLoginLink}>
        <Icon icon={Icons.Key} />&nbsp;Login Link generieren
      </Button>
      {linkToPartnerPortal}
      {errorAlert}
      <Alert intent="hint" title="Login Link">
        Anbieter loggen sich in ihr Partner Portal mit einem "Magic Link" ein. Hier kannst du einen generieren, um dich ins Portal des Partners einzuloggen.
      </Alert>
      <br />
      <ConfirmButton busy={busy} onConfirm={onClickArchive}>
        <Icon icon={PC.provider.isActive === false ? Icons.Unlock : Icons.Lock} />&nbsp;{PC.provider.isActive === false ? "wieder aktivieren" : "archivieren"}
      </ConfirmButton>
      <br />
      <Alert intent="hint" title="Archivierte Anbieter">
        Archivierte Anbieter stehen beim Erzeugen von Buchungsanfragen nicht mehr zur Auswahl. Das Archivieren löscht jedoch keinerlei Daten.
      </Alert>
    </>
  }

  // render
  return <Card title="Aktionen" icon={Icons.Bolt} collapsible={true}>
    {content}
  </Card>
}