import _ from "lodash";
import React, { useEffect, useState } from "react"
import * as TS from "../../../types";
import Util from "../../../util/util";

// components
import { Alert, Card, Button, ConfirmButton, Link, Loader, Modal, Table, Row, Tr, Td } from "../../controls";
import Details from "./Mailfail.Details";

// styling
import CSS  from "./Mailfail.Unresolved.module.scss";

export default function MailfailUnresolved() {
  // state
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [items, setItems] = useState<any[]>([]);
  const [itemToView, setItemToView] = useState<any|null>(null);

  // mount
  useEffect(() => {
    load();
  }, []);

  // loads unresolved
  const load = async() => {
    setIsLoading(true);
    setItems(await TS.Mailfail.getUnresolved());
    setIsLoading(false);
  }

  // user wants to view an item
  const onClickDetails = (item:any) => {
    setItemToView(item);
  }

  // close dialog
  const closeDialog = () => setItemToView(null);

  // resolves an item
  const resolve = async(id:any) => {
    await TS.Mailfail.resolve(id);
    await load();
  }

  // render
  let content = null
  if(isLoading) {
    content = <Loader text="lade Daten ..." />
  }
  else {
    const rows = items.map((item:any) => {
      let link = null
      switch(_.get(item, "relatesTo.entityType")) {
        case "Adventure":
          link = <Link to={`surprise/${item.relatesTo.entityId}`}>{TS.Adventure.getShortId(item.relatesTo.entityId, true)}</Link>
          break;
        default:
          link = "unbekannter Bezug"
          break;
      }
      return (
        <Tr key={item._id}>
          <Td>{Util.printDateAndTime(item.createdAt)}</Td>
          <Td>{TS.Mailfail.getStatusName(item.status)}</Td>
          <Td>{item.mail.subject}</Td>
          <Td>{link}</Td>
          <Td><Button size="small" onClick={() => onClickDetails(item)}>Details</Button></Td>
          <Td><ConfirmButton onConfirm={() => resolve(item._id)}>behoben</ConfirmButton></Td>
        </Tr>
      )
    })
    if(rows.length === 0) {
      rows.push(
        <Tr key="empty">
          <Td>alle E-Mail sind zufrieden und glücklich bei ihren Besitzern angekommen ... 🥳</Td>
        </Tr>
      );
    }
    else {
      rows.unshift(
        <Tr key="heading"> 
          <Td label>Datum</Td>
          <Td label>Status</Td>
          <Td label>Subject</Td>
          <Td label>Bezug</Td>
          <Td label></Td>
          <Td label></Td>
        </Tr>
      )
    }
    content = (
      <Table className={CSS.container}>
        {rows}
      </Table>
    )
  }

  // render
  return <>
    <Card title="Offene Probleme" collapsible={false} expanded={true}>
      {content}
      <Alert title="Was sind Mailsfails und wie verarbeite ich sie?" size="small" intent="info">
        Eine Erklärung zu Mailfails und wie man sie verarbeitet findest du im <Link to="https://docs.google.com/document/d/1OVCRk1NROU4mKLkDY9OaWoqVhkw5zzy5GYZKJhSSVhY/edit#" target="_blank">Google Drive</Link>
      </Alert>
    </Card>
    <Modal isOpen={itemToView !== null} title={itemToView ? itemToView.mail.subject : ""} onClose={closeDialog} wide={true}>
      <Details item={itemToView} closeDialog={closeDialog} />
    </Modal>
  </>
}

