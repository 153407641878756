import React, {useState, useEffect} from "react";
import * as TS from "../../../types";
import * as BL from "../../../types/BusinessLogic";

// the context's type
export type DunningContextType = {
  // properties
  surprises: any,
  // functions
  load: () => Promise<void>,
  toggleDunningBlock: (surprise:any) => Promise<void>,
  increaseLevel: (surprise:any) => Promise<void>,
  lockSurprise: (surprise:any) => Promise<void>,
}

type SurpriseCollections = {
  level0: any,
  level1: any,
  level2: any,
  level3: any,
  dunningBlocked: any,
  locked: any,
  mailbox: any,
}

// context
const DunningContext = React.createContext<DunningContextType>({} as DunningContextType);

// provider
type DunningProviderProps = {
  children: React.ReactNode|Array<React.ReactNode>
}
function DunningProvider(props: DunningProviderProps) {
  // state
  const [surprises, setSurprises] = useState<SurpriseCollections>({} as SurpriseCollections);
  // mount
  useEffect(() => {}, [])
  
  // provider value
  const value = {
    // props
    surprises, 

    // loads data
    load: async() => {
      const level0 = await BL.Dunning.getOverdueSurprises(0);
      const level1 = await BL.Dunning.getOverdueSurprises(1);
      const level2 = await BL.Dunning.getOverdueSurprises(2);
      const level3 = await BL.Dunning.getOverdueSurprises(3);
      const dunningBlocked = await BL.Dunning.getDunningBlockedSurprises();
      const locked = await BL.Dunning.getLockedSurprises();
      const mailbox = await BL.Dunning.getMailboxSurprises();
      setSurprises({level0, level1, level2, level3, dunningBlocked, locked, mailbox});
    },
    // toggges the dunning block on a surprise and reloads relevant data
    toggleDunningBlock: async(surprise:any) => {
      // set/unset dunning block
      const activateDunningBlock = surprise.DunningBlock ? false : true;
      await BL.Dunning.setDunningBlock(surprise, activateDunningBlock);
      // reload affected data 
      const dunningLevel = surprise.PaymentReminderLevel as BL.DunningLevel;
      surprises.dunningBlocked = await BL.Dunning.getDunningBlockedSurprises();
      surprises[`level${dunningLevel}`] = await BL.Dunning.getOverdueSurprises(dunningLevel);
      // update state
      setSurprises({...surprises});
    },
    // updates dunning level and reloads relevant data, depending on the level an e-mail will be sent or not
    increaseLevel: async(surprise:any) => {
      // get current level (we need it to decide which data to reload)
      const dunningLevel = surprise.PaymentReminderLevel as BL.DunningLevel;
      // increase the level
      await BL.Dunning.increaseLevel(surprise);
      // reload affected data
      surprises[`level${dunningLevel}`] = await BL.Dunning.getOverdueSurprises(dunningLevel);
      if(dunningLevel === 2)  {
        surprises[`mailbox`] = await BL.Dunning.getMailboxSurprises();
      }
      // update state
      setSurprises({...surprises});
    },
    // locks a surprise
    lockSurprise: async(surprise:any) => {
      // lock the surprise
      await BL.Dunning.lockSurprise(surprise);
      // reload affected data
      surprises.level3 = await BL.Dunning.getOverdueSurprises(3);
      surprises.locked = await BL.Dunning.getLockedSurprises();
      setSurprises({...surprises})
    }
  }

  return (
    <DunningContext.Provider value={value}>
      {props.children}
    </DunningContext.Provider>
  )
}

export {DunningContext, DunningProvider}


