import { Translatable } from "./Translatable";

export class TranslatableMapper {
  static toDb(translatable:Translatable): any {
    if(translatable.en.trim().length === 0 || translatable.en === "!_") {
      translatable.en = "!_" + translatable.de
    }
    return {
      de: translatable.de,
      en: translatable.en,
    }
  }

  static fromDb(obj:any): Translatable {
    obj = obj || {de:"", en:""};
    obj.de = obj.de || "";
    obj.en = obj.en || "!_" + obj.de;
    return {
      de: obj.de,
      en: obj.en
    }
  }
}