import { Validation } from "./Validation";
// @ts-ignore
import esr from "esr-ref";

/**
 * These are all higher order functions allowing to create validation functions with a specific error message
 */


function isRequired(message?: string): (v:any) => Validation {
  return (v) => {
    const valid = v !== null && v !== undefined && String(v).trim().length > 0;
    return new Validation(valid, message || "Bitte einen Wert eingeben");
  }
}

function isEmail(message?: string): (s:string) => Validation {
  // eslint-disable-next-line
  const re_email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return (v:string) => {
    const valid = re_email.test(String(v).toLowerCase());
    return new Validation(valid, message || "Ungültige E-Mail Adresse");
  }
}

function isAppenturaEmail(message?: string): (s:string) => Validation {
  // eslint-disable-next-line
  const re_appenturaemail = /^[a-z\-\.]+@appentura.ch/;
  return (v:string) => {
    const valid = re_appenturaemail.test(String(v).toLowerCase());
    return new Validation(valid, message || "Bitte @appentura.ch E-Mail eingeben");
  }
}

function isPrice(message?: string): (v:any) => Validation {
  return (v:any) => {
    const n = isNaN(v) ? 0 : Number(v);
    const valid = n > 0;
    return new Validation(valid, message || "bitten einen Preis grösser als 0 eingeben");
  }
}

function isPriceOrZero(message?: string): (v:any) => Validation {
  return (v:any) => {
    let valid = !isNaN(v);
    if(valid) {
      valid = Number(v) >= 0
    }
    return new Validation(valid, message || "bitten einen gültigen Preis eingeben");
  }
}

function isCount(message?: string): (v:any) => Validation {
  return (v:any) => {
    const n = isNaN(v) ? 0 : Number(v);
    const valid = (n > 0) && (n === Math.floor(n));
    return new Validation(valid, message || "Bitte eine gültige Anzahl eingeben");
  }
}

function isGreaterThan(minValue: number, message?: string): (v:any) => Validation {
  return (v:any) => {
    const n = isNaN(v) ? 0 : Number(v);
    const valid = (n > minValue);
    return new Validation(valid, message || `Bitte eine Zahl grösser als ${minValue} eingeben`);
  }
}

// TODO this should have Lat and Lng instead of lat and lng
// TODO this should be a higher order function
function isLocationInDACH(location:{lat: number, lng: number}): Validation {
  const latMin = 45.8 // south of Chiasso, Switzerland
  const latMax = 55.0 // north of Flensburg, Germany
  const lngMin = 5.8 // west of Aachen, Germany
  const lngMax = 17.2 // eastern border of Austria, south-east of Vienna
  if(location.lat < latMin ||location.lat > latMax || location.lng < lngMin || location.lng > lngMax) {
    const valid = false;
    return new Validation(valid, "Diese Koordinaten liegen nicht innerhalb des DACH-Raumes");
  }
  else {
    return new Validation(true);
  }
}

function isYouTubeUrl(message?:string): (v:any) => Validation {
  return (v:any) => {
    let valid = true;
    const url = (v || "").trim().toLowerCase();
    if(url.length === 0) {
      valid = false;
    }
    else {
      valid = v.startsWith("https://youtube.com/") || v.startsWith("https://www.youtube.com/") || v.startsWith("https://youtu.be/");
    }

    return new Validation(valid, message || "bitte Link zu einem YouTube video eingeben");
  }
}

function isYouTubeVideoId(message?: string): (v:any) => Validation {
  // ([a-zA-Z0-9_-]{11})
  const re_videoid = /([a-zA-Z0-9_-]{11})/;
  return (v:any) => {
    const valid = re_videoid.test(v);
    return new Validation(valid, message || "Bitte gültige YouTube Video-Id eingeben");
  }
}

function isSlug(options?:{allowEmpty?:boolean}): (v:string) => Validation {
  const re_slug = /^[a-zA-Z0-9](?:[a-zA-Z0-9_-]*[a-zA-Z0-9])?$/;
  return (v:string) => {
    if(options && options.allowEmpty && v.trim().length === 0) {
      return new Validation(true, "");
    }
    else {
      const valid = re_slug.test(String(v).toLowerCase());
      return new Validation(valid, "Darf nur Buchstaben, Zahlen und Bindestriche enthalten. Muss mit Zahl oder Buchstabe beginnen und enden.");
    }
  }
}

function isObjectId(message:string): (v:string) => Validation {
  return (v) => {
    let valid = false
    if(v) {
      valid = String(v).match(/^[a-f\d]{24}$/i) !== null
    }
    
    return {valid, message}
  }
}

function isGreaterThan0(message:string): (v:any) => Validation {
  return (n) => {
    let valid = !isNaN(n) && Number(n) > 0
    return {valid, message}
  }
}

function isNumeric(message:string): (v:any) => Validation {
  return (n) => {
    let valid = !isNaN(n)
    return {valid, message}
  }
}

/**
 * ESR Reference-Number
 */
 function isEsrReferenceNumber(message:string): (v:any) => Validation {
  return (referenceNumber) => {
    const valid = esr.validate(referenceNumber);
    return {valid, message}
  }
}

function isPainReferenceNumber(message:string) : (v:string) => Validation {
  return (r:string) => {
    if(r.startsWith("RF")) {
      const valid = true; // TODO actually validate
      return {valid, message};
    }
    else {
      const valid = esr.validate(r);
      return {valid, message}
    }
  }
}

/**
 * SEPA character set
 */
function isSepaCompliant(): (v:any) => Validation {
  const re_sepa = /[0-9 a-zA-Z:?.,\-(+)]+/;
  return(s) => {
    const valid = re_sepa.test(s);
    // Note: sepa actually allows for more items than the error message implies
    return {valid, message: "Darf nur Zahlen, Buchstaben und Leerschlag enthalten"}
  }
}



export default {
  isCount,
  isEmail,
  isAppenturaEmail,
  isPrice,
  isPriceOrZero,
  isRequired,
  isLocationInDACH,
  isGreaterThan,
  isYouTubeUrl,
  isYouTubeVideoId,
  isSlug,
  isObjectId,
  isGreaterThan0,
  isNumeric,
  isEsrReferenceNumber,
  isPainReferenceNumber,
  isSepaCompliant
}