import _ from "lodash";
import React, {useContext} from "react";
import * as BL from "../../../types/BusinessLogic";
import * as DA from "../../../types/DataAccess";
import { ActivityContext } from "./ActivityContext";
import { Alert, Card, Icon, Icons, Loader } from "../../controls";

import RegionVariant from "./RegionVariants/Activity.RegionVariant";

import CSS from "./Activity.RegionVariants.module.scss";


export default function RegionVariants() {
  // context
  const AC = useContext(ActivityContext)!;

  // selects a region variant by region code
  const selectRegion = async(regionCode:string) => {
    const regionVariant = AC.activity!.regionVariants.find(rv => rv.regionCode === regionCode);
    if(regionVariant) {
      // the variant exists
      AC.setRegionVariant(regionVariant);
    }
    else {
      // the variant does not exist, create it and then select it
      await AC.addRegionVariant(regionCode, true);
    }
  }

  // user clicks tab
  const onClickTab = (regionCode:string) => {
    selectRegion(regionCode);
  }

  // render
  let content = null;
  if(_.isNil(AC.regions) || AC.activity === null) {
    content = <Loader text="lade Daten ..." />
  }
  else {
    const tabs = AC.regions.map(r => {
      const regionVariant = AC.activity!.regionVariants.find(rv => rv.regionCode === r.code);
      const isActive = regionVariant && regionVariant.isActive;
      const isCurrent = AC.regionVariant && AC.regionVariant.regionCode === r.code;
      const isDefective = regionVariant && BL.Activity.sanityCheckVariant(regionVariant as DA.ActivityRegionVariant).length > 0; // TODO remove the type cast 
      
      const icon = <Icon icon={isActive ? (isDefective ? Icons.Bomb : Icons.Check) : Icons.Ban} />
      return <div key={r.code} className={`${CSS.tab} ${isActive ? CSS.active : ""} ${isCurrent ? CSS.current : ""}`} onClick={() => onClickTab(r.code)}>{icon}<span className={CSS.title}>{r.title.de.replace("Region ", "")}</span></div>
      
    })
    let regionVariant, selectVariantHint = null;
    if(_.isNil(AC.regionVariant)) {
      selectVariantHint = <Alert title="Eine Regionsvariante wählen" intent="info" size="small">
        Eine Region wählen um die Eigenschaften der dazu gehörigen Variante zu bearbeiten
      </Alert>
    }
    else {
      regionVariant = <RegionVariant />
    }
    content = <>
      <div className={CSS.tabs}>{tabs}</div>
      {selectVariantHint}
      {regionVariant}
    </>
  }
  return (
    <Card title="Regionsvarianten" expanded={true} collapsible={true} className={CSS.container}>
      {content}
    </Card>
  )
}
