import React, {useContext} from 'react';

// context
import {SurpriseContext} from "../Surprise.Context";

// controls
import { Icon, Icons } from "../../../controls";

// styling
import "./Step.Move.scss";

// the component
export default function Move({step, isFirst, isLast, index}) {
  // context
  const SC = useContext(SurpriseContext);

  // click event handler
  const move = (movement) => {
    const targetIndex = index + movement;
    const steps = SC.steps.map(s => s);
    steps.splice(targetIndex, 0, steps.splice(index, 1)[0]);
    SC.setSteps(steps);
  }
  
  // render
  const up = !isFirst ? <Icon onClick={() => move(-1)} className="upDown" icon={Icons.ArrowUp}></Icon> : null;
  const down = !isLast ?  <Icon onClick={() => move(1)} className="upDown" icon={Icons.ArrowDown}></Icon> : null;
  return (
    <div className="c-step-move">
      <span className="index">{index + 1}</span>
      <div className="title">{step.Title}</div>
      <span className="actions">
        {up}{down}
      </span>
    </div>
  )
}
