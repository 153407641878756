import _ from "lodash";
import { DropdownOption } from "."

const Countries: DropdownOption[] = [
  {label: "Schweiz", value: "CH"},
  {label: "Deutschland", value: "DE"}
]

const Currencies: DropdownOption[] = [
  {label:"CHF", value:"CHF"},
  {label:"EUR", value:"EUR"}
];

const Months: DropdownOption[] = [
  {label:"Januar", value:0}, {label:"Februar", value:1}, {label:"März", value:2}, {label:"April", value:3}, {label:"Mai", value:4}, {label:"Juni", value:5}, {label:"Juli", value:6}, {label:"August", value:7}, {label:"September", value:8}, {label:"Oktober", value:9}, {label:"November", value:10}, {label:"Dezember", value:11} 
]

const Weekdays: DropdownOption[] = [
  {label: "Montag", value: 1}, {label: "Dienstag", value: 2}, {label: "Mittwoch", value: 3}, {label: "Donnerstag", value: 4}, {label: "Freitag", value: 5}, {label: "Samstag", value: 6}, {label: "Sonntag", value: 0}
]

const HoursOfTheDay: DropdownOption[] = _.range(23).map(i => {
  return {value: i, label: `${i}:00`};
})

 

export default {
  Countries,
  Currencies,
  Months,
  Weekdays,
  HoursOfTheDay
}