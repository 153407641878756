import _ from "lodash";
import React, {useState, useEffect, useContext} from "react";
import Util from "../../../util/util";
import * as BL from "../../../types/BusinessLogic";

import { ShippingContext } from "./Shipping.Context";
import {Card, ConfirmButton, Icon, Icons, Link} from "../../controls";
// styling
import CSS from "./Shipping.Group.module.scss";

type GroupProps = {
  shippingCode: string
}
export default function Group({shippingCode}: GroupProps) {
  // context
  const SC = useContext(ShippingContext);
  //state
  const [state, setState] = useState<{shippingType:any, adventures:any[]}|null>(null);
  const [busy, setBusy] = useState(false);
  
  // mount
  useEffect(() => {
    const shippingType = SC.shippingTypes.find(st => st.code === shippingCode);
    const adventures = SC.adventures.filter(adv => adv.Order.Packaging.Code === shippingCode);
    setState({shippingType, adventures});
  }, [shippingCode, SC.adventures]);
  
  // event handler
  const markAsShipped = async(adventureId:string) => {
    setBusy(true);
    SC.markAsShipped(adventureId);
    setBusy(false);
  }

  // render
  if(_.isNil(state)) {
    return null;
  }
  if(state.adventures.length === 0) {
    return null;
  }
  const rows = state.adventures.map(adv => {
    const shippingInfo = BL.Shipping.getShippingInfo(adv, SC.shippingTypes);
    const isDirectShipping = shippingInfo.addresses.areEqual === false; // _.isEqual(addressLines.shipping, addressLines.invoice) === false;
    let button = null;
    if(_.isNil(adv.ShippingDate)) {
      button = <ConfirmButton busy={busy} onConfirm={() => markAsShipped(adv._id)}>als verschickt markieren</ConfirmButton>
    }
    else {
      button = <div className={CSS.sent}><Icon icon={Icons.CheckCircle} /> verschickt</div>
    }
    let orderComments = null;
    if((_.get(adv, "Order.Comments") || "").length > 0) {
      orderComments = <div className={CSS.order_comments}>{_.get(adv, "Order.Comments") }</div>
    }

    return <tr key={adv._id}>
      
      <td>
        <Link to={`/surprise/${adv._id}`}>
          {`[${Util.shortObjectId(adv._id, 0, 4)}] ${adv.ReserverName || "???"} für ${adv.RecieverName || "???"}`}
        </Link>
        {orderComments}
      </td>
      <td className={CSS.address}>{shippingInfo.addresses.shipping.map((l, index) => <p key={index}>{l}</p>)}</td>
      <td className={CSS.address}>{shippingInfo.addresses.invoice.map((l, index) => <p key={index}>{l}</p>)}</td>
      <td><CoverletterLinks isDirectShipping={isDirectShipping} coverletters={shippingInfo.coverletters} showAlternatives={false} /></td>
      <td className={CSS.ticket}>
        <TicketLinks ticketGroup={shippingInfo.tickets.pdf} showAlternatives={false} />
        <br/>
        <TicketLinks ticketGroup={shippingInfo.tickets.html}  showAlternatives={false} />
        <br/>
        <TicketLinks ticketGroup={shippingInfo.tickets.card} showAlternatives={false} />
      </td>
      <td>{button}</td>
    </tr>
  })
  return (
    <Card title={`${state.shippingType.title.de} (${state.shippingType.country})`} collapsible={true} expanded={true}>
      <div className={CSS.container}>
        <div className={CSS.list}>
          <table>
            <thead>
              <tr>
                <th>Überraschung / Bestellkommentar</th>
                <th>Versandadresse</th>
                <th>Rechnungsadresse</th>
                <th>Begleitbrief</th>
                <th>Ticket</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
        <div className={CSS.image}>
          <img src={state.shippingType.image.url} alt={state.shippingType.title.de} />
          <span className={CSS.count}>{rows.length}&times;</span>
        </div>
      </div>
    </Card>
  );
}


type CoverletterLinksProps = {
  coverletters: any, 
  isDirectShipping: boolean, 
  showAlternatives: boolean
}
function CoverletterLinks({coverletters, isDirectShipping, showAlternatives} : CoverletterLinksProps) {
  if(showAlternatives) {
    if(isDirectShipping) {
      return <><Link to={coverletters.direct.url} target="_blank">{coverletters.direct.label}</Link> (oder <Link to={coverletters.regular.url} target="_blank">{coverletters.regular.label}</Link>)</>
    }
    else {
      return <><Link to={coverletters.regular.url} target="_blank">{coverletters.regular.label}</Link> (oder <Link to={coverletters.direct.url} target="_blank">{coverletters.direct.label}</Link>)</>
    }
  }
  else {
    if(isDirectShipping) {
      return <Link to={coverletters.direct.url} target="_blank">{coverletters.direct.label}</Link>
    }
    else {
      return <Link to={coverletters.regular.url} target="_blank">{coverletters.regular.label}</Link>
    }
  }
}

type TicketLinksProps = {
  ticketGroup: {
    primary: {label:string, url: string}, 
    secondary: {label:string, url: string}
  }
  showAlternatives: boolean
}
function TicketLinks({ticketGroup, showAlternatives} : TicketLinksProps) {
  if(showAlternatives) {
    return <><Link to={ticketGroup.primary.url} target="_blank">{ticketGroup.primary.label}</Link> (oder <Link to={ticketGroup.secondary.url} target="_blank">{ticketGroup.secondary.label}</Link>)</>
  }
  else {
    return <Link to={ticketGroup.primary.url} target="_blank">{ticketGroup.primary.label}</Link>
  }
  
}