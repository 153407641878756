import React, { useContext } from "react";
import { ActivityContext } from "./ActivityContext";
import { Alert, Card, Loader } from "../../controls"; 

import Properties from "./Activity.Shop.Properties";
import Tags from "./Activity.Shop.Tags";
import SubactivityAsOption  from "./Activity.Shop.SubactivityAsOption";
export default function Shop() {
  // context
  const AC = useContext(ActivityContext)!;

  // render not yet loaded
  if(AC.activity === null) {
    return <Loader text="Lade Aktiviät ..." />
  }

  // pre-render
  let content;
  if(AC.activity!.isSubactivity) {
    content = <>
      <SubactivityAsOption />
      <Alert size="small" intent="info" title="Verborgene Elemente">bei Unteraktivitäten werden 'Tags' und 'Eigenschaften' nicht benötigt</Alert>
    </>
  }
  else {
    content = <>
      <Properties />
      <Tags />
    </>
  }
  
  // render
  return (
    <Card title="Shop" expanded={false} collapsible={true}>
      {content}
    </Card>
  )
}