import React, {useState} from "react";
import Code from "../Code";
import { Card, Heading, Switchboard, SwitchboardItem } from "../../../controls";

export default function SwitchboardDemo() {
  const [valuesSingle, setValuesSingle] = useState(["top"]);
  const [valuesSome, setValuesSome] = useState(["top", "bottom"]);
  const [valuesAll, setValuesAll] = useState(["top", "bottom"]);

  const [items] = useState<SwitchboardItem[]>([
    {value:"top", label:"Top"},
    {value:"left", label:"Left"},
    {value:"right", label:"Right"},
    {value:"bottom", label:"Bottom"},
  ])
  // render
  return <>
    <Heading>Switchboard</Heading>
    <Card>
      <Switchboard onChange={(v) => setValuesSingle(v)} selectedValues={valuesSingle} items={items} minItems={1} maxItems={1} />
      <Switchboard onChange={(v) => setValuesSome(v)} selectedValues={valuesSome} items={items} maxItems={2} />
      <Switchboard onChange={(v) => setValuesAll(v)} selectedValues={valuesAll} items={items} />

      <Code>{`
      const [items] = useState<SwitchboardItem[]>([
        {value:"top", label:"Top"},
        {value:"left", label:"Left"},
        {value:"right", label:"Right"},
        {value:"bottom", label:"Bottom"},
      ]);
      <Switchboard onChange={(v) => setValue(v)} value={value} items={items} />
      `}</Code>
    </Card>
  </>
}


/*

      <Value label="Placeholder if no value" placeholder="Placeholder"></Value>
      <Code>{`<Value label="Test" placeholder="Placeholder"></Value>`}</Code>

      <Value label="No placeholder if value provided" placeholder="Placeholder">Hey there</Value>
      <Code>{`<Value label="Test" placeholder="Placeholder">Hey there</Value>`}</Code>

      <Value label="With onClick Callback" onClick={() => alert("Hey there")}>Hey there</Value>
      <Code>{`<Value label="Test" onClick={() => alert("Hey there")}>Hey there</Value>`}</Code>

      <Value label="Disabled" disabled onClick={() => alert("Hey there")}>Hey there</Value>
      <Code>{`<Value label="Test" disabled onClick={() => alert("Hey there")}>Hey there</Value>`}</Code>

      <Value label="Explanation displayed" disabled onClick={() => alert("Hey there")} explanation="Call me, when nobody pick it up">Hey there</Value>
      <Code>{`<Value label="Test" disabled onClick={() => alert("Hey there")} explanation="Call me, when nobody pick it up">Hey there</Value>`}</Code>
      */
