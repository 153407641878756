import { useEffect, useState } from "react";
import * as BL from "../../../../types/BusinessLogic";
import * as DA from "../../../../types/DataAccess";
import { Loader, Row, Button, Title, Text, Link, Checkbox, Dropdown, DropdownOption, TextArea, TextInput, ConfirmButton, Icons, Icon } from "../../../controls";
import { ProviderLink } from "../../../common";

type TasksEditProps = {
  task: DA.AdventureTask,
  onUpdate: () => void,
  closeModal: () => void
}
export default function TasksEdit({task, onUpdate, closeModal: onClickCancel}: TasksEditProps) {
  // state
  const [busy, setBusy] = useState<boolean>(false);
  const [user, setUser] = useState<DA.User|null>(null);
  const [userOptions, setUserOptions] = useState<DropdownOption[]>([]);
  const [mounted, setMounted] = useState<boolean>(false);
  const [bookingRequest, setBookingRequest] = useState<DA.BookingRequest|null>(null);
  const [internalTask, setInternalTask] = useState<DA.AdventureTask>(task);
  const [completed, setCompleted] = useState<boolean>(task.dateCompleted !== null);
  const [assignedTo, setAssignedTo] = useState<string>(task.assignedTo!);
  const [title, setTitle] = useState<string>(task.title);
  const [description, setDescription] = useState<string>(task.description);

  // mount
  useEffect(() => { 
    const load = async() => {
      setUser(await BL.User.getCurrent());
      const users = await DA.UserRepository.findAll();
      const userOptions = users
        .filter(u => u.isActive || u.email.toLowerCase() === task.assignedTo.toLowerCase())
        .map(u => ({value: u.email, label: u.firstName}));
      setUserOptions(userOptions);

      if(task.relatedEntityType && task.relatedEntityId) {
        switch(task.relatedEntityType) {
          case "BookingRequest":
            const bookingRequest = await DA.BookingRequestRepository.findById(task.relatedEntityId!);
            setBookingRequest(bookingRequest);
            break;
        }
      }
      setMounted(true);
    }
    
    load();
    
  }, []);

  // user wants to save
  const onClickSave = async() => {
    setBusy(true);
    if(!task._id) {
      task.title = title;
      task.description = description;
      task.assignedTo = assignedTo;
      task.dateCompleted = completed ? new Date() : null;
      // create
      await DA.AdventureTaskRepository.create(task);
    }
    else {
      // update
      const user = await BL.User.getCurrent();
      const changeset:any = {
        assignedTo: assignedTo,
        title: title,
        description: description,
      };
      if(completed) {
        if(internalTask.dateCompleted === null) {
          changeset.dateCompleted = new Date();
        }
        if(internalTask.completedBy !== user.email) {
          changeset.completedBy = user.email;
        }
      }
      else {
        changeset.dateCompleted = null;
        changeset.completedBy = null;
      }
      await DA.AdventureTaskRepository.update(task._id!, changeset);
    }

    setBusy(false);
    onUpdate();
    onClickCancel();
  }

  // user confirms deleting task
  const onClickDelete = async() => {
    setBusy(true);
    await DA.AdventureTaskRepository.remove(task._id!);
    setBusy(false);
    onUpdate();
    onClickCancel();
  }

  // render if not mounted
  if(!mounted) {
    return <Loader />;
  }

  // render info based on related entity
  let info = null;
  switch(task.relatedEntityType) {
    case "BookingRequest":
      if(bookingRequest) {
        info = (
          <Text>
            Link zum Anbieter: <ProviderLink provider={{_id:bookingRequest.provider.id, name:bookingRequest.provider.name} as DA.Provider} />
          </Text>
        );
      }
      break;
    default:
      break;
  }


  // rendere delete button (only if we are dealing with an update)
  const buttonDelete = task._id !== null ? <ConfirmButton intent="delete" onConfirm={onClickDelete}><Icon icon={Icons.Trash} /></ConfirmButton> : null;
  // render
  return (
    <div>
      <TextInput label="Title" value={title} onChange={s => setTitle(s)} />
      <TextArea label="Beschreibung" value={description} onChange={s => setDescription(s)} />
      {info}
      <Dropdown options={userOptions} label="zuständig" value={assignedTo} onChange={v => setAssignedTo(v)} />
      <Checkbox label="Status" secondaryLabel={completed ? `erledigt (${internalTask.completedBy || user!.email})` : "noch nicht erledigt"} value={completed} onChange={setCompleted} />
      <br />
      <Row align="center">
        {buttonDelete}
        <Button busy={busy} size="small" intent="cancel" onClick={onClickCancel}>abbrechen</Button>
        <Button busy={busy} size="small" intent="confirm" onClick={onClickSave}>speichern</Button>
      </Row>
    </div>
  )
}