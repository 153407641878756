export class XmlDocument {
  attributes: XmlAttribute[];
  nodes: XmlNode[];

  constructor() {
    this.attributes = [];
    this.nodes = [];
  }

  addAttribute(attribute:XmlAttribute) {
    this.attributes.push(attribute);
  }

  addNode(node:XmlNode): XmlNode {
    this.nodes.push(node);
    return node;
  }

  toString() {
    const sAttributes:string = this.attributes.map(attribute => attribute.toString()).join(" ");
    const sNodes:string = this.nodes.map(node => node.toString()).join("");
    const sXmlVersion = `<?xml version="1.0" encoding="UTF-8"?>`;
    return `${sXmlVersion}<Document ${sAttributes}>${sNodes}</Document>`;
  }
}

export class XmlNode {
  key: string;
  value: string|number|boolean|undefined;
  attributes: XmlAttribute[];
  nodes: XmlNode[];

  constructor(key:string, value?:string|number|boolean|undefined) {
    this.key = key;
    this.value = value;
    this.attributes = [];
    this.nodes = [];
  }

  addAttribute(attribute:XmlAttribute) {
    this.attributes.push(attribute);
  }

  addNode(node:XmlNode): XmlNode {
    this.nodes.push(node);
    return node;
  }

  toString() {
    const sAttributes:string = this.attributes.map(attribute => attribute.toString()).join(" ");
    const sNodes:string = this.nodes.map(node => node.toString()).join("");
    const sValue:string = this.value ? String(this.value) : "";
    return `<${this.key} ${sAttributes}>${this.value ? sValue : sNodes}</${this.key}>`;
  }
}

export class XmlAttribute {
  key: string; 
  value: any;

  constructor(key:string, value:any) {
    this.key = key;
    this.value = value;
  }

  toString() {
    return `${this.key}="${this.value}"`;
  }
}