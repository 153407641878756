import React, {useState} from "react"
import _ from "lodash"
import * as DA from "../../../types/DataAccess";

// components
import {Redirect} from 'react-router-dom';
import {Form, Checkbox, Dropdown, CardSection, Loader, TextInput, TextArea, Validators, Breadcrumb, Card, Heading} from "../../controls";
import { debug } from "console";

// the component
function Create() {
  // state
  const [order] = useState<DA.BulkOrderCreatePayload>(DA.BulkOrderRepository.makeCreatePayload());
  const [mode, setMode] = useState<"create"|"busy"|"done"|"error">("create");
  const [redirectToList, setRedirectToList] = useState<boolean>(false);

  // TODO hardcoded ... once shipping types support the option 'useableForBulk' we get them from the database
  const [packagingOptions] = useState([
    {value:"METALLBOX", label:"Metallbox"},
    {value:"TISCHBOMBE", label:"Tischbombe (schwarz)"}
  ])

  // save button handler
  const onSave = async(formData:any) => {
    // set mode
    setMode("busy");

    // create payload from form data
    let newOrder: DA.BulkOrderCreatePayload = DA.BulkOrderRepository.makeCreatePayload();
    _.merge(newOrder, order);
    _.merge(newOrder, formData.changeset);

    // create
    let result:any = await DA.BulkOrderRepository.create(newOrder)
    if(result.success) {
      setRedirectToList(true);
    }
    else {
      alert('Could not create BulkOrder: ' + result.error.message)
      console.error('Could not create BulkOrder:', result.error.message);
    }
    
  }

  const onCancel = () => {
    setRedirectToList(true);
  }

  // redirect to list?
  if(redirectToList) {
    return <Redirect to="/bulkorders" />
  }

  // render
  let content
  switch (mode) { 
    case "busy":
      content = <Loader />
      break
    case "done":
      content = <div>DONE</div>
      break
    case "error":
      content =<div>Error (see console log)</div>
      break
    case "create":
    default:
      content = (
        <Form 
          entity={order} 
          onSave={onSave}
          onCancel={onCancel}
          labelSave="erstellen"
          labelCancel="abbrechen"
        >
          <CardSection title="Anzahl und Preise">
            <TextInput 
              path="count" 
              label="Anzahl"
              validate={Validators.isCount()} 
            />
            <TextInput 
              path="itemValue" 
              label="Wert (exklusive Versand)" 
              validate={Validators.isPrice()} 
            />
            <TextInput
              path="fees.processing"
              label="Verarbeitungspauschale (Arbeit, Verpackung, Versand)"
              validate={Validators.isPriceOrZero()} 
            />
          </CardSection>

          <CardSection title="Kunde">
            <TextInput path="customer.company" label="Firma" validate={Validators.isRequired()} />
            <TextInput path="customer.email" label="E-Mail Kontakt" validate={Validators.isEmail()} />
            <TextInput path="customer.firstName" label="Vorname Kontakt" validate={Validators.isRequired()} />
            <TextInput path="customer.lastName" label="Familienname Kontakt" validate={Validators.isRequired()} />
            <TextInput path="customer.phone" label="Telefonnummer Kontakt" />
            <TextInput path="customer.street1" label="Strasse, Nr." validate={Validators.isRequired()} />
            <TextInput path="customer.street2" label="Adress-Zusatz" />
            <TextInput path="customer.zip" label="PLZ" validate={Validators.isRequired()} />
            <TextInput path="customer.city" label="Ort" validate={Validators.isRequired()} />
          </CardSection>

          <CardSection title="Verpackung und Ticket">
            <Dropdown path="packaging.code" label="Verpackung" options={packagingOptions} />
            <TextInput path="packaging.from" label="überreicht von ..." />
            <TextArea path="packaging.message" label="Widmung" />
            <Checkbox path="packaging.hideValue" label="Wert verbergen" secondaryLabel="Wert verbergen (auf Ticket und bei Einlösung)" />
          </CardSection>

          <CardSection title="Notizen">
            <TextArea path="notes" label="Notizen" />
          </CardSection>
          
        </Form>
      )
      break
  }
  return <>
    <Breadcrumb 
      links={[ {title:'Home', to:'/'}, {title:'Sammelbestellungen', to:"/bulkorders"}]}
      location="Sammelbestellung erstellen" 
    />
    <Heading>Sammelbestellung erstellen</Heading>
    <Card>
      {content}
    </Card>
  </>
}

export default Create