import React, { useEffect, useState } from "react";
import { Form, Loader, TextInput, Validators } from "../../controls";
import * as DataAccess from "../../../types/DataAccess";

type ConditionsItemProps = {
  id?:string,
  onUpdate:() => void,
  closeModal: () => void,
}
function ConditionsItem({id, onUpdate, closeModal} : ConditionsItemProps) {
  // state
  const [entity, setEntity] = useState<DataAccess.Condition|null>(null);
  const [busy, setBusy] = useState(false);
  
  // mount
  useEffect(() => {
    const load = async(id?:string) => {
      let condition;
      if(id) {
        condition = await DataAccess.ConditionRepository.findById(id);
      }
      condition = condition || DataAccess.ConditionRepository.make();
      setEntity(condition);
    }
    load(id);
  }, []);

  // save the condition
  const onSave = async(formResult:any) => {
    if(entity !== null) {
      setBusy(true);
      if(entity._id) {
        await DataAccess.ConditionRepository.update(entity._id, formResult.changeset);
      }
      else {
        await DataAccess.ConditionRepository.create(formResult.merge(entity));
      }
      onUpdate();
      closeModal();
    }
  }

  // abort
  const onCancel = () => {
    closeModal();
  }

  // render
  if(entity === null) {
    return <Loader />
  }
  return (
    <Form entity={entity} onSave={onSave} onCancel={onCancel} busy={busy}>
      <TextInput label="" path="title.de" disabled={busy} validate={Validators.isRequired("Bedingung angeben")} />
    </Form>
  )
}

export default ConditionsItem