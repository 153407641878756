import _ from "lodash";
import React, {useState, useEffect, useContext} from "react";

// components & contexts
import { ProviderContext } from "./Provider.Context";
import { Button, Card, Form, List, TextInput, Icon, Icons, Modal } from "../../controls";

// types
import * as DA from "../../../types/DataAccess";

const linkColumns = [
  {path:"title", header:"Titel", print: (v:DA.ProviderLink) => v.title, sort:(a:any,b:any) => a.title.localeCompare(b.title)},
  {path:"url", header:"Url", print: (v:DA.ProviderLink) => v.url, sort:(a:any,b:any) => a.url.localeCompare(b.url)}
];

// the component
export default function ProviderLinks() {
  // state
  const [linkToEdit, setLinkToEdit] = useState<DA.ProviderLink|null>(null);

  // context
  const PC = useContext(ProviderContext);

  // user wants to create
  const onClickCreate = () => {
    // create an empty link
    const link:DA.ProviderLink = DA.ProviderRepository.makeLink();
    // open dialog
    setLinkToEdit(link);
  }

  // user wants to edit
  const onClickEdit = (link:DA.ProviderLink) => {
    setLinkToEdit(link);
  }

  // user wants to delete
  const onClickDelete = async(link:DA.ProviderLink) => {
    await PC.deleteLink(link);
    await PC.reload();
  }

  // stops editing, closes modal
  const closeDialog = () => setLinkToEdit(null);
 
  // render
  return <>
    <Card title="Links" expanded={false} collapsible={true}>
      <List 
        columns={linkColumns} 
        items={PC.provider.links}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
        hideFooter={true}
        hideSearch={true}
        getKey={item => item._id}
      />
      <Button onClick={onClickCreate} size="small"><Icon icon={Icons.Plus} /></Button>
    </Card>
    <Modal isOpen={linkToEdit !== null} title="Link" onClose={closeDialog}>
      <Edit link={linkToEdit!} closeDialog={closeDialog} />
    </Modal>
  </>
}

type EditProps = {
  link: DA.ProviderLink,
  closeDialog: () => void,
}
function Edit({link, closeDialog} : EditProps) {
  // context
  const PC = useContext(ProviderContext);

  // state
  const [busy, setBusy] = useState<boolean>(false);
  const [entity, setEntity] = useState<DA.ProviderLink>(link);

  // mount
  useEffect(() => {
    const entity:any = {};
    _.forOwn(link, (value, key) => {
      entity[key] = value;
    });
    setEntity(entity);
  }, []);

  // user wants to save
  const onSave = async(formResult:any) => {
    setBusy(true);
    const updatedLink = formResult.merge(entity);
    await PC.upsertLink(updatedLink);
    await PC.reload();
    closeDialog();
  }

  // render
  return <>
    <Form 
      entity={entity} 
      onSave={onSave} 
      onCancel={closeDialog}
      busy={busy}
    >
      <TextInput label="Titel" path="title" />
      <TextInput label="Url" path="url" />
    </Form>
  </>
}
