import { Result } from "./Result";
import * as Api from "../util/ApiTS";
import * as DA from "./DataAccess";
import { Adventure, AdventureStatus } from "./Adventure";
import { BookingRequest, BookingRequestRepository } from "./DataAccess";


export type CollectiveInvoiceStatus = "bezahlt" | "versendet" | "offen";

export type CollectiveInvoicePeriod = {
  month: number, // zero based, January===0
  year: number;
}

export type CollectiveInvoiceItem = {
  _id?: string,
  bookingRequest_id: string,
  bookingRequest_date: Date,
  activity_id: string,
  activity_name: string,
  activity_date: Date,
  adventure_id: string,
  adventure_date: Date,
  amount: number,
  text: string,
}
export class CollectiveInvoice {
  public static ApiEndpoint = "collectiveInvoices";

  _id: string|undefined;
  // provider
  provider_id: string;
  provider_name: string;
  // currency
  currencyCode: "CHF"|"EUR" = "CHF";
  // includes booking requests
  // - belonging to finished adventures happening up and including the end of the period
  // - that are not part of existing cololective invoices
  period:CollectiveInvoicePeriod;
  items:CollectiveInvoiceItem[] = [];
  // the date the invoice was paid
  dateSent: Date|null = null;
  datePaid: Date|null = null;

  /**
   * Constructor
   * @param providerId 
   * @param currencyCode 
   * @param month 
   * @param year 
   */
  constructor(providerId:string, providerName: string, currencyCode:"CHF"|"EUR", period:CollectiveInvoicePeriod) {
    this.provider_id = providerId;
    this.provider_name = providerName;
    this.currencyCode = currencyCode;
    this.period = period;
  }

  /** calculated properties */
  get referenceNumber():string {
    if(this._id) {
      return this._id.substring(this._id.length - 4, this._id.length);
    }
    else {
      return "????";
    }
    
  }

  /** Public Methods */

  public toDb() : any {
    return {
      _id: this._id,
      provider_id: this.provider_id,
      provider_name: this.provider_name,
      currencyCode: this.currencyCode,
      period: this.period,
      dateSent: this.dateSent || null,
      datePaid: this.datePaid || null,
      items: this.items,
    }
  }

  /** Static Methods */

  public static fromDb(obj:any) : CollectiveInvoice {
    const collectiveInvoice = new CollectiveInvoice(obj.provider_id, obj.provider_name, obj.currencyCode, obj.period);
    collectiveInvoice._id = obj._id;
    collectiveInvoice.dateSent = obj.dateSent ? new Date(obj.dateSent) : null;
    collectiveInvoice.datePaid = obj.datePaid ? new Date(obj.datePaid) : null;
    collectiveInvoice.items = obj.items || [];
    return collectiveInvoice;
  }

  static async search(filter:any): Promise<CollectiveInvoice[]> {
    const projection = {};
    const result = await Api.post(this.ApiEndpoint, "search", {filter, projection});
    if(result.success) {
      const eqs: CollectiveInvoice[] = result.data.items.map((obj:any) => {
        return CollectiveInvoice.fromDb(obj);
      })
      return eqs;
    }
    else {
      console.error(result.error);
      return [];
    }
  }

  static async all(): Promise<CollectiveInvoice[]> {
    return await CollectiveInvoice.search({});
  }

  static async findOneById(id:string) : Promise<CollectiveInvoice|null> {
    const searchResult = await CollectiveInvoice.search({_id:id});
    return searchResult[0] || null;
  }

  static async update(id:string, changeset:any) : Promise<Result<CollectiveInvoice>> {
    const result = await Api.post(this.ApiEndpoint, "update", {id:id, set:changeset});
    if(result.success) {
      return {
        success: true,
        data: CollectiveInvoice.fromDb(result.data.item)
      }
    }
    else {
      return Result.fromDb(result);
    }
  }

  static async create(provider:DA.Provider, period: CollectiveInvoicePeriod, bookingRequests: BookingRequest[]): Promise<Result<CollectiveInvoice>> {
    // make sure the provider has required info
    const providerCollectiveInvoice = provider.collectiveInvoice;
    if(providerCollectiveInvoice.isActive === false || providerCollectiveInvoice.email === null ) {
      return {
        success: false,
        error: {
          message: "Provider is missing needed information"
        }
      }
    }

    // create and save a new invoice
    const collectiveInvoice = new CollectiveInvoice(provider._id!, provider.name, providerCollectiveInvoice.currency, period);
    collectiveInvoice.items = bookingRequests.map((br:BookingRequest) => {
      return {
        bookingRequest_id: br._id!,
        bookingRequest_date: br.request.createdOn,
        activity_id: br.activity.id,
        activity_name: br.activity.name,
        activity_date: br.activity.date!, // TODO are we sure we always have a date?
        adventure_id: br.adventure_id,
        adventure_date: br.adventure_date,
        
        amount: br.collectiveInvoice!.amount,
        text: br.collectiveInvoice!.text,
      }
    });
    
    // talk to api to create the invoice
    // note: the api will also mark the booking requests with the collective invoice id
    const result = await Api.post(this.ApiEndpoint, "create", collectiveInvoice.toDb());
    if(result.success) {
      return {
        success: true,
        data: CollectiveInvoice.fromDb(result.data.item)
      }
    }
    else {
      console.error("result", result)
      return Result.fromDb(result);
    }
  }

  static async remove(collectiveInvoice:CollectiveInvoice): Promise<Result<CollectiveInvoice>> {
    // talk to api to remove the invoice
    // note: this will also 'reset' all related booking requests (i.e. set the collectiveInvoice.collectiveInvoice_id to null)
    const apiResult = await Api.post(this.ApiEndpoint, "delete", {id:collectiveInvoice._id});
    if(apiResult.success) {
      return {
        success:true, data: collectiveInvoice
      }
    }
    else {
      return Result.fromDb(apiResult);
    }
  }

  /**
   * Returns all providers that support collective invoices
   */
  static async getProviders(): Promise<DA.Provider[]> {
    const providers = await DA.ProviderRepository.search({"collectiveInvoice.isActive":true});
    const filtered = providers.filter(p => {
      return (p.collectiveInvoice.email || "").trim().length > 0
    });
    return filtered;
  }

  /**
   * Returns all booking request that can be used in a collective invoice  
   * @returns 
   */
  static async getOpenBookingRequests(period:CollectiveInvoicePeriod): Promise<BookingRequest[]> {
    // get all booking requests that have should be part of a collective invoice, but are not yet
    const bookingRequests = await BookingRequestRepository.search({$and:[
      {"collectiveInvoice":{$ne:null}},
      {"collectiveInvoice.collectiveInvoice_id":null},
      {"response.accepted":true},
    ]});

    // get all related adventures
    const adventureIds = bookingRequests.map(br => br.adventure_id);
    const adventures = await Adventure.search({_id:{$in:adventureIds}});

    // make sure we only have booking requests of adventures that are 
    // - finished 
    // - not later than the selected period
    //const lastDayInPeriod = new Date(period.year, period.month + 1, 0, 23, 59, 59);
    const firstDayInNextPeriod = new Date(period.year, period.month + 1, 1, 0, 0, 0, 0);
    const relevantBookingRequests = bookingRequests.filter(b => {
      const adventure = adventures.find(a => a._id === b.adventure_id);
      // no adventure? or adventure with no start time? no deal
      if(!adventure || !adventure.StartTime) {
        return false;
      }
      // adventure's start time not within selected period
      if(adventure.StartTime.getTime() > firstDayInNextPeriod.getTime()) {
        return false;
      }
      // we only consider a booking request if its adventure is finished
      if(adventure.Status !== AdventureStatus.Finished && adventure.Status !== AdventureStatus.Reviewed) {
        return false;
      }
      // alright
      return true;
    })
    // done
    return relevantBookingRequests;
  }

  /**
   * Returns the period before the current period. The current period being the current month.
   * @returns 
   */
  static getPreviousPeriod(): CollectiveInvoicePeriod {
    const lastMonth = new Date();
    lastMonth.setDate(1);
    lastMonth.setMonth(lastMonth.getMonth()-1);
    return {year:lastMonth.getFullYear(), month:lastMonth.getMonth()}
  }

  static printPeriod(period: CollectiveInvoicePeriod) : string {
    const monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
    return `${monthNames[period.month]} ${period.year}`;
  }

  static printStatus(invoice: CollectiveInvoice): CollectiveInvoiceStatus {
    if(invoice.datePaid) {
      return "bezahlt";
    }
    if(invoice.dateSent) {
      return "versendet";
    }
    return "offen";
  }
}