import _ from "lodash";

export class Me {
  /**
   * Returns the region closest to a given set of coordinates.
   * WARNING: regions are hardcoded and include only "CH-BE", "CH-BS", "CH-LU", "CH-SG", "CH-ZH", "DE-B", "DE-HH", "DE-M", "DE-RU"
   * @param lat 
   * @param lng 
   * @returns 
   */
  static getClosestRegion(lat:number, lng: number): null|{code:string, distance:number} {
    if(isNaN(lat) || isNaN(lng)) {
      return null;
    }
    // TODO hard-coded ... need to make coordinates part of Region model  
    const regions = [
      {code: "CH-BE", coords: {lat:46.94898865511854, lng:7.438612828875388}}, // Bern, Bahnhof
      {code: "CH-BS", coords: {lat:47.54755277055162, lng:7.589661998205497}}, // Basel, HB
      {code: "CH-LU", coords: {lat: 47.050820909416316, lng:8.310745838669284}}, // Luzern, Bahnhof
      {code: "CH-SG", coords: {lat:47.423007431123864, lng:9.370087938679607}}, // St.Gallen Bahnhof
      {code: "CH-ZH", coords: {lat:47.37784334888578, lng:8.540526446081314}}, // Zürich HB
      {code: "DE-B", coords: {lat:52.52505124193343, lng:13.369337625336591}}, // Berlin HB
      {code: "DE-HH", coords: {lat: 53.55197288431169, lng: 10.011384410024908}}, // Hamburg HB
      {code: "DE-M", coords: {lat: 48.139724862558346, lng: 11.560883296371449}}, // München HB
      {code: "DE-RU", coords: {lat: 51.504872910795555, lng: 7.101885148589249}}, // Gelsenkirchen
    ]
  
    // calculates distance between two points, returns distance in km
    const calculateDistance = (lat1:number, lng1:number, lat2:number, lng2:number) => {
      // source: https://www.geeksforgeeks.org/program-distance-two-points-earth
      // The math module contains a function named toRadians which converts from degrees to radians.
      lng1 = lng1 * Math.PI / 180;
      lng2 = lng2 * Math.PI / 180;
      lat1 = lat1 * Math.PI / 180;
      lat2 = lat2 * Math.PI / 180;
  
      // Haversine formula
      let dlon = lng2 - lng1;
      let dlat = lat2 - lat1;
      let a = Math.pow(Math.sin(dlat / 2), 2)
      + Math.cos(lat1) * Math.cos(lat2)
      * Math.pow(Math.sin(dlon / 2),2);
  
      let c = 2 * Math.asin(Math.sqrt(a));
  
      // Radius of earth in kilometers. Use 3956 for miles
      let r = 6371;
  
      // calculate the result
      return(c * r);
    }
  
    // get the closest
    let shortestDistance = 40075; // earth's circumference
    let closest = null;
    regions.forEach(r => {
      const distance = calculateDistance(lat, lng, r.coords.lat, r.coords.lng);
      if(distance < shortestDistance) {
        shortestDistance = distance;
        closest = {
          code: r.code,
          distance: distance
        }
      }
    })
    return closest;
  }
}