import _ from "lodash";
import React, {useEffect, useContext, useState} from "react";
import * as TS from "../../../types";
// subviews
import Actions from "./Contextual/Actions.View";
import Assistant from "./Assistant/Assistant";
import Audit from "./Audit/Audit";
import Infos from "./Contextual/Infos.View";
import Conditions from "./Conditions/Conditions.View";
import Dunning from "./Dunning/Dunning.View";
import Files from "./Files/Files";
import GeneralInfo from "./GeneralInfo/GeneralInfo.View";
import Links from "./Links/Links.View";
import NotesAndLog from "./NotesAndLog/NotesAndLog.View";
import Operator from "./Operator/Operator.View";
import PrepaidAndBuddy from "./PrepaidAndBuddy/PrepaidAndBuddy.View";
import Shipping from "./Shipping/Shipping.View";
import Steps from "./Steps/Steps.View";
import Warnings from "./Warnings/Warnings.View";
import Weather from "./Weather/Weather.View";
// components
import {Redirect} from "react-router-dom";
import StateVector from "./StateVector/StateVector";
import { Alert, Breadcrumb, Loader, Heading } from "../../controls";
// context
import {SurpriseContext, SurpriseProvider} from "./Surprise.Context";

// styling
import "./Surprise.View.scss";
import Tasks from "./Tasks/Tasks.List";

// the component
function SurpriseView(props:any) {
  // context
  const SC = useContext(SurpriseContext);

  // state
  const [needsFix, setNeedsFix] = useState(false);
  
  // id in url changed, load the surprise
  useEffect(() => {
    // TODO check if needs fix, if so handle it by redirecting
    // load surprise
    SC.load(_.get(props, "match.params.id"));
  }, [props.match.params.id]);

  // check if a fix is needed
  useEffect(() => {
    const checkSanity = async(surprise:any) => {
      const needsActivitiesFix = await TS.AdventureOld.needsActivitiesFix(surprise); 
      const needsSoldOfflineFix = await TS.AdventureOld.needsSoldOfflineFix(surprise);
      if(needsActivitiesFix || needsSoldOfflineFix) {
        setNeedsFix(true);
      }
    }
    if(SC.surprise) {
      checkSanity(SC.surprise);
    }
  }, [SC.surprise]);

  // the loaded surprise has changed
  useEffect(() => {
    if(SC.surprise) {
      console.log(SC.surprise);
    }
  }, [SC.surprise]);
  
  // render
  let content = null;
  if(!SC.ready) {
    content = <Loader />
  }
  else if(SC.error) {
    content = <Alert intent="error" size="large" title="Überraschung kann nicht geladen werden">{SC.error.message}</Alert>
  }
  else if(needsFix) {
    return <Redirect to={`/surprisefix/${SC.surprise._id}`} />
  }
  else {
    // let dialog = null;
    content = <>
      <Header />
      <div id="v-surprise-view-inner">
        <div id="v-surprise-view-inner-left">
          <Audit />
          <Tasks />
          <Links expanded={false} />
          <Operator />
          <GeneralInfo expanded={false} />
          <Shipping expanded={false} headless={false} />
          <Conditions expanded={false} />
          <Assistant expanded={true} />
          <Steps expanded={true} />
          <PrepaidAndBuddy expanded={false} /> 
          <Files expanded={false} />
          <Dunning expanded={false} />
          <NotesAndLog expanded={false} />
          <Weather expanded={false} />
        </div>
        <div id="v-surprise-view-inner-right">
          <Infos />
          <Actions expanded={false} />
        </div>
      </div>
      {/*dialog*/}
    </>
  }
  return (
    <div id="v-surprise-view">
      {content}
    </div>
  );
}

function Header() {
  const SC = useContext(SurpriseContext);
  const title = TS.Adventure.getTitle(SC.surprise);
  const browserTitle = `${SC.surprise.RecieverName || "????"} [${SC.surprise.__id4}]`;
  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}, {title:'Überraschungen', to:'/surprises/search'}]} location={title} browserTitle={browserTitle} />
    <Heading>{title}</Heading>
    <Warnings />
    <StateVector activeStatus={SC.surprise.Status}/>
  </>
}

export default (props:any) => <SurpriseProvider><SurpriseView {...props} /></SurpriseProvider>

