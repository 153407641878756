import _ from "lodash";
import moment from "moment";
import Util from "../../util/util";
import ApiOld from "../../util/api";
import Config from "../../config";
//import SurpriseLogic from "../../logic/Surprise";
import { Adventure, AdventureStatus } from "../Adventure";
import * as DA from "../DataAccess";


export type ShippingInfoLabelUrl = {
  label: string,
  url: string
}
export type ShippingInfoTickets = {
  pdf: {primary: ShippingInfoLabelUrl, secondary: ShippingInfoLabelUrl},
  html: {primary: ShippingInfoLabelUrl, secondary: ShippingInfoLabelUrl},
  card: {primary: ShippingInfoLabelUrl, secondary: ShippingInfoLabelUrl}
}
export type ShippingInfo = {
  addresses: {
    invoice: string[],
    shipping: string[],
    areEqual: boolean
  },
  packaging:{
    name: string, 
    imageUrl: string
  },
  tickets: ShippingInfoTickets,
  coverletters: {
    regular: ShippingInfoLabelUrl,
    direct: ShippingInfoLabelUrl
  }
}

export class Shipping {
  /**
   * Returns adventuers that need shipping
   * @param includeShippedToday 
   * @returns 
   */
  static async getAdventuresToShip(includeShippedToday:boolean) : Promise<Adventure[]> {
    const filterStatus = {Status:{$in:[AdventureStatus.Ordered, AdventureStatus.DateSelected, AdventureStatus.ConditionsSent, AdventureStatus.ConditionsAccepted, AdventureStatus.Ready]}};
    const filterNotShipped = {"ShippingDate":{$eq:null}};
    let filter = {};
    if(includeShippedToday) {
      const today = moment().set({hour:0, minute:0, second:0, millisecond:0}).toDate();
      const filterShippedToday = {"ShippingDate":{"$gt":today}};
      filter = {$and:[filterStatus, {$or:[filterNotShipped, filterShippedToday]}]};
    }
    else {
      filter = {$and:[filterStatus, filterNotShipped]};
    }

    const adventures = await Adventure.search(filter);
    return adventures;
  }

  /**
   * Gets the shipping status of a surprise
   * @param adventure 
   * @returns 
   */
  static getShippingStatus(adventure:any): {shipped:boolean, text:string} {
    if(_.isNil(adventure.ShippingDate)) {
      return {
        shipped:false, 
        text: "noch nicht verschickt"
      }
    }
    else {
      let text
      if(_.get(adventure, "Order.Packaging.Code") === "PDF") {
        text = `via E-Mail verschickt am ${Util.printDateAndTime(adventure.ShippingDate)}`;
      }
      else {
        text = `verschickt am ${Util.printDateAndTime(adventure.ShippingDate)}`;
      }
      return {
        shipped: true, 
        text 
      }
    }
  }

  /**
   * Marks an adventure as shipped and sends the shipping mail
   * @param adventureId 
   * @param suppressMail 
   */
  static async markAsShipped(adventureId:string, suppressMail:boolean = false) {
    await Adventure.update(adventureId, {ShippingDate: new Date()});
    if(!suppressMail) {
      await Shipping.sendShippingMail(adventureId);
    }
  }

  /**
   * Sends the shipping mail
   * @param surpriseId 
   */
  static async sendShippingMail(surpriseId:string) {
    // TODO use new API 
    await ApiOld.get(`mail/shipped/${surpriseId}`);
  }

  /**
   * Returns a list of address lines for a given address
   * @param address 
   * @returns 
   */
  static getAddressLines(address:any): string[] {
    const name = [address.FirstName || "", address.LastName || ""].map(s => s.trim()).filter(s => s.length > 0).join(" ");
    const company = (address.Company || "").trim();
    const address1  = (address.Address1 || "").trim();
    const address2  = (address.Address2 || "").trim();
    const zipCity = [address.Zip || "", address.City || ""].map(s => s.trim()).filter(s => s.length > 0).join(" ");
    const country = (address.Country || "").trim() === "DE" ? "GERMANY" : "";
  
    return [name, company, address1, address2, zipCity, country].filter(line => line.length > 0);
  }

  /**
   * Returns shipping related information for a given surprise
   * @param surprise 
   * @param shippingTypes 
   * @returns 
   */
  static getShippingInfo(surprise:any, shippingTypes:DA.PackagingType[]) : ShippingInfo {
    // is this a prepaid surprise?
    const isPrepaidSurprise = (_.get(surprise, "Order.Prepaid.Value") || 0) > 0;
  
    // get the shipping type for this surprise
    const shippingType: Partial<DA.PackagingType> = shippingTypes.find(s => s.code === _.get(surprise, "Order.Packaging.Code")) || {};
  
    // addresses
    const address_invoice: string[] = Shipping.getAddressLines(_.get(surprise, "Order.InvoiceAddress") || {});
    const address_shipping: string[] = Shipping.getAddressLines(_.get(surprise, "Order.ShippingAddress") || {});
    const addresses_areEqual: boolean = _.isEqual(address_invoice, address_shipping);
  
    // ticket links
    const getTicket = (kind:"A4"|"A6"|"Card", output:string, isPrepaidSurprise:boolean) : ShippingInfoLabelUrl => {
      const baseUrl = `${Config.api.ApiRoot}/v1/certificate/${surprise._id}`;
      let label = "";
      let url_kind = ""
      switch(kind) {
        case "A4":
          label = `A4-Brief [${output}]`;
          url_kind = "standard";
          break;
        case "A6":
          label = `A6-Flyer [${output}]`;
          url_kind = "flyer";
          break;
        case "Card":
        default:
          label = `Karte`
          url_kind = "card";
          break;
      }
      //const label = kind === "A4" ? `A4-Brief [${output}]` : `A6-Flyer [${output}]`;
      const url = `${baseUrl}?kind=${url_kind}${isPrepaidSurprise ? "_prepaid": ""}&o=${output}`;
      return {label, url};
    }
    let tickets: ShippingInfoTickets // = {html:ShippingInfoTicket}
    if(shippingType.code === "SEALEDLETTER") {
      tickets = {
        html: {primary: getTicket("A6", "html", isPrepaidSurprise), secondary: getTicket("A6", "html", isPrepaidSurprise)},
        pdf: {primary: getTicket("A6", "pdf", isPrepaidSurprise), secondary: getTicket("A6", "pdf", isPrepaidSurprise)},
        card: {primary:getTicket("Card", "html", isPrepaidSurprise), secondary:getTicket("Card", "html", isPrepaidSurprise)}
      }
    }
    else {
      tickets = {
        html: {primary: getTicket("A6", "html", isPrepaidSurprise), secondary: getTicket("A4", "html", isPrepaidSurprise)},
        pdf: {primary: getTicket("A6", "pdf", isPrepaidSurprise), secondary: getTicket("A4", "pdf", isPrepaidSurprise)},
        card: {primary: getTicket("Card", "html", isPrepaidSurprise), secondary: getTicket("Card", "html", isPrepaidSurprise)}
      }
    }
  
    // coverletter links
    const getCoverletter = (kind:string): ShippingInfoLabelUrl => {
      const baseUrl = `${Config.api.ApiRoot}/v1/coverletter/${surprise._id}`;
      const url = `${baseUrl}${kind === "direct" ? "?kind=direct" : ""}`;
      const label = kind === "direct" ? "Direktversand" : "Begleitbrief"; // `Begleitbrief${kind === "direct" ? " Direktversand" : ""}`;
      return {label, url}
    }
    const coverletters_regular = getCoverletter("");
    const coverletters_direct = getCoverletter("direct");
  
    // assemble the info and return it
    return {
      packaging:{
        name:_.get(shippingType, "title.de"), 
        imageUrl:_.get(shippingType, "image.url")
      },
      tickets,
      coverletters: {
        regular: coverletters_regular,
        direct: coverletters_direct
      },
      addresses: {
        invoice: address_invoice,
        shipping: address_shipping,
        areEqual: addresses_areEqual
      }
    }
  }
}