import React, {useContext, useState, useEffect} from "react"
import * as TS from "../../../../types";
import * as DataAccess from "../../../../types/DataAccess";
import { Actions, Button, Icon, Icons, List, Loader, Modal, AddRemoveItem, AddRemove, CardSection } from "../../../controls";
import { ActivityContext } from "../ActivityContext";

export default function Equipment() {
  const [mode, setMode] = useState<"edit"|"view">("view");

  // context
  const AC = useContext(ActivityContext)!;

  // users wants to edit
  const startEdit = () => setMode("edit");
  // stops edit mode
  const stopEdit = () => setMode("view");
  
  // saves changes
  const saveEdit = async(updatedEquipment: TS.ActivityRegionVariantEquipment[]) => {
    const changeset = {equipment: updatedEquipment.map(e => e.toDb())};
    await AC.updateRegionVariant(AC.regionVariant!._id!, changeset);
    stopEdit();
  }

  // render
  return <>
    <CardSection title="Ausrüstung">
      <div id="v-activity-regionvariant-equipment">
        <List 
          columns={[{header:"Ausrüstung", path:"title.de"}]} 
          items={AC.regionVariant!.equipment} 
          getKey={v => v._id}
          hideHeader={true} hideFooter={true} hideSearch={true} 
        />
        <Button onClick={startEdit} size="small"><Icon icon={Icons.Edit} /> bearbeiten</Button>
      </div>
    </CardSection>
    <Modal isOpen={mode==="edit"} title="Regionsvariante - Equipment" onClose={stopEdit}>
      <Edit equipment={AC.regionVariant!.equipment} saveEdit={saveEdit} onCancel={stopEdit} />
    </Modal>
  </>
}


type EditProps = {
  equipment: TS.ActivityRegionVariantEquipment[],
  saveEdit: (updatedEquipment:TS.ActivityRegionVariantEquipment[]) => void,
  onCancel: () => void,
}
function Edit({equipment, saveEdit, onCancel} : EditProps) {
  // state
  const [items, setItems] = useState<AddRemoveItem[]>([]);
  const [busy, setBusy] = useState(false);
  const [catalogue, setCatalogue] = useState<any>(null);

  // mount
  useEffect(() => {
    const load = async() => {
      const catalogue: DataAccess.Equipment[] = await DataAccess.EquipmentRepository.searchAll();
      const items: AddRemoveItem[] = catalogue.map(catalogueItem => {
        const selected = equipment.some(item => item.equipment_id === String(catalogueItem._id));
        return {
          selected,
          label: catalogueItem.title.de,
          id: catalogueItem._id!
        }
      })
      setItems(items);
      setCatalogue(catalogue);
    };
    load();
  }, []);

  // save the update
  const onSave = async() => {
    setBusy(true);
    const updatedEquipment: TS.ActivityRegionVariantEquipment[] = items
      .filter(item => item.selected)
      .map(item => {
        // find item in catalogue
        const catalogueItem = catalogue.find((ci:any) => ci._id === item.id);
        // create instance of region variant equipment
        const equipment = new TS.ActivityRegionVariantEquipment();
        equipment.title = TS.Translatable.fromDb(catalogueItem.title);
        equipment.equipment_id = catalogueItem._id;
        // add to collection
        return equipment;
      });
    await saveEdit(updatedEquipment); 
    setBusy(false);
  }

  // still loading data?
  if(catalogue === null) {
    return <Loader text="lade Ausrüstungs-Katalog" />
  }

  // render
  return (
    <div>
      <AddRemove items={items} maxHeight={300} />
      <Actions align="center" onSave={onSave} onCancel={onCancel} busy={busy} />
    </div>
  )
}
