import React from "react";
import { Alert, Link } from "../../../controls"

type CoverletterLinksProps = {
  coverletters:any,
  isDirectShipping:boolean
}
export default function CoverletterLinks({coverletters, isDirectShipping}: CoverletterLinksProps) {
  const esr = (
    <Alert intent="hint" size="small">
      Falls QR-Rechnungen Probleme bereiten, kann mit folgendem Link eine ESR-Rechnung erzeugt werden: <Link to={coverletters.regular.url + "?override=esr"}>ESR Rechnung</Link>
    </Alert>
  )
  if(isDirectShipping) {
    return <>
      <Link to={coverletters.direct.url} target="_blank">{coverletters.direct.label}</Link> (oder <Link to={coverletters.regular.url} target="_blank">{coverletters.regular.label}</Link>)
      {esr}
    </>
  }
  else {
    return <>
      <Link to={coverletters.regular.url} target="_blank">{coverletters.regular.label}</Link> (oder <Link to={coverletters.direct.url} target="_blank">{coverletters.direct.label}</Link>)
      {esr}
    </>
  }
}
