import React from "react";
import Code from "../Code";
import { Card, Heading, LinkButton } from "../../../controls";

export default function LinkButtonDemo() {
  
  // render
  return <>
    <Heading>LinkButton</Heading>
    <Card>
      <div>
        <LinkButton to="/" size="small">Home</LinkButton>
        <Code>{`<LinkButton to="/" size="small">Home</LinkButton>`}</Code>
      </div>

      <div>
        <LinkButton to="https://appentura.ch" size="medium" target="_blank">appentura.ch</LinkButton>
        <Code>{`<LinkButton to="https://appentura.ch" size="medium" target="_blank">appentura.ch</LinkButton>`}</Code>
      </div>

      <div>
        <LinkButton to="/" size="small" disabled>Home</LinkButton>
        <Code>{`<LinkButton to="/" size="small" disabled>Home</LinkButton>`}</Code>
      </div>
    </Card>
  </> 
}
