import _ from "lodash";
import React, {useEffect, useState, useContext} from "react";
import * as TS from "../../../types";
import { Value, Checkbox, Form, Icon, Icons, CardSection, Modal } from "../../controls";
import { ActivityPackageContext } from "./ActivityPackage.Context";


export default function ActivityPackageStatus() {
  // context
  const AC = useContext(ActivityPackageContext); 
  // state
  const [busy, setBusy] = useState<boolean>(false);
  const [mode, setMode] = useState<"edit"|"view">("view");

  // no context? no render
  if(!AC || !AC.activityPackage) { 
    return null;
  }

  // saves changes
  const save = async() => {
    setBusy(true);
    await AC.save();
    setBusy(false);
  }

  // user wants to edit status
  const onClickEdit = () => setMode("edit");
  // closes dialog
  const closeDialog = () => setMode("view");

  // render
  const isActive = AC.activityPackage.isActive;
  return <>
    <CardSection title="Status">
      <Value onClick={onClickEdit} disabled={busy} >
        <div><Icon icon={isActive ? Icons.Check : Icons.Cancel} />&nbsp;{isActive ? "ist aktiv" : "ist nicht aktiv"}</div>
      </Value>
    </CardSection>
    <Modal isOpen={mode === "edit"} title="Status bearbeiten" onClose={closeDialog}>
      <Edit activityPackage={AC.activityPackage} save={save} closeDialog={closeDialog} />
    </Modal>
  </>
}

type EditProps = {
  activityPackage: TS.ActivityPackage, 
  save:() => Promise<void>,
  closeDialog: VoidFunction
}
function Edit({activityPackage, save, closeDialog} : EditProps) {
  // state
  const [entity, setEntity] = useState({isActive:activityPackage.isActive});
  const [busy, setBusy] = useState(false);

  // mount
  useEffect(() => {
  }, []);

  // save the update
  const onSave = async(formResult:any) => {
    const updated = formResult.merge(entity);
    activityPackage.isActive = updated.isActive;
    setBusy(true);
    await save();
    closeDialog();
  }

  // cancel
  const onCancel = () => {
    closeDialog();
  }

  // render
  return (
    <Form 
      entity={entity} 
      onCancel={onCancel} 
      onSave={onSave}
      busy={busy}
    >
      <Checkbox
        path="isActive" 
        label="ist aktiv"
        disabled={busy}
        explanation="Aktive Pakete werden im Shop dargestellt." 
      />
    </Form>
  ); 
}
