import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
// types
import * as DA from "../../../../types/DataAccess";
import * as BL from "../../../../types/BusinessLogic";
import * as Cms from "../../../../types/Cms";

// contexts and components
import { PageContext } from "../Page.Context";
import { Actions, Card, Alert, TextInput, TextArea, Button, Form, Label, Dropdown, Validators, Loader, NumberInput, CloudStorageUploader, SortableList, SortableListItem } from "../../../controls";

import * as Selectors from "../Selectors";
import * as Preview from "../Preview";

// styling and images
import CSS from "./ActivitiesStatic.module.scss";


type ActivitiesStaticProps = {
  instance?: Cms.ActivitiesStaticModule,
  closeDialog: () => void,
}
export function ActivitiesStatic({instance, closeDialog}:ActivitiesStaticProps) {
  // context
  const PC = useContext(PageContext);
  
  // state
  const [mode, setMode] = useState<"properties"|"item">("properties");
  const [itemToEdit, setItemToEdit] = useState<Cms.ActivitiesStaticModuleItem>(new Cms.ActivitiesStaticModuleItem());
  const [entity, setEntity] = useState<Cms.ActivitiesStaticModule|null>(null);
  const [busy, setBusy] = useState<boolean>(false);
  const [sortingDisabled, setSortingDisabled] = useState<boolean>(false);

  // mount
  useEffect(() => {
    let entity: Cms.ActivitiesStaticModule;
    if(instance) {
      entity = instance;
    }
    else {
      entity = new Cms.ActivitiesStaticModule();
    }
    setEntity(entity)
  }, [])



  // user wants to cancel
  const onCancel = () => {
    closeDialog();
  }

  // user wants to save
  const onSave = async(formResult:any) => {
    setBusy(true);
    const updatedEntity = formResult.merge(entity);
    
    if(formResult.changeset.countries) {
      // TODO we need to do this because formResult.merge does not seem to handle arrays correctly
      updatedEntity.countries = formResult.changeset.countries;
    }

    await PC.upsertModule(updatedEntity);

    // close dialog
    closeDialog();
  }

  // user wants to save an item
  const onSaveItem = async(itemToSave:Cms.ActivitiesStaticModuleItem) => {
    if(entity) {
      // update or add the item
      const items = entity.elements.items.filter((item:Cms.ActivitiesStaticModuleItem) => String(item.id) !== String(itemToSave.id));
      items.push(itemToSave);
  
      // get updated version of the module
      const updatedModule = _.cloneDeep(entity);
      updatedModule!.elements.items = items;
      
      // save the module
      await PC.upsertModule(updatedModule);

      // get the updated module and set it as the entity
      setEntity(updatedModule);
    }
  }

  // user wants to delete item
  const onClickDelete = async(itemToDelete:Cms.ActivitiesStaticModuleItem) => {
    if(entity) {
      // update or add the item
      const items = entity.elements.items.filter((item:Cms.ActivitiesStaticModuleItem) => String(item.id) !== String(itemToDelete.id));

      // get updated version of the module
      const updatedModule = _.cloneDeep(entity);
      updatedModule!.elements.items = items;
      
      // save the module
      await PC.upsertModule(updatedModule);

      // get the updated module and set it as the entity
      setEntity(updatedModule);
    }
  }

  // user wants to add/update item
  const onClickEditItem = (item:Cms.ActivitiesStaticModuleItem) => {
    setItemToEdit(item);
    setMode("item");
  }

  // user wants to add an item
  const onClickAddItem = () => {
    const newItem = new Cms.ActivitiesStaticModuleItem();
    newItem.position = entity!.elements.items.length;
    setItemToEdit(newItem);
    setMode("item");
  }

  // user drops item for sorting
  const onDrop = async(id:string, targetIndex:number) => {
    setSortingDisabled(true);
    // get a copy of the items to work with
    const updatedItems = entity!.elements.items.map(item => item).sort((a,b) => a.position - b.position);
    // get source index
    const indexFrom = updatedItems.findIndex(item => item.id === id); // result.source.index;
    
    // remove element and move to new position
    const element = updatedItems.splice(indexFrom, 1)[0]; // splice returns the removed elements as an array, since we only remove one item, we can get it from index 0
    updatedItems.splice(targetIndex, 0, element);

    // update position property on items
    updatedItems.forEach((item, index) => item.position=index);

    // TODO NOT DRY this is to a great extent the same code as above in onSaveItem
    // get updated version of the module
    const updatedModule = _.cloneDeep(entity!);
    updatedModule!.elements.items = updatedItems;
    
    // save the module
    await PC.upsertModule(updatedModule);
    setSortingDisabled(false);

    // get the updated module and set it as the entity
    setEntity(updatedModule);

  }

  // entity not set yet? render nothing
  if(!entity) {
    return null;
  }

  // render
  if(mode === "properties") {
    return (
      <Properties 
        entity={entity}
        onClickDelete={onClickDelete}
        onClickEditItem={onClickEditItem}
        onClickAddItem={onClickAddItem}
        onCancel={onCancel}
        onSave={onSave}
        onDrop={onDrop}
        busy={busy}
        sortingDisabled={sortingDisabled}
      />
    )
  }
  else {
    return (
      <EditItem 
        item={itemToEdit}
        onSaveItem={onSaveItem}
        closeDialog={() => setMode("properties")}
      />
    )
  }

  
}

type PropertiesProps = {
  entity: Cms.ActivitiesStaticModule,
  onClickAddItem: () => void,
  onClickEditItem: (item:Cms.ActivitiesStaticModuleItem) => void,
  onClickDelete: (item:Cms.ActivitiesStaticModuleItem) => void,
  onCancel: () => void,
  onSave: (formData:any) => void,
  onDrop: (id:string, targetIndex:number) => void,
  busy: boolean,
  sortingDisabled: boolean,
}
function Properties({entity, onClickEditItem, onClickDelete, onClickAddItem, onCancel, onSave, onDrop, busy, sortingDisabled} : PropertiesProps) {
  // pre-render
  let listAction = null;
  let list = null;
  if(entity._id) {
    listAction = (
      <div>
        <Button size="small" onClick={onClickAddItem}>Eintrag hinzufügen</Button>
      </div>
    )
    // list
    // pre-render items
    const items = entity.elements.items
    .sort((a,b) => a.position - b.position)
    .map((item:Cms.ActivitiesStaticModuleItem, index:number) => {
      return (
        <SortableListItem id={item.id} key={item.id}>
          <Card title={item.name} size="small">
            <ItemPreview item={item} />
            <Actions size="small" onEdit={() => onClickEditItem(item)} onDelete={() => onClickDelete(item)} />
          </Card>
        </SortableListItem>
      )
    })
    list = (
      <SortableList onDrop={onDrop} disabled={sortingDisabled} noItemsText="noch keine Einträge">
        {items}
      </SortableList>
    )
  }
  else {
    listAction = <Alert size="small" intent="info" title="Speichern notwendig">Bevor Einträge hinzugefügt werden können, muss dieses Modul erst gespeichert werden</Alert>
  }

  // render
  return (
  <Form entity={entity} onCancel={onCancel} onSave={onSave} busy={false}>
    
    <Selectors.BaseProperties busy={busy} />

    <TextInput label="Titel" path="elements.title" placeholder="Titel eingeben" disabled={busy} />
    
    <Label label="Einträge" />

    {list}
    {listAction}
  </Form>
  )
}


type ActivityLight = {
  _id: string,  
  name: string,
  shortDescription: string,
  slug: string,
  images: [string],
  pricePerPerson: number
}
type EditItemProps = {
  item: Cms.ActivitiesStaticModuleItem,
  onSaveItem:(itemToSave:Cms.ActivitiesStaticModuleItem) => void,
  closeDialog: () => void,
}
function EditItem({item, onSaveItem, closeDialog} : EditItemProps) {
  // state
  const [entity, setEntity] = useState<Cms.ActivitiesStaticModuleItem>(item);
  const [busy, setBusy] = useState<boolean>(false);
  const [activities, setActivities] = useState<ActivityLight[]>([]);
  const [activitiesDropdownOptions, setActivitiesDropdownOptions] = useState<any[]>([]);
  const [selectedActivityId, setSelectedActivityId] = useState<string|null>(null);

  // load activities
  useEffect(() => {
    const loadActivities = async() => {
      const allActiveMain = await DA.ActivityRepository.getAllActiveMain();
      const activities:ActivityLight[] = allActiveMain.map(a => {
        return {
          _id: a._id,
          name: a.title.de,
          shortDescription: a.shortDescription.de,
          pricePerPerson: BL.Activity.getLowestPricePerPerson(a), // TODO country is hardcoded
          images: a.images.map(img => img.url),
          slug: a.slug.de
        } as ActivityLight
      }).sort((a:ActivityLight, b:ActivityLight) => a.name.localeCompare(b.name));

      const activitiesDropdownOptions = activities.map(a => {
        return { label: a.name, value: a._id}
      })

      setActivities(activities);
      setActivitiesDropdownOptions(activitiesDropdownOptions);
    }
    loadActivities();
  }, []);

  // user wants to cancel
  const onCancel = () => {
    closeDialog();
  }

  // user wants to save
  const onSave = async(formResult:any) => {
    setBusy(true);
    const updatedEntity = formResult.merge(entity);
    await onSaveItem(updatedEntity);
    closeDialog();
  }

  
  // user wants to use values from selected activity
  const onClickCopyValues = () => {
    // get the activity
    const activity = activities.find(a => a._id === selectedActivityId);
    
    if(activity) {
      const newEntity:Cms.ActivitiesStaticModuleItem = new Cms.ActivitiesStaticModuleItem();
      newEntity.name = activity.name;
      newEntity.shortDescription = activity.shortDescription;
      newEntity.buttonLabel = entity.buttonLabel;
      newEntity.imageUrl = activity.images[0];
      newEntity.pricePerPerson = activity.pricePerPerson;
      newEntity.slug = activity.slug;

      setEntity(newEntity);
    }

  }
  
  // pre-render 
  let activitySelector = <Loader text="lade Aktivitäten ..." />;
  if(activitiesDropdownOptions.length > 0) {
    activitySelector = <>
      <Dropdown options={activitiesDropdownOptions} path="__" onChange={(id:any) => setSelectedActivityId(id) } label="Übernehmen von ..." />
      <Button onClick={onClickCopyValues} size="small">Werte übernehmen</Button>
    </>
  }
  // render
  
  return <>
    
    <Form entity={entity} onCancel={onCancel} onSave={onSave} busy={false}>
      {activitySelector}
      <hr />  
      <TextInput label="Name" path="name" placeholder="Schnulzbacken-Tango" disabled={busy} validate={Validators.isRequired("Name der Aktivität eingeben")} />
      <TextArea label="Kurzbeschreibung" path="shortDescription" disabled={busy} validate={Validators.isRequired("Kurzebeschreibung eingeben")} />
      <NumberInput label="Preis pro Person" path="pricePerPerson" validate={Validators.isPrice("Preis eingeben")} />
      <TextInput label="Button Beschriftung" path="buttonLabel" placeholder="jetzt schenken" disabled={busy} validate={Validators.isRequired("Button-Beschriftung eingeben")} />
      <TextInput label="Slug" path="slug" placeholder="" disabled={busy} validate={Validators.isRequired("Slug eingeben")} />
      <CloudStorageUploader kind="image" label="Bild" folder="cms" path="imageUrl" disableUrlEditing={true} prefix="cms" />
    </Form>
  </>
}

type ActivitiesStaticPreviewType = {
  instance: Cms.ActivitiesStaticModule,
  expanded?: boolean,
  onClickAction: (module:Cms.Module, action:"edit"|"delete"|"duplicate") => void
}
export function ActivitiesStaticPreview({instance, expanded, onClickAction}:ActivitiesStaticPreviewType) {
  const items = instance.elements.items.sort((a, b) => a.position - b.position).map((item:Cms.ActivitiesStaticModuleItem) => {
    return <ItemPreview key={item.id} item={item} />
  })

  return (
    <Preview.Container expanded={expanded} module={instance} title={Cms.ActivitiesStaticModule.info.title} onClickAction={onClickAction}>
      <Preview.Table>
        <Preview.TableRow label="Titel">{instance.elements.title || "-"}</Preview.TableRow>
        <Preview.TableRow label="Einträge">
          {items}
        </Preview.TableRow>
      </Preview.Table>
    </Preview.Container>
  )
}

function ItemPreview({item} : {item: Cms.ActivitiesStaticModuleItem}) {
  return (
    <div className={CSS.preview_item}>
      <div className={CSS.left}>
        <div className={CSS.image}>
          <img src={item.imageUrl} />
        </div>
      </div>
        <div className={CSS.right}>
          <div className={CSS.name}>{item.name}</div>
          <div className={CSS.shortDescription}>{item.shortDescription}</div>
          <div className={CSS.pricePerPerson}>{item.pricePerPerson}</div>
          <Preview.Button text={item.buttonLabel} />
      </div>
    </div>
  )
}