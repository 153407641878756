import React, { useContext, useState, useEffect } from "react";
import * as TS from "../../../../../types";
import * as DA from "../../../../../types/DataAccess";
import { Loader, Dropdown, DropdownOption } from "../../../../controls";
import { SearchContext } from "../Search.Context";
import FilterGroup from "../Search.FilterGroup";

export default function Region() {
  // context
  const Search = useContext(SearchContext);

  // state
  const [options, setOptions] = useState<DropdownOption[]|null>(null);

  // mount
  useEffect(() => {
    const load = async () => {
      const regions = await DA.RegionRepository.findAll(); 
      const options:Array<DropdownOption> = regions.map(r => {
        return {
          value: r.code,
          label: `${r.title.de} (${r.code})`
        }
      })
      options.unshift({label:"-", value:""});
      setOptions(options);
    }
    load();
  }, []);

  // onChange
  const onDropdownChange = (v:string) => {
    const updatedFilter = Search.filter.clone();
    updatedFilter.region = v;
    Search.reset(updatedFilter);
  }

  // render
  let content = null;
  if(options === null) {
    content =  <Loader text="lade Regionen" />
  }
  else {
    content = <Dropdown options={options} onChange={onDropdownChange} value={Search.filter.region} />
  }
  return (
    <FilterGroup title="Region">
      {content}
    </FilterGroup>
  )
}