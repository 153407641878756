import _ from "lodash";
import React, { useEffect, useContext, useState } from "react";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";

// context
import { ActivityContext } from "./ActivityContext";
import { List, ListColumn } from "../../controls";

// controls
import { Loader, CardSection } from "../../controls";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const columns: ListColumn[] = [
  {
    header:"Rating", 
    path:"rating", 
    print: (v:DA.Provider) => <Icon icon={faCircle} style={{color: v.colorRating || "silver"}} />, sort: (a:any, b:any) => a.ratingColorSort > b.ratingColorSort ? 1 : -1
  },
  {
    header: "Name",
    path: "name"
  }
];

export default function Providers() {
  // context
  const AC = useContext(ActivityContext)!;
  
  // state
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [providers, setProviders] = useState<DA.Provider[]>([]);

  // mount
  useEffect(() => {
    if(AC.activity && !isLoaded) {
      load(AC.activity);
    }
  }, [AC.activity]);

  // loads data
  const load = async(activity:TS.Activity) => {
    const providerIds: string[] = [];
    activity.regionVariants.forEach(rv => {
      rv.providers.forEach(p => {
        if(p.isActive) {
          providerIds.push(p.id);
        }
      })
    })
    const providers = await DA.ProviderRepository.search({_id:{$in:providerIds}});  
    setProviders(providers);
    setIsLoaded(true);
  }

  // render 
  let content = null;
  if(!isLoaded) {
    content = <Loader text="lade Anbieter ..." />
  }
  else {
    content = (
      <List 
        columns={columns}
        items={providers}
        getKey={item => item._id}
        hideSearch
        hideFooter
        rowTo={(v) => `/providers/${v._id}`}
      />
    )
  }
  return (
    <CardSection title="Anbieter">
      {content}
    </CardSection>
  )
}