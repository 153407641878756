import React, {useState} from "react";
import * as DA from "../../../../../types/DataAccess";
import { Button, ConfirmButton, Link, Icon, Icons } from "../../../../controls";

import "./Selfie.scss"

type SelfieProps = {
  adventureImage: DA.AdventureImage
}
export default function Selfie({adventureImage}: SelfieProps) {
  // state
  const [selfie, setSelfie] = useState<DA.AdventureImage>(adventureImage);
  const [edit, setEdit] = useState(false);


  // event handlers
  const onToggleMarketingAllowed = async() => {
    const changeset = {MarketingAllowed:!selfie.MarketingAllowed};
    const updateResult = await DA.AdventureImageRepository.update(selfie._id!, changeset);
    const updatedSelfie = updateResult.data!;
    setSelfie(updatedSelfie);
    setEdit(false);
  }

  // render
  let marketingAllowed = selfie.MarketingAllowed //&& selfie.DeletedByUser !== true

  let marketingFlag = null
  let settingsButton = null
  let selfieButton = null
  let toggleMarketingAllowed = null
  let deletedByUserFlag = null

  if(!edit) {
    marketingFlag = marketingAllowed ?
      <span className="marketing-yes">Marketing ok</span> :
      <span className="marketing-no">kein Marketing</span>
    settingsButton = (
      <span className="show-settings" onClick={() => setEdit(true)}>
        <Icon icon={Icons.Cog} />
      </span>
    )
  }
  else {
    let toggleMarketingAllowed_label = marketingAllowed ? "kein Marketing" : "Marketing erlauben"
    toggleMarketingAllowed = (
      <div className="settings">
        <ConfirmButton onConfirm={onToggleMarketingAllowed}>{toggleMarketingAllowed_label}</ConfirmButton>
        <div className="spacer"></div>
        <Button onClick={() => setEdit(false)} size="small" intent="cancel">abbrechen</Button>
      </div>
    )
  }


  if(selfie.DeletedByUser) {
    deletedByUserFlag = (
      <div className="deleted-by-user">
        <Icon icon={Icons.ExclamationTriangle} />
        <span className="text">Bild wurde von Benutzer gelöscht</span>
      </div>
    )
  }

  return (
    <div className={`c-surprise-selfie ${edit ? 'edit':''}`}>
      {deletedByUserFlag}
      <Link to={selfie.ImageUrl} target="_blank">
        <img src={selfie.ImageUrlThumbnail || selfie.ImageUrl} alt="selfie" />
      </Link>
      {marketingFlag}
      {settingsButton}
      {selfieButton}
      {toggleMarketingAllowed}
    </div>
  )
}
