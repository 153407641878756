import _, { set } from "lodash";
import React, { useState, useEffect } from "react";
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";
import * as BL from "../../../types/BusinessLogic";

export type SurpriseContextType = {
  // properties
  surprise: any,
  error: any,
  ready: boolean,
  busy: boolean,
  log: any[],
  steps: any[],
  shippingInfo: any,
  regions: any[],
  activityCatalogue: DA.Activity[],
  loadTimestamp: number|null,
  // functions
  load: (id: string) => void,
  loadLog: () => void,
  addLog: (logEntry:any) => void,
  setSurprise: (surprise:any) => void, 
  setSteps: (steps:any[]) => void,
  updateAtPath: (path:string, value:any, reload:boolean) => void,
  updateStatus: (nextStatus:any, executeSideEffects:any, logEntry:any) => void,
  markAsShipped: () => any,
  resetMeteochecks: () => void,
  sendReviewTest: () => void,
  sendSaleMail: () => void,
  sendPostponedMail: () => void,
  sendScheduledWithoutPaymentMail: () => void,
  sendAcceptMail: () => void,
}
const SurpriseContext = React.createContext<SurpriseContextType>({} as SurpriseContextType);

type SurpriseProviderProps = {
  children: React.ReactNode|React.ReactNode[]
}
function SurpriseProvider({children}:SurpriseProviderProps) {
  // state
  const [ready, setReady] = useState<boolean>(false);
  const [surprise, setSurprise] = useState<any>(null);
  const [regions, setRegions] = useState<DA.Region[]>([]);
  const [log, setLog] = useState<any[]>([]);
  const [shippingInfo, setShippingInfo] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [busy, setBusy] = useState<boolean>(false);
  const [activityCatalogue, setActivityCatalogue] = useState<DA.Activity[]>([]);
  const [steps, setSteps] = useState<any[]>([]);
  const [loadTimestamp, setLoadTimestamp] = useState<number|null>(null);
  
  // mount
  useEffect(() => {}, [])
  
  // provider value
  const providerValue = {
    // props
    surprise,
    error,
    ready,
    busy,
    log,
    steps,
    shippingInfo,
    regions,
    activityCatalogue,
    loadTimestamp,

    // loads the adventure (and other data)
    load: async(id: string) => {
      const result = await TS.AdventureOld.load_new(id, {});
      
      if(result.success) {
        // load other data
        const shippingTypes = await DA.PackagingTypeRepository.findAll();
        setShippingInfo(await BL.Shipping.getShippingInfo(result.surprise, shippingTypes));
        setSurprise(result.surprise);
        setLog(result.surprise.Log);
        setSteps(result.surprise.Steps);
        setRegions(await DA.RegionRepository.findAll());
        setActivityCatalogue(await DA.ActivityRepository.findAll());
        setError(null);
      }
      else {
        setSurprise(null);
        setError(result.error);
      }
      setLoadTimestamp(Date.now());
      setReady(true);
    },
    // reload log
    loadLog: async() => {
      if(surprise) {
        const updatedLog = await TS.AdventureOld.loadValueAtPath(surprise._id, "Log");
        setLog([...updatedLog]);
      }
    },
    addLog: async(logEntry:any) => {
      if(surprise) {
        await TS.AdventureOld.upsertLog(surprise._id, logEntry);
        const updatedLog = await TS.AdventureOld.loadValueAtPath(surprise._id, "Log");
        setLog(updatedLog);
      }
    },
    // TODO why? ever used?
    setSurprise: setSurprise, 
    // changes steps prop, does NOT save the steps. Use updateAtPath to save
    setSteps,
    /** 
     * reload: will replace state data with data from db ... do this if new _id values are created for instance
     */
    updateAtPath: async(path:string, value:any, reload:boolean) => {
      setBusy(true);
      const changeset:any = {};
      changeset[path] = value;
      await TS.AdventureOld.update(surprise, changeset);
      if(reload) {
        value = await TS.AdventureOld.loadValueAtPath(surprise._id, path);
      }
      _.set(surprise, path, value);
      setSteps(surprise.Steps); // just in case it changed (we could check if the path is "Steps", but that won't change a thing)
      setBusy(false);
    },
    updateStatus: async(nextStatus:any, executeSideEffects:any, logEntry:any) => {
      setBusy(true);
      const status = nextStatus || TS.AdventureStatus.Ordered;
      //const oldStatus = surprise.Status ||TS.AdventureStatus.Ordered;
      await TS.AdventureOld.updateStatusServerSide(surprise._id, status, logEntry);
      if(logEntry) {
        const updatedLog = await TS.AdventureOld.loadValueAtPath(surprise._id, "Log");
        setLog(updatedLog);
      }
      // reload the surprise
      const loadResult = await TS.AdventureOld.load_new(surprise._id, {});
      if(loadResult.success) {
        setSurprise(loadResult.surprise);
        setLog(loadResult.surprise.Log);
      }
      setBusy(false);
    },
    markAsShipped: async() => {
      setBusy(true);
      await BL.Shipping.markAsShipped(surprise._id); // marks adventure as shipped in db and sends mail
      const ShippingDate = new Date();
      setSurprise({...surprise, ShippingDate});
      setBusy(false);
    },
    resetMeteochecks: async() => {
      setBusy(true);
      const updatedSurprise = await TS.AdventureOld.resetMeteochecks(surprise);
      setSurprise({...surprise, Meteochecks: updatedSurprise.Meteochecks || [], Log: updatedSurprise.Log});
      setBusy(false);
    },
    sendReviewTest: async() => { return await TS.AdventureMail.sendReviewTest(surprise._id); },
    sendSaleMail: async() => { return await TS.AdventureMail.sendSaleMail(surprise._id); },
    sendPostponedMail: async() => { return await TS.AdventureMail.sendPostponedMail(surprise._id); },
    sendScheduledWithoutPaymentMail: async() => { return await TS.AdventureMail.sendScheduledWithoutPaymentMail(surprise._id);},
    sendAcceptMail: async() => { 
      const result = await TS.AdventureMail.sendAcceptMail(surprise._id); 
      if(result.success) {
        _.set(surprise, "SentAcceptReminderMailOn", new Date());
      }
      return result;
    },
  }

  return (
    <SurpriseContext.Provider value={providerValue}>
      {children}
    </SurpriseContext.Provider>
  )
}

export {SurpriseContext, SurpriseProvider}