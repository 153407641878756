import CSS from "./Base.module.scss";

type BaseProps = {
  disabled?:boolean,
  busy?:boolean,
  children: React.ReactNode|React.ReactNode[]
}
export function Base({busy=false, disabled=false, children}: BaseProps) {
  return (
    <div className={`${CSS.container} ${disabled ? CSS.disabled: ""} ${busy ? CSS.busy : ""}`}>{children}</div>
  )
}