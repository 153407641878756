import React from "react";

// components
import { Breadcrumb } from "../../controls";
import Unresolved from "./Mailfail.Unresolved";

// the component
export default function MailfailList() {
  return (
    <div id="v-mailfail">
      <Breadcrumb links={[{title:'Home', to:'/'}]} location='Mailfail' />
      <Unresolved />
    </div>
  )
}