import { Module, ModuleInfo, Theme } from ".";

export class SimpleHeaderModule implements Module {
  readonly code: string;

  _id?: string;
  countries: string[];
  position: number;
  theme: Theme;
  anchor?: string;

  elements: {
    buttonText?:string;
    buttonHref?:string;
  }

  constructor() {
    this.code = SimpleHeaderModule.info.code;
    this.countries = ["CH", "DE"];
    this.position = 0;
    this.theme = "primary";
    this.elements = {
      buttonText: "",
      buttonHref: ""
    }
  }

  static get info(): ModuleInfo {
    return {
      code: "simpleheader",
      title: "Einfacher Header",
      description: "Header mit Appentura Logo und Button"
    }
  }
}