import React, { useContext, useState, useEffect } from "react";

import { ActivityContext } from "./ActivityContext";
import { Checkbox, Form, CardSection, Icon, Icons, Value, Modal } from "../../controls";
import * as TS from "../../../types";

export default function Meteocheck() {
  // state 
  const [mode, setMode] = useState<"view"|"edit">("view");
  // context
  const AC = useContext(ActivityContext)!;
  
  // start edit
  const startEdit = () => {
    setMode("edit");
  }

  // close edit dialog
  const stopEdit = () => {
    setMode("view");
  }

  // saves changes
  const saveEdit = async(changeset:any) => {
    await AC.update(changeset);
    stopEdit();
  }

  // render
  const active = AC.activity!.requiresMeteocheck ? true : false;
  return <>
    <CardSection title="Meteo-Check">
      <Value onClick={startEdit}>
        <Icon intent={active ? "confirm" : "cancel"} icon={active ? Icons.Check : Icons.Cancel}></Icon>
        &nbsp;{active ? "Meteo-Check muss durchgeführt werden" : "Kein Meteo-Check notwendig"}&nbsp;
      </Value>
    </CardSection>
    <Modal isOpen={mode==="edit"} title="Aktivität - Meteo-Check" onClose={stopEdit}>
      <Edit activity={AC.activity!} saveEdit={saveEdit} onCancel={stopEdit} />
    </Modal>
  </>
}

type EditProps = {
  activity: TS.Activity
  saveEdit: any,
  onCancel: () => void
}
function Edit({activity, saveEdit, onCancel} : EditProps) {
  // state
  const [entity, setEntity] = useState<any>(null);
  const [busy, setBusy] = useState(false);

  // mount
  useEffect(() => {
    const entity = {requiresMeteocheck:activity.requiresMeteocheck};
    setEntity(entity);
  }, []);

  // save the update
  const onSave = async(formResult:any) => {
    setBusy(true);
    await saveEdit(formResult.changesetFlat); 
    setBusy(false);
  }

  // render
  return (
    <Form onCancel={onCancel} onSave={onSave} entity={entity} busy={busy}>
      <Checkbox path="requiresMeteocheck" label="Meteo-Check" disabled={busy} />
    </Form>
  );
}