import _ from "lodash";
import React, {useContext, useState, useEffect} from "react";
import * as TS from "../../../../types";

import { Button, ConfirmButton, Form, Icon, Icons, TextArea, Validators, CardSection, Table, Tr, Td, Modal } from "../../../controls";
import { ActivityContext} from "../ActivityContext";

import CSS from "./Activity.RegionVariant.Benefits.module.scss";

export default function Benefits() {
  // state
  const [itemToEdit, setItemToEdit] = useState<TS.ActivityRegionVariantBenefit|null>(null);

  // context
  const AC = useContext(ActivityContext)!;

  // stops edit mode
  const stopEdit = () => setItemToEdit(null);

  // user wants to create
  const onClickAdd = () => {
    const benefit = new TS.ActivityRegionVariantBenefit();
    onClickEdit(benefit);
  }

  // users wants to edit
  const onClickEdit = (benefit: TS.ActivityRegionVariantBenefit) => {
    setItemToEdit(benefit);
  }

  // user wants to delete
  const onClickDelete = async(benefitToDelete: TS.ActivityRegionVariantBenefit) => {
    const benefits = AC.regionVariant!.benefits.filter(b => b._id !== benefitToDelete._id);
    const changeset = {benefits};
    await AC.updateRegionVariant(AC.regionVariant!._id!, changeset);
  }

  // saves changes
  const saveEdit = async(benefitToSave: TS.ActivityRegionVariantBenefit) => {
    const benefits = AC.regionVariant!.benefits.map(b => b._id === benefitToSave._id ? benefitToSave : b);
    if(_.isNil(benefitToSave._id)) {
      benefits.push(benefitToSave);
    }
    const changeset = {benefits};
    await AC.updateRegionVariant(AC.regionVariant!._id!, changeset);
    stopEdit();
  }

  // pre-render
  const items = AC.regionVariant!.benefits.map(b => {
    return (
      <Tr key={b._id}>
        <Td>{(_.get(b, "de") || "").split('\n').map((t, i) => <p key={i}>{t}</p>)}</Td>
        <Td><ConfirmButton onConfirm={() => onClickDelete(b)}><Icon icon={Icons.Trash} /></ConfirmButton></Td>
        <Td><Button onClick={() => onClickEdit(b)} size="small"><Icon icon={Icons.Edit} /></Button></Td>
      </Tr>
    )
  })
  // render
  return <>
    <CardSection title="Leistungen">
      <div  className={CSS.container}>
        <Table>{items}</Table>
      </div>
      
      <Button onClick={onClickAdd} size="small"><Icon icon={Icons.Plus} /> neue Leistung</Button>
    </CardSection>
    <Modal isOpen={itemToEdit!==null} onClose={stopEdit} title="Regionsvariante - Leistung">
      <Edit benefit={itemToEdit!} saveEdit={saveEdit} onCancel={stopEdit} />
    </Modal>
    
  </>
}


type EditProps = {
  benefit: TS.ActivityRegionVariantBenefit,
  saveEdit: Function,
  onCancel: () => void
}
function Edit({benefit, saveEdit, onCancel} : EditProps) {
  // state
  const [entity, setEntity] = useState<{text:string, _id:string|undefined}|null>(null);
  const [busy, setBusy] = useState(false);

  // mount
  useEffect(() => {
    const entity = {
      text:_.get(benefit, "de") || "",
      _id:_.get(benefit, "_id")
    }
    setEntity(entity);
  }, []);

  // save the update
  const onSave = async(formResult:any) => {
    const benefitToSave = {
      _id:entity!._id,
      de: formResult.changeset.text,
      en: `!_${formResult.changeset.text}`
    }
    setBusy(true);
    await saveEdit(benefitToSave); 
    setBusy(false);
  }

  // render
  return (
    <Form onCancel={onCancel} onSave={onSave} entity={entity} busy={busy}>
      <TextArea path="text" label="Leistung" validate={Validators.isRequired("bitte eine Beschreibung der Leistung eingeben")} />
    </Form>
  ); 
}
