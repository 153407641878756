import React, {useState, useEffect, useContext} from "react";
import { SurpriseContext } from "../Surprise.Context";

export type StepsContextType = {
  // properties
  currentStep:any|null, // step that is currently being edited
  
  // functions
  setCurrentStep: (step:any) => void,
  
  saveStep: (step:any, stayInEditMode:boolean) => void
}
const StepsContext = React.createContext<StepsContextType>({} as StepsContextType);

type StepsProviderProps = {
  children: React.ReactNode|React.ReactNode[]
}
function StepsProvider({children}:StepsProviderProps) {
  // context
  const SC = useContext(SurpriseContext);
  // state
  const [currentStep, setCurrentStep] = useState<any|null>(null); // the step that is currently in edit mode
  
  // mount
  useEffect(() => {}, [])
  
  // provider value
  const providerValue = {
    // props
    currentStep,

    // makes a step the currently edited step
    setCurrentStep: (step:any) => {
      setCurrentStep(step);
    },
    
    // saves a step
    saveStep: async(step:any, stayInEditMode:boolean) => {
      if(!stayInEditMode) {
        setCurrentStep(null);
      }
      const steps = SC.surprise.Steps.map((s:any) => s._id === step._id ? step : s);
      await SC.updateAtPath("Steps", steps, true);
    }
  }

  return (
    <StepsContext.Provider value={providerValue}>
      {children}
    </StepsContext.Provider>
  )
}


export {StepsContext, StepsProvider}