import React, { useState, useEffect } from "react";
import _ from "lodash";
import Api from "../../../util/api2";
import * as DA from "../../../types/DataAccess";

import { Button, ConfirmButton, Icon, Icons, Loader, MapLink, Modal, Row, Table, Tr, Td } from "../../controls";
import ProviderLocation from "./ProviderLocation";

import "./ProviderLocations.scss";

type ProviderLocationsProps = {
  providerId: string,
  activityId: string,
  regionVariantId: string
}
export default function ProviderLocations({providerId, activityId, regionVariantId}: ProviderLocationsProps) {
  // state
  const [locations, setLocations] = useState<DA.ProviderLocation[]|null>(null);
  const [locationToEdit, setLocationToEdit] = useState<DA.ProviderLocation|null>(null);
  const [existingVenues, setExistingVenues] = useState<string[]>([]);
  
  // mount
  useEffect(() => {
    load()
  }, [])
  
  // loads data
  const load = async() => {
    const locations = await DA.ProviderLocationRepository.findByProviderAndActivity(providerId, activityId, regionVariantId);
    setLocations(locations);
    const allVenueItems = locations.map(l => l.venue);
    const venues = allVenueItems.filter(v => v !== null).map(v => v!);
    setExistingVenues(venues);
  }

  // deletes a location
  const onClickDelete = async(l:any) => {
    // TODO remove use of Api util, use DataAccess instead (need to add corresponding actions in API first)
    // await DA.ProviderLocationRepository.remove(l._id);
    await Api.post("providers", "deleteLocation", l);
    load();
  }


  // user wants to add or update a location
  const onClickAddUpdate = (location: any) => {
    setLocationToEdit(location);
  }

  // closes dialog
  const closeDialog = () => setLocationToEdit(null);
  
  // pre-render
  let content = null;
  if(_.isNil(locations)) {
    content = <Loader />
  }
  else {
    const maplink = (loc:any) => {
      return loc ? <MapLink lat={loc.coordinates.lat} lng={loc.coordinates.lng} /> : null;
    }
    
    let rows = locations.map((l:any) => {
      return (
        <Tr key={l._id}>
          <Td>{l.venue || ""}</Td>
          <Td>{maplink(l.start)}</Td>
          <Td>{maplink(l.end)}</Td>
          <Td>
            <Row align="left">
              <ConfirmButton onConfirm={() => onClickDelete(l)}>
                <Icon icon={Icons.Trash} />
              </ConfirmButton>
              <Button size="small" onClick={() => onClickAddUpdate(l)}>
                <Icon icon={Icons.Edit} />
              </Button>
            </Row>
          </Td>
        </Tr>
      )
    })
    // table header header
    let thead = null
    if(rows.length > 0) {
      thead = 
        <Tr key="header">
          <Td label>Austragungsort</Td>
          <Td label>Treffpunkt</Td>
          <Td label>Endpunkt</Td>
          <Td></Td>
        </Tr>
    }
    else {
      thead = 
      <Tr>
        <Td label colSpan={4}>es wird der Standard-Treffpunkt verwendet</Td>
      </Tr>
    }
    // add create row
    rows.push(
      <Tr key="create">
        <Td colSpan={4} className="create" style={{textAlign:"right"}}>
          <Button size="small" onClick={() => onClickAddUpdate({providerId, activityId, regionVariantId})}>Treffpunkt hinzufügen</Button>
        </Td>
      </Tr>
    )
    content = (
      <>
      <div className="left">
        <Icon icon={Icons.MapMarker} />
      </div>
      <div className="right">
        <Table>
          {thead}
          {rows}
        </Table>
      </div>
      </>
    )
  }

  // render
  return <>
    <div className="c-providerlocations">
      {content}
    </div>
    <Modal isOpen={locationToEdit !== null} title="" onClose={closeDialog}>
      <ProviderLocation 
        location={locationToEdit}
        existingVenues={existingVenues}
        onSave={async() => {
          await load();
          closeDialog();
        }}
        closeDialog={closeDialog}
      />
    </Modal>
  </>
}