import moment from "moment";
import { VoucherMapper } from "./Voucher.Mapper";
import { Voucher } from "./Voucher";
import { RepositoryFactory } from "../_Common/RepositoryFactory";


export class VoucherRepository {
  private static mapper = new VoucherMapper();
  
  // from factory
  static search = RepositoryFactory.search<Voucher, VoucherMapper>(this.mapper);
  static create = RepositoryFactory.create<Voucher, VoucherMapper>(this.mapper);
  static update = RepositoryFactory.update<Voucher, VoucherMapper>(this.mapper);
  static remove = RepositoryFactory.remove<Voucher, VoucherMapper>(this.mapper);
  static findById = RepositoryFactory.findById<Voucher, VoucherMapper>(this.mapper);

  /**
   * Creates a new Voucher object with default values
   * @returns 
   */
  static make(): Voucher {
    const validFrom = new Date();
    const validTo = moment(validFrom).add(5, "years").toDate();
    return {
      _id: undefined,
      code: "", // must be unique
      notes: "",
      value: 0,
      kind: "amount",
      singleUse: false,
      numberOfUses: 0,
      minOrderValue: 100,
      validFrom: validFrom,
      validTo: validTo,
      countsTowardsMinValue: false,
      relevantToSales: false,
      relevantToSalesDate: new Date()
    }
  }

  /**
   * Returns all vouchers sorted by code
   * @returns all vouchers, sorted by code
   */
  static async findAll(): Promise<Voucher[]> {
    const regions = await VoucherRepository.search({});
    return regions.sort((a,b) => a.code.localeCompare(b.code));
  } 
}