import React from "react";
import Code from "../Code";
import Notes from "../Notes";
import { Card, ConfirmButton, Heading } from "../../../controls";

export default function ConfirmButtonDemo() {
  return <>
    <Heading>ConfirmButton</Heading>
    <Card title="small size (default)">
      <ConfirmButton onConfirm={() => alert("alright")} onCancel={() => alert("so be it")}>Hello</ConfirmButton>
      <Code>{`<ConfirmButton onConfirm={() => alert("alright")} onCancel={() => alert("so be it")}>Hello</ConfirmButton>`}</Code>
      
      

      <ConfirmButton disabled={true}>Hello</ConfirmButton>
      <Code>{`<ConfirmButton disabled={true}>Hello</ConfirmButton>`}</Code>

      <ConfirmButton busy={true}>Hello</ConfirmButton>
      <Code>{`<ConfirmButton busy={true}>Hello</ConfirmButton>`}</Code>

      <ConfirmButton data={{message:"hello"}} onConfirm={(data) => alert(data.message)}>Hello</ConfirmButton>
      <Code>{`<ConfirmButton data={{message:"hello"}} onConfirm={(data) => alert(data.message)}>Hello</ConfirmButton>`}</Code>
    </Card>

    <Card title="medium size">
      <ConfirmButton size="medium">Hello</ConfirmButton>
      <ConfirmButton size="medium" busy={true}>Hello</ConfirmButton>
    </Card>
  </>
}
