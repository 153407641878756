import React from "react";
import CSS from "./Code.module.scss";

type CodeProps = {
  children: React.ReactNode|Array<React.ReactNode>,
  inline?:boolean
}
export default function Code({children, inline} : CodeProps) {
  return <div className={`${CSS.container} ${inline ? CSS.inline:""}`}><pre><code>{children}</code></pre></div>;
}
