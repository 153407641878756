import { Card, Row } from "../../../controls";
// subviews
import Status from "./Filter/Search.Filter.Status";
import PaymentStatus from "./Filter/Search.Filter.PaymentStatus";
import Activity from "./Filter/Search.Filter.Activity";
import Shipping from "./Filter/Search.Filter.Shipping";
import Region from "./Filter/Search.Filter.Region";
import Id from "./Filter/Search.Filter.Id";
import Operator from "./Filter/Search.Filter.Operator";
import TransportOptions from "./Filter/Search.Filter.TransportOptions";
import NameOrEmail from "./Filter/Search.Filter.NameOrEmail";
import SoldOffline from "./Filter/Search.Filter.SoldOffline";
import Dunning from "./Filter/Search.Filter.Dunning";
import Dates from "./Filter/Search.Filter.Dates";
import Misc from "./Filter/Search.Filter.Misc";
import BulkOrder from "./Filter/Search.Filter.BulkOrder";
// styling
import CSS from "./Search.module.scss";


// type SearchFilterProps = {
//   prefab: string|null
// }
//export default function SearchFilter({prefab}:SearchFilterProps) {
export default function SearchFilter() {
  return (
    <Card className={CSS.filter}>
      <Row>
        <div className={CSS.filtercolumn}>
          <Status />
        </div>
        <div className={CSS.filtercolumn}>
          <Activity />
          <Region />
          <Shipping />
          <TransportOptions />
          <Operator />
          <NameOrEmail />
          <SoldOffline />
          <Id />
        </div>
        <div className={CSS.filtercolumn}>
          <Dates />
          <Misc />
          <BulkOrder />
        </div>
        <div className={CSS.filtercolumn}>
          <Dunning />
          <PaymentStatus />
        </div>
      </Row>
    </Card>
  )
}