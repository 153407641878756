import _ from "lodash";
import React, {useContext} from "react";
import { CardSection } from '../../../controls';
import {SurpriseContext} from "../Surprise.Context";

export default function Buddy() {
  // context
  const SC = useContext(SurpriseContext);
  
  // render
  return (
    <CardSection title="Buddy" collapsible={false}>
      <table>
        <tbody>
          <tr>
            <td className="label">Name</td>
            <td>{_.get(SC.surprise, "Order.Prepaid.BuddyName")}</td>
          </tr>
          <tr>
            <td className="label">E-Mail</td>
            <td>{_.get(SC.surprise, "Order.Prepaid.BuddyEmail")}</td>
          </tr>
          <tr>
            <td className="label">Kommentar beim Festlegen des Buddys</td>
            <td>{_.get(SC.surprise, "Order.Prepaid.BuddySelectionComment")}</td>
          </tr>
        </tbody>
      </table>
    </CardSection> 
  );
}
