import React from "react";

import CSS from "./Group.module.scss";

type GroupProps = {
  children: React.ReactNode|React.ReactNode[],
  title:string,
  id?: string
}
/**
 * A group of with a title 
 * @param {GroupProps} props
 */
export function Group({title, children, id}:GroupProps) {
  return <div className={CSS.container}>
    <div className={CSS.title}>{title}</div>
    <div className={CSS.content} id={id}>{children}</div>
  </div>
}
