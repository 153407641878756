import _ from "lodash";
import Api from "../util/api2";
import moment from "moment";
import GoogleMaps from "../util/googleMaps";

/**
 * NOTE: the types in here reflect the model AdventureOutline in the API.
 */

export type AdventureOutlineTravelMode = "TRANSIT"|"WALKING"|"DRIVING"|"BIKING";

export interface AdventureOutlineLocation {
  lat: number;
  lng: number;
  text?: string;
}

export interface AdventureOutline {
  _id?: string, // if undefined it has not yet been saved
  activities: Array<AdventureOutlineActivity>;
  adventureId: string;
  startOffset: number;
  startTime: Date;
  endTime: Date;
  startLocation: AdventureOutlineLocation;
  
  trips: Array<any>; // TODO legacy
}

export interface AdventureOutlineActivity {
  activityId: string;
  parentActivityId?: string;
  title: string;
  providerId?: string;
  providerLocationId?: string;
  providerName: string;
  duration: number;
  startTime: Date;
  startLocation: AdventureOutlineLocation;
  endLocation: AdventureOutlineLocation;
  travelMode: string;
  // available routes (the one used has its 'selected' property set to true)
  routes: Array<AdventureOutlineRoute>;
}


export interface AdventureOutlineRoute {
  _id?: string, // if undefined it has not yet been saved
  travelMode: AdventureOutlineTravelMode;
  steps: Array<AdventureOutlineRouteStep>;
  selected: boolean;
  duration: number;
  departureTime: Date; // google api legs only has this with transit items, we calculate it for driving, walking 
  arrivalTime: Date; // just like departureTime
}

export interface AdventureOutlineRouteStep {
  travelMode: AdventureOutlineTravelMode;
  startLocation: AdventureOutlineLocation;
  endLocation: AdventureOutlineLocation;
  duration: number;
  htmlInstructions: string;
  startTime: Date;
  transit?: {
    departureTime: Date;
    arrivalTime: Date;
    departureStop: string;
    arrivalStop: string;
    line: string;
    icon: string;
    vehicle: string;
    headsign: string;
  }
}

export interface AdventureOutlineAdventureInfo {
  id: string;
  id4: string; // TODO probably not used
  who: string;
  startTime: Date;
  endTime: Date;
  startLocation: AdventureOutlineLocation;
  startLocationUser: AdventureOutlineLocation; // this might differ from startLocation (e.g. if the adventure was planned, then cancelled, and the customer selected a different start location)
  startOffset: number;
  hasOutline: boolean;
  userStartTime: Date|null;
}


type CreateOutlineResult = {
  success: boolean,
  missingData: Array<string>,
  data?: {
    outline: AdventureOutline,
    activities:Array<any> // TODO type this
  }
}
export class AdventureOutlineHelper {
  /**
   * Search for outlines
   * @param filter 
   * @param projection 
   * @returns 
   */
  static async search(filter:any, projection:any): Promise<Array<AdventureOutline>> {
    const apiResult = await Api.post("adventureOutlines", "search", {filter, projection});
    if(apiResult.success) {
      return apiResult.data.items;
    }
    else {
      console.error(apiResult.error);
      return [];
    }
  }

  /**
   * Load the outline of an adventure
   * @param adventureId id of adventure
   * @returns 
   */
  static async loadOutline(adventureId:string): Promise<AdventureOutline> {
    const outlines = await this.search({adventureId}, {});
    return outlines[0];
  }

  /**
   * Creates an outline 
   * @param adventureInfo 
   * @param activities 
   * @returns 
   */
  static async createOutline(adventureInfo:AdventureOutlineAdventureInfo, activities:Array<AdventureOutlineActivity>): Promise<CreateOutlineResult> {
    // time before user must start walking to station / driving / etc in seconds
    let initialWaitSecs = (adventureInfo.startOffset || 10) * 60;

    // variables used to collect things
    let missingData = [];
    
    // variables we keep changing while we iterate through the activities
    let currentLocation = { lat:adventureInfo.startLocation.lat, lng:adventureInfo.startLocation.lng }; 
    let currentTime = moment(adventureInfo.startTime).add(initialWaitSecs, "seconds").toDate();
    let calculatedStartTime = currentTime;
    let calculatedEndTime = currentTime;
    
    // interate through all activities
    for (let i = 0; i < activities.length; i++) {
      // activity we currently deal with
      let activity = activities[i];

      // if a location could be found for activity
      if (activity.startLocation) {
        // get travel information from google
        const routes = await this.getTravelInformation({
          origin: currentLocation, 
          destination: activity.startLocation, 
          startTime: currentTime, 
          travelMode: activity.travelMode
        });

        if (routes) {
          // get the current time by looking at the selected route's arrival time
          const selectedRoute = this.getSelectedRoute(routes);
          currentTime = this.roundUpToQuarterHour(selectedRoute.arrivalTime); // round up to 15 minutes to get reasonable times for booking requests
          // add properties to activity
          activity.routes = routes;
          activity.startTime = currentTime;
          // set recommended endTime
          calculatedEndTime = moment(activity.startTime).add(activity.duration, 'seconds').toDate()
        } 
        else {
          missingData.push(`Ankunftszeit konnte nicht berechnet werden für ${activity.title} (prüfe das Startdatum)`)
        }

        // update current time and location
        currentTime = moment(currentTime).add(activity.duration, "seconds").toDate()
        currentLocation = activity.endLocation || activity.startLocation
      } 
      else {
        // without coordinates for the activity we cannot calculate things
        missingData.push(`Fehlende Startkoordinaten für ${activity.title}`);
      }
    }

    // save the outline
    if (missingData.length === 0) {
      // calculate the start time by looking at the selected route of the first activity
      const firstRoute = this.getSelectedRoute(activities[0].routes);
      if(firstRoute) {
        calculatedStartTime = moment(firstRoute.departureTime).add(-1 * initialWaitSecs, "seconds").toDate();
      }
      
      // save the outline (we do an upsert)
      let ao_filter = { adventureId: adventureInfo.id };
      let ao_update: AdventureOutline = {
        activities,
        adventureId: adventureInfo.id, 
        startOffset: adventureInfo.startOffset, 
        startLocation: adventureInfo.startLocation, 
        startTime: calculatedStartTime,
        endTime: calculatedEndTime,
        trips:[] // legacy
      };
      await Api.post("adventureOutlines", "upsert", {filter:ao_filter, set:ao_update});

      // get the outline we just saved
      const savedOutline = await this.loadOutline(adventureInfo.id);
    
      // update the Adventure's StartTime and EndTime 
      let adventure_update = {
        "StartTime":new Date(calculatedStartTime),
        "EndTime":new Date(calculatedEndTime),
        "UserStartLocationText": adventureInfo.startLocation.text,
        "UserStartLocation": {Lat: adventureInfo.startLocation.lat, Lng: adventureInfo.startLocation.lng}
      }
      await Api.post("adventures", "update", {id:adventureInfo.id, set:adventure_update});

      // done - return data
      return {
        success: true,
        missingData: [],
        data: {
          activities,
          outline:savedOutline
        }
      }
    }
    else {
      return {
        success: false, missingData, data:undefined,
      }
    }
  }

  static async recalculate(outline:AdventureOutline, fromRoute:AdventureOutlineRoute, adventureInfo:AdventureOutlineAdventureInfo) : Promise<AdventureOutline> {
    // time before user must start walking to station / driving / etc in seconds
    let initialWaitSecs = (adventureInfo.startOffset || 10) * 60;
    // as soon as we find the route, we set this index to the index of the activity it belongs
    let recalculateFromIndex = -1; 
    // variables to keep track where we currently are
    let currentLocation: AdventureOutlineLocation = adventureInfo.startLocation;
    let currentTime: Date = adventureInfo.startTime;
    let calculatedStartTime: Date = adventureInfo.startTime;
    let calculatedEndTime: Date = adventureInfo.endTime;
    // if something does not work, we collect it in this variable
    let missingData:Array<any> = [];

    // find the index of the activity the selected route is part of and set the routes flag
    for(let i=0; i < outline.activities.length; i += 1) {
      const activity = outline.activities[i];
      // is the route in this activity?
      if(activity.routes.find(r => r._id === fromRoute._id)) {
        // select the route (and deselect all the others)
        this.setSelectedRoute(activity.routes, fromRoute);
        // set the index to recalculate from 
        recalculateFromIndex = i + 1;
        // update the activity start time
        activity.startTime = this.roundUpToQuarterHour(fromRoute.arrivalTime);
        // set the tracking variables
        currentLocation = activity.endLocation || activity.startLocation;
        currentTime = moment(activity.startTime).add(activity.duration, 'seconds').toDate();
        calculatedEndTime = currentTime;
        // and break ouf of the loop
        break;
      }
    }

    // if we did not find the route, we simply exit - this should not happen when called from the UI
    if(recalculateFromIndex === -1) {
      return outline; // TODO might also have to return outline and adventureInfo 
    }

    // recalculate
    for(let i=recalculateFromIndex; i < outline.activities.length; i += 1) {
      // the activity we deal with
      const activity = outline.activities[i];
      // get travel information
      const routes = await this.getTravelInformation({
        origin: currentLocation, 
        destination: activity.startLocation,
        startTime: currentTime,
        travelMode: activity.travelMode
      })

      // handle the routes we received (or not received for that matter)
      // TODO NOT DRY ... exactly the same code as in createOutline()
      if(routes) {
        // get the current time by looking at the selected route's arrival time
        const selectedRoute = this.getSelectedRoute(routes);
        currentTime = this.roundUpToQuarterHour(selectedRoute.arrivalTime); // round up to 15 minutes to get reasonable times for booking requests
        // add properties to activity
        activity.routes = routes;
        activity.startTime = currentTime;
        // set recommended endTime
        calculatedEndTime = moment(activity.startTime).add(activity.duration, 'seconds').toDate()
      }
      else {
        missingData.push({
          text: `Ankunftszeit konnte nicht berechnet werden für ${activity.title} (prüfe das Startdatum)`
        })
      }

      // update current time and location
      currentTime = moment(currentTime).add(activity.duration, "seconds").toDate();
      currentLocation = activity.endLocation || activity.startLocation;
    }

    // calculate the start time by looking at the selected route of the first activity
    const firstRoute = this.getSelectedRoute(outline.activities[0].routes); 
    if(firstRoute) {
      calculatedStartTime = moment(firstRoute.departureTime).add(-1 * initialWaitSecs, "seconds").toDate();
    }

    // save outline
    if(missingData.length === 0) {
      // update the adventures's start and end time
      let adventure_update = {
        "StartTime":new Date(calculatedStartTime),
        "EndTime":new Date(calculatedEndTime)
      }
      await Api.post("adventures", "update", {id:adventureInfo.id, set:adventure_update});

      // update the outline
      const outlineFilter = {_id:outline._id};
      const outlineUpdate: AdventureOutline = {
        activities: outline.activities,
        adventureId: outline.adventureId,
        startLocation: outline.startLocation,
        startOffset: outline.startOffset,
        startTime: calculatedStartTime,
        endTime: calculatedEndTime,
        trips: [] // legacy
      };
      await Api.post("adventureOutlines", "upsert", {filter:outlineFilter, set:outlineUpdate});
      // get the outline we just saved and return it
      const savedOutline = await this.loadOutline(adventureInfo.id);
      return savedOutline;
    }
    else {
      return outline;
    }


  }


  /**
   * Retrieves travel data from google and returns it as an array of routees
   */
  // TODO type params
  private static async getTravelInformation({origin, destination, startTime, travelMode}:any) : Promise<AdventureOutlineRoute[]|null> {
    // get directions from google maps
    // documentation of result: https://developers.google.com/maps/documentation/javascript/directions#DirectionsResults
    let directions = await GoogleMaps.getDirections({origin, destination, startTime, travelMode});
    
    // work with the directions we received
    if (directions.status === "OK") {
      // create routes from google data
      const routes = directions.result.routes.map((googleRoute:any) => this.getRouteFromGoogleRoute(googleRoute, startTime, travelMode.toUpperCase()));
      // select best route
      this.selectBestRoute(routes, travelMode);
      // done
      return routes;
    } 
    else {
      return null
    }
  }

  private static getRouteFromGoogleRoute(googleRoute:any, startTime:Date, travelMode: AdventureOutlineTravelMode):AdventureOutlineRoute {
    // route we will return - some values will be changed further down
    const route: AdventureOutlineRoute = {
      travelMode,
      selected: false,
      duration: 0,
      departureTime: startTime,
      arrivalTime: startTime,
      steps: []
    }

    // we are not setting any waypoints in our request, so we only get one leg (so we use the first one), see https://developers.google.com/maps/documentation/javascript/directions#Legs
    // also note that we pretty much call a Route what Google calls a leg (within a route)
    const leg = googleRoute.legs[0];

    if(travelMode === "DRIVING" || travelMode === "BIKING" || travelMode === "WALKING") {
      // set start, end, and duration
      route.duration = leg.duration.value;
      route.departureTime = startTime;
      route.arrivalTime = moment(startTime).add(route.duration, "seconds").toDate(); // non-transit legs do not return a duration value // TODO check if this is true
      // only one step we are interested in
      route.steps = [
        {
          travelMode:travelMode,
          startLocation:{lat:leg.start_location.lat(), lng:leg.start_location.lng()},
          endLocation:{lat:leg.end_location.lat(), lng:leg.end_location.lng()},
          duration:leg.duration.value,
          startTime:startTime,
          htmlInstructions:""
        }
      ];
      
    }
    else if(travelMode.toUpperCase() === "TRANSIT") {
      // set start, end, and duration
      route.duration = leg.duration.value;
      if(leg.departure_time && leg.arrival_time) {
        route.departureTime = new Date(leg.departure_time.value);
        route.arrivalTime = new Date(leg.arrival_time.value);
      }
      else {
        route.departureTime = startTime;
        route.arrivalTime = moment(startTime).add(route.duration, "seconds").toDate(); // non-transit legs do not return a duration value // TODO check if this is true
      }
      
      // we need this to give the walking steps a start time, Google does only provide a duration for walking steps 
      let currentTime = route.departureTime; 

      // collect steps - combination of transit and walking steps
      route.steps = (leg.steps || []).map((step:any) => {
        if(step.travel_mode === "WALKING") {
          // WALKING
          let startTime = new Date(currentTime);
          currentTime = moment(currentTime).add(step.duration.value, "seconds").toDate();
          
          return {
            travelMode:"WALKING",
            startLocation: {lat: step.start_location.lat(), lng: step.start_location.lng()},
            endLocation: {lat: step.end_location.lat(), lng: step.end_location.lng()},
            duration: step.duration.value,
            htmlInstructions: step.html_instructions,
            startTime: startTime
          }
        }
        else {
          // TRANSIT
          let departureTime = new Date(step.transit.departure_time.value);
          let arrivalTime = new Date(step.transit.arrival_time.value);
          currentTime = new Date(arrivalTime);
          return {
            travelMode:"TRANSIT",
            startLocation: {lat: step.start_location.lat(), lng: step.start_location.lng()},
            endLocation: {lat: step.end_location.lat(), lng: step.end_location.lng()},
            duration: step.duration.value,
            htmlInstructions: step.html_instructions,
            startTime: departureTime,
            transit: {
              departureTime: departureTime,
              arrivalTime: arrivalTime,
              departureStop: step.transit.departure_stop.name,
              arrivalStop: step.transit.arrival_stop.name,
              line: step.transit.line.short_name,
              icon: step.transit.line.vehicle.icon,
              vehicle: step.transit.line.vehicle.type,
              headsign: step.transit.headsign
            }
          }
        }
        
      })
    }
    else {
      route.steps = [];
    }

    return route;
    //return {steps, selected:false, duration, departureTime, arrivalTime, travelMode};
  }

  /**
   * TRANSIT: We give preference to the route with the smallest number of steps in the leg
   * driving, walking: the one that takes the shortest amount of time
   */
  private static selectBestRoute(routes:AdventureOutlineRoute[], travelMode:any) {
    if(travelMode === "transit") {
      // simply select the first one
      routes[0].selected = true;
      return routes[0];
      /* Old code that selected the one with the smalles number of steps
      // sort by number of steps
      let leastSteps = 999999;
      routes.forEach((route:any) => {
        const stepCount = route.steps.length;
        leastSteps = stepCount < leastSteps ? stepCount : leastSteps;
      })
      // in case we have multiple routes with the same number of steps, we sort them by duration
      const routesWithLeastSteps = routes
        .filter((route:AdventureOutlineRoute) => route.steps.length === leastSteps)
        .sort((a:AdventureOutlineRoute, b:AdventureOutlineRoute) => a.duration > b.duration ? 1 : -1)
      // select the shortest with the least number of steps
      routesWithLeastSteps[0].selected = true;
      return routesWithLeastSteps[0];
      */
    }
    else {
      const routesSortedByDuration = routes.sort((a:AdventureOutlineRoute, b:AdventureOutlineRoute) => a.duration > b.duration ? 1 : -1);
      routesSortedByDuration[0].selected = true;
      return routesSortedByDuration[0];
    }
  }

  private static getSelectedRoute(routes:Array<AdventureOutlineRoute>) : AdventureOutlineRoute {
    let selectedRoute = routes.find(r => r.selected);
    if(!selectedRoute) {
      selectedRoute = routes[0];
      selectedRoute.selected = true;
    }
    return selectedRoute;
  }

  private static setSelectedRoute(routes:Array<AdventureOutlineRoute>, route:AdventureOutlineRoute) {
    routes.forEach(r => r.selected = false);
    route.selected = true;
  }

  private static roundUpToQuarterHour(date:Date) {
    let minutes = moment(date).minutes();
    let minutesToAdd = 0;

    if(minutes >= 0 && minutes <= 15){
      minutesToAdd = 15 - minutes;
    }
    else if(minutes > 15 && minutes <=30) {
      minutesToAdd = 30 - minutes;
    }
    else if(minutes > 30 && minutes <= 45) {
      minutesToAdd = 45 - minutes;
    }
    else {
      minutesToAdd = 60 - minutes;
    }

    let roundedUp = moment(date).add(minutesToAdd, "minutes").seconds(0).toDate();
    return roundedUp;
  }

}