import React, { useEffect, useState, useContext } from "react"
import { Form, Loader, Validators, Label, TextInput, Alert, Button, Icons, Icon, ConfirmButton, Card, Breadcrumb, Heading, Modal } from "../../controls";
import * as DA from "../../../types/DataAccess";

import StepEdit from "./AdventureStepTemplate.Edit";
import CSS from "./AdventureTemplate.Edit.module.scss";


type AdventureTemplateEditProps = {
  templateId: string
}
export default function AdventureTemplate({templateId} : AdventureTemplateEditProps) {
  const [template, setTemplate] = useState<DA.AdventureTemplate|null>(null);
  const [entity, setEntity] = useState<DA.AdventureTemplate>();

  // mount to load
  useEffect(() => {
    load(templateId);
  }, [templateId]);
  
  // loads
  const load = async(id:string) => {
    const template = await DA.AdventureTemplateRepository.findById(id);
    setEntity(template!);
    setTemplate(template);
  }

  // user wants to save
  const onSave = async(formResult:any) => {
    // update / create
    let result: DA.Result<DA.AdventureTemplate>;
    if(entity!._id) {
      result = await DA.AdventureTemplateRepository.update(entity!._id, formResult.changeset);
    }
    else {
      result = await DA.AdventureTemplateRepository.create(formResult.merge(entity));
    }
    if(result.success) {
      setTemplate(result.data!);
      setEntity(result.data!);
    }
  }

  // pre-render
  let content = null;
  if(template === null) {
    content = <Loader />
  }
  else {
    content = <>
      <Card>
        <Form entity={entity} onSave={onSave}>
          <TextInput path="Name" label="Name der Vorlage" validate={Validators.isRequired("Bitte Namen der Vorlage eingeben")} />
        </Form>
        <Steps template={template} />
      </Card>
    </>
  }
  
  // render
  const name = template ? ` (${template.Name})` : "";
  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}, {title:"Überraschungs-Vorlagen", to:"/adventuretemplates"}]} location="Überraschungs-Vorlagen TODO title" />
    <Heading>Überraschungs-Vorlage{name}</Heading>
    {content}
  </>
}

type StepsProps = {
  template: DA.AdventureTemplate,
  // onStartEdit: (stepTemplate:TS.AdventureStepTemplate) => void,
  // onStopEdit: () => void,
}
function Steps({template}: StepsProps) {
  // state
  const [steps, setSteps] = useState<DA.AdventureStepTemplate[]|null>([]);
  const [busy, setBusy] = useState<boolean>(false);
  const [stepTemplateToEdit, setStepTemplateToEdit] = useState<DA.AdventureStepTemplate|null>(null);
  
  // mount
  useEffect(() => {
    load();
  }, []);

  // loads steps
  const load = async() => {
    setSteps(await DA.AdventureStepTemplateRepository.findByAdventureTemplate(template._id!) );
  };

  // move item
  const onClickMove = async(step:DA.AdventureStepTemplate, direction:"up"|"down") => {
    if(steps) {
      setBusy(true);
      // fix step positions (since there is no guarantee they all have differing AdventureTemplatePosition)
      const fixedSteps = steps
        .sort((a,b) => a.AdventureTemplatePosition - b.AdventureTemplatePosition)
        .map((s,i) => {
          const updated = s;
          updated.AdventureTemplatePosition = i;
          return updated;
        });

      // swap indices
      const stepIndex = fixedSteps.findIndex(s => s._id === step._id);
      const otherIndex = direction === "down" ? stepIndex + 1 : stepIndex - 1;
      fixedSteps[stepIndex].AdventureTemplatePosition = otherIndex;
      fixedSteps[otherIndex].AdventureTemplatePosition = stepIndex;

      // save all steps
      for(const s of fixedSteps) {
        await DA.AdventureStepTemplateRepository.update(s._id!, {AdventureTemplatePosition:s.AdventureTemplatePosition})
      }

      // reload
      await load();
      setBusy(false);
    }
  }

  // edit item
  const onClickAddUpdate = async(stepTemplate?:DA.AdventureStepTemplate) => {
    stepTemplate = stepTemplate || DA.AdventureStepTemplateRepository.make();
    if(!stepTemplate._id) {
      stepTemplate = DA.AdventureStepTemplateRepository.make();
      stepTemplate.AdventureTemplateId = template._id!;
      let largestPosition = (steps || []).reduce((prev, curr) => {
        return curr.AdventureTemplatePosition > prev ? curr.AdventureTemplatePosition : prev;
      }, 0);
      stepTemplate.AdventureTemplatePosition = largestPosition + 1;
    }
    setStepTemplateToEdit(stepTemplate);
    
    //const title = stepTemplate._id ? "Schrittvorlage bearbeiten" : "Schrittvorlage erfassen";
    //setStepToEdit(stepTemplate);
    //MC.push(title, <StepEdit template={stepTemplate} onUpdate={load} />);
  }

  // delete item
  const onClickDelete = async(step:DA.AdventureStepTemplate) => {
    setBusy(true);
    await DA.AdventureStepTemplateRepository.remove(step._id!);
    await load();
    setBusy(false);
  }

  const closeDialog = () => {
    setStepTemplateToEdit(null);
  }

  // render loading
  if(!steps) {
    return <Loader text="lade Schrittvorlagen ..." />
  }
  // render
  const items = steps.sort((a,b) => a.AdventureTemplatePosition - b.AdventureTemplatePosition).map((step, index) => {
    const isFirst = index === 0;
    const isLast = index === steps.length - 1;
    return (
      <div className={CSS.step} key={step._id}>
        <Button onClick={() => onClickMove(step, "up")} disabled={isFirst} busy={busy} size="small" narrow>
          <Icon icon={Icons.ArrowUp} />
        </Button>
        <Button onClick={() => onClickMove(step, "down")} disabled={isLast} busy={busy} size="small" narrow>
          <Icon icon={Icons.ArrowDown} />
        </Button>
        <div className={CSS.title}>
        [{step.AdventureTemplatePosition}] {step.Title} 
        </div>
        <ConfirmButton onConfirm={() => onClickDelete(step)} busy={busy}>
          <Icon icon={Icons.Trash} />
        </ConfirmButton>
        <Button onClick={() => onClickAddUpdate(step)} size="small" busy={busy}>
          <Icon icon={Icons.Edit} />
        </Button>
      </div>
    )
  })
  let noItems = null;
  if(items.length === 0) {
    <Alert intent="info">noch keine Schritte vorhanden</Alert>
  }
  return <>
    <div className={CSS.steps}>
      <Label label="Schritte" />
      {items}
      {noItems}
      <div className={CSS.actions}>
        <Button size="small" onClick={() => onClickAddUpdate()} busy={busy}><Icon icon={Icons.Plus} /> Schritt hinzufügen</Button>
      </div>
    </div>
    <Modal isOpen={stepTemplateToEdit !== null} onClose={closeDialog} title="Schritt-Vorlage bearbeiten">
      <StepEdit template={stepTemplateToEdit!} onUpdate={load} closeDialog={closeDialog} />
    </Modal>
  </>
}