class StepText {
  private _singular:string;
  private _plural:string | undefined;

  constructor(singular: string, plural?: string) {
    this._singular = singular;
    this._plural = plural;
  }

  getText(numberOfPeople: number): string {
    if(numberOfPeople > 1 && this._plural) {
      return this._plural;
    }
    else {
      return this._singular;
    }
  }
}

export default StepText