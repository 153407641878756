import _ from "lodash";
import React from "react";
import { useContext } from "react";
import { AssistantContext } from "./Assistant.Context";
import OutlineStep from "./OutlineRouteStep";
import * as TS from "../../../../types";

type OutlineRouteProps = {
  route: TS.AdventureOutlineRoute
}
export default function OutlineRoute({route}:OutlineRouteProps) {
  // context
  const AC = useContext(AssistantContext);

  if(!AC) {
    return null;
  }
  
  // pre-render
  const steps = route.steps
  //.sort((a, b) => (new Date(a.startTime)) > (new Date(b.startTime)) ? 1 : -1)
  .map((step, index) => <OutlineStep key={`trip_${index}`} step={step} invalid={AC.tripsInvalid} />)

  // render
  return <div>
    {steps}
  </div>

}