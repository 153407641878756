import React from "react";
import {Activities, ActivityPackages, Donee, Doner, Review, Text} from "../Items";
import { Table } from "../../../../controls";

export default function Locked() {
  // render
  return (
    <>
      <Table fontSize="small">
        <Doner />
        <Donee />
        <Activities />
        <ActivityPackages />
        <Text label="Notizen" path="surprise.Notes" placeholder="-" />
      </Table>
      <Review />
    </>
  );
}