import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { FormContext } from "./FormContext";
import { Alert, Label } from ".";

import CSS from "./Checkbox.module.scss";
import { Base } from "./internal";

type CheckboxProps = {
  path?: string,
  value?: boolean,
  label?: string,
  secondaryLabel?: string,
  explanation?: string,
  disabled? : boolean,
  data?: any,
  onChange?: (checked: boolean, data?:any) => void
}
export function Checkbox({path, value, label, secondaryLabel, disabled, explanation, data, onChange} : CheckboxProps) {
  // get context
  const form = useContext(FormContext);

  // if we have a form context, we render with form if not without
  if(form && path) {
    // warn about value not being used if provided
    if(value) {
      console.warn("since Checkbox is inside a FormContext and a 'path' was provided, the provided 'value' will be ignored");
    }
    // render the form control
    return FormControl({path, label, secondaryLabel, explanation, disabled, data, onChange});
  }
  else if(value !== undefined) {
    // a value was provided
    return Widget({value, label, secondaryLabel, explanation, disabled, data, onChange});
  }
  // something is missing
  return (
    <Alert intent="error" size="small" title="Implementation Error">
      Expected the path property (if control is inside a Form) or the value property.
    </Alert>
  )
}


type FormControlProps = {
  path: string,
  label?: string,
  secondaryLabel?: string,
  explanation?: string,
  data?: any,
  disabled?: boolean,
  onChange?: (checked:boolean, data?: any) => void
}
function FormControl({path, label, secondaryLabel, explanation, data, disabled, onChange}: FormControlProps) {
  // state
  const [initialValue, setInitialValue] = useState<boolean>(false);
  
  // context
  const form = useContext(FormContext);

  // mount
  useEffect(() => {
    if(form.entity && path) {
      // setting initial value
      const value = _.get(form.entity, path) ? true : false; 
      setInitialValue(value);
    }
  }, [form.entity, path])

  // form.resetIndex changed -> reset the item
  useEffect(() => {
    if(form.resetIndex !== 0) {
      setInitialValue(form.getOldValue(path))
    }
  }, [form.resetIndex, path, form])

  // handler
  const onWidgetChange = (checked:boolean, data:any) => {
    form.onFieldChange(path, checked, {valid:true}); // checkbox is always valid
    if(onChange) {
      onChange(checked, data);
    }
  }

  // render
  return (
    <Widget 
      value={initialValue} 
      label={label} 
      secondaryLabel={secondaryLabel} 
      explanation={explanation} 
      data={data}
      disabled={disabled}
      onChange={onWidgetChange} 
    />
  )
}


type WidgetProps = {
  value: boolean,
  label?: string,
  secondaryLabel?: string,
  explanation?: string,
  disabled?: boolean,
  data?: any,
  onChange?: (checked:boolean, data?:any) => void
}
function Widget({value, label, secondaryLabel, explanation, disabled=false, data, onChange} : WidgetProps) {
  
  // state
  const [checked, setChecked] = useState<boolean>(value);

  // value changes
  useEffect(() => {
    setChecked(value);
  }, [value]);

  // handler
  const onCheckboxChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setChecked(checked);
    if(onChange) {
      onChange(checked, data);
    }
  }

  // render
  return (
    <Base disabled={disabled}>
      <Label label={label} explanation={explanation}>
        <div className={CSS.inner}>
          <input type="checkbox" checked={checked} onChange={onCheckboxChange} disabled={disabled} /> <span className={CSS.label}>{secondaryLabel || label || ""}</span>
        </div>
      </Label>
    </Base>
  )
}