import Api from "../util/api2";
import { Adventure, AdventureCancelReason, AdventureCancelReasons } from ".";
import { Util } from "../util";
import * as DA from "../types/DataAccess";
import * as BL from "../types/BusinessLogic";

class MailRelatesTo {
  entityId: string|null;
  entityType: "Adventure"|"BookingRequest"|null;

  constructor(entityId: string|null, entityType: "Adventure"|"BookingRequest"|null) {
    this.entityId = entityId;
    this.entityType = entityType;
  }

  static fromDb(data: any): MailRelatesTo|null {
    return data ? new MailRelatesTo(data.entityId, data.entityType) : null;
  }
}

export type MailSendResult = {
  success: boolean,
  error?: any,
  data?: {
    mail_id: string,
    mail_messageId: string
  }
}

// so sorry for the shitty name
export class MailMail {
  to: string;
  from: string;
  subject: string;
  html: string;

  constructor(to: string, from: string, subject: string, html: string) {
    this.to = to;
    this.from = from;
    this.subject = subject;
    this.html = html;
  }

  static fromDb(obj:any): MailMail {
    return new MailMail(obj.to, obj.from, obj.subject, obj.html);
  }
}

type MailLogError = {
  code: string|null,
  message: string|null,
  description: string|null,
}
export class MailLog {
  date: Date;
  status: string;
  provider: string;
  fallbackLevel: number;
  error: MailLogError|null = null;

  constructor(date: Date, status: string, provider: string, fallbackLevel: number) {
    this.date = date;
    this.status = status;
    this.provider = provider;
    this.fallbackLevel = fallbackLevel;
  }

  static fromDb(obj: any): MailLog { 
    const log = new MailLog(new Date(obj.date), obj.status, obj.provider, obj.fallbackLevel);
    log.fallbackLevel = obj.fallbackLevel;
    log.error = obj.error ? obj.error : null;
    return log;
  }
}

/**
 * Represents an email as it is stored in our database.
 * The actual e-mail is in the property `mail`
 */
export class Mail {
  _id?: string;
  messageId?: string; // // id used to recognize responses from mailgun ('v:my-var', specifically 'v:message-id') and postmark ('metadata')
  purpose: string;
  relatesTo: MailRelatesTo|null;
  mail: MailMail;
  status?: "accepted"|"delivered"|"failed"|"resolved";
  fallbackLevel: number = 0; // 0 if we had no "failed" status so far, 1 or higher if delivery failed
  provider: string = "mailgun"; // the provider (mailgun, mailchimp, etc) used to reach the current state
  isTest: boolean = true; // mail was created in staging or local environment
  log: MailLog[] = [];

  constructor(mail: MailMail, purpose: string, relatesTo: MailRelatesTo|null = null) {
    this.mail = mail;
    this.purpose = purpose;
    this.relatesTo = relatesTo
  }

  public static async search(filter:any): Promise<Mail[]> {
    const projection = {};
    let result = await Api.post("mail", "search", {filter, projection});
    if(result.success) {
      const mails = result.data.items.map((obj:any) => Mail.fromDb(obj));
      return mails;
    }
    else {
      console.error(result.error);
      return [];
    }
  }

  public static async findOneById(id:string): Promise<Mail|null> {
    const searchResult = await Mail.search({_id:id});
    return searchResult[0] || null;
  }

  static fromDb(obj:any): Mail {
    const mailMail = MailMail.fromDb(obj.mail);
    const relatesTo = MailRelatesTo.fromDb(obj.relatesTo);
    const mail = new Mail(mailMail, obj.purpose, relatesTo);
    mail._id = obj._id;
    mail.messageId = obj.messageId;
    mail.status = obj.status;
    mail.fallbackLevel = Number(obj.fallbackLevel);
    mail.isTest = obj.isTest ? true : false;
    mail.provider = obj.provider;
    mail.log = (obj.log || []).map((logObj:any) => MailLog.fromDb(logObj));

    return mail;
  }

  /**
   * Sends a mail
   * @param to 
   * @param subject 
   * @param body 
   * @param purpose 
   * @param from sender e-mail address. if none is provided, the current user is used
   * @returns 
   */
  public async send(): Promise<MailSendResult> {
    const payload = {
      to: this.mail.to,
      from: this.mail.from,
      subject: this.mail.subject,
      body: this.mail.html,
      purpose: this.purpose,
      relatesTo: this.relatesTo,
    }
    const result = await Api.post("mail", "send", payload);
    return result;
  }
  
  /**
   * Informs the provider about a booking cancellation
   * @param bookingRequest 
   */
  static async cancellationToProvider(adventure: Adventure, bookingRequest: DA.BookingRequest, reason: AdventureCancelReason, note: string): Promise<Mail|null> {
    // get provider and their login link
    const provider = await DA.ProviderRepository.findById(bookingRequest.provider.id);
    
    // we can only send an email if the provider has an email address
    if(provider && BL.Provider.getBookingKind(provider) === "email" && BL.Provider.getBookingEmail(provider) !== null) {
      // get login link for partner portal
      const providerLoginLink = await DA.ProviderRepository.fetchLoginLink(provider);

      // collect mail properties
      const from = await Mail._getOperatorFrom();
      const to = BL.Provider.getBookingEmail(provider);
      const subject = `Stornierung Buchung [${adventure.shortId}]`;
      const purpose = "booking-cancellation"; // TODO make purpose a union type
      const relatesTo:MailRelatesTo = {
        entityId: bookingRequest._id!,
        entityType: "BookingRequest"
      }

      // construct the mail body
      const mailBody = new MailBody();
      // greeting
      mailBody.addLine("Hallo,");
      mailBody.addBreak();
      // introductory text (depends on why the thing was cancelled)
      switch(reason) {
        case "provider-full":
        case "provider-postponed":
          mailBody.addLine("hiermit bestätigen wir die Stornierung folgender Buchung:");
          break;
        default:
          mailBody.addLine("leider müssen wir folgende Buchung absagen:");
          break;
      }
      // info
      mailBody.addBreak();
      mailBody.startTable();
      mailBody.addTableRow(["Referenz-Id: ", adventure.shortId]);
      bookingRequest.services.forEach((service) => {
        mailBody.addTableRow([service.key + ":", service.value]);
      });
      mailBody.addTableRow(["Grund der Absage:", this._getReasonText(reason, note)]);
      mailBody.endTable();
      mailBody.addBreak();
      mailBody.addLine(`Wir haben die Buchung in deinem Partner-Portal bereits als abgesagt markiert. Benutze <a href="${providerLoginLink}">diesen Link</a> um diese Buchung und alle anderen Buchungen einzusehen.`);
      mailBody.addBreak();
      // signature
      const signature = await Mail._getSignature();
      mailBody.addLines(signature);

      // create mail object
      const mail = new Mail(new MailMail(to!, from, subject, mailBody.toHtml()), purpose, relatesTo);
      return mail;
    }
    // nothing happened
    return null;
  }

  /**
   * Informs the customer about a cancellation
   */
  static async cancellationToCustomer(adventure: Adventure, reason: AdventureCancelReason, note: string): Promise<Mail|null> {
    if(adventure.RecieverEmail) {
      const from = await Mail._getOperatorFrom();
      const to = adventure.RecieverEmail;
      const subject = `Überraschung verschoben [${adventure.shortId}]`;
      const purpose = "cancellation"; // TODO make purpose a union type
      const relatesTo: MailRelatesTo = {
        entityId: adventure._id!,
        entityType: "Adventure"
      }

      // construct the mail body
      const mailBody = new MailBody();
      const adventureDate: string = Util.printDate(adventure.StartTime || adventure.UserStartTime);
      // greeting
      mailBody.addLine(`Hallo ${adventure.RecieverRealFirstName || adventure.RecieverName || ""},`);
      mailBody.addBreak();
      // introductory text (depends on whether the customer issued to cancel or any other reason)
      switch(reason) {
        case "customer-conditions-not-accepted":
        case "customer-not-paid":
        case "customer-postponed":
        case "customer-illness":
          mailBody.addLine(`wie von dir gewünscht verschieben wir deine Überraschung vom ${adventureDate}.`);
          break;
        default:
          mailBody.addLine(`leider müssen wir deine Überraschung vom ${adventureDate} verschieben.`);
          break;
      }
      // reason text
      mailBody.addBreak();
      mailBody.addLine(`Grund der Absage: <strong>${this._getReasonText(reason, note)}</strong>`);
      mailBody.addBreak();
      // additional text
      mailBody.addLine(`Besuche deine <a href="${Adventure.getAdventureLink(adventure._id!)}">Überraschungs-Seite</a> um einen neuen Termin für deine Überraschung zu wählen.`);
      mailBody.addLine(`Falls du noch Fragen hast, darfst du dich jederzeit bei uns melden.`);
      mailBody.addBreak();
      // signature
      const signature = await Mail._getSignature();
      mailBody.addLines(signature);
      
      // create the mail object
      const mail = new Mail(new MailMail(to, from, subject, mailBody.toHtml()), purpose, relatesTo);
      return mail
    }
    return null;
  }

  private static _getReasonText(reason: AdventureCancelReason, note: string): string {
    const reasonObject = AdventureCancelReasons.find(r => r.reason === reason)!;
    const reasonNote = note.trim() !== "" ? ` (${note})` : ""
    return reasonObject.label + reasonNote;
  }

  private static async _getSignature(): Promise<string[]> {
    const operator = await BL.User.getCurrent();
    return [
      "",
      "Liebe Grüsse,",
      `${operator.firstName} von Appentura`,
      "",
      "Appentura AG",
      "Bernapark 25",
      "3066 Stettlen (Bern)",
      "",
      "<a href='http://www.appentura.ch'>www.appentura.ch</a>",
      "<a href='mailto:hello@appentura.ch'>hello@appentura.ch</a>",
      "<a href='tel:+41315112132'>+41 31 511 21 32</a>"
    ]

  }

  private static async _getOperatorFrom(): Promise<string> {
    const operator = await BL.User.getCurrent();
    return `${operator.firstName} von Appentura <${operator.email}>`;
  }

}

class MailBody {
  private _lines: string[] = [];

  constructor() {
    this._lines = [];
  }

  toHtml(): string {
    return this._lines.join("\n");
  }

  addLine(line: string): void {
    this._lines.push(line);
    this._lines.push("<br>");
  }

  addLines(lines: string[]): void {
    lines.forEach(line => this.addLine(line));
  }

  addBreak(): void {
    this._lines.push("<br>");
  }

  startTable(): void {
    this._lines.push("<table><tbody>");
  }

  addTableRow(cells: string[]): void {
    this._lines.push("<tr>");
    cells.forEach(c => this._lines.push(`<td>${c}</td>`));
    this._lines.push("</tr>");
  }

  endTable(): void {
    this._lines.push("</tbody></table>");
  }

}