import _ from "lodash";
import React, {useContext} from "react";
import {SurpriseContext} from "../../Surprise.Context";
import {MapLink} from "../../../../controls";
import Row from "./Row";

export default function StartLocation() {
  // context 
  const SC = useContext(SurpriseContext);
  // render
  return (
    <Row label="Startort">
      <MapLink lat={_.get(SC.surprise, "UserStartLocation.Lat")} lng={_.get(SC.surprise, "UserStartLocation.Lng")}>{SC.surprise.UserStartLocationText}</MapLink>
    </Row>
  )
}