import React, { useState, useEffect } from "react";
// types
import * as Cms from "../../../../types/Cms";
// components
import { Actions, Icon, Icons } from "../../../controls";
// styling
import CSS from "./Container.module.scss";

type ContainerProps = {
  children: React.ReactNode|React.ReactNode[],
  expanded?: boolean,
  module: Cms.Module,
  title: string,
  onClickAction: (module:Cms.Module, action:"edit"|"delete"|"duplicate") => void
}
export function Container({children, expanded, module, title, onClickAction} : ContainerProps) {
  // defaults
  expanded = expanded || false;
  
  // state
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  // expanded changed
  useEffect(() => {
    if(expanded !== isExpanded) {
      setIsExpanded(expanded || false);
    }
  }, [expanded]);
  
  // render
  return (
    <div className={`${CSS.container} ${isExpanded ? CSS.expanded : CSS.collapsed}`}>
      <div className={CSS.heading} onClick={() => setIsExpanded(!isExpanded)}>
        <div className={CSS.toggle}><Icon icon={isExpanded ? Icons.MinusSquare : Icons.PlusSquare} /></div>
        <div className={CSS.title}>
          {title}
        </div>
        <div className={CSS.baseProperties}>
          <div className={CSS.basePropertyItem}><Icon icon={Icons.Flag} /> {module.countries.join(", ")}</div>
          <div className={CSS.basePropertyItem}><Icon icon={Icons.Palette} /> {module.theme}</div>
          <div className={CSS.basePropertyItem}><Icon icon={Icons.Hashtag} /> {module.anchor}</div>
        </div>
      </div>
      <div className={CSS.divider}></div>
      <div className={CSS.content}>
        {children}
        <Actions 
          size="small" 
          onEdit={() => onClickAction(module, "edit")} 
          onDelete={() => onClickAction(module, "delete")} 
          onDuplicate={() => onClickAction(module, "duplicate")} 
        />
      </div>
    </div>
  )
}