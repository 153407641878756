import { ConditionMapper } from "./Condition.Mapper";
import { Condition } from "./Condition";
import { RepositoryFactory } from "../_Common/RepositoryFactory";


export class ConditionRepository {
  // mapper
  private static mapper = new ConditionMapper();
  
  // factory methods
  static make = RepositoryFactory.make<Condition, ConditionMapper>(this.mapper);
  static search = RepositoryFactory.search<Condition, ConditionMapper>(this.mapper);
  static create = RepositoryFactory.create<Condition, ConditionMapper>(this.mapper);
  static update = RepositoryFactory.update<Condition, ConditionMapper>(this.mapper);
  static findById = RepositoryFactory.findById<Condition, ConditionMapper>(this.mapper);
  // additional
  static async searchAll(): Promise<Condition[]> {
    return ConditionRepository.search({});
  }
}

