import React, {useState, useEffect, useContext} from "react";
import Util from "../../../../util/util";
import moment from "moment";

// constants
import {GMAP_TRAVEL_MODES as TravelModes} from "../../../../config/constants"

// context
import { AssistantContext } from "./Assistant.Context"

// controls & subviews
import {Button, Link, Icon, Icons, Modal} from "../../../controls";
import ProviderSelector from "./ProviderSelector"
import ProviderInfo from "./ProviderInfo"
import OutlineTimelineItem from "./OutlineTimelineItem"

// styling
import "./OutlineActivity.scss";

type OutlineActivityProps = {
  activity:any,
  isFirst:boolean,
  isLast:boolean
}
export default function OutlineActivity({activity, isFirst, isLast} : OutlineActivityProps) {
  // context
  const AC = useContext(AssistantContext);
  
  // state
  const [modalMode, setModalMode] = useState<"none"|"selectProvider">("none");
  const [selectedProviderAndLocation, setSelectedProviderAndLocation] = useState({providerId:null, providerLocationId:null})
  const [selectedTravelMode, setSelectedTravelMode] = useState(null)
  const [selectedDuration, setSelectedDuration] = useState(null)

  // mount
  useEffect(() => {
  }, [])

  // activity received
  useEffect(() => {
    const providerLocation = {
      providerLocationId: activity.providerLocationId,
      startLocation: activity.startLocation ? activity.startLocation : null, 
      endLocation: activity.endLocation ? activity.endLocation : null 
    }
    select("providerAndLocation", {providerId:activity.providerId, providerName:activity.providerName, providerLocationId:activity.providerLocationId, providerLocation}, true)
    select("travelMode", activity.travelMode || "transit", true) 
    select("duration", activity.duration / 60, true)
  }, [activity])

  // no AC? no render
  if(!AC) {
    return null;
  }

  // opens provider selector in modal dialog
  const showProviderSelector = () => {
    setModalMode("selectProvider");
  }

  // closes modal dialog
  const closeModal = () => setModalMode("none");

  const select = (property:any, value:any, suppressUpdate:any) => {
    let update:any = {}
    switch(property) {
      case "providerAndLocation":
        update.providerId = value.providerId
        update.providerName = value.providerName
        update.providerLocationId = value.providerLocation._id || value.providerLocationId
        update.providerLocation = value.providerLocation
        update.startLocation = value.providerLocation.start ? value.providerLocation.start.coordinates : null
        update.endLocation = value.providerLocation.end ? value.providerLocation.end.coordinates :  null
        setSelectedProviderAndLocation({providerId:update.providerId, providerLocationId:update.providerLocationId})
        break
      case "travelMode":
        update.travelMode = value
        setSelectedTravelMode(value)
        break
      case "duration":
        update.duration = Number(value) * 60
        setSelectedDuration(value)
        break
      default:
        console.error(`Unsupported property '${property}' in switch statement`)
        break
    }
    if(!suppressUpdate) {
      AC.updateActivity(activity.activityId, update);
    }
  }

  // render
  const travelModeOptions = TravelModes
    .filter(t => t.value !== null)
    //.map(t => t.value.toLowerCase())
    .map((t:any) => <option key={t.value} value={t.value.toLowerCase()}>{t.label}</option>)

  const up = !isFirst ? <span className="mover" onClick={() => AC.moveActivity(activity.order, "up")}><Icon icon={Icons.ChevronUp} /></span> : <span className="mover empty" />
  const down = !isLast ? <span className="mover" onClick={() => AC.moveActivity(activity.order, "down")}><Icon icon={Icons.ChevronDown} /></span> : <span className="mover empty" />
  
  const updown = isFirst && isLast ? null : <div className="mover-group">{up}{down}</div>
  
  return <>
    <div className="c-outline-activity">
      <OutlineTimelineItem start={activity.time} end={moment(activity.time).add("minutes", activity.duration / 60).toDate()} />
      {updown}
      <table><tbody>
        <tr>
          <td className="label">Aktivität</td>
          <td className="uppercase">
            <Link to={`/activities/${activity.activityId}`} target="_blank">{activity.title}</Link>
          </td>
        </tr>
        <tr>
          <td className="label">Dauer (Minuten)</td>
          <td>
            <input type="number" value={selectedDuration || ""} onChange={(e:any) => select("duration", e.target.value, false)} />
          </td>
        </tr>
        <tr>
          <td className="label">Anreise</td>
          <td>
            <select value={selectedTravelMode || ""} onChange={(e:any) => select("travelMode", e.target.value, false)}>
              {travelModeOptions}
            </select>
          </td>
        </tr>
        <tr>
          <td className="label">Anbieter</td>
          <td>
            <ProviderInfo providerId={selectedProviderAndLocation.providerId} providerLocationId={selectedProviderAndLocation.providerLocationId} />
            <Button onClick={showProviderSelector} size="small">{selectedProviderAndLocation.providerId ? "ändern" : "wählen"}</Button>
          </td>
        </tr>
        <tr>
          <td className="label">Vorgeschlagene Zeit</td>
          <td>{activity.time ? Util.printDateAndTimeWithoutYear(activity.time) : "-"}</td>
        </tr>
      </tbody></table>
    </div>

    <Modal isOpen={modalMode==="selectProvider"} title="Anbieter wählen" onClose={closeModal}>
      <ProviderSelector 
        activityInfo={activity} 
        selectProviderAndLocation={(e:any) => {
          select("providerAndLocation", e, false)
          }
        } 
        closeDialog={closeModal}
      />
    </Modal>
  </>
}