import _ from "lodash"
import React, {useEffect, useState, useContext} from "react"
import * as TS from "../../../types";
import * as DA from "../../../types/DataAccess";
import { Alert, Button, ConfirmButton, NumberInput, Form, Card, Icon, Icons, Validators, Modal } from "../../controls";
import { ActivityPackageContext } from "./ActivityPackage.Context";

export default function ActivityPackagePrices() {
  // context
  const AC = useContext(ActivityPackageContext); // TODO we should have a type
  
  // state
  const [busy, setBusy] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalParams, setModalParams] = useState<any>({});

  // no context? no render
  if(!AC || !AC.activityPackage) { 
    return null;
  }

  // saves changes
  const save = async() => {
    setBusy(true);
    await AC.save();
    setBusy(false);
  }

  // user wants to add a configuration
  const onClickAdd = async(regionCode: string) => {
    if(AC.activityPackage) {
      let regionVariant: TS.ActivityPackageRegionVariant|null = AC.activityPackage.getRegionVariant(regionCode);
      if(!regionVariant) {
        regionVariant =  {regionCode, priceConfigurations:[]};
        AC.activityPackage.regionVariants.push(regionVariant);
      }

      const npc = AC.activityPackage.getNextPriceConfiguration(regionCode);  
      regionVariant.priceConfigurations.push(npc);
      save();
    }
  }

  // user wants to remove last configuration
  const onClickRemove = async(regionCode: string) => {
    if(AC.activityPackage) {
      const regionVariant = AC.activityPackage.getRegionVariant(regionCode);
      if(regionVariant) {
        regionVariant.priceConfigurations.pop();
        save();
      }
    }
  }

  // user wants to edit a configuration
  const onClickEdit = async(priceConfiguration: TS.ActivityPackagePriceConfiguration, region: DA.Region) => {
    if(AC.activityPackage) {
      setModalParams({
        priceConfiguration,
        region
      })
      setModalOpen(true);
      /*
      const ap = AC.activityPackage;
      MC.push("Preiskonfigurationen bearbeiten", <Edit priceConfiguration={priceConfiguration} region={region} activityPackage={ap} save={save}  />);
      */
    }
  }
  
  // closes dialog
  const closeDialog = () => setModalOpen(false);

  // pre-render
  let headerRow:any = null;
  let rows:any[] = [];

  if(AC.activityPackage) {
    const ap = AC.activityPackage;
    // pre-render header row
    const headerCells:any[] = ap.getParticipantCounts().map(count => <td key={count}>{count}</td>);
    headerRow = <tr key="header">
      <th key="title">Teilnehmer</th>
      {headerCells}
      <td></td><td></td>
    </tr>

    // pre-render rows
    AC.regionCatalogue.map(region => {
      const cells = [];
      cells.push(<td key="region">{region.title.de}</td>);
      ap.getParticipantCounts().forEach(participantCount => {
        const regionVariant = ap.getRegionVariant(region.code);
        const priceConfiguration = regionVariant ? regionVariant.priceConfigurations.find(pc => pc.participantCount === participantCount) : null;
        if(priceConfiguration) {
          const price = priceConfiguration.price;
          const button = <span className="editbutton">
            <Icon icon={Icons.Edit} onClick={() => onClickEdit(priceConfiguration, region)}  />
          </span>
          cells.push(<td key={participantCount} className="price">{price}{button}</td>)
        }
        else {
          cells.push(<td key={participantCount}></td>)
        }
        
      });
      cells.push(
        <td key="add">
          <Button size="small" onClick={() => onClickAdd(region.code)} busy={busy} ><Icon icon={Icons.Plus} /></Button>
        </td>
      )
      cells.push(
        <td key="remove">
          <ConfirmButton busy={busy} onConfirm={() => onClickRemove(region.code)}><Icon icon={Icons.Trash} /></ConfirmButton>
        </td>
      )
      rows.push(<tr key={region.code}>{cells}</tr>)
    });
  }
  
  // render
  return <>
    <Card title="Preise" expanded={true} collapsible={false} id="v-activitypackage-prices">
      <table>
        <thead>{headerRow}</thead>
        <tbody>{rows}</tbody>
      </table>
      <br /><br />
      <Issues activityPackage={AC.activityPackage} regions={AC.regionCatalogue} activityCatalogue={AC.activityCatalogue} />
    </Card>
    <Modal isOpen={modalOpen} title="Preiskonfigurationen bearbeiten" onClose={closeDialog}>
      <Edit priceConfiguration={modalParams.priceConfiguration!} region={modalParams.region!} save={save} closeDialog={closeDialog} />
    </Modal>
  </>
}

type EditProps = {
  priceConfiguration: TS.ActivityPackagePriceConfiguration, 
  region: DA.Region,
  //activityPackage: ActivityPackage, 
  save:() => Promise<void>,
  closeDialog: VoidFunction
}
function Edit({priceConfiguration, region, save, closeDialog} : EditProps) {
  // state
  const [busy, setBusy] = useState<boolean>(false);
  const [entity, setEntity] = useState<TS.ActivityPackagePriceConfiguration>({...priceConfiguration});
  
  // mount 
  useEffect(() => {
  }, []);

  // user aborts
  const onCancel = () => {
    closeDialog();
  }

  // user wants to save
  const onSave = (formResult:any) => {
    const updated = formResult.merge(entity);
    // we directly manipulate the activity package in here
    priceConfiguration.price = updated.price;
    // update states, save, leave
    setEntity(updated);
    setBusy(true);
    save();
    closeDialog();
  }

  // render
  return <div id="v-activitypackage-prices-edit">
    <Form 
      entity={entity} 
      onCancel={onCancel} onSave={onSave}
      busy={busy}
    >
      <div className="title">Preis für {priceConfiguration.participantCount} Teilnehmer in {region.title.de}</div>
      <NumberInput
        path="price"
        validate={Validators.isPriceOrZero("Preis eingeben")} 
      />
    </Form>
    <Alert title="Preis" size="small" intent="info">
      Wird als Preis 0 angegeben, steht das Paket für diese Teilnehmerzahl in dieser Region nicht zur Auswahl
    </Alert>
  </div>
}

type IssuesProps = {
  activityPackage: TS.ActivityPackage, 
  regions: Array<DA.Region>,
  activityCatalogue: Array<TS.Activity>
}
function Issues({activityPackage, regions, activityCatalogue} : IssuesProps) {
  // pre-render
  const regionIssues:any = [];
  regions.forEach(region => {
    const lis = activityPackage.validatePriceConfigurations(region.code, activityCatalogue).map((issue, index) => <li key={index}>{issue}</li>);
    if(lis.length > 0) {
      regionIssues.push(<div key={region.code}>
        <div>{region.title.de} ({region.code})</div>
        <ul>{lis}</ul>
      </div>)
    }
  })
  // render
  if(regionIssues.length === 0) {
    return null;
  }
  return <Alert title="Eventuell inkorrekte Preiskonfigurationen" size="medium" intent="warning">
    {regionIssues}
  </Alert>
  
}