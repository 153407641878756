// libs
import _ from "lodash";
import moment from "moment";
import React, {useState, useEffect, useContext} from "react";
// config, types, util
import Config from "../../../config";
import Util from "../../../util/util";
import * as TS from "../../../types";
// components
import { DunningContext, DunningProvider } from "./Dunning.Context";
import { Breadcrumb, ConfirmButton, Card, Heading, Icon, Icons, Link, LinkButton, Loader, Alert, Table, Tr, Td } from "../../controls";
// styling
import CSS from "./Dunning.List.module.scss";

function DunningList() {
  // context 
  const DC = useContext(DunningContext);
  // state
  //const [surprises, setSurprises] = useState({});

  // mount
  useEffect(() => {
    DC.load();
  }, []);

  // render
  const level3Alert = <Alert title="Postausgang" intent="hint">Erhöhen der Mahnstufe verschiebt die Überraschung in die Gruppe 'Stufe 3 - Postausgang'</Alert>
  return <>
    <Breadcrumb links={[ {title:'Home', to:'/'}]} location="Mahnwesen" />
    <Heading>Mahnwesen</Heading>
    <Group title="Stufe 0 - 1. Mahnung via E-Mail" surprises={DC.surprises.level0} />
    <Group title="Stufe 1 - 2. Mahnung via E-Mail" surprises={DC.surprises.level1} />
    <Group title="Stufe 2 - 3. Mahnung per Brief" surprises={DC.surprises.level2} alert={level3Alert} />
    <Group title="Stufe 3 - Postausgang" surprises={DC.surprises.mailbox} letterDownload={true} />
    <Group title="Stufe 3 - Überraschung sperren" surprises={DC.surprises.level3} canLockSurprise={true} />
    <Group title="Überraschungen mit Mahnsperre" surprises={DC.surprises.dunningBlocked} />
    <Group title="Gesperrte Überraschungen" surprises={DC.surprises.locked} />
  </>
}

type GroupProps = {
  surprises: any,
  title: string,
  email?: string,
  letterDownload?: boolean,
  alert?: React.ReactNode,
  canLockSurprise?: boolean,
}
function Group({surprises, title, email, letterDownload, alert, canLockSurprise}: GroupProps) {
  // pre-render
  let content = <Loader />
  if(surprises) {
    let rows = surprises
      .sort((a:any, b:any) => moment(a.CreateDate).diff(b.CreateDate))
      .map((s:any) => <Item key={s._id} surprise={s} email={email} letterDownload={letterDownload ? true : false} canLockSurprise={canLockSurprise} />);
    if(rows.length === 0) {
      rows = <Tr><Td>keine Überraschung gefunden</Td></Tr>
    }
    content = <>
      <table><tbody>{rows}</tbody></table>
    </>
  }
  
  // render
  return <Card title={title} collapsible={true} expanded={false} className={CSS.container}>
    {alert || null}
    {content}
  </Card>
}


type ItemProps = {
  surprise: any,
  email?: string,
  letterDownload?: boolean,
  canLockSurprise?: boolean,
}
function Item({surprise, letterDownload, email, canLockSurprise}: ItemProps) {
  // context
  const DC = useContext(DunningContext);

  // state
  const [showDetails, setShowDetails] = useState(false);
  const [busy, setBusy] = useState(false);

  // user confirms toggle of dunning block
  const onConfirmDunningBlockToggle = async() => {
    setBusy(true);
    await DC.toggleDunningBlock(surprise);
  }

  // user consfirms increasing dunning level
  const onConfirmIncreaseLevel = async() => {
    setBusy(true);
    await DC.increaseLevel(surprise);
  }

  // user confirms locking of surprise
  const onConfirmLockSurprise = async() => {
    setBusy(true);
    await DC.lockSurprise(surprise);
  }

  // render
  const twirl = <span className={CSS.twirl} onClick={() => setShowDetails(!showDetails)}>{showDetails ? "-" : "+"}</span>;
  let tdEmail = null;
  if([0, 1].includes(surprise.PaymentReminderLevel) && surprise.DunningBlock !== true && surprise.Status !== TS.AdventureStatus.Locked) {
    tdEmail = <Td>
      <ConfirmButton busy={busy} onConfirm={onConfirmIncreaseLevel}>
        <Icon icon={Icons.Envelope} />&nbsp;E-Mail senden und Mahnstufe erhöhen&nbsp;<Icon icon={Icons.ArrowUp} />
      </ConfirmButton>
    </Td>
  }

  // up the dunning level
  let tdIncreaseLevel = null;
  if([2].includes(surprise.PaymentReminderLevel) && surprise.DunningBlock !== true && surprise.Status !== TS.AdventureStatus.Locked) {
    tdIncreaseLevel = <Td>
    <ConfirmButton busy={busy} onConfirm={onConfirmIncreaseLevel}>
      <Icon icon={Icons.ArrowUp} />&nbsp;Mahnstufe erhöhen
    </ConfirmButton>
  </Td>
  }

  // dunning block
  let buttonToggleDunningBlock = null;
  if(surprise.Status !== TS.AdventureStatus.Locked) {
    buttonToggleDunningBlock = (
      <ConfirmButton onConfirm={onConfirmDunningBlockToggle} busy={busy}>
        <Icon icon={surprise.DunningBlock ? Icons.Unlock : Icons.Lock} />&nbsp;Mahnsperre {surprise.DunningBlock ? "entfernen" : "aktivieren"}
      </ConfirmButton>
    )
  }

  // lock surprise
  let buttonLock = null;
  if(surprise.Status !== TS.AdventureStatus.Locked && canLockSurprise) {
    buttonLock = (
      <ConfirmButton onConfirm={onConfirmLockSurprise} busy={busy}>
        <Icon icon={Icons.Ban} />&nbsp;Überraschung sperren
      </ConfirmButton>
    )
  }

  // dunning letter download
  let tdDownloadLetter = null;
  if(letterDownload) {
    tdDownloadLetter = <Td>
      <LinkButton to={`${Config.api.ApiRoot}/v1/dunningletter/${surprise._id}?level=${surprise.PaymentReminderLevel}`} target="_blank" size="small">
        <Icon icon={Icons.FileDownload} />&nbsp;Brief herunterladen
      </LinkButton>
    </Td>
  }
  
  
  return (
    <Tr>
      <Td>
        {twirl}&nbsp;<Link to={`/surprise/${surprise._id}`}>{`[${Util.shortObjectId(surprise._id, 0, 4)}] ${surprise.ReserverName || "???"} für ${surprise.RecieverName || "???"}`}</Link>
        {showDetails ? <Details surprise={surprise} /> : null}
      </Td>
      {tdIncreaseLevel}
      {tdEmail}
      <Td>
        {buttonLock}
      </Td>
      <Td>
        {buttonToggleDunningBlock}
      </Td>
      
      {tdDownloadLetter}
      
    </Tr>
  )
}

type DetailsProps = {
  surprise: any,
}
function Details({surprise}: DetailsProps) {
  // pre-render
  const log = (surprise.Log || [])
    .sort((a:any, b:any) => moment(b.CreateDate).diff(a.CreateDate))
    .map((l:any,i:any) => <div key={i}>{Util.printDateAndTime(l.CreateDate)} : {l.Text}</div>);
  // render
  return (
    <div className={CSS.details}>
      <Table>
        <Tr>
          <Td>fällig seit:</Td>
          <Td>{Util.printDate(moment(surprise.CreateDate).add(20, "days"))}</Td>
        </Tr>
        <Tr>
          <Td>Rechnungsbetrag</Td>
          <Td>{Util.printCurrency(surprise.Price, surprise.Currency)}</Td>
        </Tr>
        <Tr>
          <Td>E-Mail Käufer</Td>
          <Td><Link to={`mailto:${surprise.ReserverEmail}`} target="_blank">{surprise.ReserverEmail}</Link></Td>
        </Tr>
        <Tr>
          <Td>Mahnungen</Td>
          <Td>
            {_.isNil(surprise.PaymentReminderSentDate) && _.isNil(surprise.PaymentReminder2SentDate) ? <div>noch keine</div> : null}
            {surprise.PaymentReminderSentDate ? <div>1. Mahnung per E-Mail verschickt am {Util.printDate(surprise.PaymentReminderSentDate)}</div> : null}
            {surprise.PaymentReminder2SentDate ? <div>2. Mahnung per E-Mail verschickt am {Util.printDate(surprise.PaymentReminder2SentDate)}</div> : null}
            {surprise.PaymentReminder3SentDate ? <div>3. Mahnung per Brief verschickt am {Util.printDate(surprise.PaymentReminder3SentDate)}</div> : null}
          </Td>
        </Tr>
        <Tr>
          <Td>Notizen</Td>
          <Td>{surprise.Notes || ""}</Td>
        </Tr>
        <Tr>
          <Td>Log</Td>
          <Td>
            {log}
          </Td>
        </Tr>
      </Table>
    </div>
  )
}

/* Notes
- Mahnstufe 1: automatischer Mail-Versand 14 Tage nach Fälligkeit
- Mahnstufe 2: automatischer Mail-Versand 14 Tage nach 1. Mahnung
- Mahnstufe 3: Liste (Überraschungs-Infos, Fälligkeitsdatum, Datum 1. Mahnung, Datum 2. Mahnung) mit "Mahnung drucken" Button (PDF-Download und Datum wird gesetzt)
- Mahnsperre: Checkbox auf Überraschung (wenn aktiviert werden keine Mahnungen verschickt)
- Liste mit Überraschungen mit Mahnsperre (mit Infos wie Fälligkeitsdatum, Mahndatum, etc.)

*/

// export wrapped in provider
export default (props:any) => <DunningProvider><DunningList {...props} /></DunningProvider>