import environment  from './environment'

const firebase_config_ticker_production = {
  apiKey: "AIzaSyCrpxJzMP6Uni6hcqbpNBm_U6pp7tCvf5M",
  authDomain: "appenturaticker.firebaseapp.com",
  databaseURL: "https://appenturaticker.firebaseio.com",
  projectId: "appenturaticker",
  storageBucket: "appenturaticker.appspot.com",
  messagingSenderId: "628848148612",
  message_text:'',
  message_send_enabled:false,
  selfie_data:null
}

const firebase_config_ticker_staging = {
  apiKey: "AIzaSyArCJyIqsLSNZ7aKWZE1fd6LYzz5m1JtFs",
  authDomain: "appenturatickerstaging.firebaseapp.com",
  databaseURL: "https://appenturatickerstaging.firebaseio.com",
  projectId: "appenturatickerstaging",
  storageBucket: "appenturatickerstaging.appspot.com",
  messagingSenderId: "586073869851",
  message_text:'',
  message_send_enabled:false,
  selfie_data:null
}


const firebase_config_status = {
  apiKey: "AIzaSyAkh-hg9tSAp4c9hnXh79qoZxCbXLMJmY0",
  authDomain: "appenturastatus.firebaseapp.com",
  databaseURL: "https://appenturastatus.firebaseio.com",
  projectId: "appenturastatus",
  storageBucket: "appenturastatus.appspot.com",
  messagingSenderId: "1066022950949"
}

const config = {
  development: {
    ticker: firebase_config_ticker_staging,
    status: firebase_config_status
  },
  staging: {
    ticker: firebase_config_ticker_staging,
    status: firebase_config_status
  },
  production: {
    ticker: firebase_config_ticker_production,
    status: firebase_config_status
  }
}

// NOTE: the APPENTURA_ENV variable is delivered by Webpack via a plugin (see plugins section in webpack.config.js'. There are three environments: development, staging, production
export default config[environment]