import React, { useContext, useState } from "react";
import { ConfirmButton, Dropdown, DropdownOption, CardSection } from "../../../controls";
import { ActivityContext } from "../ActivityContext";

export default function CopyFrom() {
  // context
  const AC = useContext(ActivityContext)!;

  // state
  const [regionCode, setRegionCode] = useState<string>("");
  const [busy, setBusy] = useState(false);

  // user wants to copy from selected region
  const copyFrom = async() => {
    const targetRegionCode = AC.regionVariant!.regionCode;
    setBusy(true);
    await AC.copyRegionVariant(regionCode, targetRegionCode);
    setRegionCode("");
    setBusy(false);
  }

  // user changes selected region
  const onChangeRegion = (regionCode:any) => {
    setRegionCode(regionCode);
  }
  
  // pre-render
  const options: DropdownOption[] = AC.regions
  .filter(r => r.code !== AC.regionVariant!.regionCode)
  .map(r => {
    return {label:r.title.de, value:r.code};
  });
  options.unshift({label:"- Region wählen -", value:""})
  
  // render
  return (
    <CardSection title="aus anderer Regionsvariante kopieren">
      <Dropdown label="Region" options={options} onChange={onChangeRegion} value={regionCode} />
      <ConfirmButton disabled={regionCode === ""} busy={busy} onConfirm={copyFrom}>kopieren</ConfirmButton>
    </CardSection>
  )
}
