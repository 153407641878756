// TODO work in progress
import React, {useEffect, useState} from "react";
import { Label } from ".";
import CSS from "./Switch.module.scss";

type SwitchProps = {
  label?: string,
  onChange?: any, 
  disabled?: boolean,
  value?: boolean,
  size?: "small"|"medium"
}
export function Switch({label, value, onChange, disabled, size} : SwitchProps) {
  // defaults
  size = size ? size : "small";
  // state
  const [isOn, setIsOn] = useState(value);

  // value changed outside?
  useEffect(() => {
    setIsOn(value ? true :  false);
  }, [value]);

  // const change
  const toggle = () => {
    if(!disabled) {
      if(onChange) {
        onChange(!isOn);
      }
      else {
        setIsOn(!isOn);
      }
    }
  }

  //render
  return (
    <div className={`${CSS.container} ${CSS[size]} ${isOn ? CSS.on : CSS.off} ${disabled ? CSS.disabled : ""}` } onClick={toggle}>
      {label ? <Label label={label} /> : null}
      <div className={CSS.inner}>
        <div className={CSS.slider} />
      </div>
    </div>
  )
}
